import { buildQueryParamTypes, buildQueryParams } from '@/utils/fns/graphql-query-builder';
import type { Sorter } from '@/utils/type-definitions/filters';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';
import type { AdvisorFounderPendingConnection } from '../types';

export const GET_NED_FOUNDER_PENDING_CONNECTIONS_KEY = 'get-ned-founder-pending-connections-query';

type ResponseData = {
  total: number;
  items: AdvisorFounderPendingConnection[];
};

type QueryConfig = {
  page?: number;
  perPage?: number;
  sort?: Sorter;
};

export const getAdvisorInvites = async ({
  page = 1,
  perPage = 6, // sort,
}: QueryConfig): Promise<GraphqlResponse<ResponseData>> => {
  const variablesType = {
    start: 'Int!',
    limit: 'Int!',
    // sort: '[SortType!]',
  };

  const variables = {
    start: (page - 1) * perPage,
    limit: page * perPage,
    // sort,
  };

  const { data } = await axiosInstance.post('/query', {
    variables,
    query: `
      query getAdvisorInvites (${buildQueryParamTypes(variablesType)}) {
        getNedPendingConnections (${buildQueryParams(variablesType)}) {
          total
          items {
            connectionId
            requestedAt
            founderId
            avatarUrl
            representativeName
            businessName
            industry
            investmentStage
            description
          }
        }
      }
    `,
  });

  return graphqlResponseWrapper(data, ['getNedPendingConnections']);
};

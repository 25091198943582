import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import type { RegionValue } from '@utils/type-definitions/regions-values';
import type { GraphqlResponse } from 'src/types';

export const GET_BROWSE_COMPANIES_FILTERS_CACHE_KEY = 'get-browser-companies-filters';

export enum FilterCompaniesSortOptions {
  Newest = 'Newest',
  BusinessNameAsc = 'BusinessNameAsc',
  BusinessNameDesc = 'BusinessNameDesc',
}

export interface MetricOptions {
  name: string;
  code: string;
  min: number;
  max: number;
  step: number;
  filteringMode: MetricFilterMode;
}

export enum MetricFilterMode {
  Value = 'Value',
  Forecast = 'Forecast',
}

export interface LastSearch {
  namePhrase: string | string[] | null;
  productStage: string | string[] | null;
  investmentStage: string | string[] | null;
  industry: string | string[] | null;
  customerGroup: string | string[] | null;
  metrics: Omit<MetricOptions, 'step' | 'name'>[] | null;
  hasEisLimitsRemaining: boolean | null;
  hasSeisLimitsRemaining: boolean | null;
  onlyNewlyRegistered: boolean | null;
  sort: FilterCompaniesSortOptions | null;
  regions: RegionValue[] | null;
  countriesOfResidence: CountryCodes[] | null;
}

export interface BrowseCompaniesFilters {
  lastSearchData: LastSearch;
}

export const getBrowseCompaniesFilters = async (): Promise<GraphqlResponse<BrowseCompaniesFilters>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getBrowseCompaniesFilters { 
        getDataForCompanyFilter {
          lastSearchData {
            namePhrase
            productStage
            industry
            customerGroup
            investmentStage
            metrics {
              min
              max
              code
              filteringMode
            }
            hasEisLimitsRemaining
            hasSeisLimitsRemaining
            onlyNewlyRegistered
            sort
            countriesOfResidence
            regions
          }
        }
      }
    `,
  });

  return graphqlResponseWrapper(data, ['getDataForCompanyFilter']);
};

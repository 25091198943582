import { CloseCircleOutlined } from '@ant-design/icons';
import ModalBase from 'antd/lib/modal/Modal';
import styled from 'styled-components';

const IconHolder = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled(ModalBase)<{ hasChildren: boolean }>`
  .ant-modal-body {
    ${({ hasChildren }) => !hasChildren && 'padding: 0'};
    color: ${({ theme }) => theme.color.fontPrimary};
  }

  .ant-modal-header {
    border: none;
    background-color: ${({ theme }) => theme.color.dashboardBackground};

    & > .ant-modal-title {
      font-size: ${({ theme }) => theme.fontSize.medium};
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      color: ${({ theme }) => theme.color.primary};
      text-align: center;
    }
  }

  .ant-modal-content {
    padding-bottom: 0;
    background-color: ${({ theme }) => theme.color.sectionBackground};

    .ant-modal-close {
      .anticon.anticon-close-circle {
        font-size: 23px;
        color: ${({ theme }) => theme.color.primary};
      }
    }
  }

  .ant-modal-footer {
    border: none;
    display: flex;
    justify-content: center;
    padding-bottom: ${({ theme }) => theme.spacing.xmedium};
    padding-top: ${({ theme }) => theme.spacing.xmedium};
  }
`;

const Close = styled(CloseCircleOutlined)`
  width: 60px;
  height: auto;
`;

export default { IconHolder, Modal, Close };

import { V2Pagination } from '@/components/blocks/v2-pagination/v2-pagination';
import { V2Alert } from '@/components/ui/v2-alert/v2-alert';
import { V2Loader } from '@/components/ui/v2-loader/v2-loader';
import { useMediaQuery } from '@/hooks/use-media-query';
import { useUrlFilters } from '@/hooks/use-url-filters';
import { TranslationText } from '@/parts/translation-text/translation-text';
import type { Sorter } from '@/utils/type-definitions/filters';
import { useState } from 'react';
import { useGetConnections } from '../../hooks/use-get-connections';
import type { InvestorAdvisorConnectionIntent } from '../../types';
import { AdvisorConnectionsMobileList } from './connections-mobile-list';
import { ConnectionsTable } from './connections-table';
import { InvestorAdvisorConnectionsFilters } from './filters';

import S from '../../connections-page.styles';

const DEFAULT_INTENT_FILTER_VALUE = 'All';
const clearInvalidIntentFilter = (value: string): InvestorAdvisorConnectionIntent | undefined => {
  const ALLOWED_CONNECTION_INTENTS: InvestorAdvisorConnectionIntent[] = ['Advising', 'Investing'];
  return ALLOWED_CONNECTION_INTENTS.find((item) => item === value) ?? undefined;
};

export const Connections = () => {
  const size = useMediaQuery();
  const isMobile = ['sm', 'xs'].includes(size);

  const [sorting, setSorting] = useState<Sorter | undefined>(undefined);
  const { filterValueSingle, apply } = useUrlFilters<'intent' | 'phrase'>(['intent', 'phrase']);

  const { connectionsData, pagination, isConnectionsDataLoading, refetch } = useGetConnections({
    searchByName: filterValueSingle('phrase'),
    intent: clearInvalidIntentFilter(filterValueSingle('intent')),
    sort: sorting,
  });

  if (isConnectionsDataLoading && !connectionsData?.data) return <V2Loader height="320px" />;
  if (!connectionsData?.data)
    return <V2Alert variant="error" title="Error loading data" description="Please try again later" showIcon />;

  return (
    <S.TabWrapper data-testid="investor-advisor-connections-table">
      <InvestorAdvisorConnectionsFilters
        disabled={isConnectionsDataLoading}
        filters={[
          { value: 'All', label: <TranslationText id="connections.investor-advisor.filters.both" /> },
          { value: 'Advising', label: <TranslationText id="connections.investor-advisor.filters.advising" /> },
          { value: 'Investing', label: <TranslationText id="connections.investor-advisor.filters.investing" /> },
        ]}
        activeFilter={[filterValueSingle('intent') || DEFAULT_INTENT_FILTER_VALUE]}
        onFilterChange={(v) => apply({ intent: v, phrase: filterValueSingle('phrase') })}
        initialSearchValue={filterValueSingle('phrase')}
        onSearchSubmit={(v) => apply({ phrase: v, intent: [filterValueSingle('intent')] })}
        sortValue={sorting}
        sortOptions={[
          { label: <TranslationText id="connections.investor-advisor.sortBy.name" />, key: 'businessName' },
        ]}
        submit={(v) => {
          setSorting(v.sort ? v.sort : undefined);
          apply({ intent: v.filters, phrase: v.search });
        }}
      />

      {isMobile && <AdvisorConnectionsMobileList data={connectionsData.data.items} refetch={refetch} />}
      {!isMobile && (
        <ConnectionsTable
          data={connectionsData.data.items}
          sorting={sorting}
          setSorting={setSorting}
          refetch={refetch}
        />
      )}
      <V2Pagination current={pagination.page} total={pagination.totalPages}>
        <V2Pagination.PreviousPageButton onClick={pagination.goToPreviousPage} />
        <V2Pagination.DisplayCurrentPageNumber />
        <V2Pagination.NextPageButton onClick={pagination.goToNextPage} />
      </V2Pagination>
    </S.TabWrapper>
  );
};

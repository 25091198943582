import React from 'react';
import type { V2WithAsteriskProps } from './v2-with-asterisk.types';

import S from './v2-with-asterisk.styles';

export const V2WithAsterisk: React.FC<V2WithAsteriskProps> = ({
  children,
  color,
  position = 'end',
  asteriskClassName,
  asteriskStyle,
  asteriskTestId = 'v2-with-asterisk-asterisk',
}) => {
  const asterisk = (
    <S.Asterisk $color={color} data-testid={asteriskTestId} className={asteriskClassName} style={asteriskStyle}>
      *
    </S.Asterisk>
  );

  return (
    <>
      {position === 'start' && asterisk}
      {children}
      {position === 'end' && asterisk}
    </>
  );
};

import { MetricType } from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import { axiosInstance } from '@utils/axios/axios-instance';

export type AddDashboardElementsType = {
  metrics: {
    id: string;
    type: MetricType;
  }[];
  addTextBox: boolean;
};
export const addDashboardElementsAction =
  () =>
  async ({
    dashboardId,
    ...payload
  }: AddDashboardElementsType & {
    dashboardId: string;
  }) =>
    axiosInstance.post(`/pulse/dashboards/${dashboardId}/elements`, payload);

import AlertBase from '@parts/alert/alert';
import { Button as ButtonBase } from '@parts/button-with-tick/button-with-tick';
import { FormikRow as FormikRowBase } from '@parts/forms/formik-row/formik-row';
import { SecureLink as LinkBase } from '@parts/link/link';
import styled from 'styled-components';

const HR = styled.hr`
  border-top: ${({ theme }) => theme.baseColors.Blue300};
  margin: 20px 0;

  &:last-of-type {
    margin-bottom: 35px;
  }
`;

const ReviewButtonsDiv = styled.div`
  display: flex;
  margin-top: 30px;
`;

const ReviewBtn = styled(ButtonBase)`
  background: transparent;
  border: none;
  box-shadow: none;
  color: ${({ theme }) => theme.color.fontBlue};
`;

const Link = styled(LinkBase)`
  display: block;
  color: ${({ theme }) => theme.color.fontBlue};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontSize.small};
  text-decoration: none;
`;

const Alert = styled(AlertBase)`
  width: 80%;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  align-items: center;

  > span {
    font-size: ${({ theme }) => theme.fontSize.medium};
  }

  .ant-alert-message {
    justify-content: flex-start;
    margin: 0;
  }
`;

const AvatarWithNameWrapper = styled.div<{ cursorVisible: boolean }>`
  cursor: ${({ cursorVisible }) => (cursorVisible ? 'pointer' : 'default')};
  pointer-events: ${({ cursorVisible }) => (cursorVisible ? 'auto' : 'none')};
`;
const FormikRow = styled(FormikRowBase)`
  &:last-of-type {
    margin: 0;
  }
`;
const Button = styled(ButtonBase)`
  &[disabled],
  &[disabled]:hover {
    background: ${({ theme }) => theme.color.submitButton};
    border: 1px solid ${({ theme }) => theme.color.submitButton};
    color: ${({ theme }) => theme.color.buttonText};
    opacity: 0.5;
  }
`;

export default {
  Button,
  HR,
  ReviewBtn,
  ReviewButtonsDiv,
  Link,
  Alert,
  AvatarWithNameWrapper,
  FormikRow,
};

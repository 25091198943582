import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_MARKET_FIT_QUESTIONS_CACHE_KEY = 'get-market-fit-questions-cache-key';

export const STF_QUESTION_CODE = 'STF';
export const PSE_QUESTION_CODE = 'PSE';
export const WII_QUESTION_CODE = 'WII';
export const WWM_QUESTION_CODE = 'WWM';
export const LN_QUESTION_CODE = 'LN';
export const NI_QUESTION_CODE = 'NI';

export type QuestionCode =
  | typeof STF_QUESTION_CODE
  | typeof PSE_QUESTION_CODE
  | typeof WII_QUESTION_CODE
  | typeof WWM_QUESTION_CODE
  | typeof LN_QUESTION_CODE
  | typeof NI_QUESTION_CODE;

export interface MarketFitQuestionItem {
  questionCode: QuestionCode;
  lengthLimits: {
    max: number;
    min: number;
  };
  booleanAnswer: boolean | null;
  textAnswer: string | null;
}

export const getMarketFitQuestions = async (): Promise<GraphqlResponse<MarketFitQuestionItem[]>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getMarketFitQuestions {
        questions: founderMarketFit {
          questionCode
          lengthLimits {
            min
            max
          }
          booleanAnswer
          textAnswer
        }
      }    
    `,
  });

  return graphqlResponseWrapper(data, ['questions']);
};

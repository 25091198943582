import type { ColorThemeMode } from '@/context/theme/app-theme.context';
import { getLsThemeKey } from '@/context/theme/app-theme.provider';
import { LocaleProvider } from '@context/locale/locale.provider';
import { GlobalStyles } from '@styles/global.styles';
import { ResetStyles } from '@styles/reset.styles';
import { darkThemeColors, lightThemeColors, theme as themeBase } from '@styles/theme-config';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { InternalServerErrorPage } from '../internal-server/internal-server.page';

const useThemeWithoutProvider = () => {
  const [mode, setMode] = useState<ColorThemeMode>('light');

  useEffect(() => {
    const foundTheme = window.localStorage.getItem(getLsThemeKey());
    setMode(foundTheme === 'dark' ? 'dark' : 'light');
  });

  const color = mode === 'dark' ? darkThemeColors : lightThemeColors;
  return { theme: { ...themeBase, color, mode } };
};

export const CriticalErrorPage = () => {
  const { theme } = useThemeWithoutProvider();

  return (
    <LocaleProvider>
      <StyledThemeProvider theme={theme}>
        <ResetStyles />
        <GlobalStyles />
        <Router>
          <InternalServerErrorPage critical />
        </Router>
      </StyledThemeProvider>
    </LocaleProvider>
  );
};

import { useModal } from '@/hooks/use-modal';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import React, { useState } from 'react';
import { V2Button } from '../v2-button/v2-button';
import { V2Modal } from '../v2-modal/v2-modal';
import { V2Title } from '../v2-title/v2-title';
import type { V2ConfirmDialogProps } from './v2-confirm-dialog.types';

import S from './v2-confirm-dialog.styles';

export const V2ConfirmDialog = ({
  message,
  cancelButtonText: cancelText,
  confirmButtonText: confirmText,
  size = 'md',
  children,
  centered = false,
}: V2ConfirmDialogProps) => {
  if (!children || !React.isValidElement(children) || !('onClick' in (children as React.ReactElement).props)) {
    throw new Error('V2ConfirmDialog: children must be a single node with onClick prop');
  }

  const { isOpen, close, open } = useModal();
  const [busy, setBusy] = useState(false);
  const [cancelLL, confirmLL] = useTranslation(['global.cancel', 'global.confirm']);

  const trigger = React.cloneElement(children as React.ReactElement, { onClick: open });
  const onConfirm = (children as React.ReactElement).props.onClick;

  const handleConfirm = async () => {
    setBusy(true);
    await onConfirm();
    close();
    setBusy(false);
  };

  return (
    <>
      {trigger}
      <V2Modal
        isOpen={isOpen}
        footer={null}
        closable={false}
        onCancel={close}
        onConfirm={handleConfirm}
        data-testid="v2-confirm-dialog-modal"
        size={size}
        centered={centered}
      >
        <S.Content>
          <S.Message>
            {typeof message === 'string' ? (
              <V2Title size="xl" title={message} data-testid="v2-confirm-dialog-modal-title" />
            ) : (
              message
            )}
          </S.Message>
          <S.Footer>
            <V2Button
              disabled={busy}
              onClick={close}
              size="large"
              variant="outlined"
              data-testid="v2-confirm-dialog-modal-cancel-button"
            >
              {cancelText ?? cancelLL}
            </V2Button>
            <V2Button
              disabled={busy}
              onClick={handleConfirm}
              size="large"
              variant="primary"
              data-testid="v2-confirm-dialog-modal-confirm-button"
            >
              {confirmText ?? confirmLL}
            </V2Button>
          </S.Footer>
        </S.Content>
      </V2Modal>
    </>
  );
};

import { AdvisorRole } from '@/domain/accounts/roles';
import { GET_ADVISOR_PROFILE_READINESS } from '@/modules/advisor/homepage/api/get-advisor-profile-readiness.query';
import { GET_ADVISOR_PUBLISH_STATUS } from '@/modules/advisor/homepage/api/get-advisor-publish-status.query';
import type { TargetGeographyValue } from '@constants/target-geography';
import { setUserMetadata } from '@context/user/user-account.actions';
import {
  SubscriptionProductStatus,
  SubscriptionProductType,
  type SubscriptionProduct,
} from '@domain/accounts/subscription-products';
import { NedLayout } from '@layout/ned/ned.layout';
import { GET_NED_BROWSE_DEFAULTS_CACHE_KEY } from '@pages/content/lens/api/get-ned-browse-defaults.action';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { generateUserName } from '@utils/fns/generate-user-name';
import { getServerError } from '@utils/fns/get-server-error';
import { mapEmptyToString } from '@utils/fns/map-empty-to-string';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import message from '../../../../parts/message/message';
import {
  GET_NED_PUBLISH_STATUS_WITH_SHORTAGES_CACHE_KEY,
  getNedPublishStatusWithShortages,
} from './api/get-ned-publish-status/get-ned-publish-status.action';
import {
  GET_SKILLS_INDUSTRIES_ROLES_REQUESTS_CACHE_KEY,
  getSkillsIndustriesRolesAction,
} from './api/get-skills-industries-roles/get-skills-industries-roles.action';
import {
  updateAboutMeAction,
  updatePersonalDetailsAction,
  updateSmartMatchAction,
} from './api/ned-profile/ned-profile.action';
import { NED_PROFILE_DATA_QUERY_KEY, getNedProfileDataAction } from './api/ned-profile/ned-profile.query';
import { NedProfile } from './parts/ned-profile';
import { useGetExperience } from '@/modules/advisor/profile/hooks/use-get-experience';

export interface INedPersonalDetails {
  email: string;
  profilePhotoPath?: string;
  profilePhotoUrl?: string;
  linkedinUrl: string;
  twitterUrl: string;
  countryOfResidence: CountryCodes | null;
  resume?: {
    path: string;
    name: string;
    url: string;
  } | null;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export enum CompensationType {
  Paid = 'Paid',
  Equity = 'Equity',
  Blended = 'Blended',
}

export type Compensation = {
  role: Omit<AdvisorRole, AdvisorRole.PLACEMENT>;
  compensationType: CompensationType | null;
  minDayRate: number | null;
  maxDayRate: number | null;
};

export interface INedAbout {
  aboutMe: string | null;
  myOffering: string | null;
  myExpectations: string | null;
}

export interface INedSmartMatch {
  industries: string[];
  isNed: boolean;
  isAdvisor: boolean;
  isFractional: boolean;
  primarySkillSet: string | null;
  secondarySkillSet: string[];
  compensations: Compensation[];
  targetGeography: TargetGeographyValue[];
}

export interface INedExperience {
  id: string;
  company: {
    id: string | null;
    name: string;
    avatarUrl: string | null;
    industry: string[];
    isExternal: boolean;
    email: string | null;
  };
  role: string;
  startDate: string;
  endDate: string | null;
  compensationType: string | null;
  isOngoing: boolean;
  skillSet: string[];
  experienceSummary: string | null;
}

export type INedProfileData = INedPersonalDetails & INedSmartMatch & INedAbout;

export const NedProfilePage = ({ Layout = NedLayout }: { Layout?: Function }) => {
  const queryClient = useQueryClient();
  const [formUpdated] = useTranslation(['messages.form.success']);

  const { dispatch, state } = useUserAccount();

  const {
    data: responseNedData,
    isInitialLoading: isNedInitialLoading,
    refetch: refetchNedData,
  } = useQuery([NED_PROFILE_DATA_QUERY_KEY], getNedProfileDataAction, {
    cacheTime: 3600,
    refetchOnWindowFocus: false,
  });

  const { experience = [] } = useGetExperience();

  const {
    data: responsePublishStatus,
    isInitialLoading: publishNedStatusInitialLoading,
    refetch: refetchPublishStatus,
  } = useQuery([GET_NED_PUBLISH_STATUS_WITH_SHORTAGES_CACHE_KEY], getNedPublishStatusWithShortages, { cacheTime: 0 });

  const { mutateAsync: updatePersonalDetails } = useMutation(updatePersonalDetailsAction, {
    onSuccess: async (_, vars) => {
      message.success({ content: formUpdated });
      refetchNedData();
      refetchPublishStatus();
      await queryClient.invalidateQueries([GET_ADVISOR_PROFILE_READINESS]);
      dispatch(
        setUserMetadata({
          ...state,
          name: generateUserName(vars.firstName, vars.lastName),
          avatarUrl: vars.profilePhotoUrl || null,
        }),
      );
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  const { mutateAsync: updateSmartMatch, isLoading: isUpdatingSmartMatch } = useMutation(updateSmartMatchAction, {
    onSuccess: async () => {
      message.success({ content: formUpdated });
      refetchPublishStatus();
      refetchNedData();
      // invalidate NedLensPage NedBrowseDefaults query cache
      await queryClient.invalidateQueries({ queryKey: [GET_NED_BROWSE_DEFAULTS_CACHE_KEY] });
      await queryClient.invalidateQueries([GET_ADVISOR_PROFILE_READINESS]);
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  const { mutateAsync: updateAboutMe, isLoading: isUpdatingAboutMe } = useMutation(updateAboutMeAction, {
    onSuccess: async () => {
      message.success({ content: formUpdated });
      refetchPublishStatus();
      refetchNedData();
      await queryClient.invalidateQueries([GET_ADVISOR_PROFILE_READINESS]);
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  const {
    data: responseSkillsIndustriesRoles,
    isInitialLoading: isSkillsIndustriesInitialLoading,
    refetch: refetchSkillsIndustriesRoles,
  } = useQuery([GET_SKILLS_INDUSTRIES_ROLES_REQUESTS_CACHE_KEY], getSkillsIndustriesRolesAction);

  const handleRefetchPublishStatus = async () => {
    await Promise.all([
      refetchPublishStatus(),
      queryClient.invalidateQueries([GET_ADVISOR_PUBLISH_STATUS]),
      queryClient.invalidateQueries([GET_ADVISOR_PROFILE_READINESS]),
    ]);
  };

  const renderContent = () => {
    if (!responseNedData?.data) {
      refetchNedData();
      return;
    }

    if (!responseSkillsIndustriesRoles?.data) {
      refetchSkillsIndustriesRoles();
      return;
    }
    if (!responsePublishStatus?.data) {
      refetchPublishStatus();
      return;
    }

    const { personalDetails, smartMatch, investorTransitionState, about } = responseNedData.data;

    const nedProfileDetailsWithoutNulls = mapEmptyToString(personalDetails);
    const profileData = {
      ...nedProfileDetailsWithoutNulls,
      ...smartMatch,
      ...about,
      resume: {
        name: nedProfileDetailsWithoutNulls.resumeName,
        path: nedProfileDetailsWithoutNulls.resumePath,
        url: nedProfileDetailsWithoutNulls.resumeUrl,
      },
      profilePhotoPath: nedProfileDetailsWithoutNulls.photoUrl,
      profilePhotoUrl: nedProfileDetailsWithoutNulls.photoUrl,
      experience: [...(experience || [])],
    };

    const haveInvestorProductAwaitingAcceptance = (products: SubscriptionProduct[]) =>
      products.some(
        (product) =>
          product.type === SubscriptionProductType.INVESTOR &&
          product.status === SubscriptionProductStatus.WAITING_FOR_APPROVAL,
      );

    const isInvestorAcceptanceAwaiting = haveInvestorProductAwaitingAcceptance(state.products);

    return (
      <NedProfile
        refetchNedData={refetchNedData}
        profileData={profileData}
        handleSubmitPersonalDetails={updatePersonalDetails}
        handleSubmitSmartMatch={updateSmartMatch}
        handleSubmitAboutMe={updateAboutMe}
        isMutatingAboutMe={isUpdatingAboutMe}
        industriesOptions={responseSkillsIndustriesRoles?.data?.industries!}
        isMutatingSmartMatch={isUpdatingSmartMatch}
        skillsOptions={responseSkillsIndustriesRoles?.data?.skillSets!}
        becomeInvestor={{ ...investorTransitionState, awaitingApproval: isInvestorAcceptanceAwaiting }}
        refetchPublishStatus={handleRefetchPublishStatus}
        publishStatus={responsePublishStatus?.data!}
      />
    );
  };

  const isInitialDataLoading =
    isSkillsIndustriesInitialLoading || isNedInitialLoading || publishNedStatusInitialLoading;

  return <Layout>{isInitialDataLoading ? <FullHeightSpinner /> : renderContent()}</Layout>;
};

import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const RingInner = styled.div<{ ringWidth: number }>`
  position: relative;
  width: calc(100% - 18%);
  height: calc(100% - 18%);
  background-color: ${({ theme }) => theme.color.readinessScoreBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 100%;

  &::after {
    content: '';
    display: block;
    height: calc(100% + 19%);
    width: calc(100% + 19%);
    position: absolute;
    border-radius: 100%;
    border: ${({ ringWidth }) => ringWidth}px solid ${({ theme }) => theme.color.readinessScoreBackground};
  }
`;

const RingColor = styled.div<{ active: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  border-style: solid;
  border-radius: 100%;

  transition: opacity ease-in-out 500ms;
  opacity: ${({ active }) => (active ? 1 : 0)};
`;

const RingColorError = styled(RingColor)`
  background: ${({ theme }) => theme.gradient.red};
`;

const RingColorWarning = styled(RingColor)`
  background: ${({ theme }) => theme.gradient.yellow};
`;

const RingColorSuccess = styled(RingColor)`
  background: ${({ theme }) => theme.gradient.green};
`;

const ScoreNumber = styled.div<{ fontSize: number }>`
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.color.fontPrimary};
  line-height: 1.2;
  text-align: center;
`;

const ScoreText = styled.small<{ fontSize: number }>`
  padding: 0 15%;
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.color.fontPrimary};
  line-height: 1.2;
  text-align: center;
`;

export default {
  Wrapper,
  RingInner,
  RingColorError,
  RingColorWarning,
  RingColorSuccess,
  ScoreNumber,
  ScoreText,
};

import type { AnyAbility } from '@casl/ability';
import { createContextualCan, useAbility } from '@casl/react';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { createContext, useMemo } from 'react';
import { RoleAbility } from '../ability';
import type { AppAbility } from '../ability/types';

export const AbilityContext = createContext<AppAbility>({} as AppAbility);
export const ContextualCan = createContextualCan(AbilityContext.Consumer as React.Consumer<AnyAbility>);

export const UserAbilityProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    state: {
      isAuthorized,
      userRole,
      products,
      countryOfResidence: cor,
      onboarding,
      status: accountStatus,
      permissions: apiDefinedPermissions,
      isAcademy,
    },
  } = useUserAccount();

  const userAbility = useMemo(
    () =>
      new RoleAbility(
        isAuthorized,
        accountStatus,
        userRole,
        products,
        cor,
        onboarding,
        apiDefinedPermissions,
        isAcademy,
      ).build(),
    [isAuthorized, userRole, products, cor, onboarding, accountStatus, isAcademy, apiDefinedPermissions],
  );

  return <AbilityContext.Provider value={userAbility}>{children}</AbilityContext.Provider>;
};

export const useUserAbility = () => useAbility(AbilityContext);

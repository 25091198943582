import { useV2DropdownContext } from '../v2-dropdown-context';

import S from '../v2-dropdown.styles';

type ItemProps = {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
};

export const Item = ({ children, ...rest }: ItemProps) => {
  useV2DropdownContext();

  return <S.Item {...rest}>{children}</S.Item>;
};

import S from './image.styles';

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  name: string;
}

export const Image = ({ className, name, width = '100%', height = '100%', ...nativeImageProps }: ImageProps) => (
  <S.Wrapper className={className}>
    <S.Image src={`/assets/images/${name}`} width={width} height={height} {...nativeImageProps} />
  </S.Wrapper>
);

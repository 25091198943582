import {
  SubscriptionProductStatus,
  SubscriptionProductType,
  type SubscriptionProduct,
} from '@domain/accounts/subscription-products';

export const isUnpaidFounder = (products: SubscriptionProduct[]) => {
  const founderProducts = products
    .filter((prod) => prod.status === SubscriptionProductStatus.APPROVED)
    .filter((prod) =>
      [
        SubscriptionProductType.FOUNDER,
        SubscriptionProductType.FOUNDER_LEGAL,
        SubscriptionProductType.FOUNDER_RAISE,
        SubscriptionProductType.FOUNDER_PLUS,
      ].includes(prod.type),
    );

  return founderProducts.length === 0;
};

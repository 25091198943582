import message from '@parts/message/message';
import { useModal } from '@parts/modal/use-modal';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { changePasswordAction } from '../../api/change-password/change-password.actions';
import S from './change-password.styles';

export const ChangePassword = () => {
  const [
    changePasswordLabel,
    changePasswordConfirmLabel,
    modalDescription,
    modalListItem1,
    modalListItem2,
    changePasswordSuccess,
  ] = useTranslation([
    'profile.resetPassword.header',
    'profile.resetPassword.confirmText',
    'profile.resetPassword.modal.description',
    'profile.resetPassword.modal.description.li1',
    'profile.resetPassword.modal.description.li2',
    'profile.resetPassword.message.successText',
  ]);

  const { Modal, open, show, hide } = useModal();

  const { mutateAsync: changePassword, isLoading } = useMutation(changePasswordAction, {
    onSuccess: () => {
      message.success({
        content: changePasswordSuccess,
      });
      show();
    },
    onError: (err: AxiosError) => {
      message.error({
        content: getServerError(err),
      });
    },
  });

  const modalListItems = [modalListItem1, modalListItem2];

  return (
    <>
      <S.ChangePasswordButton
        type="link"
        onClick={() => changePassword()}
        data-testid="change-password-button"
        disabled={isLoading}
      >
        {changePasswordLabel}
      </S.ChangePasswordButton>
      <Modal
        visible={open}
        closeModal={hide}
        okButtonProps={{ disabled: false }}
        cancelButtonProps={{ hidden: true }}
        title={changePasswordLabel}
        onOk={hide}
        okText={changePasswordConfirmLabel}
        closable
      >
        <S.Description>{modalDescription}</S.Description>
        <S.List>
          {modalListItems.map((item) => (
            <S.ListItem key={item}>{item}</S.ListItem>
          ))}
        </S.List>
      </Modal>
    </>
  );
};

import { AcceptOrIgnoreInvitation } from '@pages/content/lens/parts/cards/parts/accept-or-ignore-invitation/accept-or-ignore-invitation';
import { Footer } from '@pages/content/lens/parts/footer/footer';
import { checkIfValidWysiWygValue } from '@pages/content/profile/hooks/use-wysiwyg';
import { founderIdQueryParam } from '@pages/content/public-profile/founder/founder.page';
import { CompanyTile } from '@parts/company-tile/company-tile';
import { GridItem } from '@parts/grid-item/grid-item';
import metaConfig from '@parts/meta-config/meta-config';
import { Links } from '@router/links';
import { SanitizeWysiwyg } from '@utils/fns/sanitize-wysiwyg';
import { useHistory } from '@utils/hooks/use-history/use-history';

export const InvitingCompany = ({
  id,
  name,
  logoUrl,
  industry,
  customerGroup,
  productStage,
  investmentStage,
  about,
  founderId,
  refetch,
}: {
  id: string;
  name: string;
  logoUrl: string;
  industry: string[];
  customerGroup: string[];
  investmentStage: string;
  productStage: string;
  about: string;
  founderId: string;
  refetch: Function;
}) => {
  const history = useHistory();

  return (
    <GridItem
      dataTestId="inviting-company"
      header={
        <CompanyTile name={name} logoUrl={logoUrl} supplement={industry.length > 0 ? industry.join(', ') : '---'} />
      }
      meta={[metaConfig({ customerGroup }).business, metaConfig({ investmentStage, productStage }).growth]}
      description={{
        dangerouslySet: true,
        content: checkIfValidWysiWygValue(about) ? SanitizeWysiwyg(JSON.parse(about)) : null,
      }}
      footer={
        <Footer>
          <AcceptOrIgnoreInvitation id={id} refetch={refetch} />
        </Footer>
      }
      url={history.createHref({
        search: `${founderIdQueryParam}=${founderId}`,
        pathname: Links.FOUNDER_PUBLIC_PROFILE_DETAILS(),
      })}
    />
  );
};

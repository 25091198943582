import { AdvisorRole } from '@/domain/accounts/roles';
import { useScrollToElementWithQueryParams } from '@/utils/hooks/use-scroll-to-element/use-scroll-to-element-with-query-params';
import { TARGET_GEOGRAPHIES_OPTIONS, TargetGeographyValue } from '@constants/target-geography';
import {
  usePublishStatusEvaluation,
  type PublishStatus,
} from '@pages/content/profile/hooks/use-publish-status-evaluation';
import { PublishSwitch } from '@pages/content/profile/parts/publish-switch/publish-switch';
import { LabelWithTooltip } from '@parts/label-with-tooltip/label-with-tooltip';
import { MultiSelectSearch } from '@parts/multiselect-search/multiselect-search';
import { PillButtonCheckbox } from '@parts/pill-button-checkbox/pill-button-checkbox';
import { Section } from '@parts/section/section';
import { SubmitButton } from '@parts/submit-button/submit-button';
import { Tooltip } from '@parts/tooltip/tooltip';
import { sortArrayAlphabetically } from '@utils/fns/sortArrayAlphabetically';
import useConfirmModal from '@utils/hooks/use-confirm-modal/use-confirm-modal';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Col, Row } from 'antd';
import type { SelectValue } from 'antd/lib/select';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormikField } from '../../../parts/formik-field/formik-field';
import { publishProfileAction } from '../../api/publish-profile/publish-profile.action';
import { unPublishProfileAction } from '../../api/unpublish-profile/unpublish-profile.actions';
import type { Compensation, INedSmartMatch } from '../../ned-profile.page';
import { CompensationEditor } from './parts/compensation/compensation-editor';

import S from './smart-match.styles';

type AdvisorRoleTypeBoolKey = 'isFractional' | 'isNed' | 'isAdvisor';

interface NedSmartMatchProps {
  handleSubmit: (formState: INedSmartMatch) => void;
  smartMatchData: INedSmartMatch;
  skillsOptions: string[];
  industriesOptions: string[];
  isLightTheme?: boolean;
  busy?: boolean;
  refetchPublishStatus: () => void;
  publishStatus: PublishStatus;
  currencySymbol: string;
}

type FormikData = Omit<INedSmartMatch, 'primarySkillSet'> & {
  primarySkillSet: string[];
  compensations: Compensation[];
};

const MAX_INDUSTRIES = 3;
const MAX_PRIMARY_SKILL = 1;
const MAX_SECONDARY_SKILL = 2;

const extendCompensationsWithEmpty = ({
  isNed,
  isAdvisor,
  isFractional,
  compensations,
}: {
  isNed: boolean;
  isAdvisor: boolean;
  isFractional: boolean;
  compensations: Compensation[];
}): Compensation[] => {
  const result = [...compensations];

  const roleStatus = [
    { role: AdvisorRole.NED, status: isNed },
    { role: AdvisorRole.ADVISOR, status: isAdvisor },
    { role: AdvisorRole.FRACTIONAL, status: isFractional },
  ];

  roleStatus.forEach(({ role, status }) => {
    if (status && !compensations.some((item) => item.role === role)) {
      result.push({
        role,
        compensationType: null,
        maxDayRate: null,
        minDayRate: null,
      });
    }
  });

  return result;
};

export const SmartMatch = ({
  handleSubmit,
  smartMatchData,
  skillsOptions,
  industriesOptions,
  isLightTheme,
  busy = false,
  refetchPublishStatus,
  publishStatus,
  currencySymbol,
}: NedSmartMatchProps) => {
  const scrollToRef = useScrollToElementWithQueryParams<HTMLElement>('smart-match');
  const [
    smartMatch,
    smartMatchDescription,
    industriesLabel,
    primarySkillSetLabel,
    secondarySkillSetLabel,
    preferredRoleLabel,
    save,
    selectPlaceholder,
    ned,
    advisor,
    fractional,
    industriesValidation,
    primarySkillSetValidation,
    secondarySkillSetValidation,
    modalTitle,
    modalDescription,
    publicLabel,
    notPublicLabel,
    industryTooltip,
    primarySkillSetTooltip,
    secondarySkillSetTooltip,
    profilePublishHeaderLabel,
    profilePublishMessageLabel,
    profileUnPublishMessageLabel,
    profileShortagesMessageLabel,
    profileShortagesHeaderLabel,
    closeLabel,
    enableLabel,
    disableLabel,
    targetGeographyLabel,
    targetGeographyTooltip,
    targetGeographyErrorLabel,
  ] = useTranslation([
    'profile.ned.section.smartMatch.header',
    'profile.ned.section.smartMatch.description',
    'profile.ned.section.smartMatch.form.industry',
    'profile.ned.section.smartMatch.form.primarySkillSet',
    'profile.ned.section.smartMatch.form.secondarySkillSet',
    'profile.ned.section.smartMatch.form.preferredRole',
    'profile.ned.section.smartMatch.form.saveChanges',
    'profile.ned.section.smartMatch.form.selectPlaceholder',
    'profile.ned.section.smartMatch.form.ned',
    'profile.ned.section.smartMatch.form.advisor',
    'profile.ned.section.smartMatch.form.fractional',
    'formik.validation.industries',
    'formik.validation.primarySkillSet',
    'formik.validation.secondarySkillSet',
    'profile.ned.section.smartMatch.modal.title',
    'profile.ned.section.smartMatch.modal.description',
    'profile.ned.section.smartMatch.publicLabel',
    'profile.ned.section.smartMatch.notPublicLabel',
    'profile.ned.section.smartMatch.form.industry.tooltip',
    'profile.ned.section.smartMatch.form.primarySkillSet.tooltip',
    'profile.ned.section.smartMatch.form.secondarySkillSet.tooltip',
    'profile.ned.section.smartMatch.profileVisibility.header',
    'profile.ned.section.smartMatch.profileVisibility.publish',
    'profile.ned.section.smartMatch.profileVisibility.unPublish',
    'profile.ned.section.smartMatch.profileVisibility.shortages',
    'profile.ned.section.smartMatch.profileVisibility.shortages.header',
    'profile.founder.section.smartMatch.close',
    'profile.founder.section.smartMatch.enable',
    'profile.founder.section.smartMatch.disable',
    'profile.ned.section.smartMatch.form.targetGeography.label',
    'profile.ned.section.smartMatch.form.targetGeography.tooltip',
    'profile.ned.section.smartMatch.form.targetGeography.validation.required',
  ]);

  const { isPublic, publishModal, shortagesModal, showPublishModal, showShortagesModal } = usePublishStatusEvaluation({
    refetchPublishStatus,
    publishStatus,
    disable: disableLabel,
    enable: enableLabel,
    close: closeLabel,
    publishHeader: profilePublishHeaderLabel,
    unPublishMessage: profileUnPublishMessageLabel,
    publishMessage: profilePublishMessageLabel,
    shortagesHeader: profileShortagesHeaderLabel,
    shortagesMessage: profileShortagesMessageLabel,
    unPublishAction: unPublishProfileAction,
    publishAction: publishProfileAction,
  });

  const {
    deviceData: { isDesktop },
  } = useDeviceDetect();

  const { values, isValid, dirty, submitCount, ...formik } = useFormik<FormikData>({
    initialValues: {
      ...smartMatchData,
      primarySkillSet: smartMatchData.primarySkillSet === null ? [] : [smartMatchData.primarySkillSet],
      compensations: extendCompensationsWithEmpty({
        isAdvisor: smartMatchData.isAdvisor,
        isFractional: smartMatchData.isFractional,
        isNed: smartMatchData.isNed,
        compensations: smartMatchData.compensations,
      }),
    },
    onSubmit: ({ primarySkillSet, compensations, ...restValues }) => {
      handleSubmit({
        ...restValues,
        primarySkillSet: primarySkillSet.length === 0 ? null : primarySkillSet[0],
        compensations: compensations.filter((c) => c.compensationType !== null),
      });
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: Yup.object({
      industries: Yup.array().of(Yup.string()).max(MAX_INDUSTRIES, industriesValidation),
      role: Yup.array().of(Yup.string()),
      primarySkillSet: Yup.array().of(Yup.string()).nullable().max(MAX_PRIMARY_SKILL, primarySkillSetValidation),
      isNed: Yup.boolean(),
      isAdvisor: Yup.boolean(),
      isFractional: Yup.boolean(),
      secondarySkillSet: Yup.array().of(Yup.string()).max(MAX_SECONDARY_SKILL, secondarySkillSetValidation),
      compensations: Yup.array().of(
        Yup.object({
          role: Yup.string(),
          compensationType: Yup.string().nullable(),
          minDateRate: Yup.number().nullable(),
          maxDateRate: Yup.number().nullable(),
        }),
      ),
      targetGeography: Yup.array().min(1, targetGeographyErrorLabel),
    }),
  });

  const handleIndustryChange = (value: SelectValue) => {
    formik.setFieldValue('industries', value);
    formik.setFieldTouched('industries', true);
  };

  const handleTargetGeographyChange = (value: TargetGeographyValue) => {
    const currentValues = values.targetGeography ?? [];
    const isGlobalSelected = currentValues.find((e) => e === TargetGeographyValue.Global);
    const isValueAlreadySelected = currentValues.find((e) => e === value);

    let updatedValues: TargetGeographyValue[] = [...currentValues];

    if (value === TargetGeographyValue.Global) {
      updatedValues = isGlobalSelected ? [] : [TargetGeographyValue.Global];
    } else {
      updatedValues = isValueAlreadySelected
        ? currentValues.filter((item) => item !== value)
        : [...currentValues, value];
      updatedValues = updatedValues.filter((item) => item !== TargetGeographyValue.Global);
    }

    formik.setFieldTouched('targetGeography', true);
    formik.setFieldValue('targetGeography', updatedValues);
  };

  const handleAdvisorRoleChange = (
    roleTypeBoolKey: AdvisorRoleTypeBoolKey,
    role: Omit<AdvisorRole, AdvisorRole.PLACEMENT>,
    value: boolean,
  ) => {
    formik.setFieldValue(roleTypeBoolKey, value);

    const items = values.compensations.filter((c) => c.role !== role);
    const updatedCompensations: Compensation[] = value
      ? [...items, { role, compensationType: null, minDayRate: null, maxDayRate: null }]
      : items;

    formik.setFieldValue('compensations', updatedCompensations);
  };

  const primarySkillsOptionList = skillsOptions.filter((skill) =>
    !values.secondarySkillSet.includes(skill) ? skill : null,
  );

  const secondarySkillsOptionsList = skillsOptions.filter((skill) => skill !== values.primarySkillSet[0]);

  const showCompensation = values.isAdvisor || values.isFractional || values.isNed;
  const showMobileCompensation = showCompensation && isDesktop === false;
  const showDesktopCompensation = showCompensation && isDesktop;
  const { modal: nedRequirementsModal, show: showNedRequirementsModal } = useConfirmModal({
    title: modalTitle,
    content: modalDescription,
    noCancel: true,
  });

  const handleCompensationsChange = (value: Compensation[]) => {
    const compensationsWithEmptyForEveryRole = extendCompensationsWithEmpty({
      isAdvisor: values.isAdvisor,
      isFractional: values.isFractional,
      isNed: values.isNed,
      compensations: value,
    });
    formik.setFieldValue('compensations', compensationsWithEmptyForEveryRole);
  };

  const compensationEditor = (
    <CompensationEditor
      currencySymbol={currencySymbol}
      compensations={values.compensations}
      onChange={handleCompensationsChange}
      disabled={busy}
    />
  );

  return (
    <>
      <Section
        header={smartMatch}
        innerRef={scrollToRef}
        subTitle={smartMatchDescription}
        cornerAddition={{
          content: (
            <PublishSwitch
              isPublic={isPublic}
              onClick={publishStatus?.smartMatchShortages.length === 0 ? showPublishModal : showShortagesModal}
              publicLabel={publicLabel}
              notPublicLabel={notPublicLabel}
            />
          ),
          width: 250,
        }}
      >
        <form onSubmit={formik.handleSubmit} id="ned-profile-start-match-form">
          <Row gutter={[64, 0]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <S.FieldLabel>{preferredRoleLabel}</S.FieldLabel>
              <Row gutter={[12, 0]}>
                <FormikField
                  label={{
                    for: 'isAdvisor',
                    label: '',
                  }}
                  touched={formik.touched.isAdvisor}
                  error={formik.errors.isAdvisor}
                >
                  <PillButtonCheckbox
                    isLightTheme={isLightTheme}
                    checked={values.isAdvisor}
                    onChange={(e) => {
                      handleAdvisorRoleChange('isAdvisor', AdvisorRole.ADVISOR, e.target.checked);
                    }}
                  >
                    {advisor}
                  </PillButtonCheckbox>
                </FormikField>
                <FormikField
                  label={{
                    for: 'isFractional',
                    label: '',
                  }}
                  touched={formik.touched.isFractional}
                  error={formik.errors.isFractional}
                >
                  <PillButtonCheckbox
                    isLightTheme={isLightTheme}
                    checked={values.isFractional}
                    onChange={(e) => {
                      handleAdvisorRoleChange('isFractional', AdvisorRole.FRACTIONAL, e.target.checked);
                    }}
                  >
                    {fractional}
                  </PillButtonCheckbox>
                </FormikField>
                <FormikField
                  label={{
                    for: 'isNed',
                    label: '',
                  }}
                  touched={formik.touched.isNed}
                  error={formik.errors.isNed}
                >
                  <PillButtonCheckbox
                    isLightTheme={isLightTheme}
                    checked={values.isNed}
                    onChange={(e) => {
                      if (!values.isNed) {
                        showNedRequirementsModal();
                      }

                      handleAdvisorRoleChange('isNed', AdvisorRole.NED, e.target.checked);
                    }}
                  >
                    {ned}
                  </PillButtonCheckbox>
                </FormikField>
              </Row>
              <FormikField
                label={{
                  for: 'industries',
                  label: (
                    <span>
                      {industriesLabel}
                      <Tooltip title={industryTooltip} />
                    </span>
                  ),
                }}
                error={formik.errors.industries ? (formik.errors.industries as string) : undefined}
                touched={formik.touched.industries}
              >
                <MultiSelectSearch
                  id="industries"
                  placeholder={selectPlaceholder}
                  {...formik.getFieldProps('industries')}
                  options={sortArrayAlphabetically(industriesOptions)}
                  onChange={handleIndustryChange}
                  {...(values.industries.length >= MAX_INDUSTRIES && { open: false })}
                />
              </FormikField>
              <FormikField
                label={{
                  for: 'primarySkillSet',
                  label: (
                    <span>
                      {primarySkillSetLabel}
                      <Tooltip title={primarySkillSetTooltip} />
                    </span>
                  ),
                }}
                error={formik.errors.primarySkillSet ? (formik.errors.primarySkillSet as string) : undefined}
                touched={formik.touched.primarySkillSet}
              >
                <MultiSelectSearch
                  id="primarySkillSet"
                  placeholder={selectPlaceholder}
                  {...formik.getFieldProps('primarySkillSet')}
                  options={primarySkillsOptionList}
                  onChange={(value) => formik.setFieldValue('primarySkillSet', value)}
                  {...(values.primarySkillSet && values.primarySkillSet.length >= MAX_PRIMARY_SKILL && { open: false })}
                />
              </FormikField>
              <FormikField
                label={{
                  for: 'secondarySkillSet',
                  label: (
                    <span>
                      {secondarySkillSetLabel}
                      <Tooltip title={secondarySkillSetTooltip} />
                    </span>
                  ),
                }}
                error={formik.errors.secondarySkillSet ? (formik.errors.primarySkillSet as string) : undefined}
                touched={formik.touched.primarySkillSet}
              >
                <MultiSelectSearch
                  id="secondarySkillSet"
                  placeholder={selectPlaceholder}
                  {...formik.getFieldProps('secondarySkillSet')}
                  options={secondarySkillsOptionsList}
                  onChange={(value) => formik.setFieldValue('secondarySkillSet', value)}
                  {...(values.secondarySkillSet &&
                    values.secondarySkillSet.length >= MAX_SECONDARY_SKILL && { open: false })}
                />
              </FormikField>

              {showMobileCompensation ? compensationEditor : null}
            </Col>

            {showDesktopCompensation ? (
              <S.SeparatedColumn xs={24} lg={12} xl={12}>
                {compensationEditor}
              </S.SeparatedColumn>
            ) : null}
          </Row>
          <S.RowWithBottomMargin gutter={[24, 0]}>
            <Col xs={24} sm={24} lg={14}>
              <S.FormikFieldWithNoMargin
                label={{
                  for: 'targetGeography',
                  label: <LabelWithTooltip label={targetGeographyLabel} tooltip={targetGeographyTooltip} />,
                }}
                touched={formik.touched.targetGeography}
                error={formik.errors.targetGeography}
              >
                {TARGET_GEOGRAPHIES_OPTIONS.map((target) => (
                  <PillButtonCheckbox
                    key={target}
                    isLightTheme={isLightTheme}
                    checked={values.targetGeography?.includes(target)}
                    onChange={() => handleTargetGeographyChange(target)}
                  >
                    {target}
                  </PillButtonCheckbox>
                ))}
              </S.FormikFieldWithNoMargin>
            </Col>
          </S.RowWithBottomMargin>
          <SubmitButton
            type="primary"
            htmlType="submit"
            form="ned-profile-form"
            onClick={() => formik.submitForm()}
            disabledEvaluation={{ isValid, dirty, submitCount }}
            loading={busy}
          >
            {save}
          </SubmitButton>
        </form>
      </Section>

      {nedRequirementsModal}
      {shortagesModal}
      {publishModal}
    </>
  );
};

import MetricIconBase from '@assets/icons/metric-icon.svg?react';
import styled from 'styled-components';

const MetricIcon = styled(MetricIconBase)`
  margin-bottom: ${({ theme }) => theme.spacing.medium};

  path {
    fill: ${({ theme }) => theme.color.manageMetric};
  }
`;

const Content = styled.p`
  color: ${({ theme }) => theme.color.manageMetric};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  min-height: 200px;
`;

export default { MetricIcon, Content, Wrapper };

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export interface AdditionalInfoType {
  investmentSource: string;
  investmentNote: string;
}

export const ADDITIONAL_INFORMATION_CACHE_KEY = 'get-additional-information';

export const getAdditionalInfoAction =
  (relationshipId: string) => async (): Promise<GraphqlResponse<AdditionalInfoType>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
        query getAdditionalInfo {
          companyAdditionalInfo(investingRelationshipId: "${relationshipId}") {
              investmentSource
              investmentNote
          }
        }
      `,
    });

    return graphqlResponseWrapper(data, ['companyAdditionalInfo']);
  };

export enum RoleStatus {
  Expired = 'Expired',
  InProgress = 'InProgress',
}

export type RoleMutationType = 'refresh' | 'close' | 'delete';

export interface FounderPreviewRole {
  id: string;
  title: string;
  applications: number;
  views: number;
  postedAt: string;
  expiresAt: string;
  status: RoleStatus;
}

import styled from 'styled-components';

const BulletList = styled.ul`
  margin-top: ${({ theme }) => theme.spacing.small};
  list-style: disc;
  list-style-position: inside;
`;

const Image = styled.img`
  position: relative;
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  margin-top: -${({ theme }) => theme.spacing.medium};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    position: relative;
    bottom: 30px;
  }
`;

export default { BulletList, Image };

import React from 'react';
import S from './v2-label.styles';

type V2LabelProps = {
  text: string;
  align?: 'left' | 'center' | 'right';
  htmlFor?: string;
};

export const V2Label: React.FC<V2LabelProps> = ({ text, align = 'left', htmlFor }) => {
  return (
    <S.V2Label align={align} htmlFor={htmlFor}>
      {text}
    </S.V2Label>
  );
};

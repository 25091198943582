import type { AdvisorRole } from '@/domain/accounts/roles';
import {
  GET_COUNTRIES_USED_BY_NEDS_CACHE_KEY,
  getCountriesUsedByNedsAction,
} from '@pages/content/lens/api/get-countries-used-by-neds.action';
import { CountriesRegionsSelect } from '@parts/countries-regions-select/countries-regions-select';
import { useAvailableCountriesAndRegions } from '@utils/hooks/use-available-countries-and-regions/use-available-countries-and-regions';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import { RegionNamesMap, RegionValue } from '@utils/type-definitions/regions-values';
import type { SelectValue } from 'antd/lib/select';
import type { FormikErrors } from 'formik';
import type { FormikFiltersData } from '../filters';
import S from './countries-and-regions.styles';

export const CountriesAndRegionsFilter = ({
  setFieldValue,
  values,
  advisorRole,
}: {
  setFieldValue: (field: string, value: unknown) => Promise<void | FormikErrors<FormikFiltersData>>;
  values: FormikFiltersData;
  advisorRole: AdvisorRole;
}) => {
  const [countriesAndRegionsLabel] = useTranslation(['advisors.search.filters.countriesAndRegions']);

  const { availableCountries, availableRegions, notUsedCountries, isLoaded } = useAvailableCountriesAndRegions(
    GET_COUNTRIES_USED_BY_NEDS_CACHE_KEY,
    getCountriesUsedByNedsAction(advisorRole),
  );

  const handleSelectChange = (countriesKey: string, regionsKey: string) => (value: SelectValue) => {
    const selectedValues = Array.isArray(value) ? value : [];

    const countriesData = selectedValues.filter((item) => Object.values(CountryCodes).includes(item as CountryCodes));
    const regionsData = selectedValues.filter((item) => Object.keys(RegionNamesMap).includes(item as RegionValue));

    setFieldValue(countriesKey, countriesData);

    setFieldValue(regionsKey, regionsData);
  };
  return (
    <S.FormikField
      label={{
        for: 'countriesAndRegions',
        label: countriesAndRegionsLabel,
      }}
      marginBottom
    >
      <CountriesRegionsSelect
        id="countriesAndRegions"
        onChange={handleSelectChange('countriesOfResidence', 'regions')}
        regions={availableRegions}
        countriesOfResidence={availableCountries}
        isLoading={!isLoaded}
        notUsedCountries={notUsedCountries}
        showArrow={false}
        showCountriesAndRegionsPlaceholder={false}
        value={[...values.countriesOfResidence, ...values.regions]}
      />
    </S.FormikField>
  );
};

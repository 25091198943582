import type { FunctionComponent } from 'react';
import type { V2NotificationIndicatorProps } from './v2-notification-indicator.types';

import S from './v2-notification-indicator.styles';

export const V2NotificationIndicator: FunctionComponent<V2NotificationIndicatorProps> = ({
  children,
  count = 0,
  position,
  mode = 'number',
  dotPlacementMode = 'inside',
  enlargeWithPadding = false,
  'data-testid': testId = 'v2-notification-indicator',
  ...restAttributes
}) => {
  if (count === 0) {
    return children ? <>{children}</> : null;
  }

  const content = mode === 'number' ? count : '';
  const dotElement = (
    <S.V2NotificationIndicator
      {...restAttributes}
      $small={mode === 'dot'}
      data-testid={testId}
      $position={position}
      $placementMode={dotPlacementMode}
    >
      {content}
    </S.V2NotificationIndicator>
  );

  return !children ? (
    dotElement
  ) : (
    <S.Wrapper
      $position={position}
      $enlargeWithPadding={enlargeWithPadding}
      $small={mode === 'dot'}
      {...restAttributes}
      data-testid={`${testId}-wrapper`}
    >
      {children}
      {dotElement}
    </S.Wrapper>
  );
};

const callNavigator = (text: string, onSuccess: Function, onError: Function) => {
  navigator.clipboard.writeText(text).then(
    () => onSuccess(),
    (err) => onError(err),
  );
};

export const copyToClipboard = (copiedText: string, onSuccess: Function, onError: Function) => {
  if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
    return callNavigator(copiedText, onSuccess, onError);
  }

  navigator.permissions.query({ name: 'clipboard-write' as PermissionName }).then((result) => {
    if (result.state === 'granted' || result.state === 'prompt') {
      callNavigator(copiedText, onSuccess, onError);
    }
  });
};

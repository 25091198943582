import { registrationLink } from '@constants/links';
import { Roles } from '@domain/accounts/roles';
import { founderIdQueryParam } from '@pages/content/public-profile/founder/founder.page';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './header.styles';

import { Links } from '@router/links';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';

export const Header = ({ companyId }: { companyId: string }) => {
  const history = useHistory();

  const {
    state: { userRole },
  } = useUserAccount();

  const [sloganLabel, signUpLabel, founderPageLabel] = useTranslation([
    'progress.report.slogan',
    'progress.report.singup',
    'progress.report.founderPage',
  ]);

  const founderProfileUrl = history.createHref({
    search: `${founderIdQueryParam}=${companyId}`,
    pathname: Links.FOUNDER_PUBLIC_PROFILE_DETAILS(),
  });

  const linkConfig =
    userRole === Roles.NO_ROLE
      ? { route: registrationLink, label: signUpLabel }
      : { route: founderProfileUrl, label: founderPageLabel };

  return (
    <S.Wrapper>
      <S.Content>
        <S.Brand>
          <S.Logo />
          <S.LogoSquare />
          <S.Slogan>{sloganLabel}</S.Slogan>
        </S.Brand>
        {userRole !== Roles.FOUNDER && (
          <S.SecureLink href={linkConfig.route} outer={userRole === Roles.NO_ROLE}>
            <S.GoTo type="primary" disabled={!companyId}>
              {linkConfig.label}
              <S.ArrowRight />
            </S.GoTo>
          </S.SecureLink>
        )}
      </S.Content>
    </S.Wrapper>
  );
};

import { UserAccountContext, type UserAccountContextApi } from '@context/user/user-account.context';
import { useContext } from 'react';

const useUserAccount = () => {
  const context = useContext(UserAccountContext) as UserAccountContextApi;

  if (!context || !context.dispatch) {
    throw new Error('User account context in not available');
  }

  return { ...context };
};

export default useUserAccount;

import ConfirmedIconBase from '@assets/icons/confirmed.svg?react';
import styled, { css } from 'styled-components';
import { PendingSvgIcon } from './parts/pending-svg-icon';

const iconStyles = css`
  align-self: center;
  margin-left: ${({ theme }) => theme.spacing.xsmall};
`;

const Confirmed = styled(ConfirmedIconBase)`
  ${iconStyles}
`;

const Pending = styled(PendingSvgIcon)`
  ${iconStyles}
`;

const Wrapper = {
  Confirmed,
  Pending,
};

export default { Wrapper };

import { Tabs as TabsBase } from '@parts/tabs/tabs';
import styled from 'styled-components';

const Tabs = styled(TabsBase)`
  .ant-tabs-nav {
    &::before {
      border: none;
    }

    @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
      padding-left: ${({ theme }) => theme.spacing.medium};
    }
  }
`;

export default { Tabs };

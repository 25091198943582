import { V2Button } from '@/components/ui/v2-button/v2-button';
import { useModal } from '@/hooks/use-modal';
import { AdvisorQuickSearchQuestionsModal } from '@/modules/founder/homepage/components/advisor-quick-search-questions-modal/advisor-quick-search-questions-modal';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './heading.styles';

export const AdvisorsHeadingSection = () => {
  const [title, quickSearch] = useTranslation(['advisors.title', 'advisor-quick-search']);
  const { isOpen, close, open } = useModal();

  return (
    <>
      <S.Header data-testid="advisor-heading">
        <S.Title data-testid="advisor-title">{title}</S.Title>
        <V2Button size="large" onClick={open}>
          {quickSearch}
        </V2Button>
      </S.Header>
      <AdvisorQuickSearchQuestionsModal isOpen={isOpen} close={close} />
    </>
  );
};

import S from './apex-tooltip.styles';

export const ApexTooltip = ({
  formattedDate,
  forecast,
  value,
  note,
  valueLabel,
  forecastLabel,
}: {
  formattedDate: string;
  forecast: number | string | null;
  value: number | string | null;
  note: string | null;
  valueLabel: string;
  forecastLabel: string;
}) => {
  return (
    <S.Wrapper>
      <S.Date>{formattedDate}</S.Date>
      {value ? (
        <S.Item>
          <S.Label>
            <S.Line type="value" />
            {valueLabel}
          </S.Label>
          <S.Number>{value}</S.Number>
        </S.Item>
      ) : null}
      {forecast ? (
        <S.Item>
          <S.Label>
            <S.Line type="forecast" />
            {forecastLabel}
          </S.Label>
          <S.Number>{forecast}</S.Number>
        </S.Item>
      ) : null}
      {note ? <S.Note>{note}</S.Note> : null}
    </S.Wrapper>
  );
};

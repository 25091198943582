import { MultiSelectSearch as BaseMultiSelectSearch } from '@parts/multiselect-search/multiselect-search';
import { Select as SelectBase } from '@parts/select/select';
import styled from 'styled-components';

const Form = styled.form``;

const Title = styled.h3`
  margin-bottom: ${({ theme }) => theme.spacing.base};
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.color.fontPrimary};
`;

const Row = styled.section`
  display: flex;
  width: 100%;

  & > div {
    width: 49%;
    max-width: 350px;
  }

  & > div:first-child {
    margin-right: ${({ theme }) => theme.spacing.base};
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-direction: column;

    & > div {
      width: 100%;
      max-width: 100%;
    }
  }
`;

const Select = styled(SelectBase)`
  width: 100%;
`;

const MultiSelectSearch = styled(BaseMultiSelectSearch)`
  max-width: 741px;
`;

export default {
  Form,
  Title,
  Row,
  Select,
  MultiSelectSearch,
};

import type { DisabledEvaluation } from '@parts/submit-button/submit-button';
import S from './on-top-footer.styles';

const OnTopFooter = ({
  onConfirm,
  onCancel,
  confirmLabel,
  cancelLabel,
  isDanger,
  disabled,
  modal = false,
  reverseButtons = false,
  disabledEvaluation,
  alignButtonsWithContent,
  noCancel,
  noConfirm,
  loading = false,
  'data-testid': dataTestId = 'onTopFooter',
  ...rest
}: {
  onConfirm: () => void;
  onCancel: () => void;
  confirmLabel: string;
  cancelLabel: string;
  isDanger?: boolean;
  disabled?: boolean;
  modal?: boolean;
  reverseButtons?: boolean;
  noCancel?: boolean;
  noConfirm?: boolean;
  alignButtonsWithContent?: boolean;
  disabledEvaluation?: DisabledEvaluation;
  loading?: boolean;
  'data-testid'?: string;
}) => (
  <S.Footer {...rest} modal={modal} reverseButtons={reverseButtons} alignButtonsWithContent>
    {!noCancel && (
      <S.Button data-testid={`${dataTestId}_Cancel_Button`} onClick={onCancel}>
        {cancelLabel}
      </S.Button>
    )}
    {!noConfirm && (
      <S.SubmitButton
        data-testid={`${dataTestId}_Save_Button`}
        type="primary"
        disabledEvaluation={disabledEvaluation}
        onClick={onConfirm}
        disabled={disabled}
        {...(isDanger && { danger: true })}
        loading={loading}
      >
        {confirmLabel}
      </S.SubmitButton>
    )}
  </S.Footer>
);

export default OnTopFooter;

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const READINESS_SCORE_KEY = 'homepage/readiness-score';

export type ScoreDetails = { received: number; available: number };
export type GetReadinessScoreQueryResponseData = {
  score: number;
  profile: {
    personalDetailsScore: ScoreDetails;
    smartMatchScore: ScoreDetails;
    uploadedFilesScore: ScoreDetails;
  };
  team: {
    teamMembersScore: ScoreDetails;
    selectedAreas: string[];
  };
  metrics: {
    hasScoring: boolean;
    manualMetricsScore: ScoreDetails;
    integratedMetricsScore: ScoreDetails;
    dashboardScore: ScoreDetails;
  };
  marketFit: {
    marketFitScore: ScoreDetails;
    questions: { answered: number; available: number };
  };
};

export const getReadinessScoreQuery = async (): Promise<GraphqlResponse<GetReadinessScoreQueryResponseData>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getReadinessScoreQuery {
        readinessScore {
          score
          profile {
            personalDetailsScore {
              received
              available
            }
            smartMatchScore {
              received
              available
            }
            uploadedFilesScore {
              received
              available
            }
          }
          team {
            teamMembersScore {
              received
              available
            }
            selectedAreas
          }
          metrics {
            hasScoring
            manualMetricsScore {
              received
              available
            }
            integratedMetricsScore {
              received
              available
            }
            dashboardScore {
              received
              available
            }
          }
          marketFit {
            marketFitScore {
              received
              available
            }
            questions { 
              answered
              available
            }
          }
        }
      }
    `,
  });

  return graphqlResponseWrapper(data, ['readinessScore']);
};

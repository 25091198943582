import S from '@parts/switch-labelled/switch-labelled.styles';
import type { ReactNode } from 'react';

interface SwitchLabel {
  default: ReactNode | ReactNode[];
  mobile?: ReactNode | ReactNode[];
  tablet?: ReactNode | ReactNode[];
}

export interface SwitchWithLabelsProps {
  onClick?: (() => void) | ((value: boolean | string[]) => void);
  disabled?: boolean;
  isChecked?: boolean;
  leftLabel?: SwitchLabel;
  rightLabel?: SwitchLabel;
  className?: string;
}

export const SwitchLabelled = ({
  leftLabel,
  rightLabel,
  onClick,
  disabled,
  className,
  isChecked = false,
}: SwitchWithLabelsProps) => (
  <S.Wrapper className={className} data-testid="switch-labelled">
    {leftLabel && (
      <S.LabelHolder>
        <S.LabelDesktop selected={!isChecked}>{leftLabel.default}</S.LabelDesktop>
        <S.LabelTablet selected={isChecked}>{leftLabel.tablet || leftLabel.default}</S.LabelTablet>
        <S.LabelMobile selected={!isChecked}>{leftLabel.mobile || leftLabel.default}</S.LabelMobile>
      </S.LabelHolder>
    )}
    <S.Switch disabled={disabled} defaultChecked={isChecked} checked={isChecked} onClick={onClick} />
    {rightLabel && (
      <S.LabelHolder>
        <S.LabelDesktop selected={isChecked}>{rightLabel.default}</S.LabelDesktop>
        <S.LabelTablet selected={isChecked}>{rightLabel.tablet || rightLabel.default}</S.LabelTablet>
        <S.LabelMobile selected={isChecked}>{rightLabel.mobile || rightLabel.default}</S.LabelMobile>
      </S.LabelHolder>
    )}
  </S.Wrapper>
);

import { Button as AntButton } from 'antd';
import styled from 'styled-components';

const Container = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Title = styled.h3`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontSize.medium};
  color: ${({ theme }) => theme.color.fontPrimary};
`;

const Button = styled(AntButton)`
  padding: ${({ theme }) => theme.spacing.medium};
  background-color: ${({ theme }) => theme.color.submitButton};
  line-height: 0;

  & > a {
    text-decoration: none;
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin-top: ${({ theme }) => theme.spacing.small};
    width: 100%;
  }
`;

export default {
  Container,
  Title,
  Button,
};

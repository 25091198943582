import type { FunctionComponent, ReactNode } from 'react';
import S from './layout.styles';

export const Layout = ({ Icon, children }: { Icon?: FunctionComponent; children: ReactNode | ReactNode[] }) => (
  <S.Wrapper>
    {Icon && (
      <S.IconWrapper>
        <Icon />
      </S.IconWrapper>
    )}
    {children}
  </S.Wrapper>
);

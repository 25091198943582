import { buildQueryParamTypes, buildQueryParams } from '@/utils/fns/graphql-query-builder';
import type { Sorter } from '@/utils/type-definitions/filters';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';
import type { AdvisorFounderConnection } from '../types';

export const GET_NED_FOUNDER_CONNECTIONS_KEY = 'get-ned-founder-connections-query';

type ResponseData = {
  total: number;
  items: AdvisorFounderConnection[];
};

type QueryConfig = {
  page?: number;
  perPage?: number;
  searchByName?: string;
  sort?: Sorter;
};

export const getAdvisorConnections = async ({
  page = 1,
  perPage = 6,
  searchByName = '',
  sort,
}: QueryConfig): Promise<GraphqlResponse<ResponseData>> => {
  const variablesType = {
    sort: '[SortType!]',
    searchByName: 'String!',
    start: 'Int!',
    limit: 'Int!',
  };

  const variables = {
    sort,
    searchByName,
    start: (page - 1) * perPage,
    limit: page * perPage,
  };

  const { data } = await axiosInstance.post('/query', {
    variables,
    query: `
     query getNedFounderConnections (${buildQueryParamTypes(variablesType)}) {
        getNedFounderConnections (${buildQueryParams(variablesType)}) {
           total
           items {
            connectionId
            connectionDate
            notesCount
            founderId
            businessName
            representativeName
            isNew
            investmentStage
            avatarUrl
            industry
          }
         }
       }
    `,
  });

  return graphqlResponseWrapper(data, ['getNedFounderConnections']);
};

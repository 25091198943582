import LeftArrow from '@assets/icons/left-arrow.svg?react';
import RightArrow from '@assets/icons/right-arrow.svg?react';
import { Fragment } from 'react';
import SlideshowArrow from './parts/slideshow-arrow';
import S from './slideshow.styles';

const SlideShow = ({
  banners,
  autoplay = false,
  autoplaySpeed = 5000,
}: {
  banners: JSX.Element[];
  autoplay?: boolean;
  autoplaySpeed?: number;
}) => (
  <S.SlideShow
    autoplay={autoplay}
    autoplaySpeed={autoplaySpeed}
    nextArrow={
      <SlideshowArrow type="next">
        <RightArrow />
      </SlideshowArrow>
    }
    prevArrow={
      <SlideshowArrow type="prev">
        <LeftArrow />
      </SlideshowArrow>
    }
    bannersLength={banners.length}
    arrows
  >
    {banners.map((element, index) => (
      <Fragment key={`slideshow-${index}`}>{element}</Fragment>
    ))}
  </S.SlideShow>
);
export default SlideShow;

import ExpandIconBase from '@assets/icons/expand.svg?react';
import { CornerInfo as CornerInfoBase } from '@pages/content/profile/parts/corner-info/corner-info';
import { Section as SectionBase } from '@parts/section/section';
import { SubmitButton as ButtonBase } from '@parts/submit-button/submit-button';
import styled, { css } from 'styled-components';

const Wrapper = styled(SectionBase)``;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.base};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    display: block;
  }
`;

const AddInvestment = styled(ButtonBase)``;

const ExpandView = styled(ButtonBase)<{ $svgIncHoverEffect?: boolean; $svgDecHoverEffect?: boolean }>`
  background-color: transparent;
  color: ${({ theme }) => theme.baseColors.Blue200};
  margin-right: ${({ theme }) => theme.spacing.small};

  ${({ $svgIncHoverEffect, $svgDecHoverEffect }) =>
    ($svgIncHoverEffect || $svgDecHoverEffect) &&
    css`
      svg {
        transition: 0.5s;
      }
    `}

  ${({ $svgDecHoverEffect }) =>
    $svgDecHoverEffect &&
    css`
      svg {
        transform: scale(1.2);
      }
    `}

  &:hover,
  &:focus {
    background-color: transparent;
    color: ${({ theme }) => theme.baseColors.Blue200};

    ${({ $svgIncHoverEffect }) =>
      $svgIncHoverEffect &&
      css`
        svg {
          transform: scale(1.2);
        }
      `}

    ${({ $svgDecHoverEffect }) =>
      $svgDecHoverEffect &&
      css`
        svg {
          transform: scale(1);
        }
      `}
  }
`;

const Info = styled(CornerInfoBase)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.fontPrimary};
`;

type CornerProps = { expandViewOpen?: boolean };

const Corner = styled.div<CornerProps>`
  flex-basis: ${({ expandViewOpen }) => (expandViewOpen ? '45%' : '60%')};
  display: inline-flex;

  ${Info} {
    flex: 1;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    flex-basis: 70%;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing.medium};
  }
`;

const Actions = styled.div`
  display: inline-flex;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    display: block;
  }
`;

const ExpandIcon = styled(ExpandIconBase)`
  margin-right: 7px;
  margin-bottom: -3px;
`;

const TableHeader = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  margin-bottom: 25px;
  color: ${({ theme }) => theme.color.header};
`;
export default {
  AddInvestment,
  ExpandView,
  ExpandIcon,
  Header,
  Corner,
  Actions,
  Info,
  Wrapper,
  TableHeader,
};

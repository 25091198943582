import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import LogoBase from '@assets/brand/logo.svg?react';
import { LogoSquare as LogoSquareBase } from '@parts/logo-square/logo-square';
import { ProfilePicture } from '@parts/profile-picture/profile-picture';
import { Button, Layout as LayoutBase, Menu as MenuBase } from 'antd';
import { Link as LinkBase } from 'react-router-dom';
import styled from 'styled-components';

const MenuItem = styled(MenuBase.Item)`
  color: ${({ theme }) => theme.color.primary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  &:hover {
    color: ${({ theme }) => theme.color.primary}!important;
  }

  &.ant-menu-item {
    border-bottom: none !important;
    margin: 0 15px !important;
  }

  &.ant-menu-item-disabled {
    color: ${({ theme }) => theme.color.fontPrimary}!important;
    opacity: 0.5;
  }

  &.ant-menu-item-selected {
    position: relative;
    color: ${({ theme }) => theme.color.primary}!important;

    &::after {
      content: '';
      position: absolute;
      bottom: -26px;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: ${({ theme }) => theme.color.secondary};
    }
  }

  .ant-menu-item-icon {
    display: none;
  }
`;

const OverlaidMenuItem = styled(MenuItem)`
  &.ant-menu-item-selected {
    &::after {
      bottom: -21px;
    }
  }
`;

const MobileMenuItem = styled(MenuItem)`
  &.ant-menu-item {
    padding: 0 !important;
    margin: 0 ${({ theme }) => theme.spacing.small} 0 0 !important;
    border: 0 !important;

    &.ant-menu-item-selected {
      position: relative;

      &::after {
        bottom: -16px;
      }
    }
  }
`;

const DrawerItem = styled(MenuItem)`
  &.ant-menu-item {
    margin: 0 -25px !important;
    padding: 10px 30px !important;
    border-top: 1px solid ${({ theme }) => theme.color.border}!important;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: ${({ theme }) => theme.fontSize.xmedium};

    &:first-of-type {
      justify-content: space-between;
    }

    &:last-of-type {
      border-bottom: 1px solid ${({ theme }) => theme.color.border}!important;
    }
  }

  svg {
    height: 24px;
    width: 24px;
    margin-right: 10px;
    margin-left: 0;
  }
`;

const Menu = styled(MenuBase)`
  border-right: none;
  background: transparent;
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const DrawerAvatar = styled(ProfilePicture)`
  margin: auto;
  margin-bottom: 20px;
  background: transparent;

  > div {
    width: 100%;
    height: 100%;
  }
`;

const Link = styled(LinkBase)``;

const Logo = styled(LogoBase)`
  margin-top: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.xmedium};
  width: 160px;

  path:first-of-type {
    fill: ${({ theme }) => theme.color.logoFill};
  }
`;

const MobileAvatar = styled(Button)`
  padding-left: 0;
  padding-right: 0;

  &.ant-btn-link {
    width: auto;
    border: 0;
  }
`;

const AvatarHolder = styled.div`
  padding-left: 20px !important;

  .ant-btn {
    box-shadow: none;
    background: transparent;

    .anticon-ellipsis {
      visibility: hidden;
    }
  }
`;

const Hamburger = styled.div`
  display: block;
  position: relative;
  z-index: 1;
  user-select: none;
  margin-left: 0;
  padding-top: 5px;

  span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: ${({ theme }) => theme.color.primary};
    border-radius: 3px;

    z-index: 1;
  }

  input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;

    cursor: pointer;

    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */

    -webkit-touch-callout: none;
  }
`;

const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;

  ${customMediaQuery('max-width', {
    lg: '1686px', // match with v2 container max-width
  })}

  ${customMediaQuery('padding', {
    xs: '0 16px',
    lg: '0 80px',
  })}
`;

const MobileHeader = styled(LayoutBase.Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LogoWrapper = styled.h1`
  text-align: center;
`;

const DrawerLogo = styled.div`
  text-align: center;
  margin: 30px 0;
`;

const LogoSquare = styled(LogoSquareBase)`
  margin-top: 5px;
  margin-left: 60px;
`;

const MobileRightSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default {
  MenuItem,
  Menu,
  Link,
  AvatarHolder,
  Logo,
  Content,
  Hamburger,
  LogoWrapper,
  DrawerLogo,
  DrawerItem,
  LogoSquare,
  MenuWrapper,
  DrawerAvatar,
  MobileHeader,
  MobileMenuItem,
  MobileAvatar,
  MobileRightSide,
  OverlaidMenuItem,
};

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import S from './pagination.styles';

export interface PaginationProps {
  handlePrev: () => void;
  handleNext: () => void;
  currentPage: number;
  maxPages: number;
  className?: string;
  centered?: boolean;
  bottomSpaced?: boolean;
}

export const Pagination = ({
  handlePrev,
  handleNext,
  currentPage,
  maxPages,
  className,
  centered,
  bottomSpaced,
}: PaginationProps) => (
  <S.Wrapper centered={centered} bottomSpaced={bottomSpaced} className={className}>
    <S.Button onClick={handlePrev} data-testid="btn-metrics-left" disabled={currentPage === 1}>
      <LeftOutlined />
    </S.Button>
    <S.Counter>
      {currentPage} of {maxPages}
    </S.Counter>
    <S.Button onClick={handleNext} data-testid="btn-metrics-right" disabled={currentPage === maxPages}>
      <RightOutlined />
    </S.Button>
  </S.Wrapper>
);

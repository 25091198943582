import { V2FilterPanel } from '@/components/ui/v2-filter-panel/v2-filter-panel';
import { V2MobileFilters } from '@/components/ui/v2-mobile-filters/v2-mobile-filters';
import { useMediaQuery } from '@/hooks/use-media-query';
import { TranslationText } from '@/parts/translation-text/translation-text';
import type { FounderConnectionsFiltersProps } from './filters.types';

export const FounderConnectionsFilters = ({
  filters,
  activeFilter,
  onFilterChange,
  initialSearchValue,
  onSearchSubmit,
  sortValue,
  sortOptions = [],
  submit,
  disabled = false,
}: FounderConnectionsFiltersProps) => {
  const breakpoint = useMediaQuery();
  const isMobile = ['xs', 'sm'].includes(breakpoint);

  return isMobile ? (
    <V2MobileFilters
      triggerLabel={<TranslationText id="connections.filters.button" />}
      filters={filters}
      activeFilter={activeFilter}
      initialSearchValue={initialSearchValue}
      sortValue={sortValue}
      sortOptions={sortOptions}
      submit={submit}
      disabled={disabled}
    />
  ) : (
    <V2FilterPanel
      filters={filters}
      selectedFilters={activeFilter}
      onFilterChange={onFilterChange}
      search
      initialSearchValue={initialSearchValue}
      onSearchSubmit={onSearchSubmit}
      disabled={disabled}
    />
  );
};

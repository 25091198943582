import type { TeamMemberData } from '@pages/content/profile/founder/api/get-founder-team-members/get-founder-team-members.action';
import { TrashButton } from '@pages/content/profile/parts/trash-button/trash-button';
import { OneLineEllipsis } from '@parts/one-line-ellipsis/one-line-ellipsis';
import { TableColumn } from '@parts/table-column/table-column';
import { TwoLinesEllipsis } from '@parts/two-lines-ellipsis/two-lines-ellipsis';
import type { ColumnsType, ColumnType } from 'antd/lib/table';
import S from './table.styles';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { DateFormat } from '@i18n/date-formats';

interface ITeamMembers {
  onEditRow?: (id: string) => void;
  onRemoveRow?: (row: TeamMemberData) => () => void;
  translations: { [key: string]: string };
}

const sortByDate = (roleA: TeamMemberData, roleB: TeamMemberData) =>
  new Date(roleB.joined).getTime() - new Date(roleA.joined).getTime();

export const Columns = ({ onEditRow, onRemoveRow, translations }: ITeamMembers): ColumnsType<TeamMemberData> => {
  const { dateFormatter } = useUserDateFormat();

  const formatDate = (date: Date) => dateFormatter(date, DateFormat.LETTER_MONTH_LONG_YEAR);

  const showActionsCol = Boolean(onEditRow && onRemoveRow);

  const widths = {
    name: '30%',
    area: showActionsCol ? '12.5%' : '15%',
    role: showActionsCol ? '12.5%' : '15%',
    experience: showActionsCol ? '29%' : '35%',
    joined: '10%',
    actions: '70px',
  };

  const getActionsCol = (): ColumnType<TeamMemberData> => ({
    title: '',
    dataIndex: '',
    key: 'removeRow',
    width: widths.actions,
    align: 'right',
    render: (_, row, index) => (
      <S.Controls>
        <S.EditButton
          type="link"
          key={`edit-row-${index}`}
          onClick={() => row.id && onEditRow && onEditRow(row.id)}
          data-testid="team-member-edit"
        >
          <S.EditRowIcon />
        </S.EditButton>
        <TrashButton key={`remove-row-${index}`} onRemove={onRemoveRow ? onRemoveRow(row) : () => {}} />
      </S.Controls>
    ),
  });

  const cols: ColumnsType<TeamMemberData> = [
    {
      dataIndex: 'id',
      key: 'id',
      render: () => null,
    },
    {
      title: translations.name,
      dataIndex: 'name',
      key: 'name',
      width: widths.name,
      ellipsis: true,
      render: (name: string, data) => (
        <TableColumn title={translations.name}>
          <div>
            <OneLineEllipsis>
              <S.Name>{name}</S.Name>
            </OneLineEllipsis>
            <OneLineEllipsis>{data.position}</OneLineEllipsis>
          </div>
        </TableColumn>
      ),
    },
    {
      title: translations.area,
      dataIndex: 'area',
      key: 'area',
      width: widths.area,
      ellipsis: true,
      render: (area: string = '') => (
        <TableColumn title={translations.area}>
          <TwoLinesEllipsis>{area}</TwoLinesEllipsis>
        </TableColumn>
      ),
    },
    {
      title: translations.role,
      dataIndex: 'role',
      key: 'role',
      width: widths.role,
      ellipsis: true,
      render: (role: string = '') => (
        <TableColumn title={translations.role}>
          <TwoLinesEllipsis>{role}</TwoLinesEllipsis>
        </TableColumn>
      ),
    },
    {
      title: translations.experience,
      dataIndex: 'experience',
      key: 'experience',
      width: widths.experience,
      ellipsis: true,
      render: (experience: string) => (
        <TableColumn title={translations.experience}>
          <TwoLinesEllipsis>{experience}</TwoLinesEllipsis>
        </TableColumn>
      ),
    },
    {
      title: translations.joined,
      dataIndex: 'joined',
      key: 'joined',
      width: widths.joined,
      render: (date) => <TableColumn title={translations.joined}>{formatDate(date)}</TableColumn>,
      sorter: sortByDate,
      defaultSortOrder: 'ascend',
    },
  ];

  if (showActionsCol) {
    return [...cols, getActionsCol()];
  }
  return cols;
};

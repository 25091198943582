import GreenTickBase from '@assets/icons/green-circled-tick.svg?react';
import { Footer as FooterBase } from '@pages/content/lens/parts/footer/footer';
import { SubmitButton } from '@parts/submit-button/submit-button';
import styled from 'styled-components';

const DecisionsHolder = styled.div`
  display: flex;

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-wrap: wrap;
  }
`;

const Connect = styled(SubmitButton)`
  display: flex;
  align-items: center;
  width: auto;
  margin-right: ${({ theme }) => theme.spacing.small};
`;

const Cancel = styled(SubmitButton)`
  width: auto;
  margin: 0;
  padding-bottom: 0;
  padding-left: ${({ theme }) => theme.spacing.small};
  padding-top: 0;
  height: auto !important;
`;

const NotInterested = styled(Cancel)`
  padding-left: 0;
`;

const Message = styled(SubmitButton)`
  padding: 0;
  margin: 0;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const GreenTick = styled(GreenTickBase)`
  margin-right: ${({ theme }) => theme.spacing.small};
`;

const RequestSent = styled.p`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  white-space: nowrap;
`;

const Footer = styled(FooterBase)`
  height: 80px;
`;

const Padlock = styled.img`
  width: ${({ theme }) => theme.spacing.medium};
  margin-right: ${({ theme }) => theme.spacing.small};
  margin-left: -${({ theme }) => theme.spacing.xsmall};
  padding-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

export default {
  DecisionsHolder,
  Connect,
  Message,
  GreenTick,
  NotInterested,
  RequestSent,
  Cancel,
  Footer,
  Padlock,
};

import MetricImage from '@assets/icons/codat-metric.svg?react';
import ProfileImage from '@assets/icons/codat-profile.svg?react';
import SecureImage from '@assets/icons/codat-secure.svg?react';
import TrendImage from '@assets/icons/codat-trend.svg?react';
import codatImage from '@assets/images/codat.webp';
import { privacyPolicyLink, resourcesLink } from '@constants/links';
import {
  CodatRequestContextValue,
  connectCodatAction,
} from '@pages/content/pulse/api/connect-codat/connect-codat.action';
import { SecureLink } from '@parts/link/link';
import message from '@parts/message/message';
import { SubmitButton as ConnectNow } from '@parts/submit-button/submit-button';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from '../../integrated-metrics.styles';
import { Subpoint } from '../subpoint/subpoint';

interface NotConnectedProps {
  codatRequestContext: CodatRequestContextValue;
  showHeading: boolean;
}

export const NotConnected = ({ codatRequestContext, showHeading }: NotConnectedProps) => {
  const [
    titleLabel,
    subtitleLabel,
    firstSubpointTitle,
    firstSubpointDescription,
    secondSubpointTitle,
    secondSubpointDescription,
    thirdSubpointTitle,
    thirdSubpointDescription,
    fourthSubpointTitle,
    fourthSubpointDescription,
    safeWithUsLabel,
    connectFailure,
    connectNow,
    learnMore,
  ] = useTranslation([
    'metrics.codat.title',
    'metrics.codat.description',
    'metrics.codat.firstSubpoint.title',
    'metrics.codat.firstSubpoint.description',
    'metrics.codat.secondSubpoint.title',
    'metrics.codat.secondSubpoint.description',
    'metrics.codat.thirdSubpoint.title',
    'metrics.codat.thirdSubpoint.description',
    'metrics.codat.fourthSubpoint.title',
    'metrics.codat.fourthSubpoint.description',
    'metrics.codat.safeWithUs',
    'metrics.codat.failure',
    'metrics.codat.connectNow',
    'metrics.codat.learnMore',
  ]);

  const { mutateAsync: connectWithCodat } = useMutation(connectCodatAction, {
    onError: () => {
      message.error({ content: connectFailure });
    },
    onSuccess: (response) => {
      const { redirectUrl } = response.data;
      window.location.replace(redirectUrl);
    },
  });

  return (
    <S.Wrapper data-testid="integrated-metrics">
      <S.Logo src={codatImage} />

      {showHeading && (
        <S.Heading>
          <S.Title>{titleLabel}</S.Title>
          <S.Subtitle>{subtitleLabel}</S.Subtitle>
        </S.Heading>
      )}

      <S.DescriptionList>
        <Subpoint title={firstSubpointTitle} description={firstSubpointDescription} Image={TrendImage} />
        <Subpoint title={secondSubpointTitle} description={secondSubpointDescription} Image={MetricImage} />
        <Subpoint
          title={thirdSubpointTitle}
          description={thirdSubpointDescription}
          Image={SecureImage}
          SecureLink={
            <SecureLink href={privacyPolicyLink} outer target="_blank">
              {safeWithUsLabel}
            </SecureLink>
          }
        />
        <Subpoint title={fourthSubpointTitle} description={fourthSubpointDescription} Image={ProfileImage} />
      </S.DescriptionList>

      <S.Buttons>
        <ConnectNow data-testid="codat-connect-btn" onClick={() => connectWithCodat(codatRequestContext)}>
          {connectNow}
        </ConnectNow>
        <SecureLink href={resourcesLink} outer target="_blank">
          <S.LearnMore data-testid="codat-learn-btn">{learnMore}</S.LearnMore>
        </SecureLink>
      </S.Buttons>
    </S.Wrapper>
  );
};

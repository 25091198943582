import { Tag } from '@parts/tag/tag';
import styled from 'styled-components';

const Badge = styled(Tag)`
  margin: 0;
  padding: ${({ theme }) => theme.spacing.xxsmall} ${({ theme }) => theme.spacing.xsmall};
  font-size: ${({ theme }) => theme.fontSize.xxsmall};
  font-weight: ${({ theme }) => theme.fontWeight.bold} !important;
  color: ${({ theme }) => theme.baseColors.Blue200};
  border: 0 none;
  cursor: default;
`;

export default { Badge };

import { createContext, useContext } from 'react';
import type { NoteContent } from './v2-note-tile.types';

const V2NoteTileContext = createContext<NoteContent | null>(null);

export const useV2NoteTileContext = () => {
  const context = useContext(V2NoteTileContext);
  if (!context) throw new Error('V2NoteTile.* component must be rendered as child of V2NoteTile component');

  return context;
};

export { V2NoteTileContext };

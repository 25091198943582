import DownloadIconBase from '@assets/icons/download-icon.svg?react';
import styled from 'styled-components';
import { ExternalUrl as ExternalUrlBase } from '../../../parts/extrenal-url/external-url';

const DownloadIcon = styled(DownloadIconBase)`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const ExternalUrl = styled(ExternalUrlBase)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Avatar = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    margin-bottom: ${({ theme }) => theme.spacing.xbase};
    display: block;
  }
`;

export default { DownloadIcon, ExternalUrl, Avatar };

import type { AppMessagesKey } from '@i18n/messages';
import { useSimpleModal } from '@parts/modal/use-simple-modal';
import { TranslationText } from '@parts/translation-text/translation-text';
import S from './use-extended-warning-modal.styles';

export const useExtendedWarningModal = ({
  mainListItems,
  closeButtonLabel,
  title,
}: {
  mainListItems: AppMessagesKey[];
  closeButtonLabel: string;
  title?: string;
}) => {
  const { Modal, open, show, hide } = useSimpleModal();

  const footer = (
    <S.ButtonWrapper>
      <S.Button data-testid="investor-warning-modal-close" onClick={hide}>
        {closeButtonLabel}
      </S.Button>
    </S.ButtonWrapper>
  );

  return {
    show,
    hide,
    modal: (
      <Modal visible={open} title={title} footer={footer} data-testid="risk-warning-modal">
        <S.ModalContent>
          <S.MainList>
            {mainListItems.map((item) => (
              <li key={item}>
                <TranslationText id={item} values={{ b: (boldedText) => <b>{boldedText}</b> }} />
              </li>
            ))}
          </S.MainList>
        </S.ModalContent>
      </Modal>
    ),
  };
};

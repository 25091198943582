import { useEnforceTheme } from '@/utils/hooks/use-enforce-theme/use-enforce-theme';
import { UsStateNameValue, type USState } from '@/utils/type-definitions/us-state';
import EarthImg from '@assets/icons/potato-earth.svg';
import { setUserMetadata } from '@context/user/user-account.actions';
import { WizardLayout } from '@layout/wizard/wizard.layout';
import { GET_COUNTRIES_OPTIONS_DATA_CACHE_KEY, getCountriesListAction } from '@pages/content/api/get-countries-options';
import { setCountryOfResidenceAction } from '@pages/content/profile/api/set-country-of-residence/set-country-of-residence.action';
import { CountrySelect } from '@parts/country-select/country-select';
import { FormikField } from '@parts/forms/formik-field/formik-field';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import message from '@parts/message/message';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { CountryCodes, CountryNames } from '@utils/type-definitions/iso-to-country-name';
import type { SelectValue } from 'antd/lib/select';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  ACCOUNT_COUNTRY_AND_CURRENCY_CACHE_KEY,
  getAccountCountryAndCurrency,
} from '../api/account-country-and-currency/account-country-and-currency.actions';

import S from './country-of-residence.styles';

interface CountryOfResidenceForm {
  countryOfResidence?: CountryCodes;
  usState?: USState;
}

export const CountryOfResidencePage = () => {
  useEnforceTheme('dark');
  const [titleLabel, subtitleLabel, countryOfResidenceLabel, usStateLabel, , fieldRequiredLabel, saveLabel] =
    useTranslation([
      'countryOfResidence.title',
      'countryOfResidence.subtitle',
      'register.form.countryOfResidence',
      'register.form.us.state',
      'formik.validation.required',
      'navigation.wizard.footer.save',
    ]);

  const { state, dispatch } = useUserAccount();

  const { refetch: fetchCountryAndCurrency } = useQuery(
    [ACCOUNT_COUNTRY_AND_CURRENCY_CACHE_KEY],
    getAccountCountryAndCurrency,
    {
      enabled: false,
      cacheTime: 0,
      onSuccess: (res) => {
        if (!res.data) return;

        const { countryOfResidence, currency } = res.data;
        // This action will handle redirect because of canAccess route and getRedirectRoute
        dispatch(setUserMetadata({ ...state, countryOfResidence, currency }));
      },
    },
  );

  const { mutateAsync: updateCountryOfResidence, isLoading: isMutating } = useMutation(setCountryOfResidenceAction, {
    onSuccess: () => fetchCountryAndCurrency(),
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  const formik = useFormik<CountryOfResidenceForm>({
    onSubmit: (values) => {
      const { usState, ...submitValues } = values;
      updateCountryOfResidence({ ...submitValues, ...(usState ? { usState } : {}) });
    },

    initialValues: {
      countryOfResidence: undefined,
      usState: undefined,
    },
    validationSchema: Yup.object({
      countryOfResidence: Yup.string().trim().required(fieldRequiredLabel).nullable(),
      usState: Yup.string().when('countryOfResidence', {
        is: 'US',
        then: Yup.string().required(fieldRequiredLabel),
        otherwise: Yup.string().notRequired().nullable(),
      }),
    }),
  });

  const handleSelectChange = (selectKey: string) => (value: SelectValue | undefined) => {
    formik.setFieldValue(selectKey, value || null);
  };

  const { data: countriesListResponse, isLoading: areCountriesLoading } = useQuery(
    [GET_COUNTRIES_OPTIONS_DATA_CACHE_KEY],
    getCountriesListAction,
    { refetchOnWindowFocus: false },
  );

  const countriesList = countriesListResponse?.data?.countries || [];
  const countryOptions: { value: string; label: string }[] = countriesList.map((countryCode) => ({
    value: countryCode,
    label: CountryNames[countryCode] || countryCode,
  }));

  const stateOptions: { value: string; label: string }[] = Object.entries(UsStateNameValue).map(([code, name]) => ({
    value: code,
    label: name,
  }));

  if (areCountriesLoading) return <FullHeightSpinner />;

  return (
    <WizardLayout
      footerProps={{
        nextHandler: formik.submitForm,
        customNextBtnLabel: saveLabel,
        nextDisabled: !formik.isValid || isMutating,
      }}
      centerContent
    >
      <S.Wrapper data-testid="country-of-residence">
        <S.Earth src={EarthImg} loading="lazy" />
        <S.TextWrapper>
          <S.Title>{titleLabel}</S.Title>
          <S.Subtitle>{subtitleLabel}</S.Subtitle>
        </S.TextWrapper>
        <S.Form onSubmit={formik.handleSubmit}>
          <FormikField
            marginBottom
            registerMarginBottom
            fontWeight="normal"
            label={{
              for: 'countryOfResidence',
              label: <S.Label>{countryOfResidenceLabel}</S.Label>,
            }}
            touched={formik.touched.countryOfResidence}
            error={formik.errors.countryOfResidence}
          >
            <CountrySelect
              id="countryOfResidence"
              lightMode
              centeredText={false}
              options={countryOptions}
              onChange={(v) => {
                handleSelectChange('usState')(undefined);
                handleSelectChange('countryOfResidence')(v);
              }}
            />
          </FormikField>
          {formik.values.countryOfResidence === CountryCodes.US ? (
            <FormikField
              marginBottom
              registerMarginBottom
              fontWeight="normal"
              label={{
                for: 'usState',
                label: <S.Label>{usStateLabel}</S.Label>,
              }}
              touched={formik.touched.usState}
              error={formik.errors.usState}
            >
              <CountrySelect
                id="usState"
                lightMode
                centeredText={false}
                options={stateOptions}
                showFlag={false}
                onChange={handleSelectChange('usState')}
              />
            </FormikField>
          ) : null}
        </S.Form>
      </S.Wrapper>
    </WizardLayout>
  );
};

import { theme } from '@/styles/theme-config';
import type { V2CircleProgressBarVariants } from './v2-circle-progress-bar';

type ColorType = {
  [variant in V2CircleProgressBarVariants]: {
    start: string;
    end: string;
  };
};

const opacityHex = '25';

const colorMap: ColorType = {
  magenta: {
    start: theme.v2.colors.magenta[400],
    end: `${theme.v2.colors.magenta[400]}${opacityHex}`,
  },
  green: {
    start: '#8EAEF8',
    end: theme.v2.colors.green[300],
  },
  primary: {
    start: theme.v2.colors.blue[300],
    end: theme.v2.colors.blue[300],
  },
};

export const getTextColor = (variant: V2CircleProgressBarVariants): string => {
  const colors = colorMap[variant];

  return colors.start;
};

export const getStrokeColor = (variant: V2CircleProgressBarVariants) => {
  const colors = colorMap[variant];

  return colors;
};

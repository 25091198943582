import { useTranslation } from '../use-translation/use-translation';

export const useNumericConditionLabels = () => {
  const [equalLabel, greaterThanLabel, greaterOrEqualLabel, lowerThanLabel, lowerOrEqualLabel, betweenLabel] =
    useTranslation([
      'metrics.trigger.numeric.equal',
      'metrics.trigger.numeric.greaterThan',
      'metrics.trigger.numeric.greaterOrEqual',
      'metrics.trigger.numeric.lowerThan',
      'metrics.trigger.numeric.lowerOrEqual',
      'metrics.trigger.numeric.between',
    ]);

  const conditionLabels: { [key: string]: string } = {
    Equal: equalLabel,
    GreaterThan: greaterThanLabel,
    GreaterOrEqual: greaterOrEqualLabel,
    LowerThan: lowerThanLabel,
    LowerOrEqual: lowerOrEqualLabel,
    Between: betweenLabel,
  };

  return conditionLabels;
};

import React from 'react';
import type { V2LinkProps } from './V2-link.types';
import S from './v2-link.styles';

export const V2Link: React.FC<Partial<V2LinkProps>> = ({
  children,
  variant = 'default',
  to,
  hasUnderline = true,
  outer = false,
  size = 'medium',
  disabled = false,
  download = false,
  ['data-testid']: testId = 'v2-link',
}) => {
  if (outer) {
    return (
      <S.Anchor
        href={to}
        target="_blank"
        rel="noreferrer"
        variant={variant}
        size={size}
        disabled={disabled}
        download={download}
        data-testid={testId}
      >
        {children}
      </S.Anchor>
    );
  }

  return (
    <S.Link to={to} variant={variant} $hasUnderline={hasUnderline} size={size} disabled={disabled} data-testid={testId}>
      {children}
    </S.Link>
  );
};

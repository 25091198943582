import styled from 'styled-components';

const TwoLinesEllipsis = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    overflow: visible;
    display: block;
    white-space: break-spaces;
  }
`;

export default { TwoLinesEllipsis };

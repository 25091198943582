import styled, { css } from 'styled-components';

const Section = styled.div`
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.medium};

  & + & {
    border-top: 1px solid ${({ theme }) => theme.color.border};
  }
`;

const Filter = styled.div<{ active: boolean }>`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.color.fontPrimary};

  ${({ active }) =>
    active &&
    css`
      margin-bottom: ${({ theme }) => theme.spacing.small};
    `};
`;

const Order = styled.div`
  display: flex;
  flex-direction: column;
`;

const Direction = styled.div<{ active: boolean }>`
  color: ${({ theme }) => theme.color.submitButton};
  padding-top: ${({ theme }) => theme.spacing.xsmall};

  ${({ active }) =>
    active &&
    css`
      text-decoration: underline;
    `};
`;

export default {
  Order,
  Direction,
  Section,
  Filter,
};

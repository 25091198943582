import type { FundingHistoryProps } from '@pages/content/api/get-funding-history.action';
import { Section } from '@parts/section/section';
import { ShowMoreLessButton } from '@parts/show-more-less-button/show-more-less-button';
import { Table } from '@parts/table/table';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Empty } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { Columns } from '../column/column';
import S from './table.styles';

export const FundingHistoryTable = ({ data, loading = false }: { data: FundingHistoryProps[]; loading?: boolean }) => {
  const [
    fundingHistory,
    dateLabel,
    typeLabel,
    soughtLabel,
    raisedLabel,
    valuationLabel,
    statusLabel,
    useOfFundsLabel,
    emptyLabel,
  ] = useTranslation([
    'portfolio.investor.investments.table.title.fundingHistory',
    'portfolio.investor.investments.table.date',
    'portfolio.investor.investments.table.type',
    'portfolio.investor.investments.table.sought',
    'portfolio.investor.investments.table.raised',
    'portfolio.investor.investments.table.valuation',
    'portfolio.investor.investments.table.status',
    'portfolio.investor.investments.table.useOfFunds',
    'portfolio.investor.investments.table.empty',
  ]);

  const [isResized, setIsResized] = useState(false);

  const locale = {
    emptyText: !loading ? (
      <S.Placeholder>
        <Empty image={<S.NoDataIcon />} description={<S.PlaceholderLabel>{emptyLabel}</S.PlaceholderLabel>} />
      </S.Placeholder>
    ) : (
      <S.EmptyLoader />
    ),
  };
  const {
    deviceData: { isMobile, isTablet },
  } = useDeviceDetect();

  const FUNDING_ROUNDS_STARTING_LENGTH = 3;

  return (
    <Section header={fundingHistory}>
      <Table
        locale={locale}
        dataSource={data}
        pagination={{
          style: { display: 'none' },
          pageSize: isResized ? data?.length : FUNDING_ROUNDS_STARTING_LENGTH,
          hideOnSinglePage: true,
        }}
        columns={
          Columns({
            t: {
              dateLabel,
              typeLabel,
              soughtLabel,
              raisedLabel,
              valuationLabel,
              statusLabel,
              useOfFundsLabel,
            },
            compact: isMobile || isTablet,
          }) as ColumnsType<object>
        }
        loading={loading}
        rowKey="id"
      />
      {data?.length > FUNDING_ROUNDS_STARTING_LENGTH && (
        <ShowMoreLessButton isResized={isResized} setIsResized={setIsResized} scroll />
      )}
    </Section>
  );
};

import styled from 'styled-components';

const V2TableStateInfo = styled.div<{ spaceTop?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: ${({ spaceTop }) => (spaceTop ? '10px' : '0')};
`;

export default { V2TableStateInfo };

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';
import { NumericConditions } from './set-up-numeric-trigger.actions';

export interface Trigger {
  trigger: {
    id: string;
    isActive: boolean;
    condition?: NumericConditions;
    value?: number;
    rangeValue?: number;
    isMet: boolean;
    scale: number;
  } | null;
}

export const GET_METRICS_ACTION_CACHE_KEY = 'get-founder-metrics';

export const getTriggerForMetric = (metricId: string) => async (): Promise<GraphqlResponse<Trigger>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getTriggerForMetric {
        triggerForMetric(metricId: "${metricId}") {
            trigger {
                id
                value
                rangeValue
                condition
                isActive
                isMet
                scale
            }
        }
      }`,
  });

  return graphqlResponseWrapper(data, ['triggerForMetric']);
};

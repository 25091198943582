import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export enum DocumentType {
  TermsSheet = 'terms-sheet',
  AdvisoryAgreement = 'advisory-agreement',
  AdvancedSubscription = 'advanced-subscription-agreement',
  Nda = 'nda',
  EmploymentContract = 'employment-contract',
  Seis = 'seis',
}

interface LegalDocumentsProps {
  availableDocuments: DocumentType[];
  manageDocumentsUrl: string;
  helpdeskSlug: string;
}

export const GET_LEGAL_DOCUMENTS = 'get-legal-documents';

export const getLegalDocumentsAction = async (): Promise<GraphqlResponse<LegalDocumentsProps>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
        query getLegalDocumentsAction {
            getLegalDocuments {
                availableDocuments,
                manageDocumentsUrl,
                helpdeskSlug
        }
      }`,
  });

  return graphqlResponseWrapper(data, ['getLegalDocuments']);
};

import { Roles } from '@domain/accounts/roles';
import { TableColumn } from '@parts/table-column/table-column';
import { UserTableRow } from '@parts/user-table-row/user-table-row';
import { Links } from '@router/links';
import { currencyToShortFormat } from '@utils/fns/currency-to-short-format';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import type { ColumnsType } from 'antd/lib/table';
import * as H from 'history';
import S from './columns.styles';
import { Archived } from './parts/archived-label/archived-label';

interface InvestmentPortfolio {
  id: string;
  company: {
    id: string;
    name: string;
    avatarUrl: string;
    type: string;
  };
  totalCurrentValue: number;
  totalInvested: number;
}

export const Columns = ({
  t,
  history,
  tooltipLabel,
}: {
  t: {
    [key: string]: string;
  };
  tooltipLabel: string;
  history: H.History<H.LocationState>;
}): ColumnsType<InvestmentPortfolio> => {
  const { page } = useQueryParams() as { page: string };

  const {
    deviceData: { isDesktop },
  } = useDeviceDetect();
  return [
    UserTableRow({
      history,
      avatarSize: 'medium',
      toRole: Roles.FOUNDER,
      title: t.companyLabel,
      nameKey: 'company.name',
      tooltipLabel,
      idKey: 'company.id',
      redirectEvaluation: [
        {
          shouldBePresent: true,
          key: 'company.id',
        },
      ],
      avatarUrlKey: 'company.avatarUrl',
      width: '100% - 190px',
      limitNameWidth: true,
    }),
    {
      dataIndex: 'hasUnarchivedInvestments',
      key: 'hasUnarchivedInvestments',
      render: (v) => !v && isDesktop && <Archived />,
    },
    {
      title: t.totalInvestedLabel,
      dataIndex: 'totalInvested',
      key: 'totalInvested',
      width: '120px',
      render: (value) => (
        <TableColumn data-testid="total-invested" title={t.totalInvestedLabel}>
          {value ? currencyToShortFormat(value, '£') : value}
        </TableColumn>
      ),
    },
    {
      title: t.totalCurrentValueLabel,
      dataIndex: 'totalCurrentValue',
      key: 'totalCurrentValue',
      width: '150px',
      render: (value) => (
        <TableColumn data-testid="total-current-value" title={t.totalCurrentValueLabel}>
          {value ? currencyToShortFormat(value, '£') : value}
        </TableColumn>
      ),
    },
    {
      dataIndex: 'hasUnarchivedInvestments',
      className: 'archived',
      key: 'hasUnarchivedInvestments',
      render: (v) => !v && !isDesktop && <Archived data-testid="archived" />,
    },
    {
      title: '',
      dataIndex: 'id',
      render: (id, data) =>
        data.company.type === 'Internal' && (
          <S.Expand
            data-testid="Invesment_Deatils_Button"
            onClick={() => {
              history.push(`${Links.PORTFOLIO_COMPANY_DETAILS(id)}`, {
                page,
              });
            }}
          >
            {t.detailsLabel}
            <S.Arrow />
          </S.Expand>
        ),
      width: '50px',
    },
  ];
};

import { useScrollToElementWithQueryParams } from '@/utils/hooks/use-scroll-to-element/use-scroll-to-element-with-query-params';
import { Auth0ProviderValue } from '@pages/auth/api/account-data/account-data.types';
import { ChangePassword } from '@pages/content/profile/parts/change-password/change-password';
import DisabledCountryOfResidence from '@pages/content/profile/parts/country-of-residence/country-of-residence';
import LinkedinOutlinedIcon from '@pages/content/profile/parts/linkedin-outlined-icon/linkedin-outlined-icon';
import MobileFormReversed from '@pages/content/profile/parts/mobile-form-reversed/mobile-form-reversed';
import { ProfilePhoneNumber } from '@pages/content/profile/parts/profile-phone-number/profile-phone-number';
import { SwitchToPublicButton } from '@pages/content/profile/parts/switch-to-public-button/switch-to-public-button';
import TwitterOutlinedIcon from '@pages/content/profile/parts/twitter-outlined-icon/twitter-outlined-icon';
import message from '@parts/message/message';
import { Section } from '@parts/section/section';
import { SubmitButton } from '@parts/submit-button/submit-button';
import { Tooltip } from '@parts/tooltip/tooltip';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import { Col, Input, Row } from 'antd';
import 'antd-country-phone-input/dist/index.css';
import { useFormik } from 'formik';
import { useState, type ChangeEvent } from 'react';
import { FileUpload } from '../../../../../../parts/file-upload/file-upload';
import { FormikField } from '../../../parts/formik-field/formik-field';
import type { INedUpdatePersonalDetailsPayload } from '../../api/ned-profile/ned-profile.action';
import { uploadProfileAvatar } from '../../api/upload-profile-avatar/upload-profile-avatar';
import { uploadProfileCv } from '../../api/upload-profile-cv/upload-profile-cv';
import type { INedPersonalDetails as NedPersonalDetails } from '../../ned-profile.page';
import { AvatarUploader } from '../avatar-uploader/avatar-uploader';
import { useValidationSchema } from './validation-schema';

import S from './personal-details.styles';

export enum AllowedResumeFormats {
  PDF = 'application/pdf',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

export const PersonalDetails = ({
  personalDetails,
  handleSubmit,
  togglePubicView,
  isBusy,
}: {
  handleSubmit: (formState: INedUpdatePersonalDetailsPayload) => void;
  personalDetails: NedPersonalDetails;
  togglePubicView: () => void;
  isBusy?: boolean;
}) => {
  const scrollToRef = useScrollToElementWithQueryParams<HTMLElement>('personal-details');
  const [currentAvatar, setCurrentAvatar] = useState<string | null>(personalDetails.profilePhotoUrl!);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);

  const {
    state: { auth0Provider },
  } = useUserAccount();

  const [
    personalDetailsHeader,
    email,
    firstNameLabel,
    lastNameLabel,
    countryOfResidenceLabel,
    phoneNumber,
    linkedin,
    twitter,
    myResume,
    saveChanges,
    resumeTooltip,
    phoneNumberTooltipLabel,
  ] = useTranslation([
    'profile.ned.section.personalDetails.header',
    'profile.ned.section.personalDetails.form.email',
    'profile.ned.section.personalDetails.form.firstName',
    'profile.ned.section.personalDetails.form.lastName',
    'profile.ned.section.personalDetails.form.countryOfResidence',
    'profile.ned.section.personalDetails.form.phoneNumber',
    'profile.ned.section.personalDetails.form.linkedin',
    'profile.ned.section.personalDetails.form.twitter',
    'profile.ned.section.personalDetails.form.myResume',
    'profile.ned.section.personalDetails.form.saveChanges',
    'profile.ned.section.personalDetails.form.resumeTooltip',
    'profile.phoneNumber.tooltip',
  ]);

  const { isValid, dirty, submitCount, values, ...formik } = useFormik<NedPersonalDetails>({
    initialValues: personalDetails,
    onSubmit: ({ countryOfResidence, ...v }) => {
      let valuesToSend: INedUpdatePersonalDetailsPayload = v;
      if (personalDetails.profilePhotoPath === values.profilePhotoPath) {
        const { profilePhotoPath, ...rest } = valuesToSend;
        valuesToSend = rest;
      }

      const isResumeFilled = values.resume && Object.values(values.resume).every((key) => key !== '');
      if (isResumeFilled === false) {
        const { resume, ...valuesWithoutResume } = valuesToSend;
        valuesToSend = valuesWithoutResume;
      }

      handleSubmit(valuesToSend);
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: useValidationSchema(),
  });

  const handleFieldChange = (e: ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLInputElement>, field: string) => {
    formik.setFieldTouched(field, true);
    formik.setFieldValue(field, e.target.value || '');
  };

  const deleteResume = () => formik.setFieldValue('resume', null);

  return (
    <Section header={personalDetailsHeader} innerRef={scrollToRef}>
      <form onSubmit={formik.submitForm} id="ned-profile-info-form">
        <MobileFormReversed gutter={[24, 24]}>
          <Col xs={24} sm={24} md={24} lg={16} xl={17}>
            <Row gutter={[35, 10]}>
              <Col xs={24} sm={12}>
                <FormikField
                  suffix={auth0Provider === Auth0ProviderValue.Email ? <ChangePassword /> : null}
                  label={{
                    for: 'email',
                    label: email,
                  }}
                >
                  <Input id="email" type="text" readOnly value={personalDetails.email || ''} disabled />
                </FormikField>
              </Col>
            </Row>
            <Row gutter={[35, 10]}>
              <Col xs={24} sm={12}>
                <FormikField
                  label={{
                    for: 'firstName',
                    label: firstNameLabel,
                  }}
                  error={formik.errors.firstName}
                  touched={formik.touched.firstName}
                >
                  <Input {...formik.getFieldProps('firstName')} id="firstName" type="text" />
                </FormikField>
              </Col>

              <Col xs={24} sm={12}>
                <FormikField
                  label={{
                    for: 'lastName',
                    label: lastNameLabel,
                  }}
                  error={formik.errors.lastName}
                  touched={formik.touched.lastName}
                >
                  <Input {...formik.getFieldProps('lastName')} id="lastName" type="text" />
                </FormikField>
              </Col>
            </Row>
            <Row gutter={[35, 10]}>
              <Col xs={24} sm={12}>
                <DisabledCountryOfResidence
                  countryOfResidence={personalDetails.countryOfResidence as CountryCodes}
                  countryOfResidenceLabel={countryOfResidenceLabel}
                />
              </Col>
              <Col xs={24} sm={12}>
                <ProfilePhoneNumber
                  formik={{ values, isValid, dirty, submitCount, ...formik }}
                  countryOfResidence={personalDetails.countryOfResidence}
                  field="phoneNumber"
                  label={
                    <span>
                      {phoneNumber}
                      <Tooltip title={phoneNumberTooltipLabel} />
                    </span>
                  }
                  id="phoneNumber"
                />
              </Col>
            </Row>
            <Row gutter={[35, 10]}>
              <Col xs={24} sm={12}>
                <FormikField
                  label={{
                    for: 'linkedinUrl',
                    label: linkedin,
                  }}
                  error={formik.errors.linkedinUrl}
                  touched={Boolean(formik.initialValues.linkedinUrl) || formik.touched.linkedinUrl}
                  icon={<LinkedinOutlinedIcon />}
                >
                  <Input
                    {...formik.getFieldProps('linkedinUrl')}
                    onChange={(value) => handleFieldChange(value, 'linkedinUrl')}
                    id="linkedinUrl"
                    type="text"
                  />
                </FormikField>
              </Col>
              <Col xs={24} sm={12}>
                <FormikField
                  label={{
                    for: 'twitterUrl',
                    label: twitter,
                  }}
                  error={formik.errors.twitterUrl}
                  touched={formik.touched.twitterUrl}
                  icon={<TwitterOutlinedIcon />}
                >
                  <Input
                    {...formik.getFieldProps('twitterUrl')}
                    onChange={(value) => handleFieldChange(value, 'twitterUrl')}
                    id="twitterUrl"
                    type="text"
                  />
                </FormikField>
              </Col>
            </Row>
            <Row>
              <Col span="24">
                <FormikField
                  label={{
                    for: '',
                    label: (
                      <span>
                        {myResume}
                        <Tooltip title={resumeTooltip} />
                      </span>
                    ),
                  }}
                >
                  <FileUpload
                    file={values.resume?.name}
                    fileUrl={values.resume?.url}
                    uploadMutation={(formData: FormData) => {
                      setIsFileUploading(true);
                      return uploadProfileCv(formData);
                    }}
                    onUploadSuccess={(resp, formData) => {
                      formik.setFieldValue('resume.path', resp.data.filePath);
                      formik.setFieldValue('resume.url', resp.data.fileUrl);
                      formik.setFieldValue('resume.name', formData?.get('fileName'));
                      setIsFileUploading(false);
                    }}
                    deleteFile={deleteResume}
                    mimeTypes={Object.values(AllowedResumeFormats).join(',')}
                  />
                </FormikField>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={7}>
            <S.BuildYourProfileScore />
            <FormikField
              label={{
                for: 'profilePhotoPath',
                label: '',
              }}
              error={formik.errors.profilePhotoPath}
              touched={formik.touched.profilePhotoPath}
            >
              <AvatarUploader
                name={values.firstName}
                uploadMutation={(formData: FormData) => {
                  setIsFileUploading(true);
                  return uploadProfileAvatar({ formData });
                }}
                deleteFile={() => {
                  formik.setFieldValue('profilePhotoPath', null);
                  formik.setFieldValue('profilePhotoUrl', null);

                  setCurrentAvatar(null);
                }}
                onSuccess={(res) => {
                  setCurrentAvatar(res.data.fileUrl);
                  formik.setFieldValue('profilePhotoPath', res.data.filePath);
                  formik.setFieldValue('profilePhotoUrl', res.data.fileUrl);
                  setIsFileUploading(false);
                }}
                onError={(err: AxiosError) => {
                  message.error({ content: getServerError(err) });
                }}
                currentImage={currentAvatar}
              />
            </FormikField>
          </Col>
        </MobileFormReversed>
        <Row>
          <Col md={12}>
            <SubmitButton
              type="primary"
              htmlType="submit"
              form="ned-profile-form"
              disabled={isFileUploading || isBusy}
              disabledEvaluation={{ isValid, dirty, submitCount }}
              onClick={formik.submitForm}
            >
              {saveChanges}
            </SubmitButton>
          </Col>
          <Col md={12}>
            <SwitchToPublicButton onClick={() => togglePubicView()} />
          </Col>
        </Row>
      </form>
    </Section>
  );
};

import { Avatar as AvatarBase } from '@parts/avatar/avatar';
import styled, { css } from 'styled-components';

interface ItemProps {
  read?: boolean;
}

export const unreadIndicator = css`
  content: ' ';
  position: absolute;
  right: 10px;
  top: calc(50% - 4px);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.color.submitButton};
`;

const Item = styled.section<ItemProps>`
  background-color: ${({ theme, read = false }) =>
    read ? theme.color.sectionBackground : theme.color.layoutBackground};
  padding: ${({ theme }) => `${theme.spacing.xmedium} ${theme.spacing.medium}`};
  display: flex;
  position: relative;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme, read = false }) =>
      read ? theme.color.sectionBackground : theme.color.layoutBackground};
    opacity: 0.7;
  }

  ${({ read }) =>
    !read &&
    css`
      &::after {
        ${unreadIndicator}
      }
    `};
`;

const Avatar = styled(AvatarBase)`
  margin-right: ${({ theme }) => theme.spacing.xmedium};
  flex-shrink: 0;
`;

const TextWrapper = styled.div`
  white-space: initial;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.color.fontPrimary};
`;

const Content = styled.div`
  text-align: left;
`;

const Time = styled.p`
  color: ${({ theme }) => theme.color.fontSecondary};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
`;

const S = {
  Item,
  Avatar,
  TextWrapper,
  Content,
  Time,
};

export default S;

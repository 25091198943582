import { Button } from 'antd';
import styled from 'styled-components';

const ChangePasswordButton = styled(Button)`
  margin: 0;
  padding: 0;
  height: fit-content !important;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.color.submitButton};
  font-size: ${({ theme }) => theme.fontSize.regular};
`;

const Description = styled.h3`
  margin-bottom: ${({ theme }) => theme.spacing.xmedium};
  color: ${({ theme }) => theme.baseColors.White};
`;

const List = styled.ul`
  list-style-position: inside;
`;

const ListItem = styled.li`
  margin: ${({ theme }) => theme.spacing.xsmall} 0;
`;

export default {
  ChangePasswordButton,
  ListItem,
  Description,
  List,
};

import { useRef, useState } from 'react';
import { V2Icon } from '../v2-icon/v2-icon';
import type { V2CheckboxProps } from './v2-checkbox.types';

import S from './v2-checkbox.styles';

export const V2Checkbox = ({
  value,
  name,
  disabled,
  checked,
  label,
  size = 'small',
  readonly,
  testId,
  lineTroughOnCheck,
  onChange,
}: V2CheckboxProps) => {
  const checkboxRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  const handleCheckboxChange = () => {
    if (!(readonly || disabled)) onChange?.(!checked);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      handleCheckboxChange();
    }
  };

  const iconSize = size === 'small' ? 'xs' : 'sm';

  return (
    <S.Wrapper data-testid={testId} readonly={readonly} disabled={disabled}>
      <S.InputWrapper size={size}>
        <S.InputCheckbox
          readOnly={readonly}
          ref={checkboxRef}
          type="checkbox"
          tabIndex={readonly || disabled ? -1 : 0}
          name={name}
          value={value}
          disabled={disabled}
          checked={checked}
          onChange={handleCheckboxChange}
          onKeyDown={handleKeyDown}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        <S.IconWrapper isFocused={isFocused} readonly={readonly} checked={checked} disabled={disabled}>
          {checked && <V2Icon name="checkbox-icon" size={iconSize} />}
        </S.IconWrapper>
      </S.InputWrapper>
      <S.Label size={size} disabled={disabled} lineTroughOnCheck={lineTroughOnCheck} checked={checked}>
        {label}
      </S.Label>
    </S.Wrapper>
  );
};

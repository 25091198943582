import { SubmitButton as ButtonBase } from '@parts/submit-button/submit-button';
import styled from 'styled-components';

const ReviewButton = styled(ButtonBase)`
  background-color: transparent;
  color: ${({ theme }) => theme.baseColors.Blue200};
  display: flex;
  align-items: center;
  height: 40px !important;
  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: calc(100vw - 40px);
    height: 50px !important;
    justify-content: center;
  }
  &:hover,
  &:focus {
    background-color: transparent;
    color: ${({ theme }) => theme.baseColors.Blue200};
  }
`;

const ReviewButtonWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export default { ReviewButton, ReviewButtonWrapper };

import styled from 'styled-components';

const Grid = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  grid-gap: ${({ theme }) => theme.spacing.xmedium};
  width: 100%;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) {
    grid-template-columns: repeat(2, minmax(340px, 1fr));
    grid-gap: ${({ theme }) => theme.spacing.xmedium};
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 620px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export default { Grid };

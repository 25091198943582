import { FounderLayout } from '@layout/founder/founder.layout';
import { addNewRole } from '@pages/content/roles/management/context/management.action-creators';
import message from '@parts/message/message';
import { PageHeader } from '@parts/page-header/page-header';
import { Links } from '@router/links';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useRoleManagement } from '@utils/hooks/use-role-management/use-role-management';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import Element from '../../parts/element/element';
import { createRoleAction } from '../api/create-role.actions';
import { RoleDetailsForm, type RoleDetailsPayload } from '../parts/role-details/role-details.form';

export const CreateRolePage = () => {
  const { dispatch } = useRoleManagement();

  const { mutateAsync: createRole } = useMutation(createRoleAction);

  const [successMsg, title] = useTranslation(['roles.create.success', 'roles.founder.create.title']);

  const history = useHistory();

  const handleGoBack = () => history.push(Links.ROLES_BROWSE());

  const handleRoleSubmit = async (payload: RoleDetailsPayload) => {
    try {
      const res = await createRole(payload);
      dispatch(addNewRole(res?.data.id, res?.data.title));

      message.success({ content: successMsg });

      history.push(Links.ROLES_BROWSE());
    } catch (error) {
      message.error({ content: getServerError(error as AxiosError) });
    }
  };

  return (
    <FounderLayout>
      <PageHeader title={title} onBack={handleGoBack} />
      <Element>
        <RoleDetailsForm onSubmit={handleRoleSubmit} />
      </Element>
    </FounderLayout>
  );
};

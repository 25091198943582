import { V2Button } from '@/components/ui/v2-button/v2-button';
import styled from 'styled-components';

const Button = styled(V2Button)`
  text-decoration: none;
`;

export default {
  Button,
};

import { useState } from 'react';

interface DashboardElementBaseProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

export const DashboardElementBase = ({ className = '', ...restProps }: DashboardElementBaseProps) => {
  const [IsHovering, setIsHovering] = useState(false);

  return (
    <div
      {...restProps}
      className={`${className} ${IsHovering ? 'hovering' : ''}`}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    />
  );
};

// @casl/ability imports
import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import { AbilityAction, AbilitySubject, type AppAbility, type RoleAbilityBuilderFunction } from './types';
// Domain imports
import type { SubscriptionProduct } from '@domain/accounts/subscription-products';
// Page imports
import type { ApiDefinedPermission, RoleOnboardingStatus } from '@pages/auth/api/account-data/account-data.types';
// Router imports
import { Routes } from '@router/routes';
import {
  getRoleAccessibleRoutes,
  getRoleForbiddenRoutes,
  getRoleForbiddenRoutesForNoActive,
} from '@router/utils/route-helpers';
// Utility imports
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
// Ability imports
import { createFounderAbility } from './abilities/founder';
import { createInvestorAbility } from './abilities/investor';
import { createInvestorNedAbility } from './abilities/investor-ned';
import { createNedAbility } from './abilities/ned';
import { createNoRoleAbility } from './abilities/no-role';
// Ability utility imports
import { AccountStatus } from '@context/user/user-account.context';
import { Roles } from '@domain/accounts/roles';
import { hasCoRSet } from './abilities/utils/has-cor-set';
import { hasPermissionsToAdvisorCommunity } from './abilities/utils/has-permission-to-advisor-community';
import { hasPermissionsToLegalDocs } from './abilities/utils/has-permissions-to-legal-docs';
import { hasPermissionsToPortfolio } from './abilities/utils/has-permissions-to-portfolio';
import { hasSomeActiveProduct } from './abilities/utils/has-some-active-product';
import { isActiveFounder } from './abilities/utils/is-active-founder';
import { isActiveInvestor } from './abilities/utils/is-active-investor';
import { isActiveNed } from './abilities/utils/is-active-ned';

export class RoleAbility {
  private builder: AbilityBuilder<AppAbility> = new AbilityBuilder<AppAbility>(createMongoAbility);

  constructor(
    private readonly isAuthorized: boolean,
    private readonly accountStatus: AccountStatus,
    private role: Roles,
    private readonly products: SubscriptionProduct[],
    private readonly cor: CountryCodes | null,
    private readonly onboarding: RoleOnboardingStatus,
    private readonly apiDefinedPermissions: ApiDefinedPermission[],
    private readonly hasNedAcademyAccess: boolean,
  ) {
    if (!this.isAuthorized) {
      this.role = Roles.NO_ROLE;
    }

    this.initializeAbilities();
  }

  public build() {
    return this.builder.build();
  }

  private initializeAbilities() {
    this.createBaseRoutingAbilities();
    this.assignApiDefinedPermissions();

    if (!hasCoRSet(this.cor)) return;
    this.createRoleAbilities();
  }

  private assignApiDefinedPermissions() {
    this.assignPermission(AbilityAction.ACCESS, AbilitySubject.LEGAL_DOCS, hasPermissionsToLegalDocs);
    this.assignPermission(AbilityAction.ACCESS, AbilitySubject.PORTFOLIO, hasPermissionsToPortfolio);
    this.assignPermission(AbilityAction.ACCESS, AbilitySubject.ADVISOR_COMMUNITY, hasPermissionsToAdvisorCommunity);
    this.assignRoutingPermission(Routes.LEGAL, hasPermissionsToLegalDocs);
    this.assignPortfolioRoutingPermissions(hasPermissionsToPortfolio);
  }

  private assignPermission(
    action: AbilityAction,
    subject: AbilitySubject,
    permissionCheck: (permissions: ApiDefinedPermission[]) => boolean,
  ) {
    if (permissionCheck(this.apiDefinedPermissions)) {
      this.builder.can(action, subject);
    } else {
      this.builder.cannot(action, subject);
    }
  }

  private assignRoutingPermission(route: string, permissionCheck: (permissions: ApiDefinedPermission[]) => boolean) {
    if (permissionCheck(this.apiDefinedPermissions)) {
      this.builder.can(AbilityAction.ACCESS_PAGE, route as Routes);
    } else {
      this.builder.cannot(AbilityAction.ACCESS_PAGE, route as Routes);
    }
  }

  private assignPortfolioRoutingPermissions(permissionCheck: (permissions: ApiDefinedPermission[]) => boolean) {
    const portfolioRoutes = [
      Routes.PORTFOLIO,
      Routes.PORTFOLIO_COMPANY_DETAILS,
      Routes.PORTFOLIO_EXPANDED_VIEW,
      Routes.PORTFOLIO_EXPANDED_VIEW_SINGLE,
    ];

    portfolioRoutes.forEach((route) => this.assignRoutingPermission(route, permissionCheck));
  }

  private createBaseRoutingAbilities() {
    this.assignRoleBasedRoutingPermissions();
    this.assignPaymentBasedRoutingPermissions();
    this.assignCoRBasedRoutingPermissions();
  }

  private assignRoleBasedRoutingPermissions() {
    getRoleAccessibleRoutes(this.role).forEach((route) => this.builder.can(AbilityAction.ACCESS_PAGE, route));
    getRoleForbiddenRoutes(this.role).forEach((route) => this.builder.cannot(AbilityAction.ACCESS_PAGE, route));
  }

  private assignCoRBasedRoutingPermissions() {
    if (!hasCoRSet(this.cor) && this.role !== Roles.NO_ROLE) {
      getRoleAccessibleRoutes(this.role).forEach((route) => this.builder.cannot(AbilityAction.ACCESS_PAGE, route));
      this.builder.can(AbilityAction.ACCESS_PAGE, Routes.COR_SELECTION);
    } else if (hasCoRSet(this.cor)) {
      this.builder.cannot(AbilityAction.ACCESS_PAGE, Routes.COR_SELECTION);
    }
  }

  private assignPaymentBasedRoutingPermissions() {
    if (!this.isPaid) {
      getRoleForbiddenRoutesForNoActive(this.role).forEach((route) =>
        this.builder.cannot(AbilityAction.ACCESS_PAGE, route),
      );
    }

    if (hasSomeActiveProduct(this.products)) {
      this.builder.cannot(AbilityAction.ACCESS_PAGE, Routes.CHECKOUT);
    }
  }

  private createRoleAbilities() {
    const roleAbilitiesCreators: Map<Roles, RoleAbilityBuilderFunction> = new Map([
      [Roles.FOUNDER, createFounderAbility],
      [Roles.INVESTOR, createInvestorAbility],
      [Roles.INVESTOR_NED, createInvestorNedAbility],
      [Roles.NED, createNedAbility],
      [Roles.NO_ROLE, createNoRoleAbility],
    ]);

    const roleAbilityCreator = roleAbilitiesCreators.get(this.role);

    if (!roleAbilityCreator) {
      throw new Error(`RoleAbility: RoleAbilityCreator for role ${this.role} not found`);
    }

    roleAbilityCreator(this.builder, {
      accountStatus: this.accountStatus,
      products: this.products,
      cor: this.cor,
      onboarding: this.onboarding,
      apiDefinedPermissions: this.apiDefinedPermissions,
      hasNedAcademyAccess: this.hasNedAcademyAccess,
    });
  }

  private get isPaid(): boolean {
    const hasActiveProductHelpers: Record<Roles, (p: SubscriptionProduct[]) => boolean> = {
      [Roles.FOUNDER]: isActiveFounder,
      [Roles.INVESTOR]: isActiveInvestor,
      [Roles.INVESTOR_NED]: (products) => isActiveInvestor(products) && isActiveNed(products),
      [Roles.NED]: isActiveNed,
      [Roles.NO_ROLE]: () => true,
    };

    const isAccountActive = this.accountStatus === AccountStatus.ACTIVE;
    const hasActiveProduct = hasActiveProductHelpers[this.role](this.products);

    return isAccountActive && hasActiveProduct;
  }
}

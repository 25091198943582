import { SecureLink } from '@parts/link/link';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './terms-conditions.styles';

export const TermsConditions = ({ href }: { href: string }) => {
  const [termsConditionsLabel, termsConditionsLinkLabel] = useTranslation([
    'register.form.termsConditions',
    'register.form.termsConditions.link',
  ]);

  return (
    <S.TermsConditions>
      {termsConditionsLabel}
      <SecureLink href={href} outer target="blank">
        {termsConditionsLinkLabel}
      </SecureLink>
    </S.TermsConditions>
  );
};

import { V2Button } from '@/components/ui/v2-button/v2-button';
import { V2IconBox } from '@/components/ui/v2-icon-box/v2-icon-box';
import type { V2IconBoxVariant } from '@/components/ui/v2-icon-box/v2-icon-box.types';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import type { V2IconName } from '@ui/v2-icon/icons';

import S from './tile.styles';

type ReadinessCarouselTileProps = {
  title: string;
  items: string[];
  onClick: () => void;
  completion: { completed: number; total: number };
  icon: V2IconName;
};

export const ReadinessCarouselTile: React.FC<ReadinessCarouselTileProps> = ({
  title,
  items,
  completion,
  onClick,
  icon,
}) => {
  const [subtitleInProgress, subtitleCompleted, editText, completeText] = useTranslation([
    {
      id: 'homepage.readinessScore.tile.inProgress',
      variables: {
        completed: completion.completed.toString(),
        total: completion.total.toString(),
      },
    },
    'homepage.readinessScore.tile.completed',
    'homepage.readinessScore.tile.edit',
    'homepage.readinessScore.tile.complete',
  ]);

  const isCompleted = completion.total > 0 && completion.completed === completion.total;

  const iconBoxVariant: V2IconBoxVariant = isCompleted ? 'neutral' : 'secondary';
  return (
    <S.ReadinessCarouselTile>
      <S.Header>
        <V2IconBox variant={iconBoxVariant} data-testid="readiness-tile-icon-box" icon={icon}></V2IconBox>
        <button onClick={onClick} data-testid="readiness-tile-title-button">
          <S.Title>{title}</S.Title>
          <S.SubTitle $completed={isCompleted}>{isCompleted ? subtitleCompleted : subtitleInProgress}</S.SubTitle>
        </button>
      </S.Header>

      <S.Content>
        <S.List>
          {items.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </S.List>

        <S.Actions>
          <V2Button variant="link" endIcon="chevron-right" onClick={onClick}>
            {isCompleted ? editText : completeText}
          </V2Button>
        </S.Actions>
      </S.Content>
    </S.ReadinessCarouselTile>
  );
};

import { isActiveInvestor, isActiveNed } from '@abilities';
import { Roles } from '@domain/accounts/roles';
import { InvestorNedLayout } from '@layout/investor-ned/investor-ned.layout';
import { NotFoundPage } from '@pages/utility/not-found/not-found.page';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { FounderProfilePage } from './founder/founder-profile.page';
import { InvestorNedStateProvider } from './investor-ned/context/investor-ned.provider';
import { InvestorNedProfilePage } from './investor-ned/investor-ned-profile.page';
import { InvestorProfilePage } from './investor/investor-profile.page';
import { NedProfilePage } from './ned/ned-profile.page';

export const ProfilePage = () => {
  const {
    state: { userRole, products },
  } = useUserAccount();

  switch (userRole) {
    case Roles.FOUNDER:
      return <FounderProfilePage />;
    case Roles.INVESTOR:
      return <InvestorProfilePage />;
    case Roles.INVESTOR_NED: {
      const isActiveInvestorStatus = isActiveInvestor(products);
      const isActiveNedStatus = isActiveNed(products);

      if (isActiveInvestorStatus && isActiveNedStatus) {
        return (
          <InvestorNedStateProvider>
            <InvestorNedProfilePage />
          </InvestorNedStateProvider>
        );
      }

      if (isActiveInvestorStatus) {
        return <InvestorProfilePage Layout={InvestorNedLayout} />;
      }

      return <NedProfilePage Layout={InvestorNedLayout} />;
    }
    case Roles.NED:
      return <NedProfilePage />;
    default:
      return <NotFoundPage />;
  }
};

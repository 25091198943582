import type { ReactNode } from 'react';
import S from './empty-stage.styles';

export const EmptyState = ({
  icon,
  headline,
  texts,
  children,
}: {
  icon: ReactNode;
  headline: ReactNode;
  texts: ReactNode[];
  children?: ReactNode | ReactNode[];
}) => (
  <S.Wrapper>
    {icon}
    <S.Headline>{headline}</S.Headline>
    {texts.map((t) => (
      <S.Text key={t?.toString()}>{t}</S.Text>
    ))}
    {children}
  </S.Wrapper>
);

import { SelfCertificationType } from '@pages/content/profile/investor/api/types';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_INVESTOR_SELF_CERTIFICATION_CATEGORY = 'get-investor-self-certification-category';

export const getInvestorSelfCertificationCategory = async (): Promise<
  GraphqlResponse<{
    selfCertificationCategory: SelfCertificationType | null;
    selfCertificationTimestamp: string | null;
  }>
> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getInvestorSelfCertificationCategory {
        investorOnboardingState {
          selfCertificationCategory,
          selfCertificationTimestamp
        }
      }
  `,
  });

  return graphqlResponseWrapper(data, ['investorOnboardingState']);
};

import { Table } from '@parts/table/table';
import type { ColumnsQuerying } from '@utils/hooks/use-filtering/use-filtering';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { ColumnsType } from 'antd/lib/table';
import { Columns } from './parts/columns/columns';

export const CapTable = ({
  items,
  querying,
  refetch,
}: {
  querying: ColumnsQuerying;
  items: (object & { id: string })[];
  refetch: Function;
}) => {
  const history = useHistory();

  const [
    nameOrEmailLabel,
    roundDateLabel,
    roundTypeLabel,
    sharesLabel,
    amountLabel,
    percentageOwnershipLabel,
    awaitingConfirmationLabel,
    confirmedByFounderLabel,
    confirmedByInvestorLabel,
  ] = useTranslation([
    'shareholders.table.nameOrEmail',
    'shareholders.table.roundDate',
    'shareholders.table.roundType',
    'shareholders.table.shares',
    'shareholders.table.amount',
    'shareholders.table.percentageOwnership',
    'shareholders.table.awaitingConfirmation',
    'shareholders.table.confirmedByFounder',
    'shareholders.table.confirmedByInvestor',
  ]);

  return (
    <Table
      dataSource={items}
      columns={
        Columns({
          t: {
            nameOrEmailLabel,
            roundDateLabel,
            roundTypeLabel,
            sharesLabel,
            amountLabel,
            percentageOwnershipLabel,
            awaitingConfirmationLabel,
            confirmedByFounderLabel,
            confirmedByInvestorLabel,
          },
          querying,
          history,
          refetch,
        }) as ColumnsType<object>
      }
      rowClassName={(row) => (row.archived ? 'archived-investment' : '')}
      rowKey="id"
    />
  );
};

import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import { Progress } from 'antd';
import styled from 'styled-components';
import type { SizeType, V2CircleProgressBarVariants } from './v2-circle-progress-bar';

const sizes = {
  small: 50,
  default: 84,
  large: 120,
};

const negativeMargins = {
  small: '-8px',
  default: '-14px',
  large: '-20px',
};

const innerFontSize = {
  small: '12px',
  default: '22px',
  large: '32px',
};

const V2CircleProgressBar = styled(Progress)<{
  variant?: V2CircleProgressBarVariants;
  $size: SizeType;
  $textColor: string | undefined;
}>`
  .ant-progress-text {
    font-style: normal;
    color: ${({ $textColor }) => $textColor} !important;
    font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
    font-size: ${({ $size }) => innerFontSize[$size]} !important;
    line-height: 140%;
    letter-spacing: 0.217px;
  }

  .ant-progress-inner {
    width: ${({ $size }) => sizes[$size] ?? sizes.default}px !important;
    height: ${({ $size }) => sizes[$size] ?? sizes.default}px !important;
  }

  .ant-progress-circle .ant-progress-circle-path {
    stroke: url(#exampleGradient);
  }

  .ant-progress-circle-trail {
    stroke: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[300], theme.v2.colors.blue[500])};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChildrenWrapper = styled.div<{ $size: SizeType }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: ${({ $size }) => negativeMargins[$size]};
  line-height: 1;
`;

const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: 'column';
  justify-content: center;
  align-items: center;
  position: 'relative';

  & > * {
    max-width: 100%;
    max-height: 100%;
  }
`;

export default { V2CircleProgressBar, Wrapper, ChildrenWrapper, IconWrapper };

import { SecureLink } from '@/parts/link/link';
import { Button } from '@parts/button/button';
import styled from 'styled-components';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.large};
`;

const Description = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.medium};
  color: ${({ theme }) => theme.color.fontPrimary};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    text-align: center;
  }
`;

const AddRoleButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.xbase};
`;

const SubTitle = styled.p`
  color: ${({ theme }) => theme.color.fontSecondary};
  margin-top: 24px;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    text-align: center;
  }
`;

const Link = styled(SecureLink)`
  color: ${({ theme }) => theme.baseColors.Blue200};
  font-size: ${({ theme }) => theme.fontSize.regular};
  text-decoration: none;
  white-space: nowrap;

  &:visited {
    color: ${({ theme }) => theme.baseColors.Blue200};
    text-decoration: none;
  }
`;

export default {
  Section,
  Description,
  AddRoleButton,
  SubTitle,
  Link,
};

import { Radio } from 'antd';
import type { RadioGroupVariant } from './v2-radio-group';
import styled from 'styled-components';

const V2RadioGroup = styled(Radio.Group)<{ variant: RadioGroupVariant }>`
  display: inline-flex;
  justify-content: flex-start;
  flex-direction: ${({ variant }) => (variant === 'vertical' ? 'column' : 'row')};

  @media (min-width: 744px) {
    gap: ${({ variant }) => (variant === 'vertical' ? '0' : '40px')};
  }
`;

export default { V2RadioGroup };

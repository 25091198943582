import { Roles } from '@domain/accounts/roles';
import type { RegisteredFoundingRound } from '@pages/content/api/get-registered-funding-rounds.action';
import { LazyMonthDatePicker } from '@parts/date-picker/date-picker.lazy';
import { FullWidthField } from '@parts/forms/formik-full-field/formik-full-field';
import { HalfWidthField } from '@parts/forms/formik-half-field/formik-half-field';
import { FormikLabel } from '@parts/forms/formik-label/formik-label';
import { QuarterWidthField } from '@parts/forms/formik-quarter-field/formik-quarter-field';
import { NumberInput } from '@parts/forms/number-input/number-input';
import { Icon } from '@parts/icon/icon';
import PopConfirm from '@parts/popconfirm/popconfirm';
import { Select } from '@parts/select/select';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Tooltip } from 'antd';
import type { LabeledValue } from 'antd/lib/tree-select';
import { useState } from 'react';
import { Add } from '../../portfolio/investor/parts/editor/parts/add/add';
import S from './editor-details.styles';
import { AdditionalInfo } from './parts/additional-info/additional-info';
import OptionalTooltip from './parts/optional-tooltip/optional-tooltip';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { DateFormat } from '@i18n/date-formats';

export const EditorDetails = ({
  index,
  setFieldValue,
  getFieldProps,
  getFieldMeta,
  setFieldTouched,
  internalEntityId,
  foundingRounds: { registered, activeRegistered, externalTypes },
  role,
  deleteInvestment,
  disableDeleting,
}: {
  index: number;
  setFieldValue: Function;
  getFieldProps: Function;
  getFieldMeta: Function;
  setFieldTouched: Function;
  disableDeleting?: boolean;
  deleteInvestment: (index: number) => void;
  internalEntityId?: string;
  foundingRounds: {
    registered: RegisteredFoundingRound[];
    externalTypes: string[];
    activeRegistered: string[];
  };
  role: Roles.INVESTOR | Roles.FOUNDER;
}) => {
  const [
    investmentRoundLabel,
    investmentRoundTooltip,
    dateLabel,
    preMoneyLabel,
    postMoneyLabel,
    amountLabel,
    sharesLabel,
    ownedLabel,
    addLabel,
    removeLabel,
    notEditableLabel,
  ] = useTranslation([
    'portfolio.investor.drawer.details.investmentRound',
    'portfolio.investor.drawer.details.investmentRound.tooltip',
    'portfolio.investor.drawer.details.date',
    'portfolio.investor.drawer.details.preMoneyVal',
    'portfolio.investor.drawer.details.postMoneyVal',
    'portfolio.investor.drawer.details.amount',
    'portfolio.investor.drawer.details.shares',
    'portfolio.investor.drawer.details.owned',
    'portfolio.investor.drawer.details.add',
    'investment.remove',
    'portfolio.investor.drawer.details.notEditable',
  ]);

  const { dateFormatter } = useUserDateFormat();

  const commonProps = { setFieldValue, getFieldProps, getFieldMeta };

  const [advancedShown, setAdvancedShown] = useState(false);

  const investmentKey = `investments[${index}]`;

  const idKey = `${investmentKey}.id`;
  const amountKey = `${investmentKey}.amount`;
  const sharesKey = `${investmentKey}.shares`;
  const ownedKey = `${investmentKey}.percentageOwnership`;
  const roundTypeKey = `${investmentKey}.round.type`;
  const roundDateKey = `${investmentKey}.round.date`;
  const roundIdKey = `${investmentKey}.round.id`;
  const roundIsEditableKey = `${investmentKey}.round.isEditable`;
  const preMoneyValKey = `${investmentKey}.preMoneyVal`;
  const postMoneyValKey = `${investmentKey}.postMoneyVal`;

  const roundTypeProps = getFieldProps(roundTypeKey);
  const roundDateProps = getFieldProps(roundDateKey);
  const roundIdProps = getFieldProps(roundIdKey);
  const idProps = getFieldProps(idKey);

  // It's the only way we can check if this is a case with an external user after registration process
  const isRoundNotEditable = (getFieldProps(roundIsEditableKey).value as boolean | undefined) === false;
  const disableFieldForExternalWithCreatedAccount = isRoundNotEditable;

  const onChange =
    (val: string | number | Date | null | LabeledValue, toNumber: boolean = false) =>
    (fieldKey: string) => {
      const numberized = Number(val);

      setFieldTouched(fieldKey, true);
      setFieldValue(fieldKey, toNumber && numberized ? numberized : val || null);
    };

  const formatRegisteredRound = (fr: { id: string; type: string; date: Date } | null) => {
    if (!fr) return '';

    return `${fr.type} (${dateFormatter(fr.date, DateFormat.FULL_MONTH_LONG_YEAR)})`;
  };

  return (
    <S.Wrapper>
      {!idProps.value && !disableDeleting && (
        <PopConfirm title={removeLabel} onConfirm={() => deleteInvestment(index)}>
          <Icon icon={S.RemoveIcon} />
        </PopConfirm>
      )}
      <S.FormikRow>
        {(internalEntityId && !disableFieldForExternalWithCreatedAccount) || role === Roles.FOUNDER ? (
          <FullWidthField
            data-testid="round-id"
            label={{
              for: roundIdKey,
              label: (
                <FormikLabel>
                  {investmentRoundLabel} <S.Asterisk>*</S.Asterisk>
                  {role === Roles.INVESTOR && (
                    <Tooltip title={investmentRoundTooltip}>
                      <S.InfoIcon />
                    </Tooltip>
                  )}
                </FormikLabel>
              ),
            }}
            {...getFieldMeta(roundIdKey)}
          >
            <Select
              data-testid="investments-select"
              disabled={registered.length === 0}
              value={formatRegisteredRound(registered.find((fr) => fr.id === roundIdProps.value) || null)}
              onSelect={(v) => {
                setFieldValue(roundIdKey, v);
              }}
            >
              {registered
                .filter((fr) => !activeRegistered.includes(fr.id) && !fr.hasInvestment)
                .map((fr) => (
                  <Select.Option key={fr.id} value={fr.id} data-testid={`investments-select-option-${fr.id}`}>
                    {fr.type} ({dateFormatter(fr.date, DateFormat.FULL_MONTH_LONG_YEAR)})
                  </Select.Option>
                ))}
            </Select>
          </FullWidthField>
        ) : (
          <>
            <S.InvestmentRoundField
              data-testid="investment-round-type"
              label={{
                for: roundTypeKey,
                label: (
                  <FormikLabel>
                    {investmentRoundLabel} <S.Asterisk>*</S.Asterisk>
                    <Tooltip title={investmentRoundTooltip}>
                      <S.InfoIcon />
                    </Tooltip>
                  </FormikLabel>
                ),
              }}
              {...getFieldMeta(roundTypeKey)}
            >
              <OptionalTooltip show={isRoundNotEditable} title={notEditableLabel}>
                <Select
                  data-testid="investment-select-round-type"
                  value={roundTypeProps.value || undefined}
                  onSelect={(v) => {
                    setFieldValue(roundTypeKey, v);
                  }}
                  disabled={isRoundNotEditable}
                >
                  {externalTypes.map((fr) => (
                    <Select.Option key={fr} value={fr} data-testid={`investment-select-round-type-option-${fr}`}>
                      {fr}
                    </Select.Option>
                  ))}
                </Select>
              </OptionalTooltip>
            </S.InvestmentRoundField>
            <S.DateField
              data-testid="data-round-date"
              label={{
                for: roundDateKey,
                label: (
                  <FormikLabel>
                    {dateLabel} <S.Asterisk>*</S.Asterisk>
                  </FormikLabel>
                ),
              }}
              {...getFieldMeta(roundDateKey)}
            >
              <OptionalTooltip show={isRoundNotEditable} title={notEditableLabel}>
                <LazyMonthDatePicker
                  data-testid="date-picker-month"
                  disabled={isRoundNotEditable}
                  {...roundDateProps}
                  value={roundDateProps.value ? new Date(roundDateProps.value) : null}
                  onChange={(v: Date) => {
                    setFieldValue(roundDateKey, v);
                  }}
                  format={(value: Date) => dateFormatter(value, DateFormat.FULL_MONTH_LONG_YEAR)}
                  allowClear={false}
                  disabledDate={(curr: Date) => {
                    const isFoundUsedInvestmentRound = registered.filter(
                      (el) => el.hasInvestment && el.type === roundTypeProps.value,
                    );

                    if (isFoundUsedInvestmentRound.length) {
                      const dates = isFoundUsedInvestmentRound.map((el) =>
                        dateFormatter(el.date, DateFormat.FULL_MONTH_LONG_YEAR),
                      );
                      if (dates.includes(dateFormatter(curr, DateFormat.LETTER_MONTH_LONG_YEAR))) {
                        return true;
                      }
                    }

                    return false;
                  }}
                />
              </OptionalTooltip>
            </S.DateField>
          </>
        )}
      </S.FormikRow>
      {(roundIdProps.value || (roundDateProps.value && roundTypeProps.value)) && (
        <>
          {role === Roles.INVESTOR && (
            <S.FormikRow>
              <HalfWidthField
                label={{
                  for: preMoneyValKey,
                  label: <FormikLabel>{preMoneyLabel}</FormikLabel>,
                }}
                {...getFieldMeta(preMoneyValKey)}
              >
                <NumberInput
                  data-testid="pre-money-input"
                  {...getFieldProps(preMoneyValKey)}
                  onChange={(e) => onChange(e.target.value, true)(preMoneyValKey)}
                />
              </HalfWidthField>
              {!internalEntityId && (
                <HalfWidthField
                  label={{
                    for: postMoneyValKey,
                    label: <FormikLabel>{postMoneyLabel}</FormikLabel>,
                  }}
                  {...getFieldMeta(postMoneyValKey)}
                >
                  <NumberInput
                    data-testid="post-money-input"
                    {...getFieldProps(postMoneyValKey)}
                    onChange={(e) => onChange(e.target.value, true)(postMoneyValKey)}
                  />
                </HalfWidthField>
              )}
            </S.FormikRow>
          )}
          <S.FormikRow>
            <HalfWidthField
              label={{
                for: amountKey,
                label: (
                  <FormikLabel>
                    {amountLabel} <S.Asterisk>*</S.Asterisk>
                  </FormikLabel>
                ),
              }}
              {...getFieldMeta(amountKey)}
            >
              <NumberInput
                data-testid="amount-input"
                {...getFieldProps(amountKey)}
                onChange={(e) => onChange(e.target.value, true)(amountKey)}
              />
            </HalfWidthField>
            <QuarterWidthField
              label={{
                for: sharesKey,
                label: <FormikLabel>{sharesLabel}</FormikLabel>,
              }}
              {...getFieldMeta(sharesKey)}
            >
              <NumberInput
                data-testid="shares-input"
                {...getFieldProps(sharesKey)}
                onChange={(e) => onChange(e.target.value, true)(sharesKey)}
              />
            </QuarterWidthField>
            <QuarterWidthField
              label={{
                for: ownedKey,
                label: <FormikLabel>{ownedLabel}</FormikLabel>,
              }}
              {...getFieldMeta(ownedKey)}
            >
              <NumberInput
                data-testid="owned-input"
                {...getFieldProps(ownedKey)}
                onChange={(e) => onChange(e.target.value, true)(ownedKey)}
              />
            </QuarterWidthField>
          </S.FormikRow>

          {(() => {
            if (advancedShown) {
              return <AdditionalInfo role={role} onChange={onChange} index={index} {...commonProps} />;
            }

            return (
              <Add type="link" onClick={() => setAdvancedShown(true)} data-testid="show-more-advanced">
                {addLabel}
              </Add>
            );
          })()}
        </>
      )}
    </S.Wrapper>
  );
};

import EmptyBrowsing from '@assets/icons/undraw-empty.svg';
import {
  getInvestorBrowseCompanies,
  GET_INVESTOR_BROWSE_COMPANIES_CACHE_KEY,
  type InvestorBrowseCompaniesItems,
} from '@pages/content/lens/api/get-investor-browse-companies';
import {
  getInvestorBrowseDefaults,
  GET_INVESTOR_BROWSE_DEFAULTS_CACHE_KEY,
} from '@pages/content/lens/api/get-investor-browse-defaults.actions';
import { MatchedFounder } from '@pages/content/lens/parts/cards/matched-founder';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { DEFAULT_ITEMS_PER_PAGE_IN_GRID, ItemsGrid } from '@parts/items-grid/items-grid';
import { useQuery } from '@tanstack/react-query';
import { useQueryPagination } from '@utils/hooks/use-query-pagination/use-query-pagination';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Filters, type FilterOptions } from '../filters/filters';
import { useParseAndSearch } from '../filters/hooks/use-parse-and-search';
import type { MetricOptions } from '@pages/content/lens/api/get-browse-companies-filters';

export const BrowsedMatchesGrid = ({
  userSavedFilters,
  availableMetrics,
}: {
  userSavedFilters: FilterOptions;
  availableMetrics: MetricOptions[] | undefined;
}) => {
  const [emptyTitle, emptySubTitle] = useTranslation([
    'lens.investor.emptyMatched.title',
    'lens.investor.emptyMatched.subTitle',
  ]);
  const parseAndSearch = useParseAndSearch();

  const { data: response, isLoading: defaultsLoading } = useQuery(
    [GET_INVESTOR_BROWSE_DEFAULTS_CACHE_KEY],
    getInvestorBrowseDefaults,
    { refetchOnWindowFocus: false },
  );

  const { items, total, isLoading, refetch, querying, ...paginationConfig } =
    useQueryPagination<InvestorBrowseCompaniesItems>({
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_IN_GRID,
      pageParam: 'page',
      action: getInvestorBrowseCompanies,
      cacheKey: GET_INVESTOR_BROWSE_COMPANIES_CACHE_KEY,
      queryConfig: { refetchOnWindowFocus: false },
    });

  if (!response || !response.data || defaultsLoading) {
    return <FullHeightSpinner />;
  }
  return (
    <>
      <Filters
        onSubmit={parseAndSearch}
        availableMetrics={availableMetrics}
        initialFilterOptions={userSavedFilters}
        smartMatchOptions={{
          skillSets: response.data.skillSets,
          productStages: response.data.productStages,
          investmentStages: response.data.investmentStages,
          customerGroups: response.data.customerGroups,
          industries: response.data.industries,
        }}
      />

      <ItemsGrid
        data={items}
        keyBase="browse-companies"
        isLoading={isLoading}
        Item={MatchedFounder}
        pagination={paginationConfig}
        itemProps={{ refetchSmartMatch: refetch }}
        emptyConfig={{
          isEmpty: items.length === 0,
          title: emptyTitle,
          subTitle: emptySubTitle,
          iconUrl: EmptyBrowsing,
        }}
      />
    </>
  );
};

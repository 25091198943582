import { Dropdown as DropdownBase, Menu as MenuBase, Tabs as TabsBase } from 'antd';
import styled, { css } from 'styled-components';

const Menu = styled(MenuBase)`
  .ant-dropdown-menu-item {
    line-height: 42px;
  }
`;

const tabStyles = css`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.fontSize.xmedium};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding-bottom: 3px;
`;

const MobileSelectedTab = styled.span`
  ${tabStyles}

  display: inline-block;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  margin-left: ${({ theme }) => theme.spacing.xmedium};
  line-height: 32px;
  border-bottom: 4px solid ${({ theme }) => theme.color.secondary};
  position: relative;

  svg {
    position: absolute;
    left: auto;
    right: -20px;
    top: 13px;
    bottom: auto;
    margin: auto;
  }
`;

const MobileDropdown = styled(DropdownBase)`
  background: ${({ theme }) => theme.color.background};

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    display: none;
  }
`;

const Tabs = styled(TabsBase)`
  .ant-tabs-tab-btn {
    &[aria-disabled='true'] {
      color: ${({ theme }) => theme.color.fontPrimary}!important;
      opacity: 0.5;
    }
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    .ant-tabs-nav {
      display: none;
    }
  }

  .ant-tabs-nav {
    margin-bottom: ${({ theme }) => theme.spacing.xbase};

    &:before {
      border-bottom-color: ${({ theme }) => theme.color.sectionBackground};
    }
  }

  .ant-tabs-tab,
  .ant-tabs-tab .ant-tabs-tab-btn {
    ${tabStyles}
  }

  .ant-tabs-ink-bar {
    background: ${({ theme }) => theme.color.secondary};
    height: 4px !important;
  }
`;

export default {
  Tabs,
  Menu,
  MobileSelectedTab,
  MobileDropdown,
};

import { V2TitleGroup } from '@/components/ui/v2-title-group/v2-title-group';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import S from './success-banner.styles';

export const SuccessBanner = () => {
  const [title, subTitle] = useTranslation(['metrics.codat.success.title', 'metrics.codat.success.subTitle']);

  return (
    <S.Wrapper>
      <V2TitleGroup icon="check" title={title} subtitle={subTitle} align="center" maxWidth={640} />
    </S.Wrapper>
  );
};

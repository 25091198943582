import { CountryPhoneNumber } from '@parts/country-phone-number/country-phone-number';
import { isAppEnv } from '@utils/fns/is-app-env';
import { ONLY_NUMBERS_AND_DASHES_REGEXP } from '@utils/fns/reg-exp-validators';
import { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import { Input } from 'antd';
import { defaultAreas, type CountryPhoneInputValue } from 'antd-country-phone-input';
import type { FormikProps } from 'formik';
import { useState } from 'react';
import FormikFieldPhoneNumber from '../formik-field-phone-number/formik-field-phone-number';

interface ProfilePhoneNumberProps<T> {
  formik: FormikProps<T>;
  field: keyof T;
  label: string | JSX.Element;
  id?: string;
  testId?: string;
  countryOfResidence?: CountryCodes | null;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const ProfilePhoneNumber = <T,>({
  formik,
  field,
  label,
  id,
  testId,
  countryOfResidence = null,
}: ProfilePhoneNumberProps<T>) => {
  const fieldName = field as string;

  type PhoneNumberInfo = Partial<{
    code: number;
    phone: string;
    short: string;
  }>;

  const extractPhoneNumberInfo = (): PhoneNumberInfo => {
    const value = formik.values[field];

    const result: PhoneNumberInfo = {
      code: undefined,
      phone: undefined,
      short: undefined,
    };

    if (typeof value === 'string') {
      const [code, phone] = value.split(' ');

      if (code && phone) {
        result.code = isNaN(Number(value)) ? undefined : Number(value);
        result.phone = phone;
      }
    }

    if (Number.isInteger(result.code) && countryOfResidence) {
      const matchingAreas = defaultAreas.filter((a) => a.phoneCode === result.code);
      const isCountryOfResidenceMatched = Boolean(matchingAreas.find((a) => a.short === countryOfResidence));

      result.short = isCountryOfResidenceMatched ? countryOfResidence : undefined;
    }

    if (!Number.isInteger(result.code)) result.short = countryOfResidence ?? CountryCodes.GB;

    return result;
  };

  const { code: defaultCode, phone: defaultPhone, short: defaultShort } = extractPhoneNumberInfo();

  const [phoneNumberValue, setPhoneNumberValue] = useState<CountryPhoneInputValue>({
    code: defaultCode,
    phone: defaultPhone,
    short: defaultShort,
  });

  const handlePhoneNumberChange = (inputValue: CountryPhoneInputValue) => {
    const { code, phone, short } = inputValue;
    if (ONLY_NUMBERS_AND_DASHES_REGEXP.test(inputValue.phone!)) {
      formik.setFieldTouched(fieldName, true);
      formik.setFieldValue(fieldName, phone || code ? `${code || ''} ${phone || ''}` : null);
      setPhoneNumberValue({ code, phone, short });
    }
  };

  return (
    <FormikFieldPhoneNumber
      label={{
        for: fieldName,
        label,
      }}
      touched={formik.touched[field] as boolean}
      error={formik.errors[field] as string}
    >
      {!isAppEnv('test') && <CountryPhoneNumber value={phoneNumberValue} onChange={handlePhoneNumberChange} />}

      <Input {...formik.getFieldProps(fieldName)} id={id} data-testid={testId} type="hidden" />
    </FormikFieldPhoneNumber>
  );
};

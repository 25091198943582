import { V2Notes } from '@/components/blocks/v2-notes/v2-notes';
import { V2Collapsible } from '@/components/ui/v2-collapsible/v2-collapsible';
import { V2Loader } from '@/components/ui/v2-loader/v2-loader';
import { useMediaQuery } from '@/hooks/use-media-query';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { DateFormat } from '@i18n/date-formats';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { useGetConnectionNotes } from '@modules/founder/connections/hooks/use-connection-notes';
import { V2Carousel } from '@/components/blocks/v2-carousel/v2-carousel';

type TTableRowCollapsibleProps = {
  isVisible: boolean;
  targetId: string;
  onUpdated?: () => void | Promise<void>;
};

export const TableRowCollapsible = ({ isVisible, targetId, onUpdated }: TTableRowCollapsibleProps) => {
  const mediaQuery = useMediaQuery();
  const isMobile = ['xs', 'sm'].includes(mediaQuery);
  const [isNewNoteVisible, setIsNewNoteVisible] = useState(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const { dateFormatter } = useUserDateFormat();
  const { connectionNotesData, isConnectionNotesDataLoading, refetchConnectionNotes } = useGetConnectionNotes({
    targetAccountId: targetId,
    isVisible,
  });
  const reversedNotes = useMemo(() => connectionNotesData?.data?.reverse(), [connectionNotesData?.data]);
  const [addButtonTranslation] = useTranslation(['connections.founder.table.notes.addButton']);
  const isDefaultState = reversedNotes?.length === 0;

  useEffect(() => {
    if (isDefaultState) {
      setSelectedId('new');
    }
  }, [isDefaultState]);

  const handleAddNewNote = () => {
    setIsNewNoteVisible(true);
    setSelectedId('new');
  };

  return (
    <V2Collapsible visible={isVisible} style={{ width: '100%', paddingRight: 20 }}>
      {isConnectionNotesDataLoading ? (
        <V2Loader />
      ) : (
        <V2Notes
          action={
            !isMobile ? (
              <V2Notes.TextButton
                startIcon="plus"
                variant="text"
                onClick={handleAddNewNote}
                disabled={isDefaultState}
                data-testid="connections-add-new-note-button"
              >
                {addButtonTranslation}
              </V2Notes.TextButton>
            ) : null
          }
          carousel={
            <V2Carousel>
              <V2Carousel.PrevIconButton />
              <V2Carousel.ItemsWrapper>
                {isMobile && (
                  <V2Carousel.Slide key="button">
                    <V2Notes.ButtonWrapper>
                      <V2Notes.ButtonWrapper>
                        <V2Notes.TextButton
                          onClick={handleAddNewNote}
                          startIcon="plus"
                          variant="text"
                          disabled={isDefaultState}
                        >
                          {addButtonTranslation}
                        </V2Notes.TextButton>
                      </V2Notes.ButtonWrapper>
                    </V2Notes.ButtonWrapper>
                  </V2Carousel.Slide>
                )}
                {(isNewNoteVisible || isDefaultState) && (
                  <V2Carousel.Slide key="new">
                    <V2Notes.TileWrapper
                      data-testid="connections-note-new"
                      key="new"
                      noteId="new"
                      size="lg"
                      isDefaultState={isDefaultState}
                      targetId={targetId}
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      setIsNewNoteVisible={setIsNewNoteVisible}
                      refetchConnectionNotes={refetchConnectionNotes}
                      onUpdated={onUpdated}
                      noteContent={{
                        title: '',
                        date: dateFormatter(DateTime.now(), DateFormat.FULL_DATE_DASHED),
                        content: '',
                      }}
                    />
                  </V2Carousel.Slide>
                )}
                {reversedNotes?.map((note) => (
                  <V2Carousel.Slide key={note.id}>
                    <V2Notes.TileWrapper
                      data-testid={`connections-note-${note.id}`}
                      key={note.id}
                      isDefaultState={isDefaultState}
                      noteId={note.id}
                      targetId={targetId}
                      size="lg"
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      setIsNewNoteVisible={setIsNewNoteVisible}
                      refetchConnectionNotes={refetchConnectionNotes}
                      onUpdated={onUpdated}
                      noteContent={{
                        title: note.title,
                        date: dateFormatter(note.createdAt, DateFormat.FULL_DATE_DASHED),
                        content: note.content,
                      }}
                    />
                  </V2Carousel.Slide>
                ))}
              </V2Carousel.ItemsWrapper>
              <V2Carousel.NextIconButton />
            </V2Carousel>
          }
        />
      )}
    </V2Collapsible>
  );
};

import { DateFormat } from '@i18n/date-formats';
import {
  GET_INVESTMENT_TERMS_CACHE_KEY,
  getInvestmentTermsAction,
} from '@pages/content/portfolio/investor/api/get-investment-terms/get-investment-terms.action';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import LabelRow from '@parts/label-row/label-row';
import { useSimpleModal } from '@parts/modal/use-simple-modal';
import { useQuery } from '@tanstack/react-query';
import { currencyToShortFormat } from '@utils/fns/currency-to-short-format';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { useTranslation } from '../use-translation/use-translation';
import S from './use-show-terms.styles';

const labelWidth = 20;
const useShowTerms = (investmentId: string) => {
  const [
    termsLabel,
    companyLabel,
    dateLabel,
    amountLabel,
    warrantsLabel,
    priceLabel,
    expiryLabel,
    optionsLabel,
    clnLabel,
    discountLabel,
  ] = useTranslation([
    'portfolio.investor.drawer.additionalInfo.terms',
    'portfolio.investor.showTerms.company',
    'portfolio.investor.drawer.details.date',
    'investment.confirmation.amount',
    'investment.confirmation.warrants',
    'investment.confirmation.price',
    'investment.confirmation.expiry',
    'investment.confirmation.options',
    'investment.confirmation.cln',
    'investment.confirmation.discount',
  ]);

  const { Modal, open, show, hide } = useSimpleModal();

  const { dateFormatter } = useUserDateFormat();

  const {
    data: dataResponse,
    isLoading,
    refetch,
  } = useQuery([GET_INVESTMENT_TERMS_CACHE_KEY(investmentId)], getInvestmentTermsAction(investmentId), {
    enabled: false,
  });
  const terms = isLoading ? null : dataResponse?.data?.terms;

  const openModal = () => {
    show();
    refetch();
  };

  const modal = (
    <Modal visible={open} title={termsLabel} footer={null} data-testid="use-show-terms-modal" onCancel={hide}>
      {!terms ? (
        <FullHeightSpinner small />
      ) : (
        <>
          <LabelRow dataTestId="terms-company-name" label={companyLabel} labelWidth={labelWidth}>
            {dataResponse?.data?.company.name}
          </LabelRow>
          {dataResponse?.data?.round.date && (
            <LabelRow dataTestId="terms-round-date" label={dateLabel} labelWidth={labelWidth}>
              {dateFormatter(dataResponse?.data?.round.date, DateFormat.LETTER_MONTH_LONG_YEAR)}
            </LabelRow>
          )}
          {dataResponse?.data?.round.type && (
            <LabelRow dataTestId="terms-round-type" label="Round" labelWidth={labelWidth}>
              {dataResponse?.data?.round.type}
            </LabelRow>
          )}
          {(terms.warrants.amount || terms.warrants.price || terms.warrants.expiry) && (
            <>
              <S.HR />
              <LabelRow
                dataTestId="terms-warrants-details"
                spacedChildren
                label={warrantsLabel}
                labelWidth={labelWidth}
              >
                {terms.warrants.amount && (
                  <>
                    {amountLabel}: {terms.warrants.amount}
                  </>
                )}
                {terms.warrants.price && (
                  <>
                    {priceLabel}: £{currencyToShortFormat(terms.warrants.price.toString())}
                  </>
                )}
                {terms.warrants.expiry && (
                  <>
                    {expiryLabel}: {dateFormatter(terms.warrants.expiry, DateFormat.LETTER_MONTH_LONG_YEAR)}
                  </>
                )}
              </LabelRow>
            </>
          )}
          {terms.options && (
            <LabelRow dataTestId="terms-options" label={optionsLabel} labelWidth={labelWidth}>
              {terms.options}
            </LabelRow>
          )}
          {terms.convertibleLoanNote && (
            <LabelRow dataTestId="terms-loan-note" label={clnLabel} labelWidth={labelWidth}>
              {terms.convertibleLoanNote}
            </LabelRow>
          )}
          {terms.discount && (
            <LabelRow dataTestId="terms-discount" label={discountLabel} labelWidth={labelWidth}>
              {terms.discount}%
            </LabelRow>
          )}
        </>
      )}
    </Modal>
  );

  return {
    show: openModal,
    hide: hide,
    modal: modal,
  };
};

export default useShowTerms;

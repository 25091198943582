import type { V2AlertProps, V2AlertVariant } from './v2-alert.types';

import type { V2IconName } from '../v2-icon/icons';
import { V2Icon } from '../v2-icon/v2-icon';
import S from './v2-alert.styles';

const variantIconMap: Record<V2AlertVariant, V2IconName> = {
  success: 'check',
  info: 'info-circle',
  warning: 'info-circle',
  error: 'close',
};

export const V2Alert = ({
  title = '',
  closable = false,
  description,
  variant = 'info',
  onClose = () => {},
  showIcon,
  customIcon = undefined,
  className = '',
  style = {},
  'data-testid': dataTestId = 'v2-alert',
}: V2AlertProps) => {
  return (
    <S.V2Alert
      message={title}
      description={description}
      type={variant}
      closable={closable}
      onClose={onClose}
      showIcon={showIcon}
      icon={<V2Icon name={customIcon ?? variantIconMap[variant]} size="lg" />}
      className={className}
      style={style}
      data-testid={dataTestId}
    />
  );
};

import { contactHelpConnectdEmail } from '@constants/emails';
import { metricsIntegrationsLink } from '@constants/links';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './banner.styles';

export const Banner = ({ buttonUrl }: { buttonUrl: string }) => {
  const [
    manageIntegrationsLabel,
    addIntegrationLabel,
    removeIntegrationLabel,
    descriptionFirstLabel,
    linkFirstLabel,
    descriptionSecondLabel,
    linkSecondLabel,
  ] = useTranslation([
    'metrics.manage.integrations',
    'metrics.codat.addIntegration',
    'metrics.manage.integrations.remove',
    'metrics.manage.integration.description.first',
    'metrics.manage.integration.description.link.first',
    'metrics.manage.integration.description.second',
    'metrics.manage.integration.description.link.second',
  ]);

  return (
    <S.Banner data-testid="integrations-banner">
      <S.Content>
        <S.Title>{manageIntegrationsLabel}</S.Title>
        <S.Description>
          <span>{removeIntegrationLabel}</span>
          <span>
            {descriptionFirstLabel}
            <S.Link href={metricsIntegrationsLink} outer>
              {linkFirstLabel}
            </S.Link>
            {descriptionSecondLabel}
            <S.Link href={`mailto:${contactHelpConnectdEmail}`} outer>
              {linkSecondLabel}
            </S.Link>
          </span>
        </S.Description>
      </S.Content>
      <S.Link href={buttonUrl} outer>
        <S.Button>{addIntegrationLabel}</S.Button>
      </S.Link>
    </S.Banner>
  );
};

import DetailsArrow from '@assets/icons/details-arrow.svg?react';
import styled from 'styled-components';

const Arrow = styled(DetailsArrow)``;

const Expand = styled.div`
  color: ${({ theme }) => theme.baseColors.Blue200};
  white-space: nowrap;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export default { Expand, Arrow };

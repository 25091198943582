import { Textarea } from '@pages/content/profile/parts/textarea/textarea';
import { FullWidthField } from '@parts/forms/formik-full-field/formik-full-field';
import { FormikLabel } from '@parts/forms/formik-label/formik-label';
import { QuarterWidthField } from '@parts/forms/formik-quarter-field/formik-quarter-field';
import { FormikRow } from '@parts/forms/formik-row/formik-row';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import message from '@parts/message/message';
import { Section } from '@parts/section/section';
import { Select } from '@parts/select/select';
import { useMutation, useQuery } from '@tanstack/react-query';
import useDelayedSubmit from '@utils/hooks/use-delayed-submit/use-delayed-submit';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
  ADDITIONAL_INFORMATION_CACHE_KEY,
  getAdditionalInfoAction,
} from '../../api/additional-info/get-company-additional-info.action';
import {
  getSourceOfInvestmentsOptionsAction,
  SOURCE_OF_INVESTMENTS_CACHE_KEY,
} from '../../api/additional-info/get-source-of-investments-options.action';
import {
  setSourceAndNoteAction,
  type SourceAndNotePayload,
} from '../../api/additional-info/set-source-and-note.action';

export const AdditionalInfo = () => {
  const [additionalInfoLabel, investmentSourceLabel, investmentNoteLabel, investmentNoteLengthExceeded, savedLabel] =
    useTranslation([
      'portfolio.investor.investments.additionalInformation',
      'portfolio.investor.investments.investmentSource',
      'portfolio.investor.investments.investmentNote',
      'portfolio.investor.investments.investmentNote.noteLengthExceeded',
      'global.drawer.saved',
    ]);

  const { relationshipId } = useParams<{ relationshipId: string }>();

  const { mutateAsync: setSourceAndNote } = useMutation(setSourceAndNoteAction(relationshipId), {
    onSuccess: () => {
      message.success({ content: savedLabel });
    },
  });

  const { data: investmentSourceOptions } = useQuery(
    [SOURCE_OF_INVESTMENTS_CACHE_KEY],
    getSourceOfInvestmentsOptionsAction(),
  );

  const { data: additionalInfo, isLoading } = useQuery(
    [ADDITIONAL_INFORMATION_CACHE_KEY],
    getAdditionalInfoAction(relationshipId),
  );

  const { getFieldProps, setFieldValue, submitForm, handleChange, touched, errors, values } =
    useFormik<SourceAndNotePayload>({
      onSubmit: (v) => {
        setSourceAndNote({
          investmentSource: v.investmentSource || null,
          note: v.note || null,
        });
      },
      initialValues: {
        investmentSource: additionalInfo?.data?.investmentSource || '',
        note: additionalInfo?.data?.investmentNote || '',
      },
      validationSchema: Yup.object().shape({
        investmentSource: Yup.string().nullable(),
        note: Yup.string().nullable().max(500, investmentNoteLengthExceeded),
      }),
      enableReinitialize: true,
    });

  const { onChange } = useDelayedSubmit(handleChange, submitForm);

  return (
    <Section header={additionalInfoLabel}>
      {!isLoading ? (
        <form>
          <FormikRow>
            <QuarterWidthField
              label={{
                for: 'investmentSource',
                label: <FormikLabel>{investmentSourceLabel}</FormikLabel>,
              }}
              touched={touched.investmentSource}
              error={errors.investmentSource}
            >
              <Select
                {...getFieldProps('investmentSource')}
                onChange={(v) => {
                  setFieldValue('investmentSource', v);
                  submitForm();
                }}
                value={values.investmentSource || undefined}
              >
                <Select.Option key="empty" value="">
                  {' '}
                </Select.Option>
                {investmentSourceOptions?.data?.map((v) => (
                  <Select.Option key={v} value={v}>
                    {v}
                  </Select.Option>
                ))}
              </Select>
            </QuarterWidthField>
          </FormikRow>

          <FormikRow>
            <FullWidthField
              label={{
                for: 'note',
                label: <FormikLabel>{investmentNoteLabel}</FormikLabel>,
              }}
              touched={touched.note}
              error={errors.note}
            >
              <Textarea {...getFieldProps('note')} onChange={onChange} rows={6} value={values.note || undefined} />
            </FullWidthField>
          </FormikRow>
        </form>
      ) : (
        <FullHeightSpinner small />
      )}
    </Section>
  );
};

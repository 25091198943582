import S from './trash-button.styles';

interface ITrashButtonProps {
  onRemove: () => void;
  disabled?: boolean;
}

export const TrashButton = ({ onRemove, disabled }: ITrashButtonProps) => (
  <S.Button type="link" onClick={onRemove} data-testid="trash-button" disabled={Boolean(disabled)}>
    <S.TrashIcon />
  </S.Button>
);

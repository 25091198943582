import { useEffect, useRef } from 'react';
import { useHistory } from '../use-history/use-history';
import { useQueryParams } from '../use-query/use-query-params';

export const SCROLL_TO_TARGET_PARAM = 'st';
const HEADER_HEIGHT_OFFSET = 80;

const scrollIntoViewWithOffset = (element: HTMLElement, offset: number) => {
  window.scrollTo({
    behavior: 'smooth',
    top: element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
  });
};

export const useScrollToElementWithQueryParams = <T>(elementKey: string, clearParam: boolean = true) => {
  const ref = useRef<T>(null);
  const history = useHistory();
  const queryParams = useQueryParams<{ [SCROLL_TO_TARGET_PARAM]?: string }>();
  const targetElementParam = queryParams[SCROLL_TO_TARGET_PARAM];

  const removeQueryParam = () => {
    const newParams = { ...queryParams };
    delete newParams[SCROLL_TO_TARGET_PARAM];
    history.replace({ search: new URLSearchParams(newParams).toString() });
  };

  useEffect(() => {
    if (elementKey !== targetElementParam || !ref.current) return;

    scrollIntoViewWithOffset(ref.current as unknown as HTMLElement, HEADER_HEIGHT_OFFSET);

    if (clearParam) removeQueryParam();
  }, [queryParams]);

  return ref;
};

import styled, { css } from 'styled-components';
import { Rate as RateBase } from 'antd';

const Rate = styled(RateBase)<{ small?: boolean }>`
  .ant-rate-star {
    ${({ small, theme }) =>
      small &&
      css`
        font-size: ${theme.fontSize.small};
      `}
  }

  .ant-rate-star:not(:last-child) {
    margin-right: 6px;
  }

  .ant-rate-star:not(.ant-rate-star-full) {
    svg {
      fill: ${({ theme }) => theme.baseColors.Blue300} !important;
    }
  }
`;

export default { Rate };

import { Select } from '@parts/select/select';
import {
  BooleanComparator,
  Comparator,
  DateComparator,
  FilterKinds,
  NumberComparator,
  SortDirections,
  TextComparator,
  type FilteringRules,
  type SortingRules,
} from '@utils/hooks/use-filtering/use-filtering';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useState, type ReactNode } from 'react';
import S from './filter.styles';
import { BooleanByCondition } from './parts/boolean-by-condition/boolean-by-condition';
import { Collapse } from './parts/collapse/collapse';
import { DateByCondition } from './parts/date-by-condition/date-by-condition';
import { NumberByCondition } from './parts/number-by-condtion/number-by-condition';
import { TextByCondition } from './parts/text-by-condition/text-by-condition';
import { TextByValues } from './parts/text-by-values/text-by-values';

export interface SubFilterProps {
  getFieldProps: Function;
  getFieldMeta: Function;
  setFieldValue: Function;
  dataKey: string;
  keys: { [key: string]: string };
  options: ReactNode[];
}

export const Filter = ({
  dataKey,
  getFieldProps,
  getFieldMeta,
  sortingRules,
  filteringRules,
  setFieldValue,
  keys,
  formatters,
}: {
  dataKey: string;
  getFieldProps: Function;
  getFieldMeta: Function;
  sortingRules: SortingRules;
  filteringRules: FilteringRules;
  setFieldValue: Function;
  keys: {
    [key: string]: string;
  };
  formatters?: {
    byValue?: (value: string) => string;
  };
}) => {
  const [
    betweenLabel,
    notBetweenLabel,
    greaterThanLabel,
    greaterThanOrEqualLabel,
    lessThanLabel,
    lessThanOrEqualLabel,
    emptyLabel,
    notEmptyLabel,
    containsLabel,
    doesNotContainLabel,
    startsWithLabel,
    endsWithLabel,
    isExactlyLabel,
    equalLabel,
    notEqualLabel,
    isBeforeLabel,
    isAfterLabel,
    firstLastLabel,
    lastFirstLabel,
    byConditionLabel,
    textByValuesLabel,
  ] = useTranslation([
    'filters.between',
    'filters.notBetween',
    'filters.greaterThan',
    'filters.greaterThanOrEqual',
    'filters.lessThan',
    'filters.lessThanOrEqual',
    'filters.empty',
    'filters.notEmpty',
    'filters.contains',
    'filters.doesNotContain',
    'filters.startsWith',
    'filters.endsWith',
    'filters.isExactly',
    'filters.equal',
    'filters.notEqual',
    'filters.isBefore',
    'filters.isAfter',
    'filters.firstLast',
    'filters.lastFirst',
    'filters.byCondition',
    'filters.byValue',
  ]);

  const [textByValuesOpen, setTextByValuesOpen] = useState(false);
  const [byConditionOpen, setByConditionOpen] = useState(false);

  const sortable = sortingRules.includes(dataKey);
  const filterRule = filteringRules.find((f) => f.key === dataKey);

  const filterableByValues =
    filterRule &&
    filteringRules.find(
      (f) =>
        f.key === dataKey &&
        f.availableValues &&
        f.availableValues.length > 0 &&
        (f.allowedComparators?.includes(TextComparator.ListOfValues) || f.allowedComparators === null),
    );

  const narrowedFilterByConditionOptions =
    filterRule &&
    filterRule.allowedComparators &&
    filterRule.allowedComparators.filter((c) => c !== TextComparator.ListOfValues);

  const filterableByCondition =
    (narrowedFilterByConditionOptions && narrowedFilterByConditionOptions.length > 0) ||
    filterRule?.allowedComparators === null;

  const conditionLabels: { [key: string]: string } = {
    [Comparator.Empty]: emptyLabel,
    [Comparator.NotEmpty]: notEmptyLabel,
    [TextComparator.Contains]: containsLabel,
    [TextComparator.DoesNotContain]: doesNotContainLabel,
    [TextComparator.StartsWith]: startsWithLabel,
    [TextComparator.EndsWith]: endsWithLabel,
    [TextComparator.IsExactly]: isExactlyLabel,
    [NumberComparator.Equal]: equalLabel,
    [NumberComparator.NotEqual]: notEqualLabel,
    [NumberComparator.Between]: betweenLabel,
    [NumberComparator.NotBetween]: notBetweenLabel,
    [NumberComparator.GreaterThan]: greaterThanLabel,
    [NumberComparator.GreaterThanOrEqual]: greaterThanOrEqualLabel,
    [NumberComparator.LessThan]: lessThanLabel,
    [NumberComparator.LessThanOrEqual]: lessThanOrEqualLabel,
    [DateComparator.IsBefore]: isBeforeLabel,
    [DateComparator.IsAfter]: isAfterLabel,
  };

  const conditionOptions = (() => {
    if (filterRule && filterRule.allowedComparators === null) {
      switch (filterRule.type) {
        case FilterKinds.Date:
          return Object.values(DateComparator);
        case FilterKinds.Number:
          return [...Object.values(Comparator), ...Object.values(NumberComparator)];
        case FilterKinds.String:
          return [
            ...Object.values(TextComparator).filter((c) => c !== TextComparator.ListOfValues),
            ...Object.values(Comparator),
          ];
        case FilterKinds.Boolean:
          return Object.values(BooleanComparator);
        default:
          throw new Error();
      }
    }

    if (narrowedFilterByConditionOptions) return narrowedFilterByConditionOptions;

    return [];
  })().map((v) => (
    <Select.Option value={v} key={v}>
      {conditionLabels[v]}
    </Select.Option>
  ));

  const commonProps = {
    options: conditionOptions,
    dataKey,
    getFieldProps,
    getFieldMeta,
    setFieldValue,
  };

  const setSort = (dir: SortDirections) => setFieldValue('sort', { key: dataKey, order: dir });

  const sortProps = getFieldProps('sort');
  const currentSort = sortProps && sortProps.value && sortProps.value.order;

  return (
    <>
      <S.Section>
        {sortable && (
          <S.Order>
            <S.Direction active={currentSort === SortDirections.Asc} onClick={() => setSort(SortDirections.Asc)}>
              {firstLastLabel}
            </S.Direction>
            <S.Direction active={currentSort === SortDirections.Desc} onClick={() => setSort(SortDirections.Desc)}>
              {lastFirstLabel}
            </S.Direction>
          </S.Order>
        )}
      </S.Section>

      {filterRule && filterableByCondition && (
        <S.Section>
          <S.Filter
            active={byConditionOpen}
            onClick={() => {
              setByConditionOpen((o) => !o);
              setTextByValuesOpen(false);
            }}
          >
            <Collapse open={byConditionOpen} label={byConditionLabel} />
          </S.Filter>
          {byConditionOpen &&
            (() => {
              if (filterRule?.type === FilterKinds.String) {
                return (
                  <TextByCondition
                    {...commonProps}
                    keys={{
                      comparator: keys.textByConditionComparator,
                      value: keys.textByConditionValue,
                      key: keys.textByConditionKey,
                    }}
                  />
                );
              }

              if (filterRule?.type === FilterKinds.Number) {
                return (
                  <NumberByCondition
                    {...commonProps}
                    keys={{
                      range: keys.numberRange,
                      comparator: keys.numberComparator,
                      value: keys.numberValue,
                      key: keys.numberKey,
                    }}
                  />
                );
              }

              if (filterRule?.type === FilterKinds.Boolean) {
                return <BooleanByCondition {...commonProps} keys={{ value: keys.boolean }} />;
              }

              if (filterRule?.type === FilterKinds.Date) {
                return (
                  <DateByCondition
                    {...commonProps}
                    keys={{
                      comparator: keys.dateComparator,
                      value: keys.dateValue,
                      key: keys.dateKey,
                    }}
                  />
                );
              }

              return null;
            })()}
        </S.Section>
      )}

      {filterRule && filterableByValues && (
        <S.Section>
          <S.Filter
            active={textByValuesOpen}
            onClick={() => {
              setTextByValuesOpen((o) => !o);
              setByConditionOpen(false);
            }}
          >
            <Collapse open={textByValuesOpen} label={textByValuesLabel} />
          </S.Filter>
          {textByValuesOpen && (
            <TextByValues
              {...commonProps}
              keys={{ values: keys.textByValues }}
              formatter={formatters?.byValue}
              options={filterRule.availableValues}
            />
          )}
        </S.Section>
      )}
    </>
  );
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_FUNDING_HISTORY_CACHE_KEY = (id: string) => `get-funding-history-${id}`;

export interface FundingHistoryProps {
  id: string;
  amountRaised: number;
  amountSought: number;
  date: string;
  status: string;
  type: string;
  useOfFunds: string | null;
  valuation: number;
}
export const getFundingHistory =
  (investingRelationshipId: string) => async (): Promise<GraphqlResponse<FundingHistoryProps[]>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
      query getFundingHistory {
        companyFundingHistory(investingRelationshipId: "${investingRelationshipId}") {
            id
            amountRaised
            amountSought
            date
            status
            type
            useOfFunds
            valuation
        }
      }
  `,
    });

    return graphqlResponseWrapper(data, ['companyFundingHistory']);
  };

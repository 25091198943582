import type { NedBrowseCompaniesItems } from '@pages/content/lens/api/get-ned-browse-companies';
import { sendRequestIntroAction } from '@pages/content/lens/api/send-intro-request-to-admin';
import { checkIfValidWysiWygValue } from '@pages/content/profile/hooks/use-wysiwyg';
import { founderIdQueryParam } from '@pages/content/public-profile/founder/founder.page';
import { CompanyTile } from '@parts/company-tile/company-tile';
import { GridItem } from '@parts/grid-item/grid-item';
import metaConfig from '@parts/meta-config/meta-config';
import { Links } from '@router/links';
import { SanitizeWysiwyg } from '@utils/fns/sanitize-wysiwyg';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { RequestIntro } from '../request-intro/request-intro';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { DateFormat } from '@i18n/date-formats';
interface BrowsedFounderByNedProps extends NedBrowseCompaniesItems {
  refetchBrowseFounders: () => void;
}
export const BrowsedFounderByNed = ({
  id,
  customerGroup,
  description,
  industry,
  investmentStage,
  joinedAt,
  name,
  productStage,
  logoUrl,
  requestIntroId,
  refetchBrowseFounders,
}: BrowsedFounderByNedProps) => {
  const [joined] = useTranslation(['lens.match.joined']);
  const history = useHistory();

  const { dateFormatter } = useUserDateFormat();

  return (
    <GridItem
      dataTestId="browsed-founder-by-ned"
      header={
        <CompanyTile
          name={name}
          logoUrl={logoUrl}
          supplement={industry?.join(', ')}
          about={`${joined} ${dateFormatter(joinedAt, DateFormat.SHORT_MONTH_FULL_DATE)}`}
        />
      }
      meta={[metaConfig({ customerGroup }).business, metaConfig({ investmentStage, productStage }).growth]}
      description={{
        dangerouslySet: true,
        content:
          description !== null && checkIfValidWysiWygValue(description)
            ? SanitizeWysiwyg(JSON.parse(description))
            : null,
      }}
      footer={
        <RequestIntro
          id={id}
          requestIntroId={requestIntroId}
          sendRequestIntroAction={sendRequestIntroAction}
          refetch={refetchBrowseFounders}
        />
      }
      url={history.createHref({
        search: `${founderIdQueryParam}=${id}`,
        pathname: Links.FOUNDER_PUBLIC_PROFILE_DETAILS(),
      })}
    />
  );
};

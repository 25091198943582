import { useScrollToElementWithQueryParams } from '@/utils/hooks/use-scroll-to-element/use-scroll-to-element-with-query-params';
import { setUserMetadata } from '@context/user/user-account.actions';
import { DateFormat } from '@i18n/date-formats';
import { Auth0ProviderValue } from '@pages/auth/api/account-data/account-data.types';
import { updateCompanyDetailsAction } from '@pages/content/profile/founder/api/update-company-details/update-company-details.action';
import { uploadProfileAvatarAction } from '@pages/content/profile/founder/api/upload-profile-avatar/upload-profile-avatar.action';
import type { CompanyDetailsData } from '@pages/content/profile/founder/founder-profile.page';
import { AvatarUploader } from '@pages/content/profile/ned/parts/avatar-uploader/avatar-uploader';
import AvatarFrame from '@pages/content/profile/parts/avatar-frame/avatar-frame';
import { ChangePassword } from '@pages/content/profile/parts/change-password/change-password';
import DisabledCountryOfResidence from '@pages/content/profile/parts/country-of-residence/country-of-residence';
import { FormikField } from '@pages/content/profile/parts/formik-field/formik-field';
import LinkedinOutlinedIcon from '@pages/content/profile/parts/linkedin-outlined-icon/linkedin-outlined-icon';
import MobileFormReversed from '@pages/content/profile/parts/mobile-form-reversed/mobile-form-reversed';
import { ProfilePhoneNumber } from '@pages/content/profile/parts/profile-phone-number/profile-phone-number';
import { SwitchToPublicButton } from '@pages/content/profile/parts/switch-to-public-button/switch-to-public-button';
import TwitterOutlinedIcon from '@pages/content/profile/parts/twitter-outlined-icon/twitter-outlined-icon';
import { LazyDatePicker as DatePicker } from '@parts/date-picker/date-picker.lazy';
import message from '@parts/message/message';
import { Section } from '@parts/section/section';
import { SubmitButton } from '@parts/submit-button/submit-button';
import { Tooltip } from '@parts/tooltip/tooltip';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { generateUserName } from '@utils/fns/generate-user-name';
import { getServerError } from '@utils/fns/get-server-error';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { Col, Input, Row } from 'antd';
import 'antd-country-phone-input/dist/index.css';
import { useFormik } from 'formik';
import { useState, type ChangeEvent, type ReactNode } from 'react';
import { useValidationSchema } from './validation-schema';

type BasicDetailsData = Omit<CompanyDetailsData, 'aboutCompany' | 'achievements'>;

type BasicDetailsProps = {
  data: BasicDetailsData;
  switchToPreview: () => void;
  refetchPublishStatus: () => void;
  refetchFounderProfile: () => void;
};

export const CompanyDetails = ({
  data,
  switchToPreview,
  refetchPublishStatus,
  refetchFounderProfile,
}: BasicDetailsProps) => {
  const scrollToRef = useScrollToElementWithQueryParams<HTMLDivElement>('company-information');
  const [
    formUpdatedLabel,
    headerLabel,
    emailLabel,
    nameLabel,
    representativeFirstNameLabel,
    representativeLastNameLabel,
    countryOfResidenceLabel,
    foundedLabel,
    phoneNumberLabel,
    linkedInLabel,
    twitterLabel,
    saveChangesLabel,
    emailTooltipLabel,
    phoneNumberTooltipLabel,
  ] = useTranslation([
    'messages.form.success',
    'profile.founder.section.basicDetails.header',
    'profile.founder.section.basicDetails.form.email',
    'profile.founder.section.basicDetails.form.name',
    'profile.founder.section.basicDetails.form.representativeFirstName',
    'profile.founder.section.basicDetails.form.representativeLastName',
    'profile.founder.section.basicDetails.form.countryOfResidence',
    'profile.founder.section.basicDetails.form.founded',
    'profile.founder.section.basicDetails.form.phoneNumber',
    'profile.founder.section.basicDetails.form.linkedin',
    'profile.founder.section.basicDetails.form.twitter',
    'profile.founder.section.basicDetails.form.saveChanges',
    'profile.founder.section.basicDetails.form.emailTooltip',
    'profile.phoneNumber.tooltip',
  ]);

  const { dateFormatter } = useUserDateFormat();

  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);

  const { dispatch, state } = useUserAccount();

  const { mutateAsync: updateCompanyDetails } = useMutation(updateCompanyDetailsAction, {
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
    onSuccess: (_, vars) => {
      refetchFounderProfile();
      refetchPublishStatus();
      message.success({ content: formUpdatedLabel });
      dispatch(
        setUserMetadata({
          ...state,
          avatarUrl: vars.logoUrl ?? null,
          name: generateUserName(vars.representativeFirstName, vars.representativeLastName),
          businessName: vars.businessName,
        }),
      );
    },
  });

  const { values, isValid, dirty, submitCount, ...formik } = useFormik<BasicDetailsData>({
    initialValues: {
      ...data,
    },
    onSubmit: (v) => {
      const { countryOfResidence, logoUrl, email, ...valuesToSubmit } = v;

      updateCompanyDetails(valuesToSubmit as CompanyDetailsData);
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: useValidationSchema(),
  });

  const handleStartDatePickerChange = (date: Date | null) => {
    formik.setFieldValue('founded', date ? new Date(date).toISOString() : null);
  };

  const handleFieldChange = (e: ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLInputElement>, field: string) => {
    formik.setFieldValue(field, e.target.value || null);
    formik.setFieldTouched(field, true);
  };

  const column = (content: ReactNode) => (
    <Col xs={24} sm={12}>
      {content}
    </Col>
  );

  const fullColumn = (content: ReactNode) => <Col xs={12}>{content}</Col>;

  return (
    <Section header={headerLabel} innerRef={scrollToRef}>
      <form data-testid="founder-profile-info-form">
        <MobileFormReversed gutter={[24, 24]}>
          <Col xs={24} sm={24} md={24} lg={16} xl={17}>
            <Row gutter={[35, 10]}>
              {column(
                <FormikField
                  label={{
                    for: 'email',
                    label: (
                      <span>
                        {emailLabel}
                        <Tooltip title={emailTooltipLabel} />
                      </span>
                    ),
                  }}
                  suffix={state.auth0Provider === Auth0ProviderValue.Email ? <ChangePassword /> : null}
                >
                  <Input readOnly value={data.email} disabled />
                </FormikField>,
              )}
              {column(null)}
              {column(
                <FormikField
                  label={{
                    for: 'representativeFirstName',
                    label: representativeFirstNameLabel,
                  }}
                  error={formik.errors.representativeFirstName}
                  touched={formik.touched.representativeFirstName}
                >
                  <Input {...formik.getFieldProps('representativeFirstName')} />
                </FormikField>,
              )}
              {column(
                <FormikField
                  label={{
                    for: 'representativeLastName',
                    label: representativeLastNameLabel,
                  }}
                  error={formik.errors.representativeLastName}
                  touched={formik.touched.representativeLastName}
                >
                  <Input {...formik.getFieldProps('representativeLastName')} />
                </FormikField>,
              )}
              {column(
                <FormikField
                  label={{
                    label: foundedLabel,
                    for: 'founded',
                  }}
                  error={formik.errors.founded}
                  touched={formik.touched.founded}
                >
                  <DatePicker
                    {...formik.getFieldProps('founded')}
                    onChange={handleStartDatePickerChange}
                    allowClear={false}
                    picker="month"
                    inputReadOnly
                    format={(value: Date) => dateFormatter(value, DateFormat.LETTER_MONTH_LONG_YEAR)}
                    value={values.founded ? new Date(values.founded) : null}
                    placeholder="Select start date..."
                    disabledDate={(current: Date) => current && current.valueOf() > Date.now()}
                  />
                </FormikField>,
              )}
              {column(
                <FormikField
                  label={{
                    for: 'businessName',
                    label: nameLabel,
                  }}
                  error={formik.errors.businessName}
                  touched={formik.touched.businessName}
                >
                  <Input {...formik.getFieldProps('businessName')} />
                </FormikField>,
              )}
              {column(
                <DisabledCountryOfResidence
                  countryOfResidence={data.countryOfResidence}
                  countryOfResidenceLabel={countryOfResidenceLabel}
                />,
              )}
              {column(
                <ProfilePhoneNumber
                  formik={{ values, isValid, dirty, submitCount, ...formik }}
                  countryOfResidence={state.countryOfResidence}
                  field="phoneNumber"
                  label={
                    <span>
                      {phoneNumberLabel}
                      <Tooltip title={phoneNumberTooltipLabel} />
                    </span>
                  }
                />,
              )}
            </Row>
            <Row gutter={[35, 10]}>
              {column(
                <FormikField
                  label={{ for: 'linkedinUrl', label: linkedInLabel }}
                  error={formik.errors.linkedinUrl}
                  touched={Boolean(formik.initialValues.linkedinUrl) || formik.touched.linkedinUrl}
                  icon={<LinkedinOutlinedIcon />}
                >
                  <Input
                    {...formik.getFieldProps('linkedinUrl')}
                    onChange={(value) => handleFieldChange(value, 'linkedinUrl')}
                  />
                </FormikField>,
              )}
              {column(
                <FormikField
                  label={{ for: 'twitterUrl', label: twitterLabel }}
                  error={formik.errors.twitterUrl}
                  touched={formik.touched.twitterUrl}
                  icon={<TwitterOutlinedIcon />}
                >
                  <Input
                    {...formik.getFieldProps('twitterUrl')}
                    onChange={(value) => handleFieldChange(value, 'twitterUrl')}
                  />
                </FormikField>,
              )}
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={7}>
            <FormikField
              label={{
                for: 'logoPath',
                label: '',
              }}
              error={formik.errors.logoPath}
              touched={formik.touched.logoPath}
            >
              <AvatarFrame>
                <AvatarUploader
                  name={values.businessName}
                  uploadMutation={(formData: FormData) => {
                    setIsFileUploading(true);
                    return uploadProfileAvatarAction({ formData });
                  }}
                  deleteFile={() => {
                    formik.setFieldValue('logoPath', null);
                    formik.setFieldValue('logoUrl', null);
                  }}
                  onSuccess={(res) => {
                    formik.setFieldValue('logoPath', res.data.filePath);
                    formik.setFieldValue('logoUrl', res.data.fileUrl);
                    setIsFileUploading(false);
                  }}
                  onError={(err: AxiosError) => {
                    message.error({ content: getServerError(err) });
                  }}
                  currentImage={values.logoUrl}
                />
              </AvatarFrame>
            </FormikField>
          </Col>
        </MobileFormReversed>
        <Row>
          {fullColumn(
            <SubmitButton
              data-testid="save_investment_details_btn"
              type="primary"
              disabled={isFileUploading}
              disabledEvaluation={{ isValid, dirty, submitCount }}
              onClick={formik.submitForm}
            >
              {saveChangesLabel}
            </SubmitButton>,
          )}

          {fullColumn(<SwitchToPublicButton onClick={() => switchToPreview()} />)}
        </Row>
      </form>
    </Section>
  );
};

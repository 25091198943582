import HighNetWorthIcon from '@assets/icons/investor-onboarding/high-net-worth.svg';
import InstitutionalIcon from '@assets/icons/investor-onboarding/institutional-investor.svg';
import SophisticatedIcon from '@assets/icons/investor-onboarding/sophisticated.svg';
import { accreditedInvestorLink } from '@constants/links';
import type { AppMessagesKey } from '@i18n/messages';
import { SelfCertificationType } from '@pages/content/profile/investor/api/types';

export type BaseTileConfig = {
  title: AppMessagesKey;
  description: AppMessagesKey;
  secondDescription?: AppMessagesKey;
  Icon: string;
  key: SelfCertificationType;
};

export type SimpleTileConfig = BaseTileConfig & {
  link: string;
  confirmationText: AppMessagesKey;
};

export type TileConfig = BaseTileConfig & {
  bulletPoints: {
    text: AppMessagesKey;
    list: AppMessagesKey[] | null;
  }[];
  secondBulletPoints?: {
    text: AppMessagesKey;
    list: AppMessagesKey[] | null;
  }[];
  showWarning?: boolean;
};

export const institutionalOptions: TileConfig[] = [
  {
    title: 'investor-onboarding.affirmations.institutional.tile.title',
    description: 'investor-onboarding.affirmations.institutional.tile.description',
    bulletPoints: [
      { text: 'investor-onboarding-affirmations.institutional.tile.description.li1', list: null },
      { text: 'investor-onboarding-affirmations.institutional.tile.description.li2', list: null },
      { text: 'investor-onboarding-affirmations.institutional.tile.description.li3', list: null },
      { text: 'investor-onboarding-affirmations.institutional.tile.description.li4', list: null },
    ],
    Icon: InstitutionalIcon,
    key: SelfCertificationType.InstitutionalInvestor,
  },
];

export const angelOptions: TileConfig[] = [
  {
    title: 'investor-onboarding.affirmations.angel.sophisticated.tile.title',
    description: 'investor-onboarding.affirmations.angel.sophisticated.tile.description',
    bulletPoints: [
      { text: 'investor-onboarding-affirmations.angel.sharedDescription.li1', list: null },
      { text: 'investor-onboarding-affirmations.angel.sharedDescription.li2', list: null },
      { text: 'investor-onboarding-affirmations.angel.sharedDescription.li3', list: null },
      {
        text: 'investor-onboarding-affirmations.angel.sharedDescription.li4',
        list: [
          'investor-onboarding-affirmations.angel.sharedDescription.li4.subList.first',
          'investor-onboarding-affirmations.angel.sharedDescription.li4.subList.second',
        ],
      },
      { text: 'investor-onboarding-affirmations.angel.sharedDescription.li5', list: null },
    ],
    secondDescription: 'investor-onboarding.affirmations.angel.sophisticated.tile.secondDescription',
    secondBulletPoints: [
      { text: 'investor-onboarding-affirmations.angel.sophisticated.tile.secondDescription.li1', list: null },
      { text: 'investor-onboarding-affirmations.angel.sophisticated.tile.secondDescription.li2', list: null },
      { text: 'investor-onboarding-affirmations.angel.sophisticated.tile.secondDescription.li3', list: null },
      { text: 'investor-onboarding-affirmations.angel.sophisticated.tile.secondDescription.li4', list: null },
    ],
    Icon: SophisticatedIcon,
    key: SelfCertificationType.Sophisticated,
    showWarning: true,
  },
  {
    title: 'investor-onboarding.affirmations.angel.highNetWorth.tile.title',
    description: 'investor-onboarding.affirmations.angel.highNetWorth.tile.description',
    bulletPoints: [
      { text: 'investor-onboarding-affirmations.angel.sharedDescription.li1', list: null },
      { text: 'investor-onboarding-affirmations.angel.sharedDescription.li2', list: null },
      { text: 'investor-onboarding-affirmations.angel.sharedDescription.li3', list: null },
      {
        text: 'investor-onboarding-affirmations.angel.sharedDescription.li4',
        list: [
          'investor-onboarding-affirmations.angel.sharedDescription.li4.subList.first',
          'investor-onboarding-affirmations.angel.sharedDescription.li4.subList.second',
        ],
      },
      { text: 'investor-onboarding-affirmations.angel.sharedDescription.li5', list: null },
    ],
    secondDescription: 'investor-onboarding.affirmations.angel.highNetWorth.tile.secondDescription',
    secondBulletPoints: [
      { text: 'investor-onboarding.affirmations.angel.highNetWorth.tile.secondDescription.li1', list: null },
      {
        text: 'investor-onboarding.affirmations.angel.highNetWorth.tile.secondDescription.li2',
        list: [
          'investor-onboarding.affirmations.angel.highNetWorth.tile.secondDescription.li2.subList.first',
          'investor-onboarding.affirmations.angel.highNetWorth.tile.secondDescription.li2.subList.second',
          'investor-onboarding.affirmations.angel.highNetWorth.tile.secondDescription.li2.subList.third',
        ],
      },
    ],
    Icon: HighNetWorthIcon,
    key: SelfCertificationType.HighNetWorth,
    showWarning: true,
  },
];

export const accreditedOptions: SimpleTileConfig = {
  title: 'investor-onboarding.affirmations.accredited.tile.title',
  description: 'investor-onboarding.affirmations.accredited.tile.description.li1',
  secondDescription: 'investor-onboarding.affirmations.accredited.tile.description.li2',
  confirmationText: 'investor-onboarding.affirmations.accredited.tile.confirmationText',
  link: accreditedInvestorLink,
  Icon: SophisticatedIcon,
  key: SelfCertificationType.AccreditedInvestor,
};

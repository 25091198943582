import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ isBarChartView?: boolean }>`
  display: block;
  margin-left: -125px;
  margin-top: 25px;
  max-height: 150px;
  overflow-y: scroll;

  ${({ isBarChartView }) =>
    isBarChartView &&
    css`
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(min-content, 50%));
      max-height: 100px;
      overflow-y: scroll;
      margin: 0;
      margin-left: 13px;
    `}

  ::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    margin-left: ${({ isBarChartView }) => (isBarChartView ? '13px' : '-90px')};
    width: ${({ isBarChartView }) => (isBarChartView ? '100%' : '180px')};
    grid-template-columns: ${({ isBarChartView }) => isBarChartView && '1fr'};
  }
  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: ${({ isBarChartView }) => isBarChartView && '100%'};
    margin-left: ${({ isBarChartView }) => (isBarChartView ? '13px' : '-60px')};
  }
`;

export const Item = styled.div`
  display: inline-block;
  display: flex;
  align-items: center;
  vertical-align: middle;
  margin: 2px 0;
`;

export const BulletPoint = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  margin-right: ${({ theme }) => theme.spacing.xsmall};
  background-color: ${({ color }) => color};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: 14px;
    height: 14px;
  }
`;

export const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.fontSecondary};
  width: calc(100% - 14px);

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.xsmall};
  }
`;

export default { Wrapper, BulletPoint, Item, Text };

import type { INedExperience } from '@/pages/content/profile/ned/ned-profile.page';
import { ExperienceItem } from './experience-item/experience-item';

import S from '../../experience-section.styles';

export const ExperienceList = ({ experienceData }: { experienceData: INedExperience[] }) => {
  return (
    <S.ExperienceList data-testid="experience-list">
      {experienceData.map((experience) => (
        <ExperienceItem key={experience.id} experience={experience} />
      ))}
    </S.ExperienceList>
  );
};

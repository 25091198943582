import styled from 'styled-components';

const TableWrapper = styled.div`
  width: 100%;
  .ant-table,
  .ant-table-wrapper {
    background-color: transparent;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    background-color: transparent;
  }
`;

const Button = styled.span`
  color: ${({ theme }) => theme.color.submitButton};
  display: inline-block;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  cursor: pointer;
  margin-top: ${({ theme }) => theme.spacing.xmedium};
`;

export default { TableWrapper, Button };

import { NotificationContent } from '@/parts/notifications/notification-content';
import { useHistory } from '@/utils/hooks/use-history/use-history';
import {
  useNotificationMarkAsRead,
  useNotificationRedirectUrl,
} from '@/utils/hooks/use-notifications/use-notifications';
import type { Notification } from '@context/notifications/notifications.reducer';
import { DateTime } from 'luxon';

import S from './notification-item.styles';

interface NotificationItemProps {
  item: Notification;
  refetch: Function;
}

export const NotificationItem = ({ item, refetch }: NotificationItemProps) => {
  const history = useHistory();
  const redirectUrl = useNotificationRedirectUrl(item);
  const { markAsRead } = useNotificationMarkAsRead({ notification: item, refetch });

  const handleClick = async () => {
    await markAsRead();
    if (redirectUrl) history.push(redirectUrl);
  };

  return (
    <S.Container read={item.read} onClick={handleClick}>
      <S.Content>
        <S.Avatar avatarUrl={item.iconUrl} size={40} />
        <S.Text>
          <NotificationContent item={item} />
        </S.Text>
      </S.Content>
      <S.Time>{DateTime.fromISO(item.timestamp).toRelative()}</S.Time>
    </S.Container>
  );
};

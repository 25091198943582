import { UpgradeFreemium } from '@layout/parts/upgrade-freemium/upgrade-freemium';
import { useFreemiumModalData } from '@pages/content/start/founder/hooks/use-upgrade-freemium-modal/upgrade-freemium-modal-data';

export const UpgradeFromFreemiumBanner = () => {
  const { darkTitleLabel, blueTitleLabel, freemiumList, submitButtonProps } = useFreemiumModalData();

  return (
    <UpgradeFreemium
      darkTitle={darkTitleLabel}
      blueTitle={blueTitleLabel}
      listItems={freemiumList}
      submitButtonProps={submitButtonProps}
    />
  );
};

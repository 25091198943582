import { V2Container } from '@/components/ui/v2-container/v2-container';
import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import styled from 'styled-components';

const Container = styled(V2Container)`
  margin-bottom: 24px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(194px, auto);
  gap: 16px;
  margin-top: 16px;

  ${customMediaQuery('grid-template-columns', {
    md: '1fr 1fr',
  })}
`;

export default {
  Container,
  Grid,
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_CONNECTED_INVESTORS_CACHE_KEY = (filter: string) => `get-connected-investors-${filter}`;

export interface ConnectedInvestorsResponse {
  id: string;
  name: string;
  email?: string;
  avatarUrl?: string;
  type?: 'Internal' | 'External';
  investingRelationshipId?: string;
}

export const getConnectedInvestorsAction =
  (filter: string) =>
  async (): Promise<
    GraphqlResponse<{
      investors: ConnectedInvestorsResponse[];
    }>
  > => {
    const { data } = await axiosInstance.post('/query', {
      query: `
      query getConnectedInvestors{
        connectedInvestors(filter: "${filter}") {
          investors {
            id
            name
            email
            avatarUrl 
            type
            investingRelationshipId
          }
        }
      }`,
    });

    return graphqlResponseWrapper(data, ['connectedInvestors']);
  };

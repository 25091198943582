import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.medium};

  & > span {
    margin-left: ${({ theme }) => theme.spacing.medium};
  }

  & > span:first-child {
    margin-left: 0;
  }
`;

const Label = styled.label`
  display: inline-block;
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  color: ${({ theme }) => theme.color.interval};
  cursor: pointer;
`;

const Input = styled.input`
  &[type='radio'] {
    display: none;
  }

  &[type='radio']:checked + label {
    color: ${({ theme }) => theme.color.paginationButton};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
  }
`;

export default {
  Wrapper,
  Label,
  Input,
};

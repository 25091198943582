import { SubmitButton as ButtonBase } from '@parts/submit-button/submit-button';
import styled from 'styled-components';

const Wrapper = styled.section<{ height?: number | null }>`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: ${({ theme }) => theme.radius.default};
  padding: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.xbase};
  background: ${({ theme }) => theme.color.cardBackground};
  min-height: ${({ height }) => (typeof height === 'number' ? `${height}px` : 'auto')};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-direction: column;
    align-items: center;
    padding: ${({ theme }) => theme.spacing.xmedium};
  }
`;

const TextWrapper = styled.div`
  margin-left: ${({ theme }) => theme.spacing.xbase};
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin-left: 0;
  }
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.color.fontPrimary};
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin-top: ${({ theme }) => theme.spacing.medium};
    text-align: center;
  }
`;

const Description = styled.p`
  color: ${({ theme }) => theme.color.fontPrimary};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin-top: ${({ theme }) => theme.spacing.small};
    text-align: center;
  }
`;

const LearnMoreWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: ${({ theme }) => theme.spacing.xmedium};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin-left: 0;
  }
`;

const LearnMore = styled(ButtonBase)`
  background-color: ${({ theme }) => theme.baseColors.Blue200};
  margin-right: ${({ theme }) => theme.spacing.base};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) {
    margin-right: 0;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    justify-content: center;
    width: 100%;
  }
`;
const Image = styled.img`
  border-radius: ${({ theme }) => theme.radius.default};
  width: 200px;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: 90px;
  }
`;

const SingleImageWrapper = styled.section<{ height?: number | null }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.radius.default};
  border: 1px solid ${({ theme }) => theme.color.border};
  padding: ${({ theme }) => theme.spacing.medium} 0;
  margin-bottom: ${({ theme }) => theme.spacing.xbase};
  background: ${({ theme }) => theme.color.cardBackground};
  min-height: ${({ height }) => (typeof height === 'number' ? `${height}px` : 'auto')};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    margin-bottom: 0;
    min-height: 300px;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    min-height: 350px;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.xsmall}) {
    min-height: 400px;
  }
`;

const SingleImage = styled.img`
  width: 85%;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: 100%;
  }
`;

export default {
  Wrapper,
  TextWrapper,
  Title,
  Description,
  LearnMore,
  ImageWrapper,
  LearnMoreWrapper,
  Image,
  SingleImageWrapper,
  SingleImage,
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export interface ManagementTeam {
  id: string;
  isDeletedByFounder: boolean;
  name: string;
  role: string;
  email: string | null;
  phoneNumber: string | null;
  age: number | null;
  gender: string | null;
  ethnicity: string | null;
}

export const GET_MANAGEMENT_TEAM_CACHE_KEY = 'get-management-team';

export const getManagementTeamAction =
  (relationshipId: string) => async (): Promise<GraphqlResponse<ManagementTeam[]>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
        query getManagementTeam {
            companyManagementTeam(investingRelationshipId: "${relationshipId}") {
                id
                isDeletedByFounder
                name
                role
                email
                phoneNumber
                age
                gender
                ethnicity
            }
        }
    `,
    });

    return graphqlResponseWrapper(data, ['companyManagementTeam']);
  };

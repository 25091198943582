import { Roles } from '@domain/accounts/roles';
import { PageHeader } from '@parts/page-header/page-header';
import { mapEmptyToString } from '@utils/fns/map-empty-to-string';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { useState } from 'react';
import type { PublishStatus } from '../../hooks/use-publish-status-evaluation';
import { PublicPreviewNotification } from '../../parts/public-preview-notification/public-preview-notification';
import type { IInvestorInvestmentPayload } from '../api/investments/investments';
import type { InvestorPersonalDetailsUpdatePayload } from '../api/personal-details/personal-details';
import type { IInvestorInvestment, IInvestorSmartMatch } from '../api/types';
import type { IInvestorSmartMatchPayload } from '../api/update-smart-match/update-smart-match';
import type { IInvestorPersonalDetails } from '../investor-profile.page';
import { BecomeNED } from './become-ned/become-ned';
import { Investment } from './investment/investment';
import S from './investor-profile.styles';
import { PersonalDetails } from './personal-details/personal-details';
import { SmartMatch, type IInvestorSmartMatchOptionsRequest } from './smart-match/smart-match';

interface IInvestorProfileProps {
  id: string;
  personalDetails: IInvestorPersonalDetails;
  smartMatch: IInvestorSmartMatch;
  smartMatchOptions: IInvestorSmartMatchOptionsRequest;
  publishStatus: PublishStatus;
  investment: IInvestorInvestment;
  refetchPublishStatus: () => void;
  refetchInvestorData: () => void;
  handlePersonalDetailsSubmit: (formState: InvestorPersonalDetailsUpdatePayload) => void;
  handleSmartMatchSubmit: (formState: IInvestorSmartMatchPayload) => void;
  handleSubmitInvestment: (formState: IInvestorInvestmentPayload) => void;
}

export const InvestorProfile = ({
  id,
  personalDetails,
  smartMatch,
  smartMatchOptions,
  publishStatus,
  refetchPublishStatus,
  handlePersonalDetailsSubmit,
  handleSmartMatchSubmit,
  handleSubmitInvestment,
  investment,
  refetchInvestorData,
}: IInvestorProfileProps) => {
  const [header] = useTranslation(['profile.header']);
  const {
    state: { userRole },
  } = useUserAccount();

  const [isPublicPreview, setIsPublicPreview] = useState(false);
  const profilePage = (
    <>
      <PageHeader title={header} />
      <PersonalDetails
        personalDetails={personalDetails}
        handleSubmit={handlePersonalDetailsSubmit}
        togglePubicView={() => {
          refetchInvestorData();
          setIsPublicPreview((prev) => !prev);
        }}
      />
      <SmartMatch
        smartMatchData={smartMatch}
        handleSubmit={handleSmartMatchSubmit}
        smartMatchOptions={smartMatchOptions}
        publishStatus={publishStatus}
        refetchPublishStatus={refetchPublishStatus}
      />
      <Investment investorType={personalDetails.type} investment={investment} handleSubmit={handleSubmitInvestment} />
      {userRole !== Roles.INVESTOR_NED && <BecomeNED />}
    </>
  );
  const publicProfilePage = (
    <>
      <PublicPreviewNotification onClick={() => setIsPublicPreview((prev) => !prev)} />
      <S.InvestorPublicProfile
        id={id}
        handleBackAction={() => setIsPublicPreview((prev) => !prev)}
        publicData={{
          ...mapEmptyToString(personalDetails),
          profilePicture: {
            url: personalDetails?.profilePhotoUrl || '',
          },
          ...smartMatch,
          ...mapEmptyToString(investment),
          ...investment,
          minInvestmentAmount: investment.minInvestmentAmount.toString(),
          maxInvestmentAmount: investment.maxInvestmentAmount.toString(),
        }}
      />
    </>
  );

  return isPublicPreview ? publicProfilePage : profilePage;
};

import { InvestorType } from '@pages/content/profile/investor/api/types';
import { axiosInstance } from '@utils/axios/axios-instance';

export type InstitutionTypeValue = 'Wealth Management' | 'Family Office' | 'Venture Capital' | 'Scout';

export interface InvestorTypePayload {
  investorType: InvestorType;
  institutionType?: InstitutionTypeValue;
}

export const setInvestorTypeAction = async (data: InvestorTypePayload) =>
  axiosInstance.put('/profile/investor/investor-type', data);

import { Radio as RadioBase } from '@parts/radio/radio';
import styled from 'styled-components';

const VisibilityDesc = styled.p`
  color: ${({ theme }) => theme.baseColors.Grey200};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  margin: 5px 0;
`;

const Radio = styled(RadioBase)`
  font-size: ${({ theme }) => theme.fontSize.xsmall};
`;

const Line = styled.hr`
  height: 1px;
  overflow-x: hidden !important;
  position: relative;
  border: none;
  background: ${({ theme }) => theme.baseColors.Blue300};
  margin: 30px -20px 20px;
`;
const DisabledVisibilityText = styled.span`
  opacity: 0.3;
  color: ${({ theme }) => theme.color.fontPrimary};
`;

export default { VisibilityDesc, Radio, Line, DisabledVisibilityText };

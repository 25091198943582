import { V2Button } from '@/components/ui/v2-button/v2-button';
import type { V2FiltersState } from '@/components/ui/v2-mobile-filters/mobile-filters-form/mobile-filters-form.types';
import { V2Modal } from '@/components/ui/v2-modal/v2-modal';
import { useModal } from '@/hooks/use-modal';
import { useState } from 'react';
import { MobileFiltersForm } from './mobile-filters-form/mobile-filters-form';
import type { V2MobileFiltersProps } from './v2-mobile-filters.types';

// TODO - add storybook stories
// TODO - this component needs to be refactored - it was not designed to be reusable - but should be
export const V2MobileFilters = ({
  filters = [],
  activeFilter,
  initialSearchValue,
  sortValue,
  sortOptions = [],
  submit,
  disabled = false,
  showElements = ['sort', 'filters', 'search'],
  triggerLabel,
}: V2MobileFiltersProps) => {
  const { close, isOpen, open } = useModal();

  const [activeFilterState, setActiveFilterState] = useState<V2FiltersState>({
    filters: activeFilter ?? [],
    search: initialSearchValue ?? '',
    sort: sortValue,
  });

  const handleFilterChange = (value: Partial<V2FiltersState>) => {
    setActiveFilterState((prev) => ({ ...prev, ...value }));
  };

  const handleApply = () => {
    submit({ ...activeFilterState });
    close();
  };

  return (
    <>
      <V2Button size="large" isFull variant="outlined" startIcon="funnel" onClick={open}>
        {triggerLabel}
      </V2Button>

      <V2Modal isOpen={isOpen} onCancel={handleApply} footer={null} closable={false} size="sm">
        <MobileFiltersForm
          showElements={showElements}
          filters={filters}
          activeFilters={activeFilterState.filters}
          sortValue={activeFilterState.sort}
          sortOptions={sortOptions}
          initialSearchValue={activeFilterState.search}
          onChange={handleFilterChange}
          disabled={disabled}
        />
      </V2Modal>
    </>
  );
};

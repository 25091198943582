export const pulseCompaniesKey = 'companies';
export const pulseTriggersKey = 'triggers';
export const pulseDashboardsKey = 'dashboards';
export const pulseInvestorsKey = 'investors';
export const publicProfileFounderDetailsKey = 'details';
export const publicProfileFounderDashboardsKey = 'dashboards';
export const multiRoleProfileInvestorKey = 'investor';
export const multiRoleProfileNedKey = 'ned';
export const rolesBrowseKey = 'browse';
export const rolesAppliedKey = 'applied';
export const lensSmartMatchKey = 'smart-match';
export const lensConnectionsKey = 'connections';
export const lensRequestsKey = 'requests';
export const lensBrowseCompaniesKey = 'browse-companies';
export const lensBrowseKey = 'browse';
export const lensInvitesKey = 'invites';
export const manageMetricMetricsKey = 'metrics';
export const manageMetricIntegratedMetricsKey = 'integrated-metrics';

/* eslint-disable @typescript-eslint/no-use-before-define */
import { Roles } from '@domain/accounts/roles';
import { openFundingRoundDrawerByLink } from '@pages/content/parts/funding-round/funding-round';
import { Add } from '@pages/content/portfolio/investor/parts/editor/parts/add/add';
import { createNewShareholderAction } from '@pages/content/pulse/api/create-new-shareholder';
import { updateShareholderAction } from '@pages/content/pulse/api/update-shareholder';
import Alert from '@parts/alert/alert';
import ConnectionAlert from '@parts/connection-alert-editor/connection-alert';
import EditorDrawer from '@parts/editor-drawer/editor-drawer';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import message from '@parts/message/message';
import { pulseInvestorsKey } from '@router/tabs-keys';
import {
  getActiveRegisteredFundingRounds,
  useInvestmentsEditor,
} from '@utils/hooks/use-investments-editor/use-investments-editor';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { EditorDetails } from '../../../../parts/editor-details/editor-details';
import type { FormikState } from './editor-interfaces';
import { emptyInvestment, prepareDataForUpdate, prepareInvestmentId } from './helpers';
import { InvestorSearch } from './parts/investor-search/investor-search';
import { emailValidation, useValidationSchema } from './validation-schema';

export const newQueryParamValue = 'new';
export const idQueryParam = 'id';
export const investmentIdQueryParam = 'investment';
export const clearQueryParamValue = 'clear';

const initialFormikValues = {
  nameOrEmail: null,
  investorId: null,
  investments: [],
  investingRelationshipId: null,
};

type CreateEditorProps = {
  drawer: Function;
  hideDrawer: Function;
  id: string;
  refetch: () => void;
};

export const CreateEditor = ({ drawer: Drawer, hideDrawer, id = '', refetch, ...rest }: CreateEditorProps) => {
  const [
    descriptionLabel,
    titleLabel,
    addInvestmentLabel,
    warningLabel,
    requiredLabel,
    investmentsWarningLabel,
    investmentsWarningLink,
    usedInvestmentsWarningLink,
    notConnectedAlert,
    autoConnectAlert,
  ] = useTranslation([
    'pulse.founder.drawer.description',
    'pulse.founder.drawer.title',
    'pulse.founder.drawer.addInvestment',
    'pulse.founder.drawer.warning',
    'formik.validation.required',
    'pulse.founder.investments.warning',
    'pulse.founder.investments.link',
    'pulse.founder.usedInvestments.warning',
    'pulse.founder.notConnectedAlert',
    'pulse.founder.autoConnectAlert',
  ]);

  const initialValues = initialFormikValues;
  const history = useHistory();

  const onClear = () => {
    history.replace({
      search: `${idQueryParam}=${clearQueryParamValue}`,
    });
    setValues(initialFormikValues);
  };

  const onSubmit = async (values: FormikState) => {
    if (id === newQueryParamValue) {
      const vals = prepareInvestmentId(values);

      const { nameOrEmail, investingRelationshipId, investorId, ...invest } = vals;

      if (investingRelationshipId) {
        const result = await update({ id: investingRelationshipId, ...invest });
        await refetch();
        return result;
      }

      if (investorId) {
        const result = await add({ ...invest, investorId });
        await refetch();
        return result;
      }

      if (nameOrEmail) {
        const result = await add({ ...invest, investorEmail: nameOrEmail });
        await refetch();
        return result;
      }
    } else {
      const data = prepareDataForUpdate(values);

      const result = await update({ id, investments: data.investments });
      await refetch();
      return result;
    }
  };

  const {
    add,
    update,
    createdFundingRounds,
    onClose,
    goToNew,
    goToRelation,
    isMutationLoading,
    formik: {
      setFieldValue,
      getFieldProps,
      getFieldMeta,
      submitForm,
      values: formikValues,
      setValues,
      setFieldTouched,
      dirty,
      isValid,
      submitCount,
    },
    deleteInvestment,
  } = useInvestmentsEditor({
    onClear,
    hideDrawer,
    validationSchema: useValidationSchema,
    initialValues,
    onSubmit,
    createAction: createNewShareholderAction,
    updateAction: updateShareholderAction,
    trackedPageParam: pulseInvestorsKey,
    isShareholder: true,
  });

  const values = formikValues as FormikState;
  const investmentsLoading = !createdFundingRounds.isFundingRoundsApiCalled;
  const activeRegisteredFundingRoundsIds = getActiveRegisteredFundingRounds(values.investments);
  const freeFundingRound = createdFundingRounds.data?.filter((el) => !el.hasInvestment);
  const isAtLeastOneFreeFundingRound = Boolean(freeFundingRound?.length);
  const isMoreThanOneFreeFundingRound = freeFundingRound && freeFundingRound.length > 1;
  const isNewExternal = id === idQueryParam;
  const areFreeFundingRoundsToUse = freeFundingRound && values.investments.length < freeFundingRound.length;
  const areAvailableRounds = id && areFreeFundingRoundsToUse && isMoreThanOneFreeFundingRound;
  const didUserAddAnyInvestment = Boolean(values.investments.length);
  const moreRegisteredRoundsAvailable =
    isAtLeastOneFreeFundingRound && (isNewExternal || areAvailableRounds || !didUserAddAnyInvestment);

  useEffect(() => {
    if (id) {
      createdFundingRounds.refetch();
    }
  }, [values.investingRelationshipId, id]);

  const addInvestment = () => {
    setValues({
      ...values,
      investments: [...values.investments, emptyInvestment],
    });
  };

  const onSearch = async ({
    value,
    investorId,
    investingRelationshipId,
  }: {
    value: string | null;
    investingRelationshipId: string | null;
    investorId: string | null;
  }) => {
    const validation = Yup.object({
      nameOrEmail: emailValidation(requiredLabel),
    });

    if (investingRelationshipId) {
      setValues({
        investingRelationshipId,
        investorId,
        nameOrEmail: value,
        investments: [emptyInvestment],
      });
      goToRelation(investingRelationshipId);
    } else if (!investingRelationshipId && investorId) {
      setValues({
        investingRelationshipId: null,
        investorId,
        nameOrEmail: value,
        investments: [emptyInvestment],
      });
      goToNew();
    } else {
      try {
        await validation.validate({ nameOrEmail: value });
        setValues({
          investingRelationshipId: null,
          investorId: null,
          nameOrEmail: value,
          investments: [emptyInvestment],
        });
        goToNew();
      } catch {
        onClear();
        message.warning({ content: warningLabel });
      }
    }
  };

  const commonProps = { setFieldValue, getFieldProps, getFieldMeta, setFieldTouched };

  const renderAlert = (msg: string) => (
    <Alert
      message={
        <span style={{ whiteSpace: 'pre-wrap' }}>
          {msg}
          <Link onClick={onClear} to={openFundingRoundDrawerByLink}>
            {investmentsWarningLink}
          </Link>
        </span>
      }
      type="warning"
    />
  );

  const renderEditorDetails = (index: number) => (
    <EditorDetails
      key={`${values.nameOrEmail}-${index}`}
      index={index}
      foundingRounds={{
        registered: createdFundingRounds.data!,
        externalTypes: [],
        activeRegistered: activeRegisteredFundingRoundsIds,
      }}
      role={Roles.FOUNDER}
      deleteInvestment={deleteInvestment}
      {...commonProps}
    />
  );

  const renderInvestments = () => {
    if (id) {
      if (createdFundingRounds.data?.length) {
        if (isAtLeastOneFreeFundingRound) {
          if (values.investorId === null && values.investments.length > 0) {
            return (
              <>
                <ConnectionAlert notConnectedAlertLabel={notConnectedAlert} autoConnectAlertLabel={autoConnectAlert} />
                {values.investments.map((_investment, i) => renderEditorDetails(i))}
              </>
            );
          }
          return values.investments.map((_investment, i) => renderEditorDetails(i));
        }
        return renderAlert(usedInvestmentsWarningLink);
      }

      return renderAlert(investmentsWarningLabel);
    }

    return null;
  };

  return (
    <EditorDrawer
      {...rest}
      drawer={Drawer}
      search={
        <InvestorSearch
          {...commonProps}
          disabled={values.investments.length > 0}
          onSearch={onSearch}
          onClear={onClear}
          fieldKey="nameOrEmail"
        />
      }
      title={titleLabel}
      onClose={onClose}
      description={[descriptionLabel]}
      isLoading={false}
      isMutationLoading={isMutationLoading}
      footer={{
        onConfirm: submitForm,
        disabledEvaluation: {
          dirty,
          submitCount,
          isValid: isValid && values.investments.length > 0,
        },
        onCancel: onClose,
      }}
    >
      {values.nameOrEmail && (
        <>
          {id && investmentsLoading ? <FullHeightSpinner small /> : renderInvestments()}
          {moreRegisteredRoundsAvailable && (
            <Add type="link" onClick={addInvestment}>
              {addInvestmentLabel}
            </Add>
          )}
        </>
      )}
    </EditorDrawer>
  );
};

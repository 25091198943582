import Fallback from '@assets/v2-icons/question-diamond.svg?react';
import type { V2IconProps } from '@ui/v2-icon/v2-icon.types';
import { V2_ICONS_CONFIG } from './icons';
import S from './v2-icon.styles';

export const V2Icon = ({
  name,
  color = 'currentColor',
  size = 'sm',
  'data-testid': testId = 'v2-icon',
  ...restProps
}: V2IconProps) => {
  const Component = V2_ICONS_CONFIG.find((icon) => icon.name === name)?.component ?? Fallback;

  return (
    <S.Wrapper color={color} size={size} data-testid={testId} {...restProps}>
      <Component width="inherit" height="inherit" />
    </S.Wrapper>
  );
};

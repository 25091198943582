import InfoIconBase from '@assets/icons/info-circle.svg?react';
import CheckboxFormikFieldBase from '@pages/content/profile/parts/checkbox-formik-field/checkbox-formik-field';
import { FormikField as FormikFieldBase } from '@parts/forms/formik-field/formik-field';
import { Button, Row } from 'antd';
import styled from 'styled-components';
import { Button as ActionButton } from '../button/button';
import { Text as TextBase } from '../text/text';

const Form = styled.form``;

const Text = styled(TextBase)`
  width: 90%;
`;

const FirstLine = styled(Text)`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`;

const UrlActions = styled(Text)`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
  display: flex;
  flex-wrap: wrap;
`;

const Link = styled(Button)`
  text-decoration: none;
  color: ${({ theme }) => theme.color.submitButton};
  cursor: pointer;
  height: auto !important;
  padding: 0;
  font-size: ${({ theme }) => theme.fontSize.regular};

  &:hover {
    color: ${({ theme }) => theme.color.submitButton};
  }
`;

const LinkWithTooltip = styled.span`
  display: flex;
  align-items: center;
`;

const InfoIcon = styled(InfoIconBase)`
  margin-left: ${({ theme }) => theme.spacing.xsmall};
`;

const CopyButton = styled(ActionButton)``;

const SaveButton = styled(ActionButton)`
  background-color: ${({ theme }) => theme.baseColors.White};
  border: 1px solid ${({ theme }) => theme.baseColors.Blue200};
  color: ${({ theme }) => theme.color.submitButton};
`;

const UrlSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.medium};
`;

const OrSpacer = styled.span`
  margin-left: ${({ theme }) => theme.spacing.xsmall};
  margin-right: ${({ theme }) => theme.spacing.xsmall};
`;

const Date = styled.span`
  white-space: nowrap;
`;

const PasswordWrapper = styled.div`
  display: flex;
`;

const CheckboxFormikField = styled(CheckboxFormikFieldBase)`
  margin-top: ${({ theme }) => theme.spacing.medium};
`;

const FormikField = styled(FormikFieldBase)``;

const TopMarginRow = styled(Row)`
  margin-top: ${({ theme }) => theme.spacing.medium};
`;

export default {
  Form,
  Text,
  Link,
  InfoIcon,
  CopyButton,
  UrlSection,
  FirstLine,
  UrlActions,
  LinkWithTooltip,
  OrSpacer,
  Date,
  PasswordWrapper,
  CheckboxFormikField,
  FormikField,
  SaveButton,
  TopMarginRow,
};

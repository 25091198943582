import type { V2Theme } from '@/styles/v2-theme/v2-theme';
import styled from 'styled-components';
import type { NotificationBannerVariant } from './v2-notification-banner';

const getBackground = (variant: NotificationBannerVariant, themeConfig: V2Theme) => {
  const backgroundMap = {
    info: themeConfig.colors.blue[300],
    error: themeConfig.colors.red[300],
    gradient: themeConfig.gradients['light-blue-gradient'],
  };

  return backgroundMap[variant] || backgroundMap.info;
};

const getColor = (variant: NotificationBannerVariant, themeConfig: V2Theme) => {
  const colorMap = {
    info: themeConfig.colors.neutral[50],
    error: themeConfig.colors.neutral[50],
    gradient: themeConfig.colors.blue[800],
  };

  return colorMap[variant] || colorMap.info;
};

const V2NotificationBannerCloseButton = styled('button')`
  background-color: transparent;
  color: inherit;
  border: none;

  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
  }
`;

const V2NotificationBanner = styled('div')<{ $variant: NotificationBannerVariant }>`
  position: relative;
  width: 100%;
  background: ${({ theme, $variant }) => getBackground($variant, theme.v2)};
  color: ${({ theme, $variant }) => getColor($variant, theme.v2)};
`;

const V2NotificationBannerContent = styled('div')`
  position: relative;
  text-align: center;
  font-size: 14px;
  padding: 15px 40px;
  width: 100%;
  max-width: 100%;

  ${V2NotificationBannerCloseButton} {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    padding: 5px;
  }
`;

export default { V2NotificationBanner, V2NotificationBannerCloseButton, V2NotificationBannerContent };

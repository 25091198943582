import {
  GET_SKILL_SETS_REQUESTS_CACHE_KEY,
  getSkillSetsOptionAction,
} from '@pages/content/advisors-search/api/get-skill-sets-options/get-skill-sets-options.action';
import { MultiSelectSearch } from '@parts/multiselect-search/multiselect-search';
import { useQuery } from '@tanstack/react-query';
import { sortArrayAlphabetically } from '@utils/fns/sortArrayAlphabetically';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { SelectValue } from 'antd/lib/select';
import type { FieldConfig, FieldInputProps, FormikErrors } from 'formik';
import type { FormikFiltersData } from '../filters';
import S from './skill-sets.styles';

export const SkillSetsFilter = ({
  setFieldValue,
  getFieldProps,
}: {
  setFieldValue: (field: string, value: SelectValue | null) => Promise<void | FormikErrors<FormikFiltersData>>;
  getFieldProps: (nameOrOptions: string | FieldConfig<unknown>) => FieldInputProps<unknown>;
}) => {
  const [skillSetsLabel] = useTranslation(['advisors.search.filters.skillSets']);
  const { data: response } = useQuery([GET_SKILL_SETS_REQUESTS_CACHE_KEY], getSkillSetsOptionAction);

  const handleSelectChange = (selectKey: string) => (value: SelectValue) => {
    setFieldValue(selectKey, value || null);
  };

  const options = response?.data && response.data.length > 0 ? sortArrayAlphabetically(response.data) : [];

  return (
    <S.FormikField
      label={{
        for: 'skillSet',
        label: <span>{skillSetsLabel}</span>,
      }}
    >
      <MultiSelectSearch
        {...getFieldProps('skillSet')}
        data-testid="skillSets-field"
        id="skillSet"
        showArrow={false}
        options={options}
        onChange={handleSelectChange('skillSet')}
      />
    </S.FormikField>
  );
};

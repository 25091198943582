import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';
import type { ClaimedInvestment } from './get-investment-claimed-by-founder.action';

export type ClaimedInvestmentByInvestor = ClaimedInvestment & {
  entity: ClaimedInvestment['entity'] & {
    isFollowRequestPending: boolean;
  };
  investment: ClaimedInvestment['investment'] & { shouldAssignFundingRound: boolean; investingRelationshipId: string };
};

export const GET_INVESTMENT_CLAIMED_BY_INVESTOR_CACHE_KEY = 'investmentClaimedByInvestor';

export const getInvestmentClaimedByInvestorAction =
  (id: string) => async (): Promise<GraphqlResponse<ClaimedInvestmentByInvestor | null>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
      query getInvestmentClaimedByInvestor {
        investmentClaimedByInvestor(investmentId: "${id}") {
          entity: investor  {
            id
            avatarUrl
            name
            isConnected
            isFollowRequestPending
          }
          investment {
            id
            round {
              date
              type
            }
            investingRelationshipId
            shouldAssignFundingRound
            confirmed
            archived
            shares
            shareClass
            percentageOwnership
            amount
            terms {
              warrants {
                amount
                price
                expiry
              }
              convertibleLoanNote
              discount
              options
            }
          }
        }
      }
    `,
    });

    return graphqlResponseWrapper(data, ['investmentClaimedByInvestor']);
  };

import type { Notification } from '@context/notifications/notifications.reducer';
import { Links } from '@router/links';
import { useNotificationList } from '@utils/hooks/use-notification-list/use-notification-list';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { EmptyState } from '../empty-state/empty-state';
import { NotificationItem } from '../notification-item/notification-item';
import S from './overlay.styles';

interface OverlayProps {
  notifications: Notification[];
}

export const Overlay = ({ notifications }: OverlayProps) => {
  const [title, showMore, clearAll, showArchived] = useTranslation([
    'notifications.overlay.title',
    'notifications.overlay.showMore',
    'notifications.overlay.clearAll',
    'notifications.overlay.showArchived',
  ]);

  const { unreadNotificationPresent, clearNotifications } = useNotificationList({});

  return (
    <S.Article>
      <S.Header>
        <S.Title>{title}</S.Title>
        {unreadNotificationPresent && (
          <S.ClearAllButton type="link" onClick={() => clearNotifications()}>
            {clearAll}
          </S.ClearAllButton>
        )}
      </S.Header>
      <S.Section>
        {notifications.length ? (
          <S.List>
            {notifications.map((notification) => (
              <S.ListItem key={notification.id}>
                <NotificationItem item={notification} />
              </S.ListItem>
            ))}
          </S.List>
        ) : (
          <EmptyState />
        )}
      </S.Section>
      <S.Footer>
        <S.ShowMoreLink to={Links.NOTIFICATIONS()}>
          {unreadNotificationPresent ? showMore : showArchived}
        </S.ShowMoreLink>
      </S.Footer>
    </S.Article>
  );
};

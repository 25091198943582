import styled from 'styled-components';

const Wrapper = styled.h1`
  font-size: 36px;
  color: ${({ theme }) => theme.color.primary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    font-size: 24px;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.xsmall}) {
    font-size: 18px;
  }
`;

export default { Wrapper };

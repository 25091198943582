import EditRowIconBase from '@assets/icons/edit-icon.svg?react';
import { Table as TableBase } from '@parts/table/table';
import { Button } from 'antd';
import styled from 'styled-components';

const Table = styled(TableBase)`
  border-top: 1px solid ${({ theme }) => theme.color.tableBorder};

  .ant-table-thead {
    > tr {
      > th {
        color: ${({ theme }) => theme.onboardingColors.tableHeader};
      }
    }
  }
  .ant-table-tbody {
    > tr {
      > td {
        vertical-align: top;
        font-size: ${({ theme }) => theme.fontSize.small};
        padding: ${({ theme }) => theme.spacing.xmedium} ${({ theme }) => theme.spacing.xsmall};
      }
    }
  }
`;

const Name = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const EditRowIcon = styled(EditRowIconBase)`
  width: 25px;
  height: 19px;

  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
`;

const EditButton = styled(Button)`
  height: 25px !important;
  padding: 0;
`;

export default {
  Table,
  Name,
  Controls,
  EditRowIcon,
  EditButton,
};

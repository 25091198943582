import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_PULSE_COMPANIES_CACHE_KEY = 'get-investor-connected-companies';

export interface PulseCompanies {
  id: string;
  logoUrl: string | null;
  name: string;
  industry: string[];
  customerGroup: string[];
  productStage: string;
  investmentStage: string;
  about: string;
  canBePreviewed: boolean;
}

export const getPulseCompaniesAction =
  (start: number, limit: number) =>
  async (): Promise<
    GraphqlResponse<{
      items: PulseCompanies[];
      total: number;
    }>
  > => {
    const { data } = await axiosInstance.post('/query', {
      query: `
      query getPulseCompanies {
        companies: getFollowingCompanies(start: ${start}, limit: ${limit}) {
          items {
            id
            logoUrl
            name
            industry
            customerGroup
            productStage
            investmentStage
            about
            canBePreviewed
          }
          total
      }
    }`,
    });

    return graphqlResponseWrapper(data, ['companies']);
  };

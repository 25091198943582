import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 750px;
  padding-bottom: ${({ theme }) => theme.spacing.large};
`;

const TextWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing.base} 0;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    margin: ${({ theme }) => theme.spacing.xbase} 0;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin: ${({ theme }) => theme.spacing.medium} 0;
  }
`;

const Title = styled.h1`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: 30px;
  color: ${({ theme }) => theme.baseColors.White};
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  letter-spacing: 0.5px;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    font-size: 25px;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    font-size: 22px;
  }
`;

const Subtitle = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSize.regular};
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme.onboardingColors.headingSubtitle};
  opacity: 0.5;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.small};
  }
`;

const Earth = styled.img`
  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: 60%;
    height: 60%;
  }
`;

const Form = styled.form`
  position: relative;
  width: 340px;
  max-width: 100%;
  padding: ${({ theme }) => `${theme.spacing.base} ${theme.spacing.xmedium}`};
  border-radius: 0px;
  background-color: transparent;

  @media screen and (min-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    height: auto;
    width: 480px;
    min-height: unset;
    padding: ${({ theme }) => `${theme.spacing.xsmall} ${theme.spacing.large}`};
    border-radius: 8px;
  }
`;

const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

export default { Wrapper, Title, Subtitle, TextWrapper, Earth, Form, Label };

import { axiosInstance } from '@utils/axios/axios-instance';

interface UpdateFundingRoundPayload {
  fundingRoundId: string;
  date?: string;
  type?: string;
  amountSought?: number;
  amountRaised?: number;
  valuation?: number;
  status?: string;
  useOfFunds?: string | null;
}

export const updateFundingRoundAction = async ({ fundingRoundId, ...payload }: UpdateFundingRoundPayload) =>
  axiosInstance.patch(`/funding-rounds/${fundingRoundId}`, payload);

import { Container } from '@/parts/container/container';
import { AnyLayout } from '@layout/any/any.layout';
import { Content } from '@layout/parts/content/content';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  GET_PROGRESS_REPORT_ACCESS_CONFIG_CACHE_KEY,
  getProgressReportAccessConfig,
} from './api/get-progress-report-access-config/get-progress-report-access-config.action';
import { Header } from './parts/header/header';
import { NotFound } from './parts/not-found/not-found';
import { PasswordProtected } from './parts/password-protected/password-protected';
import { Progress } from './parts/progress/progress';

export const ProgressReportPage = () => {
  const { hash } = useParams() as { hash: string };
  const [passwordVerified, setPasswordVerified] = useState(false);
  const [founderId, setFounderId] = useState('');
  const { data: response } = useQuery(
    [GET_PROGRESS_REPORT_ACCESS_CONFIG_CACHE_KEY(hash)],
    getProgressReportAccessConfig(hash),
    { onSuccess: () => setPasswordVerified(false) },
  );

  if (!response || !response.data) return <FullHeightSpinner />;

  const { isExpired: isExpiredOrNotFound, isPasswordProtected } = response.data;

  return (
    <AnyLayout>
      <Content>
        <Container>
          {(() => {
            if (isExpiredOrNotFound) return <NotFound />;

            if (isPasswordProtected && !passwordVerified) {
              return (
                <PasswordProtected setPasswordVerified={setPasswordVerified} passwordVerified={passwordVerified} />
              );
            }
            return (
              <>
                <Header companyId={founderId} />
                <Progress setFounderId={setFounderId} />
              </>
            );
          })()}
        </Container>
      </Content>
    </AnyLayout>
  );
};

import { Roles } from '@domain/accounts/roles';
import { FounderLayout } from '@layout/founder/founder.layout';
import { InvestorNedLayout } from '@layout/investor-ned/investor-ned.layout';
import { InvestorLayout } from '@layout/investor/investor.layout';
import { NedLayout } from '@layout/ned/ned.layout';
import { NotFoundPage } from '@pages/utility/not-found/not-found.page';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { AdvisorStartPage } from './advisor/advisor-start.page';
import { FounderStartPage } from './founder/founder-start.page';
import { InvestorStartPage } from './investor/investor-start-page';

export const StartPage = () => {
  const {
    state: { userRole },
  } = useUserAccount();

  switch (userRole) {
    case Roles.FOUNDER:
      return <FounderStartPage Layout={FounderLayout} />;
    case Roles.NED:
      return <AdvisorStartPage Layout={NedLayout} />;
    case Roles.INVESTOR_NED:
      return <InvestorStartPage Layout={InvestorNedLayout} />;
    case Roles.INVESTOR:
      return <InvestorStartPage Layout={InvestorLayout} />;
    default:
      return <NotFoundPage />;
  }
};

import { axiosInstance } from '@utils/axios/axios-instance';

export const batchImportNumericMetricNodesAction =
  (metricId: string) =>
  (
    payload: {
      month: number;
      year: number;
      forecast: null | number;
      value: null | number;
      note: null | string;
    }[],
  ) =>
    axiosInstance.put(`/metrics/numeric/${metricId}/batch-insert`, { dataPoints: payload });

import EmptyBrowsing from '@assets/icons/undraw-empty.svg';
import {
  getInvestorConnectionRequest,
  GET_INVESTOR_CONNECTION_REQUEST_CACHE_KEY,
  type PendingFollowInvitationsItems,
} from '@pages/content/lens/api/get-investor-connection-requests';
import { InvitingCompany } from '@pages/content/lens/parts/cards/inviting-company';
import { DEFAULT_ITEMS_PER_PAGE_IN_GRID, ItemsGrid } from '@parts/items-grid/items-grid';
import { useQueryPagination } from '@utils/hooks/use-query-pagination/use-query-pagination';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';

interface ICompaniesInvitesProps {
  refetchInvitationsCount: Function;
}

export const CompaniesInvites = ({ refetchInvitationsCount }: ICompaniesInvitesProps) => {
  const [emptyTitle, emptySubTitle] = useTranslation(['lens.investor.invites.title', 'lens.investor.invites.subTitle']);

  const { items, total, isLoading, refetch, ...paginationConfig } = useQueryPagination<PendingFollowInvitationsItems>({
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE_IN_GRID,
    pageParam: 'page',
    action: getInvestorConnectionRequest,
    cacheKey: GET_INVESTOR_CONNECTION_REQUEST_CACHE_KEY,
    queryConfig: { refetchOnWindowFocus: true },
  });

  return (
    <ItemsGrid
      pagination={paginationConfig}
      keyBase="invites"
      Item={InvitingCompany}
      itemProps={{
        refetch: () => {
          refetch();
          refetchInvitationsCount();
        },
      }}
      emptyConfig={{
        title: emptyTitle,
        subTitle: emptySubTitle,
        isEmpty: total === 0,
        iconUrl: EmptyBrowsing,
      }}
      data={items}
      isLoading={isLoading}
    />
  );
};

import styled from 'styled-components';

const Wrapper = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
`;

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  &[aria-disabled='true'] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  > svg:first-child {
    margin-right: 5px;
    vertical-align: middle;
  }

  > svg:last-child {
    height: 14px;
    width: 14px;
    margin-left: ${({ theme }) => theme.spacing.xsmall};
    // overflow Tooltip's component unnecessary margin
    margin-top: -2px;
    path {
      fill: currentColor;
    }
  }
`;

export default { Wrapper, Button };

import { useLayoutEffect } from 'react';

export const useOnWindowResize = (callback: () => void) => {
  useLayoutEffect(() => {
    const handleResize = () => {
      callback();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
};

import { DashboardsEmptyState } from '@pages/content/pulse/parts/dashboards/parts/dashboards-menu/parts/dashboards-empty-state';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { Section } from '@parts/section/section';
import { useQuery } from '@tanstack/react-query';
import { type Dispatch, type SetStateAction, useEffect } from 'react';
import { useParams } from 'react-router';
import {
  getProgressReportPublicDashboardDetails,
  GET_PROGRESS_REPORT_PUBLIC_DASHBOARD_DETAILS_CACHE_KEY,
} from '../../api/get-progress-report-public-dashboard-details/get-progress-report-public-dashboard-details.action';
import { Info } from '../info/info';
import { MetricsRaport } from '../metrics-raport/metrics-raport';
import S from './progress.styles';

export const Progress = ({ setFounderId }: { setFounderId: Dispatch<SetStateAction<string>> }) => {
  const { hash } = useParams() as { hash: string };
  const password = window.localStorage.getItem(hash);

  const { data: response, isLoading } = useQuery(
    [GET_PROGRESS_REPORT_PUBLIC_DASHBOARD_DETAILS_CACHE_KEY(hash)],
    getProgressReportPublicDashboardDetails(hash, password),
    {
      refetchOnWindowFocus: false,
      cacheTime: 60000,
    },
  );
  useEffect(() => {
    if (response?.data?.company?.id) {
      setFounderId(response?.data?.company?.id);
    }
  }, [response?.data?.company.id]);

  if (isLoading) return <FullHeightSpinner />;

  if (!response || !response.data) return <DashboardsEmptyState />;

  return (
    <>
      <Info name={response?.data?.company.name} avatarUrl={response?.data?.company.avatarUrl} />
      <S.DashboardName>{response?.data?.name}</S.DashboardName>
      <Section>
        <MetricsRaport dashboardId={response.data.id} hash={hash} password={password} />
      </Section>
    </>
  );
};

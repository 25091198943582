import {
  LN_QUESTION_CODE,
  NI_QUESTION_CODE,
  PSE_QUESTION_CODE,
  STF_QUESTION_CODE,
  WII_QUESTION_CODE,
  WWM_QUESTION_CODE,
  type MarketFitQuestionItem,
  type QuestionCode,
} from '@/pages/content/profile/founder/api/get-market-fit-questions/get-market-fit-questions.query';
import LnIcon from '@assets/icons/market-fit/ln.svg?react';
import NiIcon from '@assets/icons/market-fit/ni.svg?react';
import PseIcon from '@assets/icons/market-fit/pse.svg?react';
import StfIcon from '@assets/icons/market-fit/stf.svg?react';
import WiiIcon from '@assets/icons/market-fit/wii.svg?react';
import WwmIcon from '@assets/icons/market-fit/wwm.svg?react';
import { TranslationText } from '@parts/translation-text/translation-text';
import type { ReactNode } from 'react';
import S from './market-fit-answers.styles';

export const MarketFitAnswers = ({ answers }: { answers: Omit<MarketFitQuestionItem, 'lengthLimits'>[] }) => {
  const orderedAnswersList: { questionCode: QuestionCode; icon: ReactNode }[] = [
    { questionCode: STF_QUESTION_CODE, icon: <StfIcon data-testid="stf" /> },
    { questionCode: PSE_QUESTION_CODE, icon: <PseIcon data-testid="pse" /> },
    { questionCode: WII_QUESTION_CODE, icon: <WiiIcon data-testid="wii" /> },
    { questionCode: WWM_QUESTION_CODE, icon: <WwmIcon data-testid="wwm" /> },
    { questionCode: LN_QUESTION_CODE, icon: <LnIcon data-testid="ln" /> },
    { questionCode: NI_QUESTION_CODE, icon: <NiIcon data-testid="ni" /> },
  ];

  const givenAnswers = orderedAnswersList.reduce(
    (acc, item) => {
      const answer = answers.find((answerItem) => answerItem.questionCode === item.questionCode);
      const hasAnswer = answer?.booleanAnswer ?? false;

      return hasAnswer
        ? [...acc, { questionCode: item.questionCode, icon: item.icon, textAnswer: answer?.textAnswer ?? '' }]
        : acc;
    },
    [] as { questionCode: QuestionCode; icon: ReactNode; textAnswer: string }[],
  );

  return (
    <>
      <S.Wrapper>
        {givenAnswers.map(({ questionCode, icon, textAnswer }) => (
          <S.Item key={questionCode}>
            <S.IconWrapper>{icon}</S.IconWrapper>
            <S.ItemTitle>
              <TranslationText id={`profile.founder.marketFit.answer.${questionCode}`} />
            </S.ItemTitle>
            <S.Text>{textAnswer}</S.Text>
          </S.Item>
        ))}
      </S.Wrapper>
    </>
  );
};

export enum SubscriptionProductValue {
  NED = 'Ned',
  NED_TRANSITION_TO_PORTFOLIO = 'NedTransitionToPortfolio',
  INVESTOR = 'Investor',
  FOUNDER = 'Founder',
  FOUNDER_RAISE = 'FounderRaise',
  FOUNDER_PLUS = 'FounderPlus',
}

export enum SubscriptionProductType {
  INVESTOR = 'Investor',

  NED = 'Ned',
  NED_TRANSITION_TO_PORTFOLIO = 'NedTransitionToPortfolio',

  FOUNDER = 'Founder',
  FOUNDER_LEGAL = 'FounderLegal',
  FOUNDER_RAISE = 'FounderRaise',
  FOUNDER_PLUS = 'FounderPlus',
}

export enum SubscriptionProductStatus {
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  WAITING_FOR_APPROVAL = 'AwaitingApproval',
}

export type SubscriptionProduct = {
  type: SubscriptionProductType;
  status: SubscriptionProductStatus;
  discoverable: boolean;
};

export const createProductFromValue = (value: { type: string; status: string; discoverable: boolean }) => {
  const { type, status, ...rest } = value;

  const types: { [key: string]: SubscriptionProductType } = {
    Investor: SubscriptionProductType.INVESTOR,
    Ned: SubscriptionProductType.NED,
    NedTransitionToPortfolio: SubscriptionProductType.NED_TRANSITION_TO_PORTFOLIO,
    Founder: SubscriptionProductType.FOUNDER,
    FounderRaise: SubscriptionProductType.FOUNDER_RAISE,
    FounderPlus: SubscriptionProductType.FOUNDER_PLUS,
  };

  const statuses: { [key: string]: SubscriptionProductStatus } = {
    Approved: SubscriptionProductStatus.APPROVED,
    Rejected: SubscriptionProductStatus.REJECTED,
    AwaitingApproval: SubscriptionProductStatus.WAITING_FOR_APPROVAL,
  };

  return { type: types[type], status: statuses[status], ...rest };
};

export const getHighestSubscriptionProduct = (products: SubscriptionProduct[]): SubscriptionProduct => {
  const productWeights: { [key: string]: number } = {
    [SubscriptionProductType.INVESTOR]: 0,

    [SubscriptionProductType.NED]: 0,
    [SubscriptionProductType.NED_TRANSITION_TO_PORTFOLIO]: 1,

    [SubscriptionProductType.FOUNDER]: 0,
    [SubscriptionProductType.FOUNDER_RAISE]: 1,
    [SubscriptionProductType.FOUNDER_PLUS]: 2,
  };

  const productsSortedByWeight = products
    .filter((product) => product.status === SubscriptionProductStatus.APPROVED)
    .sort((a, b) => {
      const weightA = productWeights[b.type] ?? 0;
      const weightB = productWeights[a.type] ?? 0;

      return weightA - weightB;
    });

  return productsSortedByWeight[0];
};

const isFinalSubscriptionType = (
  products: SubscriptionProduct[],
  subscriptionType: SubscriptionProductType,
): boolean => {
  if (!products || products.length === 0) return false;
  if (!getHighestSubscriptionProduct(products)) return false;
  return getHighestSubscriptionProduct(products).type === subscriptionType;
};

export const isFounderFreemium = (products: SubscriptionProduct[]): boolean => {
  return isFinalSubscriptionType(products, SubscriptionProductType.FOUNDER);
};

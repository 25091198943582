import { FileSearchOutlined, InboxOutlined } from '@ant-design/icons';
import EditIconBase from '@assets/icons/edit-icon.svg?react';
import { Menu } from 'antd';
import { Link as LinkBased } from 'react-router-dom';
import styled from 'styled-components';

const MenuItem = styled(Menu.Item)`
  min-width: 130px;
  width: 100%;
`;

const Link = styled(LinkBased)`
  text-decoration: none;
`;

const Edit = styled(EditIconBase)`
  height: 12px;
  width: auto;
  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
`;
const Archive = styled(InboxOutlined)`
  height: 18px;
  width: auto;
  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
`;

const DetailsIcon = styled(FileSearchOutlined)`
  height: 18px;
  width: auto;
  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
`;

const DropdownOption = styled.span`
  color: ${({ theme }) => theme.color.dropdownItem};
`;

const ModalContent = styled.p`
  font-weight: bold;
  margin-bottom: 12px;
`;
export default { Edit, Archive, MenuItem, DropdownOption, ModalContent, Link, DetailsIcon };

import { V2NotificationIndicator } from '@/components/ui/v2-notification-indicator/v2-notification-indicator';
import { useInvestorAdvisorInvitesCount } from '@/modules/investor-advisor/connections/hooks/use-get-invites-count';
import {
  isActiveInvestor,
  isActiveNed,
  isInvestorWaitingForApproval,
  isNedWaitingForApproval,
  useUserAbility,
} from '@abilities';
import { AbilityAction, AbilitySubject } from '@abilities/ability/types';
import UserRegular from '@assets/icons/user-regular.svg?react';
import { communityLink } from '@constants/links';
import { ActiveLayout } from '@layout/active/active.layout';
import { DropMenu, type ItemWithIcon } from '@layout/parts/menu-dropdown/menu-dropdown';
import { InvestorRiskWarning } from '@parts/investor-risk-warning/investor-risk-warning';
import { Routes } from '@router/routes';
import {
  lensBrowseCompaniesKey,
  lensBrowseKey,
  lensSmartMatchKey,
  pulseCompaniesKey,
  pulseTriggersKey,
  rolesAppliedKey,
  rolesBrowseKey,
} from '@router/tabs-keys';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import type { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

export const InvestorNedLayout = ({
  children,
  legacyContainer = true,
}: {
  children?: ReactNode | ReactNode[];
  legacyContainer?: boolean;
}) => {
  const location = useLocation();
  const userAbility = useUserAbility();
  const { invitesCount } = useInvestorAdvisorInvitesCount();
  const {
    state: { products },
  } = useUserAccount();

  const [pulse, lens, myProfile, portfolioLabel, roles, academy, legalLabel, community, connections] = useTranslation([
    'navigation.pulse',
    'navigation.lens',
    'navigation.myProfile',
    'navigation.portfolio',
    'navigation.roles',
    'navigation.academy',
    'navigation.legal',
    'navigation.community',
    'navigation.connections',
  ]);

  const profileMenu: ItemWithIcon[] = [{ route: Routes.PROFILE, label: myProfile, icon: <UserRegular /> }];

  const isNedWaitingForApprovalStatus = isNedWaitingForApproval(products);
  const isActiveNedStatus = isActiveNed(products);

  const isInvestorWaitingForApprovalStatus = isInvestorWaitingForApproval(products);
  const isActiveInvestorStatus = isActiveInvestor(products);

  const hasPermissionsToPortfolio = userAbility.can(AbilityAction.ACCESS, AbilitySubject.PORTFOLIO);
  const hasPermissionsToLegalDocs = userAbility.can(AbilityAction.ACCESS, AbilitySubject.LEGAL_DOCS);
  const hasAccessToAcademy = userAbility.can(AbilityAction.ACCESS_PAGE, Routes.ACCESS_NED_ACADEMY);

  return (
    <ActiveLayout
      legacyContainer={legacyContainer}
      menu={[
        ...(isActiveNedStatus && isActiveInvestorStatus
          ? [
              {
                route: Routes.INVESTOR_ADVISOR_CONNECTIONS,
                label: (
                  <V2NotificationIndicator
                    count={invitesCount}
                    mode="dot"
                    position="top-right"
                    dotPlacementMode="outside"
                  >
                    {connections}
                  </V2NotificationIndicator>
                ),
              },
            ]
          : []),
        {
          route: communityLink as Routes,
          label: community,
          isExternalLink: true,
        },
        ...(isActiveInvestorStatus || isInvestorWaitingForApprovalStatus
          ? [
              ...(hasPermissionsToPortfolio
                ? [
                    {
                      route: Routes.PORTFOLIO,
                      label: portfolioLabel,
                      disabled: isInvestorWaitingForApprovalStatus,
                    },
                  ]
                : []),
              {
                route: Routes.PULSE,
                label: pulse,
                defaultParams: { content: pulseCompaniesKey },
                allowedParamValues: { content: [pulseCompaniesKey, pulseTriggersKey] },
                disabled: isInvestorWaitingForApprovalStatus,
              },
            ]
          : []),
        ...(isActiveInvestorStatus
          ? [
              {
                route: Routes.LENS,
                label: lens,
                defaultParams: { action: lensSmartMatchKey },
                allowedParamValues: { action: [lensSmartMatchKey, lensBrowseCompaniesKey] },
              },
            ]
          : []),
        ...(isActiveNedStatus || isNedWaitingForApprovalStatus
          ? [
              {
                route: Routes.ROLES,
                label: roles,
                disabled: isNedWaitingForApprovalStatus,
                defaultParams: { content: rolesBrowseKey },
                allowedParamValues: { content: [rolesBrowseKey, rolesAppliedKey] },
              },
              ...(hasPermissionsToLegalDocs ? [{ route: Routes.LEGAL, label: legalLabel }] : []),
              ...(!isActiveInvestorStatus
                ? [
                    {
                      route: Routes.LENS,
                      label: lens,
                      defaultParams: { action: lensBrowseKey },
                      allowedParamValues: { action: [lensBrowseKey] },
                    },
                  ]
                : []),
              {
                route: Routes.ACCESS_NED_ACADEMY,
                label: academy,
                disabled: !hasAccessToAcademy,
              },
            ]
          : []),
      ]}
      profileMenu={profileMenu}
      locationPathName={location.pathname}
      dropMenu={<DropMenu menu={profileMenu} />}
      infoStickyBannerTop={<InvestorRiskWarning />}
    >
      {children}
    </ActiveLayout>
  );
};

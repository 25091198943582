import type { GraphqlResponse } from '@/types';
import { useHistory } from '@/utils/hooks/use-history/use-history';
import { useQueryParams } from '@/utils/hooks/use-query/use-query-params';
import { useQuery } from '@tanstack/react-query';
import { stringify } from 'query-string';
import { useState } from 'react';

const useUrlPagination = () => {
  // TODO: change to use react-router-v6 when rebased on the v6 router branch
  const history = useHistory();
  const { page: urlParamsPage, ...restQueryParams } = useQueryParams<{ page: string }>();

  const [page, setPage] = useState(parseInt(urlParamsPage, 10) || 1);

  const goToPage = (targetPage: number) => {
    setPage(targetPage);
    history.push({ search: stringify({ ...restQueryParams, page: targetPage }) });
  };

  const replacePage = (targetPage: number) => {
    history.replace({ search: stringify({ ...restQueryParams, page: targetPage }) });
  };

  return {
    page,
    goToPage,
    replacePage,
  };
};

export type TQueryPaginationActionParam<T> = (
  page: number,
  startPage: number,
) => Promise<
  GraphqlResponse<{
    total: number;
    items: T[];
  }>
>;

type PaginatedQueryOptions = {
  perPage?: number;
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
  cacheTime?: number;
  staleTime?: number;
};

export const useQueryWithPagination = <T>(
  keys: string[],
  action: TQueryPaginationActionParam<T>,
  { perPage = 10, cacheTime = 0, staleTime = 0, refetchOnWindowFocus = false }: PaginatedQueryOptions,
) => {
  const { goToPage, replacePage, page } = useUrlPagination();
  const [totalPages, setTotalPages] = useState(0);

  const hasPreviousPage = () => page > 1;
  const hasNextPage = () => page < totalPages;
  const goToNextPage = () => goToPage(Math.min(totalPages, (page || 1) + 1));
  const goToPreviousPage = () => goToPage(Math.max(1, (page || 1) - 1));

  const queryResult = useQuery([...keys, page], () => action(page, perPage), {
    refetchOnWindowFocus,
    staleTime,
    cacheTime,
    onSuccess: (resp) => {
      if (resp?.data) {
        const max = Math.ceil(resp.data.total / perPage) || 1;

        setTotalPages(max);

        if (page > max) {
          goToPage(max);
        }
      }

      if (resp?.errors) {
        replacePage(1);
      }
    },
  });

  return {
    ...queryResult,
    pagination: {
      page,
      hasNextPage: hasNextPage,
      hasPreviousPage: hasPreviousPage,
      totalPages,

      goToPage,
      goToNextPage,
      goToPreviousPage,
      replacePage,
    },
  };
};

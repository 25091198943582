import { useV2AdvisorConnectionCardContext } from '../v2-advisor-connection-card-context';

import S from '../v2-advisor-connection-card.styles';

type RowProps = { children: React.ReactNode };

export const Row = ({ children }: RowProps) => {
  useV2AdvisorConnectionCardContext();

  return <S.Row>{children}</S.Row>;
};

import { Tooltip } from 'antd';
import type { ReactNode } from 'react';

const OptionalTooltip = ({ children, show, title }: { children: ReactNode; show: boolean; title: string }) =>
  show ? (
    <Tooltip data-testid="optional-tooltip" title={title}>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );

export default OptionalTooltip;

import { V2AdvisorPublishProfileModal } from '@/components/blocks/v2-advisor-publish-profile-modal/v2-advisor-publish-profile-modal';
import { V2Link } from '@/components/ui/v2-link/v2-link';
import { V2Tooltip } from '@/components/ui/v2-tooltip/v2-tooltip';
import { advisorBuildYourProfileTutorialLink } from '@/constants/links';
import { useModal } from '@/hooks/use-modal';
import { useGetAdvisorPersonalDetails } from '@/modules/advisor/homepage/hooks/use-get-advisor-personal-details';
import { useTogglePublishAdvisorProfile } from '@/modules/advisor/homepage/hooks/use-toggle-publish-advisor-profile';
import type { Score } from '@/modules/advisor/homepage/types';
import message from '@/parts/message/message';
import { TranslationText } from '@/parts/translation-text/translation-text';
import { Links } from '@/router/links';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { Content, type ContentStep } from './components/content/content';

import S from './modal-content.styles';

type ModalContentProps = {
  data: {
    profile: Score;
    smartMatch: Score;
    remuneration: Score;
    cv: Score;
    publish: Score;
  };
  afterPublish?: () => void;
};

export const ModalContent = ({ data, afterPublish }: ModalContentProps) => {
  const [
    titleLL,
    descriptionLL,
    profileTitleLL,
    profileDescriptionLL,
    smartMatchTitleLL,
    smartMatchDescriptionLL,
    remunerationTitleLL,
    remunerationDescriptionLL,
    cvTitleLL,
    cvDescriptionLL,
    publishTitleLL,
    publishDescriptionLL,
    publishSuccessLL,
    publishTooltipNotReadyLL,
    publishTooltipPublishedLL,
  ] = useTranslation([
    'homepage.buildYourProfileModal.title',
    'homepage.buildYourProfileModal.description',
    'homepage.buildYourProfileModal.steps.profile.title',
    'homepage.buildYourProfileModal.steps.profile.description',
    'homepage.buildYourProfileModal.steps.smartMatch.title',
    'homepage.buildYourProfileModal.steps.smartMatch.description',
    'homepage.buildYourProfileModal.steps.remuneration.title',
    'homepage.buildYourProfileModal.steps.remuneration.description',
    'homepage.buildYourProfileModal.steps.cv.title',
    'homepage.buildYourProfileModal.steps.cv.description',
    'homepage.buildYourProfileModal.steps.publish.title',
    {
      id: 'homepage.buildYourProfileModal.steps.publish.description',
      variables: {
        link: (
          <V2Link to={advisorBuildYourProfileTutorialLink} outer>
            <TranslationText id="homepage.buildYourProfileModal.steps.publish.description.linkText" />
          </V2Link>
        ),
      },
    },
    'homepage.buildYourProfileModal.steps.publish.success',
    'homepage.buildYourProfileModal.steps.publish.tooltip.notReady',
    'homepage.buildYourProfileModal.steps.publish.tooltip.published',
  ]);
  const { close: closePublishModal, open: openPublishModal, isOpen: isPublishModalOpen } = useModal();
  const { advisorPersonalDetailsData } = useGetAdvisorPersonalDetails();
  const cvUrl = advisorPersonalDetailsData?.data?.resumeUrl ?? null;
  const cvFileName = advisorPersonalDetailsData?.data?.resumeName ?? null;

  const { togglePublishAdvisorProfile, isTogglePublishAdvisorProfileLoading } = useTogglePublishAdvisorProfile({
    published: data.publish.received === data.publish.available,
    onSuccess: async () => {
      afterPublish?.();
      message.success({ content: publishSuccessLL });
      closePublishModal();
    },
  });

  const isCompleted = (step: Score) => step.received === step.available;

  const disablePublishToggle = () => {
    if (isCompleted(data.publish)) {
      return true;
    }

    return !isCompleted(data.smartMatch) || !isCompleted(data.profile);
  };

  const getPublishTooltipText = (): string => {
    if (isCompleted(data.publish)) {
      return publishTooltipPublishedLL;
    }

    if (!isCompleted(data.profile) || !isCompleted(data.smartMatch)) {
      return publishTooltipNotReadyLL;
    }

    return '';
  };

  const steps: ContentStep[] = [
    {
      id: 'profile-information',
      title: profileTitleLL,
      description: profileDescriptionLL,
      icon: 'person',
      received: data.profile.received,
      available: data.profile.available,
      actionUrl: Links.PROFILE() + '?st=about-me',
      required: true,
    },
    {
      id: 'smart-match-criteria',
      title: smartMatchTitleLL,
      description: smartMatchDescriptionLL,
      icon: 'arrow-left-right',
      received: data.smartMatch.received,
      available: data.smartMatch.available,
      actionUrl: Links.PROFILE() + '?st=smart-match',
      required: true,
    },
    {
      id: 'remuneration',
      title: remunerationTitleLL,
      description: remunerationDescriptionLL,
      icon: 'cash',
      received: data.remuneration.received,
      available: data.remuneration.available,
      actionUrl: Links.PROFILE() + '?st=smart-match',
    },
    {
      id: 'upload-cv',
      title: cvTitleLL,
      description: (
        <span>
          {cvDescriptionLL}{' '}
          {cvUrl ? (
            <V2Link to={cvUrl} variant="primary" outer>
              {cvFileName}
            </V2Link>
          ) : null}
        </span>
      ),
      icon: 'cv-document',
      received: data.cv.received,
      available: data.cv.available,
      actionUrl: Links.PROFILE() + '?st=personal-details',
    },
    {
      id: 'publish-profile',
      title: publishTitleLL,
      description: publishDescriptionLL,
      icon: 'eye',
      received: data.publish.received,
      available: data.publish.available,
      customActionElement: (
        <>
          <V2Tooltip tooltipText={getPublishTooltipText()} includeIcon={false}>
            <S.Switch
              checked={data.publish.received === data.publish.available}
              disabled={disablePublishToggle()}
              onChange={openPublishModal}
            />
          </V2Tooltip>
          <V2AdvisorPublishProfileModal
            isOpen={isPublishModalOpen}
            disabled={disablePublishToggle() || isTogglePublishAdvisorProfileLoading}
            onClose={closePublishModal}
            onClick={togglePublishAdvisorProfile}
          />
        </>
      ),
    },
  ];

  return <Content introTitle={titleLL} introDescription={descriptionLL} steps={steps} />;
};

import { Link as LinkBase } from 'react-router-dom';
import styled from 'styled-components';

import CompanyTileBase from '@pages/content/roles/parts/company-tile/company-tile';

import BillBase from '@assets/icons/gray-bill.svg?react';
import ClockBase from '@assets/icons/gray-clock.svg?react';
import PersonBase from '@assets/icons/gray-person.svg?react';
import PinBase from '@assets/icons/gray-pin.svg?react';
import { grayIcon } from '@parts/grid-item/parts/meta/meta.styles';

const CompanyTile = styled(CompanyTileBase)`
  padding: ${({ theme }) => theme.spacing.medium};
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
`;

const Pin = styled(PinBase)`
  ${grayIcon}
`;

const Bill = styled(BillBase)`
  ${grayIcon}
`;

const Clock = styled(ClockBase)`
  ${grayIcon}
`;

const Person = styled(PersonBase)`
  ${grayIcon}
`;

const Link = styled(LinkBase)`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontSize.medium};
  color: ${({ theme }) => theme.color.primary};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  text-decoration: none;
`;

export default {
  CompanyTile,
  Pin,
  Bill,
  Clock,
  Person,
  Link,
};

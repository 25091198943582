import { investorAcceptInviteFromFounderAction } from '@/pages/content/lens/api/investor-accept-invite-from-founder.action';
import { investorDeclineInvitationFromFounderAction } from '@/pages/content/lens/api/investor-decline-invitation-from-founder.action';
import { SubmitButton } from '@parts/submit-button/submit-button';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';

export const AcceptOrIgnoreInvitation = ({ id, refetch }: { id: string; refetch: Function }) => {
  const [inviteAccept, inviteIgnore] = useTranslation(['lens.investor.invite.accept', 'lens.investor.invite.ignore']);

  const mutationConfig = { onSettled: () => refetch() };

  const { mutateAsync: acceptConnectionInvite, isLoading: acceptingInProgress } = useMutation(
    investorAcceptInviteFromFounderAction,
    mutationConfig,
  );

  const { mutateAsync: cancelConnectionInvite, isLoading: cancellingInProgress } = useMutation(
    investorDeclineInvitationFromFounderAction,
    mutationConfig,
  );

  return (
    <>
      <SubmitButton
        data-testid="lens-invites-accept"
        disabled={acceptingInProgress}
        onClick={() => acceptConnectionInvite(id)}
      >
        {inviteAccept}
      </SubmitButton>
      <SubmitButton
        data-testid="lens-invites-ignore"
        disabled={cancellingInProgress}
        onClick={() => cancelConnectionInvite(id)}
        type="link"
      >
        {inviteIgnore}
      </SubmitButton>
    </>
  );
};

import { UpdateEditor } from '@pages/content/portfolio/investor/parts/editor/update-editor';
import { InvestmentsTable } from '@pages/content/portfolio/investor/parts/table/parts/sub-table/sub-table';
import { investmentIdQueryParam } from '@pages/content/pulse/founder/parts/editor/create-editor';
import { useDrawer } from '@parts/drawer/use-drawer';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { Links } from '@router/links';
import { useQuery } from '@tanstack/react-query';
import { currencyToShortFormat } from '@utils/fns/currency-to-short-format';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { createRef } from 'react';
import { useParams } from 'react-router-dom';
import { GET_INVESTMENT_SUMMARY_CACHE_KEY, getInvestmentsSummary } from '../../api/get-investments-summary.action';
import S from './investments-in-company.styles';

const renderSource = 'company-details';

export const InvestmentsInCompany = () => {
  const [investmentHistoryLabel, totalSharesLabel, totalInvestedLabel, totalOwnedLabel, currentValueLabel] =
    useTranslation([
      'portfolio.investor.investmentsInCompany.investmentHistory',
      'portfolio.investor.investmentsInCompany.totalShares',
      'portfolio.investor.investmentsInCompany.totalInvested',
      'portfolio.investor.investmentsInCompany.totalOwned',
      'portfolio.investor.investmentsInCompany.currentValue',
    ]);

  const { relationshipId } = useParams<{ relationshipId: string }>();

  const { [investmentIdQueryParam]: investmentId } = useQueryParams() as {
    [investmentIdQueryParam]: string;
  };

  const { Drawer, hide: hideDrawer } = useDrawer(Boolean(investmentId));

  const {
    deviceData: { isDesktop },
  } = useDeviceDetect();

  const {
    data: response,
    isLoading,
    refetch: refetchSummary,
  } = useQuery([GET_INVESTMENT_SUMMARY_CACHE_KEY], getInvestmentsSummary(relationshipId));

  const investmentsInCompanyRef = createRef<{ refetchInvestments: Function }>();

  const { totalShares, totalInvested, totalOwned, totalCurrentValue } = response?.data || {};

  return (
    <S.Section
      header={investmentHistoryLabel}
      cornerAddition={{
        content: isDesktop && (
          <S.Expand to={`${Links.PORTFOLIO_EXPANDED_VIEW_SINGLE(relationshipId)}`}>
            <S.Icon /> Expand view
          </S.Expand>
        ),
        width: 140,
      }}
    >
      {investmentId && (
        <UpdateEditor
          investmentId={investmentId}
          drawer={Drawer}
          hideDrawer={hideDrawer}
          refetch={() => {
            investmentsInCompanyRef.current?.refetchInvestments();
            refetchSummary();
          }}
        />
      )}
      {!response?.data || isLoading ? (
        <FullHeightSpinner small />
      ) : (
        <>
          <S.Corner>
            <S.Info label={totalSharesLabel} value={totalShares?.toString() || ''} />
            <S.Info label={totalInvestedLabel} value={currencyToShortFormat(totalInvested?.toString() || '', '£')} />
            <S.Info label={totalOwnedLabel} value={`${totalOwned || 0}%`} />
            <S.Info label={currentValueLabel} value={currencyToShortFormat(totalCurrentValue?.toString() || '', '£')} />
          </S.Corner>
          <InvestmentsTable
            investingRelationshipId={relationshipId}
            refetchCompanyDetailsSummary={refetchSummary}
            ref={investmentsInCompanyRef}
            renderSource={renderSource}
            paginated
          />
        </>
      )}
    </S.Section>
  );
};

import TickBase from '@assets/icons/green-tick-icon.svg?react';
import { gradientBorderStyles } from '@pages/content/onboarding/parts/aside/parts/aside-item/aside-item.styles';
import { tickStyles } from '@pages/content/onboarding/parts/gradient-tile/tile.styles';
import { SecureLink as SecureLinkBase } from '@parts/link/link';
import styled, { css } from 'styled-components';

const Tile = styled.div<{ $selected: boolean }>`
  width: 280px;
  height: 540px;
  position: relative;
  background: ${({ theme }) => theme.onboardingColors.investorTypeTileBackground};
  border-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.xbase} ${({ theme }) => theme.spacing.xmedium};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  text-align: center;
  white-space: pre-line;
  line-height: 1.7;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    margin: ${({ theme }) => theme.spacing.small} 0;
  }

  &:before {
    ${gradientBorderStyles}

    ${({ $selected }) =>
      $selected &&
      css`
        background: ${({ theme }) => theme.gradient.onboardingInvestorTileGradient};
      `}
  }
`;

const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.baseColors.Blue300};
  margin-top: ${({ theme }) => theme.spacing.small};
`;

const Description = styled.div`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.baseColors.Blue350};
  margin-top: ${({ theme }) => theme.spacing.xmedium};
`;

const SecureLink = styled(SecureLinkBase)`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  text-decoration: none;
  color: ${({ theme }) => theme.baseColors.Blue200};
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
  white-space: nowrap;
  z-index: 1000;

  &:hover {
    color: ${({ theme }) => theme.baseColors.Grey300};
  }
`;

const Tick = styled(TickBase)`
  ${tickStyles}
`;

export default { Tile, Tick, Title, Description, SecureLink };

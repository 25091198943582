import { Roles } from '@domain/accounts/roles';
import { SecureLink } from '@parts/link/link';
import { Routes } from '@router/routes';
import { currencyToShortFormat } from '@utils/fns/currency-to-short-format';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import type { ReactNode } from 'react';
import S from './investments.styles';

export const Investments = ({
  showDrawer,
  children,
  count,
  invested,
  value,
  shares,
  owned,
  raised,
  hideExpandButton,
  addLabel,
  expandViewOpen,
  singleMode,
}: {
  showDrawer: Function;
  children: ReactNode | ReactNode[];
  addLabel: string;
  count?: number;
  shares?: number;
  owned?: number;
  invested?: number;
  value?: number;
  raised?: number;
  hideExpandButton?: boolean;
  expandViewOpen?: boolean;
  singleMode?: {
    Component: ReactNode;
  };
}) => {
  const [
    countLabel,
    sharesLabel,
    ownedLabel,
    raisedLabel,
    investedLabel,
    valueLabel,
    expandViewLabel,
    capTableLabel,
    investmentsTable,
    summaryViewLabel,
  ] = useTranslation([
    'portfolio.investor.investments.totalInvestments',
    'portfolio.investor.investments.totalShares',
    'portfolio.investor.investments.totalOwned',
    'portfolio.founder.investments.raised',
    'portfolio.investor.investments.totalInvested',
    'portfolio.investor.investments.portfolioValue',
    'portfolio.investor.investments.expandView',
    'shareholders.founder.table.investments.capTable',
    'portfolio.investor.investments.header',
    'portfolio.investor.investments.summaaryView',
  ]);

  const {
    deviceData: { isDesktop },
  } = useDeviceDetect();

  const {
    state: { userRole },
  } = useUserAccount();

  return (
    <S.Wrapper>
      {singleMode ? singleMode.Component : null}
      {!expandViewOpen && (
        <S.TableHeader>{userRole === Roles.FOUNDER ? capTableLabel : investmentsTable}</S.TableHeader>
      )}
      <S.Header>
        <S.Corner expandViewOpen={expandViewOpen}>
          {singleMode && typeof shares === 'number' && (
            <S.Info data-testid="shares" label={sharesLabel} value={shares.toString()} />
          )}
          {!singleMode && typeof count === 'number' && (
            <S.Info data-testid="total_investments" label={countLabel} value={count.toString()} />
          )}
          {typeof invested === 'number' && (
            <S.Info
              data-testid="total_invested"
              label={investedLabel}
              value={currencyToShortFormat(invested.toString(), '£')}
            />
          )}
          {singleMode && typeof owned === 'number' && (
            <S.Info data-testid="owned" label={ownedLabel} value={`${owned.toString()}%`} />
          )}

          {typeof value === 'number' && (
            <S.Info
              data-testid="portfolio_value"
              label={valueLabel}
              value={currencyToShortFormat(value.toString(), '£')}
            />
          )}
          {typeof raised === 'number' && (
            <S.Info data-testid="raised" label={raisedLabel} value={currencyToShortFormat(raised.toString(), '£')} />
          )}
        </S.Corner>
        {!singleMode && (
          <S.Actions>
            {isDesktop && !hideExpandButton && (
              <SecureLink href={Routes.PORTFOLIO_EXPANDED_VIEW}>
                <S.ExpandView $svgIncHoverEffect data-testid="Invesment_ExpandedView_Button">
                  <S.ExpandIcon />
                  {expandViewLabel}
                </S.ExpandView>
              </SecureLink>
            )}
            {expandViewOpen && isDesktop && (
              <SecureLink data-testid="summry_view_button" href={Routes.PORTFOLIO}>
                <S.ExpandView $svgDecHoverEffect>
                  <S.ExpandIcon />
                  {summaryViewLabel}
                </S.ExpandView>
              </SecureLink>
            )}
            <S.AddInvestment type="primary" onClick={() => showDrawer()} data-testid="show-drawer">
              {addLabel}
            </S.AddInvestment>
          </S.Actions>
        )}
      </S.Header>
      {children}
    </S.Wrapper>
  );
};

import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './book-consultation.styles';

export const BookConsultation = ({ url }: { url: string }) => {
  const [description, buttonText] = useTranslation([
    'advisors.bookConsultation.description',
    'advisors.bookConsultation.buttonText',
  ]);

  return (
    <S.Section data-testid="book-consultation-section">
      <S.Description>{description}</S.Description>
      <S.Button>
        <S.Link href={url} outer target="_blank" data-testid="book-consultation-link">
          {buttonText}
        </S.Link>
      </S.Button>
    </S.Section>
  );
};

import type { ReactElement } from 'react';

interface ConditionalRenderProps {
  condition: boolean | (() => boolean);
  element: ReactElement;
  fallback: ReactElement;
}

export const ConditionalRender = ({ condition, element, fallback }: ConditionalRenderProps) => {
  const evaluationResult = condition instanceof Function ? condition() : condition;

  return evaluationResult ? element : fallback;
};

import type { AdvisorSearchProfile } from '@pages/content/lens/api/get-founder-browse-neds';
import { CompanyTile as AdvisorTile } from '@parts/company-tile/company-tile';
import { GridItem } from '@parts/grid-item/grid-item';
import { Links } from '@router/links';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './matched-advisor.styles';
import { EngagementDecision } from './parts/engagement-decision/engagement-decision';
import metaConfig from './parts/meta-config/meta-config';

export const ADVISOR_TILE_TEST_ID = 'advisor-tile';

interface MatchedAdvisorProps extends AdvisorSearchProfile {
  onPreview?: (id: string) => void;
  isLoading: boolean;
  refetch: () => void;
}

export const MatchedAdvisor = ({
  id,
  primarySkillSet,
  secondarySkillSet,
  compensationType,
  industries,
  name,
  avatarUrl,
  isNew,
  isBookmarked,
  connection,
  isLoading,
  refetch,
  onPreview,
}: MatchedAdvisorProps) => {
  const [newBadgeLabel] = useTranslation(['profile.badgeNew']);

  return (
    <GridItem
      dataTestId={ADVISOR_TILE_TEST_ID}
      header={<AdvisorTile url={Links.NED_PUBLIC_PROFILE(id)} name={name} supplement="" logoUrl={avatarUrl} />}
      meta={metaConfig({
        industries,
        primarySkillSet,
        secondarySkillSet,
        compensationType,
      })}
      onExpand={onPreview ? () => onPreview(id) : undefined}
      cornerAddition={isNew ? <S.Badge data-testid="new-badge">{newBadgeLabel}</S.Badge> : null}
      footer={
        <EngagementDecision
          key={id + isBookmarked}
          id={id}
          isBookmarked={isBookmarked}
          connectionStatus={connection?.status ?? null}
          initiator={connection?.initiator ?? null}
          connectionId={connection?.id ?? null}
          isLoading={isLoading}
          refetch={refetch}
        />
      }
    />
  );
};

import { V2Button } from '@/components/ui/v2-button/v2-button';
import { useMediaQuery } from '@/hooks/use-media-query';
import { useAdvisorBookmark } from '@/modules/founder/advisor-search/hooks/use-advisor-bookmark';
import { useEngageConversation } from '@/utils/hooks/use-engage-conversation/use-engage-conversation';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { engageNedMessagingAction } from '@pages/content/roles/parts/applications/api/message-engage-ned';
import { useQueryClient } from '@tanstack/react-query';
import { GET_ADVISOR_PROFILE_PREVIEW_DATA_KEY } from '../../api/get-advisor-profile-preview-details';
import type { TConnectionStatus } from '../../types';
import { ConnectionButton } from '../connection-button/connection-button';

import S from './connection-manager.styles';

export const ConnectionsManager = ({
  advisorId,
  isSaved,
  connectionStatus,
  initiator,
  connectionId,
  onBookmarkSuccess = () => {},
}: {
  advisorId: string;
  isSaved: boolean;
  connectionStatus: TConnectionStatus['status'];
  initiator: TConnectionStatus['initiator'];
  connectionId: string | null;
  onBookmarkSuccess?: () => void;
}) => {
  const [saveLL, savedLL, messageLL] = useTranslation(['advisors.match.save', 'advisors.match.saved', 'message.label']);
  const { mutateAsync: messageAction, isLoading: messageLoading } = useEngageConversation(
    engageNedMessagingAction,
    true,
  );
  const { handleBookmark, isBookmarkPending } = useAdvisorBookmark(advisorId, isSaved, onBookmarkSuccess);

  const size = useMediaQuery();
  const buttonSize = size === 'xs' ? 'small' : 'large';

  const client = useQueryClient();

  return (
    <S.ConnectionsManager data-testid="advisor-preview-modal-connections-manager">
      <div>
        <S.SaveButton
          size={buttonSize}
          variant="outlined"
          onClick={() => handleBookmark()}
          disabled={isBookmarkPending}
          $saved={isSaved}
          data-testid="advisor-preview-modal-save-button-save"
        >
          {isSaved ? savedLL : saveLL}
        </S.SaveButton>
      </div>

      <div>
        <V2Button
          size={buttonSize}
          variant="outlined"
          startIcon="chat-left-dots"
          disabled={messageLoading}
          onClick={() => messageAction(advisorId)}
          data-testid="advisor-preview-modal-save-button-message"
        >
          {messageLL}
        </V2Button>
        <ConnectionButton
          allowDisconnect={false}
          data-testid="advisor-preview-modal-save-button-connect"
          connectionStatus={connectionStatus}
          initiator={initiator}
          advisorId={advisorId}
          connectionId={connectionId}
          buttonSize={buttonSize}
          disabled={Boolean(client.isFetching([GET_ADVISOR_PROFILE_PREVIEW_DATA_KEY, advisorId]))}
          callback={() => client.refetchQueries([GET_ADVISOR_PROFILE_PREVIEW_DATA_KEY, advisorId])}
        />
      </div>
    </S.ConnectionsManager>
  );
};

import type { ProductDetailsDraft } from '@domain/subscriptions/product-details';
import type { ValidatePromoCodeResponse } from '../api/create-new-subscription/create-new-subscription.action';
import {
  ADD_DISCOUNT,
  CLEAR_DISCOUNT,
  SET_LOADING_ERROR,
  SET_PRODUCTS,
  SET_SELECTED_PRODUCT_VALUE,
  TOGGLE_LOADING,
  type CheckoutActionType,
} from './checkout.reducer';

export const setCheckoutLoadingError: (err: string | null) => CheckoutActionType = (err) => ({
  type: SET_LOADING_ERROR,
  err,
});

export const setCheckoutProducts: (products: ProductDetailsDraft[]) => CheckoutActionType = (products) => ({
  type: SET_PRODUCTS,
  products,
});

export const setSelectedProductValue: (value: string) => CheckoutActionType = (value) => ({
  type: SET_SELECTED_PRODUCT_VALUE,
  value,
});

export const toggleCheckoutLoading: (loading: boolean) => CheckoutActionType = (loading) => ({
  type: TOGGLE_LOADING,
  loading,
});

export const addDiscount: (discount: ValidatePromoCodeResponse) => CheckoutActionType = (discount) => ({
  type: ADD_DISCOUNT,
  discount,
});

export const clearDiscount: () => CheckoutActionType = () => ({
  type: CLEAR_DISCOUNT,
});

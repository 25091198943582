import type { Node } from '@pages/content/pulse/founder/parts/management/parts/metrics/parts/numeric-editor/parts/live-chart/live-chart';
import { axiosInstance } from '@utils/axios/axios-instance';
import type { AxiosConfig } from '@utils/axios/types';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_PROGRESS_REPORT_NUMERIC_METRIC_NODES_CACHE_KEY = (id: string) =>
  `progress-report-numeric-metric-nodes-${id}`;

export type MetricData = {
  getMetricDataPoints: Node[];
  metricMetadata: {
    scale: number;
  };
};

export const getProgressReportNumericMetricNodes =
  (hash: string, password: string | null, id: string) => async (): Promise<GraphqlResponse<MetricData>> => {
    const { data } = await axiosInstance.post(
      '/query',
      {
        query: `
      query getProgressReportNumericMetricNodes {
        getPublicDashboardMetricDataPoints(hash: "${hash}", password: "${password}", metricId: "${id}") {
          month,
          year,
          value,
          forecast,
          note,
          id,
        }
        getPublicDashboardMetricMetadata(hash: "${hash}", password: "${password}", metricId: "${id}") {
          scale
        }
      }
    `,
      },
      { skipAuthorization: true } as AxiosConfig,
    );

    return graphqlResponseWrapper(data, [
      ['getPublicDashboardMetricDataPoints', 'getMetricDataPoints'],
      ['getPublicDashboardMetricMetadata', 'metricMetadata'],
    ]);
  };

import { Alert } from 'antd';
import styled from 'styled-components';

const V2Alert = styled(Alert)`
  width: 100%;
  border-radius: ${({ theme }) => theme.v2.borders.radius.lg};

  .ant-alert-message {
    font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
  }
`;

export default { V2Alert };

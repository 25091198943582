import SignOut from '@assets/icons/sign-out.svg?react';
import type { MenuItem } from '@layout/active/active.layout';
import { ProfilePicture } from '@parts/profile-picture/profile-picture';
import { populateRoute } from '@router/utils/populate-route';
import { useLogout } from '@utils/hooks/use-logout/use-logout';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import type { ReactNode } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ThemeSwitch } from '../theme-switch/theme-switch';
import S from './menu-dropdown.styles';

export interface ItemWithIcon extends MenuItem {
  icon?: ReactNode;
}

export const DropMenu = ({ menu, extraMenuItem }: { menu: ItemWithIcon[]; extraMenuItem?: ReactNode }) => {
  const logoutLabel = useTranslation('ui.logout');

  const params = useParams() as { [key: string]: string };

  const { logout } = useLogout();

  const {
    state: { avatarUrl },
  } = useUserAccount();

  const dropdownMenu = (
    <S.Menu>
      {extraMenuItem && <S.Menu.Item className="dropdown-extra-item">{extraMenuItem}</S.Menu.Item>}

      <S.Menu.Item key="toggleMode" disabled>
        <ThemeSwitch />
      </S.Menu.Item>

      {menu.map((elem) => {
        const populatedRoute = populateRoute(params, elem);
        return (
          <S.Menu.Item key={populatedRoute}>
            <Link to={populatedRoute}>
              {elem.icon}
              {elem.label}
            </Link>
          </S.Menu.Item>
        );
      })}

      <S.Menu.Item key="logout" onClick={() => logout()}>
        <span data-testid="sign_out">
          <SignOut /> {logoutLabel}
        </span>
      </S.Menu.Item>
    </S.Menu>
  );

  return (
    <S.Trigger
      data-testid="avatar-trigger"
      overlay={dropdownMenu}
      trigger={['click']}
      getPopupContainer={(trigger: HTMLElement) => trigger.parentNode as HTMLElement}
    >
      <div>
        <ProfilePicture src={avatarUrl} />
      </div>
    </S.Trigger>
  );
};

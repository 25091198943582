import { Input as InputBase } from 'antd';
import styled from 'styled-components';

const Input = styled(InputBase)`
  .ant-input[disabled] {
    color: ${({ theme }) => theme.baseColors.Grey200};
    margin-left: 12px;
    height: 40px;
  }
  color: ${({ theme }) => theme.color.fontInput};
  background-color: ${({ theme }) => theme.color.disabled};
  &:hover {
    border-color: ${({ theme }) => theme.baseColors.Grey275};
  }
  height: 50px;
`;

const FlagIcon = styled.img`
  width: 20px;
  height: 15px;
  vertical-align: sub;
`;

export default { Input, FlagIcon };

import { V2InvestorAdvisorProfileLink } from '@/components/ui/v2-investor-advisor-profile-link/v2-investor-advisor-profile-link';
import { AdvisorRole, Roles } from '@/domain/accounts/roles';
import { useScrollToPageTop } from '@/utils/hooks/use-scroll-to-page-top/use-scroll-to-page-top';
import { PageHeader } from '@parts/page-header/page-header';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { useUserCurrency } from '@utils/hooks/use-user-currency/use-user-currency';
import type { NedPublicProfileData } from '../api/get-ned-public-page-details';
import { AboutMe } from './about-me/about-me';
import { InteractionsManager } from './interactions-manager/interactions-manager';
import { PersonalDetails } from './personal-details/personal-details';
import { Preferences } from './preferences/preferences';

import S from './ned-public-profile.styles';
import { ExperiencePublicSection } from '@/modules/advisor/profile/components/experience-section/experience-public-section';

interface NedPublicProfileProps {
  id: string;
  publicData: NedPublicProfileData;
  className?: string;
  handleBackAction: () => void;
  showBioInPersonalDetails?: boolean;
}

export const NedPublicProfile = ({
  id,
  publicData,
  className,
  handleBackAction,
  showBioInPersonalDetails = false,
}: NedPublicProfileProps) => {
  const { currencySymbol } = useUserCurrency();
  useScrollToPageTop();

  const {
    name,
    aboutMe,
    myOffering,
    myExpectations,
    linkedinUrl,
    twitterUrl,
    countryOfResidence,
    industries,
    resume,
    profilePicture,
    experience,
    isAdvisor,
    isFractional,
    isNed,
    primarySkillSet,
    secondarySkillSet,
    compensations,
    targetGeography,
    isBookmarked,
    connection,
  } = publicData;

  const {
    state: { userRole },
  } = useUserAccount();

  const isFounder = userRole === Roles.FOUNDER;

  const preferredRolesMap = new Map<AdvisorRole, boolean>([
    [AdvisorRole.ADVISOR, isAdvisor],
    [AdvisorRole.NED, isNed],
    [AdvisorRole.FRACTIONAL, isFractional],
  ]);

  const roles = [...preferredRolesMap.entries()].reduce<AdvisorRole[]>(
    (acc, [role, value]) => (value ? [...acc, role] : acc),
    [],
  );

  return (
    <div className={className}>
      <S.HeaderWrapper>
        <PageHeader
          title={
            <S.NameWrap>
              <span>{name}</span>
              {publicData.isInvestorAdvisor && <V2InvestorAdvisorProfileLink current={Roles.NED} profileId={id} />}
            </S.NameWrap>
          }
          onBack={handleBackAction}
        />
        {isFounder ? (
          <InteractionsManager
            isBookmarked={isBookmarked}
            connectionStatus={connection?.status ?? null}
            connectionId={connection?.id ?? null}
            initiator={connection?.initiator ?? null}
          />
        ) : null}
      </S.HeaderWrapper>
      <PersonalDetails
        countryOfResidence={countryOfResidence}
        linkedinUrl={linkedinUrl}
        twitterUrl={twitterUrl}
        resume={resume}
        profilePicture={profilePicture}
        bio={showBioInPersonalDetails ? aboutMe : null}
      />

      <AboutMe
        bio={showBioInPersonalDetails ? null : aboutMe}
        myExpectations={myExpectations}
        myOffering={myOffering}
      />

      <Preferences
        currencySymbol={currencySymbol}
        roles={roles}
        industries={industries}
        primarySkillSet={primarySkillSet}
        secondarySkillSet={secondarySkillSet}
        compensations={compensations}
        targetGeography={targetGeography}
      />

      {experience?.length !== 0 && <ExperiencePublicSection experience={experience} />}
    </div>
  );
};

import { useScrollToElementWithQueryParams } from '@/utils/hooks/use-scroll-to-element/use-scroll-to-element-with-query-params';
import { updateAdditionalInfoAction } from '@pages/content/profile/founder/api/update-additional-info/update-additional-info.action';
import { uploadFinancialModel } from '@pages/content/profile/founder/api/upload-financial-model/upload-financial-model.action';
import { uploadOtherFileAction } from '@pages/content/profile/founder/api/upload-other-file/upload-other-file.action';
import { uploadPitchDeckAction } from '@pages/content/profile/founder/api/upload-pitch-deck/upload-pitch-deck.action';
import type { AdditionalInfoData } from '@pages/content/profile/founder/founder-profile.page';
import { FormikField } from '@pages/content/profile/parts/formik-field/formik-field';
import Checkbox from '@parts/checkbox/checkbox';
import { FileUpload } from '@parts/file-upload/file-upload';
import { LabelWithTooltip } from '@parts/label-with-tooltip/label-with-tooltip';
import message from '@parts/message/message';
import { Section } from '@parts/section/section';
import { SubmitButton } from '@parts/submit-button/submit-button';
import { Tooltip } from '@parts/tooltip/tooltip';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { VIMEO_URL_REGEXP, YOUTUBE_URL_REGEXP } from '@utils/fns/reg-exp-validators';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Col, Input, Row } from 'antd';
import { useFormik } from 'formik';
import { useState, type ReactNode } from 'react';
import * as Yup from 'yup';

import S from './additional-info.styles';

const allowedMimeTypes = `
application/pdf,
application/msword,
application/vnd.openxmlformats-officedocument.wordprocessingml.document,
application/vnd.ms-excel,
application/vnd.ms-powerpoint,
application/vnd.openxmlformats-officedocument.presentationml.presentation,
application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`;

const fileValidationSchema = Yup.object().shape({
  path: Yup.string().required(),
  name: Yup.string().required(),
  url: Yup.string(),
});

const AdditionalInfo = ({ data }: { data: AdditionalInfoData }) => {
  const scrollToRef = useScrollToElementWithQueryParams<HTMLElement>('additional-info');

  const [
    formUpdatedLabel,
    saveLabel,
    titleLabel,
    subTitleLabel,
    productVideoLabel,
    pitchDeckLabel,
    otherFilesLabel,
    otherFilesErrorLabel,
    invalidVideoLinkLabel,
    otherFilesTooltipLabel,
    productVideoTooltipLabel,
    financialModelLabel,
    investorReadyLabel,
    investorReadyTooltip,
  ] = useTranslation([
    'messages.form.success',
    'profile.founder.section.additionalInfo.save',
    'profile.founder.section.additionalInfo.title',
    'profile.founder.section.additionalInfo.subTitle',
    'profile.founder.section.additionalInfo.productVideoLabel',
    'profile.founder.section.additionalInfo.pitchDeckLabel',
    'profile.founder.section.additionalInfo.otherFilesLabel',
    'profile.founder.section.additionalInfo.otherFilesErrorLabel',
    'profile.founder.section.additionalInfo.invalidVideoLink',
    'profile.founder.section.additionalInfo.otherFilesTooltip',
    'profile.founder.section.additionalInfo.productVideoTooltip',
    'profile.founder.section.additionalInfo.financialModel',
    'profile.founder.section.additionalInfo.investorReady.label',
    'profile.founder.section.additionalInfo.investorReady.tooltip',
  ]);

  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);

  const { mutateAsync: updateAdditionalInfo } = useMutation(updateAdditionalInfoAction, {
    onSuccess: () => {
      message.success({ content: formUpdatedLabel });
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  const { values, touched, errors, setFieldValue, getFieldProps, isValid, submitForm, submitCount, dirty } =
    useFormik<AdditionalInfoData>({
      onSubmit: (v) => updateAdditionalInfo(v),
      initialValues: {
        productVideoUrl: data.productVideoUrl || '',
        pitchDeck: data.pitchDeck,
        otherFiles: data.otherFiles,
        financialModelFiles: data.financialModelFiles,
        hasInvestorReadyMaterials: data.hasInvestorReadyMaterials,
      },
      validationSchema: Yup.object().shape({
        productVideoUrl: Yup.string()
          .nullable()
          .test('test-vimeo-youtube-url', invalidVideoLinkLabel, (value: string | undefined | null) => {
            if (!value) return true;
            return value.match(YOUTUBE_URL_REGEXP) !== null || value.match(VIMEO_URL_REGEXP) !== null;
          }),
        pitchDeck: Yup.lazy((value) => {
          if (value && typeof value === 'object') return fileValidationSchema;
          return Yup.mixed().test('is null', 'valid object or null allowed', (val) => Promise.resolve(val === null));
        }),
        otherFiles: Yup.array().of(fileValidationSchema),
        financialModelFiles: Yup.array().of(fileValidationSchema),
      }),
      enableReinitialize: true,
      validateOnMount: true,
    });

  const deletePitchDeck = () => {
    setFieldValue('pitchDeck', null, true);
  };

  const row = (content: ReactNode | ReactNode[]) => (
    <Row gutter={[24, 10]}>
      <Col xs={24} sm={24} md={24} lg={14} xl={14}>
        {content}
      </Col>
    </Row>
  );

  return (
    <Section header={titleLabel} subTitle={subTitleLabel} innerRef={scrollToRef}>
      <form>
        {row(
          <FormikField
            label={{
              for: pitchDeckLabel,
              label: pitchDeckLabel,
            }}
            touched={touched.pitchDeck}
            error={errors.pitchDeck}
          >
            <FileUpload
              file={values.pitchDeck?.name}
              fileUrl={values.pitchDeck?.url}
              uploadMutation={(formData) => {
                setIsFileUploading(true);
                return uploadPitchDeckAction(formData);
              }}
              onUploadError={() => {
                setIsFileUploading(false);
              }}
              deleteFile={deletePitchDeck}
              onUploadSuccess={async (resp) => {
                setFieldValue('pitchDeck', resp.data, true);
                setIsFileUploading(false);
              }}
              mimeTypes={allowedMimeTypes}
            />
          </FormikField>,
        )}

        {row(
          <>
            <FormikField
              label={{
                for: financialModelLabel,
                label: financialModelLabel,
              }}
              touched={touched.financialModelFiles?.some((v) => v)}
              error={errors.financialModelFiles?.length ? otherFilesErrorLabel : undefined}
            >
              {values.financialModelFiles.map((f) => (
                <S.UploadedFile
                  key={f.path}
                  fileName={f.name}
                  fileUrl={f.url}
                  handleDelete={() => {
                    setFieldValue(
                      'financialModelFiles',
                      values.financialModelFiles.filter((file) => f.path !== file.path),
                    );
                  }}
                />
              ))}
              <FileUpload
                multipleControlled
                hidden={values.financialModelFiles.length >= 2}
                uploadMutation={(formData) => {
                  setIsFileUploading(true);
                  return uploadFinancialModel(formData);
                }}
                onUploadSuccess={async (resp) => {
                  setFieldValue('financialModelFiles', [...values.financialModelFiles, resp.data], true);
                  setIsFileUploading(false);
                }}
                mimeTypes={allowedMimeTypes}
              />
            </FormikField>
            <S.CheckboxFormikField
              label={{
                for: investorReadyLabel,
                label: <LabelWithTooltip label={investorReadyLabel} tooltip={investorReadyTooltip} />,
              }}
              error={errors.hasInvestorReadyMaterials}
              touched={touched.hasInvestorReadyMaterials}
            >
              <Checkbox
                {...getFieldProps('hasInvestorReadyMaterials')}
                checked={values.hasInvestorReadyMaterials}
                data-testid="investor-ready"
              />
            </S.CheckboxFormikField>
          </>,
        )}

        {row(
          <FormikField
            label={{
              for: productVideoLabel,
              label: (
                <span>
                  {productVideoLabel}
                  <Tooltip title={productVideoTooltipLabel} />
                </span>
              ),
            }}
            touched={touched.productVideoUrl}
            error={errors.productVideoUrl}
          >
            <Input {...getFieldProps('productVideoUrl')} />
          </FormikField>,
        )}
        {row(
          <FormikField
            label={{
              for: otherFilesLabel,
              label: (
                <span>
                  {otherFilesLabel}
                  <Tooltip title={otherFilesTooltipLabel} />
                </span>
              ),
            }}
            touched={touched.otherFiles?.some((v) => v)}
            error={errors.otherFiles?.length ? otherFilesErrorLabel : undefined}
          >
            {values.otherFiles.map((f) => (
              <S.UploadedFile
                key={f.path}
                fileName={f.name}
                fileUrl={f.url}
                handleDelete={() => {
                  setFieldValue(
                    'otherFiles',
                    values.otherFiles.filter((file) => f.path !== file.path),
                  );
                }}
              />
            ))}
            <FileUpload
              multipleControlled
              hidden={values.otherFiles.length >= 2}
              uploadMutation={(formData) => {
                setIsFileUploading(true);
                return uploadOtherFileAction(formData);
              }}
              onUploadSuccess={async (resp) => {
                setFieldValue('otherFiles', [...values.otherFiles, resp.data], true);
                setIsFileUploading(false);
              }}
              mimeTypes={allowedMimeTypes}
            />
          </FormikField>,
        )}
        <SubmitButton
          disabled={isFileUploading}
          disabledEvaluation={{ isValid, dirty, submitCount }}
          onClick={submitForm}
        >
          {saveLabel}
        </SubmitButton>
      </form>
    </Section>
  );
};

export default AdditionalInfo;

import { V2AriaTable } from '@/components/blocks/v2-aria-table/v2-aria-table';
import { V2Button } from '@/components/ui/v2-button/v2-button';
import {
  universalEngageConversationAction,
  useEngageConversation,
} from '@/utils/hooks/use-engage-conversation/use-engage-conversation';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import type { InvestorAdvisorFounderConnection } from '../../../types';

export const ChatButtonCell = ({ founderId }: InvestorAdvisorFounderConnection) => {
  const [chatLabelTranslation] = useTranslation(['connections.chat']);

  const { mutateAsync: message, isLoading: isMessageLoading } = useEngageConversation(
    universalEngageConversationAction,
  );

  return (
    <V2AriaTable.BodyCell align="center">
      <V2Button
        variant="outlined"
        disabled={isMessageLoading}
        startIcon="chat-left-dots"
        onClick={() => message(founderId)}
      >
        {chatLabelTranslation}
      </V2Button>
    </V2AriaTable.BodyCell>
  );
};

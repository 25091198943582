import { Roles } from '@domain/accounts/roles';
import { AppRouteConfig, type RouteConfigRecord } from '@router/route.config';
import type { Routes } from '@router/routes';

export const getAllRoutes = (routeConfig: RouteConfigRecord = AppRouteConfig()): Routes[] => {
  const routes = Object.entries(routeConfig).map(([route]) => route);

  return routes as Routes[];
};

export const getRoleAccessibleRoutes = (role: Roles, routeConfig: RouteConfigRecord = AppRouteConfig()): Routes[] => {
  const routes = Object.entries(routeConfig)
    .filter(([, config]) => config.permissions.allowedRoles?.includes(role))
    .map(([route]) => route);

  return routes as Routes[];
};

export const getRoleForbiddenRoutes = (role: Roles, routeConfig: RouteConfigRecord = AppRouteConfig()): Routes[] => {
  const allRoutes = getAllRoutes(routeConfig);
  const roleAccessibleRoutes = getRoleAccessibleRoutes(role, routeConfig);

  return allRoutes.filter((route) => !roleAccessibleRoutes.includes(route));
};

export const getRoleForbiddenRoutesForNoActive = (
  role: Roles,
  routeConfig: RouteConfigRecord = AppRouteConfig(),
): Routes[] => {
  return Object.entries(routeConfig).reduce((acc, [route, config]) => {
    if (config.permissions.allowedRoles?.includes(role) && config.permissions.activeUser) {
      acc.push(route as Routes);
    }

    return acc;
  }, [] as Routes[]);
};

export const getForbiddenRoutesForNotOnboardedInvestor = (
  routeConfig: RouteConfigRecord = AppRouteConfig(),
): Routes[] => {
  return Object.entries(routeConfig).reduce((acc, [route, config]) => {
    if (config.permissions.allowedRoles?.includes(Roles.INVESTOR) && config.permissions.investorHasToBeOnboarded) {
      acc.push(route as Routes);
    }

    return acc;
  }, [] as Routes[]);
};

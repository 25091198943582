import { SingleButtonModal } from '@parts/single-button-modal/single-button-modal';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Button } from 'antd';

import S from './delete-modal.styles';

export const DeleteModal = ({
  onConfirmClick,
  onDeleteClick,
}: {
  onConfirmClick: () => void;
  onDeleteClick: () => void;
}) => {
  const [titleLabel, subTitleLabel, buttonLabel] = useTranslation([
    'profile.investor.section.investments.deleteModal.title',
    'profile.investor.section.investments.deleteModal.subTitle',
    'profile.investor.section.investments.deleteModal.confirmButton',
  ]);

  const footer = (
    <Button type="primary" onClick={onConfirmClick} data-testid="delete-funding-round">
      {buttonLabel}
    </Button>
  );

  return (
    <SingleButtonModal title={titleLabel} footer={footer} onClose={onDeleteClick}>
      <S.Content>{subTitleLabel}</S.Content>
    </SingleButtonModal>
  );
};

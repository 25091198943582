import styled from 'styled-components';

const Wrapper = styled.div`
  font: inherit;
  display: flex;
  justify-content: start;
  align-items: start;
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;

const Label = styled.p`
  font: inherit;
`;

export default { Wrapper, Label };

import { forwardRef } from 'react';
import styled from 'styled-components';

export const Input = styled.input`
  display: none;
`;
const HiddenFileInput = forwardRef(
  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  ({ submitFn, accept }: { submitFn: Function; accept?: string }, ref: any) => {
    const acceptFiles = accept || 'image/png,image/jpeg';

    return (
      <Input
        type="file"
        ref={ref}
        multiple={false}
        onChange={(e) => {
          const formData = new FormData();
          if (e.target.files && e.target.files.length > 0) {
            formData.append('file', e.target.files[0]);
            submitFn(formData);
          }
        }}
        accept={acceptFiles}
      />
    );
  },
);

HiddenFileInput.displayName = 'HiddenFileInput';

export default HiddenFileInput;

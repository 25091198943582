import PasswordProtectedIcon from '@assets/icons/password-protected.svg?react';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Input } from 'antd';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import * as Yup from 'yup';
import { validateProgressReportPassword } from '../../api/validate-progress-report-password/validate-progress-report-password.action';
import { EmptyState } from '../empty-state/empty-state';
import S from './password-protected.styles';

export const PasswordProtected = ({
  setPasswordVerified,
  passwordVerified,
}: {
  setPasswordVerified: Function;
  passwordVerified: boolean;
}) => {
  const { hash } = useParams() as { hash: string };

  const [headlineLabel, contentLabel, viewPulseLabel, placeholderLabel, requiredLabel] = useTranslation([
    'progress.report.passwordProtected.headline',
    'progress.report.passwordProtected.content',
    'progress.report.passwordProtected.viewPulse',
    'progress.report.passwordProtected.placeholder',
    'formik.validation.required',
  ]);

  const persistedPassword = window.localStorage.getItem(hash);

  const { mutateAsync: checkPassword, isLoading } = useMutation(validateProgressReportPassword(hash), {
    onSuccess: (_, validPassword) => {
      if (!persistedPassword) {
        window.localStorage.setItem(hash, validPassword);
      }

      setPasswordVerified(true);
    },
    onError: () => {
      window.localStorage.removeItem(hash);

      // eslint-disable-next-line
      setPasswordError();
    },
  });

  useEffect(() => {
    if (!persistedPassword || passwordVerified) return;

    checkPassword(persistedPassword);
    // eslint-disable-next-line
  }, []);

  const { isValid, getFieldMeta, getFieldProps, submitForm, setFieldError } = useFormik({
    initialValues: { password: '' },
    onSubmit: (vals) => {
      checkPassword(vals.password);
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      password: Yup.string().required(requiredLabel),
    }),
  });

  function setPasswordError() {
    setFieldError('password', 'Invalid password');
  }

  if (isLoading && !isValid) return null;

  return (
    <EmptyState headline={headlineLabel} texts={[contentLabel]} icon={<PasswordProtectedIcon />}>
      <S.Content>
        <S.PasswordField {...getFieldMeta('password')}>
          <Input
            onKeyDown={(e) => {
              if (e.keyCode === 13) submitForm();
            }}
            type="password"
            placeholder={placeholderLabel}
            {...getFieldProps('password')}
          />
        </S.PasswordField>
        <S.Button onClick={submitForm} disabled={isLoading || !isValid} type="primary">
          {viewPulseLabel}
        </S.Button>
      </S.Content>
    </EmptyState>
  );
};

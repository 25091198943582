import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_FOUNDER_INVITES_COUNT_QUERY_KEY = 'get-founder-invites-count-query';

export const getFounderInvitesCount = async (): Promise<GraphqlResponse<{ total: number }>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
     query getFounderInvitesCount {
      getFounderPendingConnections {
           total
         }
       }
    `,
  });

  return graphqlResponseWrapper(data, ['getFounderPendingConnections']);
};

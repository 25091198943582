import { Ability, AbilityBuilder } from '@casl/ability';
import type { AccountStatus } from '@context/user/user-account.context';
import type { SubscriptionProduct } from '@domain/accounts/subscription-products';
import type { ApiDefinedPermission, RoleOnboardingStatus } from '@pages/auth/api/account-data/account-data.types';
import type { Routes } from '@router/routes';
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';

export enum AbilityAction {
  ACCESS = 'access',
  ACCESS_PAGE = 'access-page',
}

export enum AbilitySubject {
  LENS_SMART_MATCH = 'lens-smart-match',
  LENS_CONNECTIONS = 'lens-connections',
  LENS_REQUESTS = 'lens-requests',

  DISCOVER = 'discover',

  LEGAL_DOCS = 'legal-docs',
  PORTFOLIO = 'portfolio',
  ADVISOR_COMMUNITY = 'advisor-community',
}

// All ability subjects - specified abilities and Routes
type AllAbilitySubjects = AbilitySubject | Routes;

export type AppAbility = Ability<[AbilityAction, AllAbilitySubjects]>;

interface RoleAbilityAttributes {
  accountStatus: AccountStatus;
  products: SubscriptionProduct[];
  cor: CountryCodes | null;
  onboarding: RoleOnboardingStatus;
  apiDefinedPermissions: ApiDefinedPermission[];
  hasNedAcademyAccess: boolean;
}

export type RoleAbilityBuilderFunction = (
  builder: AbilityBuilder<AppAbility>,
  attributes: RoleAbilityAttributes,
) => AbilityBuilder<AppAbility>;

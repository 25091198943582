import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import S from './default-avatar.styles';

export const DefaultAvatar = ({
  size,
  letter,
  className,
  pastelBackground = false,
}: {
  size?: number;
  className?: string;
  letter?: string;
  pastelBackground?: boolean;
}) => {
  const {
    state: { name, businessName },
  } = useUserAccount();

  return (
    <S.DefaultAvatar
      className={className}
      size={size}
      initial={letter || (businessName && businessName[0]) || name[0]}
      $pastelBackground={pastelBackground}
    />
  );
};

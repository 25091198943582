import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ShowAllLink = styled(Link)`
  color: ${({ theme }) => theme.baseColors.Blue200};
  float: right;
  font-size: ${({ theme }) => theme.fontSize.small};
  margin-top: -75px;
  position: relative;
  text-align: right;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.baseColors.Blue200};
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    display: none;
  }
`;

const FooterWrapper = styled.div`
  position: fixed;
  border-bottom: ${({ theme }) => `1px solid ${theme.color.border}`};
  width: 100%;

  > * {
    padding-bottom: ${({ theme }) => theme.spacing.xxsmall};
  }
`;

export default { ShowAllLink, FooterWrapper };

import { Col } from 'antd';
import type { ReactNode } from 'react';
import S from './row.styles';

interface RowPropsBase {
  content: ReactNode;
  direction?: 'row' | 'column';
  slim?: boolean;
}
type RowPropsWithLabel = RowPropsBase & { label: ReactNode };
type RowPropsWithCustomLabel = RowPropsBase & { customLabel: ReactNode };
type RowProps = RowPropsWithLabel | RowPropsWithCustomLabel;

export const Row = ({ direction = 'row', content, slim, ...restProps }: RowProps) => {
  const { label, customLabel } = restProps as { label?: ReactNode; customLabel?: ReactNode };

  return (
    <S.Row gutter={slim ? [24, 12] : [24, 24]} direction={direction}>
      <Col xs={24} sm={24} md={6}>
        {customLabel ?? <S.Label>{label}</S.Label>}
      </Col>
      <Col xs={24} sm={24} md={18}>
        {content}
      </Col>
    </S.Row>
  );
};

import type { FounderLearningStep } from '@/pages/content/onboarding/founder/types/learning-steps';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_FOUNDER_LEARNING_PROGRESS_KEY = 'get-founder-learning-progress';

type FounderLearningProgressResponseData = {
  finishedLearningSteps: FounderLearningStep[];
};

export const getFounderLearningProgressQuery = async (): Promise<
  GraphqlResponse<FounderLearningProgressResponseData>
> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
    query GetFounderLearningProgressQuery {
      founderLearningProgress {
        finishedLearningSteps
      }
    }
  `,
  });

  return graphqlResponseWrapper(data, ['founderLearningProgress']);
};

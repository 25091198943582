import {
  getCodatConsoleUrlAction,
  GET_CODAT_CONSOLE_URL,
} from '@pages/content/pulse/api/get-codat-console-url/get-codat-console-url.action';
import type { ExternalMetric } from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { useQuery } from '@tanstack/react-query';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { Metrics } from '../../../metrics/metrics';
import { Banner } from './parts/banner/banner';
import { WaitingScreen } from './parts/waiting-screen/waiting-screen';

export const Connected = ({ metrics }: { metrics: ExternalMetric[] }) => {
  const { data, isLoading } = useQuery([GET_CODAT_CONSOLE_URL], getCodatConsoleUrlAction);

  const {
    state: {
      integrations: {
        codat: { isFirstSyncCompleted },
      },
    },
  } = useUserAccount();

  if (!data || isLoading) {
    return <FullHeightSpinner />;
  }

  const { consoleUrl } = data.data;

  return (
    <>
      <Banner buttonUrl={consoleUrl} />
      {isFirstSyncCompleted ? <Metrics metrics={metrics} integratedMetricsView /> : <WaitingScreen />}
    </>
  );
};

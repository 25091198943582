import { V2AriaTable } from '@/components/blocks/v2-aria-table/v2-aria-table';
import { V2AvatarWithDetails } from '@/components/ui/v2-avatar-with-details/v2-avatar-with-details';
import { V2Badge } from '@/components/ui/v2-badge/v2-badge';
import { V2Link } from '@/components/ui/v2-link/v2-link';
import { founderIdQueryParam } from '@/pages/content/public-profile/founder/founder.page';
import { Links } from '@/router/links';
import type { InvestorAdvisorFounderConnection } from '../../../types';

export const NameAndDetailsCell = ({
  businessName,
  representativeName,
  isNew,
  founderId,
}: InvestorAdvisorFounderConnection) => {
  const badges = isNew ? [<V2Badge text="New" startIcon="person" variant="accent" size="sm" key="new" />] : [];
  const profileUrl: Record<'Founder', string> = {
    Founder: `${Links.FOUNDER_PUBLIC_PROFILE_DETAILS()}?${founderIdQueryParam}=${founderId}`,
  };

  return (
    <V2AriaTable.BodyCell>
      <V2Link to={profileUrl.Founder} hasUnderline={false}>
        <V2AvatarWithDetails
          avatar={{ name: businessName }}
          mainText={businessName}
          additionalText={representativeName}
          badges={badges}
        />
      </V2Link>
    </V2AriaTable.BodyCell>
  );
};

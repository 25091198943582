import { EmptyState as EmptyStateBase } from '@parts/empty-state/empty-state';
import { SubmitButton as ButtonBase } from '@parts/submit-button/submit-button';
import styled from 'styled-components';

const Wrapper = styled(EmptyStateBase)``;

const Button = styled(ButtonBase)`
  margin-top: ${({ theme }) => theme.spacing.xbase};
`;

export default { Wrapper, Button };

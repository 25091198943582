import type { V2_ICONS_CONFIG } from '@/components/ui/v2-icon/icons';
import { V2Icon } from '@/components/ui/v2-icon/v2-icon';
import type { HTMLAttributes } from 'react';
import { useV2AriaMobileConnectionCardContext } from '../v2-aria-mobile-connection-card-context';

import S from '../v2-aria-mobile-connection-card.styles';

type DetailProps = {
  icon: (typeof V2_ICONS_CONFIG)[number]['name'];
  text: string;
} & HTMLAttributes<HTMLDivElement>;

export const Detail = ({ icon, text, ...rest }: DetailProps) => {
  useV2AriaMobileConnectionCardContext();

  return (
    <S.Detail {...rest}>
      <V2Icon name={icon} />
      <span>{text}</span>
    </S.Detail>
  );
};

import type { GetInvestmentForFounder } from '@pages/content/pulse/api/get-founder-investment.action';
import type { FormikState, ShareholderInvestment } from './editor-interfaces';

export const prepareInvestmentId = (value: FormikState) => ({
  ...value,
  investments: value.investments.map((inv) => {
    const { id: investmentId, ...investment } = inv;

    if (investmentId) {
      return inv;
    }

    return investment;
  }),
});

export const prepareDataForUpdate = (value: FormikState) => ({
  ...value,
  investments: value.investments.map((inv: ShareholderInvestment) => {
    const { confirmed, ...investment } = inv;
    if (investment.id === null) {
      const { id, ...rest } = investment;
      return rest;
    }

    return investment;
  }),
});

export const prepareDataForInitialValues = (res: GetInvestmentForFounder): FormikState => {
  if (res) {
    const { id, investor, investingRelationshipId, ...rest } = res;

    return {
      investorId: investor.id,
      investingRelationshipId,
      nameOrEmail: investor.name || investor.email,
      investments: [rest],
    };
  }

  return res;
};

export const emptyInvestment: ShareholderInvestment = {
  id: null,
  round: {
    id: null,
  },
  amount: null,
  shares: null,
  percentageOwnership: null,
  shareClass: null,
  leadInvestor: null,
  terms: {
    warrants: {
      amount: null,
      price: null,
      expiry: null,
    },
    options: null,
    convertibleLoanNote: null,
    discount: null,
  },
};

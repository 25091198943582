import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './publish-shorages.styles';

export const PublishShortages = ({ items }: { items: string[] }) => {
  const [
    shortagesLabel,
    industryLabel,
    emailLabel,
    businessNameLabel,
    locationLabel,
    productStageLabel,
    investmentStageLabel,
    customerGroupLabel,
    aboutCompanyLabel,
    firstNameLabel,
    lastNameLabel,
    aboutLabel,
    preferredRoleLabel,
    primarySkillSetLabel,
    secondarySkillSetLabel,
    expectationsLabel,
    offeringLabel,
    preferredGeographiesLabel,
  ] = useTranslation([
    'profile.shortages',
    'profile.shortages.industry',
    'profile.shortages.email',
    'profile.shortages.businessName',
    'profile.shortages.location',
    'profile.shortages.productStage',
    'profile.shortages.investmentStage',
    'profile.shortages.customerGroup',
    'profile.shortages.aboutCompany',
    'profile.ned.shortages.firstName',
    'profile.ned.shortages.lastName',
    'profile.ned.shortages.about',
    'profile.ned.shortages.preferredRole',
    'profile.ned.shortages.primarySkillSet',
    'profile.ned.shortages.secondarySkillSet',
    'profile.ned.shortages.expectations',
    'profile.ned.shortages.offering',
    'profile.shortages.preferredGeographies',
  ]);

  const labels: { [key: string]: string } = {
    // Personal details - mutual
    account_email: emailLabel,
    first_name: firstNameLabel,
    last_name: lastNameLabel,
    business_name: businessNameLabel,
    location: locationLabel,
    aboutMe: aboutLabel,

    // Company Bio - founder
    about: aboutCompanyLabel,

    // About me - NED
    myOffering: offeringLabel,
    myExpectations: expectationsLabel,

    // Smart Match - mutual
    roles: preferredRoleLabel,
    industry: industryLabel,
    industries: industryLabel,
    customer_group: customerGroupLabel,
    product_stage: productStageLabel,
    investment_stage: investmentStageLabel,
    target_geography: preferredGeographiesLabel,
    primarySkillSet: primarySkillSetLabel,
    secondarySkillSet: secondarySkillSetLabel,
  };

  const keys = Object.keys(labels);
  const sortedItems = items.toSorted((a, b) => keys.indexOf(a) - keys.indexOf(b));

  return (
    <S.Wrapper>
      <S.Title>{shortagesLabel}</S.Title>
      <S.List>
        {sortedItems.map((el) => (
          <S.Item key={el}>{labels[el]}</S.Item>
        ))}
      </S.List>
    </S.Wrapper>
  );
};

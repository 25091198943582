import { SubmitButton as SubmitButtonBase } from '@parts/submit-button/submit-button';
import styled from 'styled-components';

const Form = styled.form`
  color: ${({ theme }) => theme.color.fontPrimary};
`;

const SubmitButton = styled(SubmitButtonBase)`
  margin-top: ${({ theme }) => theme.spacing.xmedium};
`;

export default { Form, SubmitButton };

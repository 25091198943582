import { axiosInstance } from '@utils/axios/axios-instance';
import type { AxiosResponse } from '@utils/axios/types';

export const deleteInvestorDocumentAction = ({
  investingRelationshipId,
  documentId,
}: {
  investingRelationshipId: string;
  documentId: string;
}): Promise<AxiosResponse<{}>> =>
  axiosInstance.delete(
    `/portfolio-management/investing-relationship/${investingRelationshipId}/document/${documentId}`,
  );

import { createElement, type FC, type SVGProps } from 'react';

type IconProps = Omit<SVGProps<SVGSVGElement>, 'href'> & {
  disabled?: boolean;
  title?: string;
  icon: FC<SVGProps<SVGSVGElement> & { title?: string }>;
};
export type IconComponent = FC<SVGProps<SVGSVGElement> & { title?: string }>;

export const Icon = ({ disabled, icon, onClick, ...rest }: IconProps) =>
  createElement(icon, {
    ...rest,
    onClick: disabled ? undefined : onClick,
    style: {
      ...rest.style,
      cursor: disabled ? 'default' : 'pointer',
      opacity: disabled ? 0.3 : 1,
    },
  });

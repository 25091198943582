import ContractbookIcon1 from '@assets/icons/contractbook-1.svg';
import ContractbookIcon2 from '@assets/icons/contractbook-2.svg';
import InfoLink, { type InfoLinkProps } from '@parts/info-link/info-link';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { getLegalDocMagicLinkAction, GET_MAGIC_LINK } from '../../api/get-magic-link.action';

const LegalInfoLink = ({ type, link }: { type: 'help-desk' | 'manage-documents' | 'legal-hub'; link: string }) => {
  const [
    helpDeskTitleLabel,
    helpDeskDescriptionLabel,
    helpDeskButtonLabel,
    manageDocumentsTitleLabel,
    manageDocumentsDescriptionLabel,
    manageDocumentsButtonLabel,
    legalHubTitleLabel,
    legalHubDescriptionLabel,
    legalHubButtonLabel,
  ] = useTranslation([
    'legal.helpDesk.title',
    'legal.helpDesk.description',
    'legal.helpDesk.button',
    'legal.manageDocuments.title',
    'legal.manageDocuments.description',
    'legal.manageDocuments.button',
    'legal.legalHub.title',
    'legal.legalHub.description',
    'legal.legalHub.button',
  ]);

  const { refetch } = useQuery<string>([GET_MAGIC_LINK], () => getLegalDocMagicLinkAction(link), {
    enabled: false,
  });

  const props = (): InfoLinkProps => {
    switch (type) {
      default:
      case 'help-desk':
        return {
          title: helpDeskTitleLabel,
          dataTestId: 'Legal_HelpDesk_Button',
          description: helpDeskDescriptionLabel,
          imgUrl: ContractbookIcon1,
          button: {
            text: helpDeskButtonLabel,
            theme: 'blue',
            link,
            openInNewTab: true,
            refetch,
          },
        };
      case 'manage-documents':
        return {
          title: manageDocumentsTitleLabel,
          dataTestId: 'Legal_ManageDocs_Button',
          description: manageDocumentsDescriptionLabel,
          imgUrl: ContractbookIcon2,
          button: {
            text: manageDocumentsButtonLabel,
            theme: 'white',
            link,
            openInNewTab: true,
          },
        };
      case 'legal-hub':
        return {
          title: legalHubTitleLabel,
          dataTestId: 'Legal_Contactus_Button',
          description: legalHubDescriptionLabel,
          imgUrl: ContractbookIcon1,
          button: {
            text: legalHubButtonLabel,
            theme: 'blue',
            link,
          },
        };
    }
  };

  return <InfoLink {...props()} />;
};

export default LegalInfoLink;

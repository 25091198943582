import ChartIcon from '@assets/icons/chart.svg?react';
import { Button as BaseButton } from 'antd';
import styled from 'styled-components';

const Placeholder = styled.div`
  padding: ${({ theme }) => theme.spacing.small};
  box-shadow: none;
  min-height: 205px;
  color: ${({ theme }) => theme.color.primary};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoDataIcon = styled(ChartIcon)`
  fill: ${({ theme }) => theme.color.iconFill};
  width: 50px;
  height: 50px;
`;

const PlaceholderLabel = styled.p`
  padding: ${({ theme }) => theme.spacing.small};
`;

const EmptyLoader = styled.div`
  height: 185px;
`;

const Button = styled(BaseButton)`
  color: ${({ theme }) => theme.baseColors.Blue200};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
`;

export default { NoDataIcon, PlaceholderLabel, Placeholder, EmptyLoader, Button, ButtonWrapper };

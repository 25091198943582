import type { TargetGeographyValue } from '@constants/target-geography';
import { MatchLevel } from '@pages/content/lens/parts/smart-match-progress/smart-match-progress';
import type { PreviousInvestment } from '@pages/content/profile/investor/api/types';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import { ConnectionStatus, ConnectionType } from '@utils/type-definitions/connections';
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import type { GraphqlResponse } from 'src/types';

export type Connection = {
  acceptedAt: string | null;
  requestedAt: string;
  blacklistedAt: string | null;
  status: ConnectionStatus;
  type: ConnectionType;
};

export interface FounderSmartMatchSuggestions {
  countryOfResidence: CountryCodes | null;
  name: string;
  profilePicture: {
    url: string;
  };
  linkedinUrl: string;
  twitterUrl: string;
  aboutMe: string;
  productStage: string[];
  customerGroup: string[];
  industries: string[];
  investmentStage: string[];
  investmentThesis: string | null;
  targetGeography: TargetGeographyValue[] | null;
  maxInvestmentAmount: number | null;
  minInvestmentAmount: number | null;
  hidePreviousInvestments: boolean | null;
  previousInvestmentsTable: PreviousInvestment[] | null;
  connection: Connection | null;
  followInvitationID: string;
  smartMatchLevel: MatchLevel;
  isInvestorAdvisor: boolean;
}

export const GET_INVESTOR_PUBLIC_PAGE_DETAILS_CACHE_KEY = 'get-investor-public-page-details';

export const getInvestorPublicPageDetailsAction =
  (investorId: string) => async (): Promise<GraphqlResponse<FounderSmartMatchSuggestions>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
      query getInvestorPublicPageDetails {
        getInvestorPublicProfileData(investorId: "${investorId}"){
          productStage
          customerGroup
          industries
          investmentStage
          aboutMe
          name
          countryOfResidence
          twitterUrl
          linkedinUrl
          profilePicture {
            url
          }
          investmentThesis
          targetGeography
          maxInvestmentAmount
          minInvestmentAmount
          previousInvestmentsTable {
            company,
            note
          }
          connection{acceptedAt, requestedAt, blacklistedAt, type,
            status},
          followInvitationID,
          smartMatchLevel
          isInvestorAdvisor
        }
      }
    `,
    });

    return graphqlResponseWrapper(data, ['getInvestorPublicProfileData']);
  };

import type { ChartNode } from '@pages/content/pulse/founder/parts/management/parts/metrics/parts/numeric-editor/parts/live-chart/live-chart';
import { DateTime } from 'luxon';

export const getBoundaryEntryTime = (mode: 'future' | 'past', series: ChartNode[]) => {
  const boundaryEntry = (mode === 'future' ? [...series].reverse() : series).find(([, value]) => value);

  return boundaryEntry && boundaryEntry[0] && typeof boundaryEntry[0] === 'string'
    ? DateTime.fromISO(boundaryEntry[0]).toMillis()
    : 0;
};

import { Footer as FooterBase } from '@pages/content/lens/parts/footer/footer';
import styled from 'styled-components';

const RequestSent = styled.p`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  white-space: nowrap;

  img {
    margin-right: ${({ theme }) => theme.spacing.small};
  }
`;

const Footer = styled(FooterBase)`
  height: 80px;
`;

export default {
  RequestSent,
  Footer,
};

import type { ProgressStepStatus } from '@pages/content/onboarding/types/progress';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const investorProgressList = [
  'basicInformation',
  'smartMatchCriteria',
  'investmentThesis',
  'community',
  'confirmation',
] as const;

export type InvestorProgressStep = (typeof investorProgressList)[number];

export type InvestorOnboardingProgress = Record<InvestorProgressStep, ProgressStepStatus>;

export const GET_INVESTOR_ONBOARDING_PROGRESS = 'get-investor-onboarding-progress';

export const getInvestorOnboardingProgress =
  (list: InvestorProgressStep[] = [...investorProgressList]) =>
  async (): Promise<GraphqlResponse<Partial<InvestorOnboardingProgress>>> => {
    const fetchItems = list.length === 0 ? investorProgressList : list;

    const { data } = await axiosInstance.post('/query', {
      query: `
    query getInvestorOnboardingProgress {
      investorOnboardingProgress {
         ${fetchItems.join(',')}
        }
      }
    `,
    });

    return graphqlResponseWrapper(data, ['investorOnboardingProgress']);
  };

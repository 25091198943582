import {
  getInvestorOnboardingProgress,
  GET_INVESTOR_ONBOARDING_PROGRESS,
  type InvestorOnboardingProgress,
  investorProgressList,
  type InvestorProgressStep,
} from '@pages/content/portfolio/investor/api/get-progress.action';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const useInvestorProgressAsList = (data: InvestorOnboardingProgress | null) => {
  const [personalInformationLabel, matchLabel, confirmationLabel] = useTranslation([
    'investor-onboarding.progress.personal',
    'investor-onboarding.progress.match',
    // 'investor-onboarding.progress.investment', hidden due to changes in CON-11192/CON-11619
    // 'investor-onboarding.progress.community', // Community status hidden - until community goes live
    'investor-onboarding.progress.confirmation',
  ]);

  if (!data) {
    return [];
  }

  const { basicInformation, smartMatchCriteria, confirmation } = data;

  return [
    { label: personalInformationLabel, status: basicInformation },
    { label: matchLabel, status: smartMatchCriteria },
    // { label: communityLabel, status: community },
    // { label: investmentLabel, status: investmentThesis },
    { label: confirmationLabel, status: confirmation },
  ];
};

export const useInvestorProgressFetching = () => {
  const [progress, setProgress] = useState<InvestorOnboardingProgress | null>(null);
  const progressList = useInvestorProgressAsList(progress);

  const [revalidateListId, setRevalidateListId] = useState<string>('-');
  const [revalidateList, setRevalidateList] = useState<InvestorProgressStep[]>([]);

  const revalidateListKey = revalidateList.join('-') + revalidateListId;
  useQuery([[GET_INVESTOR_ONBOARDING_PROGRESS], revalidateListKey], getInvestorOnboardingProgress(revalidateList), {
    cacheTime: 0,
    refetchOnMount: true,
    onSuccess: (data) => {
      if (!data.data) return;

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setRevalidatedProgressItems(data.data);
    },
  });

  const setRevalidatedProgressItems = (revalidatedItems: Partial<InvestorOnboardingProgress>) => {
    const initialProgress: InvestorOnboardingProgress = [...investorProgressList].reduce((acc, key) => {
      acc[key as keyof typeof initialProgress] = 'Skipped';
      return acc;
    }, {} as InvestorOnboardingProgress);

    setProgress((prevState) => {
      const newState: InvestorOnboardingProgress = { ...(prevState ?? initialProgress) };

      for (const [key, status] of Object.entries(revalidatedItems)) {
        newState[key as keyof typeof initialProgress] = status;
      }

      return newState;
    });
  };

  const handleRefetch = (list: InvestorProgressStep[]) => {
    setRevalidateList(list);
    setRevalidateListId(uuidv4());
  };

  return {
    progress,
    progressList,
    initialized: progress !== null,
    refetch: handleRefetch,
  };
};

import type { ModalProps as AntDModalProps } from 'antd/lib/modal';

import type { ButtonProps } from 'antd/lib/button';
import { memo, type ReactNode } from 'react';
import S from './modal.styles';

interface ModalPropsBase extends AntDModalProps {
  children: ReactNode | ReactNode[];
  icon?: ReactNode;
  biggerIcon?: boolean;
}

export interface ModalProps extends ModalPropsBase {
  children: ReactNode | ReactNode[];
  closeModal: () => void;
  visible: boolean;
  footer?: ReactNode;
  okButtonProps?: ButtonProps;
  icon?: ReactNode;
  biggerIcon?: boolean;
  title?: string | ReactNode;
  closeIcon?: ReactNode;
  closable?: boolean;
  testId?: string;
}

const Modal = ({
  children,
  closeModal,
  visible,
  footer,
  title,
  onOk,
  okText,
  cancelText,
  okButtonProps,
  cancelButtonProps,
  onCancel,
  icon,
  biggerIcon,
  closeIcon,
  wrapClassName,
  closable = false,
  width = 470,
  testId = 'modal',
}: ModalProps) => {
  return (
    <S.Modal
      wrapClassName={wrapClassName}
      visible={visible}
      title={title}
      onCancel={onCancel ?? closeModal}
      footer={footer}
      okButtonProps={okButtonProps}
      cancelButtonProps={cancelButtonProps}
      onOk={onOk}
      data-testid={testId}
      width={width}
      closeIcon={closeIcon ?? <S.Close />}
      centered
      closable={closable}
      okText={okText}
      cancelText={cancelText}
    >
      {icon && <S.IconHolder biggerIcon={biggerIcon}>{icon}</S.IconHolder>}
      {children}
    </S.Modal>
  );
};

export default memo(Modal);

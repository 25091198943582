import { Carousel } from 'antd';
import clamp from 'lodash.clamp';
import styled, { css } from 'styled-components';

const SlideShow = styled(Carousel)<{ bannersLength: number }>`
  margin: 25px 0;
  > .slick-dots li button {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background: ${({ theme }) => theme.color.slideshowInactiveDot};
  }
  > .slick-dots li.slick-active {
    & button {
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background: ${({ theme }) => theme.color.slideshowActiveDot};
    }
    width: 16px;
  }

  .slick-arrow {
    ${({ bannersLength }) => {
      const firstElement = bannersLength % 2 === 0 ? 8 : 19;
      const calculatedSpacing = clamp(firstElement + (bannersLength - 1) * 19, 40, Number.MAX_SAFE_INTEGER);
      return css`
        &.slick-prev {
          left: calc(50% - ${calculatedSpacing}px);
          height: 13px;
          width: 13px;
        }
        &.slick-next {
          right: calc(50% - ${calculatedSpacing}px);
          height: 13px;
          width: 13px;
        }
      `;
    }}

    pointer-events: all;
    cursor: pointer;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 2px;
    z-index: 1;
    top: unset;
  }
`;
export default { SlideShow };

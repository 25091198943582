import { CountryCodes } from '@/utils/type-definitions/iso-to-country-name';
import { setUserOnboardingStatus, setUserProducts } from '@context/user/user-account.actions';
import { Roles } from '@domain/accounts/roles';
import {
  SubscriptionProductStatus,
  SubscriptionProductType,
  createProductFromValue,
  type SubscriptionProduct,
} from '@domain/accounts/subscription-products';
import { OnboardingStatus } from '@domain/accounts/types';
import type { InvestorOnboardingStep } from '@pages/content/onboarding/investor/paths';
import {
  INVESTOR_ONBOARDING_STEP_AFFIRMATIONS_KEY,
  INVESTOR_ONBOARDING_STEP_BUSINESS_KEY,
  INVESTOR_ONBOARDING_STEP_COMMUNITY_KEY,
  INVESTOR_ONBOARDING_STEP_INTRO_KEY,
  INVESTOR_ONBOARDING_STEP_INVESTMENT_KEY,
  INVESTOR_ONBOARDING_STEP_PERSONAL_KEY,
  INVESTOR_ONBOARDING_STEP_TYPE_KEY,
} from '@pages/content/onboarding/investor/paths';
import { OnboardingLayout } from '@pages/content/onboarding/parts/layout/layout-default';
import type { InvestorProgressStep } from '@pages/content/portfolio/investor/api/get-progress.action';
import { InvestorType } from '@pages/content/profile/investor/api/types';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import message from '@parts/message/message';
import { Links } from '@router/links';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useInvestorOnboardingContext } from '@utils/hooks/use-onboarding-context/use-investor-onboarding-context';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { AsideItem } from '../../parts/aside/parts/aside-item/aside-item';
import { ProgressBox } from '../../parts/aside/progress-box/progress-box';
import { finishInvestorOnboardingAction } from '../api/finish-investor-onboarding.action';
import { useInvestorProgressFetching } from '../hooks/use-investor-progress-fetching';
import { steps } from '../paths.config';
import {
  isLastStep as assertIsLastStep,
  getCurrentStepDisplayedNumber,
  getDisplayedStepsCount,
  getNextStep,
  getPrevStep,
  getStep,
  getStepIndex,
  isStepExisting,
} from '../paths.helpers';

const progressRevalidateMap: Map<InvestorOnboardingStep, InvestorProgressStep[]> = new Map([
  [INVESTOR_ONBOARDING_STEP_PERSONAL_KEY, ['basicInformation']],
  [INVESTOR_ONBOARDING_STEP_INVESTMENT_KEY, ['investmentThesis']],
  [INVESTOR_ONBOARDING_STEP_BUSINESS_KEY, ['smartMatchCriteria']],
  [INVESTOR_ONBOARDING_STEP_COMMUNITY_KEY, ['community']],
  [INVESTOR_ONBOARDING_STEP_AFFIRMATIONS_KEY, ['confirmation']],
]);

export const InvestorOnboardingStepPage = () => {
  const history = useHistory();
  const { state: onboardingState, isLoading: isLoadingOnboardingState } = useInvestorOnboardingContext();
  const { step } = useParams<{ step: string }>();
  const { type: typeQueryParam } = useQueryParams();

  const {
    state: { userRole, products },
    helpers: { isFrom },
    dispatch,
  } = useUserAccount();

  const { progressList, refetch: refetchProgress, initialized: progressInitialized } = useInvestorProgressFetching();

  const [progressTitle, progressMobileTitle] = useTranslation([
    'onboarding.progress.title',
    'onboarding.progress.mobileTitle',
  ]);

  const investorAwaitingProduct: SubscriptionProduct = createProductFromValue({
    type: SubscriptionProductType.INVESTOR,
    status: SubscriptionProductStatus.WAITING_FOR_APPROVAL,
    discoverable: false,
  });

  const nedBecomeAwaitingInvestorProduct = userRole === Roles.NED ? [...products, investorAwaitingProduct] : products;

  const stepErrors = onboardingState.stepErrors.filter((item) => item.step === (step as InvestorOnboardingStep));

  const { mutateAsync: finishInvestorOnboarding, isLoading: isFinishOnboardingPending } = useMutation(
    finishInvestorOnboardingAction,
    {
      async onSuccess() {
        dispatch(setUserProducts(nedBecomeAwaitingInvestorProduct));
        dispatch(setUserOnboardingStatus({ investor: OnboardingStatus.Finished }));

        history.push(Links.ONBOARDING_INVESTOR_SUMMARY());
      },
      onError(err: AxiosError) {
        message.error({ content: getServerError(err) });
      },
    },
  );

  const typeNotSelected =
    step !== INVESTOR_ONBOARDING_STEP_TYPE_KEY &&
    [null, InvestorType.NonSpecified].includes(onboardingState.accountType);

  if (onboardingState.loaded && !onboardingState.started) {
    return (
      <Redirect
        to={{
          pathname: Links.ONBOARDING_INVESTOR(),
        }}
      />
    );
  }

  if (!isStepExisting(steps, step) || typeNotSelected) {
    return (
      <Redirect
        to={{
          pathname: Links.ONBOARDING_INVESTOR_STEP(INVESTOR_ONBOARDING_STEP_TYPE_KEY),
        }}
      />
    );
  }

  const nextStep = getNextStep(steps, step);
  const prevStep = getPrevStep(steps, step);
  const isLastStep = assertIsLastStep(steps, step);
  const currentStepIndex = getStepIndex(steps, step);
  const currentStep = getStep(steps, step);
  const isFromUS = isFrom(CountryCodes.US);

  const goToNextStep = () => {
    const omittedIntroStep =
      isFromUS && nextStep.key === INVESTOR_ONBOARDING_STEP_INTRO_KEY ? getNextStep(steps, nextStep.key) : nextStep;

    history.push(history.createHref({ pathname: Links.ONBOARDING_INVESTOR_STEP(omittedIntroStep.key) }));
  };

  const goToPrevStep = () => {
    const omittedIntroStep =
      isFromUS && prevStep?.key === INVESTOR_ONBOARDING_STEP_INTRO_KEY ? getPrevStep(steps, prevStep?.key) : prevStep;

    const prevPath =
      prevStep && omittedIntroStep
        ? Links.ONBOARDING_INVESTOR_STEP(omittedIntroStep.key)
        : Links.ONBOARDING_INVESTOR_STEP(INVESTOR_ONBOARDING_STEP_TYPE_KEY);

    history.push(history.createHref({ pathname: prevPath }));
  };

  const handleRefetchProgress = (key: InvestorOnboardingStep) => {
    const shouldRefetch = progressRevalidateMap.has(key);
    if (!shouldRefetch) return;

    const revalidateList = progressRevalidateMap.get(key) ?? [];
    refetchProgress(revalidateList);
  };
  const prevButtonHandler = async () => {
    if (currentStep) handleRefetchProgress(currentStep.key);

    goToPrevStep();
  };

  const nextButtonHandler = async () => {
    if (currentStep) handleRefetchProgress(currentStep.key);

    if (stepErrors[0]) {
      message.error({ content: stepErrors[0]?.error ?? '' });
      return;
    }

    if (isLastStep) {
      await finishInvestorOnboarding();
      return;
    }

    goToNextStep();
  };

  const finishLaterHandler = () => {
    history.push(Links.START_PAGE());
  };

  const isUserInInstitutionalTypeSelectionScreen = !currentStepIndex && typeQueryParam === InvestorType.Institutional;

  const showPrevButton = currentStepIndex || isUserInInstitutionalTypeSelectionScreen;

  const disableNextButton =
    isLoadingOnboardingState || isFinishOnboardingPending || stepErrors.some((item) => item.disableButton === true);

  const showFinishLater = userRole === Roles.NED && isLastStep === false;

  const { Content } = currentStep ?? { Content: () => null };
  return (
    <OnboardingLayout
      currentStep={getCurrentStepDisplayedNumber(steps, step)}
      totalSteps={getDisplayedStepsCount(steps)}
      asideContent={
        <AsideItem title={progressTitle} mobileTileTitle={progressMobileTitle} dataTestId="onboarding-progress-box">
          {progressInitialized ? <ProgressBox data={progressList} /> : <FullHeightSpinner small />}
        </AsideItem>
      }
      onPreviousClick={showPrevButton ? prevButtonHandler : undefined}
      onNextClick={nextButtonHandler}
      lastPage={isLastStep}
      nextDisabled={disableNextButton}
      finishLater={showFinishLater}
      onFinishLater={finishLaterHandler}
    >
      <Content
        data-testid="onboarding-layout-content"
        currentPage={getCurrentStepDisplayedNumber(steps, step)}
        endPage={getDisplayedStepsCount(steps)}
      />
    </OnboardingLayout>
  );
};

import { V2IconBox } from '@/components/ui/v2-icon-box/v2-icon-box';
import type { V2IconName } from '@/components/ui/v2-icon/icons';
import { V2InfoPanel } from '@/components/ui/v2-info-panel/v2-info-panel';
import { V2Link } from '@/components/ui/v2-link/v2-link';
import { V2ProgressBar } from '@/components/ui/v2-progress-bar/v2-progress-bar';
import { V2WithAsterisk } from '@/components/ui/v2-with-asterisk/v2-with-asterisk';
import { useMediaQuery } from '@/hooks/use-media-query';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import type { ReactNode } from 'react';

import S from './step-content.style';

export type ContentStep = {
  id: string;
  title: string;
  description: React.ReactNode;
  received: number;
  available: number;
  icon: V2IconName;
  required?: boolean;
  actionUrl?: string;
  customActionElement?: ReactNode;
};

export const StepContent = ({
  introTitle,
  introDescription,
  headerIcon,
  steps,
  score,
}: {
  introTitle: ReactNode;
  introDescription: ReactNode;
  headerIcon: V2IconName;
  steps: ContentStep[];
  score: {
    received: number;
    available: number;
  };
}) => {
  const [todoListTitleLL, completedListTitleLL, requiredInfoLL, editLL] = useTranslation([
    'homepage.readinessScore.modal.step.list.todo',
    'homepage.readinessScore.modal.step.list.completed',
    'homepage.readinessScore.modal.step.requiredInfo',
    'homepage.readinessScore.modal.step.edit',
  ]);
  const size = useMediaQuery();

  const isSomeStepRequired = steps.some((step) => step.required);
  const [todoSteps, completedSteps] = steps.reduce<[ContentStep[], ContentStep[]]>(
    (acc, step) => {
      if (step.received < step.available) acc[0].push(step);
      else acc[1].push(step);
      return acc;
    },
    [[], []],
  );

  return (
    <S.Wrapper>
      <S.Header>
        <S.Description>
          <S.DescriptionTitle>{introTitle}</S.DescriptionTitle>
          <S.DescriptionInfo>{introDescription}</S.DescriptionInfo>
          <S.DescriptionProgress>
            <V2ProgressBar percent={(score.received / score.available) * 100} variant="blue-red" />
          </S.DescriptionProgress>
        </S.Description>

        <S.HeaderAside>
          <V2IconBox icon={headerIcon} size={size === 'xs' ? 'md' : 'lg'} variant="secondary" />
          <S.AsideScore>
            <span>
              {score.received}/{score.available}
            </span>
            <span>points</span>
          </S.AsideScore>
        </S.HeaderAside>
      </S.Header>

      <S.Content>
        {todoSteps.length > 0 ? (
          <>
            <S.ContentTitle $variant="primary" data-testid="todo-list-title">
              {todoListTitleLL}
            </S.ContentTitle>
            <ul>
              {todoSteps.map((step) => (
                <li key={step.id}>
                  <V2InfoPanel
                    title={step.title}
                    subtitle={step.description}
                    icon={step.icon}
                    data-testid="todo-step-info-panel"
                    required={step.required}
                    {...(step.actionUrl
                      ? { actionUrl: step.actionUrl }
                      : { customActionElement: step.customActionElement })}
                  />
                </li>
              ))}
            </ul>
          </>
        ) : null}

        {completedSteps.length > 0 ? (
          <>
            <S.ContentTitle $variant="dimmed" data-testid="completed-list-title">
              {completedListTitleLL}
            </S.ContentTitle>
            <ul>
              {completedSteps.map((step) => (
                <li key={step.id}>
                  <V2InfoPanel
                    variant="inactive"
                    title={step.title}
                    subtitle={
                      <span>
                        <span>{step.description}</span>
                        {step.actionUrl ? (
                          <>
                            {' '}
                            <V2Link to={step.actionUrl} variant="primary">
                              {editLL}
                            </V2Link>
                          </>
                        ) : null}
                      </span>
                    }
                    icon={step.icon}
                    required={step.required}
                    customActionElement={step.customActionElement}
                    data-testid="completed-step-info-panel"
                  />
                </li>
              ))}
            </ul>
          </>
        ) : null}

        {isSomeStepRequired && (
          <S.RequiredInfo>
            <V2WithAsterisk position="start">{requiredInfoLL}</V2WithAsterisk>
          </S.RequiredInfo>
        )}
      </S.Content>
    </S.Wrapper>
  );
};

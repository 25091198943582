import type { V2Theme } from '@styles/v2-theme/v2-theme';
import type { FlattenSimpleInterpolation } from 'styled-components';

const isDarkMode = (themeMode: V2Theme['mode']) => themeMode === 'dark';

export const themedStyle = (
  themeMode: V2Theme['mode'],
  lightProperties: string | FlattenSimpleInterpolation,
  darkProperties: string | FlattenSimpleInterpolation,
) => {
  return isDarkMode(themeMode) ? darkProperties : lightProperties;
};

import { useHistory } from '@/utils/hooks/use-history/use-history';
import { useQueryParams } from '@/utils/hooks/use-query/use-query-params';
import { parse, stringify } from 'query-string';
import type { openModalQueryParamKey } from './use-modal-query';

export const openModalNestedContentQueryParamKey = 'modalNestedKey';

const BASE_CONTENT_KEY = 'base';

type Config = {
  id?: string;
  initialOpen?: boolean;
  scrollToTopOnChange?: boolean;
};

export const useModalMultilevelWithQuery = ({ id, scrollToTopOnChange = true }: Config) => {
  const history = useHistory();
  const { modalOpen: modalOpenSearchQueryParam, modalNestedKey: openModalNestedContentQueryParam } = useQueryParams<{
    [openModalQueryParamKey]: string | undefined;
    [openModalNestedContentQueryParamKey]: string | undefined;
  }>();
  const isOpen = modalOpenSearchQueryParam === id;

  const close = () => {
    const { modalOpen, modalNestedKey, ...restQueryParams } = parse(history.location.search);
    history.replace({ search: stringify(restQueryParams) });
  };

  const openModal = ({ key }: { key?: string | null }) => {
    history.replace({ search: stringify({ modalOpen: id, modalNestedKey: key || BASE_CONTENT_KEY }) });
  };

  const setContentKeyWithScroll = (key: string) => {
    history.replace({ search: stringify({ modalOpen: id, modalNestedKey: key }) });

    if (!scrollToTopOnChange) return;
    const modalWrapperElementClass = 'ant-modal-wrap';
    const modalWrap = document.getElementsByClassName(modalWrapperElementClass)[0];
    if (modalWrap) modalWrap.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return {
    isOpen,
    openModal,
    close,

    contentKey: openModalNestedContentQueryParam || BASE_CONTENT_KEY,
    setContentKey: setContentKeyWithScroll,
  };
};

import ShareIcon from '@assets/icons/share-variant.svg?react';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import message from '@parts/message/message';
import { useModal } from '@parts/modal/use-modal';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useState } from 'react';
import { generateExpirationTime } from './api/generate-expiration-time.action';
import { resetExpirationTime } from './api/reset-expiration-time.action';
import { sharePreviewReportAction } from './api/share-preview-report.action';
import { updatePreviewReport } from './api/update-preview-report.action';
import { upsertPreviewReport } from './api/upsert-preview-report.action';
import ShareByEmail from './parts/share-by-email/share-by-email';
import { ShareByUrl } from './parts/share-by-url/share-by-url';
import S from './share-dashboard.styles';

export interface SharedDashboard {
  expireAt: string;
  hash: string;
  id: string;
  password: string | null;
}

export const ShareDashboard = ({ dashboardId }: { dashboardId: string }) => {
  const { Modal, open, show: showModal, hide: hideModal } = useModal();

  const [shareLabel, shareAsPage, generatedSuccess, resetSuccess, passwordUpdated, messageSentText] = useTranslation([
    'founder.pulseDashboard.share',
    'pulseDashboard.shared.shareAsPage',
    'pulseDashboard.shared.modal.shareAsUrl.description.generatedSuccess',
    'pulseDashboard.shared.modal.shareAsUrl.description.resetSuccess',
    'pulseDashboard.shared.modal.shareAsUrl.description.passwordUpdated',
    'pulseDashboard.shared.modal.shareAsEmail.messageSent',
  ]);

  const [sharedDashboard, setSharedDashboard] = useState<SharedDashboard>();

  const { mutateAsync: getPublicReport, isLoading } = useMutation(upsertPreviewReport, {
    onSuccess: (resp) => setSharedDashboard(resp.data),
  });

  const onError = (err: AxiosError) => {
    message.error({ content: getServerError(err) });
  };

  const { mutateAsync: handleResetExpirationTime } = useMutation(resetExpirationTime, {
    onSuccess: (resp) => {
      setSharedDashboard(resp.data);
      message.info({ content: resetSuccess });
    },
    onError,
  });

  const { mutateAsync: handleGenerateExpirationTime } = useMutation(generateExpirationTime, {
    onSuccess: (resp) => {
      setSharedDashboard(resp.data);
      message.info({ content: generatedSuccess });
    },
    onError,
  });

  const { mutateAsync: handlePasswordUpdate } = useMutation(updatePreviewReport, {
    onSuccess: (resp) => {
      setSharedDashboard(resp.data);
      message.info({ content: passwordUpdated });
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  const { mutateAsync: handleSharePreviewReport } = useMutation(sharePreviewReportAction, {
    onSuccess: () => {
      message.success({ content: messageSentText });
    },
    onError,
  });

  const sharePage = () => {
    showModal();
    getPublicReport(dashboardId);
  };

  return (
    <>
      <S.Button size="small" onClick={sharePage} data-testid="share-button">
        <ShareIcon /> {shareLabel}
      </S.Button>
      <Modal
        visible={open}
        closeModal={hideModal}
        title={shareAsPage}
        footer={null}
        width={670}
        data-testid="share-modal"
      >
        {isLoading ? (
          <FullHeightSpinner small />
        ) : (
          sharedDashboard && (
            <div>
              <ShareByUrl
                {...sharedDashboard}
                resetUrl={handleResetExpirationTime}
                generateUrl={handleGenerateExpirationTime}
                handlePasswordUpdate={handlePasswordUpdate}
              />
              <S.Divider />
              <ShareByEmail
                handleSharePreviewReport={
                  (values: { emailAddresses: string[]; note: string | null }) =>
                    handleSharePreviewReport({ dashboardId: sharedDashboard.id, ...values })
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              />
            </div>
          )
        )}
      </Modal>
    </>
  );
};

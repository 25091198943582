import { axiosInstance } from '@utils/axios/axios-instance';
import { DateTime } from 'luxon';
import type { AddExperienceFormSchemaType } from '../components/add-experience-modal/types';

type AddNewExperiencePayload = {
  formData: AddExperienceFormSchemaType;
  companyId?: string;
};

export const addNewExperience = ({ formData, companyId }: AddNewExperiencePayload) => {
  const externalCompany = {
    name: formData.name,
    industry: formData.industry,
    email: formData.email?.trim() ? formData.email : null,
  };

  const payload = {
    companyId: companyId,
    externalCompany: companyId ? undefined : externalCompany,
    role: formData.role,
    startDate: DateTime.fromISO(formData.startDate).toUTC().toISO(),
    endDate: formData.endDate && DateTime.fromISO(formData.endDate).toUTC().toISO(),
    isOngoing: formData.isOngoing,
    compensationType: formData.compensationType === '-' ? null : formData.compensationType,
    skillSet: formData.skillSet,
    experienceSummary: formData.experienceSummary?.trim().length ? formData.experienceSummary : null,
  };

  return axiosInstance.post('/profile/ned/experience', payload);
};

import { V2ExperienceTile } from '@/components/blocks/v2-experience-tile/v2-experience-tile';
import { V2Avatar } from '@/components/ui/v2-avatar/v2-avatar';
import { useAddExperienceContext } from '../add-experience-modal.context';
import { V2Button } from '@/components/ui/v2-button/v2-button';
import { useAddNewExperience } from '../../../hooks/use-add-new-experience';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';

import * as S from './steps.styles';
import { useGetExperience } from '../../../hooks/use-get-experience';

type SummaryProps = {
  next: () => void;
  back: () => void;
};

export const Summary = ({ next, back }: SummaryProps) => {
  const { state } = useAddExperienceContext();
  const { refetchExperience } = useGetExperience();

  const onAddSuccess = () => {
    refetchExperience();
    next();
  };

  const { addNewExperienceMutation, isAddNewExperienceLoading } = useAddNewExperience(onAddSuccess);

  const [backLabel, addLabel, addAndRequest, present] = useTranslation([
    'profile.advisor.exp.form.back',
    'profile.advisor.exp.form.add',
    'profile.advisor.exp.form.addAndRequest',
    'profile.advisor.exp.form.present',
  ]);

  return (
    <div>
      <div style={{ padding: 24 }}>
        <S.Title>{addAndRequest}</S.Title>
        <V2ExperienceTile
          avatar={<V2Avatar name={state.formData.name} data-testid="user-avatar" />}
          companyInfo={{
            details: {
              companyName: state.formData.name,
              role: state.formData.role,
              startDate: state.formData.startDate,
              endDate: state.formData.endDate ?? present,
            },
            descriptionText: state.formData.experienceSummary ?? '',
          }}
          industryItems={state.formData.industry?.map((e) => (
            <V2ExperienceTile.Pill key={e} isIndustry>
              {e}
            </V2ExperienceTile.Pill>
          ))}
          skillsItems={state.formData.skillSet?.map((e) => <V2ExperienceTile.Pill key={e}>{e}</V2ExperienceTile.Pill>)}
          description={<V2ExperienceTile.Description />}
          companyInfoSection={
            <V2ExperienceTile.Details>
              <V2ExperienceTile.CompanyName />
              <V2ExperienceTile.Role />
              <V2ExperienceTile.DateRange />
            </V2ExperienceTile.Details>
          }
        />
      </div>
      <S.ButtonWrapper>
        <V2Button
          disabled={isAddNewExperienceLoading}
          isResponsive
          variant="outlined"
          startIcon="arrow-left"
          onClick={back}
        >
          {backLabel}
        </V2Button>
        <div style={{ display: 'flex' }}>
          <V2Button
            disabled={isAddNewExperienceLoading}
            isResponsive
            variant="primary"
            onClick={() => {
              addNewExperienceMutation({ formData: state.formData, companyId: state.companyId });
            }}
          >
            {addLabel}
          </V2Button>
        </div>
      </S.ButtonWrapper>
    </div>
  );
};

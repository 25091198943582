import { Tooltip } from '@parts/tooltip/tooltip';
import { Tabs as TabsBase } from 'antd';
import { type ReactNode } from 'react';
import type { Any } from 'src/types';
import S from './tabs.styles';

export interface TabItem {
  key: string;
  title: ReactNode;
  titleTooltip: ReactNode;
  content: ReactNode;
  disabled?: boolean;
}

interface TabsProps {
  items: TabItem[];
  activeKey: string;
  onChange: (key: string) => void;
}

const CustomTabBar = ({
  panes,
  activeKey,
  onChange,
}: {
  panes: Any[];
  activeKey: string;
  onChange: (key: string) => void;
}) => (
  <S.TabBar data-testid="tab-bar">
    {panes.map(
      (pane: { key: string; activeKey: string; disabled: boolean; props: { tab: ReactNode; disabled: boolean } }) => {
        const isActive = pane.key === activeKey;
        return (
          <S.TabBarItem key={pane.key} isActive={isActive} data-testid={`tab-bar-item${isActive ? '-active' : ''}`}>
            <S.TabButton type="button" disabled={pane.props.disabled} key={pane.key} onClick={() => onChange(pane.key)}>
              {pane.props.tab}
            </S.TabButton>
          </S.TabBarItem>
        );
      },
    )}
  </S.TabBar>
);

const TabTitle = ({ title, tooltip }: { title: ReactNode; tooltip: ReactNode }) => (
  <S.TabTitle data-testid="tab-title">
    {title}
    {tooltip ? (
      <div>
        <Tooltip title={tooltip} />
      </div>
    ) : null}
  </S.TabTitle>
);

export const Tabs = ({ items, activeKey, onChange }: TabsProps) => (
  <TabsBase
    activeKey={activeKey}
    onChange={onChange}
    destroyInactiveTabPane
    renderTabBar={(props) => <CustomTabBar {...props} onChange={onChange} />}
    data-testid="tabs"
  >
    {items.map((item) => (
      <TabsBase.TabPane
        disabled={item.disabled}
        key={item.key}
        tab={<TabTitle title={item.title} tooltip={item.titleTooltip} />}
      >
        {item.content}
      </TabsBase.TabPane>
    ))}
  </TabsBase>
);

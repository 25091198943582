import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  white-space: pre-line;

  svg {
    margin-left: 15px;
    flex-shrink: 0;
  }
`;

export default {
  Content,
};

import MetricsExplained from '@assets/icons/metrics-explained.svg';
import { Description } from '@pages/content/onboarding/founder/content/performance/parts/description/description';
import { Wrapper } from '@pages/content/onboarding/founder/content/performance/parts/wrapper/wrapper';
import type { StepContentProps } from '@pages/content/onboarding/founder/paths.config';
import Title from '@pages/content/onboarding/parts/title/title';
import { useListEntryRevealAnimation } from '@utils/hooks/use-list-reveal-animation/use-list-reveal-animation';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './data-driven.network.styles';

const LIST_ANIMATION_ELEMENT_ID = 'data-driven-network-list-animation-id';

export const DataDrivenNetwork = ({ currentPage, endPage }: StepContentProps) => {
  useListEntryRevealAnimation(LIST_ANIMATION_ELEMENT_ID);
  const [
    titleLabel,
    subTitleLabel,
    descriptionTitle,
    descriptionContentFirst,
    descriptionContentSecond,
    imageAltLabel,
  ] = useTranslation([
    'founder-onboarding.dataDrivenNetwork.title',
    'founder-onboarding.dataDrivenNetwork.subtitle',
    'founder-onboarding.dataDrivenNetwork.description.title',
    'founder-onboarding.dataDrivenNetwork.description.contentFirst',
    'founder-onboarding.dataDrivenNetwork.description.contentSecond',
    'founder-onboarding.dataDrivenNetwork.image.alt',
  ]);

  const descriptionContent = () => {
    const bulletPoints = descriptionContentFirst.split('\n');

    return (
      <>
        <S.BulletList>
          {bulletPoints.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </S.BulletList>
        {descriptionContentSecond}
      </>
    );
  };

  return (
    <>
      <Title title={titleLabel} currentPage={currentPage} endPage={endPage} subHeadline={subTitleLabel} />

      <S.Image
        src={MetricsExplained}
        alt={imageAltLabel}
        data-animation-id={LIST_ANIMATION_ELEMENT_ID}
        loading="lazy"
      />
      <Wrapper>
        <Description
          title={descriptionTitle}
          content={descriptionContent()}
          data-animation-id={LIST_ANIMATION_ELEMENT_ID}
          data-testid="path3-step1-description"
        />
      </Wrapper>
    </>
  );
};

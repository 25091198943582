import { useQuery } from '@tanstack/react-query';
import { GET_FOUNDER_INVITES_COUNT_QUERY_KEY, getFounderInvitesCount } from '../api/get-founder-invites-count.query';

export const useFounderInvitesCountQuery = () => {
  const queryResult = useQuery([GET_FOUNDER_INVITES_COUNT_QUERY_KEY], getFounderInvitesCount);

  const { data, error, isLoading, isFetching, isInitialLoading } = queryResult;
  const count = data?.data?.total ?? 0;

  return {
    invitesCount: count,
    invitesCountError: error,
    isInvitesCountDataLoading: isLoading,
    isInvitesCountDataFetching: isFetching,
    isInvitesCountInitialLoading: isInitialLoading,
    ogQuery: queryResult,
  };
};

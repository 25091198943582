import type { MarketFitQuestionItem } from '@/pages/content/profile/founder/api/get-market-fit-questions/get-market-fit-questions.query';
import Team from '@assets/icons/account-group.svg?react';
import CardAccount from '@assets/icons/card-account-details.svg?react';
import GreenTick from '@assets/icons/green-tick-icon.svg?react';
import Information from '@assets/icons/information.svg?react';
import RedCross from '@assets/icons/red-close.svg?react';
import Tune from '@assets/icons/tune.svg?react';
import { Roles } from '@domain/accounts/roles';
import { DateFormat } from '@i18n/date-formats';
import { FundingRound } from '@pages/content/parts/funding-round/funding-round';
import type { TeamMemberData } from '@pages/content/profile/founder/api/get-founder-team-members/get-founder-team-members.action';
import { TeamMembersTable } from '@pages/content/profile/founder/parts/editor/parts/team-members/parts/drawer/table/table';
import { checkIfValidWysiWygValue } from '@pages/content/profile/hooks/use-wysiwyg';
import { Avatar } from '@parts/avatar/avatar';
import { PageHeader } from '@parts/page-header/page-header';
import { Section } from '@parts/section/section';
import { Tooltip } from '@parts/tooltip/tooltip';
import { SanitizeWysiwyg } from '@utils/fns/sanitize-wysiwyg';
import { scrollToTop as scrollToTopFn } from '@utils/fns/scroll-to-top';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { CountryCodes, CountryNames } from '@utils/type-definitions/iso-to-country-name';
import { Col, Row as RowBase } from 'antd';
import type { RawDraftContentState } from 'draft-js';
import type { File } from '../../../../profile/founder/founder-profile.page';
import LinkedinOutlinedIcon from '../../../../profile/parts/linkedin-outlined-icon/linkedin-outlined-icon';
import TwitterOutlinedIcon from '../../../../profile/parts/twitter-outlined-icon/twitter-outlined-icon';
import { ExternalUrl } from '../../../parts/extrenal-url/external-url';
import { Row } from '../../../parts/row/row';
import { SectionReversedOnMobile } from '../../../parts/section-reversed-on-mobile/section-reversed-on-mobile';
import { Tag } from '../../../parts/tag/tag';
import { MarketFitAnswers } from './market-fit-answers/market-fit-answers';
import S from './overview.styles';

export interface FounderPublicData {
  businessName: string;
  countryOfResidence: CountryCodes | null;
  profilePicture: {
    path: string | null;
    url: string | null;
  };
  linkedinUrl: string | null;
  twitterUrl: string | null;
  about?: RawDraftContentState | null;
  founded: string | null;
  achievements: RawDraftContentState | null;
  industries: string[];
  customerGroup: string[];
  productStage: string | null;
  investmentStage: string | null;
  hasSeisLimitsRemaining?: boolean;
  hasEisLimitsRemaining?: boolean;
  pitchDeck: File | null;
  productVideoUrl: string | null;
  teamMembers: TeamMemberData[];
  marketFitAnswers: Omit<MarketFitQuestionItem, 'lengthLimits'>[];
  otherFiles: File[];
  financialModelFiles: File[];
}
export const Overview = ({
  connection,
  data: {
    businessName,
    about,
    linkedinUrl,
    twitterUrl,
    countryOfResidence,
    teamMembers,
    marketFitAnswers,
    industries,
    productStage,
    customerGroup,
    investmentStage,
    founded,
    hasSeisLimitsRemaining,
    hasEisLimitsRemaining,
    pitchDeck,
    productVideoUrl,
    profilePicture,
    otherFiles,
    achievements,
    financialModelFiles,
  },
  className,
  goBack: handleBackAction,
  scrollToTop = false,
}: {
  data: FounderPublicData;
  connection?: boolean;
  className?: string;
  goBack?: () => void;
  scrollToTop?: boolean;
}) => {
  const [
    detailsHeaderLabel,
    detailsCountryOfResidenceLabel,
    detailsFoundedLabel,
    detailsLinkedinLabel,
    detailsTwitterLabel,
    detailsAboutLabel,
    detailsAchievementsLabel,
    smartMatchIndustryLabel,
    smartMatchCustomerGroupLabel,
    smartMatchProductStageLabel,
    smartMatchInvestmentStageLabel,
    smartMatchSeisRemainingLabel,
    smartMatchSeisRemainingTooltip,
    smartMatchEisRemainingLabel,
    smartMatchEisRemainingTooltip,
    smartMatchHeaderLabel,
    teamHeader,
    yesLabel,
    noLabel,
    marketFitHeader,
    additionalInformationHeader,
    additionalInformationInvestorDeckLabel,
    additionalInformationProductVideoLabel,
    additionalInformationOtherFilesLabel,
    additionalInformationFinancialModelLabel,
    teamEmptyLabel,
    additionalInfoEmptyLabel,
    detailsEmptyLabel,
    smartMatchEmptyLabel,
  ] = useTranslation([
    'profile.preview.founder.details.header',
    'profile.preview.founder.details.countryOfResidence',
    'profile.preview.founder.details.founded',
    'profile.preview.founder.details.linkedin',
    'profile.preview.founder.details.twitter',
    'profile.preview.founder.details.about',
    'profile.preview.founder.details.achievements',
    'profile.preview.founder.smartMatch.industry',
    'profile.preview.founder.smartMatch.customerGroup',
    'profile.preview.founder.smartMatch.productStage',
    'profile.preview.founder.smartMatch.investmentStage',
    'profile.preview.founder.smartMatch.seisRemaining',
    'profile.preview.founder.smartMatch.seisRemainingTooltip',
    'profile.preview.founder.smartMatch.eisRemaining',
    'profile.preview.founder.smartMatch.eisRemainingTooltip',
    'profile.preview.founder.smartMatch.header',
    'profile.preview.founder.team.header',
    'profile.preview.founder.smartMatch.yes',
    'profile.preview.founder.smartMatch.no',
    'profile.preview.founder.marketFit.header',
    'profile.preview.founder.additionalInformation.header',
    'profile.preview.founder.additionalInformation.pitchDeck',
    'profile.preview.founder.additionalInformation.productVideo',
    'profile.preview.founder.additionalInformation.otherFiles',
    'profile.preview.founder.additionalInformation.financialModel',
    'profile.preview.founder.team.emptyState',
    'profile.preview.founder.additionalInfo.emptyState',
    'profile.preview.founder.details.emptyState',
    'profile.preview.founder.smartMatch.emptyState',
  ]);

  if (scrollToTop) {
    scrollToTopFn();
  }

  const {
    state: { userRole },
    helpers: { isFrom },
  } = useUserAccount();

  const shouldShowSeisEis = isFrom(CountryCodes.GB);

  const { dateFormatter } = useUserDateFormat();

  const isAbout =
    (checkIfValidWysiWygValue(about) && JSON.parse(about as unknown as string)?.blocks?.[0]?.text) || false;

  const isAchievements =
    (checkIfValidWysiWygValue(achievements) && JSON.parse(achievements as unknown as string)?.blocks?.[0]?.text) ||
    false;

  const isAnyProfileDetails = countryOfResidence || founded || linkedinUrl || twitterUrl || isAbout || isAchievements;

  const isAnySmartMatch =
    industries.length !== 0 ||
    productStage ||
    customerGroup.length !== 0 ||
    investmentStage ||
    hasSeisLimitsRemaining ||
    hasEisLimitsRemaining;

  const isAnyAdditionalInformation =
    otherFiles?.length !== 0 || productVideoUrl || pitchDeck || financialModelFiles?.length !== 0;

  return (
    <div className={className}>
      {handleBackAction && <PageHeader title={businessName} onBack={handleBackAction} />}
      {connection}
      <SectionReversedOnMobile header={detailsHeaderLabel}>
        {isAnyProfileDetails ? (
          <RowBase>
            <Col data-testid="viewAsPage" md={18} style={{ marginRight: '40px' }}>
              {founded && (
                <Row label={detailsFoundedLabel} content={dateFormatter(founded, DateFormat.LETTER_MONTH_LONG_YEAR)} />
              )}

              {countryOfResidence ? (
                <Row label={detailsCountryOfResidenceLabel} content={CountryNames[countryOfResidence]} />
              ) : null}

              {linkedinUrl && (
                <Row
                  label={
                    <>
                      {detailsLinkedinLabel}
                      <LinkedinOutlinedIcon />
                    </>
                  }
                  content={
                    <div data-testid="detailsLinkedin">
                      <ExternalUrl href={linkedinUrl ?? ''} />
                    </div>
                  }
                />
              )}
              {twitterUrl && (
                <Row
                  label={
                    <>
                      {detailsTwitterLabel}
                      <TwitterOutlinedIcon />
                    </>
                  }
                  content={
                    <div data-testid="twitter">
                      <ExternalUrl href={twitterUrl ?? ''} />
                    </div>
                  }
                />
              )}
              {isAbout && (
                <Row
                  label={detailsAboutLabel}
                  content={
                    <div data-testid="detailsAbout">
                      <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: SanitizeWysiwyg(JSON.parse(about as unknown as string)),
                        }}
                      />
                    </div>
                  }
                />
              )}
              {isAchievements && (
                <Row
                  label={detailsAchievementsLabel}
                  content={
                    <div data-testid="detailsAchievements">
                      <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: SanitizeWysiwyg(JSON.parse(achievements as unknown as string)),
                        }}
                      />
                    </div>
                  }
                />
              )}
            </Col>

            <Col xs={24} sm={24} md={24} lg={5}>
              <Avatar letter={businessName[0]} avatarUrl={profilePicture?.url} size={120} />
            </Col>
          </RowBase>
        ) : (
          <S.EmptyState slim Icon={CardAccount} subTitle={detailsEmptyLabel} />
        )}
      </SectionReversedOnMobile>

      <Section header={smartMatchHeaderLabel}>
        {isAnySmartMatch ? (
          <>
            {industries.length !== 0 && (
              <Row
                label={smartMatchIndustryLabel}
                content={
                  <>
                    {industries.map((el) => (
                      <Tag key={el}>{el}</Tag>
                    ))}
                  </>
                }
              />
            )}

            {productStage && (
              <Row label={smartMatchProductStageLabel} content={<Tag key={productStage}>{productStage}</Tag>} />
            )}

            {customerGroup.length !== 0 && (
              <Row
                label={smartMatchCustomerGroupLabel}
                content={
                  <>
                    {customerGroup.map((cg) => (
                      <Tag key={cg}>{cg}</Tag>
                    ))}
                  </>
                }
              />
            )}

            {investmentStage && (
              <Row
                label={smartMatchInvestmentStageLabel}
                content={<Tag key={investmentStage}>{investmentStage}</Tag>}
              />
            )}

            {shouldShowSeisEis && (
              <>
                <Row
                  label={
                    <>
                      {smartMatchSeisRemainingLabel} <Tooltip title={smartMatchSeisRemainingTooltip} />
                    </>
                  }
                  content={
                    <>
                      {hasSeisLimitsRemaining ? (
                        <>
                          <GreenTick /> {yesLabel}
                        </>
                      ) : (
                        <>
                          <RedCross /> {noLabel}
                        </>
                      )}
                    </>
                  }
                />
                <Row
                  label={
                    <>
                      {smartMatchEisRemainingLabel} <Tooltip title={smartMatchEisRemainingTooltip} />
                    </>
                  }
                  content={
                    <>
                      {hasEisLimitsRemaining ? (
                        <>
                          <GreenTick /> {yesLabel}
                        </>
                      ) : (
                        <>
                          <RedCross /> {noLabel}
                        </>
                      )}
                    </>
                  }
                />
              </>
            )}
          </>
        ) : (
          <S.EmptyState slim Icon={Tune} subTitle={smartMatchEmptyLabel} />
        )}
      </Section>

      {connection && (userRole === Roles.INVESTOR || userRole === Roles.INVESTOR_NED) && (
        <Section>
          <FundingRound readOnly />
        </Section>
      )}

      <Section header={teamHeader}>
        {teamMembers.length !== 0 ? (
          <TeamMembersTable members={teamMembers} />
        ) : (
          <S.EmptyState slim Icon={Team} subTitle={teamEmptyLabel} />
        )}
      </Section>

      {marketFitAnswers.some((answer) => answer.booleanAnswer === true) ? (
        <Section header={marketFitHeader}>
          <MarketFitAnswers answers={marketFitAnswers} />
        </Section>
      ) : null}

      <Section header={additionalInformationHeader}>
        {isAnyAdditionalInformation ? (
          <>
            {pitchDeck && (
              <Row
                label={additionalInformationInvestorDeckLabel}
                direction="column"
                content={<ExternalUrl href={pitchDeck?.url ?? ''}>{pitchDeck?.name}</ExternalUrl>}
              />
            )}

            {financialModelFiles.length !== 0 && (
              <Row
                label={additionalInformationFinancialModelLabel}
                direction="column"
                content={
                  <>
                    {financialModelFiles.map((file) => (
                      <ExternalUrl key={file.url} href={file.url ?? ''}>
                        {file.name}
                      </ExternalUrl>
                    ))}
                  </>
                }
              />
            )}

            {productVideoUrl && (
              <Row
                label={additionalInformationProductVideoLabel}
                direction="column"
                content={<ExternalUrl href={productVideoUrl} />}
              />
            )}

            {otherFiles.length !== 0 && (
              <Row
                label={additionalInformationOtherFilesLabel}
                direction="column"
                content={
                  <>
                    {otherFiles.map((file) => (
                      <ExternalUrl key={file.url} href={file.url ?? ''}>
                        {file.name}
                      </ExternalUrl>
                    ))}
                  </>
                }
              />
            )}
          </>
        ) : (
          <S.EmptyState slim Icon={Information} subTitle={additionalInfoEmptyLabel} />
        )}
      </Section>
    </div>
  );
};

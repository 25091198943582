import { AdvisorRole } from '@/domain/accounts/roles';
import { Links } from '@router/links';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './advisor-tiles-section.styles';
import { AdvisorTilesList } from './list/advisor-tiles-list';

export const AdvisorTilesSection = () => {
  const [
    proBonoTitle,
    proBonoDescription,
    fractionalTitle,
    fractionalDescription,
    advisorTitle,
    advisorDescription,
    nedTitle,
    nedDescription,
  ] = useTranslation([
    'advisors.tiles.proBono.title',
    'advisors.tiles.proBono.description',
    'advisors.tiles.fractional.title',
    'advisors.tiles.fractional.description',
    'advisors.tiles.advisors.title',
    'advisors.tiles.advisors.description',
    'advisors.tiles.ned.title',
    'advisors.tiles.neds.description',
  ]);

  const advisorSearchTiles = [
    {
      id: AdvisorRole.PLACEMENT.toLowerCase(),
      title: proBonoTitle,
      description: proBonoDescription,
      url: Links.ADVISORS_SEARCH_ROLE(AdvisorRole.PLACEMENT.toLowerCase()),
      disabled: false,
      buttonTestId: 'view-pro-bono',
    },
    {
      id: AdvisorRole.FRACTIONAL.toLowerCase(),
      title: fractionalTitle,
      description: fractionalDescription,
      url: Links.ADVISORS_SEARCH_ROLE(AdvisorRole.FRACTIONAL.toLowerCase()),
      disabled: false,
      buttonTestId: 'view-fractional',
    },
    {
      id: AdvisorRole.ADVISOR.toLowerCase(),
      title: advisorTitle,
      description: advisorDescription,
      url: Links.ADVISORS_SEARCH_ROLE(AdvisorRole.ADVISOR.toLowerCase()),
      disabled: false,
      buttonTestId: 'view-advisor',
    },
    {
      id: AdvisorRole.NED.toLowerCase(),
      title: nedTitle,
      description: nedDescription,
      url: Links.ADVISORS_SEARCH_ROLE(AdvisorRole.NED.toLowerCase()),
      disabled: false,
      buttonTestId: 'view-ned',
    },
  ];

  return (
    <S.Section>
      <AdvisorTilesList items={advisorSearchTiles} />
    </S.Section>
  );
};

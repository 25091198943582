import { PageHeader as PageHeaderBase } from 'antd';
import styled from 'styled-components';

const PageHeader = styled(PageHeaderBase)`
  padding: 0 0 ${({ theme }) => theme.spacing.base} 0;

  .ant-page-header-heading-title {
    color: ${({ theme }) => theme.color.pageHeader};
    font-size: 30px;
    line-height: 45px;
    font-weight: 700;
    white-space: normal;

    @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
      padding-left: 15px;
    }
  }

  .ant-page-header-back-button {
    border: 2px solid rgb(62, 96, 235, 0.5) !important;
    padding: 7px !important;
    font-size: 23px;
    border-radius: ${({ theme }) => theme.radius.default};
    color: ${({ theme }) => theme.color.fontPrimary};

    path {
      fill: ${({ theme }) => theme.baseColors.Blue200};
    }
  }

  .ant-page-header-heading-sub-title {
    min-width: fit-content;
  }
`;

export default {
  PageHeader,
};

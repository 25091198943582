import { useOnWindowResize } from '@/hooks/use-window-resize';
import React, { useEffect, useState } from 'react';
import { V2Tooltip } from '../v2-tooltip/v2-tooltip';
import type { V2TabsProps } from './v2-tabs.types';

import S from './v2-tabs.styles';

export const V2Tabs = ({
  items,
  defaultActiveKey,
  gaps = 'md',
  gradientColor = 'blue-red-gradient',
  onChange,
  'data-testid': testId = 'v2-tabs',
  ...restProps
}: V2TabsProps) => {
  const [activeKey, setActiveKey] = useState<string>(() => defaultActiveKey ?? items[0]?.key ?? '');

  const headerRef = React.useRef<HTMLDivElement>(null);
  const [lineWidth, setLineWidth] = useState<number>(0);

  useOnWindowResize(() => {
    setLineWidth(headerRef.current?.scrollWidth ?? 0);
  });

  const handleScrollToActiveTab = (key: string) => {
    if (!headerRef.current) return;

    const isScrollable = headerRef.current?.scrollWidth > headerRef.current?.clientWidth;
    if (!isScrollable) return;

    const target = headerRef.current?.querySelector(`#tab-${key}`);
    if (target) {
      target.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    }
  };

  const handleTabChange = (key: string) => {
    setActiveKey(key);
    handleScrollToActiveTab(key);

    if (onChange) onChange(key);
  };

  useEffect(() => {
    setLineWidth(headerRef.current?.scrollWidth ?? 0);
    handleScrollToActiveTab(activeKey);
  }, [headerRef]);

  return (
    <S.Tabs data-testid={testId} {...restProps}>
      <S.TabsHeaderWrapper>
        <S.TabsHeader $gaps={gaps} $gradientColor={gradientColor} ref={headerRef} $lineWidth={lineWidth + 'px'}>
          {items.map((item) => (
            <S.TabsButton
              key={item.key}
              onClick={() => handleTabChange(item.key)}
              $active={activeKey === item.key}
              $gradientColor={gradientColor}
              disabled={item.disabled}
              id={'tab-' + item.key}
            >
              {item.title}
              {item.tooltip && <V2Tooltip tooltipText={item.tooltip} />}
            </S.TabsButton>
          ))}
        </S.TabsHeader>
      </S.TabsHeaderWrapper>

      <S.TabsContent>
        <S.TabsPane>{items.find((item) => item.key === activeKey)?.content}</S.TabsPane>
      </S.TabsContent>
    </S.Tabs>
  );
};

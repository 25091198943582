import type { ColorThemeMode } from '@/context/theme/app-theme.context';
import { useTheme } from '@/hooks/use-theme';
import EmptyStateDarkIcon from '@assets/icons/applications-empty-dark-state.svg';
import EmptyStateIcon from '@assets/icons/applications-empty-state.svg';
import { engageFounderMessagingAction } from '@pages/content/lens/api/message-ned-engage-founder';
import { EmptyState } from '@parts/empty-state/empty-state';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { DEFAULT_ITEMS_PER_PAGE_IN_GRID } from '@parts/items-grid/items-grid';
import { Pagination } from '@parts/pagination/pagination';
import { Section } from '@parts/section/section';
import { Table } from '@parts/table/table';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useEngageConversation } from '@utils/hooks/use-engage-conversation/use-engage-conversation';
import { useQueryPagination } from '@utils/hooks/use-query-pagination/use-query-pagination';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { ColumnsType } from 'antd/lib/table';
import { GET_NED_ROLE_APPLICATIONS, getNedRoleApplications } from '../../../api/get-ned-role-applications.actions';
import { Columns } from './columns';

const EmptyIconImage = ({ mode }: { mode?: ColorThemeMode }) => (
  <img loading="lazy" src={mode === 'light' ? EmptyStateIcon : EmptyStateDarkIcon} alt="empty-state" />
);

export const Applied = () => {
  const { mode } = useTheme();

  const [
    titleLabel,
    noApplicationLabel,
    onceApplyLabel,
    roleLabel,
    companyLabel,
    appliedLabel,
    statusLabel,
    invitedLabel,
    rejectedLabel,
    sentLabel,
    chatLabel,
  ] = useTranslation([
    'roles.applied.empty.title',
    'roles.applied.empty.noApplication',
    'roles.applied.empty.onceApply',
    'roles.applied.table.role',
    'roles.applied.table.company',
    'roles.applied.table.applied',
    'roles.applied.table.status',
    'roles.applied.table.invited',
    'roles.applied.table.rejected',
    'roles.applied.table.sent',
    'roles.applied.table.chat',
  ]);

  const { mutateAsync: message, isLoading: isMessageLoading } = useEngageConversation(engageFounderMessagingAction);

  const {
    deviceData: { isDesktop },
  } = useDeviceDetect();

  const { items, isLoading, ...pagination } = useQueryPagination({
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE_IN_GRID,
    pageParam: 'page',
    action: getNedRoleApplications,
    cacheKey: GET_NED_ROLE_APPLICATIONS,
  });

  if (isLoading) {
    return <FullHeightSpinner />;
  }

  return items.length ? (
    <Section>
      <Table
        dataSource={items}
        columns={
          Columns({
            t: {
              roleLabel,
              companyLabel,
              appliedLabel,
              statusLabel,
              invitedLabel,
              rejectedLabel,
              sentLabel,
              chatLabel,
            },
            message,
            isMessageLoading,
            isDesktop,
          }) as ColumnsType<object>
        }
        rowKey="id"
      />

      {pagination.maxPages > 1 && <Pagination bottomSpaced centered {...pagination} />}
    </Section>
  ) : (
    <EmptyState
      title={titleLabel}
      subTitle={
        <div>
          <p>{noApplicationLabel}</p>
          <p>{onceApplyLabel}</p>
        </div>
      }
      Icon={() => EmptyIconImage({ mode })}
    />
  );
};

import { V2Button } from '@/components/ui/v2-button/v2-button';
import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  max-width: 420px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 40px;

  ${customMediaQuery('padding', {
    xs: '4px',
    sm: '24px',
    md: '40px',
  })}
`;

const Image = styled.img`
  width: 150px;
  height: 150px;
`;

const DarkImage = styled(Image)`
  display: ${({ theme }) => (theme.v2.mode === 'dark' ? 'block' : 'none')};
`;

const LightImage = styled(Image)`
  display: ${({ theme }) => (theme.v2.mode === 'light' ? 'block' : 'none')};
`;

const Title = styled.h1`
  text-align: center;
  color: ${({ theme }) => (theme.v2.mode === 'dark' ? theme.v2.colors.neutral['50'] : theme.v2.colors.blue['400'])};
  font-size: ${({ theme }) => theme.v2.typography.sizes['2xl']};
  font-weight: ${({ theme }) => theme.v2.typography.weights.bold};
  line-height: ${({ theme }) => theme.v2.typography.lineHeights.button};
  margin-bottom: 6px;
`;

const Paragraph = styled.p`
  text-align: center;
  margin-bottom: 4px;
  color: ${({ theme }) => (theme.v2.mode === 'dark' ? theme.v2.colors.neutral['400'] : theme.v2.colors.neutral['700'])};
  font-size: ${({ theme }) => theme.v2.typography.sizes.md};
  font-weight: ${({ theme }) => theme.v2.typography.weights.regular};
`;

const Button = styled(V2Button)`
  margin-top: 32px;
`;

export default { Container, DarkImage, LightImage, Title, Paragraph, Button };

import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import S from './headline-wrapper.styles';

const HeadlineWrapper = () => {
  const {
    state: { name },
  } = useUserAccount();

  const [welcomeLabel] = useTranslation(['start.welcome']);

  return (
    <S.HeadlineWrapper>
      <S.Headline>
        {welcomeLabel}, <span>{name}</span>
      </S.Headline>
    </S.HeadlineWrapper>
  );
};

export default HeadlineWrapper;

import { Tooltip } from 'antd';
import React from 'react';
import { V2Icon } from '../v2-icon/v2-icon';

import S from './v2-tooltip.styles';

type V2TooltipProps = {
  tooltipText?: string;
  children?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  color?: string;
  size?: 'sm' | 'md' | 'lg';
  includeIcon?: boolean;
  'data-testid'?: string;
};

export const V2Tooltip: React.FC<V2TooltipProps> = ({
  tooltipText: text,
  children,
  color,
  includeIcon = true,
  iconPosition = 'right',
  size = 'sm',
  'data-testid': testId = 'v2-tooltip',
}) => {
  return (
    <Tooltip title={text}>
      <S.V2Tooltip iconPosition={iconPosition} data-testid={testId}>
        {children}

        {includeIcon ? (
          <S.Span color={color}>
            <V2Icon size={size} name="info-circle" color="currentColor" />
          </S.Span>
        ) : null}
      </S.V2Tooltip>
    </Tooltip>
  );
};

import type { Score } from '../types';

export const calculateTotalPercentage = (input: { [key: string]: Score }): number => {
  const scores = Object.values(input);

  const total = scores.reduce(
    (acc, score) => {
      acc.received += score.received;
      acc.available += score.available;
      return acc;
    },
    { received: 0, available: 0 },
  );

  if (total.available === 0) return 0;
  return (total.received / total.available) * 100;
};

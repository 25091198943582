/* eslint-disable @typescript-eslint/no-use-before-define */
import { Roles } from '@domain/accounts/roles';
import { addExternalCompanyEmailAction } from '@pages/content/portfolio/investor/api/set-external-company-email.action';
import { clearQueryParamValue, idQueryParam } from '@pages/content/pulse/founder/parts/editor/create-editor';
import ConnectionAlert from '@parts/connection-alert-editor/connection-alert';
import EditorDrawer from '@parts/editor-drawer/editor-drawer';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { useMutation } from '@tanstack/react-query';
import {
  getActiveRegisteredFundingRounds,
  useInvestmentsEditor,
} from '@utils/hooks/use-investments-editor/use-investments-editor';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EditorDetails } from '../../../../parts/editor-details/editor-details';
import { addConnectionAction } from '../../api/add-connection.action';
import { updateConnectionAction } from '../../api/update-connection.action';
import type { FormikState, Investment } from './editor-interfaces';
import { useAllFundingRoundTypes } from './hooks/use-all-funding-round-types';
import { Add } from './parts/add/add';
import { CompanySearch } from './parts/company-search/company-search';
import { useValidationSchema } from './validation-schema';
import S from './create-editor.styles';

export const newPortfolioQueryParam = 'new';

const emptyInvestment: Investment = {
  id: null,
  round: {
    id: null,
    type: null,
    date: null,
  },
  amount: null,
  preMoneyVal: null,
  postMoneyVal: null,
  shares: null,
  percentageOwnership: null,
  shareClass: null,
  leadInvestor: null,
  personalNote: null,
  terms: {
    warrants: {
      amount: null,
      price: null,
      expiry: null,
    },
    options: null,
    convertibleLoanNote: null,
    discount: null,
  },
};

const emptyState: FormikState = {
  companyName: null,
  externalCompanyEmail: null,
  investments: [],
  investingRelationshipId: null,
  isConnected: true,
};

export const CreateEditor = ({
  drawer: Drawer,
  hideDrawer,
  id = '',
  founderId,
  setFounderId,
  refetch,
  ...rest
}: {
  drawer: Function;
  hideDrawer: Function;
  id: string;
  founderId: string;
  setFounderId: Function;
  refetch: Function;
}) => {
  const [
    descriptionLabel,
    titleLabel,
    addInvestmentLabel,
    noFundingRoundsLabel,
    usedFundingRoundsLabel,
    notConnectedAlert,
    autoConnectAlert,
    tooltip,
  ] = useTranslation([
    'portfolio.investor.drawer.description',
    'portfolio.investor.drawer.label',
    'portfolio.investor.drawer.addInvestment',
    'portfolio.investor.drawer.noFundingRounds',
    'portfolio.investor.drawer.usedFundingRounds',
    'portfolio.investor.drawer.autoConnectAlert',
    'portfolio.investor.drawer.notConnectedAlert',
    'portfolio.investor.drawer.tooltip',
  ]);

  const { allFundingRoundTypesResponse } = useAllFundingRoundTypes();

  const allFundingRoundTypes = allFundingRoundTypesResponse?.data?.type || [];
  const initialValues = emptyState;
  const history = useHistory();

  const onClear = () => {
    history.replace({
      search: `${idQueryParam}=${clearQueryParamValue}`,
    });
    setFounderId('');
    setValues(emptyState);
  };

  const [isAddedEmail, setIsAddedEmail] = useState<boolean>(false);

  const { mutateAsync: setEmail } = useMutation(addExternalCompanyEmailAction(id));

  const onSubmit = (v: {
    companyName: string | null;
    externalCompanyEmail: string | null;
    investingRelationshipId: string | null;
    investments: Investment[];
  }) => {
    const vals = {
      ...v,
      investments: v.investments.map((inv) => {
        const { id: investId, ...investment } = inv;

        if (investId) {
          return { ...investment, id: investId };
        }
        return investment;
      }),
    };

    const { companyName, investingRelationshipId, externalCompanyEmail, ...existingPortfolio } = vals;
    const { isConnected, ...restPortfolio } = existingPortfolio as typeof existingPortfolio & { isConnected: boolean };

    if (id === newPortfolioQueryParam) {
      return add({ ...restPortfolio, externalCompanyEmail, companyName });
    }

    if (!isAddedEmail && values.externalCompanyEmail) {
      setEmail({ email: values.externalCompanyEmail });
    }

    return update({ ...restPortfolio });
  };

  const {
    add,
    update,
    createdFundingRounds,
    onClose,
    goToRelation,
    deleteInvestment,
    isMutationLoading,
    formik: {
      setFieldValue,
      getFieldProps,
      getFieldMeta,
      submitForm,
      values: formikValues,
      setValues,
      setFieldTouched,
      dirty,
      isValid,
      submitCount,
    },
  } = useInvestmentsEditor({
    onClear,
    hideDrawer,
    initialValues,
    onSubmit,
    validationSchema: useValidationSchema,
    createAction: addConnectionAction,
    updateAction: updateConnectionAction(id),
    founderId,
    trackedPageParam: 'page',
    closeRefetch: refetch,
  });

  const values = formikValues as FormikState;
  const freeFundingRound = createdFundingRounds.data?.filter((el) => !el.hasInvestment);
  const isAtLeastOneFreeFundingRound = Boolean(freeFundingRound?.length);
  const isMoreThanOneFreeFundingRound = freeFundingRound && freeFundingRound.length > 1;
  const isAddedExternal = Boolean(values.investments.length && !founderId);
  const activeRegisteredFundingRoundsIds = getActiveRegisteredFundingRounds(values.investments);
  const investmentsLoading =
    !createdFundingRounds.isFundingRoundsApiCalled && id !== newPortfolioQueryParam && founderId;
  const didUserAddAnyInvestment = Boolean(values.investments.length);
  const moreRegisteredRoundsAvailable =
    founderId && createdFundingRounds.data && (isMoreThanOneFreeFundingRound || !didUserAddAnyInvestment);

  const addInvestment = () => {
    setValues({
      ...values,
      investments: [...values.investments, emptyInvestment],
    });
  };

  const onSearch = ({
    value,
    companyId,
    investingRelationshipId,
    isConnected,
    externalCompanyEmail,
  }: {
    value: string | null;
    companyId?: string;
    investingRelationshipId?: string;
    isConnected: boolean;
    externalCompanyEmail?: string;
  }) => {
    if (typeof companyId === 'string' || typeof companyId === 'undefined') {
      setFounderId(companyId);
    }

    if (typeof investingRelationshipId === 'string') {
      goToRelation(investingRelationshipId);
    }
    setValues({
      isConnected,
      companyName: value,
      investments: [emptyInvestment],
      investingRelationshipId,
      externalCompanyEmail:
        typeof externalCompanyEmail === 'string' || externalCompanyEmail !== null ? externalCompanyEmail : undefined,
    });
  };

  const commonProps = { setFieldValue, getFieldProps, getFieldMeta, setFieldTouched };

  const renderEditorDetails = (index: number) => (
    <EditorDetails
      key={`${values.companyName}-${index}`}
      internalEntityId={founderId}
      index={index}
      foundingRounds={{
        registered: createdFundingRounds.data || [],
        externalTypes: allFundingRoundTypes,
        activeRegistered: activeRegisteredFundingRoundsIds,
      }}
      role={Roles.INVESTOR}
      deleteInvestment={deleteInvestment}
      {...commonProps}
    />
  );

  const renderInvestments = () => {
    if (createdFundingRounds.data?.length || isAddedExternal) {
      if (isAtLeastOneFreeFundingRound || isAddedExternal) {
        if (!values.isConnected && !isAddedExternal) {
          return (
            <>
              <ConnectionAlert notConnectedAlertLabel={notConnectedAlert} autoConnectAlertLabel={autoConnectAlert} />
              {values.investments.map((_investment, i) => renderEditorDetails(i))}
            </>
          );
        }
        return values.investments.map((_investment, i) => renderEditorDetails(i));
      }

      if (!isAddedExternal) {
        return <S.Alert message={usedFundingRoundsLabel} type="warning" />;
      }
    }

    if (values.investments.length && !isAddedExternal) {
      return <S.Alert message={noFundingRoundsLabel} type="warning" />;
    }

    return null;
  };

  return (
    <EditorDrawer
      {...rest}
      drawer={Drawer}
      search={
        <CompanySearch
          {...commonProps}
          data-testid="company-search-container"
          disabled={values.investments.length > 0}
          setFounderId={setFounderId}
          onSearch={onSearch}
          onClear={onClear}
          isAddedExternal={isAddedExternal}
          isAddedEmail={isAddedEmail}
          setIsAddedEmail={setIsAddedEmail}
        />
      }
      title={titleLabel}
      onClose={onClose}
      description={[descriptionLabel]}
      tooltip={tooltip}
      isLoading={false}
      isMutationLoading={isMutationLoading}
      footer={{
        onConfirm: submitForm,
        disabledEvaluation: {
          dirty,
          submitCount,
          isValid: isValid && values.investments.length > 0,
        },
        onCancel: onClose,
      }}
    >
      {values.companyName && (
        <>
          {investmentsLoading ? <FullHeightSpinner small /> : renderInvestments()}
          {(!founderId || moreRegisteredRoundsAvailable) && (
            <Add type="link" onClick={addInvestment} data-testid="add-one-more-investment">
              {addInvestmentLabel}
            </Add>
          )}
        </>
      )}
    </EditorDrawer>
  );
};

import type { Any } from '@/types';
import { countryFlag } from '@pages/content/profile/utils/country-flag';
import type { SelectValue } from 'antd/lib/select';
import S from './country-select.styles';

export interface CountrySelectProps {
  id: string;
  onChange: (value: SelectValue) => void;
  options: { value: string; label: string }[];
  centeredText?: boolean;
  lightMode?: boolean;
  showFlag?: boolean;
}

export const CountrySelect = ({
  options,
  onChange,
  centeredText = true,
  lightMode = false,
  id,
  showFlag = true,
  ...rest
}: CountrySelectProps) => {
  const filterFn = (inputValue: string, option?: Any) => {
    if (!option?.value || !option?.key) {
      return false;
    }

    return (
      option.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 ||
      (option.key as string).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
    );
  };

  return (
    <S.Select
      id={id}
      data-testid="cor-select"
      dropdownClassName={lightMode ? 'country-select-light' : 'country-select-dark'}
      onChange={onChange}
      $centeredText={centeredText}
      $lightMode={lightMode}
      optionFilterProp="children"
      filterOption={filterFn}
      showSearch
      {...rest}
    >
      {options.map((item) => (
        <S.Select.Option key={item.label} value={item.value}>
          <S.Space>
            {showFlag && <S.Img alt={`flag of the ${item.label}`} src={countryFlag(item.value)} loading="lazy" />}
            {item.label}
          </S.Space>
        </S.Select.Option>
      ))}
    </S.Select>
  );
};

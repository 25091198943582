import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export interface InvestmentSummary {
  company: {
    id: string;
    name: string;
    avatarUrl: string | null;
  };
  totalShares: number;
  totalInvested: number;
  totalOwned: number;
  totalCurrentValue: number;
}

export const GET_INVESTMENT_SUMMARY_CACHE_KEY = 'get-investment-summary';

export const getInvestmentsSummary =
  (relationshipId: string) => async (): Promise<GraphqlResponse<InvestmentSummary>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
        query getInvestmentsSummary {
            investmentsInCompanySummary(investingRelationshipId: "${relationshipId}") {
                company {
                    id
                    name
                    avatarUrl
                  }
                  totalShares
                  totalInvested
                  totalOwned
                  totalCurrentValue
            }
        }
    `,
    });

    return graphqlResponseWrapper(data, ['investmentsInCompanySummary']);
  };

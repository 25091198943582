import styled from 'styled-components';

const OneLineEllipsis = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    overflow: visible;
    display: block;
    white-space: break-spaces;
  }
`;

export default { OneLineEllipsis };

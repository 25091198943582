import styled from 'styled-components';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const NotesList = styled.div`
  max-height: 460px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const ButtonWrapper = styled.div`
  padding: 20px 0px;
  margin-left: -10px;
`;

export default { ModalContent, ButtonWrapper, NotesList };

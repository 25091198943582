import { usePeriodicNotification } from '@/hooks/use-periodic-notification';

import { TrustedSenderNotificationBanner } from './parts/trusted-sender';

const TRUSTED_SENDER_BANNER_REOPEN_IN_DAYS = 60;
const TRUSTED_SENDER_BANNER_KEY = 'trusted-sender';

export const NotificationBanners = () => {
  const { isNotificationVisible: showTrustedSenderBanner, hideNotification: closeTrustedSenderBanner } =
    usePeriodicNotification(TRUSTED_SENDER_BANNER_KEY, { days: TRUSTED_SENDER_BANNER_REOPEN_IN_DAYS });

  const banners = [
    showTrustedSenderBanner && (
      <TrustedSenderNotificationBanner key="trusted-sender-banner" onClose={closeTrustedSenderBanner} />
    ),
  ].filter(Boolean);

  return banners.length ? <>{banners}</> : null;
};

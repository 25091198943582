import type { AdvisorRole } from '@/domain/accounts/roles';
import XSlimIcon from '@assets/icons/close-slim.svg?react';
import { CompensationType, type Compensation } from '@pages/content/profile/ned/ned-profile.page';
import { COMPENSATION_MAX } from '@parts/compensation-selector/compensation-selector';
import { TableColumn } from '@parts/table-column/table-column';
import { TranslationText } from '@parts/translation-text/translation-text';
import { roundToHundreds } from '@utils/fns/round-to-hundreds';
import type { ColumnsType } from 'antd/lib/table';
import type { ReactNode } from 'react';

import S from './table.style';

export type RowData = {
  role: AdvisorRole;
  [CompensationType.Blended]: Compensation | null;
  [CompensationType.Equity]: Compensation | null;
  [CompensationType.Paid]: Compensation | null;
};

export const Columns = (
  t: {
    columnRoleLabel: ReactNode;
    columnBlendedLabel: ReactNode;
    columnEquityLabel: ReactNode;
    columnPaidLabel: ReactNode;
  },
  currency: string,
): ColumnsType<RowData> => {
  return [
    {
      title: t.columnRoleLabel,
      dataIndex: 'role',
      key: 'role',
      render: (_value, record) => (
        <TableColumn title={t.columnRoleLabel}>
          <S.RoleCell>
            <S.HeaderText>{record.role}</S.HeaderText>
          </S.RoleCell>
        </TableColumn>
      ),
    },
    {
      title: (
        <S.Centered>
          <S.HeaderText>{t.columnBlendedLabel}</S.HeaderText>
        </S.Centered>
      ),
      dataIndex: CompensationType.Blended,
      key: CompensationType.Blended,
      render: (_value, record) => (
        <TableColumn title={t.columnBlendedLabel}>
          <S.Centered>
            {record[CompensationType.Blended]?.compensationType ? <TranslationText id="global.yes" /> : <XSlimIcon />}
          </S.Centered>
        </TableColumn>
      ),
    },
    {
      title: (
        <S.Centered>
          <S.HeaderText>{t.columnEquityLabel}</S.HeaderText>
        </S.Centered>
      ),
      dataIndex: CompensationType.Equity,
      key: CompensationType.Equity,
      render: (_value, record) => (
        <TableColumn title={t.columnEquityLabel}>
          <S.Centered>
            {record[CompensationType.Equity]?.compensationType ? <TranslationText id="global.yes" /> : <XSlimIcon />}
          </S.Centered>
        </TableColumn>
      ),
    },
    {
      title: (
        <S.Centered>
          <S.HeaderText>{t.columnPaidLabel}</S.HeaderText>
        </S.Centered>
      ),
      dataIndex: CompensationType.Paid,
      key: CompensationType.Paid,
      render: (_value, record) => {
        const compensation = record[CompensationType.Paid];

        const getCompensationPaidRangeLabel = (comp: Compensation) => {
          const minDisplayValue = Math.max(roundToHundreds(comp.minDayRate), 0);
          const maxDisplayValue = Math.min(roundToHundreds(comp.maxDayRate), COMPENSATION_MAX);

          return `${minDisplayValue} - ${maxDisplayValue}${
            maxDisplayValue === COMPENSATION_MAX ? '+' : ''
          } ${currency}`;
        };

        return (
          <TableColumn title={t.columnPaidLabel}>
            <S.Centered>{compensation ? `${getCompensationPaidRangeLabel(compensation)}` : <XSlimIcon />}</S.Centered>
          </TableColumn>
        );
      },
    },
  ];
};

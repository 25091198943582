import styled from 'styled-components';

const V2Loader = styled.div<{ $height: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ $height }) => $height};
  width: 100%;
  padding: 24px;
`;

export default { V2Loader };

import { V2Container } from '@/components/ui/v2-container/v2-container';
import S from './readiness-score-section.style';

type ReadinessScoreSectionProps = {
  children?: React.ReactNode[];
  title: string;
  subtitle: string;
};

export const ReadinessScoreSection = ({ children, title, subtitle }: ReadinessScoreSectionProps) => {
  if (!children || children.length !== 5) throw new Error('ReadinessScoreSection must have 5 children');
  const [left, ...restChildren] = children;

  return (
    <S.Wrapper data-testid="homepage-readiness-score-section">
      <V2Container>
        <S.TitleWrapper>
          <S.Title>{title}</S.Title>
          <S.Subtitle>{subtitle}</S.Subtitle>
        </S.TitleWrapper>
        <S.Grid data-testid="homepage-readiness-score-section-grid">
          {left}
          <S.SubGrid data-testid="homepage-readiness-score-section-subgrid">{restChildren}</S.SubGrid>
        </S.Grid>
      </V2Container>
    </S.Wrapper>
  );
};

import type { AdvisorRole } from '@/domain/accounts/roles';
import { CompensationType, type Compensation } from '@pages/content/profile/ned/ned-profile.page';
import Checkbox from '@parts/checkbox/checkbox';
import { roundToHundreds } from '@utils/fns/round-to-hundreds';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { useState } from 'react';

import S from './compensation-selector.style';

export const COMPENSATION_STEP = 100;
export const COMPENSATION_MIN = 0;
export const COMPENSATION_MAX = 3_000;

interface CompensationSelectCheckboxProps {
  role: Omit<AdvisorRole, AdvisorRole.PLACEMENT>;
  compensations: Compensation[];
  onChange: (payload: Compensation[]) => void;
  currencySymbol: string;
  title?: string;
  disabled?: boolean;
}

const getInitialPaidValue = (compensations: Compensation[]) => {
  const paidCompensation = compensations.find((item) => item.compensationType === CompensationType.Paid);
  const { minDayRate, maxDayRate } = paidCompensation ?? { minDayRate: null, maxDayRate: null };

  return [
    roundToHundreds(minDayRate) || COMPENSATION_MIN,
    Math.min(roundToHundreds(maxDayRate) || COMPENSATION_MAX, COMPENSATION_MAX),
  ];
};

export const CompensationSelector = ({
  role,
  compensations,
  onChange,
  currencySymbol,
  disabled = false,
  title = '',
}: CompensationSelectCheckboxProps) => {
  const [valueDisplayLabel] = useTranslation(['compensation.dayRate']);
  const [paidValue, setPaidValue] = useState(getInitialPaidValue(compensations));
  const [selectedMin, selectedMax] = paidValue;

  const createCompensation = (config: { type: CompensationType; minRate: number | null; maxRate: number | null }) => ({
    role: role,
    compensationType: config.type,
    minDayRate: config.minRate,
    maxDayRate: config.maxRate,
  });

  const handleSelectionChange = (values: CheckboxValueType[] | null) => {
    if (!values) return;

    const updatedCompensations: Compensation[] = values.map((value) => {
      const val = value as CompensationType;

      return createCompensation({
        type: val,
        maxRate: val === CompensationType.Paid ? selectedMax : null,
        minRate: val === CompensationType.Paid ? selectedMin : null,
      });
    });

    onChange(updatedCompensations);
  };

  const handleUpdatePaidCompensation = () => {
    onChange([
      ...compensations.filter((item) => item.compensationType !== CompensationType.Paid),
      createCompensation({ type: CompensationType.Paid, minRate: selectedMin, maxRate: selectedMax }),
    ]);
  };

  const checkedCompensations = compensations.map((item) => item.compensationType as string);
  const showRangeSlider = checkedCompensations.includes(CompensationType.Paid);

  return (
    <S.Wrapper data-testid="compensation-type-selection">
      {title ? <S.Title>{title}</S.Title> : null}

      <S.CheckboxGroup value={checkedCompensations} onChange={handleSelectionChange}>
        <Checkbox value={CompensationType.Paid}>{CompensationType.Paid}</Checkbox>
        {showRangeSlider ? (
          <S.AmountRangeSlider
            disabled={disabled}
            step={COMPENSATION_STEP}
            min={COMPENSATION_MIN}
            max={COMPENSATION_MAX}
            value={[selectedMin, selectedMax]}
            onChange={setPaidValue}
            onAfterChange={handleUpdatePaidCompensation}
            data-testid="compensation-paid-slider"
            valueDisplay={
              <S.ValueDisplayWrapper>
                {valueDisplayLabel}: {`${currencySymbol}${selectedMin}`} - {`${currencySymbol}${selectedMax}`}
                {selectedMax >= COMPENSATION_MAX ? '+' : null}
              </S.ValueDisplayWrapper>
            }
          />
        ) : null}
        <Checkbox value={CompensationType.Equity}>{CompensationType.Equity}</Checkbox>
        <Checkbox value={CompensationType.Blended}>{CompensationType.Blended}</Checkbox>
      </S.CheckboxGroup>
    </S.Wrapper>
  );
};

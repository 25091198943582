export enum UsStateNameValue {
  'US-AL' = 'Alabama',
  'US-AK' = 'Alaska',
  'US-AS' = 'American Samoa',
  'US-AZ' = 'Arizona',
  'US-AR' = 'Arkansas',
  'US-CA' = 'California',
  'US-CO' = 'Colorado',
  'US-CT' = 'Connecticut',
  'US-DE' = 'Delaware',
  'US-DC' = 'District of Columbia',
  'US-FL' = 'Florida',
  'US-GA' = 'Georgia',
  'US-GU' = 'Guam',
  'US-HI' = 'Hawaii',
  'US-ID' = 'Idaho',
  'US-IL' = 'Illinois',
  'US-IN' = 'Indiana',
  'US-IA' = 'Iowa',
  'US-KS' = 'Kansas',
  'US-KY' = 'Kentucky',
  'US-LA' = 'Louisiana',
  'US-ME' = 'Maine',
  'US-MD' = 'Maryland',
  'US-MA' = 'Massachusetts',
  'US-MI' = 'Michigan',
  'US-MN' = 'Minnesota',
  'US-MS' = 'Mississippi',
  'US-MO' = 'Missouri',
  'US-MT' = 'Montana',
  'US-NE' = 'Nebraska',
  'US-NV' = 'Nevada',
  'US-NH' = 'New Hampshire',
  'US-NJ' = 'New Jersey',
  'US-NM' = 'New Mexico',
  'US-NY' = 'New York',
  'US-NC' = 'North Carolina',
  'US-ND' = 'North Dakota',
  'US-MP' = 'Northern Mariana Islands',
  'US-OH' = 'Ohio',
  'US-OK' = 'Oklahoma',
  'US-OR' = 'Oregon',
  'US-PA' = 'Pennsylvania',
  'US-PR' = 'Puerto Rico',
  'US-RI' = 'Rhode Island',
  'US-SC' = 'South Carolina',
  'US-SD' = 'South Dakota',
  'US-TN' = 'Tennessee',
  'US-TX' = 'Texas',
  'US-UM' = 'United States Minor Outlying Islands',
  'US-UT' = 'Utah',
  'US-VT' = 'Vermont',
  'US-VI' = 'Virgin Islands, U.S.',
  'US-VA' = 'Virginia',
  'US-WA' = 'Washington',
  'US-WV' = 'West Virginia',
  'US-WI' = 'Wisconsin',
  'US-WY' = 'Wyoming',
}

export type USState = keyof typeof UsStateNameValue;

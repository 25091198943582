/* eslint-disable react/no-danger */

import { useModal } from '@parts/modal/use-modal';
import OnTopFooter from '@parts/on-top-footer/on-top-footer';
import { type ReactNode } from 'react';
import { useTranslation } from '../use-translation/use-translation';

interface IConfirmModalProps {
  title: string;
  content: ReactNode | ReactNode[];
  onConfirm?: () => void;
  noCancel?: boolean;
  noConfirm?: boolean;
  alignButtonsWithContent?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  icon?: ReactNode;
  biggerIcon?: boolean;
  dataTestId?: string;
}

const useConfirmModal = ({
  title,
  content,
  onConfirm,
  noCancel,
  noConfirm,
  confirmLabel,
  cancelLabel,
  alignButtonsWithContent,
  icon,
  biggerIcon,
  dataTestId,
}: IConfirmModalProps) => {
  const { Modal: ModalBase, open, hide, show } = useModal();
  const [confirm, cancel] = useTranslation(['global.confirm', 'global.cancel']);

  const onOk = () => {
    if (onConfirm) onConfirm();
    hide();
  };

  const modal = (
    <ModalBase
      visible={open}
      closeModal={hide}
      biggerIcon={biggerIcon}
      footer={
        <OnTopFooter
          data-testid={dataTestId}
          onCancel={hide}
          onConfirm={onOk}
          confirmLabel={confirmLabel ?? confirm}
          cancelLabel={cancelLabel ?? cancel}
          noCancel={noCancel}
          noConfirm={noConfirm}
          alignButtonsWithContent={alignButtonsWithContent}
          modal
        />
      }
      onCancel={hide}
      title={title}
      icon={icon}
      testId={dataTestId}
    >
      {content}
    </ModalBase>
  );

  return { modal, show };
};

export default useConfirmModal;

import NotFoundIcon from '@assets/icons/not-found.svg?react';
import { MailLink } from '@parts/mail-link/mail-link';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { EmptyState } from '../empty-state/empty-state';

export const NotFound = () => {
  const [headlineLabel, contentLabel, encouragementLabel] = useTranslation([
    'progress.report.notFound.headline',
    'progress.report.notFound.content',
    'progress.report.notFound.encouragement',
  ]);

  return (
    <EmptyState
      icon={<NotFoundIcon />}
      headline={headlineLabel}
      texts={[
        contentLabel,
        <>
          {encouragementLabel} <MailLink />
        </>,
      ]}
    />
  );
};

import { buildQueryParams, buildQueryParamTypes } from '@/utils/fns/graphql-query-builder';
import type { Sorter } from '@/utils/type-definitions/filters';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';
import type { InvestorAdvisorConnectionIntent, InvestorAdvisorFounderInvites } from '../types';

export const GET_NED_INVESTOR_FOUNDER_INVITES_KEY = 'get-ned-investor-founder-invites-query';

type ResponseData = {
  total: number;
  items: InvestorAdvisorFounderInvites[];
};

type QueryConfig = {
  intent: InvestorAdvisorConnectionIntent | undefined;
  page?: number;
  perPage?: number;
  sort?: Sorter;
};

export const getInvestorAdvisorInvites = async ({
  page = 1,
  perPage = 6,
  intent, // sort,
}: QueryConfig): Promise<GraphqlResponse<ResponseData>> => {
  const variablesType = {
    start: 'Int!',
    limit: 'Int!',
    intent: 'FilterableConnectionIntentValue',
  };

  const variables = {
    start: (page - 1) * perPage,
    limit: page * perPage,
    intent,
    // sort,
  };

  const { data } = await axiosInstance.post('/query', {
    variables,
    query: `
     query getInvestorAdvisorInvites(${buildQueryParamTypes(variablesType)}) {
        getNedInvestorPendingConnections(${buildQueryParams(variablesType)}){
           total
           items {
           connectionId,
            requestedAt,
            founderId,
            avatarUrl,
            avatarUrl
            representativeName
            businessName 
            industry
            investmentStage
            description
          }
         }
       }
    `,
  });

  return graphqlResponseWrapper(data, ['getNedInvestorPendingConnections']);
};

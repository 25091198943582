import { V2Container } from '@/components/ui/v2-container/v2-container';
import { V2NavigationTabs } from '@/components/ui/v2-navigation-tabs/v2-navigation-tabs';
import { V2NotificationIndicator } from '@/components/ui/v2-notification-indicator/v2-notification-indicator';
import { V2Title } from '@/components/ui/v2-title/v2-title';
import { useMediaQuery } from '@/hooks/use-media-query';
import { NedLayout } from '@/layout/ned/ned.layout';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { AdvisorConnections } from './components/connections/connections';
import { AdvisorInvites } from './components/invites/invites';
import { useAdvisorInvitesCount } from './hooks/use-get-advisor-invites-count';

import S from './connections-page.styles';

export const AdvisorConnectionsPage = () => {
  const size = useMediaQuery();
  const isMobile = ['xs'].includes(size);
  const [title, connectionsLabel, invitesLabel] = useTranslation([
    'connections.title',
    'connections.advisor.tab.connections',
    'connections.advisor.tab.invites',
  ]);
  const { invitesCount } = useAdvisorInvitesCount();

  return (
    <NedLayout legacyContainer={false}>
      <V2Container>
        {!isMobile && (
          <S.TitleWrapper>
            <V2Title title={title} size="4xl" />
          </S.TitleWrapper>
        )}
        <V2NavigationTabs
          tabs={[
            {
              content: <AdvisorConnections />,
              key: 'connections',
              label: connectionsLabel,
              disabled: false,
            },
            {
              content: <AdvisorInvites />,
              key: 'invites',
              label: (
                <V2NotificationIndicator count={invitesCount} position="middle-right" enlargeWithPadding>
                  {invitesLabel}
                </V2NotificationIndicator>
              ),
              disabled: false,
            },
          ]}
          fallbackElement={<div>TODO Tab not found</div>}
        />
      </V2Container>
    </NedLayout>
  );
};

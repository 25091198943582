import message from '@/parts/message/message';
import { getServerError } from '@/utils/fns/get-server-error';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { GET_INVESTOR_CAPITAL_DEPLOYMENT_KEY } from '../api/get-investor-capital-deployment';
import { patchInvestorCapitalDeployment } from '../api/patch-investor-capital-deployment';

export const usePatchInvestorCapitalDeployment = (cb?: () => void) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: patchInvestorCapitalDeployment,
    onSuccess: () => {
      queryClient.refetchQueries([GET_INVESTOR_CAPITAL_DEPLOYMENT_KEY]);
      cb?.();
    },
    onError: (error: AxiosError) => message.error({ content: getServerError(error) }),
  });

  return {
    ...mutation,
    isInvestorCapitalDeploymentLoading: mutation.isLoading,
    isInvestorCapitalDeploymentError: mutation.isError,
    patchInvestorCapitalDeploymentMutation: mutation.mutate,
  };
};

import { V2InvisibleButton } from '@/components/ui/v2-invisible-button/v2-invisible-button';
import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import { List } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 48px 0;
  min-height: 684px;
`;

const InputWrapper = styled.div`
  max-width: 512px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const InvisibleButton = styled(V2InvisibleButton)`
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const CompaniesList = styled(List)`
  border-color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[50], theme.v2.colors.blue[500])};
  background-color: ${({ theme }) =>
    themedStyle(theme.v2.mode, theme.v2.colors.neutral[50], theme.v2.colors.blue[800])};

  & .ant-list-footer {
    padding: 0;
  }

  & .ant-list-item {
    padding: 0;
    color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[700], theme.v2.colors.neutral[100])};
    border-color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[50], theme.v2.colors.blue[500])};
  }

  & .ant-list-footer {
    color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[700], theme.v2.colors.neutral[100])};
    border-width: 1px;
    border-color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[50], theme.v2.colors.blue[500])};
    transform: translateY(-1px);
    background-color: ${({ theme }) =>
      themedStyle(theme.v2.mode, theme.v2.colors.neutral[50], theme.v2.colors.blue[800])};
  }

  & .ant-list-empty-text {
    display: none;
  }
`;

export default {
  Container,
  InputWrapper,
  InvisibleButton,
  CompaniesList,
};

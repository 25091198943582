import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';
import type { Score } from '../types';

export const GET_ADVISOR_PROFILE_READINESS = 'get-ned-profile-readiness';

type ResponseData = {
  profileInformationScore: Score;
  smartMatchScore: Score;
  remunerationScore: Score;
  cvScore: Score;
  publishScore: Score;
};

export const getAdvisorProfileReadiness = async (): Promise<GraphqlResponse<ResponseData>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getNedProfileReadiness {
        getNedProfileReadiness {
          profileInformationScore {
            received
            available
          }
          smartMatchScore {
            received
            available
          }
          remunerationScore {
            received
            available
          }
          cvScore {
            received
            available
          }
          publishScore {
            received
            available
          }
        }
      }
    `,
  });

  return graphqlResponseWrapper(data, ['getNedProfileReadiness']);
};

import { convertFromRaw, EditorState, Modifier, type RawDraftContentState } from 'draft-js';
import { useState } from 'react';
import type { Any } from 'src/types';

export const checkIfValidWysiWygValue = (value: Any) => {
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }

  return true;
};

export const checkIfWysiwygContainsText = (item: Any) => {
  if (typeof item.text !== 'string') return false;

  return item.text !== '';
};

export const getWysiWygValueSafely = (content: Any) => {
  return checkIfValidWysiWygValue(content) ? content : null;
};

export const useWysiwyg = ({ content }: { content: RawDraftContentState | undefined | null | string }) => {
  const [isActive, setIsActive] = useState(false);

  const initialState = content
    ? EditorState.createWithContent(convertFromRaw(JSON.parse(content.toString())))
    : EditorState.createEmpty();

  const initialStateHasText = initialState.getCurrentContent().hasText();

  const [state, setState] = useState<EditorState>(() => initialState);

  const insertText = (text: string) => {
    const currentContent = state.getCurrentContent();
    const currentSelection = state.getSelection();

    const wordBeforeCursor = currentContent
      .getBlockMap()
      .find((b) => b?.getKey() === currentSelection.getFocusKey())
      .getText();

    const lastLetterBeforeCursor = wordBeforeCursor.slice(-1);

    const addSpace = lastLetterBeforeCursor && lastLetterBeforeCursor !== ' ';

    const nextContent = Modifier.replaceText(currentContent, currentSelection, addSpace ? ` ${text}` : text);

    const nextEditorState = EditorState.push(state, nextContent, 'insert-characters');
    return EditorState.forceSelection(nextEditorState, nextContent.getSelectionAfter());
  };

  const reset = () => {
    setState(initialState);
  };

  return { state, setState, isActive, setIsActive, reset, insertText, initialStateHasText };
};

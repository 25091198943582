import type { SharedDashboard } from '@pages/content/pulse/parts/dashboards/parts/dashboard/submenu/parts/share-dashboard/share-dashboards';
import { axiosInstance } from '@utils/axios/axios-instance';
import type { AxiosResponse } from '@utils/axios/types';

export const sharePreviewReportAction = ({
  dashboardId,
  emailAddresses,
  note,
}: {
  dashboardId: string;
  emailAddresses: string[];
  note: string | null;
}): Promise<AxiosResponse<SharedDashboard>> =>
  axiosInstance.post(`/pulse/dashboards/${dashboardId}/preview-endpoint/share`, {
    emailAddresses,
    note,
  });

import InfoIconBase from '@assets/icons/info-circle.svg?react';
import AlertBase from '@parts/alert/alert';
import { FieldSearch as FieldSearchBase } from '@parts/field-search/field-search';
import styled from 'styled-components';

const Alert = styled(AlertBase)`
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  .ant-alert-message {
    text-wrap: wrap;
  }
`;

const InfoIcon = styled(InfoIconBase)`
  margin-left: ${({ theme }) => theme.spacing.xsmall};
  margin-top: 4px;
`;

const FieldSearch = styled(FieldSearchBase)`
  align-items: start;
`;

export default { Alert, InfoIcon, FieldSearch };

import type { FormikContextType, FormikErrors } from 'formik';
import { useEffect } from 'react';

const getFormattedValidationMessage = <T>(
  labels: Map<keyof T, string>,
  fieldErrors: FormikErrors<T[keyof T]>,
  key: keyof T,
): string => {
  const rawErrorText = Array.isArray(fieldErrors) ? fieldErrors[0] : fieldErrors;
  const fieldLabel = labels.get(key) ?? (key as string);

  return rawErrorText.includes(fieldLabel!) ? rawErrorText : `${fieldLabel}: ${rawErrorText}`;
};

export const useOnFormValidationChangeMessage = <T>(
  formik: FormikContextType<T>,
  fieldLabels: Map<keyof T, string>,
  callback: (validStatus: boolean, errorText: string | null) => void,
) => {
  useEffect(() => {
    const formattedErrors: Record<string, string> = {};
    for (const key in formik.errors) {
      if (Object.prototype.hasOwnProperty.call(formik.errors, key)) {
        // @ts-ignore
        formattedErrors[key] = getFormattedValidationMessage<T>(fieldLabels, formik.errors[key]!, key as keyof T);
      }
    }

    const errorText = Array.from(new Set(Object.values(formattedErrors))).join('\n');
    const firstErrorText = errorText.split('required')[0] + 'required';

    if (formik.touched) {
      callback(formik.isValid, firstErrorText);
    }
  }, [formik.touched, formik.errors, formik.isValid]);
};

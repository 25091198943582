import S from './v2-carousel.styles';
import useEmblaCarousel from 'embla-carousel-react';
import { type EmblaOptionsType } from 'embla-carousel';
import { V2CarouselContext } from './v2-carousel-context';
import type { V2CarouselProps } from './v2-carousel.types';
import { Dots } from './parts/dots';
import { PrevIconButton } from './parts/prev-icon-button';
import { NextIconButton } from './parts/next-icon-button';
import { StepIndicatorWithActions } from './parts/step-indicator-with-actions';
import { ItemsWrapper } from './parts/items-wrapper';
import { usePrevNextButtons } from './hooks/use-prev-next-buttons';
import { useMemo } from 'react';

const options: EmblaOptionsType = {
  align: 'start',
};

const V2Carousel = ({ children, style, stack = 'horizontal' }: V2CarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const { nextBtnDisabled, onNextButtonClick, prevBtnDisabled, onPrevButtonClick } = usePrevNextButtons(emblaApi);

  const context = useMemo(
    () => ({
      emblaApi,
      emblaRef,
      nextBtnDisabled,
      prevBtnDisabled,
      onNextButtonClick,
      onPrevButtonClick,
    }),
    [emblaApi, emblaRef, nextBtnDisabled, prevBtnDisabled, onNextButtonClick, onPrevButtonClick],
  );

  return (
    <V2CarouselContext.Provider value={context}>
      <S.Carousel style={style} $stack={stack}>
        {children}
      </S.Carousel>
    </V2CarouselContext.Provider>
  );
};

V2Carousel.ItemsWrapper = ItemsWrapper;
V2Carousel.Slide = S.Slide;
V2Carousel.Dots = Dots;
V2Carousel.PrevIconButton = PrevIconButton;
V2Carousel.NextIconButton = NextIconButton;
V2Carousel.StepIndicatorWithActions = StepIndicatorWithActions;

export { V2Carousel };

import type { ReactNode } from 'react';
import S from './footer.styles';

export const Footer = ({
  className,
  children,
  noBorder,
}: {
  className?: string;
  children: ReactNode;
  noBorder?: boolean;
}) => (
  <S.Wrapper className={className} noBorder={noBorder} onClick={(e) => e.stopPropagation()}>
    {children}
  </S.Wrapper>
);

import { Roles } from '@domain/accounts/roles';
import { NotFoundPage } from '@pages/utility/not-found/not-found.page';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { FounderNotifications } from './founder/founder-notifications.page';
import { InvestorNedNotifications } from './investor-ned/investor-ned-notifications.page';
import { InvestorNotifications } from './investor/investor-notifications.page';
import { NedNotifications } from './ned/ned-notifications.page';

export const NotificationsPage = () => {
  const {
    state: { userRole },
  } = useUserAccount();

  switch (userRole) {
    case Roles.FOUNDER:
      return <FounderNotifications />;
    case Roles.INVESTOR:
      return <InvestorNotifications />;
    case Roles.INVESTOR_NED:
      return <InvestorNedNotifications />;
    case Roles.NED:
      return <NedNotifications />;
    default:
      return <NotFoundPage />;
  }
};

import styled from 'styled-components';

export const InlineError = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-size: 13px;
  margin-top: ${({ theme }) => theme.spacing.small};
  color: ${({ theme }) => theme.baseColors.Red};
  margin-top: 5px;
  overflow-wrap: break-word;
  word-break: break-word;
`;

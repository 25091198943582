import type { PendingInvestmentsInvestor } from '@pages/content/portfolio/investor/api/get-pending-investments.action';
import type { PendingInvestmentsFounder } from '@pages/content/pulse/api/get-pending-investments.action';
import { PendingInvestmentsTable } from './table/table';

export const PendingInvestments = ({
  data,
  isLoading,
  refetch,
}: {
  data: PendingInvestmentsFounder[] | PendingInvestmentsInvestor[];
  isLoading: boolean;
  refetch?: Function;
}) => <PendingInvestmentsTable refetch={refetch} data={data} loading={isLoading} />;

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';
import type { TargetGeographyValue } from '@constants/target-geography';

export const ONBOARDING_INVESTOR_BUSINESS_DATA_CACHE_KEY = 'onboarding-investor-business-data';

export type InvestorOnboardingBusinessResponseData = {
  industries: string[];
  customerGroup: string[];
  productStage: string[];
  investmentStage: string[];
  targetGeography: TargetGeographyValue[];
};

export const getInvestorOnboardingBusinessDataAction = async (): Promise<
  GraphqlResponse<InvestorOnboardingBusinessResponseData>
> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
    query getInvestorOnboardingBusinessDataAction {
      smartMatch: getInvestorSmartMatch {
        industries,
        investmentStage,
        customerGroup,
        productStage,
        targetGeography,
      }
    }
  `,
  });

  return graphqlResponseWrapper(data, ['smartMatch']);
};

import { Roles } from '@domain/accounts/roles';
import { InvestorNedLayout } from '@layout/investor-ned/investor-ned.layout';
import { InvestorLayout } from '@layout/investor/investor.layout';
import { PageHeader } from '@parts/page-header/page-header';
import { Tabs } from '@parts/tabs/tabs';
import { Routes } from '@router/routes';
import { pulseCompaniesKey, pulseTriggersKey } from '@router/tabs-keys';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { Companies } from './parts/companies/companies';
import { Triggers } from './parts/triggers/triggers';

export const InvestorPulsePage = () => {
  const {
    state: { userRole },
  } = useUserAccount();

  const Layout = userRole === Roles.INVESTOR_NED ? InvestorNedLayout : InvestorLayout;

  const [titleLabel, companiesLabel, triggersLabel] = useTranslation([
    'pulse.investor.title',
    'pulse.investor.tabs.trackedCompanies',
    'pulse.investor.tabs.triggersOverview',
  ]);

  return (
    <Layout>
      <PageHeader title={<span data-testid="investor-pulse-page-header">{titleLabel}</span>} />
      <Tabs
        locator="content"
        route={Routes.PULSE}
        defaultLocator={pulseCompaniesKey}
        destroyInactiveTabPane
        tabs={[
          {
            key: pulseCompaniesKey,
            label: companiesLabel,
            content: <Companies />,
          },
          {
            key: pulseTriggersKey,
            label: triggersLabel,
            content: <Triggers />,
          },
        ]}
      />
    </Layout>
  );
};

import { scrollToTop } from '@/utils/fns/scroll-to-top';
import { useEffect } from 'react';
import { useQueryParams } from '../use-query/use-query-params';
import { SCROLL_TO_TARGET_PARAM } from '../use-scroll-to-element/use-scroll-to-element-with-query-params';

/**
 * Custom hook that scrolls the page to the top when the component mounts.
 * If the `st` (SCROLL_TO_TARGET_PARAM) query param is present in the url, the page will not scroll to the top.
 *
 * @returns An object containing the `scrollToTop` function.
 */
export const useScrollToPageTop = () => {
  const { st: scrollTarget } = useQueryParams<{ [SCROLL_TO_TARGET_PARAM]?: string }>();

  useEffect(() => {
    if (scrollTarget) return;

    scrollToTop();
  }, []);

  return { scrollToTop };
};

import { V2Pagination } from '@/components/blocks/v2-pagination/v2-pagination';
import { V2Alert } from '@/components/ui/v2-alert/v2-alert';
import { V2Grid } from '@/components/ui/v2-grid/v2-grid';
import { V2Loader } from '@/components/ui/v2-loader/v2-loader';
import { useGetAdvisorPendingConnections } from '../../hooks/use-get-advisor-invites';
import { InviteCard } from './invite-card';
import { V2TableStateInfo } from '@/components/ui/v2-table-state-info/v2-table-state-info';
import { TranslationText } from '@/parts/translation-text/translation-text';
import type { Sorter } from '@/utils/type-definitions/filters';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { ADVISOR_FOUNDER_INVITES_COUNT_QUERY_KEY } from '../../api/get-advisor-invites-count.query';

import S from '../../connections-page.styles';

export const AdvisorInvites = () => {
  const queryClient = useQueryClient();
  const [sorting] = useState<Sorter | undefined>(undefined);

  const { pagination, isAdvisorPendingConnectionsDataLoading, advisorPendingConnectionsData, refetch } =
    useGetAdvisorPendingConnections({ sort: sorting });

  const handleRefetchData = () => {
    refetch();
    queryClient.invalidateQueries([ADVISOR_FOUNDER_INVITES_COUNT_QUERY_KEY]);
  };

  if (isAdvisorPendingConnectionsDataLoading && !advisorPendingConnectionsData?.data)
    return <V2Loader height="320px" />;
  if (!advisorPendingConnectionsData?.data)
    return <V2Alert variant="error" title="Error loading data" description="Please try again later" showIcon />;

  return (
    <S.TabWrapper>
      {advisorPendingConnectionsData.data.items.length === 0 && (
        <V2TableStateInfo
          spaceTop
          showIcon
          title={<TranslationText id="connections.advisor.table.invites.empty.title" />}
          description={<TranslationText id="connections.advisor.table.invites.empty.description" />}
        />
      )}

      <V2Grid>
        {advisorPendingConnectionsData.data.items.map((connection) => (
          <InviteCard key={connection.connectionId} connection={connection} onActionSuccess={handleRefetchData} />
        ))}
      </V2Grid>

      <V2Pagination current={pagination.page} total={pagination.totalPages}>
        <V2Pagination.PreviousPageButton onClick={pagination.goToPreviousPage} />
        <V2Pagination.DisplayCurrentPageNumber />
        <V2Pagination.NextPageButton onClick={pagination.goToNextPage} />
      </V2Pagination>
    </S.TabWrapper>
  );
};

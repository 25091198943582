import { axiosInstance } from '@utils/axios/axios-instance';

export interface ManagementTeam {
  email?: string;
  phoneNumber?: string;
  age?: number;
  gender?: string;
  ethnicity?: string;
}

export const updateManagementTeamAction =
  (relationshipId: string, teamMemberId: string) => async (payload: ManagementTeam | null) =>
    axiosInstance.patch(
      `/portfolio-management/investing-relationship/${relationshipId}/management-team-member/${teamMemberId}`,
      payload,
    );

import WideTable from '@parts/wide-table/wide-table';
import type { ColumnsQuerying } from '@utils/hooks/use-filtering/use-filtering';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { ExtendedInvestment } from '../../api/get-extended-investments/get-extended-investments.action';
import { Columns } from './parts/columns';

const PortfolioWideTable = ({
  isLoading,
  querying,
  items,
  refetch,
  singleMode,
  ...rest
}: {
  isLoading: boolean;
  querying: ColumnsQuerying;
  items: ExtendedInvestment[];
  singleMode?: boolean;
  refetch: Function;
}) => {
  const [
    companyLabel,
    dateLabel,
    roundLabel,
    leadLabel,
    sharesLabel,
    classLabel,
    investedLabel,
    ownedLabel,
    preMoneyLabel,
    postMoneyLabel,
    noteLabel,
    currentValueLabel,
    tooltipLabel,
  ] = useTranslation([
    'portfolio.investor.table.company',
    'portfolio.investor.table.closeDate',
    'portfolio.investor.table.round',
    'portfolio.investor.table.leadInvestor',
    'portfolio.investor.table.shares',
    'portfolio.investor.table.class',
    'portfolio.investor.table.invested',
    'portfolio.investor.table.owned',
    'portfolio.investor.table.preMoneyVal',
    'portfolio.investor.table.postMoneyVal',
    'portfolio.investor.table.note',
    'portfolio.investor.table.currentValueFull',
    'portfolio.investor.investments.external.tooltip',
  ]);

  return (
    <WideTable
      {...rest}
      dataSource={items}
      loading={isLoading}
      rowKey={(row) => row.id}
      rowClassName={(row) => (row.archived ? 'archived-investment' : '')}
      columns={Columns({
        t: {
          companyLabel,
          dateLabel,
          roundLabel,
          leadLabel,
          sharesLabel,
          classLabel,
          investedLabel,
          ownedLabel,
          preMoneyLabel,
          postMoneyLabel,
          currentValueLabel,
          noteLabel,
          tooltipLabel,
        },
        querying,
        refetch,
        singleMode,
      })}
    />
  );
};

export default PortfolioWideTable;

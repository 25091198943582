import { V2Loader } from '@/components/ui/v2-loader/v2-loader';
import { useGetCompanyDetails } from '../../../hooks/use-get-company-details';
import { useAddExperienceContext } from '../add-experience-modal.context';
import { useGetSkillsAndIndustries } from '../../../hooks/use-get-skills-and-industries';
import { ExperienceForm } from '../../experience-form';

type InsertDataProps = {
  next: () => void;
  back: () => void;
};

export const InsertData = ({ next, back }: InsertDataProps) => {
  const { state, dispatch } = useAddExperienceContext();
  const { companyData, isCompanyDataLoading } = useGetCompanyDetails(state.companyId);
  const { skillSets, industries } = useGetSkillsAndIndustries();

  if (typeof state.companyId === 'string' && isCompanyDataLoading) return <V2Loader />;

  return (
    <ExperienceForm
      isInternalCompany={Boolean(state.companyId)}
      industries={industries ?? []}
      skillSets={skillSets ?? []}
      initialValues={{
        name: companyData?.businessName ?? state.searchPhrase,
        role: '',
        industry: companyData?.industry.length ? companyData?.industry : null,
        email: null,
        startDate: '',
        endDate: '',
        isOngoing: false,
        compensationType: undefined,
        skillSet: undefined,
        experienceSummary: undefined,
      }}
      onSubmit={(values) => {
        dispatch({ type: 'SET_FORM_DATA', payload: values });
        next();
      }}
      back={back}
    />
  );
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_ADDITIONAL_INFO_SELECT_OPTIONS_CACHE_KEY = 'additional-info-select-options';

export enum ShareClasses {
  ORDINARY = 'Ordinary',
  PREFERENCE = 'Preference',
  NON_VOTING = 'Non-voting',
  REDEEMABLE = 'Redeemable',
}

export enum Options {
  VESTING = 'Vesting',
  GOOD_LEAVER = 'Good leaver',
  BAD_LEAVER = 'Bad leaver',
}

export enum ConvertibleLoanNotes {
  COUPON_INTEREST = 'Coupon interest',
  PAYOUT = 'Payout',
  BACKSTOP = 'Backstop',
}

export const getAdditionalInfoSelectOptionsAction = async (): Promise<
  GraphqlResponse<{
    convertibleLoanNotes: ConvertibleLoanNotes[];
    options: Options[];
    shareClasses: ShareClasses[];
  }>
> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getAdditionalInfoSelectOptions {
            getPortfolioManagementLabelOptions {
                convertibleLoanNotes: convertibleLoanNotes
                options: termsOptions
                shareClasses: shareClass
            }
        }
        `,
  });

  return graphqlResponseWrapper(data, ['getPortfolioManagementLabelOptions']);
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export interface PublishStatus {
  smartMatchShortages: string[];
  published: boolean;
}
export const GET_NED_PUBLISH_STATUS_WITH_SHORTAGES_CACHE_KEY = 'get-ned-publish-status-with-shortages';

export const getNedPublishStatusWithShortages = async (): Promise<GraphqlResponse<PublishStatus>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
    query getNedPublishStatusWithShortages {
      getNedSmartMatch {
        smartMatchShortages,
        published,
      },
     
    }
  `,
  });

  return graphqlResponseWrapper(data, ['getNedSmartMatch']);
};

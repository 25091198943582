import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_CREATED_FUNDING_ROUNDS_CACHE_KEY = (founderId: string) =>
  `${founderId}-get-registered-founding-rounds`;

export type RegisteredFoundingRound = {
  type: string;
  id: string;
  date: Date;
  hasInvestment: boolean;
};

export const getCreatedFundingRoundsAction =
  (id: string, isInvestingRelationshipId: boolean) => async (): Promise<GraphqlResponse<RegisteredFoundingRound[]>> => {
    let queryArgs = 'investmentRounds';

    if (isInvestingRelationshipId) {
      queryArgs = id ? `investmentRounds(investingRelationshipId: "${id}")` : 'investmentRounds';
    } else {
      queryArgs = `investmentRounds(companyId: "${id}")`;
    }

    const { data } = await axiosInstance.post('/query', {
      query: `
      query getCreatedFundingRounds {
        ${queryArgs} {
          id
          type
          date
          hasInvestment
        }
      }`,
    });

    return graphqlResponseWrapper(data, ['investmentRounds']);
  };

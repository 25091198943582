import { Button } from 'antd';
import styled from 'styled-components';

const SubmitButton = styled(Button)`
  height: 50px;
  background-color: ${({ theme }) => theme.color.submitButton};
  border: 1px solid ${({ theme }) => theme.color.submitButton};
  color: ${({ theme }) => theme.baseColors.White};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  padding: 12px 21px;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: 100%;
  }

  &:disabled,
  &:disabled:hover {
    background-color: ${({ theme }) => theme.color.submitButton};
    border: 1px solid ${({ theme }) => theme.color.submitButton};
    color: ${({ theme }) => theme.baseColors.White};
    opacity: 0.5;
  }

  &.ant-btn-link {
    background-color: transparent !important;
    color: ${({ theme }) => theme.color.submitButton}!important;
    font-weight: ${({ theme }) => theme.fontWeight.normal}!important;
    border: none;
  }
`;

export default { SubmitButton };

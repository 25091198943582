import EyeIcon from '@assets/icons/eye.svg?react';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './switch-to-public-button.styles';

export const SwitchToPublicButton = ({ onClick }: { onClick: () => void }) => {
  const [viewAs] = useTranslation(['profile.viewAs']);

  return (
    <S.SwitchToPublicWrapper>
      <S.SwitchToPublic data-testid="view-as" type="link" onClick={onClick}>
        <EyeIcon /> {viewAs}
      </S.SwitchToPublic>
    </S.SwitchToPublicWrapper>
  );
};

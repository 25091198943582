import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import * as Yup from 'yup';
import { firstNameValidation, phoneNumberValidation } from '../founder-form/validation-schema';

const ADVISOR_NAME_MAX_LENGTH = 100;

export const useValidationSchema = () => {
  const [fieldRequired, minLength, maxLength, validPhoneNumber] = useTranslation([
    'formik.validation.required',
    'formik.validation.min',
    'formik.validation.max',
    'formik.validation.phoneNumber',
  ]);

  return Yup.object({
    firstName: firstNameValidation(minLength, fieldRequired).max(ADVISOR_NAME_MAX_LENGTH, maxLength),
    lastName: Yup.string().trim().required(fieldRequired).max(ADVISOR_NAME_MAX_LENGTH, maxLength),
    phoneNumber: phoneNumberValidation(validPhoneNumber, fieldRequired),
    countryOfResidence: Yup.string().trim().required(fieldRequired),
    usState: Yup.string().when('countryOfResidence', {
      is: 'US',
      then: Yup.string().required(fieldRequired),
      otherwise: Yup.string().notRequired().nullable(),
    }),
  });
};

import { useUserAbility } from '@abilities';
import { AbilityAction, AbilitySubject } from '@abilities/ability/types';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useEffect, useState } from 'react';
import { DocumentType } from '../../api/get-legal-documents.action';
import S from './document-section.styles';
import { AllDocuments } from './parts/all-documents';

export enum FilterType {
  All = 'all',
  Legal = 'legal',
  Accounting = 'accounting',
}
export const DocumentSection = ({
  availableDocuments,
  availableBlocking,
}: {
  availableDocuments: DocumentType[];
  availableBlocking: boolean;
}) => {
  const [choosedFilter, setChoosedFilter] = useState<FilterType>(FilterType.All);
  const [headerLabel, legalLabel, allLabel, accoutingLabel] = useTranslation([
    'legal.document.header',
    'legal.document.filter.legal',
    'legal.document.filter.all',
    'legal.document.filter.accounting',
  ]);
  const [showFilter, setShowFilter] = useState(false);

  const getDocType = (name: string) => {
    switch (name) {
      case DocumentType.TermsSheet:
      case DocumentType.AdvisoryAgreement:
      case DocumentType.AdvancedSubscription:
      case DocumentType.Nda:
      case DocumentType.EmploymentContract:
        return FilterType.Legal;
      case DocumentType.Seis:
        return FilterType.Accounting;
      default:
    }
  };

  const userAbility = useUserAbility();

  useEffect(() => {
    let isAnyAccountingType = false;
    let isAnyLegalType = false;
    availableDocuments.forEach((document: DocumentType) => {
      if (!isAnyAccountingType) {
        isAnyAccountingType = getDocType(document) === FilterType.Accounting;
      }
      if (!isAnyLegalType) {
        isAnyLegalType = getDocType(document) === FilterType.Legal;
      }
    });
    setShowFilter(isAnyAccountingType && isAnyLegalType);
  }, [availableDocuments]);

  const isBlocked = availableBlocking && !userAbility.can(AbilityAction.ACCESS, AbilitySubject.LEGAL_DOCS);

  return (
    <S.Section
      header={headerLabel}
      cornerAddition={{
        content: (
          <S.CornerWrapper>
            {!isBlocked && showFilter && (
              <>
                <S.Filter
                  isActive={choosedFilter === FilterType.All}
                  onClick={() => {
                    setChoosedFilter(FilterType.All);
                  }}
                  data-testid="all_filter"
                >
                  {allLabel}
                </S.Filter>
                <S.Filter
                  isActive={choosedFilter === FilterType.Legal}
                  onClick={() => {
                    setChoosedFilter(FilterType.Legal);
                  }}
                  data-testid="legal_filter"
                >
                  {legalLabel}
                </S.Filter>
                <S.Filter
                  isActive={choosedFilter === FilterType.Accounting}
                  onClick={() => {
                    setChoosedFilter(FilterType.Accounting);
                  }}
                  data-testid="accounting_filter"
                >
                  {accoutingLabel}
                </S.Filter>
              </>
            )}
          </S.CornerWrapper>
        ),
        width: 999,
        // TODO check this
      }}
    >
      <AllDocuments
        availableBlocking={availableBlocking}
        availableDocuments={availableDocuments}
        choosedFilter={choosedFilter}
      />
    </S.Section>
  );
};

import { CloseCircleOutlined } from '@ant-design/icons';
import type { DrawerProps } from 'antd/lib/drawer';
import { useEffect, useMemo, useState, type ReactNode } from 'react';
import CustomDrawer from './drawer';

interface ModalProps extends DrawerProps {
  children: ReactNode | ReactNode[];
  title?: string | ReactNode;
  footer?: ReactNode;
  onCancel?: () => void;
  width?: string | number;
}

export const useDrawer = (visible: boolean = false, { onAfterHide }: { onAfterHide?: () => void } = {}) => {
  const [isVisible, setIsVisible] = useState(visible);
  const show = () => setIsVisible(true);
  const hide = () => {
    setIsVisible(false);
    onAfterHide?.();
  };

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  const Drawer = ({ children, footer, title, onCancel, width, ...rest }: ModalProps) => (
    <CustomDrawer
      visible={isVisible}
      closeDrawer={hide}
      closeIcon={<CloseCircleOutlined />}
      footer={footer}
      title={title}
      width={width || 320}
      data-testid="use-drawer"
      {...rest}
    >
      {children}
    </CustomDrawer>
  );

  // eslint-disable-next-line
  const memoizedDrawer = useMemo(() => Drawer, [isVisible]);

  return {
    show,
    hide,
    Drawer: isVisible ? memoizedDrawer : () => null,
    isVisible,
  };
};

import { Tooltip } from 'antd';
import styled from 'styled-components';

const TooltipIcon = styled(Tooltip)`
  margin-left: 5px;
  position: relative;
  top: 2px;
`;

export default {
  TooltipIcon,
};

import type { MarketFitAnswer } from '@/pages/content/profile/founder/api/update-market-fit/market-fit.action';
import type { UseMutateAsyncFunction } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from '@utils/axios/types';
import { useFormik } from 'formik';
import type { Any } from 'src/types';
import type { MarketFitQuestionWithLabel } from './use-market-fit-questions';
import { useValidationSchema } from './use-validation-schema';

export type MarketFitFormValues = Record<string, boolean | string | null>;

export function createMarketFitFormValues(data: MarketFitQuestionWithLabel[] | MarketFitAnswer[]): MarketFitFormValues {
  const values: MarketFitFormValues = {};

  data.forEach((item) => {
    let boolKey = '';
    let textKey = '';

    if ('code' in item) {
      boolKey = `${item.code}-bool`;
      textKey = `${item.code}-text`;
    }

    if ('questionCode' in item) {
      boolKey = `${item.questionCode}-bool`;
      textKey = `${item.questionCode}-text`;
    }

    values[boolKey] = typeof item.booleanAnswer === 'boolean' ? item.booleanAnswer : null;
    values[textKey] = item.textAnswer;
  });

  return values;
}

export const useMarketFitFormik = (
  questionsList: MarketFitQuestionWithLabel[],
  submit: UseMutateAsyncFunction<AxiosResponse<Any>, AxiosError, { marketFitAnswers: MarketFitAnswer[] }, unknown>,
  enableReinitialize = false,
) =>
  useFormik<MarketFitFormValues>({
    onSubmit(values) {
      const answers: MarketFitAnswer[] = questionsList.reduce((acc, curr) => {
        const code = curr.questionCode;
        const boolKey = `${curr.questionCode}-bool`;
        const textKey = `${curr.questionCode}-text`;

        const booleanAnswer = values[boolKey] as boolean | null;
        const textAnswer = values[boolKey] === false ? null : (values[textKey] as string) ?? null;

        return booleanAnswer === null ? acc : [...acc, { code, booleanAnswer, textAnswer }];
      }, [] as MarketFitAnswer[]);

      submit({ marketFitAnswers: answers });
    },
    initialValues: createMarketFitFormValues(questionsList),
    validationSchema: useValidationSchema(questionsList),
    enableReinitialize: enableReinitialize,
  });

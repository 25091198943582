import type { TV2SortProps, V2SortOrder } from './v2-sort.types';

import S from './v2-sort.styles';

export const V2Sort = ({ variant = 'none', tagName = 'button', ...rest }: TV2SortProps) => {
  return (
    <S.Button as={tagName} {...rest}>
      <S.Icon name="sorting" variant={(variant?.toLowerCase() as V2SortOrder) ?? 'none'} />
    </S.Button>
  );
};

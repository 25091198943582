import { axiosInstance } from '@utils/axios/axios-instance';
import type { QuestionCode } from '../get-market-fit-questions/get-market-fit-questions.query';

export interface MarketFitAnswer {
  code: QuestionCode;
  booleanAnswer: boolean | null;
  textAnswer: string | null;
}

type UpdateFounderMarketFitPayload = {
  marketFitAnswers: MarketFitAnswer[];
};

export const updateFounderMarketFitAction = async (payload: UpdateFounderMarketFitPayload) =>
  axiosInstance.patch('/profile/founder/market-fit', payload);

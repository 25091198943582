import { CloseCircleOutlined } from '@ant-design/icons';
import ModalBase from 'antd/lib/modal/Modal';
import styled from 'styled-components';

const Modal = styled(ModalBase)`
  .ant-modal-header {
    border: none;
    background-color: ${({ theme }) => theme.color.dashboardBackground};
    padding-right: ${({ theme }) => theme.spacing.base};

    .ant-modal-title {
      text-align: center;
      font-size: ${({ theme }) => theme.fontSize.medium};

      margin-top: 0;
      text-align: left;
      color: ${({ theme }) => theme.color.primary};
      font-weight: ${({ theme }) => theme.fontWeight.semibold};
    }
  }

  .ant-modal-body {
    color: ${({ theme }) => theme.color.fontPrimary};
  }

  .ant-modal-content {
    padding-bottom: 0;
    background-color: ${({ theme }) => theme.color.sectionBackground};

    .ant-modal-close {
      .anticon.anticon-close-circle {
        font-size: 23px;
        color: ${({ theme }) => theme.color.primary};
      }
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: center;
    border: 1px solid ${({ theme }) => theme.color.dashboardBackground};
    padding-bottom: ${({ theme }) => theme.spacing.medium};
    padding-top: ${({ theme }) => theme.spacing.medium};

    & > button {
      width: 80%;
      font-weight: ${({ theme }) => theme.fontWeight.semibold};
    }
  }
`;

const IconHolder = styled.div<{ biggerIcon?: boolean }>`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    width: ${({ biggerIcon }) => (biggerIcon ? '240px' : '60px')};
    height: auto;
  }
`;

const Close = styled(CloseCircleOutlined)`
  width: 60px;
  height: auto;
`;

export default { Modal, IconHolder, Close };

/* eslint-disable no-param-reassign */
import type { Query } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import type { GraphQlErrorResponse } from 'src/types';

export interface BaseNotice {
  name: string;
  tags: string | string[];
  message: string;
  fingerprint?: string;
  params: Record<string, unknown>;
  [key: string]: unknown;
}

export enum ReportTags {
  API = 'API',
  MUTATION = 'MUTATION',
  QUERY = 'QUERY',
  GRAPHQL_INTERNAL = 'GRAPHQL_INTERNAL',
}

const getMessage = (error: AxiosError): string => {
  if (error.response?.data.error?.details?.message) {
    return error.response?.data.error?.details?.message;
  }

  if (error.response?.data.error) {
    return error.response.data.error;
  }

  if (error.response?.data.errors?.[0]?.message) {
    return error.response.data.errors[0].message;
  }

  return error.message;
};

const getErrorResponseData = (error: AxiosError) => {
  if (error.response?.data) {
    return error.response?.data;
  }

  return null;
};

export const createApiQueryNotice = (error: AxiosError, query: Query): BaseNotice => {
  const name = `API Query Error - ${query.queryKey} - ${error.response?.status ?? 'NO-STATUS'}`;

  return {
    name,
    message: getMessage(error),
    tags: [ReportTags.API, ReportTags.QUERY],
    params: {
      responseData: getErrorResponseData(error),
    },
  };
};

export const createApiMutationNotice = (error: AxiosError): BaseNotice => {
  const name = `API Mutation Error - ${error.config?.url} - ${error.response?.status ?? '---'}`;

  return {
    name,
    message: getMessage(error),
    tags: [ReportTags.API, ReportTags.MUTATION],
    params: {
      responseData: getErrorResponseData(error),
    },
  };
};

export const createGraphQlErrorNotice = (response: GraphQlErrorResponse): BaseNotice => {
  const queryName = response.errors[0]?.path[0] ?? 'NO-QUERY-NAME';
  const errorCode =
    response.errors[0]?.extensions?.exception?.errorCode ?? response.errors[0]?.extensions?.code ?? 'NO-CODE';
  const errorCodeName = response.errors[0]?.extensions?.exception?.name || null;
  const reportResponseData = response.errors[0]?.extensions ?? null;

  const name = `API GraphQl Error - ${queryName} - ${errorCodeName ? errorCodeName + ' ' : ''}(${errorCode})`;
  const message = response.errors[0].message || 'No message provided';

  return {
    name,
    message,
    tags: [ReportTags.API, ReportTags.QUERY, ReportTags.GRAPHQL_INTERNAL],
    params: { responseData: reportResponseData },
  };
};

import { DashboardElements } from '@pages/content/pulse/parts/dashboards/parts/dashboard/dashboard-elements/dashboard-elements';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { useQuery } from '@tanstack/react-query';
import {
  getProgressReportMetrics,
  GET_PROGRESS_REPORT_METRICS_CACHE_KEY,
} from '../../api/get-progress-report-metrics/get-public-report-metrics.action';

interface MetricsRaportProps {
  dashboardId: string;
  hash: string;
  password: string | null;
}

export const MetricsRaport = ({ dashboardId, hash, password }: MetricsRaportProps) => {
  const { data: response, isLoading } = useQuery(
    [GET_PROGRESS_REPORT_METRICS_CACHE_KEY(hash)],
    getProgressReportMetrics(hash, password),
    {
      refetchOnWindowFocus: false,
      cacheTime: 60000,
    },
  );

  if (isLoading) return <FullHeightSpinner />;

  if (!response || !response.data) return null;

  return (
    <DashboardElements
      reportMode
      metrics={response.data.metrics}
      textBoxes={response.data.textBoxes}
      gridConfig={response.data.gridConfig}
      dashboardId={dashboardId}
    />
  );
};

import { FormikField } from '@parts/forms/formik-field/formik-field';
import { NumberInput } from '@parts/forms/number-input/number-input';
import { SubmitButton } from '@parts/submit-button/submit-button';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Input, Select } from 'antd';
import type { SelectValue } from 'antd/lib/select';
import type { RawDraftContentState } from 'draft-js';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  getRoleFormDataAction,
  GET_ROLE_FORM_DATA_CACHE_KEY,
} from '../../api/get-role-form-data/get-role-form-data.actions';
import { DescriptionEditor } from '../description-editor/description-editor';

import S from './role-details.styles';

export interface RoleDetailsPayload {
  title: string;
  location?: string;
  type?: string;
  compensation?: string;
  engagement?: string;
  experience?: number;
  skillSet: string[];
  description: string;
}

interface RoleDetailsFormProps {
  onSubmit: (payload: RoleDetailsPayload) => void;
  roleDetails?: {
    title?: string;
    location?: string;
    type?: string;
    compensation?: string;
    engagement?: string;
    experience?: number;
    skillSet?: string[];
    description?: string;
  };
}

const MAX_SKILL_SETS = 5;
const DESCRIPTION_MAX_LENGTH = 6000;

export const RoleDetailsForm = ({ onSubmit, roleDetails = {} }: RoleDetailsFormProps) => {
  const [
    formTitle,
    title,
    location,
    type,
    compensation,
    engagement,
    experience,
    skillSet,
    description,
    button,
    titlePlaceholder,
    locationPlaceholder,
    typePlaceholder,
    compensationPlaceholder,
    engagementPlaceholder,
    experiencePlaceholder,
    skillSetPlaceholder,
    descriptionPlaceholder,
    formikRequired,
    formikMin,
    formikMax,
  ] = useTranslation([
    'roles.founder.create.formTitle',
    'roles.founder.form.title',
    'roles.founder.form.location',
    'roles.founder.form.type',
    'roles.founder.form.compensation',
    'roles.founder.form.engagement',
    'roles.founder.form.experience',
    'roles.founder.form.skillSet',
    'roles.founder.form.description',
    'roles.founder.form.button',
    'roles.founder.form.title.placeholder',
    'roles.founder.form.location.placeholder',
    'roles.founder.form.type.placeholder',
    'roles.founder.form.compensation.placeholder',
    'roles.founder.form.engagement.placeholder',
    'roles.founder.form.experience.placeholder',
    'roles.founder.form.skillSet.placeholder',
    'roles.founder.form.description.placeholder',
    'formik.validation.required',
    'formik.validation.min',
    'formik.validation.max',
  ]);

  const { data: formDataResponse, isLoading } = useQuery([GET_ROLE_FORM_DATA_CACHE_KEY], getRoleFormDataAction);

  const formik = useFormik<RoleDetailsPayload>({
    onSubmit,
    initialValues: {
      compensation: roleDetails.compensation ?? undefined,
      description: roleDetails.description ?? '',
      engagement: roleDetails.engagement ?? undefined,
      experience: roleDetails.experience ?? undefined,
      location: roleDetails.location ?? undefined,
      skillSet: roleDetails.skillSet ?? [],
      title: roleDetails.title ?? '',
      type: roleDetails.type ?? undefined,
    },

    validationSchema: Yup.object({
      title: Yup.string().trim().min(3, formikMin).max(100, formikMax).required(formikRequired),
      description: Yup.string()
        .trim()
        .min(10, formikMin)
        .max(DESCRIPTION_MAX_LENGTH, formikMax)
        .required(formikRequired),
      engagement: Yup.string().trim().required(formikRequired),
      experience: Yup.number().min(1, formikMin).required(formikRequired),
      location: Yup.string().trim().min(3, formikMin).required(formikRequired),
      compensation: Yup.string().trim().min(3, formikMin).required(formikRequired),
      skillSet: Yup.array().min(1, formikMin).max(5, formikMax).required(formikRequired),
      type: Yup.string().trim().min(3, formikMin).required(formikRequired),
    }),
  });

  const handleDescriptionChange = (contentState: RawDraftContentState) => {
    if (!contentState.blocks[0].text.length) {
      formik.setFieldValue('description', '');
    } else {
      formik.setFieldValue('description', JSON.stringify(contentState));
    }
  };

  const handleSkillSetChange = (values: string[]) => {
    formik.setFieldValue('skillSet', values);
  };

  const handleSelectChange = (selectKey: string) => (value: SelectValue) => {
    formik.setFieldValue(selectKey, value.toString());
  };

  return (
    <S.Form onSubmit={formik.handleSubmit}>
      <S.Title>{formTitle}</S.Title>
      <S.Row>
        <FormikField
          error={formik.errors.title}
          touched={formik.touched.title}
          label={{
            for: 'title',
            label: title,
          }}
        >
          <Input id="title" {...formik.getFieldProps('title')} placeholder={titlePlaceholder} />
        </FormikField>
        <FormikField
          error={formik.errors.location}
          touched={formik.touched.location}
          label={{
            for: 'location',
            label: location,
          }}
        >
          <S.Select
            id="location"
            {...formik.getFieldProps('location')}
            placeholder={locationPlaceholder}
            loading={isLoading}
            onChange={handleSelectChange('location')}
            showSearch
          >
            {formDataResponse?.data?.locations.map((locationValue) => (
              <Select.Option value={locationValue} key={locationValue}>
                {locationValue}
              </Select.Option>
            ))}
          </S.Select>
        </FormikField>
      </S.Row>
      <S.Row>
        <FormikField
          error={formik.errors.type}
          touched={formik.touched.type}
          label={{
            for: 'type',
            label: type,
          }}
        >
          <S.Select
            id="type"
            {...formik.getFieldProps('type')}
            placeholder={typePlaceholder}
            loading={isLoading}
            onChange={handleSelectChange('type')}
          >
            {formDataResponse?.data?.roleTypes.map((roleTypeValue) => (
              <Select.Option value={roleTypeValue} key={roleTypeValue}>
                {roleTypeValue}
              </Select.Option>
            ))}
          </S.Select>
        </FormikField>
        <FormikField
          error={formik.errors.compensation}
          touched={formik.touched.compensation}
          label={{
            for: 'compensation',
            label: compensation,
          }}
        >
          <S.Select
            id="compensation"
            {...formik.getFieldProps('compensation')}
            placeholder={compensationPlaceholder}
            loading={isLoading}
            onChange={handleSelectChange('compensation')}
          >
            {formDataResponse?.data?.compensationTypes.map((compensationTypeValue) => (
              <Select.Option value={compensationTypeValue} key={compensationTypeValue}>
                {compensationTypeValue}
              </Select.Option>
            ))}
          </S.Select>
        </FormikField>
      </S.Row>
      <S.Row>
        <FormikField
          error={formik.errors.engagement}
          touched={formik.touched.engagement}
          label={{
            for: 'engagement',
            label: engagement,
          }}
        >
          <S.Select
            id="engagement"
            {...formik.getFieldProps('engagement')}
            placeholder={engagementPlaceholder}
            loading={isLoading}
            onChange={handleSelectChange('engagement')}
          >
            {formDataResponse?.data?.engagementTypes.map((engagementTypeValue) => (
              <Select.Option value={engagementTypeValue} key={engagementTypeValue}>
                {engagementTypeValue}
              </Select.Option>
            ))}
          </S.Select>
        </FormikField>
        <FormikField
          error={formik.errors.experience}
          touched={formik.touched.experience}
          label={{
            for: 'experience',
            label: experience,
          }}
        >
          <NumberInput id="experience" {...formik.getFieldProps('experience')} placeholder={experiencePlaceholder} />
        </FormikField>
      </S.Row>
      <FormikField
        error={formik.errors.skillSet as string}
        touched={formik.touched.skillSet}
        label={{
          for: 'skillSet',
          label: skillSet,
        }}
      >
        <S.MultiSelectSearch
          id="skillSet"
          {...formik.getFieldProps('skillSet')}
          onChange={handleSkillSetChange}
          filterOption={undefined}
          placeholder={skillSetPlaceholder}
          options={formDataResponse?.data?.skillSets ?? []}
          loading={isLoading}
          {...(formik.values.skillSet.length >= MAX_SKILL_SETS && { open: false })}
        />
      </FormikField>
      <FormikField
        error={formik.errors.description}
        touched={formik.touched.description}
        label={{
          for: 'description',
          label: description,
        }}
      >
        <DescriptionEditor
          onChange={handleDescriptionChange}
          placeholder={descriptionPlaceholder}
          content={roleDetails?.description as unknown as RawDraftContentState}
        />
      </FormikField>
      <SubmitButton type="primary" htmlType="submit" data-testid="founder-save-publish-role">
        {button}
      </SubmitButton>
    </S.Form>
  );
};

import { Roles } from '@/domain/accounts/roles';
import useUserAccount from '@/utils/hooks/use-user-account/use-user-account';
import { InvestorLayout } from '@layout/investor/investor.layout';
import { getInvestorPublishStatus } from '@pages/content/api/get-investor-publish-status.action';
import { publishInvestorProfileAction } from '@pages/content/api/publish-investor-profile.action';
import {
  GET_INVESTOR_SMART_MATCH_SUGGESTION_CACHE_KEY,
  getInvestorSmartMatchSuggestion,
} from '@pages/content/lens/api/get-investor-smart-match-suggestion';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { PageHeader } from '@parts/page-header/page-header';
import { Tabs } from '@parts/tabs/tabs';
import { Routes } from '@router/routes';
import { lensBrowseCompaniesKey, lensInvitesKey, lensSmartMatchKey } from '@router/tabs-keys';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { GET_INVESTOR_INVITES_COUNT_CACHE_KEY, getInvestorInvitesCount } from '../api/get-investor-invites-count';
import { MatchedFounder } from '../parts/cards/matched-founder';
import { Counter } from '../parts/counter/counter';
import { SmartMatch } from '../parts/smart-match/smart-match';
import { BrowseCompanies } from './parts/browse-companies/browse-companies';
import { CompaniesInvites } from './parts/companies-invites/companies-invites';

export const InvestorLensPage = ({ Layout = InvestorLayout }: { Layout?: Function }) => {
  const [title, smartMatch, browseCompanies, invites] = useTranslation([
    'lens.title',
    'lens.investor.tabs.smartMatch',
    'lens.investor.tabs.browseCompanies',
    'lens.investor.tabs.invites',
  ]);

  const [emptySecuredTitle, emptySecuredSubTitle, emptyPrivateTitle, emptyPrivateSubTitle, emptyPublish, goToProfile] =
    useTranslation([
      'lens.investor.secured.title',
      'lens.investor.secured.subTitle',
      'lens.investor.private.title',
      'lens.investor.private.subTitle',
      'lens.investor.private.publish',
      'lens.investor.goToProfile',
    ]);

  const {
    state: { userRole },
  } = useUserAccount();

  const { data: response, refetch } = useQuery([GET_INVESTOR_INVITES_COUNT_CACHE_KEY], getInvestorInvitesCount);

  if (!response?.data) {
    refetch();
    return (
      <Layout>
        <FullHeightSpinner />
      </Layout>
    );
  }

  const { total: invitesCount } = response.data;

  return (
    <Layout>
      <PageHeader title={title} />
      <Tabs
        destroyInactiveTabPane
        locator="action"
        route={Routes.LENS}
        defaultLocator={lensSmartMatchKey}
        tabs={[
          {
            key: lensSmartMatchKey,
            label: smartMatch,
            content: (
              <SmartMatch
                translations={{
                  emptySecuredTitle,
                  emptySecuredSubTitle,
                  emptyPrivateTitle,
                  emptyPrivateSubTitle,
                  emptyPublish,
                  goToProfile,
                }}
                publishProfileAction={publishInvestorProfileAction}
                smartMatchSuggestion={getInvestorSmartMatchSuggestion}
                getPublishStatusAction={getInvestorPublishStatus}
                Item={MatchedFounder}
                smartMatchSuggestionCacheKey={GET_INVESTOR_SMART_MATCH_SUGGESTION_CACHE_KEY}
                refetchInvitationsCount={refetch}
              />
            ),
          },
          {
            key: lensBrowseCompaniesKey,
            label: browseCompanies,
            content: (
              <BrowseCompanies
                publishProfileAction={publishInvestorProfileAction}
                translations={{
                  emptySecuredTitle,
                  emptySecuredSubTitle,
                  emptyPrivateTitle,
                  emptyPrivateSubTitle,
                  emptyPublish,
                  goToProfile,
                }}
              />
            ),
          },
          ...(userRole === Roles.INVESTOR_NED
            ? []
            : [
                {
                  key: lensInvitesKey,
                  label: (
                    <p>
                      <span>{invites}</span>
                      {invitesCount > 0 && <Counter value={invitesCount} />}
                    </p>
                  ),
                  content: <CompaniesInvites refetchInvitationsCount={refetch} />,
                },
              ]),
        ]}
      />
    </Layout>
  );
};

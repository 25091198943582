import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { TableProps } from 'antd/lib/table';
import type { ReactNode } from 'react';
import type { Any } from 'src/types';
import S from './expandable-table.styles';

export const ExpandableTable = ({
  expandIcon,
  expandedRowRender,
  expandedRowClassName,
  ...rest
}: TableProps<Any> & {
  expandIcon?: () => ReactNode | ReactNode[];
  expandedRowClassName?: string;
  expandedRowRender: Function;
}) => {
  const {
    deviceData: { isDesktop },
  } = useDeviceDetect();

  const [moreLabel, lessLabel] = useTranslation(['global.show.more', 'global.show.less']);

  return (
    <S.Wrapper
      {...rest}
      expandable={{
        expandedRowRender,
        expandedRowClassName,
        expandIcon: ({ expanded, onExpand, record }: { expanded: boolean; onExpand: Function; record: object }) => {
          const scopeLabel = expanded ? lessLabel : moreLabel;

          return (
            <S.Trigger data-testid="expandable-table-trigger" onClick={(e) => onExpand(record, e)}>
              {(() => {
                if (expanded) {
                  if (isDesktop) {
                    return <S.Arrow />;
                  }

                  return <S.ArrowUp />;
                }

                return <S.ArrowRight />;
              })()}

              {!isDesktop && <S.Scope>{scopeLabel}</S.Scope>}
            </S.Trigger>
          );
        },
      }}
    />
  );
};

import CloseIcon from '@assets/icons/toast/toast-close-icon.svg?react';
import AlertBase from '@parts/alert/alert';
import styled from 'styled-components';

const Alert = styled(AlertBase)`
  background: ${({ theme }) => theme.color.closableInfoBackground};
  border: 2px solid ${({ theme }) => theme.baseColors.Blue200};
  color: ${({ theme }) => theme.color.fontPrimary};

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .ant-alert-content {
    margin-top: -2px;
    flex: 0 1 auto;
    margin-right: 17px;
    word-break: break-all;
  }

  a {
    color: ${({ theme }) => theme.baseColors.Blue200};
    text-decoration: none;
  }
  .ant-alert-close-icon {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Close = styled(CloseIcon)`
  > path {
    fill: ${({ theme }) => theme.baseColors.Blue200};
  }
`;

export default { Alert, Close };

import { Group } from '@/pages/content/onboarding/investor/content/business/parts/group/group';
import type { SmartMatchOptionsResponseData } from '@/pages/content/profile/api/get-smart-match-options/get-smart-match-options.action';
import { TARGET_GEOGRAPHIES_OPTIONS, TargetGeographyValue } from '@constants/target-geography';
import type { IInvestorSmartMatchPayload } from '@pages/content/profile/investor/api/update-smart-match/update-smart-match';
import { PillButtonCheckbox } from '@parts/pill-button-checkbox/pill-button-checkbox';
import { useListEntryRevealAnimation } from '@utils/hooks/use-list-reveal-animation/use-list-reveal-animation';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useFormik } from 'formik';
import { useState } from 'react';
import type { Any } from 'src/types';
import S from '../../../../parts/business/business.styles';
import type { InvestorOnboardingBusinessResponseData } from '../../../api/get-investor-onboarding-business-data.action';
import { INITIAL_INDUSTRIES_OPTION_NUMBER } from './constants';
import { useValidationSchema } from './validation-schema';

const LIST_ANIMATION_ELEMENT_ID = 'business-form-field';

interface FormikFormData {
  industries: string[] | null;
  customerGroup: string[] | null;
  investmentStage: string[] | null;
  productStage: string[] | null;
  targetGeography: string[];
}

interface InvestorBusinessEditorProps {
  smartMatchOptions: SmartMatchOptionsResponseData;
  data: InvestorOnboardingBusinessResponseData;
  refetchData: () => Promise<Any>;
  handleUpdate: (v: IInvestorSmartMatchPayload) => Promise<Any>;
  actionsPending?: boolean;
}

export const InvestorBusinessEditor = ({
  smartMatchOptions,
  data,
  refetchData,
  handleUpdate,
  actionsPending = false,
}: InvestorBusinessEditorProps) => {
  const [
    industryLabel,
    productStageLabel,
    customerGroupLabel,
    investmentStageLabel,
    targetGeographyLabel,
    targetGeographyTooltipLabel,
    showLessLabel,
    showMoreLabel,
    noResultsLabel,
    selectAllLabel,
    unselectAllLabel,
  ] = useTranslation([
    'investor-onboarding.business.industry',
    'investor-onboarding.business.productStage',
    'investor-onboarding.business.customerGroup',
    'investor-onboarding.business.investmentStage',
    'investor-onboarding.business.targetGeographyLabel',
    'investor-onboarding.business.targetGeography.tooltip',
    'investor-onboarding.business.showLess',
    'investor-onboarding.business.showMore',
    'investor-onboarding.business.noResults',
    'investor-onboarding.business.selectAll',
    'investor-onboarding.business.unselectAll',
  ]);
  useListEntryRevealAnimation(LIST_ANIMATION_ELEMENT_ID);

  const formik = useFormik<FormikFormData>({
    async onSubmit(values) {
      await handleUpdate(values as IInvestorSmartMatchPayload);
      await refetchData();
    },
    initialValues: {
      industries: data.industries || null,
      customerGroup: data.customerGroup || null,
      investmentStage: data.investmentStage || null,
      productStage: data.productStage || null,
      targetGeography: data.targetGeography || [],
    },
    enableReinitialize: true,
    validationSchema: useValidationSchema(),
  });

  const [searchIndustry, setSearchIndustry] = useState<string>('');
  const [showAllIndustries, setShowAllIndustries] = useState<boolean>(false);

  const filterOptionsByQuery = (query: string, option: string) => new RegExp(query, 'ig').test(option);

  const industriesOptions = smartMatchOptions.industries ?? [];
  const isUserSearching = searchIndustry.length > 0;
  const foundOptions = industriesOptions.some((option) => filterOptionsByQuery(searchIndustry, option));
  const areSelectedIndustries = Boolean(formik.values.industries?.length);
  const selectedIndustriesOptions = industriesOptions.filter((item) => formik.values.industries?.includes(item));
  const industriesOptionsNumberToShow =
    showAllIndustries || searchIndustry ? industriesOptions.length : INITIAL_INDUSTRIES_OPTION_NUMBER;
  const industriesOptionsToShow = industriesOptions
    .filter((item) => !formik.values.industries?.includes(item))
    .filter((item) => (isUserSearching ? filterOptionsByQuery(searchIndustry, item) : true))
    .slice(0, industriesOptionsNumberToShow);
  const toggleSelectAllLabel = selectedIndustriesOptions.length > 0 ? unselectAllLabel : selectAllLabel;

  const industriesToShowLeft = `${
    Number(industriesOptions.length - selectedIndustriesOptions.length - industriesOptionsNumberToShow) > 0
      ? `+${
          industriesOptions.length - selectedIndustriesOptions.length - industriesOptionsNumberToShow
        } ${showMoreLabel}`
      : ''
  }`;

  {
    const toggleIndustriesButtonText = showAllIndustries ? showLessLabel : industriesToShowLeft;

    const handleSelectItem = (fieldName: keyof FormikFormData, value: string) => {
      const currentValue = formik.values[fieldName];

      if (
        currentValue === null ||
        (fieldName === 'targetGeography' &&
          (value === TargetGeographyValue.Global || currentValue.includes(TargetGeographyValue.Global)))
      ) {
        formik.setFieldValue(fieldName, [value]);
        return;
      }

      const alreadyOnList = currentValue.includes(value);
      const updatedList = alreadyOnList ? currentValue.filter((v: string) => v !== value) : [...currentValue, value];

      formik.setFieldValue(fieldName, updatedList);
    };

    const handleToggleSelectAllIndustries = (fieldName: keyof FormikFormData = 'industries') => {
      if (selectedIndustriesOptions.length > 0) {
        formik.setFieldValue(fieldName, []);
        setShowAllIndustries(false);
      } else {
        formik.setFieldValue(fieldName, industriesOptions);
      }
    };

    const selectItemWithSubmit = (fieldName: keyof FormikFormData, value: string) => {
      handleSelectItem(fieldName, value);
      formik.submitForm();
    };

    const isChecked = (fieldName: keyof FormikFormData, value: string) =>
      formik.values[fieldName] === null ? false : (formik.values[fieldName] as string[]).includes(value);

    const fieldGroups: {
      key: keyof FormikFormData;
      value: string[] | null;
      label: string;
      options: string[];
      isDisabled: boolean;
      tooltipLabel?: string;
      itemChecked: (field: keyof FormikFormData, value: string) => boolean;
      onItemSelect: (field: keyof FormikFormData, value: string) => void;
    }[] = [
      {
        key: 'productStage',
        value: formik.values.productStage,
        label: productStageLabel,
        options: smartMatchOptions.productStages ?? [],
        isDisabled: actionsPending,
        itemChecked: isChecked,
        onItemSelect: selectItemWithSubmit,
      },
      {
        key: 'investmentStage',
        value: formik.values.investmentStage,
        label: investmentStageLabel,
        options: smartMatchOptions.investmentStages ?? [],
        isDisabled: actionsPending,
        itemChecked: isChecked,
        onItemSelect: selectItemWithSubmit,
      },
      {
        key: 'customerGroup',
        value: formik.values.customerGroup,
        label: customerGroupLabel,
        options: smartMatchOptions.customerGroups ?? [],
        isDisabled: actionsPending,
        itemChecked: isChecked,
        onItemSelect: selectItemWithSubmit,
      },
      {
        key: 'targetGeography',
        value: formik.values.targetGeography,
        label: targetGeographyLabel,
        options: TARGET_GEOGRAPHIES_OPTIONS,
        isDisabled: actionsPending,
        itemChecked: isChecked,
        onItemSelect: selectItemWithSubmit,
        tooltipLabel: targetGeographyTooltipLabel,
      },
    ];

    return (
      <S.Form data-testid="investor-business-editor">
        <Group title={industryLabel} data-animation-id={LIST_ANIMATION_ELEMENT_ID}>
          <>
            <S.InputWrapper>
              <S.Input
                value={searchIndustry}
                onChange={(e) => setSearchIndustry(e.target.value.trimStart())}
                prefix={<S.SearchIcon />}
                data-testid="search-industry-input"
              />
            </S.InputWrapper>
            {isUserSearching && !foundOptions ? <S.Error>{noResultsLabel}</S.Error> : null}

            {areSelectedIndustries ? (
              <S.SelectedIndustries>
                {selectedIndustriesOptions.map((item) => (
                  <PillButtonCheckbox
                    key={item}
                    onChange={() => {
                      handleSelectItem('industries', item);
                      formik.submitForm();
                    }}
                    checked={isChecked('industries', item)}
                    disabled={actionsPending}
                    testidPrefix="industries-selected"
                  >
                    {item} <S.CloseIcon />
                  </PillButtonCheckbox>
                ))}
              </S.SelectedIndustries>
            ) : null}

            {industriesOptionsToShow.map((item) => (
              <PillButtonCheckbox
                key={item}
                onChange={() => {
                  handleSelectItem('industries', item);
                  formik.submitForm();
                }}
                checked={isChecked('industries', item)}
                disabled={actionsPending}
                testidPrefix="industries"
              >
                {item}
              </PillButtonCheckbox>
            ))}
            {!isUserSearching && (
              <S.ToggleButton type="button" onClick={() => setShowAllIndustries((prev) => !prev)}>
                {toggleIndustriesButtonText}
              </S.ToggleButton>
            )}
            <S.ToggleButton
              type="button"
              onClick={() => {
                handleToggleSelectAllIndustries();
                formik.submitForm();
              }}
            >
              {toggleSelectAllLabel}
            </S.ToggleButton>
          </>
        </Group>

        {fieldGroups.map((group) => (
          <Group
            key={group.key}
            title={group.label}
            data-animation-id={LIST_ANIMATION_ELEMENT_ID}
            tooltipLabel={group.tooltipLabel}
          >
            {group.options.map((item) => (
              <PillButtonCheckbox
                key={item}
                onChange={() => group.onItemSelect(group.key, item)}
                checked={group.itemChecked(group.key, item)}
                disabled={
                  group.isDisabled ||
                  (group.key === 'targetGeography' &&
                    formik.values.targetGeography.includes(item) &&
                    formik.values.targetGeography.length === 1)
                }
                testidPrefix={`${group.key}-option`}
              >
                {item}
              </PillButtonCheckbox>
            ))}
          </Group>
        ))}
      </S.Form>
    );
  }
};

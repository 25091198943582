import type {
  Dashboard,
  GridConfig,
} from '@pages/content/pulse/parts/dashboards/api/get-founder-dashboard/get-founder-dashboard.action';
import { Size } from '@pages/content/pulse/parts/dashboards/parts/dashboard/metrics/parts/metric-item/parts/change-size/change-size';
import { ChartViewType } from '@pages/content/pulse/parts/dashboards/parts/dashboard/metrics/parts/metric-item/parts/chart-metric/parts/chart-view-dropdown/chart-view-dropdown';
import { draggableClass } from '@pages/content/pulse/parts/dashboards/parts/dashboard/metrics/parts/metric-item/parts/drag-icon/drag-icon';
import type { AxiosResponse } from '@utils/axios/types';
import { isDesktop } from '@utils/fns/is-desktop';
import useIsMount from '@utils/hooks/use-is-mount/use-is-mount';
import isEqual from 'lodash.isequal';
import { Children, cloneElement, isValidElement, useEffect, useState, type ReactElement } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import S from './draggable.styles';
import {
  COLS,
  ROW_HEIGHT,
  addOrRemoveItemInGrid,
  dragHeightSizes,
  dragWidthSizes,
  getOnlyRequiredFields,
  prepareGridData,
} from './helpers';

const ResponsiveGridLayout = WidthProvider(Responsive);

const Draggable = ({
  children,
  idKey,
  gridConfig,
  updateGrid,
  publicMode = false,
}: {
  children: ReactElement[];
  idKey: string;
  gridConfig: Dashboard['gridConfig'];
  publicMode?: boolean;
  updateGrid: (data: { gridConfig: GridConfig[] }) => Promise<AxiosResponse>;
}) => {
  const isMount = useIsMount();
  const [loading, setLoading] = useState(false);

  const preparedData = gridConfig || prepareGridData(Children.map(children, (child) => child.props[idKey]));

  const layouts = {
    lg: preparedData,
    md: preparedData,
    xs: preparedData,
    xxs: preparedData,
  };

  useEffect(() => {
    if (isMount || publicMode) return;

    const newGrid = addOrRemoveItemInGrid(
      Children.map(children, (child) => child.props[idKey]),
      preparedData,
    );

    // It's called when metric is added or removed
    updateGrid({ gridConfig: getOnlyRequiredFields(newGrid) });
  }, [children.length]);

  const onSizeChange = (size: Size, chartId: string, preventSmall?: boolean, mediumHeight?: boolean) => {
    const childrenArr = Children.map(children, (child) => child);
    const previousSelectedMetric = childrenArr.find((child) => child.props.id === chartId);

    const getNewSizes = (config: GridConfig) => {
      if (preventSmall) {
        if (config.w === dragWidthSizes.small) {
          return {
            w: dragWidthSizes.medium,
            h: dragHeightSizes.medium,
          };
        }

        return {
          w: config.w,
          h: previousSelectedMetric?.props.selectedChartType === ChartViewType.TABLE ? dragHeightSizes.large : config.h,
        };
      }

      return {
        w: dragWidthSizes[size],
        h: mediumHeight ? dragHeightSizes.medium : dragHeightSizes[size],
      };
    };

    const newGrid = preparedData.map((item) =>
      item.i === chartId
        ? {
            ...item,
            ...getNewSizes(item),
          }
        : item,
    );

    if (!isEqual(preparedData, newGrid)) {
      updateGrid({ gridConfig: getOnlyRequiredFields(newGrid) });
    }
  };

  return (
    <S.Wrapper $loading={loading}>
      <ResponsiveGridLayout
        layouts={layouts}
        allowOverlap={false}
        isResizable={false}
        compactType={null}
        rowHeight={ROW_HEIGHT}
        useCSSTransforms={false}
        cols={COLS}
        margin={[20, 20]}
        isDraggable={!publicMode}
        draggableHandle={`.${draggableClass}`}
        onLayoutChange={async (updatedData: GridConfig[]) => {
          if (publicMode || loading) return;

          const updatedRequiredData = getOnlyRequiredFields(updatedData);
          const currentRequiredData = getOnlyRequiredFields(preparedData);

          if (isDesktop(window) && (gridConfig === null || !isEqual(updatedRequiredData, currentRequiredData))) {
            setLoading(true);

            await updateGrid({ gridConfig: updatedRequiredData });
            setLoading(false);
          }
        }}
      >
        {preparedData.map(({ i: id, h, w }) => {
          const childrenElement = children.find((el) => el.key === id);
          const sizeKey = `w${w}-h${h}`;

          return (
            <S.Item data-testid="draggable-item" key={id}>
              {isValidElement(childrenElement) &&
                cloneElement(childrenElement as ReactElement, { onSizeChange, sizeKey })}
            </S.Item>
          );
        })}
      </ResponsiveGridLayout>
    </S.Wrapper>
  );
};

export default Draggable;

import { isActiveInvestor } from '@abilities';
import { InvestorSummaryContent } from '@pages/content/onboarding/investor/content/summary/summary-page';
import { OnboardingLayout } from '@pages/content/onboarding/parts/layout/layout-default';
import { Links } from '@router/links';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { Redirect } from 'react-router-dom';

export const InvestorOnboardingSummaryPage = () => {
  const {
    state: { products },
  } = useUserAccount();

  if (isActiveInvestor(products)) {
    return <Redirect to={Links.HOME()} />;
  }

  return (
    <OnboardingLayout showFooter={false} onNextClick={null} centerContent>
      <InvestorSummaryContent />
    </OnboardingLayout>
  );
};

import { V2Button } from '@/components/ui/v2-button/v2-button';
import { V2RadioGroup } from '@/components/ui/v2-radio-group/v2-radio-group';
import { V2Radio } from '@/components/ui/v2-radio/v2-radio';
import { useMediaQuery } from '@/hooks/use-media-query';
import type { FounderQualifyingQuestionsResponseData } from '@/modules/founder/homepage/api/get-founder-qualifying-questions.action';
import { usePatchFounderQualifyingQuestions } from '@/modules/founder/homepage/hooks/use-patch-founder-qualifying-questions';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import S from './steps.styles';

type HeadcountScaleStepProps = {
  currentStep: number;
  countOfSteps: number;
  initialValues: FounderQualifyingQuestionsResponseData;
  back: () => void;
  next: () => void;
};

export const HeadcountScaleStep = ({
  currentStep,
  countOfSteps,
  initialValues,
  back,
  next,
}: HeadcountScaleStepProps) => {
  const { patchFounderQualifyingQuestionsMutation } = usePatchFounderQualifyingQuestions(next);
  const device = useMediaQuery();

  const formik = useFormik<{ headcountScale?: number }>({
    initialValues: { headcountScale: initialValues.headcountScale ?? undefined },
    validationSchema: Yup.object({ headcountScale: Yup.number().required() }),
    onSubmit: async (values) => patchFounderQualifyingQuestionsMutation({ headcountScale: values.headcountScale }),
  });

  const [title, subtitle, backText, nextText] = useTranslation([
    'qualifying-questions.headcount-scale-step.title',
    'qualifying-questions.select-one',
    'qualifying-questions.previous-question.action',
    'qualifying-questions.next-question.action',
  ]);

  const options = [
    { value: 1, label: '1 - 4' },
    { value: 2, label: '5 - 10' },
    { value: 3, label: '11 - 20' },
    { value: 4, label: '21 - 50' },
    { value: 5, label: '50+' },
  ];

  const selectedButtonSize = device === 'xs' ? 'small' : 'large';
  const shouldIconBeDisplayed = device === 'xs' ? undefined : true;
  const selectedRadioVariant = device === 'xs' ? 'primary' : 'secondary';

  return (
    <S.Wrapper>
      <S.TitleGroupWrapper
        title={title}
        subtitle={subtitle}
        align="center"
        currentStep={currentStep}
        countOfSteps={countOfSteps}
      />

      <V2RadioGroup variant="vertical" defaultValue={formik.values.headcountScale}>
        {options.map((option) => (
          <V2Radio
            variant={selectedRadioVariant}
            key={option.value}
            value={option.value}
            checked={formik.values.headcountScale === option.value}
            onChange={() => formik.setFieldValue('headcountScale', option.value)}
          >
            {option.label}
          </V2Radio>
        ))}
      </V2RadioGroup>

      <S.ButtonWrapper>
        <V2Button
          variant="outlined"
          onClick={back}
          startIcon={shouldIconBeDisplayed && 'arrow-left'}
          size={selectedButtonSize}
        >
          {backText}
        </V2Button>
        <V2Button
          variant="primary"
          onClick={formik.submitForm}
          endIcon={shouldIconBeDisplayed && 'arrow-right'}
          disabled={Boolean(formik.errors.headcountScale) || !formik.values.headcountScale}
          size={selectedButtonSize}
        >
          {nextText}
        </V2Button>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

import { founderInvitesInvestorAction } from '@pages/content/lens/api/founder-invites-investor.action';
import message from '@parts/message/message';
import OnTopFooter from '@parts/on-top-footer/on-top-footer';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useTranslation } from '../use-translation/use-translation';

export const OnTopFooterConnection = ({
  id,
  hide,
  confirmLabel,
  cancelLabel,
  refetch,
}: {
  id: string;
  hide: () => void;
  confirmLabel: string;
  cancelLabel: string;
  refetch?: Function;
}) => {
  const [successMessage] = useTranslation(['portfolio.investment.modalConnection.succes']);
  const mutationConfig = {
    onSuccess: () => {
      message.success({ content: successMessage });
      if (refetch) {
        refetch();
      }
      hide();
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
    ignoreResults: true,
  };
  const { mutateAsync: connect } = useMutation(founderInvitesInvestorAction, mutationConfig);

  return (
    <OnTopFooter
      modal
      onCancel={hide}
      onConfirm={() => connect(id)}
      confirmLabel={confirmLabel}
      cancelLabel={cancelLabel}
    />
  );
};

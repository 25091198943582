import { useQueryWithPagination } from '@/hooks/use-query-with-pagination';
import type { Sorter } from '@/utils/type-definitions/filters';
import { GET_NED_INVESTOR_FOUNDER_CONNECTIONS_KEY, getConnections } from '../api/get-connections';
import type { InvestorAdvisorConnectionIntent, InvestorAdvisorFounderConnection } from '../types';

const PER_PAGE = 8;

type GetConnectionsQueryConfig = {
  intent: InvestorAdvisorConnectionIntent | undefined;
  searchByName?: string;
  sort?: Sorter;
};

export const useGetConnections = ({ searchByName = '', intent = undefined, sort }: GetConnectionsQueryConfig) => {
  const { data, error, isLoading, isFetching, isInitialLoading, pagination, ...restQueryResult } =
    useQueryWithPagination<InvestorAdvisorFounderConnection>(
      [GET_NED_INVESTOR_FOUNDER_CONNECTIONS_KEY, searchByName, sort?.key ?? '', sort?.order ?? '', intent ?? ''],
      (page: number, perPage: number) => getConnections({ page, perPage, searchByName, intent, sort }),
      { perPage: PER_PAGE, refetchOnWindowFocus: true },
    );

  return {
    ...restQueryResult,
    connectionsData: data,
    connectionsError: error,
    isConnectionsDataLoading: isLoading,
    isConnectionsDataFetching: isFetching,
    isConnectionsInitialLoading: isInitialLoading,
    pagination,
  };
};

import { V2IconBox } from '@/components/ui/v2-icon-box/v2-icon-box';
import { V2Icon } from '@/components/ui/v2-icon/v2-icon';
import { V2WithAsterisk } from '../v2-with-asterisk/v2-with-asterisk';
import type { V2InfoPanelProps } from './v2-info-panel.types';

import { useTheme } from 'styled-components';
import S from './v2-info-panel.styles';

export const V2InfoPanel = ({
  icon,
  subtitle,
  title,
  actionUrl = undefined,
  variant = 'default',
  'data-testid': testId = 'v2-info-panel',
  required = false,
  customActionElement,
  ...restProps
}: V2InfoPanelProps) => {
  const theme = useTheme();
  const content = (
    <>
      <S.IconWrapper>
        <V2IconBox
          icon={icon}
          variant={variant === 'inactive' ? 'neutral-light' : 'secondary'}
          data-testid={`v2-info-panel-icon-box-${variant}`}
        />
      </S.IconWrapper>
      <S.DescriptionWrapper>
        <S.Title>
          {}
          {required ? (
            <V2WithAsterisk color={variant === 'default' ? theme.v2.colors.red[400] : 'inherit'}>
              {title}
            </V2WithAsterisk>
          ) : (
            title
          )}
        </S.Title>
        <S.Subtitle>{subtitle}</S.Subtitle>
      </S.DescriptionWrapper>
    </>
  );

  return actionUrl ? (
    <S.V2InfoPanelLink to={actionUrl} data-testid={testId} {...restProps}>
      {content}
      <S.PanelActionWrapper data-testid="panel-link-arrow">
        <V2Icon name="chevron-right" size="lg" />
      </S.PanelActionWrapper>
    </S.V2InfoPanelLink>
  ) : (
    <S.V2InfoPanel data-testid={testId} {...restProps}>
      {content}
      <S.PanelActionWrapper data-testid="panel-action">{customActionElement}</S.PanelActionWrapper>
    </S.V2InfoPanel>
  );
};

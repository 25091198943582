import type { GraphqlResponse } from '@/types';
import { axiosInstance } from '@/utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@/utils/fns/graphql-response-wrapper';

type ResponseData = {
  businessName: string;
  industry: string[];
};

export const GET_COMPANY_DETAILS_QUERY_KEY = 'get-company-details-query';

export const getCompanyDetails = async (value: string): Promise<GraphqlResponse<ResponseData>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getFounderPublicData {
        getFounderPublicData(founderId: "${value}") {
          businessName,
          industry
        }
      }
    `,
  });

  return graphqlResponseWrapper(data, ['getFounderPublicData']);
};

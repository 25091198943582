import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import {
  BooleanComparator,
  Comparator,
  DateComparator,
  NumberComparator,
  QueryFilterKind,
  setColumn,
  SortDirections,
  TextComparator,
  type FiltersSet,
} from '../use-filtering/use-filtering';
import { useQueryParams } from '../use-query/use-query-params';
import { useTranslation } from '../use-translation/use-translation';

export const useColumnFiltering = ({
  dataKey,
  dispatch,
  isSorting,
}: {
  dataKey: string;
  dispatch: Function;
  isSorting: boolean;
}) => {
  const query = useQueryParams();

  const [requiredLabel] = useTranslation(['formik.validation.required']);

  const { sort: querySort, filters: queryFilter } = query;

  const recreatedFilters = (() => {
    if (typeof queryFilter === 'string') {
      const filters = JSON.parse(queryFilter);

      if (Array.isArray(filters)) {
        const textByValues = filters.find(
          (f) =>
            f.key === dataKey &&
            f[QueryFilterKind.Text] &&
            f[QueryFilterKind.Text].comparator === TextComparator.ListOfValues,
        );

        const textByCondition = filters.find(
          (f) =>
            f.key === dataKey &&
            f[QueryFilterKind.Text] &&
            f[QueryFilterKind.Text].comparator !== TextComparator.ListOfValues,
        );

        const boolean = filters.find((f) => f.key === dataKey && f[QueryFilterKind.Boolean]);

        const number = filters.find((f) => f.key === dataKey && f[QueryFilterKind.Numeric]);

        const date = filters.find((f) => f.key === dataKey && f[QueryFilterKind.Date]);

        return {
          textByValues,
          textByCondition,
          boolean,
          number,
          date,
        };
      }
    }

    return {
      textByValues: undefined,
      textByCondition: undefined,
      boolean: undefined,
      number: undefined,
      date: undefined,
    };
  })();

  const recreatedSort = (() => {
    if (typeof querySort === 'string') {
      const sort = JSON.parse(querySort);

      if (Array.isArray(sort)) {
        return sort.find((s) => s.key === dataKey);
      }
    }

    return undefined;
  })();

  const {
    isValid,
    submitForm,
    setValues,
    values,
    getFieldProps,
    getFieldMeta,
    setFieldValue,
    resetForm,
    dirty,
    submitCount,
  } = useFormik({
    initialValues: {
      sort: recreatedSort,
      filters: recreatedFilters,
    },
    enableReinitialize: true,
    onSubmit: (v) => {
      if (Object.values(v.filters).filter((f) => f).length > 0 || v.sort) {
        dispatch(
          setColumn(
            Object.values(v.filters)
              .filter((f) => f)
              .map((f) => {
                if (f.rangeValue === null) {
                  const { rangeValue, ...withoutRangeValue } = f;

                  return withoutRangeValue;
                }

                return f;
              }) as FiltersSet,
            dataKey,
            v.sort,
          ),
        );
      }
    },
    validationSchema: Yup.object().shape({
      sort: Yup.object().shape({
        direction: Yup.string().oneOf(Object.values(SortDirections)),
      }),
      // @ts-ignore
      filters: Yup.object().shape({
        number: Yup.object()
          .shape({
            [QueryFilterKind.Numeric]: Yup.object().shape({
              comparator: Yup.string()
                .oneOf([...Object.values(Comparator), ...Object.values(NumberComparator)])
                .required(requiredLabel),
              value: Yup.number().test({
                name: 'value-present-for-certain-comparators',
                message: requiredLabel,
                // eslint-disable-next-line object-shorthand
                test: function (val) {
                  if (![Comparator.Empty, Comparator.NotEmpty].includes(this.parent.comparator)) {
                    return Promise.resolve(Boolean(val) || val === 0);
                  }

                  return Promise.resolve(true);
                },
              }),
              rangeValue: Yup.number().test({
                name: 'value-present-for-certain-comparators',
                message: requiredLabel,
                // eslint-disable-next-line object-shorthand
                test: function (val) {
                  if ([NumberComparator.Between, NumberComparator.NotBetween].includes(this.parent.comparator)) {
                    return Promise.resolve(Boolean(val) || val === 0);
                  }

                  return Promise.resolve(true);
                },
              }),
            }),
          })
          .notRequired()
          .default(undefined),
        textByValues: Yup.object()
          .shape({
            [QueryFilterKind.Text]: Yup.object().shape({
              comparator: Yup.string().oneOf([TextComparator.ListOfValues]).required(requiredLabel),
              values: Yup.array().of(Yup.string().required(requiredLabel)).required(requiredLabel),
            }),
          })
          .notRequired()
          .default(undefined),
        textByCondition: Yup.object()
          .shape({
            [QueryFilterKind.Text]: Yup.object().shape({
              comparator: Yup.string()
                .oneOf([
                  ...Object.values(Comparator),
                  ...Object.values(TextComparator).filter((c) => c !== TextComparator.ListOfValues),
                ])
                .required(requiredLabel),
              value: Yup.string().test({
                name: 'value-present-for-certain-comparators',
                message: requiredLabel,
                // eslint-disable-next-line object-shorthand
                test: function (val) {
                  if (![Comparator.Empty, Comparator.NotEmpty].includes(this.parent.comparator)) {
                    return Promise.resolve(Boolean(val));
                  }

                  return Promise.resolve(true);
                },
              }),
            }),
          })
          .notRequired()
          .default(undefined),
        date: Yup.object()
          .shape({
            [QueryFilterKind.Date]: Yup.object().shape({
              comparator: Yup.string().oneOf(Object.values(DateComparator)).required(requiredLabel),
              value: Yup.date().required(requiredLabel),
            }),
          })
          .notRequired()
          .default(undefined),
        boolean: Yup.object()
          .shape({
            [QueryFilterKind.Boolean]: Yup.object().shape({
              comparator: Yup.string().oneOf(Object.values(BooleanComparator)),
              value: Yup.string().required(requiredLabel),
            }),
          })
          .notRequired()
          .default(undefined),
      }),
    }),
  });

  useEffect(() => {
    if (!isSorting) {
      setValues({ filters: values.filters, sort: undefined });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSorting]);

  return {
    getFieldProps,
    getFieldMeta,
    setFieldValue,
    values,
    isValid,
    dirty,
    submitCount,
    submit: submitForm,
    reset: resetForm,
    isActive: Object.values(recreatedFilters).filter((f) => f).length > 0 || recreatedSort,
    keys: {
      numberComparator: `filters.number.${QueryFilterKind.Numeric}.comparator`,
      numberValue: `filters.number.${QueryFilterKind.Numeric}.value`,
      numberRange: `filters.number.${QueryFilterKind.Numeric}.rangeValue`,
      numberKey: 'filters.number.key',
      textByValues: 'filters.textByValues',
      boolean: 'filters.boolean',
      textByConditionComparator: `filters.textByCondition.${QueryFilterKind.Text}.comparator`,
      textByConditionValue: `filters.textByCondition.${QueryFilterKind.Text}.value`,
      textByConditionKey: 'filters.textByCondition.key',
      dateComparator: `filters.date.${QueryFilterKind.Date}.comparator`,
      dateValue: `filters.date.${QueryFilterKind.Date}.value`,
      dateKey: 'filters.date.key',
    },
  };
};

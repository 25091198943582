import { useColumnFiltering } from '@utils/hooks/use-column-filtering/use-column-filtering';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { resetColumn, type ColumnsQuerying } from '@utils/hooks/use-filtering/use-filtering';
import { useNumericConditionLabels } from '@utils/hooks/use-numeric-condition-labels/use-numeric-condition-labels';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useState } from 'react';
import S from './filter-header.styles';
import { Filter } from './parts/filter/filter';

export const FilterHeader = ({
  title,
  dataKey,
  querying: { dispatch, sortKey, sortingRules, filteringRules },
}: {
  title: string;
  dataKey: string;
  querying: ColumnsQuerying;
}) => {
  const {
    deviceData: { isDesktop },
  } = useDeviceDetect();

  const { submit, reset, getFieldProps, setFieldValue, getFieldMeta, keys, isValid, dirty, submitCount, isActive } =
    useColumnFiltering({
      dataKey,
      dispatch,
      isSorting: sortKey === dataKey,
    });
  const conditionLabels = useNumericConditionLabels();

  const [cancelLabel, resetLabel, okLabel] = useTranslation(['filters.cancel', 'filters.reset', 'filters.ok']);

  const [visible, setVisible] = useState(false);

  const formatters = {
    byValue: (val: string) => conditionLabels[val] || val,
  };

  return (
    <S.Dropdown
      isActive={isActive}
      onVisibleChange={(flag) => setVisible(flag)}
      visible={visible}
      overlay={
        <S.Overlay>
          <Filter
            keys={keys}
            getFieldMeta={getFieldMeta}
            dataKey={dataKey}
            setFieldValue={setFieldValue}
            sortingRules={sortingRules}
            filteringRules={filteringRules}
            getFieldProps={getFieldProps}
            formatters={formatters}
          />
          <S.Footer>
            <S.Save
              disabledEvaluation={{ dirty, isValid, submitCount }}
              onClick={() => {
                setVisible(false);
                submit();
              }}
            >
              {okLabel}
            </S.Save>
            <S.Cancel type="ghost" onClick={() => setVisible(false)}>
              {cancelLabel}
            </S.Cancel>
            <S.Reset
              type="link"
              onClick={() => {
                dispatch(resetColumn(dataKey));
                reset();
              }}
            >
              {resetLabel}
            </S.Reset>
          </S.Footer>
        </S.Overlay>
      }
      trigger={['click']}
    >
      <div>
        {title} {isDesktop ? <S.Filters /> : null}
      </div>
    </S.Dropdown>
  );
};

import type { GetReadinessScoreQueryResponseData } from '@/modules/founder/readiness-score/api/get-readiness-score.query';
import {
  INITIAL_DATA_SEARCH_KEY,
  TEAM_MEMBER_DRAWER_OPEN_SEARCH_KEY,
} from '@/pages/content/profile/founder/parts/editor/parts/team-members/team-members';
import { Links } from '@/router/links';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { stringify } from 'query-string';
import { StepContent, type ContentStep } from '../step-content/step-content';
export const TeamContent = ({ data }: { data: GetReadinessScoreQueryResponseData['team'] }) => {
  const [
    titleLL,
    descriptionLL,
    operationsTitleLL,
    operationsDescriptionLL,
    salesTitleLL,
    salesDescriptionLL,
    marketingTitleLL,
    marketingDescriptionLL,
    productTitleLL,
    productDescriptionLL,
    financeTitleLL,
    financeDescriptionLL,
    technologyTitleLL,
    technologyDescriptionLL,
  ] = useTranslation([
    'homepage.readinessScore.modal.team.title',
    'homepage.readinessScore.modal.team.description',
    'homepage.readinessScore.modal.team.step.operations.title',
    'homepage.readinessScore.modal.team.step.operations.description',
    'homepage.readinessScore.modal.team.step.sales.title',
    'homepage.readinessScore.modal.team.step.sales.description',
    'homepage.readinessScore.modal.team.step.marketing.title',
    'homepage.readinessScore.modal.team.step.marketing.description',
    'homepage.readinessScore.modal.team.step.product.title',
    'homepage.readinessScore.modal.team.step.product.description',
    'homepage.readinessScore.modal.team.step.finance.title',
    'homepage.readinessScore.modal.team.step.finance.description',
    'homepage.readinessScore.modal.team.step.technology.title',
    'homepage.readinessScore.modal.team.step.technology.description',
  ]);

  const { selectedAreas, teamMembersScore } = data;

  const isFulfilled = (key: string) => selectedAreas.findIndex((item) => item.toLowerCase() === key.toLowerCase()) >= 0;

  const createLink = (area: string) => {
    const queryString = stringify({
      st: 'team-members',
      [TEAM_MEMBER_DRAWER_OPEN_SEARCH_KEY]: isFulfilled(area) ? undefined : 'true',
      [INITIAL_DATA_SEARCH_KEY]: isFulfilled(area) ? undefined : stringify({ area }),
    });

    return Links.PROFILE() + '?' + queryString;
  };

  const steps: ContentStep[] = [
    {
      id: 'Operations',
      title: operationsTitleLL,
      description: operationsDescriptionLL,
      icon: 'arrow-left-right',
      actionUrl: createLink('Operations'),
      received: isFulfilled('Operations') ? 1 : 0,
      available: 1,
    },
    {
      id: 'Sales',
      title: salesTitleLL,
      description: salesDescriptionLL,
      icon: 'graph-up',
      actionUrl: createLink('Sales'),
      received: isFulfilled('Sales') ? 1 : 0,
      available: 1,
    },
    {
      id: 'Marketing',
      title: marketingTitleLL,
      description: marketingDescriptionLL,
      icon: 'megaphone',
      actionUrl: createLink('Marketing'),
      received: isFulfilled('Marketing') ? 1 : 0,
      available: 1,
    },
    {
      id: 'Product',
      title: productTitleLL,
      description: productDescriptionLL,
      icon: 'box',
      actionUrl: createLink('Product'),
      received: isFulfilled('Product') ? 1 : 0,
      available: 1,
    },
    {
      id: 'Finance',
      title: financeTitleLL,
      description: financeDescriptionLL,
      icon: 'bank',
      actionUrl: createLink('Finance'),
      received: isFulfilled('Finance') ? 1 : 0,
      available: 1,
    },
    {
      id: 'Technology',
      title: technologyTitleLL,
      description: technologyDescriptionLL,
      icon: 'cpu',
      actionUrl: createLink('Technology'),
      received: isFulfilled('Technology') ? 1 : 0,
      available: 1,
    },
  ];

  return (
    <StepContent
      introTitle={titleLL}
      introDescription={descriptionLL}
      headerIcon="people"
      steps={steps}
      score={teamMembersScore}
    />
  );
};

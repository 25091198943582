import { SingleButtonModal } from '@parts/single-button-modal/single-button-modal';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Button } from 'antd';
import styled from 'styled-components';

const StyledContent = styled.p`
  width: 100%;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.regular};
`;

interface DeleteFundingRoundModalProps {
  onClick: () => void;
  onClose: () => void;
}

export const DeleteFundingRoundModal = ({ onClick, onClose }: DeleteFundingRoundModalProps) => {
  const [buttonLabel, messageLabel] = useTranslation(['fundingRounds.delete.button', 'fundingRounds.delete.message']);

  const footer = (
    <Button type="primary" onClick={onClick} data-testid="delete-funding-round">
      {buttonLabel}
    </Button>
  );

  return (
    <SingleButtonModal title="Delete Funding Round" footer={footer} onClose={onClose}>
      <StyledContent>{messageLabel}</StyledContent>
    </SingleButtonModal>
  );
};

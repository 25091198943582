import { V2IconButton } from '@/components/ui/v2-icon-button/v2-icon-button';
import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const V2Pagination = styled.div`
  width: 100%;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  margin: 48px 0;
`;

const Button = styled(V2IconButton)`
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.blue[300])};

  &:disabled {
    color: ${({ theme }) => theme.v2.colors.neutral[400]};
  }
`;

const CurrentPageNumber = styled.div`
  user-select: none;
  display: block;
  font-size: ${({ theme }) => theme.v2.typography.sizes.sm};
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[800], theme.v2.colors.neutral[200])};
`;

export default { V2Pagination, Button, CurrentPageNumber };

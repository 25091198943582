import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export interface FundingRoundsMeta {
  type: string[];
  status: string[];
}

export const GET_FUNDING_ROUNDS_META_CACHE_KEY = 'get-funding-rounds-meta';

export const getFundingRoundsMetaAction = async (): Promise<GraphqlResponse<FundingRoundsMeta>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getFundingRoundsMeta {
        fundingRoundsMeta {
          status
          type
        }
      }
    `,
  });

  return graphqlResponseWrapper(data, ['fundingRoundsMeta']);
};

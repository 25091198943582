import { Button as AntButton } from 'antd';
import styled from 'styled-components';

const ModalContent = styled.div`
  a {
    color: ${({ theme }) => theme.color.link};

    &:hover {
      color: ${({ theme }) => theme.color.link};
    }
  }
`;

const MainList = styled.ul`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  max-height: 400px;
  list-style: none;
  gap: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  padding-right: ${({ theme }) => theme.spacing.small};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin-top: ${({ theme }) => theme.spacing.medium};
  }
`;

const MainListItem = styled.li`
  padding-right: ${({ theme }) => theme.spacing.medium};
`;

const ButtonWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.xsmall};
  width: 100%;
  display: flex;
  justify-content: left;
`;

const Button = styled(AntButton)`
  &.ant-btn:not(.ant-btn-sm) {
    width: 100px;
    float: left;
    align-self: stretch;
  }
`;

export default { ModalContent, MainList, MainListItem, Button, ButtonWrapper };

import styled from 'styled-components';

import { FormikField } from '@parts/forms/formik-field/formik-field';

const Wrapper = styled(FormikField)`
  margin-bottom: ${({ theme }) => theme.spacing.small};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export default { Wrapper };

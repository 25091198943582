import { AddExperienceFormSchema, type AddExperienceFormSchemaType } from './add-experience-modal/types';
import { useFormik } from 'formik';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { Input } from 'antd';
import { CompensationType } from '@/pages/content/profile/ned/ned-profile.page';
import { V2Button } from '@/components/ui/v2-button/v2-button';

import * as S from './add-experience-modal/steps/steps.styles';

type FormProps = {
  isInternalCompany: boolean;
  industries: string[];
  skillSets: string[];
  initialValues: AddExperienceFormSchemaType;
  isLoading?: boolean;
  onSubmit: (values: AddExperienceFormSchemaType) => void;
  back?: () => void;
};

export const ExperienceForm = ({
  initialValues,
  isInternalCompany,
  industries,
  skillSets,
  isLoading,
  onSubmit,
  back,
}: FormProps) => {
  const formik = useFormik<AddExperienceFormSchemaType>({
    validateOnBlur: true,
    validateOnChange: false,
    validateOnMount: false,
    initialValues,
    validationSchema: AddExperienceFormSchema,
    onSubmit: (values) => onSubmit(values),
  });

  const [
    companyLabel,
    companyPlaceholder,
    roleLabel,
    rolePlaceholder,
    industryLabel,
    industryPlaceholder,
    startDateLabel,
    endDateLabel,
    onGoingLabel,
    skillSetLabel,
    skillSetPlaceholder,
    compensationLabel,
    summaryLabel,
    summaryPlaceholder,
    emailLabel,
    emailPlaceholder,
    saveLabel,
    backLabel,
  ] = useTranslation([
    'profile.advisor.exp.form.company.label',
    'profile.advisor.exp.form.company.placeholder',
    'profile.advisor.exp.form.role.label',
    'profile.advisor.exp.form.role.placeholder',
    'profile.advisor.exp.form.industry.label',
    'profile.advisor.exp.form.industry.placeholder',
    'profile.advisor.exp.form.startDate.label',
    'profile.advisor.exp.form.endDate.label',
    'profile.advisor.exp.form.onGoing.label',
    'profile.advisor.exp.form.skillSet.label',
    'profile.advisor.exp.form.skillSet.placeholder',
    'profile.advisor.exp.form.compensation.label',
    'profile.advisor.exp.form.summary.label',
    'profile.advisor.exp.form.summary.placeholder',
    'profile.advisor.exp.form.email.label',
    'profile.advisor.exp.form.email.placeholder',
    'profile.advisor.exp.form.save',
    'profile.advisor.exp.form.back',
  ]);

  return (
    <form>
      <S.FormWrapper>
        <S.InputWrapper>
          <S.Label>{companyLabel}</S.Label>
          <Input
            placeholder={companyPlaceholder}
            disabled={isInternalCompany}
            value={formik.values.name}
            onChange={(e) =>
              formik.setValues({
                ...formik.values,
                name: e.target.value,
              })}
          />
          <S.ErrorMessage>{formik.errors.name}</S.ErrorMessage>
        </S.InputWrapper>

        <S.InputWrapper>
          <S.Label>{roleLabel}</S.Label>{' '}
          <Input
            style={{ position: 'relative' }}
            placeholder={rolePlaceholder}
            value={formik.values.role}
            onChange={(e) =>
              formik.setValues({
                ...formik.values,
                role: e.target.value,
              })}
          />
          <S.ErrorMessage>{formik.errors.role}</S.ErrorMessage>
        </S.InputWrapper>

        <S.InputWrapper>
          <S.Label>{industryLabel}</S.Label>
          <S.MultiSelect
            placeholder={industryPlaceholder}
            disabled={isInternalCompany}
            mode="multiple"
            size="middle"
            value={formik.values.industry || []}
            onChange={(value) => {
              const newValue = value as string[];
              formik.setValues({
                ...formik.values,
                industry: newValue.slice(0, 3),
              });
            }}
            options={industries.map((el) => ({
              label: el,
              value: el,
            }))}
          />
          <S.ErrorMessage>{formik.errors.industry}</S.ErrorMessage>
        </S.InputWrapper>

        <S.InputWrapper>
          <S.Label>{startDateLabel}</S.Label>
          <S.DateInput
            type="date"
            value={formik.values.startDate}
            onChange={(e) =>
              formik.setValues({
                ...formik.values,
                startDate: e.target.value,
              })}
          />
          <S.ErrorMessage>{formik.errors.startDate}</S.ErrorMessage>
        </S.InputWrapper>

        <S.InputWrapper>
          <S.Label>{endDateLabel}</S.Label>
          <S.DateInput
            disabled={formik.values.isOngoing}
            type="date"
            value={formik.values.endDate ?? ''}
            onChange={(e) =>
              formik.setValues({
                ...formik.values,
                endDate: e.target.value,
              })}
          />
          <S.ErrorMessage>{formik.errors.endDate}</S.ErrorMessage>
        </S.InputWrapper>

        <S.InputWrapper>
          <S.Checkbox
            checked={formik.values.isOngoing}
            value={formik.values.isOngoing}
            onChange={() => {
              formik.setValues({
                ...formik.values,
                endDate: undefined,
                isOngoing: !formik.values.isOngoing,
              });
            }}
          >
            {onGoingLabel}
          </S.Checkbox>
          <S.ErrorMessage>{formik.errors.isOngoing}</S.ErrorMessage>
        </S.InputWrapper>

        <S.InputWrapper>
          <S.Label>{skillSetLabel}</S.Label>
          <S.MultiSelect
            placeholder={skillSetPlaceholder}
            maxTagCount={3}
            mode="multiple"
            size="middle"
            value={formik.values.skillSet || []}
            onChange={(value) => {
              const newValue = value as string[];
              formik.setValues({
                ...formik.values,
                skillSet: newValue.slice(0, 3),
              });
            }}
            options={skillSets.map((el) => ({
              label: el,
              value: el,
            }))}
          />
          <S.ErrorMessage>{formik.errors.skillSet}</S.ErrorMessage>
        </S.InputWrapper>

        <S.InputWrapper>
          <S.Label>{compensationLabel}</S.Label>
          <S.Select
            value={formik.values.compensationType ?? ''}
            onChange={(value) =>
              formik.setValues({
                ...formik.values,
                compensationType: value as string,
              })}
            options={[CompensationType.Paid, CompensationType.Equity, CompensationType.Blended, '-'].map((el) => ({
              label: el,
              value: el,
            }))}
          />
          <S.ErrorMessage>{formik.errors.compensationType}</S.ErrorMessage>
        </S.InputWrapper>

        {!isInternalCompany && (
          <S.InputWrapper>
            <S.Label>{emailLabel}</S.Label>
            <Input
              style={{ width: '100% !important' }}
              placeholder={emailPlaceholder}
              value={formik.values.email ?? undefined}
              onChange={(e) =>
                formik.setValues({
                  ...formik.values,
                  email: e.target.value,
                })}
            />
            <S.ErrorMessage>{formik.errors.email}</S.ErrorMessage>
          </S.InputWrapper>
        )}

        <S.InputWrapper>
          <S.Label>{summaryLabel}</S.Label>
          <Input.TextArea
            style={{ width: '100% !important' }}
            placeholder={summaryPlaceholder}
            rows={8}
            value={formik.values.experienceSummary ?? ''}
            onChange={(e) =>
              formik.setValues({
                ...formik.values,
                experienceSummary: e.target.value,
              })}
          />
          <S.ErrorMessage>{formik.errors.experienceSummary}</S.ErrorMessage>
        </S.InputWrapper>
      </S.FormWrapper>
      <S.ButtonWrapper>
        {back && (
          <V2Button
            disabled={isLoading}
            type="button"
            isResponsive
            variant="outlined"
            startIcon="arrow-left"
            onClick={(e) => {
              e.preventDefault();
              back();
            }}
          >
            {backLabel}
          </V2Button>
        )}
        <V2Button
          disabled={isLoading}
          isResponsive
          variant="primary"
          onClick={async (e) => {
            e.preventDefault();
            await formik.validateForm();
            formik.submitForm();
          }}
        >
          {saveLabel}
        </V2Button>
      </S.ButtonWrapper>
    </form>
  );
};

import type { Dashboard as DashboardType } from '@pages/content/pulse/api/get-founder-dashboards/get-founder-dashboards.action';
import type { Dashboard as DashboardDetailsData } from '@pages/content/pulse/parts/dashboards/api/get-founder-dashboard/get-founder-dashboard.action';
import { DashboardDetails } from '@pages/content/pulse/parts/dashboards/parts/dashboard/dashboard-details';
import {
  dashboardIdParam,
  DashboardsMenu,
} from '@pages/content/pulse/parts/dashboards/parts/dashboards-menu/dashboards-menu';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';

export const InvestorDashboards = ({
  dashboards,
  hasCloseConnection,
  dashboardDetailsIsLoading,
  dashboardDetails,
  followMode,
  allowSetNotifications,
  businessName,
}: {
  dashboards: DashboardType[] | null | undefined;
  hasCloseConnection?: boolean;
  dashboardDetailsIsLoading: boolean;
  dashboardDetails: DashboardDetailsData;
  followMode?: boolean;
  allowSetNotifications?: boolean;
  businessName: string;
}) => {
  const { [dashboardIdParam]: selectedDashboardId } = useQueryParams() as {
    [dashboardIdParam]: string;
  };

  const currentDashboardId = selectedDashboardId || dashboards?.[0]?.id;

  return (
    <>
      <DashboardsMenu hasCloseConnection={hasCloseConnection} dashboards={dashboards} />
      {currentDashboardId && dashboardDetails && (
        <DashboardDetails
          followMode={followMode}
          dashboardDetailsIsLoading={dashboardDetailsIsLoading}
          dashboardDetails={dashboardDetails}
          dashboardId={currentDashboardId}
          allowSetNotifications={allowSetNotifications}
          businessName={businessName}
        />
      )}
    </>
  );
};

import { SwitchLabelled as SwitchLabelledBase } from '@parts/switch-labelled/switch-labelled';
import styled from 'styled-components';

const DarkModeLabel = styled.span`
  padding-left: ${({ theme }) => theme.spacing.small}!important;
  color: ${({ theme }) => theme.color.fontPrimary}!important;
`;

const SwitchLabelled = styled(SwitchLabelledBase)`
  margin-right: ${({ theme }) => theme.spacing.small}!important;
`;

export default { DarkModeLabel, SwitchLabelled };

import { publishFounderProfileAction } from '@/modules/founder/readiness-score/api/publish-founder-profile.action';
import { unPublishFounderProfileAction } from '@/modules/founder/readiness-score/api/unpublish-founder-profile.action';
import message from '@/parts/message/message';
import { getServerError } from '@/utils/fns/get-server-error';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';

export const useTogglePublishFounderProfile = ({
  published,
  onSuccessCallback,
  onErrorCallback,
}: {
  published: boolean;
  onSuccessCallback?: (() => void) | (() => Promise<void>);
  onErrorCallback?: (() => void) | (() => Promise<void>);
}) => {
  const action = published ? unPublishFounderProfileAction : publishFounderProfileAction;
  const mutation = useMutation(action, {
    onSuccess: () => {
      onSuccessCallback?.();
    },
    onError: (error: AxiosError) => {
      message.error({ content: getServerError(error) });
      onErrorCallback?.();
    },
  });

  return {
    togglePublishFounderProfile: mutation.mutateAsync,
    isTogglePublishFounderProfileLoading: mutation.isLoading,
    ogMutationResult: { ...mutation },
  };
};

import { V2InvisibleButton } from '@/components/ui/v2-invisible-button/v2-invisible-button';
import { V2TitleGroup } from '@/components/ui/v2-title-group/v2-title-group';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';

import * as S from './steps.styles';

type AllDoneProps = {
  close: () => void;
};

export const AllDone = ({ close }: AllDoneProps) => {
  const [allDone, thankYou] = useTranslation(['profile.advisor.exp.form.allDone', 'profile.advisor.exp.form.thankYou']);

  return (
    <S.AllDoneWrapper>
      <V2InvisibleButton onClick={close}>
        <V2TitleGroup title={allDone} subtitle={thankYou} icon="check" align="center" />
      </V2InvisibleButton>
    </S.AllDoneWrapper>
  );
};

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing.xmedium};
  margin-bottom: 70px;

  span {
    font-size: ${({ theme }) => theme.fontSize.xmedium};

    @media (min-width: ${({ theme }) => theme.deviceBreakpoint.highResDesktop}) {
      font-size: ${({ theme }) => theme.fontSize.medium};
    }
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    padding-top: 25px;
  }
`;

const Title = styled.h1`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: 26px;
  text-align: center;
  color: ${({ theme }) => theme.baseColors.White};
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.highResDesktop}) {
    font-size: 30px;
  }
`;

const Subtitle = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSize.regular};
  line-height: 1.5;
  color: ${({ theme }) => theme.onboardingColors.headingSubtitle};
  opacity: 0.5;
  text-align: center;
`;

export default { Subtitle, Title, Wrapper };

import { CheckoutStateProvider } from '../context/checkout.provider';
import { LazyCheckoutPage } from './lazy-checkout.page';
import { CheckoutPageMode } from './types/checkout-page-mode';

export const CheckoutPageWithProvider = () => {
  return (
    <CheckoutStateProvider>
      <LazyCheckoutPage />
    </CheckoutStateProvider>
  );
};

export const ExtendSubscriptionPageWithProvider = () => {
  return (
    <CheckoutStateProvider>
      <LazyCheckoutPage mode={CheckoutPageMode.Extend} />
    </CheckoutStateProvider>
  );
};

export const UpgradeSubscriptionPageWithProvider = () => {
  return (
    <CheckoutStateProvider>
      <LazyCheckoutPage mode={CheckoutPageMode.Upgrade} />
    </CheckoutStateProvider>
  );
};

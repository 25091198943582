import styled from 'styled-components';
import { V2Link } from '../v2-link/v2-link';

const Link = styled(V2Link)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
`;

export default {
  Link,
};

import DragIconBase from '@assets/icons/drag.svg?react';
import styled from 'styled-components';

const padding = 10;

const DragHolder = styled.div`
  cursor: pointer;
  display: inline-block;
  margin-right: 5px;
  margin-left: -${padding}px;
  padding: 0 ${padding}px;

  &:hover {
    transform: scale(1.1);
  }
`;

const DragIcon = styled(DragIconBase)`
  vertical-align: sub;
`;

export default {
  DragHolder,
  DragIcon,
};

import Swappsi from '@/assets/images/curated-feed/swappsi.webp';
import Marvin from '@/assets/v2-images/marvin-logo.webp';
import Capchase from '@/assets/v2-images/capchase-logo.webp';
import CityGate from '@/assets/v2-images/citygate-logo.webp';
import Vestd from '@/assets/images/curated-feed/vestd-logo-light.svg';
import { V2CuratedFeedSection } from '@/components/ui/v2-curated-feed-section/v2-curated-feed-section';
import type { CuratedFeedItem } from '@/components/ui/v2-curated-feed-section/v2-curated-feed-section.types';
import { capchaseLink, cityGateLink, marvinLink, swappsiLink, vestdLink } from '@constants/links';
import { V2SuggestionBanner } from '@ui/v2-suggestion-banner/v2-suggestion-banner';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';

export const CuratedFeed = () => {
  const [
    title,
    description,
    bannerContent,
    vestdTitle,
    vestdDescription,
    vestdButton,
    vestdBadge,
    swappsiTitle,
    swappsiDescription,
    swappsiButton,
    swappsiBadge,
    marvinDescription,
    marvinButton,
    marvinBadge,
    cityGateDescription,
    cityGateButton,
    cityGateBadge,
    capchaseDescription,
    capchaseButton,
  ] = useTranslation([
    'homepage.curatedFeed.title',
    'homepage.curatedFeed.description',
    'homepage.curatedFeed.banner',
    'homepage.curatedFeed.vestd.title',
    'homepage.curatedFeed.vestd.description',
    'homepage.curatedFeed.vestd.button',
    'homepage.curatedFeed.vestd.badge',
    'homepage.curatedFeed.swappsi.title',
    'homepage.curatedFeed.swappsi.description',
    'homepage.curatedFeed.swappsi.button',
    'homepage.curatedFeed.swappsi.badge',
    'homepage.curatedFeed.marvin.description',
    'homepage.curatedFeed.marvin.button',
    'homepage.curatedFeed.marvin.badge',
    'homepage.curatedFeed.cityGate.description',
    'homepage.curatedFeed.cityGate.button',
    'homepage.curatedFeed.cityGate.badge',
    'homepage.curatedFeed.capchase.description',
    'homepage.curatedFeed.capchase.button',
  ]);

  const items: CuratedFeedItem[] = [
    {
      title: vestdTitle,
      description: vestdDescription,
      variant: 'navy',
      'data-testid': 'feed-tile-vestd',
      badgeText: vestdBadge,
      button: { text: vestdButton, link: vestdLink, endIcon: 'stars' },
      logoImgUrl: Vestd,
    },
    {
      title: swappsiTitle,
      description: swappsiDescription,
      variant: 'blue',
      'data-testid': 'feed-tile-swappsi',
      badgeText: swappsiBadge,
      button: { text: swappsiButton, link: swappsiLink, endIcon: 'stars' },
      logoImgUrl: Swappsi,
    },
    {
      description: marvinDescription,
      badgeText: marvinBadge,
      variant: 'green',
      button: { text: marvinButton, link: marvinLink, endIcon: 'stars' },
      logoImgUrl: Marvin,
      'data-testid': 'feed-tile-marvin',
    },
    {
      description: cityGateDescription,
      variant: 'blue',
      button: { text: cityGateButton, link: cityGateLink, endIcon: 'stars' },
      badgeText: cityGateBadge,
      logoImgUrl: CityGate,
      'data-testid': 'feed-tile-city-gate',
    },
    {
      description: capchaseDescription,
      variant: 'navy',
      button: { text: capchaseButton, link: capchaseLink, endIcon: 'stars' },
      logoImgUrl: Capchase,
      'data-testid': 'feed-tile-capchase',
    },
  ];

  return (
    <V2CuratedFeedSection
      title={title}
      description={description}
      items={items}
      data-testid="homepage-curated-feed-section"
      footer={
        <V2SuggestionBanner
          text={bannerContent}
          icon="graph-up-arrow"
          variant="primary"
          data-testid="curated-feed-suggestion-banner"
        />
      }
    />
  );
};

import { useLocale } from '@utils/hooks/use-locale/use-locale';
import { AppMessages, type AppMessagesKey } from '@utils/i18n/messages';
import type { ReactNode } from 'react';

type TranslationMessageConfig = {
  id: AppMessagesKey;
  variables?: Record<string, ReactNode>;
};

function useTranslation<T = string>(key: AppMessagesKey): T;
function useTranslation<T = string>(key: AppMessagesKey[]): T[];
function useTranslation<T = string>(key: TranslationMessageConfig): T;
function useTranslation<T = string>(key: TranslationMessageConfig[]): T[];
function useTranslation<T = string>(key: (AppMessagesKey | TranslationMessageConfig)[]): T[];
function useTranslation<T = string>(
  key:
    | AppMessagesKey
    | AppMessagesKey[]
    | TranslationMessageConfig
    | TranslationMessageConfig[]
    | (AppMessagesKey | TranslationMessageConfig)[],
): T | T[] {
  const { formatMessage } = useLocale();

  if (Array.isArray(key)) {
    return key.map((translationKey) => {
      if (typeof translationKey === 'string') {
        return formatMessage({ id: translationKey });
      }

      return formatMessage({ id: translationKey.id }, translationKey.variables);
    }) as T;
  }

  if (typeof key === 'object') {
    return formatMessage({ id: key.id }, key.variables) as T;
  }

  return formatMessage({
    id: AppMessages[key as keyof typeof AppMessages],
  }) as T;
}

export { useTranslation };

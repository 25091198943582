import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 1px 1px 10px ${({ theme }) => theme.color.border};

  .ant-layout-header {
    background: ${({ theme }) => theme.color.sectionBackground};
    line-height: 20px;
    height: ${({ theme }) => theme.ui.desktopMenuHeight};
    padding: 0;
    margin: 0;

    @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
      height: ${({ theme }) => theme.ui.mobileMenuHeight};
    }
  }

  .ant-menu {
    flex-basis: 80%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    line-height: 100%;
    border: 0;
    background: ${({ theme }) => theme.color.sectionBackground};
  }
`;

export default { Wrapper };

import { Button as ButtonBase } from '@parts/button/button';
import { SecureLink } from '@parts/link/link';
import styled from 'styled-components';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.large};
`;

const Description = styled.h3`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontSize.medium};
  color: ${({ theme }) => theme.color.fontPrimary};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    text-align: center;
  }
`;

const Button = styled(ButtonBase)`
  border: 1px solid ${({ theme }) => theme.onboardingColors.buttonBorder};
  margin-top: ${({ theme }) => theme.spacing.xbase};
  background: transparent;
  color: ${({ theme }) => theme.color.fontPrimary};

  &:hover {
    background-color: ${({ theme }) => theme.onboardingColors.buttonBorder};
    color: ${({ theme }) => theme.color.fontPrimary};
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin-top: ${({ theme }) => theme.spacing.medium};
  }
`;

const Link = styled(SecureLink)`
  padding: ${({ theme }) => theme.spacing.xmedium};
  color: ${({ theme }) => theme.color.fontPrimary};
  text-decoration: none;

  &:hover {
    color: inherit;
  }
`;

export default {
  Section,
  Description,
  Button,
  Link,
};

import message from '@/parts/message/message';
import { getServerError } from '@/utils/fns/get-server-error';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { markAsClose } from '../api/mark-as-close.action';
import { unmarkAsClose } from '../api/unmark-as-close.action';

export const useHandleCloseConnection = (connectionId: string, isAlreadyClose: boolean, callback: () => void) => {
  const onSuccess = () => {
    callback();
    message.success({ content: 'Connection status has been updated' });
  };

  const onError = (err: AxiosError) => {
    message.error({ content: getServerError(err) });
  };

  const markAsCloseMutation = useMutation(markAsClose(connectionId), { onSuccess, onError });
  const unmarkAsCloseMutation = useMutation(unmarkAsClose(connectionId), { onSuccess, onError });

  return {
    markAsClose: markAsCloseMutation.mutate,
    unmarkAsClose: unmarkAsCloseMutation.mutate,
    isMarkAsCloseLoading: markAsCloseMutation.isLoading,
    isUnmarkAsCloseLoading: unmarkAsCloseMutation.isLoading,

    handleCloseConnection: isAlreadyClose ? unmarkAsCloseMutation.mutate : markAsCloseMutation.mutate,
    isCloseConnectionLoading: markAsCloseMutation.isLoading || unmarkAsCloseMutation.isLoading,
  };
};

import { Avatar as AvatarBase } from '@parts/avatar/avatar';
import { Select as SelectBase } from 'antd';
import styled from 'styled-components';

const Select = styled(SelectBase)`
  width: 100%;
  margin: auto;

  .ant-select-selection-search-input {
    height: 100% !important;
  }

  input {
    width: 92% !important;
  }

  .ant-select-selection-item {
    margin: auto 28px auto auto !important;
    color: ${({ theme }) => theme.color.fontPrimary};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    display: flex;
  }

  .ant-select-selection-placeholder {
    margin: auto;
  }

  &.ant-select-disabled {
    .ant-select-selector {
      cursor: help;
    }
  }
  .ant-select-clear {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    border-radius: 100%;
    border: solid 2px ${({ theme }) => theme.color.removeButtonInputBorder};
    background: ${({ theme }) => theme.color.removeButtonInputBackround};
    padding: 10px;
    margin-top: -12px;
    svg {
      fill: ${({ theme }) => theme.color.removeButtonInputBorder};
    }
  }
`;

const Option = styled(Select.Option)``;

const Span = styled.span`
  color: ${({ theme }) => theme.color.fontSecondary};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;

const Avatar = styled(AvatarBase)`
  margin-right: ${({ theme }) => theme.spacing.xsmall};
  display: inline-flex;
`;

export default { Select, Option, Span, Avatar };

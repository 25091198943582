import type { AdvisorRole } from '@/domain/accounts/roles';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import { RegionValue } from '@utils/type-definitions/regions-values';
import type { GraphqlResponse } from 'src/types';

export interface GetUsedCountriesAndRegionsResult {
  usedCountries: CountryCodes[];
  notUsedCountries: CountryCodes[];
  regions: RegionValue[];
}

export const GET_COUNTRIES_USED_BY_NEDS_CACHE_KEY = 'get-countries-used-by-neds';

export const getCountriesUsedByNedsAction =
  (advisorType: AdvisorRole) => async (): Promise<GraphqlResponse<GetUsedCountriesAndRegionsResult>> => {
    const queryName = `getCountriesUsedByNeds (advisorType: ${advisorType})`;

    const { data } = await axiosInstance.post('/query', {
      query: `
    query getCountriesUsedByNeds {
      ${queryName} {
          usedCountries
          notUsedCountries
          regions
      }
    }
      `,
    });

    return graphqlResponseWrapper(data, ['getCountriesUsedByNeds']);
  };

export const countriesUsedByNedsMock = {
  data: {
    getCountriesUsedByNeds: {
      usedCountries: ['PL', 'GB'],
      notUsedCountries: ['UA', 'FR'],
      regions: ['Europe'],
    },
  },
};

import { V2SignOutButton } from '@/components/ui/v2-sign-out-button/v2-sign-out-button';
import { UsStateNameValue, type USState } from '@/utils/type-definitions/us-state';
import { termsAndConditionsLinkNed } from '@constants/links';
import { Roles } from '@domain/accounts/roles';
import {
  registerAdvisorAction,
  type RegisterAdvisorActionPayloadAuth0,
} from '@pages/auth/api/register/register-advisor.actions';
import { GET_COUNTRIES_OPTIONS_DATA_CACHE_KEY, getCountriesListAction } from '@pages/content/api/get-countries-options';
import { CountrySelect } from '@parts/country-select/country-select';
import { FormikField } from '@parts/forms/formik-field/formik-field';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { Links } from '@router/links';
import { Routes } from '@router/routes';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { scrollToTop } from '@utils/fns/scroll-to-top';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { CountryCodes, CountryNames } from '@utils/type-definitions/iso-to-country-name';
import type { SelectValue } from 'antd/lib/select';
import { useFormik } from 'formik';
import jwt from 'jsonwebtoken';
import { stringify } from 'query-string';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SESSION_STORAGE_REGISTRATION_KEY } from '../../choose-role/form/choose-role.form';
import { checkIsTokenActive } from '../../helpers/helpers';
import { AuthInputField } from '../../parts/auth-input-field/auth-input-field';
import Buttons from '../../parts/buttons/buttons';
import FormHolder from '../../parts/form-holder/form-holder';
import { FormAlert } from '../../parts/form-holder/form-holder.styles';
import { TermsConditions } from '../../parts/terms-conditions/terms-conditions';
import { CONTINUE_AUTH0_URL } from '../founder-form/founder-form';
import { useValidationSchema } from './validation-schema';

type AdvisorFormData = RegisterAdvisorActionPayloadAuth0 & {
  countryOfResidence: CountryCodes | '';
  usState?: USState;
};

const AdvisorForm = () => {
  const [
    firstNameLabel,
    lastNameLabel,
    emailLabel,
    phoneNumberLabel,
    registerError,
    yourDetailsLabel,
    countryOfResidenceLabel,
    usStateLabel,
    advisorLabel,
  ] = useTranslation([
    'register.form.firstName',
    'register.form.lastName',
    'register.advisor.form.email',
    'register.form.phoneNumber',
    'register.error',
    'register.form.header.yourDetails',
    'register.form.countryOfResidence',
    'register.form.us.state',
    'register.advisor',
  ]);

  const history = useHistory();

  const [errorState, setErrorState] = useState<string | null>(null);

  const { token: auth0Token, state } = useQueryParams();

  const {
    mutateAsync: mutation,
    isLoading,
    isSuccess,
  } = useMutation(registerAdvisorAction, {
    onSuccess: (response) => {
      window.location.href = history.createHref({
        pathname: CONTINUE_AUTH0_URL,
        search: stringify({ token: response.data.token, state }),
      });
    },
    onError: (error) => {
      const axiosError = (error as AxiosError).response?.data.error;
      scrollToTop(0, 'smooth');
      setErrorState(axiosError as string);
    },
  });

  const handleSubmit = async (values: RegisterAdvisorActionPayloadAuth0) => {
    const isTokenActive = checkIsTokenActive(auth0Token as string);
    setErrorState(null);

    if (!isTokenActive) {
      sessionStorage.setItem(SESSION_STORAGE_REGISTRATION_KEY, JSON.stringify({ ...values, role: Roles.NED }));
      window.location.href = window.location.origin + history.createHref({ pathname: Links.REGISTER() });
    }

    return mutation({ registrationToken: auth0Token as string, state: state as string, ...values });
  };

  const formik = useFormik<AdvisorFormData>({
    onSubmit: (values) => {
      setErrorState(null);
      const { email, usState, ...submitValues } = values;

      handleSubmit({ ...submitValues, ...(usState ? { usState } : {}) });
    },
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      countryOfResidence: '',
      phoneNumber: '',
      usState: undefined,
    },
    validateOnMount: true,
    validationSchema: useValidationSchema(),
  });

  const handleSelectChange = (selectKey: string) => (value: SelectValue | undefined) => {
    formik.setFieldValue(selectKey, value || null);
  };

  const { data: countriesListResponse, isInitialLoading: isCountriesLoading } = useQuery(
    [GET_COUNTRIES_OPTIONS_DATA_CACHE_KEY],
    getCountriesListAction,
    { refetchOnWindowFocus: false, cacheTime: 3600000 },
  );

  const populateFieldsFromToken = (decodedToken: Record<string, string>) => {
    const fieldMappings = ['email', 'firstName', 'lastName'];

    fieldMappings.forEach((key) => {
      const decodedValue = decodedToken[key];

      if (decodedValue) {
        formik.setFieldValue(key, decodedValue).then(() => formik.validateForm());
      }
    });
  };

  useEffect(() => {
    try {
      if (auth0Token) {
        const decodedToken = jwt.decode(auth0Token as string) as Record<string, string>;
        populateFieldsFromToken(decodedToken);
      }
    } catch {
      history.replace(Routes.LOGIN);
    }
  }, [auth0Token]);

  useEffect(() => {
    const lastSelectedData = sessionStorage.getItem(SESSION_STORAGE_REGISTRATION_KEY);

    if (lastSelectedData) {
      const fieldsToSet = ['phoneNumber', 'firstName', 'lastName', 'countryOfResidence'];
      const parsedData = JSON.parse(lastSelectedData);

      fieldsToSet.forEach((field) => {
        if (parsedData[field]) {
          formik.setFieldValue(field, parsedData[field]);
        }
      });
    }

    if (auth0Token) {
      sessionStorage.removeItem(SESSION_STORAGE_REGISTRATION_KEY);
    }
  }, []);

  if (isCountriesLoading) {
    return (
      <FormHolder>
        <FullHeightSpinner small />
      </FormHolder>
    );
  }

  const countriesList = countriesListResponse?.data?.countries || [];
  const countryOptions: { value: string; label: string }[] = countriesList.map((countryCode) => ({
    value: countryCode,
    label: CountryNames[countryCode] || countryCode,
  }));

  const stateOptions: { value: string; label: string }[] = Object.entries(UsStateNameValue).map(([code, name]) => ({
    value: code,
    label: name,
  }));

  return (
    <>
      <FormHolder onSubmit={formik.handleSubmit} label={yourDetailsLabel}>
        {errorState && <FormAlert message={registerError} description={errorState} type="error" showIcon />}
        <AuthInputField formik={formik} label={emailLabel} field="email" type="email" disabled />
        <AuthInputField formik={formik} required label={firstNameLabel} field="firstName" type="text" />
        <AuthInputField formik={formik} required label={lastNameLabel} field="lastName" type="text" />
        <FormikField
          labelAlignedToCenter
          marginBottom
          registerMarginBottom
          required
          fontWeight="normal"
          label={{
            for: 'countryOfResidence',
            label: countryOfResidenceLabel,
          }}
          touched={formik.touched.countryOfResidence}
          error={formik.errors.countryOfResidence}
        >
          <CountrySelect
            id="countryOfResidence"
            options={countryOptions}
            onChange={(v) => {
              handleSelectChange('usState')(undefined);
              handleSelectChange('countryOfResidence')(v);
            }}
          />
        </FormikField>

        {formik.values.countryOfResidence === CountryCodes.US ? (
          <FormikField
            labelAlignedToCenter
            marginBottom
            registerMarginBottom
            required
            fontWeight="normal"
            label={{
              for: 'usState',
              label: usStateLabel,
            }}
            touched={formik.touched.usState}
            error={formik.errors.usState}
          >
            <CountrySelect
              id="usState"
              options={stateOptions}
              showFlag={false}
              onChange={handleSelectChange('usState')}
            />
          </FormikField>
        ) : null}

        <AuthInputField
          formik={formik}
          required
          label={phoneNumberLabel}
          field="phoneNumber"
          type="phone_number"
          countryOfResidence={formik.values.countryOfResidence as CountryCodes}
        />
        <Buttons
          disabled={isLoading || !formik.isValid || isSuccess}
          data-testid="submit-btn"
          htmlType="submit"
          selectedRole={advisorLabel}
        />
        <TermsConditions href={termsAndConditionsLinkNed} />
      </FormHolder>
      <V2SignOutButton />
    </>
  );
};

export default AdvisorForm;

import { buildQueryParamTypes, buildQueryParams } from '@/utils/fns/graphql-query-builder';
import type { Sorter } from '@/utils/type-definitions/filters';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';
import type { FounderPendingConnection } from '../types';

export const GET_FOUNDER_INVITES_QUERY_KEY = 'get-founder-pending-connections-query';

type ResponseData = {
  total: number;
  items: FounderPendingConnection[];
};

type QueryConfig = {
  page?: number;
  perPage?: number;
  sort?: Sorter;
};

export const getFounderInvites = async ({
  page = 1,
  perPage = 6,
  sort,
}: QueryConfig): Promise<GraphqlResponse<ResponseData>> => {
  const variablesType = {
    sort: '[SortType!]',
    start: 'Int!',
    limit: 'Int!',
  };

  const variables = {
    sort,
    start: (page - 1) * perPage,
    limit: page * perPage,
  };

  const { data } = await axiosInstance.post('/query', {
    variables,
    query: `
     query getFounderInvites (${buildQueryParamTypes(variablesType)}) {
      getFounderPendingConnections (${buildQueryParams(variablesType)}) {
           total
           items {
            participantId
            connectionId
            name
            avatarUrl
            description
            location
            industry
            customerGroup
            investmentStage
            maximumInvestmentAmount
            minimumInvestmentAmount
           }
         }
       }
    `,
  });

  return graphqlResponseWrapper(data, ['getFounderPendingConnections']);
};

import styled from 'styled-components';
import { gradientBorderStyles } from '../parts/aside-item/aside-item.styles';

const ItemWrapper = styled.div`
  background: ${({ theme }) => theme.onboardingColors.asideBackground};
  padding: ${({ theme }) => theme.spacing.medium};
  border-radius: 10px;
  position: relative;
  z-index: 0;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    padding: ${({ theme }) => theme.spacing.small};
  }

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    &:before {
      ${gradientBorderStyles}
    }
  }
`;

const ScoreWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.p`
  margin-top: ${({ theme }) => theme.spacing.xmedium};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  line-height: 1;
  letter-spacing: 0.5px;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.xsmall};
    margin-top: 0;
    margin-right: ${({ theme }) => theme.spacing.medium};
    text-align: left;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin-right: ${({ theme }) => theme.spacing.small};
  }

  @media (max-width: 380px) {
    display: none;
  }
`;

const ScoreRingOuter = styled.div`
  position: relative;
  width: 84px;
  height: 84px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin: 6px;

  &::before {
    content: '';
    position: absolute;
    top: -6px;
    right: -6px;
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.baseColors.Blue300};
    height: 96px;
    width: 96px;
    border-radius: 100%;
    opacity: 0.2;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    width: 56px;
    height: 56px;

    &::before {
      height: 64px;
      width: 64px;
      top: -4px;
      right: -4px;
    }
  }
`;

const ScoreRingColor = styled.div<{ active: boolean }>`
  position: absolute;
  width: 84px;
  height: 84px;
  padding: 4px;
  border-radius: 100%;
  transition: opacity ease-in-out 500ms;

  opacity: ${({ active }) => (active ? 1 : 0)};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    width: 56px;
    height: 56px;
  }
`;

const ScoreRingColorError = styled(ScoreRingColor)`
  background: ${({ theme }) => theme.gradient.red};
`;

const ScoreRingColorWarning = styled(ScoreRingColor)`
  background: ${({ theme }) => theme.gradient.yellow};
`;

const ScoreRingColorSuccess = styled(ScoreRingColor)`
  background: ${({ theme }) => theme.gradient.green};
`;

const ScoreRingInner = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.background};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;

  font-size: 36px;
  line-height: 1;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    font-size: 24px;
  }
`;

export default {
  ItemWrapper,
  Title,
  ScoreWrapper,
  ScoreRingOuter,
  ScoreRingInner,
  ScoreRingColorError,
  ScoreRingColorWarning,
  ScoreRingColorSuccess,
};

import styled from 'styled-components';

const Wrapper = styled('form')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto 0 auto;
  gap: 48px;

  @media (${({ theme }) => theme.v2.breakpoints.sm}) {
    min-height: 404px;
  }
`;

const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 8px;
  width: 100%;
  margin-top: 32px;

  @media (${({ theme }) => theme.v2.breakpoints.sm}) {
    min-height: 404px;
    gap: 16px;
    flex-direction: row;
    margin-top: 75px;
    margin-bottom: 40px;
  }
`;

const ButtonSelectWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  max-width: 440px;
`;

export default {
  Wrapper,
  ButtonWrapper,
  ButtonSelectWrapper,
};

import message from '@/parts/message/message';
import { getServerError } from '@/utils/fns/get-server-error';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { patchConnectionNoteAction } from '../api/patch-connection-note.action';

export const FOUNDER_CONNECTION_NOTE_KEY = 'founder-connection-note';

export const useUpdateConnectionNote = ({
  onSuccessCb,
  onErrorCb,
}: {
  onSuccessCb?: () => void | Promise<unknown>;
  onErrorCb?: () => void | Promise<unknown>;
} = {}) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: patchConnectionNoteAction,
    onSuccess: () => {
      queryClient.invalidateQueries([FOUNDER_CONNECTION_NOTE_KEY]);
      message.success({ content: 'Changes in the note has been saved' });
      onSuccessCb?.();
    },
    onError: (error: AxiosError) => {
      message.error({ content: getServerError(error) });
      onErrorCb?.();
    },
  });

  return {
    isUpdateConnectionNoteLoading: mutation.isLoading,
    isUpdateConnectionNoteError: mutation.isError,
    updateConnectionNoteMutation: mutation.mutateAsync,
    rawMutation: {
      ...mutation,
    },
  };
};

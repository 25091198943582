import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import type { GraphqlResponse } from 'src/types';

export interface IGetNedBrowseDefaults {
  smartMatch: {
    industries: string[];
  };
  personalDetails: {
    countryOfResidence: CountryCodes;
  };
}

export const GET_NED_BROWSE_DEFAULTS_CACHE_KEY = 'get-ned-browse-defaults';

export const getNedBrowseDefaultsAction = async (): Promise<GraphqlResponse<IGetNedBrowseDefaults>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getNedBrowseDefaults {
        smartMatch: getNedSmartMatch {
          industries
        }
        personalDetails: getNedPersonalDetails {
          countryOfResidence
        }
      }
  `,
  });

  return graphqlResponseWrapper(data, ['smartMatch', 'personalDetails']);
};

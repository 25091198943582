import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export interface PendingFollowInvitationsItems {
  id: string;
  logoUrl: string;
  name: string;
  industry: string[];
  customerGroup: string[];
  productStage: string;
  investmentStage: string;
  about: string;
  founderId: string;
}
export interface GetInvestorInvites {
  items: PendingFollowInvitationsItems[];
  total: number;
}

export const GET_INVESTOR_CONNECTION_REQUEST_CACHE_KEY = 'get-investor-invites';

export const getInvestorConnectionRequest =
  (start: number, limit: number) => async (): Promise<GraphqlResponse<GetInvestorInvites>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
      query getInvestorConnectionRequest {
        getPendingFollowInvitations(start: ${start}, limit: ${limit}) {
            items {
                id
                logoUrl
                name
                industry
                customerGroup
                productStage
                investmentStage
                about
                founderId
            }
          total
      }
    }`,
    });

    return graphqlResponseWrapper(data, ['getPendingFollowInvitations']);
  };

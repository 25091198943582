import styled from 'styled-components';

const BulletList = styled.ul`
  list-style: disc;
  list-style-position: inside;
`;

const ListsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.small} auto 0;

  > ${BulletList} {
    width: 30%;
    min-width: 180px;
    margin: 0 ${({ theme }) => theme.spacing.small};
  }
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  max-width: 600px;
  margin: auto;
  margin-bottom: 60px;
  margin-top: -${({ theme }) => theme.spacing.base};
`;

export default { ListsWrapper, Image, BulletList };

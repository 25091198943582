import { Arrow as ArrowBase } from '@parts/arrow/arrow';
import { Col as ColBase, Collapse as CollapseBase } from 'antd';
import styled from 'styled-components';

const Arrow = styled(ArrowBase)`
  margin-left: ${({ theme }) => theme.spacing.small};
`;

const Collapse = styled(CollapseBase)`
  background-color: ${({ theme }) => theme.color.sectionBackground};
  margin: 0 0 ${({ theme }) => theme.spacing.xmedium} 0;
  border: 1px solid ${({ theme }) => theme.color.border};

  .ant-collapse-item {
    padding: ${({ theme }) => theme.spacing.small};
    border: 0;
  }

  .ant-collapse-header[aria-expanded='true'] {
    margin-bottom: ${({ theme }) => theme.spacing.xbase};

    ${Arrow} {
      transform: rotate(180deg);
    }
  }
`;

const Col = styled(ColBase)`
  padding: 0 !important;
`;

const Form = styled.form`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    padding-right: ${({ theme }) => theme.spacing.xmedium};
  }
`;

export default { Arrow, Collapse, Col, Form };

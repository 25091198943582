import { Link as LinkBased } from 'react-router-dom';
import styled, { css } from 'styled-components';
import type { FontSize, Size } from './avatar-with-name';

const AvatarHolder = styled.div<{ avatarSize: Size }>`
  align-items: center;
  display: flex;
  font-size: ${({ theme }) => theme.fontWeight.normal};
  font-weight: ${({ theme }) => theme.fontWeight.normal};

  > div {
    margin-right: 5px;

    ${({ avatarSize }) =>
      avatarSize === 'medium' &&
      css`
        margin-right: 10px;
      `};
  }
`;

const Text = styled.p<{ fontSize: FontSize; limitWidth: boolean; smallerWidthLimit?: boolean }>`
  color: ${({ theme }) => theme.color.fontPrimary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ limitWidth, smallerWidthLimit }) =>
    limitWidth &&
    css`
      max-width: ${smallerWidthLimit ? '220px' : '300px'};
      word-break: break-all;

      @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
        white-space: normal;
        max-width: unset;
      }
    `};

  ${({ fontSize }) =>
    fontSize === 'xsmall' &&
    css`
      font-size: ${({ theme }) => theme.fontSize[fontSize]};
      font-weight: ${({ theme }) => theme.fontWeight.semibold};
    `};

  ${({ fontSize }) =>
    fontSize === 'medium' &&
    css`
      font-size: ${({ theme }) => theme.fontSize[fontSize]};
      font-weight: ${({ theme }) => theme.fontWeight.semibold};
      margin-left: 5px;
    `};
`;

const Link = styled(LinkBased)`
  text-decoration: none;
`;

export default { AvatarHolder, Text, Link };

import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './archived-label.styles';

export const Archived = () => {
  const [archivedLabel] = useTranslation(['shareholders.table.archived']);

  return (
    <S.Wrapper>
      <S.Text>{archivedLabel}</S.Text>
    </S.Wrapper>
  );
};

import styled, { css } from 'styled-components';

import EditBase from '@assets/icons/edit-icon.svg?react';
import TrashBase from '@assets/icons/trash-icon.svg?react';
import TriggerActive from '@assets/icons/trigger/trigger-active.svg?react';
import TriggerAdd from '@assets/icons/trigger/trigger-add.svg?react';
import TriggerMet from '@assets/icons/trigger/trigger-met.svg?react';
import TriggerMute from '@assets/icons/trigger/trigger-mute.svg?react';

const icon = css`
  width: 19px;

  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
`;

const menuIcon = css`
  margin-right: ${({ theme }) => theme.spacing.small};
`;

const dropdownIcon = css`
  width: 25px;
  margin-left: -${({ theme }) => theme.spacing.xsmall};
  margin-right: ${({ theme }) => theme.spacing.small};
`;

const Radar = styled(TriggerAdd)`
  ${icon}
`;

const Edit = styled(EditBase)`
  ${icon}
  ${menuIcon};
`;

const Add = styled(TriggerAdd)`
  ${icon}
  ${menuIcon};
`;

const Mute = styled(TriggerMute)`
  ${icon}
  ${dropdownIcon}
`;

const Active = styled(TriggerActive)`
  ${icon}
  ${dropdownIcon}
`;

const Trash = styled(TrashBase)`
  ${icon};
  ${menuIcon};
`;

const Item = styled.div`
  flex: 100%;
  display: flex;
  align-items: center;
`;

export default {
  Radar,
  Item,
  Trash,
  Edit,
  Add,
  Mute,
  Active,
  TriggerActive,
  TriggerAdd,
  TriggerMute,
  TriggerMet,
};

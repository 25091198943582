import type { TConnectionStatus } from '@/modules/founder/advisor-search/types';
import type { TargetGeographyValue } from '@constants/target-geography';
import type { Compensation } from '@pages/content/profile/ned/ned-profile.page';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import type { RawDraftContentState } from 'draft-js';
import type { GraphqlResponse } from 'src/types';

export interface Experience {
  company: {
    id: string | null;
    name: string;
    avatarUrl: string | null;
    industry: string[];
    isExternal: boolean;
  };
  role: string;
  startDate: Date;
  endDate: Date | null;
  isOngoing: boolean;
  skillSet: string[];
  experienceSummary: string | null;
}

export interface NedPublicProfileData {
  countryOfResidence: CountryCodes | null;
  linkedinUrl: string | null;
  twitterUrl: string | null;
  aboutMe: RawDraftContentState | null;
  myExpectations: RawDraftContentState | null;
  myOffering: RawDraftContentState | null;
  name: string;
  profilePicture: {
    path: string;
    url: string;
  };
  resume: {
    name: string;
    path: string;
    url: string;
  } | null;
  industries: string[];
  primarySkillSet: string | null;
  secondarySkillSet: string[];
  isAdvisor: boolean;
  isNed: boolean;
  isFractional: boolean;
  isInvestorAdvisor: boolean;
  isBookmarked: boolean;
  connection: TConnectionStatus | null;
  experience: Experience[];
  compensations: Compensation[];
  targetGeography: TargetGeographyValue[] | null;
}

export const GET_NED_PUBLIC_PAGE_DETAILS_CACHE_KEY = 'get-ned-public-page-details';

export const getNedPublicProfileDetailsAction =
  (nedId: string) => async (): Promise<GraphqlResponse<NedPublicProfileData>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
      query getNedPublicProfileDetails {
        getNedPublicData(nedId:"${nedId}"){
          name,
          countryOfResidence,
          profilePicture {
            path,
            url,
          },
          linkedinUrl,
          twitterUrl,
          aboutMe,
          myExpectations,
          myOffering,
          industries,
          primarySkillSet,
          secondarySkillSet,
          isAdvisor,
          isNed,
          isFractional,
          isBookmarked,
          isInvestorAdvisor,
          connection { id, status, initiator },
          compensations {
            role,
            compensationType,
            minDayRate,
            maxDayRate,
          },
          targetGeography
          resume {
            name,
            path,
            url,
          },
          experience {
            role,
            startDate,
            endDate,
            isOngoing,
            skillSet,
            experienceSummary,
            company {
              id,
              name,
              avatarUrl,
              industry,
              isExternal,
            }
          },
        }
      }
    `,
    });

    return graphqlResponseWrapper(data, ['getNedPublicData']);
  };

import { Button as BaseButton } from 'antd';
import styled from 'styled-components';

const Button = styled(BaseButton)`
  color: ${({ theme }) => theme.baseColors.Blue200};
  height: 25px !important;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
`;

export default { Button, ButtonWrapper };

import type { ReactNode } from 'react';
import styled from 'styled-components';

const StyledNav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.medium};

  > div {
    margin-left: ${({ theme }) => theme.spacing.medium};
    padding-left: ${({ theme }) => theme.spacing.medium};

    & + div {
      border-left: 1px solid ${({ theme }) => theme.color.border};
    }
  }
`;

interface TopNavProps {
  children?: ReactNode;
}

export const TopNav = ({ children }: TopNavProps) => <StyledNav>{children}</StyledNav>;

import ManagementTeamIcon from '@assets/icons/management-team.svg';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './empty-state.styles';

export const EmptyState = () => {
  const [emptyStateLabel] = useTranslation(['portfolio.investor.managementTeam.emptyState']);

  return (
    <S.Wrapper>
      <img src={ManagementTeamIcon} alt="management-icon" loading="lazy" />
      <S.Title>{emptyStateLabel}</S.Title>
    </S.Wrapper>
  );
};

import styled from 'styled-components';

const Wrapper = styled.div``;

const Image = styled.img<{ width: string | number; height: string | number }>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

export default { Image, Wrapper };

import { Checkbox as CheckboxBase } from 'antd';
import styled, { css } from 'styled-components';

const Checkbox = styled(CheckboxBase)<{ round?: boolean }>`
  .ant-checkbox-inner {
    width: 25px;
    height: 25px;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner::after {
      transform: rotate(45deg) scale(1.5) translate(-22%, -50%);
    }
  }

  ${({ round }) =>
    round &&
    css`
      .ant-checkbox-checked::after {
        border-radius: 50%;
      }

      .ant-checkbox-inner {
        border-radius: 50%;
      }
    `}
`;

const CheckboxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.xmedium};

  > ${Checkbox} {
    color: ${({ theme }) => theme.color.fontPrimary};
    margin: 0 0 ${({ theme }) => theme.spacing.xmedium} 0;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default { Checkbox, CheckboxGroup };

import { Routes } from '@/router/routes';
import { Links } from '@router/links';
import { storeErrorNoticeInLocalStorage } from '@utils/honeybadger/utils/local-storage-error-reporter';
import { createGraphQlErrorNotice } from '@utils/honeybadger/utils/notifications';
import type { Any, GraphQlErrorResponse, GraphqlResponse } from 'src/types';

const INTERNAL_SERVER_ERROR_NAME = 'INTERNAL_SERVER_ERROR';
const debugEnvironments = ['local', 'development', 'feature-preview', 'staging'];

type Rename = [string, string];

const handleErrorRedirection = (response: GraphQlErrorResponse): void => {
  const messagesList = [
    'Unauthorized Error has occurred.',
    'Access denied! You need to be authorized to perform this action!',
    'Founder and Ned accounts must be connected or Both founder and Ned accounts must be published or Advisor must apply for your role before conversation.',
  ];

  const isInternalServerErrorPage = window.location.href.includes(Links.INTERNAL_SERVER_ERROR());
  const isDebugMode = window.localStorage.getItem('debug') === 'true';
  const isEnvironmentForDebug = debugEnvironments.includes(process.env.REACT_APP_ENV);
  // TODO: Remove in https://exlabs.atlassian.net/browse/CON-12523
  const isMessagingPage = window.location.href.includes(Routes.MESSAGING);
  if (isInternalServerErrorPage || (isDebugMode && isEnvironmentForDebug)) {
    return;
  }

  if (isMessagingPage && response.errors[0].message === 'Ned account must be active to preview public data.') {
    return;
  }

  const hasGraphQlInternalServerError = response?.errors?.[0]?.extensions?.code === INTERNAL_SERVER_ERROR_NAME;
  if (hasGraphQlInternalServerError && !messagesList.includes(response.errors[0].message)) {
    const notice = createGraphQlErrorNotice(response);
    // TODO: set the second argument to error requestId
    storeErrorNoticeInLocalStorage(notice, notice.name);

    window.location.href = Links.INTERNAL_SERVER_ERROR();
  }
};

export const graphqlResponseWrapper = <T = Any>(response: Any, keys: (Rename | string)[]): GraphqlResponse<T> => {
  const isShallow = keys.length === 1;

  if (response.data && !response.errors) {
    if (isShallow) {
      return { data: response.data[Array.isArray(keys[0]) ? keys[0][0] : keys[0]], errors: null };
    }

    const data: { [key: string]: Any } = {};

    for (const key of keys) {
      if (Array.isArray(key)) {
        data[key[1]] = response.data[key[0]];
      } else {
        data[key] = response.data[key];
      }
    }

    return { data: data as T };
  }

  handleErrorRedirection(response);

  const errorResult = { ...response, data: null };
  return errorResult;
};

import { V2Button } from '@/components/ui/v2-button/v2-button';
import { usePatchFounderAdvisorsQuestions } from '@/modules/founder/hooks/use-patch-founder-advisors-questions';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import type { StepProps } from '../types';

import S from './steps.styles';
import { V2Tooltip } from '@/components/ui/v2-tooltip/v2-tooltip';

export const AdvisorTypeStep = ({ back, next, initialValues }: StepProps) => {
  const { patchFounderAdvisorsQuestionsMutation, isPatchFounderAdvisorsQuestionsLoading } =
    usePatchFounderAdvisorsQuestions(next);

  const formik = useFormik<{ advisorType?: 'Advisor' | 'Fractional' | 'Ned' | 'NotSureYet' | null }>({
    validateOnChange: true,
    validateOnMount: true,
    initialValues: { advisorType: initialValues.advisorType },
    validationSchema: Yup.object({ advisorType: Yup.string().required() }),
    onSubmit: (values) => {
      patchFounderAdvisorsQuestionsMutation({ advisorType: values.advisorType ?? undefined });
    },
  });

  const [
    description,
    title,
    advisor,
    fractional,
    ned,
    notSureYet,
    nextButton,
    backButton,
    advisorTooltip,
    fractionalTooltip,
    nedTooltip,
    notSureYetTooltip,
  ] = useTranslation([
    'advisor-quick-search.step.description',
    'advisor-quick-search.advisory-step.title',
    'advisor-quick-search.advisory-step.advisor',
    'advisor-quick-search.advisory-step.fractional',
    'advisor-quick-search.advisory-step.ned',
    'advisor-quick-search.advisory-step.not-sure-yet',
    'advisors-quick-search.modal-next-button',
    'advisors-quick-search.modal-back-button',
    'advisor-quick-search.advisory-step.advisor.description',
    'advisor-quick-search.advisory-step.fractional.description',
    'advisor-quick-search.advisory-step.ned.description',
    'advisor-quick-search.advisory-step.not-sure-yet.description',
  ]);

  const options = [
    {
      label: advisor,
      value: 'Advisor',
      tooltip: advisorTooltip,
    },
    {
      label: fractional,
      value: 'Fractional',
      tooltip: fractionalTooltip,
    },
    {
      label: ned,
      value: 'Ned',
      tooltip: nedTooltip,
    },
    {
      label: notSureYet,
      value: 'NotSureYet',
      tooltip: notSureYetTooltip,
    },
  ] as const;

  return (
    <S.Wrapper>
      <S.TitleGroupWrapper title={title} subtitle={description} align="center" currentStep={3} countOfSteps={4} />
      <S.QuestionsWrapper>
        <form onSubmit={formik.handleSubmit}>
          <S.ButtonWrapper>
            {options.map((option) => (
              <V2Tooltip tooltipText={option.tooltip} key={option.value} includeIcon={false}>
                <V2Button
                  type="button"
                  variant={formik.values.advisorType === option.value ? 'selected' : 'outlined'}
                  isResponsive
                  onClick={() => formik.setValues({ advisorType: option.value })}
                >
                  {option.label}
                </V2Button>
              </V2Tooltip>
            ))}
          </S.ButtonWrapper>
        </form>
      </S.QuestionsWrapper>

      <S.ButtonWrapper>
        <V2Button
          variant="outlined"
          startIcon="arrow-left"
          onClick={back}
          isResponsive
          size="large"
          disabled={isPatchFounderAdvisorsQuestionsLoading}
        >
          {backButton}
        </V2Button>
        <V2Button
          endIcon="arrow-right"
          onClick={formik.submitForm}
          isResponsive
          size="large"
          disabled={!formik.isValid || isPatchFounderAdvisorsQuestionsLoading}
        >
          {nextButton}
        </V2Button>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

import { useDelayFunction } from '@utils/hooks/use-delay-function/use-delay-fn';
import { useState } from 'react';

export type FieldsRecord = Record<string, number | string | boolean | null | undefined>;

export const useChangedFields = () => {
  const [lastChanged, setLastChanged] = useState<string[]>([]);
  const resetChangedWithDelay = useDelayFunction(() => setLastChanged([]));

  const getChangedFields = (actual: FieldsRecord, initial: FieldsRecord) => {
    type ItemKey = keyof FieldsRecord;
    const changed: ItemKey[] = [];

    Object.keys(actual).forEach((k) => {
      if (actual[k] !== initial[k]) {
        changed.push(k);
      }
    });

    return changed;
  };

  const setChanged = (actual: FieldsRecord, initial: FieldsRecord) => setLastChanged(getChangedFields(actual, initial));
  const resetChanged = () => setLastChanged([]);

  const isChanged = (key: string) => lastChanged.includes(key);

  return {
    setChanged,
    resetChanged,
    resetChangedWithDelay,
    isChanged,
  };
};

import { LINKEDIN_URL_REGEXP, PHONE_NUMBER_REGEXP, TWITTER_URL_REGEXP } from '@utils/fns/reg-exp-validators';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import * as Yup from 'yup';

const FOUNDER_PROFILE_NAME_MAX_LENGTH = 100;

export const profilePhoneNumberValidation = (validPhoneNumberLabel: string, fieldRequired: string) =>
  Yup.string()
    .nullable()
    .test(
      'customPhoneNumber',
      (field) => {
        if (field.value !== null && !field.value.split(' ')[1]) return fieldRequired;
        return validPhoneNumberLabel;
      },
      (value) => {
        if (!value) return true;
        const val = value.split(' ')[1];
        if (!val) return false;
        return val.match(PHONE_NUMBER_REGEXP) !== null;
      },
    );

export const useValidationSchema = () => {
  const [fieldRequired, minLength, maxLength, validPhoneNumber, validLinkedInUrl, validTwitterUrl] = useTranslation([
    'formik.validation.required',
    'formik.validation.min',
    'formik.validation.max',
    'formik.validation.phoneNumber',
    'formik.validation.linkedInUrl',
    'formik.validation.twitterUrl',
  ]);

  return Yup.object({
    email: Yup.string().email(),
    founded: Yup.date().nullable(),
    logoPath: Yup.string().nullable(),
    linkedinUrl: Yup.string().matches(LINKEDIN_URL_REGEXP, validLinkedInUrl).nullable(),
    twitterUrl: Yup.string().matches(TWITTER_URL_REGEXP, validTwitterUrl).nullable(),
    location: Yup.string().min(2, minLength).nullable(),
    businessName: Yup.string().required(fieldRequired).nullable(),
    representativeFirstName: Yup.string()
      .trim()
      .min(2, minLength)
      .max(FOUNDER_PROFILE_NAME_MAX_LENGTH, maxLength)
      .required(fieldRequired),
    representativeLastName: Yup.string().max(FOUNDER_PROFILE_NAME_MAX_LENGTH, maxLength).trim(),
    phoneNumber: profilePhoneNumberValidation(validPhoneNumber, fieldRequired),
  });
};

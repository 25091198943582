import { Button as ButtonBase } from 'antd';
import styled, { css } from 'styled-components';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.base};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-direction: column;
  }
`;

const continueButtonStyles = css<{ disabled?: boolean }>`
  border-radius: ${({ theme }) => theme.radius.medium};
  color: ${({ theme }) => theme.registerColors.continueButtonText};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  background: ${({ theme, disabled }) =>
    disabled ? theme.registerColors.disabledContinueButtonBackground : theme.registerColors.continueButtonBackground};
  padding: ${({ theme }) => `${theme.spacing.xmedium} ${theme.spacing.xsmall}`};
  min-width: 120px;
  border: unset;
`;

const Continue = styled(ButtonBase)<{ disabled?: boolean }>`
  ${continueButtonStyles}
  &:hover,
  &:active,
  &:focus, &[disabled],&[disabled]:hover, &[disabled]:active,&[disabled]:focus {
    ${continueButtonStyles}
  }
`;

const ChangeType = styled(ButtonBase)`
  cursor: pointer;
  color: ${({ theme }) => theme.baseColors.White};
  font-size: ${({ theme }) => theme.fontSize.xsmall};

  :hover {
    color: ${({ theme }) => theme.baseColors.White};
  }

  span {
    text-decoration: underline;
  }
`;

export default { Continue, ButtonsWrapper, ChangeType };

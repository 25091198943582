import type { FunctionComponent } from 'react';
import S from './empty-state.styles';

export const EmptyState = ({
  title,
  subTitle,
  buttonLabel,
  Icon,
  onClick: showDrawer,
}: {
  title: string;
  subTitle: string;
  buttonLabel: string;
  Icon: FunctionComponent;
  onClick: Function;
}) => (
  <S.Wrapper title={title} subTitle={subTitle} Icon={Icon}>
    <S.Button data-testid="empty-state-add-new-investment" type="primary" onClick={() => showDrawer()}>
      {buttonLabel}
    </S.Button>
  </S.Wrapper>
);

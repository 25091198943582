import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';
import { V2Icon } from '../v2-icon/v2-icon';
import type { V2SortOrder } from './v2-sort.types';

type TArrowOpacityMap = Record<V2SortOrder, number>;

const topArrowOpacity: TArrowOpacityMap = {
  asc: 1,
  desc: 0.5,
  none: 0.5,
};

const bottomArrowOpacity: TArrowOpacityMap = {
  asc: 0.5,
  desc: 1,
  none: 0.5,
};

const Icon = styled(V2Icon)<{ variant: V2SortOrder }>`
  margin: 0;
  padding: 0;
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.blue[300])};

  #top-arrow {
    opacity: ${({ variant }) => topArrowOpacity[variant]};
  }

  #bottom-arrow {
    opacity: ${({ variant }) => bottomArrowOpacity[variant]};
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: none;
  background: none;

  &:hover {
    opacity: 0.75;
  }

  &:active {
    opacity: 0.5;
  }
`;

export default { Icon, Button };

import { Radio } from 'antd';
import styled from 'styled-components';

const Label = styled.legend<{ textAlign?: 'center' | 'start' | 'right' | 'inherit' }>`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: ${({ theme }) => theme.spacing.small};
  text-align: ${({ textAlign }) => textAlign ?? 'inherit'};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const ChildWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.medium};
`;

const Wrapper = styled.div<{ justify?: 'center' | 'start' | 'end'; align?: 'center' | 'start' | 'end' }>`
  display: flex;
  justify-content: ${({ justify }) => justify ?? 'center'};
  align-items: ${({ align }) => align ?? 'center'};
  flex-direction: column;

  ${ChildWrapper} {
    width: 100%;
  }
`;

const RadioGroup = styled(Radio.Group)``;

const RadioButton = styled(Radio.Button)`
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: 24px;
  text-align: center;
  background-color: transparent;
  height: auto;
  margin: 0 ${({ theme }) => theme.spacing.xsmall};
  padding: 8px 16px;
  border: 1px solid ${({ theme }) => theme.onboardingColors.buttonBorder};
  border-radius: 20px;

  &.ant-radio-button-wrapper {
    color: ${({ theme }) => theme.baseColors.Blue200};
    border-color: ${({ theme }) => theme.baseColors.Blue200};
    border-radius: ${({ theme }) => theme.spacing.xlarge};
    border-width: 1px;

    &::before {
      display: none;
    }
  }

  &.ant-radio-button-wrapper-disabled {
    background-color: initial;
    &:hover {
      background-color: initial;
    }
  }

  &.ant-radio-button-wrapper-checked.ant-radio-button-wrapper-disabled {
    color: ${({ theme }) => theme.baseColors.White};
    background-color: ${({ theme }) => theme.baseColors.Blue200};

    &:hover {
      background-color: ${({ theme }) => theme.baseColors.Blue200};
    }
  }

  &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: ${({ theme }) => theme.baseColors.White};
    background-color: ${({ theme }) => theme.baseColors.Blue200};
  }
`;

export default {
  Label,
  Wrapper,
  RadioGroup,
  RadioButton,
  ChildWrapper,
};

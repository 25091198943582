import { V2NotificationIndicator } from '@/components/ui/v2-notification-indicator/v2-notification-indicator';
import { Dropdown } from 'antd';
import styled from 'styled-components';

const Trigger = styled(Dropdown.Button)`
  .ant-btn {
    padding: 0;
    border: 0;
    margin: 0;
    box-shadow: none;
    height: 24px !important;
    background: transparent;

    &:after {
      display: none;
    }
    > div {
      margin: 0;
    }
  }

  .anticon.anticon-ellipsis {
    display: none;
  }

  .ant-btn-icon-only {
    position: absolute;
    border: 0px;
    background: transparent;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 9;
    height: 24px !important;
  }
`;

const NotificationIndicator = styled(V2NotificationIndicator)`
  position: absolute;
  top: -4px;
  right: -5px;
`;

export default {
  Trigger,
  NotificationIndicator,
};

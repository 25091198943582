import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const V2ExperienceTile = styled.div`
  border: 1px solid ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[100], theme.v2.colors.blue[500])};
  border-radius: ${({ theme }) => theme.radius.default};
  padding: ${({ theme }) => theme.spacing.medium};
  display: flex;
  gap: 10px;
  position: relative;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Details = styled.div``;

const CompanyName = styled.h3`
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.blue[50])};
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  word-break: break-word;
  max-width: 90%;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    max-width: 75%;
  }
`;

const Role = styled.p`
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[800], theme.v2.colors.neutral[100])};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const DateRange = styled.p`
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[600], theme.v2.colors.neutral[400])};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  margin: 2px 0;
`;

const PillsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const Pill = styled.span<{ $isIndustry?: boolean }>`
  padding: 4px 12px 4px 12px;
  border-radius: ${({ theme }) => theme.radius.default};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  background-color: ${({ theme, $isIndustry }) => ($isIndustry ? theme.v2.colors.blue[100] : theme.v2.colors.red[50])};
  color: ${({ theme, $isIndustry }) => ($isIndustry ? theme.v2.colors.blue[300] : theme.v2.colors.red[300])};
`;

const Description = styled.p`
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[700], theme.v2.colors.neutral[100])};
  font-size: ${({ theme }) => theme.fontSize.small};
  word-break: break-word;
`;

const Actions = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

export default {
  V2ExperienceTile,
  ContentWrapper,
  Details,
  CompanyName,
  Role,
  DateRange,
  PillsRow,
  Pill,
  Description,
  Actions,
};

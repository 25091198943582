import type { Theme } from '@styles/theme-config';
import { Button as AntButton } from 'antd';
import styled, { css } from 'styled-components';

const centerAdjustment = css`
  margin-top: ${({ theme }: { theme: Theme }) => theme.spacing.medium};
  justify-content: center;
  flex: 100%;
`;

const bottomSpacedAdjustment = css`
  margin-bottom: ${({ theme }: { theme: Theme }) => theme.spacing.medium};
`;

const Wrapper = styled.div<{ centered?: boolean; bottomSpaced?: boolean }>`
  display: flex;
  align-items: center;

  ${({ centered }) => centered && centerAdjustment}
  ${({ bottomSpaced }) => bottomSpaced && bottomSpacedAdjustment}
`;

const Button = styled(AntButton)`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border-width: 2px;
  border-color: ${({ theme }) => theme.color.paginationButton};
  color: ${({ theme }) => theme.color.paginationButton};
  background-color: ${({ theme }) => theme.color.sectionBackground};

  &:hover,
  &:active,
  &:focus {
    background-color: ${({ theme }) => theme.color.sectionBackground};
  }

  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:active,
  &:disabled,
  &:disabled:hover {
    background-color: ${({ theme }) => theme.color.sectionBackground};
    opacity: 0.4;
    border-color: ${({ theme }) => theme.color.paginationButton};
    color: ${({ theme }) => theme.color.paginationButton};
  }
`;

const Counter = styled.p`
  margin: 0 ${({ theme }) => theme.spacing.medium};
  color: ${({ theme }) => theme.color.fontPrimary};
`;

export default {
  Wrapper,
  Button,
  Counter,
};

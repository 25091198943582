import { V2AriaTable } from '@/components/blocks/v2-aria-table/v2-aria-table';
import { V2ConnectionType } from '@/components/ui/v2-connection-type/v2-connection-type';
import { V2Tooltip } from '@/components/ui/v2-tooltip/v2-tooltip';
import { TranslationText } from '@/parts/translation-text/translation-text';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import type { AppMessagesKey } from '@/utils/i18n/messages';
import { useHandleCloseConnection } from '../../../hooks/use-handle-close-connection';
import type { FounderConnection, FounderConnectionType } from '../../../types';

type ConnectionTypeCellProps = FounderConnection & { refetch: () => void; 'data-testid': string };
export const ConnectionTypeCell = ({
  connectionId,
  isCloseConnection,
  refetch,
  connectionType,
  'data-testid': dataTestId,
}: ConnectionTypeCellProps) => {
  const { handleCloseConnection, isCloseConnectionLoading } = useHandleCloseConnection(
    connectionId,
    isCloseConnection,
    refetch,
  );

  const handleClick = () => {
    handleCloseConnection();
  };

  const connectionTypeTextId: Record<FounderConnectionType, AppMessagesKey> = {
    Both: 'connections.founder.connectionType.both',
    Investor: 'connections.founder.connectionType.investor',
    Advisor: 'connections.founder.connectionType.advisor',
  };

  const [tooltipText] = useTranslation(['connections.founder.closeConnection.tooltip']);

  return (
    <V2AriaTable.BodyCell>
      <V2Tooltip tooltipText={tooltipText} includeIcon={false} data-testid={dataTestId}>
        <V2ConnectionType
          text={<TranslationText id={connectionTypeTextId[connectionType]} />}
          isClose={isCloseConnection}
          buttonProps={{ onClick: handleClick, disabled: isCloseConnectionLoading }}
        />
      </V2Tooltip>
    </V2AriaTable.BodyCell>
  );
};

import { SubmitButton } from '@parts/submit-button/submit-button';
import { Button } from 'antd';
import styled from 'styled-components';

const Footer = styled.footer<{ noBorder?: boolean }>`
  display: flex;
  padding: ${({ theme }) => theme.spacing.xmedium} ${({ theme }) => theme.spacing.medium};
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${({ theme, noBorder }) => (noBorder ? 'transparent' : theme.color.border)};
  gap: ${({ theme }) => theme.spacing.small};
  width: 100%;

  > div {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.small};

    > button {
      padding-right: ${({ theme }) => theme.spacing.small};
      padding-left: ${({ theme }) => theme.spacing.small};
    }
  }
`;

const MessageButton = styled(Button)`
  padding: 0;
  margin: 0;
  line-height: 1;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.xxsmall} 0 !important;
`;

const ConnectedBtn = styled(SubmitButton)`
  padding: ${({ theme }) => theme.spacing.small};
  background-color: ${({ theme }) => theme.color.connectedAdvisorButtonBackground};
  border-color: ${({ theme }) => theme.color.connectedAdvisorButtonBackground};
  color: ${({ theme }) => theme.baseColors.Grey200};
  pointer-events: none;
`;

export default { Footer, MessageButton, ConnectedBtn };

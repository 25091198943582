import type { ReactNode } from 'react';
import S from './page-header.styles';

interface IPageHeader {
  title: ReactNode;
  subTitle?: ReactNode;
  onBack?: () => void;
}

export const PageHeader = ({ title, subTitle, onBack }: IPageHeader) => (
  <S.PageHeader title={title} subTitle={subTitle} onBack={onBack} />
);

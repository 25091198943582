import styled from 'styled-components';

const Section = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  justify-items: center;
  column-gap: 24px;
  row-gap: 24px;
  padding-bottom: 24px;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) {
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  }
`;
export default { Section };

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export interface RoleTitle {
  title: string;
}

export const GET_FOUNDER_ROLE_TITLE_CACHE_KEY = 'get-founder-role-title';

export const getFounderRoleTitleAction = (roleId: string) => async (): Promise<GraphqlResponse<RoleTitle>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getFounderRoleTitle {
        getRoleTitle: previewRoleByOwner(roleId: "${roleId}") {
          title
        }    
      }    
    `,
  });

  return graphqlResponseWrapper(data, ['getRoleTitle']);
};

import type { V2SwitchProps } from './v2-switch.types';

import S from './v2-switch.styles';

export const V2Switch = ({
  checked,
  onChange,
  size = 'md',
  ['data-testid']: dataTestId = 'v2-switch',
  disabled = false,
  ...restAttributes
}: V2SwitchProps) => {
  const sizeMap = {
    sm: 'small',
    md: 'default',
  } as const;

  return (
    <S.V2Switch
      checked={checked}
      onChange={onChange}
      size={sizeMap[size]}
      data-testid={dataTestId}
      disabled={disabled}
      {...restAttributes}
    />
  );
};

import styled from 'styled-components';

const Headline = styled.h1`
  color: ${({ theme }) => theme.color.primary};
  font-size: 30px;
  margin-bottom: 30px;

  > span {
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
  }
`;

const HeadlineWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-direction: column;
  }
`;

export default { Headline, HeadlineWrapper };

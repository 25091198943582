import type { TeamMemberData } from '@pages/content/profile/founder/api/get-founder-team-members/get-founder-team-members.action';
import { FormikField } from '@pages/content/profile/parts/formik-field/formik-field';
import { Select } from '@pages/content/profile/parts/select/select';
import { Tooltip } from '@parts/tooltip/tooltip';
import OnTopFooter from '@parts/on-top-footer/on-top-footer';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Input } from 'antd';
import type { RadioChangeEvent } from 'antd/lib/radio';
import type { SelectValue } from 'antd/lib/select';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import S from './drawer.styles';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { DateFormat } from '@i18n/date-formats';

export const Drawer = ({
  teamMember,
  roleOptions,
  areaOptions,
  onClose,
  onSubmit,
  Drawer: Wrapper,
  disabled,
}: {
  teamMember: TeamMemberData;
  areaOptions: string[];
  roleOptions: string[];
  onClose: () => void;
  onSubmit: (row: TeamMemberData) => void;
  Drawer: Function;
  disabled: boolean;
}) => {
  const [
    headerAddLabel,
    headerEditLabel,
    nameLabel,
    positionLabel,
    areaLabel,
    roleLabel,
    joinedLabel,
    experienceLabel,
    experienceTooltipLabel,
    saveBtnLabel,
    cancelLabel,
    requiredFieldLabel,
    limitMinLabel,
  ] = useTranslation([
    'profile.founder.section.teamMembers.drawer.header.add',
    'profile.founder.section.teamMembers.drawer.header.edit',
    'profile.founder.section.teamMembers.drawer.form.name',
    'profile.founder.section.teamMembers.drawer.form.position',
    'profile.founder.section.teamMembers.drawer.form.area',
    'profile.founder.section.teamMembers.drawer.form.role',
    'profile.founder.section.teamMembers.drawer.form.joined',
    'profile.founder.section.teamMembers.drawer.form.experience',
    'profile.founder.section.teamMembers.drawer.form.experienceTooltip',
    'profile.founder.section.teamMembers.drawer.form.save',
    'profile.founder.section.teamMembers.drawer.form.cancel',
    'formik.validation.required',
    'formik.validation.min',
  ]);

  const {
    deviceData: { isMobile },
  } = useDeviceDetect();

  const { formats } = useUserDateFormat();

  const formik = useFormik({
    initialValues: teamMember,
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().required(requiredFieldLabel).min(3, limitMinLabel),
      position: Yup.string().trim().required(requiredFieldLabel).min(3, limitMinLabel),
      area: Yup.string().trim().nullable(),
      role: Yup.string().trim().nullable(),
      joined: Yup.date().required(requiredFieldLabel),
      experience: Yup.string().trim().required(requiredFieldLabel).min(3, limitMinLabel),
    }),
    onSubmit,
  });

  const handleStartDatePickerChange = (date: Date | null) => {
    formik.setFieldValue('joined', date);
  };

  return (
    <Wrapper
      width={isMobile ? '100%' : '400px'}
      footer={
        <OnTopFooter
          confirmLabel={saveBtnLabel}
          cancelLabel={cancelLabel}
          onConfirm={() => formik.submitForm()}
          onCancel={onClose}
          disabled={!formik.dirty || disabled}
          reverseButtons
          data-testid="team-member-drawer"
        />
      }
      onClose={onClose}
      closable={false}
      data-testid="team-member-drawer"
      title={teamMember?.name ? headerEditLabel : headerAddLabel}
    >
      <FormikField
        label={{
          for: 'name',
          label: nameLabel,
        }}
        error={formik.errors.name}
        touched={formik.touched.name}
      >
        <Input {...formik.getFieldProps('name')} />
      </FormikField>

      <FormikField
        label={{
          for: 'position',
          label: positionLabel,
        }}
        error={formik.errors.position}
        touched={formik.touched.position}
      >
        <Input {...formik.getFieldProps('position')} />
      </FormikField>

      <FormikField
        label={{
          for: 'area',
          label: areaLabel,
        }}
        touched={formik.touched.area}
      >
        <Select
          {...formik.getFieldProps('area')}
          value={formik.values.area || undefined}
          data-testid="area-dropdown"
          onChange={(value: SelectValue) => {
            formik.setFieldValue('area', value || null);
            formik.setFieldTouched('area', true);
          }}
          showSearch
        >
          <Select.Option key="empty" value="">
            {' '}
          </Select.Option>
          {areaOptions.map((option) => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      </FormikField>

      <FormikField
        label={{
          for: 'role',
          label: roleLabel,
        }}
      >
        <S.Radio.Group
          value={formik.values.role}
          onChange={(e: RadioChangeEvent) => {
            formik.setFieldValue('role', e.target.value || null);
            formik.setFieldTouched('role', true);
          }}
        >
          {roleOptions.map((option) => (
            <S.Radio key={option} value={option}>
              {option}
            </S.Radio>
          ))}
        </S.Radio.Group>
      </FormikField>

      <FormikField
        label={{
          for: 'experience',
          label: (
            <span>
              {experienceLabel}
              <Tooltip title={experienceTooltipLabel} />
            </span>
          ),
        }}
        error={formik.errors.experience}
        touched={formik.touched.experience}
      >
        <Input.TextArea rows={3} {...formik.getFieldProps('experience')} />
      </FormikField>

      <FormikField
        label={{
          label: joinedLabel,
          for: 'joined',
        }}
        error={formik.errors.joined}
        touched={formik.touched.joined}
      >
        <S.DatePicker
          {...formik.getFieldProps('joined')}
          onChange={handleStartDatePickerChange}
          allowClear={false}
          inputReadOnly
          value={formik.values.joined ? new Date(formik.values.joined) : null}
          format={formats[DateFormat.FULL_DATE_DASHED]}
        />
      </FormikField>
    </Wrapper>
  );
};

import { theme } from '@styles/theme-config';
import { EditorState, type EditorProps, type RawDraftContentState } from 'draft-js';
import type { CSSProperties } from 'react';
import { LazyEditor } from './lazy-editor';

export const Wysiwyg = ({
  editorState: state,
  onChange,
  onEditorStateChange,
  isEditorActive,
  onFocus,
  onBlur,
  placeholder,
  additionalStyles = null,
  highlight = false,
}: EditorProps & {
  onEditorStateChange: (nextState: EditorState) => void;
  isEditorActive: boolean;
  onChange?: (contentState: RawDraftContentState) => void;
  additionalStyles?: Partial<CSSProperties> | null;
  highlight?: boolean;
}) => (
  <LazyEditor
    stripPastedStyles
    onChange={onChange}
    editorState={state}
    onEditorStateChange={onEditorStateChange}
    toolbarHidden={!isEditorActive}
    onFocus={onFocus}
    onBlur={onBlur}
    toolbar={{
      options: ['link', 'inline', 'list', 'textAlign'],
      inline: { options: ['bold', 'italic', 'underline'] },
      textAlign: {
        inDropdown: true,
        options: ['left', 'center', 'right', 'justify'],
      },
      list: {
        inDropdown: false,
        options: ['ordered', 'unordered'],
      },
      link: {
        inDropdown: false,
        options: ['link'],
        showOpenOptionOnHover: false,
        defaultTargetOption: '_blank',
      },
    }}
    wrapperClassName={highlight ? 'saved' : ''}
    placeholder={placeholder}
    editorStyle={{
      paddingLeft: theme.spacing.small,
      paddingRight: theme.spacing.small,
      wordBreak: 'break-all',
      minHeight: '120px',
      ...additionalStyles,
    }}
  />
);

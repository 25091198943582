import { Button as ButtonBase, Menu as MenuBase } from 'antd';
import styled from 'styled-components';

const Menu = styled(MenuBase)`
  padding: ${({ theme }) => theme.spacing.small};
  border-radius: ${({ theme }) => theme.radius.default};
  border-color: ${({ theme }) => theme.color.border};

  &:hover {
    opacity: 1 !important;
  }

  li:hover {
    opacity: 0.7;
    background-color: ${({ theme }) => theme.color.sectionBackground};
  }

  & > li:last-child {
    margin-bottom: 0;
  }

  p {
    color: ${({ theme }) => theme.color.fontPrimary};
  }
`;

const MenuItem = styled(MenuBase.Item)`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const Wrapper = styled.div``;

const Button = styled(ButtonBase)`
  margin: 0;
  padding: 0;
  margin-left: ${({ theme }) => theme.spacing.xsmall};
  width: auto;

  &:hover {
    background-color: inherit;
  }
`;

export default { Wrapper, Button, Menu, MenuItem };

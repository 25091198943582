import { CountryCodes } from '@utils/type-definitions/iso-to-country-name';

export enum DateFormat {
  FULL_DATE = 'fullDate',
  SHORT_MONTH_FULL_DATE = 'shortMonthFullDate',
  FULL_DATE_TIME = 'fullDateTime',
  FULL_MONTH_LONG_YEAR = 'fullMonthLongYear',
  DIGIT_MONTH_LONG_YEAR = 'digitMonthLongYear',
  LETTER_MONTH_LONG_YEAR = 'letterMonthLongYear',
  LETTER_MONTH_SHORT_YEAR = 'letterMonthShortYear',
  DIGIT_MONTH_SHORT_YEAR = 'digitMonthShortYear',
  FULL_DATE_DASHED = 'fullDateDashed',
}

type DateFormatConfig = {
  [key: string]: {
    [key in DateFormat]: string;
  };
};

export const DATE_FORMATS: DateFormatConfig = {
  [CountryCodes.GB]: {
    [DateFormat.FULL_DATE]: 'dd MMMM yyyy',
    [DateFormat.SHORT_MONTH_FULL_DATE]: 'dd MMM yyyy',
    [DateFormat.FULL_DATE_TIME]: 'd LLL yyyy t',
    [DateFormat.FULL_MONTH_LONG_YEAR]: 'MMMM yyyy',
    [DateFormat.DIGIT_MONTH_LONG_YEAR]: 'MM yyyy',
    [DateFormat.LETTER_MONTH_LONG_YEAR]: 'MMM yyyy',
    [DateFormat.LETTER_MONTH_SHORT_YEAR]: 'MMM !yy',
    [DateFormat.DIGIT_MONTH_SHORT_YEAR]: 'MM !yy',
    [DateFormat.FULL_DATE_DASHED]: 'dd-MM-yyyy',
  },

  [CountryCodes.US]: {
    [DateFormat.FULL_DATE]: 'MMMM dd yyyy',
    [DateFormat.SHORT_MONTH_FULL_DATE]: 'MMM dd yyyy',
    [DateFormat.FULL_DATE_TIME]: 'd LLL yyyy t',
    [DateFormat.FULL_MONTH_LONG_YEAR]: 'MMMM yyyy',
    [DateFormat.DIGIT_MONTH_LONG_YEAR]: 'MM yyyy',
    [DateFormat.LETTER_MONTH_LONG_YEAR]: 'MMM yyyy',
    [DateFormat.LETTER_MONTH_SHORT_YEAR]: 'MMM !yy',
    [DateFormat.DIGIT_MONTH_SHORT_YEAR]: 'MM !yy',
    [DateFormat.FULL_DATE_DASHED]: 'MM-dd-yyyy',
  },
};

import type { V2AdvisorHomepageCardProps } from './v2-advisor-homepage-card.types';

import { V2IconBox } from '@/components/ui/v2-icon-box/v2-icon-box';
import { V2Title } from '@/components/ui/v2-title/v2-title';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import S from './v2-advisor-homepage-card.styles';

const DisabledContent = ({ children }: { children: React.ReactNode }) => {
  const [comingSoonLL] = useTranslation(['default.comingSoon']);

  return (
    <S.DisabledOverlay data-testid="v2-advisor-homepage-card-disabled-content-overlay">
      {children ?? (
        <S.DisabledOverlayInner data-testid="v2-advisor-homepage-card-disabled-content">
          <V2IconBox icon="lock" size="md" variant="neutral" />
          <V2Title title={comingSoonLL} weight="semibold" size="sm" />
        </S.DisabledOverlayInner>
      )}
    </S.DisabledOverlay>
  );
};

const V2AdvisorHomepageCard = ({
  children,
  disabled = false,
  disabledOverlayContent,
  ['data-testid']: dataTestId = 'v2-advisor-homepage-card',
  ...restAttributes
}: V2AdvisorHomepageCardProps) => {
  const disabledOverlay = disabled ? <DisabledContent>{disabledOverlayContent}</DisabledContent> : null;

  return (
    <S.Card data-testid={dataTestId} tabIndex={disabled ? -1 : 0} {...restAttributes}>
      {disabledOverlay}
      {children}
    </S.Card>
  );
};

export { V2AdvisorHomepageCard };

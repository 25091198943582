import {
  GET_FOUNDER_DASHBOARD_CACHE_KEY,
  type Dashboard,
  type MetricMeta,
  type TextBoxMeta,
} from '@pages/content/pulse/parts/dashboards/api/get-founder-dashboard/get-founder-dashboard.action';
import { MetricItem } from '@pages/content/pulse/parts/dashboards/parts/dashboard/metrics/parts/metric-item/metric-item';
import Draggable from '@parts/draggable/draggable';
import { ChartTimePeriod } from '@parts/periods/periods';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateGridConfig } from '../../../api/update-grid-config/update-grid-config';
import { TextBoxElement } from '../textbox-element/textbox-element';

interface DashboardElementsBaseProps {
  dashboardId: string;
  metrics?: MetricMeta[];
  textBoxes?: TextBoxMeta[];
  businessName?: string;
}

interface ReportModeProps {
  reportMode: true;
  onEmptyClick?: never;
  dashboardDetailsQueryKeyCache?: never;
  followMode?: never;
  allowSetNotifications?: never;
  gridConfig: Dashboard['gridConfig'];
}

interface WithoutReportModeProps {
  dashboardDetailsQueryKeyCache?: string | unknown[];
  gridConfig: Dashboard['gridConfig'];
  followMode?: boolean;
  reportMode?: boolean;
  allowSetNotifications?: boolean;
}

type MetricProps = DashboardElementsBaseProps & (ReportModeProps | WithoutReportModeProps);

export const DashboardElements = ({
  metrics = [],
  textBoxes = [],
  dashboardId,
  dashboardDetailsQueryKeyCache,
  gridConfig,
  allowSetNotifications = false,
  followMode = false,
  reportMode = false,
  businessName,
}: MetricProps) => {
  const queryClient = useQueryClient();

  const dashboardDetailsQuery = (
    Array.isArray(dashboardDetailsQueryKeyCache)
      ? [...dashboardDetailsQueryKeyCache, dashboardId]
      : [dashboardDetailsQueryKeyCache, dashboardId]
  ) as string[];

  const { mutateAsync: updateGrid } = useMutation(updateGridConfig(dashboardId), {
    onSuccess() {
      queryClient.invalidateQueries([GET_FOUNDER_DASHBOARD_CACHE_KEY]);
    },
  });

  return (
    <Draggable idKey="id" gridConfig={gridConfig ?? []} updateGrid={updateGrid} publicMode={followMode || reportMode}>
      {[
        ...metrics.map(({ id, chartType, triggerSet, name, type, metricId, isExternal }) => (
          <MetricItem
            key={id}
            id={id}
            metricId={metricId}
            dashboardId={dashboardId}
            title={name}
            type={type}
            triggerSet={triggerSet}
            selectedChartType={chartType}
            selectedPeriod={ChartTimePeriod.MAX}
            isExternal={isExternal}
            allowSetNotifications={allowSetNotifications}
            dashboardDetailsQueryToInvalidate={dashboardDetailsQuery}
            followMode={followMode}
            reportMode={reportMode}
            businessName={businessName}
          />
        )),
        ...textBoxes.map(({ id, title, mainText }) => (
          <TextBoxElement
            id={id}
            key={id}
            dashboardId={dashboardId}
            title={title}
            mainText={mainText}
            isEditingMode={!followMode && !reportMode}
            dashboardDetailsQueryToInvalidate={dashboardDetailsQuery}
          />
        )),
      ]}
    </Draggable>
  );
};

import type { AppAction } from 'src/types';

export const SET_OWNER_DATA = 'metrics/set-owner-data';

export type MetricOwnerData = {
  name: string;
  avatarUrl: string;
};
export interface MetricsState {
  ownerData: MetricOwnerData;
}

export type MetricsActionType = AppAction<typeof SET_OWNER_DATA, { ownerData: MetricOwnerData }>;

export const metricsReducer = (state: MetricsState, action: MetricsActionType): MetricsState => {
  switch (action.type) {
    case SET_OWNER_DATA:
      return {
        ...state,
        ownerData: action.ownerData,
      };

    default:
      return state;
  }
};

import type { FundingHistoryProps } from '@pages/content/api/get-funding-history.action';
import { TableColumn } from '@parts/table-column/table-column';
import { currencyToShortFormat } from '@utils/fns/currency-to-short-format';
import { Tooltip } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import S from './columns.styles';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { DateFormat } from '@i18n/date-formats';

export const Columns = ({
  t,
  compact,
}: {
  t: { [key: string]: string };
  compact: boolean;
}): ColumnsType<FundingHistoryProps> => {
  const { dateFormatter } = useUserDateFormat();

  return [
    {
      title: t.dateLabel,
      dataIndex: 'date',
      key: 'date',
      width: '15%',
      render: (date: string) => (
        <TableColumn title={t.dateLabel}>{dateFormatter(date, DateFormat.SHORT_MONTH_FULL_DATE)}</TableColumn>
      ),
    },

    {
      title: t.typeLabel,
      dataIndex: 'type',
      key: 'type',
      width: '15%',
      render: (type: string) => <TableColumn title={t.typeLabel}>{type}</TableColumn>,
    },
    {
      title: t.soughtLabel,
      dataIndex: 'amountSought',
      key: 'amountSought',
      width: '15%',
      render: (sought: number) => (
        <TableColumn title={t.soughtLabel}>{currencyToShortFormat(sought.toString())}</TableColumn>
      ),
    },
    {
      title: t.raisedLabel,
      dataIndex: 'amountRaised',
      key: 'amountRaised',
      width: '15%',
      render: (raised: number) => (
        <TableColumn title={t.raisedLabel}>{currencyToShortFormat(raised.toString())}</TableColumn>
      ),
    },
    {
      title: t.valuationLabel,
      dataIndex: 'valuation',
      key: 'valuation',
      width: '15%',
      render: (valuation: number) => (
        <TableColumn title={t.valuationLabel}>{currencyToShortFormat(valuation.toString())}</TableColumn>
      ),
    },
    {
      title: t.statusLabel,
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: (status: string) => <TableColumn title={t.statusLabel}>{status}</TableColumn>,
    },
    {
      title: t.useOfFundsLabel,
      dataIndex: 'useOfFunds',
      key: 'useOfFunds',
      width: '200px',
      render: (useOfFunds: string) =>
        compact ? (
          <TableColumn title={t.useOfFundsLabel}>{useOfFunds}</TableColumn>
        ) : (
          <TableColumn>
            <Tooltip title={useOfFunds}>
              <S.UseOfFundsContainer>{useOfFunds}</S.UseOfFundsContainer>
            </Tooltip>
          </TableColumn>
        ),
    },
  ];
};

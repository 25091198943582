import styled from 'styled-components';
import { V2Button } from '@/components/ui/v2-button/v2-button';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.medium};
  align-items: center;
  padding-left: 60px;
  width: 100%;
  border-radius: ${({ theme }) => theme.v2.borders.radius.sm};
  margin-bottom: 20px;

  @media (max-width: ${({ theme }) => theme.v2.breakpoints.md}) {
    padding: 0px;
  }
`;

const TextButton = styled(V2Button)`
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  &:disabled {
    border: none;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 223px;
`;

export default { Row, TextButton, ButtonWrapper };

import type { GetReadinessScoreQueryResponseData } from '@/modules/founder/readiness-score/api/get-readiness-score.query';
import {
  ONBOARDING_PATH_PERFORMANCE_KEY,
  ONBOARDING_STEP_PERFORMANCE_DATA_DRIVEN_NETWORK,
} from '@/pages/content/onboarding/founder/paths';
import { SecureLink } from '@/parts/link/link';
import { Links } from '@/router/links';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { StepContent, type ContentStep } from '../step-content/step-content';

export const MetricsContent = ({ data }: { data: GetReadinessScoreQueryResponseData['metrics'] }) => {
  const [
    titleLL,
    descriptionLL,
    dashboardTitleLL,
    dashboardDescriptionLL,
    manualTitleLL,
    manualDescriptionLL,
    integratedTitleLL,
    integratedDescriptionLL,
  ] = useTranslation([
    'homepage.readinessScore.modal.metrics.title',
    {
      id: 'homepage.readinessScore.modal.metrics.description',
      variables: {
        link: (
          <SecureLink
            href={Links.ONBOARDING_FOUNDER_STEP(
              ONBOARDING_PATH_PERFORMANCE_KEY,
              ONBOARDING_STEP_PERFORMANCE_DATA_DRIVEN_NETWORK,
            )}
          >
            &quot;Metrics & Reporting&quot;
          </SecureLink>
        ),
      },
    },
    'homepage.readinessScore.modal.metrics.step.dashboard.title',
    'homepage.readinessScore.modal.metrics.step.dashboard.description',
    'homepage.readinessScore.modal.metrics.step.manual.title',
    'homepage.readinessScore.modal.metrics.step.manual.description',
    'homepage.readinessScore.modal.metrics.step.integrated.title',
    'homepage.readinessScore.modal.metrics.step.integrated.description',
  ]);

  const { dashboardScore, integratedMetricsScore, manualMetricsScore } = data;
  const steps: ContentStep[] = [
    {
      id: 'manual',
      title: manualTitleLL,
      description: manualDescriptionLL,
      icon: 'graph-up',
      actionUrl: Links.MANAGE_METRIC_METRICS('metrics'),
      received: manualMetricsScore.received,
      available: manualMetricsScore.available,
    },
    {
      id: 'dashboard',
      title: dashboardTitleLL,
      description: dashboardDescriptionLL,
      icon: 'grid',
      actionUrl: Links.PULSE_FOUNDER_DASHBOARDS() + '?new=true',
      received: dashboardScore.received,
      available: dashboardScore.available,
    },
    {
      id: 'integrated',
      title: integratedTitleLL,
      description: integratedDescriptionLL,
      icon: 'graph-up-arrow',
      actionUrl: Links.MANAGE_METRIC_INTEGRATED_METRICS(),
      received: integratedMetricsScore.received,
      available: integratedMetricsScore.available,
    },
  ];

  const score = steps.reduce(
    (acc, step) => {
      acc.received += step.received;
      acc.available += step.available;
      return acc;
    },
    { received: 0, available: 0 },
  );

  return (
    <StepContent
      introTitle={titleLL}
      introDescription={descriptionLL}
      headerIcon="graph-up"
      steps={steps}
      score={score}
    />
  );
};

import Bulb from '@assets/icons/bulb.svg?react';
import styled from 'styled-components';

const Content = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  line-height: 22px;
`;

const WrapperWithTick = styled.div`
  &:before {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid #b01077;
    border-top: 10px solid #b01077;
    border-bottom: 10px solid transparent;
    right: 22px;
    bottom: -19px;
  }

  &:after {
    content: '';
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid ${({ theme }) => theme.onboardingColors.asideBackground};
    border-top: 10px solid ${({ theme }) => theme.onboardingColors.asideBackground};
    border-bottom: 10px solid transparent;
    right: 24px;
    bottom: -13px;
  }
`;

export const BulbIcon = styled(Bulb)`
  position: relative;
  bottom: 9px;
  display: none;

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    display: block;
  }
`;

export { Content, WrapperWithTick };

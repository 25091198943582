import { V2Icon } from '@/components/ui/v2-icon/v2-icon';
import CloseIconBase from '@assets/icons/close-outlined.svg?react';
import EditIconBase from '@assets/icons/edit-icon.svg?react';
import RefreshIconBase from '@assets/icons/refresh.svg?react';
import TrashIconBase from '@assets/icons/trash-icon.svg?react';
import { Button as ButtonBase } from 'antd';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface RoleStatusProps {
  expired?: boolean;
}

const iconFill = css`
  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
`;

const iconStyles = css`
  width: 17px;
  height: 17px;

  ${iconFill}
`;

const largerIconStyles = css`
  height: 19px;
  ${iconFill}
`;

const DetailsIcon = styled(V2Icon)`
  color: ${({ theme }) => theme.color.iconFill};
`;

const EditIcon = styled(EditIconBase)`
  ${largerIconStyles}
  ${iconFill}
`;

const TrashIcon = styled(TrashIconBase)`
  ${iconFill}
`;

const RefreshIcon = styled(RefreshIconBase)`
  ${iconFill}
`;

const CloseIcon = styled(CloseIconBase)`
  ${iconStyles}
`;

const Button = styled(ButtonBase)`
  padding: 0;
  height: 25px !important;
  width: 25px !important;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;

  & > button {
    margin-left: 10px;
  }

  &:first-child {
    margin-left: 0;
  }
`;

const RoleStatusWrapper = styled.section`
  display: flex;
  align-items: center;
`;

const RoleTitle = styled(Link)`
  margin-left: 10px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  color: ${({ theme }) => theme.color.primary};
  text-decoration: none;

  &:hover {
    opacity: 0.8;
    color: ${({ theme }) => theme.color.primary};
  }
`;

const RoleStatus = styled.span<RoleStatusProps>`
  color: ${({ theme, expired = false }) => (expired ? theme.color.fontSecondary : theme.color.fontPrimary)};
`;

const TableContainer = styled.section`
  margin-top: ${({ theme }) => theme.spacing.base};
`;

export default {
  Button,
  ButtonsContainer,
  RoleStatusWrapper,
  RoleTitle,
  RoleStatus,
  TableContainer,
  DetailsIcon,
  RefreshIcon,
  EditIcon,
  CloseIcon,
  TrashIcon,
};

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const style = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: 5px;

  color: ${({ theme }) => theme.baseColors.White};
  background: ${({ theme }) => theme.baseColors.Blue200};
  border-color: ${({ theme }) => theme.baseColors.Blue200};
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  :hover {
    color: ${({ theme }) => theme.baseColors.White};
    background: ${({ theme }) => theme.color.submitButtonHover};
    border-color: ${({ theme }) => theme.color.submitButtonHover};
  }
`;

export const LinkButton = styled(Link)`
  ${style}
`;

export const LinkButtonA = styled.a`
  ${style}
`;

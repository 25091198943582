import RemoveRowIcon from '@assets/icons/delete-icon.svg?react';
import { Button as BaseButton } from 'antd';
import styled from 'styled-components';

const Button = styled(BaseButton)`
  height: 25px !important;
  padding: 0;
`;

const TrashIcon = styled(RemoveRowIcon)`
  width: 25px;
  height: 19px;

  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
`;

export default {
  Button,
  TrashIcon,
};

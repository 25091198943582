import { createContext, useContext } from 'react';

const V2DropdownContext = createContext<{ value: undefined } | null>(null);

export const useV2DropdownContext = () => {
  const context = useContext(V2DropdownContext);
  if (!context) throw new Error('V2Dropdown.* component must be rendered as child of V2Dropdown component');

  return context;
};

export { V2DropdownContext };

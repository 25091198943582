import { useQuery } from '@tanstack/react-query';
import { ADVISOR_PERSONAL_DATA_QUERY, getAdvisorPersonalDetailsQuery } from '../api/get-advisor-personal-details.query';

export const useGetAdvisorPersonalDetails = () => {
  const query = useQuery([ADVISOR_PERSONAL_DATA_QUERY], getAdvisorPersonalDetailsQuery, {
    refetchOnWindowFocus: true,
    cacheTime: 0,
  });

  return {
    advisorPersonalDetailsData: query.data,
    isAdvisorPersonalDetailsLoading: query.isLoading,
    isAdvisorPersonalDetailsFetching: query.isFetching,
    isAdvisorPersonalDetailsFetched: query.isFetched,
    refetchAdvisorPersonalDetails: query.refetch,
    ogQueryResult: query,
  };
};

import { AdvisorRole } from '@/domain/accounts/roles';
import { CompensationType, type Compensation } from '@pages/content/profile/ned/ned-profile.page';
import {
  COMPENSATION_MAX,
  COMPENSATION_MIN,
  CompensationSelector,
} from '@parts/compensation-selector/compensation-selector';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useUserCurrency } from '@utils/hooks/use-user-currency/use-user-currency';
import type { FieldConfig, FieldInputProps, FormikErrors } from 'formik';
import type { CompensationFilterType, FormikFiltersData } from '../filters';

import S from './compensation-filter.styles';

type SetFieldValue = (
  field: string,
  value: CompensationFilterType | null,
) => Promise<void | FormikErrors<FormikFiltersData>>;

type GetFieldProps = (
  nameOrOptions: string | FieldConfig<CompensationFilterType>,
) => FieldInputProps<CompensationFilterType>;

interface CompensationFilterProps {
  advisorRole: AdvisorRole;
  setFieldValue: SetFieldValue;
  getFieldProps: GetFieldProps;
}

export const CompensationFilter = ({ setFieldValue, getFieldProps, advisorRole }: CompensationFilterProps) => {
  const { currencySymbol } = useUserCurrency();
  const [title] = useTranslation(['advisors.search.filters.compensationType.title']);
  const fieldValue = getFieldProps('compensation').value;

  const onChange = (value: Compensation[]) => {
    const [isBlended, isEquity, isPaid] = [
      CompensationType.Blended,
      CompensationType.Equity,
      CompensationType.Paid,
    ].map((type) => value.some((c) => c.compensationType === type));
    const paidCompensation = value.find((c) => c.compensationType === CompensationType.Paid);

    setFieldValue('compensation', {
      isBlended,
      isEquity,
      isPaid,
      paidRates: {
        minDayRate: paidCompensation ? paidCompensation?.minDayRate ?? COMPENSATION_MIN : 0,
        maxDayRate: paidCompensation ? paidCompensation?.maxDayRate ?? COMPENSATION_MAX : 0,
      },
    });
  };

  const buildCompensationsFromFilter = (): Compensation[] => {
    const createCompensation = (
      type: CompensationType,
      minDayRate: null | number,
      maxDayRate: null | number,
    ): Compensation => ({
      role: advisorRole,
      compensationType: type,
      minDayRate,
      maxDayRate,
    });

    const result: Compensation[] = [];
    if (fieldValue.isBlended) result.push(createCompensation(CompensationType.Blended, null, null));
    if (fieldValue.isEquity) result.push(createCompensation(CompensationType.Equity, null, null));
    if (fieldValue.isPaid) {
      result.push(
        createCompensation(CompensationType.Paid, fieldValue.paidRates.minDayRate, fieldValue.paidRates.maxDayRate),
      );
    }

    return result;
  };

  return (
    <S.Wrapper data-testid="compensation-filter">
      <S.FieldLabel>{title}</S.FieldLabel>
      <CompensationSelector
        role={AdvisorRole.FRACTIONAL}
        compensations={buildCompensationsFromFilter()}
        currencySymbol={currencySymbol}
        onChange={onChange}
      />
    </S.Wrapper>
  );
};

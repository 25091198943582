import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import { Carousel } from 'antd';
import styled from 'styled-components';

const ModalCarousel = styled(Carousel)`
  padding-top: 45px;
  padding-bottom: 1px;

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: auto;
  }

  .slick-slide {
    > div {
      padding: 0 6px;
      height: 100%;

      @media screen and (min-width: 1140px) {
        padding: 0 8px;
      }

      > div {
        height: 100%;
      }
    }
  }

  .slick-prev,
  .slick-next {
    position: absolute;
    height: unset;
    width: unset;
    left: unset;
    transform: translate(0, 50%);
    color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.neutral[50])};

    &:hover,
    &:focus,
    &:active {
      color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.neutral[50])};
    }

    &.slick-disabled {
      color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[400], theme.v2.colors.blue[400])};
      cursor: not-allowed;

      &:hover,
      &:focus,
      &:active {
        color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[400], theme.v2.colors.blue[400])};
      }
    }
  }

  .slick-prev {
    top: 0;
    right: 55px;
  }

  .slick-next {
    top: 0;
    right: 15px;
  }
`;

const SlidesStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    width: 100%;

    :not(:first-child) {
      margin-top: 12px;
    }
  }
`;

export default { ModalCarousel, SlidesStack };

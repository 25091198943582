import { Arrow as ArrowBase } from '@parts/arrow/arrow';
import { Table as TableBase } from '@parts/table/table';
import { Button as ButtonBase } from 'antd';
import styled from 'styled-components';

const Arrow = styled(ArrowBase)`
  transform-origin: center;
  width: 15px;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    margin-right: ${({ theme }) => theme.spacing.xsmall};
  }
`;

const ArrowRight = styled(Arrow)`
  transform: rotate(-90deg);
`;

const ArrowUp = styled(Arrow)`
  transform: rotate(180deg);
`;

const Wrapper = styled(TableBase)`
  position: relative;

  .ant-table-expanded-row {
    padding-top: 0;

    > td {
      padding: 0 !important;
    }
  }

  .ant-table-cell {
    &.archived {
      position: absolute;
      bottom: 14px;
      right: 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    .ant-table-row {
      display: flex;
      flex-direction: column;
      position: relative;

      .ant-table-row-expand-icon-cell {
        order: 1;
      }
    }
  }
`;

const Trigger = styled(ButtonBase)`
  border: 0;
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  background: transparent !important;
  color: ${({ theme }) => theme.color.fontPrimary}!important;
  width: 50px;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    margin: 0;
    padding: 0;
    width: unset;
  }
`;

const Scope = styled.p`
  display: inline-block;
`;

export default { Arrow, ArrowRight, Wrapper, Trigger, ArrowUp, Scope };

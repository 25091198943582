import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const CuratedFeed = styled.div`
  width: 100%;
  padding: 0;
  margin-bottom: 24px;

  @media (min-width: ${({ theme }) => theme.v2.breakpoints.md}) {
    padding: 16px;
    border: 1px solid
      ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue['100'], theme.v2.colors.blue['500'])};
    border-radius: ${({ theme }) => theme.v2.borders.radius.md};
    background-color: ${({ theme }) =>
      themedStyle(theme.v2.mode, theme.v2.colors.neutral['50'], theme.v2.colors.blue['700'])};
  }

  @media (min-width: ${({ theme }) => theme.v2.breakpoints.lg}) {
    padding: 24px;
  }
`;

const Header = styled.header`
  margin-bottom: 20px;

  > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 15px;

  ${customMediaQuery('grid-template-columns', {
    md: 'repeat(3, 1fr)',
  })}

  ${customMediaQuery('grid-gap', {
    md: '24px',
  })}
`;

export default { CuratedFeed, Header, Grid };

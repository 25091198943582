import CloseOutlined from '@assets/icons/close-outlined.svg?react';
import type { SelectValue } from 'antd/lib/select';
import type { OptionData, OptionGroupData } from 'rc-select/lib/interface';
import { useRef } from 'react';
import type { Any } from 'src/types';
import S from './multiselect-search.styles';

export interface MultiSelectSearchProps {
  id: string;
  placeholder?: string;
  onChange: (value: SelectValue) => void;
  options: string[];
  noTags?: boolean;
  formatter?: (val: string) => string;
  [index: string]: unknown;
  autoClearSearchValue?: boolean;
  onSelect?: (value: unknown, option: OptionData | OptionGroupData) => void;
  onDeselect?: (value: unknown, option: OptionData | OptionGroupData) => void;
}

export const MultiSelectSearch = ({
  id,
  placeholder,
  onChange,
  options,
  noTags,
  formatter,
  autoClearSearchValue = true,
  onSelect,
  onDeselect,
  ...rest
}: MultiSelectSearchProps) => {
  const selectRef = useRef<HTMLElement>(null);

  const tagRender = ({ label, closable, onClose }: Any): JSX.Element =>
    noTags ? (
      <></>
    ) : (
      <S.Tag
        key={`key_${label}`}
        closeIcon={<CloseOutlined />}
        closable={closable}
        onClose={(e) => {
          onClose(e);
          if (selectRef.current) {
            selectRef.current.focus();
          }
        }}
      >
        {label}
      </S.Tag>
    );

  const filterOption = (input: string, option: OptionData | OptionGroupData | undefined) =>
    option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  return (
    <S.Select
      ref={selectRef}
      id={id}
      showArrow
      showSearch
      mode="multiple"
      placeholder={placeholder}
      optionFilterProp="children"
      tagRender={tagRender}
      onChange={onChange}
      autoClearSearchValue={autoClearSearchValue}
      filterOption={filterOption}
      {...rest}
      onSelect={onSelect}
      onDeselect={onDeselect}
    >
      {options.map((option) => (
        <S.Select.Option key={option} value={option}>
          {formatter ? formatter(option) : option}
        </S.Select.Option>
      ))}
    </S.Select>
  );
};

import { setUpBooleanTrigger } from '@pages/content/public-profile/founder/api/set-up-boolean-trigger.actions';
import { useModal } from '@parts/modal/use-modal';
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Field } from '../field/field';
import { Input } from '../input/input';
import { OwnerDataInput } from '../owner-data-input/owner-data-input';

export const SetUpBooleanTrigger = ({
  metricId,
  onClose,
  currentValue,
  handleTriggerSet,
  metricName,
  businessName,
  companyAvatarUrl,
}: {
  metricId: string;
  onClose: () => void;
  currentValue: boolean;
  handleTriggerSet: () => void;
  metricName: string;
  businessName?: string;
  companyAvatarUrl?: string;
}) => {
  const { Modal, open, hide } = useModal(true);

  const [setText, setupText, metricLabel] = useTranslation([
    'metrics.trigger.modal.text',
    'metrics.trigger.modal.title.setup',
    'metrics.trigger.modal.activate',
    'metrics.trigger.modal.cancel',
    'metrics.trigger.metric',
  ]);

  const { mutateAsync: setUpBoolean } = useMutation(setUpBooleanTrigger(metricId), {
    onSettled: () => {
      handleTriggerSet();
    },
  });

  return (
    <Modal
      visible={open}
      closeModal={hide}
      onOk={() => setUpBoolean({ value: !currentValue })}
      onCancel={onClose}
      title={setupText}
    >
      <OwnerDataInput name={businessName || ''} avatarUrl={companyAvatarUrl || ''} />
      <Field
        label={{
          label: metricLabel,
          for: 'metric',
        }}
      >
        <Input id="metric" value={metricName} readOnly />
      </Field>
      <br />
      {setText}
    </Modal>
  );
};

import { FullWidthField } from '@parts/forms/formik-full-field/formik-full-field';
import { HalfWidthField } from '@parts/forms/formik-half-field/formik-half-field';
import { NumberInput } from '@parts/forms/number-input/number-input';
import { Select } from '@parts/select/select';
import { Comparator, NumberComparator } from '@utils/hooks/use-filtering/use-filtering';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { ChangeEvent } from 'react';
import type { SubFilterProps } from '../../filter';
import S from './number-by-condition.styles';

export const NumberByCondition = ({
  getFieldProps,
  getFieldMeta,
  setFieldValue,
  dataKey,
  options,
  keys,
}: SubFilterProps) => {
  const [fromLabel, toLabel, selectConditionLabel, startTypingLabel] = useTranslation([
    'filters.from',
    'filters.to',
    'filters.selectCondition',
    'filters.startTyping',
  ]);

  const comparatorMeta = getFieldMeta(keys.comparator);
  const comparatorProps = getFieldProps(keys.comparator);

  const rangeProps = getFieldProps(keys.range);
  const rangeMeta = getFieldMeta(keys.range);

  const valueProps = getFieldProps(keys.value);
  const valueMeta = getFieldMeta(keys.value);

  const showValue = ![Comparator.Empty, Comparator.NotEmpty].includes(comparatorProps.value);

  const showRange = [NumberComparator.Between, NumberComparator.NotBetween].includes(comparatorProps.value);

  const Field = showRange ? HalfWidthField : FullWidthField;

  const getValueFromEvent = (e: ChangeEvent<HTMLInputElement>) =>
    e.target.value || e.target.value === '0' ? Number(e.target.value) : '';

  return (
    <>
      <FullWidthField
        label={{ label: '', for: `${dataKey} by number condition comparator` }}
        error={comparatorMeta.error}
        touched={comparatorMeta.touched}
      >
        <Select
          {...comparatorProps}
          value={comparatorProps.value}
          placeholder={selectConditionLabel}
          onChange={(v) => {
            if (v === Comparator.Empty || v === Comparator.NotEmpty) {
              setFieldValue(keys.value, 0);
            }

            if (v !== NumberComparator.Between && v !== NumberComparator.NotBetween) {
              setFieldValue(keys.range, 0);
            }

            setFieldValue(keys.key, dataKey);
            setFieldValue(keys.comparator, v);
          }}
        >
          {options}
        </Select>
      </FullWidthField>
      {showValue && (
        <S.NumberValues>
          <Field
            label={{
              label: showRange ? fromLabel : '',
              for: `${dataKey} by number condition value`,
            }}
            error={valueMeta.error}
            touched={valueMeta.touched}
          >
            <NumberInput
              {...valueProps}
              value={valueProps.value}
              placeholder={startTypingLabel}
              onChange={(e) => {
                setFieldValue(keys.value, getValueFromEvent(e));
              }}
            />
          </Field>
          {showRange && (
            <HalfWidthField
              label={{
                label: showRange ? toLabel : '',
                for: `${dataKey} by number condition range`,
              }}
              error={rangeMeta.error}
              touched={rangeMeta.touched}
            >
              <NumberInput
                {...rangeProps}
                value={rangeProps.value}
                placeholder={startTypingLabel}
                onChange={(e) => {
                  setFieldValue(keys.range, getValueFromEvent(e));
                }}
              />
            </HalfWidthField>
          )}
        </S.NumberValues>
      )}
    </>
  );
};

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.small};
  margin-bottom: ${({ theme }) => theme.spacing.xmedium};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-wrap: wrap;
  }
`;

export default { Wrapper };

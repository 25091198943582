import type { AdvisorRole } from '@/domain/accounts/roles';
import {
  GET_FOUNDER_BROWSE_NEDS_CACHE_KEY,
  getFounderBrowseNeds,
  type AdvisorSearchProfile,
} from '@pages/content/lens/api/get-founder-browse-neds';
import { useInfiniteQueryPagination } from '@utils/hooks/use-infinite-query/use-infinite-query';

const ITEMS_PER_PAGE = 9;
const PAGE_PARAM = 'page';

export const useAdvisorsInfiniteQuery = (role: AdvisorRole) => {
  return useInfiniteQueryPagination<AdvisorSearchProfile>({
    pageParam: PAGE_PARAM,
    itemsPerPage: ITEMS_PER_PAGE,
    action: getFounderBrowseNeds(role),
    cacheKey: [GET_FOUNDER_BROWSE_NEDS_CACHE_KEY, role],
    queryConfig: { refetchOnWindowFocus: false, cacheTime: 0 },
  });
};

import { useDotButton } from '../hooks/use-dot-buttons';
import { useV2CarouselContext } from '../v2-carousel-context';

import S from '../v2-carousel.styles';

export const Dots = () => {
  const { emblaApi } = useV2CarouselContext();
  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

  return (
    <S.Dots data-testid="carousel-step-indicator">
      {scrollSnaps.map((snap, index) => (
        <S.Dot $isSelected={index === selectedIndex} key={snap} onClick={() => onDotButtonClick(index)} />
      ))}
    </S.Dots>
  );
};

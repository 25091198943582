import type { AppMessagesKey } from '@i18n/messages';
import { useExtendedWarningModal as useInvestorWarning } from '@parts/investor-risk-warning/parts/extended-warning-modal/use-extended-warning-modal';
import { Links } from '@router/links';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import { useLocation } from 'react-router-dom';
import S from './investor-risk-warning.styles';

export const InvestorRiskWarning = () => {
  const [
    investorWarningLabel,
    learnMoreLabel,
    investorUSWarningLabel,
    usTitleLabel,
    closeButtonLabel,
    readMoreLabel,
    okButtonLabel,
  ] = useTranslation([
    'navigation.investor.investorWarning.text',
    'navigation.investor.investorWarning.modalLink',
    'navigation.investor.investorUSWarning.text',
    'navigation.investor.investorUSWarning.title',
    'global.close',
    'global.readMore',
    'global.ok',
  ]);
  const location = useLocation();
  const {
    helpers: { isFrom },
  } = useUserAccount();

  const isUserFromUS = isFrom(CountryCodes.US);

  const restOfWorldListModal: AppMessagesKey[] = [
    'navigation.investor.investorWarning.modal.bullet1',
    'navigation.investor.investorWarning.modal.bullet2',
    'navigation.investor.investorWarning.modal.bullet3',
  ];

  const usListModal: AppMessagesKey[] = [
    'navigation.investor.investorUSWarning.modal.bullet1',
    'navigation.investor.investorUSWarning.modal.bullet2',
  ];

  const { modal, show: openModal } = useInvestorWarning({
    closeButtonLabel: isUserFromUS ? okButtonLabel : closeButtonLabel,
    mainListItems: isUserFromUS ? usListModal : restOfWorldListModal,
    title: isUserFromUS ? usTitleLabel : undefined,
  });

  const showPageWarningToRestOfWorld = new Set([
    Links.LENS_SMART_MATCH(),
    Links.LENS_BROWSE_COMPANIES(),
    Links.LENS_INVITES(),
    Links.FOUNDER_PUBLIC_PROFILE_DETAILS(),
    Links.FOUNDER_PUBLIC_PROFILE_DASHBOARDS(),
  ]).has(location.pathname);

  const showPageWarningToUs = new Set([
    Links.LENS_SMART_MATCH(),
    Links.LENS_BROWSE_COMPANIES(),
    Links.LENS_INVITES(),
    Links.PULSE_INVESTOR_TRIGGERS(),
    Links.PULSE_INVESTOR_COMPANIES(),
  ]).has(location.pathname);

  const showPageWarning = isUserFromUS ? showPageWarningToUs : showPageWarningToRestOfWorld;

  if (!showPageWarning) return null;

  return (
    <S.InvestorRiskWarning>
      <div>
        {isUserFromUS ? investorUSWarningLabel : investorWarningLabel}
        <S.ModalLink data-testid="investor-warning-modal-opener" onClick={openModal}>
          {isUserFromUS ? readMoreLabel : learnMoreLabel}
        </S.ModalLink>
      </div>
      {modal}
    </S.InvestorRiskWarning>
  );
};

import { Layout } from 'antd';
import styled from 'styled-components';

export const PlainBackground = styled(Layout)`
  height: 100vh;
  width: 100%;
  background: ${({ theme }) => theme.color.background};
  padding: ${({ theme }) => theme.appGlobalMargin.default};
  display: flex;
  align-items: center;
  overflow-y: auto;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    padding: ${({ theme }) => theme.appGlobalMargin.mobile};
  }

  .ant-alert {
    width: 100%;
  }
`;

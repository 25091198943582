import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const FOUNDER_ONBOARDING_QUESTIONS_KEY = 'onboarding/get-founder-qualifying-questions';

export interface FounderQualifyingQuestionsResponseData {
  investmentStage: string;
  monthlyRevenueScale: number | null;
  headcountScale: number | null;
  interestSourcingInvestment: number | null;
  interestFindingAdvisors: number | null;
  interestInvestorReporting: number | null;
}

export const getFounderQualifyingQuestions = async (): Promise<
  GraphqlResponse<FounderQualifyingQuestionsResponseData>
> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getFounderQualifyingQuestions {
        founderQualifyingQuestions {
            investmentStage
            monthlyRevenueScale
            headcountScale
            interestSourcingInvestment
            interestFindingAdvisors
            interestInvestorReporting
          }
        }
    `,
  });

  return graphqlResponseWrapper(data, ['founderQualifyingQuestions']);
};

export const founderQualifyingQuestionsMock = {
  data: {
    founderQualifyingQuestions: {
      investmentStage: 'Pre-Seed',
      monthlyRevenueScale: 100000,
      headcountScale: 10,
      interestSourcingInvestment: 1,
      interestFindingAdvisors: 2,
      interestInvestorReporting: 3,
    },
  },
};

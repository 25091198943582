import { ChartViewType } from '@pages/content/pulse/parts/dashboards/parts/dashboard/metrics/parts/metric-item/parts/chart-metric/parts/chart-view-dropdown/chart-view-dropdown';
import { axiosInstance } from '@utils/axios/axios-instance';

export const updateMetricChartType = ({
  id,
  dashboardId,
  chartType,
}: {
  id: string;
  dashboardId: string;
  chartType: ChartViewType;
}) => axiosInstance.patch(`/pulse/dashboards/${dashboardId}/metrics/${id}/chart-type`, { chartType });

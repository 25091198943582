import { CaretDownOutlined } from '@ant-design/icons';
import { Select as SelectBase } from 'antd';
import styled from 'styled-components';

const SelectWrapper = styled.div`
  color: ${({ theme }) => theme.baseColors.Blue200};
  display: flex;
  align-items: center;

  margin-bottom: ${({ theme }) => theme.spacing.xmedium};
`;

const Select = styled(SelectBase)`
  color: inherit;
  background-color: transparent;
  min-width: 115px;

  .ant-select-selector {
    cursor: pointer !important;
    background-color: transparent !important;
    padding: 0 ${({ theme }) => theme.spacing.xsmall} !important;
  }

  .ant-select-selection-item {
    color: ${({ theme }) => theme.baseColors.Blue200};
    display: flex;
    align-items: center;
  }

  .ant-select-arrow span {
    background-color: transparent;
    color: inherit;
  }

  .ant-select-arrow svg path {
    fill: ${({ theme }) => theme.baseColors.Blue200};
  }
`;

const CaretIcon = styled(CaretDownOutlined)`
  pointer-events: none !important;
`;

export default { SelectWrapper, Select, CaretIcon };

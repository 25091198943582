import S from './corner-info.styles';

export const CornerInfo = ({
  label,
  value,
  className,
  ...rest
}: {
  label: string;
  value: string;
  className?: string;
}) => (
  <S.Wrapper className={className}>
    <S.Label>{label}</S.Label>
    <S.Value {...rest}>{value}</S.Value>
  </S.Wrapper>
);

import { useMutation } from '@tanstack/react-query';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { extendSubscriptionAction } from '../../api/extend-subscription/extend-subscription.action';

import { BecomeEncouragement } from '@pages/content/profile/parts/become-encouragement/become-encouragement';

import { Routes } from '@router/routes';

export const BecomeNED = () => {
  const [headerLine1, headerLine2, description, becomeNedButton] = useTranslation([
    'profile.investor.section.becomeNed.header.line1',
    'profile.investor.section.becomeNed.header.line2',
    'profile.investor.section.becomeNed.description',
    'profile.investor.section.becomeNed.becomeNedButton',
  ]);

  const history = useHistory();

  const { mutateAsync: extendSubscription, isLoading } = useMutation(extendSubscriptionAction, {
    onSuccess: () => {
      history.push(Routes.EXTEND_PRODUCTS);
    },
  });

  return (
    <BecomeEncouragement
      header={
        <>
          {headerLine1}
          <br />
          {headerLine2}
        </>
      }
      onDecision={extendSubscription}
      description={description}
      decisionLoading={isLoading}
      buttonContent={becomeNedButton}
    />
  );
};

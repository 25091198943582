import { css } from 'styled-components';

export const reactGridLayoutStyles = css`
  .react-grid-item.react-grid-placeholder {
    background-color: ${({ theme }) => theme.color.dragShadowBackground};
    border: 2px dashed ${({ theme }) => theme.color.dragShadowBorder};
    border-radius: ${({ theme }) => theme.radius.default};
  }

  .transform-none {
    transform: none !important;
  }
`;

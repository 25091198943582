import type { ProgressStepStatus } from '@pages/content/onboarding/types/progress';

import S from './progress-box.styles';

type DotColor = 'green' | 'red' | 'yellow';

export const getProgressStatusColor = (status: ProgressStepStatus): DotColor => {
  switch (status) {
    case 'Finished':
      return 'green';
    case 'Started':
      return 'yellow';
    case 'Skipped':
      return 'red';
    default:
      return 'green';
  }
};

export const ProgressBox = ({ data }: { data: { label: string; status: ProgressStepStatus }[] }) => (
  <S.ProgressList data-testid="onboarding-progress-box-list">
    {data.map((item) => (
      <S.ProgressItem key={item.label} color={getProgressStatusColor(item.status)}>
        <span>{item.label}</span>
      </S.ProgressItem>
    ))}
  </S.ProgressList>
);

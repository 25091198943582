import {
  GET_ADVISOR_PROFILE_PREVIEW_DATA_KEY,
  getAdvisorProfilePreviewData,
} from '@/modules/founder/advisor-search/api/get-advisor-profile-preview-details';
import { useQuery } from '@tanstack/react-query';

export const useAdvisorPreviewProfileQuery = (id: string | null) => {
  const query = useQuery([GET_ADVISOR_PROFILE_PREVIEW_DATA_KEY, id], getAdvisorProfilePreviewData(id ?? ''), {
    cacheTime: 0,
    refetchOnWindowFocus: false,
    enabled: Boolean(id),
  });

  return {
    ...query,
    advisorPreviewProfileData: query.data,
    isAdvisorPreviewProfileLoading: query.isLoading,
    isAdvisorPreviewProfileError: query.isError,
    isAdvisorPreviewProfileFetching: query.isFetching,
    refetchAdvisorPreviewProfile: query.refetch,
  };
};

import type { FontWeight } from '@styles/theme-config';
import styled, { css } from 'styled-components';

export const StyledLabel = styled.label<{
  labelAlignedToCenter?: boolean;
  required?: boolean;
  fontWeight?: FontWeight;
  marginBottom?: boolean;
}>`
  color: ${({ theme }) => theme.color.fontPrimary};
  font-weight: ${({ theme, fontWeight }) => fontWeight || theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontSize.small};
  display: flex;
  justify-content: ${({ labelAlignedToCenter }) => (labelAlignedToCenter ? 'center' : 'space-between')};
  flex-wrap: wrap;
  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: 6px;
    `};
  text-align: ${({ labelAlignedToCenter }) => (labelAlignedToCenter ? 'center' : 'inherit')};

  ${({ required }) =>
    required &&
    css`
      &:after {
        content: '*';
        margin-left: ${({ theme }) => theme.spacing.xsmall};
        color: ${({ theme }) => theme.registerColors.requiredStar};
      }
    `}
`;

import Description from '@parts/description/description';
import styled, { css } from 'styled-components';

const Wrapper = styled(Description)<{ disabled?: boolean }>`
  --lh: 1.6;
  --lines: 4;
  margin: ${({ theme }) => theme.spacing.medium};
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  position: relative;
  line-height: var(--lh);
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: var(--lines);
  height: calc(1em * var(--lh) * var(--lines));
  font-size: ${({ theme }) => theme.fontSize.regular};
  word-break: break-word;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `};
`;

export default { Wrapper };

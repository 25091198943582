import { V2Modal } from '@/components/ui/v2-modal/v2-modal';

import { EditData } from './edit-data';
import type { INedExperience } from '@/pages/content/profile/ned/ned-profile.page';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';

type ModalProps = {
  isOpen: boolean;
  close: () => void;
  experienceData: INedExperience;
};

export const EditExperienceModal = ({ isOpen, close, experienceData }: ModalProps) => {
  const [title] = useTranslation(['profile.advisor.exp.editExperience']);

  return (
    <V2Modal
      title={title}
      isOpen={isOpen}
      closable={true}
      onCancel={close}
      footer={null}
      size="lg"
      data-testid="edit-experience-modal"
    >
      <EditData experienceData={experienceData} closeModal={close} />
    </V2Modal>
  );
};

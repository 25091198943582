import S from '../v2-aria-table.styles';

type TRowWrapperProps = {
  rowContent: React.ReactElement;
  collapsible: React.ReactElement;
};

export const RowWrapper = ({ rowContent, collapsible }: TRowWrapperProps) => {
  return (
    <S.RowWrapper>
      {rowContent}
      {collapsible}
    </S.RowWrapper>
  );
};

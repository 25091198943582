import { Table as TableBase } from '@parts/table/table';
import styled from 'styled-components';

const Wrapper = styled(TableBase)`
  .disabled {
    pointer-events: all !important;
  }
`;

export default { Wrapper };

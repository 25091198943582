import type { MarketFitQuestionItem } from '@/pages/content/profile/founder/api/get-market-fit-questions/get-market-fit-questions.query';
import { useScrollToElementWithQueryParams } from '@/utils/hooks/use-scroll-to-element/use-scroll-to-element-with-query-params';
import { Section } from '@parts/section/section';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { MarketFitManager } from './market-fit';

export const MarketFitSection = ({ questions }: { questions: MarketFitQuestionItem[] }) => {
  const scrollElementRef = useScrollToElementWithQueryParams<HTMLDivElement>('market-fit');
  const [headerLabel] = useTranslation(['profile.founder.section.marketFit.header']);

  return (
    <Section header={headerLabel} innerRef={scrollElementRef}>
      <MarketFitManager data={questions} />
    </Section>
  );
};

import type { ReactNode } from 'react';
import S from './highliht-wrapper.style';

interface HighlightInputProps {
  highlight?: boolean;
  children: ReactNode;
}

export const HighlightWrapper = ({ highlight = false, children }: HighlightInputProps) => (
  <S.HighlightWrapper>
    <S.HighlightWrapperChildren>{children}</S.HighlightWrapperChildren>
    <S.TickMarker $visible={highlight} data-testid="input-feedback-tick" />
  </S.HighlightWrapper>
);

import type { TeamMemberData } from '@pages/content/profile/founder/api/get-founder-team-members/get-founder-team-members.action';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { ColumnsType } from 'antd/lib/table';
import type { AllOrNone } from 'src/types';
import { Columns } from './columns';
import S from './table.styles';

interface TeamMembersEditProps {
  onEditRow: (id: string) => void;
  onRemoveRow: (row: TeamMemberData) => () => void;
}

interface TeamMembersProps {
  members: TeamMemberData[];
}

type TeamMembersTableProps = TeamMembersProps & AllOrNone<TeamMembersEditProps>;

export const TeamMembersTable = ({ members, onEditRow, onRemoveRow }: TeamMembersTableProps) => {
  const [nameLabel, areaLabel, roleLabel, experienceLabel, joinedLabel] = useTranslation([
    'profile.founder.section.teamMembers.name',
    'profile.founder.section.teamMembers.area',
    'profile.founder.section.teamMembers.role',
    'profile.founder.section.teamMembers.experience',
    'profile.founder.section.teamMembers.joined',
  ]);
  return (
    <S.Table
      data-testid="team-members-table"
      dataSource={members}
      columns={
        Columns({
          onEditRow,
          onRemoveRow,
          translations: {
            name: nameLabel,
            area: areaLabel,
            role: roleLabel,
            experience: experienceLabel,
            joined: joinedLabel,
          },
        }).filter((col) => col.key !== 'id') as ColumnsType<object>
      }
      rowKey="id"
    />
  );
};

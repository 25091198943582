import S from './progress-bar.styles';

export const ProgressBar = ({ currentStep, totalSteps }: { currentStep: number; totalSteps: number }) => {
  const progressBarWidth = (currentStep / totalSteps) * 100;

  return (
    <S.ProgressBarRail>
      <S.ProgressBar $width={progressBarWidth} data-testid="wizard-layout-progress-bar" />
    </S.ProgressBarRail>
  );
};

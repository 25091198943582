import type { ColorThemeMode } from '@/context/theme/app-theme.context';
import { useTheme } from '@/hooks/use-theme';
import { useUserAbility } from '@abilities';
import { AbilityAction, AbilitySubject } from '@abilities/ability/types';
import { GET_MAGIC_LINK, getLegalDocMagicLinkAction } from '@pages/content/legal/api/get-magic-link.action';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './document.styles';

type DocumentBaseProps = {
  image: JSX.Element;
  title: string;
  description: string;
  isComingSoon?: boolean;
  comingSoonLabel: string;
  isBlocked: boolean;
  mode?: ColorThemeMode;
};

type DocumentProps = Omit<DocumentBaseProps, 'comingSoonLabel' | 'isBlocked' | 'mode'> & {
  link: string;
  availableBlocking: boolean;
  dataTestId?: string;
};

const CornerIcon = ({
  isBlocked,
  isComingSoon,
  comingSoonLabel,
  mode,
}: {
  isBlocked: boolean;
  isComingSoon?: boolean;
  comingSoonLabel: string;
  mode?: string;
}) => {
  if (isComingSoon) {
    return <S.CornerLabel data-testid="coming_soon">{comingSoonLabel}</S.CornerLabel>;
  }
  if (isBlocked) {
    return <S.LockIcon data-testid="filled-lock" mode={mode} />;
  }

  return <S.OpenInNew data-testid="open-in-new" />;
};
const DocumentBase = ({
  isComingSoon,
  image,
  title,
  description,
  comingSoonLabel,
  isBlocked,
  mode,
}: DocumentBaseProps) => (
  <>
    <CornerIcon isComingSoon={isComingSoon} comingSoonLabel={comingSoonLabel} isBlocked={isBlocked} mode={mode} />
    <S.Image isBlocked={isBlocked}>{image}</S.Image>
    <S.Line />
    <S.Title isBlocked={isBlocked} isComingSoon={isComingSoon}>
      {title}
    </S.Title>
    <S.Description isBlocked={isBlocked} isComingSoon={isComingSoon}>
      {description}
    </S.Description>
  </>
);

export const Document = ({ title, link, isComingSoon, availableBlocking, dataTestId, ...rest }: DocumentProps) => {
  const userAbility = useUserAbility();
  const { mode } = useTheme();

  const isBlocked = availableBlocking && !userAbility.can(AbilityAction.ACCESS, AbilitySubject.LEGAL_DOCS);

  const { refetch } = useQuery<string>([GET_MAGIC_LINK], () => getLegalDocMagicLinkAction(link), {
    enabled: false,
  });

  const [comingSoonLabel] = useTranslation(['legal.document.comingSoon']);
  return (
    <S.Wrapper data-testid={dataTestId}>
      {isComingSoon ? (
        <DocumentBase
          {...rest}
          mode={mode}
          title={title}
          isComingSoon={isComingSoon}
          comingSoonLabel={comingSoonLabel}
          isBlocked={isBlocked}
        />
      ) : (
        <S.Link
          aria-hidden="true"
          onClick={async () => {
            const magicLink = await refetch();
            window.open(magicLink.data, '_blank', 'noreferrer,noopener');
          }}
          isBlocked={isBlocked}
        >
          <DocumentBase
            {...rest}
            mode={mode}
            title={title}
            isComingSoon={isComingSoon}
            comingSoonLabel={comingSoonLabel}
            isBlocked={isBlocked}
          />
        </S.Link>
      )}
    </S.Wrapper>
  );
};

import type { AdvisorSearchProfile } from '@pages/content/lens/api/get-founder-browse-neds';
import { Business as Industries } from '@parts/meta-config/parts/business/business';
import S from '../../matched-advisor.styles';

export default ({
  industries,
  primarySkillSet,
  secondarySkillSet,
  compensationType,
}: Partial<AdvisorSearchProfile>) => {
  const secondarySkillSetValue = secondarySkillSet ? secondarySkillSet.join(', ') : '';
  const industriesValue = industries ? industries.slice(0, 3).join(', ') : '';

  return [
    {
      icon: <Industries />,
      value: industriesValue || '',
      visible: Boolean(industries),
    },
    {
      icon: <S.PrimarySkillSet />,
      value: primarySkillSet || '',
      visible: Boolean(primarySkillSet),
    },
    {
      icon: <S.SecondarySkillSet />,
      value: secondarySkillSetValue,
      visible: Boolean(secondarySkillSet),
    },
    {
      icon: <S.CompensationType />,
      value: compensationType ? compensationType.join(', ') : '',
      visible: Boolean(compensationType && compensationType.length > 0),
    },
  ];
};

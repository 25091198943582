import message from '@/parts/message/message';
import { getServerError } from '@/utils/fns/get-server-error';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { declineConnection } from '../api/decline-connection.action';

export const useDeclineConnection = (connectionId: string, callback: () => void) => {
  const mutation = useMutation(declineConnection(connectionId), {
    onSuccess: () => {
      callback();
      message.success({ content: 'The connection has been declined' });
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  return {
    isDeclineConnectionLoading: mutation.isLoading,
    declineConnection: mutation.mutate,
    ogMutation: mutation,
  };
};

export enum ConnectionIntent {
  Investing = 'Investing',
  Advising = 'Advising',
  Both = 'Both',
}

export enum NotificationModules {
  Account = 'account',
  Connections = 'connections',
  Metrics = 'metrics',
  Roles = 'roles',
  AdvisorExperience = 'advisor-experience',
}

export enum NotificationTypes {
  Registered = 'registered',
  ConnectionSentToFounder = 'connection-sent-to-founder',
  ConnectionAcceptedByFounder = 'connection-accepted-by-founder',
  ConnectionDeclinedByFounder = 'connection-declined-by-founder',
  ConnectionSentToParticipant = 'connection-sent-to-participant',
  ConnectionAcceptedByParticipant = 'connection-accepted-by-participant',
  ConnectionDeclinedByParticipant = 'connection-declined-by-participant',
  MetricTriggerSet = 'metric-trigger-set',
  MetricTriggerMatched = 'metric-trigger-matched',
  MetricTriggerReactivated = 'metric-trigger-reactivated',
  ApplicationForRoleSent = 'application-for-role-sent',
  ApplicationForRoleApproved = 'application-for-role-approved',
  InternalExperienceAddedByAdvisor = 'internal-experience-added-by-advisor',
}

import type { PublishStatus } from '@pages/content/profile/hooks/use-publish-status-evaluation';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_INVESTOR_PUBLISH_STATUS_CACHE_KEY = 'get-investor-publish-status';

export const getInvestorPublishStatus = async (): Promise<GraphqlResponse<PublishStatus>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getInvestorPublishStatus {
        publishStatus: getInvestorSmartMatch {
          published
          smartMatchShortages
        }
      }
  `,
  });

  return graphqlResponseWrapper(data, ['publishStatus']);
};

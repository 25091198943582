import ConnectIcon from '@assets/icons/investor-onboarding/connect.svg?react';
import InvestIcon from '@assets/icons/investor-onboarding/invest.svg?react';
import MetricsIcon from '@assets/icons/investor-onboarding/metrics.svg?react';
import ProfileIcon from '@assets/icons/investor-onboarding/profile.svg?react';
import TrackIcon from '@assets/icons/investor-onboarding/track.svg?react';
import { TranslationText } from '@parts/translation-text/translation-text';
import { useElementRevealAnimation } from '@utils/hooks/use-element-reveal-animation/use-element-reveal-animation';
import { useListEntryRevealAnimation } from '@utils/hooks/use-list-reveal-animation/use-list-reveal-animation';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { ReactNode } from 'react';
import S from './intro-features.style';

const elements: { title: ReactNode; icon: ReactNode }[] = [
  { title: <TranslationText id="investor-onboarding.intro.features.list.profile" />, icon: <ProfileIcon /> },
  { title: <TranslationText id="investor-onboarding.intro.features.list.metrics" />, icon: <MetricsIcon /> },
  { title: <TranslationText id="investor-onboarding.intro.features.list.connect" />, icon: <ConnectIcon /> },
  { title: <TranslationText id="investor-onboarding.intro.features.list.invest" />, icon: <InvestIcon /> },
  { title: <TranslationText id="investor-onboarding.intro.features.list.track" />, icon: <TrackIcon /> },
];

export const IntroFeatures = () => {
  const [description] = useTranslation(['investor-onboarding.intro.features.description']);
  useListEntryRevealAnimation('list-elements');
  useElementRevealAnimation('description', { duration: 700, delay: 0 });

  return (
    <section data-testid="intro-features">
      <S.Description data-animation-id="description">{description}</S.Description>
      <S.List data-testid="intro-features-list">
        {elements.map(({ title, icon }, idx) => (
          <S.ListElement key={idx} data-testid="intro-features-list-element" data-animation-id="list-elements">
            {icon}
            <S.ListElementTitle>{title}</S.ListElementTitle>
          </S.ListElement>
        ))}
      </S.List>
    </section>
  );
};

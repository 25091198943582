import { Section } from '@parts/section/section';
import styled from 'styled-components';

const SectionReversedOnMobile = styled(Section)`
  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    > .ant-row {
      flex-direction: column-reverse;
    }
  }
`;

export default { SectionReversedOnMobile };

import { moneyFormat } from '@pages/payments/helpers/money-format';
import { useCurrency } from '../use-currency/use-currency';
import useUserAccount from '../use-user-account/use-user-account';

const commaize =
  (commaSymbol: string) =>
  (value: number | string, withoutDecimals = false, scale = 2) =>
    moneyFormat(value, '', commaSymbol, withoutDecimals, scale);

export const useUserCurrency = () => {
  const {
    state: { currency: currencyIsoCode, countryOfResidence },
  } = useUserAccount();

  const { getCurrencySymbol, getDecimalSeparator: getCommaSymbol } = useCurrency();

  const currencySymbol = getCurrencySymbol(currencyIsoCode);
  const commaSymbol = getCommaSymbol(countryOfResidence);

  return {
    currencySymbol,
    currencyIsoCode,
    commaize: commaize(commaSymbol as string),
  };
};

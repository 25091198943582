import React from 'react';
import { useTheme } from 'styled-components';
import { V2Icon } from '../v2-icon/v2-icon';
import type { V2ConnectionTypeProps } from './v2-connection-type.types';

import S from './v2-connection-type.styles';

export const V2ConnectionType: React.FC<V2ConnectionTypeProps> = ({ text, isClose, buttonProps }) => {
  const theme = useTheme();
  const color = isClose ? theme.v2.colors.blue[300] : theme.v2.colors.neutral[400];

  return (
    <S.Wrapper>
      <S.Text>{text}</S.Text>
      <S.Button {...buttonProps}>
        <V2Icon name="connection" size="lg" color={color} />
      </S.Button>
    </S.Wrapper>
  );
};

import { Badge } from '@pages/content/public-profile/parts/badge/badge';
import { countryFlag } from '@pages/content/profile/utils/country-flag';
import { Avatar } from '@parts/avatar/avatar';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { countryNameToIso } from '@utils/type-definitions/country-name-to-iso';
import S from './company-tile.styles';

export interface ICompanyTileProps {
  logoUrl: string | null;
  name: string;
  about?: string | null;
  supplement?: string;
  className?: string;
  url?: string;
  supplementUrl?: string;
  isNew?: boolean;
  isFlagVisible?: boolean;
  disabled?: boolean;
}

const CompanyTile = ({
  logoUrl,
  name,
  about,
  supplement,
  className,
  url,
  supplementUrl,
  isNew = false,
  isFlagVisible = false,
  disabled = false,
}: ICompanyTileProps) => {
  const [badgeNewText] = useTranslation(['profile.badgeNew']);

  const avatar = <Avatar letter={name[0]} avatarUrl={logoUrl} size={40} />;
  const nameLabel = <S.Name $spaceRight={isNew}>{name}</S.Name>;
  const supplementLabel = <S.Supplement>{supplement}</S.Supplement>;
  const badges = <S.Badges>{isNew && <Badge data-testid="badge-new">{badgeNewText}</Badge>}</S.Badges>;

  const countryCode = countryNameToIso(supplement);
  const flag = countryCode ? countryFlag(countryCode) : undefined;

  const supplementToShow = isFlagVisible ? (
    <S.CountryCointaner>
      <S.CountryImage src={flag} alt={`flag of ${supplement}`} loading="lazy" />
      {supplementLabel}
    </S.CountryCointaner>
  ) : (
    supplementLabel
  );

  return (
    <S.Wrapper className={className} disabled={disabled}>
      {badges}
      <S.Header>
        {url ? <S.Link to={url}>{avatar}</S.Link> : avatar}
        <S.Data>
          {url ? <S.Link to={url}>{nameLabel}</S.Link> : nameLabel}
          {supplement && (supplementUrl ? <S.Link to={supplementUrl}>{supplementLabel}</S.Link> : supplementToShow)}
        </S.Data>
      </S.Header>
      {about && <S.Description dangerouslySetInnerHTML={{ __html: about }} />}
    </S.Wrapper>
  );
};

export default CompanyTile;

import * as Yup from 'yup';

export const useValidationSchema = () =>
  Yup.object({
    roleTitle: Yup.string().nullable(),
    location: Yup.array().of(Yup.string()).nullable(),
    skillSet: Yup.array().of(Yup.string()).nullable(),
    roleType: Yup.array().of(Yup.string()).nullable(),
    engagement: Yup.array().of(Yup.string()).nullable(),
    compensation: Yup.array().of(Yup.string()).nullable(),
    companyStage: Yup.array().of(Yup.string()).nullable(),
    onlyNewlyActivated: Yup.boolean().nullable(),
  });

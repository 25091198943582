import type { ReactNode } from 'react';
import S from './title.styles';

const Title = ({
  title,
  currentPage,
  endPage,
  subHeadline,
}: {
  title: string;
  currentPage?: number;
  endPage?: number;
  subHeadline?: ReactNode;
}) => (
  <S.Wrapper>
    {currentPage && endPage ? (
      <span data-testid="page-status">
        {currentPage} / {endPage}
      </span>
    ) : null}
    <S.Title data-testid="title">{title}</S.Title>
    {subHeadline && <S.Subtitle data-testid="sub-text">{subHeadline}</S.Subtitle>}
  </S.Wrapper>
);

export default Title;

import styled from 'styled-components';

const TermsConditions = styled.span`
  font-size: 10px;
  display: block;
  color: white;
  margin-top: ${({ theme }) => theme.spacing.medium};
  text-align: center;
  margin-top: 25px;
`;

export default { TermsConditions };

import { getHighestSubscriptionProduct } from '@domain/accounts/subscription-products';
import { AppRoutes } from '@router/app-routing';
import { AdminRoutes } from '@router/routes';
import { shouldRenderSplash } from '@router/utils/should-render-spash';
import { useGoogleTagManager } from '@utils/hooks/use-google-tag-manager/use-google-tag-manager';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { usePendo } from '@utils/hooks/use-pendo/use-pendo';
import { useHotJar } from '@utils/hooks/use-hotjar/use-hotjar';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { useEffect } from 'react';
import { isActiveFounder } from './abilities/ability/abilities/utils/is-active-founder';
import { isActiveInvestor } from './abilities/ability/abilities/utils/is-active-investor';
import { isActiveNed } from './abilities/ability/abilities/utils/is-active-ned';
import { DebugComponent } from './debug-component';
import { LoginAsUserPage, SplashScreenPage } from './pages';

import './app.less';

export const App = () => {
  const { location } = useHistory();

  usePendo();
  useHotJar();

  const { isAvailable: isGtmAvailable, dataLayer: gtmDataLayer } = useGoogleTagManager();

  const {
    state: {
      status,
      isAuthorized,
      products,
      email,
      userId: id,
      name: fullName,
      businessName: companyName,
      countryOfResidence,
      integrations: {
        stripe: { customerId },
      },
    },
  } = useUserAccount();

  const isAdminLoginAsUser = location.pathname === AdminRoutes.LOGIN_AS_USER;

  const { type: subscriptionType } = getHighestSubscriptionProduct(products) || {};

  useEffect(() => {
    if (process.env.REACT_APP_PENDO_API_KEY && isAuthorized && !isAdminLoginAsUser) {
      pendo.initialize();
      pendo.updateOptions({
        visitor: {
          id,
          email,
          fullName,
          companyName,
          isNed: isActiveNed(products),
          isFounder: isActiveFounder(products),
          isInvestor: isActiveInvestor(products),
          countryofresidence: countryOfResidence,
          subscriptiontype: subscriptionType,
        },
      });
    }
  }, [isAuthorized, fullName, companyName, products, countryOfResidence, email, id]);

  useEffect(() => {
    if (isGtmAvailable && gtmDataLayer && customerId && !isAdminLoginAsUser) {
      gtmDataLayer.push({ event: 'start_pw', pw_customer_id: customerId });
    }
  }, [customerId, gtmDataLayer]);

  if (isAdminLoginAsUser) {
    return <LoginAsUserPage />;
  }

  if (shouldRenderSplash(status, isAuthorized)) {
    return <SplashScreenPage />;
  }

  return (
    <>
      {process.env.REACT_APP_ENV === 'local' && <DebugComponent />}
      <AppRoutes />
    </>
  );
};

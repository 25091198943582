import SuccessIcon from '@assets/icons/investor-onboarding/success.svg?react';
import { contactHelpConnectdEmail } from '@constants/emails';
import { Roles } from '@domain/accounts/roles';
import { Links } from '@router/links';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';

import S from './banner.style';

export const SummaryPageBanner = () => {
  const [title, description, contactText, contactLinkText, returnToProfile] = useTranslation([
    'investor-onboarding.summary.title',
    'investor-onboarding.summary.description',
    'investor-onboarding.summary.contactText',
    'investor-onboarding.summary.contactLinkText',
    'investor-onboarding.summary.nedReturnToProfile',
  ]);

  const {
    state: { userRole },
  } = useUserAccount();

  return (
    <S.Wrapper data-testid="investor-summary-page-banner">
      <SuccessIcon />
      <S.Title data-testid="investor-summary-page-banner-title">{title}</S.Title>
      <S.Description data-testid="investor-summary-page-banner-subtitle">{description}</S.Description>

      <S.Description>
        {contactText}
        <S.SecureLink data-testid="investor-summary-page-banner-link" href={`mailto:${contactHelpConnectdEmail}`} outer>
          {contactLinkText}
        </S.SecureLink>
      </S.Description>

      {userRole === Roles.NED && <S.ProfileReturnLink to={Links.PROFILE()}>{returnToProfile}</S.ProfileReturnLink>}
    </S.Wrapper>
  );
};

import { CloseOutlined } from '@ant-design/icons';
import ModalBase from 'antd/lib/modal/Modal';
import styled from 'styled-components';

const Modal = styled(ModalBase)`
  width: auto !important;

  .ant-modal-body {
    padding: 0;
  }
`;

const Close = styled(CloseOutlined)`
  margin-top: ${({ theme }) => theme.spacing.xmedium};
  color: ${({ theme }) => theme.baseColors.Blue100} !important;
  stroke: ${({ theme }) => theme.baseColors.Blue100} !important;
  stroke-width: 40;

  svg {
    width: 24px;
    height: ${({ theme }) => theme.spacing.medium};
  }
`;

export default { Modal, Close };

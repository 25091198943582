import { createContext, type Dispatch } from 'react';
import type { FundingRoundsActionType, FundingRoundsState } from './funding-rounds.reducer';

export type FundingRoundsStateApi = {
  state: FundingRoundsState;
  dispatch: Dispatch<FundingRoundsActionType>;
};

export const fundingRoundsInitialState: FundingRoundsState = {
  fundingRounds: [],
  loadingFundingRounds: true,
};

export const FundingRoundsContext = createContext<FundingRoundsState | FundingRoundsStateApi>(
  fundingRoundsInitialState,
);

import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import { Collapse } from 'antd';
import styled from 'styled-components';

const SidePanel = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.xmedium};
  width: 300px;
  flex-shrink: 0;
  background: ${({ theme }) => theme.color.sectionBackground};
  border: 1px solid ${({ theme }) => theme.color.border};
  border-left: none;
  border-top-right-radius: ${({ theme }) => theme.radius.default};
  border-bottom-right-radius: ${({ theme }) => theme.radius.default};

  @media (max-width: ${({ theme }) => theme.v2.breakpoints.md}) {
    margin-top: ${({ theme }) => theme.spacing.medium};
    width: 100%;
    border: 1px solid ${({ theme }) => theme.color.border};
    border-radius: ${({ theme }) => theme.radius.default};
    padding-bottom: 20px;
  }
`;

const DropdownWrapper = styled.div`
  position: absolute;
  right: 5px;
  top: 7px;
`;

const ItemWrapper = styled.span<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    path {
      fill: ${({ theme, $disabled }) => ($disabled ? theme.v2.colors.neutral[400] : theme.v2.colors.blue[400])};
    }
  }

  &:hover {
    path {
      fill: ${({ theme, $disabled }) => ($disabled ? theme.v2.colors.neutral[400] : theme.v2.colors.blue[300])};
    }
  }
`;

const Name = styled.p`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  word-break: break-word;
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.neutral[50])};
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
`;

const StyledCollapse = styled(Collapse)`
  color: inherit;
  width: 100%;
  margin-top: 20px;
  overflow-y: scroll;
  overflow-x: hidden;

  .ant-collapse-item {
    border-top: 1px solid ${({ theme }) => theme.color.border};
    padding: 10px 0px;
    border-radius: 0px !important;

    &:last-child {
      border-bottom: 1px solid ${({ theme }) => theme.color.border};
    }

    .ant-collapse-header {
      position: relative;
      display: flex;
      color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.neutral[50])};
      font-size: ${({ theme }) => theme.v2.typography.sizes.sm};
      font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
      padding: 0px;

      ${({ theme }) =>
        customMediaQuery('font-size', {
          sm: theme.v2.typography.sizes.md,
        })}

      // icon
      > div:first-child {
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%) rotate(0);
        transform-origin: center center;
        transition: transform 0.3s ease;
        background-color: ${({ theme }) =>
          themedStyle(theme.v2.mode, theme.v2.colors.blue[100], theme.v2.colors.blue[300])};
        border-radius: 100%;
        width: 18px;
        height: 18px;

        svg {
          width: 12px;
        }
      }

      &[aria-expanded='true'] {
        > div:first-child {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    .ant-collapse-content {
      color: inherit;
      padding-top: 10px;

      > .ant-collapse-content-box {
        padding: 0px;
        font-size: ${({ theme }) => theme.v2.typography.sizes.xs};
        color: inherit;

        ${({ theme }) =>
          customMediaQuery('font-size', {
            sm: theme.v2.typography.sizes.sm,
          })}
      }
    }

    .ant-collapse-arrow {
      padding: 0 0 !important;
    }
  }
`;

const Bio = styled.p`
  line-height: 16px;
  word-break: break-all;
`;

const UnstyledLink = styled.a`
  color: inherit;
  text-decoration: none;

  > button {
    width: 100%;
  }
`;

export default { SidePanel, DropdownWrapper, ItemWrapper, Name, ButtonsWrapper, StyledCollapse, Bio, UnstyledLink };

import { FormAlert as FormAlertBase } from '@parts/forms/error/form-alert/form-alert';
import styled from 'styled-components';

const Form = styled.form`
  position: relative;
  width: 530px;
  max-width: 100%;
  padding: ${({ theme }) => `${theme.spacing.base} ${theme.spacing.xmedium}`};
  border-radius: 0px;
  background-color: ${({ theme }) => theme.registerColors.backgroundForm};

  @media screen and (min-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    height: auto;
    min-height: unset;
    padding: ${({ theme }) => `${theme.spacing.base} ${theme.spacing.large}`};
    border-radius: 8px;
  }
`;

const Label = styled.p`
  margin-bottom: ${({ theme }) => theme.spacing.xmedium};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.xmedium};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.baseColors.White};
`;

export const FormAlert = styled(FormAlertBase)`
  margin: ${({ theme }) => theme.spacing.small} auto ${({ theme }) => theme.spacing.xmedium};
`;

export default { Label, Form };

import styled from 'styled-components';

import { V2Loader } from '@/components/ui/v2-loader/v2-loader';
import { V2ModalMultilevel as V2ModalMultilevelBase } from '@/components/ui/v2-modal-multilevel/v2-modal-multilevel';
import type { MultilevelModalContentElements } from '@/components/ui/v2-modal-multilevel/v2-modal-multilevel.types';
import { useModalMultilevelWithQuery } from '@/hooks/use-modal-multilevel-query';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { ReadinessScoreModalContentBase } from '../components/readiness-score-modal/content/base/base-content';
import { MarketFitContent } from '../components/readiness-score-modal/content/market-fit/market-fit-content';
import { MetricsContent } from '../components/readiness-score-modal/content/metrics/metrics-content';
import { ProfileContent } from '../components/readiness-score-modal/content/profile/profile-content';
import { TeamContent } from '../components/readiness-score-modal/content/team/team-content';
import { useGetReadinessScore } from './use-get-readiness-score-query';

const V2ModalMultilevel = styled(V2ModalMultilevelBase)`
  .ant-modal-body {
    margin-right: -16px;
    margin-left: -16px;
  }
`;

export const useReadinessScoreModal = (id: string) => {
  const [modalTitle] = useTranslation(['homepage.readinessScore.modal.title']);
  const {
    isOpen: isModalOpen,
    close: closeModal,
    openModal,
    setContentKey,
    contentKey,
  } = useModalMultilevelWithQuery({ id });

  const { readinessScoreData, isReadinessScoreLoading, isReadinessScoreFetched, isReadinessScoreError } =
    useGetReadinessScore();

  const getModalContent = (): MultilevelModalContentElements => {
    if (isReadinessScoreLoading || !isReadinessScoreFetched) {
      return {
        base: { title: modalTitle, body: <V2Loader height="300px" /> },
        nested: [],
      };
    }

    if (isReadinessScoreError || !readinessScoreData) {
      return {
        base: { title: modalTitle, body: <p>Failed to load readiness score data</p> },
        nested: [],
      };
    }

    return {
      base: {
        title: modalTitle,
        body: <ReadinessScoreModalContentBase data={readinessScoreData} setContentKey={(key) => setContentKey(key)} />,
      },
      nested: [
        { key: 'profile', body: <ProfileContent data={readinessScoreData.profile} /> },
        { key: 'team', body: <TeamContent data={readinessScoreData.team} /> },
        { key: 'metrics', body: <MetricsContent data={readinessScoreData.metrics} /> },
        { key: 'marketFit', body: <MarketFitContent data={readinessScoreData.marketFit} /> },
      ],
    };
  };

  return {
    openReadinessScoreModal: openModal as (payload?: { key: 'profile' | 'team' | 'metrics' | 'marketFit' }) => void,
    readinessScoreModal: (
      <V2ModalMultilevel
        isOpen={isModalOpen}
        onCancel={closeModal}
        setActiveKey={setContentKey}
        activeKey={contentKey}
        data-testid="readiness-score-modal"
        content={getModalContent()}
      />
    ),
  };
};

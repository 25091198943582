import { useTheme } from '@/hooks/use-theme';
import DarkEmptyGraphImage from '@assets/icons/dark-empty-graph.svg?react';
import LightEmptyGraphImage from '@assets/icons/light-empty-graph.svg?react';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from '../../graph.styles';

export const ComingSoonGraph = ({ title }: { title: string }) => {
  const [comingSoonLabel] = useTranslation(['portfolio.investor.graph.comingSoon']);
  const { mode } = useTheme();

  return (
    <S.Section
      header={title}
      cornerAddition={{ content: <S.CornerLabel mode={mode}>{comingSoonLabel}</S.CornerLabel>, width: 0 }}
    >
      <S.EmptyGraph>{mode === 'light' ? <LightEmptyGraphImage /> : <DarkEmptyGraphImage />}</S.EmptyGraph>
    </S.Section>
  );
};

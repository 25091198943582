import { V2Button } from '@/components/ui/v2-button/v2-button';
import { usePatchFounderAdvisorsQuestions } from '@/modules/founder/hooks/use-patch-founder-advisors-questions';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import TextArea from 'antd/lib/input/TextArea';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import type { StepProps } from '../types';

import S from './steps.styles';

export const HelpStep = ({ back, next, initialValues }: StepProps) => {
  const { patchFounderAdvisorsQuestionsMutation, isPatchFounderAdvisorsQuestionsLoading } =
    usePatchFounderAdvisorsQuestions(next);

  const formik = useFormik<{ help?: string | null }>({
    validateOnChange: true,
    initialValues: { help: initialValues.help },
    validationSchema: Yup.object({ help: Yup.string().optional() }),
    onSubmit: (values) => {
      patchFounderAdvisorsQuestionsMutation({ helpNeeded: values.help || undefined });
    },
  });

  const [title, description, nextButtonLabel, backButtonLabel] = useTranslation([
    'advisor-quick-search.help-step.title',
    'advisor-quick-search.help-step.description',
    'advisors-quick-search.modal-next-button',
    'advisors-quick-search.modal-back-button',
  ]);

  return (
    <S.Wrapper>
      <S.TitleGroupWrapper title={title} subtitle={description} align="center" currentStep={4} countOfSteps={4} />
      <S.QuestionsWrapper>
        <form onSubmit={formik.handleSubmit}>
          <TextArea
            rows={5}
            onChange={(e) => formik.setValues({ help: e.target.value ?? undefined })}
            value={formik.values.help ?? ''}
          />
        </form>
      </S.QuestionsWrapper>

      <S.ButtonWrapper>
        <V2Button
          variant="outlined"
          startIcon="arrow-left"
          onClick={back}
          isResponsive
          size="large"
          disabled={isPatchFounderAdvisorsQuestionsLoading}
        >
          {backButtonLabel}
        </V2Button>
        <V2Button
          endIcon="arrow-right"
          onClick={formik.submitForm}
          isResponsive
          size="large"
          disabled={!formik.isValid || isPatchFounderAdvisorsQuestionsLoading}
        >
          {nextButtonLabel}
        </V2Button>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

import { Table as TableBase } from '@parts/table/table';
import styled from 'styled-components';

const Table = styled(TableBase)`
  max-width: 100%;
  width: 625px;

  @media screen and (min-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    border: 1px solid ${({ theme }) => theme.color.tableBorder};
    border-radius: ${({ theme }) => theme.radius.default};

    .ant-table-thead {
      > tr {
        > th {
          padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.xsmall};
          font-size: ${({ theme }) => theme.fontSize.small};
        }
      }
    }

    .ant-table-tbody {
      > tr.ant-table-row {
        &:last-child {
          > td {
            border-bottom: 0 none;
          }
        }
      }
    }
  }
`;

const HeaderText = styled.span`
  color: ${({ theme }) => theme.color.tableAlternateHeaderColor};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  @media screen and (min-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    font-size: ${({ theme }) => theme.fontSize.small};
  }
`;

const Centered = styled.span`
  @media screen and (min-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
  }
`;

const RoleCell = styled.span`
  @media screen and (min-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    display: inline-block;
    padding-left: ${({ theme }) => theme.spacing.small};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    color: ${({ theme }) => theme.color.tableAlternateHeaderColor};
  }
`;

export default { Table, Centered, RoleCell, HeaderText };

import MetricRibbonSvg from '@assets/icons/metric-ribbon.svg?react';
import styled from 'styled-components';

export const MetricRibbon = styled(MetricRibbonSvg)`
  cursor: auto;

  path {
    fill: ${({ theme }) => theme.color.ribbonFill};
  }
`;

export default {
  MetricRibbon,
};

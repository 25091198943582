import styled from 'styled-components';

interface ProgressProps {
  positive?: boolean;
}

const Wrapper = styled.section`
  width: auto;
  min-height: 200px;
  height: 85%;
  background-color: ${({ theme }) => theme.color.sectionBackground};
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: ${({ theme }) => theme.radius.default};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing.medium} 15px 0;
  cursor: default;
`;

const DateWrapper = styled.h3`
  color: ${({ theme }) => theme.color.fontPrimary};
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

const Value = styled.h4`
  color: ${({ theme }) => theme.color.fontPrimary};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontSize.medium};
  margin: ${({ theme }) => theme.spacing.small} 0;
`;

const Progress = styled.span<ProgressProps>`
  margin-left: ${({ theme }) => theme.spacing.small};
  font-size: ${({ theme }) => theme.fontSize.regular};
  color: ${({ theme, positive = false }) => (positive ? theme.baseColors.Green : theme.baseColors.Red)};
`;

const Forecast = styled.h5`
  color: ${({ theme }) => theme.color.fontPrimary};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
`;

const ForecastValue = styled.span`
  color: ${({ theme }) => theme.color.fontPrimary};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  margin-left: ${({ theme }) => theme.spacing.xsmall};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const Note = styled.p`
  color: ${({ theme }) => theme.baseColors.Grey200};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.small};
  margin-top: ${({ theme }) => theme.spacing.small};
`;

export default {
  Wrapper,
  DateWrapper,
  Value,
  Progress,
  Forecast,
  ForecastValue,
  Note,
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import type { GraphqlResponse } from 'src/types';
import type { INedAbout, INedExperience, INedSmartMatch } from '../../ned-profile.page';

export const NED_PROFILE_DATA_QUERY_KEY = 'ned-profile-summary-request';

export interface INedProfileDataResponse {
  personalDetails: {
    name: string;
    email: string;
    photoUrl: string;
    phoneNumber: string;
    resumePath: string;
    resumeName: string;
    resumeUrl: string;
    countryOfResidence: CountryCodes | null;
    twitterUrl: string;
    linkedinUrl: string;
  };
  about: INedAbout;
  smartMatch: INedSmartMatch;
  experience: INedExperience[];
  investorTransitionState: {
    hasProcessStarted: boolean;
    hasProcessEnded: boolean;
  };
}

export const getNedProfileDataAction = async (): Promise<GraphqlResponse<INedProfileDataResponse>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
    query getNedProfileData {
      nedInvestorTransitionState {
        hasProcessStarted
        hasProcessEnded
      },
      getNedPersonalDetails {
        email
        lastName
        firstName
        photoUrl
        phoneNumber
        resumePath
        resumeName
        resumeUrl
        countryOfResidence
        twitterUrl
        linkedinUrl
      },
      getNedAboutSection {
        aboutMe
        myExpectations
        myOffering
      },
      getNedSmartMatch {
        industries
        isNed
        isAdvisor
        isFractional
        primarySkillSet
        secondarySkillSet
        compensations {
          role
          compensationType
          minDayRate
          maxDayRate
        }
        targetGeography
      },
      getNedExperience {
        id
        company {
          id
          name
          avatarUrl
          industry
          isExternal
          email
        }
        role
        startDate
        endDate
        compensationType
        isOngoing
        skillSet
        experienceSummary
      }
    }
  `,
  });

  return graphqlResponseWrapper(data, [
    ['getNedPersonalDetails', 'personalDetails'],
    ['getNedAboutSection', 'about'],
    ['getNedSmartMatch', 'smartMatch'],
    ['getNedExperience', 'experience'],
    ['nedInvestorTransitionState', 'investorTransitionState'],
  ]);
};

import { V2NoteTile } from '@/components/blocks/v2-note-tile/v2-note-tile';
import { V2Button } from '@/components/ui/v2-button/v2-button';
import { V2ConfirmDialog } from '@/components/ui/v2-confirm-dialog/v2-confirm-dialog';
import { V2Title } from '@/components/ui/v2-title/v2-title';
import { useCreateConnectionNote } from '@/modules/founder/connections/hooks/use-create-connection-note';
import { useRemoveConnectionNote } from '@/modules/founder/connections/hooks/use-remove-connection-note';
import { useUpdateConnectionNote } from '@/modules/founder/connections/hooks/use-update-connection-note';
import { theme } from '@/styles/theme-config';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { useFormik } from 'formik';
import { useValidationSchema } from './validations-schema';
type NoteContent = {
  title: string;
  date: string;
  content: string;
};

type Props = {
  noteContent: NoteContent;
  noteId: string;
  targetId: string;
  isDefaultState: boolean;
  selectedId: string | null;
  size: 'sm' | 'lg';
  'data-testid'?: string;
  setSelectedId: (id: string | null) => void;
  setIsNewNoteVisible: (isVisible: boolean) => void;
  refetchConnectionNotes: () => Promise<unknown>;
  onUpdated?: () => void | Promise<unknown>;
};

type NoteForm = Omit<NoteContent, 'date'>;

const TileWrapper = ({
  noteContent,
  noteId,
  targetId,
  isDefaultState,
  selectedId,
  size,
  'data-testid': dataTestId,
  setSelectedId,
  setIsNewNoteVisible,
  refetchConnectionNotes,
  onUpdated,
}: Props) => {
  const { createConnectionNoteMutation } = useCreateConnectionNote({ onSuccessCb: onUpdated });
  const { updateConnectionNoteMutation } = useUpdateConnectionNote({ onSuccessCb: onUpdated });
  const { removeConnectionNote } = useRemoveConnectionNote({ onSuccessCb: onUpdated });

  const [
    titlePlaceholderTranslation,
    contentPlaceholderTranslation,
    saveButtonTranslation,
    editButtonTranslation,
    cancelButtonTranslation,
  ] = useTranslation([
    'connections.founder.table.notes.tile.titlePlaceholder',
    'connections.founder.table.notes.tile.contentPlaceholder',
    'connections.founder.table.notes.tile.saveButton',
    'connections.founder.table.notes.tile.editButton',
    'connections.founder.table.notes.tile.cancelButton',
  ]);

  const { values, isValid, errors, dirty, submitCount, ...formik } = useFormik<NoteForm>({
    initialValues: {
      ...noteContent,
    },
    onSubmit: async (v) => {
      const { title, content } = v;
      if (noteId.includes('new')) {
        await createConnectionNoteMutation({ targetId, body: { content, title } });
        setSelectedId(null);
        await refetchConnectionNotes();
        formik.setFieldValue('title', '');
        formik.setFieldValue('content', '');
        setIsNewNoteVisible(false);
        return;
      }
      await updateConnectionNoteMutation({
        targetId,
        noteId,
        body: {
          content,
          title,
        },
      });
      refetchConnectionNotes();
      setSelectedId(null);
    },
    validateOnBlur: true,
    enableReinitialize: true,
    validationSchema: useValidationSchema(),
  });

  const handleEdit = () => setSelectedId(noteId);

  const noteTitle = formik.getFieldProps('title').value;

  const [removeConfirmMessageLL, removeConfirmButtonLL] = useTranslation([
    'connections.notes.confirmRemove.message',
    'connections.notes.confirmRemove.confirmButton',
  ]);

  const handleRemoveNote = async () => {
    await removeConnectionNote({ targetId, noteId });
    refetchConnectionNotes();
  };

  const handleCancel = () => {
    formik.setFieldValue('title', noteContent.title);
    formik.setFieldValue('content', noteContent.content);
    setSelectedId(null);
  };

  return (
    <div data-testid={dataTestId} key={noteId}>
      <V2NoteTile
        inEditMode={selectedId === noteId}
        warningText={errors.content ?? errors.title}
        size={size}
        heading={
          <V2NoteTile.Heading>
            <V2NoteTile.Title
              {...formik.getFieldProps('title')}
              value={formik.getFieldProps('title').value || ''}
              name="title"
              disabled={selectedId !== noteId}
              onChange={formik.handleChange}
              placeholder={titlePlaceholderTranslation}
            />
            <V2NoteTile.DateLabel />
          </V2NoteTile.Heading>
        }
        content={
          <V2NoteTile.Content
            {...formik.getFieldProps('content')}
            value={formik.getFieldProps('content').value || ''}
            name="content"
            disabled={selectedId !== noteId}
            onChange={formik.handleChange}
            placeholder={contentPlaceholderTranslation}
          />
        }
        footer={
          <V2NoteTile.ButtonsWrapper>
            {selectedId === noteId ? (
              <V2Button type="submit" disabled={!isValid} variant="outlined" onClick={formik.submitForm}>
                {saveButtonTranslation}
              </V2Button>
            ) : (
              <V2NoteTile.LeftTextButton onClick={handleEdit} startIcon="edit" variant="text">
                {editButtonTranslation}
              </V2NoteTile.LeftTextButton>
            )}
            {selectedId === noteId ? (
              <V2NoteTile.RightTextButton onClick={handleCancel} variant="text">
                {cancelButtonTranslation}
              </V2NoteTile.RightTextButton>
            ) : noteId === 'new' ? (
              <V2NoteTile.IconButton
                name="trash"
                color={theme.v2.colors.blue[300]}
                onClick={() => {
                  setIsNewNoteVisible(false);
                  setSelectedId(null);
                }}
                disabled={isDefaultState}
              />
            ) : (
              <V2ConfirmDialog
                confirmButtonText={removeConfirmButtonLL}
                message={
                  <>
                    <V2Title size="xl" title={removeConfirmMessageLL} data-testid="v2-confirm-dialog-modal-title" />
                    <br />
                    <V2Title size="lg" title={`"${noteTitle}"`} data-testid="v2-confirm-dialog-modal-title" />
                  </>
                }
              >
                <V2NoteTile.IconButton
                  name="trash"
                  color={theme.v2.colors.blue[300]}
                  onClick={handleRemoveNote}
                  disabled={isDefaultState}
                />
              </V2ConfirmDialog>
            )}
          </V2NoteTile.ButtonsWrapper>
        }
        noteContent={noteContent}
      />
    </div>
  );
};

export { TileWrapper };

/* eslint-disable @typescript-eslint/no-unused-vars */
import { Avatar } from '@/parts/avatar/avatar';
import { arrayInsert } from '@/utils/fns/array-insert';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import useUserAccount from '@/utils/hooks/use-user-account/use-user-account';
import { V2CircleProgressBar } from '@ui/v2-circle-progress-bar/v2-circle-progress-bar';
import type { GetReadinessScoreQueryResponseData, ScoreDetails } from '../../../../api/get-readiness-score.query';
import { ReadinessScoreModalCarousel, type ReadinessScoreCarouselItem } from './carousel/carousel';

import S from './base-content.styles';

const useReadinessScoreModalCarouselItems = (
  data: GetReadinessScoreQueryResponseData,
  action: (key: string) => void,
) => {
  const [
    profileTitle,
    profileStep1,
    profileStep2,
    profileStep3,
    metricsTitle,
    metricsStep1,
    metricsStep2,
    metricsStep3,
    teamTitle,
    teamStep1,
    marketFitTitle,
    marketFitStep1,
  ] = useTranslation([
    'homepage.readinessScore.carousel.profile.title',
    'homepage.readinessScore.carousel.profile.step1',
    'homepage.readinessScore.carousel.profile.step2',
    'homepage.readinessScore.carousel.profile.step3',
    'homepage.readinessScore.carousel.metrics.title',
    'homepage.readinessScore.carousel.metrics.step1',
    'homepage.readinessScore.carousel.metrics.step2',
    'homepage.readinessScore.carousel.metrics.step3',
    'homepage.readinessScore.carousel.team.title',
    'homepage.readinessScore.carousel.team.step1',
    'homepage.readinessScore.carousel.marketFit.title',
    'homepage.readinessScore.carousel.marketFit.step1',
  ]);

  const getCompletedNumber = (stepsScores: ScoreDetails[]) => {
    const getStepCompletedVal = (status: { received: number; available: number }) =>
      status.received === status.available ? 1 : 0;

    return stepsScores.reduce((acc, step) => acc + getStepCompletedVal(step), 0);
  };

  const profileStepsData = [
    data.profile.personalDetailsScore,
    data.profile.smartMatchScore,
    data.profile.uploadedFilesScore,
  ];

  const metricsStepsData = [
    data.metrics.manualMetricsScore,
    data.metrics.integratedMetricsScore,
    data.metrics.dashboardScore,
  ];

  const teamStepsData = [data.team.teamMembersScore];

  let items: ReadinessScoreCarouselItem[] = [
    {
      title: profileTitle,
      items: [profileStep1, profileStep2, profileStep3],
      completion: { completed: getCompletedNumber(profileStepsData), total: profileStepsData.length },
      onClick: () => action('profile'),
      icon: 'person',
    },
    {
      title: teamTitle,
      items: [teamStep1],
      completion: { completed: getCompletedNumber(teamStepsData), total: teamStepsData.length },
      onClick: () => action('team'),
      icon: 'people',
    },
    {
      title: marketFitTitle,
      items: [marketFitStep1],
      completion: {
        completed: data.marketFit.questions.answered === data.marketFit.questions.available ? 1 : 0,
        total: 1,
      },
      onClick: () => action('marketFit'),
      icon: 'shop',
    },
  ];

  if (data.metrics.hasScoring) {
    items = arrayInsert(items, 1, {
      title: metricsTitle,
      items: [metricsStep1, metricsStep2, metricsStep3],
      completion: { completed: getCompletedNumber(metricsStepsData), total: metricsStepsData.length },
      onClick: () => action('metrics'),
      icon: 'graph-up',
    });
  }

  return items;
};

type ReadinessScoreModalContentBaseProps = {
  data: GetReadinessScoreQueryResponseData;
  setContentKey: (key: string) => void;
};

export const ReadinessScoreModalContentBase = ({ setContentKey, data }: ReadinessScoreModalContentBaseProps) => {
  const [introTitle, introDescription, introScorePoints] = useTranslation([
    'homepage.readinessScore.modal.intro.title',
    'homepage.readinessScore.modal.intro.description',
    'homepage.readinessScore.modal.intro.scorePoints',
  ]);
  const items = useReadinessScoreModalCarouselItems(data, setContentKey);
  const {
    state: { avatarUrl },
  } = useUserAccount();

  return (
    <div data-testid="readiness-score-modal-base-content">
      <S.ModalContentOverview>
        <div>
          <V2CircleProgressBar
            percent={data.score}
            variant="magenta"
            size="large"
            icon={<Avatar avatarUrl={avatarUrl} size={60} imageBackground="transparent" />}
            data-testid="readiness-circle-score-modal-progress-bar"
          >
            <S.ProgressPointsDisplay>
              <span>{data.score}</span>
              <span>{introScorePoints}</span>
            </S.ProgressPointsDisplay>
          </V2CircleProgressBar>
        </div>
        <S.TopContent>
          <header>
            <h3>{introTitle}</h3>
          </header>
          <p>{introDescription}</p>
        </S.TopContent>
      </S.ModalContentOverview>
      <S.CarouselWrapper>
        <ReadinessScoreModalCarousel items={items} />
      </S.CarouselWrapper>
    </div>
  );
};

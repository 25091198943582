import LogoBase from '@assets/brand/logo.svg?react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled(Link)`
  display: block;
  width: 160px;
  max-width: 100%;
  height: auto;
  position: relative;
  z-index: 10;
`;

const Logo = styled(LogoBase)`
  path:first-of-type {
    fill: ${({ theme }) => theme.color.logoFill} !important;
  }
`;

export default {
  Wrapper,
  Logo,
};

import { useHistory } from '@/utils/hooks/use-history/use-history';
import { useQueryParams } from '@/utils/hooks/use-query/use-query-params';
import { parse, stringify } from 'query-string';

export const openModalQueryParamKey = 'modalOpen';

export const useModalWithQuery = (id: string) => {
  const history = useHistory();
  const { modalOpen: modalOpenSearchQueryParam } = useQueryParams<{ [openModalQueryParamKey]: string | undefined }>();
  const isOpen = modalOpenSearchQueryParam === id;

  const close = () => {
    const { modalNestedContent: removedModalNestedContent, ...restQueryParams } = parse(history.location.search);
    history.replace({ search: stringify(restQueryParams) });
  };

  const open = () => {
    history.replace({ search: stringify({ [openModalQueryParamKey]: id }) });
  };

  return {
    isOpen,
    open,
    close,
  };
};

/* eslint-disable no-param-reassign */
import { AppLocale } from '@context/locale/locale.context';
import enMessages from './en';

type KeyAsValue<T> = { [P in keyof T]: P };

export const keysToValues = <T extends Record<string, string | object>>(source: T): KeyAsValue<typeof source> =>
  (Object.keys(source) as Array<keyof T>).reduce(
    (accumulated, current) => {
      accumulated[current] = current;
      return accumulated;
    },
    {} as KeyAsValue<typeof source>,
  );

export const AppMessages = {
  ...keysToValues(enMessages),
};

export type AppMessagesKey = keyof typeof AppMessages;

export const translations: Record<AppLocale, Record<AppMessagesKey, string>> = {
  [AppLocale.en]: enMessages as Record<string, string>,
};

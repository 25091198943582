/* eslint-disable react/no-array-index-key */
import { Dropdown } from 'antd';
import type { ReactNode } from 'react';
import S from './nav-dropdown.styles';

const NavDropdown = ({ label, icon, items }: { icon: ReactNode; items: ReactNode[]; label?: string }) => {
  const menuOverlay = (
    <S.Menu>
      {label && (
        <>
          <p>{label}</p> <S.Menu.Divider />
        </>
      )}

      {items.map((item, i) => (
        <S.MenuItem key={`${label}-item-${i}`}>{item}</S.MenuItem>
      ))}
    </S.Menu>
  );

  return (
    <Dropdown overlay={menuOverlay} trigger={['click']} arrow placement="bottomRight">
      <S.Button type="text">{icon}</S.Button>
    </Dropdown>
  );
};

export default NavDropdown;

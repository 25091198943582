import type { AxiosConfig } from '@/utils/axios/types';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import type { GraphqlResponse } from 'src/types';

export const GET_COUNTRIES_OPTIONS_DATA_CACHE_KEY = 'get-countries';

export interface GetCountriesQueryResult {
  countries: CountryCodes[];
}

export const getCountriesListAction = async (): Promise<GraphqlResponse<GetCountriesQueryResult>> => {
  const { data } = await axiosInstance.post(
    '/query',
    {
      query: `
      query getCountriesRegionsAction {
        countriesAndRegions {
          countries
        }
     }
  `,
    },
    { skipAuthorization: true } as AxiosConfig,
  );

  return graphqlResponseWrapper(data, ['countriesAndRegions']);
};

import { axiosInstance } from '@utils/axios/axios-instance';

export type UpsertNumericMetricNodeActionPayload = {
  month: number;
  year: number;
  forecast: null | number;
  value: null | number;
  note: null | string;
  id: string | null;
};

type UpsertNumericMetricNodeActionOptionalPayload = Pick<
  Partial<UpsertNumericMetricNodeActionPayload>,
  'forecast' | 'note'
> & { id: UpsertNumericMetricNodeActionPayload['id'] };

export const upsertNumericMetricNodeAction =
  (metricId: string) =>
  (payload: UpsertNumericMetricNodeActionPayload | UpsertNumericMetricNodeActionOptionalPayload) =>
    axiosInstance.put(`/metrics/numeric/${metricId}`, payload);

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 850px;
  margin: 0 auto;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.xmedium};
`;

const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xlarge};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin: ${({ theme }) => theme.spacing.xmedium};
  color: ${({ theme }) => theme.onboardingColors.fontPrimary};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.big};
  }
`;

const Description = styled.p`
  color: ${({ theme }) => theme.onboardingColors.fontPrimary};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin-top: ${({ theme }) => theme.spacing.xmedium};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.regular};
  }
`;

export default { Wrapper, Title, Description };

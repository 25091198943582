import type { TargetGeographyValue } from '@constants/target-geography';
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import type { RawDraftContentState } from 'draft-js';

export enum InvestmentsScale {
  NONE = 'None',
  ONE_TO_THREE = 'OneToThree',
  FOUR_TO_TEN = 'FourToTen',
  MORE_THAN_TEN = 'More',
}

export enum InvestorType {
  NonSpecified = 'NonSpecified',
  Angel = 'Angel',
  Institutional = 'Institutional',
}

export enum InstitutionType {
  WealthManagement = 'WealthManagement',
  FamilyOffice = 'FamilyOffice',
  VentureCapital = 'VentureCapital',
  Scout = 'Scout',
}

export enum SelfCertificationType {
  Sophisticated = 'Sophisticated',
  HighNetWorth = 'HighNetWorth',
  InstitutionalInvestor = 'InstitutionalInvestor',
  AccreditedInvestor = 'AccreditedInvestor',
}

export enum GenderType {
  Woman = 'Woman',
  Man = 'Man',
  NonBinary = 'NonBinary',
  PreferNotToSay = 'PreferNotToSay',
}

export interface IInvestorSmartMatch {
  industries: string[];
  customerGroup: string[];
  productStage: string[];
  investmentStage: string[];
  targetGeography: TargetGeographyValue[];
}

export interface IGetInvestorPersonalDetails {
  firstName: string;
  lastName: string;
  gender: GenderType | null;
  email: string;
  linkedinUrl: string;
  twitterUrl: string;
  aboutMe: RawDraftContentState | null;
  countryOfResidence: CountryCodes | null;
  recentCompany?: string | null;
  recentOccupation?: string | null;
  companyName?: string | null;
  phoneNumber: string;
  type: InvestorType;
  institutionType: InstitutionType | null;
  profilePicture: {
    path: string | null;
    url: string | null;
  };
}

export interface PreviousInvestment {
  company: string;
  note: string;
}

export interface IInvestorInvestment {
  minInvestmentAmount: number;
  maxInvestmentAmount: number;
  investmentThesis: string;
  previousInvestmentsTable: PreviousInvestment[];
  hidePreviousInvestments: boolean;
  isDeployingCapitalInNextThreeMonths?: boolean;
}

export interface IGetInvestorInvestment {
  investmentThesis: string | null;
  maxInvestmentAmount: number | null;
  minInvestmentAmount: number | null;
  previousInvestmentsTable: PreviousInvestment[] | null;
  hidePreviousInvestments: boolean | null;
  isDeployingCapitalInNextThreeMonths?: boolean;
  shouldShowDeployingCapitalModal: boolean;
}

export interface IInvestorProfileDataResponse {
  getInvestorPersonalDetails: IGetInvestorPersonalDetails;
  getInvestorInvestmentSpec: IGetInvestorInvestment;
  getInvestorSmartMatch: IInvestorSmartMatch;
}

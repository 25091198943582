import { Group } from '@/pages/content/onboarding/investor/content/business/parts/group/group';
import { FormikField as FormikFieldBase } from '@pages/content/profile/parts/formik-field/formik-field';
import { ConditionalShowToggle as ConditionalShowToggleBase } from '@parts/conditional-show-toggle/conditional-show-toggle';
import { TextArea as TextAreaBase } from '@parts/forms/highlight/form-elements.style';
import styled from 'styled-components';

const FormWrapper = styled.form`
  width: 100%;
  max-width: 350px;
  margin: ${({ theme }) => theme.spacing.base} auto;
  background-color: ${({ theme }) => theme.onboardingColors.onboardingBackground};
`;

const FormikField = styled(FormikFieldBase)`
  width: 100%;
  max-width: 350px;
  margin: 0 auto ${({ theme }) => theme.spacing.xbase};

  > label {
    display: inline-block;
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize.small};
  }
`;

const ConditionalShowToggle = styled(ConditionalShowToggleBase)`
  margin-top: ${({ theme }) => theme.spacing.small};
`;

const TextArea = styled(TextAreaBase)`
  max-width: 100%;
`;

const GroupFullWidth = styled(Group)`
  max-width: 100%;
`;

export default {
  FormWrapper,
  FormikField,
  ConditionalShowToggle,
  TextArea,
  GroupFullWidth,
};

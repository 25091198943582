import { Roles } from '@domain/accounts/roles';
import { InvestorNedLayout } from '@layout/investor-ned/investor-ned.layout';
import { NotFoundPage } from '@pages/utility/not-found/not-found.page';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { FounderCatalogue } from './founder/founder.page';
import { NedCatalogue } from './ned/ned.page';

export const CataloguePage = () => {
  const {
    state: { userRole },
  } = useUserAccount();

  switch (userRole) {
    case Roles.FOUNDER:
      return <FounderCatalogue />;
    case Roles.NED:
      return <NedCatalogue />;
    case Roles.INVESTOR_NED:
      return <NedCatalogue Layout={InvestorNedLayout} />;
    default:
      return <NotFoundPage />;
  }
};

import Icon from '@assets/icons/confirm-email.svg';
import message from '@parts/message/message';
import { Links } from '@router/links';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Link } from 'react-router-dom';
import { resendConfirmationLinkAction } from '../api/resend-confirmation-action/resent-confirmation.action';
import { useAuth0Token } from '../register/auth0/hooks/use-auth0-token/use-auth0-token';
import { Header } from './parts/header';

import S from './email-unverified.styles';

export const UnverifiedEmailPage = () => {
  const [
    titleLabel,
    subTextFirstLabel,
    subTextSecondLabel,
    subTextThirdLabel,
    checkSpamLabel,
    linkTextLabel,
    confirmationSentLabel,
    loginLabel,
  ] = useTranslation([
    'unverified.email.confirm.title',
    'unverified.email.confirm.subText.first',
    'unverified.email.confirm.subText.second',
    'unverified.email.confirm.subText.third',
    'unverified.email.confirm.checkSpam',
    'unverified.email.confirm.linkText',
    'unverified.email.confirmationSent',
    'unverified.email.confirm.subText.login',
  ]);

  const { isAuth0Token, auth0Token } = useAuth0Token();

  const { mutateAsync: resendConfirmationLink } = useMutation(resendConfirmationLinkAction, {
    onSuccess: () => {
      message.success({
        content: <p>{confirmationSentLabel}</p>,
      });
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  return (
    <>
      <Header />
      <S.Wrapper>
        <img src={Icon} alt="icon" loading="lazy" data-testid="icon-img" />
        <S.Title>{titleLabel}</S.Title>
        <S.Description>{subTextFirstLabel}</S.Description>
        <S.Description $noMargin>{subTextSecondLabel}</S.Description>
        {isAuth0Token ? (
          <S.Description>
            {checkSpamLabel}
            <S.ResendButton onClick={() => resendConfirmationLink({ token: auth0Token as string })}>
              {linkTextLabel}
            </S.ResendButton>
          </S.Description>
        ) : null}
        <S.Description>
          {subTextThirdLabel}
          <Link to={Links.LOGIN()}>
            <S.LoginButton>{loginLabel}</S.LoginButton>
          </Link>
        </S.Description>
      </S.Wrapper>
    </>
  );
};

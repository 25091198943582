/**
 * TODO: shouldPreventNotify and handleSetApiNotificationInStorage functions should be removed when correctly handle promise rejections
 * - without this there will be a lot of error report duplicates because rectQuery is rethrowing async mutation errors
 * {@link https://exlabs.atlassian.net/browse/CON-9200 CON-9200}
 */
import { isNoticeExpected } from './expected-notices';
import { ReportTags, type BaseNotice } from './notifications';

/**
 * The timespan (in milliseconds) within which duplicate reports are considered unwanted.
 */
const UNWANTED_REPORTS_TIMESPAN = 100;
const SESSION_STORAGE_NOTICE_KEY = 'lastAPIHbNotice';

/**
 * Checks if a notification should be prevented based on the previous notices.
 */
const shouldPreventNotify = (): boolean => {
  let lastApiNotice: (BaseNotice & { timestamp: number }) | null = null;

  try {
    const lastHbNoticeStr = sessionStorage.getItem(SESSION_STORAGE_NOTICE_KEY);
    if (lastHbNoticeStr) {
      lastApiNotice = JSON.parse(lastHbNoticeStr) as BaseNotice & { timestamp: number };
    }
  } catch (error) {
    lastApiNotice = null;
  }

  if (!lastApiNotice) {
    return false;
  }

  const msSinceLastNotify = Math.abs(Date.now() - lastApiNotice.timestamp);
  if (msSinceLastNotify > 0 && msSinceLastNotify < UNWANTED_REPORTS_TIMESPAN) {
    return true;
  }

  return false;
};

/**
 * Sets API notification in Session storage to prevent reporting duplicate notifications based on the previous notices.
 */
const handleSetApiNotificationInStorage = (notice: BaseNotice | undefined): void => {
  // Check if the notice is reported as an API error
  if (notice?.tags.includes(ReportTags.API)) {
    sessionStorage.setItem(SESSION_STORAGE_NOTICE_KEY, JSON.stringify({ ...notice, timestamp: Date.now() }));
  }
};

const createNoticeFingerprint = (message: string, name: string) => {
  function xorEncryptDecrypt(input: string, key = 3): string {
    let output = '';
    for (let i = 0; i < input.length; i++) {
      let charCode = input.charCodeAt(i) ^ key;
      output += String.fromCharCode(charCode);
    }
    return output;
  }

  return xorEncryptDecrypt(`${message}-${name}`);
};

export const beforeNotify = (notice: BaseNotice | undefined): boolean => {
  if (!notice) return false;

  handleSetApiNotificationInStorage(notice);

  if (isNoticeExpected(notice) || shouldPreventNotify()) {
    return false;
  }

  if (!notice.fingerprint) {
    notice.fingerprint = createNoticeFingerprint(notice.message, notice.name);
  }

  return true;
};

import { InvestorType } from '@pages/content/profile/investor/api/types';
import { maxWords } from '@pages/content/profile/utils/max-words';
import { LINKEDIN_URL_REGEXP, PHONE_NUMBER_REGEXP, TWITTER_URL_REGEXP } from '@utils/fns/reg-exp-validators';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';

import * as Yup from 'yup';

const INVESTOR_PROFILE_NAME_MAX_LENGTH = 100;

export const useValidationSchema = (investorType: InvestorType) => {
  const [
    minLength,
    maxLength,
    requiredMessage,
    validLinkedInUrl,
    formikPhoneNumber,
    twitterUrlError,
    occupationOrLinkedinUrlError,
  ] = useTranslation([
    'formik.validation.min',
    'formik.validation.max',
    'formik.validation.required',
    'formik.validation.linkedInUrl',
    'formik.validation.phoneNumber',
    'formik.validation.twitterUrl',
    'profile.investor.section.personalDetails.form.occupationOrLinkedinUrlError',
  ]);

  const validationSchema = Yup.object().shape(
    {
      firstName: Yup.string()
        .trim()
        .min(2, minLength)
        .max(INVESTOR_PROFILE_NAME_MAX_LENGTH, maxLength)
        .required(requiredMessage),
      lastName: Yup.string().trim().min(2, minLength).max(INVESTOR_PROFILE_NAME_MAX_LENGTH, maxLength),
      gender: Yup.string().nullable(),
      location: Yup.string().trim().min(2, minLength),
      aboutMe: maxWords(500, minLength, maxLength).nullable(),
      profilePicturePath: Yup.string().trim().nullable(),
      profilePictureUrl: Yup.string().trim().nullable(),
      ...(investorType === InvestorType.Institutional && {
        companyName: Yup.string().trim().min(2, minLength).max(50, maxLength).required(requiredMessage).nullable(),
      }),
      ...(investorType === InvestorType.Angel && {
        recentOccupation: Yup.string()
          .trim()
          .max(50, maxLength)
          .nullable()
          .when('linkedinUrl', {
            is: (val: string | null | undefined) => !val || val.length === 0,
            then: Yup.string().trim().max(50, maxLength).nullable().required(occupationOrLinkedinUrlError),
          }),
        linkedinUrl: Yup.string()
          .trim()
          .matches(LINKEDIN_URL_REGEXP, validLinkedInUrl)
          .nullable()
          .when('recentOccupation', {
            is: (val: string | null | undefined) => !val || val.length === 0,
            then: Yup.string()
              .trim()
              .matches(LINKEDIN_URL_REGEXP, validLinkedInUrl)
              .nullable()
              .required(occupationOrLinkedinUrlError),
          }),
        phoneNumber: Yup.string().test('customPhoneNumber', formikPhoneNumber, (value) => {
          if (!value) return true;
          return value.match(PHONE_NUMBER_REGEXP) !== null;
        }),
        twitterUrl: Yup.string().matches(TWITTER_URL_REGEXP, twitterUrlError).nullable(),
      }),
    },
    investorType === InvestorType.Angel ? [['recentOccupation', 'linkedinUrl']] : undefined,
  );

  return validationSchema;
};

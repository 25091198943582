import SearchIcon from '@assets/icons/search.svg?react';
import {
  MetricType,
  type ExternalMetric,
  type InternalMetric,
  type MetricsOwner,
} from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Collapse, Tooltip } from 'antd';
import levenshtein from 'js-levenshtein';
import groupBy from 'lodash.groupby';
import { useEffect, useState, type ChangeEvent } from 'react';
import S from './collapse-search.styles';

const MAX_WRONG_LETTERS = 3;
const ORGANIZED_METRICS_CATEGORIES = ['Revenue', 'Financial', 'Marketing', 'Customer Success', 'Other'];

const CollapseSearch = ({
  metrics: { internalMetrics, externalMetrics },
  setFieldValue,
  fieldName,
}: {
  metrics: MetricsOwner;
  setFieldValue: Function;
  fieldName: string;
}) => {
  const connectedMetrics: (InternalMetric | ExternalMetric)[] = [...internalMetrics, ...externalMetrics];
  const groupedMetrics = groupBy(connectedMetrics, 'category');
  const [filter, setFilter] = useState<string | null>(null);
  const categories = Object.keys(groupedMetrics);
  const [categoriesOpen, setCategoriesOpen] = useState(new Set<string>([]));
  const [checkedMetrics, setCheckedMetrics] = useState(new Map<string, { id: string; type: MetricType }>([]));
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const metrics = Array.from(new Set<string>([...ORGANIZED_METRICS_CATEGORIES, ...categories]));

  const [searchLabel, textBoxLabel, otherLabel, verifiedMetricLabel, noDataPointMetricLabel] = useTranslation([
    'pulseDashboard.shared.search',
    'pulse.dashboard.textBox',
    'pulse.dashboard.other',
    'pulse.chart.tooltip.verifiedMetric',
    'metrics.integratedMetrics.tooltip',
  ]);

  const panelWrapperOnClick = (category: string) => {
    const newCategoryOpen = new Set<string>(categoriesOpen);
    if (categoriesOpen.has(category)) {
      newCategoryOpen.delete(category);
    } else {
      newCategoryOpen.add(category);
    }
    setCategoriesOpen(newCategoryOpen);
  };

  const checkFilterMatch = (name: string) => {
    const nameInLowerCase = name.toLocaleLowerCase();
    const filterInLowerCase = filter?.toLocaleLowerCase() ?? '';

    return filter
      ? nameInLowerCase.includes(filterInLowerCase) ||
          levenshtein(nameInLowerCase, filterInLowerCase) <= MAX_WRONG_LETTERS
      : false;
  };

  useEffect(() => {
    const newCategoryOpen = new Set<string>([]);
    metrics.forEach((category) => {
      const group = groupedMetrics[category];
      const isInCategory = Boolean(group && group.some((metric) => checkFilterMatch(metric.name)));

      if (isInCategory) {
        newCategoryOpen.add(category);
      }
    });

    setCategoriesOpen(newCategoryOpen);
  }, [filter]);

  useEffect(() => {
    const checkedMetricsAsArray = [...checkedMetrics.values()];
    setFieldValue(fieldName, checkedMetricsAsArray);
  }, [checkedMetrics]);

  const listedMetric = (metric: InternalMetric | ExternalMetric, disabled: boolean = false) => (
    <div key={metric.id} data-testid={metric.name.toLowerCase().replace(/\s/g, '_')}>
      <S.Checkbox
        onChange={({ target: { value, checked } }) => {
          setCheckedMetrics((previousState) => {
            const newCategoryMap = new Map(previousState);
            if (checked) {
              newCategoryMap.set(value, { id: value, type: metric.type });
            } else {
              newCategoryMap.delete(value);
            }
            return newCategoryMap;
          });
        }}
        key={metric.id}
        value={metric.id}
        disabled={disabled}
      >
        {(() => {
          if (metric.isExternal) {
            return (
              <>
                <S.Name>{metric.name}</S.Name>
                <Tooltip title={verifiedMetricLabel}>
                  <S.MetricRibbon />
                </Tooltip>
              </>
            );
          }
          return metric.name;
        })()}
      </S.Checkbox>
    </div>
  );

  return (
    <>
      <S.SearchInput
        placeholder={searchLabel}
        onChange={onChange}
        prefix={<SearchIcon />}
        data-testid="metric-search"
      />
      <Collapse expandIconPosition="right" ghost activeKey={[...categoriesOpen]}>
        {metrics.map((category) => (
          <S.Panel
            isActive={categoriesOpen.has(category)}
            extra={
              <S.PanelWrapper
                data-testid={`panel-${category.toLowerCase()}`}
                onClick={() => panelWrapperOnClick(category)}
              />
            }
            header={category}
            key={category}
          >
            <S.CheckboxGroup>
              {groupedMetrics[category].map(
                (metric) =>
                  (!filter || checkFilterMatch(metric.name)) &&
                  (() => {
                    if (metric.isExternal) {
                      const externalMetric = metric as ExternalMetric;
                      const isDisabled = !externalMetric.hasDataPoints;

                      return isDisabled ? (
                        <Tooltip
                          key={metric.name.toLowerCase().replace(/\s/g, '_')}
                          title={noDataPointMetricLabel}
                          placement="leftTop"
                        >
                          {listedMetric(externalMetric, isDisabled)}
                        </Tooltip>
                      ) : (
                        listedMetric(externalMetric)
                      );
                    }
                    return listedMetric(metric);
                  })(),
              )}
            </S.CheckboxGroup>
          </S.Panel>
        ))}
        {(checkFilterMatch(textBoxLabel) || !filter) && (
          <S.OtherCategoryWrapper>
            <S.CheckboxGroup>
              <S.OtherCategory>{otherLabel}</S.OtherCategory>
              <S.Checkbox onChange={(event) => setFieldValue('addTextBox', event.target.checked)}>
                <S.OtherCategoryName>{textBoxLabel}</S.OtherCategoryName>
              </S.Checkbox>
            </S.CheckboxGroup>
          </S.OtherCategoryWrapper>
        )}
      </Collapse>
    </>
  );
};
export default CollapseSearch;

import ForecastDisabledIconBase from '@assets/icons/forecast-disabled.svg?react';
import ForecastEnabledIconBase from '@assets/icons/forecast-enabled.svg?react';
import ZoomOutOutlinedBase from '@assets/icons/toast/toast-close-icon.svg?react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const iconStyles = css`
  font-size: 19px;
  color: ${({ theme }) => theme.color.primary};

  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
`;

const ZoomOutOutlined = styled(ZoomOutOutlinedBase)`
  ${iconStyles}
`;

const ForecastEnabledIcon = styled(ForecastEnabledIconBase)`
  ${iconStyles}
`;

const ForecastDisabledIcon = styled(ForecastDisabledIconBase)`
  ${iconStyles}
`;

const Wrapper = styled.article<{ size: string; zoomCursor: boolean; isTable: boolean }>`
  width: 100%;
  height: 100%;
  padding-bottom: ${({ theme }) => theme.spacing.medium};

  ${({ isTable }) =>
    isTable &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;

      header > section {
        top: auto;
      }
    `}
  ${({ zoomCursor }) =>
    zoomCursor &&
    css`
      cursor: zoom-in;
    `}
    .apexcharts-tooltip {
    border: none;
    box-shadow: 0 8px 16px 0 rgba(50, 50, 71, 6%);

    & > div {
      border-radius: 10px;
      padding: 0;
      border-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  ${({ size }) =>
    size === 'default' &&
    css`
      header {
        padding-top: ${({ theme }) => theme.spacing.small};

        button:last-of-type {
          margin-left: 0;
        }
      }
    `}
`;

const actionIcon = css`
  height: fit-content;
  padding: 0;
  margin: 0;
  margin-left: ${({ theme }) => theme.spacing.xsmall};
`;

const NavButton = styled(Button)`
  ${actionIcon}
`;

const NavLink = styled(Link)`
  ${actionIcon}
`;

const Footer = styled.footer`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`;

export const TitleRibbonWrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-left: 1px;
`;

const TitleTooltipContent = styled.span`
  display: inline-flex;
  align-items: center;
`;

export default {
  Wrapper,
  NavButton,
  NavLink,
  Footer,
  ZoomOutOutlined,
  ForecastEnabledIcon,
  ForecastDisabledIcon,
  TitleRibbonWrapper,
  TitleTooltipContent,
};

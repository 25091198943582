import { InvestmentsScale } from '@pages/content/profile/investor/api/types';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const INVESTOR_ONBOARDING_INVESTMENT_CACHE_KEY = 'INVESTOR_ONBOARDING_INVESTMENT_CACHE_KEY';

export type InvestorOnboardingInvestmentSpecResponseData = {
  maxInvestmentAmount: number | null;
  minInvestmentAmount: number | null;
  previousInvestmentsScale: InvestmentsScale;
  isDeployingCapitalInNextThreeMonths: boolean | null;
};

export const getInvestorOnboardingInvestmentSpecData = async (): Promise<
  GraphqlResponse<InvestorOnboardingInvestmentSpecResponseData>
> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
    query getInvestorOnboardingInvestmentSpecData {
      investmentSpec: getInvestorInvestmentSpecQuery {
        maxInvestmentAmount
        minInvestmentAmount,
        previousInvestmentsScale
        isDeployingCapitalInNextThreeMonths
      }
    }
  `,
  });

  return graphqlResponseWrapper(data, ['investmentSpec']);
};

import BarChartIconBase from '@assets/icons/bar-chart.svg?react';
import CircularChartIconBase from '@assets/icons/circular-chart.svg?react';
import ColumnChartIconBase from '@assets/icons/column-chart.svg?react';
import LineChartIconBase from '@assets/icons/line-chart.svg?react';
import NumericChartIconBase from '@assets/icons/numeric-chart.svg?react';
import TableChartIconBase from '@assets/icons/table-chart.svg?react';
import styled, { css } from 'styled-components';

const Item = styled.div`
  flex: 100%;
  display: flex;
`;

const iconStyles = css`
  height: 19px;

  path {
    stroke: ${({ theme }) => theme.color.iconFill};
  }
`;

const optionIconStyles = css`
  margin-right: 10px;
`;

const LineChartIcon = styled(LineChartIconBase)`
  ${iconStyles}
`;
const ColumnChartIcon = styled(ColumnChartIconBase)`
  ${iconStyles}
`;
const NumericChartIcon = styled(NumericChartIconBase)`
  ${iconStyles}
  height: 28px;
`;
const BarChartIcon = styled(BarChartIconBase)`
  ${iconStyles}
`;

const CircularChartIcon = styled(CircularChartIconBase)`
  ${iconStyles}
`;

const LineChartOptionIcon = styled(LineChartIcon)`
  ${optionIconStyles}
`;
const ColumnChartOptionIcon = styled(ColumnChartIcon)`
  ${optionIconStyles}
`;
const NumericChartOptionIcon = styled(NumericChartIcon)`
  ${optionIconStyles}
`;
const CircularChartOptionIcon = styled(CircularChartIcon)`
  ${optionIconStyles}
`;
const BarChartOptionIcon = styled(BarChartIconBase)`
  ${optionIconStyles}
`;
const TableChartIcon = styled(TableChartIconBase)`
  ${optionIconStyles}
`;

export default {
  Item,
  LineChartIcon,
  ColumnChartIcon,
  NumericChartIcon,
  CircularChartIcon,
  BarChartIcon,
  LineChartOptionIcon,
  ColumnChartOptionIcon,
  NumericChartOptionIcon,
  CircularChartOptionIcon,
  BarChartOptionIcon,
  TableChartIcon,
};

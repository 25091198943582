import S from '../v2-carousel.styles';
import { Dots } from './dots';
import { useV2CarouselContext } from '../v2-carousel-context';
import { V2Icon } from '@/components/ui/v2-icon/v2-icon';

export const StepIndicatorWithActions = () => {
  const { nextBtnDisabled, onNextButtonClick, onPrevButtonClick, prevBtnDisabled } = useV2CarouselContext();

  return (
    <S.BottomNavigationPanel>
      <S.IconButton data-testid="carousel-prev-button" disabled={prevBtnDisabled} onClick={onPrevButtonClick}>
        <V2Icon name="chevron-left" size="lg" />
      </S.IconButton>
      <Dots />
      <S.IconButton data-testid="carousel-next-button" disabled={nextBtnDisabled} onClick={onNextButtonClick}>
        <V2Icon name="chevron-right" size="lg" />
      </S.IconButton>
    </S.BottomNavigationPanel>
  );
};

import EmptyBrowsing from '@assets/icons/undraw-empty.svg';
import { Roles } from '@domain/accounts/roles';
import type {
  GetFounderSmartMatchSuggestions,
  SmartMatchSuggestionForFounderItems,
} from '@pages/content/lens/api/get-founder-smart-match-suggestion';
import type {
  GetInvestorSmartMatchSuggestions,
  SmartMatchSuggestionsItemsForInvestor,
} from '@pages/content/lens/api/get-investor-smart-match-suggestion';
import { DEFAULT_ITEMS_PER_PAGE_IN_GRID, ItemsGrid } from '@parts/items-grid/items-grid';
import { useQueryPagination } from '@utils/hooks/use-query-pagination/use-query-pagination';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import type { ParsedQuery } from 'query-string';
import type { ReactNode } from 'react';
import type { GraphqlResponse } from 'src/types';
import { FoundersFiltering } from '../filters/filters';
import { InvestorsFiltering } from '../investors-filters/investors-filters';
import { CountryCodes } from '@utils/type-definitions/iso-to-country-name';

interface IMatchesGridProps {
  getSmartMatchSuggestion: (
    start: number,
    limit: number,
    excludedParams: string[],
    otherParams?: ParsedQuery<string>,
  ) => () => Promise<GraphqlResponse<GetInvestorSmartMatchSuggestions | GetFounderSmartMatchSuggestions>>;
  Item: Function;
  smartMatchSuggestionCacheKey: string;
  discoverable: boolean;
  refetchInvitationsCount?: Function;
}

export const MatchesGrid = ({
  getSmartMatchSuggestion,
  Item,
  smartMatchSuggestionCacheKey,
  discoverable,
  refetchInvitationsCount,
}: IMatchesGridProps) => {
  const [emptyTitle, emptySubTitle] = useTranslation([
    'lens.investor.emptyMatched.title',
    'lens.investor.emptyMatched.subTitle',
  ]);

  const {
    state: { userRole },
    helpers: { isFrom },
  } = useUserAccount();

  const shouldParseSeisEis = isFrom(CountryCodes.GB);

  const excludedParamsArr = shouldParseSeisEis ? [] : ['hasEisLimitsRemaining', 'hasSeisLimitsRemaining'];

  const { items, total, isLoading, refetch, ...paginationConfig } = useQueryPagination<
    SmartMatchSuggestionsItemsForInvestor | SmartMatchSuggestionForFounderItems
  >({
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE_IN_GRID,
    pageParam: 'page',
    action: getSmartMatchSuggestion,
    cacheKey: smartMatchSuggestionCacheKey,
    excludedParams: excludedParamsArr,
  });

  const refetchSmartMatch = () => {
    refetch();
    if (refetchInvitationsCount) {
      refetchInvitationsCount();
    }
  };

  const filtersElements: Partial<Record<Roles | 'default', ReactNode>> = {
    [Roles.INVESTOR]: <FoundersFiltering />,
    [Roles.INVESTOR_NED]: <FoundersFiltering />,
    [Roles.FOUNDER]: <InvestorsFiltering />,
    default: null,
  };

  return (
    <>
      {filtersElements[userRole] ?? filtersElements.default}
      <ItemsGrid
        pagination={paginationConfig}
        keyBase="smart-match"
        Item={Item}
        isLoading={isLoading}
        data={items}
        itemProps={{ discoverable, refetchSmartMatch }}
        emptyConfig={{
          isEmpty: items.length === 0,
          title: emptyTitle,
          subTitle: emptySubTitle,
          iconUrl: EmptyBrowsing,
        }}
      />
    </>
  );
};

import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const Text = styled.p`
  font-size: ${({ theme }) => theme.v2.typography.sizes.sm};
  font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[800], theme.v2.colors.neutral[50])};
`;

const Button = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`;

export default { Wrapper, Text, Button };

import message from '@/parts/message/message';
import { useMutation } from '@tanstack/react-query';
import { removeConnection } from '../api/remove-connection.action';

export const useRemoveConnection = (connectionId: string, callback: () => void) => {
  const mutation = useMutation(removeConnection(connectionId), {
    onSuccess: () => {
      callback();
      message.success({ content: 'The connection has been removed' });
    },
  });

  return {
    isRemoveConnectionLoading: mutation.isLoading,
    removeConnection: mutation.mutate,
    ogMutation: mutation,
  };
};

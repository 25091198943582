import styled from 'styled-components';

const PhoneNumberInputWrapper = styled.div<{ error: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .ant-select-arrow {
    .anticon {
      background-color: ${({ theme }) => theme.baseColors.Blue700};
    }
    path {
      fill: ${({ theme }) => theme.baseColors.Grey50};
    }
  }

  .antd-country-phone-input {
    height: 52px;
    padding: 0;
    border: none;
    background-color: ${({ error, theme }) => (error ? theme.baseColors.Red : theme.baseColors.Grey50)};
    transition: background 0.3s;

    :hover {
      border: none;
    }
    :focus {
      border: none;
      box-shadow: none;
    }

    :focus-within {
      background: unset;
      .ant-input,
      .ant-select,
      .ant-select input {
        background-color: ${({ theme }) => theme.registerColors.inputBackground};
        color: ${({ theme }) => theme.baseColors.White};
      }
      .ant-select-selection-item {
        color: ${({ theme }) => theme.baseColors.White};
      }
      .ant-select-arrow {
        .anticon {
          background-color: ${({ theme }) => theme.registerColors.inputBackground};
        }
        path {
          fill: ${({ theme }) => theme.baseColors.White};
        }
      }
    }

    .ant-input,
    .ant-select {
      background-color: ${({ theme }) => theme.registerColors.inputBackground};
      color: ${({ theme }) => theme.baseColors.White};
      height: 52px;
    }
    .ant-input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .ant-input-prefix {
      align-items: flex-start;
      margin-right: 1px;
    }
    .ant-select-selection-search-input {
      height: 52px !important;
    }
    .ant-select-selection-item {
      line-height: 52px;
      color: ${({ theme }) => theme.baseColors.White};
    }
  }
`;

export default { PhoneNumberInputWrapper };

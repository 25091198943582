import GreenTickBase from '@assets/icons/green-circled-tick.svg?react';
import { Tabs as TabsBase } from '@parts/tabs/tabs';
import styled from 'styled-components';

const GreenTick = styled(GreenTickBase)`
  margin-right: ${({ theme }) => theme.spacing.small};
`;

const RequestIntroPending = styled.p`
  font-weight: 600;
  margin: ${({ theme }) => `${theme.spacing.medium} 0`};
  display: flex;
  align-items: center;
  > svg {
    margin-right: ${({ theme }) => theme.spacing.xsmall};
  }
`;

const PortfolioBadge = styled.span`
  background: ${({ theme }) => theme.gradient.green};
  border-radius: 5px;
  color: ${({ theme }) => theme.baseColors.White};
  display: block;
  font-weight: 500;
  padding: 2px 5px;
  text-transform: uppercase;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.xsmall}) {
    bottom: 20px;
    left: 75px;
    position: absolute;
  }

  &:not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing.xsmall};
  }
`;

const Tabs = styled(TabsBase)`
  .ant-tabs-nav {
    &::before {
      border: none;
    }
  }
`;

export default { RequestIntroPending, PortfolioBadge, Tabs, GreenTick };

import { Section as SectionBase } from '@parts/section/section';
import { Col as ColBase } from 'antd';
import styled from 'styled-components';

const Col = styled(ColBase)`
  padding-right: ${({ theme }) => theme.spacing.base};
  border-right: 1px solid ${({ theme }) => theme.color.border};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    padding-right: 0;
    border-right: 0;
  }
`;

const Section = styled(SectionBase)`
  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    padding-right: 0;
  }
`;

const Editor = styled.div`
  margin-left: ${({ theme }) => theme.spacing.base};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    margin-left: 0;
  }
`;

export default {
  Editor,
  Col,
  Section,
};

import { startInvestorOnboardingAction } from '@/pages/content/onboarding/investor/api/start-investor-onboarding.action';
import ArrowRightIcon from '@assets/icons/arrow-right.svg';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import message from '@parts/message/message';
import { Links } from '@router/links';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useInvestorOnboardingContext } from '@utils/hooks/use-onboarding-context/use-investor-onboarding-context';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import anime from 'animejs';
import { useEffect, useMemo } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { AnimatedLogo } from './parts/animated-logo/animated-logo';
import S from './welcome-page.styles';

export const WelcomePageContent = () => {
  const history = useHistory();

  const [startLabel, welcomeLabel, descriptionFirst, descriptionSecond] = useTranslation([
    'navigation.wizard.footer.startSetup',
    'investor-onboarding.welcome.title',
    'investor-onboarding.welcome.description1',
    'investor-onboarding.welcome.description2',
  ]);

  const { state: onboardingState, refetch } = useInvestorOnboardingContext();

  const { mutateAsync: startOnboarding, isLoading: isMutationLoading } = useMutation(startInvestorOnboardingAction, {
    async onSuccess() {
      await refetch();
      history.push(Links.ONBOARDING_INVESTOR());
    },
    onError(err: AxiosError) {
      message.error({ content: getServerError(err) });
    },
  });

  const {
    deviceData: { isMobile },
  } = useDeviceDetect();

  const animatedLogo = useMemo(() => <AnimatedLogo />, []);

  useEffect(() => {
    anime({
      duration: 5000,
      targets: '#welcome-text, #welcome-lower-text',
      opacity: 1,
      delay: 3250,
    });

    anime({
      targets: '#welcome-button',
      duration: 0,
      translateY: 50,
    });

    anime({
      targets: '#welcome-button',
      duration: 500,
      delay: 3750,
      translateY: isMobile ? 35 : -100,
      opacity: 1,
    });

    return () => {
      anime.remove('#welcome-text, #welcome-lower-text');
      anime.remove('#welcome-button');
    };
  }, [isMobile]);

  if (!onboardingState.loaded) return <FullHeightSpinner $background small />;

  if (onboardingState.started) {
    return <Redirect to={Links.ONBOARDING_INVESTOR()} />;
  }

  return (
    <S.Wrapper id="onboarding-welcome-page" data-testid="onboarding-welcome-page">
      <S.WelcomeText id="welcome-text">{welcomeLabel}</S.WelcomeText>
      {animatedLogo}
      <S.LowerText id="welcome-lower-text">
        <S.Text $bolded>{descriptionFirst}</S.Text>
        <S.Text>{descriptionSecond}</S.Text>
      </S.LowerText>
      <S.Button
        id="welcome-button"
        data-testid="onboarding-welcome-start-button"
        onClick={() => startOnboarding()}
        disabled={isMutationLoading}
      >
        {startLabel} <img src={ArrowRightIcon} alt="arrow-right" loading="lazy" />
      </S.Button>
    </S.Wrapper>
  );
};

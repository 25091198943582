import { V2CircleProgressBar } from '@/components/ui/v2-circle-progress-bar/v2-circle-progress-bar';
import { V2Title } from '@/components/ui/v2-title/v2-title';
import { useBuildYourProfileModal } from '@/modules/advisor/homepage/components/build-your-profile/hooks/use-build-your-profile-modal';
import { useGetAdvisorProfileReadiness } from '@/modules/advisor/homepage/hooks/use-get-advisor-profile-readiness';
import { calculateTotalPercentage } from '@/modules/advisor/homepage/utils/calculate-total-percentage';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import type { HtmlHTMLAttributes } from 'react';

import S from './build-your-profile-score.styles';

export const BuildYourProfileScore = (props: HtmlHTMLAttributes<HTMLDivElement>) => {
  const [title] = useTranslation(['profile.ned.section.personalDetails.buildYourProfileScore.title']);
  const { advisorProfileReadinessData } = useGetAdvisorProfileReadiness();
  const { modal, open } = useBuildYourProfileModal();

  const progressScore = advisorProfileReadinessData?.data
    ? calculateTotalPercentage(advisorProfileReadinessData.data)
    : 0;

  return (
    <div {...props}>
      <S.BuildYourProfileScoreButton
        onClick={open}
        data-test-id="profile-build-your-profile-score-button"
        type="button"
      >
        <V2CircleProgressBar percent={progressScore} valuePostfix="%" variant="magenta" size="small" />
        <V2Title title={title} size="xs" />
      </S.BuildYourProfileScoreButton>
      {modal}
    </div>
  );
};

import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './archived-label.styles';

export const Archived = ({ ...rest }) => {
  const [archivedLabel] = useTranslation(['portfolio.archived']);

  return (
    <S.Wrapper {...rest}>
      <S.Text>{archivedLabel}</S.Text>
    </S.Wrapper>
  );
};

// TODO: CON-11593 The code needs refactoring.
//  The router should be designed to pass the
//  layout and inject the component into the outlet.

import { Roles } from '@domain/accounts/roles';
import * as Pages from '@pages';
import { Routes } from '@router/routes';
import type { ComponentType } from 'react';
import { Redirect, type RouteComponentProps } from 'react-router-dom';
import type { Any } from 'src/types';

export interface RouteConfig {
  component: ComponentType<RouteComponentProps<Any>> | ComponentType<Any>;
  exact?: boolean;
  permissions: {
    // Empty array means that route is forbidden for all roles and permissions will be assigned based on ApiDefinedPermissions;
    allowedRoles: Roles[];
    activeUser: boolean;
    investorHasToBeOnboarded?: boolean;
  };
}

export type RouteConfigRecord = Record<Routes, RouteConfig>;

export const AppRouteConfig: () => RouteConfigRecord = () => ({
  [Routes.HOME]: {
    component: () => <Redirect to={Routes.START_PAGE} />,
    permissions: {
      allowedRoles: [Roles.INVESTOR, Roles.FOUNDER, Roles.NED, Roles.INVESTOR_NED],
      activeUser: true,
      investorHasToBeOnboarded: true,
    },
  },
  [Routes.START_PAGE]: {
    component: Pages.StartPage,
    permissions: {
      allowedRoles: [Roles.INVESTOR, Roles.FOUNDER, Roles.NED, Roles.INVESTOR_NED],
      activeUser: true,
      investorHasToBeOnboarded: true,
    },
  },
  [Routes.FOUNDER_CONNECTIONS]: {
    component: Pages.FounderConnectionsPage,
    permissions: {
      allowedRoles: [Roles.FOUNDER],
      activeUser: true,
    },
  },
  [Routes.ADVISOR_CONNECTIONS]: {
    component: Pages.AdvisorConnectionsPage,
    permissions: {
      allowedRoles: [Roles.NED],
      activeUser: true,
    },
  },
  [Routes.INVESTOR_ADVISOR_CONNECTIONS]: {
    component: Pages.InvestorAdvisorConnectionsPage,
    permissions: {
      allowedRoles: [Roles.INVESTOR_NED],
      activeUser: true,
    },
  },
  [Routes.EXTEND_PRODUCTS]: {
    component: Pages.ExtendSubscriptionPage,
    permissions: {
      allowedRoles: [Roles.INVESTOR, Roles.NED, Roles.FOUNDER],
      activeUser: true,
      investorHasToBeOnboarded: true,
    },
  },
  [Routes.UPGRADE_PRODUCTS]: {
    component: Pages.UpgradeSubscriptionPage,
    permissions: { allowedRoles: [Roles.FOUNDER], activeUser: true, investorHasToBeOnboarded: true },
  },
  [Routes.LOGIN]: {
    component: Pages.LoginPage,
    permissions: { allowedRoles: [Roles.NO_ROLE], activeUser: false },
  },
  [Routes.PORTFOLIO_COMPANY_DETAILS]: {
    component: Pages.CompanyDetailsPage,
    permissions: {
      allowedRoles: [], // All roles - access based on api defined permissions
      activeUser: true,
      investorHasToBeOnboarded: true,
    },
  },
  [Routes.PORTFOLIO_EXPANDED_VIEW]: {
    component: Pages.ExpandView,
    permissions: {
      allowedRoles: [], // All roles - access based on api defined permissions
      activeUser: true,
      investorHasToBeOnboarded: true,
    },
  },
  [Routes.PORTFOLIO_EXPANDED_VIEW_SINGLE]: {
    component: Pages.ExpandView,
    permissions: {
      allowedRoles: [], // All roles - access based on api defined permissions
      activeUser: true,
      investorHasToBeOnboarded: true,
    },
  },
  [Routes.PORTFOLIO]: {
    component: Pages.PortfolioPage,
    permissions: {
      allowedRoles: [], // All roles - access based on api defined permissions
      activeUser: true,
      investorHasToBeOnboarded: true,
    },
  },
  [Routes.REGISTER]: {
    component: Pages.ChooseRolePage,
    permissions: { allowedRoles: [Roles.NO_ROLE], activeUser: false },
  },
  [Routes.REGISTER_INVITED_INVESTOR]: {
    component: Pages.InvitationRegistrationPage,
    permissions: { allowedRoles: [Roles.NO_ROLE], activeUser: false },
  },
  [Routes.REGISTER_INVITED_FOUNDER]: {
    component: Pages.InvitationRegistrationPage,
    permissions: { allowedRoles: [Roles.NO_ROLE], activeUser: false },
  },
  [Routes.REGISTER_ROLE]: {
    component: Pages.RegisterFormPage,
    permissions: { allowedRoles: [Roles.NO_ROLE], activeUser: false },
  },
  [Routes.EMAIL_UNVERIFIED]: {
    component: Pages.UnverifiedEmailPage,
    permissions: { allowedRoles: [Roles.NO_ROLE], activeUser: false },
  },
  [Routes.REDIRECT_TO_REGISTER]: {
    component: Pages.RedirectToRegisterPage,
    permissions: { allowedRoles: [Roles.NO_ROLE], activeUser: false },
  },
  [Routes.LEGAL]: {
    component: Pages.LegalPage,
    permissions: {
      allowedRoles: [], // All roles - access based on api defined permissions
      activeUser: true,
      investorHasToBeOnboarded: true,
    },
  },
  [Routes.PULSE]: {
    component: Pages.PulsePage,
    permissions: {
      allowedRoles: [Roles.INVESTOR, Roles.FOUNDER, Roles.NED, Roles.INVESTOR_NED],
      activeUser: true,
      investorHasToBeOnboarded: true,
    },
  },
  [Routes.PROFILE]: {
    component: Pages.ProfilePage,
    permissions: {
      allowedRoles: [Roles.INVESTOR, Roles.FOUNDER, Roles.NED, Roles.INVESTOR_NED],
      activeUser: true,
      investorHasToBeOnboarded: true,
    },
  },
  [Routes.MULTI_ROLE_PROFILE]: {
    component: Pages.ProfilePage,
    permissions: {
      allowedRoles: [Roles.INVESTOR, Roles.NED, Roles.INVESTOR_NED],
      activeUser: true,
      investorHasToBeOnboarded: true,
    },
  },
  [Routes.INVESTOR_PUBLIC_PROFILE]: {
    component: Pages.InvestorPreviewPage,
    permissions: { allowedRoles: [Roles.FOUNDER], activeUser: true, investorHasToBeOnboarded: true },
  },
  [Routes.FOUNDER_PUBLIC_PROFILE]: {
    component: Pages.FounderPublicPage,
    permissions: {
      allowedRoles: [Roles.INVESTOR, Roles.NED, Roles.INVESTOR_NED],
      activeUser: true,
      investorHasToBeOnboarded: true,
    },
  },
  [Routes.NED_PUBLIC_PROFILE]: {
    component: Pages.NedPublicPage,
    permissions: { allowedRoles: [Roles.FOUNDER], activeUser: true, investorHasToBeOnboarded: true },
  },
  [Routes.PROGRESS_REPORT]: {
    component: Pages.ProgressReportPage,
    permissions: {
      allowedRoles: [Roles.INVESTOR, Roles.FOUNDER, Roles.NED, Roles.NO_ROLE, Roles.INVESTOR_NED],
      activeUser: false,
    },
  },
  [Routes.CHECKOUT]: {
    component: Pages.CheckoutPage,
    permissions: {
      allowedRoles: [Roles.FOUNDER, Roles.NED, Roles.INVESTOR_NED],
      activeUser: false,
    },
  },
  [Routes.CHECKOUT_SUCCESS]: {
    component: Pages.SuccessfulPaymentPage,
    permissions: {
      allowedRoles: [Roles.FOUNDER, Roles.NED, Roles.INVESTOR, Roles.INVESTOR_NED],
      activeUser: true,
    },
  },
  [Routes.CHECKOUT_CANCEL]: {
    component: Pages.PaymentCancelledPage,
    permissions: {
      allowedRoles: [Roles.FOUNDER, Roles.NED, Roles.INVESTOR, Roles.INVESTOR_NED],
      activeUser: false,
    },
  },
  [Routes.PAYMENT_IN_PROGRESS]: {
    component: Pages.PaymentInProgressPage,
    permissions: {
      allowedRoles: [Roles.FOUNDER, Roles.NED, Roles.INVESTOR, Roles.INVESTOR_NED],
      activeUser: false,
    },
  },
  [Routes.ROLES]: {
    component: Pages.CataloguePage,
    permissions: {
      allowedRoles: [Roles.NED, Roles.FOUNDER, Roles.INVESTOR_NED],
      activeUser: true,
      investorHasToBeOnboarded: true,
    },
  },
  [Routes.CREATE_NEW_ROLE]: {
    component: Pages.CreateRolePage,
    permissions: { allowedRoles: [Roles.FOUNDER], activeUser: true, investorHasToBeOnboarded: true },
  },
  [Routes.EDIT_ROLE]: {
    component: Pages.EditRolePage,
    permissions: { allowedRoles: [Roles.FOUNDER], activeUser: true, investorHasToBeOnboarded: true },
  },
  [Routes.PREVIEW_ROLE]: {
    component: Pages.PreviewRolePage,
    permissions: {
      allowedRoles: [Roles.INVESTOR, Roles.FOUNDER, Roles.NED, Roles.INVESTOR_NED],
      activeUser: true,
      investorHasToBeOnboarded: true,
    },
  },
  [Routes.ROLE_APPLICATIONS]: {
    component: Pages.ListApplicationsPage,
    permissions: { allowedRoles: [Roles.FOUNDER], activeUser: true, investorHasToBeOnboarded: true },
  },
  [Routes.LENS]: {
    component: Pages.LensPage,
    permissions: {
      allowedRoles: [Roles.INVESTOR, Roles.FOUNDER, Roles.NED, Roles.INVESTOR_NED],
      activeUser: true,
      investorHasToBeOnboarded: true,
    },
  },
  [Routes.ADVISORS]: {
    component: Pages.AdvisorsPage,
    permissions: { allowedRoles: [Roles.FOUNDER], activeUser: true, investorHasToBeOnboarded: true },
  },
  [Routes.ADVISORS_SEARCH_ROLE]: {
    component: Pages.AdvisorsSearchPage,
    permissions: { allowedRoles: [Roles.FOUNDER], activeUser: true, investorHasToBeOnboarded: true },
  },
  [Routes.MESSAGING]: {
    component: Pages.MessagingPage,
    permissions: {
      allowedRoles: [Roles.INVESTOR, Roles.FOUNDER, Roles.NED, Roles.INVESTOR_NED],
      activeUser: true,
      investorHasToBeOnboarded: true,
    },
  },
  [Routes.NOTIFICATIONS]: {
    component: Pages.NotificationsPage,
    permissions: {
      allowedRoles: [Roles.INVESTOR, Roles.FOUNDER, Roles.NED, Roles.INVESTOR_NED],
      activeUser: true,
      investorHasToBeOnboarded: true,
    },
  },
  [Routes.ONBOARDING_FOUNDER_STEP]: {
    component: Pages.FounderOnboardingStepPage,
    exact: false,
    permissions: {
      allowedRoles: [Roles.FOUNDER],
      activeUser: true,
    },
  },
  [Routes.ONBOARDING_INVESTOR_SUMMARY]: {
    component: Pages.InvestorOnboardingSummaryPage,
    permissions: {
      allowedRoles: [Roles.INVESTOR, Roles.NED, Roles.INVESTOR_NED],
      activeUser: false,
      investorHasToBeOnboarded: true,
    },
  },
  [Routes.ONBOARDING_INVESTOR]: {
    component: Pages.InvestorOnboardingRoutingPage,
    exact: false,
    permissions: {
      allowedRoles: [Roles.INVESTOR, Roles.NED],
      activeUser: false,
    },
  },
  [Routes.INVESTOR_AWAITING_APPROVAL]: {
    component: Pages.SplashScreenPage,
    permissions: {
      allowedRoles: [Roles.INVESTOR, Roles.INVESTOR_NED],
      activeUser: false,
      investorHasToBeOnboarded: true,
    },
  },
  [Routes.ACCESS_NED_ACADEMY]: {
    component: Pages.NedAcademyAccessPage,
    permissions: {
      allowedRoles: [Roles.NED, Roles.INVESTOR_NED],
      activeUser: true,
    },
  },
  [Routes.MANAGE_METRIC]: {
    component: Pages.ManagementPage,
    permissions: { allowedRoles: [Roles.FOUNDER], activeUser: true, investorHasToBeOnboarded: true },
  },
  [Routes.INVESTOR_CONFIRM_INVESTMENT]: {
    component: Pages.InvestmentConfirmationPage,
    permissions: {
      allowedRoles: [Roles.INVESTOR, Roles.INVESTOR_NED],
      activeUser: true,
      investorHasToBeOnboarded: true,
    },
  },
  [Routes.FOUNDER_CONFIRM_INVESTMENT]: {
    component: Pages.InvestmentConfirmationPage,
    permissions: { allowedRoles: [Roles.FOUNDER], activeUser: true, investorHasToBeOnboarded: true },
  },
  [Routes.COR_SELECTION]: {
    component: Pages.CountryOfResidencePage,
    permissions: {
      allowedRoles: [Roles.INVESTOR, Roles.FOUNDER, Roles.NED, Roles.INVESTOR_NED],
      activeUser: false,
    },
  },
  [Routes.INTERNAL_SERVER_ERROR]: {
    component: Pages.InternalServerErrorPage,
    permissions: {
      allowedRoles: [Roles.INVESTOR, Roles.FOUNDER, Roles.NED, Roles.NO_ROLE, Roles.INVESTOR_NED],
      activeUser: false,
    },
  },
  [Routes.NOT_FOUND]: {
    component: Pages.NotFoundPage,
    permissions: {
      allowedRoles: [Roles.INVESTOR, Roles.FOUNDER, Roles.NED, Roles.NO_ROLE, Roles.INVESTOR_NED],
      activeUser: false,
    },
  },
  [Routes.CATCH_ALL]: {
    component: Pages.NotFoundPage,
    permissions: {
      allowedRoles: [Roles.INVESTOR, Roles.FOUNDER, Roles.NED, Roles.NO_ROLE, Roles.INVESTOR_NED],
      activeUser: false,
    },
  },
});

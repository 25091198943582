import { FounderLayout } from '@layout/founder/founder.layout';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { useQuery } from '@tanstack/react-query';
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import type { RawDraftContentState } from 'draft-js';
import { useState } from 'react';
import {
  GET_FOUNDER_PROFILE_CACHE_KEY,
  getFounderProfileAction,
} from './api/get-founder-profile/get-founder-profile.action';
import { Editor } from './parts/editor/editor';
import { Preview } from './parts/preview/preview';

export type File = {
  path: string;
  name: string;
  url?: string;
};

export interface CompanyDetailsData {
  logoPath: string | null;
  logoUrl: string | null;
  linkedinUrl: string | null;
  email: string;
  twitterUrl: string | null;
  aboutCompany: RawDraftContentState | null;
  achievements: RawDraftContentState | null;
  countryOfResidence: CountryCodes;
  businessName: string;
  representativeFirstName: string;
  representativeLastName: string | null;
  phoneNumber: string | null;
  founded: string | null;
}

export interface FounderSmartMatchData {
  industries: string[];
  customerGroup: string[];
  productStage: string | null;
  investmentStage: string;
  hasSeisLimitsRemaining?: boolean;
  hasEisLimitsRemaining?: boolean;
}

export interface AdditionalInfoData {
  pitchDeck: File | null;
  productVideoUrl: string | null;
  otherFiles: File[];
  financialModelFiles: File[];
  hasInvestorReadyMaterials: boolean;
}

export const FounderProfilePage = () => {
  const [previewActive, setPreviewActive] = useState(false);

  const {
    data: response,
    isLoading: loading,
    refetch: refetchFounderProfile,
  } = useQuery([GET_FOUNDER_PROFILE_CACHE_KEY], getFounderProfileAction, {
    cacheTime: 100,
    staleTime: 100,
    refetchOnWindowFocus: false,
  });

  if (!response || !response.data || loading) {
    return <FullHeightSpinner />;
  }

  const { additionalInformation, teamMembers, marketFitQuestions, smartMatch, ...founderData } = response.data;

  const { logo, aboutCompany, ...companyDetails } = founderData.personalDetail;

  return (
    <FounderLayout>
      {previewActive ? (
        <Preview
          data={{
            ...companyDetails,
            ...smartMatch,
            ...additionalInformation,
            teamMembers,
            marketFitAnswers: marketFitQuestions.map(({ lengthLimits, ...item }) => ({ ...item })),
            profilePicture: {
              url: logo?.url,
              path: logo?.path,
            },
            about: aboutCompany,
          }}
          switchToEdit={() => setPreviewActive(false)}
        />
      ) : (
        <Editor
          data={{
            smartMatch,
            teamMembers,
            marketFitQuestions,
            additionalInformation,
            companyDetails: {
              ...companyDetails,
              aboutCompany,
              logoPath: logo?.path,
              logoUrl: logo?.url,
            },
          }}
          switchToPreview={() => setPreviewActive(true)}
          refetchFounderProfile={refetchFounderProfile}
        />
      )}
    </FounderLayout>
  );
};

import message from '@/parts/message/message';
import { getServerError } from '@/utils/fns/get-server-error';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { removeConnectionNote } from '../api/remove-connection-note.action';

export const useRemoveConnectionNote = ({
  onSuccessCb,
  onErrorCb,
}: {
  onSuccessCb?: () => void | Promise<unknown>;
  onErrorCb?: () => void | Promise<unknown>;
} = {}) => {
  const mutation = useMutation({
    mutationFn: removeConnectionNote,
    onSuccess: () => {
      message.success({ content: 'The note has been removed' });
      onSuccessCb?.();
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
      onErrorCb?.();
    },
  });

  return {
    isRemoveConnectionNoteLoading: mutation.isLoading,
    removeConnectionNote: mutation.mutateAsync,
    rawMutation: {
      ...mutation,
    },
  };
};

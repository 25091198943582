import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import styled from 'styled-components';

const V2Grid = styled.div`
  display: grid;
  gap: 20px;

  ${customMediaQuery('grid-template-columns', {
    xs: '1fr',
    sm: 'repeat(2, 1fr)',
    md: 'repeat(3, 1fr)',
    lg: 'repeat(4, 1fr)',
  })}
`;

export default { V2Grid };

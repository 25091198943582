import { UpgradeFreemium } from '@layout/parts/upgrade-freemium/upgrade-freemium';
import { Routes } from '@router/routes';
import type { ButtonProps } from 'antd/lib/button';
import { memo, type ReactNode } from 'react';
import type { ModalProps as ModalPropsBase } from '../modal';
import S from './extensive-modal.styles';

export interface UpgradeModalProps extends Omit<ModalPropsBase, 'children'> {
  closeModal: () => void;
  visible: boolean;
  okButtonProps?: ButtonProps;
  closeIcon?: ReactNode;
  closable?: boolean;
  okText?: string | ReactNode;
  cancelText?: string | ReactNode;
  testId?: string;
  darkTitle: string;
  blueTitle: string;
  listItems: { icon: string; text: string; alt: string }[];
  submitButtonProps: { text: ReactNode; link: Routes };
  cancelButtonProps?: { text: ReactNode; onClick: () => void };
}

const ExtensiveModal = ({
  closeModal,
  visible,
  onOk,
  okButtonProps,
  onCancel,
  closeIcon,
  closable = false,
  cancelText = 'No, thanks',
  okText = 'Upgrade',
  testId,
  darkTitle,
  blueTitle,
  listItems,
  submitButtonProps,
  cancelButtonProps,
}: UpgradeModalProps) => {
  const defaultCloseButtonProps = { text: cancelText, onClick: closeModal };

  return (
    <S.Modal
      visible={visible}
      onCancel={onCancel ?? closeModal}
      footer={null}
      okButtonProps={okButtonProps}
      onOk={onOk}
      closeIcon={closeIcon ?? <S.Close />}
      centered
      closable={closable}
      okText={okText}
      cancelText={cancelText}
      data-testid={testId}
    >
      <UpgradeFreemium
        darkTitle={darkTitle}
        blueTitle={blueTitle}
        listItems={listItems}
        submitButtonProps={submitButtonProps}
        cancelButtonProps={cancelButtonProps ?? defaultCloseButtonProps}
      />
    </S.Modal>
  );
};

export default memo(ExtensiveModal);

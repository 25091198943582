import { isActiveFounder, isActiveNed } from '@abilities';
import { Roles } from '@domain/accounts/roles';
import { DateFormat } from '@i18n/date-formats';
import { FounderLayout } from '@layout/founder/founder.layout';
import { InvestorNedLayout } from '@layout/investor-ned/investor-ned.layout';
import { InvestorLayout } from '@layout/investor/investor.layout';
import {
  confirmInvestmentActionFounder,
  confirmInvestmentActionInvestor,
} from '@pages/content/investment-confirmation/api/confirm-investment';
import AvatarWithName from '@parts/avatar-with-name/avatar-with-name';
import ConnectionAlert from '@parts/connection-alert-editor/connection-alert';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import LabelRow from '@parts/label-row/label-row';
import message from '@parts/message/message';
import { PageHeader } from '@parts/page-header/page-header';
import { Section } from '@parts/section/section';
import { Links } from '@router/links';
import { Routes } from '@router/routes';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { currencyToShortFormat } from '@utils/fns/currency-to-short-format';
import { getLastArrayElement } from '@utils/fns/get-last-array-element';
import { getServerError } from '@utils/fns/get-server-error';
import useConfirmModal from '@utils/hooks/use-confirm-modal/use-confirm-modal';
import useConnectionModal from '@utils/hooks/use-connection-modal/use-connection-modal';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { useMemo, useState } from 'react';
import { AdditionalInfo } from './additional-info';
import {
  GET_INVESTMENT_CLAIMED_BY_FOUNDER_CACHE_KEY,
  getInvestmentClaimedByFounderAction,
} from './api/get-investment-claimed-by-founder.action';
import {
  GET_INVESTMENT_CLAIMED_BY_INVESTOR_CACHE_KEY,
  getInvestmentClaimedByInvestorAction,
  type ClaimedInvestmentByInvestor,
} from './api/get-investment-claimed-by-investor.action';
import S from './investment-confirmation.styles';

export const InvestmentConfirmationPage = () => {
  const onConfirmRouteMapper: { [key in Roles]: Routes | string } = useMemo(
    () => ({
      [Roles.FOUNDER]: Links.PULSE_FOUNDER_INVESTORS(),
      [Roles.INVESTOR]: Links.PORTFOLIO(),
      [Roles.NO_ROLE]: Links.NOT_FOUND(),
      [Roles.INVESTOR_NED]: Links.PORTFOLIO(),
      [Roles.NED]: Links.NOT_FOUND(),
    }),
    [],
  );
  const [fundingRoundId, setFundingRoundId] = useState('');
  const [enableButton, setEnableButton] = useState(false);

  const { location, push } = useHistory();

  const investmentId = getLastArrayElement(location.pathname.split('/'));

  const {
    state: { products, userRole },
  } = useUserAccount();

  const isLoggedInAsFounder = userRole === Roles.FOUNDER;

  const Layout = (() => {
    if (isActiveFounder(products)) {
      return FounderLayout;
    }

    if (isActiveNed(products)) {
      return InvestorNedLayout;
    }

    return InvestorLayout;
  })();

  const { dateFormatter } = useUserDateFormat();

  const [
    titleLabel,
    confirmLabel,
    contentLabel,
    descriptionFounderLabel,
    descriptionInvestorLabel,
    companyLabel,
    investorLabel,
    dateLabel,
    roundLabel,
    sharesLabel,
    investedLabel,
    ownedLabel,
    warrantsLabel,
    optionsLabel,
    clnLabel,
    discountLabel,
    reviewLaterLabel,
    confirmInvestmentLabel,
    amountLabel,
    priceLabel,
    expiryLabel,
    successConfirmationMessage,
    confirmedAlert,
    archivedAlert,
    showInPortfolioLabel,
    showInShareholderLabel,
    notConnectedAlert,
    autoConnectAlert,
    confirmTitleModalLabel,
    confirmModalLabel,
    cancelModalLabel,
    contentModalLabel,
    contentQuestionModalLabel,
  ] = useTranslation([
    'investment.confirmation.title',
    'investment.confirmation.btn',
    'investment.confirmation.content',
    'investment.confirmation.founder.description',
    'investment.confirmation.investor.description',
    'investment.confirmation.company',
    'investment.confirmation.investor',
    'investment.confirmation.date',
    'investment.confirmation.round',
    'investment.confirmation.shares',
    'investment.confirmation.invested',
    'investment.confirmation.owned',
    'investment.confirmation.warrants',
    'investment.confirmation.options',
    'investment.confirmation.cln',
    'investment.confirmation.discount',
    'investment.confirmation.reviewLater',
    'investment.confirmation.confirmInvestment',
    'investment.confirmation.amount',
    'investment.confirmation.price',
    'investment.confirmation.expiry',
    'investment.confirmation.success.message',
    'investment.confirmation.confirmedAlert',
    'investment.confirmation.archivedAlert',
    'investment.confirmation.showInPortfolio',
    'investment.confirmation.showInShareholders',
    'investment.confirmation.notConnectedAlert',
    'investment.confirmation.autoConnectAlert',
    'investment.confirmation.modalConnection.title',
    'investment.confirmation.modalConnection.confirm',
    'investment.confirmation.modalConnection.cancel',
    'investment.confirmation.modalConnection.content',
    'investment.confirmation.modalConnection.contentQuestion',
  ]);

  const {
    Modal: ConnectionModal,
    open: isConnectionModalOpen,
    show: openConnectionModal,
    hide: hideConnectionModal,
  } = useConnectionModal();

  const showInLabelMapper: { [key in Roles]: Routes | string } = useMemo(
    () => ({
      [Roles.FOUNDER]: showInShareholderLabel,
      [Roles.INVESTOR]: showInPortfolioLabel,
      [Roles.NO_ROLE]: '',
      [Roles.INVESTOR_NED]: showInPortfolioLabel,
      [Roles.NED]: '',
    }),
    [showInShareholderLabel, showInPortfolioLabel],
  );

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery(
    [isLoggedInAsFounder ? GET_INVESTMENT_CLAIMED_BY_INVESTOR_CACHE_KEY : GET_INVESTMENT_CLAIMED_BY_FOUNDER_CACHE_KEY],
    isLoggedInAsFounder
      ? getInvestmentClaimedByInvestorAction(investmentId)
      : getInvestmentClaimedByFounderAction(investmentId),
    {
      onSuccess: (data) => {
        if (!data.data) return;

        const entity = data.data.entity;
        const openCOnnectionModal =
          !entity.isConnected &&
          isLoggedInAsFounder &&
          !(entity as ClaimedInvestmentByInvestor['entity']).isFollowRequestPending;

        if (openCOnnectionModal) openConnectionModal();
      },
    },
  );

  const { mutateAsync: confirmInvestment } = useMutation(
    isLoggedInAsFounder
      ? confirmInvestmentActionFounder(investmentId, fundingRoundId)
      : confirmInvestmentActionInvestor(investmentId),
    {
      onSuccess: () => {
        message.success({ content: successConfirmationMessage });
        push(onConfirmRouteMapper[userRole]);
      },
      onError: (err: AxiosError) => {
        message.error({ content: getServerError(err) });
      },
    },
  );

  const { modal: confirmModal, show: showModal } = useConfirmModal({
    title: titleLabel,
    confirmLabel,
    content: contentLabel,
    noCancel: true,
    onConfirm: () => {
      push(onConfirmRouteMapper[userRole]);
    },
  });

  const onConfirm = () => {
    confirmInvestment();
  };

  if (response?.data === null && !isLoading) {
    push(Routes.PROFILE);
    return null;
  }

  if (!response?.data || isLoading) {
    return <FullHeightSpinner />;
  }

  const {
    investment: { round, shareClass, shares, amount, percentageOwnership, terms },
    entity,
    investment,
  } = response.data;

  const isConfirmed = response?.data?.investment?.confirmed;
  const isArchived = response?.data?.investment?.archived;
  const showAdditionalInfo =
    isLoggedInAsFounder && (investment as ClaimedInvestmentByInvestor['investment']).shouldAssignFundingRound;

  const allowTriggerConnectionModal =
    !entity.isConnected &&
    isLoggedInAsFounder &&
    !(entity as ClaimedInvestmentByInvestor['entity']).isFollowRequestPending;

  return (
    <Layout>
      <PageHeader title={confirmInvestmentLabel} />
      <Section>
        <p>{isLoggedInAsFounder ? descriptionFounderLabel : descriptionInvestorLabel}</p>
        {!entity.isConnected && !isConfirmed && !isArchived && (
          <ConnectionAlert notConnectedAlertLabel={notConnectedAlert} autoConnectAlertLabel={autoConnectAlert} />
        )}
        <S.HR />
        <dl>
          <LabelRow dataTestId="avatar" label={isLoggedInAsFounder ? investorLabel : companyLabel}>
            <S.AvatarWithNameWrapper
              aria-hidden="true"
              onClick={openConnectionModal}
              cursorVisible={allowTriggerConnectionModal}
            >
              <AvatarWithName name={entity.name} avatarUrl={entity.avatarUrl || ''} avatarSize="medium" />
            </S.AvatarWithNameWrapper>
          </LabelRow>
          <S.HR />
          <LabelRow dataTestId="close_date" label={dateLabel}>
            {dateFormatter(round.date, DateFormat.LETTER_MONTH_LONG_YEAR)}
          </LabelRow>
          <LabelRow dataTestId="round" label={roundLabel}>
            {round.type}
          </LabelRow>
          {(shares || shareClass) && (
            <LabelRow dataTestId="shares" label={sharesLabel}>
              {shares}
              {shareClass && shares ? `, ${shareClass}` : shareClass}
            </LabelRow>
          )}
          {amount && (
            <LabelRow dataTestId="invested" label={investedLabel}>
              £{currencyToShortFormat(amount.toString())}
            </LabelRow>
          )}
          {percentageOwnership && (
            <LabelRow dataTestId="percentageOwnership" label={ownedLabel}>
              {percentageOwnership}%
            </LabelRow>
          )}
          {(terms.warrants.amount || terms.warrants.price || terms.warrants.expiry) && (
            <>
              <S.HR />
              <LabelRow dataTestId="warrants" spacedChildren label={warrantsLabel}>
                {terms.warrants.amount && (
                  <>
                    {amountLabel}: {terms.warrants.amount}
                  </>
                )}
                {terms.warrants.price && (
                  <>
                    {priceLabel}: £{currencyToShortFormat(terms.warrants.price.toString())}
                  </>
                )}
                {terms.warrants.expiry && (
                  <>
                    {expiryLabel}: {dateFormatter(round.date, DateFormat.LETTER_MONTH_LONG_YEAR)}
                  </>
                )}
              </LabelRow>
            </>
          )}
          {terms.options && (
            <LabelRow dataTestId="terms_options" label={optionsLabel}>
              {terms.options}
            </LabelRow>
          )}
          {terms.convertibleLoanNote && (
            <LabelRow dataTestId="convertible_loan_note" label={clnLabel}>
              {terms.convertibleLoanNote}
            </LabelRow>
          )}
          {terms.discount && (
            <LabelRow dataTestId="terms_discount" label={discountLabel}>
              {terms.discount}%
            </LabelRow>
          )}
        </dl>
        <S.HR />
        {showAdditionalInfo && (
          <AdditionalInfo
            investingRelationshipId={(investment as ClaimedInvestmentByInvestor['investment']).investingRelationshipId}
            setEnableButton={setEnableButton}
            fundingRoundId={fundingRoundId}
            setFundingRoundId={setFundingRoundId}
          />
        )}
        {!isConfirmed && !isArchived ? (
          <S.ReviewButtonsDiv>
            <S.Button
              disabled={showAdditionalInfo && !enableButton}
              data-testid="Founder_ConfirmInvestment_GotIt_Button"
              onClick={!showAdditionalInfo || (enableButton && showAdditionalInfo) ? onConfirm : undefined}
            >
              {confirmLabel}
            </S.Button>
            <S.ReviewBtn onClick={showModal}>{reviewLaterLabel}</S.ReviewBtn>
          </S.ReviewButtonsDiv>
        ) : (
          <S.Alert
            showIcon
            message={isConfirmed ? confirmedAlert : archivedAlert}
            description={
              <S.Link outer href={onConfirmRouteMapper[userRole]}>
                {showInLabelMapper[userRole]}
              </S.Link>
            }
            type="info"
          />
        )}
      </Section>

      {confirmModal}
      <ConnectionModal
        open={isConnectionModalOpen}
        hide={hideConnectionModal}
        key={entity.id}
        id={entity.id}
        refetch={refetch}
        confirmTitleLabel={confirmTitleModalLabel}
        contentLabel={contentModalLabel}
        nameOfRecipient={entity.name}
        contentQuestionLabel={contentQuestionModalLabel}
        confirmLabel={confirmModalLabel}
        cancelLabel={cancelModalLabel}
      />
    </Layout>
  );
};

import styled from 'styled-components';

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 25px;
`;

const CompanyName = styled.span`
  font-size: ${({ theme }) => theme.fontSize.xmedium};
  display: block;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  letter-spacing: 0.3px;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSize.regular};
  color: ${({ theme }) => theme.color.primary};
  align-self: flex-start;
  max-width: 100%;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  white-space: pre-line;
`;

export default { Content, CompanyName, Description };

import { CountryPhoneNumber } from '@parts/country-phone-number/country-phone-number';
import { isAppEnv } from '@utils/fns/is-app-env';
import { ONLY_NUMBERS_AND_DASHES_REGEXP } from '@utils/fns/reg-exp-validators';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import { Input } from 'antd';
import type { CountryPhoneInputValue } from 'antd-country-phone-input';
import type { FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import S from './auth-phone-number-input.styles';

interface AuthPhoneNumberInputProps<T> {
  formik: FormikProps<T>;
  field: keyof T;
  testId?: string;
  id?: string;
  disabled?: boolean;
  countryOfResidence: CountryCodes | '';
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const AuthPhoneNumberInput = <T,>({
  formik,
  field,
  countryOfResidence,
  id,
  testId,
  disabled,
}: AuthPhoneNumberInputProps<T>) => {
  const [phoneNumberLabel] = useTranslation(['register.form.phoneNumber']);
  const fieldName = field as string;
  const error = (formik.errors[field] && formik.touched[field]) as boolean;
  const [phoneNumberValue, setPhoneNumberValue] = useState<CountryPhoneInputValue>({
    phone: undefined,
    code: undefined,
    short: CountryCodes.GB,
  });

  useEffect(() => {
    if (countryOfResidence && !formik.touched[field]) {
      setPhoneNumberValue((prev) => ({ ...prev, short: countryOfResidence }));
    }
  }, [countryOfResidence]);

  const handlePhoneNumberChange = (inputValue: CountryPhoneInputValue) => {
    if (ONLY_NUMBERS_AND_DASHES_REGEXP.test(inputValue.phone!)) {
      const { code, phone, short } = inputValue;
      formik.setFieldTouched(fieldName, true);
      formik.setFieldValue(fieldName, `${code} ${phone}`);
      setPhoneNumberValue({ code, phone, short });
    }
  };

  useEffect(() => {
    if (phoneNumberValue.phone === '' && formik.values[field]) {
      const [code, phone] = (formik.values[field] as unknown as string).split(' ');
      setPhoneNumberValue({ code: Number(code), phone });
    }
  }, [formik.values[field]]);

  return (
    <S.PhoneNumberInputWrapper
      error={error}
      onBlur={() => {
        formik.setFieldTouched(fieldName, true);
      }}
    >
      {!isAppEnv('test') && (
        <CountryPhoneNumber disabled={disabled} onChange={handlePhoneNumberChange} value={phoneNumberValue} />
      )}
      <Input
        disabled={disabled}
        {...formik.getFieldProps(fieldName)}
        id={id}
        data-testid={testId}
        type="hidden"
        aria-label={phoneNumberLabel}
      />
    </S.PhoneNumberInputWrapper>
  );
};

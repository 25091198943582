import CloseIconBase from '@assets/icons/close-industry-select.svg?react';
import SearchIconBase from '@assets/icons/search.svg?react';
import { UploadedFile as UploadedFileBase } from '@pages/content/profile/founder/parts/editor/parts/additional-info/additional-info.styles';
import CheckboxFormikFieldBase from '@pages/content/profile/parts/checkbox-formik-field/checkbox-formik-field';
import { FileUpload as FileUploadBase } from '@parts/file-upload/file-upload';
import { Input as InputBase } from 'antd';
import styled from 'styled-components';

const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 70px;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    margin-top: 25px;
  }
`;

const ToggleButton = styled.button`
  margin-left: ${({ theme }) => theme.spacing.small};
  border: none;
  background: transparent;
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.onboardingColors.showMoreButton};
  cursor: pointer;
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.small};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`;

const Input = styled(InputBase)`
  width: 240px;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.onboardingColors.inputBorder};
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
  border-radius: 0;

  :focus-within,
  :focus,
  :hover {
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.onboardingColors.inputBorderFocus};
  }
`;

const SearchIcon = styled(SearchIconBase)`
  margin-right: ${({ theme }) => theme.spacing.xsmall};

  path {
    fill: ${({ theme }) => theme.onboardingColors.searchIcon};
  }
`;

const Error = styled.span`
  display: block;
  color: ${({ theme }) => theme.color.error};
  font-size: ${({ theme }) => theme.fontSize.small};
  margin-top: -12px;
`;

const SelectedIndustries = styled.div`
  border-bottom: 1px dashed ${({ theme }) => theme.baseColors.Grey150};
  padding-bottom: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const CloseIcon = styled(CloseIconBase)`
  margin-left: 8px;
`;

const FileUpload = styled(FileUploadBase)`
  min-width: 140px;
`;

const UploadedFile = styled(UploadedFileBase)`
  max-width: 500px;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    max-width: 300px;
  }
`;

export const CheckboxFormikField = styled(CheckboxFormikFieldBase)`
  label {
    margin-bottom: 0;
  }
`;

export default {
  Form,
  ToggleButton,
  Input,
  SearchIcon,
  InputWrapper,
  Error,
  SelectedIndustries,
  CloseIcon,
  FileUpload,
  UploadedFile,
  CheckboxFormikField,
};

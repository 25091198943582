/* eslint-disable no-console */

import { useModal } from '@/hooks/use-modal';
import { getServerError } from '@/utils/fns/get-server-error';
import { useQuery } from '@tanstack/react-query';
import axios, { type AxiosError } from 'axios';
import { satisfies, valid } from 'semver';
import { AppResetModal } from './app-reset-modal';

const REFETCH_TIME = 1000 * 60 * (process.env.MODE !== 'production' ? 1 : 15);
const LAST_PROMPT_TIMESTAMP_KEY = 'lastPromptTimestamp';
const PROMPT_INTERVAL = 6 * 60 * 60 * 1000;

export const AppVersionChecker = () => {
  const { isOpen: isModalOpen, close: closeModal, open: openModal } = useModal(false);

  const { data: releasedVersion } = useQuery(
    [APP_VERSION],
    async () => {
      const res = await axios.get<string>('/version.txt');
      return res.data;
    },
    {
      staleTime: REFETCH_TIME,
      refetchInterval: REFETCH_TIME,
      refetchIntervalInBackground: false,
      onSuccess: () => {
        if (releasedVersion) {
          const currentAppVersion = valid(APP_VERSION);
          const releasedAppVersion = valid(releasedVersion);

          console.log('currentAppVersion:', currentAppVersion);
          console.log('releasedAppVersion:', releasedAppVersion);

          if (currentAppVersion && releasedAppVersion) {
            const isAppOutdated = satisfies(currentAppVersion, `<${releasedAppVersion}`);

            const now = Date.now();
            const lastPromptTimestamp = localStorage.getItem(LAST_PROMPT_TIMESTAMP_KEY) ?? String(now);
            const isPromptIntervalPassed = now - Number(lastPromptTimestamp) > PROMPT_INTERVAL;

            if (isAppOutdated) {
              console.log('isAppOutdated:', isAppOutdated);
              console.log('Modal opened because the app is outdated and:');

              // If the prompt interval has passed, show the prompt and update the timestamp
              if (!lastPromptTimestamp || isPromptIntervalPassed) {
                // 2 if's for debugging - can be deleted if issue of random modal opening no longer occurs
                if (!lastPromptTimestamp) {
                  console.log('lastPromptTimestamp is not set');
                  console.log('lastPromptTimestamp before conversion:', lastPromptTimestamp);
                  console.log('lastPromptTimestamp after conversion:', !lastPromptTimestamp);
                }
                if (isPromptIntervalPassed) {
                  console.log('isPromptIntervalPassed has passed');
                  console.log('isPromptIntervalPassed:', isPromptIntervalPassed);
                }
                openModal();
                localStorage.setItem(LAST_PROMPT_TIMESTAMP_KEY, String(now));
              }
            }
          }
        }
      },
      onError: (error: AxiosError) => {
        console.error('Error fetching version:', getServerError(error));
      },
    },
  );

  const handleCloseModal = () => {
    closeModal();
    localStorage.setItem(LAST_PROMPT_TIMESTAMP_KEY, String(Date.now()));
  };

  // TODO Change to generic Confirm Modal when designs are done
  return isModalOpen ? <AppResetModal close={handleCloseModal} isOpen={isModalOpen} /> : null;
};

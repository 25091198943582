import { SubmitButton } from '@parts/submit-button/submit-button';
import styled from 'styled-components';

const Button = styled(SubmitButton)`
  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: calc(100vw - 40px);
  }
`;

export default {
  Button,
};

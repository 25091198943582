import { useEffect, useState } from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import S from './text-ellipsis.styles';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export const TextEllipsis = ({
  lines,
  text,
  recalcDeps = [],
  recalcDelay = 0,
  ellipsisText = '',
}: {
  lines: number;
  text: string;
  recalcDeps?: string[];
  recalcDelay?: number;
  ellipsisText?: string;
}) => {
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | null>(null);
  // rerender ResponsiveEllipsis component on change of the one of the deps
  const [key, setKey] = useState(() => recalcDeps.join());
  const ellipsisContent = ellipsisText ? `... ${` ${ellipsisText}`}` : '...';

  useEffect(() => {
    if (!recalcDelay) {
      setKey(recalcDeps.join());
      return;
    }

    if (timeoutId) clearTimeout(timeoutId);

    const id = setTimeout(() => {
      setKey(recalcDeps.join());
    }, recalcDelay);

    setTimeoutId(id);

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [recalcDeps.join()]);

  return (
    <S.StyledEllipsis>
      <ResponsiveEllipsis
        key={key}
        text={text}
        maxLine={lines}
        ellipsis={ellipsisContent}
        trimRight
        basedOn="letters"
      />
    </S.StyledEllipsis>
  );
};

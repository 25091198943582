import React from 'react';
import { V2Container } from '../v2-container/v2-container';
import { V2Icon } from '../v2-icon/v2-icon';
import S from './v2-notification-banner.styles';

export type NotificationBannerVariant = 'info' | 'error' | 'gradient';

type V2NotificationBannerProps = {
  variant?: NotificationBannerVariant;
  onClose?: () => void;
  closable?: boolean;
  containerized?: boolean;
  'data-testid'?: string;
};

export const V2NotificationBanner: React.FC<V2NotificationBannerProps> = ({
  children,
  variant = 'info',
  onClose,
  closable = false,
  containerized = false,
  'data-testid': dataTestId = 'v2-notification-bar',
}) => {
  const content = (
    <S.V2NotificationBannerContent>
      {closable ? (
        <S.V2NotificationBannerCloseButton data-testid="v2-notification-bar-close" onClick={onClose}>
          <V2Icon name="close" />
        </S.V2NotificationBannerCloseButton>
      ) : null}

      {children}
    </S.V2NotificationBannerContent>
  );

  return (
    <S.V2NotificationBanner $variant={variant} data-testid={dataTestId}>
      {containerized ? <V2Container>{content}</V2Container> : content}
    </S.V2NotificationBanner>
  );
};

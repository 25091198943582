import anime, { type AnimeAnimParams } from 'animejs';
import { useEffect, useRef, type RefObject } from 'react';

type ElementRevealAnimationConfig = Pick<AnimeAnimParams, 'duration' | 'delay'>;

export const useElementRevealAnimation = (
  elementAnimationId: string,
  animationConfig: ElementRevealAnimationConfig = {},
): RefObject<anime.AnimeInstance | null> => {
  const animation = useRef<anime.AnimeInstance | null>(null);

  useEffect(() => {
    animation.current = anime({
      targets: `[data-animation-id="${elementAnimationId}"]`,
      opacity: [0, 1],
      translateY: [-15, 0],
      duration: 700,
      easing: 'easeInOutSine',
      delay: 0,
      ...animationConfig,
    });

    return () => {
      anime.remove(`[data-animation-id="${elementAnimationId}"]`);
    };
  }, []);

  return animation;
};

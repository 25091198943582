import { EllipsisOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const DotsIcon = styled(EllipsisOutlined)`
  font-size: 200%;
  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
`;
const DropdownIconWrapper = styled.div`
  cursor: pointer;
  text-align: right;
`;

export default { DotsIcon, DropdownIconWrapper };

import { Section as SectionBase } from '@parts/section/section';
import styled from 'styled-components';

const Section = styled(SectionBase)`
  padding-bottom: 20px;
  margin-bottom: 55px;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    margin-bottom: 25px;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin-bottom: 15px;
  }
`;
const Filter = styled.span<{ isActive: boolean }>`
  padding: 0 5px;
  margin: 0 2.5px;
  color: ${({ theme }) => theme.color.fontPrimary};
  font-weight: ${({ theme, isActive }) => (isActive ? theme.fontWeight.semibold : 400)};
  cursor: pointer;
  color: ${({ theme, isActive }) => (isActive ? theme.color.activeFilter : theme.color.submitButton)};
`;

const CornerWrapper = styled.div`
  text-align: right;
  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    text-align: left;
  }
`;

export default {
  Section,
  Filter,
  CornerWrapper,
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_SKILLS_SETS_KEY = 'get-most-popular-skillsets';

export const getMostPopularSkillsets = async (): Promise<GraphqlResponse<string[]>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getMostPopularSkillsets {
        getMostPopularSkillsets 
      }
    `,
  });

  return graphqlResponseWrapper(data, ['getMostPopularSkillsets']);
};

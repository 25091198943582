import { ReadinessScore } from '@/layout/founder/parts/readiness-score-tab/parts/score/score';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { GET_READINESS_SCORE_CACHE_KEY, getReadinessScore } from './api/get-readiness-score.action';

import S from './readiness-score-tab.styles';

export const ReadinessScoreTab = ({
  fontSize = 13,
  scoreSize = 55,
  scoreNumberFontSize = 22,
  onClick = () => {},
}: {
  fontSize?: number;
  scoreSize?: number;
  scoreNumberFontSize?: number;
  onClick?: () => void;
}) => {
  const [readinessScoreLabel, improveLabel] = useTranslation([
    'founder-onboarding.readinessScore.title',
    'founder-onboarding.readinessScore.improve',
  ]);

  const { data: scoreData } = useQuery([GET_READINESS_SCORE_CACHE_KEY], getReadinessScore);

  const { score } = scoreData?.data || {};

  return score ? (
    <S.Wrapper data-testid="readiness-score-tab" onClick={onClick}>
      <S.ScoreWrapper size={scoreSize}>
        <ReadinessScore score={score} numberFontSize={scoreNumberFontSize} ringWidth={1} />
      </S.ScoreWrapper>
      <S.TextWrapper>
        <S.Title fontSize={fontSize}>{readinessScoreLabel}</S.Title>
        <S.Subtext fontSize={fontSize}>
          {improveLabel} <S.ArrowRightIcon />
        </S.Subtext>
      </S.TextWrapper>
    </S.Wrapper>
  ) : null;
};

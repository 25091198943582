import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Col, Form, Row } from 'antd';
import { useFormik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { Button as ActionButton } from '../button/button';
import S from './share-by-email.styles';

const ShareByEmail = ({
  handleSharePreviewReport,
}: {
  handleSharePreviewReport: (values: { emailAddresses: string[]; note: string | null }) => void;
}) => {
  const [headerText, descriptionText, placeholderText, sendText, addNoteText, addNotePlaceholderText, requiredText] =
    useTranslation([
      'pulseDashboard.shared.modal.shareAsEmail.header',
      'pulseDashboard.shared.modal.shareAsEmail.description',
      'pulseDashboard.shared.modal.shareAsEmail.placeholder',
      'pulseDashboard.shared.modal.shareAsEmail.send',
      'pulseDashboard.shared.modal.shareAsEmail.addNote',
      'pulseDashboard.shared.modal.shareAsEmail.addNotePlaceholder',
      'formik.validation.required',
    ]);

  const { getFieldProps, touched, errors, submitForm, dirty, resetForm, isValid, isSubmitting, setSubmitting } =
    useFormik<{
      emailAddresses: string;
      note: string | null;
    }>({
      onSubmit: (values) => {
        handleSharePreviewReport({
          emailAddresses: values.emailAddresses.split(/[\s,]+/).filter((v) => Boolean(v)),
          note: values.note === '' ? null : values.note,
        });
        resetForm({ values });
        setSubmitting(false);
      },
      initialValues: {
        emailAddresses: '',
        note: null,
      },
      validateOnMount: false,
      validationSchema: Yup.object({
        note: Yup.string().nullable().optional(),
        emailAddresses: Yup.array()
          .transform(function (value, originalValue) {
            if (this.isType(value) && value !== null) {
              return value;
            }
            return originalValue ? originalValue.split(/[\s,]+/) : [];
          })
          .of(Yup.string().email(({ value }) => `${value} is not a valid email`))
          .min(1, requiredText)
          .required(requiredText),
      }),
    });

  const [displayNoteInput, setDisplayNoteInput] = useState(false);

  return (
    <Form>
      <S.Header>{headerText}</S.Header>
      <S.Text>{descriptionText}</S.Text>
      <S.TopMarginRow gutter={[10, 0]}>
        <Col xs={20} sm={20} md={20} lg={20} xl={20}>
          <S.FormikField error={errors.emailAddresses} touched={touched.emailAddresses}>
            <S.Input {...getFieldProps('emailAddresses')} placeholder={placeholderText} />
          </S.FormikField>
        </Col>
        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
          <ActionButton
            data-testid="send-report-btn"
            onClick={submitForm}
            disabled={!dirty || !isValid}
            animate={isSubmitting}
          >
            {sendText}
          </ActionButton>
        </Col>
      </S.TopMarginRow>

      {!displayNoteInput ? (
        <S.ActionLink type="link" onClick={() => setDisplayNoteInput(true)}>
          {addNoteText}
        </S.ActionLink>
      ) : (
        <Row gutter={[10, 0]}>
          <Col xs={20} sm={20} md={20} lg={20} xl={20}>
            <S.FormikField error={errors.note} touched={touched.note}>
              <S.Textarea {...getFieldProps('note')} placeholder={addNotePlaceholderText} rows={5} />
            </S.FormikField>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default ShareByEmail;

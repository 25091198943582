import type { NedPublicProfileData } from '@/pages/content/public-profile/ned/api/get-ned-public-page-details';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export type AdvisorProfilePreviewData = NedPublicProfileData;

export const GET_ADVISOR_PROFILE_PREVIEW_DATA_KEY = 'get-advisor-profile-preview-data';

export const getAdvisorProfilePreviewData =
  (nedId: string) => async (): Promise<GraphqlResponse<AdvisorProfilePreviewData>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
        query getAdvisorProfilePreviewData {
          getNedPublicData(nedId: "${nedId}") {
            name,
            countryOfResidence,
            profilePicture {
              path,
              url
            },
            linkedinUrl,
            twitterUrl,
            aboutMe,
            myExpectations,
            myOffering,
            industries,
            primarySkillSet,
            secondarySkillSet,
            isAdvisor,
            isNed,
            isFractional,
            isBookmarked,
            connection {
              id,
              status,
              initiator
            },
            compensations {
              role,
              compensationType,
              minDayRate,
              maxDayRate
            },
            targetGeography,
            resume {
              name,
              path,
              url
            },
            experience {
              company {
                id,
                name,
                avatarUrl,
                industry,
                isExternal
              },
              role,
              startDate,
              endDate,
              isOngoing,
              skillSet,
              experienceSummary
            }
          }
        }
      `,
    });

    return graphqlResponseWrapper(data, ['getNedPublicData']);
  };

import styled from 'styled-components';

const width = '650px';

const Wrapper = styled.div`
  max-width: ${width};
  margin: auto;
`;

const DescriptionWrapper = styled.div`
  margin-top: -${({ theme }) => theme.spacing.base};
`;

export default { DescriptionWrapper, Wrapper };

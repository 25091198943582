import { useUserAbility } from '@abilities';
import { AbilityAction, AbilitySubject } from '@abilities/ability/types';
import { contactHelpConnectdEmail } from '@constants/emails';
import { contractBookLink } from '@constants/links';
import { ClosableInfo } from '@parts/closable-info/closable-info';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { PageHeader } from '@parts/page-header/page-header';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { FC } from 'react';
import { GET_LEGAL_DOCUMENTS, getLegalDocumentsAction } from './api/get-legal-documents.action';
import S from './legal-page.styles';
import { DocumentSection } from './parts/documents/document-section';
import LegalInfoLink from './parts/legal-info-link/legal-info-link';

export const Legal = ({
  Layout,
  availableBlocking,
}: {
  Layout: FC<{ legacyContainer?: boolean }>;
  availableBlocking: boolean;
}) => {
  const userAbility = useUserAbility();
  const [titleLabel, closableInfoText1, closableInfoLink] = useTranslation([
    'legal.title',
    'legal.closableInfoText1',
    'legal.closableInfoLink',
  ]);
  const { data: response, isLoading } = useQuery([GET_LEGAL_DOCUMENTS], getLegalDocumentsAction);
  const ClosableInfoText = () => (
    <>
      {`${closableInfoText1} `}
      <S.Link
        aria-hidden="true"
        onClick={() => {
          window.open(contractBookLink, '_blank', 'noreferrer,noopener');
        }}
      >
        {closableInfoLink}
      </S.Link>
    </>
  );
  const hasPermissionsToLegalDocs = userAbility.can(AbilityAction.ACCESS, AbilitySubject.LEGAL_DOCS);
  const { availableDocuments, helpdeskSlug, manageDocumentsUrl } = Object(response?.data);

  if (isLoading || !response || !response.data) {
    return (
      <Layout>
        <FullHeightSpinner />
      </Layout>
    );
  }

  return (
    <Layout>
      <PageHeader title={titleLabel} />
      {(hasPermissionsToLegalDocs || !availableBlocking) && (
        <ClosableInfo text={ClosableInfoText()} uniqueKey="closable-info-legal-founder" />
      )}
      {!hasPermissionsToLegalDocs && availableBlocking && (
        <LegalInfoLink link={`mailto:${contactHelpConnectdEmail}`} type="legal-hub" />
      )}
      {(hasPermissionsToLegalDocs || !availableBlocking) && (
        <>
          <LegalInfoLink link={helpdeskSlug} type="help-desk" />
          <LegalInfoLink link={manageDocumentsUrl} type="manage-documents" />
        </>
      )}
      <DocumentSection availableBlocking={availableBlocking} availableDocuments={availableDocuments} />
    </Layout>
  );
};

import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import * as Yup from 'yup';

const NOTE_MAX_LENGTH = 5000;

export const useValidationSchema = () => {
  const [minLengthLabel, maxLengthLabel, oneFieldRequiredError] = useTranslation([
    'formik.validation.min',
    'formik.validation.max',
    'profile.investor.section.investments.oneFieldRequiredError',
  ]);

  return Yup.object().shape(
    {
      company: Yup.string().when('note', {
        is: (val: string | null | undefined) => Boolean(val),
        then: Yup.string().trim().min(2, minLengthLabel).required(oneFieldRequiredError),
        otherwise: Yup.string().trim().min(2, minLengthLabel),
      }),
      note: Yup.string()
        .max(NOTE_MAX_LENGTH, maxLengthLabel)
        .when('company', {
          is: (val: string | null | undefined) => Boolean(val),
          then: Yup.string().trim().min(2, minLengthLabel).required(oneFieldRequiredError),
          otherwise: Yup.string().trim().min(2, minLengthLabel),
        }),
    },
    [['company', 'note']],
  );
};

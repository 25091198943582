import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import React, { useEffect, useRef } from 'react';

import S from './infinite-scroller.styles';

interface InfiniteScrollProps extends React.HTMLAttributes<HTMLDivElement> {
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isLoading: boolean;
  intersectionOptions?: IntersectionObserverInit;
  dataTestId?: string;
}

export const InfiniteScroller = ({
  fetchNextPage,
  hasNextPage,
  isLoading,
  intersectionOptions,
  children,
  dataTestId,
  ...props
}: InfiniteScrollProps) => {
  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && hasNextPage) fetchNextPage();
    }, intersectionOptions);

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current as unknown as Element);
      }
      observer.disconnect();
    };
  }, [hasNextPage]);

  return (
    <div {...props} style={{ overflowAnchor: 'none' }} data-testid={dataTestId}>
      {children}
      <S.ObserverTarget ref={observerTarget} style={{ display: isLoading ? 'none' : 'block' }} />
      {isLoading ? <FullHeightSpinner small /> : null}
    </div>
  );
};

InfiniteScroller.displayName = 'InfiniteScroller';

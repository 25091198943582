import LogoBase from '@assets/brand/logo.svg?react';
import { Button } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.sectionBackground};
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1100px;
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.medium};
`;

const Logo = styled(LogoBase)`
  width: 160px;

  path:first-of-type {
    fill: ${({ theme }) => theme.color.logoFill} !important;
  }
`;

const SignOut = styled(Button)`
  color: ${({ theme }) => theme.color.fontBlue};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  padding: 0;
`;

export default {
  Wrapper,
  Header,
  Logo,
  SignOut,
};

import { FormikField } from '@parts/forms/formik-field/formik-field';
import { SecureLink } from '@parts/link/link';
import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 790px;
  width: 100%;
  margin: 0 auto;
`;

const TilesList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0 -${({ theme }) => theme.spacing.small};
`;

const TilesListItem = styled.li`
  margin: 0 ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.medium};
`;

const Link = styled(SecureLink)`
  color: ${({ theme }) => theme.baseColors.White};
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.baseColors.Grey50};
  }
`;

const CheckboxFormikField = styled(FormikField)`
  display: flex;
  flex-direction: column;
  position: relative;

  > div {
    width: auto;
  }

  .ant-checkbox-wrapper {
    margin: 0;
    position: absolute;
    top: ${({ theme }) => theme.spacing.xmedium};
    left: ${({ theme }) => theme.spacing.xmedium};
  }

  label:not(.ant-checkbox-wrapper) {
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    padding: ${({ theme }) => theme.spacing.xmedium};
    padding-left: 50px;
    border: 2px solid ${({ theme }) => theme.baseColors.Blue100};
    border-radius: ${({ theme }) => theme.radius.medium};
  }
`;

export default { Wrapper, TilesList, TilesListItem, Link, CheckboxFormikField };

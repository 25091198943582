import SuccessIcon from '@assets/icons/investor-onboarding/success.svg?react';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';

import S from './banner.style';

export const PendingApprovalBanner = () => {
  const [title, description1, description2] = useTranslation([
    'investor-onboarding.pending-approval.title',
    'investor-onboarding.pending-approval.description1',
    'investor-onboarding.pending-approval.description2',
  ]);

  return (
    <S.Wrapper data-testid="pending-approval-banner">
      <SuccessIcon />
      <S.Title>{title}</S.Title>
      <S.Description>{description1}</S.Description>
      <S.Description>{description2}</S.Description>
    </S.Wrapper>
  );
};

import UploadFileBase from '@assets/icons/upload-file-arrow-up.svg?react';
import { Button } from 'antd';
import styled, { css } from 'styled-components';

const FileUpload = styled.div<{ $hiddenMode: boolean; slim?: boolean }>`
  border: 1px solid ${({ theme }) => theme.color.border};
  position: relative;
  height: ${({ theme }) => theme.ui.inputHeight};
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: ${({ theme }) => theme.radius.default};
  max-width: 100%;

  ${({ $hiddenMode }) =>
    $hiddenMode &&
    css`
      visibility: hidden;
      height: 0;
    `}

  ${({ slim }) =>
    slim &&
    css`
      height: auto;
      min-height: 30px;
    `}

  .ant-progress {
    position: absolute;
    height: 100%;
    width: 100%;

    div {
      height: 100%;
    }

    .ant-progress-bg {
      height: 100% !important;
      border-radius: 0;
      background-color: ${({ theme }) => theme.color.fileUploadingProgress}!important;
      transition: all 1.8s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
    }
  }

  .ant-progress-outer {
    padding-right: 0;
  }

  .ant-progress-inner {
    border-radius: 0;
    background-color: ${({ theme }) => theme.color.dashboardBackground};
  }

  .ant-progress-text {
    display: none;
  }
`;

const EmptyState = styled(Button)<{ $hiddenMode: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  width: 100%;
  padding: 0;
  height: 100% !important;
  border: 1px solid ${({ theme }) => theme.baseColors.Blue200};
  background-color: ${({ theme }) => theme.color.sectionBackground};

  ${({ $hiddenMode }) =>
    $hiddenMode &&
    css`
      visibility: hidden;
    `}

  &:hover {
    background-color: ${({ theme }) => theme.color.sectionBackground};
    opacity: 0.7;
  }

  span {
    color: ${({ theme }) => theme.baseColors.Blue200};
    margin-left: 5px;
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
  }
`;

const Uploading = styled.div<{ hiddenMode?: boolean; slim?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  > div:last-child {
    padding: 0 15px;
  }

  ${({ hiddenMode }) =>
    hiddenMode &&
    css`
      justify-content: center;
      margin: 0 auto;
      max-width: 600px;
      padding: 0 10px;
    `}

  ${({ slim }) =>
    slim &&
    css`
      > div:last-child {
        padding: 0;
      }
    `}
`;

const EmptyStateLabel = styled.span``;

const UploadFile = styled(UploadFileBase)`
  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
`;

export default {
  FileUpload,
  EmptyState,
  Uploading,
  EmptyStateLabel,
  UploadFile,
};

import type { AxiosError } from '@utils/axios/types';

export const getServerError = (err: AxiosError) => {
  if (!err.response) return err;
  const { data } = err.response;

  if (data.details) {
    return `${err.response?.data.details[0].message}`;
  }

  return `${err.response?.data.name}: ${err.response?.data.error}`;
};

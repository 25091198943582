import { V2Avatar } from '@/components/ui/v2-avatar/v2-avatar';
import S from './side-panel.styles';
import { V2Button } from '@/components/ui/v2-button/v2-button';
import { V2Icon } from '@/components/ui/v2-icon/v2-icon';
import { Info } from './parts/info';
import { type NedPublicProfileData } from '@/pages/content/public-profile/ned/api/get-ned-public-page-details';
import { Links } from '@/router/links';
import { useModal } from '@/hooks/use-modal';
import { NotesModal } from '../notes-modal/notes-modal';
import { ConnectionButton } from '@/modules/founder/advisor-search/components/connection-button/connection-button';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { FullHeightSpinner } from '@/parts/full-height-spinner/full-height-spinner';
import { V2Dropdown } from '@/components/blocks/v2-dropdown/v2-dropdown';
import { V2IconButton } from '@/components/ui/v2-icon-button/v2-icon-button';
import { ConnectionStatus } from '@utils/type-definitions/connections';
import { SanitizeWysiwyg } from '@/utils/fns/sanitize-wysiwyg';
import { V2Alert } from '@/components/ui/v2-alert/v2-alert';
import { checkIfValidWysiWygValue } from '@/pages/content/profile/hooks/use-wysiwyg';

type Props = {
  data: NedPublicProfileData;
  targetId: string;
  isLoading: boolean;
  refetchProfileDetails: () => Promise<unknown>;
};

const ExpandIcon = () => <V2Icon name="chevron-down" size="sm" />;

export const SidePanel = ({ data, targetId, isLoading, refetchProfileDetails }: Props) => {
  const [viewProfileLabel, infoLabel, bioLabel, seeNotesLabel, downloadCvLabel] = useTranslation([
    'inbox.sidePanel.viewProfile',
    'inbox.sidePanel.info',
    'inbox.sidePanel.bio',
    'inbox.sidePanel.seeNotes',
    'inbox.sidePanel.downloadCv',
  ]);
  const { isOpen, open, close } = useModal();

  if (isLoading) return <FullHeightSpinner />;

  if (!data)
    return <V2Alert variant="error" title="Error loading data" description="Please try again later" showIcon />;

  const showConnectionButton =
    data.connection?.status !== ConnectionStatus.Accepted && data.connection?.status !== ConnectionStatus.Declined;

  const handleDownloadCv = () => {
    const url = data?.resume?.url;
    window.open(url, '_blank');
  };

  return (
    <S.SidePanel>
      <NotesModal isOpen={isOpen} close={close} targetId={targetId} connectionName={data.name} />
      <S.DropdownWrapper>
        <V2Dropdown
          overlay={
            <V2Dropdown.Menu>
              <V2Dropdown.Item onClick={open}>
                <S.ItemWrapper>
                  <V2Icon name="file-text" />
                  {seeNotesLabel}
                </S.ItemWrapper>
              </V2Dropdown.Item>
              <V2Dropdown.Item onClick={handleDownloadCv} disabled={!data.resume?.url}>
                <S.ItemWrapper $disabled={!data.resume?.url}>
                  <V2Icon name="download" />
                  {downloadCvLabel}
                </S.ItemWrapper>
              </V2Dropdown.Item>
            </V2Dropdown.Menu>
          }
          toggle={<V2IconButton name="kebab" size="lg" />}
        />
      </S.DropdownWrapper>
      <V2Avatar name={data.name} src={data.profilePicture?.url} data-testid="user-avatar" />
      <S.Name>{data.name}</S.Name>
      <S.ButtonsWrapper>
        {showConnectionButton && (
          <ConnectionButton
            connectionStatus={data.connection?.status ?? null}
            advisorId={targetId}
            initiator={data.connection?.initiator ?? null}
            connectionId={data.connection?.id ?? null}
            disabled={false}
            callback={refetchProfileDetails}
            data-testid="connection-button"
          />
        )}
        <S.UnstyledLink href={Links.NED_PUBLIC_PROFILE(targetId)} target="_blank" rel="noreferrer">
          <V2Button variant="outlined" data-testid="view-profile-button">
            {viewProfileLabel}
          </V2Button>
        </S.UnstyledLink>
      </S.ButtonsWrapper>
      <S.StyledCollapse expandIcon={ExpandIcon} expandIconPosition="right" ghost>
        <S.StyledCollapse.Panel header={infoLabel} key={1}>
          <Info data={data} />
        </S.StyledCollapse.Panel>
        {data?.aboutMe && (
          <S.StyledCollapse.Panel header={bioLabel} key={2}>
            <S.Bio
              dangerouslySetInnerHTML={{
                __html: checkIfValidWysiWygValue(data?.aboutMe)
                  ? SanitizeWysiwyg(JSON.parse(data?.aboutMe as unknown as string))
                  : '',
              }}
            />
          </S.StyledCollapse.Panel>
        )}
      </S.StyledCollapse>
    </S.SidePanel>
  );
};

import { useEffect, useRef, useState } from 'react';
import type { RangerSliderProps } from '../amount-range-slider';
import S from './value-display.style';
import { useUserCurrency } from '@utils/hooks/use-user-currency/use-user-currency';

const roundToNearest = (num: number, roundTo: number) => Math.round(num / roundTo) * roundTo;

interface ValueDisplayProps extends RangerSliderProps {}

export const ValueDisplay = ({
  min,
  max,
  value,
  onChange,
  onAfterChange,
  step,
  displayValuePrefix,
  disabled = false,
  loading = false,
  allowEditing = true,
}: ValueDisplayProps) => {
  const [inputDisplayVal, setInputDisplayVal] = useState([min, max]);
  const [displayMinVal, displayMaxVal] = inputDisplayVal;
  const minInputRef = useRef<HTMLInputElement>(null);
  const maxInputRef = useRef<HTMLInputElement>(null);

  const { commaize } = useUserCurrency();

  const handleInputBlur = (field: 'min' | 'max', val: number) => {
    const valueAsNumber = Number.isNaN(val) ? 0 : val;
    let newValue: [number, number] = [displayMinVal, displayMaxVal];

    if (field === 'min') newValue[0] = Math.min(max, Math.max(min, roundToNearest(valueAsNumber, step)));
    if (field === 'max') newValue[1] = Math.max(min, Math.min(max, roundToNearest(valueAsNumber, step)));

    const isEnteredMinGreaterThanMax = newValue[0] > newValue[1];
    if (isEnteredMinGreaterThanMax) {
      newValue = [newValue[1], newValue[0]];
    }

    onChange(newValue);
    if (typeof onAfterChange === 'function') onAfterChange(newValue);
  };

  useEffect(() => {
    setInputDisplayVal(value);
  }, [value]);

  return (
    <S.ValueDisplay data-testid="ranger-slider-display">
      <S.InputWrapper>
        {displayValuePrefix && (
          <S.ValuePrefix onClick={() => minInputRef.current?.focus()}>{displayValuePrefix}</S.ValuePrefix>
        )}
        <S.MoneyInput
          ref={minInputRef}
          value={displayMinVal}
          formatter={(val) => commaize(val || 0, true)}
          parser={(val) => (val ? val.replace(/,/g, '') : 0)}
          onChange={(val) => setInputDisplayVal([Number(val), displayMaxVal])}
          $width={`${displayMinVal.toString().length + 1}ch`}
          disabled={allowEditing === false || disabled || loading}
          onBlur={(e) => {
            const numValue = Number(e.target.value.replace(/[^0-9.-]+/g, ''));
            handleInputBlur('min', numValue);
          }}
        />
      </S.InputWrapper>
      <S.Separator>—</S.Separator>
      <S.InputWrapper>
        {displayValuePrefix && (
          <S.ValuePrefix onClick={() => maxInputRef.current?.focus()}>{displayValuePrefix}</S.ValuePrefix>
        )}
        <S.MoneyInput
          ref={maxInputRef}
          value={displayMaxVal}
          formatter={(val) => commaize(val || 0, true)}
          parser={(val) => (val ? val.replace(/,/g, '') : 0)}
          onChange={(val) => setInputDisplayVal([displayMinVal, Number(val)])}
          onBlur={(e) => {
            const numValue = Number(e.target.value.replace(/[^0-9.-]+/g, ''));
            handleInputBlur('max', numValue);
          }}
          $width={`${displayMaxVal.toString().length + 1}ch`}
          disabled={allowEditing === false || disabled || loading}
        />
        {displayMaxVal >= max && '+'}
      </S.InputWrapper>
    </S.ValueDisplay>
  );
};

import CheckboxFormikFieldBase from '@pages/content/profile/parts/checkbox-formik-field/checkbox-formik-field';
import { Arrow as ArrowBase } from '@parts/arrow/arrow';
import { MultiSelectSearch as MultiSelectSearchBase } from '@parts/multiselect-search/multiselect-search';
import { Section as SectionBase } from '@parts/section/section';
import { Col as ColBase, Collapse as CollapseBase, Input as InputBase } from 'antd';
import styled from 'styled-components';

const Section = styled(SectionBase)`
  padding: ${({ theme }) => theme.spacing.xmedium};
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const Arrow = styled(ArrowBase)`
  margin-left: ${({ theme }) => theme.spacing.small};
`;

const Collapse = styled(CollapseBase)`
  background-color: ${({ theme }) => theme.color.sectionBackground};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  border: 1px solid ${({ theme }) => theme.color.border};

  .ant-collapse-item {
    padding: ${({ theme }) => theme.spacing.small};
    border: 0;
  }

  .ant-collapse-header[aria-expanded='true'] {
    ${Arrow} {
      transform: rotate(270deg);
    }
  }
`;

const Col = styled(ColBase)`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > div {
    margin: 0;
  }
`;

const Input = styled(InputBase)`
  height: 50px;
  font-size: ${({ theme }) => theme.fontSize.xsmall};

  .ant-input-affix-wrapper > input.ant-input {
    padding-bottom: 10px !important;
  }

  input {
    height: 100%;
  }
`;

const MultiSelectSearch = styled(MultiSelectSearchBase)`
  .ant-select-selector {
    height: 50px;
  }

  .ant-select-selection-overflow {
    flex-wrap: nowrap;
  }
`;

const CheckboxFormikField = styled(CheckboxFormikFieldBase)`
  .ant-checkbox-wrapper {
    margin-right: ${({ theme }) => theme.spacing.small};
  }
`;

export default { Section, Col, MultiSelectSearch, Collapse, Arrow, Input, CheckboxFormikField };

import type { Dashboard as DashboardType } from '@pages/content/pulse/parts/dashboards/api/get-founder-dashboard/get-founder-dashboard.action';
import { DashboardElements } from '@pages/content/pulse/parts/dashboards/parts/dashboard/dashboard-elements/dashboard-elements';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';

export const DashboardDetails = ({
  dashboardId,
  dashboardDetails,
  dashboardDetailsIsLoading,
  dashboardDetailsQueryKeyCache,
  followMode,
  reportMode,
  allowSetNotifications,
  businessName,
}: {
  dashboardDetails: DashboardType;
  dashboardId: string;
  dashboardDetailsIsLoading: boolean;
  dashboardDetailsQueryKeyCache?: string | unknown[];
  followMode?: boolean;
  reportMode?: boolean;
  allowSetNotifications?: boolean;
  businessName?: string;
}) =>
  dashboardDetailsIsLoading || !dashboardDetails ? (
    <FullHeightSpinner />
  ) : (
    <DashboardElements
      followMode={followMode}
      reportMode={reportMode}
      dashboardDetailsQueryKeyCache={dashboardDetailsQueryKeyCache}
      metrics={dashboardDetails.metrics}
      allowSetNotifications={allowSetNotifications}
      dashboardId={dashboardId}
      gridConfig={dashboardDetails.gridConfig}
      textBoxes={dashboardDetails.textBoxes}
      businessName={businessName}
    />
  );

import { ConnectionStatus, ConnectionType } from '@/utils/type-definitions/connections';
import { isInvestorWaitingForApproval } from '@abilities';
import DisconnectIcon from '@assets/icons/unfriend.svg?react';
import { Roles } from '@domain/accounts/roles';
import { DateFormat } from '@i18n/date-formats';
import { type INotInterestedFeedback } from '@pages/content/lens/parts/feedback-modal/feedback-modal';
import { MatchLevel, SmartMatchProgress } from '@pages/content/lens/parts/smart-match-progress/smart-match-progress';
import { MessageSymbol } from '@parts/message-symbol/message-symbol';
import notification from '@parts/message/message';
import { SubmitButton } from '@parts/submit-button/submit-button';
import { Links } from '@router/links';
import { useMutation, type MutationFunction } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import {
  universalEngageConversationAction,
  useEngageConversation,
} from '@utils/hooks/use-engage-conversation/use-engage-conversation';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { stringify } from 'query-string';
import type { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import type { Connection } from '../../investor/api/get-investor-public-page-details.action';
import { InvestorSmartMatchCheck } from './investor-smart-match-check';

import S from './connection-manager.styles';

// TODO this connections manager needs to be refactored because of the introduction of the advisor-founder connection
export const ConnectionManager = ({
  counterpartId,
  canConnect = true,
  connection,
  invitationId,
  name,
  smartMatchLevel,
  refetch,
  actions,
  children,
  notConnectedMessaging = true,
}: {
  counterpartId: string;
  canConnect?: boolean;
  connection: Connection | null;
  invitationId: string | null;
  name: string;
  smartMatchLevel: MatchLevel;
  refetch: Function;
  actions: {
    send: MutationFunction<{ data: string }, string>;
    decline: MutationFunction<{ data: string }, string>;
    accept: (connectInvitationId: string) => Promise<AxiosResponse<unknown>>;
    disconnect: (connectInvitationId: string) => Promise<AxiosResponse<unknown>>;
    blacklist: (counterpartId: string) => (payload: INotInterestedFeedback) => Promise<AxiosResponse<unknown>>;
    cancelSent: MutationFunction<{ data: string }, string>;
  };
  children?: ReactNode | ReactNode[];
  notConnectedMessaging?: boolean;
}) => {
  const {
    state: { userRole, products },
  } = useUserAccount();
  const investorIsWaitingForApproval = isInvestorWaitingForApproval(products);

  const { dateFormatter } = useUserDateFormat();

  const { state } = useLocation<{ page: string }[]>();

  const [
    inviteAcceptLabel,
    inviteIgnoreLabel,
    wantsToConnect,
    matchLabel,
    chatLabel,
    connectedOnLabel,
    connectLabel,
    sentLabel,
    cancelLabel,
    notInterestedSuccessful,
    notInterestedLabel,
  ] = useTranslation([
    'profile.preview.investor.accept',
    'profile.preview.investor.ignore',
    'profile.preview.investor.wantsToConnect',
    'profile.preview.investor.match',
    'profile.preview.investor.chat',
    'profile.preview.investor.connectedOn',
    'profile.preview.founder.connect',
    'profile.preview.founder.requestSent',
    'profile.preview.founder.cancel',
    'profile.preview.founder.notInterestedSuccessful',
    'profile.preview.founder.notInterested',
  ]);

  const history = useHistory();
  const { mutateAsync: message, isLoading: isMessageLoading } = useEngageConversation(
    universalEngageConversationAction,
  );

  const exitConfig = {
    onSettled: () => {
      refetch();
      if (!state?.[0]?.page) return;

      history.push(
        history.createHref({
          pathname: Links.LENS_SMART_MATCH(),
          search: stringify({ page: state[0].page }),
        }),
      );
    },
  };

  const refetchConfig = {
    onSettled: () => {
      refetch();
    },
  };

  const mutationConfig = userRole === Roles.FOUNDER ? exitConfig : refetchConfig;

  const { mutateAsync: decline, isLoading: cancellingInProgress } = useMutation(actions.decline, mutationConfig);

  const { mutateAsync: accept, isLoading: acceptingInProgress } = useMutation(actions.accept, refetchConfig);

  const { mutateAsync: unfollow, isLoading: unfollowInProgress } = useMutation(actions.disconnect, mutationConfig);

  const { mutateAsync: send } = useMutation(actions.send, {
    ...refetchConfig,
    onError: (error: AxiosError) => notification.error({ content: getServerError(error) }),
  });

  const { mutateAsync: cancelSent } = useMutation(actions.cancelSent, mutationConfig);

  const { mutateAsync: blacklist } = useMutation(actions.blacklist(counterpartId), {
    ...exitConfig,
    onSuccess: () => {
      notification.success({
        content: (
          <p>
            {notInterestedSuccessful} {name}
          </p>
        ),
      });
    },
    onError: (error: AxiosError) => notification.error({ content: getServerError(error) }),
  });

  const pending = (
    <>
      <div>
        <S.ConnectionRequest>
          {name} {wantsToConnect}.
        </S.ConnectionRequest>

        <S.CtaWrapper>
          {invitationId && (
            <>
              <SubmitButton disabled={acceptingInProgress} onClick={() => accept(invitationId)}>
                {inviteAcceptLabel}
              </SubmitButton>
              <SubmitButton disabled={cancellingInProgress} onClick={() => decline(invitationId)} type="link">
                {inviteIgnoreLabel}
              </SubmitButton>
            </>
          )}
          {notConnectedMessaging && (
            <SubmitButton
              data-testid="public-profile-smart-match-pending-connection-message"
              type="link"
              disabled={isMessageLoading}
              onClick={() => message(counterpartId)}
            >
              <MessageSymbol />
            </SubmitButton>
          )}
        </S.CtaWrapper>
      </div>
      <S.ProgressWrapper>
        {matchLabel} <SmartMatchProgress level={smartMatchLevel} />
      </S.ProgressWrapper>
    </>
  );

  const connected = (
    <>
      <S.CtaWrapper>
        <S.SubmitButton
          data-testid="public-profile-smart-match-connected-message"
          type="link"
          disabled={isMessageLoading}
          onClick={() => message(counterpartId)}
        >
          <MessageSymbol /> {chatLabel}
        </S.SubmitButton>
      </S.CtaWrapper>
      <S.ConnectedWrapper>
        {connection?.acceptedAt && (
          <S.ConnectionLabel>
            {connectedOnLabel} {dateFormatter(connection.acceptedAt, DateFormat.FULL_DATE_DASHED)}
          </S.ConnectionLabel>
        )}
        <SubmitButton type="link" disabled={unfollowInProgress} onClick={() => unfollow(counterpartId)}>
          <DisconnectIcon />
        </SubmitButton>
      </S.ConnectedWrapper>
    </>
  );

  const notInterested =
    userRole === Roles.FOUNDER ? (
      <S.NotInterested
        type="link"
        onClick={(e) => {
          e.preventDefault();
          blacklist({});
        }}
      >
        {notInterestedLabel}
      </S.NotInterested>
    ) : null;

  const notConnected = () => {
    const callToAction =
      userRole === Roles.INVESTOR || userRole === Roles.INVESTOR_NED ? (
        <InvestorSmartMatchCheck
          canConnect={canConnect}
          acceptingInProgress={acceptingInProgress}
          message={message}
          send={send}
          counterpartId={counterpartId}
          isMessageLoading={isMessageLoading}
        />
      ) : (
        <div>
          <S.CtaWrapper>
            <SubmitButton
              data-testid="public-profile-smart-match-connect"
              disabled={acceptingInProgress || !canConnect}
              onClick={() => send(counterpartId)}
            >
              {connectLabel}
            </SubmitButton>

            {notInterested}

            {notConnectedMessaging && (
              <SubmitButton
                data-testid="public-profile-smart-match-not-connected-message"
                type="link"
                disabled={isMessageLoading}
                onClick={() => message(counterpartId)}
              >
                <MessageSymbol />
              </SubmitButton>
            )}
          </S.CtaWrapper>
        </div>
      );
    return (
      <>
        {callToAction}
        <S.ProgressWrapper>
          {matchLabel} <SmartMatchProgress level={smartMatchLevel} />
        </S.ProgressWrapper>
      </>
    );
  };

  const blackListed = (
    <>
      <div />
      <S.ProgressOnly>
        {matchLabel} <SmartMatchProgress level={smartMatchLevel} />
      </S.ProgressOnly>
    </>
  );

  const sent = (
    <>
      <S.ResendWrapper>
        <S.ConnectionRequest>{sentLabel}</S.ConnectionRequest>
        <SubmitButton type="link" onClick={() => cancelSent(invitationId!)}>
          {cancelLabel}
        </SubmitButton>
      </S.ResendWrapper>
      <S.ProgressOnly>
        {matchLabel} <SmartMatchProgress level={smartMatchLevel} />
      </S.ProgressOnly>
    </>
  );

  const renderContent = () => {
    if (userRole === Roles.INVESTOR_NED && investorIsWaitingForApproval) return null;
    if (connection?.blacklistedAt) return blackListed;
    if (connection?.acceptedAt) return connected;

    if (connection?.status === ConnectionStatus.Pending) {
      if (connection?.type === ConnectionType.Request) {
        if (userRole === Roles.FOUNDER) {
          return pending;
        }

        return sent;
      }

      if (connection?.type === ConnectionType.Invitation) {
        if (userRole === Roles.FOUNDER) {
          return sent;
        }

        return pending;
      }
    }

    if (userRole !== Roles.NED) {
      if (!connection) {
        return notConnected();
      }
    }
  };

  return (
    <S.Wrapper>
      {renderContent()}
      {children && <S.CtaWrapper>{children}</S.CtaWrapper>}
    </S.Wrapper>
  );
};

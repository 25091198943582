import Checkbox from '@parts/checkbox/checkbox';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import type { FieldConfig, FieldInputProps, FormikErrors } from 'formik';
import type { FormikFiltersData } from '../filters';
import S from './advisors-filters-checkboxes.styles';

export const AdvisorsFiltersCheckboxes = ({
  getFieldProps,
  setFieldValue,
}: {
  getFieldProps: (nameOrOptions: string | FieldConfig) => FieldInputProps<boolean>;
  setFieldValue: (field: string, value: boolean | null) => Promise<void | FormikErrors<FormikFiltersData>>;
}) => {
  const [newUsersLabel, savedProfilesLabel, connectionsLabel] = useTranslation([
    'advisors.search.filters.checkbox.newUsers',
    'advisors.search.filters.checkbox.savedProfiles',
    'advisors.search.filters.checkbox.connections',
  ]);

  const handleChange = (field: string, e: CheckboxChangeEvent) => {
    setFieldValue(field, e.target.checked);
  };

  return (
    <S.Wrapper>
      <S.CheckboxFormikField
        label={{
          for: 'onlyNewlyRegistered',
          label: newUsersLabel,
        }}
      >
        <Checkbox
          {...getFieldProps('onlyNewlyRegistered')}
          onChange={(e: CheckboxChangeEvent) => handleChange('onlyNewlyRegistered', e)}
          checked={getFieldProps('onlyNewlyRegistered').value}
          id="onlyNewlyRegistered"
          data-testid="new-users-checkbox"
        />
      </S.CheckboxFormikField>
      <S.CheckboxFormikField
        label={{
          for: 'onlySavedProfiles',
          label: savedProfilesLabel,
        }}
      >
        <Checkbox
          {...getFieldProps('onlySavedProfiles')}
          onChange={(e: CheckboxChangeEvent) => handleChange('onlySavedProfiles', e)}
          checked={getFieldProps('onlySavedProfiles').value}
          id="onlySavedProfiles"
          data-testid="saved-users-checkbox"
        />
      </S.CheckboxFormikField>
      <S.CheckboxFormikField
        label={{
          for: 'hideConnections',
          label: <S.DisabledSpan>{connectionsLabel}</S.DisabledSpan>,
        }}
      >
        <Checkbox data-testid="hide-connections-checkbox" id="hideConnections" disabled />
      </S.CheckboxFormikField>
    </S.Wrapper>
  );
};

import styled, { css } from 'styled-components';

export const grayIcon = css`
  margin-right: ${({ theme }) => theme.spacing.small};
  flex-shrink: 0;
`;

const Wrapper = styled.div<{ disabled?: boolean }>`
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.medium};
  color: ${({ theme }) => theme.color.fontSecondary};
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  flex-grow: 1;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `};
`;

const Row = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.xsmall};

  & + & {
    margin-top: ${({ theme }) => theme.spacing.xsmall};
    flex-grow: 1;
  }
`;

export default { Wrapper, Row };

import { buildQueryParamTypes, buildQueryParams } from '@/utils/fns/graphql-query-builder';
import type { Sorter } from '@/utils/type-definitions/filters';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';
import type { FounderConnection, FounderConnectionType } from '../types';

export const GET_FOUNDER_CONNECTIONS_KEY = 'get-founder-connections-query';

type ResponseData = {
  total: number;
  items: FounderConnection[];
};

type QueryConfig = {
  page?: number;
  perPage?: number;
  connectionType?: FounderConnectionType;
  search?: string;
  sort?: Sorter;
};

export const getFounderConnections = async ({
  page = 1,
  perPage = 6,
  search = '',
  sort,
  connectionType = 'Both',
}: QueryConfig): Promise<GraphqlResponse<ResponseData>> => {
  const variablesType = {
    sort: '[SortType!]',
    search: 'String!',
    connectionType: 'ConnectionTypeValue!',
    start: 'Int!',
    limit: 'Int!',
  };

  const variables = {
    sort,
    search,
    connectionType,
    start: (page - 1) * perPage,
    limit: page * perPage,
  };

  const { data } = await axiosInstance.post('/query', {
    variables,
    query: `
     query getFounderConnections (${buildQueryParamTypes(variablesType)}) {
         getFounderConnections (${buildQueryParams(variablesType)}) {
           total
           items {
             participantId
             connectionId
             connectionNotesCount
             name
             avatarUrl
             connectionDate
             connectionType
             investorType
             isCloseConnection
             isNew,
             conversationId
           }
         }
       }
    `,
  });

  return graphqlResponseWrapper(data, ['getFounderConnections']);
};

import Title from '@pages/content/onboarding/parts/title/title';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { StepContentProps } from '../../paths.config';
import { MatchFeatures } from './parts/match-features';

export const MatchPageContent = ({ currentPage, endPage }: StepContentProps) => {
  const [title] = useTranslation(['investor-onboarding.match.title']);

  return (
    <>
      <Title title={title} currentPage={currentPage} endPage={endPage} />
      <MatchFeatures />
    </>
  );
};

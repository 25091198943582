import { useEnforceTheme } from '@/utils/hooks/use-enforce-theme/use-enforce-theme';
import { AccountStatus } from '@context/user/user-account.context';
import { Roles } from '@domain/accounts/roles';
import { OnHoldLayout } from '@layout/on-hold/on-hold.layout';
import { Links } from '@router/links';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { Deactivated } from './parts/deactivated/deactivated';
import { PendingReview } from './parts/pending-review/pending-review';
import { PendingReviewInvestor } from './pending-review-investor/pending-review-investor';

export const SplashScreenPage = () => {
  useEnforceTheme('dark');

  const {
    state: { status, userRole },
  } = useUserAccount();
  // TODO: Check if could be replaced with proper policy
  window.history.pushState({}, '', Links.HOME());

  const renderContent = () => {
    if (status === AccountStatus.INACTIVE || status === AccountStatus.BAN) {
      return (
        <OnHoldLayout>
          <Deactivated />
        </OnHoldLayout>
      );
    }

    if (AccountStatus.AWAITING_ADMIN_ACCEPTANCE && userRole === Roles.INVESTOR) {
      return <PendingReviewInvestor />;
    }

    return (
      <OnHoldLayout>
        <PendingReview />
      </OnHoldLayout>
    );
  };

  return renderContent();
};

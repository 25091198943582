import { useQuery } from '@tanstack/react-query';
import { GET_ADVISOR_PUBLISH_STATUS, getAdvisorPublishedStatus } from '../api/get-advisor-publish-status.query';

export const useGetAdvisorPublishStatus = () => {
  const query = useQuery([GET_ADVISOR_PUBLISH_STATUS], getAdvisorPublishedStatus, {
    refetchOnWindowFocus: true,
    cacheTime: 0,
  });

  return {
    advisorPublishStatusData: query.data?.data,
    isAdvisorPublishStatusLoading: query.isLoading,
    isAdvisorPublishStatusFetching: query.isFetching,
    isAdvisorPublishStatusFetched: query.isFetched,
    refetchAdvisorPublishStatus: query.refetch,
    ogQueryResult: query,
  };
};

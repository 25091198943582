import type { BaseNotice } from './notifications';
import { hasEveryCommonFieldAndValue, hasSomeCommonFieldAndValue } from './parts/helpers';

const expectedNotices: Partial<BaseNotice & { matchAllFields?: boolean }>[] = [
  // File upload
  { message: 'The file is larger than 10MB, please upload a different file.' },

  // Auth
  { message: 'Account is already confirmed.' },
  { message: 'You need to confirm your email address before login' },
  { name: 'API Mutation Error - /login - 401', message: 'Invalid credentials', matchAllFields: true },
  { name: 'API Mutation Error - /login - 401', message: 'Unauthorized Error has occurred.', matchAllFields: true },
  { name: 'API Mutation Error - /finish-interrupted-registration - 404', message: 'Account does not exist.' },
  {
    name: 'API GraphQl Error - account - (INTERNAL_SERVER_ERROR)',
    message: 'Access denied! You need to be authorized to perform this action!',
    url: /login-as-user|register|email-unverified/i,
    matchAllFields: true,
  },
  // TODO: Remove in https://exlabs.atlassian.net/browse/CON-12523
  {
    name: 'API GraphQl Error - getNedPublicData - BusinessRuleValidationError (400)',
    message: 'Ned account must be active to preview public data.',
  },
];

export function isNoticeExpected(notice: BaseNotice, notices = expectedNotices): boolean {
  return notices.some((expectedNotice) => {
    const { matchAllFields, ...noticeConfig } = expectedNotice;

    if (expectedNotice.matchAllFields) {
      return hasEveryCommonFieldAndValue(noticeConfig, notice);
    }

    return hasSomeCommonFieldAndValue(noticeConfig, notice);
  });
}

import type { FormikFieldProps } from '@parts/forms/formik-field/formik-field';
import type { ReactNode } from 'react';
import S from './checkbox-formik-field.styles';

interface ICheckboxFormikFieldProps extends FormikFieldProps {
  children: ReactNode;
}

const CheckboxFormikField = ({ children, ...props }: ICheckboxFormikFieldProps) => (
  <S.CheckboxFormikField {...props}>{children}</S.CheckboxFormikField>
);

export default CheckboxFormikField;

import { Select as SelectBase } from '@parts/select/select';
import { Tag as TagBase } from '@parts/tag/tag';
import styled from 'styled-components';

const Select = styled(SelectBase)`
  width: 100%;

  .ant-select-selection-overflow {
    padding-bottom: ${({ theme }) => theme.spacing.xsmall};
  }

  .ant-select-selection-overflow-item {
    margin: 4px 2px;
    margin-bottom: 0;
  }

  .ant-select-selector {
    padding-right: ${({ theme }) => theme.spacing.base} !important;
  }
`;

const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  border-bottom: 1px solid rgba(128, 128, 128, 0.5);
`;

const Span = styled.span`
  margin-left: ${({ theme }) => theme.spacing.xsmall};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-style: italic;
  opacity: 0.3;
`;

const OptGroupLabel = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const Tag = styled(TagBase)``;

export default {
  Tag,
  Select,
  Label,
  Span,
  OptGroupLabel,
};

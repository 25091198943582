import { isDiscoverableFounder, isUnpaidFounder } from '@abilities';
import type { AbilityBuilder } from '@casl/ability';
import { isFounderFreemium } from '@domain/accounts/subscription-products';
import { Routes } from '@router/routes';
import { AbilityAction, AbilitySubject, type AppAbility, type RoleAbilityBuilderFunction } from '../types';
import { canUpgradeFounderProduct } from './utils/can-upgrade-founder-product';

const createRoutingAbilities: RoleAbilityBuilderFunction = (
  builder: AbilityBuilder<AppAbility>,
  { products },
): AbilityBuilder<AppAbility> => {
  const { cannot, can } = builder;

  if (!canUpgradeFounderProduct(products)) {
    cannot(AbilityAction.ACCESS_PAGE, Routes.UPGRADE_PRODUCTS);
  }

  if (isUnpaidFounder(products)) {
    can(AbilityAction.ACCESS_PAGE, Routes.EXTEND_PRODUCTS);
  } else {
    cannot(AbilityAction.ACCESS_PAGE, Routes.EXTEND_PRODUCTS);
  }

  return builder;
};

const createActionAbilities: RoleAbilityBuilderFunction = (builder, { products }) => {
  const { can } = builder;

  if (!isFounderFreemium(products)) {
    can(AbilityAction.ACCESS, AbilitySubject.LENS_SMART_MATCH);
    can(AbilityAction.ACCESS, AbilitySubject.LENS_CONNECTIONS);
    can(AbilityAction.ACCESS, AbilitySubject.LENS_REQUESTS);
  }

  if (isDiscoverableFounder(products)) {
    can(AbilityAction.ACCESS, AbilitySubject.DISCOVER);
  }

  return builder;
};

export const createFounderAbility: RoleAbilityBuilderFunction = (builder, attributes) => {
  createRoutingAbilities(builder, attributes);
  createActionAbilities(builder, attributes);

  return builder;
};

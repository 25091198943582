import { useMutation } from '@tanstack/react-query';
import { SEARCH_COMPANIES_QUERY_KEY, searchCompanies } from '../api/get-search-companies.query';

export const useSearchCompanies = () => {
  const { data, error, isLoading, mutate, ...restQueryResult } = useMutation({
    mutationKey: [SEARCH_COMPANIES_QUERY_KEY],
    mutationFn: searchCompanies,
  });

  return {
    ...restQueryResult,
    companiesData: data?.data,
    companiesError: error,
    isCompaniesDataLoading: isLoading,
    companiesMutate: mutate,
  };
};

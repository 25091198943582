import { FullWidthField } from '@parts/forms/formik-full-field/formik-full-field';
import { MultiSelectSearch } from '@parts/multiselect-search/multiselect-search';
import { QueryFilterKind, TextComparator } from '@utils/hooks/use-filtering/use-filtering';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { SelectValue } from 'antd/lib/select';
import S from './text-by-values.styles';

export const TextByValues = ({
  getFieldProps,
  getFieldMeta,
  setFieldValue,
  dataKey,
  keys,
  options,
  formatter,
}: {
  getFieldProps: Function;
  getFieldMeta: Function;
  setFieldValue: Function;
  dataKey: string;
  keys: { values: string };
  options: string[];
  formatter?: (val: string) => string;
}) => {
  const [selectAllLabel, selectNoneLabel, selectValueLabel] = useTranslation([
    'filters.selectAll',
    'filters.selectNone',
    'filters.selectValue',
  ]);

  const textByValuesProps = getFieldProps(keys.values);
  const textByValuesMeta = getFieldMeta(keys.values);

  const setTextByValues = (values: SelectValue[] | string[]) => {
    setFieldValue(keys.values, {
      key: dataKey,
      [QueryFilterKind.Text]: {
        values,
        comparator: TextComparator.ListOfValues,
      },
    });
  };

  return (
    <>
      <S.Action onClick={() => setTextByValues(options)}>{selectAllLabel}</S.Action> -
      <S.Action onClick={() => setFieldValue(keys.values, undefined)}>{selectNoneLabel}</S.Action>
      <FullWidthField
        error={textByValuesMeta?.error}
        touched={textByValuesMeta?.touched}
        label={{ label: '', for: `${dataKey} by values` }}
      >
        <MultiSelectSearch
          formatter={formatter}
          value={textByValuesProps?.value?.[QueryFilterKind.Text].values ?? []}
          id={`${dataKey} by values`}
          options={options}
          placeholder={selectValueLabel}
          onChange={(values: SelectValue) => {
            if (Array.isArray(values) && values.length > 0) {
              return setTextByValues(values);
            }

            return setFieldValue(keys.values, undefined);
          }}
          maxTagCount="responsive"
        />
      </FullWidthField>
    </>
  );
};

import { CircularImage } from '@parts/circular-image/circular-image';
import { DefaultAvatar } from '@parts/default-avatar/default-avatar';

type AvatarProps = {
  avatarUrl: string | null | undefined;
  size?: number;
  className?: string;
  letter?: string;
  pastelBackground?: boolean;
  imageBackground?: string;
};

export const Avatar = ({
  avatarUrl,
  size,
  className,
  letter,
  pastelBackground = false,
  imageBackground,
}: AvatarProps) =>
  avatarUrl ? (
    <CircularImage size={size} imageUrl={avatarUrl} className={className} background={imageBackground} />
  ) : (
    <DefaultAvatar className={className} size={size} letter={letter} pastelBackground={pastelBackground} />
  );

import type { V2FlagProps } from './v2-flag.types';

import S from './v2-flag.styles';

const getCountryFlagImageUrl = (countryShort: string) => `${process.env.REACT_APP_S3_FLAGS}${countryShort}.svg`;

export const V2Flag = ({ countryIso, 'data-testid': testId = 'v2-flag', className }: V2FlagProps) => {
  return (
    <S.V2FlagImage
      src={getCountryFlagImageUrl(countryIso)}
      className={className}
      loading="lazy"
      alt={`Flag ${countryIso}`}
      data-testid={testId}
      width={20} // prevent collapsing
      height={12} // prevent collapsing
    />
  );
};

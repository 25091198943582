import type { FounderRoleResponse } from '@pages/content/roles/catalogue/api/get-founder-roles.actions';
import { RoleStatus } from '@pages/content/roles/roles.types';
import { DateTime } from 'luxon';
import type { AppAction } from 'src/types';

export const SET_ROLES = 'management/set-roles';
export const ADD_NEW_ROLE = 'management/add-new-role';
export const CLOSE_ROLE = 'management/close-role';
export const REFRESH_ROLE = 'management/refresh-role';
export const DELETE_ROLE = 'management/delete-role';
export const UPDATE_ROLE = 'management/update-role';

export interface RoleManagementState {
  roles: FounderRoleResponse[];
  isDataLoaded: boolean;
}

export type RoleManagementActionType =
  | AppAction<typeof SET_ROLES, { roles: FounderRoleResponse[] }>
  | AppAction<typeof ADD_NEW_ROLE, { role: { id: string; title: string } }>
  | AppAction<typeof CLOSE_ROLE, { roleId: string }>
  | AppAction<typeof REFRESH_ROLE, { roleId: string }>
  | AppAction<typeof DELETE_ROLE, { roleId: string }>
  | AppAction<typeof UPDATE_ROLE, { role: { id: string; title: string } }>;

export const roleManagementReducer = (
  state: RoleManagementState,
  action: RoleManagementActionType,
): RoleManagementState => {
  const monthsAfterRoleExpires = 6;

  switch (action.type) {
    case SET_ROLES:
      return {
        ...state,
        isDataLoaded: true,
        roles: action.roles,
      };

    case ADD_NEW_ROLE:
      return {
        ...state,
        roles: [
          ...state.roles,
          {
            id: action.role.id,
            title: action.role.title,
            applications: 0,
            views: 0,
            status: RoleStatus.InProgress,
            expiresAt: DateTime.now()
              .plus({
                month: monthsAfterRoleExpires,
              })
              .toISO(),
            postedAt: new Date().toISOString(),
          },
        ],
      };

    case CLOSE_ROLE:
      return {
        ...state,
        roles: state.roles.map((role) =>
          role.id === action.roleId
            ? {
                ...role,
                status: RoleStatus.Expired,
              }
            : role,
        ),
      };

    case REFRESH_ROLE:
      return {
        ...state,
        roles: state.roles.map((role) =>
          role.id === action.roleId
            ? {
                ...role,
                status: RoleStatus.InProgress,
                expiresAt: DateTime.now()
                  .plus({
                    month: monthsAfterRoleExpires,
                  })
                  .toISO(),
              }
            : role,
        ),
      };

    case UPDATE_ROLE: {
      return {
        ...state,
        roles: state.roles.map((role) =>
          role.id === action.role.id
            ? {
                ...role,
                title: action.role.title,
              }
            : role,
        ),
      };
    }

    case DELETE_ROLE:
      return {
        ...state,
        roles: state.roles.filter((role) => role.id !== action.roleId),
      };

    default:
      return state;
  }
};

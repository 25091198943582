import { V2Loader } from '@/components/ui/v2-loader/v2-loader';
import { type INedExperience } from '@/pages/content/profile/ned/ned-profile.page';
import { useGetSkillsAndIndustries } from '../../hooks/use-get-skills-and-industries';
import { useEditExperience } from '../../hooks/use-edit-experience';
import { ExperienceForm } from '../experience-form';

export const EditData = ({
  experienceData,
  closeModal,
}: {
  experienceData: INedExperience;
  closeModal: () => void;
}) => {
  const { editExperienceMutation, isEditExperienceLoading } = useEditExperience(closeModal);

  const { skillSets, industries, isLoading: areSkillsAndIndustriesLoading } = useGetSkillsAndIndustries();

  if (areSkillsAndIndustriesLoading) return <V2Loader />;

  return (
    <ExperienceForm
      isInternalCompany={Boolean(experienceData.company.id)}
      industries={industries ?? []}
      skillSets={skillSets ?? []}
      initialValues={{
        name: experienceData.company.name,
        role: experienceData.role,
        industry: experienceData.company.industry || null,
        email: experienceData.company.email,
        startDate: experienceData.startDate,
        endDate: experienceData.endDate || null,
        isOngoing: experienceData.isOngoing,
        compensationType: experienceData.compensationType,
        skillSet: experienceData.skillSet,
        experienceSummary: experienceData.experienceSummary,
      }}
      onSubmit={(values) =>
        editExperienceMutation({
          formData: values,
          companyId: experienceData.company.id || null,
          experienceId: experienceData.id,
        })}
      isLoading={isEditExperienceLoading}
    />
  );
};

import { Roles } from '@/domain/accounts/roles';
import { useMediaQuery } from '@/hooks/use-media-query';
import { useTheme } from '@/hooks/use-theme';
import {
  GET_NED_PUBLIC_PAGE_DETAILS_CACHE_KEY,
  getNedPublicProfileDetailsAction,
} from '@/pages/content/public-profile/ned/api/get-ned-public-page-details';
import { Routes } from '@router/routes';
import { theme } from '@styles/theme-config';
import { useQuery } from '@tanstack/react-query';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { useCallback, useEffect, useRef, useState } from 'react';
import Talk from 'talkjs';
import { SidePanel } from '../side-panel/side-panel';

import S from './inbox.styles';

export const conversationQueryParam = 'conversationId';

export const Inbox = () => {
  const {
    state: { messaging, userRole },
  } = useUserAccount();
  const { mode } = useTheme();

  const size = useMediaQuery();
  const isMobile = ['sm', 'xs'].includes(size);
  const talkJsContainer = useRef<HTMLDivElement>(null);
  const [interlocutorId, setInterlocutorId] = useState<string>('');

  const {
    windowSize: { height },
    deviceData: { isDesktop },
  } = useDeviceDetect();

  const { [conversationQueryParam]: id } = useQueryParams() as {
    [conversationQueryParam]?: string;
  };

  const history = useHistory();

  const setHeight = useCallback(() => {
    if (talkJsContainer && talkJsContainer.current) {
      talkJsContainer.current.style.height = isDesktop
        ? theme.ui.messagingHeight
        : `${Math.max(
            window.innerHeight - talkJsContainer.current.offsetTop - Number(theme.spacing.medium.replace('px', '')),
            Number(theme.ui.minMessagingHeight.replace('px', '')),
          )}px`;
    }
  }, [isDesktop]);

  useEffect(() => {
    Talk.ready.then(() => {
      if (messaging.session) {
        const inbox = messaging.session.createInbox({
          selected: id ?? undefined,
          theme: mode,
        });

        inbox.mount(talkJsContainer.current);
        inbox.on('conversationSelected', (e) => {
          if (!e.others) return;
          if (typeof e.others[0].id === 'string') return setInterlocutorId(e.others[0].id);
        });
        inbox.on('selectConversation', (e) => {
          history.push({
            search: `${conversationQueryParam}=${e.conversation.id}`,
            pathname: Routes.MESSAGING,
          });
        });
      }
    });
    // eslint-disable-next-line
  }, [messaging.session, mode]);

  useEffect(() => {
    setHeight();
  }, [isDesktop, setHeight, height]);

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery(
    [GET_NED_PUBLIC_PAGE_DETAILS_CACHE_KEY, interlocutorId],
    getNedPublicProfileDetailsAction(interlocutorId),
    {
      enabled: Boolean(interlocutorId) && userRole === Roles.FOUNDER,
    },
  );

  return (
    <>
      <S.Wrapper>
        <S.Inbox ref={talkJsContainer} />
        {response?.data && !isMobile && (
          <SidePanel
            data={response.data}
            targetId={interlocutorId}
            isLoading={isLoading}
            refetchProfileDetails={refetch}
          />
        )}
      </S.Wrapper>
      {response?.data && isMobile && (
        <SidePanel
          data={response.data}
          targetId={interlocutorId}
          isLoading={isLoading}
          refetchProfileDetails={refetch}
        />
      )}
    </>
  );
};

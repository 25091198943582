import { engageFounderMessagingAction } from '@pages/content/lens/api/message-engage-founder';
import { investorDisconnectFounderAction } from '@pages/content/pulse/api/disconnect-founder/disconnect-founder.action';
import { Table } from '@parts/table/table';
import { useMutation } from '@tanstack/react-query';
import useConfirmModal from '@utils/hooks/use-confirm-modal/use-confirm-modal';
import { useEngageConversation } from '@utils/hooks/use-engage-conversation/use-engage-conversation';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { Columns } from './columns';

export interface ConnectedCompany {
  about: string;
  customerGroup: string[];
  id: string;
  industry: string[];
  investmentStage: string;
  logoUrl: null | string;
  name: string;
  productStage: string;
  canBePreviewed: boolean;
}

export const CompaniesTable = ({ items, refetch }: { items: ConnectedCompany[]; refetch: Function }) => {
  const history = useHistory();

  const { mutateAsync: disconnect, isLoading: disconnectInProgress } = useMutation(investorDisconnectFounderAction, {
    onSettled: () => refetch(),
  });

  const [
    disconnectModalLabel,
    disconnectFounder,
    companyNameLabel,
    industryLabel,
    investmentStageLabel,
    foundingStatusLabel,
    chatLabel,
    disconnectLabel,
  ] = useTranslation([
    'lens.disconnect',
    'lens.investor.disconnectCompany',
    'lens.investor.table.companyName',
    'lens.investor.table.industry',
    'lens.investor.table.investmentStage',
    'lens.investor.table.foundingStatus',
    'lens.investor.table.chat',
    'lens.investor.table.disconnect',
  ]);

  const [connectionIdToDisconnect, setConnectionIdToDisconnect] = useState<string | undefined>(undefined);

  const { mutateAsync: message, isLoading: isMessageLoading } = useEngageConversation(engageFounderMessagingAction);

  const { modal: confirmDisconnectModal, show: showConfirmDisconnectModal } = useConfirmModal({
    title: disconnectModalLabel,
    content: disconnectFounder,
    onConfirm: () => disconnect(connectionIdToDisconnect as string),
  });

  const onDisconnect = (id: string) => {
    showConfirmDisconnectModal();
    setConnectionIdToDisconnect(id);
  };

  return (
    <>
      {confirmDisconnectModal}
      <Table
        dataSource={items}
        columns={
          Columns({
            message,
            isMessageLoading,
            onDisconnect,
            disconnectInProgress,
            translations: {
              companyNameLabel,
              industryLabel,
              investmentStageLabel,
              foundingStatusLabel,
              chatLabel,
              disconnectLabel,
            },
            history,
          }) as ColumnsType<object>
        }
        rowKey="id"
      />
    </>
  );
};

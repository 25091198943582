import { V2AriaTable } from '@/components/blocks/v2-aria-table/v2-aria-table';
import { V2AvatarWithDetails } from '@/components/ui/v2-avatar-with-details/v2-avatar-with-details';
import { V2Badge } from '@/components/ui/v2-badge/v2-badge';
import { V2Link } from '@/components/ui/v2-link/v2-link';
import { investorIdQueryParam } from '@/pages/content/public-profile/investor/investor.page';
import { Links } from '@/router/links';
import type { FounderConnection, FounderConnectionType } from '@modules/founder/connections/types';

export const NameAndDetailsCell = ({ name, avatarUrl, isNew, participantId, connectionType }: FounderConnection) => {
  const profileUrl: Record<FounderConnectionType, string> = {
    Advisor: `${Links.NED_PUBLIC_PROFILE(participantId)}`,
    Investor: `${Links.INVESTOR_PUBLIC_PROFILE()}?${investorIdQueryParam}=${participantId}`,
    Both: `${Links.INVESTOR_PUBLIC_PROFILE()}?${investorIdQueryParam}=${participantId}`,
  };

  const badges = isNew ? [<V2Badge text="New" startIcon="person" variant="accent" size="sm" key="new" />] : [];

  return (
    <V2AriaTable.BodyCell>
      <V2Link to={profileUrl[connectionType]} hasUnderline={false}>
        <V2AvatarWithDetails avatar={{ name, src: avatarUrl }} mainText={name} badges={badges} />
      </V2Link>
    </V2AriaTable.BodyCell>
  );
};

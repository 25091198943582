import { NedLayout } from '@layout/ned/ned.layout';
import { checkIfValidWysiWygValue } from '@pages/content/profile/hooks/use-wysiwyg';
import { founderIdQueryParam } from '@pages/content/public-profile/founder/founder.page';
import { Badge } from '@pages/content/public-profile/parts/badge/badge';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { PageHeader } from '@parts/page-header/page-header';
import { Links } from '@router/links';
import { useQuery } from '@tanstack/react-query';
import { SanitizeWysiwyg } from '@utils/fns/sanitize-wysiwyg';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Col, Row } from 'antd';
import { useParams } from 'react-router';
import Description from '../../../../../parts/description/description';
import CompanyTile from '../../parts/company-tile/company-tile';
import { GET_ROLE_DETAILS_CACHE_KEY, getRoleDetails } from '../api/role-details.actions';
import { Apply } from '../parts/apply/apply';
import { Meta } from '../parts/meta/meta';
import S from './ned.page.styles';

export const NedPreview = ({ Layout = NedLayout }: { Layout?: Function }) => {
  const [badgeNewText] = useTranslation(['profile.badgeNew']);
  const { id } = useParams() as { id: string };
  const history = useHistory();

  const {
    data: response,
    refetch: refetchRoleData,
    isLoading,
  } = useQuery([`${GET_ROLE_DETAILS_CACHE_KEY}_${id}`], getRoleDetails(id));

  const handleGoBack = () => {
    history.goBack();
  };

  if (isLoading) {
    return (
      <Layout>
        <FullHeightSpinner />
      </Layout>
    );
  }

  if (!response?.data) {
    return null;
  }

  const {
    location,
    roleType,
    compensation,
    engagement,
    experience,
    skillSet,
    placedAt,
    expiresAt,
    isApplied,
    companyLogoUrl: logoUrl,
    companyName: name,
    aboutCompany: about,
    isProfileCvUploaded,
    file,
    companyId,
  } = response.data;

  const metaData = {
    location,
    roleType,
    compensation,
    engagement,
    skillSet,
    experience: Number(experience),
    postedAt: placedAt,
    closesAt: expiresAt,
  };

  const founderProfileUrl = history.createHref({
    search: `${founderIdQueryParam}=${companyId}`,
    pathname: Links.FOUNDER_PUBLIC_PROFILE_DETAILS(),
  });

  return (
    <Layout>
      <PageHeader
        title={response.data.roleTitle || ''}
        subTitle={response.data.isNew ? <Badge data-testid="badge-new">{badgeNewText}</Badge> : null}
        onBack={handleGoBack}
      />
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
          <S.MainSection>
            <Meta {...metaData} />
            <Description
              dangerouslySetInnerHTML={{
                __html: SanitizeWysiwyg(JSON.parse(response.data.description || '{}')),
              }}
            />
          </S.MainSection>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <S.Section>
            <CompanyTile
              url={founderProfileUrl}
              about={checkIfValidWysiWygValue(about) ? SanitizeWysiwyg(JSON.parse(about)) : null}
              logoUrl={logoUrl}
              name={name}
            />
          </S.Section>
          <Apply
            dataTestId="ned-apply-for-a-role-btn"
            isApplied={isApplied}
            refetch={refetchRoleData}
            roleCvName={file ? file.name : ''}
            profileCvUploaded={isProfileCvUploaded}
          />
        </Col>
      </Row>
    </Layout>
  );
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { InvestmentsScale, type PreviousInvestment } from '../types';

export interface IInvestorInvestmentPayload {
  investmentThesis?: string | null;
  minInvestmentAmount?: number | null;
  maxInvestmentAmount?: number | null;
  isOpenToLeadRound?: boolean | true;
  previousInvestments?: boolean;
  previousInvestmentsScale?: InvestmentsScale | null;
  previousInvestmentsTable?: PreviousInvestment[] | null;
  isDeployingCapitalInNextThreeMonths?: boolean | null;
}

export const updateInvestmentsAction = (formData: IInvestorInvestmentPayload) =>
  axiosInstance.patch('/profile/investor/investment-spec', formData);

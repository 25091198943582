import { InvestorPublicProfile } from '@pages/content/public-profile/investor/parts/investor-public-profile';
import { NedPublicProfile } from '@pages/content/public-profile/ned/parts/ned-public-profile';
import { Routes } from '@router/routes';
import { multiRoleProfileInvestorKey, multiRoleProfileNedKey } from '@router/tabs-keys';
import { mapEmptyToString } from '@utils/fns/map-empty-to-string';
import type { IInvestorProfileDataResponse } from '../../investor/api/types';
import type { INedProfileDataResponse } from '../../ned/api/ned-profile/ned-profile.query';
import { PublicPreviewNotification } from '../../parts/public-preview-notification/public-preview-notification';
import S from '../investor-ned-profile.styles';

interface IPublicDataPage {
  id: string;
  investorProfileData: IInvestorProfileDataResponse;
  nedProfileData: INedProfileDataResponse;
  togglePubicView: () => void;
  investorLabel: string;
  nedLabel: string;
}

export const PublicDataPage = ({
  id,
  investorProfileData,
  nedProfileData,
  togglePubicView,
  investorLabel,
  nedLabel,
}: IPublicDataPage) => {
  const {
    getInvestorPersonalDetails,
    getInvestorSmartMatch,
    getInvestorInvestmentSpec,
    getInvestorInvestmentSpec: { minInvestmentAmount, maxInvestmentAmount },
  } = investorProfileData;

  const { personalDetails, smartMatch, experience, about } = nedProfileData;

  return (
    <>
      <PublicPreviewNotification onClick={() => togglePubicView()} />
      <S.Tabs
        defaultLocator={multiRoleProfileInvestorKey}
        route={Routes.MULTI_ROLE_PROFILE}
        locator="role"
        tabs={[
          {
            key: multiRoleProfileInvestorKey,
            label: investorLabel,
            content: (
              <InvestorPublicProfile
                id={id}
                handleBackAction={togglePubicView}
                publicData={{
                  ...mapEmptyToString(getInvestorPersonalDetails),
                  profilePicture: { url: getInvestorPersonalDetails.profilePicture?.url ?? '' },
                  ...getInvestorSmartMatch,
                  ...mapEmptyToString(getInvestorInvestmentSpec),
                  ...getInvestorInvestmentSpec,
                  minimumInvestmentAmount: {
                    amount: (minInvestmentAmount || 0).toString(),
                  },
                  maximumInvestmentAmount: {
                    amount: (maxInvestmentAmount || 0).toString(),
                  },
                }}
              />
            ),
          },
          {
            key: multiRoleProfileNedKey,
            label: nedLabel,
            content: (
              <NedPublicProfile
                id={id}
                handleBackAction={togglePubicView}
                showBioInPersonalDetails
                publicData={{
                  ...mapEmptyToString(personalDetails),
                  aboutMe: about.aboutMe,
                  myExpectations: about.myExpectations,
                  myOffering: about.myOffering,
                  roles: [],
                  skillSet: [],
                  industries: smartMatch.industries,
                  isAdvisor: smartMatch.isAdvisor,
                  isNed: smartMatch.isNed,
                  primarySkillSet: smartMatch.primarySkillSet,
                  secondarySkillSet: smartMatch.secondarySkillSet,
                  isFractional: smartMatch.isFractional,
                  compensations: smartMatch.compensations,
                  resume: {
                    name: personalDetails?.resumeName ?? '',
                    path: personalDetails?.resumePath ?? '',
                    url: personalDetails?.resumeUrl ?? '',
                  },
                  profilePicture: {
                    name: personalDetails?.photoUrl,
                    path: personalDetails?.photoUrl,
                    url: personalDetails?.photoUrl,
                  },
                  experience: [...(experience ?? [])],
                  targetGeography: smartMatch.targetGeography ?? [],
                }}
              />
            ),
          },
        ]}
      />
    </>
  );
};

import type { ButtonProps } from 'antd/es/button';
import { useEffect, useState, type ReactNode } from 'react';
import S from './button.styles';

const ANIMATION_TIME = 3000;

export const Button = ({ children, animate, ...rest }: ButtonProps & { children: ReactNode; animate?: boolean }) => {
  const [showTick, setShowTick] = useState(false);

  useEffect(() => {
    if (animate) {
      setShowTick(true);
      setTimeout(() => setShowTick(false), ANIMATION_TIME);
    }
  }, [animate]);

  return (
    <S.Button {...rest} disabled={rest.disabled || showTick} animate={showTick} animationTime={ANIMATION_TIME}>
      {showTick ? <S.Tick animationTime={ANIMATION_TIME} /> : children}
    </S.Button>
  );
};

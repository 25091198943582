import message from '@/parts/message/message';
import { getServerError } from '@/utils/fns/get-server-error';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { acceptConnection } from '../api/accept-connection.action';

export const useAcceptConnection = (connectionId: string, callback: () => void) => {
  const mutation = useMutation(acceptConnection(connectionId), {
    onSuccess: () => {
      callback();
      message.success({ content: 'The connection has been accepted' });
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  return {
    isAcceptConnectionLoading: mutation.isLoading,
    acceptConnection: mutation.mutate,
    rawMutation: {
      ...mutation,
    },
  };
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_BOOLEAN_METRIC_VALUE_QUERY_CACHE_KEY = (metricId: string) => `get-boolean-metric-value-${metricId}`;

export const getBooleanMetricValue =
  (id: string) => async (): Promise<GraphqlResponse<{ value: boolean | null; note: string | null }>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
        query getBooleanMetricValue {
          getBooleanMetricValue(metricId: "${id}")
        {
          value,
          note,
        }
      }
    `,
    });

    return graphqlResponseWrapper(data, ['getBooleanMetricValue']);
  };

import { Dropdown } from 'antd';
import { useMemo } from 'react';
import { Item } from './parts/Item';
import { Menu } from './parts/Menu';
import { V2DropdownContext } from './v2-dropdown-context';
import type { V2DropdownProps } from './v2-dropdown.types';

const V2Dropdown = ({ overlay, toggle }: V2DropdownProps) => {
  return (
    <V2DropdownContext.Provider value={useMemo(() => ({ value: undefined }), [])}>
      <Dropdown overlay={overlay} trigger={['click']}>
        {toggle}
      </Dropdown>
    </V2DropdownContext.Provider>
  );
};

V2Dropdown.Menu = Menu;
V2Dropdown.Item = Item;

export { V2Dropdown };

import { useHistory } from '@utils/hooks/use-history/use-history';
import type { FunctionComponent } from 'react';

import { Routes } from '@router/routes';
import S from './empty-state.styles';

export const EmptyState = ({
  to,
  toLabel,
  ...rest
}: {
  toLabel: string;
  to: Routes | string;
  Icon: FunctionComponent;
  title: string;
  subTitle: string;
}) => {
  const history = useHistory();

  return (
    <S.Wrapper {...rest}>
      <S.Button type="primary" onClick={() => history.push(to)}>
        {toLabel}
      </S.Button>
    </S.Wrapper>
  );
};

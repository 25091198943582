import type { ReactChild, ReactNode } from 'react';
import S from './single-button-modal.styles';

interface SingleButtonModalProps {
  footer: ReactChild | ReactNode;
  title: string;
  icon?: ReactChild;
  children?: ReactChild;
  onClose?: () => void;
  visible?: boolean;
  width?: string;
  className?: string;
}

export const SingleButtonModal = ({
  children,
  footer,
  title,
  onClose,
  visible = true,
  width,
  icon,
  className,
}: SingleButtonModalProps) => (
  <S.Modal
    className={className}
    visible={visible}
    footer={footer}
    hasChildren={Boolean(children)}
    title={
      icon ? (
        <>
          <S.IconHolder>{icon}</S.IconHolder>
          {title}
        </>
      ) : (
        title
      )
    }
    onCancel={onClose}
    closeIcon={<S.Close />}
    width={width}
  >
    {children}
  </S.Modal>
);

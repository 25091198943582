import type { ExternalMetric, InternalMetric } from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import { MetricsViewConfigure } from '@parts/metrics-view-configure/metrics-view-configure';
import { useMetricsConfigure } from '@utils/hooks/use-metrics-configure/use-metrics-confugure';

export const Configuration = ({
  data,
  onConfigurationFinish,
  integratedMetricsView,
}: {
  data: ExternalMetric[] | InternalMetric[];
  onConfigurationFinish: (data: ExternalMetric[] | InternalMetric[]) => void;
  integratedMetricsView?: boolean;
}) => {
  const { values } = useMetricsConfigure({
    data,
    onConfigurationFinish,
    autoSave: true,
  });

  return <MetricsViewConfigure values={values} integratedMetricsView={integratedMetricsView} />;
};

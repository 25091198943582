import { Head } from './parts/head';
import { Row } from './parts/row';
import { V2AriaMobileConnectionCardContext } from './v2-aria-mobile-connection-card-context';
import type { V2AriaMobileConnectionCardProps } from './v2-aria-mobile-connection-card.types';

import { Action } from './parts/action';
import { Body } from './parts/body';
import { Detail } from './parts/detail';
import { Footer } from './parts/footer';
import { List } from './parts/list';

import S from './v2-aria-mobile-connection-card.styles';

const V2AriaMobileConnectionCard = ({ head, body, footer, notes, ...rest }: V2AriaMobileConnectionCardProps) => {
  return (
    <V2AriaMobileConnectionCardContext.Provider value={{ value: undefined }}>
      <S.Card {...rest}>
        {head}
        {body}
        {footer}
        {notes && <S.Divider />}
        {notes}
      </S.Card>
    </V2AriaMobileConnectionCardContext.Provider>
  );
};

V2AriaMobileConnectionCard.Head = Head;
V2AriaMobileConnectionCard.Row = Row;
V2AriaMobileConnectionCard.List = List;
V2AriaMobileConnectionCard.Action = Action;
V2AriaMobileConnectionCard.Body = Body;
V2AriaMobileConnectionCard.Detail = Detail;
V2AriaMobileConnectionCard.Footer = Footer;

export { V2AriaMobileConnectionCard };

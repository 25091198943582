import styled from 'styled-components';

const Wrapper = styled.h2`
  text-align: center;
  font-size: 22px;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.color.header};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`;

export default { Wrapper };

import { AdvisorRole } from '@/domain/accounts/roles';
import { useAdvisorPreview } from '@/modules/founder/advisor-search/components/preview-modal/hooks/use-advisor-preview-modal';
import { ConditionalRender } from '@parts/condition-renderer/conditional-renderer';
import { InfiniteScroller } from '@parts/infinite-scroller/infinite-scroller';
import { Filters } from '../filters/filters';
import { useAdvisorsInfiniteQuery } from './hooks/use-advisors-infinite-query';
import { BrowsedMatchesGrid } from './parts/browsed-matches-grid/browsed-matches-grid';
import { Grid } from './parts/grid';
import { NoResultsBanner } from './parts/no-results-banner/no-results-banner';

export const PlacementsSearch = () => {
  const { items, hasNextPage, fetchNextPage, isLoading, isFetching, refetch } = useAdvisorsInfiniteQuery(
    AdvisorRole.PLACEMENT,
  );
  const { component: advisorPreview, openPreview } = useAdvisorPreview({
    idList: items.map((item) => item.id),
    hasNextPage,
    fetchNextPage,
    role: AdvisorRole.PLACEMENT,
    revalidateData: refetch,
  });
  const showEmptyState = items.length === 0 && !isLoading && !isFetching;

  return (
    <>
      <Grid>
        <Filters
          advisorRole={AdvisorRole.PLACEMENT}
          filtersList={['checkboxes', 'industries', 'skillSet', 'countriesAndRegions', 'previousPositions', 'rating']}
        />

        <InfiniteScroller
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isLoading={isLoading || isFetching}
          dataTestId="advisor-search-role-placements"
        >
          <ConditionalRender
            condition={showEmptyState}
            element={<NoResultsBanner />}
            fallback={
              <BrowsedMatchesGrid items={items} onPreview={openPreview} isLoading={isLoading} refetch={refetch} />
            }
          />
        </InfiniteScroller>
      </Grid>

      {advisorPreview}
    </>
  );
};

import TableChartIconNoMargin from '@assets/icons/table-chart.svg?react';
import NavDropdown from '@parts/nav-dropdown/nav-dropdown';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useState } from 'react';
import S from './chart-view-dropdown.styles';

export enum ChartViewType {
  LINEAR = 'Linear',
  COLUMN = 'Column',
  CIRCULAR = 'Circular',
  NUMERIC = 'Numeric',
  BAR = 'Bar',
  TABLE = 'Table',
}

export enum ChartSize {
  SMALL = 'small',
  DEFAULT = 'default',
}

export const ChartViewDropdown = ({
  onMenuItemSelect,
  chartsGroup = [ChartViewType.LINEAR, ChartViewType.COLUMN],
  numericDisabled = false,
  chartTypeBase,
}: {
  onMenuItemSelect: (type: ChartViewType) => void;
  chartsGroup?: ChartViewType[];
  numericDisabled?: boolean;
  chartTypeBase?: ChartViewType;
}) => {
  const [titleLabel, lineLabel, columnLabel, numericLabel, barLabel, circularLabel, tableLabel] = useTranslation([
    'pulseDashboard.metricTypeSelect.title',
    'pulseDashboard.metricTypeSelect.line',
    'pulseDashboard.metricTypeSelect.column',
    'pulseDashboard.metricTypeSelect.numeric',
    'pulseDashboard.metricTypeSelect.bar',
    'pulseDashboard.metricTypeSelect.circular',
    'pulseDashboard.metricTypeSelect.table',
  ]);

  const [chartType, setChartType] = useState(chartTypeBase || chartsGroup[0]);

  const chartLabels = {
    [ChartViewType.LINEAR]: {
      label: lineLabel,
      icon: <S.LineChartOptionIcon />,
    },
    [ChartViewType.COLUMN]: {
      label: columnLabel,
      icon: <S.ColumnChartOptionIcon />,
    },
    [ChartViewType.BAR]: {
      label: barLabel,
      icon: <S.BarChartOptionIcon />,
    },
    [ChartViewType.CIRCULAR]: {
      label: circularLabel,
      icon: <S.CircularChartOptionIcon />,
    },
    [ChartViewType.NUMERIC]: {
      label: numericLabel,
      icon: <S.NumericChartOptionIcon />,
    },
    [ChartViewType.TABLE]: {
      label: tableLabel,
      icon: <S.TableChartIcon />,
    },
  };

  const icon = (() => {
    switch (chartType) {
      case ChartViewType.COLUMN:
        return <S.ColumnChartIcon />;

      case ChartViewType.NUMERIC:
        return <S.NumericChartIcon />;

      case ChartViewType.CIRCULAR:
        return <S.CircularChartIcon />;

      case ChartViewType.BAR:
        return <S.BarChartIcon />;

      case ChartViewType.TABLE:
        return <TableChartIconNoMargin />;

      case ChartViewType.LINEAR:
      default:
        return <S.LineChartIcon />;
    }
  })();

  const handleMenuItemSelect = (type: ChartViewType) => () => {
    setChartType(type);
    onMenuItemSelect(type);
  };

  const items = chartsGroup.map((chart, key) => (
    <S.Item key={`chart-view-${chart}-${key}`} onClick={handleMenuItemSelect(chart)}>
      {chartLabels[chart].icon} {chartLabels[chart].label}
    </S.Item>
  ));

  if (!numericDisabled) {
    items.push(
      <S.Item onClick={handleMenuItemSelect(ChartViewType.NUMERIC)}>
        <S.NumericChartOptionIcon /> {numericLabel}
      </S.Item>,
    );
  }

  return <NavDropdown label={titleLabel} icon={icon} items={items} />;
};

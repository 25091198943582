import { InvestorType } from '@pages/content/profile/investor/api/types';
import { createContext, type Dispatch } from 'react';
import type { InvestorNedActionType, InvestorNedState } from './investor-ned.reducer';

export type InvestorNedStateApi = {
  state: InvestorNedState;
  dispatch: Dispatch<InvestorNedActionType>;
};

export const investorNedInitialState: InvestorNedState = {
  personalDetails: {
    email: '',
    firstName: '',
    lastName: '',
    gender: null,
    countryOfResidence: null,
    phoneNumber: '',
    linkedinUrl: '',
    twitterUrl: '',
    profilePhotoPath: '',

    institutionType: null,
    investorType: InvestorType.NonSpecified,
    companyName: '',
    recentOccupation: '',
  },
  about: {
    aboutMe: null,
    myExpectations: null,
    myOffering: null,
  },
  investorSmartMatch: {
    industries: [],
    customerGroup: [],
    productStage: [],
    investmentStage: [],
    targetGeography: [],
  },
  nedSmartMatch: {
    industries: [],
    isNed: false,
    isAdvisor: false,
    isFractional: false,
    primarySkillSet: '',
    secondarySkillSet: [],
    compensations: [],
    targetGeography: [],
  },
  nedBoardExperience: [],
  investment: {
    minInvestmentAmount: 0,
    maxInvestmentAmount: 0,
    investmentThesis: '',
    previousInvestmentsTable: [],
    hidePreviousInvestments: false,
    isDeployingCapitalInNextThreeMonths: undefined,
  },
  publishStatus: {
    smartMatchShortages: [],
    published: false,
  },
  investorDataLoaded: false,
  nedDataLoaded: false,
};

export const InvestorNedContext = createContext<InvestorNedStateApi | InvestorNedState>(investorNedInitialState);

import styled from 'styled-components';

const Link = styled.div`
  color: ${({ theme }) => theme.baseColors.Blue200};
  cursor: pointer;
  display: inline;
`;

export default {
  Link,
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_FOUNDER_PUBLISH_STATUS_CACHE_KEY = 'homepage-get-founder-publish-status';

type FounderPublishStatus = {
  published: boolean;
};

export const getFounderPublishStatus = async (): Promise<GraphqlResponse<FounderPublishStatus>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getFounderPublishStatus {
        publishStatus: getFounderSmartMatch {
          published
        }
      }
  `,
  });

  return graphqlResponseWrapper(data, ['publishStatus']);
};

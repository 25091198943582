import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_DETAILED_PORTFOLIO_CACHE_KEY = (id: string) => `get-investor-portfolios-${id}`;

export type InvestmentInCompany = {
  id: string;
  round: {
    date: string;
    type: string;
  };
  percentageOwnership: number;
  amount: number;
  shares: number;
  currentValue: number;
  preMoneyVal: number;
  postMoneyVal: number;
  confirmed: boolean;
  initiator: string;
  archived: boolean;
};

export const getInvestmentsInCompanyAction =
  (investingRelationshipId: string) => async (): Promise<GraphqlResponse<InvestmentInCompany[]>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
      query getInvestmentsInCompany {
        investmentsInCompany(investingRelationshipId: "${investingRelationshipId}") {
            id
            round {
              date
              type
            }
            percentageOwnership
            amount
            shares
            currentValue
            preMoneyVal
            postMoneyVal
            confirmed
            initiator
            archived
        }
      }`,
    });

    return graphqlResponseWrapper(data, ['investmentsInCompany']);
  };

import styled from 'styled-components';

interface FundingRoundFooterProps {
  readOnly?: boolean;
}

export const FundingRoundContainer = styled.article`
  overflow-y: hidden;
`;

export const FundingRoundFooter = styled.footer<FundingRoundFooterProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ readOnly }) => (readOnly ? 'flex-end' : 'space-between')};
  padding: 5px 0;
  background-color: ${({ theme }) => theme.color.sectionBackground};

  & > button {
    display: flex;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSize.small};
    color: ${({ theme }) => theme.color.fontPrimary};

    & > .anticon {
      font-size: 23px;
      color: ${({ theme }) => theme.color.primary};
    }
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-direction: column-reverse;
    align-items: ${({ readOnly }) => (readOnly ? 'flex-end' : 'flex-start')};

    & > button {
      & > .anticon {
        margin-right: ${({ readOnly }) => (readOnly ? '0px' : '8px')};
        margin-left: ${({ readOnly }) => (readOnly ? '8px' : '0px')};
      }
    }

    & > button:nth-child(2) {
      flex-direction: row-reverse;
    }
  }
`;

import { FormikLabel as FormikLabelBase } from '@parts/forms/formik-label/formik-label';
import { FormikRow as FormikRowBase } from '@parts/forms/formik-row/formik-row';
import { Select as SelectBase } from '@parts/select/select';
import { Input as InputBase } from 'antd';
import styled from 'styled-components';

const FormikRow = styled(FormikRowBase)`
  &:last-of-type {
    margin: 0;
  }
`;

const Line = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  margin-top: ${({ theme }) => theme.spacing.xbase};
`;

const Title = styled.span`
  font-size: ${({ theme }) => theme.fontSize.xmedium};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  margin-top: ${({ theme }) => theme.spacing.xmedium};
  margin-bottom: ${({ theme }) => theme.spacing.small};
  display: block;
`;

const PersonalNote = styled(InputBase.TextArea)`
  resize: none;
`;

const LightLabel = styled(FormikLabelBase)`
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;

const WarrantsLabel = styled(FormikLabelBase)`
  margin-bottom: ${({ theme }) => theme.spacing.small};
  display: block;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

const Select = styled(SelectBase)`
  width: 100%;
`;

export default {
  FormikRow,
  Select,
  PersonalNote,
  Line,
  Title,
  WarrantsLabel,
  LightLabel,
};

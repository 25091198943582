import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { RadioChangeEvent } from 'antd/lib/radio';
import type { RadioButtonProps } from 'antd/lib/radio/radioButton';
import type { ReactNode } from 'react';
import type { Any } from 'src/types';
import S from './conditional-show-toggle.style';

export const ConditionalShowToggle = ({
  children,
  onChange,
  value,
  label = '',
  disabled = false,
  dataTestId = 'conditional-show-toggle',
  justify = 'center',
  align = 'center',
  textAlign = 'inherit',
  RadioItem = S.RadioButton,
  ...restProps
}: {
  children: ReactNode;
  onChange: (e: RadioChangeEvent) => void;
  value: boolean | null | undefined;
  label?: string;
  disabled?: boolean;
  dataTestId?: string;
  justify?: 'center' | 'start' | 'end';
  align?: 'center' | 'start' | 'end';
  textAlign?: 'start' | 'center' | 'right' | 'inherit';
  RadioItem?: React.ForwardRefExoticComponent<RadioButtonProps & React.RefAttributes<Any>>;
}) => {
  const [yesLabel, noLabel] = useTranslation(['global.yes', 'global.no']);

  return (
    <fieldset data-testid={dataTestId} {...restProps}>
      {label && <S.Label textAlign={textAlign}>{label}</S.Label>}
      <S.Wrapper data-testid="conditional-show-toggle" align={align} justify={justify}>
        <S.RadioGroup data-testid="conditional-show-toggle-radio" onChange={onChange} value={value} disabled={disabled}>
          <RadioItem data-testid={`${dataTestId}-toggle-answer-false`} value={false}>
            {noLabel}
          </RadioItem>
          <RadioItem data-testid={`${dataTestId}-toggle-answer-true`} value={true}>
            {yesLabel}
          </RadioItem>
        </S.RadioGroup>
        {value && children && <S.ChildWrapper data-testid="conditional-show-toggle-child">{children}</S.ChildWrapper>}
      </S.Wrapper>
    </fieldset>
  );
};

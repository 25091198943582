import {
  investorProfileDataAction,
  INVESTOR_PROFILE_DATA_REQUEST,
} from '@pages/content/profile/investor/api/investor-profile/investor-profile';
import { useQuery } from '@tanstack/react-query';
import { type ReactNode, type Reducer, useEffect, useReducer } from 'react';
import { getNedProfileDataAction, NED_PROFILE_DATA_QUERY_KEY } from '../../ned/api/ned-profile/ned-profile.query';
import {
  setInvestorNedAboutMe,
  setInvestorNedBoardExperience,
  setInvestorNedInvestorDataLoaded,
  setInvestorNedInvestorInvestments,
  setInvestorNedInvestorSmartMatch,
  setInvestorNedNedDataLoaded,
  setInvestorNedPersonalDetails,
  setInvestorNedResume,
  setInvestorNedSmartMatch,
} from './investor-ned.action-creators';
import { InvestorNedContext, investorNedInitialState } from './investor-ned.context';
import { type InvestorNedActionType, investorNedReducer, type InvestorNedState } from './investor-ned.reducer';

interface CheckoutStateProviderProps {
  children: ReactNode;
}

export const InvestorNedStateProvider = ({ children }: CheckoutStateProviderProps) => {
  const [state, dispatch] = useReducer<Reducer<InvestorNedState, InvestorNedActionType>>(
    investorNedReducer,
    investorNedInitialState,
  );
  const {
    data: investorProfileData,
    isLoading: investorIsLoading,
    refetch: refetchInvestorData,
  } = useQuery([INVESTOR_PROFILE_DATA_REQUEST], investorProfileDataAction, {
    enabled: false,
    cacheTime: 0,
  });

  const {
    data: nedProfileData,
    isLoading: nedIsLoading,
    refetch: refetchNedData,
  } = useQuery([NED_PROFILE_DATA_QUERY_KEY], getNedProfileDataAction, {
    enabled: false,
    cacheTime: 0,
  });

  useEffect(() => {
    if (investorIsLoading || !investorProfileData || !investorProfileData.data) {
      refetchInvestorData();
      dispatch(setInvestorNedInvestorDataLoaded(false));
      return;
    }

    const { getInvestorPersonalDetails, getInvestorSmartMatch, getInvestorInvestmentSpec } = investorProfileData.data;
    dispatch(setInvestorNedPersonalDetails(getInvestorPersonalDetails));
    dispatch(setInvestorNedInvestorSmartMatch(getInvestorSmartMatch));
    dispatch(setInvestorNedInvestorInvestments(getInvestorInvestmentSpec));
    dispatch(setInvestorNedInvestorDataLoaded(true));
  }, [investorIsLoading, investorProfileData, refetchInvestorData]);

  useEffect(() => {
    if (nedIsLoading || !nedProfileData || !nedProfileData.data) {
      refetchNedData();
      dispatch(setInvestorNedNedDataLoaded(false));

      return;
    }

    const { personalDetails, experience, smartMatch, about } = nedProfileData.data;

    if (personalDetails.resumePath || personalDetails.resumeName) {
      dispatch(
        setInvestorNedResume({
          resumePath: personalDetails.resumePath,
          resumeName: personalDetails.resumeName,
          resumeUrl: personalDetails.resumeUrl,
        }),
      );
    }
    dispatch(setInvestorNedBoardExperience(experience));
    dispatch(setInvestorNedSmartMatch(smartMatch));
    dispatch(setInvestorNedAboutMe(about));
    dispatch(setInvestorNedNedDataLoaded(true));
  }, [nedIsLoading, nedProfileData, refetchNedData]);

  return <InvestorNedContext.Provider value={{ state, dispatch }}>{children}</InvestorNedContext.Provider>;
};

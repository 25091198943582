import Image from '@assets/icons/splash-message-icon.svg?react';
import { MailLink } from '@parts/mail-link/mail-link';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Header } from '../header/header';
import { Screen } from '../screen/screen';

export const PendingReview = () => {
  const [header, line1, line2] = useTranslation([
    'splash.pending-review.header',
    'splash.pending-review.content.line1',
    'splash.pending-review.content.line2',
  ]);

  return (
    <Screen>
      <Image />
      <Header>{header}</Header>
      <p>{line1}</p>
      <p>
        {line2} <MailLink />
      </p>
    </Screen>
  );
};

import React, { type ReactNode } from 'react';
import { V2GradientSvg } from '../v2-gradient-svg/v2-gradient-svg';
import { getStrokeColor, getTextColor } from './v2-circle-progress-bar.helpers';
import S from './v2-circle-progress-bar.styles';

const STROKE_WIDTH = 8;

export type V2CircleProgressBarVariants = 'primary' | 'green' | 'magenta';
export type SizeType = 'large' | 'default' | 'small';

export type V2CircleProgressBarProps = {
  isCircleClosed?: boolean;
  percent: number;
  variant: V2CircleProgressBarVariants;
  'data-testid'?: string;
  size?: SizeType;
  icon?: ReactNode;
  valuePostfix?: string;
};

export const V2CircleProgressBar: React.FC<V2CircleProgressBarProps> = ({
  children,
  isCircleClosed = false,
  percent = 0,
  variant,
  'data-testid': testId = 'v2-progress-bar',
  size = 'default',
  valuePostfix = '',
  icon,
}) => {
  const format = (percentValue: number | undefined) => {
    return icon ? <S.IconWrapper>{icon}</S.IconWrapper> : `${percentValue}${valuePostfix}`;
  };

  const { start, end } = getStrokeColor(variant);

  return (
    <S.Wrapper>
      <V2GradientSvg startColor={start} endColor={end} idCSS="exampleGradient" rotation={90} />
      <S.V2CircleProgressBar
        type={isCircleClosed ? 'circle' : 'dashboard'}
        percent={percent}
        variant={variant}
        status="normal"
        data-testid={testId}
        $size={size}
        $textColor={getTextColor(variant)}
        format={(percentValue) => format(percentValue)}
        strokeWidth={STROKE_WIDTH}
      />
      <S.ChildrenWrapper $size={size}>{children}</S.ChildrenWrapper>
    </S.Wrapper>
  );
};

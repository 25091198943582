import type { StepContentPropsWithoutRefetch } from '@pages/content/onboarding/founder/paths.config';
import Title from '@pages/content/onboarding/parts/title/title';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { EmailBoxesTable } from './parts/email-boxes-table';

export const TrustedSender = ({ currentPage, endPage }: StepContentPropsWithoutRefetch) => {
  const [title, subtitle] = useTranslation([
    'founder-onboarding.trustedSender.title',
    'founder-onboarding.trustedSender.subtitle',
  ]);

  return (
    <>
      <Title title={title} currentPage={currentPage} endPage={endPage} subHeadline={subtitle} />
      <EmailBoxesTable revealAnimation />
    </>
  );
};

import BellIcon from '@assets/icons/bell-icon.svg?react';
import { useNotificationsContext } from '@utils/hooks/use-notifications/use-notifications';
import S from './bell.styles';
import { Overlay } from './overlay/overlay';

export const Bell = ({ showMenu = false }: { showMenu?: boolean }) => {
  const {
    state: { currentNotifications },
  } = useNotificationsContext();

  const isUnreadNotification = currentNotifications.some((notification) => !notification.read);

  const indicator = (
    <>
      <BellIcon data-testid="bell" />
      <S.NotificationIndicator count={isUnreadNotification ? 1 : 0} mode="dot" data-testid="bell" />
    </>
  );

  return showMenu ? (
    <S.Trigger
      data-testid="bell"
      overlay={<Overlay notifications={currentNotifications} />}
      trigger={showMenu ? ['click'] : []}
      getPopupContainer={(trigger: HTMLElement) => trigger.parentNode as HTMLElement}
    >
      {indicator}
    </S.Trigger>
  ) : (
    indicator
  );
};

import { V2Alert } from '@/components/ui/v2-alert/v2-alert';
import { V2Loader } from '@/components/ui/v2-loader/v2-loader';
import { V2Modal } from '@/components/ui/v2-modal/v2-modal';
import { useModal } from '@/hooks/use-modal';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { useGetAdvisorProfileReadiness } from '../../../hooks/use-get-advisor-profile-readiness';
import { ModalContent } from '../components/modal/modal-content';

export const useBuildYourProfileModal = () => {
  const { isOpen, close, open } = useModal();
  const [loadErrorTitleLL, loadErrorDescriptionLL] = useTranslation([
    'homepage.buildYourProfileModal.data.loadError.title',
    'homepage.buildYourProfileModal.data.loadError.description',
  ]);
  const { advisorProfileReadinessData, isAdvisorProfileReadinessLoading, refetchAdvisorProfileReadiness } =
    useGetAdvisorProfileReadiness();

  const modalContent = advisorProfileReadinessData?.data ? (
    <ModalContent
      afterPublish={refetchAdvisorProfileReadiness}
      data={{
        profile: advisorProfileReadinessData.data.profileInformationScore,
        smartMatch: advisorProfileReadinessData.data.smartMatchScore,
        remuneration: advisorProfileReadinessData.data.remunerationScore,
        cv: advisorProfileReadinessData.data.cvScore,
        publish: advisorProfileReadinessData.data.publishScore,
      }}
    />
  ) : (
    <V2Alert variant="error" title={loadErrorTitleLL} description={loadErrorDescriptionLL} />
  );

  const modal = (
    <V2Modal isOpen={isOpen} onCancel={close} title={<>&nbsp;</>} footer={null} size="lg">
      {isAdvisorProfileReadinessLoading ? <V2Loader height="300px" /> : modalContent}
    </V2Modal>
  );

  return {
    modal,
    open,
    close,
  };
};

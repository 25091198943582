import { getScoreStatus, useChangingNumber } from '@pages/content/onboarding/parts/aside/score/score';
import S from './score.style';

interface ReadinessScoreProps {
  score: number;
  text?: string;
  initial?: number;
  numberFontSize?: number;
  textFontSize?: number;
  ringWidth?: number;
}

export const ReadinessScore = ({
  score,
  text,
  initial = 0,
  numberFontSize = 80,
  textFontSize = 14,
  ringWidth = 7,
}: ReadinessScoreProps) => {
  const displayScore = useChangingNumber(score, initial);

  const scoreStatus = getScoreStatus(score);

  return (
    <S.Wrapper data-testid="readiness-score-circle">
      <S.RingColorError
        data-testid={`readiness-score-circle-ring-error${scoreStatus === 'error' ? '-active' : ''}`}
        active={scoreStatus === 'error'}
      />
      <S.RingColorWarning
        data-testid={`readiness-score-circle-ring-warning${scoreStatus === 'warning' ? '-active' : ''}`}
        active={scoreStatus === 'warning'}
      />
      <S.RingColorSuccess
        data-testid={`readiness-score-circle-ring-success${scoreStatus === 'success' ? '-active' : ''}`}
        active={scoreStatus === 'success'}
      />

      <S.RingInner ringWidth={ringWidth}>
        <S.ScoreNumber fontSize={numberFontSize}>{displayScore}</S.ScoreNumber>
        {text ? <S.ScoreText fontSize={textFontSize}>{text}</S.ScoreText> : null}
      </S.RingInner>
    </S.Wrapper>
  );
};

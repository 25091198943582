import { useV2AdvisorConnectionCardContext } from '../v2-advisor-connection-card-context';

import S from '../v2-advisor-connection-card.styles';

type ActionsProps = {
  children: React.ReactNode;
};

export const Actions = ({ children }: ActionsProps) => {
  useV2AdvisorConnectionCardContext();

  return <S.Actions>{children}</S.Actions>;
};

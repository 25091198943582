import { Avatar } from '@parts/avatar/avatar';
import { Tooltip } from 'antd';
import type { ReactNode } from 'react';
import S from './avatar-with-name.styles';

export type Size = 'small' | 'medium';
export type FontSize = 'xsmall' | 'small' | 'medium';

const ImageSizes = {
  small: 30,
  medium: 40,
};

const Wrapper = ({ children, link }: { children: ReactNode; link?: string | boolean }) =>
  !link ? <>{children}</> : <S.Link to={link}>{children}</S.Link>;

const AvatarWithName = ({
  avatarUrl,
  name,
  avatarSize = 'small',
  fontSize = 'small',
  link,
  tooltip = '',
  limitWidth,
  smallerWidthLimit,
}: {
  avatarUrl: string | null;
  name: string;
  avatarSize?: Size;
  fontSize?: FontSize;
  link?: string | boolean;
  tooltip?: string;
  limitWidth?: boolean;
  smallerWidthLimit?: boolean;
}) => (
  <Wrapper link={link}>
    <Tooltip title={tooltip}>
      <S.AvatarHolder data-testid="avatar-name" avatarSize={avatarSize}>
        <Avatar avatarUrl={avatarUrl} letter={name[0] || ''} size={ImageSizes[avatarSize]} />
        <S.Text limitWidth={Boolean(limitWidth)} smallerWidthLimit={smallerWidthLimit} fontSize={fontSize}>
          {name}
        </S.Text>
      </S.AvatarHolder>
    </Tooltip>
  </Wrapper>
);

export default AvatarWithName;

import anime, { type AnimeAnimParams } from 'animejs';
import { useEffect, useRef, type RefObject } from 'react';
import type { Any } from 'src/types';

type ListRevealAnimationConfig = Pick<AnimeAnimParams, 'duration' | 'delay'>;

const usePrevious = (value: Any[] = []) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useListEntryRevealAnimation = (
  elementsAnimationId: string,
  animationConfig: ListRevealAnimationConfig = {},
  reinitConfig: {
    deps: Any[];
    shouldReinit: (prev: Any[], curr: Any[]) => boolean;
  } = {
    deps: [],
    shouldReinit: () => true,
  },
  disableAnimation = false,
  onComplete: () => void = () => {},
): RefObject<anime.AnimeInstance | null> => {
  const animation = useRef<anime.AnimeInstance | null>(null);
  const prevDepsValue = usePrevious(reinitConfig.deps);

  useEffect(() => {
    if (disableAnimation) return;

    if (reinitConfig.shouldReinit(prevDepsValue, reinitConfig.deps) === false) return;

    animation.current = anime({
      targets: `[data-animation-id="${elementsAnimationId}"]`,
      opacity: [0, 1],
      translateY: [-15, 0],
      duration: 350,
      easing: 'easeInOutSine',
      delay: anime.stagger(150, { start: 50 }),
      complete: onComplete,
      ...animationConfig,
    });

    return () => {
      anime.remove(`[data-animation-id="${elementsAnimationId}"]`);
    };
  }, [...reinitConfig.deps]);

  return animation;
};

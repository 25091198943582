import type { GridConfig } from '@pages/content/pulse/parts/dashboards/api/get-founder-dashboard/get-founder-dashboard.action';
import { Size } from '@pages/content/pulse/parts/dashboards/parts/dashboard/metrics/parts/metric-item/parts/change-size/change-size';

const LARGE_SIZE = 8;
const SMALL_SIZE = LARGE_SIZE / 4;
const MEDIUM_SIZE = LARGE_SIZE / 2;
const MAX_ELEMENT_HEIGHT = 1.5;
const MIN_ELEMENT_HEIGHT = 1;

export const ROW_HEIGHT = 300;
export const COLS = { lg: LARGE_SIZE, md: LARGE_SIZE, sm: LARGE_SIZE, xs: SMALL_SIZE, xxs: SMALL_SIZE };
export const dragWidthSizes = {
  [Size.Large]: LARGE_SIZE,
  [Size.Medium]: MEDIUM_SIZE,
  [Size.Small]: SMALL_SIZE,
};
export const dragHeightSizes = {
  [Size.Large]: MAX_ELEMENT_HEIGHT,
  [Size.Medium]: MIN_ELEMENT_HEIGHT,
  [Size.Small]: MIN_ELEMENT_HEIGHT,
};

export const prepareGridData = (ids: string[], { x, y }: { x?: number; y?: number | number[] } = {}): GridConfig[] =>
  ids.map((id, index) => ({
    i: id,
    x: x || (index % 2 === 0 ? 0 : MEDIUM_SIZE),
    y: (Array.isArray(y) ? y[index] : y) || index,
    w: MEDIUM_SIZE,
    h: MIN_ELEMENT_HEIGHT,
    minW: SMALL_SIZE,
    maxW: LARGE_SIZE,
    maxH: MAX_ELEMENT_HEIGHT,
    minH: MIN_ELEMENT_HEIGHT,
  }));

export const addOrRemoveItemInGrid = (ids: string[], gridConfig: GridConfig[]) => {
  const newGridConfig = gridConfig.filter((el) => ids.includes(el.i));
  const newElements = ids.filter((id) => !newGridConfig.some((item) => item.i === id));

  for (const id of newElements) {
    const maxY = newGridConfig.reduce((acc, { y }) => Math.max(acc, y), 0);
    const lastRowElements = newGridConfig.filter(({ y }) => y === maxY);

    const lastPosAndSize = lastRowElements.reduce(
      (acc, elem) => ({
        x: Math.max(acc.x, elem.x),
        w: elem.w,
      }),
      { x: 0, w: 0 },
    );

    const isItFitInCurrentY = lastPosAndSize.x + lastPosAndSize.w + MEDIUM_SIZE <= LARGE_SIZE;
    newGridConfig.push({
      i: id,
      x: isItFitInCurrentY ? lastPosAndSize.x + lastPosAndSize.w : 0,
      y: isItFitInCurrentY ? maxY : maxY + 1,
      w: MEDIUM_SIZE,
      h: MIN_ELEMENT_HEIGHT,
    });
  }

  return newGridConfig;
};

export const getOnlyRequiredFields = (data: GridConfig[]) => data.map(({ i, x, y, w, h }) => ({ i, x, y, w, h }));

import Redirect from '@assets/icons/undraw-ned-academy.svg?react';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useEffect } from 'react';
import { Description } from '../parts/description/description';
import { Heading } from '../parts/heading/heading';
import { Layout } from '../parts/layout/layout';
import { nedAcademyAccess } from './api/ned-academy-access';

export const NedAcademyAccessPage = () => {
  const [titleLabel, descriptionLabel] = useTranslation(['nedAcademy.title', 'nedAcademy.description']);

  useEffect(() => {
    nedAcademyAccess();
  }, []);

  return (
    <Layout Icon={Redirect}>
      <Heading>{titleLabel}</Heading>
      <Description>{descriptionLabel}</Description>
    </Layout>
  );
};

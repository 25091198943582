import { V2Button } from '@/components/ui/v2-button/v2-button';
import { V2IconBox } from '@/components/ui/v2-icon-box/v2-icon-box';
import type { V2IconBoxVariant } from '@/components/ui/v2-icon-box/v2-icon-box.types';
import type { V2IconName } from '@/components/ui/v2-icon/icons';
import { V2Icon } from '@/components/ui/v2-icon/v2-icon';
import type { V2IconSize } from '@/components/ui/v2-icon/v2-icon.types';
import { V2Link } from '@/components/ui/v2-link/v2-link';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';

import S from './widget-card-content.styles';

export type WidgetCardContentProps = {
  title: string;
  subTitle: string;
  secondarySubTitle?: string;
  description: string;
  actionText: string;
  actionUrl: string;
  onActionClick: () => void;
  variant: V2IconBoxVariant;
  icon: V2IconName;
  iconSize?: V2IconSize;
  ['data-testid']?: string;
  disabled?: boolean;
};

export const WidgetCardContent = ({
  title,
  subTitle,
  secondarySubTitle = '',
  description,
  actionText: linkText,
  actionUrl = '/',
  variant = 'primary',
  icon,
  iconSize = 'lg',
  onActionClick,
  disabled = false,
  'data-testid': dataTestId = 'widget-card-content',
}: Partial<WidgetCardContentProps>) => {
  const [fillNowLabel] = useTranslation(['homepage.readinessScore.tile.fillNow']);

  const actionElement = onActionClick ? (
    <V2Button onClick={onActionClick} variant="link" disabled={disabled} data-testid={`${dataTestId}-action-element`}>
      {linkText ?? fillNowLabel}
    </V2Button>
  ) : (
    <V2Link to={actionUrl} variant="primary" disabled={disabled} data-testid={`${dataTestId}-action-element`}>
      {linkText}
    </V2Link>
  );

  return (
    <S.Wrapper data-testid={dataTestId}>
      <S.TitleSectionWrapper>
        <V2IconBox variant={variant} size="md">
          <V2Icon name={icon ?? 'house'} size={iconSize ?? 'md'} />
        </V2IconBox>
        <S.TitleWrapper>
          <S.Title data-testid={`${dataTestId}-title`}>{title}</S.Title>
          <S.SubTitleWrapper data-testid={`${dataTestId}-sub-title`}>
            <S.SubTitle>{subTitle} </S.SubTitle>
            <S.SecondarySubTitle>{secondarySubTitle}</S.SecondarySubTitle>
          </S.SubTitleWrapper>
        </S.TitleWrapper>
      </S.TitleSectionWrapper>
      <S.DescriptionWrapper>
        <S.Description>{description}</S.Description>
        {actionElement}
      </S.DescriptionWrapper>
    </S.Wrapper>
  );
};

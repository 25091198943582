import { Roles } from '@domain/accounts/roles';
import type { ProductDetailsDraft } from '@domain/subscriptions/product-details';
import {
  FOUNDER_PRODUCTS_CACHE_KEY,
  getFounderProducts,
} from '@pages/auth/api/get-founder-products/get-founder-products.actions';
import { PRODUCT_DATA_CACHE_KEY, getNedProducts } from '@pages/auth/api/get-ned-products/get-ned-products.actions';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import currency from 'currency.js';
import { useReducer, type ReactNode, type Reducer } from 'react';
import type { ValidatePromoCodeResponse } from '../api/create-new-subscription/create-new-subscription.action';
import { calculatePercentageDiscountValue } from '../helpers/calculate-percentage-discount';
import { negativeToZero } from '../helpers/negative-to-zero';
import { setCheckoutLoadingError, setCheckoutProducts, toggleCheckoutLoading } from './checkout.action-creators';
import { CheckoutContext, checkoutInitialState } from './checkout.context';
import { checkoutReducer, type CheckoutActionType, type CheckoutState } from './checkout.reducer';

interface CheckoutStateProviderProps {
  children: ReactNode;
}

export const CheckoutStateProvider = ({ children }: CheckoutStateProviderProps) => {
  const [checkoutQueryError] = useTranslation(['checkout.queryError']);
  const [state, dispatch] = useReducer<Reducer<CheckoutState, CheckoutActionType>>(
    checkoutReducer,
    checkoutInitialState,
  );

  const {
    state: { userRole },
  } = useUserAccount();

  const isNedOrInvestor = userRole === Roles.NED || userRole === Roles.INVESTOR;
  const queryKey = isNedOrInvestor ? PRODUCT_DATA_CACHE_KEY : FOUNDER_PRODUCTS_CACHE_KEY;
  const queryAction = isNedOrInvestor ? getNedProducts : getFounderProducts;

  const handleQueryError = () => {
    dispatch(setCheckoutLoadingError(checkoutQueryError));
    dispatch(setCheckoutProducts([]));
    dispatch(toggleCheckoutLoading(false));
  };

  const handleQuerySuccess = (products: ProductDetailsDraft[]) => {
    dispatch(setCheckoutLoadingError(null));
    dispatch(setCheckoutProducts(products));
    dispatch(toggleCheckoutLoading(false));
  };

  useQuery([queryKey], queryAction, {
    onSuccess: (res) => {
      if (!res.data) {
        handleQueryError();
        return;
      }
      handleQuerySuccess(res.data);
    },
    onError: handleQueryError,
    refetchOnWindowFocus: false,
  });

  const getSelectedProduct = (productValue: string, products: ProductDetailsDraft[]): ProductDetailsDraft | null => {
    return products.find((product) => product.value === productValue) ?? products[0] ?? null;
  };

  const calculateAppliedDiscount = (product: ProductDetailsDraft, promoCodeConfig: ValidatePromoCodeResponse) => {
    if (promoCodeConfig.amountOff !== null) {
      return currency(promoCodeConfig.amountOff / 100).toString();
    }

    if (promoCodeConfig.percentOff !== null) {
      return currency(calculatePercentageDiscountValue(product.netAmount, promoCodeConfig.percentOff)).toString();
    }

    return currency(0).toString();
  };

  const getVatFromNetPrice = (netPrice: string, vatRate: string): string => {
    return currency(netPrice).multiply(vatRate).divide(100).toString();
  };

  const selectedProduct = getSelectedProduct(state.selectedProductValue, state.products);
  const discountValue =
    state.discount === null || selectedProduct === null
      ? currency(0).toString()
      : calculateAppliedDiscount(selectedProduct, state.discount);

  const productGrossPrice = negativeToZero(currency(selectedProduct?.grossAmount ?? 0).toString());
  const productNetPrice = negativeToZero(currency(selectedProduct?.netAmount ?? 0).toString());
  const productVat = negativeToZero(currency(selectedProduct?.vatAmount ?? 0).toString());
  const productVatRate = currency(selectedProduct?.vatRate ?? 0).toString();

  const currentVat = state.discount
    ? negativeToZero(getVatFromNetPrice(currency(productNetPrice).subtract(discountValue).toString(), productVatRate))
    : productVat;

  const currentNetPrice = state.discount
    ? negativeToZero(currency(productNetPrice).subtract(discountValue).toString())
    : productNetPrice;

  const currentGrossPrice = state.discount
    ? negativeToZero(currency(currentNetPrice).add(currentVat).toString())
    : productGrossPrice;

  const finalPrice = currentGrossPrice;

  return (
    <CheckoutContext.Provider
      value={{
        state,
        computed: {
          selectedProduct,
          productGrossPrice,
          productNetPrice,
          productVat,
          discountValue,
          currentVat,
          currentNetPrice,
          currentGrossPrice,
          finalPrice,
        },
        dispatch,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

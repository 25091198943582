import CollapseClosedIcon from '@assets/icons/collapse-closed.svg?react';
import CollapseOpenIcon from '@assets/icons/collapse-open.svg?react';
import styled, { css } from 'styled-components';

const iconStyles = css`
  margin-right: ${({ theme }) => theme.spacing.small};
  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
`;

const Closed = styled(CollapseClosedIcon)`
  ${iconStyles}
`;

const Open = styled(CollapseOpenIcon)`
  ${iconStyles}
`;

export default {
  Closed,
  Open,
};

import DrawerSearch from '@pages/content/parts/drawer-search/drawer-search';
import {
  CompanyType,
  GET_AVAILABLE_COMPANIES_CACHE_KEY,
  getAvailableCompaniesAction,
} from '@pages/content/portfolio/investor/api/get-available-companies.action';
import { clearQueryParamValue, idQueryParam } from '@pages/content/pulse/founder/parts/editor/create-editor';
import { DRAWER_DEBOUNCE_MIN_SEARCHABLE_LENGTH } from '@pages/content/pulse/founder/parts/editor/parts/investor-search/investor-search';
import { FieldSearch } from '@parts/field-search/field-search';
import { useQuery } from '@tanstack/react-query';
import useConfirmModal from '@utils/hooks/use-confirm-modal/use-confirm-modal';
import { useDebounce } from '@utils/hooks/use-debounce/use-debounce';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Input, Tooltip } from 'antd';
import { useRef, type Dispatch, type SetStateAction } from 'react';
import { useLocation } from 'react-router-dom';
import { newPortfolioQueryParam } from '../../create-editor';
import S from './company-search.styles';

export const CompanySearch = ({
  setFieldValue,
  getFieldProps,
  getFieldMeta,
  onSearch,
  setFounderId,
  onClear,
  isAddedEmail,
  setIsAddedEmail,
  disabled,
  isAddedExternal,
}: {
  setFieldValue: Function;
  getFieldProps: Function;
  getFieldMeta: Function;
  onSearch: Function;
  onClear: Function;
  setFounderId: Function;
  isAddedEmail: boolean;
  setIsAddedEmail: Dispatch<SetStateAction<boolean>>;
  disabled: boolean;
  isAddedExternal: boolean;
}) => {
  const [
    companyLabel,
    placeholderLabel,
    externalLabel,
    resetTitleLabel,
    resetDescriptionLabel,
    companyEmailLabel,
    companyEmailTooltip,
    inviteFounderAlert,
  ] = useTranslation([
    'portfolio.investor.drawer.name',
    'portfolio.investor.drawer.placeholder',
    'portfolio.investor.drawer.external',
    'portfolio.investor.drawer.resetTitle',
    'portfolio.investor.drawer.resetDescription',
    'portfolio.investor.drawer.email',
    'portfolio.investor.drawer.email.tooltip',
    'portfolio.investor.drawer.inviteCompany',
  ]);

  const { id } = useQueryParams() as { id: string };
  const { value: companyNameValue } = getFieldProps('companyName');

  const self = useRef<HTMLInputElement>(null);
  const debouncedSearch = useDebounce(companyNameValue || '', 300);
  const location = useLocation();

  const { modal, show } = useConfirmModal({
    title: resetTitleLabel,
    content: resetDescriptionLabel,
    onConfirm: () => onClear(),
  });

  const { data: response } = useQuery(
    [GET_AVAILABLE_COMPANIES_CACHE_KEY(debouncedSearch)],
    getAvailableCompaniesAction(debouncedSearch),
    {
      enabled: debouncedSearch.length >= DRAWER_DEBOUNCE_MIN_SEARCHABLE_LENGTH,
      onSuccess: (res) => {
        const activePortfolio = res.data?.companies.find((c) => c.investingRelationshipId === id);
        const params = new URLSearchParams(location.search);

        if (activePortfolio && params.get(idQueryParam) !== clearQueryParamValue) {
          setFounderId(activePortfolio.id);
        }
      },
    },
  );

  const companies = response?.data?.companies ?? [];
  const searchMatchesPortfolio = Boolean(
    companies.find((c) => c.name.toLocaleLowerCase() === String(companyNameValue).toLocaleLowerCase()),
  );

  return (
    <>
      {modal}
      <FieldSearch
        label={{
          for: 'companyName',
          label: companyLabel,
        }}
        {...getFieldMeta('companyName')}
        data-testid="companyName-field-search"
      >
        <DrawerSearch
          data-testid="drawer-search"
          placeholder={placeholderLabel}
          selectRef={self}
          isDisabled={disabled}
          onSearch={(v) => {
            setFieldValue('companyName', v.trim() || null);
          }}
          value={companyNameValue}
          onSelect={(v) => {
            const company = companies.find((c) => c.name.toLocaleLowerCase() === v.toString().toLocaleLowerCase());
            onSearch({
              value: company ? company?.name : v,
              companyId: company?.id,
              investingRelationshipId: company?.investingRelationshipId || newPortfolioQueryParam,
              isConnected: Boolean(company?.isConnected),
              externalCompanyEmail: company?.founderEmail,
            });
            if (company?.founderEmail) {
              setIsAddedEmail(true);
              return;
            }
            setIsAddedEmail(false);
          }}
          onClick={() => {
            if (disabled) show();
          }}
          dropdownClassName={`portfolio-entity-select ${
            debouncedSearch.length < DRAWER_DEBOUNCE_MIN_SEARCHABLE_LENGTH ? 'hidden' : ''
          }`}
          options={[
            ...(!searchMatchesPortfolio ? [{ name: companyNameValue, type: CompanyType.EXTERNAL }] : []),
            ...(response?.data ? [...response?.data.companies] : []),
          ]}
          additionalLabel={externalLabel}
        />
      </FieldSearch>
      {isAddedExternal && (
        <>
          <S.Alert message={inviteFounderAlert} type="info" showIcon />
          <S.FieldSearch
            label={{
              for: 'externalCompanyEmail',
              label: (
                <>
                  {companyEmailLabel}
                  <Tooltip title={companyEmailTooltip} placement="right">
                    <S.InfoIcon />
                  </Tooltip>
                </>
              ),
            }}
            data-testid="companyEmail-field-search"
            {...getFieldMeta('externalCompanyEmail')}
          >
            <Input
              id="externalCompanyEmail"
              disabled={isAddedEmail}
              {...getFieldProps('externalCompanyEmail')}
              onChange={(v) => setFieldValue('externalCompanyEmail', v.target.value || undefined)}
            />
          </S.FieldSearch>
        </>
      )}
    </>
  );
};

import { V2Icon } from '@/components/ui/v2-icon/v2-icon';
import { useV2AdvisorConnectionCardContext } from '../v2-advisor-connection-card-context';

import S from '../v2-advisor-connection-card.styles';

type IndustryListElementProps = {};

export const IndustryListElement = (_: IndustryListElementProps) => {
  const { pendingConnection } = useV2AdvisorConnectionCardContext();

  return (
    <S.ListElement>
      <V2Icon name="cash-coin" />
      {pendingConnection.industry?.join(', ') ?? '-'}
    </S.ListElement>
  );
};

import styled from 'styled-components';

const ExternalUrl = styled.a`
  color: ${({ theme }) => theme.baseColors.Blue200};
  text-decoration: none;
  display: block;

  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

export default { ExternalUrl };

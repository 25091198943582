import { axiosInstance } from '@utils/axios/axios-instance';
import type { AxiosConfig } from '@utils/axios/types';

export interface RegisterInvestorActionPayloadAuth0 {
  firstName: string;
  lastName: string;
  email?: string;
  invitationToken?: string;
  state?: string;
  registrationToken?: string;
  countryOfResidence: string;
}

export const registerInvestorAction = async (payload: RegisterInvestorActionPayloadAuth0) =>
  axiosInstance.post('/investor', payload, {
    skipAuthorization: true,
  } as AxiosConfig);

import { InputNumber } from 'antd';
import styled from 'styled-components';

const ValueDisplay = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.xmedium};
`;

const MoneyInput = styled(InputNumber)<{ $width: string }>`
  .ant-input-number-handler-wrap {
    display: none;
  }

  &.ant-input-number {
    padding: 0;
    margin: 0;
    height: auto;
    background-color: transparent;
    border: 0 none;
    line-height: 1;
    text-align: center;
    color: transparent;
    outline: none;
    cursor: pointer;
    width: ${({ $width }) => $width};

    display: inline-block;
    font-size: ${({ theme }) => theme.fontSize.regular};

    .ant-input-number-input-wrap {
      height: 16px;
    }

    .ant-input-number-input {
      height: auto !important;
      padding: 0;
      color: ${({ theme }) => theme.color.fontPrimary};

      > input {
        height: auto !important;
      }
    }
  }
`;

const ValuePrefix = styled.span`
  display: inline-block;
  margin: 0;
  line-height: 1;
  position: relative;
  transform: translateY(-1px) translateX(-1px);
  padding-right: ${({ theme }) => theme.spacing.xxsmall};
`;

const Separator = styled.span`
  display: inline-block;
  margin: 0 ${({ theme }) => theme.spacing.small} 0 ${({ theme }) => theme.spacing.small};
`;

const InputWrapper = styled.span`
  display: inline-block;
  padding: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.xsmall}
    ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.small};
  border-radius: ${({ theme }) => theme.radius.default};
  border: 1px solid ${({ theme }) => theme.color.border};
`;

export default {
  ValueDisplay,
  ValuePrefix,
  MoneyInput,
  Separator,
  InputWrapper,
};

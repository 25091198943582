import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export interface SingleRole {
  id: string;
  title: string;
  closesAt: string;
  compensation: string;
  description: string;
  engagement: string;
  experience: number;
  location: string;
  postedAt: string;
  roleType: string;
  skillSet: string[];
}

export const GET_FOUNDER_SINGLE_ROLE_CACHE_KEY = 'get-founder-single-role';

export const getFounderSingleRole = (roleId: string) => async (): Promise<GraphqlResponse<SingleRole>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getFounderSingleRole {
        previewRoleByOwner(roleId: "${roleId}") {
          id
          title
          closesAt
          compensation
          description
          engagement
          experience
          location
          postedAt
          roleType
          skillSet
        }    
      }    
    `,
  });

  return graphqlResponseWrapper(data, ['previewRoleByOwner']);
};

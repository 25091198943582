import { SubmitButton as SubmitButtonBase } from '@parts/submit-button/submit-button';
import { Button as ButtonBase } from 'antd';
import styled, { css } from 'styled-components';

interface IFooterProps {
  modal: boolean;
  reverseButtons: boolean;
  alignButtonsWithContent: boolean;
}

const Button = styled(ButtonBase)`
  flex: 50%;
`;

const Footer = styled.div<IFooterProps>`
  display: flex;
  justify-content: flex-end;
  flex: 100%;
  gap: ${({ theme }) => theme.spacing.xmedium};

  ${({ alignButtonsWithContent }) =>
    alignButtonsWithContent &&
    css`
      padding-left: 7px;
      padding-right: 7px;

      .ant-btn-primary {
        margin-left: 0;
      }
    `}

  ${({ modal }) =>
    modal &&
    css`
      flex-direction: row-reverse;
      justify-content: flex-end;
    `}

  ${({ reverseButtons }) =>
    reverseButtons &&
    css`
      flex-direction: row-reverse;

      .ant-btn-primary {
        margin: 0;
        margin-right: 10px;
      }
    `}

  .ant-btn {
    ${({ modal, theme }) =>
      modal &&
      css`
        flex: 0;
        margin-right: 10px;
        font-weight: ${theme.fontWeight.semibold};
      `}

    &:not(.ant-btn-primary) {
      ${({ modal, theme }) =>
        modal &&
        css`
          color: ${theme.baseColors.Blue200};
          border: 1px solid ${theme.baseColors.Blue200};
        `}
    }
  }
`;

const SubmitButton = styled(SubmitButtonBase)`
  flex: 50%;
  margin-left: ${({ theme }) => theme.spacing.small};
`;

export default { Button, Footer, SubmitButton };

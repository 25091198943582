import {
  SubscriptionProductStatus,
  SubscriptionProductType,
  type SubscriptionProduct,
} from '@domain/accounts/subscription-products';

export const isUnpaidNed = (products: SubscriptionProduct[]) => {
  const nedProducts = products
    .filter((prod) => prod.status === SubscriptionProductStatus.APPROVED)
    .filter((product) =>
      [SubscriptionProductType.NED, SubscriptionProductType.NED_TRANSITION_TO_PORTFOLIO].includes(product.type),
    );

  return nedProducts.length === 0;
};

import { Routes } from '@router/routes';
import { AbilityAction, type RoleAbilityBuilderFunction } from '../types';
import { hasFinishedInvestorOnboarding } from './utils/has-finished-investor-onboarding';
import { isUnpaidNed } from './utils/is-unpaid-ned';

const createRoutingAbilities: RoleAbilityBuilderFunction = (builder, { onboarding, hasNedAcademyAccess, products }) => {
  const { cannot, can } = builder;

  if (hasFinishedInvestorOnboarding(onboarding)) {
    can(AbilityAction.ACCESS_PAGE, Routes.ONBOARDING_INVESTOR_SUMMARY);
  }

  if (hasNedAcademyAccess) {
    can(AbilityAction.ACCESS_PAGE, Routes.ACCESS_NED_ACADEMY);
  } else {
    cannot(AbilityAction.ACCESS_PAGE, Routes.ACCESS_NED_ACADEMY);
  }

  if (isUnpaidNed(products)) {
    can(AbilityAction.ACCESS_PAGE, Routes.EXTEND_PRODUCTS);
  } else {
    cannot(AbilityAction.ACCESS_PAGE, Routes.EXTEND_PRODUCTS);
  }

  return builder;
};

const createActionAbilities: RoleAbilityBuilderFunction = (builder) => {
  return builder;
};

export const createInvestorNedAbility: RoleAbilityBuilderFunction = (builder, attributes) => {
  createRoutingAbilities(builder, attributes);
  createActionAbilities(builder, attributes);

  return builder;
};

import { Routes } from '@router/routes';
import {
  lensBrowseCompaniesKey,
  lensBrowseKey,
  lensConnectionsKey,
  lensInvitesKey,
  lensRequestsKey,
  lensSmartMatchKey,
  manageMetricIntegratedMetricsKey,
  manageMetricMetricsKey,
  multiRoleProfileInvestorKey,
  multiRoleProfileNedKey,
  publicProfileFounderDashboardsKey,
  publicProfileFounderDetailsKey,
  pulseCompaniesKey,
  pulseDashboardsKey,
  pulseInvestorsKey,
  pulseTriggersKey,
  rolesAppliedKey,
  rolesBrowseKey,
} from './tabs-keys';
import { replaceRouteParams } from './utils/replace-route-params';

type AppLinks =
  | 'PULSE_FOUNDER_DASHBOARDS'
  | 'PULSE_FOUNDER_INVESTORS'
  | 'PULSE_INVESTOR_COMPANIES'
  | 'PULSE_INVESTOR_TRIGGERS'
  | 'PULSE_NED_COMPANIES'
  | 'PULSE_NED_TRIGGERS'
  | 'FOUNDER_PUBLIC_PROFILE_DETAILS'
  | 'FOUNDER_PUBLIC_PROFILE_DASHBOARDS'
  | 'MULTI_ROLE_PROFILE_INVESTOR'
  | 'MULTI_ROLE_PROFILE_NED'
  | 'ROLES_BROWSE'
  | 'ROLES_APPLIED'
  | 'LENS_SMART_MATCH'
  | 'LENS_CONNECTIONS'
  | 'LENS_REQUESTS'
  | 'LENS_BROWSE_COMPANIES'
  | 'LENS_BROWSE'
  | 'LENS_INVITES'
  | 'MANAGE_METRIC_METRICS'
  | 'MANAGE_METRIC_INTEGRATED_METRICS'
  | 'ONBOARDING_FOUNDER_STEP'
  | 'ONBOARDING_INVESTOR'
  | 'ONBOARDING_INVESTOR_STEP'
  | 'ONBOARDING_INVESTOR_SUMMARY'
  | 'NOT_FOUND'
  | Exclude<
      keyof typeof Routes,
      'PULSE' | 'FOUNDER_PUBLIC_PROFILE' | 'MULTI_ROLE_PROFILE' | 'ROLES' | 'MANAGE_METRIC' | 'LENS' | 'CATCH_ALL'
    >;

export const Links: Record<AppLinks, (...arg: string[]) => string> = {
  HOME: () => Routes.HOME,
  START_PAGE: () => Routes.START_PAGE,
  PULSE_FOUNDER_DASHBOARDS: () => replaceRouteParams(Routes.PULSE, [pulseDashboardsKey]),
  PULSE_FOUNDER_INVESTORS: () => replaceRouteParams(Routes.PULSE, [pulseInvestorsKey]),
  PULSE_INVESTOR_COMPANIES: () => replaceRouteParams(Routes.PULSE, [pulseCompaniesKey]),
  PULSE_INVESTOR_TRIGGERS: () => replaceRouteParams(Routes.PULSE, [pulseTriggersKey]),
  PULSE_NED_COMPANIES: () => replaceRouteParams(Routes.PULSE, [pulseCompaniesKey]),
  PULSE_NED_TRIGGERS: () => replaceRouteParams(Routes.PULSE, [pulseTriggersKey]),
  LOGIN: () => Routes.LOGIN,
  REGISTER: () => Routes.REGISTER,
  REGISTER_INVITED_INVESTOR: () => Routes.REGISTER_INVITED_INVESTOR,
  REGISTER_INVITED_FOUNDER: () => Routes.REGISTER_INVITED_FOUNDER,
  REGISTER_ROLE: (role) => `${Routes.REGISTER}/${role}`,
  REDIRECT_TO_REGISTER: () => Routes.REDIRECT_TO_REGISTER,
  LEGAL: () => Routes.LEGAL,
  PORTFOLIO: () => Routes.PORTFOLIO,
  PORTFOLIO_COMPANY_DETAILS: (relationId) => replaceRouteParams(Routes.PORTFOLIO_COMPANY_DETAILS, [relationId]),
  PORTFOLIO_EXPANDED_VIEW: () => Routes.PORTFOLIO_EXPANDED_VIEW,
  PORTFOLIO_EXPANDED_VIEW_SINGLE: (id) => replaceRouteParams(Routes.PORTFOLIO_EXPANDED_VIEW_SINGLE, [id]),
  CHECKOUT: () => Routes.CHECKOUT,
  CHECKOUT_SUCCESS: () => Routes.CHECKOUT_SUCCESS,
  CHECKOUT_CANCEL: () => Routes.CHECKOUT_CANCEL,
  EXTEND_PRODUCTS: () => Routes.EXTEND_PRODUCTS,
  UPGRADE_PRODUCTS: () => Routes.UPGRADE_PRODUCTS,
  EMAIL_UNVERIFIED: () => Routes.EMAIL_UNVERIFIED,
  PROGRESS_REPORT: (hash) => replaceRouteParams(Routes.PROGRESS_REPORT, [hash]),
  PAYMENT_IN_PROGRESS: () => Routes.PAYMENT_IN_PROGRESS,
  INVESTOR_PUBLIC_PROFILE: () => Routes.INVESTOR_PUBLIC_PROFILE,
  FOUNDER_PUBLIC_PROFILE_DETAILS: () =>
    replaceRouteParams(Routes.FOUNDER_PUBLIC_PROFILE, [publicProfileFounderDetailsKey]),
  FOUNDER_PUBLIC_PROFILE_DASHBOARDS: () =>
    replaceRouteParams(Routes.FOUNDER_PUBLIC_PROFILE, [publicProfileFounderDashboardsKey]),
  NED_PUBLIC_PROFILE: (id) => replaceRouteParams(Routes.NED_PUBLIC_PROFILE, [id]),
  ADVISORS_SEARCH_ROLE: (role) => replaceRouteParams(Routes.ADVISORS_SEARCH_ROLE, [role]),
  ADVISORS: () => Routes.ADVISORS,
  PROFILE: () => Routes.PROFILE,
  MULTI_ROLE_PROFILE_INVESTOR: () => replaceRouteParams(Routes.MULTI_ROLE_PROFILE, [multiRoleProfileInvestorKey]),
  MULTI_ROLE_PROFILE_NED: () => replaceRouteParams(Routes.MULTI_ROLE_PROFILE, [multiRoleProfileNedKey]),
  ROLES_BROWSE: () => replaceRouteParams(Routes.ROLES, [rolesBrowseKey]),
  ROLES_APPLIED: () => replaceRouteParams(Routes.ROLES, [rolesAppliedKey]),
  CREATE_NEW_ROLE: () => Routes.CREATE_NEW_ROLE,
  EDIT_ROLE: (id) => replaceRouteParams(Routes.EDIT_ROLE, [id]),
  PREVIEW_ROLE: (id) => replaceRouteParams(Routes.PREVIEW_ROLE, [id]),
  ROLE_APPLICATIONS: (id) => replaceRouteParams(Routes.ROLE_APPLICATIONS, [id]),
  LENS_SMART_MATCH: () => replaceRouteParams(Routes.LENS, [lensSmartMatchKey]),
  LENS_CONNECTIONS: () => replaceRouteParams(Routes.LENS, [lensConnectionsKey]),
  LENS_REQUESTS: () => replaceRouteParams(Routes.LENS, [lensRequestsKey]),
  LENS_BROWSE_COMPANIES: () => replaceRouteParams(Routes.LENS, [lensBrowseCompaniesKey]),
  LENS_BROWSE: () => replaceRouteParams(Routes.LENS, [lensBrowseKey]),
  LENS_INVITES: () => replaceRouteParams(Routes.LENS, [lensInvitesKey]),
  MESSAGING: () => Routes.MESSAGING,
  NOTIFICATIONS: () => Routes.NOTIFICATIONS,
  INTERNAL_SERVER_ERROR: () => Routes.INTERNAL_SERVER_ERROR,
  ACCESS_NED_ACADEMY: () => Routes.ACCESS_NED_ACADEMY,
  MANAGE_METRIC_METRICS: () => replaceRouteParams(Routes.MANAGE_METRIC, [manageMetricMetricsKey]),
  MANAGE_METRIC_INTEGRATED_METRICS: () => replaceRouteParams(Routes.MANAGE_METRIC, [manageMetricIntegratedMetricsKey]),
  INVESTOR_CONFIRM_INVESTMENT: (id) => replaceRouteParams(Routes.INVESTOR_CONFIRM_INVESTMENT, [id]),
  FOUNDER_CONFIRM_INVESTMENT: (id) => replaceRouteParams(Routes.FOUNDER_CONFIRM_INVESTMENT, [id]),
  ONBOARDING_FOUNDER_STEP: (path: string, step: string) =>
    replaceRouteParams(Routes.ONBOARDING_FOUNDER_STEP, [path, step]),
  FOUNDER_CONNECTIONS: () => Routes.FOUNDER_CONNECTIONS,
  ADVISOR_CONNECTIONS: () => Routes.ADVISOR_CONNECTIONS,
  INVESTOR_ADVISOR_CONNECTIONS: () => Routes.INVESTOR_ADVISOR_CONNECTIONS,
  ONBOARDING_INVESTOR: () => Routes.ONBOARDING_INVESTOR,
  ONBOARDING_INVESTOR_STEP: (step: string) => `${Routes.ONBOARDING_INVESTOR}/${step}`,
  ONBOARDING_INVESTOR_SUMMARY: () => Routes.ONBOARDING_INVESTOR_SUMMARY,
  COR_SELECTION: () => Routes.COR_SELECTION,
  INVESTOR_AWAITING_APPROVAL: () => Routes.INVESTOR_AWAITING_APPROVAL,
  NOT_FOUND: () => Routes.NOT_FOUND,
};

import S from '../v2-carousel.styles';
import { useV2CarouselContext } from '../v2-carousel-context';
import { V2Icon } from '@/components/ui/v2-icon/v2-icon';

export const NextIconButton = () => {
  const { nextBtnDisabled, onNextButtonClick } = useV2CarouselContext();

  return (
    <S.IconButton data-testid="carousel-next-button" disabled={nextBtnDisabled} onClick={onNextButtonClick}>
      <V2Icon name="chevron-right" size="lg" />
    </S.IconButton>
  );
};

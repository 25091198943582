import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_INVESTOR_PORTFOLIO_CACHE_KEY = 'get-investor-portfolio';

export interface InvestorPortfolioItem {
  id: string;
  company: {
    id: string;
    name: string;
    avatarUrl: string;
    type: string;
  };
  totalCurrentValue: number;
  totalInvested: number;
  hasUnarchivedInvestments: boolean;
}

export interface InvestorPortfolio {
  totalInvestmentsCount: number;
  totalInvested: number;
  totalInvestmentsValue: number;
  total: number;
  items: InvestorPortfolioItem[];
}

export const getInvestorPortfolioAction =
  (start: number, limit: number) => async (): Promise<GraphqlResponse<InvestorPortfolio>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
      query getInvestorPortfolio {
        investorPortfolio(start: ${start}, limit: ${limit}) {
          total: totalItems
          totalInvestmentsCount
          totalInvestmentsValue
          totalInvested
          items {
            id: investingRelationshipId
            company {
              id
              name
              avatarUrl
              type
            }
            totalCurrentValue
            totalInvested
            hasUnarchivedInvestments
          }
        }
      }`,
    });

    return graphqlResponseWrapper(data, ['investorPortfolio']);
  };

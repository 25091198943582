import type { LastSearch, MetricOptions } from '@pages/content/lens/api/get-browse-companies-filters';
import type { FilterOptions } from '../filters';

export const mapLastSearchName = (
  lastSearch: LastSearch,
  availableMetrics?: MetricOptions[],
): (LastSearch & { metrics: Omit<MetricOptions, 'step'>[] }) | undefined => {
  if (!lastSearch?.metrics || !availableMetrics?.length) {
    return {
      ...lastSearch,
      metrics: [],
    };
  }

  const metricsWithNames = lastSearch.metrics.map((metric) => {
    const metricIndex = availableMetrics.findIndex((availableMetric) => availableMetric.code === metric.code);

    if (metricIndex === -1) return null;

    return {
      ...metric,
      name: availableMetrics[metricIndex].name,
    };
  });

  return {
    ...lastSearch,
    metrics: metricsWithNames.filter((el) => el !== null) as Omit<MetricOptions, 'step'>[],
  };
};

export const removeNameFromMetrics = (values: Pick<FilterOptions, 'metrics'>) => {
  const valuesWithMetricsWithoutName = {
    ...values,
    metrics: [
      ...values.metrics.map((el) => {
        const { name, ...rest } = el;
        return rest;
      }),
    ],
  };

  return valuesWithMetricsWithoutName as FilterOptions;
};

export const getNamePhraseForSearch = (namePhrase: LastSearch['namePhrase']) => (namePhrase ? `"${namePhrase}"` : null);

import { termsAndConditionsLinkInvestor } from '@constants/links';
import { setUserOnboardingStatus } from '@context/user/user-account.actions';
import { OnboardingStatus } from '@domain/accounts/types';
import { BecomeEncouragement } from '@pages/content/profile/parts/become-encouragement/become-encouragement';
import { SecureLink } from '@parts/link/link';
import { Links } from '@router/links';
import { useMutation } from '@tanstack/react-query';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { nedBecomingInvestorAction } from '../../api/ned-becoming-investor/ned-becoming-investor.action';
import { BecomeInvestorAwaiting } from './parts/become-awaiting';

export const BecomeInvestor = ({ awaitingAcceptance = false }: { awaitingAcceptance?: boolean }) => {
  const [header, description, becomeAnInvestorButton, becomeInvestorContinueButton, termsInfo, termsLinkText] =
    useTranslation([
      'profile.ned.section.becomeAnInvestor.header',
      'profile.ned.section.becomeAnInvestor.description',
      'profile.ned.section.becomeAnInvestor.becomeAnInvestorButton',
      'profile.ned.section.becomeAnInvestor.becomeAnInvestorContinueButton',
      'profile.ned.section.becomeAnInvestor.terms.info',
      'profile.ned.section.becomeAnInvestor.terms.link',
    ]);

  const history = useHistory();
  const {
    dispatch,
    state: { onboarding },
  } = useUserAccount();

  const isInvestorOnboardingPending = onboarding.investor === OnboardingStatus.Pending;

  const { mutateAsync: nedBecomingInvestor, isLoading } = useMutation(nedBecomingInvestorAction, {
    onSuccess: () => {
      dispatch(setUserOnboardingStatus({ investor: OnboardingStatus.Pending }));
      history.push(Links.ONBOARDING_INVESTOR());
    },
  });

  const handleBecomeInvestor = () => {
    if (isInvestorOnboardingPending) {
      history.push(Links.ONBOARDING_INVESTOR());
      return;
    }

    nedBecomingInvestor();
  };

  const bannerAdditionalInfo = isInvestorOnboardingPending ? null : (
    <>
      {termsInfo}
      <SecureLink href={termsAndConditionsLinkInvestor} outer target="_blank">
        {termsLinkText}
      </SecureLink>
    </>
  );

  if (awaitingAcceptance) {
    return <BecomeInvestorAwaiting />;
  }

  const buttonText = isInvestorOnboardingPending ? becomeInvestorContinueButton : becomeAnInvestorButton;

  return (
    <BecomeEncouragement
      header={header}
      onDecision={handleBecomeInvestor}
      description={description}
      decisionLoading={isLoading}
      buttonContent={buttonText}
      additionalInfo={bannerAdditionalInfo}
    />
  );
};

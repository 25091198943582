import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 422px;
`;

const TitleSectionWrapper = styled.div`
  align-self: stretch;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  display: inline-flex;
`;

const DescriptionWrapper = styled.div`
  height: 62px;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;

const Description = styled.p`
  align-self: stretch;
  color: ${({ theme }) => (theme.v2.mode === 'light' ? theme.v2.colors.blue[800] : theme.v2.colors.neutral[50])};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.v2.typography.weights.medium};
  line-height: 19.6px;
  word-break: break-word;

  @media (max-width: ${({ theme }) => theme.v2.breakpoints.sm}) {
    font-size: 12px;
  }
`;

const LinkWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.v2.colors.blue[300]};
  gap: 8px;

  @media (min-width: ${({ theme }) => theme.v2.breakpoints.sm}) {
    padding-top: 15px;
  }
`;

const TitleWrapper = styled.div`
  display: inline-flex;
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Title = styled.p`
  align-self: stretch;
  color: ${({ theme }) => (theme.v2.mode === 'light' ? theme.v2.colors.blue[400] : theme.v2.colors.neutral[50])};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
  line-height: 22.4px;
  word-wrap: break-word;
`;

const SubTitleWrapper = styled.div`
  align-self: stretch;
`;

const SubTitleBase = styled.span`
  font-size: 12px;
  font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
  line-height: 16.8px;
  letter-spacing: 0.22px;
  word-wrap: break-word;
`;

const SubTitle = styled(SubTitleBase)`
  color: ${({ theme }) => theme.v2.colors.red[300]};
`;

const SecondarySubTitle = styled(SubTitleBase)`
  color: ${({ theme }) => (theme.v2.mode === 'light' ? theme.v2.colors.blue[300] : theme.v2.colors.neutral[50])};
`;

export default {
  Wrapper,
  TitleSectionWrapper,
  DescriptionWrapper,
  Description,
  LinkWrapper,
  TitleWrapper,
  Title,
  SubTitleWrapper,
  SubTitle,
  SecondarySubTitle,
};

import S from './v2-progress-bar.styles';

export type V2ProgressBarVariants = 'primary' | 'blue-red' | 'magenta' | 'green' | 'blue';

type V2ProgressBarProps = {
  variant: V2ProgressBarVariants;
  percent?: number;
  strokeColor?: string;
  'data-testid'?: string;
};

export const V2ProgressBar = ({
  variant,
  percent = 0,
  strokeColor,
  'data-testid': testId = 'v2-progress-bar',
}: V2ProgressBarProps) => {
  return (
    <S.V2ProgressBar
      percent={percent}
      variant={variant}
      strokeColor={strokeColor}
      status="normal"
      showInfo={false}
      data-testid={testId}
    />
  );
};

import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  margin: 0;
  margin-bottom: 24px;
  padding: 16px 0;
  background-color: ${({ theme }) => theme.v2.colors.blue[theme.v2.mode === 'light' ? 50 : 800]};

  @media (min-width: ${({ theme }) => theme.v2.breakpoints.sm}) {
    padding: 24px 0;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 16px;
`;

const Title = styled.h1`
  text-align: left;
  font-family: ${({ theme }) => theme.v2.typography.fonts.body};
  font-weight: ${({ theme }) => theme.v2.typography.weights.bold};
  color: ${({ theme }) => (theme.v2.mode === 'light' ? theme.v2.colors.blue[400] : theme.v2.colors.neutral[50])};

  ${({ theme }) =>
    customMediaQuery('font-size', {
      xs: theme.v2.typography.sizes.lg,
      sm: theme.v2.typography.sizes.xl,
      md: theme.v2.typography.sizes['2xl'],
    })}
`;

const Subtitle = styled.h2`
  text-align: left;
  font-family: ${({ theme }) => theme.v2.typography.fonts.body};
  font-weight: ${({ theme }) => theme.v2.typography.weights.regular};
  color: ${({ theme }) => (theme.v2.mode === 'light' ? theme.v2.colors.neutral[700] : theme.v2.colors.neutral[400])};

  ${({ theme }) =>
    customMediaQuery('font-size', {
      xs: theme.v2.typography.sizes.xs,
      sm: theme.v2.typography.sizes.sm,
      md: theme.v2.typography.sizes.md,
    })}
`;

const Grid = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr;

  @media (min-width: ${({ theme }) => theme.v2.breakpoints.md}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${({ theme }) => theme.v2.breakpoints.lg}) {
    gap: 24px;
  }
`;

const SubGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.v2.breakpoints.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

export default {
  Wrapper,
  TitleWrapper,
  Title,
  Subtitle,
  Grid,
  SubGrid,
};

import { DownCircleFilled as DownCircleOutlinedBase } from '@ant-design/icons';
import { Select as SelectBase } from 'antd';
import styled from 'styled-components';

const DownCircleOutlined = styled(DownCircleOutlinedBase)`
  color: ${({ theme }) => theme.color.background};
  background-color: ${({ theme }) => theme.color.primary};
  border-radius: 50%;
`;

const Wrapper = styled(SelectBase)`
  width: 100%;

  .ant-select-selector {
    cursor: pointer !important;
    background-color: transparent !important;
    z-index: 1;
    border: ${({ theme }) => `1px solid ${theme.color.border}!important`};

    .ant-select-selection-placeholder {
      line-height: 43px;
    }
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }

  .ant-select-selection-placeholder {
    line-height: 50px !important;
  }

  &.ant-select-open .ant-select-selector {
    cursor: text !important;
  }

  .ant-select-selection-overflow {
    align-items: center;
  }

  .ant-select-selection-overflow-item {
    margin: 1px;
  }
`;

Wrapper.Option = SelectBase.Option;

export default {
  DownCircleOutlined,
  Wrapper,
};

import { useExtensiveModal } from '@parts/modal/extensive-modal/use-extensive-modal';
import { useFreemiumModalData } from './upgrade-freemium-modal-data';

export const useUpgradeFreemiumModal = (isVisible: boolean = false) => {
  const { Modal, open, show, hide } = useExtensiveModal(isVisible);

  const { darkTitleLabel, blueTitleLabel, freemiumList, submitButtonProps } = useFreemiumModalData();

  return {
    modal: (
      <Modal
        visible={open}
        closeModal={hide}
        submitButtonProps={submitButtonProps}
        darkTitle={darkTitleLabel}
        blueTitle={blueTitleLabel}
        listItems={freemiumList}
        closable
        testId="modal"
      />
    ),
    show,
  };
};

import { HoneybadgerErrorBoundary } from '@honeybadger-io/react';
import { CriticalErrorPage } from '@pages/utility/critical-error/critical-error.page';
import type { ReactNode } from 'react';
import { honeybadger } from './honeybadger';

export const AppLoggerErrorBoundary = ({ children }: { children: ReactNode }) => (
  <HoneybadgerErrorBoundary honeybadger={honeybadger} ErrorComponent={CriticalErrorPage}>
    {children}
  </HoneybadgerErrorBoundary>
);

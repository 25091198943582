import type { FounderOnboardingPathStep } from '@pages/content/onboarding/founder/paths';
import { removeDuplicates } from '@utils/fns/remove-duplicates';
import type { AppAction } from 'src/types';
import { FounderOnboardingActions } from './founder-onboarding.actions';
import type { FounderOnboardingState, OnboardingStepError } from './founder-onboarding.context';

export const initialFounderOnboardingState: FounderOnboardingState = {
  finished: false,
  stepErrors: [],
  playgroundFinished: false,
};

export type FounderOnboardingActionType =
  | AppAction<
      typeof FounderOnboardingActions.ADD_ONBOARDING_STEP_ERROR,
      { payload: OnboardingStepError<FounderOnboardingPathStep> }
    >
  | AppAction<typeof FounderOnboardingActions.REMOVE_ONBOARDING_STEP_ERROR, { payload: string }>;

export const reducer = (state: FounderOnboardingState, action: FounderOnboardingActionType): FounderOnboardingState => {
  switch (action.type) {
    case FounderOnboardingActions.ADD_ONBOARDING_STEP_ERROR:
      return { ...state, stepErrors: removeDuplicates([action.payload, ...state.stepErrors]) };

    case FounderOnboardingActions.REMOVE_ONBOARDING_STEP_ERROR:
      return { ...state, stepErrors: state.stepErrors.filter((item) => item.id !== action.payload) };

    default:
      return { ...state };
  }
};

import type { FilteredQueryResponse } from '@pages/content/pulse/api/get-investments-in-founder/get-investments-in-founder.action';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import { mapQueryStringToGraphqlParams } from '@utils/fns/map-query-string-to-graphql-params';
import type { ParsedQuery } from 'query-string';
import type { GraphqlResponse } from 'src/types';
import type { Investment } from '../../parts/editor/editor-interfaces';

export const GET_EXTENDED_INVESTMENTS_CACHE_KEY = 'GET_EXTENDED_INVESTMENTS';

export interface ExtendedInvestment extends Omit<Investment, 'terms'> {
  investingRelationshipId: string;
  initiator: 'Founder' | 'Investor';
  currentValue: number;
  confirmed: boolean;
  archived: boolean;
  company: {
    id: string;
    name: string;
    avatarUrl?: string;
    type: 'Internal' | 'External';
  };
}

type GetExtendedInvestments = {
  start: number;
  limit: number;
  excludedParams: string[];
  otherParams?: ParsedQuery<string>;
  relationId?: string;
};
export interface ExtendedInvestments extends FilteredQueryResponse {
  items: ExtendedInvestment[];
  totalInvested: number;
  totalCurrentValue: number;
  totalInvestmentsCount: number;
  totalShares: number;
  totalOwned: number;
}
export const getExtendedInvestments =
  ({ start, limit, excludedParams, otherParams, relationId }: GetExtendedInvestments) =>
  async (): Promise<GraphqlResponse<ExtendedInvestments>> => {
    const params = otherParams && mapQueryStringToGraphqlParams(otherParams, excludedParams);

    const queryName = relationId
      ? `extendedInvestments(investingRelationshipId: "${relationId}",start: ${start}, limit: ${limit}${params?.supplement})`
      : `extendedInvestments(start: ${start}, limit: ${limit}${params?.supplement})`;

    const { data } = await axiosInstance.post('/query', {
      variables: params?.variables,
      query: `
      query getExtendedInvestments${params?.arguments} {
        ${queryName} {
          filteringRules {
            availableValues,
            type
            allowedComparators
            key
          }
          sortingRules
          items {
            id
            investingRelationshipId
            company {
              id
              avatarUrl
              name
              type
            }
            round {
              type
              date
            }
            amount
            shares
            percentageOwnership
            preMoneyVal
            postMoneyVal
            shareClass
            leadInvestor
            personalNote
            initiator
            currentValue
            confirmed
            archived
          }
          total
          hasItems
          totalShares
          totalOwned
          totalInvested
          totalCurrentValue
          totalInvestmentsCount
        }
      }`,
    });
    return graphqlResponseWrapper(data, ['extendedInvestments']);
  };

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: ${({ theme }) => theme.color.fontPrimary};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex: 1;
  }
`;

const TitleDT = styled.dt<{ $labelWidth?: number }>`
  width: ${({ $labelWidth }) => ($labelWidth ? `${$labelWidth}%` : '15%')};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: auto;
    flex: 1;
  }
`;

const DescriptionDD = styled.dd`
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex: 1;
  }
`;

const InlineText = styled.span`
  &:not(:last-child) {
    margin-right: 15px;
  }
`;

export default { Wrapper, TitleDT, DescriptionDD, InlineText };

import { FormikField as FormikFieldBase } from '@pages/content/profile/parts/formik-field/formik-field';
import styled from 'styled-components';

const FormikField = styled(FormikFieldBase)`
  margin-bottom: ${({ theme }) => theme.spacing.xmedium};

  label {
    margin-bottom: ${({ theme }) => theme.spacing.small};
  }
`;

export default { FormikField };

import { useInfiniteQuery } from '@tanstack/react-query';
import { removeObjectProperties } from '@utils/fns/remove-object-properties';
import queryString from 'query-string';
import type { Any, GraphqlResponse } from 'src/types';
import { useFiltering } from '../use-filtering/use-filtering';
import {
  PERMANENTLY_EXCLUDED_QUERY_PARAMS,
  type PaginationQueryConfig,
  type TQueryPaginationActionParam,
} from '../use-query-pagination/use-query-pagination';
import { useQueryParams } from '../use-query/use-query-params';

interface UseInfiniteQueryPaginationParams<T> {
  pageParam: string;
  itemsPerPage: number;
  action: TQueryPaginationActionParam<T | Any>;
  cacheKey: string[];
  excludedParams?: string[];
  queryConfig?: PaginationQueryConfig;
}

export const useInfiniteQueryPagination = <T>({
  pageParam,
  itemsPerPage,
  action,
  cacheKey,
  excludedParams = [],
  queryConfig = {
    refetchOnWindowFocus: false,
  },
}: UseInfiniteQueryPaginationParams<T>) => {
  const { page: initialQueryPage, ...query } = useQueryParams();

  const stringifiedQuery = queryString.stringify(
    removeObjectProperties(query, [...PERMANENTLY_EXCLUDED_QUERY_PARAMS, ...excludedParams]),
  );

  const { dispatch, sortKey } = useFiltering({ pageParam });

  const {
    data: response,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isLoading,
    refetch,
  } = useInfiniteQuery({
    queryKey: [cacheKey, stringifiedQuery],
    queryFn: ({ pageParam: currentPage = 1 }) =>
      action(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage,
        [...PERMANENTLY_EXCLUDED_QUERY_PARAMS, pageParam, ...excludedParams],
        query,
      )(),
    ...queryConfig,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage?.data) {
        const totalItems = lastPage.data.total;
        const numberOfFetchedItems = allPages.length * itemsPerPage;
        const nextPageNumber = totalItems > numberOfFetchedItems ? allPages.length + 1 : null;
        return nextPageNumber;
      }
    },
  });

  const result = {
    response,
    hasNextPage: Boolean(hasNextPage),
    fetchNextPage,
    isFetching,
    isLoading,
    items: [] as T[],
    total: 0,
    hasItems: false,
    refetch: refetch as () => Promise<GraphqlResponse<{ items: (object & { id: string })[]; total: number }> | Any>,
    querying: {
      dispatch,
      sortKey,
      sortingRules: response?.pages[0]?.data?.sortingRules || [],
      filteringRules: response?.pages[0]?.data?.filteringRules || [],
    },
    additionalData: {},
  };

  if (response?.pages) {
    const { hasItems, total } = response.pages[0].data || {};

    const allItemsFlattened = response.pages.map((page: Any) => page.data.items).flat();
    return { ...result, items: allItemsFlattened, total, hasItems } as typeof result;
  }

  return result;
};

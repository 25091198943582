import AdvancedSubscription from '@assets/icons/legal/documents/advanced-subscription-agreement.svg?react';
import AdvisoryAgreement from '@assets/icons/legal/documents/advisory-agreement.svg?react';
import EmploymentContract from '@assets/icons/legal/documents/employment-contract.svg?react';
import Nda from '@assets/icons/legal/documents/nda.svg?react';
import SeisEisApplication from '@assets/icons/legal/documents/SEIS-EIS-application-form.svg?react';
import TermSheet from '@assets/icons/legal/documents/term-sheet.svg?react';

import { DocumentType } from '@pages/content/legal/api/get-legal-documents.action';
import S from '@parts/items-grid/items-grid.styles';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { FC } from 'react';
import { FilterType } from '../document-section';
import { Document } from './document';

export const AllDocuments = ({
  choosedFilter,
  availableDocuments,
  availableBlocking,
}: {
  choosedFilter: FilterType;
  availableDocuments: DocumentType[];
  availableBlocking: boolean;
}) => {
  const [
    termsSheetTitle,
    advisoryAgreementTitle,
    advancedSubscriptionAgreementTitle,
    ndaTitle,
    employmentContractTitle,
    seisTitle,
    termsSheetDescription,
    advisoryAgreementDescription,
    advancedSubscriptionAgreementDescription,
    ndaDescription,
    employmentContractDescription,
    seisDescription,
  ] = useTranslation([
    'legal.document.termsSheet.title',
    'legal.document.advisoryAgreement.title',
    'legal.document.advancedSubscriptionAgreement.title',
    'legal.document.nda.title',
    'legal.document.employmentContract.title',
    'legal.document.seis.title',
    'legal.document.termsSheet.description',
    'legal.document.advisoryAgreement.description',
    'legal.document.advancedSubscriptionAgreement.description',
    'legal.document.nda.description',
    'legal.document.employmentContract.description',
    'legal.document.seis.description',
  ]);
  const keyToObjectMapper: {
    [key: string]: {
      type: FilterType;
      component: FC<{ isComingSoon?: boolean }>;
    };
  } = {
    [DocumentType.TermsSheet]: {
      type: FilterType.Legal,
      component: () => (
        <Document
          image={<TermSheet />}
          dataTestId="Legal_TermsSheet_Doc"
          title={termsSheetTitle}
          description={termsSheetDescription}
          link={DocumentType.TermsSheet}
          availableBlocking={availableBlocking}
        />
      ),
    },
    [DocumentType.AdvisoryAgreement]: {
      type: FilterType.Legal,
      component: () => (
        <Document
          image={<AdvisoryAgreement />}
          title={advisoryAgreementTitle}
          dataTestId="Legal_Advisory_Doc"
          description={advisoryAgreementDescription}
          link={DocumentType.AdvisoryAgreement}
          availableBlocking={availableBlocking}
        />
      ),
    },
    [DocumentType.AdvancedSubscription]: {
      type: FilterType.Legal,
      component: () => (
        <Document
          image={<AdvancedSubscription />}
          title={advancedSubscriptionAgreementTitle}
          dataTestId="Legal_ASA_Doc"
          description={advancedSubscriptionAgreementDescription}
          link={DocumentType.AdvancedSubscription}
          availableBlocking={availableBlocking}
        />
      ),
    },
    [DocumentType.Nda]: {
      type: FilterType.Legal,
      component: () => (
        <Document
          image={<Nda />}
          title={ndaTitle}
          dataTestId="Legal_NDA_Doc"
          description={ndaDescription}
          link={DocumentType.Nda}
          availableBlocking={availableBlocking}
        />
      ),
    },
    [DocumentType.EmploymentContract]: {
      type: FilterType.Legal,
      component: () => (
        <Document
          image={<EmploymentContract />}
          title={employmentContractTitle}
          dataTestId="Legal_Employment_Doc"
          description={employmentContractDescription}
          link={DocumentType.EmploymentContract}
          availableBlocking={availableBlocking}
        />
      ),
    },
    [DocumentType.Seis]: {
      type: FilterType.Accounting,
      component: () => (
        <Document
          image={<SeisEisApplication />}
          title={seisTitle}
          dataTestId="Legal_SEIS_Doc"
          description={seisDescription}
          link={DocumentType.Seis}
          availableBlocking={availableBlocking}
          isComingSoon
        />
      ),
    },
  };

  return (
    <S.Wrapper>
      <S.Grid columns={3}>
        {availableDocuments
          .filter((item) => {
            const { type } = keyToObjectMapper[item];
            return choosedFilter === FilterType.All || type === choosedFilter;
          })
          .map((item, index) => {
            const { component: CorrectDocument } = keyToObjectMapper[item];
            const key = `${item}-${index}`;
            return <CorrectDocument key={key} />;
          })}
      </S.Grid>
    </S.Wrapper>
  );
};

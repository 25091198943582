import { V2BigBoyMultiSelect } from '@/components/ui/v2-big-boy-multi-select/v2-big-boy-multi-select';
import { V2Button } from '@/components/ui/v2-button/v2-button';
import { usePatchFounderAdvisorsQuestions } from '@/modules/founder/hooks/use-patch-founder-advisors-questions';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import type { StepProps } from '../types';

import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import S from './steps.styles';

export const SkillsetStep = ({
  mostPopularSkillsetsData,
  founderSmartMatchSkillsData,
  next,
  initialValues,
}: StepProps) => {
  const { patchFounderAdvisorsQuestionsMutation, isPatchFounderAdvisorsQuestionsLoading } =
    usePatchFounderAdvisorsQuestions(next);

  const formik = useFormik<{ skillSet?: string[] | null }>({
    validateOnChange: true,
    validateOnMount: true,
    initialValues: { skillSet: initialValues.skillSet },
    validationSchema: Yup.object({ skillSet: Yup.array().of(Yup.string()).min(1) }),
    onSubmit: (values) => {
      patchFounderAdvisorsQuestionsMutation({ skillSet: values.skillSet ?? undefined });
    },
  });
  const [nextButton, title, description] = useTranslation([
    'advisors-quick-search.modal-next-button',
    'advisors-quick-search.skills-step.title',
    'advisors-quick-search.skills-step.description',
  ]);

  return (
    <S.Wrapper>
      <S.TitleGroupWrapper title={title} subtitle={description} align="center" currentStep={1} countOfSteps={4} />
      <S.QuestionsWrapper>
        <form onSubmit={formik.handleSubmit}>
          <V2BigBoyMultiSelect
            options={founderSmartMatchSkillsData}
            value={formik.values.skillSet ?? []}
            onChange={(val) => formik.setValues({ skillSet: val })}
            quickOptions={mostPopularSkillsetsData}
            maxChoices={5}
            optionsName="skills"
          />
        </form>
      </S.QuestionsWrapper>

      <S.ButtonWrapper>
        <V2Button
          endIcon="arrow-right"
          onClick={formik.submitForm}
          isResponsive
          size="large"
          disabled={!formik.isValid || isPatchFounderAdvisorsQuestionsLoading}
        >
          {nextButton}
        </V2Button>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_INVESTOR_PORTFOLIO_METRICS_CACHE_KEY = 'get-investor-portfolio-metrics';

export interface InvestorPortfolioMetricsData {
  investmentsHistory: [Date, number][];
  portfolioValueHistory: [Date, number][];
  customerGroups: [string, number][];
  locations: [string, number][];
  investmentStages: [string, number][];
}

export const getInvestorPortfolioMetricsAction = async (): Promise<GraphqlResponse<InvestorPortfolioMetricsData>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getInvestorPortfolioMetric {
        investorPortfolioMetrics {
          investmentsHistory
          portfolioValueHistory
          customerGroups
          locations
          investmentStages
        }
      }`,
  });

  return graphqlResponseWrapper(data, ['investorPortfolioMetrics']);
};

import ArrowLeftIcon from '@assets/icons/arrow-left.svg';
import ArrowRightIcon from '@assets/icons/arrow-right.svg';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './footer.styles';

export interface FooterProps {
  previousHandler?: () => void;
  nextHandler: () => void;
  nextDisabled?: boolean;
  startingPage?: boolean;
  lastPage?: boolean;
  customNextBtnLabel?: string;
}

export const Footer = ({
  previousHandler,
  nextHandler,
  nextDisabled = false,
  startingPage,
  lastPage,
  customNextBtnLabel = '',
}: FooterProps) => {
  const [startLabel, nextLabel, finishLabel] = useTranslation([
    'navigation.wizard.footer.startSetup',
    'navigation.wizard.footer.next',
    'navigation.wizard.footer.finish',
  ]);

  const getNextButtonLabel = () => {
    if (startingPage) {
      return (
        <>
          {startLabel} <img src={ArrowRightIcon} alt="arrow-right" loading="lazy" />
        </>
      );
    }
    if (lastPage) {
      return finishLabel;
    }
    return (
      <>
        {customNextBtnLabel || nextLabel}
        <img src={ArrowRightIcon} alt="arrow-right" loading="lazy" />
      </>
    );
  };

  return (
    <S.Wrapper data-testid="wizard-layout-footer">
      {Boolean(previousHandler) && (
        <S.PreviousButton data-testid="wizard-layout-footer-prev" onClick={previousHandler}>
          <img src={ArrowLeftIcon} alt="arrow-left" loading="lazy" />
        </S.PreviousButton>
      )}
      <S.NextButton
        disabled={nextDisabled}
        onClick={nextHandler}
        data-testid="wizard-layout-footer-next"
        $wider={startingPage}
      >
        {getNextButtonLabel()}
      </S.NextButton>
    </S.Wrapper>
  );
};

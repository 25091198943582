import type { FilterOptions } from '../filters';
import { applySearchToQuery, type Search } from '@pages/content/lens/utils/apply-filters-to-query';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { getNamePhraseForSearch, removeNameFromMetrics } from '../helpers/helpers';

export type FiltersSubmitFn = ({
  firstMetricTypeFilter,
  secondMetricTypeFilter,
  thirdMetricTypeFilter,
  hasSeisLimitsRemaining,
  hasEisLimitsRemaining,
  onlyNewlyRegistered,
  sort,
  ...submitValues
}: FilterOptions) => void;

export const useParseAndSearch = (): FiltersSubmitFn => {
  const history = useHistory();
  const query = useQueryParams();

  const parseAndSearch = ({
    firstMetricTypeFilter,
    secondMetricTypeFilter,
    thirdMetricTypeFilter,
    hasSeisLimitsRemaining,
    hasEisLimitsRemaining,
    onlyNewlyRegistered,
    sort,
    regions,
    countriesOfResidence,
    ...submitValues
  }: FilterOptions) => {
    const valuesWithoutMetricName = removeNameFromMetrics(submitValues);
    const { page, ...initialQuery } = query;

    applySearchToQuery({
      history,
      initialQuery,
      search: {
        ...valuesWithoutMetricName,
        page: page || 1,
        namePhrase: getNamePhraseForSearch(submitValues.namePhrase),
        hasSeisLimitsRemaining: hasSeisLimitsRemaining ? true : undefined,
        hasEisLimitsRemaining: hasEisLimitsRemaining ? true : undefined,
        onlyNewlyRegistered: onlyNewlyRegistered ? true : undefined,
        sort: sort ?? undefined,
        shouldSavePayload: true,
        countriesOfResidence,
        regions,
      } as Search,
    });
  };

  return parseAndSearch;
};

import {
  SubscriptionProductStatus,
  SubscriptionProductType,
  type SubscriptionProduct,
} from '@domain/accounts/subscription-products';

export const isActiveNed = (products: SubscriptionProduct[]) =>
  products.some((product) => {
    return product.type === SubscriptionProductType.NED && product.status === SubscriptionProductStatus.APPROVED;
  });

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const SOURCE_OF_INVESTMENTS_CACHE_KEY = 'get-source-of-investments-options';

export const getSourceOfInvestmentsOptionsAction = () => async (): Promise<GraphqlResponse<string[]>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
        query getSourceOfInvestmentsOptionsAction {
          sourceOfInvestment
        }
      `,
  });

  return graphqlResponseWrapper(data, ['sourceOfInvestment']);
};

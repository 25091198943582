import EmptyStateIcon from '@assets/icons/undraw-file-searching.svg';
import type { FunctionComponent, ReactNode } from 'react';
import S from './empty-state.styles';

export const EmptyState = ({
  title,
  subTitle,
  Icon,
  children,
  className,
  slim,
}: {
  title?: ReactNode;
  subTitle?: ReactNode;
  Icon?: FunctionComponent;
  children?: ReactNode | ReactNode[];
  className?: string;
  slim?: boolean;
}) => {
  return (
    <S.Wrapper className={className}>
      <S.Frame slim={Boolean(slim)}>
        {Icon ? <Icon /> : <img src={EmptyStateIcon} alt="empty-icon" loading="lazy" />}
      </S.Frame>
      {title && <S.Title>{title}</S.Title>}
      {subTitle && <S.SubTitle>{subTitle}</S.SubTitle>}
      {children}
    </S.Wrapper>
  );
};

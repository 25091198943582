import { V2Button } from '@/components/ui/v2-button/v2-button';
import { usePatchFounderAdvisorsQuestions } from '@/modules/founder/hooks/use-patch-founder-advisors-questions';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import type { StepProps } from '../types';

import S from './steps.styles';

export const IndustryKnowledgeStep = ({ back, next, initialValues }: StepProps) => {
  const { patchFounderAdvisorsQuestionsMutation, isPatchFounderAdvisorsQuestionsLoading } =
    usePatchFounderAdvisorsQuestions(next);

  const formik = useFormik<{ industryKnowledge?: number | null }>({
    validateOnChange: true,
    validateOnMount: true,
    initialValues: { industryKnowledge: initialValues.industryKnowledge },
    validationSchema: Yup.object({ industryKnowledge: Yup.number().required() }),
    onSubmit: (values) => {
      patchFounderAdvisorsQuestionsMutation({ industryKnowledge: values.industryKnowledge ?? undefined });
    },
  });

  const [title, notImportant, somewhatImportant, veryImportant, nextButton, backButton, description] = useTranslation([
    'advisor-quick-search.industry-step.title',
    'advisor-quick-search.industry-step.not-important',
    'advisor-quick-search.industry-step.somewhat-important',
    'advisor-quick-search.industry-step.very-important',
    'advisors-quick-search.modal-next-button',
    'advisors-quick-search.modal-back-button',
    'advisor-quick-search.industry-step.description',
  ]);

  const options = [
    {
      label: notImportant,
      value: 1,
    },
    {
      label: somewhatImportant,
      value: 2,
    },
    {
      label: veryImportant,
      value: 3,
    },
  ];

  return (
    <S.Wrapper>
      <S.TitleGroupWrapper title={title} subtitle={description} align="center" currentStep={2} countOfSteps={4} />
      <S.QuestionsWrapper>
        <form onSubmit={formik.handleSubmit}>
          <S.ButtonWrapper>
            {options.map((option) => (
              <V2Button
                variant={formik.values.industryKnowledge === option.value ? 'selected' : 'outlined'}
                key={option.value}
                type="button"
                isResponsive
                onClick={() => formik.setValues({ industryKnowledge: option.value })}
              >
                {option.label}
              </V2Button>
            ))}
          </S.ButtonWrapper>
        </form>
      </S.QuestionsWrapper>

      <S.ButtonWrapper>
        <V2Button
          variant="outlined"
          startIcon="arrow-left"
          onClick={back}
          isResponsive
          size="large"
          disabled={isPatchFounderAdvisorsQuestionsLoading}
        >
          {backButton}
        </V2Button>
        <V2Button
          endIcon="arrow-right"
          onClick={formik.submitForm}
          isResponsive
          size="large"
          disabled={!formik.isValid || isPatchFounderAdvisorsQuestionsLoading}
        >
          {nextButton}
        </V2Button>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

/* eslint-disable react/no-danger */
import { FounderLayout } from '@layout/founder/founder.layout';
import { checkIfValidWysiWygValue } from '@pages/content/profile/hooks/use-wysiwyg';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { PageHeader } from '@parts/page-header/page-header';
import { Links } from '@router/links';
import { useQuery } from '@tanstack/react-query';
import { SanitizeWysiwyg } from '@utils/fns/sanitize-wysiwyg';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Col, Row } from 'antd';
import { useParams } from 'react-router';
import Description from '../../../../../parts/description/description';
import CompanyTile from '../../parts/company-tile/company-tile';
import { getFounderDataAction, GET_FOUNDER_DATA_REQUEST } from '../api/get-founder-details';
import { getFounderSingleRole, GET_FOUNDER_SINGLE_ROLE_CACHE_KEY } from '../api/get-founder-single-role.actions';
import { Apply } from '../parts/apply/apply';
import { Meta } from '../parts/meta/meta';
import Section from '../parts/section/section';

export const FounderPreview = () => {
  const { id } = useParams() as { id: string };

  const [preview] = useTranslation(['roles.founder.preview']);

  const { data: response, isLoading } = useQuery(
    [`${GET_FOUNDER_SINGLE_ROLE_CACHE_KEY}_${id}`],
    getFounderSingleRole(id),
  );

  const { data: founderDataResponse } = useQuery([`${GET_FOUNDER_DATA_REQUEST}`], getFounderDataAction);

  const history = useHistory();

  const handleGoBack = () => history.push(Links.ROLES_BROWSE());

  if (isLoading || !response?.data || !founderDataResponse?.data) {
    return (
      <FounderLayout>
        <FullHeightSpinner />
      </FounderLayout>
    );
  }

  const { description, title, ...meta } = response.data;

  return (
    <FounderLayout>
      {title && <PageHeader title={`${preview}: ${title}`} onBack={handleGoBack} />}
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={24} md={24} lg={16} xl={16}>
          <Section>
            <Meta {...meta} />
            <Description
              dangerouslySetInnerHTML={{
                __html: SanitizeWysiwyg(JSON.parse(description)),
              }}
            />
          </Section>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Section>
            <CompanyTile
              about={
                checkIfValidWysiWygValue(founderDataResponse.data.aboutCompany)
                  ? SanitizeWysiwyg(JSON.parse(founderDataResponse.data.aboutCompany))
                  : null
              }
              logoUrl={founderDataResponse.data.logo.url || ''}
              name={founderDataResponse.data.businessName}
            />
          </Section>

          <Apply
            isApplied={false}
            roleCvName=""
            profileCvUploaded={false}
            disabled
            dataTestId="founder-apply-for-a-role-btn"
          />
        </Col>
      </Row>
    </FounderLayout>
  );
};

import { Input } from 'antd';
import styled from 'styled-components';

const Textarea = styled(Input.TextArea)`
  resize: none;
`;

export default {
  Textarea,
};

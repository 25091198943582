import { V2WidgetCard } from '@/components/ui/v2-widget-card/v2-widget-card';
import type { WidgetCardProps } from '@/modules/founder/homepage/homepage';
import {
  ONBOARDING_PATH_PERFORMANCE_KEY,
  ONBOARDING_STEP_PERFORMANCE_DATA_DRIVEN_NETWORK,
} from '@/pages/content/onboarding/founder/paths';
import { Links } from '@/router/links';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { WidgetCardContent } from '../widget-card-content';

type ActionWidgetCardProps = {
  completedCount: number;
  totalCount: number;
  ['data-testid']?: string;
};

export const MetricsReportingCard = ({
  completedCount,
  totalCount,
  ['data-testid']: dataTestId = 'metrics-reporting-card',
}: ActionWidgetCardProps) => {
  const [
    profileTitle,
    profileCompletedLabel,
    profileDescription,
    profileDescriptionCompleted,
    linkText,
    editLabel,
    allDoneLabel,
  ] = useTranslation([
    'homepage.readinessScore.actionsWidget.metricsAndReporting.title',
    'homepage.completedLabel',
    'homepage.readinessScore.actionsWidget.metricsAndReporting.description',
    'homepage.updateAnswers',
    'homepage.readinessScore.tile.fillNow',
    'homepage.readinessScore.tile.edit',
    'homepage.readinessScore.tile.completed',
  ]);

  const isCompleted = completedCount === totalCount;

  const widgetCardData: Omit<WidgetCardProps, 'onActionClick'> = {
    title: profileTitle,
    subTitle: isCompleted ? '' : `${completedCount}/${totalCount}`,
    secondarySubTitle: isCompleted ? allDoneLabel : profileCompletedLabel,
    description: isCompleted ? profileDescriptionCompleted : profileDescription,
    actionText: isCompleted ? editLabel : linkText,
    actionUrl: Links.ONBOARDING_FOUNDER_STEP(
      ONBOARDING_PATH_PERFORMANCE_KEY,
      ONBOARDING_STEP_PERFORMANCE_DATA_DRIVEN_NETWORK,
    ),
    variant: isCompleted ? 'neutral' : 'primary',
    icon: 'graph-up',
    iconSize: 'lg',
    badges: [{ text: 'Learn This', variant: 'accent', size: 'sm' }],
    ['data-testid']: dataTestId,
  };

  return (
    <V2WidgetCard badges={widgetCardData.badges}>
      <WidgetCardContent {...widgetCardData} />
    </V2WidgetCard>
  );
};

import { SelfCertificationType } from '@pages/content/profile/investor/api/types';
import { axiosInstance } from '@utils/axios/axios-instance';

interface SelfCertificationCategoryPayload {
  certificationCategory: SelfCertificationType;
  confirmed: boolean;
}

export const setSelfCertificationCategoryAction = async (payload: SelfCertificationCategoryPayload) =>
  axiosInstance.put('/onboarding/investor/self-certification', payload);

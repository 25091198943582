import ResizeMediumIcon from '@assets/icons/resize-medium.svg?react';
import ResizeSmallIcon from '@assets/icons/resize-small.svg?react';
import ResizeIcon from '@assets/icons/resize.svg?react';
import { Tooltip } from '@parts/tooltip/tooltip';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Dropdown, Menu } from 'antd';
import S from './change-size.styles';

export enum Size {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

interface SizeMenuItem {
  key: Size;
  label: string;
  icon: JSX.Element;
  disabled: boolean;
  tooltip: string;
  onClick: () => void;
}

const ChangeSize = ({
  onClick,
  disableSmall = false,
  disableMedium = false,
  disableLarge = false,
  disableSmallTooltipText = '',
  disableMediumTooltipText = '',
  disableLargeTooltipText = '',
}: {
  onClick: (size: Size) => void;
  disableSmall?: boolean;
  disableMedium?: boolean;
  disableLarge?: boolean;
  disableSmallTooltipText?: string;
  disableMediumTooltipText?: string;
  disableLargeTooltipText?: string;
}) => {
  const [largeLabel, mediumLabel, smallLabel] = useTranslation([
    'pulse.dashboard.changeSize.large',
    'pulse.dashboard.changeSize.medium',
    'pulse.dashboard.changeSize.small',
  ]);

  const menuOptions: SizeMenuItem[] = [
    {
      key: Size.Large,
      label: largeLabel,
      icon: <ResizeIcon />,
      disabled: disableLarge,
      tooltip: disableLargeTooltipText,
      onClick: () => !disableLarge && onClick(Size.Large),
    },
    {
      key: Size.Medium,
      label: mediumLabel,
      icon: <ResizeMediumIcon />,
      disabled: disableMedium,
      tooltip: disableMediumTooltipText,
      onClick: () => !disableMedium && onClick(Size.Medium),
    },
    {
      key: Size.Small,
      label: smallLabel,
      icon: <ResizeSmallIcon />,
      disabled: disableSmall,
      tooltip: disableSmallTooltipText,
      onClick: () => !disableSmall && onClick(Size.Small),
    },
  ];

  return (
    <S.Wrapper data-testid="change-size-trigger">
      <Dropdown
        trigger={['click']}
        data-testid="change-size-dropdown"
        overlay={
          <Menu data-testid="change-size-menu">
            {menuOptions.map(({ key, label, disabled, onClick: onClickHandler, icon, tooltip }) => (
              <Menu.Item key={key} disabled={disabled}>
                <S.Button
                  disabled={disabled}
                  onClick={onClickHandler}
                  aria-disabled={disabled}
                  type="button"
                  data-testid={`change-size-${key}`}
                >
                  {icon} {label}
                  {Boolean(disabled && tooltip) && <Tooltip title={tooltip} />}
                </S.Button>
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <ResizeIcon data-testid="resize" />
      </Dropdown>
    </S.Wrapper>
  );
};

export default ChangeSize;

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const SEARCH_COMPANIES_QUERY_KEY = 'search-companies-query';

type ResponseData = {
  total: number;
  items: {
    id: string;
    name: string;
  }[];
};

export const searchCompanies = async (value: string): Promise<GraphqlResponse<ResponseData>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query searchCompanies {
        searchCompanies(limit: 5, start:0, filter: "${value}"){
          total
          items {
            id
            name
          }
        }
      }
    `,
  });

  return graphqlResponseWrapper(data, ['searchCompanies']);
};

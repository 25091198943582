import InternalServerError from '@assets/icons/internal-server.svg?react';
import { LinkButtonA } from '@parts/link-button/link-button';
import { Links } from '@router/links';
import { readAndSendErrorNoticeFromLocalStorage } from '@utils/honeybadger/utils/local-storage-error-reporter';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useEffect, useState } from 'react';
import { DashboardButton } from '../parts/dashboard-button/dashboard-button';
import { Description } from '../parts/description/description';
import { Heading } from '../parts/heading/heading';
import { Layout } from '../parts/layout/layout';
import { QuestionContact } from '../parts/question-contact/question-contact';

import S from './internal-server.styles';

export interface InternalServerErrorPageProps {
  critical: boolean;
}

export const InternalServerErrorPage = ({ critical = false }: InternalServerErrorPageProps) => {
  const [titleLabel, descriptionLabel, ctaLabel] = useTranslation([
    'internalServer.title',
    'internalServer.description',
    'default.cta',
  ]);

  const [errorCode, setErrorCode] = useState<string | null>(null);

  useEffect(() => {
    const errorCodeId = readAndSendErrorNoticeFromLocalStorage();
    setErrorCode(errorCodeId);
  }, []);

  const errorCodeElement = errorCode ? <S.DimmedMessage code>{errorCode}</S.DimmedMessage> : null;

  return (
    <Layout Icon={InternalServerError}>
      <Heading>{titleLabel}</Heading>
      <Description>{descriptionLabel}</Description>
      <QuestionContact />
      {critical ? <LinkButtonA href={Links.START_PAGE()}>{ctaLabel}</LinkButtonA> : <DashboardButton />}
      {errorCodeElement}
    </Layout>
  );
};

import { V2Button } from '@/components/ui/v2-button/v2-button';
import { V2Label } from '@/components/ui/v2-label/v2-label';
import { V2RadioGroup } from '@/components/ui/v2-radio-group/v2-radio-group';
import { V2Radio } from '@/components/ui/v2-radio/v2-radio';
import { useMediaQuery } from '@/hooks/use-media-query';
import type { FounderQualifyingQuestionsResponseData } from '@/modules/founder/homepage/api/get-founder-qualifying-questions.action';
import { usePatchFounderQualifyingQuestions } from '@/modules/founder/homepage/hooks/use-patch-founder-qualifying-questions';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import S from './steps.styles';

type InterestsStepProps = {
  currentStep: number;
  countOfSteps: number;
  initialValues: FounderQualifyingQuestionsResponseData;
  back: () => void;
  close: () => void;
};

export const InterestsStep = ({ currentStep, countOfSteps, initialValues, back, close }: InterestsStepProps) => {
  const { patchFounderQualifyingQuestionsMutation } = usePatchFounderQualifyingQuestions(close);
  const device = useMediaQuery();

  const formik = useFormik<{
    interestFindingAdvisors?: number;
    interestInvestorReporting?: number;
    interestSourcingInvestment?: number;
  }>({
    initialValues: {
      interestFindingAdvisors: initialValues.interestFindingAdvisors ?? undefined,
      interestInvestorReporting: initialValues.interestInvestorReporting ?? undefined,
      interestSourcingInvestment: initialValues.interestSourcingInvestment ?? undefined,
    },
    validationSchema: Yup.object({
      interestFindingAdvisors: Yup.number().required(),
      interestInvestorReporting: Yup.number().required(),
      interestSourcingInvestment: Yup.number().required(),
    }),
    onSubmit: async (values) => patchFounderQualifyingQuestionsMutation(values),
  });

  const [title, subtitle, backText, closeText] = useTranslation([
    'qualifying-questions.interests-step.title',
    'qualifying-questions.select-one',
    'qualifying-questions.previous-question.action',
    'qualifying-questions.last-question.action',
  ]);

  const [interestSourcingInvestment, interestFindingAdvisors, interestInvestorReporting] = useTranslation([
    'qualifying-questions.interests-step.interest-sourcing-investment',
    'qualifying-questions.interests-step.interest-finding-advisors',
    'qualifying-questions.interests-step.interest-investor-reporting',
  ]);

  const [answer1, answer2, answer3, answer4] = useTranslation([
    'qualifying-questions.interests-step.answer1',
    'qualifying-questions.interests-step.answer2',
    'qualifying-questions.interests-step.answer3',
    'qualifying-questions.interests-step.answer4',
  ]);

  const questions = [
    { label: interestSourcingInvestment, field: 'interestSourcingInvestment' },
    { label: interestFindingAdvisors, field: 'interestFindingAdvisors' },
    { label: interestInvestorReporting, field: 'interestInvestorReporting' },
  ] as const;

  const options = [
    { value: 1, label: answer1 },
    { value: 2, label: answer2 },
    { value: 3, label: answer3 },
    { value: 4, label: answer4 },
  ];

  const selectedButtonSize = device === 'xs' ? 'small' : 'large';
  const shouldIconBeDisplayed = device === 'xs' ? undefined : true;
  const radioGroupVariant = device === 'xs' ? 'vertical' : 'horizontal';
  const selectedRadioVariant = device === 'xs' ? 'primary' : 'secondary';

  return (
    <S.Wrapper>
      <S.TitleGroupWrapper
        title={title}
        subtitle={subtitle}
        align="center"
        currentStep={currentStep}
        countOfSteps={countOfSteps}
      />

      <S.QuestionsWrapper>
        {questions.map((question) => (
          <S.SingleQuestionWrapper key={question.field}>
            <V2Label text={question.label} align="center" />
            <V2RadioGroup defaultValue={formik.values[question.field]} variant={radioGroupVariant}>
              {options.map((option) => (
                <V2Radio
                  variant={selectedRadioVariant}
                  key={option.value}
                  value={option.value}
                  checked={formik.values[question.field] === option.value}
                  onChange={() => formik.setFieldValue(question.field, option.value)}
                >
                  {option.label}
                </V2Radio>
              ))}
            </V2RadioGroup>
          </S.SingleQuestionWrapper>
        ))}
      </S.QuestionsWrapper>

      <S.ButtonWrapper>
        <V2Button
          variant="outlined"
          onClick={back}
          startIcon={shouldIconBeDisplayed && 'arrow-left'}
          size={selectedButtonSize}
        >
          {backText}
        </V2Button>
        <V2Button
          variant="primary"
          onClick={formik.submitForm}
          endIcon={shouldIconBeDisplayed && 'arrow-right'}
          disabled={
            Boolean(formik.errors.interestFindingAdvisors) ||
            Boolean(formik.errors.interestInvestorReporting) ||
            Boolean(formik.errors.interestSourcingInvestment) ||
            !formik.values.interestFindingAdvisors ||
            !formik.values.interestInvestorReporting ||
            !formik.values.interestSourcingInvestment
          }
          size={selectedButtonSize}
        >
          {closeText}
        </V2Button>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

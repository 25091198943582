import type { MarketFitQuestionItem } from '@/pages/content/profile/founder/api/get-market-fit-questions/get-market-fit-questions.query';
import type { SmartMatchOptionsResponseData } from '@pages/content/profile/api/get-smart-match-options/get-smart-match-options.action';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';
import type { AdditionalInfoData, CompanyDetailsData, FounderSmartMatchData } from '../../founder-profile.page';
import type { TeamMemberData } from '../get-founder-team-members/get-founder-team-members.action';

export const GET_FOUNDER_PROFILE_CACHE_KEY = 'get-founder-profile';

interface ProfileFounderProfileResponse extends SmartMatchOptionsResponseData {
  personalDetail: CompanyDetailsData & {
    logo: {
      path: string | null;
      url: string | null;
    };
  };
  teamMembers: TeamMemberData[];
  marketFitQuestions: MarketFitQuestionItem[];
  smartMatch: Omit<FounderSmartMatchData, 'skillSets'>;
  additionalInformation: AdditionalInfoData;
  skillSetOptions: string[];
  productStageOptions: string[];
  investmentStageOptions: string[];
  customerGroupOptions: string[];
  industryOptions: string[];
  aboutCompany: string | null;
  achievements: string | null;
}

export const getFounderProfileAction = async (): Promise<GraphqlResponse<ProfileFounderProfileResponse>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
    query getFounderProfile {
      personalDetail: getFounderPersonalDetails {
        businessName,
        email,
        countryOfResidence,
        logo {
          path,
          url,
        },
        founded,
        phoneNumber,
        linkedinUrl,
        twitterUrl,
        aboutCompany,
        achievements,
        representativeFirstName,
        representativeLastName,
      },
      teamMembers: getFounderTeamMembers {
        id,
        name,
        position,
        experience,
        area,
        role,
        joined,
      },
      marketFitQuestions: founderMarketFit {
        questionCode
        lengthLimits {
          min
          max
        }
        booleanAnswer
        textAnswer
      },
      smartMatch: getFounderSmartMatch {
        industries,
        investmentStage,
        customerGroup,
        productStage,
        hasSeisLimitsRemaining,
        hasEisLimitsRemaining
      }
      additionalInformation: getFounderAdditionalInformation {
        pitchDeck {
          name
          path
          url
        }
        productVideoUrl
        otherFiles {
          name
          path
          url
        }
        financialModelFiles {
          name
          path
          url
        }
        hasInvestorReadyMaterials
      }
    }
  `,
  });

  return graphqlResponseWrapper(data, [
    'personalDetail',
    'teamMembers',
    'marketFitQuestions',
    'smartMatch',
    'additionalInformation',
    'locationOptions',
  ]);
};

import styled, { css, keyframes } from 'styled-components';

const FOOTER_NAV_HEIGHT = 70;

const draw = keyframes`
  to {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  z-index: 1000;
  height: 100%;
`;

export const TopRightPattern = styled.img`
  position: absolute;
  top: ${FOOTER_NAV_HEIGHT}px;
  right: 0;
  opacity: 0;
  animation: ${draw} 2s linear;
  animation-fill-mode: forwards;
  width: 33%;
  min-width: 250px;
  pointer-events: none;
  z-index: -1;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    width: 50%;
  }
`;

export const BottomLeftPattern = styled(TopRightPattern)<{ $withFooter?: boolean }>`
  top: auto;
  right: auto;
  bottom: ${({ $withFooter }) => ($withFooter ? `${FOOTER_NAV_HEIGHT}px` : '0')};
  left: 0;
  transform: rotate(180deg);
  pointer-events: none;
`;

export const Content = styled.div<{ $centerContent: boolean }>`
  padding: ${({ theme }) => theme.spacing.medium};
  margin-top: ${({ theme }) => theme.spacing.small};
  min-height: calc(100vh - 2 * ${FOOTER_NAV_HEIGHT}px);

  ${({ $centerContent }) =>
    $centerContent &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `};
`;

export default { Wrapper, TopRightPattern, BottomLeftPattern, Content };

import { useMemo } from 'react';
import { Actions } from './parts/actions';
import { IndustryListElement } from './parts/industry-list-element';
import { InvestmentStageListElement } from './parts/investment-list-element';
import { List } from './parts/list';
import { Paragraph } from './parts/paragraph';
import { Row } from './parts/row';
import { Section } from './parts/section';
import { V2AdvisorConnectionCardContext } from './v2-advisor-connection-card-context';
import type { V2AdvisorConnectionCardProps } from './v2-advisor-connection-card.types';

import S from './v2-advisor-connection-card.styles';

const V2AdvisorConnectionCard = ({
  pendingConnection,
  header,
  details,
  description,
  footer,
}: V2AdvisorConnectionCardProps) => {
  const contextValue = useMemo(() => ({ pendingConnection }), [pendingConnection]);

  return (
    <V2AdvisorConnectionCardContext.Provider value={contextValue}>
      <S.Card>
        {header}
        {details}
        {Boolean(pendingConnection.description) && description}
        {footer}
      </S.Card>
    </V2AdvisorConnectionCardContext.Provider>
  );
};

V2AdvisorConnectionCard.Section = Section;
V2AdvisorConnectionCard.Row = Row;
V2AdvisorConnectionCard.Paragraph = Paragraph;
V2AdvisorConnectionCard.Actions = Actions;
V2AdvisorConnectionCard.IndustryListElement = IndustryListElement;
V2AdvisorConnectionCard.InvestmentStageListElement = InvestmentStageListElement;
V2AdvisorConnectionCard.List = List;

export { V2AdvisorConnectionCard };

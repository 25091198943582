import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';
import type { IInvestorProfileDataResponse } from '../types';

export const INVESTOR_PROFILE_DATA_REQUEST = 'investor-profile-summary-request';

export const investorProfileDataAction = async (): Promise<GraphqlResponse<IInvestorProfileDataResponse>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
    query investorProfileData {
      getInvestorPersonalDetails {
        email,
        firstName,
        lastName,
        gender,
        recentOccupation,
        companyName,
        type,
        institutionType
        countryOfResidence,
        phoneNumber,
        linkedinUrl,
        twitterUrl,
        aboutMe,
        profilePicture {
          url,
          path
        }
      },
      getInvestorSmartMatch{
        industries,
        investmentStage,
        customerGroup,
        productStage,
        targetGeography
      }
      getInvestorInvestmentSpec: getInvestorInvestmentSpecQuery {
        investmentThesis,
        maxInvestmentAmount,
        minInvestmentAmount,
        hidePreviousInvestments
        previousInvestmentsTable {
          company,
          note
        }
        isDeployingCapitalInNextThreeMonths
        shouldShowDeployingCapitalModal
      },
    }
  `,
  });

  return graphqlResponseWrapper(data, [
    'getInvestorPersonalDetails',
    'getInvestorInvestmentSpec',
    'getInvestorSmartMatch',
  ]);
};

import styled from 'styled-components';

const NumberValues = styled.div`
  display: flex;
  width: 100%;
`;

export default {
  NumberValues,
};

import TriggersIcon from '@assets/icons/trigger-chart.svg?react';
import {
  getPulseCompaniesTotalAction,
  GET_PULSE_COMPANIES_TOTAL_CACHE_KEY,
} from '@pages/content/pulse/api/get-investor-connected-companies-total/get-investor-connected-companies-total.action';
import {
  getTriggersAction,
  GET_TRIGGERS_ACTION_CACHE_KEY,
  type Trigger,
} from '@pages/content/pulse/api/get-triggers/get-triggers.action';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { Pagination } from '@parts/pagination/pagination';
import { Section } from '@parts/section/section';
import { Links } from '@router/links';
import { useQuery } from '@tanstack/react-query';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useQueryPagination } from '@utils/hooks/use-query-pagination/use-query-pagination';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { EmptyState } from '../empty-state/empty-state';
import { TriggersTable } from './parts/table/table';

export interface TriggerRowData extends Trigger {
  isAvailable: boolean;
  tooltip: string;
  businessName?: string;
}

export const Triggers = () => {
  const {
    deviceData: { isDesktop },
  } = useDeviceDetect();

  const {
    items,
    total,
    isLoading: triggersLoading,
    refetch,
    hasItems,
    querying,
    ...pagination
  } = useQueryPagination<Trigger>({
    itemsPerPage: 4,
    pageParam: 'triggers',
    excludedParams: ['companies', 'triggers'],
    action: getTriggersAction,
    cacheKey: GET_TRIGGERS_ACTION_CACHE_KEY,
    scrollToTopOnPageChanged: !isDesktop,
    queryConfig: {
      cacheTime: 0,
      refetchOnWindowFocus: true,
    },
  });

  const { data: response, isLoading: companiesLoading } = useQuery(
    [GET_PULSE_COMPANIES_TOTAL_CACHE_KEY],
    getPulseCompaniesTotalAction,
    { cacheTime: 0 },
  );

  const [
    toLensTitleLabel,
    toLensSubtitleLabel,
    browseLabel,
    toCompaniesTitleLabel,
    toCompaniesSubtitleLabel,
    chooseLabel,
    notConnectedLabel,
    dataHiddenLabel,
  ] = useTranslation([
    'pulse.investor.triggers.lens.title',
    'pulse.investor.triggers.lens.subTitle',
    'pulse.investor.triggers.lens.browseNow',
    'pulse.investor.triggers.companies.title',
    'pulse.investor.triggers.companies.subtitle',
    'pulse.investor.triggers.companies.choose',
    'pulse.investor.triggers.notConnected.tooltip',
    'pulse.investor.triggers.dataHidden.tooltip',
  ]);

  if (triggersLoading || companiesLoading) return <FullHeightSpinner small />;

  const followsCompanies = response && response.data && response.data.total > 0;

  if (!hasItems) {
    return (
      <EmptyState
        toLabel={followsCompanies ? chooseLabel : browseLabel}
        to={followsCompanies ? Links.PULSE_INVESTOR_COMPANIES() : Links.LENS_SMART_MATCH()}
        Icon={TriggersIcon}
        title={followsCompanies ? toCompaniesTitleLabel : toLensTitleLabel}
        subTitle={followsCompanies ? toCompaniesSubtitleLabel : toLensSubtitleLabel}
      />
    );
  }

  return (
    <>
      <Section>
        <TriggersTable
          querying={querying}
          refetch={refetch}
          items={items.map((trigger) => ({
            ...trigger,
            isAvailable: trigger.isConnected && trigger.isMetricVisible,
            tooltip: !trigger.isConnected ? notConnectedLabel : dataHiddenLabel,
          }))}
        />
      </Section>
      {pagination && pagination.maxPages > 1 && <Pagination centered {...pagination} />}
    </>
  );
};

import type { AnyAbility } from '@casl/ability';
import { type BoundCanProps } from '@casl/react';
import { ContextualCan } from '../context/context';

type CanWithFallbackProps = BoundCanProps<AnyAbility> & {
  Fallback?: React.FunctionComponent;
};

export const CanWithFallback = ({ Fallback = () => null, ...props }: CanWithFallbackProps) => (
  <ContextualCan {...props} passThrough>
    {(allowed) => (allowed ? props.children : <Fallback />)}
  </ContextualCan>
);

export { CanWithFallback as Can };

import { V2ExperienceTile } from '@/components/blocks/v2-experience-tile/v2-experience-tile';
import { V2Avatar } from '@/components/ui/v2-avatar/v2-avatar';
import { V2ConfirmDialog } from '@/components/ui/v2-confirm-dialog/v2-confirm-dialog';

import { useRemoveExperience } from '@/modules/advisor/profile/hooks/use-remove-experience';
import { NED_PROFILE_DATA_QUERY_KEY } from '@/pages/content/profile/ned/api/ned-profile/ned-profile.query';
import type { INedExperience } from '@/pages/content/profile/ned/ned-profile.page';
import { useQueryClient } from '@tanstack/react-query';
import { EditExperienceModal } from '../../../../edit-experience-modal/edit-experience-modal';
import { useModal } from '@/hooks/use-modal';
import { useUserDateFormat } from '@/utils/hooks/use-user-date-format/use-user-date-format';
import { DateFormat } from '@/utils/i18n/date-formats';
import { DateTime } from 'luxon';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import S from './experience-item.styles';

type ExperienceItemProps = {
  experience: INedExperience;
};

export const ExperienceItem = ({ experience }: ExperienceItemProps) => {
  const [deleteModalMessageLL, deleteModalConfirmLL, deleteModalCancelLL, presentLL] = useTranslation([
    'profile.advisor.exp.deleteExperience.message',
    'profile.advisor.exp.deleteExperience.confirm',
    'profile.advisor.exp.deleteExperience.cancel',
    'profile.advisor.exp.form.present',
  ]);
  const { id, company, role, startDate, endDate, experienceSummary, skillSet, isOngoing } = experience;

  const queryClient = useQueryClient();
  const { dateFormatter } = useUserDateFormat();
  const formatDate = (date: string) => dateFormatter(date, DateFormat.LETTER_MONTH_LONG_YEAR);

  const { deleteExperience } = useRemoveExperience(id, () =>
    queryClient.invalidateQueries([NED_PROFILE_DATA_QUERY_KEY]),
  );

  const { isOpen, close, open } = useModal();

  return (
    <>
      <V2ExperienceTile
        data-testid="experience-item"
        key={id}
        avatar={<V2Avatar src={company.avatarUrl} name={company.name} />}
        companyInfo={{
          descriptionText: experienceSummary,
          details: {
            companyName: company.name,
            role,
            startDate: formatDate(startDate),
            endDate: isOngoing ? presentLL : endDate && formatDate(endDate),
          },
        }}
        companyInfoSection={
          <V2ExperienceTile.Details>
            <V2ExperienceTile.CompanyName />
            <V2ExperienceTile.Role />
            <V2ExperienceTile.DateRange />
          </V2ExperienceTile.Details>
        }
        skillsItems={skillSet.map((skill) => (
          <V2ExperienceTile.Pill key={skill}>{skill}</V2ExperienceTile.Pill>
        ))}
        industryItems={company.industry.map((industry) => (
          <V2ExperienceTile.Pill key={industry} isIndustry>
            {industry}
          </V2ExperienceTile.Pill>
        ))}
        description={<V2ExperienceTile.Description />}
        action={
          <V2ExperienceTile.Actions>
            <S.Button name="edit" onClick={open} />
            <V2ConfirmDialog
              message={deleteModalMessageLL}
              confirmButtonText={deleteModalConfirmLL}
              cancelButtonText={deleteModalCancelLL}
              centered
            >
              <S.Button name="trash" onClick={() => deleteExperience()} />
            </V2ConfirmDialog>
          </V2ExperienceTile.Actions>
        }
      />
      <EditExperienceModal
        experienceData={{
          ...experience,
          startDate: DateTime.fromISO(startDate).toFormat('yyyy-MM-dd'),
          endDate: endDate ? DateTime.fromISO(endDate).toFormat('yyyy-MM-dd') : null,
        }}
        isOpen={isOpen}
        close={close}
      />
    </>
  );
};

import { MetricType } from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import * as Yup from 'yup';

export const useValidationSchema = () =>
  Yup.object().shape(
    {
      metrics: Yup.array()
        .when(['addTextBox'], {
          is: false,
          then: (schema) => schema.required().min(1),
        })
        .of(
          Yup.object({
            id: Yup.string().uuid().required(),
            type: Yup.mixed<MetricType>().oneOf(Object.values(MetricType)).required(),
          }),
        ),
      addTextBox: Yup.bool().when(['metrics'], {
        is: true,
        then: (schema) => schema.isTrue().required(),
      }),
    },
    [['metrics', 'addTextBox']],
  );

import { FounderLayout } from '@layout/founder/founder.layout';
import { CodatRequestContextValue } from '@pages/content/pulse/api/connect-codat/connect-codat.action';
import {
  getFounderMetricsAction,
  GET_METRICS_ACTION_CACHE_KEY,
} from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import { MetricRibbon } from '@pages/content/pulse/parts/dashboards/parts/dashboard/metrics/parts/metric-item/parts/ribbon/ribbon.styles';
import { PageHeader } from '@parts/page-header/page-header';
import { Tabs, type ITabProps } from '@parts/tabs/tabs';
import { Links } from '@router/links';
import { manageMetricIntegratedMetricsKey, manageMetricMetricsKey } from '@router/tabs-keys';
import { useQuery } from '@tanstack/react-query';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './management.styles';
import { IntegratedMetrics } from './parts/integrated-metrics/integrated-metrics';
import { Metrics } from './parts/metrics/metrics';
import { Routes } from '@router/routes';

export const metricIdQueryParam = 'id';

export const ManagementPage = () => {
  const [titleLabel, integratedMetricsLabel, metricsLabel] = useTranslation([
    'metrics.manage.title',
    'metrics.integratedMetrics',
    'metrics.metrics',
  ]);

  const { data: getMetricsResponse } = useQuery([GET_METRICS_ACTION_CACHE_KEY], getFounderMetricsAction, {
    refetchOnWindowFocus: false,
  });
  const history = useHistory();
  const handleGoBack = () => history.push(Links.PULSE_FOUNDER_DASHBOARDS());
  if (!getMetricsResponse?.data) return null;

  const { internalMetrics, externalMetrics } = getMetricsResponse.data;
  return (
    <FounderLayout>
      <PageHeader title={titleLabel} onBack={handleGoBack} />
      <Tabs
        locator="mode"
        route={Routes.MANAGE_METRIC}
        defaultLocator={manageMetricIntegratedMetricsKey}
        tabs={
          [
            {
              key: manageMetricIntegratedMetricsKey,
              label: (
                <S.IntegratedMetricsLabel>
                  {integratedMetricsLabel} <MetricRibbon />
                </S.IntegratedMetricsLabel>
              ),
              content: (
                <IntegratedMetrics metrics={externalMetrics} codatRequestContext={CodatRequestContextValue.Pulse} />
              ),
            },
            {
              key: manageMetricMetricsKey,
              label: metricsLabel,
              content: <Metrics metrics={internalMetrics} />,
            },
          ].filter(Boolean) as ITabProps[]
        }
      />
    </FounderLayout>
  );
};

import type { PendingInvestmentsInvestor } from '@pages/content/portfolio/investor/api/get-pending-investments.action';
import type { PendingInvestmentsFounder } from '@pages/content/pulse/api/get-pending-investments.action';
import { Section } from '@parts/section/section';
import { ShowMoreLessButton } from '@parts/show-more-less-button/show-more-less-button';
import { Table } from '@parts/table/table';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { Columns } from '../column/column';

export const PendingInvestmentsTable = ({
  data,
  loading = false,
  refetch,
}: {
  data: PendingInvestmentsFounder[] | PendingInvestmentsInvestor[];
  loading?: boolean;
  refetch?: Function;
}) => {
  const [
    pendingInvestments,
    nameLabel,
    dateLabel,
    roundLabel,
    sharesLabel,
    investedLabel,
    ownedLabel,
    reviewButtonLabel,
  ] = useTranslation([
    'portfolio.investments.table.pendingInvestments.header',
    'portfolio.investments.table.pendingInvestments.name',
    'portfolio.investments.table.pendingInvestments.date',
    'portfolio.investments.table.pendingInvestments.round',
    'portfolio.investments.table.pendingInvestments.shares',
    'portfolio.investments.table.pendingInvestments.invested',
    'portfolio.investments.table.pendingInvestments.owned',
    'portfolio.investments.table.pendingInvestments.reviewButton',
  ]);
  const history = useHistory();

  const [isResized, setIsResized] = useState(false);

  const FUNDING_ROUNDS_STARTING_LENGTH = 3;

  return (
    <Section header={pendingInvestments}>
      <Table
        dataSource={data}
        pagination={{
          style: { display: 'none' },
          pageSize: isResized ? data?.length : FUNDING_ROUNDS_STARTING_LENGTH,
          hideOnSinglePage: true,
        }}
        columns={
          Columns({
            t: {
              nameLabel,
              dateLabel,
              roundLabel,
              sharesLabel,
              investedLabel,
              ownedLabel,
              reviewButtonLabel,
            },
            refetch,
            history,
          }) as ColumnsType<object>
        }
        loading={loading}
        rowKey="id"
      />
      {data?.length > FUNDING_ROUNDS_STARTING_LENGTH && (
        <ShowMoreLessButton isResized={isResized} setIsResized={setIsResized} scroll />
      )}
    </Section>
  );
};

import { Links } from '@router/links';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Link } from 'react-router-dom';
import S from './roles-table-heading.styles';

export const RolesTableHeading = () => {
  const [tableTitle, ctaLabel] = useTranslation(['roles.founder.table.title', 'roles.founder.cta']);

  return (
    <S.Container>
      <S.Title>{tableTitle}</S.Title>
      <S.Button type="primary">
        <Link to={Links.CREATE_NEW_ROLE()}>{ctaLabel}</Link>
      </S.Button>
    </S.Container>
  );
};

import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './smart-match-progress.styles';

export enum MatchLevel {
  Perfect = 'Perfect',
  Strong = 'Strong',
  Good = 'Good',
  Weak = 'Weak',
}

export const SmartMatchProgress = ({ level, className }: { level: MatchLevel; className?: string }) => {
  const [strong, perfect] = useTranslation(['lens.progress.strong', 'lens.progress.perfect']);

  const getConfig = () => {
    if (level === MatchLevel.Perfect) return { percentage: 100, label: perfect };
    if (level === MatchLevel.Strong) return { percentage: 90, label: strong };
    if (level === MatchLevel.Good) return { percentage: 70, label: null };
    return { percentage: 20, label: null };
  };

  const config = getConfig();

  return (
    <S.Wrapper className={className}>
      <S.BarHolder>
        {config.label && <S.Label>{config.label}</S.Label>}
        <S.Bar percent={config.percentage} showInfo={false} $matchLevel={level} />
      </S.BarHolder>
    </S.Wrapper>
  );
};

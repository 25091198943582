import { createGlobalStyle, css } from 'styled-components';
import { reactApexcharts } from './react-apexcharts.styles';
import { reactDraftWysiwyg } from './react-draft-wysiwyg.styles';
import { reactGridLayoutStyles } from './react-grid-layout.styles';

export const GlobalStyles = createGlobalStyle<{
  adjustToRegister?: boolean;
}>`
  html,
  body {
    background-color: ${({ theme }) => theme.color.layoutBackground};
    color: ${({ theme }) => theme.color.fontPrimary};
    font-size: ${({ theme }) => theme.fontSize.regular};
  }
  
  .ant-layout {
    background-color: ${({ theme }) => theme.color.layoutBackground};
  }

  .ant-select-item-option-state {
    pointer-events: none;
  }

  .ant-modal-root:not([data-ui-v="2"]){
    .ant-modal {
      @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
        max-width: calc(100% - ${({ theme }) => theme.spacing.medium} * 2);
      }
    }
  }
 
  // TODO: remove this after the upgrade all apps modals from UI v1 to UI v2
  .ant-modal-mask {
    background-color: ${({ theme }) => theme.color.maskBg};
  }

  .ant-modal-root[data-ui-v="2"] {
    .ant-modal-mask {
      background: ${({ theme }) =>
        theme.v2.mode === 'light'
          ? 'linear-gradient(162.53deg, rgba(217, 227, 254, 0.9) 7.06%, rgba(255, 255, 255, 0.9) 49.21%, rgba(255, 255, 255, 0.752833) 65.57%, rgba(255, 255, 255, 0.873) 89.32%, rgba(255, 255, 255, 0) 136.17%)'
          : 'linear-gradient(91.95deg, rgba(22, 31, 127, 0.8) 1.16%, rgba(17, 26, 114, 0.8) 10.7%, rgba(13, 20, 101, 0.8) 22.74%, rgba(0, 6, 65, 0.8) 42.69%, rgba(0, 6, 65, 0.8) 68.16%, rgba(0, 6, 65, 0.8) 100%)'};
    }
  }

  .ant-row {
    color: ${({ theme }) => theme.color.fontPrimary};
  }

  .ant-message {
    .ant-message-notice-content {
      width: 500px;
      min-height: 50px;
      text-align: left;
      padding: 0;
      font-size: 14px;
      cursor: pointer;

      @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
        width: 90%;
      }
    }

    .ant-message-success {
      background-color: ${({ theme }) => theme.baseColors.Green};
    }

    .ant-message-error {
      background-color: ${({ theme }) => theme.baseColors.Red};
    }

    .ant-message-info {
      background-color: ${({ theme }) => theme.baseColors.Blue200};
    }

    .ant-message-warning {
      background-color: ${({ theme }) => theme.baseColors.Orange};
    }

    .ant-message-custom-content {
      padding: ${({ theme }) => theme.spacing.xmedium};
      color: ${({ theme }) => theme.baseColors.White};
      display: flex;
      justify-content: flex-start;
      align-items: center;

      > svg {
        margin-right: ${({ theme }) => theme.spacing.xmedium};
        flex-shrink: 0;
      }

      > span {
        width: 100%;
      }
    }
  }






  .tooltip-hidden,
  .ant-popover-arrow {
    display: none;
  }

  .tooltip-visible {
    display: block;
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-affix-wrapper,
  .ant-input-number,
  .ant-select,
  .ant-picker,
  .ant-input {
    background-color: ${({ theme }) => theme.color.inputBackground};
    overflow: hidden;
    color: ${({ theme }) => theme.color.fontInput};
    border-color: ${({ theme }) => theme.color.border};

    input {
      color: ${({ theme }) => theme.color.fontInput};
    }
  }

  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    opacity: 0.7;
  }

  .ant-picker {
    &, input {
      cursor: pointer;
    }
  }

  .ant-select-disabled, .ant-picker.ant-picker-disabled {
    background-color: ${({ theme }) => theme.color.disabled};
    border-color: ${({ theme }) => theme.color.border};
    opacity: 0.7;

    .ant-select-selection-item, input {
      color: ${({ theme }) => theme.color.fontInput};
    }

    * {
      cursor: not-allowed;
    }
  }

  .ant-table-tbody > tr > td,
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: ${({ theme }) => theme.color.tableBackground};
  }

  .ant-picker-cell:not(.ant-picker-cell-in-view) {
    opacity: 0.5;
  }

  .ant-picker-cell-inner, .ant-picker-content th {
    color: ${({ theme }) => theme.color.fontPrimary};
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover {
    background-color: ${({ theme }) => theme.color.tableBackground};
  }

  .ant-input {
    height: ${({ theme }) => theme.ui.inputHeight};
    border: 1px solid ${({ theme }) => theme.color.border};

    &[disabled] {
      background-color: ${({ theme }) => theme.color.disabled};
      color: ${({ theme }) => theme.color.fontInput};
    }
  }

  .ant-btn:not(.ant-btn-sm) {
    height: ${({ theme }) => theme.ui.inputHeight};
  }

  a {
    text-decoration: underline;
    font-size: ${({ theme }) => theme.fontSize.small};
  }

  .ant-select.ant-select-multiple.ant-select-show-search {
    min-height: ${({ theme }) => theme.ui.inputHeight};

    & > .ant-select-selector {
      min-height: ${({ theme }) => theme.ui.inputHeight};
      border: 1px solid ${({ theme }) => theme.color.border};

      & > .ant-select-selection-item {
        height: 28px;
        line-height: 28px;
      }
    }
  }

  .ant-table {
    background-color: ${({ theme }) => theme.color.tableBackground};
  }

  .ant-select-multiple .ant-select-selection-item {
    border: 1px solid ${({ theme }) => theme.color.border};
    background-color: ${({ theme }) => theme.color.tagBackground};
    color: ${({ theme }) => theme.color.tagFont};
  }

  .ant-picker {
    border: 1px solid ${({ theme }) => theme.color.border};
    min-height: ${({ theme }) => theme.ui.inputHeight};
  }

  .antd-country-phone-input {
    border: 1px solid ${({ theme }) => theme.color.border};
    min-height: ${({ theme }) => theme.ui.inputHeight};
  }

  .ant-tooltip-inner {
    font-size: ${({ theme }) => theme.fontSize.xsmall};
  }

  .ant-tooltip, .ant-tooltip-content {
    z-index: 5000;
  }

  .ant-select-item-option-content:first-letter {
    text-transform: uppercase;
  }

  .investment-round-company-select {  
    .ant-select-item-option-content {
      color: ${({ theme }) => theme.color.fontPrimary};
      font-weight: ${({ theme }) => theme.fontWeight.normal};
      display: flex;
      align-items: center;
    }
  }

  .ant-drawer-wrapper-body {
    background-color: ${({ theme }) => theme.color.sectionBackground};
  }

  .ant-drawer-header,
  .ant-drawer-footer {
    border-color: ${({ theme }) => theme.color.border};
  }

  .ant-select-arrow {
    span {
      background-color: ${({ theme }) => theme.color.selectArrowBackground};
    }
    path {
      fill: ${({ theme }) => theme.color.selectArrowFill};
    }
  }

  .ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background-color: ${({ theme }) => theme.color.sectionBackground};
  }


  .ant-empty-description {
    color: ${({ theme }) => theme.color.fontPrimary};
  }

  .ant-dropdown {
    z-index: 1000;
  }

  .ant-dropdown-menu {
    background-color: ${({ theme }) => theme.color.sectionBackground};
  }

  .ant-dropdown-menu-item:not(.ant-dropdown-menu-item-selected),
  .ant-dropdown-menu-submenu-title {
    color: ${({ theme }) => theme.color.fontPrimary};
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${({ theme }) => theme.color.backgroundHover};
  }

  .ant-select-dropdown {
    background-color: ${({ theme }) => theme.color.layoutBackground};
    border-color: ${({ theme }) => theme.color.border};
  }

 .portfolio-entity-select {
  .ant-select-item-option-content {
    display: flex;
    align-items: center;
  }
 }

  .ant-select-item {
    color: ${({ theme }) => theme.color.fontPrimary};
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: ${({ theme }) => theme.color.backgroundHover};
    color: ${({ theme }) => theme.color.fontPrimary};
  }

  .ant-picker-dropdown {
    .ant-picker-cell-in-view {
      color: ${({ theme }) => theme.color.fontPrimary};
    }

    .ant-picker-panel-container {
      background-color: ${({ theme }) => theme.color.layoutBackground};
      border-color: ${({ theme }) => theme.color.border};
      color: ${({ theme }) => theme.color.fontPrimary};
    }

    button {
      color: ${({ theme }) => theme.color.fontPrimary};

      &:hover {
        color: ${({ theme }) => theme.color.backgroundHover};
      }
    }

    .ant-picker-cell-disabled .ant-picker-cell-inner {
      background-color: ${({ theme }) => theme.color.layoutBackground};
      color: ${({ theme }) => theme.color.fontPrimary};
      opacity: 0.5;
    }

    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
      .ant-picker-cell-inner {
      background: ${({ theme }) => theme.color.border};
    }

    .ant-picker-cell-disabled::before {
      display: none;
    }
  }

  .ant-picker-clear {
    padding: 3px;
  }

  .ant-radio-wrapper {
    color: ${({ theme }) => theme.color.fontPrimary};
  }

  .ant-dropdown-menu-item-selected {
    color: ${({ theme }) => theme.color.fontPrimary};
    background-color: ${({ theme }) => theme.color.backgroundHover};
  }

  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: ${({ theme }) => theme.color.backgroundHover};
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 100%;
  }

  .ant-popover-inner {
    background-color: ${({ theme }) => theme.color.sectionBackground};
    
    .ant-popover-message-title {
      color: ${({ theme }) => theme.color.fontPrimary};
    }
    
    .ant-popover-buttons {
      .ant-btn {
        background-color: ${({ theme }) => theme.baseColors.White};
      }
      
      .ant-btn-primary {
        background-color: ${({ theme }) => theme.color.submitButton};
        border-color: ${({ theme }) => theme.color.submitButton};
        color: ${({ theme }) => theme.baseColors.White};
      }
    }
  }

  .antd-country-phone-input {
    .ant-select-selector {
      cursor: pointer !important;

      input {
        cursor: pointer !important;
      }
    }
  }

  .ant-table-ping-right .ant-table-cell-fix-right-first::after, 
  .ant-table-ping-right .ant-table-cell-fix-right-last::after {
    box-shadow: none;
  }

  body.no-transition * {
    transition: none !important;
  }

  .linkedin-integrate-modal {
    .ant-modal-header {
      padding: 0;
    }
  }

  .linkedin-share-modal {
    .ant-modal-body {
      padding-bottom: 0;
    }

    .ant-modal-footer {
      padding-top: 0;
    }
  }

  .ant-slider-track {
    background-color: ${({ theme }) => theme.baseColors.Blue100};
  }

  .ant-slider-handle {
    border: ${({ theme }) => theme.baseColors.Blue100};
    background-color: ${({ theme }) => theme.baseColors.Blue100};
  }

  .ant-slider-rail {
    background-color: ${({ theme }) => theme.baseColors.Blue300};
  }

  .referral-modal-wrapper {
    .ant-modal-body {
      padding: ${({ theme }) => theme.spacing.medium};
    }
  }

  .readiness-score-modal {
    .ant-modal-body {
      padding-bottom: 40px;
    }
  }
  
  .typeform-modal-wrapper {
    .ant-modal-header {
      display: none;
    }
  }

  .country-select-dark {
    .ant-select-item-option-active,
      .ant-select-item-option-selected,
      .ant-select-item,
      .ant-select-item-option {
        background-color: ${({ theme }) => theme.baseColors.DarkerGray};
        color: ${({ theme }) => theme.baseColors.White};
        &:hover,
        &:focus,
        &:active,
        &[selected] {
          background-color: ${({ theme }) => theme.baseColors.DarkGrey};
        }
      }
  }
  
  .country-select-light {
    .ant-select-item-option-active,
      .ant-select-item-option-selected,
      .ant-select-item,
      .ant-select-item-option {
        background-color: ${({ theme }) => theme.baseColors.White};
        color: ${({ theme }) => theme.baseColors.Black};
        &:hover,
        &:focus,
        &:active,
        &[selected] {
          background-color: ${({ theme }) => theme.baseColors.Blue300};
        }
      }
  }

  .countries-and-regions-select {
    .ant-select-item-option-disabled{
        background-color: ${({ theme }) => theme.color.disabledSelectOption};
        padding-left: 25px;
    }
  }

  .hide-column-sorters {
    pointer-events: none;

    .ant-table-column-sorter {
        display: none;
    }
  }

  // reveal animation elements initial opacity
  [data-animation-id] {
    opacity: 0;
  }

  ${reactGridLayoutStyles}
  
  ${reactDraftWysiwyg}

  ${reactApexcharts}

  ${({ adjustToRegister }) =>
    adjustToRegister &&
    css`
      .ant-select-item-option-active,
      .ant-select-item-option-selected,
      .ant-select-item,
      .ant-select-item-option {
        background-color: ${({ theme }) => theme.registerColors.inputBackground};
        &:hover,
        &:focus,
        &:active,
        &[selected] {
          background-color: ${({ theme }) => theme.baseColors.Grey200};
        }
      }
    `}

    ::-webkit-scrollbar {
      background-color: #fff;
      background: ${({ theme }) => (theme.v2.mode === 'dark' ? 'transparent' : '#fff')};
      width: 14px;
    }

    ::-webkit-scrollbar-track {
      background: ${({ theme }) => (theme.v2.mode === 'dark' ? 'transparent' : '#fff')};
    }
    
    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => (theme.v2.mode === 'dark' ? '#3c434f' : '#babac0')};
      border-radius: 16px;
      border: 4px solid ${({ theme }) => (theme.v2.mode === 'dark' ? theme.v2.colors.blue[800] : '#fff')};
    }

    ::-webkit-scrollbar-button {
      display: none;
    }
`;

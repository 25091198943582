import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const Table = styled.div`
  display: flex;
  flex-direction: column;
  /* background-color: ${({ theme }) =>
    themedStyle(theme.v2.mode, theme.v2.colors.neutral[50], theme.v2.colors.blue[700])}; */
`;

const HeadRow = styled.div<{ gridTemplateColumns: string }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  border-top: 1px solid
    ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[100], theme.v2.colors.blue[500])};

  border-bottom: 1px solid
    ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[100], theme.v2.colors.blue[500])};
`;

const HeadCell = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
  justify-content: start;
  align-items: center;
  padding: 8px 16px;
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[500], theme.v2.colors.neutral[300])};
  font-weight: ${({ theme }) => theme.v2.typography.weights.medium};
  font-size: ${({ theme }) => theme.v2.typography.sizes.xs};
  min-width: 0;
  min-height: 0;
`;

const BodyRow = styled.div<{ gridTemplateColumns: string }>`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  width: 100%;
  min-height: 72px;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid
    ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[100], theme.v2.colors.blue[500])};
`;

const BodyCell = styled.div<{ align: 'start' | 'end' | 'center' }>`
  min-height: 72px;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: ${({ align }) => align};
  align-items: center;
  min-width: 0;
  min-height: 0;
`;

const Text = styled.span`
  font-size: ${({ theme }) => theme.v2.typography.sizes.xs};
`;

export default { Table, HeadRow, HeadCell, BodyRow, RowWrapper, BodyCell, Text };

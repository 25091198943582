import { CountriesRegionsSelect } from '@parts/countries-regions-select/countries-regions-select';
import { FormikField } from '@parts/forms/formik-field/formik-field';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import { RegionNamesMap, RegionValue } from '@utils/type-definitions/regions-values';
import { Col, Row } from 'antd';
import type { SelectValue } from 'antd/lib/select';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import S from './form.styles';
export interface CountriesAndRegionsForm {
  countriesOfResidence: CountryCodes[];
  regions: RegionValue[];
}

export const InvestorFiltersForm = ({
  initialValues,
  onSubmit,
  availableCountries,
  availableRegions,
  notUsedCountries,
}: {
  onSubmit: (formValues: CountriesAndRegionsForm) => void;
  initialValues: CountriesAndRegionsForm;
  availableCountries: CountryCodes[];
  availableRegions: RegionValue[];
  notUsedCountries: CountryCodes[];
}) => {
  const [applyLabel, countriesAndRegionsLabel] = useTranslation([
    'lens.filters.apply',
    'lens.filters.countriesAndRegions',
  ]);

  const { setFieldValue, values, submitForm, setFieldTouched } = useFormik<CountriesAndRegionsForm>({
    initialValues: { ...initialValues },
    onSubmit: (submitValues) => onSubmit(submitValues),
    validationSchema: Yup.object({
      countriesOfResidence: Yup.array().of(Yup.string()),
      regions: Yup.array().of(Yup.string()),
    }),
  });

  const handleSelectChange = (countriesKey: string, regionsKey: string) => (value: SelectValue) => {
    const eventValues = Array.isArray(value) ? value : [];

    const countriesData = eventValues.filter((item) => Object.values(CountryCodes).includes(item as CountryCodes));
    const regionsData = eventValues.filter((item) => Object.keys(RegionNamesMap).includes(item as RegionValue));

    setFieldValue(countriesKey, countriesData);
    setFieldTouched(countriesKey, true);

    setFieldValue(regionsKey, regionsData);
    setFieldTouched(regionsKey, true);
  };

  return (
    <Row gutter={[24, 0]}>
      <Col xs={24} sm={24} md={16}>
        <FormikField
          label={{
            for: 'countriesAndRegions',
            label: countriesAndRegionsLabel,
          }}
          marginBottom
        >
          <CountriesRegionsSelect
            id="countriesAndRegions"
            onChange={handleSelectChange('countriesOfResidence', 'regions')}
            regions={availableRegions}
            countriesOfResidence={availableCountries}
            notUsedCountries={notUsedCountries}
            value={[...values.countriesOfResidence, ...values.regions]}
          />
        </FormikField>
      </Col>

      <S.SubmitButton onClick={submitForm}>{applyLabel}</S.SubmitButton>
    </Row>
  );
};

import { useV2AriaTableContext } from '../v2-aria-table-context';

import S from '../v2-aria-table.styles';

type HeadRowProps = {
  children: React.ReactElement | React.ReactElement[];
};

export const HeadRow = ({ children }: HeadRowProps) => {
  const { gridTemplateColumns } = useV2AriaTableContext();

  return <S.HeadRow gridTemplateColumns={gridTemplateColumns}>{children}</S.HeadRow>;
};

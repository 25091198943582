import { TableStatus } from '@parts/table-status/table-status';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Tooltip } from 'antd';
import type { ExtendedInvestment } from '../../api/get-extended-investments/get-extended-investments.action';

const RoundTypeTooltip = ({
  typeName,
  confirmed,
  initiator,
}: {
  typeName: string;
  confirmed: boolean;
  initiator: ExtendedInvestment['initiator'];
}) => {
  const [confirmedByInvestorLabel, confirmedByFounderLabel, awaitingConfirmationLabel] = useTranslation([
    'portfolio.investor.table.confirmedByInvestor',
    'portfolio.investor.table.confirmedByFounder',
    'portfolio.investor.table.awaitingConfirmation',
  ]);

  return (
    <>
      {typeName}
      <Tooltip
        title={() => {
          if (confirmed) {
            return initiator === 'Investor' ? confirmedByFounderLabel : confirmedByInvestorLabel;
          }
          return awaitingConfirmationLabel;
        }}
      >
        {confirmed ? <TableStatus.Confirmed data-testid="confirmed" /> : <TableStatus.Pending data-testid="pending" />}
      </Tooltip>
    </>
  );
};

export default RoundTypeTooltip;

import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import LinesEllipsis from 'react-lines-ellipsis';

import S from './ellipsis-text.styles';

export const EllipsisText = ({
  text,
  maxLine,
  onSeeMore,
  seeMoreText,
}: {
  text: string;
  maxLine: number;
  onSeeMore?: () => void;
  seeMoreText?: string;
}) => {
  const [seeMoreDefaultText] = useTranslation(['default.seeMore']);
  const seeMorePrefix = '... ';

  const handleCustomSeeMore = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (onSeeMore) onSeeMore();
  };

  const seeMoreTextToUse = seeMoreText ?? seeMoreDefaultText;

  return (
    <LinesEllipsis
      text={text}
      maxLine={maxLine}
      basedOn="letters"
      trimRight
      ellipsis={
        onSeeMore
          ? ((
              <S.StyledEllipsisText onClick={(e) => e.preventDefault()}>
                {seeMorePrefix} <button onClick={(e) => handleCustomSeeMore(e)}>{seeMoreTextToUse}</button>
              </S.StyledEllipsisText>
            ) as unknown as string)
          : seeMoreTextToUse
      }
    />
  );
};

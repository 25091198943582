import type { V2ExperienceTileProps } from './v2-experience-tile.types';

import S from './v2-experience-tile.styles';
import { Details } from './parts/details';
import { V2ExperienceTileContext } from './v2-experience-tile-context';
import { Pill } from './parts/pill';
import { Description } from './parts/description';
import { CompanyName } from './parts/company-name';
import { Role } from './parts/role';
import { DateRange } from './parts/date-range';
import { Actions } from './parts/actions';

const V2ExperienceTile = ({
  avatar,
  industryItems,
  skillsItems,
  companyInfo,
  description,
  companyInfoSection,
  action,
}: V2ExperienceTileProps) => {
  const { descriptionText, details } = companyInfo;

  return (
    <V2ExperienceTileContext.Provider value={{ descriptionText, details }}>
      <S.V2ExperienceTile>
        {avatar}
        <S.ContentWrapper>
          {companyInfoSection}
          <S.PillsRow>
            {industryItems}
            {skillsItems}
          </S.PillsRow>
          {description}
        </S.ContentWrapper>
        {action}
      </S.V2ExperienceTile>
    </V2ExperienceTileContext.Provider>
  );
};

V2ExperienceTile.Details = Details;
V2ExperienceTile.CompanyName = CompanyName;
V2ExperienceTile.Role = Role;
V2ExperienceTile.DateRange = DateRange;
V2ExperienceTile.Pill = Pill;
V2ExperienceTile.Description = Description;
V2ExperienceTile.Actions = Actions;

export { V2ExperienceTile };

import { TranslationText } from '@/parts/translation-text/translation-text';
import { DateFormat } from '@i18n/date-formats';
import { RoleStatus, type FounderPreviewRole, type RoleMutationType } from '@pages/content/roles/roles.types';
import { TableColumn } from '@parts/table-column/table-column';
import { Links } from '@router/links';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { Tooltip } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { StatusDot } from '../status-dot/status-dot';
import S from './roles.styles';

const isExpired = (status: RoleStatus) => status === RoleStatus.Expired;

const detailsLink = (record: FounderPreviewRole) => {
  const translationId =
    record.status === 'Expired' ? 'roles.founder.table.actions.refresh' : 'roles.founder.table.actions.preview';
  return (
    <Tooltip title={<TranslationText id={translationId} />}>
      <Link to={Links.PREVIEW_ROLE(record.id)}>
        <S.DetailsIcon name="eye" size="lg" data-testid="preview-role-icon" />
      </Link>
    </Tooltip>
  );
};

const details = (record: FounderPreviewRole) => (record.status === 'Expired' ? <S.RefreshIcon /> : detailsLink(record));

const detailsBtn = (record: FounderPreviewRole, onMutationAction: Function) => (
  <S.Button
    type="link"
    onClick={isExpired(record.status) ? onMutationAction(record, 'refresh') : undefined}
    data-testid="refresh-or-preview-role"
  >
    {details(record)}
  </S.Button>
);

const closeOrRemove = (record: FounderPreviewRole) =>
  isExpired(record.status) ? (
    <Tooltip title={<TranslationText id={'roles.founder.table.actions.delete'} />}>
      <S.TrashIcon data-testid="delete-role-icon" />
    </Tooltip>
  ) : (
    <Tooltip title={<TranslationText id={'roles.founder.table.actions.close'} />}>
      <S.CloseIcon data-testid="close-role-icon" />
    </Tooltip>
  );

const removeBtn = (record: FounderPreviewRole, onMutationAction: Function) => (
  <S.Button
    type="link"
    onClick={isExpired(record.status) ? onMutationAction(record, 'delete') : onMutationAction(record, 'close')}
    data-testid="close-or-delete-role"
  >
    {closeOrRemove(record)}
  </S.Button>
);

const editUrl = (record: FounderPreviewRole) => Links.EDIT_ROLE(record.id);

const editBtn = (record: FounderPreviewRole) => (
  <S.Button type="link" data-testid="edit-role">
    <Tooltip title={<TranslationText id={'roles.founder.table.actions.edit'} />}>
      <Link to={editUrl(record)}>
        <S.EditIcon data-testid="edit-role-icon" />
      </Link>
    </Tooltip>
  </S.Button>
);

export const Columns = ({
  onMutationAction,
  translations: [title, applications, views, posted, expires, closed],
  oneColumn = false,
}: {
  translations: string[];
  onMutationAction: (row: FounderPreviewRole, mutationType: RoleMutationType) => () => void;
  oneColumn?: boolean;
}): ColumnsType<FounderPreviewRole> => {
  const { dateFormatter } = useUserDateFormat();

  return [
    {
      title,
      dataIndex: 'title',
      key: 'title',
      width: '40%',
      render: (value, record) => (
        <TableColumn title={title}>
          <S.RoleStatusWrapper>
            <StatusDot roleStatus={record.status} />
            <Tooltip title={<TranslationText id="roles.founder.table.actions.applications" />} placement="right">
              <S.RoleTitle to={Links.ROLE_APPLICATIONS(record.id)}>{value}</S.RoleTitle>
            </Tooltip>
          </S.RoleStatusWrapper>
        </TableColumn>
      ),
    },
    {
      title: applications,
      dataIndex: 'applications',
      key: 'applications',
      render: (application) => <TableColumn title={applications}>{application}</TableColumn>,
    },
    {
      title: views,
      dataIndex: 'views',
      key: 'views',
      render: (view) => <TableColumn title={views}>{view}</TableColumn>,
    },
    {
      title: posted,
      dataIndex: 'postedAt',
      key: 'postedAt',
      render: (value) => <TableColumn title={posted}>{dateFormatter(value, DateFormat.FULL_DATE)}</TableColumn>,
    },
    {
      title: expires,
      dataIndex: 'expiresAt',
      key: 'expiresAt',
      render: (value, record) => (
        <TableColumn title={expires}>
          {isExpired(record.status) ? (
            <S.RoleStatus expired={isExpired(record.status)}>{closed}</S.RoleStatus>
          ) : (
            DateTime.fromISO(value)
              .toRelative({
                unit: 'days',
                base: DateTime.now().minus({ day: 1 }),
              })
              ?.replace('in', '')
              .replace(/^ 0 days$/, 'Today')
          )}
        </TableColumn>
      ),
    },
    ...(oneColumn
      ? ([
          {
            title: '',
            render: (_, record) => (
              <S.ButtonsContainer>
                {detailsBtn(record, onMutationAction)}
                {editBtn(record)}
                {removeBtn(record, onMutationAction)}
              </S.ButtonsContainer>
            ),
          },
        ] as ColumnsType<FounderPreviewRole>)
      : ([
          {
            title: '',
            render: (_, record) => detailsBtn(record, onMutationAction),
          },
          {
            title: '',
            render: (_, record) => editBtn(record),
          },
          {
            title: '',
            render: (_, record) => removeBtn(record, onMutationAction),
          },
        ] as ColumnsType<FounderPreviewRole>)),
  ];
};

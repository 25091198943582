import { axiosInstance } from '@utils/axios/axios-instance';
import type { AxiosConfig } from '@utils/axios/types';

interface Payload {
  registrationToken: string;
  state: string;
}

export const finishInterruptedRegistrationAction = async (payload: Payload) =>
  axiosInstance.post<Payload, { data: { token: string } }>('/finish-interrupted-registration', payload, {
    skipAuthorization: true,
  } as AxiosConfig);

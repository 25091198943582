import type { V2TourMessageProps } from './v2-tour-message.types';

import S from './v2-tour-message.styles';

const V2TourMessage = ({ title, description, footer }: V2TourMessageProps) => {
  return (
    <S.V2TourMessage>
      <S.Title>{title}</S.Title>
      <S.Description>{description}</S.Description>
      <S.Footer>{footer}</S.Footer>
    </S.V2TourMessage>
  );
};

export { V2TourMessage };

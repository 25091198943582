import { Button as AntButton } from 'antd';
import styled, { css } from 'styled-components';

const Wrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.base};
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.small};
`;

const Frame = styled.div<{ slim: boolean }>`
  ${({ slim }) =>
    !slim &&
    css`
      margin-top: 100px;
    `}
`;

const Title = styled.h3`
  margin: ${({ theme }) => `${theme.spacing.base} 0 ${theme.spacing.medium}`};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.medium};
  color: ${({ theme }) => theme.color.fontPrimary};
  text-align: center;
`;

const SubTitle = styled.h4`
  font-size: ${({ theme }) => theme.fontSize.regular};
  color: ${({ theme }) => theme.color.fontSecondary};
  text-align: center;
`;

const Button = styled(AntButton)`
  margin-top: 30px;
  padding: ${({ theme }) => theme.spacing.medium};
  line-height: 0;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontSize.regular};

  & > a {
    text-decoration: none;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: 100%;
  }
`;

export default {
  Wrapper,
  Frame,
  Title,
  SubTitle,
  Button,
};

import { useQuery } from '@tanstack/react-query';
import { GET_ADVISOR_PROFILE_READINESS, getAdvisorProfileReadiness } from '../api/get-advisor-profile-readiness.query';

export const useGetAdvisorProfileReadiness = () => {
  const query = useQuery([GET_ADVISOR_PROFILE_READINESS], getAdvisorProfileReadiness, {
    refetchOnWindowFocus: true,
    cacheTime: 0,
  });

  return {
    advisorProfileReadinessData: query.data,
    isAdvisorProfileReadinessLoading: query.isLoading,
    isAdvisorProfileReadinessFetching: query.isFetching,
    isAdvisorProfileReadinessFetched: query.isFetched,
    refetchAdvisorProfileReadiness: query.refetch,
    ogQueryResult: query,
  };
};

import { V2Alert } from '@/components/ui/v2-alert/v2-alert';
import { useWizard } from '@/hooks/use-wizard';
import { InvestmentStageStep } from '@/modules/founder/homepage/components/qualifying-questions-modal/components/investment-stage-step';
import { MonthlyRevenueScaleStep } from '@/modules/founder/homepage/components/qualifying-questions-modal/components/monthly-revenue-scale-step';
import { useGetFounderQualifyingQuestions } from '@/modules/founder/homepage/hooks/use-get-founder-qualifying-questions';
import { READINESS_SCORE_KEY } from '@/modules/founder/readiness-score/api/get-readiness-score.query';
import { ACCOUNT_DATA_CACHE_KEY } from '@/pages/auth/api/account-data/account-data.actions';
import message from '@/parts/message/message';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { useQueryClient } from '@tanstack/react-query';
import { V2Modal } from '@ui/v2-modal/v2-modal';
import { Spin } from 'antd';
import { HeadcountScaleStep } from './components/headcount-scale-step';
import { InterestsStep } from './components/interests-step';

type QualifyingQuestionsModalProps = {
  isOpen: boolean;
  mode: 'auto' | 'manual';
  close?: () => void;
};

const steps = [
  {
    id: 'investment-stage',
    component: InvestmentStageStep,
  },
  {
    id: 'monthly-revenue-scale',
    component: MonthlyRevenueScaleStep,
  },
  {
    id: 'headcount-scale',
    component: HeadcountScaleStep,
  },
  {
    id: 'interests',
    component: InterestsStep,
  },
];

export const QualifyingQuestionsModal = ({ isOpen, mode, close }: QualifyingQuestionsModalProps) => {
  const queryClient = useQueryClient();
  const { founderQualifyingQuestionsData, isLoading, isSuccess, isError } = useGetFounderQualifyingQuestions();
  const { currentStep, nextStep, previousStep, progress, isLastStep } = useWizard({ steps, init: 0 });
  const [saveSuccessTextLL, loadingErrorTitleLL, loadingErrorDescriptionLL] = useTranslation([
    'qualifying-questions.successfully-updated',
    'messages.error.defaultTitle',
    'messages.error.default',
  ]);

  const CurrentStepComponent = steps[currentStep].component;

  const handleClose = async () => {
    close?.();
    if (isLastStep) {
      message.success({ content: saveSuccessTextLL });
      queryClient.invalidateQueries([ACCOUNT_DATA_CACHE_KEY]);
      queryClient.invalidateQueries([READINESS_SCORE_KEY]);
    }
  };

  return (
    <V2Modal
      isOpen={isOpen}
      closable={mode === 'manual'}
      onCancel={mode === 'manual' ? close : undefined}
      footer={null}
      size="lg"
      progress={progress}
      data-testid="qualifying-questions-modal"
    >
      {/* TODO: Complete the missing components */}
      {isLoading && <Spin />}
      {isError && (
        <V2Alert
          showIcon
          variant="error"
          title={loadingErrorTitleLL}
          description={loadingErrorDescriptionLL}
          style={{ margin: '40px 0 0' }}
        />
      )}

      {isSuccess && founderQualifyingQuestionsData && (
        <CurrentStepComponent
          close={handleClose}
          back={previousStep}
          next={nextStep}
          initialValues={founderQualifyingQuestionsData}
          currentStep={currentStep + 1}
          countOfSteps={steps.length}
        />
      )}
    </V2Modal>
  );
};

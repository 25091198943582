import { useDebouncedCallback } from '@/hooks/use-debounced-callback';
import message from '@parts/message/message';
import useIsMount from '@utils/hooks/use-is-mount/use-is-mount';
import { useEffect, useState } from 'react';
import { useTranslation } from '../use-translation/use-translation';

const SUBMITTED_ACTIVE_TIME = 1_500;

export const useAutoSubmit = ({
  watch,
  debounceDelay,
  enabled,
  submit,
  messageOnSave = false,
  isValid = true,
  extendedDependencyArray = [],
  preventUpdate,
}: {
  watch: string;
  submit: Function;
  debounceDelay: number;
  enabled: boolean;
  messageOnSave?: boolean;
  isValid?: boolean;
  extendedDependencyArray?: unknown[];
  preventUpdate?: boolean;
}) => {
  const [savedLabel] = useTranslation(['messages.form.success']);
  const isMount = useIsMount();
  const [justSubmitted, setJustSubmitted] = useState(false);
  const debouncedSubmitHandler = useDebouncedCallback(async () => {
    setJustSubmitted(true);
    await submit();
    if (messageOnSave) message.success({ content: savedLabel });
    setTimeout(() => setJustSubmitted(false), SUBMITTED_ACTIVE_TIME);
  }, debounceDelay);

  useEffect(() => {
    if (preventUpdate) return;
    if (!isMount && enabled && isValid) debouncedSubmitHandler();
  }, [watch, ...extendedDependencyArray]);

  return {
    justSubmitted,
    setJustSubmitted,
  };
};

import { AdvisorRole } from '@/domain/accounts/roles';
import { stringify } from 'query-string';

export const createAdvisorSearchSearchQuery = (
  data: { filters: Record<string, unknown>; regions: string[] },
  role: string,
) => {
  const { filters, regions } = data;

  const removeCompensationsFromFilters = (filtersObj: Record<string, unknown>) => {
    const { compensation, ...restFilters } = filtersObj;
    return restFilters;
  };

  const processedFilters =
    role.toLowerCase() === AdvisorRole.PLACEMENT.toLowerCase() ? removeCompensationsFromFilters(filters) : filters;
  const processedRegions = regions.length ? regions : undefined;

  return stringify({
    filters: Object.keys(processedFilters).length === 0 ? undefined : JSON.stringify(processedFilters),
    regions: processedRegions?.length ? JSON.stringify(processedRegions) : undefined,
  });
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_EXTERNAL_FUNDING_ROUND_TYPES_CACHE_KEY = 'get-external-funding-round-types';

export const getExternalFundingRoundTypesAction = async (): Promise<
  GraphqlResponse<{
    type: string[];
  }>
> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getExternalFundingRoundTypes {
        fundingRoundsMeta {
          type
        }
      }`,
  });

  return graphqlResponseWrapper(data, ['fundingRoundsMeta']);
};

import { MetricType } from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';
import { ChartViewType } from '../../parts/dashboard/metrics/parts/metric-item/parts/chart-metric/parts/chart-view-dropdown/chart-view-dropdown';

export enum VisibilityMode {
  Private = 'Private',
  Connections = 'Connections',
  Community = 'Community',
  CloseConnections = 'CloseConnections',
}

export interface MetricMeta {
  id: string;
  name: string;
  type: MetricType;
  metricId: string;
  chartType: ChartViewType;
  triggerSet?: boolean;
  isExternal: boolean;
}

export interface TextBoxMeta {
  id: string;
  title: string | null;
  mainText: string | null;
}

export interface GridConfig {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
}

export interface Dashboard {
  name: string;
  id: string;
  visibility: VisibilityMode;
  gridConfig: GridConfig[] | null;
  metrics: MetricMeta[];
  textBoxes: TextBoxMeta[];
}

export const GET_FOUNDER_DASHBOARD_CACHE_KEY = 'get-founder-dashboard';

export const getFounderDashboardAction = (dashboardId: string) => async (): Promise<GraphqlResponse<Dashboard>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getFounderDashboard {
        foundersDashboard(dashboardId: "${dashboardId}") {
          name,
          id,
          visibility,
          gridConfig {
            i,
            x,
            y,
            w,
            h,
          }
          metrics {
            id,
            name,
            type,
            metricId
            chartType,
            isExternal
          }
          textBoxes {
            id
            title
            mainText
          }
        }
      }
`,
  });

  return graphqlResponseWrapper(data, ['foundersDashboard']);
};

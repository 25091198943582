import styled from 'styled-components';

const Container = styled.section`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  background-color: ${({ theme }) => theme.color.layoutBackground};
`;

const S = {
  Container,
};

export default S;

import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import S from './v2-sign-out-button.styles';
import { useLogout } from '@/utils/hooks/use-logout/use-logout';
import type { CSSProperties } from 'react';

export const V2SignOutButton: React.FC<{ style?: CSSProperties }> = ({ style }) => {
  const { logout } = useLogout();
  const [signOut] = useTranslation(['ui.logout']);

  return (
    <S.SignOutButton style={style} variant="linkThin" onClick={() => logout()}>
      {signOut}
    </S.SignOutButton>
  );
};

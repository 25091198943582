import { type GetUsedCountriesAndRegionsResult } from '@pages/content/lens/api/get-countries-used-by-investors.action';
import { useQuery, type QueryFunction } from '@tanstack/react-query';
import { useMemo } from 'react';
import type { GraphqlResponse } from 'src/types';

export const useAvailableCountriesAndRegions = (
  queryCacheKey: string,
  queryHandler: QueryFunction<GraphqlResponse<GetUsedCountriesAndRegionsResult>>,
) => {
  const { data: queryData, isFetched } = useQuery([queryCacheKey], queryHandler);
  const data = useMemo(() => {
    return {
      isLoaded: isFetched && Boolean(queryData),
      availableCountries: queryData?.data?.usedCountries ?? [],
      availableRegions: queryData?.data?.regions ?? [],
      notUsedCountries: queryData?.data?.notUsedCountries ?? [],
    };
  }, [queryData, isFetched]);
  return data;
};

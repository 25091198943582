import { Section as SectionBase } from '@parts/section/section';
import styled, { css } from 'styled-components';

const Section = styled(SectionBase)<{ $deactivate?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${({ $deactivate }) =>
    $deactivate &&
    css`
      opacity: 0.6;
    `};
`;

const Header = styled.p`
  font-size: 22px;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin: ${({ theme }) => theme.spacing.small} auto;
  text-align: center;
`;

const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  margin: ${({ theme }) => theme.spacing.small} auto;
  color: ${({ theme }) => theme.color.fontSecondary};
`;

const ButtonWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing.medium} auto;
`;

const AdditionalInfo = styled.p`
  font-size: ${({ theme }) => theme.fontSize.small};

  a {
    color: inherit;
  }
`;

export default {
  Section,
  Header,
  ButtonWrapper,
  Description,
  AdditionalInfo,
};

import { EmptyState as EmptyStateBase } from '@parts/empty-state/empty-state';
import { Button as ButtonBase } from 'antd';
import styled from 'styled-components';

const Wrapper = styled(EmptyStateBase)``;

const Button = styled(ButtonBase)`
  margin-top: ${({ theme }) => theme.spacing.base};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

export default { Wrapper, Button };

import type { ReactNode } from 'react';
import S from './extrenal-url.styles';

interface IExternalUrl {
  href: string;
  children?: ReactNode | ReactNode[];
  className?: string;
}

export const ExternalUrl = ({ href, children, className }: IExternalUrl) => (
  <S.ExternalUrl href={href} target="_blank" rel="noopener noreferrer" className={className}>
    {children || href}
  </S.ExternalUrl>
);

import {
  amountValidation,
  leadInvestorValidation,
  percentageOwnershipValidation,
  shareClassValidation,
  sharesValidation,
  termsValidation,
} from '@pages/content/portfolio/investor/parts/editor/validation-schema';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import * as Yup from 'yup';

export const emailValidation = (requiredLabel: string) =>
  Yup.string().nullable().trim().email('validEmailLabel').required(requiredLabel);

export const useValidationSchema = () => {
  const [requiredLabel, integerLabel, minValueLabel, maxValueLabel, bigLabel, leadInvestorMaxLength] = useTranslation([
    'formik.validation.required',
    'formik.validation.integer',
    'formik.validation.lessThanMinimum',
    'formik.validation.largerThanMaximum',
    'formik.validation.numberTooBig',
    'pulse.founder.drawer.leadInvestorLengthExceeded',
  ]);

  return Yup.object({
    nameOrEmail: Yup.string().when(['investingRelationshipId', 'investorId'], {
      is: (investingRelationshipId: string, investorId: string) => investingRelationshipId || investorId,
      then: () => Yup.string().required(requiredLabel).nullable(),
      otherwise: () => emailValidation(requiredLabel),
    }),
    investments: Yup.array().of(
      Yup.object({
        round: Yup.object({
          id: Yup.string()
            .test('id-validation', 'Invalid id', function () {
              const { type, date, id: val } = this.parent;
              const alternativeValue = type || date;
              return (val && !alternativeValue) || (!val && alternativeValue);
            })
            .nullable(),
        }),
        amount: amountValidation(requiredLabel, bigLabel),
        shares: sharesValidation(minValueLabel, integerLabel, bigLabel),
        percentageOwnership: percentageOwnershipValidation(minValueLabel, maxValueLabel),
        shareClass: shareClassValidation(),
        leadInvestor: leadInvestorValidation(leadInvestorMaxLength),
        terms: termsValidation(minValueLabel, integerLabel, bigLabel),
      }),
    ),
  });
};

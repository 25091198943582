import { Button } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.footer`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.small};
  align-items: center;
  gap: ${({ theme }) => theme.spacing.small};
  background: ${({ theme }) => theme.onboardingColors.footerBackground};
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 1000;
`;

const PreviousButton = styled(Button)`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.onboardingColors.buttonBorder};
  height: 40px;
  font-size: ${({ theme }) => theme.fontSize.regular};
  color: ${({ theme }) => theme.color.dashboardBtn};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.onboardingColors.buttonBorder};
    background-color: ${({ theme }) => theme.onboardingColors.previousButtonHoverBackground};
  }
`;

export const NextButton = styled(PreviousButton)<{ $wider?: boolean }>`
  background: ${({ theme }) => theme.onboardingColors.nextButtonBackground};
  flex-basis: ${({ $wider }) => ($wider ? '200px' : '150px')};
  color: ${({ theme }) => theme.onboardingColors.fontPrimary};

  img {
    margin-left: ${({ theme }) => theme.spacing.small};
  }

  &:active,
  &:focus {
    background: ${({ theme }) => theme.onboardingColors.nextButtonBackground};
    color: ${({ theme }) => theme.onboardingColors.fontPrimary};
  }

  &:hover {
    background: ${({ theme }) => theme.color.submitButtonHover};
    color: ${({ theme }) => theme.onboardingColors.fontPrimary};
  }

  &:disabled,
  &:disabled:hover {
    background-color: ${({ theme }) => theme.color.submitButton};
    border: 1px solid ${({ theme }) => theme.color.submitButton};
    color: ${({ theme }) => theme.baseColors.White};
    opacity: 0.5;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.xsmall}) {
    flex-basis: 100px;
  }
`;

export default { Wrapper, PreviousButton, NextButton };

import styled from 'styled-components';
import { Section as SectionBase } from '@parts/section/section';
import { SubmitButton as SubmitButtonBase } from '@parts/submit-button/submit-button';

const Section = styled(SectionBase)`
  padding: ${({ theme }) => theme.spacing.xbase};
  padding-bottom: ${({ theme }) => theme.spacing.small};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    padding: ${({ theme }) => theme.spacing.small};
  }
`;

const SubmitButton = styled(SubmitButtonBase)`
  margin-top: 27px;
  margin-left: ${({ theme }) => theme.spacing.small};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin: 0 ${({ theme }) => theme.spacing.xmedium};
  }
`;

export default { SubmitButton, Section };

import { AdvisorRole } from '@/domain/accounts/roles';
import type { Compensation } from '@pages/content/profile/ned/ned-profile.page';
import { CompensationSelector } from '@parts/compensation-selector/compensation-selector';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './compensation-editor.style';
import { CompensationHeader } from './parts/header';

export const CompensationEditor = ({
  compensations,
  onChange,
  currencySymbol,
  disabled = false,
}: {
  onChange: (v: Compensation[]) => void;
  compensations: Compensation[];
  currencySymbol: string;
  disabled?: boolean;
}) => {
  const [headerTitle, headerTooltip] = useTranslation([
    'profile.ned.section.smartMatch.form.typicalCompensationTitle',
    'profile.ned.section.smartMatch.form.typicalCompensationTitleTooltip',
  ]);

  const handleChange = (role: AdvisorRole, value: Compensation[]) => {
    const otherRoleCompensations = compensations.filter((item) => item.role !== role);
    onChange([...otherRoleCompensations, ...value]);
  };

  const getRoleDisplayedTitle = (role: AdvisorRole) => {
    return role === AdvisorRole.NED ? role.toUpperCase() : role;
  };

  const orderedCompensationRoles = [AdvisorRole.ADVISOR, AdvisorRole.FRACTIONAL, AdvisorRole.NED].filter((role) =>
    compensations.map((item) => item.role).includes(role),
  );

  return (
    <S.Wrapper>
      <CompensationHeader title={headerTitle} tooltip={headerTooltip} />
      {orderedCompensationRoles.map((role) => (
        <CompensationSelector
          key={role}
          role={role}
          title={getRoleDisplayedTitle(role)}
          compensations={compensations.filter((compensation) => compensation.role === role)}
          onChange={(value) => handleChange(role, value)}
          disabled={disabled}
          currencySymbol={currencySymbol}
        />
      ))}
    </S.Wrapper>
  );
};

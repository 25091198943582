import { axiosInstance } from '@utils/axios/axios-instance';
import { DateTime } from 'luxon';
import type { AddExperienceFormSchemaType } from '../components/add-experience-modal/types';

type AddNewExperiencePayload = {
  formData: AddExperienceFormSchemaType;
  companyId: string | null;
  experienceId: string;
};

export const editExperience = ({ formData, companyId, experienceId }: AddNewExperiencePayload) => {
  const externalCompany = {
    name: formData.name,
    industry: formData.industry,
    email: formData.email?.trim() ? formData.email : null,
  };

  const payload = {
    externalCompany: companyId ? undefined : externalCompany,
    role: formData.role,
    startDate: DateTime.fromISO(formData.startDate).toUTC().toISO(),
    endDate: formData.endDate ? DateTime.fromISO(formData.endDate).toUTC().toISO() : null,
    isOngoing: formData.isOngoing,
    compensationType: formData.compensationType === '-' ? null : formData.compensationType,
    skillSet: formData.skillSet,
    experienceSummary: formData.experienceSummary?.trim().length ? formData.experienceSummary : null,
  };

  return axiosInstance.patch(`/profile/ned/experience/${experienceId}`, payload);
};

import styled from 'styled-components';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-wrap: wrap;
  }
`;

const NameWrap = styled.span`
  word-break: break-word;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export default { HeaderWrapper, NameWrap };

import ArrowLeftIcon from '@assets/icons/arrow-left.svg?react';
import { type ProfileDetails, ProfileTile } from '../profile-tile/profile-tile';
import S from './advisors-carousel.styles';

const carouselResponsiveConfig = [
  {
    breakpoint: 1140,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: true,
      dots: true,
    },
  },
  {
    breakpoint: 850,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 620,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];

export const AdvisorsCarousel = ({ items }: { items: ProfileDetails[] }) => {
  return (
    <S.CarouselWrapper data-testid="carousel-wrapper">
      <S.Carousel
        arrows
        dots={false}
        centerPadding="20px"
        slidesToShow={4}
        slidesToScroll={1}
        responsive={carouselResponsiveConfig}
        prevArrow={
          <span>
            <S.PrevButton
              data-testid="carousel-prev-button"
              type="primary"
              shape="round"
              size="small"
              icon={<ArrowLeftIcon />}
            />
          </span>
        }
        nextArrow={
          <span>
            <S.NextButton
              data-testid="carousel-next-button"
              type="primary"
              shape="round"
              size="small"
              icon={<ArrowLeftIcon />}
            />
          </span>
        }
      >
        {items.map((item) => (
          <S.CarouselSlide key={item.companyName} data-testid="carousel-slide">
            <ProfileTile profileDetails={item} />
          </S.CarouselSlide>
        ))}
      </S.Carousel>
    </S.CarouselWrapper>
  );
};

import { createContext, useContext } from 'react';

export type ColorThemeMode = 'dark' | 'light';

export type ThemeModeContextConfig = {
  mode: ColorThemeMode;
  setMode: (themeMode: ColorThemeMode) => void;
};

export const AppThemeContext = createContext<ThemeModeContextConfig | null>(null);

export const useThemeContext = (): ThemeModeContextConfig => {
  const theme = useContext(AppThemeContext);

  if (!theme) {
    throw new Error('useThemeContext must be used within a AppThemeProvider');
  }

  return theme;
};

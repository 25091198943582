import AngelIcon from '@assets/images/onboarding-investor-types/angel.webp';
import FamilyOfficeIcon from '@assets/images/onboarding-investor-types/family-office.webp';
import InstitutionalIcon from '@assets/images/onboarding-investor-types/institutional.webp';
import ScoutIcon from '@assets/images/onboarding-investor-types/scout.webp';
import VentureCapitalIcon from '@assets/images/onboarding-investor-types/venture-capital.webp';
import WealthManagementIcon from '@assets/images/onboarding-investor-types/wealth-management.webp';
import type { OnboardingStepError } from '@context/founder-onboarding/founder-onboarding.context';
import {
  addInvestorOnboardingStepError,
  removeInvestorOnboardingStepError,
  setInvestorOnboardingAccountType,
} from '@context/investor-onboarding/investor-onboarding.actions';
import { AppMessages } from '@i18n/messages';
import type { InvestorOnboardingStep } from '@pages/content/onboarding/investor/paths';
import { INVESTOR_ONBOARDING_STEP_TYPE_KEY } from '@pages/content/onboarding/investor/paths';
import { GradientTile } from '@pages/content/onboarding/parts/gradient-tile/gradient-tile';
import Title from '@pages/content/onboarding/parts/title/title';
import { InvestorType } from '@pages/content/profile/investor/api/types';
import message from '@parts/message/message';
import { TranslationText } from '@parts/translation-text/translation-text';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { sleep } from '@utils/fns/sleep';
import { useListEntryRevealAnimation } from '@utils/hooks/use-list-reveal-animation/use-list-reveal-animation';
import { useInvestorOnboardingContext } from '@utils/hooks/use-onboarding-context/use-investor-onboarding-context';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { setInvestorTypeAction, type InstitutionTypeValue } from '../../api/set-investor-type.action';
import S from './user-type.styles';

export const typeParamKey = 'type';
export const institutionParamKey = 'institution';

const TYPE_OPTIONS: { iconSrc: string; title: keyof typeof AppMessages; key: InvestorType }[] = [
  {
    iconSrc: AngelIcon,
    title: 'investor-onboarding.userType.angel',
    key: InvestorType.Angel,
  },
  {
    iconSrc: InstitutionalIcon,
    title: 'investor-onboarding.userType.institutional',
    key: InvestorType.Institutional,
  },
];

const INSTITUTION_OPTIONS: { iconSrc: string; title: keyof typeof AppMessages; key: InstitutionTypeValue }[] = [
  {
    iconSrc: WealthManagementIcon,
    title: 'investor-onboarding.userType.wealthManagement',

    key: 'Wealth Management',
  },
  {
    iconSrc: FamilyOfficeIcon,
    title: 'investor-onboarding.userType.familyOffice',

    key: 'Family Office',
  },
  {
    iconSrc: VentureCapitalIcon,
    title: 'investor-onboarding.userType.ventureCapital',
    key: 'Venture Capital',
  },
  {
    iconSrc: ScoutIcon,
    title: 'investor-onboarding.userType.scout',
    key: 'Scout',
  },
];

export const UserTypePageContent = () => {
  const history = useHistory();
  const [titleLabel, subTitleLabel, selectCategoryLabel, errorLabel] = useTranslation([
    'investor-onboarding.userType.title',
    'investor-onboarding.userType.subTitle',
    'investor-onboarding.category.title',
    'investor-onboarding.userType.error',
  ]);

  const {
    state: { stepErrors, accountType, institutionType },
    dispatch: dispatchOnboardingState,
  } = useInvestorOnboardingContext();

  const [selectedType, setSelectedType] = useState<InvestorType | null>(null);
  const [selectedInstitution, setSelectedInstitution] = useState<InstitutionTypeValue | null>(null);

  const { type: typeQueryParam, institution: institutionQueryParam } = useQueryParams() as {
    [typeParamKey]: string | undefined;
    [institutionParamKey]: string | undefined;
  };

  useListEntryRevealAnimation(
    'type-tiles',
    {},
    {
      deps: [typeQueryParam],
      shouldReinit(prev, curr) {
        // skip animation reinitialisation when type is changed from not selected to 'Angel'
        if (prev[0] === undefined && curr[0] === 'Angel' && !accountType) return false;
        return true;
      },
    },
  );
  useListEntryRevealAnimation(
    'institution-tiles',
    {},
    {
      deps: [typeQueryParam],
      shouldReinit() {
        return true;
      },
    },
  );

  const error: OnboardingStepError<InvestorOnboardingStep> = {
    id: `${INVESTOR_ONBOARDING_STEP_TYPE_KEY}-client-data-error`,
    step: INVESTOR_ONBOARDING_STEP_TYPE_KEY,
    error: errorLabel,
    disableButton: false,
  };

  const { mutateAsync: selectInvestorType, isLoading: isMutating } = useMutation(setInvestorTypeAction, {
    onSuccess(_, variables) {
      dispatchOnboardingState(setInvestorOnboardingAccountType(variables.investorType));

      if (stepErrors.length) {
        dispatchOnboardingState(removeInvestorOnboardingStepError(error.id));
      }
    },
    onError(err: AxiosError) {
      message.error({ content: getServerError(err) });
      dispatchOnboardingState(addInvestorOnboardingStepError(error));
    },
  });

  const handleSetStepError = () => {
    if (
      !typeQueryParam ||
      (typeQueryParam === InvestorType.Institutional && !institutionQueryParam && !institutionType)
    ) {
      return dispatchOnboardingState(addInvestorOnboardingStepError(error));
    }

    if (typeQueryParam === InvestorType.Institutional && institutionQueryParam && stepErrors.length) {
      return dispatchOnboardingState(removeInvestorOnboardingStepError(error.id));
    }

    if (typeQueryParam === InvestorType.Angel && stepErrors.length) {
      return dispatchOnboardingState(removeInvestorOnboardingStepError(error.id));
    }
  };

  const setUrlParams = (param: string, value: string) => {
    const params = new URLSearchParams(window.location.search);
    params.set(param, value);

    history.push({ search: params.toString() });
  };

  const handleParamsUpdate = async () => {
    if (selectedType === InvestorType.Angel) setUrlParams(typeParamKey, InvestorType.Angel);

    if (selectedType === InvestorType.Institutional) {
      await sleep(400);
      setUrlParams(typeParamKey, InvestorType.Institutional);
    }

    if (selectedInstitution) {
      setUrlParams(institutionParamKey, selectedInstitution);
    }
  };

  const handleValuesSubmit = async () => {
    if (typeQueryParam === InvestorType.Angel) await selectInvestorType({ investorType: typeQueryParam });

    if (typeQueryParam === InvestorType.Institutional && typeof institutionQueryParam === 'string') {
      await selectInvestorType({
        investorType: typeQueryParam,
        institutionType: institutionQueryParam as InstitutionTypeValue,
      });
    }
  };

  useEffect(() => {
    handleParamsUpdate();
  }, [selectedType, selectedInstitution]);

  useEffect(() => {
    setSelectedType((typeQueryParam as InvestorType) || null);
    setSelectedInstitution((institutionQueryParam as InstitutionTypeValue) || null);

    handleSetStepError();

    if (accountType === InvestorType.Angel && accountType === typeQueryParam) {
      return;
    }

    if (accountType === InvestorType.Institutional && institutionType === institutionQueryParam) {
      return;
    }

    handleValuesSubmit();
  }, [typeQueryParam, institutionQueryParam]);

  const isFirstStep = typeQueryParam !== InvestorType.Institutional;
  const title = isFirstStep ? titleLabel : selectCategoryLabel;

  useEffect(() => {
    if (accountType === InvestorType.Angel) {
      setSelectedType(accountType);
      dispatchOnboardingState(removeInvestorOnboardingStepError(error.id));
    }
  }, []);

  const firstStepContent = TYPE_OPTIONS.map((option, i) => (
    <GradientTile
      iconSrc={option.iconSrc}
      title={<TranslationText id={option.title} />}
      onClick={() => setSelectedType(option.key)}
      selected={selectedType === option.key}
      className={`type-tile-${i}`}
      key={`type-tile-${i}`}
      dataAnimationId="type-tiles"
      disabled={isMutating}
    />
  ));

  const secondStepContent = INSTITUTION_OPTIONS.map((option, i) => (
    <GradientTile
      iconSrc={option.iconSrc}
      title={<TranslationText id={option.title} />}
      onClick={() => setSelectedInstitution(option.key)}
      selected={selectedInstitution === option.key || selectedInstitution === option.key.replace(' ', '')}
      className={`institution-tile-${i}`}
      key={`institution-tile-${i}`}
      dataAnimationId="institution-tiles"
      disabled={isMutating}
    />
  ));

  return (
    <S.Wrapper>
      <Title title={title} subHeadline={subTitleLabel} />
      <S.Tiles>{isFirstStep ? firstStepContent : secondStepContent}</S.Tiles>
    </S.Wrapper>
  );
};

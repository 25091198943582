import { useQuery } from '@tanstack/react-query';
import { GET_EXPERIENCE_QUERY_KEY, getNedExperience } from '../api/get-experience.action';

export const useGetExperience = () => {
  const { data, error, isLoading, refetch, ...restQueryResult } = useQuery({
    queryFn: () => getNedExperience(),
    queryKey: [GET_EXPERIENCE_QUERY_KEY],
    cacheTime: 3600,
  });

  return {
    ...restQueryResult,
    experience: data?.data,
    refetchExperience: refetch,
    experienceError: error,
    isExperienceLoading: isLoading,
  };
};

import { useQueryParams } from '@/utils/hooks/use-query/use-query-params';
import { useHistory } from 'react-router-dom';

export const useQueryParamsTabs = ({
  defaultActive,
  tabsList,
  tabKey = 'tab',
}: {
  tabsList: string[];
  defaultActive?: string;
  tabKey?: string;
}) => {
  const defaultTab = defaultActive || tabsList[0];

  const history = useHistory();
  const { [tabKey]: tab = defaultTab } = useQueryParams();

  const setActiveTab = (key: string) => {
    if (tabsList.includes(key)) {
      history.push({ search: `?${tabKey}=${key}` });
      return;
    }

    history.push({ search: `?${tabKey}=${defaultTab}` });
  };

  return {
    activeTab: tab,
    setActiveTab,
  };
};

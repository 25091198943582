import { V2Flag } from '@/components/ui/v2-flag/v2-flag';
import { Link } from 'react-router-dom';
import type { V2ProfileCardHeaderProps } from './v2-profile-card-header.types';

import { V2Avatar } from '../v2-avatar/v2-avatar';
import S from './v2-profile-card-header.styles';

export const V2ProfileCardHeader = ({
  name,
  url,
  country,
  avatarUrl,
  subtitle = null,
  badges = null,
  footer = null,
  metaExtra = null,
  className,
  'data-testid': testId = 'v2-profile-card-header',
}: V2ProfileCardHeaderProps) => {
  return (
    <S.ProfileCardHeader data-testid={testId} className={className}>
      {badges ? <S.BadgesWrapper>{badges}</S.BadgesWrapper> : null}
      <div>
        <S.ProfileMeta>
          <Link to={url}>
            <V2Avatar src={avatarUrl} name={name} alt={name} />
          </Link>
          <div>
            <Link to={url}>
              <S.ProfileMetaTitle>
                {name}
                {country ? <V2Flag countryIso={country} /> : null}
              </S.ProfileMetaTitle>
            </Link>
            {subtitle ? <S.ProfileMetaSubTitle>{subtitle}</S.ProfileMetaSubTitle> : null}

            {metaExtra}
          </div>
        </S.ProfileMeta>
      </div>
      {footer ? <S.FooterWrapper>{footer}</S.FooterWrapper> : null}
    </S.ProfileCardHeader>
  );
};

import { Homepage } from '@/modules/founder/homepage/homepage';
import type { FC } from 'react';

type FounderStartPageProps = {
  Layout: FC<{ legacyContainer?: boolean }>;
};

export const FounderStartPage = ({ Layout }: FounderStartPageProps) => {
  return (
    <Layout legacyContainer={false}>
      <Homepage />
    </Layout>
  );
};

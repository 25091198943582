import message from '@/parts/message/message';
import { getServerError } from '@/utils/fns/get-server-error';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { removeConnection } from '../api/remove-connection.action';

export const useRemoveConnection = (connectionId: string, callback: () => void) => {
  const mutation = useMutation(removeConnection(connectionId), {
    onSuccess: () => {
      callback();
      message.success({ content: 'The connection has been removed' });
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  return {
    isRemoveConnectionLoading: mutation.isLoading,
    removeConnection: mutation.mutate,
    rawMutation: {
      ...mutation,
    },
  };
};

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  max-height: 722px;
  width: 100%;
  justify-content: space-between;
  flex-shrink: 1;
  padding: ${({ theme }) => theme.spacing.medium};
  border: 1px solid ${({ theme }) => theme.color.border};
  background: ${({ theme }) => theme.color.sectionBackground};
  border-radius: ${({ theme }) => theme.radius.default};
`;

const Inbox = styled.div`
  background: ${({ theme }) => theme.color.sectionBackground};
  width: 100%;
  flex-shrink: 1;
  border-bottom-left-radius: ${({ theme }) => theme.radius.default};
  border-top-left-radius: ${({ theme }) => theme.radius.default};
`;

export default { Wrapper, Inbox };

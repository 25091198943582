/* eslint-disable  @typescript-eslint/no-explicit-any */
import HiddenImageInput from '@pages/content/parts/hidden-file-input/hidden-file-input';
import { Avatar } from '@parts/avatar/avatar';
import { useMutation, type MutationFunction } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from '@utils/axios/types';
import { Spin } from 'antd';
import { useRef } from 'react';
import S from './avatar-uploader.styles';

export const AvatarUploader = ({
  uploadMutation,
  deleteMutation,
  deleteFile,
  onSuccess,
  currentImage,
  onError,
  name,
}: {
  uploadMutation: MutationFunction<AxiosResponse<any>, FormData>;
  deleteMutation?: MutationFunction<AxiosResponse<any>>;
  deleteFile?: () => void;
  onSuccess?: (data: AxiosResponse<any>, variables: FormData) => void | Promise<unknown>;
  currentImage?: string | null;
  name: string;
  onError?: (error: AxiosError, variables: FormData, snapshotValue: unknown) => void | Promise<unknown>;
}) => {
  const invisibleInputRef = useRef<HTMLInputElement>(null);

  const { mutateAsync: uploadPhoto, isLoading: isUploadPending } = useMutation(uploadMutation, {
    onSuccess,
    onError,
  });

  const { mutateAsync: deletePhotoMutation, isLoading: isDeletePending } = useMutation(deleteMutation!, {});

  const handlePhotoDelete = () => {
    if (deleteMutation) {
      deletePhotoMutation('delete-photo');
    }

    if (deleteFile) {
      deleteFile();
    }
  };

  const loader = (
    <S.SpinnerWrapper>
      <Spin size="large" />
    </S.SpinnerWrapper>
  );

  const image = (
    <>
      <HiddenImageInput submitFn={uploadPhoto} ref={invisibleInputRef} />
      <div data-testid="avatar-uploader-uploaded-image">
        <Avatar letter={name[0]} size={100} avatarUrl={currentImage} />
      </div>
      <S.ActionWrapper>
        <S.ActionButton type="link" onClick={() => invisibleInputRef.current?.click()}>
          Change
        </S.ActionButton>
        {currentImage && (
          <S.ActionButton onClick={handlePhotoDelete} type="link">
            Remove
          </S.ActionButton>
        )}
      </S.ActionWrapper>
    </>
  );

  return <S.ImageWrapper>{isUploadPending || isDeletePending ? loader : image}</S.ImageWrapper>;
};

import { V2Pagination } from '@/components/blocks/v2-pagination/v2-pagination';
import { V2Alert } from '@/components/ui/v2-alert/v2-alert';
import { V2Grid } from '@/components/ui/v2-grid/v2-grid';
import { V2Loader } from '@/components/ui/v2-loader/v2-loader';
import { V2TableStateInfo } from '@/components/ui/v2-table-state-info/v2-table-state-info';
import { useUrlFilters } from '@/hooks/use-url-filters';
import { TranslationText } from '@/parts/translation-text/translation-text';
import type { Any } from '@/types';
import { useQueryClient } from '@tanstack/react-query';
import { INVESTOR_ADVISOR_FOUNDER_INVITES_COUNT_QUERY_KEY } from '../../api/get-investor-advisor-invites-count';
import { useGetInvites } from '../../hooks/use-get-invites';
import type { InvestorAdvisorConnectionIntent } from '../../types';
import { InviteFilters } from './filters';
import { InviteCard } from './invite-card';

import S from '../../connections-page.styles';

const DEFAULT_INTENT_FILTER_VALUE = 'All';
const getValidatedConnectionIntent = (value: Any): InvestorAdvisorConnectionIntent | undefined => {
  const ALLOWED_CONNECTION_INTENTS: InvestorAdvisorConnectionIntent[] = ['Advising', 'Investing'];
  return ALLOWED_CONNECTION_INTENTS.includes(value) ? value : undefined;
};

export const Invites = () => {
  const queryClient = useQueryClient();
  const { filterValueSingle, apply } = useUrlFilters<'intent'>(['intent']);

  const { pagination, isInvitesDataLoading, invitesData, invitesError, refetch } = useGetInvites({
    intent: getValidatedConnectionIntent(filterValueSingle('intent')),
  });

  const handleRefetchData = () => {
    refetch();
    queryClient.invalidateQueries([INVESTOR_ADVISOR_FOUNDER_INVITES_COUNT_QUERY_KEY]);
  };

  if (isInvitesDataLoading) return <V2Loader height="320px" />;
  if (invitesError)
    return <V2Alert variant="error" title="Error loading data" description="Please try again later" showIcon />;

  return (
    <S.TabWrapper>
      <InviteFilters
        disabled={isInvitesDataLoading}
        filters={[
          { value: 'All', label: <TranslationText id="connections.investor-advisor.filters.both" /> },
          { value: 'Investing', label: <TranslationText id="connections.investor-advisor.filters.investing" /> },
          { value: 'Advising', label: <TranslationText id="connections.investor-advisor.filters.advising" /> },
        ]}
        activeFilter={[filterValueSingle('intent') || DEFAULT_INTENT_FILTER_VALUE]}
        onFilterChange={(v) => apply({ intent: v })}
        submit={(v) => apply({ intent: v.filters })}
      />

      {invitesData?.data?.items.length === 0 && (
        <V2TableStateInfo
          spaceTop
          showIcon
          title={<TranslationText id="connections.investor-advisor.table.invites.empty.title" />}
          description={<TranslationText id="connections.investor-advisor.table.invites.empty.description" />}
        />
      )}

      <V2Grid>
        {invitesData?.data?.items.map((connection) => (
          <InviteCard key={connection.connectionId} connection={connection} onActionSuccess={handleRefetchData} />
        ))}
      </V2Grid>

      <V2Pagination current={pagination.page} total={pagination.totalPages}>
        <V2Pagination.PreviousPageButton onClick={pagination.goToPreviousPage} />
        <V2Pagination.DisplayCurrentPageNumber />
        <V2Pagination.NextPageButton onClick={pagination.goToNextPage} />
      </V2Pagination>
    </S.TabWrapper>
  );
};

import type { RoleMutationType } from '@pages/content/roles/roles.types';
import { Table } from '@parts/table/table';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { ColumnsType } from 'antd/lib/table';
import type { FounderRoleResponse } from '../../../api/get-founder-roles.actions';
import { RolesTableHeading } from '../roles-table-heading/roles-table-heading';
import { Columns } from './columns';
import S from './roles.styles';
interface RolesTableProps {
  data: FounderRoleResponse[];
  onRowClick: (role: FounderRoleResponse, mutationType: RoleMutationType) => void;
  loading?: boolean;
}

export const RolesTable = ({ data, onRowClick, loading = false }: RolesTableProps) => {
  const {
    deviceData: { isDesktop },
  } = useDeviceDetect();

  const handleMutationAction = (role: FounderRoleResponse, mutationType: RoleMutationType) => () =>
    onRowClick(role, mutationType);

  const translations = useTranslation([
    'roles.founder.table.role',
    'roles.founder.table.applications',
    'roles.founder.table.views',
    'roles.founder.table.posted',
    'roles.founder.table.expires',
    'roles.founder.status.closed',
  ]);

  return (
    <>
      <RolesTableHeading />
      <S.TableContainer>
        <Table
          columns={
            Columns({
              translations,
              oneColumn: !isDesktop,
              onMutationAction: handleMutationAction,
            }) as ColumnsType<object>
          }
          rowKey="id"
          dataSource={[...data].reverse()}
          loading={loading}
        />
      </S.TableContainer>
    </>
  );
};

import { Can } from '@abilities';
import { AbilityAction } from '@abilities/ability/types';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { Redirect as RedirectBase, Route, type RouteProps } from 'react-router-dom';
import { Routes, getRouteConfigPath } from './routes';
import { getRedirectRoute } from './utils/get-redirect-route';

const Redirect = (routeConfigPath: Routes) => {
  const {
    state: { userRole, onboarding, countryOfResidence },
  } = useUserAccount();
  const redirectRoute = getRedirectRoute(routeConfigPath, userRole, onboarding, countryOfResidence);

  return <RedirectBase to={redirectRoute} />;
};

export const ConditionalRoute = (routerProps: RouteProps) => {
  const routeConfigPath = getRouteConfigPath(routerProps.path);

  return (
    <Can I={AbilityAction.ACCESS_PAGE} a={routeConfigPath!} Fallback={() => Redirect(routeConfigPath!)}>
      {<Route {...routerProps} />}
    </Can>
  );
};

import { Dropdown } from 'antd';
import type { DropDownProps } from 'antd/lib/dropdown';
import S from './dropdown-list.styles';

interface DropdownListProps extends DropDownProps {
  dropdownInvokerElement?: JSX.Element;
}

export const DropdownList = ({
  trigger,
  dropdownInvokerElement: icon = <S.DotsIcon />,
  ...rest
}: DropdownListProps) => (
  <Dropdown trigger={trigger || ['click']} {...rest} data-testid="dropdown-list">
    <S.DropdownIconWrapper>{icon}</S.DropdownIconWrapper>
  </Dropdown>
);

import { createContext, useContext } from 'react';
import type { CarouselActions } from './v2-carousel.types';

const V2CarouselContext = createContext<CarouselActions | null>(null);

export const useV2CarouselContext = () => {
  const context = useContext(V2CarouselContext);
  if (!context) throw new Error('V2Carousel.* component must be rendered as child of V2Carousel component');

  return context;
};

export { V2CarouselContext };

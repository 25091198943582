import { useScrollToElementWithQueryParams } from '@/utils/hooks/use-scroll-to-element/use-scroll-to-element-with-query-params';
import type { TeamMemberData } from '@pages/content/profile/founder/api/get-founder-team-members/get-founder-team-members.action';
import { Section } from '@parts/section/section';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { TeamMembersManager } from './team-members';

export const TeamMembersSection = ({ teamMembers }: { teamMembers: TeamMemberData[] }) => {
  const [headerLabel] = useTranslation(['profile.founder.section.teamMembers.header']);
  const scrollElementRef = useScrollToElementWithQueryParams<HTMLElement>('team-members');

  return (
    <Section header={headerLabel} innerRef={scrollElementRef}>
      <TeamMembersManager teamMembers={teamMembers} />
    </Section>
  );
};

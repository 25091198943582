import type { FunctionComponent, SVGProps } from 'react';
import S from './subpoint.styles';

export const Subpoint = ({
  title,
  description,
  Image,
  SecureLink,
}: {
  title: string;
  description: string;
  Image: FunctionComponent<SVGProps<SVGSVGElement>>;
  SecureLink?: JSX.Element;
}) => (
  <S.Wrapper>
    <Image />
    <S.Text>
      <S.Title>{title}</S.Title>
      <S.Description>
        {description} {SecureLink && SecureLink}
      </S.Description>
    </S.Text>
  </S.Wrapper>
);

export const removeObjectProperties = <T>(
  obj: Record<string | number, T>,
  keysToRemove: string[],
): Record<string | number, T> => {
  return Object.keys(obj).reduce((newObj: Record<string | number, T>, key: string) => {
    if (!keysToRemove.includes(key)) {
      newObj[key] = obj[key];
    }
    return newObj;
  }, {});
};

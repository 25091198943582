export const cleanObject = (obj: Record<string, unknown>): Record<string, unknown> => {
  return Object.keys(obj).reduce(
    (newObj, key) => {
      const value = obj[key];
      if (value !== null && value !== undefined && value !== '' && !(Array.isArray(value) && value.length === 0)) {
        newObj[key] = value;
      }
      return newObj;
    },
    {} as Record<string, unknown>,
  );
};

import { Roles } from '@domain/accounts/roles';
import { HalfWidthField } from '@parts/forms/formik-half-field/formik-half-field';
import { Select } from '@parts/select/select';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { type Dispatch, type SetStateAction, useState } from 'react';
import type { Any } from 'src/types';
import {
  getCreatedFundingRoundsAction,
  GET_CREATED_FUNDING_ROUNDS_CACHE_KEY,
} from '../api/get-registered-funding-rounds.action';
import { openFundingRoundDrawerByLink } from '../parts/funding-round/funding-round';
import S from './additional-info.styles';
import InvestmentConfirmationS from './investment-confirmation.styles';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { DateFormat } from '@i18n/date-formats';

export const AdditionalInfo = ({
  fundingRoundId,
  investingRelationshipId,
  setFundingRoundId,
  setEnableButton,
}: {
  fundingRoundId: string;
  investingRelationshipId: string;
  setFundingRoundId: Dispatch<SetStateAction<string>>;
  setEnableButton: Dispatch<SetStateAction<boolean>>;
}) => {
  const {
    state: { userRole },
  } = useUserAccount();

  const { dateFormatter } = useUserDateFormat();

  const [
    goToPulseLink,
    allFundingRoundsUsedLabel,
    noFundingRoundsLabel,
    noCorrectFundingRounds,
    selectPlaceholderLabel,
  ] = useTranslation([
    'investment.confirmation.founder.warningInfo.goToPulse',
    'investment.confirmation.founder.warningInfo.allFundingRoundsUsed',
    'investment.confirmation.founder.warningInfo.noFundingRounds',
    'investment.confirmation.founder.warningInfo.noCorrectFundingRounds',
    'investment.confirmation.founder.select.placeholder',
  ]);

  const [label, setLabel] = useState(selectPlaceholderLabel);

  const isLoggedInAsFounder = userRole === Roles.FOUNDER;

  const { data: response } = useQuery(
    [GET_CREATED_FUNDING_ROUNDS_CACHE_KEY(investingRelationshipId)],
    getCreatedFundingRoundsAction(investingRelationshipId, isLoggedInAsFounder),
  );

  const fundingRounds = response?.data;

  const freeFundingRound = fundingRounds?.filter((el) => !el.hasInvestment);
  const isAtLeastOneFreeFundingRound = Boolean(freeFundingRound?.length);

  const renderAlert = (msg: string) => (
    <InvestmentConfirmationS.Alert
      showIcon
      message={
        <span>
          {msg}
          <br />
          <S.Link href={openFundingRoundDrawerByLink}>{goToPulseLink}</S.Link>
        </span>
      }
      type="info"
    />
  );

  const DropdownFundingRound = () => (
    <S.Row>
      <HalfWidthField data-testid="funding_round">
        <Select
          data-testid="funding_round_select"
          labelInValue
          value={{
            value: fundingRoundId,
            label,
          }}
          onChange={({ value, label: fundingRoundLabel }: Any) => {
            setEnableButton(true);
            setFundingRoundId(value);
            setLabel(fundingRoundLabel);
          }}
        >
          {freeFundingRound?.map(({ id, date, type }) => (
            <Select.Option key={id} value={id} data-testid={`funding_round_select_select_option_${id}`}>
              {type} ({dateFormatter(date, DateFormat.FULL_MONTH_LONG_YEAR)})
            </Select.Option>
          ))}
        </Select>
      </HalfWidthField>
    </S.Row>
  );
  const renderAdditionalInfo = () => {
    if (fundingRounds?.length) {
      if (isAtLeastOneFreeFundingRound) {
        return (
          <>
            {renderAlert(noCorrectFundingRounds)}
            <DropdownFundingRound />
          </>
        );
      }
      return renderAlert(allFundingRoundsUsedLabel);
    }
    return renderAlert(noFundingRoundsLabel);
  };

  return renderAdditionalInfo();
};

import { type ReactNode } from 'react';
import { V2Icon } from '../v2-icon/v2-icon';
import { V2Modal } from '../v2-modal/v2-modal';
import type { MultilevelModalContentElements, V2ModalMultilevelProps } from './v2-modal-multilevel.types';

import S from './v2-modal-multilevel.styles';

const MultilevelModalTitle = ({
  activeKey,
  setActiveKey,
  titles,
}: {
  activeKey: string;
  titles: { key: string; title: ReactNode }[];
  setActiveKey: (key: string) => void;
}) => {
  const getTitle = (key: string) => {
    const found = titles.find((item) => item.key === key);
    return found ? found.title : null;
  };

  return (
    <S.TitleWrapper>
      {activeKey !== 'base' ? (
        <S.BackButton onClick={() => setActiveKey('base')} data-testid="v2-modal-multilevel-back-button">
          <V2Icon name="chevron-left" size={'md'} />
        </S.BackButton>
      ) : null}
      {getTitle(activeKey)}
    </S.TitleWrapper>
  );
};

const MultilevelModalContent = ({
  activeKey,
  content,
}: {
  activeKey: string;
  content: MultilevelModalContentElements;
}) => {
  const getSecondLevelBody = (key: string) => {
    const found = content.nested.find((item) => item.key === key);

    if (!found && key !== 'base') {
      // eslint-disable-next-line no-console
      console.error(`MultilevelModalContent: Second level content with key ${key} not found`);
    }

    return found ? found.body : null;
  };

  return (
    <S.MultilevelContentWrapper $activeKey={activeKey}>
      <S.MultilevelBaseContentWrapper $isActive={activeKey === 'base'}>
        {content.base.body}
      </S.MultilevelBaseContentWrapper>
      <S.MultilevelNestedContentWrapper $isActive={activeKey !== 'base'}>
        {getSecondLevelBody(activeKey)}
      </S.MultilevelNestedContentWrapper>
    </S.MultilevelContentWrapper>
  );
};

export const V2ModalMultilevel = ({
  isOpen: isModalOpen,
  onCancel: closeModal,
  setActiveKey: setContentKey,
  activeKey: contentKey,
  'data-testid': testId = 'v2-modal-multilevel',
  className,
  content,
}: V2ModalMultilevelProps) => {
  const titles = [{ ...content.base, key: 'base' }, ...content.nested].map(({ key, title }) => ({ key, title }));

  return (
    <V2Modal
      isOpen={isModalOpen}
      onCancel={closeModal}
      title={<MultilevelModalTitle activeKey={contentKey} titles={titles} setActiveKey={setContentKey} />}
      footer={null}
      size="lg"
      data-testid={testId}
      closable
      className={className}
    >
      <MultilevelModalContent activeKey={contentKey} content={content} />
    </V2Modal>
  );
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';
import type { Dashboard } from '../get-founder-dashboard/get-founder-dashboard.action';

export const getFoundersDashboardForViewerAction =
  (dashboardId: string, founderId: string) => async (): Promise<GraphqlResponse<Dashboard>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
      query getFoundersDashboardForViewer {
        foundersDashboardForViewer(dashboardId: "${dashboardId}", founderId: "${founderId}") {
          name,
          id,
          visibility,
          metrics {
            id,
            name,
            type,
            metricId,
            chartType,
            triggerSet
            isExternal
          }
          textBoxes {
            id
            title
            mainText
          }
          gridConfig {
            i,
            x,
            y,
            w,
            h,
          }
        }
      }
`,
    });

    return graphqlResponseWrapper(data, ['foundersDashboardForViewer']);
  };

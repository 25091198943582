import styled from 'styled-components';

const Wrapper = styled('div')<{ align: 'left' | 'center' | 'right'; maxWidth?: number }>`
  text-align: ${(props) => props.align};
  max-width: ${(props) => (props.maxWidth ?? 500) + 'px'};
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  gap: 6px;
`;

const Fraction = styled('span')`
  width: 100%;
  color: ${({ theme }) => theme.v2.colors.blue[200]};
  font-size: ${({ theme }) => theme.v2.typography.sizes.xs};
  @media (min-width: ${({ theme }) => theme.v2.breakpoints.sm}) {
    font-size: ${({ theme }) => theme.v2.typography.sizes.sm};
  }
  @media (min-width: ${({ theme }) => theme.v2.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.v2.typography.sizes.md};
  }
`;

const Title = styled('h1')`
  width: 100%;
  white-space: wrap;
  color: ${({ theme }) => {
    if (theme.v2.mode === 'dark') return theme.v2.colors.neutral[50];
    if (theme.v2.mode === 'light') return theme.v2.colors.blue[400];
  }};
  font-size: ${({ theme }) => theme.v2.typography.sizes.lg};
  @media (min-width: ${({ theme }) => theme.v2.breakpoints.sm}) {
    font-size: ${({ theme }) => theme.v2.typography.sizes.xl};
  }
  @media (min-width: ${({ theme }) => theme.v2.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.v2.typography.sizes['2xl']};
  }
  font-weight: ${({ theme }) => theme.v2.typography.weights.bold};
`;

const Subtitle = styled('p')`
  width: 100%;
  white-space: wrap;
  color: ${({ theme }) => {
    if (theme.v2.mode === 'dark') return theme.v2.colors.neutral[50];
    if (theme.v2.mode === 'light') return theme.v2.colors.neutral[400];
  }};
  font-size: ${({ theme }) => theme.v2.typography.sizes.xs};
  @media (min-width: ${({ theme }) => theme.v2.breakpoints.sm}) {
    font-size: ${({ theme }) => theme.v2.typography.sizes.sm};
  }
  @media (min-width: ${({ theme }) => theme.v2.breakpoints.lg}) {
    font-size: ${({ theme }) => theme.v2.typography.sizes.md};
  }
  font-weight: ${({ theme }) => theme.v2.typography.weights.regular};
`;

const IconWrapper = styled.div`
  padding: 12px;
  background: ${({ theme }) => theme.v2.gradients['light-blue-gradient']};
  border-radius: 3px;
  color: ${({ theme }) => theme.v2.colors.blue[400]};
  margin-bottom: 12px;
`;

export default { Wrapper, Fraction, Title, Subtitle, IconWrapper };

import { Section } from '@parts/section/section';
import { SubmitButton as SubmitButtonBase } from '@parts/submit-button/submit-button';
import styled from 'styled-components';

const Wrapper = styled(Section)`
  padding: ${({ theme }) => `0 ${theme.spacing.base}`};
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    flex-direction: column;
    padding: ${({ theme }) => `0 ${theme.spacing.medium} ${theme.spacing.medium}`};
  }
`;

const ConnectedWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${({ theme }) => `-${theme.spacing.base}`};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
  }
`;

const ConnectionLabel = styled.p`
  white-space: nowrap;
`;

const SubmitButton = styled(SubmitButtonBase)`
  border: 1px solid;
  align-items: center;
  display: flex;

  > span {
    margin-left: ${({ theme }) => theme.spacing.small};
  }

  &:hover {
    border: 1px solid;
  }
`;

export const CtaWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: ${({ theme }) => `${theme.spacing.medium} 0`};

  button:nth-of-type(2) {
    margin-left: 20px;
    &:disabled {
      border: none;
    }
  }
`;

const ProgressWrapper = styled.div`
  width: 100%;
  display: flex;
  max-width: 400px;
  justify-content: space-between;

  > div {
    width: 100%;
    margin-left: ${({ theme }) => theme.spacing.medium};
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    margin: ${({ theme }) => `${theme.spacing.medium}`};

    div {
      line-height: 14px;
    }
  }
`;

const ResendWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90px;
`;

const ProgressOnly = styled(ProgressWrapper)`
  margin: ${({ theme }) => `${theme.spacing.medium} 0`};
`;

const ConnectionRequest = styled.p`
  font-weight: 600;
  margin: ${({ theme }) => `${theme.spacing.medium} 0`};
  display: flex;
  align-items: center;
  > svg {
    margin-right: ${({ theme }) => theme.spacing.xsmall};
  }
`;

const CancelButton = styled(SubmitButton)`
  margin-left: ${({ theme }) => theme.spacing.small};

  > span {
    margin-left: 0;
  }
`;

const NotInterested = styled(SubmitButtonBase)`
  width: auto;
  margin: 0;
  padding-bottom: 0;
  padding-left: ${({ theme }) => theme.spacing.small};
  padding-top: 0;
  height: auto !important;
  background-color: transparent !important;
  border: none;

  &:hover {
    border: none;
  }
`;

export default {
  Wrapper,
  CtaWrapper,
  ProgressWrapper,
  ConnectionRequest,
  SubmitButton,
  ConnectedWrapper,
  ConnectionLabel,
  CancelButton,
  ProgressOnly,
  ResendWrapper,
  NotInterested,
};

import { V2AriaTable } from '@/components/blocks/v2-aria-table/v2-aria-table';
import { V2Dropdown } from '@/components/blocks/v2-dropdown/v2-dropdown';
import { V2IconButton } from '@/components/ui/v2-icon-button/v2-icon-button';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { useRemoveConnection } from '../../../hooks/use-remove-connection';
import type { FounderConnection } from '../../../types';

export const MoreActionsCell = ({ connectionId, refetch }: FounderConnection & { refetch: () => void }) => {
  const [removeLabelTranslation] = useTranslation(['connections.remove-connection']);
  const { isRemoveConnectionLoading, removeConnection } = useRemoveConnection(connectionId, refetch);

  return (
    <V2AriaTable.BodyCell align="center">
      <V2Dropdown
        overlay={
          <V2Dropdown.Menu>
            <V2Dropdown.Item onClick={removeConnection} disabled={isRemoveConnectionLoading}>
              {removeLabelTranslation}
            </V2Dropdown.Item>
          </V2Dropdown.Menu>
        }
        toggle={<V2IconButton name="kebab" size="lg" />}
      />
    </V2AriaTable.BodyCell>
  );
};

import max from 'lodash.max';

export type BannerHeight = number | null;

export const getGreaterHeight = (initialHeight: BannerHeight, bannersHeight: BannerHeight[]) => {
  const isHeightPresent = bannersHeight.some((el) => el !== null);
  if (isHeightPresent) {
    return max([...bannersHeight]) as number;
  }
  return initialHeight;
};

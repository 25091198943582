import {
  INVESTOR_ONBOARDING_STEP_AFFIRMATIONS_KEY,
  INVESTOR_ONBOARDING_STEP_BUSINESS_KEY,
  INVESTOR_ONBOARDING_STEP_INTRO_KEY,
  INVESTOR_ONBOARDING_STEP_INVESTMENT_KEY,
  INVESTOR_ONBOARDING_STEP_MATCH_KEY,
  INVESTOR_ONBOARDING_STEP_PERSONAL_KEY,
  INVESTOR_ONBOARDING_STEP_TRUSTED_SENDER_KEY,
  INVESTOR_ONBOARDING_STEP_TYPE_KEY,
  type InvestorOnboardingStep,
} from '@pages/content/onboarding/investor/paths';
import type { FunctionComponent } from 'react';
import { AffirmationsPageContent } from './content/affirmations/affirmations';
import { BusinessPageContent } from './content/business/business';
import { IntroPageContent } from './content/intro/intro';
import { InvestmentPageContent } from './content/investment/investment';
import { MatchPageContent } from './content/match/match';
import { PersonalInfoPageContent } from './content/personal-info/personal-info';
import { TrustedSenderPageContent } from './content/trusted-sender/trusted-sender';
import { UserTypePageContent } from './content/user-type/user-type';

export interface StepContentProps {
  currentPage: number;
  endPage: number;
}

export interface Step {
  key: InvestorOnboardingStep;
  Content: FunctionComponent<StepContentProps>;
  excludeFromCount?: boolean;
}

export const steps: Step[] = [
  { key: INVESTOR_ONBOARDING_STEP_TYPE_KEY, Content: UserTypePageContent, excludeFromCount: true },
  { key: INVESTOR_ONBOARDING_STEP_INTRO_KEY, Content: IntroPageContent, excludeFromCount: true },
  { key: INVESTOR_ONBOARDING_STEP_PERSONAL_KEY, Content: PersonalInfoPageContent },
  { key: INVESTOR_ONBOARDING_STEP_BUSINESS_KEY, Content: BusinessPageContent },
  { key: INVESTOR_ONBOARDING_STEP_INVESTMENT_KEY, Content: InvestmentPageContent },
  { key: INVESTOR_ONBOARDING_STEP_MATCH_KEY, Content: MatchPageContent },
  // { key: INVESTOR_ONBOARDING_STEP_COMMUNITY_KEY, Content: CommunityPageContent },  // Step hidden - until community goes live
  { key: INVESTOR_ONBOARDING_STEP_AFFIRMATIONS_KEY, Content: AffirmationsPageContent },
  { key: INVESTOR_ONBOARDING_STEP_TRUSTED_SENDER_KEY, Content: TrustedSenderPageContent },
];

import S from '../v2-carousel.styles';
import { useV2CarouselContext } from '../v2-carousel-context';
import type React from 'react';

export const ItemsWrapper = ({ children }: { children: React.ReactNode }) => {
  const { emblaRef } = useV2CarouselContext();

  return (
    <S.Viewport ref={emblaRef}>
      <S.Container>{children}</S.Container>
    </S.Viewport>
  );
};

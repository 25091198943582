import {
  SubscriptionProductType,
  getHighestSubscriptionProduct,
  type SubscriptionProduct,
} from '@domain/accounts/subscription-products';
import { isActiveFounder } from './is-active-founder';

export const canUpgradeFounderProduct = (products: SubscriptionProduct[]) => {
  const isActiveFounderValue = isActiveFounder(products);
  if (!isActiveFounderValue) return false;

  const upgradableProductTypes = [SubscriptionProductType.FOUNDER];
  const currentHighestProduct = getHighestSubscriptionProduct(products);
  const isUpgradable = upgradableProductTypes.includes(currentHighestProduct.type);

  return isUpgradable;
};

import { Textarea as TextareaBase } from '@pages/content/profile/parts/textarea/textarea';
import { FormikField as FormikFieldBase } from '@parts/forms/formik-field/formik-field';
import { Button, Row } from 'antd';
import styled from 'styled-components';
import { Input as InputBase } from '../input/input';

const Header = styled.p`
  color: ${({ theme }) => theme.color.fontPrimary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.regular};
`;

const Text = styled.p`
  color: ${({ theme }) => theme.color.fontSecondary};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSize.regular};
`;

const Input = styled(InputBase)`
  width: 80%;
`;

const ActionLink = styled(Button)`
  padding-left: 0;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.color.submitButton};
`;

const InputWithButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.medium};
`;

const FormikField = styled(FormikFieldBase)`
  flex-basis: 80%;
  margin-bottom: 0;

  input {
    width: 100%;
  }
`;

const Textarea = styled(TextareaBase)`
  margin-top: ${({ theme }) => theme.spacing.small};
  width: 100%;
`;

const TopMarginRow = styled(Row)`
  margin-top: ${({ theme }) => theme.spacing.medium};
`;

export default {
  Header,
  Text,
  Input,
  ActionLink,
  InputWithButtonWrapper,
  FormikField,
  Textarea,
  TopMarginRow,
};

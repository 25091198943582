import styled from 'styled-components';
import { Switch as SwitchBase } from '../switch/switch';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LabelHolder = styled.div`
  max-width: 250px;
`;

const Label = styled.span<{
  selected: boolean;
}>`
  display: none;
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${(props) => (props.selected ? props.theme.fontWeight.bold : props.theme.fontWeight.normal)};
`;

const LabelMobile = styled(Label)`
  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    display: block;
  }
`;

const LabelTablet = styled(Label)`
  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) and (min-width: ${({ theme }) =>
      theme.deviceBreakpoint.tablet}) {
    display: block;
  }
`;

const LabelDesktop = styled(Label)`
  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) {
    display: block;
  }
`;

const Switch = styled(SwitchBase)`
  ${LabelHolder} + & {
    margin-right: ${({ theme }) => theme.spacing.xsmall};
  }

  & + ${LabelHolder} {
    margin-left: ${({ theme }) => theme.spacing.xsmall};
  }
`;

export default { Label, Wrapper, Switch, LabelMobile, LabelHolder, LabelTablet, LabelDesktop };

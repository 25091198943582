import { V2AdvisorHomepageCard } from '@/components/blocks/v2-advisor-homepage-card/v2-advisor-homepage-card';
import { V2IconBox } from '@/components/ui/v2-icon-box/v2-icon-box';
import type { V2IconName } from '@/components/ui/v2-icon/icons';
import { V2Title } from '@/components/ui/v2-title/v2-title';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';

import S from './coming-soon.styles';

export const PreviewCardContent = ({ title, iconName }: { title: string; iconName: V2IconName }) => {
  return (
    <S.PreviewCardContent>
      <V2IconBox icon={iconName} size="md" />
      <V2Title size="lg" title={title} />
    </S.PreviewCardContent>
  );
};

export const ComingSoonCards = () => (
  <>
    <V2AdvisorHomepageCard disabled />
    <V2AdvisorHomepageCard disabled />
    <V2AdvisorHomepageCard disabled />
  </>
);

export const T2PComingSoonCards = () => {
  const [academyProgressLL, selectMentorLL, placementManagementLL] = useTranslation([
    'homepage.advisor.welcome-section.coming-soon.academy',
    'homepage.advisor.welcome-section.coming-soon.select-mentor',
    'homepage.advisor.welcome-section.coming-soon.placement-management',
  ]);

  return (
    <>
      <V2AdvisorHomepageCard disabled>
        <PreviewCardContent title={academyProgressLL} iconName="mortarboard" />
      </V2AdvisorHomepageCard>
      <V2AdvisorHomepageCard disabled>
        <PreviewCardContent title={selectMentorLL} iconName="person" />
      </V2AdvisorHomepageCard>
      <V2AdvisorHomepageCard disabled>
        <PreviewCardContent title={placementManagementLL} iconName="briefcase" />
      </V2AdvisorHomepageCard>
    </>
  );
};

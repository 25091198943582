import { V2WidgetCard } from '@/components/ui/v2-widget-card/v2-widget-card';
import type { WidgetCardProps } from '@/modules/founder/homepage/homepage';
import { Routes } from '@/router/routes';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { WidgetCardContent } from '../widget-card-content';

type DiscoverabilityCardProps = {
  isAllDone: boolean;
  ['data-testid']?: string;
};

export const DiscoverabilityCard = ({
  isAllDone,
  ['data-testid']: dataTestId = 'discoverability-card',
}: DiscoverabilityCardProps) => {
  const [
    profileTitle,
    profileTitleCompleted,
    profileDescription,
    profileDescriptionCompleted,
    linkText,
    allDoneLabel,
    notCompletedLabel,
  ] = useTranslation([
    'homepage.readinessScore.actionsWidget.discoverability.title',
    'homepage.readinessScore.actionsWidget.discoverability.title.completed',
    'homepage.readinessScore.actionsWidget.discoverability.description',
    'homepage.readinessScore.actionsWidget.discoverability.description.completed',
    'homepage.readinessScore.tile.fillNow',
    'homepage.readinessScore.tile.completed',
    'homepage.notCompletedLabel',
  ]);

  const widgetCardData: Omit<WidgetCardProps, 'onActionClick'> = {
    title: isAllDone ? profileTitleCompleted : profileTitle,
    subTitle: '',
    secondarySubTitle: isAllDone ? allDoneLabel : notCompletedLabel,
    description: isAllDone ? profileDescriptionCompleted : profileDescription,
    actionText: isAllDone ? '' : linkText,
    actionUrl: `${Routes.PROFILE}?st=smart-match`,
    variant: isAllDone ? 'neutral' : 'primary',
    icon: 'eye',
    ['data-testid']: dataTestId,
  };

  return (
    <V2WidgetCard>
      <WidgetCardContent {...widgetCardData} />
    </V2WidgetCard>
  );
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

interface GetInvestorInvitesCount {
  total: number;
}

export const GET_INVESTOR_INVITES_COUNT_CACHE_KEY = 'get-investor-invites-count';

export const getInvestorInvitesCount = async (): Promise<GraphqlResponse<GetInvestorInvitesCount>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getInvestorInvitesCount {
        getPendingFollowInvitations(start: 0, limit: 0) {
          total
      }
    }`,
  });

  return graphqlResponseWrapper(data, ['getPendingFollowInvitations']);
};

import type { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import type { ExternalMetric, InternalMetric } from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import { useFormik } from 'formik';

export const useMetricsConfigure = ({
  onConfigurationFinish,
  data,
  autoSave = false,
}: {
  onConfigurationFinish: Function;
  data: ExternalMetric[] | InternalMetric[];
  autoSave?: boolean;
}) => {
  const { setValues, values, submitForm, getFieldHelpers, getFieldProps } = useFormik({
    initialValues: data,
    onSubmit: (v) => {
      onConfigurationFinish(v);
    },
  });

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      setValues((items) => {
        const oldIndex = items.findIndex((m) => m.id === active.id);
        const newIndex = items.findIndex((m) => m.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });

      if (autoSave) submitForm();
    }
  };

  const handleVisibilityChange = () => {
    setValues((v: ExternalMetric[] | InternalMetric[]) => {
      if (autoSave) onConfigurationFinish(v);
      return v;
    });
  };

  return {
    setValues,
    values,
    submitForm,
    getFieldHelpers,
    getFieldProps,
    handleDragEnd,
    handleVisibilityChange,
  };
};

import React from 'react';
import type { V2CollapsibleProps } from './v2-collapsible.types';

export const V2Collapsible: React.FC<V2CollapsibleProps> = ({ visible, children, display = 'block', ...rest }) => {
  return (
    <div {...rest} style={{ ...rest.style, display: visible ? display : 'none' }}>
      {children}
    </div>
  );
};

import styled, { css } from 'styled-components';

const Wrapper = styled.div<{ $loading: boolean }>`
  position: relative;
  /* Override value of the ResponsiveGridLayout margin/gap */
  margin: 0 -20px;

  ${({ $loading }) =>
    $loading &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const Item = styled.div`
  transition: all 200ms ease-out;
`;

export default { Wrapper, Item };

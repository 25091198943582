import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const CardContent = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-template-rows: repeat(3, auto);
  grid-column-gap: 12px;
  grid-row-gap: 16px;
  height: 100%;

  & > :nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
  }
  & > :nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
    align-self: center;
  }
  & > :nth-child(3) {
    grid-area: 2 / 1 / 3 / 3;
  }
  & > :nth-child(4) {
    grid-area: 3 / 1 / 4 / 3;
  }
`;

const Title = styled.div`
  color: ${({ theme }) => theme.v2.colors.magenta[400]};
  font-weight: ${({ theme }) => theme.v2.typography.weights.bold};
  line-height: ${({ theme }) => theme.v2.typography.lineHeights.button};

  ${({ theme }) =>
    customMediaQuery('font-size', {
      xs: theme.v2.typography.sizes.lg,
      sm: theme.v2.typography.sizes.xl,
    })}
`;

const Paragraph = styled.div`
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[500], theme.v2.colors.neutral[300])};
  font-weight: ${({ theme }) => theme.v2.typography.weights.regular};
  font-size: ${({ theme }) => theme.v2.typography.sizes.sm};
  max-width: 100%;
  word-break: break-word;
  white-space: pre-wrap;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export default { CardContent, Title, Paragraph, Actions };

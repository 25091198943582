import { translations } from '@utils/i18n/messages';
import { useState, type ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { AppLocale, DEFAULT_LOCALE, LocaleContext } from './locale.context';

interface LocaleProviderProps {
  children: ReactNode;
}

export const LocaleProvider = ({ children }: LocaleProviderProps) => {
  const [locale, setLocale] = useState<AppLocale>(DEFAULT_LOCALE);

  return (
    <IntlProvider defaultLocale={DEFAULT_LOCALE} locale={locale} messages={translations[locale]}>
      <LocaleContext.Provider
        value={{
          defaultLocale: DEFAULT_LOCALE,
          locale,
          setLocale,
        }}
      >
        {children}
      </LocaleContext.Provider>
    </IntlProvider>
  );
};

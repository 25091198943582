import message from '@/parts/message/message';
import { getServerError } from '@/utils/fns/get-server-error';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { addNewExperience } from '../api/add-new-experience.action';
import { GET_EXPERIENCE_QUERY_KEY } from '../api/get-experience.action';

export const useAddNewExperience = (cb?: () => void) => {
  const [saved] = useTranslation(['global.saved']);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: addNewExperience,
    onSuccess: async () => {
      queryClient.refetchQueries([GET_EXPERIENCE_QUERY_KEY]);
      message.success({ content: saved });
      cb?.();
    },
    onError: async (error: AxiosError) => {
      message.error({ content: getServerError(error) });
    },
    cacheTime: 0,
    retry: 0,
  });

  return {
    isAddNewExperienceLoading: mutation.isLoading,
    isAddNewExperienceError: mutation.isError,
    addNewExperienceMutation: mutation.mutate,
    ogMutation: mutation,
  };
};

import chartBasicFreemium from '@assets/icons/freemium/chart-basic-freemium.svg';
import chartWithStarsFreemium from '@assets/icons/freemium/chart-with-stars-freemium.svg';
import targetFreemium from '@assets/icons/freemium/target-freemium.svg';
import { Routes } from '@router/routes';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';

export const useFreemiumModalData = () => {
  const [darkTitleLabel, blueTitleLabel, listLi1, listLi2, listLi3, upgrade] = useTranslation([
    'upgrade.freemium.modal.title.li1',
    'upgrade.freemium.modal.title.li2',
    'upgrade.freemium.modal.title.list.li1',
    'upgrade.freemium.modal.title.list.li2',
    'upgrade.freemium.modal.title.list.li3',
    'upgrade.freemium.modal.upgrade',
  ]);

  const freemiumList = [
    {
      text: listLi1,
      icon: chartWithStarsFreemium,
      alt: 'chart with stars icon',
    },
    {
      text: listLi2,
      icon: chartBasicFreemium,
      alt: 'chart icon',
    },
    {
      text: listLi3,
      icon: targetFreemium,
      alt: 'target icon',
    },
  ];

  const submitButtonProps = {
    text: upgrade,
    link: Routes.UPGRADE_PRODUCTS,
  };

  return { darkTitleLabel, blueTitleLabel, freemiumList, submitButtonProps };
};

import { GET_ADVISOR_PROFILE_READINESS } from '@/modules/advisor/homepage/api/get-advisor-profile-readiness.query';
import { GET_ADVISOR_PUBLISH_STATUS } from '@/modules/advisor/homepage/api/get-advisor-publish-status.query';
import { ExperienceSection } from '@/modules/advisor/profile/components/experience-section/experience-section';
import { setUserMetadata } from '@context/user/user-account.actions';
import { InvestorNedLayout } from '@layout/investor-ned/investor-ned.layout';
import {
  GET_INVESTOR_PUBLISH_STATUS_CACHE_KEY,
  getInvestorPublishStatus,
} from '@pages/content/api/get-investor-publish-status.action';
import { GET_NED_BROWSE_DEFAULTS_CACHE_KEY } from '@pages/content/lens/api/get-ned-browse-defaults.action';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import message from '@parts/message/message';
import { Routes } from '@router/routes';
import { multiRoleProfileInvestorKey, multiRoleProfileNedKey } from '@router/tabs-keys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import finishUpdate from '@utils/fns/finish-update';
import { generateUserName } from '@utils/fns/generate-user-name';
import { getServerError } from '@utils/fns/get-server-error';
import { useInvestorNedState } from '@utils/hooks/use-investor-ned-state/use-investor-ned-state';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { useUserCurrency } from '@utils/hooks/use-user-currency/use-user-currency';
import { useState } from 'react';
import type { Any } from 'src/types';
import { updateInvestmentsAction, type IInvestorInvestmentPayload } from '../investor/api/investments/investments';
import {
  INVESTOR_PROFILE_DATA_REQUEST,
  investorProfileDataAction,
} from '../investor/api/investor-profile/investor-profile';
import {
  updatePersonalDetailsAction as updateInvestorPersonalDetailsAction,
  type InvestorPersonalDetailsUpdatePayload,
} from '../investor/api/personal-details/personal-details';
import { InvestorType } from '../investor/api/types';
import { updateSmartMatchAction as updateInvestorSmartMatchAction } from '../investor/api/update-smart-match/update-smart-match';
import { Investment } from '../investor/parts/investment/investment';
import { SmartMatch } from '../investor/parts/smart-match/smart-match';
import {
  GET_NED_PUBLISH_STATUS_WITH_SHORTAGES_CACHE_KEY,
  getNedPublishStatusWithShortages,
} from '../ned/api/get-ned-publish-status/get-ned-publish-status.action';
import {
  updateAboutMeAction,
  updatePersonalDetailsAction as updateNedPersonalDetailsAction,
  updateSmartMatchAction as updateNedSmartMatchAction,
} from '../ned/api/ned-profile/ned-profile.action';
import { NED_PROFILE_DATA_QUERY_KEY, getNedProfileDataAction } from '../ned/api/ned-profile/ned-profile.query';
import type { INedAbout, INedSmartMatch } from '../ned/ned-profile.page';
import { AboutMe } from '../ned/parts/about-me/about-me';
import { SmartMatch as NedSmartMatch } from '../ned/parts/smart-match/smart-match';
import {
  GET_INVESTOR_NED_SELECT_OPTIONS,
  getInvestorNedSelectOptionsAction,
} from './api/get-investor-ned-select-options/get-investor-ned-select-options.action';
import type { IInvestorNedPersonalDetails } from './context/investor-ned.reducer';
import { PersonalDetails } from './parts/personal-details/personal-details';
import { PublicDataPage } from './parts/public-data.page';

import S from './investor-ned-profile.styles';

const handleApiError = (err: AxiosError) => {
  message.error({ content: getServerError(err) });
};

export const InvestorNedProfilePage = () => {
  const queryClient = useQueryClient();
  const [formUpdated, investorLabel, advisorLabel] = useTranslation([
    'messages.form.success',
    'profile.investorNed.section.tabs.investor',
    'profile.investorNed.section.tabs.advisor',
  ]);
  const { dispatch, state } = useUserAccount();
  const { currencySymbol } = useUserCurrency();
  const { state: investorNedState } = useInvestorNedState();

  const {
    data: selectOptionsResponse,
    isInitialLoading: selectOptionsResponseInitialLoading,
    refetch: refetchSelectOptionsResponse,
  } = useQuery([GET_INVESTOR_NED_SELECT_OPTIONS], getInvestorNedSelectOptionsAction, {
    cacheTime: 0,
    enabled: false,
  });

  const {
    data: responseNedPublishStatus,
    isInitialLoading: publishNedStatusInitialLoading,
    refetch: refetchNedPublishStatus,
  } = useQuery([GET_NED_PUBLISH_STATUS_WITH_SHORTAGES_CACHE_KEY], getNedPublishStatusWithShortages, { cacheTime: 0 });

  const {
    data: responsePublishStatus,
    isInitialLoading: publishStatusInitialLoading,
    refetch: refetchPublishStatus,
  } = useQuery([GET_INVESTOR_PUBLISH_STATUS_CACHE_KEY], getInvestorPublishStatus, {
    cacheTime: 0,
    enabled: false,
  });

  const {
    data: investorProfileData,
    isLoading: investorProfileDataLoading,
    isFetching: investorProfileDataFetching,
    refetch: refetchInvestorData,
  } = useQuery([INVESTOR_PROFILE_DATA_REQUEST], investorProfileDataAction, {
    enabled: false,
    cacheTime: 0,
  });

  const {
    data: nedProfileData,
    isLoading: nedProfileDataIsLoading,
    isFetching: nedProfileDataIsFetching,
    refetch: refetchNedData,
  } = useQuery([NED_PROFILE_DATA_QUERY_KEY], getNedProfileDataAction, {
    enabled: false,
    cacheTime: 0,
  });

  const { mutateAsync: updateNedPersonalDetails, isLoading: isUpdatePersonalDetailsPending } = useMutation(
    updateNedPersonalDetailsAction,
    { onError: handleApiError },
  );

  const { mutateAsync: updateInvestorPersonalDetails, isLoading: isUpdateInvestorPersonalDetailsPending } = useMutation(
    updateInvestorPersonalDetailsAction,
    { onError: handleApiError },
  );

  const { mutateAsync: updateAboutMe, isLoading: isUpdatingAboutMe } = useMutation(updateAboutMeAction, {
    onSuccess: () => {
      message.success({ content: formUpdated });
      refetchNedPublishStatus();
      refetchInvestorData();
      refetchNedData();
    },
    onError: handleApiError,
  });

  const { mutateAsync: updateNedSmartMatch } = useMutation(updateNedSmartMatchAction, {
    onSuccess: async () => {
      message.success({ content: formUpdated });
      refetchNedPublishStatus();
      refetchInvestorData();
      refetchNedData();

      // invalidate NedLensPage NedBrowseDefaults query cache
      await queryClient.invalidateQueries({ queryKey: [GET_NED_BROWSE_DEFAULTS_CACHE_KEY] });
    },
    onError: handleApiError,
  });
  const { mutateAsync: updateInvestments } = useMutation(updateInvestmentsAction, { onError: handleApiError });
  const { mutateAsync: updateInvestorSmartMatch } = useMutation(updateInvestorSmartMatchAction, {
    onSuccess: () => {
      message.success({ content: formUpdated });
      refetchPublishStatus();
      refetchInvestorData();
      refetchNedData();
    },
    onError: handleApiError,
  });

  const handleUpdateAboutMeSection = async ({ aboutMe, ...values }: INedAbout) => {
    await updateAboutMe({ ...investorNedState.about, ...values });
    message.success({ content: formUpdated });
  };

  type SubmitPersonalDetailsPayload = Omit<IInvestorNedPersonalDetails, 'investorType' | 'institutionType'> & {
    aboutMe: INedAbout['aboutMe'];
  };
  const submitPersonalDetails = async ({
    profilePhotoUrl,
    recentOccupation,
    companyName,
    aboutMe,
    gender,
    ...personalDetailsData
  }: SubmitPersonalDetailsPayload) => {
    const { about, personalDetails } = investorNedState;

    const actionsToPerform: Promise<Any>[] = [];

    const aboutMeChanged = aboutMe !== about.aboutMe;
    const investorDetailsChanged =
      personalDetails.recentOccupation !== recentOccupation ||
      personalDetails.companyName !== companyName ||
      personalDetails.gender !== gender;
    const nedDetailsChanged = Object.entries(personalDetailsData).some(
      ([key, value]) => value !== personalDetails[key as keyof typeof personalDetails],
    );

    if (aboutMeChanged) {
      actionsToPerform.push(updateAboutMe({ aboutMe }));
    }
    if (nedDetailsChanged) {
      const { countryOfResidence, ...rest } = personalDetailsData;
      actionsToPerform.push(updateNedPersonalDetails({ ...rest }));
    }
    if (investorDetailsChanged) {
      const { investorType } = personalDetails;
      const detailsPayload: Partial<InvestorPersonalDetailsUpdatePayload> = {};

      detailsPayload.gender = gender;

      if (investorType === InvestorType.Angel) {
        detailsPayload.recentOccupation = recentOccupation;
      }
      if (investorType === InvestorType.Institutional) {
        detailsPayload.companyName = companyName;
      }

      actionsToPerform.push(updateInvestorPersonalDetails(detailsPayload as InvestorPersonalDetailsUpdatePayload));
    }

    await Promise.all(actionsToPerform);

    dispatch(
      setUserMetadata({
        ...state,
        name: generateUserName(personalDetailsData.firstName, personalDetailsData.lastName),
        avatarUrl: profilePhotoUrl!,
      }),
    );

    message.success({ content: formUpdated });

    refetchInvestorData();
    refetchNedData();
    refetchPublishStatus();
    refetchNedPublishStatus();
    message.success({ content: formUpdated });
  };

  const submitNedSmartMatch = (startMatchData: INedSmartMatch) => {
    finishUpdate(updateNedSmartMatch(startMatchData), formUpdated);
  };

  const handleSubmitInvestment = (formState: IInvestorInvestmentPayload) => {
    const { investorType } = investorNedState.personalDetails;
    const isAngel = investorType === InvestorType.Angel;

    const payload = {
      ...formState,
      previousInvestmentsTable: isAngel ? formState.previousInvestmentsTable : null,
    };

    finishUpdate(updateInvestments(payload), formUpdated);
  };

  const handleRefetchPublishStatus = async () => {
    await Promise.all([
      refetchPublishStatus(),
      queryClient.invalidateQueries([GET_ADVISOR_PUBLISH_STATUS]),
      queryClient.invalidateQueries([GET_ADVISOR_PROFILE_READINESS]),
    ]);
  };

  const [isPublicPreview, setIsPublicPreview] = useState(false);

  const renderContent = () => {
    if (!selectOptionsResponse || !selectOptionsResponse.data) {
      refetchSelectOptionsResponse();
      return;
    }

    if (!responsePublishStatus || !responsePublishStatus.data) {
      refetchPublishStatus();
      return;
    }

    if (!responseNedPublishStatus?.data) {
      refetchNedPublishStatus();
      return;
    }

    if (isPublicPreview) {
      if (investorProfileDataLoading || nedProfileDataIsLoading) {
        return <FullHeightSpinner />;
      }

      if (!investorProfileData?.data) {
        refetchInvestorData();
        return null;
      }

      if (!nedProfileData?.data) {
        refetchNedData();
        return null;
      }

      return (
        <PublicDataPage
          id={state.userId!}
          investorProfileData={investorProfileData.data}
          nedProfileData={nedProfileData.data}
          investorLabel={investorLabel}
          nedLabel={advisorLabel}
          togglePubicView={() => {
            setIsPublicPreview((prev) => !prev);
          }}
        />
      );
    }

    const isBusy =
      nedProfileDataIsFetching ||
      investorProfileDataFetching ||
      isUpdatePersonalDetailsPending ||
      isUpdatingAboutMe ||
      isUpdateInvestorPersonalDetailsPending;

    return (
      <>
        <PersonalDetails
          personalDetails={investorNedState.personalDetails}
          handleSubmit={submitPersonalDetails}
          about={investorNedState.about}
          isBusy={isBusy}
          togglePubicView={() => {
            refetchInvestorData();
            refetchNedData();
            setIsPublicPreview((prev) => !prev);
          }}
        />
        <S.Tabs
          defaultLocator={multiRoleProfileInvestorKey}
          route={Routes.MULTI_ROLE_PROFILE}
          locator="role"
          tabs={[
            {
              key: multiRoleProfileInvestorKey,
              label: investorLabel,
              content: (
                <>
                  <SmartMatch
                    smartMatchData={investorNedState.investorSmartMatch}
                    handleSubmit={updateInvestorSmartMatch}
                    smartMatchOptions={{
                      industries: selectOptionsResponse?.data.industries,
                      productStages: selectOptionsResponse?.data.productStages,
                      investmentStages: selectOptionsResponse?.data.investmentStages,
                      customerGroups: selectOptionsResponse?.data.customerGroups,
                    }}
                    publishStatus={responsePublishStatus?.data!}
                    refetchPublishStatus={refetchPublishStatus}
                  />
                  <Investment
                    investorType={investorNedState.personalDetails.investorType}
                    investment={investorNedState.investment}
                    handleSubmit={handleSubmitInvestment}
                  />
                </>
              ),
            },
            {
              key: multiRoleProfileNedKey,
              label: advisorLabel,
              content: (
                <>
                  <AboutMe
                    includeFields={['myExpectations', 'myOffering']}
                    handleSubmit={handleUpdateAboutMeSection}
                    busy={isUpdatingAboutMe}
                    data={{
                      ...investorNedState.about,
                      aboutMe: null, // this field is handled in the personal details section
                    }}
                  />
                  <NedSmartMatch
                    currencySymbol={currencySymbol}
                    handleSubmit={submitNedSmartMatch}
                    smartMatchData={investorNedState.nedSmartMatch}
                    skillsOptions={selectOptionsResponse?.data?.skillSets}
                    industriesOptions={selectOptionsResponse?.data?.industries}
                    refetchPublishStatus={handleRefetchPublishStatus}
                    publishStatus={responseNedPublishStatus?.data!}
                  />
                  {/* TODO: remove div when moving to v2 */}
                  <div style={{ marginBottom: 20 }}>
                    <ExperienceSection />
                  </div>
                </>
              ),
            },
          ]}
        />
      </>
    );
  };

  return (
    <InvestorNedLayout>
      {selectOptionsResponseInitialLoading ||
      !investorNedState.investorDataLoaded ||
      !investorNedState.nedDataLoaded ||
      publishStatusInitialLoading ||
      publishNedStatusInitialLoading ? (
        <FullHeightSpinner />
      ) : (
        renderContent()
      )}
    </InvestorNedLayout>
  );
};

import type { Notification } from '@context/notifications/notifications.reducer';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_ALL_NOTIFICATIONS_CACHE_KEY = 'notifications/get-all-notifications';

export const getAllNotifications =
  (start: number, limit: number) => async (): Promise<GraphqlResponse<{ items: Notification[]; total: number }>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
    query getAllNotifications {
      notifications (start: ${start}, limit: ${limit}) {
        total
        items {
          id
          module
          type
          payload
          timestamp
          iconUrl
          read
          senderName
        }
      }
    }
        `,
    });

    return graphqlResponseWrapper(data, ['notifications']);
  };

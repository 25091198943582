import { Menu } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSize.regular};
  display: inline-block;
  color: ${({ theme }) => theme.color.fontPrimary};
`;

const Button = styled.button`
  font-size: ${({ theme }) => theme.fontSize.regular};
  color: ${({ theme }) => theme.color.metricVisibilityBtn};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  border: none;
  background: none;
  display: inline-block;
  display: flex;
  align-items: center;
  cursor: pointer;

  img:first-child {
    margin-right: 4px;
  }
`;

const MenuItem = styled(Menu.Item)<{ disabled?: boolean }>`
  min-width: 130px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2px;

  opacity: ${({ disabled }) => disabled && 0.3};
`;

const Option = styled.span`
  color: ${({ theme }) => theme.color.dropdownItem};
  margin-left: 4px;
`;

export default { Wrapper, Text, Button, MenuItem, Option };

import styled from 'styled-components';
import { Radio as RadioBase } from 'antd';

const RadioGroup = styled(RadioBase.Group)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing.xsmall};
  padding: ${({ theme }) => theme.spacing.xsmall} 0;

  .ant-radio-disabled {
    + span {
      color: inherit;
      opacity: 0.5;

      .ant-radio-input:checked + .ant-radio-inner {
        background-color: ${({ theme }) => theme.baseColors.Blue200};
      }
    }

    .ant-radio-inner {
      opacity: 0.5;
      border-color: ${({ theme }) => theme.baseColors.Blue200} !important;

      ::after {
        background-color: ${({ theme }) => theme.baseColors.Blue200};
      }
    }
  }
`;

const Radio = styled(RadioBase)`
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const Span = styled.span`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.color.fontPrimary};
  white-space: nowrap;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) {
    flex: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) {
    align-items: flex-start;
  }
`;

const ShimmerBadgeWrapper = styled.div`
  margin-left: ${({ theme }) => theme.spacing.xsmall};
`;

export default { RadioGroup, Radio, Span, Wrapper, ShimmerBadgeWrapper };

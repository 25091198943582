import { Roles } from '@domain/accounts/roles';
import { FounderLayout } from '@layout/founder/founder.layout';
import { InvestorNedLayout } from '@layout/investor-ned/investor-ned.layout';
import { NedLayout } from '@layout/ned/ned.layout';
import { NotFoundPage } from '@pages/utility/not-found/not-found.page';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { Legal } from './legal-base.page';

export const LegalPage = () => {
  const {
    state: { userRole },
  } = useUserAccount();

  switch (userRole) {
    case Roles.FOUNDER:
      return <Legal availableBlocking Layout={FounderLayout} />;
    case Roles.NED:
      return <Legal availableBlocking={false} Layout={NedLayout} />;
    case Roles.INVESTOR_NED:
      return <Legal availableBlocking={false} Layout={InvestorNedLayout} />;
    default:
      return <NotFoundPage />;
  }
};

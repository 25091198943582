import { useModal } from '@parts/modal/use-modal';
import OnTopFooter from '@parts/on-top-footer/on-top-footer';
import { Radio } from '@parts/radio/radio';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import S from './feedback-modal.styles';

export interface INotInterestedFeedback {
  reason?: string;
  tellUsMore?: string;
}

export const useFeedbackModal = (onSubmit: (v: INotInterestedFeedback) => void) => {
  const { Modal, open, show, hide } = useModal();
  const [
    title,
    textLine1,
    textLine2,
    reasonHeader,
    timingLabel,
    teamLabel,
    tooEarlyLabel,
    tooLateLabel,
    valuationLabel,
    geographyLabel,
    subIndustryLabel,
    currentlyIlliquidLabel,
    tellUsMore,
    tellUsMorePlaceholder,
    rejectionModalHeader,
    continueLabel,
    sendFeedback,
    cancelLabel,
  ] = useTranslation([
    'lens.investor.rejectionModal.description.title',
    'lens.investor.rejectionModal.description.text.line1',
    'lens.investor.rejectionModal.description.text.line2',
    'lens.investor.rejectionModal.reasons.header',
    'lens.investor.rejectionModal.reasons.timing',
    'lens.investor.rejectionModal.reasons.team',
    'lens.investor.rejectionModal.reasons.tooEarly',
    'lens.investor.rejectionModal.reasons.tooLate',
    'lens.investor.rejectionModal.reasons.valuation',
    'lens.investor.rejectionModal.reasons.geography',
    'lens.investor.rejectionModal.reasons.subIndustry',
    'lens.investor.rejectionModal.reasons.currentlyIlliquid',
    'lens.investor.rejectionModal.tellUsMore',
    'lens.investor.rejectionModal.tellUsMore.placeholder',
    'lens.investor.rejectionModal.header',
    'lens.investor.rejectionModal.continue',
    'lens.investor.rejectionModal.continueWitFeedback',
    'lens.investor.rejectionModal.cancelLabel',
  ]);

  const { getFieldProps, submitForm, values } = useFormik<INotInterestedFeedback>({
    initialValues: {},
    onSubmit,
    validateOnMount: true,
    validationSchema: Yup.object({
      reason: Yup.string(),
      tellUsMore: Yup.string(),
    }),
  });

  const provideFeedback = async () => {
    await submitForm();
  };

  const isFeedbackProvided = Boolean(values.reason || values.tellUsMore);

  const feedbackModalContent = (
    <Modal
      visible={open}
      closeModal={hide}
      title={rejectionModalHeader}
      closable
      footer={
        <OnTopFooter
          confirmLabel={isFeedbackProvided ? sendFeedback : continueLabel}
          cancelLabel={cancelLabel}
          onConfirm={provideFeedback}
          onCancel={hide}
          reverseButtons
          modal
        />
      }
    >
      <S.Header>{title}</S.Header>
      <S.Text>{textLine1}</S.Text>
      <S.Text>{textLine2}</S.Text>

      <S.Header>{reasonHeader}</S.Header>

      <Radio.Group {...getFieldProps('reason')}>
        <Radio value={timingLabel}>{timingLabel}</Radio>
        <Radio value={teamLabel}>{teamLabel}</Radio>
        <Radio value={tooEarlyLabel}>{tooEarlyLabel}</Radio>
        <Radio value={tooLateLabel}>{tooLateLabel}</Radio>
        <Radio value={valuationLabel}>{valuationLabel}</Radio>
        <Radio value={geographyLabel}>{geographyLabel}</Radio>
        <Radio value={subIndustryLabel}>{subIndustryLabel}</Radio>
        <Radio value={currentlyIlliquidLabel}>{currentlyIlliquidLabel}</Radio>
      </Radio.Group>
      <S.Header>{tellUsMore}</S.Header>

      <S.Textarea {...getFieldProps('tellUsMore')} rows={4} placeholder={tellUsMorePlaceholder} />
    </Modal>
  );

  return {
    hide,
    show,
    modal: feedbackModalContent,
  };
};

import EditRowIconBase from '@assets/icons/edit-icon.svg?react';
import RemoveRowIconBase from '@assets/icons/trash.svg?react';
import { Table as TableBase } from '@parts/table/table';
import styled, { css } from 'styled-components';

const IconsStyles = css`
  width: 25px;
  height: 19px;
  cursor: pointer;

  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
`;

const Table = styled(TableBase)`
  border-top: 1px solid ${({ theme }) => theme.color.tableBorder};

  .ant-table-thead {
    > tr {
      > th {
        color: ${({ theme }) => theme.color.tableHeader};
      }
    }
  }
  .ant-table-tbody {
    > tr {
      > td {
        vertical-align: top;
        font-size: ${({ theme }) => theme.fontSize.small};
        padding: 12px ${({ theme }) => theme.spacing.xsmall};
      }
    }
  }
  .ant-table-cell {
    span {
      color: ${({ theme }) => theme.color.fontPrimary};
    }
  }
`;

const EditRowIcon = styled(EditRowIconBase)`
  ${IconsStyles};
`;

const RemoveRowIcon = styled(RemoveRowIconBase)`
  ${IconsStyles};
  margin-left: 7px;
`;

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    justify-content: flex-start;
  }
`;

export default { Table, EditRowIcon, RemoveRowIcon, Controls };

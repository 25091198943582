import { Button, Dropdown as DropdownBase, Tabs as TabsBase } from 'antd';
import styled, { css } from 'styled-components';

const fontStyles = css`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.fontSize.regular} !important;
  font-weight: ${({ theme }) => theme.fontWeight.normal} !important;
`;

const MobileSelectedTab = styled.span`
  margin-left: ${({ theme }) => theme.spacing.xmedium};
  ${fontStyles}
  display: inline-block;
`;

const MobileButton = styled(Button)`
  ${fontStyles}
`;

const MobileAddBtn = styled(Button)`
  ${fontStyles}
  &.ant-btn:not(.ant-btn-sm) {
    height: auto;
  }

  > span {
    font-size: 30px;
    font-weight: ${({ theme }) => theme.fontWeight.light};
  }
`;

const ManageDataBtn = styled(Button)`
  color: ${({ theme }) => theme.color.fontBlue};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
`;

const MobileDropdownTabs = styled.div`
  display: flex;
  align-items: center;
`;

const MobileMenuPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    display: none;
  }
`;
const Tabs = styled(TabsBase)`
  .ant-tabs-nav-more {
    position: relative;
    bottom: 2px;
  }

  .ant-tabs-nav {
    border-top: 1px solid ${({ theme }) => theme.color.border};
    border-bottom: 1px solid ${({ theme }) => theme.color.border};

    .ant-tabs-ink-bar {
      visibility: visible;
    }

    &:before {
      border: none;
    }
  }

  .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-nav .ant-tabs-nav-add {
    background: transparent;
    border: none;
  }

  .ant-tabs-nav .ant-tabs-nav-add {
    margin: 10px 0;
  }

  .ant-tabs-nav-more,
  .ant-tabs-nav-add,
  .ant-tabs-tab-btn {
    ${fontStyles}
    white-space: nowrap;
    max-width: 150px;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 0 !important;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    .ant-tabs-nav {
      display: none;
    }
  }
`;

const Dropdown = styled(DropdownBase)`
  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    height: 30px;
  }
`;

export default {
  MobileSelectedTab,
  MobileButton,
  MobileAddBtn,
  Tabs,
  MobileDropdownTabs,
  MobileMenuPanel,
  ManageDataBtn,
  Dropdown,
};

import Modal from '@parts/modal/modal';
import { useModal } from '@parts/modal/use-modal';
import { OnTopFooterConnection } from './OnTopFooterConnection';
import S from './use-connection-modal.styles';

interface ModalProps {
  id: string;
  confirmLabel: string;
  cancelLabel: string;
  contentLabel: string;
  contentQuestionLabel: string;
  confirmTitleLabel: string;
  nameOfRecipient: string;
  refetch?: Function;
  open: boolean;
  hide: () => void;
}

const CustomModal: React.FC<ModalProps> = ({
  id,
  confirmLabel,
  cancelLabel,
  contentLabel,
  contentQuestionLabel,
  confirmTitleLabel,
  nameOfRecipient,
  refetch,
  open,
  hide,
}) => (
  <Modal
    visible={open}
    closeModal={hide}
    title={confirmTitleLabel}
    footer={
      <OnTopFooterConnection
        id={id}
        hide={hide}
        confirmLabel={confirmLabel}
        cancelLabel={cancelLabel}
        refetch={refetch}
      />
    }
  >
    {`${contentLabel} `}
    <S.ReciptianModalName>{nameOfRecipient}</S.ReciptianModalName>
    {` ${contentQuestionLabel}`}
  </Modal>
);

const useConnectionModal = (initialOpen = false) => {
  const { open, hide, show } = useModal(initialOpen);

  return {
    Modal: CustomModal,
    open,
    show,
    hide,
  };
};

export default useConnectionModal;

import { PHONE_NUMBER_REGEXP } from '@utils/fns/reg-exp-validators';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import * as Yup from 'yup';

const FOUNDER_NAME_MAX_LENGTH = 100;

export const firstNameValidation = (minValueLabel: string, requiredLabel: string) =>
  Yup.string().trim().min(2, minValueLabel).required(requiredLabel);

export const phoneNumberValidation = (validPhoneNumberLabel: string, requiredLabel: string) =>
  Yup.string()
    .required(requiredLabel)
    .test('customPhoneNumber', validPhoneNumberLabel, (value) => {
      if (!value) return true;
      const val = value.split(' ')[1];
      return val.match(PHONE_NUMBER_REGEXP) !== null;
    });

export const useValidationSchema = () => {
  const [fieldRequiredLabel, minLengthLabel, maxLengthLabel, validPhoneNumberLabel] = useTranslation([
    'formik.validation.required',
    'formik.validation.min',
    'formik.validation.max',
    'formik.validation.phoneNumber',
  ]);

  return Yup.object({
    businessName: Yup.string().trim().required(fieldRequiredLabel),
    representativeFirstName: firstNameValidation(minLengthLabel, fieldRequiredLabel).max(
      FOUNDER_NAME_MAX_LENGTH,
      maxLengthLabel,
    ),
    representativeLastName: Yup.string()
      .trim()
      .required(fieldRequiredLabel)
      .max(FOUNDER_NAME_MAX_LENGTH, maxLengthLabel),
    phoneNumber: phoneNumberValidation(validPhoneNumberLabel, fieldRequiredLabel),
    countryOfResidence: Yup.string().trim().required(fieldRequiredLabel),
    usState: Yup.string().when('countryOfResidence', {
      is: 'US',
      then: Yup.string().required(fieldRequiredLabel),
      otherwise: Yup.string().notRequired().nullable(),
    }),
  });
};

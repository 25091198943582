import { Avatar as AvatarBase } from '@parts/avatar/avatar';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  font-size: ${({ theme }) => theme.fontSize.medium};
  margin: ${({ theme }) => theme.spacing.medium} 0;
  padding-bottom: ${({ theme }) => theme.spacing.medium};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-direction: column;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) {
    padding-left: ${({ theme }) => theme.spacing.xsmall};
    padding-right: ${({ theme }) => theme.spacing.xsmall};
  }
`;

const Avatar = styled(AvatarBase)`
  margin-left: ${({ theme }) => theme.spacing.xsmall};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin: ${({ theme }) => theme.spacing.xsmall} 0;
  }
`;

const Name = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-left: ${({ theme }) => theme.spacing.xsmall};
`;

export default { Wrapper, Avatar, Name };

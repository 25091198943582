import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { type ReactNode, useState } from 'react';
import S from './aside-item.styles';

export const AsideItem = ({
  title,
  children,
  mobileTileTitle,
  dataTestId,
  gradientBorder = false,
  titleIcon = null,
}: {
  title: string;
  children: ReactNode;
  mobileTileTitle: string;
  dataTestId: string;
  gradientBorder?: boolean;
  titleIcon?: ReactNode;
}) => {
  const [fullScreen, setFullScreen] = useState(false);

  const MobileTile = () => (
    <S.MobileTile
      title={mobileTileTitle}
      onClick={() => {
        setFullScreen(true);
      }}
    />
  );

  const {
    deviceData: { isDesktop },
  } = useDeviceDetect();

  const biggerView = isDesktop || fullScreen;

  return biggerView ? (
    <S.Wrapper $fullScreen={fullScreen} data-testid={dataTestId} $gradientBorder={gradientBorder}>
      <S.HeadingWrapper>
        <S.Title>{title}</S.Title>
        {fullScreen ? <S.CloseIcon onClick={() => setFullScreen(false)} /> : null}
        {titleIcon}
      </S.HeadingWrapper>
      {children}
    </S.Wrapper>
  ) : (
    <MobileTile />
  );
};

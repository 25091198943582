import { CompensationType, type Compensation } from '@pages/content/profile/ned/ned-profile.page';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Columns, type RowData } from './columns';

import S from './table.style';

export const CompensationsTable = ({
  compensations,
  currencySymbol,
}: {
  compensations: Compensation[];
  currencySymbol: string;
}) => {
  const [paidDayRateLabel] = useTranslation(['profile.preview.ned.preferences.compensationDayRate']);

  const rowsData = (): RowData[] => {
    const result: RowData[] = [];

    const activeRoles = new Set(compensations.map((c) => c.role));
    for (const role of activeRoles) {
      const roleCompensations = compensations.filter((c) => c.role === role);

      const getRoleCompensation = (compensationType: CompensationType) =>
        roleCompensations.find((c) => c.compensationType === compensationType) ?? null;

      const roleCompensation = {
        role,
        [CompensationType.Blended]: getRoleCompensation(CompensationType.Blended),
        [CompensationType.Equity]: getRoleCompensation(CompensationType.Equity),
        [CompensationType.Paid]: getRoleCompensation(CompensationType.Paid),
      };

      result.push(roleCompensation as RowData);
    }

    return result;
  };

  return (
    <S.Table
      data-testid="compensations-table"
      columns={Columns(
        {
          columnRoleLabel: null,
          columnBlendedLabel: CompensationType.Blended,
          columnEquityLabel: CompensationType.Equity,
          columnPaidLabel: `${CompensationType.Paid} ${paidDayRateLabel}`,
        },
        currencySymbol,
      )}
      dataSource={rowsData()}
      stripped
    />
  );
};

import type { FounderPublicData } from '@pages/content/public-profile/founder/parts/overview/overview';
import { PublicPreviewNotification } from '../../../parts/public-preview-notification/public-preview-notification';
import S from './preview.styles';

export const Preview = ({ switchToEdit, data }: { switchToEdit: Function; data: FounderPublicData }) => (
  <>
    <PublicPreviewNotification onClick={() => switchToEdit()} />
    <S.Wrapper scrollToTop goBack={() => switchToEdit()} data={data} />
  </>
);

import { MetricType } from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import * as Yup from 'yup';

const NAME_MAX_LENGTH = 50;

export const visibilityValues = ['Private', 'Connections', 'CloseConnections', 'Community'];

export const nameValidation = (requiredLabel: string) =>
  Yup.string().required(requiredLabel).max(NAME_MAX_LENGTH).nullable();

export const useValidationSchema = () => {
  const [requiredLabel] = useTranslation(['formik.validation.required']);

  return Yup.object({
    name: nameValidation(requiredLabel),
    visibility: Yup.string().oneOf(visibilityValues).required(requiredLabel),
    metrics: Yup.array().of(
      Yup.object({
        id: Yup.string().uuid().required(),
        type: Yup.mixed<MetricType>().oneOf(Object.values(MetricType)).required(),
      }),
    ),
  });
};

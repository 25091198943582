import { axiosInstance } from '@utils/axios/axios-instance';
import type { Investment } from '../parts/editor/editor-interfaces';

export const updateInvestmentAction =
  (investmentId: string) => (payload: Investment & { investingRelationshipId: string }) => {
    const { investingRelationshipId, ...data } = payload;

    return axiosInstance.put(
      `/portfolio-management/investing-relationship/${investingRelationshipId}/investment/${investmentId}`,
      data,
    );
  };

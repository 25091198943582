import { FilterCompaniesSortOptions } from '@pages/content/lens/api/get-browse-companies-filters';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { SelectValue } from 'antd/lib/select';

import S from './sort-select.styles';

export const InvestorLensSortSelect = ({
  value,
  onChange,
  options,
  label = '',
}: {
  value?: string;
  onChange: (v: SelectValue) => void;
  options?: { label: string; value: string }[];
  label?: string;
}) => {
  const [sortSelectOptionDate, sortSelectOptionAZ, sortSelectOptionZA] = useTranslation([
    'lens.filters.sortSelect.options.date',
    'lens.filters.sortSelect.options.az',
    'lens.filters.sortSelect.options.za',
  ]);

  const optionsList: { label: string; value: string }[] = options ?? [
    { label: sortSelectOptionDate, value: FilterCompaniesSortOptions.Newest },
    { label: sortSelectOptionAZ, value: FilterCompaniesSortOptions.BusinessNameAsc },
    { label: sortSelectOptionZA, value: FilterCompaniesSortOptions.BusinessNameDesc },
  ];

  return (
    <S.SelectWrapper>
      {label}
      <S.Select
        value={value}
        defaultValue={FilterCompaniesSortOptions.Newest}
        onChange={onChange}
        bordered={false}
        suffixIcon={<S.CaretIcon />}
      >
        {optionsList.map((option) => (
          <S.Select.Option key={option.value} value={option.value} data-testid={`sort-select-${option.value}`}>
            {option.label}
          </S.Select.Option>
        ))}
      </S.Select>
    </S.SelectWrapper>
  );
};

import { V2Tabs as V2TabsBase } from '@/components/ui/v2-tabs/v2-tabs';
import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import styled from 'styled-components';

const StyledAttributesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 24px;
  font-size: ${({ theme }) => theme.v2.typography.sizes.xs};

  ${({ theme }) =>
    customMediaQuery('font-size', {
      sm: theme.v2.typography.sizes.sm,
    })}

  li {
    display: flex;
    margin-bottom: 8px;

    > span:first-child {
      width: 95px;
      max-width: 50%;
      flex-shrink: 0;
      font-weight: ${({ theme }) => theme.v2.typography.weights.bold};
      display: inline-block;
      margin-right: 12px;

      ${customMediaQuery('width', {
        sm: '150px',
      })}
    }
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  margin-bottom: 24px;

  a {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-right: 2px;
    gap: 4px;
  }
`;

const V2Tabs = styled(V2TabsBase)`
  max-height: 500px;
  overflow-y: auto;
`;

const CardSubtitle = styled.div`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  > a {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    font-size: inherit;
    color: inherit;

    &:hover,
    &:focus,
    &:active {
      color: inherit;
    }
  }
`;

export default {
  StyledAttributesList,
  LinkWrapper,
  V2Tabs,
  CardSubtitle,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ExpandableTable } from '@parts/expandable-table/expandable-table';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { RefObject } from 'react';
import type { InvestorPortfolioItem } from '../../api/get-investor-portfolio/get-investor-portfolio.action';
import { Columns } from './columns';
import { InvestmentsTable } from './parts/sub-table/sub-table';

export const Table = ({
  items,
  subTableRefs,
  entityId,
}: {
  entityId: string;
  items: InvestorPortfolioItem[];
  subTableRefs: {
    id: string;
    ref: RefObject<{ refetchInvestments: Function }>;
  }[];
}) => {
  const history = useHistory();

  const [companyLabel, totalInvestedLabel, totalCurrentValueLabel, detailsLabel, tooltipLabel] = useTranslation([
    'portfolio.investor.table.company',
    'portfolio.investor.table.totalInvested',
    'portfolio.investor.table.totalCurrentValue',
    'portfolio.investor.table.details',
    'portfolio.investor.investments.external.tooltip',
  ]);

  return (
    <ExpandableTable
      dataSource={items}
      columns={Columns({
        t: {
          companyLabel,
          totalInvestedLabel,
          totalCurrentValueLabel,
          detailsLabel,
        },
        history,
        tooltipLabel,
      })}
      rowKey={(row) => row.id}
      rowClassName={(row) => (row.hasUnarchivedInvestments ? '' : 'archived-investment')}
      expandedRowRender={(row) => (
        <InvestmentsTable
          ref={subTableRefs.find((r) => r.id === row.id || r.id === entityId)?.ref}
          investingRelationshipId={row.id}
        />
      )}
    />
  );
};

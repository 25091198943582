import { Table as TableBase } from '@parts/table/table';
import styled from 'styled-components';

const Wrapper = styled(TableBase)`
  width: 100%;
  padding: 0;
  padding-left: 60px;
  border-bottom: 0;

  tr:first-of-type {
    padding-top: 0;
  }

  .ant-table-expanded-row {
    background: transparent;
  }

  .ant-table {
    margin: 0 !important;

    .ant-table-container {
      min-height: 0;
      border-top: 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) {
    padding-left: 30px;
  }
`;

export default { Wrapper };

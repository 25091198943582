import { Element as ElementBase } from '@layout/parts/element/element';
import styled from 'styled-components';

const Element = styled(ElementBase)`
  padding: ${({ theme }) => theme.spacing.base};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    padding: ${({ theme }) => theme.spacing.medium};
  }
`;

export default { Element };

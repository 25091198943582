import styled from 'styled-components';

const Wrapper = styled.div<{ noBorder?: boolean }>`
  display: flex;
  padding: ${({ theme }) => theme.spacing.medium};
  align-items: center;
  border-top: 1px solid ${({ theme, noBorder }) => (noBorder ? 'transparent' : theme.color.border)};
`;

export default { Wrapper };

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_PULSE_COMPANIES_TOTAL_CACHE_KEY = 'get-investor-connected-companies-total';

export const getPulseCompaniesTotalAction = async (): Promise<
  GraphqlResponse<{
    total: number;
  }>
> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getPulseCompaniesTotal {
        companies: getFollowingCompanies(start: 0, limit: 1) {
          total
      }
    }`,
  });

  return graphqlResponseWrapper(data, ['companies']);
};

import DropdownIcon from '@assets/icons/dropdown-blue-tick.svg?react';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { Routes } from '@router/routes';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import queryString from 'query-string';
import { useEffect, useMemo, type ReactNode, type ReactText } from 'react';
import { useParams } from 'react-router-dom';
import S from './tabs.styles';

export interface ITabProps {
  key: string;
  label: ReactNode | string;
  content: ReactNode;
  disabled?: boolean;
}

interface ITabsProps {
  tabs: ITabProps[];
  route: Routes;
  locator: string;
  defaultLocator: string;
  persistQueryParamsOnTabChange?: boolean;
  destroyInactiveTabPane?: boolean;
  className?: string;
}

export const Tabs = ({
  tabs,
  route,
  locator,
  defaultLocator,
  destroyInactiveTabPane,
  className,
  persistQueryParamsOnTabChange = false,
}: ITabsProps) => {
  const { [locator]: activeKey } = useParams() as {
    [key: string]: string;
  };

  const query = useQueryParams();

  const history = useHistory();
  const activeTab = tabs.find((tab) => tab?.key === activeKey);

  const setActiveTab = (key: string) => {
    history.replace({
      ...(persistQueryParamsOnTabChange && { search: queryString.stringify(query) }),
      pathname: route.replace(`:${locator}`, key),
    });
  };

  useEffect(() => {
    if ((activeKey === `:${locator}` || !activeKey) && defaultLocator) {
      setActiveTab(defaultLocator);
    }

    if (!activeTab) {
      setActiveTab(defaultLocator);
    }
  }, [activeKey, defaultLocator]);

  const mobileMenuPositions = (
    <S.Menu
      onClick={(item: { key: ReactText }) => setActiveTab(item.key.toString())}
      selectedKeys={[activeTab?.key || '']}
    >
      {tabs.map((tab) => (
        <S.Menu.Item disabled={tab?.disabled} key={tab?.key}>
          {tab?.label}
        </S.Menu.Item>
      ))}
    </S.Menu>
  );
  const renderActiveTab = useMemo(
    () =>
      tabs.map((tab) => (
        <S.Tabs.TabPane disabled={tab?.disabled} tab={tab?.label} key={tab?.key}>
          {tab?.key === activeKey ? tab?.content : <></>}
        </S.Tabs.TabPane>
      )),
    [tabs],
  );
  return !activeTab ? (
    <FullHeightSpinner />
  ) : (
    <>
      <S.MobileDropdown overlay={mobileMenuPositions}>
        <S.MobileSelectedTab>
          {activeTab?.label} <DropdownIcon />
        </S.MobileSelectedTab>
      </S.MobileDropdown>
      <S.Tabs
        destroyInactiveTabPane={destroyInactiveTabPane}
        onChange={setActiveTab}
        activeKey={activeKey}
        defaultActiveKey={tabs[0]?.key}
        className={className}
      >
        {renderActiveTab}
      </S.Tabs>
    </>
  );
};

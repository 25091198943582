import { InvestorType } from '@pages/content/profile/investor/api/types';
import { maxWords } from '@pages/content/profile/utils/max-words';
import { LINKEDIN_URL_REGEXP } from '@utils/fns/reg-exp-validators';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import * as Yup from 'yup';

export const useValidationSchema = (investorType: InvestorType) => {
  const [minLength, maxLength, requiredMessage, validLinkedInUrl, occupationOrLinkedinUrlError, recentCompanyError] =
    useTranslation([
      'formik.validation.min',
      'formik.validation.max',
      'formik.validation.required',
      'formik.validation.linkedInUrl',
      'investor-onboarding.personal.occupationOrLinkedinUrlValidationError',
      'investor-onboarding.personal.recentCompanyError',
    ]);

  const validationSchema = Yup.object().shape(
    {
      firstName: Yup.string().trim().min(2, minLength).required(requiredMessage),
      lastName: Yup.string().trim().min(2, minLength),
      location: Yup.string().trim().min(2, minLength),
      aboutMe: maxWords(500, minLength, maxLength).nullable(),
      profilePicturePath: Yup.string().trim().nullable(),
      profilePictureUrl: Yup.string().trim().nullable(),
      // Institutional investor type specific fields
      ...(investorType === InvestorType.Institutional && {
        companyName: Yup.string().trim().min(2, minLength).max(50, maxLength).required(requiredMessage).nullable(),
        recentCompany: Yup.string().trim().nullable(),
        linkedinUrl: Yup.string().trim().matches(LINKEDIN_URL_REGEXP, validLinkedInUrl).nullable(),
      }),
      // Angel investor type specific fields
      ...(investorType === InvestorType.Angel && {
        recentOccupation: Yup.string()
          .trim()
          .max(50, maxLength)
          .nullable()
          .when('linkedinUrl', {
            is: (val: string | null | undefined) => !val || val.length === 0,
            then: Yup.string().trim().max(50, maxLength).nullable().required(occupationOrLinkedinUrlError),
          }),
        recentCompany: Yup.string()
          .trim()
          .nullable()
          .when('recentOccupation', {
            is: (val: string | null | undefined) => Boolean(val),
            then: Yup.string()
              .trim()
              .nullable()
              .required(recentCompanyError)
              // When recent occupation is not empty but linkedinUrl is
              .when('linkedinUrl', {
                is: (val: string | null | undefined) => !val || val.length === 0,
                then: Yup.string().required(recentCompanyError).nullable(),
                otherwise: Yup.string().required(recentCompanyError).nullable(),
              }),
            otherwise: Yup.string().trim().nullable(),
          })
          // When linkedin is empty but recentOccupation is not
          .when('linkedinUrl', {
            is: (val: string | null | undefined) => !val || val.length === 0,
            then: Yup.string()
              .trim()
              .max(50, maxLength)
              .nullable()
              .required(occupationOrLinkedinUrlError)
              .when('recentOccupation', {
                is: (val: string | null | undefined) => Boolean(val),
                then: Yup.string().required(recentCompanyError).nullable(),
              }),
          }),
        linkedinUrl: Yup.string()
          .trim()
          .matches(LINKEDIN_URL_REGEXP, validLinkedInUrl)
          .nullable()
          .when('recentOccupation', {
            is: (val: string | null | undefined) => !val || val.length === 0,
            then: Yup.string()
              .trim()
              .matches(LINKEDIN_URL_REGEXP, validLinkedInUrl)
              .nullable()
              .required(occupationOrLinkedinUrlError),
          }),
      }),
    },

    investorType === InvestorType.Angel ? [['recentOccupation', 'linkedinUrl']] : undefined,
  );

  return validationSchema;
};

import { gradients, type V2Gradients } from '@/styles/v2-theme/gradients';
import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled, { css } from 'styled-components';
import type { V2TabsGapSize } from './v2-tabs.types';

const BORDER_WIDTH = '2px';

const tabsGapSize = {
  none: '0',
  sm: '8px',
  md: '16px',
  lg: '24px',
};

const gradientBorder = (color: string, borderWidth: string = BORDER_WIDTH, borderRadius: string = '3px') => css`
  content: '';
  position: absolute;
  inset: 0;
  padding: ${borderWidth};
  background: ${color};
  border-radius: ${borderRadius};
  -webkit-mask:
    linear-gradient(transparent 0 0) content-box,
    linear-gradient(transparent 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
  z-index: 1;
`;

const Tabs = styled.div``;

const TabsHeaderWrapper = styled.div`
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

const TabsHeader = styled.div<{ $gaps: V2TabsGapSize; $gradientColor: V2Gradients; $lineWidth: string }>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ $gaps }) => tabsGapSize[$gaps] ?? tabsGapSize.sm};
  padding-bottom: ${BORDER_WIDTH};

  > button {
    flex-grow: 1;
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    min-width: ${({ $lineWidth }) => $lineWidth};
    height: ${BORDER_WIDTH};
    background: ${({ $gradientColor }) => gradients[$gradientColor]} border-box;
  }
`;

const TabsButton = styled.button<{ $active?: boolean; $gradientColor: V2Gradients }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 12px;
  background-color: transparent;
  border: 1px solid ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[100], theme.v2.colors.blue[500])};
  border-bottom: 0 none;
  border-radius: 4px 4px 0 0;
  font-size: ${({ theme }) => theme.v2.typography.sizes.sm};
  font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  width: 100%;

  // Tooltip
  > div:last-child {
    margin-left: 16px;
  }

  &:disabled {
    cursor: not-allowed;
    color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[400], theme.v2.colors.neutral[600])};

    // Tooltip
    > div:last-child {
      color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[400], theme.v2.colors.neutral[600])};

      svg {
        g,
        path {
          fill: ${({ theme }) =>
            themedStyle(theme.v2.mode, theme.v2.colors.neutral[400], theme.v2.colors.neutral[600])};
        }
      }
    }
  }

  ${({ theme, $active, $gradientColor }) =>
    $active &&
    css`
      background-color: ${themedStyle(theme.v2.mode, theme.v2.colors.neutral[50], theme.v2.colors.blue[800])};

      &::before {
        ${gradientBorder(gradients[$gradientColor], '2px', '4px 4px 0 0')}
        padding-bottom: 0;
      }

      &::after {
        content: '';
        position: absolute;
        display: block;
        bottom: -${BORDER_WIDTH};
        right: 2px;
        left: 2px;
        height: ${BORDER_WIDTH};
        background: ${themedStyle(theme.v2.mode, theme.v2.colors.neutral[50], theme.v2.colors.blue[800])};
        z-index: 1;
      }
    `}
`;

const TabsContent = styled.div``;

const TabsPane = styled.div`
  padding: 12px 0;
`;

export default { Tabs, TabsHeader, TabsButton, TabsPane, TabsContent, TabsHeaderWrapper };

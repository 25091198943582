import styled from 'styled-components';

const Wrapper = styled.div`
  width: 70px;
  height: 22px;
  padding: 3px;
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: 2px;
`;

const Text = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.color.fontSecondary};
  font-size: ${({ theme }) => theme.fontSize.xxsmall};
  text-transform: uppercase;
  text-align: center;
`;

export default { Wrapper, Text };

import styled from 'styled-components';

const Wrapper = styled.div`
  padding-left: ${({ theme }) => theme.spacing.small};
  border-left: 2px solid ${({ theme }) => theme.baseColors.Blue200};
  display: inline-block;
`;

const Label = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xxsmall};
  color: ${({ theme }) => theme.color.fontSecondary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  letter-spacing: 1.2px;
`;

const Value = styled.p`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  line-height: ${({ theme }) => theme.fontSize.medium};
  margin-top: ${({ theme }) => theme.spacing.xsmall};
`;

export default { Wrapper, Label, Value };

import { axiosInstance } from '@utils/axios/axios-instance';
import type { RequireOnlyOne } from 'src/types';
import type { ShareholderInvestment } from '../founder/parts/editor/editor-interfaces';

interface BasePayload {
  investments: ShareholderInvestment[];
  investorEmail: string;
  investorId: string;
}

export const createNewShareholderAction = (payload: RequireOnlyOne<BasePayload, 'investorEmail' | 'investorId'>) =>
  axiosInstance.post('/shareholders-management/shareholder', payload);

import { WelcomePageContent } from '@pages/content/onboarding/investor/content/welcome/welcome-page';
import { OnboardingLayout } from '@pages/content/onboarding/parts/layout/layout-default';
import { Links } from '@router/links';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useInvestorOnboardingContext } from '@utils/hooks/use-onboarding-context/use-investor-onboarding-context';
import { Redirect } from 'react-router-dom';
import { INVESTOR_ONBOARDING_STEP_INTRO_KEY, INVESTOR_ONBOARDING_STEP_TYPE_KEY } from '../paths';

export const InvestorOnboardingWelcomePage = () => {
  const { state: onboardingState } = useInvestorOnboardingContext();
  const history = useHistory();

  const goToNextStep = async () => {
    history.push(history.createHref({ pathname: Links.ONBOARDING_INVESTOR_STEP(INVESTOR_ONBOARDING_STEP_TYPE_KEY) }));
  };

  if (onboardingState.started) {
    return <Redirect to={Links.ONBOARDING_INVESTOR_STEP(INVESTOR_ONBOARDING_STEP_INTRO_KEY)} />;
  }

  return (
    <OnboardingLayout onNextClick={goToNextStep} lastPage={false} startingPage centerContent showFooter={false}>
      <WelcomePageContent />
    </OnboardingLayout>
  );
};

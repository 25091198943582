import { GET_READINESS_SCORE_CACHE_KEY } from '@/layout/founder/parts/readiness-score-tab/api/get-readiness-score.action';
import SignOut from '@assets/icons/sign-out.svg?react';
import type { MenuItem as Item } from '@layout/active/active.layout';
import { useDrawer } from '@parts/drawer/use-drawer';
import { ProfilePicture } from '@parts/profile-picture/profile-picture';
import { Links } from '@router/links';
import { Routes } from '@router/routes';
import { populateRoute } from '@router/utils/populate-route';
import { useQueryClient } from '@tanstack/react-query';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useLogout } from '@utils/hooks/use-logout/use-logout';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { Layout, Tooltip } from 'antd';
import { useState, type ReactNode } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Bell } from '../bell/bell';
import { type ItemWithIcon } from '../menu-dropdown/menu-dropdown';
import { ThemeSwitch } from '../theme-switch/theme-switch';
import S from './menu.styles';

interface MenuProps {
  locationPathName: string;
  menuItems: Item[];
  profileMenuItems: ItemWithIcon[];
  dropMenu?: ReactNode;
  notification: ReactNode;
  message: ReactNode;
  extraProfileMenuItem?: ReactNode;
}

enum MobileModes {
  AVATAR = 'avatar',
  HAMBURGER = 'hamburger',
}

interface MenuState {
  mode?: MobileModes;
  data: (Item | ItemWithIcon)[];
}

export const Menu = ({
  locationPathName,
  menuItems,
  dropMenu,
  profileMenuItems,
  notification,
  message,
  extraProfileMenuItem,
}: MenuProps) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const logoutLabel = useTranslation('ui.logout');

  const { Drawer, show, hide } = useDrawer();

  const params = useParams() as { [key: string]: string };

  const {
    state: { avatarUrl },
  } = useUserAccount();

  const initialState = {
    mode: undefined,
    data: [],
  };

  const [menuState, setMenuState] = useState<MenuState>(initialState);

  const { logout } = useLogout();

  const {
    deviceData: { isTablet, isMobile },
  } = useDeviceDetect();

  const avatarMode = menuState.mode === MobileModes.AVATAR;

  const expandAvatar = () => {
    show();
    setMenuState({ mode: MobileModes.AVATAR, data: profileMenuItems });
  };

  const expandHamburger = () => {
    show();
    setMenuState({ mode: MobileModes.HAMBURGER, data: menuItems });
  };

  const closeDrawer = () => {
    setMenuState(initialState);
    hide();
  };

  const getRouteProps = (item: ItemWithIcon) => {
    const populatedRoute = populateRoute(params, item);

    const onClickHandler = () => {
      if (item.isExternalLink) {
        window.location.href = item.route;
        return;
      }
      history.push(populatedRoute);
      hide();
    };

    return {
      route: populatedRoute,
      key: populatedRoute,
      onClick: onClickHandler,
      disabled: item.disabled,
    };
  };

  const [comingSoonLabel] = useTranslation(['navigation.comingSoon']);

  const showLabelWithTooltip = (item: ItemWithIcon) =>
    item.showTooltip && (
      <Tooltip title={comingSoonLabel} placement="bottom">
        {item.label}
      </Tooltip>
    );

  const alwaysVisibleItems = [
    { icon: message, route: Routes.MESSAGING, label: 'messaging' },
    { icon: notification, route: Routes.NOTIFICATIONS, label: 'notifications' },
  ];

  const Items = ({
    direction = 'horizontal',
    children,
  }: {
    direction?: 'horizontal' | 'vertical';
    children: ReactNode | ReactNode[];
  }) => (
    <S.Menu mode={direction} selectedKeys={[locationPathName]}>
      {children}
    </S.Menu>
  );

  const avatarDrawer = (
    <>
      <S.DrawerAvatar src={avatarUrl} height="80" width="80" />
      <Items direction="vertical">
        <S.DrawerItem key="toggleMode">
          <ThemeSwitch />
        </S.DrawerItem>
        {extraProfileMenuItem && <S.DrawerItem key="extra-item">{extraProfileMenuItem}</S.DrawerItem>}
        {menuState.data.map((item: ItemWithIcon) => {
          const { key, ...properties } = getRouteProps(item);
          return (
            <S.DrawerItem {...properties} key={key}>
              {item.icon}
              {item.label}
            </S.DrawerItem>
          );
        })}
        <S.DrawerItem key="logout" onClick={() => logout()}>
          <SignOut /> {logoutLabel}
        </S.DrawerItem>
      </Items>
    </>
  );

  const hamburgerDrawer = (
    <>
      <S.DrawerLogo>
        <Link to={Links.START_PAGE()}>
          <S.Logo />
        </Link>
      </S.DrawerLogo>
      <Items direction="vertical">
        {menuState.data.map((item: ItemWithIcon) => {
          const { key, ...properties } = getRouteProps(item);
          return (
            <S.DrawerItem {...properties} key={key}>
              {item.label}
            </S.DrawerItem>
          );
        })}
      </Items>
    </>
  );

  const hamburger = (
    <S.Hamburger>
      <input type="checkbox" onChange={expandHamburger} />
      <span />
      <span />
      <span />
    </S.Hamburger>
  );

  const mobileMenu = () => (
    <S.MobileHeader>
      <S.Content>
        {hamburger}
        <S.LogoSquare />
        <S.MobileRightSide>
          <Items>
            {alwaysVisibleItems.map((item: ItemWithIcon) => {
              const { key, ...properties } = getRouteProps(item);
              if (item.route === Routes.NOTIFICATIONS) {
                return (
                  <S.MobileMenuItem key={key} {...properties} onClick={undefined}>
                    <Bell showMenu />
                  </S.MobileMenuItem>
                );
              }
              return (
                <S.MobileMenuItem key={key} {...properties}>
                  {item.icon || item.label}
                </S.MobileMenuItem>
              );
            })}
          </Items>
          <S.MobileAvatar
            type="link"
            onClick={() => {
              expandAvatar();
              queryClient.invalidateQueries([GET_READINESS_SCORE_CACHE_KEY]);
            }}
          >
            <ProfilePicture src={avatarUrl} />
          </S.MobileAvatar>
        </S.MobileRightSide>
      </S.Content>

      <Drawer
        onClose={closeDrawer}
        onCancel={closeDrawer}
        width="80%"
        placement={avatarMode ? 'right' : 'left'}
        closable={false}
      >
        {avatarMode ? avatarDrawer : hamburgerDrawer}
      </Drawer>
    </S.MobileHeader>
  );

  const desktopMenu = () => (
    <Layout.Header>
      <S.Content>
        <Link to={Links.START_PAGE()}>
          <S.Logo />
        </Link>

        <S.MenuWrapper>
          <Items>
            {menuItems.concat(alwaysVisibleItems).map((item: ItemWithIcon) => {
              const { key, ...properties } = getRouteProps(item);
              if (item.route === Routes.NOTIFICATIONS) {
                return (
                  <S.OverlaidMenuItem key={key} {...properties} onClick={undefined}>
                    <Bell showMenu />
                  </S.OverlaidMenuItem>
                );
              }

              return (
                <S.MenuItem data-testid={`menuitem-${item.label}`} key={key} {...properties}>
                  {item.icon || showLabelWithTooltip(item) || item.label}
                </S.MenuItem>
              );
            })}
          </Items>
          {dropMenu && (
            <S.AvatarHolder
              data-testid="avatar"
              onClick={() => queryClient.invalidateQueries([GET_READINESS_SCORE_CACHE_KEY])}
            >
              {dropMenu}
            </S.AvatarHolder>
          )}
        </S.MenuWrapper>
      </S.Content>
    </Layout.Header>
  );

  return isTablet || isMobile ? mobileMenu() : desktopMenu();
};

import * as Yup from 'yup';

export const useValidationSchema = () =>
  Yup.object({
    namePhrase: Yup.string().nullable(),
    industry: Yup.array().of(Yup.string()).nullable(),
    customerGroup: Yup.array().of(Yup.string()).nullable(),
    productStage: Yup.array().of(Yup.string()).nullable(),
    investmentStage: Yup.array().of(Yup.string()).nullable(),
    metrics: Yup.array().of(
      Yup.object()
        .shape({
          name: Yup.string(),
          min: Yup.number(),
          max: Yup.number().nullable(),
          filteringMode: Yup.string(),
        })
        .nullable(),
    ),
    hasEisLimitsRemaining: Yup.boolean().nullable(),
    hasSeisLimitsRemaining: Yup.boolean().nullable(),
  });

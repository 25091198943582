import DOMPurify from 'dompurify';
import type { RawDraftContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import linkifyHtml from 'linkifyjs/html';

export const SanitizeWysiwyg = (content: RawDraftContentState) =>
  linkifyHtml(
    DOMPurify.sanitize(draftToHtml(content).replace(/<p><\/p>/g, '<br/>'), {
      ADD_ATTR: ['target'],
    }),
  );

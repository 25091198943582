import { TARGET_GEOGRAPHIES_OPTIONS, TargetGeographyValue } from '@constants/target-geography';
import { publishInvestorProfileAction } from '@pages/content/api/publish-investor-profile.action';
import {
  usePublishStatusEvaluation,
  type PublishStatus,
} from '@pages/content/profile/hooks/use-publish-status-evaluation';
import { unPublishProfileAction } from '@pages/content/profile/investor/api/unpublish-profile/unpublish-profile';
import { FormikField } from '@pages/content/profile/parts/formik-field/formik-field';
import { PublishSwitch } from '@pages/content/profile/parts/publish-switch/publish-switch';
import { MultiSelectSearch } from '@parts/multiselect-search/multiselect-search';
import { PillButtonCheckbox } from '@parts/pill-button-checkbox/pill-button-checkbox';
import { Section } from '@parts/section/section';
import { SubmitButton } from '@parts/submit-button/submit-button';
import { sortArrayAlphabetically } from '@utils/fns/sortArrayAlphabetically';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Col, Row } from 'antd';
import type { SelectValue } from 'antd/lib/select';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import type { IInvestorSmartMatch } from '../../api/types';

import { Tooltip } from '@parts/tooltip/tooltip';
import type { IInvestorSmartMatchPayload } from '../../api/update-smart-match/update-smart-match';

export interface IInvestorSmartMatchOptionsRequest {
  industries: string[];
  productStages: string[];
  investmentStages: string[];
  customerGroups: string[];
}

interface ISmartMatchProps {
  smartMatchData: IInvestorSmartMatch;
  handleSubmit: (formState: IInvestorSmartMatchPayload) => void;
  smartMatchOptions: IInvestorSmartMatchOptionsRequest;
  publishStatus: PublishStatus;
  refetchPublishStatus: () => void;
}

export const SmartMatch = ({
  smartMatchData,
  handleSubmit,
  smartMatchOptions,
  publishStatus,
  refetchPublishStatus,
}: ISmartMatchProps) => {
  const [
    header,
    subTitle,
    industries,
    selectPlaceholder,
    customerGroup,
    productStage,
    investmentStage,
    targetGeographiesLabel,
    targetGeographiesTooltipLabel,
    save,
    close,
    enable,
    disable,
    profilePublishHeader,
    profilePublishMessage,
    profileUnPublishMessage,
    profileShortagesMessage,
    profileShortagesHeader,
    publicLabel,
    targetGeographyRequiredErrorLabel,
    targetGeographyNotBothErrorLabel,
  ] = useTranslation([
    'profile.investor.section.smartMatch.header',
    'profile.investor.section.smartMatch.subtitle',
    'profile.investor.section.smartMatch.form.industry',
    'profile.investor.section.smartMatch.selectPlaceholder',
    'profile.investor.section.smartMatch.customerGroup',
    'profile.investor.section.smartMatch.productStage',
    'profile.investor.section.smartMatch.investmentStage',
    'profile.investor.section.smartMatch.targetGeographyLabel',
    'profile.investor.section.smartMatch.targetGeographyTooltip',
    'profile.investor.section.smartMatch.save',
    'profile.investor.section.smartMatch.close',
    'profile.investor.section.smartMatch.enable',
    'profile.investor.section.smartMatch.disable',
    'profile.investor.section.smartMatch.profileVisibility.header',
    'profile.investor.section.smartMatch.profileVisibility.publish',
    'profile.investor.section.smartMatch.profileVisibility.unPublish',
    'profile.investor.section.smartMatch.profileVisibility.shortages',
    'profile.investor.section.smartMatch.profileVisibility.shortages.header',
    'profile.smartMatch.investor.publicLabel',
    'profile.investor.section.smartMatch.targetGeography.validation.required',
    'profile.investor.section.smartMatch.targetGeography.validation.notBoth',
  ]);

  const {
    industries: possibleIndustries,
    customerGroups: possibleCustomerGroups,
    productStages: possibleProductStages,
    investmentStages: possibleInvestmentStage,
  } = smartMatchOptions;

  const {
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
    getFieldProps,
    submitForm,
    isValid,
    dirty,
    values,
    submitCount,
  } = useFormik<IInvestorSmartMatch>({
    initialValues: { ...smartMatchData },
    onSubmit: handleSubmit,
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: Yup.object({
      industries: Yup.array().of(Yup.string()),
      customerGroup: Yup.array().of(Yup.string()),
      productStage: Yup.array().of(Yup.string()),
      investmentStage: Yup.array().of(Yup.string()),
      targetGeography: Yup.array()
        .min(1, targetGeographyRequiredErrorLabel)
        .required()
        .test('global-or-others', targetGeographyNotBothErrorLabel, function (value) {
          return !(value!.includes(TargetGeographyValue.Global) && value!.length > 1);
        }),
    }),
  });

  const handleSelectChange = (selectKey: string) => (value: SelectValue) => {
    setFieldTouched(selectKey, true);
    setFieldValue(selectKey, value);
  };

  const { isPublic, publishModal, shortagesModal, showPublishModal, showShortagesModal } = usePublishStatusEvaluation({
    refetchPublishStatus,
    publishStatus,
    disable,
    enable,
    close,
    publishHeader: profilePublishHeader,
    unPublishMessage: profileUnPublishMessage,
    publishMessage: profilePublishMessage,
    shortagesHeader: profileShortagesHeader,
    shortagesMessage: profileShortagesMessage,
    unPublishAction: unPublishProfileAction,
    publishAction: publishInvestorProfileAction,
  });

  const isTargetGeographyChecked = (option: string): boolean =>
    Boolean(values.targetGeography.find((check) => option === check));

  const onTargetGeographyButtonClick = (item: string) => {
    if (isTargetGeographyChecked(item)) {
      const targetGeographiesAfterRemove = values.targetGeography.filter((target) => target !== item);

      return handleSelectChange('targetGeography')(targetGeographiesAfterRemove);
    }

    if (item === TargetGeographyValue.Global || values.targetGeography.includes(TargetGeographyValue.Global)) {
      return handleSelectChange('targetGeography')([item]);
    }

    handleSelectChange('targetGeography')([...values.targetGeography, item]);
  };

  return (
    <Section
      cornerAddition={{
        content: (
          <PublishSwitch
            isPublic={isPublic}
            onClick={publishStatus.smartMatchShortages.length === 0 ? showPublishModal : showShortagesModal}
            publicLabel={publicLabel}
          />
        ),
        width: 440,
      }}
      header={header}
      subTitle={subTitle}
      data-testid="founder-profile-start-match-form"
    >
      {publishModal}
      {shortagesModal}
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={16} xl={17}>
          <FormikField
            label={{
              for: 'industries',
              label: industries,
            }}
            error={errors.industries ? (errors.industries as string) : undefined}
            touched={touched.industries}
          >
            <MultiSelectSearch
              id="industries"
              placeholder={selectPlaceholder}
              {...getFieldProps('industries')}
              options={sortArrayAlphabetically(possibleIndustries)}
              onChange={handleSelectChange('industries')}
            />
          </FormikField>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={16} xl={17}>
          <FormikField
            label={{
              for: 'customerGroup',
              label: customerGroup,
            }}
            error={errors.customerGroup ? (errors.customerGroup as string) : undefined}
            touched={touched.customerGroup}
          >
            <MultiSelectSearch
              id="customerGroup"
              placeholder={selectPlaceholder}
              {...getFieldProps('customerGroup')}
              options={possibleCustomerGroups}
              onChange={handleSelectChange('customerGroup')}
            />
          </FormikField>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={16} xl={17}>
          <FormikField
            label={{
              for: 'productStage',
              label: productStage,
            }}
            touched={touched.productStage}
          >
            <MultiSelectSearch
              id="productStage"
              {...getFieldProps('productStage')}
              onChange={handleSelectChange('productStage')}
              placeholder={selectPlaceholder}
              options={possibleProductStages}
            />
          </FormikField>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={16} xl={17}>
          <FormikField
            label={{
              for: 'investmentStage',
              label: investmentStage,
            }}
            touched={touched.investmentStage}
          >
            <MultiSelectSearch
              id="investmentStage"
              {...getFieldProps('investmentStage')}
              onChange={handleSelectChange('investmentStage')}
              placeholder={selectPlaceholder}
              options={possibleInvestmentStage}
            />
          </FormikField>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} lg={14}>
          <FormikField
            label={{
              for: 'targetGeography',
              label: (
                <span>
                  {targetGeographiesLabel}
                  <Tooltip title={targetGeographiesTooltipLabel} />
                </span>
              ),
            }}
            touched={touched.targetGeography}
            error={errors.targetGeography}
          >
            {TARGET_GEOGRAPHIES_OPTIONS.map((item) => (
              <PillButtonCheckbox
                key={item}
                checked={isTargetGeographyChecked(item)}
                onChange={() => onTargetGeographyButtonClick(item)}
              >
                {item}
              </PillButtonCheckbox>
            ))}
          </FormikField>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={24} lg={16} xl={17}>
          <SubmitButton
            type="primary"
            htmlType="submit"
            form="founder-profile-form"
            onClick={submitForm}
            disabledEvaluation={{ isValid, dirty, submitCount }}
            data-testid="smart-match-submit"
          >
            {save}
          </SubmitButton>
        </Col>
      </Row>
    </Section>
  );
};

import { scrollToTop } from '@utils/fns/scroll-to-top';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useScrollToTopOnPathChange = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollToTop(0, 'auto');
  }, [pathname]);
};

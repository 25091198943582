import EditIconBase from '@assets/icons/edit-icon.svg?react';
import { Button as AntButton } from 'antd';
import styled, { css } from 'styled-components';
import { DashboardElementBase } from './dashboard-element';

const MetricIcons = styled.section<{ hoverEffectDisabled: boolean }>`
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
  display: flex;
  align-items: center;
  transition: opacity 0.3s;
  position: absolute;
  right: 15px;
  background: ${({ theme }) => theme.color.sectionBackground};
  box-shadow: ${({ theme }) => theme.color.sectionBackground} 0px 0px 10px 10px;

  ${({ hoverEffectDisabled }) =>
    !hoverEffectDisabled &&
    css`
      opacity: 0;
    `}

  .ant-btn:empty {
    display: none;
  }
`;

const BaseWrapper = styled(DashboardElementBase)`
  background-color: ${({ theme }) => theme.color.sectionBackground};
  border: 1px solid ${({ theme }) => theme.color.border};
  padding: ${({ theme }) => theme.spacing.small};
  border-radius: ${({ theme }) => theme.radius.default};
  box-shadow: none;
  min-height: 235px;
  height: 100%;
`;

const Wrapper = styled(BaseWrapper)`
  &.hovering {
    ${MetricIcons} {
      opacity: 1;
    }
  }
`;

const Header = styled.header`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  display: flex;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing.xmedium};
  overflow: hidden;

  .ant-btn:not(.ant-btn-sm) {
    height: fit-content;
    padding: 0;

    &:nth-of-type(2) {
      margin-left: 3px;
    }
  }
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.color.metricTitle};
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: default;
`;

const Button = styled(AntButton)`
  height: fit-content;
  padding: ${({ theme }) => theme.spacing.xsmall};

  &:hover {
    background: transparent;
  }
`;

const EditIcon = styled(EditIconBase)`
  width: 25px;

  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
`;

export default {
  Wrapper,
  Header,
  Title,
  Button,
  EditIcon,
  MetricIcons,
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_TEAM_MEMBER_ATTRIBUTE_OPTIONS_KEY = 'get-team-member-attribute-options';

export interface TeamMemberAttributeOptionsResponse {
  areas: string[];
  roles: string[];
}

export const getTeamMemberAttributeList = async (): Promise<GraphqlResponse<TeamMemberAttributeOptionsResponse>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
    query getTeamMemberAttributeList {
      teamMemberAttributes: founderTeamMemberAttributesList {
        areas
        roles
      }
    }
  `,
  });

  return graphqlResponseWrapper(data, ['teamMemberAttributes']);
};

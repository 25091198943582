import styled from 'styled-components';

const AsideBar = styled.aside`
  width: 100%;
  z-index: 1000;
  position: relative;
  margin-right: ${({ theme }) => theme.spacing.xbase};

  > *:not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing.medium};
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    padding-top: ${({ theme }) => theme.spacing.xsmall};
    margin-right: 0;
    display: flex;
    flex-direction: row-reverse;

    > * {
      flex: 1;
    }

    > *:not(:last-child) {
      margin-right: 0;
    }

    > *:not(:first-child) {
      margin-top: 0;
      margin-right: ${({ theme }) => theme.spacing.small};
    }
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
  }
`;

export default { AsideBar };

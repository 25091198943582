import { Button } from 'antd';
import styled from 'styled-components';

const SwitchToPublic = styled(Button)`
  text-align: right;
  color: ${({ theme }) => theme.color.primary};
  display: flex;
  align-items: center;

  > span {
    margin-left: 5px;
  }
`;

const SwitchToPublicWrapper = styled.div`
  float: right;
`;

export default { SwitchToPublic, SwitchToPublicWrapper };

import styled, { css } from 'styled-components';

const DefaultAvatar = styled.div<{ initial: string; size?: number; $pastelBackground: boolean }>`
  height: 100%;

  ${({ size }) =>
    size &&
    css`
      min-width: ${size}px;
      height: ${size}px;
    `}

  background-color: ${({ theme, $pastelBackground }) =>
    $pastelBackground ? theme.color.defaultAvatarPastel : theme.color.defaultAvatar};
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: '${(props) => props.initial}';
    color: ${({ theme, $pastelBackground }) =>
      $pastelBackground ? theme.color.avatarContentPastel : theme.color.avatarContent};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.fontSize.medium};
    ${({ size }) =>
      size &&
      css`
        font-size: calc(${size}px * 0.7);
      `}
  }
`;

export default { DefaultAvatar };

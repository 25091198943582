import { V2Button } from '@/components/ui/v2-button/v2-button';
import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  padding: 0;
`;

const Title = styled.h6`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
  text-align: center;
  background: ${({ theme }) => theme.v2.gradients['blue-gradient']};
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Description = styled.span`
  font-size: 14px;
  margin-top: -20px;
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[600], theme.v2.colors.neutral[100])};
  text-align: center;
  margin-bottom: 10px;
`;

const CancelButton = styled(V2Button)`
  text-decoration: none;
  background: ${({ theme }) => theme.v2.gradients['blue-gradient']};
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export default { Wrapper, Title, Description, CancelButton };

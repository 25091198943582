import { Links } from '@router/links';
import { Routes } from '@router/routes';
import { axiosInstance } from '@utils/axios/axios-instance';

const redirect = (location: string | Routes) => {
  setTimeout(() => {
    window.location.replace(location);
  }, 2000);
};

export const nedAcademyAccess = () =>
  axiosInstance
    .get('/ned-academy-access')
    .then((res) => {
      redirect(res.data);
    })
    .catch(() => {
      redirect(Links.NOT_FOUND());
    });

import { AsideItem } from '../aside/parts/aside-item/aside-item';
import * as S from './tips.styles';

const Tips = ({ title, content }: { title: string; content: string }) => (
  <AsideItem title={title} mobileTileTitle={title} dataTestId="tips" gradientBorder titleIcon={<S.BulbIcon />}>
    <S.WrapperWithTick>
      <S.Content dangerouslySetInnerHTML={{ __html: content }} />
    </S.WrapperWithTick>
  </AsideItem>
);

export default Tips;

import type { ReactNode } from 'react';
import S from './section.styles';

interface ISectionProps {
  children: ReactNode | ReactNode[];
  header?: string | ReactNode;
  subTitle?: string;
  cornerAddition?: {
    content: ReactNode | ReactNode[];
    width: number;
  };
  className?: string;
  dataTestId?: string;
  innerRef?: React.Ref<HTMLElement>;
}

export const Section = ({
  children,
  header,
  subTitle,
  className,
  cornerAddition = { content: null, width: 450 },
  dataTestId,
  innerRef = null,
}: ISectionProps) => (
  <S.Section ref={innerRef} className={className} data-testid={dataTestId}>
    {(header || subTitle || cornerAddition.content) && (
      <S.Header>
        {(header || subTitle) && (
          <S.TitleHolder>
            {header && <S.Title>{header}</S.Title>}
            {subTitle && <S.SubTitle>{subTitle}</S.SubTitle>}
          </S.TitleHolder>
        )}
        {cornerAddition.content && (
          <S.CornerAddition $width={cornerAddition.width} $spaced={Boolean(header) || Boolean(subTitle)}>
            {cornerAddition.content}
          </S.CornerAddition>
        )}
      </S.Header>
    )}
    {children}
  </S.Section>
);

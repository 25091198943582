import { Typography } from 'antd';
import styled from 'styled-components';

const DimmedMessage = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.color.fontPrimary};
  text-align: center;
  opacity: 0.5;
  margin-top: ${({ theme }) => theme.spacing.medium};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export default { DimmedMessage };

import { useQueryWithPagination } from '@/hooks/use-query-with-pagination';
import type { Sorter } from '@/utils/type-definitions/filters';
import { GET_NED_INVESTOR_FOUNDER_INVITES_KEY, getInvestorAdvisorInvites } from '../api/get-investor-advisor-invites';
import type { InvestorAdvisorConnectionIntent, InvestorAdvisorFounderInvites } from '../types';

const PER_PAGE = 8;

type GetInvitesQueryConfig = {
  intent: InvestorAdvisorConnectionIntent | undefined;
  sort?: Sorter;
};

export const useGetInvites = ({ intent, sort }: GetInvitesQueryConfig) => {
  const { data, error, isLoading, isFetching, isInitialLoading, pagination, ...restQueryResult } =
    useQueryWithPagination<InvestorAdvisorFounderInvites>(
      [GET_NED_INVESTOR_FOUNDER_INVITES_KEY, intent ?? '', sort?.key ?? '', sort?.order ?? ''],
      (page: number, perPage: number) => getInvestorAdvisorInvites({ page, perPage, intent }),
      { perPage: PER_PAGE, refetchOnWindowFocus: true },
    );

  return {
    ...restQueryResult,
    invitesData: data,
    invitesError: error,
    isInvitesDataLoading: isLoading,
    isInvitesDataFetching: isFetching,
    isInvitesInitialLoading: isInitialLoading,
    pagination,
  };
};

import { V2AriaTable } from '@/components/blocks/v2-aria-table/v2-aria-table';
import { V2TableStateInfo } from '@/components/ui/v2-table-state-info/v2-table-state-info';
import { V2Tooltip } from '@/components/ui/v2-tooltip/v2-tooltip';
import { TableRowCollapsible } from '@/modules/founder/connections/components/connections/table-parts/row-collapsible';
import { TranslationText } from '@/parts/translation-text/translation-text';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import type { Sorter } from '@/utils/type-definitions/filters';
import { useState, type Dispatch, type SetStateAction } from 'react';
import type { InvestorAdvisorFounderConnection } from '../../types';
import { ChatButtonCell } from './table-parts/chat-button-cell';
import { ConnectionIntentCell } from './table-parts/connection-intent-cell';
import { IndustryCell } from './table-parts/industry-cell';
import { InvestmentStageCell } from './table-parts/investment-stage-cell';
import { MoreActionsCell } from './table-parts/more-actions-cell';
import { NameAndDetailsCell } from './table-parts/name-and-details-cell';
import { NotesButtonCell } from './table-parts/notes-button-cell';
import { DateCell } from './table-parts/date-cell';

type ConnectionsTableProps = {
  data: InvestorAdvisorFounderConnection[];
  refetch: () => void;
  sorting?: Sorter;
  setSorting: Dispatch<SetStateAction<Sorter | undefined>>;
};

export const ConnectionsTable = ({ data, sorting, setSorting, refetch }: ConnectionsTableProps) => {
  const [nameLL, dateLL, stageLL, industryLL, connectionTypeLL, intentChangeButtonTooltipLL] = useTranslation([
    'connections.investor-advisor.table.name',
    'connections.investor-advisor.table.date',
    'connections.investor-advisor.table.stage',
    'connections.investor-advisor.table.industry',
    'connections.investor-advisor.table.connectionType',
    'connections.investor-advisor.intent-change-button-tooltip',
  ]);

  const [isVisibleMap, setIsVisibleMap] = useState(() => {
    const initialVisibility: Record<string, boolean> = {};
    data.forEach((row) => (initialVisibility[row.connectionId] = false));
    return initialVisibility;
  });

  const toggleVisibility = (id: string) => setIsVisibleMap((prev) => ({ ...prev, [id]: !prev[id] }));

  const handleSort = (id: string) => {
    setSorting((prevSorting) => {
      if (!prevSorting || prevSorting.key !== id) return { key: id, order: 'Asc' };
      else if (prevSorting.order === 'Asc') return { key: id, order: 'Desc' };
      else return undefined;
    });
  };

  return (
    <V2AriaTable
      sorting={sorting}
      gridTemplateColumns="0.27fr 0.15fr 0.21fr 0.22fr 0.14fr 0.16fr 0.16fr 0.05fr"
      head={
        <V2AriaTable.HeadRow>
          <V2AriaTable.HeadCell id="businessName" onSort={() => handleSort('businessName')}>
            {nameLL}
          </V2AriaTable.HeadCell>
          <V2AriaTable.HeadCell id="date">{dateLL}</V2AriaTable.HeadCell>
          <V2AriaTable.HeadCell id="stage">{stageLL}</V2AriaTable.HeadCell>
          <V2AriaTable.HeadCell id="industry">{industryLL}</V2AriaTable.HeadCell>
          <V2AriaTable.HeadCell id="connectionType">
            <V2Tooltip tooltipText={intentChangeButtonTooltipLL}>{connectionTypeLL}</V2Tooltip>
          </V2AriaTable.HeadCell>
          <V2AriaTable.HeadCell id="notes" />
          <V2AriaTable.HeadCell id="chat" />
          <V2AriaTable.HeadCell id="more" />
        </V2AriaTable.HeadRow>
      }
      body={
        data.length === 0 ? (
          <V2AriaTable.BodyRow key={'empty'} customGridTemplateColumns="1fr">
            <V2TableStateInfo
              spaceTop
              showIcon
              title={<TranslationText id="connections.investor-advisor.table.connections.empty.title" />}
              description={<TranslationText id="connections.investor-advisor.table.connections.empty.description" />}
            />
          </V2AriaTable.BodyRow>
        ) : (
          data.map((row, index) => (
            <V2AriaTable.RowWrapper
              key={row.connectionId}
              rowContent={
                <V2AriaTable.BodyRow key={row.connectionId}>
                  <NameAndDetailsCell {...row} />
                  <DateCell {...row} />
                  <InvestmentStageCell {...row} />
                  <IndustryCell {...row} />
                  <ConnectionIntentCell {...row} onUpdated={refetch} />
                  <NotesButtonCell
                    {...row}
                    toggleVisibility={toggleVisibility}
                    data-testid={`investor-advisor-connection-open-notes-${index}`}
                  />
                  <ChatButtonCell {...row} />
                  <MoreActionsCell {...row} refetch={refetch} />
                </V2AriaTable.BodyRow>
              }
              collapsible={
                <TableRowCollapsible
                  onUpdated={refetch}
                  targetId={row.founderId}
                  isVisible={isVisibleMap[row.connectionId]}
                />
              }
            />
          ))
        )
      }
    />
  );
};

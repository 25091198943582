import { MetricType } from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import { deleteMetricAction } from '@pages/content/pulse/parts/dashboards/api/delete-metric/delete-metric.action';
import { updateMetricChartType } from '@pages/content/pulse/parts/dashboards/api/update-metric-chart-type/update-metric-chart-type';
import message from '@parts/message/message';
import { ChartTimePeriod } from '@parts/periods/periods';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useEffect, useRef, useState } from 'react';
import DES from '../../../dashboard-element/dashboard-element.styles';
import S from './metric-item.styles';
import { BooleanMetric } from './parts/boolean-metric/boolean-metric';
import { Size } from './parts/change-size/change-size';
import { ChartMetric } from './parts/chart-metric/chart-metric';
import { ChartSize, ChartViewType } from './parts/chart-metric/parts/chart-view-dropdown/chart-view-dropdown';

export const MetricItem = ({
  id,
  metricId,
  title,
  type,
  allowSetNotifications = false,
  triggerSet = false,
  followMode,
  reportMode,
  selectedChartType,
  selectedPeriod,
  dashboardId,
  dashboardDetailsQueryToInvalidate,
  onSizeChange,
  isExternal,
  businessName,
}: {
  id: string;
  metricId: string;
  title: string;
  type: MetricType;
  allowSetNotifications?: boolean;
  followMode: boolean;
  reportMode: boolean;
  selectedChartType: ChartViewType;
  selectedPeriod: ChartTimePeriod;
  dashboardId: string;
  triggerSet?: boolean;
  dashboardDetailsQueryToInvalidate?: string[];
  onSizeChange?: (size: Size, metricId: string, preventSmall?: boolean, mediumHeight?: boolean) => void;
  isExternal: boolean;
  businessName?: string;
}) => {
  const [open, setOpen] = useState(false);
  const [timePeriod, setTimePeriod] = useState(selectedPeriod);
  const [chartType, setChartType] = useState<ChartViewType>(selectedChartType);

  const zoomedChartRef = useRef<HTMLDivElement>(null);
  const zoomedDivRef = useRef<HTMLDivElement>(null);
  const draggableDivRef = useRef<HTMLElement | null>(null);

  const queryClient = useQueryClient();
  const {
    deviceData: { isMobile, isDesktop },
  } = useDeviceDetect();
  const isZooming = !isMobile && open;

  const [successfullyDeletedMetric] = useTranslation(['pulse.chart.delete.modal.success']);

  const { mutateAsync: deleteMetric } = useMutation(deleteMetricAction, {
    onSuccess: async () => {
      message.success({
        content: successfullyDeletedMetric,
      });
      await queryClient.invalidateQueries(dashboardDetailsQueryToInvalidate);
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  const { mutateAsync: configure } = useMutation(updateMetricChartType, {
    onSuccess: async (_cos, vars) => {
      await queryClient.invalidateQueries(dashboardDetailsQueryToInvalidate);
      setChartType(vars.chartType);
    },
  });

  const updateMetricChartTypeAction = (chartTypeArgument: ChartViewType) => {
    if (dashboardId && id) {
      configure({ dashboardId, id, chartType: chartTypeArgument });
    }
  };

  useEffect(() => {
    if (isZooming && zoomedDivRef.current?.parentElement) {
      draggableDivRef.current = zoomedDivRef.current.parentElement;
      draggableDivRef.current.classList.add('transform-none');
    }
    return () => {
      if (draggableDivRef.current) {
        draggableDivRef.current.classList.remove('transform-none');
      }
    };
  }, [isZooming]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleClick = (event: any) => {
      if (zoomedChartRef.current && !zoomedChartRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('click', handleClick, true);

    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [zoomedChartRef]);

  const metricProps = {
    metricId,
    name: title,
    triggerSet,
    followMode,
    reportMode,
    type,
    isDesktop,
    allowSetNotifications,
    dashboardId,
    onSizeChange: (size: Size, preventSmall?: boolean, mediumHeight?: boolean) => {
      if (onSizeChange && id) onSizeChange(size, id, preventSmall, mediumHeight);
    },
    isExternal,
  };

  const chartMetricProps = {
    isMobile,
    chartType,
    timePeriod,
    setChartType: (t: ChartViewType) => (reportMode || followMode ? setChartType(t) : updateMetricChartTypeAction(t)),
    setTimePeriod: (p: ChartTimePeriod) => setTimePeriod(p),
  };

  const wrapperProps = {
    layoutId: 'expandable-item',
    layout: true,
    itemID: metricId,
  };

  return (
    <S.FullscreenWrapper ref={zoomedDivRef} isZooming={open}>
      {isZooming ? (
        <S.ZoomedWrapper ref={zoomedChartRef} isTable={chartType === ChartViewType.TABLE} {...wrapperProps}>
          <S.ZoomedChartMetric
            onRemove={() => deleteMetric({ id, dashboardId })}
            {...metricProps}
            {...chartMetricProps}
            onZoomOut={() => setOpen(false)}
            isZoomed={open}
          />
        </S.ZoomedWrapper>
      ) : (
        <DES.Wrapper data-testid="metrics" {...wrapperProps}>
          {type === MetricType.Boolean ? (
            <BooleanMetric onRemove={() => deleteMetric({ id, dashboardId })} {...metricProps} />
          ) : (
            <ChartMetric
              businessName={businessName}
              size={ChartSize.SMALL}
              onZoomIn={() => setOpen(true)}
              onRemove={() => deleteMetric({ id, dashboardId })}
              {...{ ...metricProps, ...chartMetricProps }}
            />
          )}
        </DES.Wrapper>
      )}
    </S.FullscreenWrapper>
  );
};

import type { MarketFitQuestionItem } from '@/pages/content/profile/founder/api/get-market-fit-questions/get-market-fit-questions.query';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { PageHeader } from '@parts/page-header/page-header';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import {
  GET_FOUNDER_PUBLISH_STATUS_CACHE_KEY,
  getFounderPublishStatusAction,
} from '../../api/get-founder-publish-status/get-founder-publish-status.action';
import type { TeamMemberData } from '../../api/get-founder-team-members/get-founder-team-members.action';
import type { AdditionalInfoData, CompanyDetailsData, FounderSmartMatchData } from '../../founder-profile.page';
import AdditionalInfo from './parts/additional-info/additional-info';
import { CompanyBio } from './parts/company-bio/company-bio';
import { CompanyDetails } from './parts/company-details/company-details';
import { MarketFitSection } from './parts/market-fit/market-fit-section';
import SmartMatch from './parts/smart-match/smart-match';
import { TeamMembersSection } from './parts/team-members/team-members-section';

export const Editor = ({
  data: { companyDetails, smartMatch, additionalInformation, teamMembers, marketFitQuestions },
  refetchFounderProfile,
  switchToPreview,
}: {
  data: {
    companyDetails: CompanyDetailsData;
    smartMatch: FounderSmartMatchData;
    additionalInformation: AdditionalInfoData;
    teamMembers: TeamMemberData[];
    marketFitQuestions: MarketFitQuestionItem[];
  };
  refetchFounderProfile: () => void;
  switchToPreview: () => void;
}) => {
  const [pageHeaderLabel] = useTranslation(['profile.header']);

  const {
    data: response,
    isLoading: loading,
    refetch: refetchPublishStatus,
  } = useQuery([GET_FOUNDER_PUBLISH_STATUS_CACHE_KEY], getFounderPublishStatusAction, { cacheTime: 0 });

  if (loading || !response?.data) return <FullHeightSpinner />;

  return (
    <>
      <PageHeader title={pageHeaderLabel} />

      <CompanyDetails
        data={companyDetails}
        refetchPublishStatus={refetchPublishStatus}
        refetchFounderProfile={refetchFounderProfile}
        switchToPreview={() => {
          refetchFounderProfile();
          switchToPreview();
        }}
      />
      <CompanyBio
        data={companyDetails}
        refetchPublishStatus={refetchPublishStatus}
        refetchFounderProfile={refetchFounderProfile}
      />
      <SmartMatch data={smartMatch} publishStatus={response.data} refetchPublishStatus={refetchPublishStatus} />
      <TeamMembersSection teamMembers={teamMembers} />
      <MarketFitSection questions={marketFitQuestions} />
      <AdditionalInfo data={additionalInformation} />
    </>
  );
};

import { FormikField } from '@parts/forms/formik-field/formik-field';
import { Select as SelectBase } from '@parts/select/select';
import styled from 'styled-components';

const Select = styled(SelectBase)`
  width: 100%;
  display: flex;
  align-items: center;

  .ant-select-selector {
    border-color: ${({ theme }) => theme.color.background}!important;
  }
`;

const FieldSet = styled(FormikField)`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 0;
`;

const Inputs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 100%;
  gap: ${({ theme }) => theme.spacing.xmedium};
`;

const Description = styled.p`
  margin: auto;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`;

export default { Select, FieldSet, Inputs, Description };

import { Input } from 'antd';
import type { FormikProps } from 'formik';
import type { Any } from 'src/types';
import S from './formik-string-input.styles';

interface FormikStringInputProps {
  formik: FormikProps<Any>;
  name: string;
  label: string;
  'data-testid'?: string;
}

export const FormikStringInput = ({ formik, name, label, 'data-testid': dataTestId }: FormikStringInputProps) => {
  const testId = dataTestId || `formik-string-input-${name}`;

  return (
    <S.FormikField label={{ for: name, label }} {...formik.getFieldMeta(name)}>
      <Input id={name} type="text" {...formik.getFieldProps(name)} data-testid={testId} />
    </S.FormikField>
  );
};

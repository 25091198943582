export const slugify = (...args: string[]): string => {
  const value = args.join(' ');

  let result = value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '')
    .replace(/\s+/g, '-');

  while (result.endsWith('-')) {
    result = result.slice(0, -1);
  }

  return result;
};

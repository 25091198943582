import { Archived } from '@pages/content/portfolio/investor/parts/table/parts/archived-label/archived-label';
import { founderIdQueryParam } from '@pages/content/public-profile/founder/founder.page';
import { investmentIdQueryParam } from '@pages/content/pulse/founder/parts/editor/create-editor';
import AvatarWithName from '@parts/avatar-with-name/avatar-with-name';
import { FilterHeader } from '@parts/filter-header/filter-header';
import { TableColumn } from '@parts/table-column/table-column';
import { Links } from '@router/links';
import { currencyToShortFormat } from '@utils/fns/currency-to-short-format';
import type { ColumnsQuerying } from '@utils/hooks/use-filtering/use-filtering';
import type { ColumnsType } from 'antd/lib/table';
import type { ExtendedInvestment } from '../../../api/get-extended-investments/get-extended-investments.action';
import RoundTypeTooltip from '../../round-type-tooltip/round-type-tooltip';
import { PortfolioDropdownList } from '../../table/parts/sub-table/dropdown-list';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { DateFormat } from '@i18n/date-formats';

export const Columns = ({
  t,
  refetch,
  querying,
  singleMode,
}: {
  t: {
    [key: string]: string;
  };
  refetch: Function;
  querying: ColumnsQuerying;
  singleMode?: boolean;
}): ColumnsType<ExtendedInvestment> => {
  const { dateFormatter } = useUserDateFormat();

  return [
    !singleMode
      ? {
          title: <FilterHeader querying={querying} dataKey="company.name" title={t.companyLabel} />,
          dataIndex: 'company',
          key: 'company',
          width: '240px',
          render: (value, record) => (
            <TableColumn hideMobile data-testid="company" title={t.companyLabel}>
              <AvatarWithName
                name={value.name}
                avatarUrl={value.avatarUrl}
                link={
                  record.company.type === 'Internal' &&
                  !record.archived &&
                  `${Links.FOUNDER_PUBLIC_PROFILE_DETAILS()}?${founderIdQueryParam}=${record.company.id}`
                }
                tooltip={record.company.type === 'External' ? t.tooltipLabel : ''}
              />
              {record.archived && (
                <>
                  &nbsp;
                  <Archived data-testid="archived_label" />
                </>
              )}
            </TableColumn>
          ),
        }
      : { width: '0px' },
    {
      title: <FilterHeader querying={querying} dataKey="round.date" title={t.dateLabel} />,
      dataIndex: 'round',
      key: 'round',
      width: '140px',
      render: (value) => (
        <TableColumn hideMobile data-testid="round-date" title={t.dateLabel}>
          {dateFormatter(value.date, DateFormat.LETTER_MONTH_LONG_YEAR)}
        </TableColumn>
      ),
    },
    {
      title: <FilterHeader querying={querying} dataKey="round.type" title={t.roundLabel} />,
      dataIndex: 'round',
      key: 'round',
      width: '140px',
      render: (value, record) => (
        <TableColumn hideMobile data-testid="round-type" title={t.roundLabel}>
          <RoundTypeTooltip confirmed={record.confirmed} typeName={value.type} initiator={record.initiator} />
        </TableColumn>
      ),
    },
    {
      title: <FilterHeader querying={querying} dataKey="leadInvestor" title={t.leadLabel} />,
      dataIndex: 'leadInvestor',
      key: 'leadInvestor',
      width: '200px',
      render: (value) => (
        <TableColumn hideMobile data-testid="lead-investor" title={t.leadLabel}>
          {value}
        </TableColumn>
      ),
    },
    {
      title: <FilterHeader querying={querying} dataKey="shares" title={t.sharesLabel} />,
      dataIndex: 'shares',
      key: 'shares',
      width: '100px',
      render: (value) => (
        <TableColumn hideMobile data-testid="shares" title={t.sharesLabel}>
          {value}
        </TableColumn>
      ),
    },
    {
      title: <FilterHeader querying={querying} dataKey="shareClass" title={t.classLabel} />,
      dataIndex: 'shareClass',
      key: 'class',
      width: '100px',
      render: (value) => (
        <TableColumn hideMobile data-testid="class" title={t.classLabel}>
          {value}
        </TableColumn>
      ),
    },
    {
      title: <FilterHeader querying={querying} dataKey="amount" title={t.investedLabel} />,
      dataIndex: 'amount',
      key: 'amount',
      width: '100px',
      render: (value) => (
        <TableColumn hideMobile data-testid="amount" title={t.investedLabel}>
          {value ? currencyToShortFormat(value, '£') : value}
        </TableColumn>
      ),
    },
    {
      title: <FilterHeader querying={querying} dataKey="percentageOwnership" title={t.ownedLabel} />,
      dataIndex: 'percentageOwnership',
      key: 'percentageOwnership',
      width: '100px',
      render: (value) => (
        <TableColumn hideMobile data-testid="percentage-ownership" title={t.ownedLabel}>
          {value ? `${value}%` : value}
        </TableColumn>
      ),
    },
    {
      title: <FilterHeader querying={querying} dataKey="preMoneyVal" title={t.preMoneyLabel} />,
      dataIndex: 'preMoneyVal',
      key: 'preMoneyVal',
      width: '100px',
      render: (value) => (
        <TableColumn hideMobile data-testid="premoney-val" title={t.preMoneyLabel}>
          {value ? currencyToShortFormat(value, '£') : value}
        </TableColumn>
      ),
    },
    {
      title: <FilterHeader querying={querying} dataKey="postMoneyVal" title={t.postMoneyLabel} />,
      dataIndex: 'postMoneyVal',
      key: 'postMoneyVal',
      width: '100px',
      render: (value) => (
        <TableColumn hideMobile data-testid="postmoney-val" title={t.postMoneyLabel}>
          {value ? currencyToShortFormat(value, '£') : value}
        </TableColumn>
      ),
    },
    {
      title: <FilterHeader querying={querying} dataKey="currentValue" title={t.currentValueLabel} />,
      dataIndex: 'currentValue',
      key: 'currentValue',
      width: '100px',
      render: (value) => (
        <TableColumn hideMobile data-testid="current-value" title={t.currentValueLabel}>
          {value ? currencyToShortFormat(value, '£') : value}
        </TableColumn>
      ),
    },
    {
      title: t.noteLabel,
      dataIndex: 'personalNote',
      key: 'personalNote',
      render: (value) => (
        <TableColumn hideMobile data-testid="personal-note" title={t.noteLabel}>
          {value}
        </TableColumn>
      ),
    },
    {
      render: (_value, record) =>
        record.archived ? (
          <Archived />
        ) : (
          <PortfolioDropdownList
            refetch={refetch}
            editHref={`${window.location.pathname}?${investmentIdQueryParam}=${record.id}`}
            investmentId={record.id!}
          />
        ),
      fixed: 'right',
    },
  ];
};

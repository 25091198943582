import { checkIfValidWysiWygValue } from '@pages/content/profile/hooks/use-wysiwyg';
import type { NedRole } from '@pages/content/roles/catalogue/api/get-ned-roles.actions';
import { GridItem } from '@parts/grid-item/grid-item';
import { Links } from '@router/links';
import { SanitizeWysiwyg } from '@utils/fns/sanitize-wysiwyg';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { stringify } from 'query-string';
import { useHistory } from 'react-router-dom';
import S from './role.styles';

export const Role = ({
  id,
  location,
  roleType,
  compensation,
  engagement,
  companyLogoUrl,
  companyName,
  description,
  roleTitle,
  isNew,
}: NedRole) => {
  const [details] = useTranslation(['roles.ned.search.details']);
  const { page } = useQueryParams();
  const history = useHistory();

  return (
    <GridItem
      header={<S.CompanyTile logoUrl={companyLogoUrl} name={roleTitle} supplement={companyName} isNew={isNew} />}
      meta={[
        { icon: <S.Pin />, value: location, visible: Boolean(location) },
        { icon: <S.Person />, value: roleType, visible: Boolean(roleType) },
        { icon: <S.Bill />, value: compensation, visible: Boolean(compensation) },
        { icon: <S.Clock />, value: engagement, visible: Boolean(engagement) },
      ]}
      url={Links.PREVIEW_ROLE(id)}
      footer={
        <S.Link
          data-testid="roles-view-details"
          to={history.createHref({
            pathname: Links.PREVIEW_ROLE(id),
            search: stringify({ page }),
          })}
        >
          {details}
        </S.Link>
      }
      description={{
        dangerouslySet: true,
        content: checkIfValidWysiWygValue(description) ? SanitizeWysiwyg(JSON.parse(description)) : null,
      }}
    />
  );
};

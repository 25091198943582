import type { AdvisorRole } from '@/domain/accounts/roles';
import type { TConnectionStatus } from '@/modules/founder/advisor-search/types';
import { CompensationType } from '@pages/content/profile/ned/ned-profile.page';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import { mapQueryStringToGraphqlParams } from '@utils/fns/map-query-string-to-graphql-params';
import type { ParsedQuery } from 'query-string';
import type { GraphqlResponse } from 'src/types';

export interface AdvisorSearchProfile {
  id: string;
  name: string;
  avatarUrl: string;
  industries: string[];
  primarySkillSet: string | null;
  secondarySkillSet: string[];
  compensationType: CompensationType[] | null;
  isNew: boolean;
  isBookmarked: boolean;
  connection: TConnectionStatus | null;
}

export interface GetFounderBrowseNeds {
  items: AdvisorSearchProfile[];
  total: number;
}

export const GET_FOUNDER_BROWSE_NEDS_CACHE_KEY = 'get-founder-browse-neds';

export const getFounderBrowseNeds =
  (advisorType: AdvisorRole) =>
  (start: number, limit: number, excludedParams: string[], otherParams?: ParsedQuery<string>) =>
  async (): Promise<GraphqlResponse<GetFounderBrowseNeds>> => {
    const NED_FILTER_TYPE = 'FilteredNedFilterType';
    const params =
      otherParams && mapQueryStringToGraphqlParams(otherParams, excludedParams, false, undefined, NED_FILTER_TYPE);

    const queryName = `founderBrowseNeds(advisorType: ${advisorType},  start: ${start}, limit: ${limit}${params?.supplement})`;

    const { data } = await axiosInstance.post('/query', {
      variables: params?.variables,
      query: `
        query getFounderBrowseNeds${params?.arguments} {
          ${queryName} {
            items {
              id
              name
              avatarUrl
              industries
              primarySkillSet
              secondarySkillSet
              compensationType
              isNew
              isBookmarked
              connection { id, status, initiator }
            }
            total
          }
      }
    `,
    });

    return graphqlResponseWrapper(data, ['founderBrowseNeds']);
  };

import MetricRibbonBase from '@assets/icons/metric-ribbon.svg?react';
import styled, { css } from 'styled-components';

export const activeBorder = css`
  border: 1px solid ${({ theme }) => theme.baseColors.Blue200};
  border-radius: 4px;
`;

const Item = styled.div<{ active: boolean; disabled?: boolean }>`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.small};
  padding: 7px 10px;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
  border-radius: 4px;
  opacity: ${({ disabled }) => disabled && '0.4'};
  border: 1px solid
    ${({ theme, active }) => {
      if (active) {
        return theme.baseColors.Blue200;
      }
      return theme.color.border;
    }};

  &:hover {
    ${({ disabled }) => !disabled && activeBorder}
  }
`;

const Label = styled.p<{ disabled?: boolean }>`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  display: flex;
  justify-content: space-between;
`;

export const MetricRibbon = styled(MetricRibbonBase)<{ disabled: boolean }>`
  margin-top: 2px;
  margin-left: 1px;
  cursor: auto;

  path {
    fill: ${({ theme, disabled }) => (disabled ? theme.color.disabledRibbon : theme.color.ribbonFill)};
  }
`;

export default {
  Item,
  Label,
  MetricRibbon,
};

import { DisplayCurrentPageNumber } from './parts/display-current-page-number';
import { NextPageButton } from './parts/next-page-button';
import { PreviousPageButton } from './parts/previous-page-button';
import { V2PaginationContext } from './v2-pagination-context';
import type { V2PaginationProps } from './v2-pagination.types';

import S from './v2-pagination.styles';

const V2Pagination = ({
  current,
  total,
  children,
  'data-testid': dataTestId = 'v2-pagination',
  ...rest
}: V2PaginationProps) => {
  if (total < 1) return null;

  return (
    <V2PaginationContext.Provider value={{ pagination: { current, total } }}>
      <S.V2Pagination data-testid={dataTestId} {...rest}>
        {children}
      </S.V2Pagination>
    </V2PaginationContext.Provider>
  );
};

V2Pagination.PreviousPageButton = PreviousPageButton;
V2Pagination.NextPageButton = NextPageButton;
V2Pagination.DisplayCurrentPageNumber = DisplayCurrentPageNumber;

export { V2Pagination };

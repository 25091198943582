import { MultiSelectSearch as MultiSelectSearchBase } from '@parts/multiselect-search/multiselect-search';
import { Section as SectionBase } from '@parts/section/section';
import { SubmitButton } from '@parts/submit-button/submit-button';
import { Input as InputBase } from 'antd';
import styled from 'styled-components';

const Section = styled(SectionBase)`
  padding: ${({ theme }) => theme.spacing.xmedium};
`;

const Input = styled(InputBase)`
  height: 50px;
  font-size: ${({ theme }) => theme.fontSize.xsmall} .ant-input-affix-wrapper > input.ant-input {
    padding-bottom: 10px !important;
  }

  input {
    height: 100%;
  }
`;

const MultiSelectSearch = styled(MultiSelectSearchBase)`
  .ant-select-selector {
    height: 50px;
  }

  .ant-select-selection-overflow {
    flex-wrap: nowrap;
  }
`;

const Apply = styled(SubmitButton)`
  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin-top: ${({ theme }) => theme.spacing.medium};
  }
`;

export default { Section, Input, MultiSelectSearch, Apply };

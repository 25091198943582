/* eslint-disable no-nested-ternary */
import { deleteFundingRoundAction } from '@pages/content/pulse/api/delete-funding-round/delete-funding-round.actions';
import { updateFundingRoundAction } from '@pages/content/pulse/api/update-funding-round/update-funding-round.actions';
import {
  deleteFundingRound,
  updateFundingRound,
} from '@pages/content/pulse/founder/parts/progress/parts/context/funding-rounds.action-creators';
import { useDrawer } from '@parts/drawer/use-drawer';
import { Table } from '@parts/table/table';
import { useMutation } from '@tanstack/react-query';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useFundingRoundsState } from '@utils/hooks/use-funding-rounds-state/use-funding-rounds-state';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { DeleteFundingRoundModal } from '../delete-funding-round-modal/delete-funding-round-modal';
import { FundingRoundDrawer, type SubmitFundingRound } from '../funding-round-drawer/funding-round-drawer';
import { Columns } from './columns';
import S from './funding-rounds.styles';

export interface FundingRound {
  id: string;
  date: string | Date;
  type: string;
  amountSought: number;
  amountRaised: number;
  valuation: number;
  status: 'Planned' | 'Open' | 'Mid Round' | 'Closing Soon' | 'Closed' | '';
  useOfFunds?: string;
  isUsedInInvestment: boolean;
}

const MIN_PAGE_SIZE = 5;
const MAX_PAGE_SIZE = 100;
const TEST_ID = 'Createfundinground';

export const FundingRounds = ({
  data,
  loading = false,
  readOnly = false,
  showLimit,
  currencySymbol,
}: {
  data: FundingRound[];
  loading?: boolean;
  showLimit: boolean;
  readOnly?: boolean;
  currencySymbol: string;
}) => {
  const [isPerformingFundingRoundAction, setIsPerformingFundingRoundAction] = useState(false);
  const { dispatch } = useFundingRoundsState();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const {
    deviceData: { isMobile, isTablet },
  } = useDeviceDetect();

  const [isSortingData] = useState(false);
  const { mutateAsync: updateFundingRoundApiCall } = useMutation(updateFundingRoundAction);
  const { mutateAsync: deleteFundingRoundApiCall } = useMutation(deleteFundingRoundAction);
  const [currentRow, setCurrentRow] = useState<FundingRound | null>(null);
  const { Drawer, show: showDrawer, hide: hideDrawer } = useDrawer();

  const [error, setError] = useState<string | null>(null);

  const translations = useTranslation([
    'fundingRounds.table.date',
    'fundingRounds.table.type',
    'fundingRounds.table.amountSought',
    'fundingRounds.table.amountRaised',
    'fundingRounds.table.valuation',
    'fundingRounds.table.status',
    'fundingRounds.table.useOfFunds',
    'fundingRounds.table.disabledRemoving',
  ]);

  const errorTranslation = useTranslation('fundingRounds.edit.errorDescription');
  const fundingRoundsHeader = useTranslation('fundingRounds.table.header');

  const handleFundingRoundUpdate = async (values: SubmitFundingRound) => {
    setIsPerformingFundingRoundAction(true);
    const fundingRoundToUpdate = {
      ...values,
      date: new Date(values.date).toISOString(),
      fundingRoundId: currentRow!.id,
    };

    try {
      await updateFundingRoundApiCall(fundingRoundToUpdate);

      dispatch(
        updateFundingRound({
          ...fundingRoundToUpdate,
          id: currentRow!.id,
          isUsedInInvestment: currentRow!.isUsedInInvestment,
        }),
      );

      hideDrawer();
      setError(null);
      setCurrentRow(null);
    } catch {
      setError(errorTranslation);
    } finally {
      setIsPerformingFundingRoundAction(false);
    }
  };

  const handleModalClose = () => {
    hideDrawer();
    setCurrentRow(null);
  };

  const handleEditRowClick = (row: FundingRound) => () => {
    setCurrentRow(row);
    setIsDeleteModalVisible(false);
    setError(null);
    showDrawer();
  };

  const handleDeleteRowClick = (row: FundingRound) => async () => {
    setCurrentRow(row);
    hideDrawer();
    setError(null);
    setIsDeleteModalVisible(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalVisible(false);
  };

  const handleDeleteFundingRound = async () => {
    try {
      setIsPerformingFundingRoundAction(true);
      await deleteFundingRoundApiCall(currentRow!.id);
      dispatch(deleteFundingRound(currentRow!.id));

      setCurrentRow(null);
      setIsDeleteModalVisible(false);
    } finally {
      setIsPerformingFundingRoundAction(false);
    }
  };

  return (
    <>
      <S.HeaderInfo>
        <S.HeaderTitle>{fundingRoundsHeader}</S.HeaderTitle>
      </S.HeaderInfo>
      <Table
        dataSource={data}
        columns={
          Columns({
            translations,
            onEditRow: handleEditRowClick,
            onDeleteRow: handleDeleteRowClick,
            readOnly,
            compact: isMobile || isTablet,
            currencySymbol,
          }) as ColumnsType<object>
        }
        showSorterTooltip
        pagination={{
          style: { display: 'none' },
          pageSize: showLimit ? MAX_PAGE_SIZE : MIN_PAGE_SIZE,
          hideOnSinglePage: true,
        }}
        loading={loading || isSortingData}
        rowKey="id"
      />
      {currentRow && (
        <FundingRoundDrawer
          onSubmit={handleFundingRoundUpdate}
          onClose={handleModalClose}
          fundingRound={currentRow}
          error={error || undefined}
          loading={isPerformingFundingRoundAction}
          drawer={Drawer}
          testID={TEST_ID}
          data-testid={TEST_ID}
          currencySymbol={currencySymbol}
        />
      )}
      {isDeleteModalVisible && currentRow && (
        <DeleteFundingRoundModal onClick={handleDeleteFundingRound} onClose={handleDeleteModalClose} />
      )}
    </>
  );
};

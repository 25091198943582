import { CodatRequestContextValue } from '@pages/content/pulse/api/connect-codat/connect-codat.action';
import type { ExternalMetric } from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { Connected } from './parts/connected/connected';
import { NotConnected } from './parts/not-connected/not-connected';

interface IntegratedMetricsProps {
  metrics?: ExternalMetric[];
  codatRequestContext: CodatRequestContextValue;
  showNotConnectedHeading?: boolean;
  connectedSuccessStateOverride?: JSX.Element;
}

export const IntegratedMetrics = ({
  metrics = [],
  codatRequestContext,
  showNotConnectedHeading = true,
  connectedSuccessStateOverride,
}: IntegratedMetricsProps) => {
  const {
    state: { integrations },
  } = useUserAccount();

  const { isConnected } = integrations.codat;

  if (isConnected) {
    return connectedSuccessStateOverride ?? <Connected metrics={metrics} />;
  }

  return <NotConnected codatRequestContext={codatRequestContext} showHeading={showNotConnectedHeading} />;
};

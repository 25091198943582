import { V2Modal } from '@/components/ui/v2-modal/v2-modal';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import imageDarkMode from '@assets/v2-images/subscribe-dark.webp';
import imageLightMode from '@assets/v2-images/subscribe-light.webp';
import type { V2AdvisorPublishProfileModalProps } from './v2-advisor-publish-profile-modal.types';

import S from './v2-advisor-publish-profile-modal.styles';

export const V2AdvisorPublishProfileModal: React.FC<V2AdvisorPublishProfileModalProps> = ({
  isOpen,
  disabled,
  onClick,
  onClose,
}) => {
  const [title, firstParagraph, secondParagraph, publishButton] = useTranslation([
    'homepage.advisor.publishProfileModal.title',
    'homepage.advisor.publishProfileModal.fistParagraph',
    'homepage.advisor.publishProfileModal.secondParagraph',
    'homepage.advisor.publishProfileModal.publishButton',
  ]);
  return (
    <V2Modal isOpen={isOpen} footer={null} closable={true} title={null} onCancel={onClose}>
      <S.Container>
        <S.LightImage src={imageLightMode} />
        <S.DarkImage src={imageDarkMode} />
        <S.Title>{title}</S.Title>
        <S.Paragraph>{firstParagraph}</S.Paragraph>
        <S.Paragraph>{secondParagraph}</S.Paragraph>
        <S.Button variant="primary" size="large" onClick={onClick} disabled={disabled}>
          {publishButton}
        </S.Button>
      </S.Container>
    </V2Modal>
  );
};

import {
  getBooleanMetricValue,
  GET_BOOLEAN_METRIC_VALUE_QUERY_CACHE_KEY,
} from '@pages/content/pulse/api/get-boolean-metric-value/get-boolean-metric-value.action';
import { upsertBooleanMetric } from '@pages/content/pulse/api/upsert-boolean-metric/upsert-boolean-metric.action';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAutoSubmit } from '@utils/hooks/use-autosubmit/use-autosubmit';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { RadioChangeEvent } from 'antd/lib/radio';
import { useFormik } from 'formik';
import type { ChangeEvent } from 'react';
import * as Yup from 'yup';
import { Info } from '../info/info';
import { EDITOR_DEBOUNCE_DURATION } from '../numeric-editor/parts/row/row';
import S from './boolean-editor.styles';

const Content = ({
  metricId,
  data,
  readOnly,
}: {
  metricId: string;
  data: { value: boolean | null; note: string | null };
  readOnly?: boolean;
}) => {
  const [formikMax, trueLabel, falseLabel, noValueLabel, noteMaxInfoLabel] = useTranslation([
    'formik.validation.max',
    'metrics.manage.boolean.true',
    'metrics.manage.boolean.false',
    'metrics.manage.boolean.noValue',
    'metrics.manage.boolean.noteMaxInfo',
  ]);

  const { values, getFieldProps, getFieldMeta, setValues, isValid } = useFormik<{
    value: boolean | null;
    note: string | null;
  }>({
    initialValues: data,
    enableReinitialize: true,
    onSubmit: () => {},
    validationSchema: Yup.object({
      value: Yup.boolean().nullable().optional(),
      note: Yup.string().max(100, formikMax).nullable().optional(),
    }),
  });

  const { mutateAsync: save } = useMutation(upsertBooleanMetric(metricId));

  useAutoSubmit({
    isValid,
    submit: () => save(values),
    watch: `${values.value} // ${values.note}`,
    debounceDelay: EDITOR_DEBOUNCE_DURATION,
    enabled: true,
    messageOnSave: true,
  });

  const onChange = (e: RadioChangeEvent | ChangeEvent<HTMLInputElement>, value: string) => {
    setValues({
      ...values,
      [value]: (() => {
        if (typeof e.target.value === 'boolean') return e.target.value;

        return e.target.value || null;
      })(),
    });
  };

  return (
    <S.Wrapper>
      <S.RadioGroup {...getFieldProps('value')} onChange={(e) => onChange(e, 'value')} disabled={readOnly}>
        <S.Radio value={null}>{noValueLabel}</S.Radio>
        <S.Radio value={false}>{falseLabel}</S.Radio>
        <S.Radio value>{trueLabel}</S.Radio>
      </S.RadioGroup>
      {!readOnly && (
        <S.NoteWrapper>
          <S.Header>{noteMaxInfoLabel}</S.Header>
          <S.FormikField {...getFieldMeta('note')}>
            <S.Input {...getFieldProps('note')} onChange={(e) => onChange(e, 'note')} />
          </S.FormikField>
        </S.NoteWrapper>
      )}
    </S.Wrapper>
  );
};

export const BooleanEditor = ({
  title,
  metricId,
  readOnly,
}: {
  title: string;
  metricId: string;
  readOnly?: boolean;
}) => {
  const [autosavedLabel, cannotEditLabel] = useTranslation(['metrics.manage.autosaved', 'metrics.data.cannotEdit']);

  const { data: response, isLoading } = useQuery(
    [GET_BOOLEAN_METRIC_VALUE_QUERY_CACHE_KEY(metricId)],
    getBooleanMetricValue(metricId),
    { refetchOnWindowFocus: false, cacheTime: 1000 },
  );

  if (isLoading || !response?.data) return <FullHeightSpinner />;

  const descriptionLabel = readOnly ? cannotEditLabel : autosavedLabel;

  return (
    <>
      <Info title={title} description={descriptionLabel} />
      <Content data={response?.data} metricId={metricId} readOnly={readOnly} />
    </>
  );
};

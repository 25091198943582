import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 70px);
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;
  padding: 0 ${({ theme }) => theme.spacing.base};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    padding: 0 ${({ theme }) => theme.spacing.medium};
  }
`;

const Title = styled.h1`
  font-size: 22px;
  color: ${({ theme }) => theme.color.fontPrimary};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-top: ${({ theme }) => theme.spacing.xbase};
`;

const Description = styled.p<{ $noMargin?: boolean }>`
  color: ${({ theme }) => theme.color.fontSecondary};
  font-size: ${({ theme }) => theme.fontSize.regular};
  margin-top: ${({ $noMargin, theme }) => ($noMargin ? 0 : theme.spacing.medium)};
  display: block;

  a {
    text-decoration: none;
  }
`;

const ResendButton = styled.button`
  color: ${({ theme }) => theme.baseColors.Blue200};
  cursor: pointer;
  text-decoration: none;
  background: transparent;
  border: none;
  padding: 0;
`;

const LoginButton = styled.p`
  color: ${({ theme }) => theme.baseColors.Blue200};
  display: inline-block;
`;

export default { Wrapper, Title, Description, ResendButton, LoginButton };

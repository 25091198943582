import { Spin } from 'antd';
import type { PickerProps } from 'antd/lib/date-picker/generatePicker';
import { lazy, Suspense } from 'react';
import S from './date-picker.styles';

const DatePicker = lazy(async () => {
  try {
    return await import('./date-picker');
  } catch (error) {
    if (error instanceof Error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return { default: () => <></> } as unknown as Promise<typeof import('./date-picker')>;
  }
});
const MonthDatePicker = lazy(async () => {
  try {
    return await import('./month-date-picker');
  } catch (error) {
    if (error instanceof Error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return { default: () => <></> } as unknown as Promise<typeof import('./month-date-picker')>;
  }
});

export const LazyDatePicker = (props: PickerProps<Date>) => (
  <Suspense fallback={<S.LoadingPlaceholder prefix={<Spin />} />}>
    <DatePicker {...props} />
  </Suspense>
);

export const LazyMonthDatePicker = (props: PickerProps<Date>) => (
  <Suspense fallback={<S.LoadingPlaceholder prefix={<Spin />} />}>
    <MonthDatePicker {...props} />
  </Suspense>
);

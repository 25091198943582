import { useModal } from '@parts/modal/use-modal';
import { ProfilePicture } from '@parts/profile-picture/profile-picture';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { ProfileDetails } from '../../../profile-tile';
import S from './use-profile-details-modal.styles';

const AVATAR_SIZE_PX = '96';

export const useProfileDetailsModal = ({
  visible,
  companyName,
  companyImgUrl,
  description,
}: ProfileDetails & { visible: boolean }) => {
  const [titleLabel] = useTranslation(['advisors.profile.detailsModal.title']);
  const { Modal, open, show, hide } = useModal(visible);

  return {
    show,
    modal: (
      <Modal title={titleLabel} footer={null} visible={open} closeModal={hide} onCancel={hide} closable>
        <S.Content>
          <ProfilePicture width={AVATAR_SIZE_PX} height={AVATAR_SIZE_PX} src={companyImgUrl} />
          <S.CompanyName>{companyName}</S.CompanyName>
          <S.Description>{description}</S.Description>
        </S.Content>
      </Modal>
    ),
  };
};

import ShimmerBase from '@assets/icons/shimmer.svg?react';
import { Badge as BadgeBase } from 'antd';
import styled from 'styled-components';

const Badge = styled(BadgeBase)`
  display: flex;
  align-items: center;
  height: 20px;
  border-radius: ${({ theme }) => theme.radius.big};
  background-color: rgba(247, 59, 96, 0.1);
  padding: ${({ theme }) => theme.spacing.xsmall};
`;

const BadgeLabel = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.baseColors.Red};
  font-size: 9px;
  line-height: 1;
  text-transform: uppercase;
`;

const ShimmerIcon = styled(ShimmerBase)`
  margin: ${({ theme }) => theme.spacing.xxsmall};
`;

export default { Badge, BadgeLabel, ShimmerIcon };

import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Tooltip } from 'antd';
import type { DetailedHTMLProps, HTMLAttributes } from 'react';

export const Row = ({
  className,
  ...rest
}: DetailedHTMLProps<HTMLAttributes<HTMLTableRowElement>, HTMLTableRowElement>) => {
  const [tooltipLabel] = useTranslation(['portfolio.investor.managementTeam.archivedTeamMember']);

  const isDisabled = className?.split(' ').includes('archived-row');

  return (
    <Tooltip
      data-testid="tooltip"
      title={tooltipLabel}
      overlayClassName={!isDisabled ? 'tooltip-hidden' : ''}
      align={{ offset: [-500, 10] }}
    >
      <tr {...rest} className={className} />
    </Tooltip>
  );
};

import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './public-preview-notification.styles';

export const PublicPreviewNotification = ({ onClick }: { onClick: () => void }) => {
  const [message, link] = useTranslation(['profile.preview.notification.message', 'profile.preview.notification.link']);

  return (
    <S.NotificationWrapper>
      <S.EyeIcon />
      <span>{message}</span>
      <S.Link type="link" onClick={onClick}>
        {link}
      </S.Link>
    </S.NotificationWrapper>
  );
};

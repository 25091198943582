import { DownCircleOutlined, PlusCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { createNewFundingRoundAction } from '@pages/content/pulse/api/create-new-funding-round/create-new-funding-round.actions';
import { insertFundingRound } from '@pages/content/pulse/founder/parts/progress/parts/context/funding-rounds.action-creators';
import {
  FundingRoundDrawer,
  type SubmitFundingRound,
} from '@pages/content/pulse/founder/parts/progress/parts/funding-round-drawer/funding-round-drawer';
import { useDrawer } from '@parts/drawer/use-drawer';
import { useMutation } from '@tanstack/react-query';
import { scrollToTop } from '@utils/fns/scroll-to-top';
import { useFundingRoundsState } from '@utils/hooks/use-funding-rounds-state/use-funding-rounds-state';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useUserCurrency } from '@utils/hooks/use-user-currency/use-user-currency';
import { Button } from 'antd';
import qs from 'query-string';
import { useEffect, useState } from 'react';
import { FundingRounds } from '../../pulse/founder/parts/progress/parts/funding-rounds/funding-rounds';
import { FundingRoundContainer, FundingRoundFooter } from './funding-round.styles';

export const openQueryParam = 'open';
export const newFundingRoundQueryValue = 'add-round';
const FUNDING_ROUNDS_STARTING_LENGTH = 5;
const PULSE = '/pulse/:content';
export const openFundingRoundDrawerByLink = `${PULSE.replace(
  ':content',
  'investors',
)}?${openQueryParam}=${newFundingRoundQueryValue}`;

export const FundingRound = ({ readOnly = false }: { readOnly?: boolean }) => {
  const {
    dispatch,
    state: { fundingRounds, loadingFundingRounds },
  } = useFundingRoundsState();
  const { currencySymbol } = useUserCurrency();

  const history = useHistory();
  const [isCreatingFundingRound, setIsCreatingFundingRound] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { mutateAsync: createNewFundingRound } = useMutation(createNewFundingRoundAction);
  const { Drawer, show: showDrawer, hide: hideDrawer } = useDrawer();

  const [addNewFundingLabel, showMoreFundingsLabel, showLessFundingsLabel, errorTranslation] = useTranslation([
    'fundingRounds.addNew',
    'fundingRounds.showMore',
    'fundingRounds.showLess',
    'fundingRounds.edit.errorDescription',
  ]);

  const [isResized, setIsResized] = useState(false);

  const handleShowMoreFundingRoundsClick = () => {
    setIsResized(true);
  };

  const handleShowLessFundingRoundsClick = () => {
    setIsResized(false);
    scrollToTop(0);
  };

  const handleFundingRoundCreation = async (values: SubmitFundingRound) => {
    try {
      setIsCreatingFundingRound(true);

      const res = await createNewFundingRound({
        ...values,
        date: new Date(values.date).toISOString(),
      });

      if (!res) {
        return;
      }

      dispatch(insertFundingRound(res?.data.fundingRound));
      hideDrawer();
    } catch {
      setError(errorTranslation);
    } finally {
      setIsCreatingFundingRound(false);
    }
  };

  const handleModalClose = () => {
    hideDrawer();
  };

  const handleOpenModal = () => {
    setError(null);
    showDrawer();
  };
  const parsed = qs.parse(window.location.search);

  useEffect(() => {
    if (parsed[openQueryParam] === newFundingRoundQueryValue) {
      const { open, ...rest } = parsed;

      handleOpenModal();
      setTimeout(() => {
        history.push(`${window.location.pathname}?${qs.stringify(rest)}`);
      }, 0);
    }
  }, []);

  return (
    <FundingRoundContainer>
      <FundingRounds
        data={fundingRounds}
        showLimit={isResized}
        loading={loadingFundingRounds}
        readOnly={readOnly}
        currencySymbol={currencySymbol}
      />
      <FundingRoundFooter readOnly={readOnly}>
        {!readOnly && (
          <Button type="link" onClick={handleOpenModal}>
            <PlusCircleOutlined data-testid="add-funding-round" /> {addNewFundingLabel}
          </Button>
        )}
        {fundingRounds?.length > FUNDING_ROUNDS_STARTING_LENGTH &&
          (isResized ? (
            <Button type="link" onClick={handleShowLessFundingRoundsClick}>
              {showLessFundingsLabel} <UpCircleOutlined />
            </Button>
          ) : (
            <Button type="link" onClick={handleShowMoreFundingRoundsClick}>
              {showMoreFundingsLabel} <DownCircleOutlined />
            </Button>
          ))}
      </FundingRoundFooter>

      <FundingRoundDrawer
        drawer={Drawer}
        onSubmit={handleFundingRoundCreation}
        onClose={handleModalClose}
        error={error || undefined}
        loading={isCreatingFundingRound}
        testID="Createfundinground"
        currencySymbol={currencySymbol}
      />
    </FundingRoundContainer>
  );
};

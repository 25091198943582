import { FullWidthField } from '@parts/forms/formik-full-field/formik-full-field';
import { Select } from '@parts/select/select';
import { Comparator } from '@utils/hooks/use-filtering/use-filtering';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Input } from 'antd';
import type { SubFilterProps } from '../../filter';

export const TextByCondition = ({
  getFieldProps,
  getFieldMeta,
  setFieldValue,
  dataKey,
  options,
  keys,
}: SubFilterProps) => {
  const [selectConditionLabel, startTypingLabel] = useTranslation(['filters.selectCondition', 'filters.startTyping']);

  const valueProps = getFieldProps(keys.value);
  const valueMeta = getFieldMeta(keys.value);

  const comparatorProps = getFieldProps(keys.comparator);
  const comparatorMeta = getFieldMeta(keys.comparator);

  const showCondition = ![Comparator.Empty, Comparator.NotEmpty].includes(comparatorProps.value);

  return (
    <>
      <FullWidthField
        label={{ label: '', for: `${dataKey} by text condition comparator` }}
        error={comparatorMeta.error}
        touched={comparatorMeta.touched}
      >
        <Select
          {...comparatorProps}
          value={comparatorProps.value}
          placeholder={selectConditionLabel}
          onChange={(v) => {
            if (v === Comparator.Empty || v === Comparator.NotEmpty) {
              setFieldValue(keys.value, '');
            }

            setFieldValue(keys.key, dataKey);
            setFieldValue(keys.comparator, v);
          }}
        >
          {options}
        </Select>
      </FullWidthField>
      {showCondition && (
        <FullWidthField
          label={{ label: '', for: `${dataKey} by text condition value` }}
          error={valueMeta.error}
          touched={valueMeta.touched}
        >
          <Input
            {...valueProps}
            placeholder={startTypingLabel}
            value={valueProps.value}
            onChange={(e) => {
              setFieldValue(keys.value, e.target.value);
            }}
          />
        </FullWidthField>
      )}
    </>
  );
};

import { V2AdvisorConnectionCard as Card } from '@/components/blocks/v2-advisor-connection-card/v2-advisor-connection-card';
import { V2AvatarWithDetails } from '@/components/ui/v2-avatar-with-details/v2-avatar-with-details';
import { V2Button } from '@/components/ui/v2-button/v2-button';
import { V2InvisibleButton } from '@/components/ui/v2-invisible-button/v2-invisible-button';
import { founderIdQueryParam } from '@/pages/content/public-profile/founder/founder.page';
import { Links } from '@/router/links';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { useHistory } from 'react-router-dom';
import { useAcceptConnection } from '../../hooks/use-accept-connection';
import { useDeclineConnection } from '../../hooks/use-decline-connection';
import type { AdvisorFounderPendingConnection } from '../../types';

type InviteCardProps = {
  connection: AdvisorFounderPendingConnection;
  onActionSuccess: () => void;
};

export const InviteCard = ({ connection, onActionSuccess }: InviteCardProps) => {
  const [accept, noInterest] = useTranslation(['connections.accept', 'connections.no-interest']);
  const history = useHistory();

  const profileUrl: Record<'Founder', string> = {
    Founder: `${Links.FOUNDER_PUBLIC_PROFILE_DETAILS()}?${founderIdQueryParam}=${connection.founderId}`,
  };

  const { acceptConnection, isAcceptConnectionLoading } = useAcceptConnection(connection.connectionId, onActionSuccess);
  const { declineConnection, isDeclineConnectionLoading } = useDeclineConnection(
    connection.connectionId,
    onActionSuccess,
  );

  return (
    <Card
      pendingConnection={connection}
      header={
        <Card.Section>
          <V2InvisibleButton onClick={() => history.push(profileUrl.Founder)}>
            <V2AvatarWithDetails
              avatar={{ name: connection.businessName, src: connection.avatarUrl }}
              mainText={connection.businessName}
              additionalText={connection.representativeName}
            />
          </V2InvisibleButton>
        </Card.Section>
      }
      details={
        <Card.Section>
          <Card.List>
            <Card.IndustryListElement />
            <Card.InvestmentStageListElement />
          </Card.List>
        </Card.Section>
      }
      description={
        <Card.Section>
          <Card.Paragraph />
        </Card.Section>
      }
      footer={
        <Card.Section>
          <Card.Row>
            <Card.Actions>
              <V2Button variant="primary" onClick={() => acceptConnection()} disabled={isAcceptConnectionLoading}>
                {accept}
              </V2Button>
              <V2Button variant="linkThin" onClick={() => declineConnection()} disabled={isDeclineConnectionLoading}>
                {noInterest}
              </V2Button>
            </Card.Actions>
          </Card.Row>
        </Card.Section>
      }
    />
  );
};

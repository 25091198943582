import { useV2AriaTableContext } from '../v2-aria-table-context';

import S from '../v2-aria-table.styles';

type BodyRowProps = {
  children: React.ReactElement | React.ReactElement[];
  customGridTemplateColumns?: string;
};

export const BodyRow = ({ children, customGridTemplateColumns = undefined }: BodyRowProps) => {
  const { gridTemplateColumns } = useV2AriaTableContext();

  return <S.BodyRow gridTemplateColumns={customGridTemplateColumns ?? gridTemplateColumns}>{children}</S.BodyRow>;
};

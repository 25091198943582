import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export interface SmartMatchOptionsResponseData {
  skillSets: string[];
  productStages: string[];
  investmentStages: string[];
  customerGroups: string[];
  industries: string[];
}

export const GET_SMART_MATCH_OPTIONS_REQUESTS_CACHE_KEY = 'get-smart-match-options-requests';

export const getSmartMatchOptionsAction = async (): Promise<GraphqlResponse<SmartMatchOptionsResponseData>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
    query getSmartMatchOptions {
      skillSets,
      productStages,
      investmentStages,
      customerGroups,
      industries
    }
  `,
  });

  return graphqlResponseWrapper(data, [
    'skillSets',
    'productStages',
    'investmentStages',
    'customerGroups',
    'industries',
  ]);
};

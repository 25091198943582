import { Link as BaseLink } from 'react-router-dom';
import styled from 'styled-components';

const Link = styled(BaseLink)`
  text-decoration: none;
`;

export default {
  Link,
};

import { useQueryWithPagination } from '@/hooks/use-query-with-pagination';
import type { Sorter } from '@/utils/type-definitions/filters';
import { GET_FOUNDER_CONNECTIONS_KEY, getFounderConnections } from '../api/get-founder-connections.query';
import type { FounderConnection, FounderConnectionType } from '../types';

type GetFounderConnectionsQueryConfig = {
  connectionType?: FounderConnectionType;
  search?: string;
  sort?: Sorter;
};

export const useGetFounderConnectionsQuery = ({
  connectionType = 'Both',
  search = '',
  sort,
}: GetFounderConnectionsQueryConfig) => {
  const { data, error, isLoading, isFetching, isInitialLoading, pagination, ...restQueryResult } =
    useQueryWithPagination<FounderConnection>(
      [GET_FOUNDER_CONNECTIONS_KEY, connectionType, search, sort?.key ?? '', sort?.order ?? ''],
      (page: number, perPage: number) => getFounderConnections({ page, perPage, connectionType, search, sort }),
      { perPage: 6, refetchOnWindowFocus: true },
    );

  return {
    ...restQueryResult,
    founderConnectionsData: data,
    founderConnectionsError: error,
    isFounderConnectionsDataLoading: isLoading,
    isFounderConnectionsDataFetching: isFetching,
    isFounderConnectionsInitialLoading: isInitialLoading,
    pagination,
  };
};

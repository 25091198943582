import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export interface GetSingleRoleDataResponse {
  id: string;
  title: string;
  roleType: string;
  location: string;
  skillSet: string[];
  compensation: string;
  engagement: string;
  experience: number;
  description: string;
  postedAt: string;
  closesAt: string | null;
}

export const GET_SINGLE_ROLE_CACHE_KEY = 'get-single-role';

export const getSingleRoleAction =
  (roleId: string) => async (): Promise<GraphqlResponse<GetSingleRoleDataResponse>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
        query getSingleRole {
          previewRoleByOwner(roleId: "${roleId}") {
            id
            title
            closesAt
            compensation
            description
            engagement
            experience
            location
            postedAt
            roleType
            skillSet
          }
        }
      `,
    });

    return graphqlResponseWrapper(data, ['previewRoleByOwner']);
  };

import { Avatar as AvatarBase } from '@parts/avatar/avatar';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.background};
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.xmedium};
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const Avatar = styled(AvatarBase)`
  margin-right: ${({ theme }) => theme.spacing.xmedium};
`;

export default { Avatar, Wrapper };

import type { MenuItem as Item } from '@layout/active/active.layout';

import { Routes } from '@router/routes';

export const populateRoute = (params: { [key: string]: string }, item: Item) => {
  const { route, defaultParams, allowedParamValues } = item;

  if (defaultParams) {
    let populatedRoute = route as string;

    for (const param in defaultParams) {
      if (defaultParams[param] || defaultParams[param] === '') {
        const current = params[param];
        const next =
          allowedParamValues && (allowedParamValues[param].includes(current) || allowedParamValues[param].includes('*'))
            ? current
            : defaultParams[param];

        populatedRoute = populatedRoute.replace(`:${param}?`, next || '');
        populatedRoute = populatedRoute.replace(`:${param}`, next || '');
      }
    }

    return populatedRoute as Routes;
  }

  return route;
};

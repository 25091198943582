import type { Step } from '@pages/content/onboarding/investor/paths.config';

export const getStepIndex = (path: Step[], step: string) => path.findIndex((item) => item.key === step);

export const isLastStep = (path: Step[], step: string) => getStepIndex(path, step) === path.length - 1;

export const isFirstStep = (path: Step[], step: string) => getStepIndex(path, step) === 0;

export const getStep = (path: Step[], step: string) => {
  if (getStepIndex(path, step) === -1) return null;
  return path[getStepIndex(path, step)];
};

export const getNextStep = (path: Step[], step?: string) => {
  if (!step) return path[0];

  return path[getStepIndex(path, step) + 1] ?? null;
};

export const getPrevStep = (path: Step[], step: string) => {
  if (getStepIndex(path, step) <= 0) return null;
  return path[getStepIndex(path, step) - 1];
};

export const getPathLength = (path: Step[]) => path.length ?? 0;

export const isStepExisting = (path: Step[], step: string) => getStepIndex(path, step) > -1;

export const getDisplayedStepsCount = (path: Step[]) =>
  path.filter((step) => step.excludeFromCount !== true).length ?? 0;

export const getCurrentStepDisplayedNumber = (path: Step[], stepKey: string) =>
  getStepIndex(
    path.filter((step) => step.excludeFromCount !== true),
    stepKey,
  ) + 1;

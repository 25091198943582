import type {
  GridConfig,
  MetricMeta,
  TextBoxMeta,
} from '@pages/content/pulse/parts/dashboards/api/get-founder-dashboard/get-founder-dashboard.action';
import { axiosInstance } from '@utils/axios/axios-instance';
import type { AxiosConfig } from '@utils/axios/types';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_PROGRESS_REPORT_METRICS_CACHE_KEY = (hash: string) => `progress-report-metrics-${hash}`;

interface ProgressReportMetricsResponseData {
  gridConfig: GridConfig[];
  metrics: MetricMeta[];
  textBoxes: TextBoxMeta[];
}

export const getProgressReportMetrics =
  (hash: string, password: string | null) => async (): Promise<GraphqlResponse<ProgressReportMetricsResponseData>> => {
    const { data } = await axiosInstance.post(
      '/query',
      {
        query: `
      query getProgressReportMetrics {
        getPublicDashboardCompanyMetrics(hash: "${hash}", password: "${password}") {
          gridConfig {
            i,
            x,
            y,
            w,
            h,
          }
          metrics {
            id,
            name,
            type,
            metricId
            chartType,
            isExternal
          }
          textBoxes {
            id
            title
            mainText
          }
        }
      }
    `,
      },
      { skipAuthorization: true } as AxiosConfig,
    );

    return graphqlResponseWrapper(data, ['getPublicDashboardCompanyMetrics']);
  };

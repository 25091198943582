import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const MainTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  text-decoration: none !important;
`;

const MainText = styled.h3`
  font-size: ${({ theme }) => theme.v2.typography.sizes.sm};
  font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[800], theme.v2.colors.neutral[50])};
  white-space: pre-wrap;
  text-decoration: none;
  word-break: break-all;
`;

const AdditionalText = styled.h4`
  font-size: ${({ theme }) => theme.v2.typography.sizes.sm};
  font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};

  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[500], theme.v2.colors.neutral[400])};
  text-decoration: none;
  word-break: break-all;

  text-align: left;
`;

const Badges = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export default {
  Wrapper,
  Details,
  MainTextWrapper,
  MainText,
  AdditionalText,
  Badges,
};

import { OneLineEllipsis } from '@parts/one-line-ellipsis/one-line-ellipsis';
import { TableColumn } from '@parts/table-column/table-column';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { ColumnsType } from 'antd/lib/table';
import type { ManagementTeam } from '../table';
import { EditableTableColumn } from './editable-table-column';
import S from './editable-table-column.styles';

export const Columns = ({ t }: { t: { [key: string]: string } }): ColumnsType<ManagementTeam> => {
  const [addPhoneLabel, addEmailLabel] = useTranslation([
    'portfolio.investor.managementTeam.addPhone',
    'portfolio.investor.managementTeam.addEmail',
  ]);

  return [
    {
      title: t.namePositionLabel,
      dataIndex: 'name',
      key: 'name',
      width: '32.5%',
      render: (name, data) => (
        <TableColumn title={t.namePositionLabel}>
          <div>
            <OneLineEllipsis>
              <S.Name>{name}</S.Name>
            </OneLineEllipsis>
            <OneLineEllipsis>{data.role}</OneLineEllipsis>
          </div>
        </TableColumn>
      ),
    },
    {
      title: t.contactLabel,
      dataIndex: 'email',
      key: 'contact',
      className: 'editable-double-column',
      width: '32.5%',
      render: (value, record) => (
        <>
          <EditableTableColumn
            fieldKey="email"
            maxLength={64}
            title={t.contactLabel}
            id={record.id}
            placeholder={addEmailLabel}
            disabled={record.isDeletedByFounder}
          >
            {value}
          </EditableTableColumn>
          <EditableTableColumn
            fieldKey="phoneNumber"
            maxLength={15}
            id={record.id}
            placeholder={addPhoneLabel}
            disabled={record.isDeletedByFounder}
          >
            {record.phoneNumber}
          </EditableTableColumn>
        </>
      ),
    },
    {
      title: t.ageLabel,
      dataIndex: 'age',
      key: 'age',
      width: '10%',
      render: (value, record) => (
        <EditableTableColumn
          fieldKey="age"
          maxLength={2}
          title={t.ageLabel}
          id={record.id}
          disabled={record.isDeletedByFounder}
        >
          {value}
        </EditableTableColumn>
      ),
    },
    {
      title: t.genderLabel,
      dataIndex: 'gender',
      key: 'gender',
      width: '10%',
      render: (value, record) => (
        <EditableTableColumn
          fieldKey="gender"
          maxLength={10}
          title={t.genderLabel}
          id={record.id}
          disabled={record.isDeletedByFounder}
        >
          {value}
        </EditableTableColumn>
      ),
    },
    {
      title: t.ethnicityLabel,
      dataIndex: 'ethnicity',
      key: 'ethnicity',
      width: '15%',
      render: (value, record) => (
        <EditableTableColumn
          fieldKey="ethnicity"
          maxLength={15}
          title={t.ethnicityLabel}
          id={record.id}
          disabled={record.isDeletedByFounder}
        >
          {value}
        </EditableTableColumn>
      ),
    },
  ];
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export interface ISkillsIndustriesRolesRequest {
  industries: string[];
  // ! TODO https://exlabs.atlassian.net/browse/CON-8580
  // possibleNedRoles: string[];
  skillSets: string[];
}

export const GET_SKILLS_INDUSTRIES_ROLES_REQUESTS_CACHE_KEY = 'get-skills-industries-roles-requests';

export const getSkillsIndustriesRolesAction = async (): Promise<GraphqlResponse<ISkillsIndustriesRolesRequest>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
    query getSkillsIndustriesRoles {
      industries,
      skillSets
    }
  `,
  });

  return graphqlResponseWrapper(data, ['industries', 'skillSets']);
};

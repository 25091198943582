import { CloseOutlined } from '@ant-design/icons';
import { CompanyType } from '@pages/content/portfolio/investor/api/get-available-companies.action';
import type { LabeledValue } from 'antd/lib/select';
import type { RefObject } from 'react';
import S from './drawer-search.styles';

const DrawerSearch = ({
  placeholder,
  selectRef,
  isDisabled,
  additionalLabel,
  onSearch,
  onSelect,
  onClick,
  dropdownClassName,
  value,
  options,
}: {
  placeholder: string;
  selectRef: RefObject<HTMLInputElement>;
  isDisabled: boolean;
  additionalLabel?: string;
  onSearch: (v: string) => void;
  onSelect: (v: LabeledValue | string | number) => void;
  onClick: () => void;
  dropdownClassName: string;
  value: string;
  options: { name?: string; type?: string; avatarUrl?: string | null }[];
}) => {
  const compareOptionToInput = (option: string, input: string) =>
    option.toLocaleLowerCase().trim().startsWith(input.toLocaleLowerCase().trim());

  return (
    <S.Select
      placeholder={placeholder}
      showArrow={false}
      ref={selectRef}
      showSearch={!isDisabled}
      allowClear
      open={isDisabled ? false : undefined}
      clearIcon={<CloseOutlined data-testid="remove_button" />}
      onClick={onClick}
      value={value}
      dropdownClassName={dropdownClassName}
      onSearch={isDisabled ? undefined : onSearch}
      onSelect={isDisabled ? undefined : onSelect}
      data-testid="drawer-search-select"
      filterOption={(input, option) => compareOptionToInput(option?.value, input)}
    >
      {options.map((val: { name?: string; type?: string; avatarUrl?: string | null }, index: number) =>
        val.name ? (
          <S.Option key={`${val.name}-${index}`} value={val.name} data-testid={`drawer-search-option-${val.name}`}>
            <S.Avatar letter={val.name[0]} avatarUrl={val.avatarUrl} size={30} />
            {val.name}
            {val.type === CompanyType.EXTERNAL && <S.Span>{` ${additionalLabel}`}</S.Span>}
          </S.Option>
        ) : null,
      )}
    </S.Select>
  );
};
export default DrawerSearch;

import { useQuery } from '@tanstack/react-query';
import {
  GET_INVESTOR_CAPITAL_DEPLOYMENT_KEY,
  getInvestorCapitalDeployment,
} from '../api/get-investor-capital-deployment';

export const useGetInvestorCapitalDeployment = () => {
  const query = useQuery([GET_INVESTOR_CAPITAL_DEPLOYMENT_KEY], getInvestorCapitalDeployment);

  return {
    investorCapitalDeploymentData: query.data?.data,
    isInvestorCapitalDeploymentLoading: query.isLoading,
    isInvestorCapitalDeploymentError: query.isError,
  };
};

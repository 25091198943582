import { axiosInstance } from '@utils/axios/axios-instance';
import type { AxiosConfig } from '@utils/axios/types';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_PROGRESS_REPORT_PUBLIC_DASHBOARD_DETAILS_CACHE_KEY = (hash: string) =>
  `progress-report-public-dashboard-details-${hash}`;

interface ProgressReportPublicDashboardDetails {
  id: string;
  name: string;
  company: {
    id: string;
    name: string;
    avatarUrl: string | null;
  };
}

export const getProgressReportPublicDashboardDetails =
  (hash: string, password: string | null) =>
  async (): Promise<GraphqlResponse<ProgressReportPublicDashboardDetails>> => {
    const { data } = await axiosInstance.post(
      '/query',
      {
        query: `
      query getProgressReportPublicDashboardDetails {
        getPublicDashboardDetails(hash: "${hash}", password: "${password}") {
            id
            name
            company {
              id
              name
              avatarUrl
            }
        }
      }
    `,
      },
      { skipAuthorization: true } as AxiosConfig,
    );

    return graphqlResponseWrapper(data, ['getPublicDashboardDetails']);
  };

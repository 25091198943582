import {
  GET_FUNDING_ROUNDS_META_CACHE_KEY,
  getFundingRoundsMetaAction,
} from '@pages/content/pulse/api/get-funding-rounds-meta/get-funding-rounds-meta.actions';
import { LazyDatePicker as DatePicker } from '@parts/date-picker/date-picker.lazy';
import { FormAlert } from '@parts/forms/error/form-alert/form-alert';
import { FormikField } from '@parts/forms/formik-field/formik-field';
import { theme } from '@styles/theme-config';
import { useQuery } from '@tanstack/react-query';
import disableDotAndComma from '@utils/fns/disable-dot-and-comma';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Button, InputNumber, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import * as Yup from 'yup';
import type { FundingRound } from '../funding-rounds/funding-rounds';
import { ButtonsContainer, StyledForm } from './funding-round-drawer.styles';
import { DateFormat } from '@i18n/date-formats';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';

const FUNDING_ROUND_CLOSED_STATUS = 'Closed';

const USE_OF_FUNDS_MAX_LENGTH = 5000;

export type SubmitFundingRound = Omit<FundingRound, 'id' | 'isUsedInInvestment'>;
interface EditFundingRoundModalProps {
  onSubmit: (values: SubmitFundingRound) => void;
  onClose?: () => void;
  fundingRound?: FundingRound | null;
  error?: string;
  loading?: boolean;
  drawer: Function;
  testID?: string;
  disableStatusClosed?: boolean;
  currencySymbol: string;
}

export const FundingRoundDrawer = ({
  onSubmit,
  fundingRound,
  error,
  drawer: Drawer,
  onClose,
  loading,
  testID = '',
  disableStatusClosed = false,
  currencySymbol,
}: EditFundingRoundModalProps) => {
  const {
    deviceData: { isMobile },
  } = useDeviceDetect();

  const [
    dateLabel,
    typeLabel,
    amountSoughtLabel,
    amountRaisedLabel,
    valuationLabel,
    statusLabel,
    useOfFundsLabel,
    datePlaceholder,
    typePlaceholder,
    amountSoughtPlaceholder,
    amountRaisedPlaceholder,
    valuationPlaceholder,
    statusPlaceholder,
    useOfFundsPlaceholder,
    errorTitle,
    editTitleLabel,
    createTitleLabel,
    createButtonLabel,
    editButtonLabel,
    cancelLabel,
    requiredLabel,
    minValueLabel,
    maxValueLabel,
    integerError,
    maxValueExceededLabel,
  ] = useTranslation([
    'fundingRounds.edit.date',
    'fundingRounds.edit.type',
    'fundingRounds.edit.amountSought',
    'fundingRounds.edit.amountRaised',
    'fundingRounds.edit.valuation',
    'fundingRounds.edit.status',
    'fundingRounds.edit.useOfFunds',
    'fundingRounds.edit.placeholder.date',
    'fundingRounds.edit.placeholder.type',
    'fundingRounds.edit.placeholder.amountSought',
    'fundingRounds.edit.placeholder.amountRaised',
    'fundingRounds.edit.placeholder.valuation',
    'fundingRounds.edit.placeholder.status',
    'fundingRounds.edit.placeholder.useOfFunds',
    'fundingRound.edit.error',
    'fundingRounds.edit.title',
    'fundingRounds.create.title',
    'fundingRounds.create.button',
    'fundingRounds.edit.button',
    'global.cancel',
    'formik.validation.required',
    'formik.validation.minValue',
    'formik.validation.maxValue',
    'formik.validation.integer',
    'formik.validation.max',
  ]);

  const isCreatingFundingRound = useMemo(() => !fundingRound, [fundingRound]);

  const { data: fundingRoundsMetaRespnse, isLoading } = useQuery(
    [GET_FUNDING_ROUNDS_META_CACHE_KEY],
    getFundingRoundsMetaAction,
  );

  const maxValidInteger = Number.MAX_SAFE_INTEGER;

  const formik = useFormik<Omit<FundingRound, 'id'>>({
    initialValues: {
      amountRaised: fundingRound?.amountRaised ?? 0,
      amountSought: fundingRound?.amountSought ?? 0,
      date: fundingRound?.date ? new Date(fundingRound.date) : '',
      status: fundingRound?.status ?? '',
      type: fundingRound?.type ?? '',
      useOfFunds: fundingRound?.useOfFunds ?? undefined,
      valuation: fundingRound?.valuation ?? 0,
      isUsedInInvestment: fundingRound?.isUsedInInvestment ?? false,
    },
    onSubmit: (v) => {
      const { isUsedInInvestment, ...data } = v;
      onSubmit(data);
      if (!fundingRound) {
        formik.resetForm();
      }
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      amountRaised: Yup.number()
        .typeError(integerError)
        .integer(integerError)
        .min(0, `${minValueLabel} 0.`)
        .max(maxValidInteger, `${maxValueLabel} ${maxValidInteger}`),
      amountSought: Yup.number()
        .typeError(integerError)
        .integer(integerError)
        .min(0, `${minValueLabel} 0.`)
        .max(maxValidInteger, `${maxValueLabel} ${maxValidInteger}`),
      date: Yup.string().required(requiredLabel),
      status: Yup.string().trim().required(requiredLabel),
      type: Yup.string().trim().required(requiredLabel),
      useOfFunds: Yup.string().max(USE_OF_FUNDS_MAX_LENGTH, maxValueExceededLabel),
      valuation: Yup.number()
        .typeError(integerError)
        .integer(integerError)
        .min(0, `${minValueLabel} 0.`)
        .max(maxValidInteger, `${maxValueLabel} ${maxValidInteger}`),
    }),
  });

  const handleSelectChange = (field: string) => (value: string) => {
    formik.setFieldTouched(field, true);
    formik.setFieldValue(field, value);
  };

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  const handleDatePickerChange = (date: any) => {
    formik.setFieldTouched('date', true);
    formik.setFieldValue('date', date);
  };

  const handleNumberValueChange = (field: string) => (value: string | number | null | undefined) => {
    formik.setFieldTouched(field, true);
    formik.setFieldValue(field, value !== null ? value : '0');
  };

  const handleZeroValue = (value: number) => (value === 0 ? undefined : value);

  const { formats } = useUserDateFormat();

  const footer = (
    <ButtonsContainer>
      <Button
        type="primary"
        htmlType="submit"
        form="edit-funding-round-form"
        data-testid={`${testID}_Button_Create`}
        loading={loading}
      >
        {isCreatingFundingRound ? createButtonLabel : editButtonLabel}
      </Button>
      <Button
        onClick={() => {
          formik.resetForm();
          if (onClose) {
            onClose();
          }
        }}
        data-testid={`${testID}_Button_Cancel`}
      >
        {cancelLabel}
      </Button>
    </ButtonsContainer>
  );

  return (
    <Drawer
      width={isMobile ? '100%' : theme.ui.drawerWidth}
      title={isCreatingFundingRound ? createTitleLabel : editTitleLabel}
      footer={footer}
      onClose={() => {
        formik.resetForm();
        if (onClose) {
          onClose();
        }
      }}
    >
      <>
        {error && <FormAlert message={errorTitle} description={error} type="error" showIcon />}
        <StyledForm onSubmit={formik.handleSubmit} id="edit-funding-round-form">
          <FormikField
            label={{
              label: dateLabel,
              for: 'date',
            }}
            error={formik.errors.date}
            touched={formik.touched.date}
          >
            <DatePicker
              id="date"
              {...formik.getFieldProps('date')}
              onChange={handleDatePickerChange}
              allowClear={false}
              inputReadOnly
              placeholder={datePlaceholder}
              data-testid="date-picker"
              disabled={formik.values.isUsedInInvestment}
              format={formats[DateFormat.FULL_DATE_DASHED]}
            />
          </FormikField>
          <FormikField
            label={{
              label: typeLabel,
              for: 'type',
            }}
            error={formik.errors.type}
            touched={formik.touched.type}
          >
            <Select
              id="type"
              {...formik.getFieldProps('type')}
              onChange={handleSelectChange('type')}
              style={{ width: '100%' }}
              placeholder={typePlaceholder}
              value={formik.values.type || undefined}
              loading={isLoading}
              data-testid="type-select"
              disabled={formik.values.isUsedInInvestment}
            >
              {fundingRoundsMetaRespnse?.data?.type.map((type) => (
                <Select.Option value={type} key={type} data-testid={type}>
                  {type}
                </Select.Option>
              ))}
            </Select>
          </FormikField>
          <FormikField
            label={{
              label: statusLabel,
              for: 'status',
            }}
            error={formik.errors.status}
            touched={formik.touched.status}
          >
            <Select
              id="status"
              {...formik.getFieldProps('status')}
              style={{ width: '100%' }}
              onChange={handleSelectChange('status')}
              placeholder={statusPlaceholder}
              value={formik.values.status || undefined}
              loading={isLoading}
              data-testid="status-select"
            >
              {fundingRoundsMetaRespnse?.data?.status.map((status) => (
                <Select.Option
                  key={status}
                  value={status}
                  data-testid={status}
                  disabled={disableStatusClosed && status === FUNDING_ROUND_CLOSED_STATUS}
                >
                  {status}
                </Select.Option>
              ))}
            </Select>
          </FormikField>
          <FormikField
            label={{
              label: useOfFundsLabel,
              for: 'use-of-funds',
            }}
            error={formik.errors.useOfFunds}
            touched={formik.touched.useOfFunds}
          >
            <TextArea
              id="use-of-funds"
              autoSize={{ minRows: 2, maxRows: 4 }}
              {...formik.getFieldProps('useOfFunds')}
              placeholder={useOfFundsPlaceholder}
            />
          </FormikField>
          <FormikField
            label={{
              label: (
                <>
                  {amountSoughtLabel} ({currencySymbol})
                </>
              ),
              for: 'amount-sought',
            }}
            error={formik.errors.amountSought}
            touched={formik.touched.amountSought}
          >
            <InputNumber
              id="amount-sought"
              {...formik.getFieldProps('amountSought')}
              onChange={handleNumberValueChange('amountSought')}
              min={0}
              placeholder={amountSoughtPlaceholder}
              onKeyDown={disableDotAndComma}
              value={handleZeroValue(formik.values.amountSought)}
            />
          </FormikField>
          <FormikField
            label={{
              label: (
                <>
                  {amountRaisedLabel} ({currencySymbol})
                </>
              ),
              for: 'amount-raised',
            }}
            error={formik.errors.amountRaised}
            touched={formik.touched.amountRaised}
          >
            <InputNumber
              id="amount-raised"
              {...formik.getFieldProps('amountRaised')}
              onChange={handleNumberValueChange('amountRaised')}
              min={0}
              placeholder={amountRaisedPlaceholder}
              onKeyDown={disableDotAndComma}
              value={handleZeroValue(formik.values.amountRaised)}
            />
          </FormikField>
          <FormikField
            label={{
              label: (
                <>
                  {valuationLabel} ({currencySymbol})
                </>
              ),
              for: 'valuation',
            }}
            error={formik.errors.valuation}
            touched={formik.touched.valuation}
          >
            <InputNumber
              id="valuation"
              {...formik.getFieldProps('valuation')}
              onChange={handleNumberValueChange('valuation')}
              placeholder={valuationPlaceholder}
              min={0}
              onKeyDown={disableDotAndComma}
              value={handleZeroValue(formik.values.valuation)}
            />
          </FormikField>
        </StyledForm>
      </>
    </Drawer>
  );
};

import message from '@parts/message/message';
import type { AxiosError } from '@utils/axios/types';
import type { ReactNode } from 'react';
import { getServerError } from './get-server-error';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (update: Promise<any>, successText: ReactNode) => {
  update
    .then(() => message.success({ content: successText }))
    .catch((err: AxiosError) => {
      message.error({ content: getServerError(err) });
    });
};

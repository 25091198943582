import type { Node } from '@pages/content/pulse/founder/parts/management/parts/metrics/parts/numeric-editor/parts/live-chart/live-chart';
import { useEffect } from 'react';
import { Columns } from './parts/columns';
import S from './table-metric.styles';

export const TableMetric = ({ data, onClick }: { data: Node[]; onClick?: () => void }) => {
  useEffect(() => {
    const lastColumns = document.querySelectorAll('.scroll-column');

    lastColumns.forEach((column) => {
      column.scrollIntoView();
    });
  }, []);

  return (
    <S.Wrapper onClick={onClick}>
      <S.WideTable columns={Columns({ data })} dataSource={[data]} rowKey="id" />
    </S.Wrapper>
  );
};

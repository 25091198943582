import styled from 'styled-components';

const Label = styled.p`
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const Value = styled.p`
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  inline-size: 150px;
  overflow-wrap: break-word;
  word-break: break-word;
`;

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 25%;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    flex-direction: row;
    width: 100%;

    ${Label}, ${Value} {
      width: 50%;
    }
  }
`;

export default { Wrapper, Label, Value };

/* eslint-disable react/no-danger */
import { checkIfValidWysiWygValue } from '@pages/content/profile/hooks/use-wysiwyg';
import { Row } from '@pages/content/public-profile/parts/row/row';
import { Section } from '@parts/section/section';
import { SanitizeWysiwyg } from '@utils/fns/sanitize-wysiwyg';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Col, Row as RowBase } from 'antd';
import type { RawDraftContentState } from 'draft-js';

type WysiWygBlock = {
  data: {};
  depth: number;
  entityRanges: [];
  inlineStyleRanges: [];
  key: string;
  text: string;
  type: string;
};

export const AboutMe = ({
  bio,
  myExpectations,
  myOffering,
}: {
  bio: RawDraftContentState | null;
  myExpectations: RawDraftContentState | null;
  myOffering: RawDraftContentState | null;
}) => {
  const [headingLabel, bioLabel, offeringLabel, expectationsLabel] = useTranslation([
    'profile.preview.ned.aboutMe.header',
    'profile.preview.ned.aboutMe.bio',
    'profile.preview.ned.aboutMe.offering',
    'profile.preview.ned.aboutMe.expectations',
  ]);

  const hasNonEmptyText = (item: WysiWygBlock) => item.text !== '';
  const isBio =
    (checkIfValidWysiWygValue(bio) && JSON.parse(bio as unknown as string)?.blocks?.some(hasNonEmptyText)) || false;
  const areExpectations =
    (checkIfValidWysiWygValue(myExpectations) &&
      JSON.parse(myExpectations as unknown as string)?.blocks?.some(hasNonEmptyText)) ||
    false;
  const isOffering =
    (checkIfValidWysiWygValue(myOffering) &&
      JSON.parse(myOffering as unknown as string)?.blocks?.some(hasNonEmptyText)) ||
    false;

  const shouldDisplaySection = isBio || areExpectations || isOffering;

  return shouldDisplaySection ? (
    <Section header={headingLabel}>
      <RowBase>
        <Col md={24}>
          {isBio && (
            <Row
              label={bioLabel}
              content={
                <div
                  dangerouslySetInnerHTML={{
                    __html: SanitizeWysiwyg(JSON.parse(bio as unknown as string)),
                  }}
                />
              }
            />
          )}

          {isOffering && (
            <Row
              label={offeringLabel}
              content={
                <div
                  dangerouslySetInnerHTML={{
                    __html: SanitizeWysiwyg(JSON.parse(myOffering as unknown as string)),
                  }}
                />
              }
            />
          )}

          {areExpectations && (
            <Row
              label={expectationsLabel}
              content={
                <div
                  dangerouslySetInnerHTML={{
                    __html: SanitizeWysiwyg(JSON.parse(myExpectations as unknown as string)),
                  }}
                />
              }
            />
          )}
        </Col>
      </RowBase>
    </Section>
  ) : null;
};

import { Roles } from '@domain/accounts/roles';
import { NotFoundPage } from '@pages/utility/not-found/not-found.page';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { FounderMessaging } from './founder/founder-messaging.page';
import { InvestorNedMessaging } from './investor-ned/investor-ned-messaging.page';
import { InvestorMessaging } from './investor/investor-messaging.page';
import { NedMessaging } from './ned/ned-messaging.page';

export const MessagingPage = () => {
  const {
    state: { userRole },
  } = useUserAccount();

  switch (userRole) {
    case Roles.FOUNDER:
      return <FounderMessaging />;
    case Roles.INVESTOR:
      return <InvestorMessaging />;
    case Roles.INVESTOR_NED:
      return <InvestorNedMessaging />;
    case Roles.NED:
      return <NedMessaging />;
    default:
      return <NotFoundPage />;
  }
};

import { NumericConditions } from '@pages/content/public-profile/founder/api/set-up-numeric-trigger.actions';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_NUMERIC_TRIGGER_OPTIONS_CACHE_KEY = 'get-numeric-trigger-options';

export const getNumericTriggerOptions = async (): Promise<GraphqlResponse<NumericConditions[]>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getNumericTriggerOptions {
        getAvailableNumericTriggerConditions
      }`,
  });

  return graphqlResponseWrapper(data, ['getAvailableNumericTriggerConditions']);
};

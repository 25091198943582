import { axiosInstance } from '@utils/axios/axios-instance';
import type { AxiosConfig } from '@utils/axios/types';

export interface RegisterFounderActionAuth0Payload {
  businessName: string;
  representativeFirstName: string;
  representativeLastName: string;
  email?: string;
  countryOfResidence: string;
  phoneNumber: string;
  invitationToken?: string;
  state?: string;
  registrationToken?: string;
  referralCode?: string;
}

export const registerFounderAction = async (payload: RegisterFounderActionAuth0Payload) =>
  axiosInstance.post('/founder', payload, {
    skipAuthorization: true,
  } as AxiosConfig);

import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Popconfirm } from 'antd';
import type { MouseEvent, ReactNode } from 'react';

const PopConfirm = ({
  children,
  onConfirm,
  onCancel,
  title,
}: {
  children: ReactNode;
  title: string;
  onConfirm: (e?: MouseEvent<HTMLElement>) => void;
  onCancel?: (e?: MouseEvent<HTMLElement>) => void;
}) => {
  const [confirmLabel, cancelLabel] = useTranslation(['global.yes', 'global.no']);

  return (
    <Popconfirm
      title={title}
      onConfirm={onConfirm}
      onCancel={onCancel}
      okText={confirmLabel}
      cancelText={cancelLabel}
      placement="topLeft"
    >
      {children}
    </Popconfirm>
  );
};

export default PopConfirm;

import React from 'react';
import { V2Icon } from '../v2-icon/v2-icon';
import type { V2IconButtonProps } from './v2-icon-button.types';

import S from './v2-icon-button.styles';

export const V2IconButton: React.FC<V2IconButtonProps> = ({
  name,
  color,
  size,
  'data-testid': dataTestId = 'v2-icon-button',
  ...rest
}) => {
  return (
    <S.V2IconButton {...rest} data-testid={dataTestId}>
      <V2Icon name={name} color={color} size={size} />
    </S.V2IconButton>
  );
};

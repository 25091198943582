import styled from 'styled-components';

const Description = styled.div`
  word-wrap: break-word;
  overflow-wrap: break-word;

  > p + p {
    margin-top: ${({ theme }) => theme.spacing.medium};
  }

  ul,
  ol {
    margin-bottom: ${({ theme }) => theme.spacing.medium};
  }

  li {
    margin-left: ${({ theme }) => theme.spacing.base};
  }
`;

export default { Description };

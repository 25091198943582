import { AmountRangeSlider as AmountRangeSliderBase } from '@parts/amount-range-slider/amount-range-slider';
import Checkbox from '@parts/checkbox/checkbox';
import styled from 'styled-components';

const Wrapper = styled.div``;

const AmountRangeSlider = styled(AmountRangeSliderBase)`
  width: 100%;
  padding: 0 ${({ theme }) => theme.spacing.xsmall};

  .ant-slider {
    margin-left: 0;
    margin-right: 0;
  }
`;

const ValueDisplayWrapper = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: ${({ theme }) => theme.spacing.small};
  margin-top: ${({ theme }) => theme.spacing.small};
  color: ${({ theme }) => theme.color.fontPrimary};
`;

const Title = styled.p`
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const CheckboxGroup = styled(Checkbox.Group)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  ${AmountRangeSlider} {
    margin-top: -${({ theme }) => theme.spacing.small};
    margin-bottom: ${({ theme }) => theme.spacing.xmedium};
    margin-left: ${({ theme }) => theme.spacing.xbase};
    width: calc(100% - ${({ theme }) => theme.spacing.xlarge});
    max-width: 250px;
  }
`;

export default { Wrapper, AmountRangeSlider, ValueDisplayWrapper, Title, CheckboxGroup };

import { Roles } from '@domain/accounts/roles';
import type { PendingInvestmentsInvestor } from '@pages/content/portfolio/investor/api/get-pending-investments.action';
import type { PendingInvestmentsFounder } from '@pages/content/pulse/api/get-pending-investments.action';
import { SecureLink } from '@parts/link/link';
import { TableColumn } from '@parts/table-column/table-column';
import { UserTableRow } from '@parts/user-table-row/user-table-row';
import { Links } from '@router/links';
import { currencyToShortFormat } from '@utils/fns/currency-to-short-format';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import type { ColumnsType } from 'antd/lib/table';
import * as H from 'history';
import S from './column.styles';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { DateFormat } from '@i18n/date-formats';

export const Columns = ({
  t,
  history,
  refetch,
}: {
  t: { [key: string]: string };
  history: H.History<H.LocationState>;
  refetch?: Function;
}): ColumnsType<PendingInvestmentsFounder | PendingInvestmentsInvestor> => {
  const [confirmTitleLabel, confirmLabel, cancelLabel, contentLabel, contentQuestionLabel] = useTranslation([
    'portfolio.investment.modalConnection.title',
    'portfolio.investment.modalConnection.confirm',
    'portfolio.investment.modalConnection.cancel',
    'portfolio.investment.modalConnection.content',
    'portfolio.investment.modalConnection.contentQuestion',
  ]);
  const {
    state: { userRole },
  } = useUserAccount();

  const { dateFormatter } = useUserDateFormat();

  return [
    UserTableRow<PendingInvestmentsFounder | PendingInvestmentsInvestor>({
      history,
      avatarSize: 'medium',
      toRole: userRole === Roles.FOUNDER ? Roles.INVESTOR : Roles.FOUNDER,
      title: t.nameLabel,
      nameKey: `${userRole === Roles.FOUNDER ? Roles.INVESTOR : 'company'}.name`,
      idKey: `${userRole === Roles.FOUNDER ? Roles.INVESTOR : 'company'}.id`,
      redirectEvaluation: [
        {
          shouldBePresent: userRole === Roles.FOUNDER ? [Roles.INVESTOR, 'isConnected'] : true,
          key: `${userRole === Roles.FOUNDER ? Roles.INVESTOR : 'company'}.id`,
        },
      ],
      connectionModal: {
        shouldBePresent: userRole === Roles.FOUNDER,
        confirmLabel,
        cancelLabel,
        contentLabel,
        contentQuestionLabel,
        confirmTitleLabel,
        isFollowRequestPending: [Roles.INVESTOR, 'isFollowRequestPending'],
      },
      avatarUrlKey: `${userRole === Roles.FOUNDER ? Roles.INVESTOR : 'company'}.avatarUrl`,
      width: '100% - 190px',
      refetch,
      limitNameWidth: true,
    }),

    {
      title: t.dateLabel,
      dataIndex: ['round', 'date'],
      key: 'round.date',
      width: '20%',
      render: (date: string) => (
        <TableColumn title={t.dateLabel}>{dateFormatter(date, DateFormat.SHORT_MONTH_FULL_DATE)}</TableColumn>
      ),
    },

    {
      title: t.roundLabel,
      dataIndex: ['round', 'type'],
      key: 'round.type',
      width: '15%',
      render: (type: string) => <TableColumn title={t.roundLabel}>{type}</TableColumn>,
    },
    {
      title: t.sharesLabel,
      dataIndex: 'shares',
      key: 'shares',
      width: '15%',
      render: (shares: number) => (
        <TableColumn title={t.sharesLabel}>{shares ? currencyToShortFormat(shares.toString()) : shares}</TableColumn>
      ),
    },
    {
      title: t.investedLabel,
      dataIndex: 'amount',
      key: 'amount',
      width: '15%',
      render: (amount: number) => (
        <TableColumn title={t.investedLabel}>
          {amount ? currencyToShortFormat(amount.toString(), '£') : amount}
        </TableColumn>
      ),
    },
    {
      title: t.ownedLabel,
      dataIndex: 'percentageOwnership',
      key: 'percentageOwnership',
      width: '15%',
      render: (owned: number) => <TableColumn title={t.ownedLabel}>{owned ? `${owned}%` : owned}</TableColumn>,
    },

    {
      width: '15%',
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <S.ReviewButtonWrapper>
          <SecureLink
            data-testid="review-btn"
            href={history.createHref({
              pathname:
                userRole === Roles.FOUNDER
                  ? Links.FOUNDER_CONFIRM_INVESTMENT(id)
                  : Links.INVESTOR_CONFIRM_INVESTMENT(id),
            })}
          >
            <S.ReviewButton>{t.reviewButtonLabel}</S.ReviewButton>
          </SecureLink>
        </S.ReviewButtonWrapper>
      ),
    },
  ];
};

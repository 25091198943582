import { SecureLink } from '@parts/link/link';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useEffect, useRef, type Dispatch, type SetStateAction } from 'react';
import S from './community-banners.styles';

export interface CommunityBannerType {
  title?: string;
  description?: string;
  image: string;
  url?: string;
  height: number | null;
  setHeight: Dispatch<SetStateAction<number | null>> | undefined;
  buttonText?: string;
}

export const CommunityBanner = ({
  title = '',
  description = '',
  image,
  url,
  height,
  setHeight,
  buttonText,
}: CommunityBannerType) => {
  const [learnMoreLabel] = useTranslation(['start.confirmBtnLabel']);

  const wrapperRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (wrapperRef.current?.clientHeight && setHeight) {
      setHeight(wrapperRef.current?.clientHeight);
    }
  }, [wrapperRef.current]);

  const isBannerOnlyImage = Boolean(description);

  return isBannerOnlyImage ? (
    <S.Wrapper height={height} ref={wrapperRef}>
      <S.ImageWrapper>
        <S.Image src={image} alt={title} loading="lazy" />
      </S.ImageWrapper>
      <S.TextWrapper>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
      </S.TextWrapper>
      {url && (
        <S.LearnMoreWrapper>
          <SecureLink href={url} outer target="_blank">
            <S.LearnMore>{buttonText ?? learnMoreLabel}</S.LearnMore>
          </SecureLink>
        </S.LearnMoreWrapper>
      )}
    </S.Wrapper>
  ) : (
    <S.SingleImageWrapper height={height} ref={wrapperRef}>
      <S.SingleImage src={image} alt={title} loading="lazy" />
    </S.SingleImageWrapper>
  );
};

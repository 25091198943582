import styled from 'styled-components';
import type { V2CheckboxProps } from './v2-checkbox.types';

const Wrapper = styled.label<Pick<V2CheckboxProps, 'disabled' | 'readonly'>>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: start;
  align-items: center;
  gap: 8px;
  user-select: none;
  cursor: ${({ readonly, disabled }) => (readonly || disabled ? 'auto' : 'pointer')};
`;

const InputWrapper = styled.div<Pick<V2CheckboxProps, 'size'>>`
  position: relative;
  width: ${({ size }) => (size === 'small' ? '16px' : '20px')};
  height: ${({ size }) => (size === 'small' ? '16px' : '20px')};
`;

const InputCheckbox = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  clip: rect(0 0 0 0);
  z-index: -1;
  pointer-events: none;
  user-select: none;
`;

const IconWrapper = styled.span<{ isFocused: boolean } & Pick<V2CheckboxProps, 'readonly' | 'checked' | 'disabled'>>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 2px solid
    ${({ theme, isFocused, readonly }) =>
      !readonly && isFocused
        ? theme.v2.mode === 'light'
          ? theme.v2.colors.blue[200]
          : theme.v2.colors.blue[400]
        : 'transparent'};
  outline-offset: 2px;
  border-radius: 4px;
  color: ${({ theme }) => theme.v2.colors.neutral[50]};

  ${({ theme, disabled, checked }) => {
    if (!checked)
      return `
          background: transparent;
          border: 1px solid ${theme.v2.mode === 'light' ? theme.v2.colors.blue[100] : theme.v2.colors.blue[500]};
        `;
    if (checked && !disabled)
      return `
          background: ${theme.v2.colors.blue[300]};
          border: none;
        `;
    if (checked && disabled)
      return `
          background: ${theme.v2.mode === 'light' ? theme.v2.colors.blue[200] : theme.v2.colors.blue[500]};
          color: ${theme.v2.mode === 'light' ? theme.v2.colors.neutral[50] : theme.v2.colors.neutral[600]};
          border: none;
        `;
  }}
`;

const Label = styled.span<Pick<V2CheckboxProps, 'size' | 'disabled' | 'lineTroughOnCheck' | 'checked'>>`
  font-family: ${({ theme }) => theme.v2.typography.fonts.body};
  font-size: ${({ theme, size }) => (size === 'small' ? theme.v2.typography.sizes.xs : theme.v2.typography.sizes.md)};
  color: ${({ theme, disabled }) => {
    return theme.v2.mode === 'dark'
      ? theme.v2.colors.neutral[disabled ? 500 : 50]
      : theme.v2.colors.neutral[disabled ? 400 : 700];
  }};
  text-decoration: ${({ lineTroughOnCheck, checked }) => (lineTroughOnCheck && checked ? 'line-through' : 'none')};
`;

export default { Wrapper, InputWrapper, InputCheckbox, IconWrapper, Label };

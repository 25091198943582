import styled from 'styled-components';
import { V2Button } from '@ui/v2-button/v2-button';

const tabletAndDesktopPadding = 24;
const mobilePadding = 16;

const Base = styled.div<{ $hasButton: boolean }>`
  width: 100%;
  position: relative;
  border-radius: ${({ theme }) => theme.v2.borders.radius.md};
  min-height: 150px;
  height: 290px;
  flex: 1;
  padding: ${mobilePadding}px;

  @media (min-width: ${({ theme }) => theme.v2.breakpoints.sm}) {
    padding: ${tabletAndDesktopPadding}px;
  }

  @media (max-width: ${({ theme }) => theme.v2.breakpoints.md}) {
    height: auto;
  }

  display: flex;
  flex-direction: column;
  justify-content: ${({ $hasButton }) => ($hasButton ? 'space-between' : 'flex-start')};
  gap: ${({ $hasButton }) => ($hasButton ? 'auto' : '16px')};

  a {
    text-decoration: none;
  }
`;

const Title = styled('h4')`
  font-weight: ${({ theme }) => theme.v2.typography.weights.bold};
  line-height: ${({ theme }) => theme.v2.typography.lineHeights.button};
  font-size: ${({ theme }) => theme.v2.typography.sizes.md};
  margin-right: 90px;

  @media (min-width: ${({ theme }) => theme.v2.breakpoints.sm}) {
    font-size: 22px;
    margin-right: 70px;
  }
`;

const Description = styled('p')`
  font-size: ${({ theme }) => theme.v2.typography.sizes.xs};
  font-weight: ${({ theme }) => theme.v2.typography.weights.light};
  line-height: ${({ theme }) => theme.v2.typography.lineHeights.button};

  @media (min-width: ${({ theme }) => theme.v2.breakpoints.sm}) {
    font-size: ${({ theme }) => theme.v2.typography.sizes.md};
  }
`;

const Green = styled(Base)`
  background: ${({ theme }) => theme.v2.gradients['green-gradient']};
  color: ${({ theme }) => theme.v2.colors.neutral[50]};

  ${Title} {
    color: ${({ theme }) => theme.v2.colors.neutral[50]};
  }
`;

const Blue = styled(Base)`
  background: ${({ theme }) => theme.v2.gradients['blue-gradient']};
  color: ${({ theme }) => theme.v2.colors.neutral[50]};

  ${Title} {
    color: ${({ theme }) => theme.v2.colors.neutral[50]};
  }
`;

const Navy = styled(Base)`
  background: ${({ theme }) => theme.v2.gradients['navy-blue-gradient']};
  color: ${({ theme }) => theme.v2.colors.neutral[50]};

  ${Title} {
    color: ${({ theme }) => theme.v2.colors.neutral[50]};
  }
`;

const TopWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const BadgeWrapper = styled('div')`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Image = styled.img`
  height: 40px;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
  margin-bottom: 5px;

  ${Title} {
    font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
    font-size: ${({ theme }) => theme.v2.typography.sizes.md};
    margin-right: 0;
  }
`;

const Button = styled(V2Button)`
  margin-top: 8px;
  align-self: flex-start;

  @media (min-width: ${({ theme }) => theme.v2.breakpoints.md}) {
    margin-top: 16px;
  }
`;

export default {
  Green,
  Blue,
  Navy,
  Title,
  Description,
  BadgeWrapper,
  TopWrapper,
  Image,
  ImageWrapper,
  Button,
};

import { unreadIndicator } from '@layout/parts/bell/notification-item/notification-item.styles';
import { Avatar as AvatarBase } from '@parts/avatar/avatar';
import styled, { css } from 'styled-components';

interface ContainerProps {
  read?: boolean;
}

const Container = styled.section<ContainerProps>`
  background-color: ${({ theme, read = false }) =>
    read ? theme.color.sectionBackground : theme.color.layoutBackground};
  padding: ${({ theme }) => `${theme.spacing.xmedium} ${theme.spacing.medium}`};
  display: flex;
  position: relative;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }

  &:hover {
    opacity: 0.6;
  }

  ${({ read }) =>
    !read &&
    css`
      &::after {
        ${unreadIndicator}
      }
    `};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled(AvatarBase)`
  margin-right: ${({ theme }) => theme.spacing.xmedium};
  flex-shrink: 0;
`;

const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSize.small};
`;

const Time = styled.span`
  padding-right: ${({ theme }) => theme.spacing.xbase};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  color: ${({ theme }) => theme.color.fontSecondary};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin-top: 15px;
  }
`;

const S = {
  Container,
  Content,
  Avatar,
  Text,
  Time,
};

export default S;

import { Button } from 'antd';
import styled from 'styled-components';

const ImageWrapper = styled.div`
  border-top-right-radius: ${({ theme }) => theme.radius.default};
  border-bottom-right-radius: ${({ theme }) => theme.radius.default};
  width: 100px;
  height: 100px;
  margin: auto;
  border-radius: 50%;
  margin-bottom: 40px;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActionButton = styled(Button)`
  color: ${({ theme }) => theme.color.submitButton};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontSize.regular};
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.base};
`;

export default {
  SpinnerWrapper,
  ActionWrapper,
  ActionButton,
  ImageWrapper,
};

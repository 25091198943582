import type { AppMessagesKey } from '@i18n/messages';
import { Image } from '@parts/image/image';
import { SecureLink } from '@parts/link/link';
import LinkIconBase from '@assets/icons/link.svg';
import { TranslationText } from '@parts/translation-text/translation-text';
import { useListEntryRevealAnimation } from '@utils/hooks/use-list-reveal-animation/use-list-reveal-animation';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';

import S from './email-boxes-table.styles';

const LIST_ANIMATION_ELEMENT_ID = 'email-boxes-animate-element';

const emailProvidersData: {
  id: string;
  url: string | null;
  title: AppMessagesKey;
  steps: AppMessagesKey[];
}[] = [
  {
    id: 'outlook',
    url: 'https://outlook.live.com/',
    title: 'founder-onboarding.trustedSender.outlook.title',
    steps: [
      'founder-onboarding.trustedSender.outlook.step1',
      'founder-onboarding.trustedSender.outlook.step2',
      'founder-onboarding.trustedSender.outlook.step3',
      'founder-onboarding.trustedSender.outlook.step4',
    ],
  },
  {
    id: 'gmail',
    url: 'https://mail.google.com/mail/',
    title: 'founder-onboarding.trustedSender.gmail.title',
    steps: [
      'founder-onboarding.trustedSender.gmail.step1',
      'founder-onboarding.trustedSender.gmail.step2',
      'founder-onboarding.trustedSender.gmail.step3',
      'founder-onboarding.trustedSender.gmail.step4',
      'founder-onboarding.trustedSender.gmail.step5',
      'founder-onboarding.trustedSender.gmail.step6',
    ],
  },
  {
    id: 'apple-mail',
    url: null,
    title: 'founder-onboarding.trustedSender.apple.title',
    steps: [
      'founder-onboarding.trustedSender.apple.step1',
      'founder-onboarding.trustedSender.apple.step2',
      'founder-onboarding.trustedSender.apple.step3',
      'founder-onboarding.trustedSender.apple.step4',
    ],
  },
];

export const EmailBoxesTable = ({ revealAnimation = false }: { revealAnimation?: boolean }) => {
  useListEntryRevealAnimation('email-boxes-animate-element', undefined, undefined, revealAnimation === false);

  const [tableTitleStart, tableTitleDomain, tableTitleEnd] = useTranslation([
    'founder-onboarding.trustedSender.tableTitleStart',
    'founder-onboarding.trustedSender.tableTitleDomain',
    'founder-onboarding.trustedSender.tableTitleEnd',
  ]);

  return (
    <>
      <S.Title data-animation-id={LIST_ANIMATION_ELEMENT_ID}>
        {tableTitleStart} <b>{tableTitleDomain}</b> {tableTitleEnd}
      </S.Title>

      <S.MailBoxList data-testid="trusted-senders-list">
        {emailProvidersData.map(({ id, url, title, steps }) => (
          <S.MailBoxItem key={id} data-animation-id={LIST_ANIMATION_ELEMENT_ID}>
            <S.MailBoxImageWrapper>
              {url ? (
                <SecureLink outer href={url} target="_blank" rel="noopener noreferrer">
                  <Image name={`mailboxes/${id}.webp`} width="100px" height="auto" />
                </SecureLink>
              ) : (
                <Image name={`mailboxes/${id}.webp`} width="100px" height="auto" />
              )}
            </S.MailBoxImageWrapper>

            <div>
              {url ? (
                <S.TitleLink outer href={url} target="_blank" rel="noopener noreferrer">
                  <S.MailBoxTitle>
                    <TranslationText id={title} />
                  </S.MailBoxTitle>
                  <img src={LinkIconBase} alt="link-icon" loading="lazy" />
                </S.TitleLink>
              ) : (
                <S.MailBoxTitle>
                  <TranslationText id={title} />
                </S.MailBoxTitle>
              )}
              <S.StepsList>
                {steps.map((step) => (
                  <li key={step}>
                    <TranslationText id={step} />
                  </li>
                ))}
              </S.StepsList>
            </div>
          </S.MailBoxItem>
        ))}
      </S.MailBoxList>
    </>
  );
};

import { axiosInstance } from '@utils/axios/axios-instance';
import type { IInvestorPersonalDetails } from '../../investor-profile.page';

export type InvestorPersonalDetailsUpdatePayload = Omit<
  IInvestorPersonalDetails,
  'type' | 'institutionType' | 'email' | 'countryOfResidence'
>;

export const updatePersonalDetailsAction = (payload: InvestorPersonalDetailsUpdatePayload) =>
  axiosInstance.patch('/profile/investor/personal-details', payload);

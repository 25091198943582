import { V2AdvisorHomepageWidget } from '@/components/blocks/v2-advisor-homepage-widget/v2-advisor-homepage-widget';
import { V2AdvisorPublishProfileModal } from '@/components/blocks/v2-advisor-publish-profile-modal/v2-advisor-publish-profile-modal';
import { V2Button } from '@/components/ui/v2-button/v2-button';
import { V2Tooltip } from '@/components/ui/v2-tooltip/v2-tooltip';
import { useModal } from '@/hooks/use-modal';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import type React from 'react';
import { useTogglePublishAdvisorProfile } from '../hooks/use-toggle-publish-advisor-profile';
import { useYouAreHiddenReminder } from '../hooks/use-you-are-hidden-reminder';
import { useBuildYourProfileModal } from './build-your-profile/hooks/use-build-your-profile-modal';

type PublishProfileCardProps = {
  progress: number;
  status: 'unpublished' | 'published';
  canUserPublish: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const PublishProfileCard = ({ progress, status, canUserPublish, ...rest }: PublishProfileCardProps) => {
  const { isOpen, open: openPublishModal, close } = useModal();
  const { modal, open } = useBuildYourProfileModal();

  const [buildYourProfile, publishProfile, editProfile, publishProfileTooltip] = useTranslation([
    'homepage.advisor.readiness.widget.button.build',
    'homepage.advisor.readiness.widget.button.publish',
    'homepage.advisor.readiness.widget.button.edit',
    'homepage.advisor.readiness.widget.button.publish.tooltip',
  ]);

  const { targetElementId } = useYouAreHiddenReminder({
    enabled: status === 'unpublished',
    onPrimaryActionClick: open,
  });

  const { togglePublishAdvisorProfile } = useTogglePublishAdvisorProfile({
    published: status === 'published',
    onSuccess: close,
  });

  return (
    <>
      <V2AdvisorPublishProfileModal isOpen={isOpen} onClick={togglePublishAdvisorProfile} onClose={close} />
      <V2AdvisorHomepageWidget
        {...rest}
        progress={progress}
        status={status}
        data-tour={targetElementId}
        actions={
          <>
            {status === 'unpublished' && (
              <>
                <V2Button variant="outlined" isResponsive onClick={open}>
                  {buildYourProfile}
                </V2Button>
                <V2Tooltip tooltipText={!canUserPublish ? publishProfileTooltip : ''} includeIcon={false}>
                  <V2Button variant="primary" isResponsive disabled={!canUserPublish} onClick={openPublishModal}>
                    {publishProfile}
                  </V2Button>
                </V2Tooltip>
              </>
            )}
            {status === 'published' && (
              <V2Button variant="link" isResponsive onClick={open}>
                {editProfile}
              </V2Button>
            )}
          </>
        }
      />
      {modal}
    </>
  );
};

import type { FounderOnboardingPathName } from '@pages/content/onboarding/founder/paths';
import type { FounderPathsConfig } from './paths.config';

export const getPathSteps = (paths: FounderPathsConfig, path: FounderOnboardingPathName) => paths[path] ?? null;

export const getStepIndex = (paths: FounderPathsConfig, path: FounderOnboardingPathName, step: string) =>
  getPathSteps(paths, path).findIndex((item) => item.key === step);

export const isLastStep = (paths: FounderPathsConfig, path: FounderOnboardingPathName, step: string) =>
  getStepIndex(paths, path, step) === getPathSteps(paths, path).length - 1;

export const isFirstStep = (paths: FounderPathsConfig, path: FounderOnboardingPathName, step: string) =>
  getStepIndex(paths, path, step) === 0;

export const getStep = (paths: FounderPathsConfig, path: FounderOnboardingPathName, step: string) => {
  if (getStepIndex(paths, path, step) === -1) return null;
  return getPathSteps(paths, path)[getStepIndex(paths, path, step)];
};

export const getNextStep = (paths: FounderPathsConfig, path: FounderOnboardingPathName, step?: string) => {
  if (!step) return getPathSteps(paths, path)[0];

  return getPathSteps(paths, path)[getStepIndex(paths, path, step) + 1] ?? null;
};

export const getPrevStep = (paths: FounderPathsConfig, path: FounderOnboardingPathName, step: string) => {
  if (getStepIndex(paths, path, step) <= 0) return null;
  return getPathSteps(paths, path)[getStepIndex(paths, path, step) - 1];
};

export const getPathLength = (paths: FounderPathsConfig, path: FounderOnboardingPathName) =>
  getPathSteps(paths, path).length ?? 0;

export const isStepExisting = (paths: FounderPathsConfig, path: FounderOnboardingPathName, step: string) =>
  Object.keys(paths).includes(path) && getStepIndex(paths, path, step) > -1;

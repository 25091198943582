import { Roles } from '@domain/accounts/roles';
import { InvestorNedLayout } from '@layout/investor-ned/investor-ned.layout';
import { NotFoundPage } from '@pages/utility/not-found/not-found.page';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { InvestorPortfolio } from './investor/investor-portfolio.page';

export const PortfolioPage = () => {
  const {
    state: { userRole },
  } = useUserAccount();

  switch (userRole) {
    case Roles.INVESTOR:
      return <InvestorPortfolio />;
    case Roles.INVESTOR_NED:
      return <InvestorPortfolio Layout={InvestorNedLayout} />;
    default:
      return <NotFoundPage />;
  }
};

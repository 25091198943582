import LogoBase from '@assets/icons/connectd-logo.svg?react';
import styled from 'styled-components';

const Logo = styled(LogoBase)`
  width: 1000px;
  height: 600px;
  overflow: visible !important;

  path {
    transform-box: fill-box;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: 100%;
    height: 100%;
  }
`;

export default { Logo };

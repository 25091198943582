import { useState } from 'react';

import S from './modal.styles';

export const useSimpleModal = (open: boolean = false) => {
  const [isOpen, setIsOpen] = useState(() => open);

  return {
    Modal: S.Modal,
    open: isOpen,
    show: () => setIsOpen(true),
    hide: () => setIsOpen(false),
  };
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';
import type { ShareholderInvestment } from '../founder/parts/editor/editor-interfaces';

export const GET_INVESTMENT_FOR_FOUNDER_CACHE_KEY = (investmentId: string) =>
  `get-investment-for-founder-${investmentId}`;

export interface GetInvestmentForFounder extends ShareholderInvestment {
  id: string;
  investingRelationshipId: string | null;
  investor: {
    id: string;
    name: string;
    email: string;
  };
}

export const getInvestmentForFounderAction =
  (investmentId: string) => async (): Promise<GraphqlResponse<GetInvestmentForFounder>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
      query getInvestmentForFounder {
        investmentForFounder(investmentId: "${investmentId}") {
          id,
          investingRelationshipId,
          investor {
            id
            name
            email
          }
          round {
            id
          }
          shares,
          shareClass,
          percentageOwnership,
          amount,
          leadInvestor,
          terms {
            warrants {
              amount,
              price,
              expiry,
            }
            options,
            convertibleLoanNote,
            discount
          }
          confirmed
        }
      }`,
    });

    return graphqlResponseWrapper(data, ['investmentForFounder']);
  };

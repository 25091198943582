import TickBase from '@assets/icons/green-tick-icon.svg?react';
import { Button as ButtonBase } from 'antd';
import styled, { css } from 'styled-components';

const Button = styled(ButtonBase)<{ animate: boolean; animationTime: number }>`
  background-color: ${({ theme }) => theme.color.submitButton};
  height: 44px;
  border-radius: ${({ theme }) => theme.radius.default};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.baseColors.White};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ animate, animationTime }) =>
    animate &&
    css`
      animation: backgroundAnimation ${animationTime}ms ease-in;
    `}
  @keyframes backgroundAnimation {
    0% {
      background-color: ${({ theme }) => theme.baseColors.Blue200};
    }
    70% {
      background-color: ${({ theme }) => theme.baseColors.Blue200};
    }
    90% {
      background-color: ${({ theme }) => theme.baseColors.White};
    }
  }
`;

const Tick = styled(TickBase)<{ animationTime: number }>`
  ${({ animationTime }) => css`
    path {
      animation: tickAnimation ${animationTime}ms ease-in;
    }
  `}
  @keyframes tickAnimation {
    0% {
      fill: ${({ theme }) => theme.baseColors.White};
    }
    70% {
      fill: ${({ theme }) => theme.baseColors.White};
    }
    90% {
      fill: ${({ theme }) => theme.baseColors.Blue200};
    }
  }
`;

export default { Button, Tick };

import { investorCancelInviteFromFounderAction } from '@/pages/content/lens/api/investor-cancel-invite-from-founder.action';
import { investorInvitesFounderAction } from '@/pages/content/lens/api/invite-founder.action';
import { Roles } from '@domain/accounts/roles';
import { DateFormat } from '@i18n/date-formats';
import { investorBlacklistFounderAction } from '@pages/content/lens/api/blacklist-founder.action';
import type { FollowRequestDetails } from '@pages/content/lens/api/get-investor-browse-companies';
import { useFeedbackModal } from '@pages/content/lens/parts/feedback-modal/feedback-modal';
import { MatchLevel } from '@pages/content/lens/parts/smart-match-progress/smart-match-progress';
import { checkIfValidWysiWygValue } from '@pages/content/profile/hooks/use-wysiwyg';
import { founderIdQueryParam } from '@pages/content/public-profile/founder/founder.page';
import S from '@parts/card/card.styles';
import { CompanyTile } from '@parts/company-tile/company-tile';
import { EngagementDecision } from '@parts/engagement-decision/engagement-decision';
import { GridItem } from '@parts/grid-item/grid-item';
import message from '@parts/message/message';
import metaConfig from '@parts/meta-config/meta-config';
import { Links } from '@router/links';
import { useMutation } from '@tanstack/react-query';
import { SanitizeWysiwyg } from '@utils/fns/sanitize-wysiwyg';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { engageFounderMessagingAction } from '../../api/message-engage-founder';

export const MatchedFounder = ({
  id,
  followRequest,
  name,
  description,
  industry,
  joinedAt,
  level,
  customerGroup,
  investmentStage,
  productStage,
  logoUrl,
  refetchSmartMatch,
  isNew = false,
}: {
  id: string;
  name: string;
  description: string;
  industry: string[];
  joinedAt: string;
  level?: MatchLevel;
  customerGroup: string[];
  investmentStage: string;
  productStage: string;
  logoUrl: string;
  refetchSmartMatch: Function;
  followRequest: FollowRequestDetails | null;
  isNew?: boolean;
}) => {
  const history = useHistory();

  const [joined, notInterestedSuccessful] = useTranslation(['lens.match.joined', 'lens.match.notInterestedSuccessful']);

  const { dateFormatter } = useUserDateFormat();

  const { mutateAsync: blacklistFounder } = useMutation(investorBlacklistFounderAction(id), {
    onSuccess: () => {
      refetchSmartMatch();
      message.success({
        content: `${notInterestedSuccessful} ${name}`,
      });
    },
  });

  const { show: showFeedBackModal, modal } = useFeedbackModal(blacklistFounder);

  return (
    <>
      <GridItem
        dataTestId="matched-founder"
        header={
          <CompanyTile
            name={name}
            logoUrl={logoUrl}
            supplement={industry?.join(', ')}
            about={`${joined} ${dateFormatter(joinedAt, DateFormat.SHORT_MONTH_FULL_DATE)}`}
            isNew={isNew}
          />
        }
        meta={[metaConfig({ customerGroup }).business, metaConfig({ investmentStage, productStage }).growth]}
        description={{
          dangerouslySet: true,
          content: checkIfValidWysiWygValue(description) ? SanitizeWysiwyg(JSON.parse(description)) : null,
        }}
        footer={
          <EngagementDecision
            id={id}
            followRequestId={followRequest?.id ?? null}
            followRequestType={followRequest?.type ?? null}
            userRole={Roles.INVESTOR}
            notInterested={showFeedBackModal}
            cancelConnectionAction={investorCancelInviteFromFounderAction}
            connectAction={investorInvitesFounderAction}
            messageAction={engageFounderMessagingAction}
            refetch={refetchSmartMatch}
          />
        }
        url={history.createHref({
          search: `${founderIdQueryParam}=${id}`,
          pathname: Links.FOUNDER_PUBLIC_PROFILE_DETAILS(),
        })}
      >
        {level && <S.SmartMatchProgress level={level} />}
      </GridItem>
      {modal}
    </>
  );
};

import { CircularImage as CircularImageBase } from '@parts/circular-image/circular-image';
import { TableColumn, TableColumn as TableColumnBase } from '@parts/table-column/table-column';
import { Button as BaseButton } from 'antd';
import { Link as LinkBase } from 'react-router-dom';
import styled from 'styled-components';

const Name = styled.div`
  display: flex;
  align-items: center;
`;

const CircularImage = styled(CircularImageBase)`
  margin-right: ${({ theme }) => theme.spacing.small};
`;

const TableRow = styled(TableColumnBase)`
  white-space: break-spaces;
  padding-right: ${({ theme }) => theme.spacing.small};
`;

const Button = styled(BaseButton)`
  padding-right: ${({ theme }) => theme.spacing.xsmall};
`;

const Link = styled(LinkBase)`
  text-decoration: none;
  color: unset;
`;

const MessageTableColumn = styled(TableColumn)`
  justify-content: flex-end;
`;

export default {
  Name,
  CircularImage,
  TableRow,
  Button,
  Link,
  MessageTableColumn,
};

import { FormikField as FormikFieldBase } from '@parts/forms/formik-field/formik-field';
import styled from 'styled-components';

const FormikField = styled(FormikFieldBase)`
  position: relative;
  margin-bottom: 20px;

  label {
    margin-bottom: 5px;
    font-size: 16px;
    color: ${({ theme }) => theme.color.fontPrimary};
  }
`;

export default {
  FormikField,
};

import { getMetricCsvTemplate } from '@pages/content/pulse/api/get-metric-csv-template/get-metric-csv-template.action';
import { parseMetricCsvAction } from '@pages/content/pulse/api/parse-metric-csv/parse-metric-csv.action';
import { FileUpload } from '@parts/file-upload/file-upload';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { v4 as uuidv4 } from 'uuid';
import { scaleNodesTransform, ScaleTransformPurpose } from '../../numeric-editor';
import type { Node } from '../live-chart/live-chart';
import S from './uploader.styles';

export const Uploader = ({ metricId, setValues, scale }: { metricId: string; setValues: Function; scale: number }) => {
  const [fieldsLabel, descriptionLabel, csvFileLabel, downloadExampleLabel] = useTranslation([
    'metrics.import.csv.fields',
    'metrics.import.csv.description',
    'metrics.import.csv.csvFile',
    'metrics.import.csv.downloadExample',
  ]);

  return (
    <S.Wrapper>
      <S.Row>
        <S.Label>{fieldsLabel}</S.Label>
        <S.Content>
          <S.Description>{descriptionLabel}</S.Description>
          <S.Download onClick={() => getMetricCsvTemplate()}>{downloadExampleLabel}</S.Download>
        </S.Content>
      </S.Row>
      <S.Row>
        <S.Label>{csvFileLabel}</S.Label>
        <S.Content>
          <FileUpload
            uploadMutation={(formData) => parseMetricCsvAction(metricId)(formData)}
            onUploadSuccess={async (res: { data: (Node & { errors: { [key: string]: string } })[] }) => {
              setValues(
                scaleNodesTransform(
                  res.data.map((n) => {
                    const { errors, ...node } = n;

                    return {
                      ...node,
                      id: uuidv4(),
                    };
                  }),
                  ScaleTransformPurpose.READ,
                  scale,
                ),
                true,
              );
            }}
            deleteFile={() => setValues([])}
            mimeTypes="text/csv"
          />
        </S.Content>
      </S.Row>
    </S.Wrapper>
  );
};

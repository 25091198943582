import S from '../v2-experience-tile.styles';

type Props = {
  isIndustry?: boolean;
  children: React.ReactNode;
};

export const Pill = ({ isIndustry, children }: Props) => {
  return <S.Pill $isIndustry={isIndustry}>{children}</S.Pill>;
};

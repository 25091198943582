import GreenTickBase from '@assets/icons/green-circled-tick.svg';
import message from '@parts/message/message';

import { SubmitButton } from '@parts/submit-button/submit-button';
import { useMutation, type MutationFunction } from '@tanstack/react-query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { cancelRequestedIntroAction } from '../../api/cancel-intro-request-to-admin';
import S from './request-intro.styles';

interface IRequestIntro {
  id: string;
  requestIntroId: string | null;
  sendRequestIntroAction: MutationFunction<{ data: string }, string>;
  refetch: Function;
}

export const RequestIntro = ({ id, requestIntroId, sendRequestIntroAction, refetch }: IRequestIntro) => {
  const [requestSent, requestIntroLabel, cancelLabel, requestSentLabel] = useTranslation([
    'lens.match.requestSent',
    'lens.match.requestIntro',
    'lens.match.cancel',
    'lens.requestIntro.requestSend',
  ]);

  const { mutateAsync: connect, isLoading: connectIsLoading } = useMutation(sendRequestIntroAction, {
    onSuccess: () => {
      refetch();
      message.success({ content: requestSentLabel });
    },
  });

  const { mutateAsync: cancelRequestedIntro, isLoading: cancelRequestedIntroInProgress } = useMutation(
    cancelRequestedIntroAction,
    {
      onSettled: () => {
        refetch();
      },
    },
  );

  return (
    <S.Footer noBorder>
      {requestIntroId ? (
        <>
          <S.RequestSent>
            <img src={GreenTickBase} alt="green-tick" loading="lazy" /> {requestSent}
          </S.RequestSent>
          <SubmitButton
            type="link"
            disabled={cancelRequestedIntroInProgress}
            onClick={() => cancelRequestedIntro(requestIntroId)}
          >
            {cancelLabel}
          </SubmitButton>
        </>
      ) : (
        <SubmitButton data-testid="lens-request-intro" disabled={connectIsLoading} onClick={() => connect(id)}>
          {requestIntroLabel}
        </SubmitButton>
      )}
    </S.Footer>
  );
};

import {
  GET_NUMERIC_TRIGGER_OPTIONS_CACHE_KEY,
  getNumericTriggerOptions,
} from '@pages/content/api/get-numeric-triggers-options';
import {
  NumericConditions,
  setUpNumericRangeTrigger,
  setUpNumericTrigger,
  updateNumericRangeTrigger,
  updateNumericTrigger,
} from '@pages/content/public-profile/founder/api/set-up-numeric-trigger.actions';
import {
  ScaleTransformPurpose,
  scaleTransform,
  scaleValidation,
} from '@pages/content/pulse/founder/parts/management/parts/metrics/parts/numeric-editor/numeric-editor';
import Modal from '@parts/modal/modal';
import OnTopFooter from '@parts/on-top-footer/on-top-footer';
import { useMutation, useQuery } from '@tanstack/react-query';
import disableDotAndComma from '@utils/fns/disable-dot-and-comma';
import { useNumericConditionLabels } from '@utils/hooks/use-numeric-condition-labels/use-numeric-condition-labels';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Field } from '../field/field';
import { Input } from '../input/input';
import { OwnerDataInput } from '../owner-data-input/owner-data-input';
import S from './set-up-numeric-trigger.styles';

export const SetUpNumericTrigger = ({
  isOpen,
  metricId,
  onClose,
  handleTriggerSet,
  currentTrigger,
  metricName,
  businessName,
  companyName,
  companyAvatarUrl,
  activate,
  scale,
}: {
  isOpen: boolean;
  metricId: string;
  onClose: () => void;
  handleTriggerSet: () => void;
  currentTrigger: {
    id: string;
    value?: number | boolean;
    condition?: NumericConditions;
    rangeValue?: number;
  };
  metricName: string;
  businessName?: string;
  companyName?: string;
  companyAvatarUrl?: string;
  activate?: Function;
  scale: number;
}) => {
  const [
    rangeEndLargerThanStart,
    rangeStartRequired,
    rangeEndRequired,
    required,
    conditionLabel,
    rangeLabel,
    valueLabel,
    setText,
    setupText,
    editText,
    activateLabel,
    saveLabel,
    cancelLabel,
    metricLabel,
    tooManyDigitsLabel,
  ] = useTranslation([
    'metrics.trigger.rangeEndLargerThanStart',
    'metrics.trigger.rangeStartRequired',
    'metrics.trigger.rangeEndRequired',
    'metrics.trigger.valueRequired',
    'metrics.trigger.condition',
    'metrics.trigger.range',
    'metrics.trigger.value',
    'metrics.trigger.modal.text',
    'metrics.trigger.modal.title.setup',
    'metrics.trigger.modal.title.edit',
    'metrics.trigger.modal.activate',
    'metrics.trigger.modal.save',
    'metrics.trigger.modal.cancel',
    'metrics.trigger.metric',
    'formik.validation.tooManyDigits',
  ]);

  const conditionLabels = useNumericConditionLabels();

  const triggerPresent = Boolean(currentTrigger.id);

  const mutationConfig = {
    throwOnError: true,
    onSettled: () => {
      handleTriggerSet();
    },
    onSuccess: () => {
      if (activate) {
        activate();
      }
    },
  };

  const { data: optionsResponse } = useQuery([GET_NUMERIC_TRIGGER_OPTIONS_CACHE_KEY], getNumericTriggerOptions);

  const { mutateAsync: setUpNumeric } = useMutation(setUpNumericTrigger(metricId), mutationConfig);
  const { mutateAsync: setUpNumericRange } = useMutation(setUpNumericRangeTrigger(metricId), mutationConfig);
  const { mutateAsync: updateNumeric } = useMutation(updateNumericTrigger(currentTrigger.id), mutationConfig);
  const { mutateAsync: updateNumericRange } = useMutation(updateNumericRangeTrigger(currentTrigger.id), mutationConfig);

  const currentTriggerValue = scaleTransform(Number(currentTrigger?.value), scale, ScaleTransformPurpose.READ) || 0;

  const tooManyDigitsScaleLabel = `${tooManyDigitsLabel} (max ${scale})`;

  const formik = useFormik({
    initialValues: {
      value: currentTriggerValue,
      startValue: currentTriggerValue,
      endValue: scaleTransform(Number(currentTrigger?.rangeValue), scale, ScaleTransformPurpose.READ) || 0,
      condition: currentTrigger?.condition || NumericConditions.Between,
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      condition: Yup.string().oneOf(Object.values(NumericConditions)),
      startValue: Yup.number().when('condition', {
        is: (con: NumericConditions) => con === NumericConditions.Between,
        then: Yup.number()
          .max(scaleTransform(Number.MAX_SAFE_INTEGER, scale, ScaleTransformPurpose.READ))
          .required(rangeStartRequired)
          .test('there-should-not-be-more-than-scale-digits-after-decimal', tooManyDigitsScaleLabel, function () {
            return scaleValidation(this.parent.startValue, scale);
          }),
        otherwise: Yup.number(),
      }),
      endValue: Yup.number().when('condition', {
        is: (con: NumericConditions) => con === NumericConditions.Between,
        then: Yup.number()
          .max(scaleTransform(Number.MAX_SAFE_INTEGER, scale, ScaleTransformPurpose.READ))
          .moreThan(Yup.ref('startValue'), rangeEndLargerThanStart)
          .required(rangeEndRequired)
          .test('there-should-not-be-more-than-scale-digits-after-decimal', tooManyDigitsScaleLabel, function () {
            return scaleValidation(this.parent.endValue, scale);
          }),
        otherwise: Yup.number(),
      }),
      value: Yup.number().when('condition', {
        is: (con: NumericConditions) => con !== NumericConditions.Between,
        then: Yup.number()
          .max(scaleTransform(Number.MAX_SAFE_INTEGER, scale, ScaleTransformPurpose.READ))
          .required(required)
          .test('there-should-not-be-more-than-scale-digits-after-decimal', tooManyDigitsScaleLabel, function () {
            return scaleValidation(this.parent.value, scale);
          }),
        otherwise: Yup.number(),
      }),
    }),
    onSubmit: (values: { value: number; startValue: number; endValue: number; condition: NumericConditions }) => {
      const { condition } = values;
      const betweenSelected = formik.values.condition === NumericConditions.Between;

      const value = scaleTransform(values.value, scale, ScaleTransformPurpose.WRITE);
      const startValue = scaleTransform(values.startValue, scale, ScaleTransformPurpose.WRITE);
      const endValue = scaleTransform(values.endValue, scale, ScaleTransformPurpose.WRITE);

      const betweenSubmit = triggerPresent ? updateNumericRange : setUpNumericRange;
      const compareSubmit = triggerPresent ? updateNumeric : setUpNumeric;

      if (betweenSelected) {
        return betweenSubmit({ startValue, endValue });
      }

      compareSubmit({ value, condition });
    },
  });

  const betweenSelected = formik.values.condition === NumericConditions.Between;
  const commonInputProps = {
    onKeyDown: !scale ? disableDotAndComma : undefined,
  };

  return (
    <Modal
      visible={isOpen}
      closeModal={onClose}
      onCancel={onClose}
      title={currentTrigger.id ? editText : setupText}
      footer={
        <OnTopFooter
          confirmLabel={activate ? activateLabel : saveLabel}
          cancelLabel={cancelLabel}
          onConfirm={formik.submitForm}
          onCancel={onClose}
          disabled={!formik.isValid}
        />
      }
    >
      <S.Description>{setText}</S.Description>
      <OwnerDataInput name={businessName || companyName || ''} avatarUrl={companyAvatarUrl || ''} />
      <Field
        label={{
          label: metricLabel,
          for: 'metric',
        }}
      >
        <Input id="metric" value={metricName} readOnly />
      </Field>
      <S.FieldSet
        error={formik.errors.startValue || formik.errors.endValue || formik.errors.value}
        touched={formik.touched.startValue || formik.touched.endValue || formik.touched.value}
      >
        <Field
          label={{
            label: conditionLabel,
            for: conditionLabel,
          }}
          touched={formik.touched.condition}
          error={formik.errors.condition}
        >
          <S.Select
            {...formik.getFieldProps('condition')}
            onChange={(value) => {
              formik.setFieldValue('condition', value);
            }}
          >
            {optionsResponse?.data?.map((c) => (
              <S.Select.Option value={c} key={metricId + c}>
                {conditionLabels[c]}
              </S.Select.Option>
            ))}
          </S.Select>
        </Field>
        <Field
          label={{
            label: betweenSelected ? rangeLabel : valueLabel,
            for: betweenSelected ? rangeLabel : valueLabel,
          }}
        >
          <S.Inputs>
            {betweenSelected ? (
              <>
                <Input {...commonInputProps} {...formik.getFieldProps('startValue')} type="number" />
                <Input {...commonInputProps} {...formik.getFieldProps('endValue')} type="number" />
              </>
            ) : (
              <Input {...commonInputProps} {...formik.getFieldProps('value')} type="number" />
            )}
          </S.Inputs>
        </Field>
      </S.FieldSet>
    </Modal>
  );
};

import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import * as Yup from 'yup';

const TITLE_MAX_LENGTH = 50;
const MAIN_TEXT_MAX_LENGTH = 900;

export const useValidationSchema = () => {
  const [requiredLabel] = useTranslation(['formik.validation.required']);

  return Yup.object({
    title: Yup.string().nullable().required(requiredLabel).max(TITLE_MAX_LENGTH),
    mainText: Yup.string().nullable().required(requiredLabel).max(MAIN_TEXT_MAX_LENGTH),
  });
};

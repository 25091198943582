// Note: Below is the configuration of the old theme, which we are slowly trying to get rid of from the codebase.
//       The new theme is located in src/styles/v2-theme/v2-theme.ts

import { v2, type V2Theme } from '@styles/v2-theme/v2-theme';

import {
  DESKTOP_CONTAINER_WIDTH,
  DESKTOP_DEVICE_BREAKPOINT,
  HIGH_RES_DESKTOP_DEVICE_BREAKPOINT,
  MOBILE_DEVICE_BREAKPOINT,
  TABLET_DEVICE_BREAKPOINT,
} from '@config';

export type FontFamily = 'primary';

export type DeviceBreakpoint = 'xsmall' | 'mobile' | 'tablet' | 'desktop' | 'highResDesktop';

export type Color =
  | 'primary'
  | 'secondary'
  | 'background'
  | 'disabled'
  | 'disabledSelectOption'
  | 'disabledCheckbox'
  | 'fontPrimary'
  | 'fontSecondary'
  | 'fontInput'
  | 'dashboardBackground'
  | 'border'
  | 'maskBg'
  | 'submitButton'
  | 'submitButtonHover'
  | 'lightButtonHover'
  | 'header'
  | 'savedButton'
  | 'inputBackground'
  | 'selectArrowBackground'
  | 'selectArrowFill'
  | 'sectionBackground'
  | 'iconFill'
  | 'tagBackground'
  | 'tagFont'
  | 'cardBackground'
  | 'cardTitle'
  | 'cardSubTitle'
  | 'layoutBackground'
  | 'logoFill'
  | 'tableBackground'
  | 'tableBorder'
  | 'tableStripedRowBackground'
  | 'tableAlternateHeaderColor'
  | 'pageHeader'
  | 'profileMenu'
  | 'backgroundHover'
  | 'paginationButton'
  | 'wyswigButton'
  | 'ellipseIcon'
  | 'metricTitle'
  | 'placeholder'
  | 'metricFont'
  | 'tableHeader'
  | 'tableBody'
  | 'metricTooltip'
  | 'interval'
  | 'manageMetric'
  | 'success'
  | 'activeMetricManagementTitle'
  | 'activeMetricManagementDescription'
  | 'error'
  | 'fontPrimaryInverted'
  | 'fileUploadingProgress'
  | 'legalDocumentTitle'
  | 'legalDocumentDesc'
  | 'legalDocumentHoverBackground'
  | 'legalDocumentImage'
  | 'activeFilter'
  | 'infoLinkBackground'
  | 'infoLinkButtonHover'
  | 'infoLinkButton'
  | 'infoLinkButtonTextColor'
  | 'bannerBackground'
  | 'bannerBorder'
  | 'bannerWithTestimonialBackground'
  | 'bannerWithTestimonialTestimonialBackground'
  | 'bannerWithTestimonialTestimonialFontColor'
  | 'bannerWithTestimonialDescriptionColor'
  | 'bannerWithTestimonialBorder'
  | 'closableInfoBackground'
  | 'lockIcon'
  | 'defaultAvatar'
  | 'defaultAvatarPastel'
  | 'avatarContent'
  | 'avatarContentPastel'
  | 'portfolioChartBackground'
  | 'chartBlueColor'
  | 'bonusBorder'
  | 'dropdownItem'
  | 'fontBlue'
  | 'link'
  | 'companyDetailsEmptyState'
  | 'removeButtonInputBackround'
  | 'removeButtonInputBorder'
  | 'buttonText'
  | 'comingSoonLabel'
  | 'grayedOutText'
  | 'bannerLabelBackground'
  | 'bannerLabelText'
  | 'slideshowActiveDot'
  | 'slideshowInactiveDot'
  | 'checkoutTile'
  | 'checkoutHeader'
  | 'checkoutLabel'
  | 'checkoutPromo'
  | 'checkoutBackground'
  | 'addMetricColor'
  | 'metricVisibilityBtn'
  | 'dashboardBtn'
  | 'panelBackground'
  | 'checkboxBorder'
  | 'checkboxTogglerBackground'
  | 'checkboxTogglerBorder'
  | 'dragShadowBackground'
  | 'dragShadowBorder'
  | 'ribbonFill'
  | 'disabledRibbon'
  | 'closeConnectionIconColor'
  | 'linkHover'
  | 'benefitMain'
  | 'benefitSecondary'
  | 'readinessScoreBackground'
  | 'readinessScoreMenuTab'
  | 'referralFormImageBackground'
  | 'tabButtonBackground'
  | 'connectedAdvisorButtonBackground';

export type Gradient =
  | 'green'
  | 'yellow'
  | 'pink'
  | 'red'
  | 'auth'
  | 'checkoutButtons'
  | 'checkoutTile'
  | 'neon'
  | 'onboardingAsideGradient'
  | 'onboardingInvestorTileGradient'
  | 'scrollThumb'
  | 'scrollTrack';

export type Spacing = 'large' | 'xlarge' | 'base' | 'xbase' | 'medium' | 'xmedium' | 'small' | 'xsmall' | 'xxsmall';

export type FontSize = 'xxsmall' | 'xsmall' | 'small' | 'regular' | 'medium' | 'xmedium' | 'big' | 'xlarge' | 'large';

export type FontWeight = 'bold' | 'semibold' | 'normal' | 'light' | 'regular';

export type Radius = 'default' | 'medium' | 'big';

export type UI =
  | 'menuWidth'
  | 'browseCompaniesSearchWidth'
  | 'drawerWidth'
  | 'liveChartHeight'
  | 'chartHeight'
  | 'feedIndent'
  | 'feedIndentSmall'
  | 'inputHeight'
  | 'columnFiltersWidth'
  | 'messagingHeight'
  | 'minMessagingHeight'
  | 'containerWidth'
  | 'notificationOverlayHeight'
  | 'notificationOverlayWidth'
  | 'mobileMenuHeight'
  | 'desktopMenuHeight';

export type ApplicationGlobalMargin = 'default' | 'mobile';

export type BaseColor =
  | 'Blue100'
  | 'Blue150'
  | 'Blue200'
  | 'Blue250'
  | 'Blue300'
  | 'Blue350'
  | 'Blue400'
  | 'Blue500'
  | 'Blue550'
  | 'Blue600'
  | 'Blue700'
  | 'Navy400'
  | 'Navy500'
  | 'Navy600'
  | 'Green'
  | 'Red'
  | 'White'
  | 'Black'
  | 'Pink400'
  | 'Pink'
  | 'Grey'
  | 'Grey50'
  | 'Grey100'
  | 'Grey150'
  | 'Grey200'
  | 'Grey210'
  | 'Grey220'
  | 'Grey225'
  | 'Grey250'
  | 'Grey275'
  | 'Grey300'
  | 'DarkGrey'
  | 'DarkerGray'
  | 'Orange'
  | 'PinkPastel'
  | 'RedPastel'
  | 'LightRed'
  | 'DarkRed'
  | 'Transparent'
  | 'GrayedBlue'
  | 'DeepNavy'
  | 'LightBlack';

export type ChartColors = 'Blue1' | 'Blue2' | 'Blue3' | 'Yellow' | 'Orange' | 'Pink' | 'Purple';

export type OnboardingColors =
  | 'headerBackground'
  | 'progressBarRail'
  | 'logoFill'
  | 'footerBackground'
  | 'buttonBorder'
  | 'previousButtonHoverBackground'
  | 'nextButtonBackground'
  | 'fontPrimary'
  | 'finishedPath'
  | 'asideBackground'
  | 'mobileTileBackground'
  | 'tipsBackground'
  | 'headingSubtitle'
  | 'onboardingBackground'
  | 'avatarInfo'
  | 'buttonText'
  | 'inputBorder'
  | 'inputBorderFocus'
  | 'showMoreButton'
  | 'searchIcon'
  | 'tableHeader'
  | 'selectBorder'
  | 'selectBackground'
  | 'greenTick'
  | 'yellowTick'
  | 'redTick'
  | 'investorTypeTileBackground';

type RegisterColors =
  | 'background'
  | 'backgroundForm'
  | 'inputBackground'
  | 'inputBorder'
  | 'borderShadow'
  | 'continueButtonBackground'
  | 'disabledContinueButtonBackground'
  | 'indicatorBorder'
  | 'checkIcon'
  | 'selectRoleButtonBorder'
  | 'selectRoleButtonBackground'
  | 'continueButtonText'
  | 'requiredStar'
  | 'upperHeader';

type CheckoutColors = 'Black' | 'Red' | 'Purple' | 'Gray' | 'DarkerGray' | 'DarkGrey';

export interface Theme {
  v2: V2Theme;
  fontFamily: { [key in FontFamily]: string };
  color: { [key in Color]: string };
  chartColors: { [key in ChartColors]: string };
  checkoutColors: { [key in CheckoutColors]: string };
  onboardingColors: { [key in OnboardingColors]: string };
  registerColors: { [key in RegisterColors]: string };
  gradient: { [key in Gradient]: string };
  spacing: { [key in Spacing]: string };
  deviceBreakpoint: { [key in DeviceBreakpoint]: string };
  fontSize: { [key in FontSize]: string };
  fontWeight: { [key in FontWeight]: number };
  radius: { [key in Radius]: string };
  ui: { [key in UI]: string };
  appGlobalMargin: { [key in ApplicationGlobalMargin]: string };
  baseColors: { [key in BaseColor]: string };
}

const baseColors: { [key in BaseColor]: string } = {
  Blue100: '#10328E',
  Blue150: '#2473d4',
  Blue200: '#3E60EB',
  Blue250: '#3e548e',
  Blue300: '#D9E3FE',
  Blue350: '#9BA9CF',
  Blue400: '#070F25',
  Blue500: '#0E1731',
  Blue550: '#0B142C',
  Blue600: '#1B2748',
  Blue700: '#151C26',
  Navy400: '#0b1d4f',
  Navy500: '#091b48',
  Navy600: '#08173b',
  Green: '#86A531',
  Grey: '#F5F5F5',
  Red: '#F73B60',
  LightRed: '#d6455c',
  DarkRed: '#d6455c80',
  White: '#fff',
  Pink400: '#f2486a',
  Pink: '#e83e61',
  Orange: '#F8A504',
  Grey50: '#a0a3a7',
  Grey100: '#070F25',
  Grey150: '#858283',
  Grey200: '#616A71',
  Grey210: '#B0B4B8',
  Grey220: '#b8b6b7',
  Grey225: '#737D97',
  Grey250: '#c6c9cc',
  Grey275: '#d8d7d8',
  Grey300: '#F8F8FB',
  DarkGrey: '#12171C',
  DarkerGray: '#181E25',
  LightBlack: '#1F242B',
  Black: '#000',
  PinkPastel: '#eddcdf',
  RedPastel: '#7d3140',
  GrayedBlue: '#707991',
  DeepNavy: '#1D294D',
  Transparent: 'transparent',
};

export const chartColors = {
  Blue1: baseColors.Blue200,
  Blue2: '#89b2ff',
  Blue3: '#c7d3e6',
  Yellow: '#F7DDA7',
  Orange: '#E07B74',
  Pink: '#CA4B77',
  Purple: '#712B6B',
};

export const registerColors: { [key in RegisterColors]: string } = {
  background: baseColors.DarkGrey,
  backgroundForm: baseColors.DarkerGray,
  inputBackground: baseColors.DarkerGray,
  inputBorder: baseColors.LightRed,
  borderShadow: baseColors.DarkRed,
  continueButtonBackground: baseColors.LightRed,
  disabledContinueButtonBackground: baseColors.DarkRed,
  indicatorBorder: baseColors.White,
  checkIcon: baseColors.White,
  selectRoleButtonBorder: baseColors.White,
  selectRoleButtonBackground: baseColors.LightBlack,
  continueButtonText: baseColors.White,
  requiredStar: baseColors.LightRed,
  upperHeader: baseColors.LightRed,
};

const checkoutColors = {
  Black: '#1F242B',
  Red: '#D6455C',
  Purple: '#9747FF',
  DarkGrey: '#12171C',
  DarkerGray: '#181E25',
  Gray: '#82868a',
};

const onboardingColors = {
  headerBackground: baseColors.Blue500,
  progressBarRail: '#223788',
  logoFill: baseColors.White,
  footerBackground: baseColors.Blue500,
  buttonBorder: baseColors.Blue200,
  previousButtonHoverBackground: baseColors.Navy500,
  nextButtonBackground: baseColors.Blue200,
  fontPrimary: baseColors.White,
  finishedPath: baseColors.Green,
  asideBackground: baseColors.Blue500,
  mobileTileBackground: baseColors.Blue500,
  tipsBackground: baseColors.Blue500,
  headingSubtitle: baseColors.Blue300,
  onboardingBackground: baseColors.Blue400,
  avatarInfo: baseColors.Blue300,
  buttonText: baseColors.Blue200,
  inputBorder: baseColors.Blue300,
  inputBorderFocus: baseColors.Blue200,
  showMoreButton: baseColors.Blue200,
  searchIcon: baseColors.Blue300,
  tableHeader: baseColors.GrayedBlue,
  selectBorder: baseColors.DeepNavy,
  selectBackground: baseColors.Blue500,
  greenTick: '#8EB71B',
  yellowTick: '#FFA800',
  redTick: '#F73B60',
  investorTypeTileBackground: baseColors.Navy600,
};

export const lightThemeColors: { [key in Color]: string } = {
  primary: baseColors.Blue100,
  secondary: baseColors.Red,
  background: baseColors.Blue300,
  disabled: baseColors.Grey300,
  disabledSelectOption: baseColors.Grey250,
  disabledCheckbox: baseColors.Grey100,
  fontPrimary: baseColors.Blue400,
  fontPrimaryInverted: baseColors.White,
  fontSecondary: baseColors.Grey200,
  fontInput: baseColors.Blue400,
  dashboardBackground: baseColors.Grey300,
  border: baseColors.Blue300,
  maskBg: 'rgba(16, 50, 142, 0.5)',
  submitButton: baseColors.Blue200,
  submitButtonHover: baseColors.Blue150,
  lightButtonHover: baseColors.Grey300,
  header: baseColors.Blue400,
  inputBackground: baseColors.White,
  savedButton: baseColors.Green,
  selectArrowFill: baseColors.Blue300,
  selectArrowBackground: baseColors.Blue100,
  sectionBackground: baseColors.White,
  iconFill: baseColors.Blue100,
  tagBackground: baseColors.Blue300,
  tagFont: baseColors.Blue100,
  cardBackground: baseColors.White,
  cardTitle: baseColors.Blue100,
  cardSubTitle: baseColors.Blue400,
  layoutBackground: baseColors.Grey300,
  logoFill: baseColors.Grey100,
  tableBackground: baseColors.White,
  tableStripedRowBackground: `${baseColors.Blue300}26`, // HEX transparentize 15%
  tableAlternateHeaderColor: baseColors.Blue100,
  tableBorder: baseColors.Blue300,
  tableBody: baseColors.Black,
  tableHeader: baseColors.Grey200,
  profileMenu: baseColors.Blue100,
  backgroundHover: baseColors.Grey300,
  pageHeader: baseColors.Blue100,
  paginationButton: baseColors.Blue100,
  wyswigButton: baseColors.White,
  ellipseIcon: baseColors.Blue300,
  metricTitle: baseColors.Blue100,
  placeholder: baseColors.Grey220,
  metricFont: baseColors.Grey200,
  metricTooltip: baseColors.Grey100,
  activeMetricManagementTitle: baseColors.Grey100,
  activeMetricManagementDescription: baseColors.Grey200,
  interval: baseColors.Grey100,
  manageMetric: baseColors.Grey210,
  success: baseColors.Green,
  error: baseColors.Red,
  fileUploadingProgress: baseColors.Blue300,
  legalDocumentTitle: baseColors.Grey100,
  legalDocumentDesc: baseColors.Grey200,
  legalDocumentImage: baseColors.Grey200,
  legalDocumentHoverBackground: baseColors.White,
  activeFilter: baseColors.Grey100,
  infoLinkBackground: baseColors.White,
  infoLinkButton: baseColors.White,
  infoLinkButtonTextColor: baseColors.Blue200,
  infoLinkButtonHover: baseColors.Grey300,
  bannerBackground: `${baseColors.Blue200}1A`,
  bannerBorder: baseColors.Blue200,
  bannerWithTestimonialTestimonialBackground: baseColors.Blue100,
  bannerWithTestimonialTestimonialFontColor: baseColors.White,
  bannerWithTestimonialDescriptionColor: baseColors.Grey100,
  bannerWithTestimonialBackground: baseColors.White,
  bannerWithTestimonialBorder: baseColors.Blue300,
  closableInfoBackground: baseColors.Blue300,
  lockIcon: baseColors.Black,
  defaultAvatar: baseColors.Blue200,
  defaultAvatarPastel: baseColors.PinkPastel,
  avatarContent: baseColors.White,
  avatarContentPastel: baseColors.RedPastel,
  portfolioChartBackground: baseColors.White,
  chartBlueColor: 'rgba(62, 96, 235, 0.2)',
  bonusBorder: baseColors.Blue200,
  dropdownItem: baseColors.Blue100,
  fontBlue: baseColors.Blue200,
  link: baseColors.Blue200,
  companyDetailsEmptyState: baseColors.Grey210,
  removeButtonInputBackround: baseColors.White,
  removeButtonInputBorder: '#10328E',
  buttonText: baseColors.White,
  comingSoonLabel: baseColors.Blue100,
  grayedOutText: baseColors.Grey210,
  bannerLabelBackground: baseColors.White,
  bannerLabelText: baseColors.Grey200,
  slideshowActiveDot: baseColors.Blue200,
  slideshowInactiveDot: baseColors.Blue300,
  checkoutTile: checkoutColors.Black,
  checkoutHeader: checkoutColors.Red,
  checkoutLabel: checkoutColors.DarkGrey,
  checkoutPromo: checkoutColors.Red,
  checkoutBackground: checkoutColors.DarkGrey,
  addMetricColor: baseColors.Grey200,
  metricVisibilityBtn: baseColors.Blue200,
  dashboardBtn: baseColors.Blue200,
  panelBackground: baseColors.Grey300,
  checkboxBorder: baseColors.Blue300,
  checkboxTogglerBackground: '',
  checkboxTogglerBorder: baseColors.Blue100,
  dragShadowBackground: baseColors.Blue300,
  dragShadowBorder: baseColors.Blue200,
  ribbonFill: baseColors.Blue200,
  disabledRibbon: baseColors.Blue350,
  closeConnectionIconColor: baseColors.Blue200,
  linkHover: baseColors.Pink,
  benefitMain: baseColors.White,
  benefitSecondary: baseColors.Blue200,
  readinessScoreBackground: baseColors.White,
  readinessScoreMenuTab: baseColors.Black,
  referralFormImageBackground: baseColors.Grey300,
  tabButtonBackground: baseColors.White,
  connectedAdvisorButtonBackground: baseColors.Grey300,
};

export const darkThemeColors: { [key in Color]: string } = {
  primary: baseColors.Blue300,
  secondary: baseColors.Red,
  background: baseColors.Blue400,
  disabled: baseColors.Blue400,
  disabledSelectOption: baseColors.Grey250,
  disabledCheckbox: baseColors.Grey250,
  fontPrimary: baseColors.White,
  fontPrimaryInverted: baseColors.Blue400,
  fontSecondary: baseColors.Blue300,
  fontInput: baseColors.Blue300,
  dashboardBackground: baseColors.Blue400,
  border: baseColors.Blue600,
  maskBg: 'rgba(1, 2, 6, 0.78)',
  submitButton: baseColors.Blue200,
  submitButtonHover: baseColors.Blue150,
  lightButtonHover: baseColors.Grey300,
  header: baseColors.Blue300,
  inputBackground: baseColors.Blue400,
  savedButton: baseColors.Blue300,
  selectArrowFill: baseColors.Blue200,
  selectArrowBackground: baseColors.Blue400,
  sectionBackground: baseColors.Blue500,
  iconFill: baseColors.Blue200,
  tagBackground: baseColors.Blue300,
  tagFont: baseColors.Blue100,
  cardBackground: baseColors.Blue500,
  cardTitle: baseColors.White,
  cardSubTitle: baseColors.Blue300,
  layoutBackground: baseColors.Blue400,
  logoFill: baseColors.White,
  tableBackground: baseColors.Blue500,
  tableStripedRowBackground: baseColors.Blue550,
  tableAlternateHeaderColor: baseColors.White,
  tableBorder: baseColors.Blue600,
  tableHeader: baseColors.Grey225,
  tableBody: baseColors.Blue300,
  profileMenu: baseColors.Blue200,
  backgroundHover: baseColors.DeepNavy,
  pageHeader: baseColors.White,
  paginationButton: baseColors.Blue200,
  wyswigButton: baseColors.Blue300,
  ellipseIcon: baseColors.Blue500,
  metricTitle: baseColors.White,
  placeholder: baseColors.Grey220,
  metricFont: baseColors.Blue300,
  metricTooltip: baseColors.Grey100,
  activeMetricManagementTitle: baseColors.White,
  activeMetricManagementDescription: baseColors.Blue300,
  interval: baseColors.White,
  manageMetric: baseColors.Blue250,
  success: baseColors.Green,
  error: baseColors.Red,
  fileUploadingProgress: baseColors.Blue250,
  legalDocumentTitle: baseColors.Grey220,
  legalDocumentDesc: baseColors.Grey300,
  legalDocumentImage: baseColors.Grey300,
  legalDocumentHoverBackground: baseColors.Navy500,
  activeFilter: baseColors.White,
  infoLinkBackground: baseColors.Navy500,
  infoLinkButton: baseColors.Navy500,
  infoLinkButtonHover: baseColors.Navy600,
  infoLinkButtonTextColor: baseColors.Blue200,
  bannerBackground: `${baseColors.Blue200}1A`,
  bannerBorder: baseColors.Blue200,
  bannerWithTestimonialTestimonialBackground: baseColors.Blue100,
  bannerWithTestimonialTestimonialFontColor: baseColors.White,
  bannerWithTestimonialDescriptionColor: baseColors.White,
  bannerWithTestimonialBackground: baseColors.Blue500,
  bannerWithTestimonialBorder: baseColors.Blue100,
  closableInfoBackground: baseColors.Navy400,
  lockIcon: '#e5e9f9',
  defaultAvatar: baseColors.Blue200,
  defaultAvatarPastel: baseColors.PinkPastel,
  avatarContent: baseColors.White,
  avatarContentPastel: baseColors.RedPastel,
  portfolioChartBackground: baseColors.Blue400,
  chartBlueColor: 'rgba(62, 96, 235, 0.3)',
  bonusBorder: baseColors.Blue200,
  dropdownItem: baseColors.White,
  fontBlue: baseColors.Blue200,
  link: baseColors.Blue200,
  companyDetailsEmptyState: baseColors.Grey210,
  removeButtonInputBackround: baseColors.Blue500,
  removeButtonInputBorder: baseColors.White,
  buttonText: baseColors.White,
  comingSoonLabel: '#9C8392',
  grayedOutText: baseColors.Grey210,
  bannerLabelBackground: `${baseColors.Blue300}4D`,
  bannerLabelText: baseColors.White,
  slideshowActiveDot: baseColors.Blue200,
  slideshowInactiveDot: baseColors.Blue300,
  checkoutTile: checkoutColors.Black,
  checkoutHeader: checkoutColors.Red,
  checkoutLabel: checkoutColors.DarkGrey,
  checkoutPromo: checkoutColors.Red,
  checkoutBackground: checkoutColors.DarkGrey,
  addMetricColor: baseColors.Grey300,
  metricVisibilityBtn: baseColors.Blue200,
  dashboardBtn: baseColors.Blue200,
  panelBackground: baseColors.Blue400,
  checkboxBorder: baseColors.Navy400,
  checkboxTogglerBackground: '#2252c1',
  checkboxTogglerBorder: '#2252c1',
  dragShadowBackground: baseColors.Blue200,
  dragShadowBorder: baseColors.Grey300,
  ribbonFill: baseColors.Green,
  disabledRibbon: baseColors.Blue300,
  closeConnectionIconColor: baseColors.Green,
  linkHover: baseColors.Pink,
  benefitMain: baseColors.White,
  benefitSecondary: baseColors.Blue200,
  readinessScoreBackground: baseColors.Blue400,
  readinessScoreMenuTab: baseColors.White,
  referralFormImageBackground: 'transparent',
  tabButtonBackground: baseColors.Blue500,
  connectedAdvisorButtonBackground: baseColors.Blue400,
};

export const theme: Theme = {
  v2,
  fontFamily: {
    primary: 'Montserrat',
  },
  baseColors,
  chartColors,
  checkoutColors,
  onboardingColors,
  registerColors,
  color: { ...darkThemeColors },
  gradient: {
    green: 'linear-gradient(90deg, #8EB71B -22.86%, #A7DD0F 112.86%)',
    pink: 'linear-gradient(45deg, #d6455c 0%, #9747ff 100%)',
    yellow: 'linear-gradient(90deg, #FFA800 -22.86%, #FFCE6F 112.86%)',
    red: 'linear-gradient(90deg, #CA042B 3.68%, #F73B60 112.86%)',
    scrollThumb: 'linear-gradient(0deg, #1f242b 0%, #1f242b 100%), #12171d',
    scrollTrack: 'linear-gradient(0deg, #12171d 0%, #12171d 100%), #12171d;',
    auth: 'linear-gradient(to right top, rgba(105, 82, 188, 255) 0%, rgba(226, 64, 103, 255) 100%)',
    checkoutButtons: `linear-gradient(265deg, ${checkoutColors.Purple} 0%, ${checkoutColors.Red} 100%)`,
    checkoutTile: `linear-gradient(196.49deg,${checkoutColors.Purple} 0%, ${checkoutColors.Red} 100%);`,
    neon: 'linear-gradient(90deg, #BF0975 -22.86%, #622E7B 112.86%);',
    onboardingAsideGradient: 'linear-gradient(to right, #6b2b7b, #b01077);',
    onboardingInvestorTileGradient: 'linear-gradient(to left, #6b2b7b, #b01077);',
  },
  radius: {
    default: '5px',
    medium: '10px',
    big: '20px',
  },
  fontSize: {
    xxsmall: '11px',
    xsmall: '12px',
    small: '14px',
    regular: '16px',
    xmedium: '18px',
    medium: '20px',
    big: '34px',
    xlarge: '42px',
    large: '48px',
  },
  fontWeight: {
    bold: 700,
    semibold: 600,
    normal: 500,
    regular: 400,
    light: 300,
  },
  ui: {
    browseCompaniesSearchWidth: '540px',
    liveChartHeight: '230px',
    menuWidth: '200px',
    drawerWidth: '500px',
    chartHeight: '170px',
    feedIndent: '60px',
    feedIndentSmall: '50px',
    columnFiltersWidth: '300px',
    inputHeight: '50px',
    messagingHeight: '680px',
    minMessagingHeight: '400px',
    containerWidth: `${DESKTOP_CONTAINER_WIDTH}px`,
    notificationOverlayHeight: '485px',
    notificationOverlayWidth: '360px',
    desktopMenuHeight: '70px',
    mobileMenuHeight: '50px',
  },
  spacing: {
    xxsmall: '3px',
    xsmall: '5px',
    small: '10px',
    xmedium: '15px',
    medium: '20px',
    xbase: '30px',
    base: '40px',
    xlarge: '50px',
    large: '60px',
  },
  deviceBreakpoint: {
    xsmall: '480px',
    mobile: `${MOBILE_DEVICE_BREAKPOINT}px`,
    tablet: `${TABLET_DEVICE_BREAKPOINT}px`,
    desktop: `${DESKTOP_DEVICE_BREAKPOINT}px`,
    highResDesktop: `${HIGH_RES_DESKTOP_DEVICE_BREAKPOINT}px`,
  },
  appGlobalMargin: {
    default: '40px',
    mobile: '20px',
  },
};

import TickBase from '@assets/icons/green-tick-icon.svg?react';
import { gradientBorderStyles } from '@pages/content/onboarding/parts/aside/parts/aside-item/aside-item.styles';
import styled, { css } from 'styled-components';

export const tickStyles = css`
  width: 30px;
  height: 30px;
  position: absolute;
  top: 13px;
  right: 13px;

  path {
    fill: ${({ theme }) => theme.onboardingColors.redTick};
  }
`;

const Button = styled.button<{ $selected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 190px;
  position: relative;
  background: ${({ theme }) => theme.onboardingColors.investorTypeTileBackground};
  padding: ${({ theme }) => theme.spacing.medium};
  border-color: transparent;
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.highResDesktop}) {
    width: 225px;
    height: 220px;
  }

  &::before {
    ${gradientBorderStyles}
    pointer-events: none;

    ${({ $selected }) =>
      $selected &&
      css`
        background: ${({ theme }) => theme.gradient.onboardingInvestorTileGradient};
      `}
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

const Icon = styled.img<{ $small: boolean }>`
  width: auto;
  max-width: 100px;
  height: auto;
  max-height: 100px;

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.highResDesktop}) {
    max-width: 135px;
    max-height: 135px;
  }

  ${({ $small, theme }) =>
    $small &&
    css`
      width: auto;
      max-width: calc(100% - ${theme.spacing.xlarge});
      height: auto;
      max-height: 80px;
    `}
`;

const Title = styled.p`
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-top: ${({ theme }) => theme.spacing.medium};
  line-height: 1;
`;

const SubTitle = styled.p`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  margin-top: ${({ theme }) => theme.spacing.small};
  color: ${({ theme }) => theme.baseColors.White};
  white-space: pre-wrap;
`;

const Tick = styled(TickBase)`
  ${tickStyles}
`;

export default { Button, Icon, Title, SubTitle, Tick };

import type { MarketFitQuestionItem } from '@/pages/content/profile/founder/api/get-market-fit-questions/get-market-fit-questions.query';
import { MatchLevel } from '@pages/content/lens/parts/smart-match-progress/smart-match-progress';
import type { TeamMemberData } from '@pages/content/profile/founder/api/get-founder-team-members/get-founder-team-members.action';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import { ConnectionStatus, ConnectionType } from '@utils/type-definitions/connections';
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import type { RawDraftContentState } from 'draft-js';
import type { GraphqlResponse } from 'src/types';

export interface GetFounderPublicProfile {
  businessName: string;
  countryOfResidence: CountryCodes | null;
  hasInvestingRelationship: boolean;
  profilePicture: {
    path: string;
    url: string;
  };
  linkedinUrl: string;
  twitterUrl: string;
  about: RawDraftContentState;
  achievements: RawDraftContentState;
  founded: string;
  isNew: boolean;
  published: boolean;
  industries: string[];
  customerGroup: string[];
  productStage: string;
  investmentStage: string;
  hasSeisLimitsRemaining: boolean;
  hasEisLimitsRemaining: boolean;
  pitchDeck: {
    name: string;
    path: string;
    url: string;
  } | null;
  otherFiles: {
    name: string;
    path: string;
    url: string;
  }[];
  financialModelFiles: {
    name: string;
    path: string;
    url: string;
  }[];
  productVideoUrl: string;
  teamMembers: TeamMemberData[];
  marketFitAnswers: Omit<MarketFitQuestionItem, 'lengthLimits'>[];
  smartMatchLevel: MatchLevel;
  followInvitationId: string;
  requestIntroId: string | null;
  canConnect: boolean;
  connection: {
    acceptedAt: string | null;
    requestedAt: string;
    blacklistedAt: string | null;
    type: ConnectionType;
    status: ConnectionStatus;
  } | null;
}

export const GET_FOUNDER_PUBLIC_PAGE_DETAILS_CACHE_KEY = 'get-founder-public-page-details';

export const getFounderPublicProfileDetailsAction =
  (founderId: string) => async (): Promise<GraphqlResponse<GetFounderPublicProfile>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
      query getFounderPublicProfileDetails{
        getFounderPublicData(founderId:"${founderId}"){
          businessName
          countryOfResidence
          hasInvestingRelationship
          profilePicture {
            path
            url
          }
          linkedinUrl
          twitterUrl
          about
          achievements
          founded
          isNew
          published
          industries: industry
          achievements
          customerGroup
          productStage
          investmentStage
          hasSeisLimitsRemaining
          hasEisLimitsRemaining
          pitchDeck {
            name
            url
            path
          }
          otherFiles {
            url
            name 
            path
          }
          financialModelFiles {
            url
            name
            path
          }
          productVideoUrl
          teamMembers {
            name
            joined
            position
            experience
            area
            role
          }
          marketFitAnswers {
            questionCode
            booleanAnswer
            textAnswer
          },
          smartMatchLevel
          followInvitationId
          requestIntroId
          canConnect
          connection {
            acceptedAt 
            requestedAt
            blacklistedAt
            type
            status
          }
        }
      }
    `,
    });

    return graphqlResponseWrapper(data, ['getFounderPublicData']);
  };

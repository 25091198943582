import { useEnforceTheme } from '@/utils/hooks/use-enforce-theme/use-enforce-theme';
import SadIcon from '@assets/icons/sad-tear.svg?react';
import { CenterBlock } from '@layout/center-block/center-block.layout';
import { PatternLayout } from '@layout/pattern/pattern.layout';
import BigBrandHeading from '@parts/big-brand-heading/big-brand-heading';
import { Links } from '@router/links';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { NarrowLink } from './checkout.page.styles';
import { PaymentInfoContainer } from './payment-info.page.styles';

export const PaymentCancelledPage = () => {
  useEnforceTheme('dark');

  const [headingLabel, descriptionLabel, buttonLabel] = useTranslation([
    'paymentCancelled.heading',
    'paymentCancelled.description',
    'paymentCancelled.button',
  ]);

  return (
    <PatternLayout>
      <CenterBlock gradient>
        <PaymentInfoContainer data-testid="payment-info-container-canceled">
          <SadIcon data-testid="payment-sad-icon" />
          <BigBrandHeading>{headingLabel}</BigBrandHeading>
          <p>{descriptionLabel}</p>
          <NarrowLink data-testid="go-to-login-button" to={Links.LOGIN()}>
            {buttonLabel}
          </NarrowLink>
        </PaymentInfoContainer>
      </CenterBlock>
    </PatternLayout>
  );
};

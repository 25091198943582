import message from '@/parts/message/message';
import { getServerError } from '@/utils/fns/get-server-error';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { postConnectionNoteAction } from '../api/post-connection-note.action';
import { FOUNDER_CONNECTION_NOTE_KEY } from './use-update-connection-note';

export const useCreateConnectionNote = ({
  onSuccessCb,
  onErrorCb,
}: {
  onSuccessCb?: () => void | Promise<unknown>;
  onErrorCb?: () => void | Promise<unknown>;
} = {}) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: postConnectionNoteAction,
    onSuccess: () => {
      queryClient.invalidateQueries([FOUNDER_CONNECTION_NOTE_KEY]);
      message.success({ content: 'The note has been saved' });
      onSuccessCb?.();
    },
    onError: (error: AxiosError) => {
      message.error({ content: getServerError(error) });
      onErrorCb?.();
    },
  });

  return {
    isCreateConnectionNoteLoading: mutation.isLoading,
    isCreateConnectionNoteError: mutation.isError,
    createConnectionNoteMutation: mutation.mutateAsync,
    rawMutation: {
      ...mutation,
    },
  };
};

import type { AdvisorFounderPendingConnection } from '@/modules/advisor/connections/types';
import type { FounderPendingConnection } from '@/modules/founder/connections/types';
import type { InvestorAdvisorFounderInvites } from '@/modules/investor-advisor/connections/types';
import { createContext, useContext } from 'react';

const V2AdvisorConnectionCardContext = createContext<{
  pendingConnection: AdvisorFounderPendingConnection | InvestorAdvisorFounderInvites | FounderPendingConnection;
} | null>(null);

export const useV2AdvisorConnectionCardContext = () => {
  const context = useContext(V2AdvisorConnectionCardContext);
  if (!context)
    throw new Error(
      'V2AdvisorConnectionCard.* component must be rendered as child of V2AdvisorConnectionCard component',
    );

  return context;
};

export { V2AdvisorConnectionCardContext };

import type { ReactNode } from 'react';
import S from './closable-info.styles';

export const ClosableInfo = ({ text, uniqueKey }: { text: ReactNode; uniqueKey: string }) => {
  const key = `closable-info-${uniqueKey}`;
  const isHidden = Boolean(localStorage.getItem(key));

  const onClose = () => {
    localStorage.setItem(key, 'true');
  };

  return !isHidden ? (
    <S.Alert
      type="info"
      onClose={onClose}
      closable
      closeText={<S.Close data-testid="toast-close-icon" />}
      showIcon
      description={text}
      message=""
      data-testid="closable-info"
    />
  ) : null;
};

export default ClosableInfo;

import { useV2AdvisorConnectionCardContext } from '../v2-advisor-connection-card-context';

import S from '../v2-advisor-connection-card.styles';

type ListProps = {
  children: React.ReactNode;
};

export const List = ({ children }: ListProps) => {
  useV2AdvisorConnectionCardContext();

  return <S.List>{children}</S.List>;
};

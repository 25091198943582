import { SecureLink } from '@parts/link/link';
import styled from 'styled-components';

const TitleLink = styled(SecureLink)`
  display: flex;
  text-decoration: none;

  img {
    position: relative;
    bottom: 1px;
    margin: 0 10px;
  }
`;

const Title = styled.h3`
  text-align: center;
  color: ${({ theme }) => theme.color.fontPrimary};
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const MailBoxList = styled.ul`
  width: 100%;
  max-width: 800px;
  margin: ${({ theme }) => theme.spacing.base} auto 0;
  color: ${({ theme }) => theme.color.fontPrimary};
  list-style: none;
`;

const MailBoxItem = styled.li`
  display: flex;
  padding: ${({ theme }) => theme.spacing.xbase} 0;
  border-top: 1px solid ${({ theme }) => theme.color.border};
`;

const MailBoxImageWrapper = styled.div`
  width: 60px;
  margin-right: ${({ theme }) => theme.spacing.medium};
  flex-shrink: 0;

  @media screen and (min-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    width: 80px;
  }

  @media screen and (min-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) {
    width: 100px;
    margin-right: ${({ theme }) => theme.spacing.xbase};
  }

  img {
    max-width: 100%;
  }

  a {
    display: block;

    &:focus,
    &:active {
      outline: 1px dashed ${({ theme }) => theme.color.primary};
    }
  }
`;

const MailBoxTitle = styled.h4`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.color.fontPrimary};
  font-size: ${({ theme }) => theme.fontSize.regular};
`;

const StepsList = styled.ol`
  margin-top: ${({ theme }) => theme.spacing.small};
  padding-left: ${({ theme }) => theme.spacing.medium};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export default {
  Title,
  MailBoxList,
  MailBoxItem,
  MailBoxImageWrapper,
  MailBoxTitle,
  StepsList,
  TitleLink,
};

import { axiosInstance } from '@utils/axios/axios-instance';
import type { AxiosConfig } from '@utils/axios/types';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_PROGRESS_REPORT_BOOLEAN_METRIC_VALUE_CACHE_KEY = (id: string) =>
  `progress-report-boolean-metric-value-${id}`;

export const getProgressReportBooleanMetricValue =
  (hash: string, password: string | null, id: string) =>
  async (): Promise<GraphqlResponse<{ value: boolean | null; note: string | null }>> => {
    const { data } = await axiosInstance.post(
      '/query',
      {
        query: `
      query getProgressReportBooleanMetricValue {
        getPublicDashboardBooleanMetricValue(hash: "${hash}", password: "${password}", metricId: "${id}") {
          value,
          note,
        }
      }
    `,
      },
      { skipAuthorization: true } as AxiosConfig,
    );

    return graphqlResponseWrapper(data, ['getPublicDashboardBooleanMetricValue']);
  };

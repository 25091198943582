import { useReducer, type ReactNode, type Reducer } from 'react';
import { RoleManagementContext, roleManagementInitialState } from './management.context';
import { roleManagementReducer, type RoleManagementActionType, type RoleManagementState } from './management.reducer';

interface RoleManagementStateProviderProps {
  children: ReactNode;
}

export const RoleManagementStateProvider = ({ children }: RoleManagementStateProviderProps) => {
  const [state, dispatch] = useReducer<Reducer<RoleManagementState, RoleManagementActionType>>(
    roleManagementReducer,
    roleManagementInitialState,
  );

  return <RoleManagementContext.Provider value={{ state, dispatch }}>{children}</RoleManagementContext.Provider>;
};

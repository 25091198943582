import { Menu as AntdMenu } from 'antd';
import styled from 'styled-components';

const Menu = styled(AntdMenu)``;

const Item = styled(AntdMenu.Item)`
  font-size: ${({ theme }) => theme.v2.typography.sizes.md};
  padding: 4px 2px;
`;

export default { Menu, Item };

import { getIndustriesOptionAction } from '@pages/content/advisors-search/api/get-industries-options/get-industries-options.action';
import { GET_SMART_MATCH_OPTIONS_REQUESTS_CACHE_KEY } from '@pages/content/profile/api/get-smart-match-options/get-smart-match-options.action';
import { MultiSelectSearch } from '@parts/multiselect-search/multiselect-search';
import { useQuery } from '@tanstack/react-query';
import { sortArrayAlphabetically } from '@utils/fns/sortArrayAlphabetically';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { SelectValue } from 'antd/lib/select';
import type { FieldConfig, FieldInputProps, FormikErrors } from 'formik';
import type { FormikFiltersData } from '../filters';
import S from './industries.styles';

export const IndustriesFilter = ({
  setFieldValue,
  getFieldProps,
}: {
  setFieldValue: (field: string, value: SelectValue | null) => Promise<void | FormikErrors<FormikFiltersData>>;
  getFieldProps: (nameOrOptions: string | FieldConfig<unknown>) => FieldInputProps<unknown>;
}) => {
  const [industriesLabel] = useTranslation(['advisors.search.filters.industries']);

  const { data: response } = useQuery([GET_SMART_MATCH_OPTIONS_REQUESTS_CACHE_KEY], getIndustriesOptionAction);

  const handleSelectChange = (selectKey: string) => (value: SelectValue) => {
    setFieldValue(selectKey, value);
  };

  const options = response?.data && response.data.length > 0 ? sortArrayAlphabetically(response.data) : [];

  return (
    <S.FormikField
      label={{
        for: 'industries',
        label: <span>{industriesLabel}</span>,
      }}
    >
      <MultiSelectSearch
        {...getFieldProps('industries')}
        data-testid="industries-field"
        id="industries"
        showArrow={false}
        options={options}
        onChange={handleSelectChange('industries')}
      />
    </S.FormikField>
  );
};

import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const V2FilterPanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  min-height: 42px;
  border: 1px solid;
  border-color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[100], theme.v2.colors.neutral[500])};
  border-radius: ${({ theme }) => theme.v2.borders.radius.md};
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[100], theme.v2.colors.neutral[700])};
  line-height: 1;
  font-size: ${({ theme }) => theme.v2.typography.sizes.xs};
  font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
`;

const FilterSwitch = styled.ul`
  display: flex;
  gap: 40px;
  list-style: none;
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[700], theme.v2.colors.neutral[300])};

  li {
    input {
      position: absolute;
      opacity: 0;
      pointer-events: none;

      &:checked + label {
        border-bottom: 2px solid currentColor;
      }

      &:disabled + label {
        opacity: 0.65;
      }
    }

    label {
      color: inherit;
      cursor: pointer;
      transition: opacity 250ms;
    }
  }
`;

const Search = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 400px;
  margin-right: 0px;
  line-height: 1;
  font-size: ${({ theme }) => theme.v2.typography.sizes.xs};
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[500], theme.v2.colors.neutral[400])};

  ${() =>
    customMediaQuery('margin-right', {
      lg: '100px',
    })}
`;

export default { V2FilterPanel, FilterSwitch, Search };

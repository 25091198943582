import styled from 'styled-components';

const StyledEllipsisText = styled.span`
  font-size: inherit;
  color: inherit;

  button {
    color: ${({ theme }) => theme.color.link};

    background: none;
    border: none;
    display: inline;
    line-height: inherit;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
    }
  }
`;

export default { StyledEllipsisText };

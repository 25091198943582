import type { OnboardingStepError } from '@context/founder-onboarding/founder-onboarding.context';
import { InvestorType } from '@pages/content/profile/investor/api/types';
import { removeDuplicates } from '@utils/fns/remove-duplicates';
import type { AppAction } from 'src/types';
import { InvestorOnboardingActions } from './investor-onboarding.actions';
import type { InvestorOnboardingState } from './investor-onboarding.context';
import type { InvestorOnboardingStep } from '@pages/content/onboarding/investor/paths';

export const initialInvestorOnboardingState: InvestorOnboardingState = {
  loaded: false,
  started: false,
  finished: false,
  accountType: null,
  institutionType: null,
  stepErrors: [],
};

export type InvestorOnboardingActionType =
  | AppAction<typeof InvestorOnboardingActions.SET_ONBOARDING_STATE, { payload: InvestorOnboardingState }>
  | AppAction<typeof InvestorOnboardingActions.SET_ONBOARDING_ACCOUNT_TYPE, { payload: InvestorType }>
  | AppAction<
      typeof InvestorOnboardingActions.ADD_ONBOARDING_STEP_ERROR,
      { payload: OnboardingStepError<InvestorOnboardingStep> }
    >
  | AppAction<typeof InvestorOnboardingActions.REMOVE_ONBOARDING_STEP_ERROR, { payload: string }>;

export const reducer = (
  state: InvestorOnboardingState,
  action: InvestorOnboardingActionType,
): InvestorOnboardingState => {
  switch (action.type) {
    case InvestorOnboardingActions.SET_ONBOARDING_STATE:
      return { ...state, ...action.payload };

    case InvestorOnboardingActions.SET_ONBOARDING_ACCOUNT_TYPE:
      return { ...state, accountType: action.payload };

    case InvestorOnboardingActions.ADD_ONBOARDING_STEP_ERROR:
      return { ...state, stepErrors: removeDuplicates([action.payload, ...state.stepErrors]) };

    case InvestorOnboardingActions.REMOVE_ONBOARDING_STEP_ERROR:
      return { ...state, stepErrors: state.stepErrors.filter((item) => item.id !== action.payload) };

    default:
      return state;
  }
};

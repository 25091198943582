import { EmptyState as EmptyStateBase } from '@parts/empty-state/empty-state';
import styled from 'styled-components';

const EmptyState = styled(EmptyStateBase)`
  path {
    fill: ${({ theme }) => theme.baseColors.Grey210};
  }

  > h3 {
    color: ${({ theme }) => theme.baseColors.Grey210};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    margin: 15px 0 25px;
  }
`;

export default { EmptyState };

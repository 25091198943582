import styled from 'styled-components';
import { FormikField } from '../formik-field/formik-field';

const CheckboxFormikField = styled(FormikField)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;

  > div {
    width: auto;
  }
  .ant-checkbox-wrapper {
    margin: 0;
    margin-right: 15px;
  }

  label {
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    margin-bottom: 0;
  }
`;
export default { CheckboxFormikField };

import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import * as Yup from 'yup';

export const useValidationSchema = () => {
  const [errorLabel] = useTranslation(['investor-onboarding.business.targetGeography.validation.notBoth']);

  return Yup.object({
    industries: Yup.array().of(Yup.string()).nullable(),
    productStage: Yup.array().of(Yup.string()).nullable(),
    investmentStage: Yup.array().of(Yup.string()).nullable(),
    customerGroup: Yup.array().of(Yup.string()).nullable(),
    targetGeography: Yup.array()
      .of(Yup.string())
      .test('global-or-others', errorLabel, function (value) {
        if (value === null) return true;
        return !(value!.includes('Global') && value!.length > 1);
      }),
  });
};

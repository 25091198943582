import { axiosInstance } from '@utils/axios/axios-instance';

export interface TextBox {
  title: string | null;
  mainText: string | null;
}

export const updateTextBoxAction = async ({
  payload,
  dashboardId,
  textBoxId,
}: {
  payload: TextBox;
  dashboardId: string;
  textBoxId: string;
}) => axiosInstance.patch(`/pulse/dashboards/${dashboardId}/text-boxes/${textBoxId}`, payload);

import styled from 'styled-components';

const Wrapper = styled.div``;

const Title = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-top: ${({ theme }) => theme.spacing.xbase};
`;

const List = styled.ul`
  margin-left: ${({ theme }) => theme.spacing.medium};
  margin-top: ${({ theme }) => theme.spacing.xbase};
`;

const Item = styled.li``;

export default { Wrapper, Title, List, Item };

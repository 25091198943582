import styled from 'styled-components';

const Wrapper = styled.div`
  border-radius: 10px;
  background-color: ${({ theme }) => theme.onboardingColors.mobileTileBackground};
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.small};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.span`
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

export default { Wrapper, Title };

import { Tag as TagBase } from 'antd';
import { transparentize } from 'polished';
import styled from 'styled-components';

const Wrapper = styled(TagBase)`
  border: 0;

  font-size: 14px;
  font-weight: 600;
  line-height: 24px;

  &.ant-tag {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-left: 35px;

    > svg {
      position: absolute;
      left: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  &.ant-tag-success {
    background-color: ${({ theme }) => transparentize(0.9, theme.baseColors.Green)};
    span {
      color: ${({ theme }) => theme.baseColors.Green};
    }
  }

  &.ant-tag-error {
    background-color: ${({ theme }) => transparentize(0.9, theme.baseColors.Red)};
    span {
      color: ${({ theme }) => theme.baseColors.Red};
    }
  }
`;

export default { Wrapper };

import styled from 'styled-components';
import { themedStyle } from '../../../styles/v2-theme/utils/themed-style';
import type { V2IconBoxSize } from './v2-icon-box.types';

const sizes = new Map<V2IconBoxSize, string>([
  ['xs', '28px'],
  ['sm', '40px'],
  ['md', '48px'],
  ['lg', '60px'],
]);

const V2IconBox = styled.div<{ $size?: V2IconBoxSize }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ $size }) => sizes.get($size ?? 'md')};
  width: ${({ $size }) => sizes.get($size ?? 'md')};
  min-height: ${({ $size }) => sizes.get($size ?? 'md')};
  min-width: ${({ $size }) => sizes.get($size ?? 'md')};
  border-radius: ${({ theme }) => theme.v2.borders.radius.sm};
  background-color: ${({ theme }) => theme.v2.colors.blue[300]};
  color: ${({ theme }) => theme.v2.colors.neutral[50]};
`;

const Primary = styled(V2IconBox)``;

const Secondary = styled(V2IconBox)`
  background-color: ${({ theme }) => theme.v2.colors.magenta[400]};
`;

const Neutral = styled(V2IconBox)`
  background-color: ${({ theme }) =>
    themedStyle(theme.v2.mode, theme.v2.colors.neutral[400], theme.v2.colors.blue[500])};
`;

const NeutralLight = styled(V2IconBox)`
  background-color: ${({ theme }) =>
    themedStyle(theme.v2.mode, theme.v2.colors.neutral[300], theme.v2.colors.blue[500])};
`;

export default { Primary, Secondary, Neutral, NeutralLight };

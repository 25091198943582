import LogoBase from '@assets/brand/logo.svg?react';
import { Button } from 'antd';
import styled from 'styled-components';

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.onboardingColors.headerBackground};
  padding: ${({ theme }) => theme.spacing.small};
`;

const Logo = styled(LogoBase)`
  width: 160px;
  margin-left: ${({ theme }) => theme.spacing.small};

  path:first-of-type {
    fill: ${({ theme }) => theme.onboardingColors.logoFill} !important;
  }
`;

const SignOut = styled(Button)`
  color: ${({ theme }) => theme.color.fontBlue};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
`;

export default {
  Nav,
  Logo,
  SignOut,
};

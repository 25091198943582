import { V2Modal } from '@/components/ui/v2-modal/v2-modal';
import { useWizard } from '@/hooks/use-wizard';
import { AddExperienceProvider, useAddExperienceContext } from './add-experience-modal.context';
import { AllDone } from './steps/all-done';
import { InsertData } from './steps/insert-data';
import { SelectCompany } from './steps/select-company';
import { Summary } from './steps/summary';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';

type ModalProps = {
  isOpen: boolean;
  close: () => void;
};

const steps = [
  {
    id: 'select-company-step',
    component: SelectCompany,
  },
  {
    id: 'insert-data-step',
    component: InsertData,
  },
  {
    id: 'summary-step',
    component: Summary,
  },
  {
    id: 'all-done-step',
    component: AllDone,
  },
];

const AddExperienceModalContent = ({ isOpen, close }: ModalProps) => {
  const [title] = useTranslation(['profile.advisor.exp.addExperience']);
  const { currentStep, progress, nextStep, previousStep, setStep } = useWizard({ steps, init: 0 });
  const { dispatch } = useAddExperienceContext();
  const CurrentStepComponent = steps[currentStep].component;

  const onCancel = () => {
    if (close) {
      close();
      setStep(0);
      dispatch({ type: 'RESET' });
    }
  };

  return (
    <V2Modal
      maskCollapse={false}
      title={title}
      isOpen={isOpen}
      closable={true}
      onCancel={onCancel}
      footer={null}
      size="lg"
      progress={progress}
      data-testid="experience-modal"
    >
      <CurrentStepComponent next={nextStep} back={previousStep} close={onCancel} />
    </V2Modal>
  );
};

export const AddExperienceModal = ({ isOpen, close }: ModalProps) => {
  return (
    <AddExperienceProvider>
      <AddExperienceModalContent isOpen={isOpen} close={close} />
    </AddExperienceProvider>
  );
};

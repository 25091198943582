import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';
import type { MetricFilterMode } from './get-browse-companies-filters';

export interface MetricOptions {
  name: string;
  code: string;
  min: number;
  max: number;
  step: number;
  filteringMode: MetricFilterMode;
}

export interface BrowseCompaniesMetrics {
  availableMetrics: MetricOptions[];
}

export const GET_BROWSE_COMPANIES_METRICS_CACHE_KEY = 'get-browser-companies-metrics';

export const getBrowseCompaniesMetrics = async (): Promise<GraphqlResponse<BrowseCompaniesMetrics>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getBrowseCompaniesMetrics { 
        getDataForCompanyFilter {
          availableMetrics {
            name
            code
            min
            max
            step
          }
        }
      }
    `,
  });

  return graphqlResponseWrapper(data, ['getDataForCompanyFilter']);
};

import { useDebouncedCallback } from '@/hooks/use-debounced-callback';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { useRef, useState } from 'react';
import { V2Icon } from '../v2-icon/v2-icon';
import type { V2SearchInputFormProps } from './v2-search-input-form.types';

import S from './v2-search-input-form.styles';

const DEBOUNCED_SUBMIT_DELAY = 250;

export const V2SearchInputForm = ({
  initialValue,
  onSubmit,
  label,
  disabled = false,
  ['data-testid']: testId = 'v2-search-input-form',
  ...restProps
}: V2SearchInputFormProps) => {
  const [defaultSearchLabel] = useTranslation(['global.search']);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isActivated, setIsActivated] = useState(Boolean(initialValue));
  const [value, setValue] = useState(() => initialValue);

  const debouncedSubmit = useDebouncedCallback(() => onSubmit(value), DEBOUNCED_SUBMIT_DELAY);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!isActivated) {
      setIsActivated(true);
      inputRef.current?.focus();
      return;
    }

    setIsActivated(Boolean(value));
    debouncedSubmit();
  };

  return (
    <S.V2SearchInputForm $isActive={isActivated} onSubmit={handleSubmit} data-testid={testId} {...restProps}>
      <label htmlFor="filter-search">{label ?? defaultSearchLabel}</label>
      <input
        ref={inputRef}
        id="filter-search"
        type="text"
        value={value}
        onFocus={() => setIsActivated(true)}
        onChange={(e) => setValue(e.target.value)}
        onBlur={debouncedSubmit}
        disabled={disabled}
      />
      <button type="submit" title={defaultSearchLabel} disabled={disabled}>
        <V2Icon name="search" />
      </button>
    </S.V2SearchInputForm>
  );
};

import { EmptyState } from '@parts/empty-state/empty-state';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { Pagination } from '@parts/pagination/pagination';
import S from './items-grid.styles';

export const DEFAULT_ITEMS_PER_PAGE_IN_GRID = 6;
export const INVESTOR_ITEMS_PER_PAGE_IN_GRID = 4;

const EmptyIconImage = (props: { imageUrl: string }) => <img src={props.imageUrl} alt="empty-icon" loading="lazy" />;

export const ItemsGrid = ({
  columns = 3,
  data,
  Item,
  additionalData,
  keyBase,
  className,
  pagination,
  itemProps = {},
  emptyConfig,
  isLoading,
}: {
  data: (object & { id: string })[];
  additionalData?: object;
  Item: Function;
  keyBase: string;
  emptyConfig?: {
    isEmpty: boolean;
    iconUrl: string;
    title: string;
    subTitle: string;
  };
  itemProps?: object;
  pagination?: {
    handleNext: () => void;
    handlePrev: () => void;
    maxPages: number;
    currentPage: number;
  };
  isLoading?: boolean;
  columns?: 2 | 3 | 4;
  className?: string;
}) => {
  if (isLoading) return <FullHeightSpinner />;

  return (
    <S.Wrapper>
      {emptyConfig?.isEmpty ? (
        <EmptyState
          Icon={() => EmptyIconImage({ imageUrl: emptyConfig.iconUrl })}
          title={emptyConfig.title}
          subTitle={emptyConfig.subTitle}
        />
      ) : (
        <>
          <S.Grid className={className} columns={columns}>
            {data.map((r) => (
              <Item {...itemProps} {...additionalData} key={`${keyBase}-${r.id}`} {...r} />
            ))}
          </S.Grid>
          {pagination && pagination.maxPages > 1 && <Pagination centered {...pagination} />}
        </>
      )}
    </S.Wrapper>
  );
};

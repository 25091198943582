import { SubmitButton as SubmitButtonBase } from '@parts/submit-button/submit-button';
import { TableColumn as TableColumnBase } from '@parts/table-column/table-column';
import styled from 'styled-components';

const SubmitButton = styled(SubmitButtonBase)`
  padding: 0;

  &:disabled {
    svg,
    path {
      opacity: 0.7;
    }
  }

  &:disabled:hover {
    border: none;
  }
`;

const TableColumn = styled(TableColumnBase)`
  display: flex;
  justify-content: center;
`;

export default { SubmitButton, TableColumn };

import { V2SuggestionBanner } from '@/components/ui/v2-suggestion-banner/v2-suggestion-banner';
import { useModal } from '@/hooks/use-modal';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { AddExperienceModal } from '../add-experience-modal/add-experience-modal';
import S from './experience-section.styles';
import { ExperienceList } from './components/experience-list/experience-list';
import { useGetExperience } from '../../hooks/use-get-experience';
import { V2Loader } from '@/components/ui/v2-loader/v2-loader';

export const ExperienceSection = () => {
  const [title, description, banner, addExperience] = useTranslation([
    'profile.advisor.exp.title',
    'profile.advisor.exp.description',
    'profile.advisor.exp.banner',
    'profile.advisor.exp.addExperience',
  ]);

  const { experience = [], isExperienceLoading } = useGetExperience();

  const { close, isOpen, open } = useModal();

  if (isExperienceLoading) return <V2Loader />;

  const hasExperience = Boolean(experience?.length);

  return (
    <>
      <S.Card>
        <S.Title>{title}</S.Title>
        <S.Paragraph>{description}</S.Paragraph>
        {hasExperience && <ExperienceList experienceData={experience!} />}
        {!hasExperience && (
          <V2SuggestionBanner
            text={banner}
            icon="bell"
            variant="primary"
            data-testid="curated-feed-suggestion-banner"
          />
        )}
        <S.ButtonWrapper>
          <S.Button startIcon="plus" variant="link" isResponsive size="large" onClick={open}>
            {addExperience}
          </S.Button>
        </S.ButtonWrapper>
      </S.Card>
      <AddExperienceModal isOpen={isOpen} close={close} />
    </>
  );
};

import { useReducer } from 'react';
import type { AddExperienceFormSchemaType } from './types';

export type Action =
  | { type: 'SET_SEARCH_PHRASE'; payload: string }
  | { type: 'SET_COMPANY_ID'; payload: string | undefined }
  | { type: 'SET_FORM_DATA'; payload: AddExperienceFormSchemaType }
  | { type: 'RESET' };

export type State = {
  searchPhrase: string;
  companyId?: string;
  formData: AddExperienceFormSchemaType;
};

export const initialState: State = {
  searchPhrase: '',
  companyId: undefined,
  formData: {
    name: '',
    role: '',
    industry: [],
    email: undefined,
    startDate: '',
    endDate: undefined,
    isOngoing: false,
    compensationType: '-',
    skillSet: [],
    experienceSummary: undefined,
  },
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_SEARCH_PHRASE':
      return { ...state, searchPhrase: action.payload.length <= 100 ? action.payload : state.searchPhrase };
    case 'SET_COMPANY_ID':
      return { ...state, companyId: action.payload };
    case 'SET_FORM_DATA':
      return { ...state, formData: action.payload };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};

export const useAddExperienceReducer = (): [State, React.Dispatch<Action>] => {
  return useReducer(reducer, initialState);
};

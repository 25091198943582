import {
  GET_SAVED_PARAMS_FOR_QUERY_CACHE_KEY,
  getSavedParamsForQuery,
  type LastSearchDataResponseData,
  type SavedParamsForQueryKey,
} from '@/pages/content/api/get-saved-params-for-query';
import type { GraphqlResponse } from '@/types';
import { useQuery } from '@tanstack/react-query';

export const useSavedParamsForQuery = ({
  queryKey,
  enabled,
  onSuccess,
}: {
  queryKey: SavedParamsForQueryKey;
  enabled: boolean;
  onSuccess?: (data: GraphqlResponse<LastSearchDataResponseData>) => void;
}) => {
  const { data, isFetching, isInitialLoading, ...restQueryResult } = useQuery(
    [GET_SAVED_PARAMS_FOR_QUERY_CACHE_KEY, queryKey],
    getSavedParamsForQuery(queryKey),
    { refetchOnWindowFocus: false, enabled, onSuccess, cacheTime: 0 },
  );

  return {
    ...restQueryResult,
    savedParamsDataResponse: data,
    isInitialSavedParamsLoading: isInitialLoading,
    isSavedParamsFetching: isFetching,
  };
};

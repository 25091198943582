import { useEffect, useState } from 'react';

type PeriodicNotificationOptions = Partial<{ days: number; hours: number; minutes: number }>;

const buildKey = (key: string) => `periodic-reopen-${key}`;
const calculateHideDuration = (days = 0, hours = 0, minutes = 0) =>
  days * 24 * 60 * 60 * 1000 + hours * 60 * 60 * 1000 + minutes * 60 * 1000;

export const useResetPeriodicNotification = (key: string) => {
  const notificationKey = buildKey(key);

  const reset = () => {
    localStorage.removeItem(notificationKey);
  };

  return { reset };
};

// TODO: change the implementation to use the backend-provided time data
// We should not solely depend on user device time but rather on time data received from the backend for better reliability and security.
// Relying on backend-provided time data helps avoid potential issues related to user device time manipulation or inaccuracies.
export const usePeriodicNotification = (key: string, { days, hours, minutes }: PeriodicNotificationOptions) => {
  if ((days && days < 0) || (hours && hours < 0) || (minutes && minutes < 0))
    throw new Error('Days, hours, and minutes must be greater or equal to 0');

  const notificationKey = buildKey(key);
  const hideDuration = calculateHideDuration(days, hours, minutes);
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);

  const hideNotification = () => {
    localStorage.setItem(notificationKey, Date.now().toString());
    setIsNotificationVisible(false);
  };

  useEffect(() => {
    const notificationClosedTimestamp = localStorage.getItem(notificationKey);

    if (!notificationClosedTimestamp) {
      setIsNotificationVisible(true);
      return;
    }

    const closedAt = parseInt(notificationClosedTimestamp, 10);
    const reopenAt = closedAt + hideDuration;

    const shouldBeVisible = Date.now() > reopenAt;

    setIsNotificationVisible(shouldBeVisible);
    if (shouldBeVisible) localStorage.removeItem(notificationKey);
  }, [notificationKey, hideDuration]);

  return { isNotificationVisible, hideNotification };
};

import InfoIconBase from '@assets/icons/info-circle.svg?react';
import RemoveIconBase from '@assets/icons/trash.svg?react';
import { FormikField as FormikFieldBase } from '@parts/forms/formik-field/formik-field';
import { FormikRow as FormikRowBase } from '@parts/forms/formik-row/formik-row';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.backgroundHover};
  padding: ${({ theme }) => theme.spacing.medium};
  margin-top: ${({ theme }) => theme.spacing.medium};

  & + & {
    margin-top: ${({ theme }) => theme.spacing.medium};
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    padding: ${({ theme }) => theme.spacing.small};
  }
`;

const FormikField = styled(FormikFieldBase)`
  margin-bottom: 0;
`;

const FormikRow = styled(FormikRowBase)`
  &:last-of-type {
    margin: 0;
  }
`;

const Asterisk = styled.span`
  color: red;
  display: inline-block;
`;

const InfoIcon = styled(InfoIconBase)`
  margin-left: ${({ theme }) => theme.spacing.xsmall};
  position: relative;
  top: 2px;
`;

const fullWidthMobile = css`
  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-basis: 100%;
  }
`;

const InvestmentRoundField = styled(FormikField)`
  flex-basis: 60%;

  ${fullWidthMobile};
`;

const DateField = styled(FormikField)`
  flex-basis: 40%;

  ${fullWidthMobile};
`;

const RemoveIcon = styled(RemoveIconBase)`
  width: 15px;
  position: absolute;
  right: 20px;
  top: 20px;

  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
`;

export default {
  Wrapper,
  FormikRow,
  FormikField,
  InvestmentRoundField,
  DateField,
  InfoIcon,
  Asterisk,
  RemoveIcon,
};

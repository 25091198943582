import { axiosInstance } from '@utils/axios/axios-instance';
import type { AxiosResponse } from '@utils/axios/types';

export interface UserIdentityResponse {
  signature: string;
  appId: string;
}

export const GET_USER_IDENTITY_ACTION = 'messaging/get-user-identity';

export const getUserIdentityAction = (): Promise<AxiosResponse<UserIdentityResponse>> =>
  axiosInstance.get('/messaging/identity');

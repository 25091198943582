import styled from 'styled-components';

const Description = styled.div`
  max-width: 700px;
  margin: ${({ theme }) => theme.spacing.xmedium} auto ${({ theme }) => theme.spacing.xlarge};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  text-align: center;
  white-space: pre-line;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 800px;
  margin: -${({ theme }) => theme.spacing.xbase} auto;
`;

const ListElement = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing.medium} ${({ theme }) => theme.spacing.xbase};
`;

const ListElementTitle = styled.p`
  margin-top: ${({ theme }) => theme.spacing.small};
`;

export default { ListElement, List, Description, ListElementTitle };

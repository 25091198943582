import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { CurrencyValue } from '@utils/type-definitions/currencies';
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import type { GraphqlResponse } from 'src/types';

export interface AccountCountryAndCurrencyResponse {
  currency: CurrencyValue | null;
  countryOfResidence: CountryCodes | null;
}

export const ACCOUNT_COUNTRY_AND_CURRENCY_CACHE_KEY = 'account-country-and-currency';

export const getAccountCountryAndCurrency = async (): Promise<GraphqlResponse<AccountCountryAndCurrencyResponse>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
        query getAccountCountryAndCurrency {
          account { 
            currency
            countryOfResidence
          }
        }
    `,
  });

  return graphqlResponseWrapper(data, ['account']);
};

import { ShimmerBadge } from '@parts/shimmer-badge/shimmer-badge';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './tile.styles';

const Tile = ({
  comingSoonBadge = false,
  externalLink = false,
  title,
  description,
  link,
  Img,
  disabled = false,
}: {
  comingSoonBadge?: boolean;
  externalLink?: boolean;
  title: string;
  description: string;
  link: string;
  Img: string;
  disabled?: boolean;
}) => {
  const [comingSoonLabel] = useTranslation(['badges.comingSoon']);

  return (
    <S.LinkWrapper disabled={disabled}>
      <S.Link disabled={disabled} outer={externalLink} href={link} data-testid="tile">
        {comingSoonBadge && (
          <S.BadgeWrapper data-testid="coming-soon">
            <ShimmerBadge label={comingSoonLabel} />
          </S.BadgeWrapper>
        )}
        <S.ImgHolder>
          <img src={Img} alt={title} loading="lazy" height={140} />
        </S.ImgHolder>
        <S.Title>{title}</S.Title>
        <S.Desc>{description}</S.Desc>
      </S.Link>
    </S.LinkWrapper>
  );
};
export default Tile;

import { V2Button } from '@/components/ui/v2-button/v2-button';
import { V2CircleProgressBar } from '@/components/ui/v2-circle-progress-bar/v2-circle-progress-bar';
import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100%;
  position: relative;
  background-color: ${({ theme }) =>
    themedStyle(theme.v2.mode, theme.v2.colors.neutral[50], theme.v2.colors.blue[700])};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[50], theme.v2.colors.blue[500])};

  ${customMediaQuery('padding', {
    xs: '16px',
    md: '24px',
  })}
`;

const Head = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
  gap: 12px;
  margin-bottom: 24px;

  ${customMediaQuery('align-items', {
    xs: 'center',
    md: 'start',
  })}

  ${customMediaQuery('gap', {
    xs: '12px',
    md: '16px',
  })}
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: 32px;
  gap: 6px;
  align-items: start;
  justify-content: center;
  height: 100%;
`;

const Progressbar = styled(V2CircleProgressBar)`
  display: block;
`;

const Title = styled.h2`
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.magenta[400], theme.v2.colors.neutral[50])};
  font-family: ${({ theme }) => theme.v2.typography.fonts.body};
  font-weight: ${({ theme }) => theme.v2.typography.weights.bold};

  ${({ theme }) =>
    customMediaQuery('font-size', {
      xs: theme.v2.typography.sizes.xl,
      md: theme.v2.typography.sizes['2xl'],
    })}
`;

const Paragraph = styled.p`
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[700], theme.v2.colors.neutral[400])};
  font-family: ${({ theme }) => theme.v2.typography.fonts.body};
  font-weight: ${({ theme }) => theme.v2.typography.weights.bold};

  ${({ theme }) =>
    customMediaQuery('font-size', {
      xs: theme.v2.typography.sizes.xs,
      md: theme.v2.typography.sizes.sm,
    })}

  display: none;
  @media (min-width: ${({ theme }) => theme.v2.breakpoints.md}) {
    display: block;
  }
`;

const MobileParagraph = styled(Paragraph)`
  display: block;
  margin-bottom: 20px;

  @media (min-width: ${({ theme }) => theme.v2.breakpoints.md}) {
    display: none;
    width: 100%;
    margin-bottom: 0;
  }
`;

const Line = styled.hr`
  display: none;
  @media (min-width: ${({ theme }) => theme.v2.breakpoints.md}) {
    width: 100%;
    display: block;
    margin-bottom: 16px;
  }

  border: ${({ theme }) =>
    `1px solid ${themedStyle(theme.v2.mode, theme.v2.colors.blue[100], theme.v2.colors.blue[500])}`};
`;

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${customMediaQuery('gap', {
    xs: '8px',
    md: '12px',
  })}
`;

const Button = styled(V2Button)`
  padding: 0;
  line-height: inherit;
  color: ${({ theme }) => theme.v2.colors.magenta[400]};

  ${({ theme }) =>
    customMediaQuery('font-size', {
      xs: theme.v2.typography.sizes.xs,
      md: theme.v2.typography.sizes.md,
    })}
`;

const TooltipWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const TooltipLabel = styled.span`
  display: none;
  @media (min-width: ${({ theme }) => theme.v2.breakpoints.lg}) {
    display: inline-block;
    color: ${({ theme }) => theme.v2.colors.blue[300]};
  }
`;

const CheckboxGroupItemWrapper = styled.span`
  display: flex;
  align-items: center;

  > ${Button} {
    margin-left: 8px;
  }
`;

export default {
  Wrapper,
  Head,
  TextWrapper,
  Progressbar,
  MobileParagraph,
  Title,
  Paragraph,
  Line,
  CheckboxGroup,
  CheckboxGroupItemWrapper,
  Button,
  TooltipWrapper,
  TooltipLabel,
};

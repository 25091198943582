import styled from 'styled-components';

const UseOfFundsContainer = styled.p`
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default { UseOfFundsContainer };

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import type { GraphqlResponse } from 'src/types';

export const ADVISOR_PERSONAL_DATA_QUERY = 'advisor-personal-data-query';

export interface AdvisorPersonalDetailsResponse {
  name: string;
  email: string;
  photoUrl: string;
  phoneNumber: string;
  resumePath: string;
  resumeName: string;
  resumeUrl: string;
  countryOfResidence: CountryCodes | null;
  twitterUrl: string;
  linkedinUrl: string;
}

export const getAdvisorPersonalDetailsQuery = async (): Promise<GraphqlResponse<AdvisorPersonalDetailsResponse>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
    query getNedPersonalDetails {
      getNedPersonalDetails {
        email
        lastName
        firstName
        photoUrl
        phoneNumber
        resumePath
        resumeName
        resumeUrl
        countryOfResidence
        twitterUrl
        linkedinUrl
      }
    }
  `,
  });

  return graphqlResponseWrapper(data, ['getNedPersonalDetails']);
};

import ExpandIcon from '@assets/icons/arrow-expand.svg?react';
import type { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { Description } from './parts/description/description';
import { Meta } from './parts/meta/meta';

import S from './grid-item.styles';

export const GridItem = ({
  header,
  meta,
  description,
  children,
  footer,
  onExpand,
  cornerAddition,
  dataTestId,
  url,
  disabled,
}: {
  header?: ReactNode | ReactNode[];
  meta?: {
    icon: ReactNode;
    value: string | JSX.Element;
    visible?: boolean;
    maxLine?: number;
    onSeeMore?: () => void;
  }[];
  description?: {
    dangerouslySet: boolean;
    content: ReactNode | ReactNode[] | string;
  };
  children?: ReactNode | ReactNode[];
  footer?: ReactNode | ReactNode[];
  onExpand?: () => void;
  cornerAddition?: ReactNode;
  dataTestId?: string;
  url?: string;
  disabled?: boolean;
}) => {
  const history = useHistory();

  const handleOnClick = () => {
    if (disabled || !url) return;

    history.push(url);
  };

  return (
    <S.Wrapper data-testid={dataTestId} onClick={handleOnClick} $clickable={Boolean(url)} disabled={disabled}>
      {header}
      {meta && <Meta items={meta} disabled={disabled} />}
      {description &&
        (description.dangerouslySet && typeof description.content === 'string' ? (
          <Description dangerouslySetInnerHTML={{ __html: description.content || '---' }} disabled={disabled} />
        ) : (
          <Description disabled={disabled}>{description?.content || '---'}</Description>
        ))}
      <S.Content>{children}</S.Content>
      {footer}
      {onExpand ? (
        <S.ExpandButton onClick={onExpand} data-testid="grid-item-expand-button">
          <ExpandIcon />
        </S.ExpandButton>
      ) : null}
      {cornerAddition ? <S.CornerAddition>{cornerAddition}</S.CornerAddition> : null}
    </S.Wrapper>
  );
};

export const sortArrayAlphabetically = (data: string[]) =>
  data.sort((a, b) => {
    const aLowerCase = a.toLowerCase();
    const bLowerCase = b.toLowerCase();

    if (aLowerCase < bLowerCase) {
      return -1;
    }

    if (aLowerCase > bLowerCase) {
      return 1;
    }

    return 0;
  });

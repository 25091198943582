import { setUserMetadata } from '@context/user/user-account.actions';
import { InvestorLayout } from '@layout/investor/investor.layout';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { useMutation, useQuery } from '@tanstack/react-query';
import finishUpdate from '@utils/fns/finish-update';
import { generateUserName } from '@utils/fns/generate-user-name';
import { mapEmptyToString } from '@utils/fns/map-empty-to-string';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import type { RawDraftContentState } from 'draft-js';
import {
  GET_INVESTOR_PUBLISH_STATUS_CACHE_KEY,
  getInvestorPublishStatus,
} from '../../api/get-investor-publish-status.action';
import {
  GET_SMART_MATCH_OPTIONS_REQUESTS_CACHE_KEY,
  getSmartMatchOptionsAction,
} from '../api/get-smart-match-options/get-smart-match-options.action';
import { updateInvestmentsAction, type IInvestorInvestmentPayload } from './api/investments/investments';
import { INVESTOR_PROFILE_DATA_REQUEST, investorProfileDataAction } from './api/investor-profile/investor-profile';
import {
  updatePersonalDetailsAction,
  type InvestorPersonalDetailsUpdatePayload,
} from './api/personal-details/personal-details';
import { InstitutionType, InvestorType, type IInvestorInvestment } from './api/types';
import { updateSmartMatchAction, type IInvestorSmartMatchPayload } from './api/update-smart-match/update-smart-match';
import { InvestorProfile } from './parts/investor-profile';

export interface IInvestorPersonalDetails {
  email: string;
  firstName: string;
  lastName: string;
  gender: string | null;
  countryOfResidence: CountryCodes | null;
  phoneNumber: string;
  linkedinUrl: string | null;
  twitterUrl: string | null;
  aboutMe: RawDraftContentState | null;
  profilePhotoPath: string | null;
  profilePhotoUrl?: string;
  recentOccupation?: string | null;
  companyName?: string | null;
  type: InvestorType;
  institutionType: InstitutionType | null;
}

export const InvestorProfilePage = ({ Layout = InvestorLayout }: { Layout?: Function }) => {
  const { dispatch, state } = useUserAccount();
  const [formUpdated] = useTranslation(['messages.form.success']);

  const {
    data: responsePublishStatus,
    isInitialLoading: isPublishStatusInitialLoading,
    refetch: refetchPublishStatus,
  } = useQuery([GET_INVESTOR_PUBLISH_STATUS_CACHE_KEY], getInvestorPublishStatus, {
    cacheTime: 0,
    enabled: false,
  });

  const {
    data: responseInvestorData,
    isInitialLoading: isInvestorInitialLoading,
    refetch: refetchInvestorData,
  } = useQuery([INVESTOR_PROFILE_DATA_REQUEST], investorProfileDataAction, {
    enabled: false,
    cacheTime: 0,
  });

  const {
    data: responseSmartMatchOptions,
    isInitialLoading: isSmartMatchOptionsInitialLoading,
    refetch: refetchSmartMatchOptions,
  } = useQuery([GET_SMART_MATCH_OPTIONS_REQUESTS_CACHE_KEY], getSmartMatchOptionsAction, {
    enabled: false,
  });

  const { mutateAsync: updatePersonalDetails } = useMutation(updatePersonalDetailsAction, {
    onSuccess: () => refetchInvestorData(),
  });
  const { mutateAsync: updateInvestments } = useMutation(updateInvestmentsAction, {
    onSuccess: () => refetchInvestorData(),
  });
  const { mutateAsync: updateSmartMatch } = useMutation(updateSmartMatchAction, {
    onSuccess: () => refetchInvestorData(),
  });

  const updatePublishStatusConfig = {
    onSuccess: () => {
      refetchPublishStatus();
    },
  };

  const handlePersonalDetailsSubmit = (formStateData: InvestorPersonalDetailsUpdatePayload) => {
    const { profilePhotoUrl, ...formState } = formStateData;
    finishUpdate(updatePersonalDetails(formState, updatePublishStatusConfig), formUpdated);
    dispatch(
      setUserMetadata({
        ...state,
        avatarUrl: profilePhotoUrl!,
        name: generateUserName(formStateData.firstName, formStateData.lastName),
      }),
    );
  };

  const handleSmartMatchSubmit = (formState: IInvestorSmartMatchPayload) => {
    finishUpdate(updateSmartMatch(formState, updatePublishStatusConfig), formUpdated);
  };

  const handleSubmitInvestment = (formState: IInvestorInvestmentPayload) => {
    const isAngel = responseInvestorData?.data?.getInvestorPersonalDetails.type === InvestorType.Angel;

    const payload = {
      ...formState,
      previousInvestmentsTable: isAngel ? formState.previousInvestmentsTable : null,
    };

    finishUpdate(updateInvestments(payload), formUpdated);
  };

  const renderContent = () => {
    if (!responseInvestorData?.data) {
      refetchInvestorData();
      return;
    }

    if (!responseSmartMatchOptions?.data) {
      refetchSmartMatchOptions();
      return;
    }

    if (!responsePublishStatus?.data) {
      refetchPublishStatus();
      return;
    }

    const personalDetailsWithoutNulls = {
      ...mapEmptyToString(responseInvestorData.data.getInvestorPersonalDetails),
      profilePhotoPath: responseInvestorData.data.getInvestorPersonalDetails.profilePicture?.path ?? '',
      profilePhotoUrl: responseInvestorData.data.getInvestorPersonalDetails.profilePicture?.url ?? '',
    };

    const { profilePicture, logoPath, ...personalDetails } = personalDetailsWithoutNulls;

    const {
      minInvestmentAmount,
      maxInvestmentAmount,
      investmentThesis,
      previousInvestmentsTable,
      hidePreviousInvestments,
      isDeployingCapitalInNextThreeMonths,
    } = responseInvestorData.data.getInvestorInvestmentSpec;

    const investmentsWithoutNulls: IInvestorInvestment = {
      minInvestmentAmount: minInvestmentAmount ?? 0,
      maxInvestmentAmount: maxInvestmentAmount ?? 0,
      investmentThesis: investmentThesis ?? '',
      hidePreviousInvestments: hidePreviousInvestments || false,
      previousInvestmentsTable: previousInvestmentsTable || [],
      isDeployingCapitalInNextThreeMonths: isDeployingCapitalInNextThreeMonths,
    };

    const publishStatus = responsePublishStatus?.data;

    return (
      <InvestorProfile
        id={state.userId!}
        refetchInvestorData={refetchInvestorData}
        personalDetails={personalDetails}
        smartMatch={responseInvestorData.data.getInvestorSmartMatch}
        investment={investmentsWithoutNulls}
        publishStatus={publishStatus}
        smartMatchOptions={responseSmartMatchOptions.data}
        refetchPublishStatus={refetchPublishStatus}
        handlePersonalDetailsSubmit={handlePersonalDetailsSubmit}
        handleSmartMatchSubmit={handleSmartMatchSubmit}
        handleSubmitInvestment={handleSubmitInvestment}
      />
    );
  };

  const isInitialDataLoading =
    isInvestorInitialLoading || isPublishStatusInitialLoading || isSmartMatchOptionsInitialLoading;

  return <Layout>{isInitialDataLoading ? <FullHeightSpinner /> : renderContent()}</Layout>;
};

import EmptyBrowsing from '@assets/icons/undraw-empty.svg';
import {
  GET_PULSE_COMPANIES_CACHE_KEY,
  getPulseCompaniesAction,
  type PulseCompanies,
} from '@pages/content/pulse/api/get-investor-connected-companies/get-investor-connected-companies.action';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { Pagination } from '@parts/pagination/pagination';
import { Section } from '@parts/section/section';
import { Routes } from '@router/routes';
import { useQueryPagination } from '@utils/hooks/use-query-pagination/use-query-pagination';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useState } from 'react';
import { EmptyState } from '../empty-state/empty-state';
import S from './companies.styles';
import { CompaniesGrid } from './parts/grid/grid';
import { CompaniesTable, type ConnectedCompany } from './parts/table/table';

const EmptyIconImage = () => <img src={EmptyBrowsing} alt="empty-browsing" loading="lazy" />;

enum Mode {
  Grid = 'grid',
  Rows = 'rows',
}

export const Companies = () => {
  const [emptyTitle, emptySubTitle, toLabel] = useTranslation([
    'pulse.investor.connected.title',
    'pulse.investor.connected.subTitle',
    'pulse.investor.connected.toLabel',
  ]);

  const [displayMode, setDisplayMode] = useState<Mode>(Mode.Rows);

  const { items, total, isLoading, refetch, ...pagination } = useQueryPagination<PulseCompanies>({
    itemsPerPage: 4,
    pageParam: 'companies',
    action: getPulseCompaniesAction,
    cacheKey: GET_PULSE_COMPANIES_CACHE_KEY,
  });

  if (isLoading) return <FullHeightSpinner small />;

  if (items.length === 0) {
    return (
      <EmptyState
        to={Routes.LENS}
        toLabel={toLabel}
        Icon={EmptyIconImage}
        title={emptyTitle}
        subTitle={emptySubTitle}
      />
    );
  }

  return (
    <>
      <S.DisplayMode>
        <S.ListViewIcon onClick={() => setDisplayMode(Mode.Rows)} $active={displayMode === Mode.Rows} />
        <S.GridViewIcon onClick={() => setDisplayMode(Mode.Grid)} $active={displayMode === Mode.Grid} />
      </S.DisplayMode>
      {displayMode === Mode.Grid ? (
        <CompaniesGrid refetch={refetch} items={items} isLoading={isLoading} />
      ) : (
        <Section>
          <CompaniesTable items={items as ConnectedCompany[]} refetch={refetch} />
        </Section>
      )}
      {pagination && pagination.maxPages > 1 && <Pagination centered {...pagination} />}
    </>
  );
};

import styled from 'styled-components';

const Grid = styled.section`
  display: grid;
  gap: ${({ theme }) => theme.spacing.xsmall};
  grid-template-columns: minmax(265px, 1fr) 3fr;
  padding: ${({ theme }) => theme.spacing.xmedium} 0;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) {
    padding: ${({ theme }) => theme.spacing.small} 0;
    grid-template-columns: minmax(265px, 1fr) 2fr;
    gap: ${({ theme }) => theme.spacing.xmedium};
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    display: block;
  }
`;

export default { Grid };

/* eslint-disable */
// @ts-nocheck
import { useEffect, useState } from 'react';

interface GoogleTagManagerHookResponse {
  isAvailable: boolean;
  dataLayer: any[] | null;
}

export const useGoogleTagManager = (): GoogleTagManagerHookResponse => {
  const [gtm, setGtm] = useState(null);

  const apiKey = process.env.VITE_REACT_APP_GTM_API_KEY;

  useEffect(() => {
    if (apiKey) {
      (function (w, d, s, l, i) {
        // configure data layer
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

        // get first script element
        const htmlScriptElement = d.getElementsByTagName(s)[0];

        // append gtm script tag
        const gtmScriptElement = d.createElement(s);
        const dl = l != 'dataLayer' ? `&l=${l}` : '';
        gtmScriptElement.async = true;
        gtmScriptElement.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
        htmlScriptElement.parentNode.insertBefore(gtmScriptElement, htmlScriptElement);

        // set data layer object for pushing data in other places
        setGtm(w[l]);
      })(window, document, 'script', 'dataLayer', apiKey);
    }
  }, []);

  return {
    isAvailable: !!apiKey,
    dataLayer: gtm,
  };
};

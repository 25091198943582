import CodatClock from '@assets/icons/codat-clock.svg?react';
import { Section } from '@parts/section/section';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './waiting-screen.styles';

export const WaitingScreen = () => {
  const [titleLabel, descriptionLabel] = useTranslation([
    'metric.manage.integration.waitingScreen.title',
    'metric.manage.integration.waitingScreen.description',
  ]);

  return (
    <Section dataTestId="integrations-waiting-screen">
      <S.WaitingScreen title={titleLabel} subTitle={descriptionLabel} Icon={CodatClock} />
    </Section>
  );
};

import type { FundingRound } from '@pages/content/pulse/founder/parts/progress/parts/funding-rounds/funding-rounds';
import {
  DELETE_FUNDING_ROUND,
  INSERT_FUNDING_ROUND,
  SET_FUNDING_ROUNDS,
  TOGGLE_FUNDING_ROUNDS_LOADING,
  UPDATE_FUNDING_ROUND,
  type FundingRoundsActionType,
} from './funding-rounds.reducer';

export const setFundingRounds: (fundingRounds: FundingRound[]) => FundingRoundsActionType = (fundingRounds) => ({
  fundingRounds,
  type: SET_FUNDING_ROUNDS,
});

export const toggleFundingRoundsLoading: (loading: boolean) => FundingRoundsActionType = (loading) => ({
  loading,
  type: TOGGLE_FUNDING_ROUNDS_LOADING,
});

export const updateFundingRound: (fundingRound: FundingRound) => FundingRoundsActionType = (fundingRound) => ({
  fundingRound,
  type: UPDATE_FUNDING_ROUND,
});

export const insertFundingRound: (fundingRound: FundingRound) => FundingRoundsActionType = (fundingRound) => ({
  fundingRound,
  type: INSERT_FUNDING_ROUND,
});

export const deleteFundingRound: (fundingRoundId: string) => FundingRoundsActionType = (fundingRoundId) => ({
  fundingRoundId,
  type: DELETE_FUNDING_ROUND,
});

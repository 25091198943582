import { Auth0ProviderValue } from '@pages/auth/api/account-data/account-data.types';
import { getWysiWygValueSafely, useWysiwyg } from '@pages/content/profile/hooks/use-wysiwyg';
import { uploadProfileAvatar } from '@pages/content/profile/investor/api/upload-profile-avatar/upload-profile-avatar';
import { AvatarUploader } from '@pages/content/profile/ned/parts/avatar-uploader/avatar-uploader';
import AvatarFrame from '@pages/content/profile/parts/avatar-frame/avatar-frame';
import { ChangePassword } from '@pages/content/profile/parts/change-password/change-password';
import DisabledCountryOfResidence from '@pages/content/profile/parts/country-of-residence/country-of-residence';
import { FormikField } from '@pages/content/profile/parts/formik-field/formik-field';
import LinkedinOutlinedIcon from '@pages/content/profile/parts/linkedin-outlined-icon/linkedin-outlined-icon';
import MobileFormReversed from '@pages/content/profile/parts/mobile-form-reversed/mobile-form-reversed';
import { ProfilePhoneNumber } from '@pages/content/profile/parts/profile-phone-number/profile-phone-number';
import { SwitchToPublicButton } from '@pages/content/profile/parts/switch-to-public-button/switch-to-public-button';
import TwitterOutlinedIcon from '@pages/content/profile/parts/twitter-outlined-icon/twitter-outlined-icon';
import { Wysiwyg } from '@pages/content/profile/parts/wysiwyg/wysiwyg';
import message from '@parts/message/message';
import { Section } from '@parts/section/section';
import { Select } from '@parts/select/select';
import { SubmitButton } from '@parts/submit-button/submit-button';
import { Tooltip } from '@parts/tooltip/tooltip';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import { Col, Input, Row } from 'antd';
import 'antd-country-phone-input/dist/index.css';
import { EditorState, type RawDraftContentState } from 'draft-js';
import { useFormik } from 'formik';
import { useState, type ChangeEvent } from 'react';
import type { InvestorPersonalDetailsUpdatePayload } from '../../api/personal-details/personal-details';
import { GenderType, InvestorType } from '../../api/types';
import type { IInvestorPersonalDetails } from '../../investor-profile.page';
import { useValidationSchema } from './hooks/use-validation.schema';

interface IPersonalDetailsProps {
  personalDetails: IInvestorPersonalDetails;
  handleSubmit: (formState: InvestorPersonalDetailsUpdatePayload) => void;
  togglePubicView: () => void;
}

export const PersonalDetails = ({ personalDetails, handleSubmit, togglePubicView }: IPersonalDetailsProps) => {
  const [
    header,
    emailLabel,
    firstNameLabel,
    lastNameLabel,
    genderLabel,
    manLabel,
    womanLabel,
    nonBinaryLabel,
    preferNotToSayLabel,
    recentOccupationLabel,
    companyNameLabel,
    countryOfResidenceLabel,
    phoneNumber,
    linkedin,
    twitter,
    aboutMe,
    investorTypeLabel,
    saveChanges,
    phoneNumberTooltipLabel,
  ] = useTranslation([
    'profile.investor.section.personalDetails.header',
    'profile.investor.section.personalDetails.form.email',
    'profile.investor.section.personalDetails.form.firstName',
    'profile.investor.section.personalDetails.form.lastName',
    'gender.select.title',
    'gender.select.man',
    'gender.select.woman',
    'gender.select.nonBinary',
    'gender.select.preferNotToSay',
    'profile.investor.section.personalDetails.form.recentOccupation',
    'profile.investor.section.personalDetails.form.companyName',
    'profile.investor.section.personalDetails.form.countryOfResidence',
    'profile.investor.section.personalDetails.form.phoneNumber',
    'profile.investor.section.personalDetails.form.linkedin',
    'profile.investor.section.personalDetails.form.twitter',
    'profile.investor.section.personalDetails.form.aboutMe',
    'profile.investor.section.personalDetails.form.investorType',
    'profile.investor.section.personalDetails.form.saveChanges',
    'profile.phoneNumber.tooltip',
  ]);

  const [currentAvatar, setCurrentAvatar] = useState<string | null>(personalDetails.profilePhotoUrl || '');

  const {
    state: editorState,
    setState: setEditorState,
    isActive: isEditorActive,
    setIsActive: setIsEditorActive,
  } = useWysiwyg({
    content: getWysiWygValueSafely(personalDetails.aboutMe),
  });

  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);

  const {
    state: { auth0Provider },
  } = useUserAccount();

  const { type: investorType, institutionType, email, countryOfResidence, ...initialValues } = personalDetails;
  const { isValid, dirty, submitCount, values, ...formik } = useFormik<InvestorPersonalDetailsUpdatePayload>({
    initialValues,
    onSubmit: (v) => {
      const { ...valuesToSend } = v;

      if (investorType === InvestorType.Angel) {
        valuesToSend.companyName = null;
      }
      if (investorType === InvestorType.Institutional) {
        valuesToSend.recentOccupation = null;
      }
      if (investorType === InvestorType.NonSpecified) {
        valuesToSend.companyName = null;
        valuesToSend.recentOccupation = null;
      }

      if (values.profilePhotoPath === '') {
        valuesToSend.profilePhotoPath = null;
      }
      if (valuesToSend.phoneNumber.trim().length === 0) {
        valuesToSend.phoneNumber = '';
      }

      handleSubmit({
        ...valuesToSend,
        recentOccupation: valuesToSend.recentOccupation || null,
      });
    },
    enableReinitialize: true,
    validationSchema: useValidationSchema(investorType),
  });

  const handleFieldChange = (e: ChangeEvent<HTMLTextAreaElement> | ChangeEvent<HTMLInputElement>, field: string) => {
    formik.setFieldTouched(field, true);
    formik.setFieldValue(field, e.target.value || null);
  };

  const onEditorStateChange = (nextState: EditorState) => {
    setEditorState(nextState);
    formik.setFieldTouched('aboutMe', true);
  };

  const showInvestorTypeField = [InvestorType.Angel, InvestorType.Institutional].includes(investorType);

  const getInvestorTypeValue = () => {
    if (investorType === InvestorType.Institutional && institutionType !== null) {
      return `${investorType} (${institutionType})`;
    }
    return investorType;
  };

  const genderLabels = new Map<GenderType, string>([
    [GenderType.Man, manLabel],
    [GenderType.Woman, womanLabel],
    [GenderType.NonBinary, nonBinaryLabel],
    [GenderType.PreferNotToSay, preferNotToSayLabel],
  ]);

  return (
    <Section header={header}>
      <form onSubmit={formik.submitForm} data-testid="investor-profile-info-form">
        <MobileFormReversed gutter={[24, 24]}>
          <Col xs={24} sm={24} md={24} lg={16} xl={17}>
            <Row gutter={[35, 10]}>
              <Col xs={24} sm={12}>
                <FormikField
                  label={{
                    for: 'email',
                    label: emailLabel,
                  }}
                  suffix={auth0Provider === Auth0ProviderValue.Email ? <ChangePassword /> : null}
                >
                  <Input id="email" type="text" readOnly value={email} disabled />
                </FormikField>
              </Col>
            </Row>
            <Row gutter={[35, 10]}>
              <Col xs={24} sm={12}>
                <FormikField
                  label={{
                    for: 'firstName',
                    label: firstNameLabel,
                  }}
                  error={formik.errors.firstName}
                  touched={formik.touched.firstName}
                >
                  <Input {...formik.getFieldProps('firstName')} id="firstName" type="text" />
                </FormikField>
              </Col>
              <Col xs={24} sm={12}>
                <FormikField
                  label={{
                    for: 'lastName',
                    label: lastNameLabel,
                  }}
                  error={formik.errors.lastName}
                  touched={formik.touched.lastName}
                >
                  <Input {...formik.getFieldProps('lastName')} id="lastName" type="text" />
                </FormikField>
              </Col>

              {personalDetails.type === InvestorType.Angel ? (
                <Col xs={24} sm={12}>
                  <FormikField
                    label={{
                      for: 'recentOccupation',
                      label: recentOccupationLabel,
                    }}
                    error={formik.errors.recentOccupation}
                    touched={formik.touched.recentOccupation}
                  >
                    <Input {...formik.getFieldProps('recentOccupation')} id="recentOccupation" />
                  </FormikField>
                </Col>
              ) : null}

              {personalDetails.type === InvestorType.Institutional ? (
                <Col xs={24} sm={12}>
                  <FormikField
                    label={{
                      for: 'companyName',
                      label: companyNameLabel,
                    }}
                    error={formik.errors.companyName}
                    touched={formik.touched.companyName}
                  >
                    <Input {...formik.getFieldProps('companyName')} id="companyName" />
                  </FormikField>
                </Col>
              ) : null}

              <Col xs={24} sm={12}>
                <FormikField
                  label={{
                    for: 'gender',
                    label: genderLabel,
                  }}
                  error={formik.errors.gender}
                  touched={formik.touched.gender}
                >
                  <Select
                    {...formik.getFieldProps('gender')}
                    value={values.gender || undefined}
                    onChange={(value) => {
                      formik.setFieldTouched('gender', true);
                      formik.setFieldValue('gender', value || null);
                    }}
                  >
                    <Select.Option key="empty" value="">
                      ---
                    </Select.Option>
                    {[...genderLabels.entries()].map(([value, label]) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    ))}
                  </Select>
                </FormikField>
              </Col>

              <Col xs={24} sm={12}>
                <DisabledCountryOfResidence
                  countryOfResidence={countryOfResidence as CountryCodes}
                  countryOfResidenceLabel={countryOfResidenceLabel}
                />
              </Col>
              <Col xs={24} sm={12}>
                <ProfilePhoneNumber
                  formik={{ values, isValid, dirty, submitCount, ...formik }}
                  countryOfResidence={personalDetails.countryOfResidence}
                  field="phoneNumber"
                  label={
                    <span>
                      {phoneNumber}
                      <Tooltip title={phoneNumberTooltipLabel} />
                    </span>
                  }
                  id="phoneNumber"
                />
              </Col>
            </Row>
            <Row gutter={[35, 10]}>
              <Col xs={24} sm={12}>
                <FormikField
                  label={{
                    for: 'linkedinUrl',
                    label: linkedin,
                  }}
                  error={formik.errors.linkedinUrl}
                  touched={Boolean(formik.initialValues.linkedinUrl) || formik.touched.linkedinUrl}
                  icon={<LinkedinOutlinedIcon />}
                >
                  <Input
                    defaultValue={personalDetails.linkedinUrl ?? ''}
                    {...formik.getFieldProps('linkedinUrl')}
                    onChange={(value) => handleFieldChange(value, 'linkedinUrl')}
                    id="linkedinUrl"
                    type="text"
                  />
                </FormikField>
              </Col>
              <Col xs={24} sm={12}>
                <FormikField
                  label={{
                    for: 'twitterUrl',
                    label: twitter,
                  }}
                  error={formik.errors.twitterUrl}
                  touched={formik.touched.twitterUrl}
                  icon={<TwitterOutlinedIcon />}
                >
                  <Input
                    {...formik.getFieldProps('twitterUrl')}
                    onChange={(value) => handleFieldChange(value, 'twitterUrl')}
                    id="twitterUrl"
                    type="text"
                  />
                </FormikField>
              </Col>
            </Row>
            <Row>
              <Col span="24">
                <FormikField
                  label={{
                    for: 'aboutMe',
                    label: aboutMe,
                  }}
                  error={formik.errors.aboutMe}
                  touched={formik.touched.aboutMe}
                >
                  <Wysiwyg
                    editorState={editorState}
                    onChange={(contentState: RawDraftContentState | EditorState) => {
                      formik.setFieldValue('aboutMe', JSON.stringify(contentState));
                    }}
                    onEditorStateChange={onEditorStateChange}
                    isEditorActive={isEditorActive}
                    onFocus={() => setIsEditorActive(true)}
                  />
                </FormikField>
              </Col>
            </Row>

            {showInvestorTypeField ? (
              <Row gutter={[35, 10]}>
                <Col xs={24} sm={12}>
                  <FormikField
                    label={{
                      for: 'investorType',
                      label: investorTypeLabel,
                    }}
                  >
                    <Input id="investorType" type="text" readOnly value={getInvestorTypeValue()} disabled />
                  </FormikField>
                </Col>
              </Row>
            ) : null}
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={7}>
            <FormikField
              label={{
                for: 'logoPath',
                label: '',
              }}
              error={formik.errors.profilePhotoPath}
              touched={formik.touched.profilePhotoPath}
            >
              <AvatarFrame>
                <AvatarUploader
                  name={values.firstName}
                  uploadMutation={(formData: FormData) => {
                    setIsFileUploading(true);
                    return uploadProfileAvatar({ formData });
                  }}
                  deleteFile={() => {
                    formik.setFieldValue('profilePhotoPath', null);
                    formik.setFieldValue('profilePhotoUrl', null);
                    setCurrentAvatar(null);
                  }}
                  onSuccess={(res) => {
                    setCurrentAvatar(res.data.fileUrl);
                    formik.setFieldValue('profilePhotoPath', res.data.filePath);
                    formik.setFieldValue('profilePhotoUrl', res.data.fileUrl);
                    setIsFileUploading(false);
                  }}
                  onError={(err: AxiosError) => {
                    message.error({ content: getServerError(err) });
                  }}
                  currentImage={currentAvatar}
                />
              </AvatarFrame>
            </FormikField>
          </Col>
        </MobileFormReversed>
        <Row>
          <Col xs={12}>
            <SubmitButton
              type="primary"
              htmlType="submit"
              form="founder-profile-info-form"
              disabled={isFileUploading}
              disabledEvaluation={{ isValid, dirty, submitCount }}
              onClick={formik.submitForm}
            >
              {saveChanges}
            </SubmitButton>
          </Col>
          <Col xs={12}>
            <SwitchToPublicButton onClick={() => togglePubicView()} />
          </Col>
        </Row>
      </form>
    </Section>
  );
};

import styled from 'styled-components';

const Title = styled.h3`
  padding: ${({ theme }) => theme.spacing.medium} ${({ theme }) => theme.spacing.small};
  color: ${({ theme }) => theme.color.fontPrimary};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-top: ${({ theme }) => theme.spacing.medium};
`;

const Content = styled.div`
  white-space: pre-line;
  color: ${({ theme }) => theme.color.fontSecondary};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.light};
`;

export default { Title, Content };

import { V2Button } from '@/components/ui/v2-button/v2-button';
import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import styled, { css } from 'styled-components';

const ConnectionsManager = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;

  ${customMediaQuery('gap', {
    sm: '12px',
  })}

  > div {
    display: flex;
    gap: 8px;

    ${customMediaQuery('gap', {
      sm: '12px',
    })}

    button {
      height: 100%;
      margin-left: 0 !important;

      + button {
        margin-left: 0 !important;
      }
    }
  }
`;

const SaveButton = styled(V2Button)<{ $saved: boolean }>`
  ${(props) =>
    props.$saved &&
    css`
      color: ${({ theme }) => theme.v2.colors.green['500']} !important;
      border-color: ${({ theme }) => theme.v2.colors.green['500']} !important;

      &:hover,
      &:focus,
      &:active {
        color: ${({ theme }) => theme.v2.colors.green['600']} !important;
        border-color: ${({ theme }) => theme.v2.colors.green['600']} !important;
      }
    `}
`;

export default { ConnectionsManager, SaveButton };

import { Tooltip } from '@parts/tooltip/tooltip';
import type { HTMLAttributes, ReactNode } from 'react';

import S from './group.styles';

type GroupProps = HTMLAttributes<HTMLDivElement> & {
  title: string;
  children: ReactNode;
  className?: string;
  tooltipLabel?: string;
};

export const Group = ({ title, children, className, tooltipLabel = '', ...restProps }: GroupProps) => (
  <S.Wrapper {...restProps} className={className}>
    <S.TitleWrapper>
      <S.Title>{title}</S.Title> {tooltipLabel ? <Tooltip title={tooltipLabel} /> : null}
    </S.TitleWrapper>
    <S.Content>{children}</S.Content>
  </S.Wrapper>
);

import { checkIfValidWysiWygValue } from '@/pages/content/profile/hooks/use-wysiwyg';
import { SanitizeWysiwyg } from '@/utils/fns/sanitize-wysiwyg';
import { useV2AdvisorConnectionCardContext } from '../v2-advisor-connection-card-context';
import { useRef } from 'react';

import S from '../v2-advisor-connection-card.styles';

type ParagraphProps = {};

export const Paragraph = (_: ParagraphProps) => {
  const { pendingConnection } = useV2AdvisorConnectionCardContext();
  const description = checkIfValidWysiWygValue(pendingConnection.description) ? pendingConnection.description : null;

  const paragraphRef = useRef<HTMLDivElement>(null);
  const paragraphHeight = paragraphRef.current?.offsetHeight ?? 0;

  return (
    <S.ParagraphWrapper>
      <S.Paragraph
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: SanitizeWysiwyg(JSON.parse(description as string)) }}
        ref={paragraphRef}
      />
      {paragraphHeight > 200 && <S.Gradient />}
    </S.ParagraphWrapper>
  );
};

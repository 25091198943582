import { useV2ExperienceTileContext } from '../v2-experience-tile-context';
import S from '../v2-experience-tile.styles';

export const DateRange = () => {
  const { details } = useV2ExperienceTileContext();

  return (
    <S.DateRange>
      {details.startDate} - {details.endDate}
    </S.DateRange>
  );
};

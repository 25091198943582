import { useMediaQuery } from '@/hooks/use-media-query';
import { V2Badge } from '@ui/v2-badge/v2-badge';
import type { V2FeedTileProps } from '@ui/v2-feed-tile/v2-feed-tile.types';
import { V2Link } from '@ui/v2-link/v2-link';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import React from 'react';
import S from './v2-feed-tile.styles';

export const V2FeedTile: React.FC<V2FeedTileProps> = ({
  title,
  description,
  variant = 'blue',
  comingSoon = false,
  'data-testid': testId = 'v2-feed-tile',
  badgeText,
  button,
  logoImgUrl,
}) => {
  const [comingSoonLabel] = useTranslation(['badges.comingSoon']);

  const mediaQuery = useMediaQuery();

  const FeedVariant = {
    blue: S.Blue,
    green: S.Green,
    navy: S.Navy,
  }[variant];

  return (
    <FeedVariant data-testid={testId} $hasButton={Boolean(button)}>
      {logoImgUrl ? (
        <S.ImageWrapper>
          <S.Image src={logoImgUrl} loading="lazy" />
          <S.Title>{title}</S.Title>
        </S.ImageWrapper>
      ) : (
        <S.Title>{title}</S.Title>
      )}

      <S.Description>{description}</S.Description>

      {button && (
        <V2Link to={button.link} outer hasUnderline={false}>
          <S.Button variant={variant === 'blue' ? 'secondary' : 'primary'} endIcon={button.endIcon}>
            {button.text}
          </S.Button>
        </V2Link>
      )}

      {comingSoon && (
        <S.BadgeWrapper>
          <V2Badge
            text={comingSoonLabel}
            variant="accent"
            size={mediaQuery === 'xs' ? 'sm' : 'md'}
            data-testid={testId + '-v2-badge'}
          />
        </S.BadgeWrapper>
      )}
      {badgeText && (
        <S.BadgeWrapper>
          <V2Badge text={badgeText} variant="neutral" withBorder={false} size="sm" data-testid={testId + '-v2-badge'} />
        </S.BadgeWrapper>
      )}
    </FeedVariant>
  );
};

import NotFound from '@assets/icons/not-found.svg?react';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { DashboardButton } from '../parts/dashboard-button/dashboard-button';
import { Description } from '../parts/description/description';
import { Heading } from '../parts/heading/heading';
import { Layout } from '../parts/layout/layout';
import { QuestionContact } from '../parts/question-contact/question-contact';

export const NotFoundPage = () => {
  const [titleLabel, descriptionLabel] = useTranslation(['notFound.title', 'notFound.description']);

  return (
    <Layout Icon={NotFound}>
      <Heading>{titleLabel}</Heading>
      <Description>{descriptionLabel}</Description>
      <QuestionContact />
      <DashboardButton />
    </Layout>
  );
};

import { useScrollToElementWithQueryParams } from '@/utils/hooks/use-scroll-to-element/use-scroll-to-element-with-query-params';
import { getWysiWygValueSafely, useWysiwyg } from '@pages/content/profile/hooks/use-wysiwyg';
import { Wysiwyg } from '@pages/content/profile/parts/wysiwyg/wysiwyg';
import { Section } from '@parts/section/section';
import { Tooltip } from '@parts/tooltip/tooltip';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import 'antd-country-phone-input/dist/index.css';
import { EditorState, type RawDraftContentState } from 'draft-js';
import { useFormik } from 'formik';
import { FormikField } from '../../../parts/formik-field/formik-field';
import type { INedAbout } from '../../ned-profile.page';
import { useValidationSchema } from './validation-schema';

import S from './about-me.styles';

interface AboutMeProps {
  data: INedAbout;
  handleSubmit: (formState: INedAbout) => void;
  busy?: boolean;
  includeFields?: (keyof INedAbout)[];
}

export const AboutMe = ({
  data,
  handleSubmit,
  busy = false,
  includeFields = ['aboutMe', 'myOffering', 'myExpectations'],
}: AboutMeProps) => {
  const scrollToRef = useScrollToElementWithQueryParams<HTMLElement>('about-me');
  const [
    header,
    description,
    aboutMeLabel,
    myOfferingLabel,
    myExpectationsLabel,
    saveChanges,
    aboutMeTooltip,
    myOfferingTooltip,
    myExpectationsTooltip,
  ] = useTranslation([
    'profile.ned.section.aboutMe.header',
    'profile.ned.section.aboutMe.description',
    'profile.ned.section.aboutMe.aboutMeLabel',
    'profile.ned.section.aboutMe.myOfferingLabel',
    'profile.ned.section.aboutMe.myExpectationsLabel',
    'profile.ned.section.aboutMe.saveChanges',
    'profile.ned.section.aboutMe.aboutMe.tooltip',
    'profile.ned.section.aboutMe.myOffering.tooltip',
    'profile.ned.section.aboutMe.myExpectations.tooltip',
  ]);

  const {
    state: aboutMeEditorState,
    setState: setAboutMeEditorState,
    isActive: isAboutMeEditorActive,
    setIsActive: setIsAboutMeEditorActive,
  } = useWysiwyg({ content: getWysiWygValueSafely(data.aboutMe) });

  const {
    state: myOfferingEditorState,
    setState: setMyOfferingEditorState,
    isActive: isMyOfferingEditorActive,
    setIsActive: setIsMyOfferingEditorActive,
  } = useWysiwyg({ content: getWysiWygValueSafely(data.myOffering) });

  const {
    state: myExpectationsEditorState,
    setState: setMyExpectationsEditorState,
    isActive: isMyExpectationsEditorActive,
    setIsActive: setIsMyExpectationsEditorActive,
  } = useWysiwyg({ content: getWysiWygValueSafely(data.myExpectations) });

  const { isValid, dirty, values, submitCount, ...formik } = useFormik<INedAbout>({
    initialValues: {
      aboutMe: getWysiWygValueSafely(data.aboutMe),
      myOffering: getWysiWygValueSafely(data.myOffering),
      myExpectations: getWysiWygValueSafely(data.myExpectations),
    },
    onSubmit: (formValue: INedAbout) => {
      handleSubmit(formValue);

      setIsAboutMeEditorActive(false);
      setIsMyOfferingEditorActive(false);
      setIsMyExpectationsEditorActive(false);
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: useValidationSchema(),
  });

  return (
    <Section header={header} subTitle={description} innerRef={scrollToRef} dataTestId="ned-profile-about-me-section">
      <S.Form onSubmit={formik.submitForm} id="ned-about-me-form">
        {includeFields.includes('aboutMe') ? (
          <FormikField
            label={{
              for: 'aboutMe',
              label: (
                <span>
                  {aboutMeLabel}
                  <Tooltip title={aboutMeTooltip} />
                </span>
              ),
            }}
            error={formik.errors.aboutMe}
            touched={formik.touched.aboutMe}
          >
            <Wysiwyg
              editorState={aboutMeEditorState}
              onChange={(contentState: RawDraftContentState | EditorState) => {
                formik.setFieldValue('aboutMe', JSON.stringify(contentState));
              }}
              onEditorStateChange={(nextState) => {
                setAboutMeEditorState(nextState);
                formik.setFieldTouched('aboutMe', true);
              }}
              isEditorActive={isAboutMeEditorActive}
              onFocus={() => setIsAboutMeEditorActive(true)}
            />
          </FormikField>
        ) : null}

        {includeFields.includes('myOffering') ? (
          <FormikField
            label={{
              for: 'myOffering',
              label: (
                <span>
                  {myOfferingLabel}
                  <Tooltip title={myOfferingTooltip} />
                </span>
              ),
            }}
            error={formik.errors.myOffering}
            touched={formik.touched.myOffering}
          >
            <Wysiwyg
              editorState={myOfferingEditorState}
              onChange={(contentState: RawDraftContentState | EditorState) => {
                formik.setFieldValue('myOffering', JSON.stringify(contentState));
              }}
              onEditorStateChange={(nextState) => {
                setMyOfferingEditorState(nextState);
                formik.setFieldTouched('myOffering', true);
              }}
              isEditorActive={isMyOfferingEditorActive}
              onFocus={() => setIsMyOfferingEditorActive(true)}
            />
          </FormikField>
        ) : null}

        {includeFields.includes('myExpectations') ? (
          <FormikField
            label={{
              for: 'myExpactations',
              label: (
                <span>
                  {myExpectationsLabel}
                  <Tooltip title={myExpectationsTooltip} />
                </span>
              ),
            }}
            error={formik.errors.myExpectations}
            touched={formik.touched.myExpectations}
          >
            <Wysiwyg
              editorState={myExpectationsEditorState}
              onChange={(contentState: RawDraftContentState | EditorState) => {
                formik.setFieldValue('myExpectations', JSON.stringify(contentState));
              }}
              onEditorStateChange={(nextState) => {
                setMyExpectationsEditorState(nextState);
                formik.setFieldTouched('myExpectations', true);
              }}
              isEditorActive={isMyExpectationsEditorActive}
              onFocus={() => setIsMyExpectationsEditorActive(true)}
            />
          </FormikField>
        ) : null}

        <S.SubmitButton
          type="primary"
          htmlType="submit"
          form="ned-profile-about-me-form"
          disabledEvaluation={{ isValid, dirty, submitCount }}
          onClick={formik.submitForm}
          loading={busy}
        >
          {saveChanges}
        </S.SubmitButton>
      </S.Form>
    </Section>
  );
};

import type { INedExperience } from '@/pages/content/profile/ned/ned-profile.page';
import type { GraphqlResponse } from '@/types';
import { axiosInstance } from '@/utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@/utils/fns/graphql-response-wrapper';

export const GET_EXPERIENCE_QUERY_KEY = 'get-ned-experience';

export const getNedExperience = async (): Promise<GraphqlResponse<INedExperience[]>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
    query getNedProfileData {
      getNedExperience {
        id
        company {
          id
          name
          avatarUrl
          industry
          isExternal
          email
        }
        role
        startDate
        endDate
        compensationType
        isOngoing
        skillSet
        experienceSummary
      }
    }
  `,
  });

  return graphqlResponseWrapper(data, ['getNedExperience']);
};

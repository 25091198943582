import type { HTMLAttributes } from 'react';
import { useV2AriaMobileConnectionCardContext } from '../v2-aria-mobile-connection-card-context';

import S from '../v2-aria-mobile-connection-card.styles';

type HeadProps = {
  children: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export const Head = ({ children, ...rest }: HeadProps) => {
  useV2AriaMobileConnectionCardContext();

  return <S.Head {...rest}>{children}</S.Head>;
};

import { useScrollToElementWithQueryParams } from '@/utils/hooks/use-scroll-to-element/use-scroll-to-element-with-query-params';
import {
  GET_SMART_MATCH_OPTIONS_REQUESTS_CACHE_KEY,
  getSmartMatchOptionsAction,
} from '@pages/content/profile/api/get-smart-match-options/get-smart-match-options.action';
import { publishFounderProfileAction } from '@pages/content/profile/founder/api/publish-founder-profile/publish-founder-profile.action';
import { unPublishProfileAction } from '@pages/content/profile/founder/api/unpublish-profile/unpublish-profile.action';
import { updateSmartMatchAction } from '@pages/content/profile/founder/api/update-smart-match/update-smart-match.action';
import type { FounderSmartMatchData } from '@pages/content/profile/founder/founder-profile.page';
import {
  usePublishStatusEvaluation,
  type PublishStatus,
} from '@pages/content/profile/hooks/use-publish-status-evaluation';
import { Select } from '@pages/content/profile/parts/select/select';
import Checkbox from '@parts/checkbox/checkbox';
import { FormikField } from '@parts/forms/formik-field/formik-field';
import message from '@parts/message/message';
import { MultiSelectSearch } from '@parts/multiselect-search/multiselect-search';
import { Section } from '@parts/section/section';
import { SubmitButton } from '@parts/submit-button/submit-button';
import { Tooltip } from '@parts/tooltip/tooltip';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { sortArrayAlphabetically } from '@utils/fns/sortArrayAlphabetically';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import { Col, Row } from 'antd';
import type { SelectValue } from 'antd/lib/select';
import { useFormik } from 'formik';
import { type ReactNode } from 'react';
import * as Yup from 'yup';
import { PublishSwitch } from '../../../../../parts/publish-switch/publish-switch';
import { CheckboxFormikField } from '../additional-info/additional-info.styles';

const MAX_INDUSTRIES = 3;
const MAX_CUSTOMER_GROUP = 3;

const SmartMatch = ({
  refetchPublishStatus,
  data,
  publishStatus,
}: {
  refetchPublishStatus: Function;
  publishStatus: PublishStatus;
  data: FounderSmartMatchData;
}) => {
  const scrollToRef = useScrollToElementWithQueryParams<HTMLElement>('smart-match');
  const [
    formUpdatedLabel,
    headerLabel,
    subTitleLabel,
    industriesValidationLabel,
    industriesLabel,
    selectPlaceholderLabel,
    customerGroupLabel,
    productStageLabel,
    investmentStageLabel,
    seisLimitsRemainingLabel,
    seisLimitsRemainingTooltip,
    eisLimitRemainingLabel,
    eisLimitRemainingTooltip,
    saveLabel,
    closeLabel,
    enableLabel,
    disableLabel,
    profilePublishHeaderLabel,
    profilePublishMessageLabel,
    profileUnPublishMessageLabel,
    profileShortagesMessageLabel,
    profileShortagesHeaderLabel,
    publicLabel,
    industriesTooltipLabel,
    customerGroupTooltipLabel,
  ] = useTranslation([
    'messages.form.success',
    'profile.founder.section.smartMatch.header',
    'profile.founder.section.smartMatch.subtitle',
    'formik.validation.industries',
    'profile.founder.section.smartMatch.form.industry',
    'profile.founder.section.smartMatch.selectPlaceholder',
    'profile.founder.section.smartMatch.customerGroup',
    'profile.founder.section.smartMatch.productStage',
    'profile.founder.section.smartMatch.investmentStage',
    'profile.founder.section.smartMatch.seisRemainingLabel',
    'profile.founder.section.smartMatch.seisRemainingTooltip',
    'profile.founder.section.smartMatch.eisRemainingLabel',
    'profile.founder.section.smartMatch.eisRemainingTooltip',
    'profile.founder.section.smartMatch.save',
    'profile.founder.section.smartMatch.close',
    'profile.founder.section.smartMatch.enable',
    'profile.founder.section.smartMatch.disable',
    'profile.founder.section.smartMatch.profileVisibility.header',
    'profile.founder.section.smartMatch.profileVisibility.publish',
    'profile.founder.section.smartMatch.profileVisibility.unPublish',
    'profile.founder.section.smartMatch.profileVisibility.shortages',
    'profile.founder.section.smartMatch.profileVisibility.shortages.header',
    'profile.smartMatch.founder.publicLabel',
    'profile.founder.section.smartMatch.form.industryTooltip',
    'profile.founder.section.smartMatch.form.customerGroupTooltip',
  ]);

  const {
    helpers: { isFrom },
  } = useUserAccount();

  const shouldShowSeisEis = isFrom(CountryCodes.GB);

  const { mutateAsync: updateSmartMatch } = useMutation(updateSmartMatchAction, {
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
    onSuccess: () => {
      refetchPublishStatus();
      message.success({ content: formUpdatedLabel });
    },
  });

  const { data: response } = useQuery([GET_SMART_MATCH_OPTIONS_REQUESTS_CACHE_KEY], getSmartMatchOptionsAction);

  const { isPublic, publishModal, shortagesModal, showPublishModal, showShortagesModal } = usePublishStatusEvaluation({
    refetchPublishStatus,
    publishStatus,
    disable: disableLabel,
    enable: enableLabel,
    close: closeLabel,
    publishHeader: profilePublishHeaderLabel,
    unPublishMessage: profileUnPublishMessageLabel,
    publishMessage: profilePublishMessageLabel,
    shortagesHeader: profileShortagesHeaderLabel,
    shortagesMessage: profileShortagesMessageLabel,
    unPublishAction: unPublishProfileAction,
    publishAction: publishFounderProfileAction,
  });

  const {
    setFieldValue,
    setFieldTouched,
    values,
    errors,
    touched,
    getFieldProps,
    submitForm,
    isValid,
    dirty,
    submitCount,
  } = useFormik<FounderSmartMatchData>({
    initialValues: data,
    onSubmit: (valuesWithSeisEis) => {
      const { hasEisLimitsRemaining, hasSeisLimitsRemaining, ...valuesWithoutSeisEis } = valuesWithSeisEis;
      updateSmartMatch(shouldShowSeisEis ? valuesWithSeisEis : valuesWithoutSeisEis);
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      industries: Yup.array().of(Yup.string()).max(MAX_INDUSTRIES, industriesValidationLabel).nullable(),
      customerGroup: Yup.array().of(Yup.string()).max(MAX_CUSTOMER_GROUP).nullable(),
      productStage: Yup.string().nullable(),
      investmentStage: Yup.string(),
      hasSeisLimitsRemaining: Yup.boolean(),
      hasEisLimitsRemaining: Yup.boolean(),
    }),
  });

  const handleSelectChange = (selectKey: string) => (value: SelectValue) => {
    setFieldValue(selectKey, value || null);
    setFieldTouched(selectKey, true);
  };

  const row = (content: ReactNode) => (
    <Row gutter={[24, 0]}>
      <Col xs={24} sm={24} md={24} lg={14} xl={14}>
        {content}
      </Col>
    </Row>
  );

  const determineModalToShow = publishStatus.smartMatchShortages.length === 0 ? showPublishModal : showShortagesModal;

  return (
    <Section
      cornerAddition={{
        content: <PublishSwitch isPublic={isPublic} onClick={determineModalToShow} publicLabel={publicLabel} />,
        width: 440,
      }}
      header={headerLabel}
      subTitle={subTitleLabel}
      innerRef={scrollToRef}
      data-testid="founder-profile-start-match-form"
    >
      {publishModal}
      {shortagesModal}

      {row(
        <FormikField
          label={{
            for: 'industries',
            label: (
              <span>
                {industriesLabel}
                <Tooltip title={industriesTooltipLabel} />
              </span>
            ),
          }}
          error={errors.industries ? (errors.industries as string) : undefined}
          touched={touched.industries}
        >
          <MultiSelectSearch
            data-testid="industries_field"
            id="industries"
            placeholder={selectPlaceholderLabel}
            {...getFieldProps('industries')}
            options={sortArrayAlphabetically(response?.data?.industries || [])}
            onChange={handleSelectChange('industries')}
            {...(values.industries.length >= MAX_INDUSTRIES && { open: false })}
          />
        </FormikField>,
      )}

      {row(
        <FormikField
          label={{
            for: 'customerGroup',
            label: (
              <span>
                {customerGroupLabel}
                <Tooltip title={customerGroupTooltipLabel} />
              </span>
            ),
          }}
          error={errors.customerGroup ? (errors.customerGroup as string) : undefined}
          touched={touched.customerGroup}
        >
          <MultiSelectSearch
            id="customerGroup"
            placeholder={selectPlaceholderLabel}
            {...getFieldProps('customerGroup')}
            options={response?.data?.customerGroups || []}
            onChange={handleSelectChange('customerGroup')}
            data-testid="customer-group-select"
            {...(values.customerGroup.length >= MAX_CUSTOMER_GROUP && { open: false })}
          />
        </FormikField>,
      )}

      {row(
        <FormikField
          label={{
            for: 'productStage',
            label: productStageLabel,
          }}
          touched={touched.productStage}
        >
          <Select
            data-testid="product_stage"
            {...getFieldProps('productStage')}
            onChange={handleSelectChange('productStage')}
            placeholder={selectPlaceholderLabel}
            showSearch={false}
            value={values.productStage || undefined}
          >
            <Select.Option key="empty" value="">
              {' '}
            </Select.Option>
            {(response?.data?.productStages || []).map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        </FormikField>,
      )}

      {row(
        <FormikField
          label={{
            for: 'investmentStage',
            label: investmentStageLabel,
          }}
          touched={touched.investmentStage}
        >
          <Select
            data-testid="investment_stage"
            {...getFieldProps('investmentStage')}
            onChange={handleSelectChange('investmentStage')}
            placeholder={selectPlaceholderLabel}
            showSearch={false}
            value={values.investmentStage || undefined}
          >
            {(response?.data?.investmentStages || []).map((option) => (
              <Select.Option
                key={option}
                value={option}
                data-testid={`investment_stage_${option.replaceAll(' ', '').toLowerCase()}`}
              >
                {option}
              </Select.Option>
            ))}
          </Select>
        </FormikField>,
      )}

      {shouldShowSeisEis && (
        <>
          {row(
            <CheckboxFormikField
              label={{
                for: 'hasSeisLimitsRemaining',
                label: (
                  <span>
                    {seisLimitsRemainingLabel}
                    <Tooltip title={seisLimitsRemainingTooltip} />
                  </span>
                ),
              }}
              error={errors.hasSeisLimitsRemaining}
              touched={touched.hasSeisLimitsRemaining}
              data-testid="seis-limits-remaining"
            >
              <Checkbox
                {...getFieldProps('hasSeisLimitsRemaining')}
                checked={values.hasSeisLimitsRemaining}
                id="hasSeisLimitsRemaining"
              />
            </CheckboxFormikField>,
          )}

          {row(
            <CheckboxFormikField
              label={{
                for: 'hasEisLimitsRemaining',
                label: (
                  <span>
                    {eisLimitRemainingLabel}
                    <Tooltip title={eisLimitRemainingTooltip} />
                  </span>
                ),
              }}
              error={errors.hasEisLimitsRemaining}
              touched={touched.hasEisLimitsRemaining}
              data-testid="eis-limits-remaining"
            >
              <Checkbox
                {...getFieldProps('hasEisLimitsRemaining')}
                checked={values.hasEisLimitsRemaining}
                id="hasEisLimitsRemaining"
              />
            </CheckboxFormikField>,
          )}
        </>
      )}

      {row(
        <SubmitButton
          type="primary"
          htmlType="submit"
          form="founder-profile-form"
          onClick={() => submitForm()}
          disabledEvaluation={{ isValid, dirty, submitCount }}
          data-testid="smart-match-submit"
        >
          {saveLabel}
        </SubmitButton>,
      )}
    </Section>
  );
};

export default SmartMatch;

import { Button } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Article = styled.article`
  width: ${({ theme }) => theme.ui.notificationOverlayWidth};
  height: ${({ theme }) => theme.ui.notificationOverlayHeight};
  background-color: ${({ theme }) => theme.color.sectionBackground};
  border: 1px solid ${({ theme }) => theme.color.border};
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.radius.default};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    width: 100vw;
    height: calc(100vh - 52px);
    margin-top: 10px;
  }
`;

const Header = styled.header`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacing.xmedium} ${theme.spacing.medium}`};
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.color.fontPrimary};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontSize.regular};
`;

const ClearAllButton = styled(Button)`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.color.submitButton};
`;

const Section = styled.section`
  height: 100%;
  overflow-y: scroll;
`;

const List = styled.ul`
  & > li:last-child {
    border-bottom: none;
  }
`;

const ListItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
`;

const Footer = styled.footer`
  height: 50px;
  display: flex;
  border-top: 1px solid ${({ theme }) => theme.color.border};
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => `${theme.spacing.xmedium} 0`};
`;

const ShowMoreLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.color.submitButton} !important;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  &::before {
    display: none;
  }
`;

const S = {
  Article,
  Header,
  Title,
  ClearAllButton,
  Section,
  List,
  ListItem,
  Footer,
  ShowMoreLink,
};

export default S;

import { Button as ButtonBase } from 'antd';
import styled from 'styled-components';

const Content = styled.div<{ isEmpty: boolean }>`
  padding: 0 ${({ theme }) => theme.spacing.small} 0;
  margin-bottom: ${({ theme }) => theme.spacing.small};
  overflow: hidden;
  height: 100%;
  max-height: calc(100% - 50px);
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme, isEmpty }) => (isEmpty ? theme.color.grayedOutText : theme.color.fontPrimary)};
  white-space: pre-wrap;
`;

const ModalContent = styled.div`
  white-space: pre-wrap;
`;

const ModalFooter = styled.div`
  width: 100%;
  display: flex;
`;

const ModalButton = styled(ButtonBase)`
  background: transparent;
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.medium};
  color: ${({ theme }) => theme.color.dashboardBtn};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  border: 1px solid ${({ theme }) => theme.color.submitButton};

  &:hover,
  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.baseColors.Blue200};
    background-color: ${({ theme }) => theme.color.legalDocumentHoverBackground};
  }
`;

export default {
  Content,
  ModalContent,
  ModalButton,
  ModalFooter,
};

import styled from 'styled-components';

const Wrapper = styled.div`
  color: ${({ theme }) => theme.color.fontPrimary};
`;

const FieldLabel = styled.p`
  display: block;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

export default { Wrapper, FieldLabel };

import styled from 'styled-components';

const TitleWrapper = styled.div`
  margin-top: 32px;
`;

const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default { TitleWrapper, TabWrapper, ActionsWrapper };

import type { V2BigBoyMultiSelectProps } from './v2-big-boy-multi-select.types';

import { Select } from 'antd';
import { V2Button } from '../v2-button/v2-button';
import S from './v2-big-boy-multi-select.styles';

export const V2BigBoyMultiSelect = ({
  options,
  quickOptions,
  value,
  maxChoices,
  optionsName = 'options',
  onChange,
}: V2BigBoyMultiSelectProps) => {
  const nonQuickOptionsCount = options.filter((option) => !quickOptions.includes(option)).length;

  const onClickChange = (option: string) => {
    if (value.includes(option)) {
      onChange(value.filter((val) => val !== option));
    } else if (value.length < maxChoices) {
      onChange([...value, option]);
    }
  };

  return (
    <S.V2BigBoyMultiSelect>
      <Select
        mode="multiple"
        size="middle"
        value={value}
        onChange={(val) => {
          if (val.length <= maxChoices) onChange(val);
        }}
        style={{ width: '100%', margin: 0, maxWidth: 640 }}
        options={options.map((option) => ({ label: option, value: option }))}
      />

      <S.ButtonWrapper>
        {quickOptions.map((option) => (
          <V2Button
            role="checkbox"
            aria-checked={value.includes(option)}
            aria-labelledby="chk1-label"
            variant={value.includes(option) ? 'selected' : 'outlined'}
            key={option}
            isResponsive
            onClick={(e) => {
              e.preventDefault();
              onClickChange(option);
            }}
          >
            {option}
          </V2Button>
        ))}
        <S.Text>{`+ ${nonQuickOptionsCount} ${optionsName}`}</S.Text>
      </S.ButtonWrapper>
    </S.V2BigBoyMultiSelect>
  );
};

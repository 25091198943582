import styled from 'styled-components';

export const StyledInput = styled.input`
  display: none;

  &:checked ~ label div {
    display: block;
  }

  &:checked ~ label {
    animation: none;
    border-color: ${({ theme }) => theme.color.primary};
    transition: border 0.5s ease-out;
  }
`;

export const StyledLabel = styled.label`
  height: 100px;
  width: 100px;
  display: inline-block;
  border: 3px solid ${({ theme }) => theme.color.border};
  border-radius: 50%;
  border-left-color: ${({ theme }) => theme.color.primary};
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing.medium};

  animation: rotate 1.2s linear infinite;

  @keyframes rotate {
    50% {
      border-left-color: ${({ theme }) => theme.color.primary};
    }

    75% {
      border-left-color: ${({ theme }) => theme.baseColors.Green};
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

export const CheckIcon = styled.div`
  display: none;

  &::after {
    content: '';
    position: absolute;
    left: 24px;
    top: 55%;
    transform: scaleX(-1) rotate(135deg);
    height: 46px;
    width: 18px;
    border-top: 4px solid ${({ theme }) => theme.baseColors.Green};
    border-right: 4px solid ${({ theme }) => theme.baseColors.Green};
    transform-origin: left top;
    animation: check-icon 0.8s ease;
  }

  @keyframes check-icon {
    0% {
      height: 0;
      width: 0;
      opacity: 1;
    }

    20% {
      height: 0;
      width: 18px;
      opacity: 1;
    }

    40% {
      height: 46px;
      width: 18px;
      opacity: 1;
    }

    100% {
      height: 46px;
      width: 18px;
      opacity: 1;
    }
  }
`;

import { type OnboardingStepError } from '@context/founder-onboarding/founder-onboarding.context';
import { InstitutionType, InvestorType } from '@pages/content/profile/investor/api/types';
import { createContext, type Dispatch } from 'react';
import type { Any } from 'src/types';
import { initialInvestorOnboardingState, type InvestorOnboardingActionType } from './investor-onboarding.reducer';
import type { InvestorOnboardingStep } from '@pages/content/onboarding/investor/paths';

export interface InvestorOnboardingState {
  loaded: boolean;

  started: boolean;
  finished: boolean;

  stepErrors: OnboardingStepError<InvestorOnboardingStep>[];

  accountType: InvestorType | null;
  institutionType: InstitutionType | null;
}

export interface InvestorOnboardingContextApi {
  state: InvestorOnboardingState;
  dispatch: Dispatch<InvestorOnboardingActionType>;
  refetch: () => Promise<Any>;
  isLoading: boolean;
}

export const InvestorOnboardingContext = createContext<InvestorOnboardingState | InvestorOnboardingContextApi>(
  initialInvestorOnboardingState,
);

import styled from 'styled-components';
import type { Variant } from '@ui/v2-suggestion-banner/v2-suggestion-banner.types';
import type { V2Theme } from '@styles/v2-theme/v2-theme';

const getBackground = (variant: Variant, themeConfig: V2Theme) => {
  const backgroundMap = {
    primary: themeConfig.gradients['light-blue-gradient'],
    secondary: themeConfig.colors.blue[400],
  };

  return backgroundMap[variant] || backgroundMap.primary;
};

const getColor = (variant: Variant, themeConfig: V2Theme) => {
  const colorMap = {
    primary: themeConfig.colors.neutral[700],
    secondary: themeConfig.colors.neutral[50],
  };

  return colorMap[variant] || colorMap.primary;
};

const V2SuggestionBanner = styled('div')<{ $variant: Variant }>`
  border-radius: ${({ theme }) => theme.v2.borders.radius.md};
  background: ${({ theme, $variant }) => getBackground($variant, theme.v2)};
  color: ${({ theme, $variant }) => getColor($variant, theme.v2)};
  width: 100%;
  font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
  line-height: ${({ theme }) => theme.v2.typography.lineHeights.button};

  display: flex;
  align-items: flex-start;
  justify-content: center;
  text-align: center;
  padding: 12px;
  gap: 12px;
  font-size: ${({ theme }) => theme.v2.typography.sizes.xs};

  @media (min-width: ${({ theme }) => theme.v2.breakpoints.sm}) {
    padding: 12px 24px;
    gap: 16px;
    align-items: center;
    font-size: ${({ theme }) => theme.v2.typography.sizes.sm};
  }

  @media (min-width: ${({ theme }) => theme.v2.breakpoints.md}) {
    padding: 12px 40px;
    gap: 32px;
  }
`;

const TextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;

  @media (min-width: ${({ theme }) => theme.v2.breakpoints.md}) {
    flex-direction: row;
    gap: 32px;
  }
`;

export default { V2SuggestionBanner, TextWrapper };

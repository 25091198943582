import { axiosInstance } from '@utils/axios/axios-instance';
import type { AxiosResponse } from '@utils/axios/types';

export const updateDocumentsNoteAction =
  (investingRelationshipId: string) =>
  ({ documentId, note }: { documentId: string; note: string }): Promise<AxiosResponse<{ note: string }>> =>
    axiosInstance.patch(
      `/portfolio-management/investing-relationship/${investingRelationshipId}/documents/${documentId}`,
      { note: note || null },
    );

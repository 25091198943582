import { Tag as TagBase } from 'antd';
import styled from 'styled-components';

const Tag = styled(TagBase)`
  color: ${({ theme }) => theme.color.tagFont};
  background-color: ${({ theme }) => theme.color.tagBackground};
  margin-right: 2px;
  font-size: 14px;
  padding: 6px 14px 6px 6px;

  :first-letter {
    text-transform: uppercase;
  }
  .ant-tag-close-icon {
    top: 2px;
    position: relative;
    right: -6px;
  }
`;

export default { Tag };

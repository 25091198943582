import type { GraphqlResponse } from '@/types';
import { axiosInstance } from '@/utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@/utils/fns/graphql-response-wrapper';

type FollowInvitationId = {
  followInvitationId: string;
};

export const getFollowInvitationIdFromFounderId = async (
  founderId: string,
): Promise<GraphqlResponse<FollowInvitationId>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getFollowInvitationIdFromFounderId {
          getFounderPublicData(founderId:"${founderId}") {
            followInvitationId
          }
      }`,
  });

  return graphqlResponseWrapper(data, ['getFounderPublicData']);
};

export const advisorRemoveConnections = async (founderId: string) => {
  const res = await getFollowInvitationIdFromFounderId(founderId);

  if (res.data) {
    const { followInvitationId } = res.data;
    return axiosInstance.post(`/connections/ned/connection/${followInvitationId}/remove`);
  }

  throw new Error('Failed to disconnect founder');
};

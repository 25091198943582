import S from './intro.style';
import { IntroCompanies } from './parts/intro-companies/intro-companies';
import { IntroFeatures } from './parts/intro-features';

export const IntroPageContent = () => (
  <>
    <IntroFeatures />
    <S.Divider />
    <IntroCompanies />
  </>
);

import styled from 'styled-components';

const StyledEllipsis = styled.div`
  .LinesEllipsis-ellipsis {
    color: ${({ theme }) => theme.baseColors.Blue200};
    cursor: pointer;
    position: relative;

    // react-lines-ellipsis package has np stable html ellipsis text option
    // this hacky solution is applied to color ... part in different
    &::before {
      content: '...';
      color: ${({ theme }) => theme.color.fontPrimary};
      position: absolute;
    }
  }
`;

export default {
  StyledEllipsis,
};

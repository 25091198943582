import { DefaultAvatar } from '@parts/default-avatar/default-avatar';
import S from '@parts/profile-picture/profile-picture.styles';

interface ProfilePictureProps {
  src: string | null;
  width?: string;
  height?: string;
  className?: string;
}

export const ProfilePicture = ({ src, width, height, className }: ProfilePictureProps) => (
  <S.Wrapper className={className} width={width} height={height}>
    {src ? <S.Image src={src} loading="lazy" /> : <DefaultAvatar size={40} />}
  </S.Wrapper>
);

import BottomLeft from '@assets/illustrations/auth/bottom-left-pattern.svg?react';
import TopRight from '@assets/illustrations/auth/top-right-pattern.svg?react';
import styled, { keyframes } from 'styled-components';

const draw = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

export const PatternLayoutWrapper = styled.div`
  position: relative;
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background: ${({ theme }) => theme.color.background};
`;

export const TopRightPattern = styled(TopRight)`
  position: absolute;
  top: 0px;
  right: -800px;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: ${draw} 1.5s linear forwards;
`;

export const BottomLeftPattern = styled(BottomLeft)`
  position: absolute;
  bottom: 0;
  left: 0;
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: ${draw} 2s linear reverse;
`;

export default { PatternLayoutWrapper, TopRightPattern, BottomLeftPattern };

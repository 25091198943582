import { type RoleAbilityBuilderFunction } from '../types';

const createRoutingAbilities: RoleAbilityBuilderFunction = (builder) => {
  return builder;
};

const createActionAbilities: RoleAbilityBuilderFunction = (builder) => {
  return builder;
};

export const createNoRoleAbility: RoleAbilityBuilderFunction = (builder, attributes) => {
  createRoutingAbilities(builder, attributes);
  createActionAbilities(builder, attributes);

  return builder;
};

import CloseOutlined from '@assets/icons/close-outlined.svg?react';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { SelectValue } from 'antd/lib/select';
import { useRef, type ReactNode } from 'react';
import S from './group-select.styles';

interface OptionGroup {
  label: string | null;
  disabled?: boolean;
  options: { value: string; label: string; disabled?: boolean }[];
}

interface GroupSelectProps {
  onChange: (value: SelectValue) => void;
  options: OptionGroup[];
  noTags?: boolean;
  value: string[] | null;
  autoClearSearchValue?: boolean;
  id?: string;
  showSearch?: boolean;
  placeholder?: string;
  showArrow?: boolean;
  isLoading?: boolean;
}

export const GroupSelect = ({
  onChange,
  options,
  value,
  noTags,
  autoClearSearchValue = true,
  showSearch = true,
  showArrow = true,
  placeholder = '',
  isLoading = false,
  ...rest
}: GroupSelectProps) => {
  const selectRef = useRef<HTMLElement>(null);

  const [unavailableLabel] = useTranslation(['option.group.unavailable']);

  const tagRender = ({
    label,
    closable,
    onClose,
  }: {
    label: ReactNode;
    closable: boolean;
    onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  }): JSX.Element =>
    noTags ? (
      <></>
    ) : (
      <S.Tag
        key={`key_${value}`}
        closeIcon={<CloseOutlined />}
        closable={closable}
        onClose={(e) => {
          onClose(e);
          if (selectRef.current) {
            selectRef.current.focus();
          }
        }}
      >
        {label}
      </S.Tag>
    );

  return (
    <S.Select
      ref={selectRef}
      showArrow={showArrow}
      showSearch={showSearch}
      mode="multiple"
      optionFilterProp="children"
      value={value ?? []}
      tagRender={tagRender}
      onChange={onChange}
      autoClearSearchValue={autoClearSearchValue}
      dropdownClassName="countries-and-regions-select"
      placeholder={placeholder}
      loading={isLoading}
      {...rest}
    >
      <>
        {options.map((optionGroup: OptionGroup) => {
          if (optionGroup.label) {
            return (
              <S.Select.OptGroup
                label={<S.OptGroupLabel>{optionGroup.label}</S.OptGroupLabel>}
                key={optionGroup.label}
                disabled={optionGroup.disabled}
              >
                {optionGroup.options.map((option) => (
                  <S.Select.Option key={option.value} value={option.value} disabled={option.disabled}>
                    {option.label}
                  </S.Select.Option>
                ))}
              </S.Select.OptGroup>
            );
          }

          return optionGroup.options.map((option) => (
            <S.Select.Option key={option.label} value={option.value} disabled={option.disabled}>
              {option.disabled ? (
                <>
                  <>{option.label}</>
                  <S.Span>{unavailableLabel}</S.Span>
                </>
              ) : (
                option.label
              )}
            </S.Select.Option>
          ));
        })}
      </>
    </S.Select>
  );
};

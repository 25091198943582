import SearchIcon from '@assets/icons/search.svg?react';
import { applySearchToQuery } from '@pages/content/lens/utils/apply-filters-to-query';
import CheckboxFormikField from '@pages/content/profile/parts/checkbox-formik-field/checkbox-formik-field';
import { FormikField } from '@pages/content/profile/parts/formik-field/formik-field';
import type { RolesFiltersOption } from '@pages/content/roles/catalogue/api/get-ned-roles.actions';
import {
  GET_ROLE_SEARCH_OPTIONS_CACHE_KEY,
  getRoleSearchOptionsAction,
} from '@pages/content/roles/catalogue/api/get-role-search-options.actions';
import Checkbox from '@parts/checkbox/checkbox';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { Tooltip } from '@parts/tooltip/tooltip';
import { useQuery } from '@tanstack/react-query';
import { queryParamToArray, queryParamToBoolean, queryParamToString } from '@utils/fns/parse-query-param';
import { sortArrayAlphabetically } from '@utils/fns/sortArrayAlphabetically';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Col, Row } from 'antd';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import type { SelectValue } from 'antd/lib/select';
import { useFormik } from 'formik';
import { useValidationSchema } from './validation-schema';

import S from './filters.styles';

export const Filters = () => {
  const history = useHistory();
  const query = useQueryParams();

  const [
    locationLabel,
    skillSetLabel,
    roleLabel,
    roleTypeLabel,
    compensationLabel,
    engagementLabel,
    companyStageLabel,
    placeholder,
    newOnlyLabel,
    newOnlyTooltip,
    apply,
  ] = useTranslation([
    'roles.ned.search.locations',
    'roles.ned.search.skillSets',
    'roles.ned.search.role',
    'roles.ned.search.roleType',
    'roles.ned.search.compensationTypes',
    'roles.ned.search.engagementTypes',
    'roles.ned.search.companyStage',
    'roles.ned.search.placeholder',
    'lens.filters.newRolesLabel',
    'lens.filters.newRolesTooltip',
    'roles.ned.search.apply',
  ]);

  const { page, ...initialQuery } = query;

  const { roleTitle, location, skillSet, roleType, engagement, compensation, companyStage, onlyNewlyActivated } =
    initialQuery;

  const { submitForm, getFieldProps, setFieldValue, setFieldTouched, values, errors, touched, isValid } =
    useFormik<RolesFiltersOption>({
      initialValues: {
        roleTitle: queryParamToString(roleTitle),
        location: queryParamToArray(location),
        skillSet: queryParamToArray(skillSet),
        roleType: queryParamToArray(roleType),
        engagement: queryParamToArray(engagement),
        compensation: queryParamToArray(compensation),
        companyStage: queryParamToArray(companyStage),
        onlyNewlyActivated: queryParamToBoolean(onlyNewlyActivated),
      },
      onSubmit: () => {
        applySearchToQuery({
          history,
          initialQuery: { page: '1', ...initialQuery },
          search: values,
        });
      },
      validationSchema: useValidationSchema(),
    });

  const handleSelectChange = (value: SelectValue, field: string) => {
    setFieldValue(field, value);
    setFieldTouched(field, true);
  };

  const { data: response, isLoading } = useQuery([GET_ROLE_SEARCH_OPTIONS_CACHE_KEY], getRoleSearchOptionsAction);

  if (isLoading || !response?.data) return <FullHeightSpinner small />;

  const { locations, skillSets, roleTypes, engagementTypes, compensationTypes, productStages } = response.data;

  return (
    <S.Section>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={24} lg={8}>
          <CheckboxFormikField
            data-testid="filters-show-only-newly-activated"
            label={{
              for: 'onlyNewlyActivated',
              label: (
                <span>
                  {newOnlyLabel}
                  <Tooltip title={newOnlyTooltip} />
                </span>
              ),
            }}
          >
            <Checkbox
              id="onlyNewlyActivated"
              checked={values.onlyNewlyActivated}
              onChange={(e: CheckboxChangeEvent) => {
                setFieldValue('onlyNewlyActivated', e.target.checked);
                setFieldTouched('onlyNewlyActivated', true);
              }}
            />
          </CheckboxFormikField>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={16}>
          <FormikField
            label={{
              for: 'roleTitle',
              label: roleLabel,
            }}
          >
            <S.Input
              {...getFieldProps('roleTitle')}
              placeholder={placeholder}
              data-testid="role-title-input"
              prefix={<SearchIcon />}
              onChange={(e) => setFieldValue('roleTitle', e.target.value)}
              value={values.roleTitle || undefined}
            />
          </FormikField>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <FormikField
            label={{
              for: 'location',
              label: locationLabel,
            }}
            error={errors.location ? (errors.location as string) : undefined}
            touched={touched.location}
          >
            <S.MultiSelectSearch
              placeholder={placeholder}
              {...getFieldProps('location')}
              options={sortArrayAlphabetically(locations)}
              onChange={(value: SelectValue) => handleSelectChange(value, 'location')}
              maxTagCount="responsive"
            />
          </FormikField>
        </Col>
      </Row>
      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={8}>
          <FormikField
            label={{
              for: 'skillSet',
              label: skillSetLabel,
            }}
            error={errors.skillSet ? (errors.skillSet as string) : undefined}
            touched={touched.skillSet}
          >
            <S.MultiSelectSearch
              placeholder={placeholder}
              {...getFieldProps('skillSet')}
              options={skillSets}
              onChange={(value: SelectValue) => handleSelectChange(value, 'skillSet')}
              maxTagCount="responsive"
            />
          </FormikField>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <FormikField
            label={{
              for: 'roleType',
              label: roleTypeLabel,
            }}
            touched={touched.roleType}
          >
            <S.MultiSelectSearch
              {...getFieldProps('roleType')}
              onChange={(value: SelectValue) => handleSelectChange(value, 'roleType')}
              placeholder={placeholder}
              options={roleTypes}
              maxTagCount="responsive"
            />
          </FormikField>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <FormikField
            label={{
              for: 'compensation',
              label: compensationLabel,
            }}
            touched={touched.compensation}
          >
            <S.MultiSelectSearch
              {...getFieldProps('compensation')}
              onChange={(value: SelectValue) => handleSelectChange(value, 'compensation')}
              placeholder={placeholder}
              options={compensationTypes}
              maxTagCount="responsive"
            />
          </FormikField>
        </Col>
      </Row>

      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={8}>
          <FormikField
            label={{
              for: 'engagement',
              label: engagementLabel,
            }}
          >
            <S.MultiSelectSearch
              {...getFieldProps('engagement')}
              onChange={(value: SelectValue) => handleSelectChange(value, 'engagement')}
              placeholder={placeholder}
              options={engagementTypes}
              maxTagCount="responsive"
            />
          </FormikField>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <FormikField
            label={{
              for: 'companyStage',
              label: companyStageLabel,
            }}
            error={errors.companyStage ? (errors.companyStage as string) : undefined}
            touched={touched.companyStage}
          >
            <S.MultiSelectSearch
              placeholder={placeholder}
              {...getFieldProps('companyStage')}
              options={productStages}
              onChange={(value: SelectValue) => handleSelectChange(value, 'companyStage')}
              maxTagCount="responsive"
            />
          </FormikField>
        </Col>
      </Row>

      <Row>
        <S.Apply onClick={() => submitForm()} disabled={!isValid}>
          {apply}
        </S.Apply>
      </Row>
    </S.Section>
  );
};

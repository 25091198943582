import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import Big from 'big.js';
import { DateTime } from 'luxon';
import * as Yup from 'yup';
import type { InternalOptions } from 'yup/lib/types';
import type { Investment } from './editor-interfaces';

const getFactor = (scale: number) => Big(10).pow(scale).toNumber();

const scaleValidation = (value: number, scale: number) =>
  Number.isInteger(
    Big(value || 0)
      .times(getFactor(scale))
      .toNumber(),
  );

export const termsValidation = (minValueLabel: string, integerLabel: string, bigLabel: string) =>
  Yup.object({
    warrants: Yup.object({
      amount: Yup.number()
        .integer(integerLabel)
        .min(1, minValueLabel)
        .max(Number.MAX_SAFE_INTEGER, bigLabel)
        .nullable(),
      price: Yup.number()
        .test('there-should-not-be-more-than-2-digits-after-decimal', 'Two decimal places are allowed', function () {
          return scaleValidation(this.parent.price, 2);
        })
        .min(1, minValueLabel)
        .max(Number.MAX_SAFE_INTEGER, bigLabel)
        .nullable(),
      expiry: Yup.string().nullable(),
    }),
    options: Yup.string().nullable(),
    convertibleLoanNote: Yup.string().nullable(),
    discount: Yup.number()
      .test('there-should-not-be-more-than-1-digits-after-decimal', 'One decimal place is allowed', function () {
        return scaleValidation(this.parent.discount, 1);
      })
      .min(1, minValueLabel)
      .max(100, bigLabel)
      .nullable(),
  });

export const amountValidation = (requiredLabel: string, bigLabel: string) =>
  Yup.number()
    .test('there-should-not-be-more-than-2-digits-after-decimal', 'Two decimal places are allowed', function () {
      return scaleValidation(this.parent.amount, 2);
    })
    .nullable()
    .required(requiredLabel)
    .max(Number.MAX_SAFE_INTEGER, bigLabel);

export const sharesValidation = (minValueLabel: string, integerLabel: string, bigLabel: string) =>
  Yup.number().integer(integerLabel).min(1, minValueLabel).max(Number.MAX_SAFE_INTEGER, bigLabel).nullable();

export const leadInvestorValidation = (maxLengthLabel: string) => Yup.string().max(100, maxLengthLabel).nullable();

export const shareClassValidation = () => Yup.string().nullable();

export const percentageOwnershipValidation = (minValueLabel: string, maxValueLabel: string) =>
  Yup.number()
    .test('there-should-not-be-more-than-3-digits-after-decimal', 'Three decimal places are allowed', function () {
      return scaleValidation(this.parent.percentageOwnership, 3);
    })
    .min(0.1, minValueLabel)
    .max(100, maxValueLabel)
    .nullable();

export const useValidationSchema = () => {
  const [
    requiredLabel,
    integerLabel,
    minValueLabel,
    maxValueLabel,
    noteLengthLabel,
    duplicateRoundLabel,
    bigLabel,
    leadInvestorLengthLabel,
    validEmailLabel,
  ] = useTranslation([
    'formik.validation.required',
    'formik.validation.integer',
    'formik.validation.lessThanMinimum',
    'formik.validation.largerThanMaximum',
    'portfolio.investor.drawer.noteLengthExceeded',
    'portfolio.investor.drawer.duplicateRound',
    'formik.validation.numberTooBig',
    'portfolio.investor.drawer.leadInvestorLengthExceeded',
    'formik.validation.email',
  ]);

  return Yup.object({
    externalCompanyEmail: Yup.string().trim().email(validEmailLabel).nullable(),
    investments: Yup.array().of(
      Yup.object({
        round: Yup.object({
          id: Yup.string()
            .test('id-validation', 'Invalid id', function () {
              const { type, date, id: val } = this.parent;
              const alternativeValue = type || date;
              return (val && !alternativeValue) || (!val && alternativeValue);
            })
            .nullable(),
          type: Yup.string()
            .test('type-validation', 'Invalid type', function () {
              const { type: val, id: roundId } = this.parent;
              return (!val && roundId) || (val && !roundId);
            })
            .nullable(),
          date: Yup.string()
            .test('date-validation', 'Invalid date', function () {
              const { date: val, id: roundId } = this.parent;
              return (!val && roundId) || (val && !roundId);
            })
            .test('there-should-not-be-duplicated-round', duplicateRoundLabel, function () {
              const { id: roundId, type: selectedType, date: selectedDate } = this.parent;

              if (roundId) return true;

              const options = this.options as InternalOptions<{}>;
              const investments = options.from && options.from.find((ofr) => ofr.value.investments)?.value.investments;

              if (!selectedDate) return false;

              const { month: selectedMonth, year: selectedYear } = DateTime.fromJSDate(new Date(selectedDate));

              return (
                investments.filter((inv: Investment) => {
                  const {
                    round: { date, type },
                  } = inv;

                  if (!date) return false;

                  const { month, year } = DateTime.fromJSDate(new Date(date));

                  return type === selectedType && month === selectedMonth && year === selectedYear;
                }).length <= 1
              );
            })
            .nullable(),
        }),
        amount: amountValidation(requiredLabel, bigLabel),
        preMoneyVal: Yup.number()
          .test('there-should-not-be-more-than-2-digits-after-decimal', 'Two decimal places are allowed', function () {
            return scaleValidation(this.parent.preMoneyVal, 2);
          })
          .min(0, minValueLabel)
          .max(Number.MAX_SAFE_INTEGER, bigLabel)
          .nullable(),
        postMoneyVal: Yup.number()
          .test('there-should-not-be-more-than-2-digits-after-decimal', 'Two decimal places are allowed', function () {
            return scaleValidation(this.parent.postMoneyVal, 2);
          })
          .min(0, minValueLabel)
          .max(Number.MAX_SAFE_INTEGER, bigLabel)
          .nullable(),
        shares: sharesValidation(minValueLabel, integerLabel, bigLabel),
        percentageOwnership: percentageOwnershipValidation(minValueLabel, maxValueLabel),
        shareClass: shareClassValidation(),
        leadInvestor: leadInvestorValidation(leadInvestorLengthLabel),
        personalNote: Yup.string().max(230, noteLengthLabel).nullable(),
        terms: termsValidation(minValueLabel, integerLabel, bigLabel),
      }),
    ),
  });
};

import { GradientBorderBox } from '@parts/gradient-border-box/gradient-border-box';
import styled, { css } from 'styled-components';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;

const Wrapper = styled(GradientBorderBox)<{ $disabled: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 250px;
  width: 100%;
  min-width: 265px;
  max-width: 400px;
  padding: ${({ theme }) => theme.spacing.xbase};
  padding-top: ${({ theme }) => theme.spacing.base};
  text-align: center;

  ${({ $disabled }) =>
    $disabled &&
    css`
      &::before,
      ${Content} {
        opacity: 0.45;
        pointer-events: none;
      }
    `}
`;

const BadgesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing.small};
`;

const Title = styled.h3`
  margin-bottom: ${({ theme }) => theme.spacing.xmedium};
  color: inherit;
  font-size: ${({ theme }) => theme.fontSize.medium};
`;

const Text = styled.p`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export default { Wrapper, Title, Text, Content, BadgesWrapper };

import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { getPercentageDifference } from '@utils/fns/get-percentage-difference';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './numeric-metric.styles';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { DateFormat } from '@i18n/date-formats';

export const NumericMetric = ({
  value,
  forecast,
  note,
  date,
}: {
  value: number | null;
  forecast: number | null;
  note: string | null;
  date: string | null;
}) => {
  const { dateFormatter } = useUserDateFormat();

  const forecastLabel = useTranslation('pulseDashboard.numericMetric.forecast');

  const showDiff = typeof value === 'number' && typeof forecast === 'number';
  const percentageDiff = showDiff ? getPercentageDifference(value, forecast) : 0;

  return (
    <S.Wrapper>
      <S.DateWrapper>{date && dateFormatter(date, DateFormat.FULL_MONTH_LONG_YEAR)}</S.DateWrapper>
      <S.Value>
        {value}
        {showDiff ? (
          <S.Progress positive={percentageDiff >= 0}>
            {percentageDiff > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />} {percentageDiff}%
          </S.Progress>
        ) : null}
      </S.Value>
      {forecast !== null && (
        <S.Forecast>
          {forecastLabel}: <S.ForecastValue>{forecast}</S.ForecastValue>
        </S.Forecast>
      )}
      {note && <S.Note>{note}</S.Note>}
    </S.Wrapper>
  );
};

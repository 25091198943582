import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 460px;
`;

const Title = styled.h3`
  margin: ${({ theme }) => theme.spacing.medium} 0 0;
  font-size: 22px;
  line-height: 1.25;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.color.fontPrimary};
`;

const Description = styled.p`
  margin: ${({ theme }) => theme.spacing.xbase} 0 0;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  color: ${({ theme }) => theme.color.fontSecondary};
`;

export default {
  Wrapper,
  Title,
  Description,
};

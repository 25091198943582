import { V2Button } from '@/components/ui/v2-button/v2-button';
import { V2RadioGroup } from '@/components/ui/v2-radio-group/v2-radio-group';
import { V2Radio } from '@/components/ui/v2-radio/v2-radio';
import { useMediaQuery } from '@/hooks/use-media-query';
import type { FounderQualifyingQuestionsResponseData } from '@/modules/founder/homepage/api/get-founder-qualifying-questions.action';
import { usePatchFounderQualifyingQuestions } from '@/modules/founder/homepage/hooks/use-patch-founder-qualifying-questions';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { useUserCurrency } from '@/utils/hooks/use-user-currency/use-user-currency';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import S from '@/modules/founder/homepage/components/qualifying-questions-modal/components/steps.styles';

type MonthlyRevenueScaleStepProps = {
  currentStep: number;
  countOfSteps: number;
  initialValues: FounderQualifyingQuestionsResponseData;
  back: () => void;
  next: () => void;
};

export const MonthlyRevenueScaleStep = ({
  currentStep,
  countOfSteps,
  initialValues,
  back,
  next,
}: MonthlyRevenueScaleStepProps) => {
  const { patchFounderQualifyingQuestionsMutation } = usePatchFounderQualifyingQuestions(next);
  const device = useMediaQuery();
  const { currencySymbol } = useUserCurrency();

  const formik = useFormik<{ monthlyRevenueScale?: number }>({
    initialValues: { monthlyRevenueScale: initialValues.monthlyRevenueScale ?? undefined },
    validationSchema: Yup.object({ monthlyRevenueScale: Yup.number().required() }),
    onSubmit: (values) => patchFounderQualifyingQuestionsMutation({ monthlyRevenueScale: values.monthlyRevenueScale }),
  });

  const [title, subtitle, backText, nextText, answer1] = useTranslation([
    'qualifying-questions.monthly-revenue-scale-step.title',
    'qualifying-questions.select-one',
    'qualifying-questions.previous-question.action',
    'qualifying-questions.next-question.action',
    'qualifying-questions.monthly-revenue-scale-step.answer1',
  ]);

  const options = [
    { value: 1, label: answer1 },
    { value: 2, label: `${currencySymbol}1 - ${currencySymbol}10,000` },
    { value: 3, label: `${currencySymbol}10,001 - ${currencySymbol}50,000` },
    { value: 4, label: `${currencySymbol}50,001 - ${currencySymbol}200,000` },
    { value: 5, label: `${currencySymbol}200,000+` },
  ];

  const selectedButtonSize = device === 'xs' ? 'small' : 'large';
  const shouldIconBeDisplayed = device === 'xs' ? undefined : true;
  const selectedRadioVariant = device === 'xs' ? 'primary' : 'secondary';

  return (
    <S.Wrapper>
      <S.TitleGroupWrapper
        title={title}
        subtitle={subtitle}
        align="center"
        currentStep={currentStep}
        countOfSteps={countOfSteps}
      />

      <V2RadioGroup variant="vertical" defaultValue={formik.values.monthlyRevenueScale}>
        {options.map((option) => (
          <V2Radio
            variant={selectedRadioVariant}
            key={option.value}
            value={option.value}
            checked={formik.values.monthlyRevenueScale === option.value}
            onChange={() => formik.setFieldValue('monthlyRevenueScale', option.value)}
          >
            {option.label}
          </V2Radio>
        ))}
      </V2RadioGroup>

      <S.ButtonWrapper>
        <V2Button
          variant="outlined"
          onClick={back}
          startIcon={shouldIconBeDisplayed && 'arrow-left'}
          size={selectedButtonSize}
        >
          {backText}
        </V2Button>
        <V2Button
          variant="primary"
          onClick={formik.submitForm}
          endIcon={shouldIconBeDisplayed && 'arrow-right'}
          disabled={Boolean(formik.errors.monthlyRevenueScale) || !formik.values.monthlyRevenueScale}
          size={selectedButtonSize}
        >
          {nextText}
        </V2Button>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

import { Switch as SwitchBase } from 'antd';
import styled from 'styled-components';

const Wrapper = styled(SwitchBase)`
  &[aria-checked='true'] {
    background-color: ${({ theme }) => theme.baseColors.Blue200};
  }
`;

export default { Wrapper };

import { type V2Breakpoints, breakpoints } from '../breakpoints';

export const customMediaQuery = (property: string, values: Partial<V2Breakpoints>): string => {
  let styles = '';

  Object.entries(values).forEach(([breakpoint, value]) => {
    if (breakpoint && breakpoints[breakpoint as keyof V2Breakpoints]) {
      styles += `@media (min-width: ${breakpoints[breakpoint as keyof V2Breakpoints]}) {
          ${property}: ${value};
        }`;
    }
  });

  return styles;
};

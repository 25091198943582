import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing.base};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    justify-content: center !important;
  }
`;

const Grid = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  column-gap: ${({ theme }) => theme.spacing.xbase};
  row-gap: ${({ theme }) => theme.spacing.xbase};
  width: 100%;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    grid-template-columns: repeat(2, minmax(360px, 1fr));
    width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    grid-template-columns: minmax(310px, 1fr);
    width: 100%;
  }
`;

export default { Grid, Wrapper };

import { applySearchToQuery, type Search } from '@pages/content/lens/utils/apply-filters-to-query';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useEffect, useReducer } from 'react';
import type { AppAction } from 'src/types';
import { useQueryParams } from '../use-query/use-query-params';

export const RESET = 'column-filters/reset';
export const RECOVER = 'column-filters/reproduce';
export const RESET_COLUMN = 'column-filters/reset-column';
export const RESET_SORT = 'column-filters/reset-sort';
export const RESET_FILTERS = 'column-filters/reset-filters';
export const SET_COLUMN = 'column-filters/set-column';

export type NumericFilter = { comparator: NumberComparator; value: number; rangeValue?: number };
export type TextFilter = { comparator: TextComparator; value: string; values: string[] };
export type BooleanFilter = { comparator: BooleanComparator; value: boolean };
export type DateFilter = { comparator: DateComparator; value: string };
export type Sort = { key: string; order: SortDirections };

export enum Comparator {
  Empty = 'Empty',
  NotEmpty = 'NotEmpty',
}

export enum FilterKinds {
  String = 'String',
  Number = 'Number',
  Boolean = 'Boolean',
  Date = 'Date',
}

export enum NumberComparator {
  Equal = 'Equal',
  NotEqual = 'NotEqual',
  GreaterThan = 'GreaterThan',
  GreaterThanOrEqual = 'GreaterThanOrEqual',
  LessThan = 'LessThan',
  LessThanOrEqual = 'LessThanOrEqual',
  Between = 'Between',
  NotBetween = 'NotBetween',
}

export enum TextComparator {
  Contains = 'Contains',
  DoesNotContain = 'DoesNotContain',
  StartsWith = 'StartsWith',
  EndsWith = 'EndsWith',
  IsExactly = 'IsExactly',
  ListOfValues = 'ListOfValues',
}

export enum BooleanComparator {
  Equal = 'Equal',
  NotEqual = 'NotEqual',
}

export enum DateComparator {
  Equal = 'Equal',
  IsBefore = 'IsBefore',
  IsAfter = 'IsAfter',
}

export enum SortDirections {
  Asc = 'Asc',
  Desc = 'Desc',
}

export type ColumnsQuerying = {
  sortKey?: string;
  filteringRules: FilteringRules;
  sortingRules: SortingRules;
  dispatch: Function;
};

export enum QueryFilterKind {
  Numeric = 'numericFilter',
  Date = 'dateFilter',
  Boolean = 'booleanFilter',
  Text = 'textFilter',
}

export type FiltersSet = {
  key: string;
  [QueryFilterKind.Numeric]?: NumericFilter;
  [QueryFilterKind.Date]?: DateFilter;
  [QueryFilterKind.Boolean]?: BooleanFilter;
  [QueryFilterKind.Text]?: TextFilter;
}[];

type ColumnsFilterState = {
  sort?: Sort[];
  filters?: FiltersSet;
};

export type Actions =
  | AppAction<typeof RESET>
  | AppAction<typeof RECOVER, { state: ColumnsFilterState }>
  | AppAction<
      typeof SET_COLUMN,
      {
        filters: FiltersSet;
        key: string;
        sort?: Sort;
      }
    >
  | AppAction<typeof RESET_SORT>
  | AppAction<typeof RESET_FILTERS>
  | AppAction<typeof RESET_COLUMN, { key: string }>;

export type SortingRules = string[];

export type FilteringRules = {
  key: string;
  availableValues: string[];
  type: FilterKinds;
  allowedComparators: AllowedComparators;
}[];

export type AllowedComparators =
  | (NumberComparator | TextComparator | Comparator | BooleanComparator | DateComparator)[]
  | null;

export const reset: () => Actions = () => ({
  type: RESET,
});

export const resetSort: () => Actions = () => ({
  type: RESET_SORT,
});

export const resetFilters: () => Actions = () => ({
  type: RESET_FILTERS,
});

export const recover: (state: ColumnsFilterState) => Actions = (state) => ({
  type: RECOVER,
  state,
});

export const setColumn: (filters: FiltersSet, key: string, sort?: Sort) => Actions = (filters, key, sort) => ({
  type: SET_COLUMN,
  key,
  filters,
  sort,
});

export const resetColumn: (key: string) => Actions = (key) => ({
  type: RESET_COLUMN,
  key,
});

const reducer = (state: ColumnsFilterState, action: Actions): ColumnsFilterState => {
  switch (action.type) {
    case RESET:
      return {};

    case RESET_COLUMN: {
      const nextFilters = state.filters?.filter((f) => f.key !== action.key);
      const nextSort = state.sort?.filter((s) => s.key !== action.key);

      return {
        ...state,
        filters: nextFilters && nextFilters?.length > 0 ? nextFilters : undefined,
        sort: nextSort && nextSort.length > 0 ? nextSort : undefined,
      };
    }

    case SET_COLUMN: {
      const nextFilters = state.filters
        ? [...state.filters.filter((f) => f.key !== action.key), ...action.filters]
        : action.filters;

      return {
        sort: action.sort ? [action.sort] : state.sort,
        filters: nextFilters && nextFilters.length > 0 ? nextFilters : undefined,
      };
    }

    case RESET_SORT: {
      const { sort, ...rest } = state;
      return rest;
    }

    case RESET_FILTERS: {
      const { filters, ...rest } = state;
      return rest;
    }

    case RECOVER:
      return action.state;

    default:
      return state;
  }
};

export const useFiltering = ({ pageParam }: { pageParam: string }) => {
  const history = useHistory();
  const query = useQueryParams();
  const { [pageParam]: page, ...initialQuery } = query;

  const [state, dispatch] = useReducer(reducer, {});

  useEffect(() => {
    dispatch(
      recover({
        ...(query.filters && typeof query.filters === 'string' && { filters: JSON.parse(query.filters) }),
        ...(query.sort && typeof query.sort === 'string' && { sort: JSON.parse(query.sort) }),
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stringifiedState = JSON.stringify(state);

  useEffect(() => {
    if (Object.keys(state).length) {
      applySearchToQuery({
        history,
        initialQuery,
        search: { [pageParam]: page, ...state } as Search,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringifiedState]);

  return { dispatch, sortKey: state.sort?.[0]?.key };
};

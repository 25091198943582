import { EmptyState } from '@parts/empty-state/empty-state';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';

export const NoResultsBanner = () => {
  const [title, subTitle] = useTranslation([
    'advisors.search.no-results-banner.title',
    'advisors.search.no-results-banner.subTitle',
  ]);

  return <EmptyState title={title} subTitle={subTitle} />;
};

import { FormikField as FormikFieldBase } from '@parts/forms/formik-field/formik-field';
import { TableColumn as TableColumnBase } from '@parts/table-column/table-column';
import { Input as InputBase } from 'antd';
import styled from 'styled-components';

const Input = styled(InputBase.TextArea)`
  &.ant-input {
    border: none;
    box-shadow: none;
    height: 45px;
    resize: none;
    width: 100%;
  }
`;

const Link = styled.a`
  color: ${({ theme }) => theme.color.link};

  &:hover {
    color: ${({ theme }) => theme.color.link};
  }
`;

const RightTableColumn = styled(TableColumnBase)`
  justify-content: flex-end;

  & > div {
    justify-content: flex-end;
  }
`;

const FormikField = styled(FormikFieldBase)`
  width: 100%;
  margin-left: -10px;
`;

const ComponentHolder = styled.div`
  left: 0;
  max-width: 90%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    max-width: 100%;
    position: relative;
    transform: none;

    div {
      white-space: initial;
    }
  }
`;

export default { Input, Link, RightTableColumn, FormikField, ComponentHolder };

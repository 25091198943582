import { V2AriaTable } from '@/components/blocks/v2-aria-table/v2-aria-table';
import { V2Badge } from '@/components/ui/v2-badge/v2-badge';
import type { FounderConnection } from '../../../types';

export const ComingSoonCell = (_: FounderConnection) => {
  return (
    <V2AriaTable.BodyCell>
      <V2Badge
        text="COMING SOON"
        variant="accent"
        startIcon="stars"
        withBorder={false}
        size="lg"
        style={{ width: '100%', textAlign: 'center' }}
      />
    </V2AriaTable.BodyCell>
  );
};

import { buildQueryParams, buildQueryParamTypes } from '@/utils/fns/graphql-query-builder';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';
import type { InvestorAdvisorConnectionIntent } from '../types';

export const INVESTOR_ADVISOR_FOUNDER_INVITES_COUNT_QUERY_KEY = 'investor-advisor-founder-invites-count-query';

type QueryConfig = {
  intent: InvestorAdvisorConnectionIntent | undefined;
};

export const getInvestorAdvisorInvitesCount = async ({
  intent,
}: QueryConfig): Promise<GraphqlResponse<{ total: number }>> => {
  const variablesType = { intent: 'FilterableConnectionIntentValue' };
  const variables = { intent };

  const { data } = await axiosInstance.post('/query', {
    variables,
    query: `
     query getInvestorAdvisorInvitesCount (${buildQueryParamTypes(variablesType)}) {
        getNedInvestorPendingConnections(${buildQueryParams(variablesType)}){
           total
         }
       }
    `,
  });

  return graphqlResponseWrapper(data, ['getNedInvestorPendingConnections']);
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_INVESTOR_CAPITAL_DEPLOYMENT_KEY = 'homepage-get-investor-capital-deployment';

export type InvestorOnboardingInvestmentSpecResponseData = {
  isDeployingCapitalInNextThreeMonths: boolean | null;
  shouldShowDeployingCapitalModal: boolean;
};

export const getInvestorCapitalDeployment = async (): Promise<
  GraphqlResponse<InvestorOnboardingInvestmentSpecResponseData>
> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
    query {
      getInvestorInvestmentSpecQuery {
        shouldShowDeployingCapitalModal
        isDeployingCapitalInNextThreeMonths
      }
    }
  `,
  });

  return graphqlResponseWrapper(data, ['getInvestorInvestmentSpecQuery']);
};

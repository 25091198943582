import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import styled from 'styled-components';

const V2Container = styled('div')<{ $background?: string }>`
  width: 100%;
  margin: 0 auto;
  background: ${({ $background }) => $background ?? 'transparent'};
  box-sizing: border-box;

  ${customMediaQuery('max-width', {
    xs: '540px',
    sm: '754px',
    md: '1440px',
    lg: '1686px',
  })}

  ${customMediaQuery('padding', {
    xs: '0 16px',
    sm: '0 40px',
    lg: '0 80px',
  })}
`;

export default { V2Container };

import { V2Button } from '@/components/ui/v2-button/v2-button';
import { bookmarkNedAction, unbookmarkNedAction } from '@/modules/founder/advisor-search/api/bookmark-ned.action';
import { ConnectionButton } from '@/modules/founder/advisor-search/components/connection-button/connection-button';
import type { TConnectionStatus } from '@/modules/founder/advisor-search/types';
import { engageNedMessagingAction } from '@pages/content/roles/parts/applications/api/message-engage-ned';
import { MessageSymbol } from '@parts/message-symbol/message-symbol';
import message from '@parts/message/message';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useEngageConversation } from '@utils/hooks/use-engage-conversation/use-engage-conversation';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useState } from 'react';

import S from './engagement-decision.styles';

type EngagementDecisionProps = {
  id: string;
  isBookmarked: boolean;
  connectionStatus: TConnectionStatus['status'];
  initiator: TConnectionStatus['initiator'];
  connectionId: string | null;
  isLoading: boolean;
  refetch: () => void;
};

export const EngagementDecision = ({
  id,
  isBookmarked,
  connectionStatus,
  initiator,
  connectionId,
  isLoading,
  refetch,
}: EngagementDecisionProps) => {
  const { mutateAsync: messageAction, isLoading: isMessageLoading } = useEngageConversation(engageNedMessagingAction);

  const [saveLabel, savedLabel, saveSuccessLabel, unsaveSuccessLabel] = useTranslation([
    'advisors.match.save',
    'advisors.match.saved',
    'message.saved.success',
    'message.unsaved.success',
  ]);

  const [isSaved, setIsSaved] = useState(() => isBookmarked);

  const { mutateAsync: saveNed, isLoading: isSaving } = useMutation(bookmarkNedAction(id), {
    onSuccess: () => {
      setIsSaved(true);

      message.success({ content: saveSuccessLabel });
    },
    onError: (error: AxiosError) => {
      message.error({ content: getServerError(error) });
    },
  });

  const { mutateAsync: unsaveNed, isLoading: isUnsaving } = useMutation(unbookmarkNedAction(id), {
    onSuccess: () => {
      setIsSaved(false);

      message.success({ content: unsaveSuccessLabel });
    },
    onError: (error: AxiosError) => {
      message.error({ content: getServerError(error) });
    },
  });

  const determineSaveAction = isSaved ? () => unsaveNed() : () => saveNed();

  return (
    <S.Footer noBorder>
      <div>
        <ConnectionButton
          data-testid="advisor-search-card-connect-button"
          connectionStatus={connectionStatus}
          initiator={initiator}
          advisorId={id}
          connectionId={connectionId}
          buttonSize="small"
          buttonFixedHeight="50px"
          disabled={isLoading}
          callback={refetch}
          allowDisconnect={false}
        />
        <V2Button
          onClick={determineSaveAction}
          disabled={isSaving || isUnsaving}
          variant={isSaved ? 'outlined-success' : 'outlined'}
          data-testid="advisor-search-card-save-button"
          size="small"
          style={{ height: '50px' }}
          startIcon={isSaved ? 'checkbox-icon' : undefined}
        >
          {isSaved ? savedLabel : saveLabel}
        </V2Button>
      </div>

      <div>
        <S.MessageButton
          type="link"
          disabled={isMessageLoading}
          onClick={() => messageAction(id)}
          data-testid="advisor-search-card-message-button"
        >
          <MessageSymbol />
        </S.MessageButton>
      </div>
    </S.Footer>
  );
};

import { Link as LinkBase } from 'react-router-dom';
import styled, { css } from 'styled-components';

const Wrapper = styled.div<{ disabled?: boolean }>`
  position: relative;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `};
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Data = styled.div`
  display: flex;
  flex-direction: column;
`;

const Supplement = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-left: ${({ theme }) => theme.spacing.small};
`;

const Name = styled(Supplement)<{ $spaceRight?: boolean }>`
  font-size: ${({ theme }) => theme.fontSize.medium};
  color: ${({ theme }) => theme.color.primary};
  text-decoration: none;
  white-space: break-spaces;
  word-break: break-word;
  padding-right: ${({ $spaceRight, theme }) => ($spaceRight ? theme.spacing.xbase : 0)};
`;

const Link = styled(LinkBase)`
  text-decoration: none;
  color: unset;
`;

const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.fontPrimary};
  margin-top: ${({ theme }) => theme.spacing.medium};
  word-break: break-word;
`;

const Badges = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const CountryCointaner = styled.div`
  display: flex;
  margin-left: ${({ theme }) => theme.spacing.small};
`;

const CountryImage = styled.img`
  width: 23px;
  border-radius: ${({ theme }) => theme.radius.default};
`;

export default { Wrapper, Data, Header, Name, Supplement, Badges, Description, Link, CountryCointaner, CountryImage };

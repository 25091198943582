import {
  addInvestorOnboardingStepError,
  removeInvestorOnboardingStepError,
} from '@context/investor-onboarding/investor-onboarding.actions';
import Title from '@pages/content/onboarding/parts/title/title';
import { InvestorType, type IGetInvestorPersonalDetails } from '@pages/content/profile/investor/api/types';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { useQuery } from '@tanstack/react-query';
import { useInvestorOnboardingContext } from '@utils/hooks/use-onboarding-context/use-investor-onboarding-context';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useEffect } from 'react';
import {
  getInvestorPersonalDetails,
  INVESTOR_PERSONAL_DETAILS_CACHE_KEY,
} from '../../api/get-investor-personal-details.action';
import { type StepContentProps } from '../../paths.config';
import { PersonalInfoForm } from './parts/form';
import { INVESTOR_ONBOARDING_STEP_PERSONAL_KEY } from '@pages/content/onboarding/investor/paths';

export const PersonalInfoPageContent = ({ currentPage, endPage }: StepContentProps) => {
  const initialDataLoadErrorId = `${INVESTOR_ONBOARDING_STEP_PERSONAL_KEY}-initial-load-data-error`;
  const [title, occupationOrLinkedinUrlError, companyNameError] = useTranslation([
    'investor-onboarding.personal.title',
    'investor-onboarding.personal.occupationOrLinkedinUrlError',
    'investor-onboarding.personal.companyNameError',
  ]);
  const { dispatch: investorOnboardingDispatch } = useInvestorOnboardingContext();

  const {
    data,
    isLoading: isDataLoading,
    refetch: refetchData,
  } = useQuery([INVESTOR_PERSONAL_DETAILS_CACHE_KEY], getInvestorPersonalDetails, {
    cacheTime: 0,
    onSuccess: (resData) => {
      if (!resData.data) return;
      investorOnboardingDispatch(removeInvestorOnboardingStepError(initialDataLoadErrorId));

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setNextStepError(resData.data);
    },
  });

  const handleFormValidStatusChange = (validStatus: boolean, errorText: string | null) => {
    const errorId = `${INVESTOR_ONBOARDING_STEP_PERSONAL_KEY}-client-data-error`;

    if (validStatus === true) {
      investorOnboardingDispatch(removeInvestorOnboardingStepError(errorId));
      return;
    }

    investorOnboardingDispatch(
      addInvestorOnboardingStepError({
        id: errorId,
        step: INVESTOR_ONBOARDING_STEP_PERSONAL_KEY,
        error: errorText ?? '',
        disableButton: false,
      }),
    );
  };

  const setNextStepError = (details: IGetInvestorPersonalDetails) => {
    const { type: investorType } = details;

    if (investorType === InvestorType.Angel) {
      const errorId = `${INVESTOR_ONBOARDING_STEP_PERSONAL_KEY}-api-data-error`;

      const isFormDataValid = [details.recentOccupation, details.linkedinUrl].some((v) => Boolean(v));
      if (!isFormDataValid) {
        investorOnboardingDispatch(
          addInvestorOnboardingStepError({
            id: errorId,
            step: INVESTOR_ONBOARDING_STEP_PERSONAL_KEY,
            error: occupationOrLinkedinUrlError,
            disableButton: false,
          }),
        );
        return;
      }

      investorOnboardingDispatch(removeInvestorOnboardingStepError(errorId));
    }

    if (investorType === InvestorType.Institutional) {
      const errorId = `${INVESTOR_ONBOARDING_STEP_PERSONAL_KEY}-api-data-error`;

      if (!details.companyName) {
        investorOnboardingDispatch(
          addInvestorOnboardingStepError({
            id: errorId,
            step: INVESTOR_ONBOARDING_STEP_PERSONAL_KEY,
            error: companyNameError,
            disableButton: false,
          }),
        );
        return;
      }

      investorOnboardingDispatch(removeInvestorOnboardingStepError(errorId));
    }
  };

  useEffect(() => {
    if (data?.data) return;

    investorOnboardingDispatch(
      addInvestorOnboardingStepError({
        id: initialDataLoadErrorId,
        step: INVESTOR_ONBOARDING_STEP_PERSONAL_KEY,
        error: '',
        disableButton: true,
      }),
    );
  }, []);

  return (
    <section data-testid="investor-onboarding-personal-info-page-content">
      <Title title={title} currentPage={currentPage} endPage={endPage} />

      {isDataLoading ? (
        <FullHeightSpinner small />
      ) : (
        data?.data && (
          <PersonalInfoForm
            personalDetails={data?.data}
            investorType={data?.data?.type}
            isPersonalDetailsLoading={isDataLoading}
            refetchPersonalDetails={refetchData}
            onFormValidStateChange={handleFormValidStatusChange}
          />
        )
      )}
    </section>
  );
};

import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import { Checkbox as AntdCheckbox, Select as AntdSelect, Input } from 'antd';
import styled from 'styled-components';

const FormWrapper = styled.div`
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  padding: 24px;
  flex-direction: column;

  ${customMediaQuery('display', {
    xs: 'flex',
    sm: 'grid',
  })}

  & > div {
    box-sizing: border-box;
  }

  & > div:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  & > div:nth-child(2) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  & > div:nth-child(3) {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
  }

  & > div:nth-child(4) {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  & > div:nth-child(5) {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
  }

  & > div:nth-child(6) {
    grid-column: 3 / 4;
    grid-row: 3 / 4;
    display: flex;
    justify-content: center;
  }

  & > div:nth-child(7) {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
  }

  & > div:nth-child(8) {
    grid-column: 2 / 3;
    grid-row: 4 / 5;
  }

  & > div:nth-child(9) {
    grid-column: 1 / 3;
    grid-row: 5 / 6;
  }

  & > div:nth-child(10) {
    grid-column: 1 / 3;
    grid-row: 6 / 7;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 4px;
`;

const Label = styled.label``;

const ErrorMessage = styled.span`
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.red[500], theme.v2.colors.red[300])};
`;

const Select = styled(AntdSelect)`
  width: 100% !important;
  margin: 0 !important;
  height: 50px;

  & > .ant-select-selector {
    height: 50px !important;
  }

  & .ant-select-selection-item {
    line-height: 48px !important;
  }

  & .ant-select-arrow {
    display: none;
  }
`;

const MultiSelect = styled(AntdSelect)`
  width: 100% !important;
  margin: 0 !important;
  &.ant-select-disabled,
  & > .ant-select-selector {
    opacity: 1 !important;
  }

  &.ant-select-disabled > .ant-select-selector {
    background-color: ${({ theme }) =>
      themedStyle(theme.v2.mode, theme.v2.colors.neutral[100], theme.v2.colors.blue[800])} !important;
  }

  .ant-select-selection-item {
    opacity: 1 !important;
    color: #10328e !important;
    background-color: #d9e3fe !important;
  }
`;

const Checkbox = styled(AntdCheckbox)`
  margin-top: 28px;
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[700], theme.v2.colors.neutral[100])};
`;

const DateInput = styled(Input)`
  color-scheme: ${({ theme }) => themedStyle(theme.v2.mode, 'light', 'dark')};
`;

const ButtonWrapper = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-between;

  & button {
    min-width: 160px;
  }
`;

const AllDoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 64px 0;
`;

const Title = styled('h1')`
  width: 100%;
  white-space: wrap;
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.neutral[50])};
  font-size: ${({ theme }) => theme.v2.typography.sizes.lg};
  @media (min-width: ${({ theme }) => theme.v2.breakpoints.sm}) {
    font-size: ${({ theme }) => theme.v2.typography.sizes.xl};
  }
  font-weight: ${({ theme }) => theme.v2.typography.weights.bold};
  margin-bottom: 20px;
`;

export {
  AllDoneWrapper,
  ButtonWrapper,
  Checkbox,
  DateInput,
  FormWrapper,
  InputWrapper,
  Label,
  ErrorMessage,
  MultiSelect,
  Select,
  Title,
};

import { ConditionalRender } from '@/parts/condition-renderer/conditional-renderer';
import { Container as LegacyContainer } from '@/parts/container/container';
import { AnyLayout } from '@layout/any/any.layout';
import { Bell } from '@layout/parts/bell/bell';
import type { ItemWithIcon } from '@layout/parts/menu-dropdown/menu-dropdown';
import { Message } from '@layout/parts/message/message';
import { TopNav } from '@layout/parts/top-nav/top-nav';
import { Routes } from '@router/routes';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import type { ReactNode } from 'react';
import { MenuWrapper } from '../parts/menu-holder/menu-holder';
import { Menu } from '../parts/menu/menu';

import S from './active.styles';

export interface MenuItem {
  route: Routes;
  label: ReactNode;
  allowedParamValues?: { [key: string]: string[] };
  disabled?: boolean;
  defaultParams?: { [key: string]: string };
  isExternalLink?: boolean;
  showTooltip?: boolean;
}

interface DashboardLayoutProps {
  menu: MenuItem[];
  profileMenu: ItemWithIcon[];
  topNav?: ReactNode;
  dropMenu?: ReactNode;
  children: ReactNode;
  locationPathName: string;
  infoStickyBannerTop?: ReactNode;
  infoStickyBannerBottom?: ReactNode;
  extraProfileMenuItem?: ReactNode | MenuItem;
  legacyContainer?: boolean;
}

export const ActiveLayout = ({
  children,
  topNav,
  menu,
  profileMenu,
  locationPathName,
  dropMenu,
  infoStickyBannerTop,
  infoStickyBannerBottom,
  extraProfileMenuItem,
  legacyContainer = true,
}: DashboardLayoutProps) => {
  const {
    state: { messaging },
  } = useUserAccount();

  const renderTopNav = () => {
    if (locationPathName !== '/') return null;
    if (topNav) return <TopNav>{topNav}</TopNav>;
    return null;
  };

  return (
    <AnyLayout>
      <MenuWrapper>
        {infoStickyBannerTop}
        <Menu
          menuItems={menu}
          profileMenuItems={profileMenu}
          dropMenu={dropMenu}
          locationPathName={locationPathName}
          notification={<Bell />}
          message={<Message unreadCount={messaging?.unreadCount} />}
          extraProfileMenuItem={extraProfileMenuItem}
        />
        {infoStickyBannerBottom}
      </MenuWrapper>
      <S.Content $spacedTop={legacyContainer}>
        <ConditionalRender
          condition={legacyContainer}
          element={
            <LegacyContainer>
              {renderTopNav()}
              {children}
            </LegacyContainer>
          }
          fallback={
            <>
              {renderTopNav()}
              {children}
            </>
          }
        />
      </S.Content>
    </AnyLayout>
  );
};

import message from '@/parts/message/message';
import { getServerError } from '@/utils/fns/get-server-error';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { GET_FOUNDER_ADVISOR_QUESTIONS_ANSWERS } from '../api/get-founder-advisor-questions-answers.query';
import { patchFounderAdvisorsQuestions } from '../api/patch-founder-advisor-questions.action';

export const usePatchFounderAdvisorsQuestions = (cb?: () => void) => {
  const [saved] = useTranslation(['global.saved']);
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: patchFounderAdvisorsQuestions,
    onSuccess: async () => {
      await queryClient.invalidateQueries([GET_FOUNDER_ADVISOR_QUESTIONS_ANSWERS]);
      message.success({ content: saved });
      cb?.();
    },
    onError: (error: AxiosError) => message.error({ content: getServerError(error) }),
  });

  return {
    isPatchFounderAdvisorsQuestionsLoading: mutation.isLoading,
    isPatchFounderAdvisorsQuestionsError: mutation.isError,
    patchFounderAdvisorsQuestionsMutation: mutation.mutate,
    ogMutation: mutation,
  };
};

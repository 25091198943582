import styled, { type DefaultTheme, css } from 'styled-components';
import { V2Button } from '@/components/ui/v2-button/v2-button';
import { V2IconButton } from '@/components/ui/v2-icon-button/v2-icon-button';
import { themedStyle } from '@/styles/v2-theme/utils/themed-style';

const handleBorderColor = (theme: DefaultTheme, $inEditMode: boolean, $isWarning?: boolean) => {
  if ($isWarning) {
    return theme.v2.colors.magenta[400];
  }
  if ($inEditMode) {
    return themedStyle(theme.v2.mode, theme.v2.colors.blue[300], theme.v2.colors.blue[400]);
  }
  return themedStyle(theme.v2.mode, theme.v2.colors.blue[50], theme.v2.colors.blue[500]);
};

const WarningWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NoteTile = styled.div<{ $inEditMode: boolean; $isWarning?: boolean; $size: 'sm' | 'lg' }>`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({ theme, $inEditMode, $isWarning }) => handleBorderColor(theme, $inEditMode, $isWarning)};
  width: 100%;
  height: ${({ $size }) => ($size === 'sm' ? '143px' : '223px')};
  padding: 15px;
  border-radius: ${({ theme }) => theme.radius.default};

  ${({ $inEditMode }) =>
    !$inEditMode &&
    css`
      ::after {
        position: absolute;
        top: 0;
        content: '';
        display: block;
        width: 100%;
        height: 80%;
        opacity: 0;
      }
    `}
`;

const HeadingWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.input`
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  padding: 0;
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.neutral[50])};
  border: none;
  background-color: inherit;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const DateLabel = styled.p`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  text-align: right;
  color: ${({ theme }) => theme.v2.colors.neutral[500]};
  width: 100%;
`;

const Content = styled.textarea`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  width: 100%;
  height: 100%;
  border: none;
  resize: none;
  background-color: inherit;
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[700], theme.v2.colors.neutral[50])};

  &:focus {
    outline: none;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  transform: translateY(10px);
  align-items: center;
`;

const StyledLeftTextButton = styled(V2Button)`
  transform: translateX(-15px);
`;

const StyledRightTextButton = styled(V2Button)`
  transform: translateX(15px);
`;

const StyledIconButton = styled(V2IconButton)`
  transform: translateX(10px);
`;

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
  width: 100%;
`;

const Heading = styled.h2`
  font-size: 24px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.neutral[50])};
`;

const Text = styled.p`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[800], theme.v2.colors.neutral[500])};
  text-align: center;
`;

const ModalButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.medium};
  margin-top: ${({ theme }) => theme.spacing.medium};
`;

const Warning = styled.p`
  color: ${({ theme }) => theme.v2.colors.red[300]};
`;

export default {
  NoteTile,
  HeadingWrapper,
  Title,
  DateLabel,
  Content,
  ButtonsWrapper,
  StyledLeftTextButton,
  StyledRightTextButton,
  StyledIconButton,
  ModalContentWrapper,
  Heading,
  Text,
  ModalButtonsWrapper,
  Warning,
  WarningWrapper,
};

import { V2Radio } from '@/components/ui/v2-radio/v2-radio';
import { useChangedFields } from '@/pages/content/onboarding/hooks/use-changed-fields';
import { setUserMetadata } from '@context/user/user-account.actions';
import S from '@pages/content/onboarding/parts/personal-info-form/personal-info-form.styles';
import HiddenImageInput from '@pages/content/parts/hidden-file-input/hidden-file-input';
import {
  updatePersonalDetailsAction,
  type InvestorPersonalDetailsUpdatePayload,
} from '@pages/content/profile/investor/api/personal-details/personal-details';
import { GenderType, InvestorType, type IGetInvestorPersonalDetails } from '@pages/content/profile/investor/api/types';
import { uploadProfileAvatar } from '@pages/content/profile/investor/api/upload-profile-avatar/upload-profile-avatar';
import { Avatar } from '@parts/avatar/avatar';
import { Input } from '@parts/forms/highlight/form-elements.style';
import { HighlightWrapper } from '@parts/forms/highlight/highlight-wrapper';
import message from '@parts/message/message';
import { Select } from '@parts/select/select';
import { Tooltip } from '@parts/tooltip/tooltip';
import { useMutation } from '@tanstack/react-query';
import { type AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useListEntryRevealAnimation } from '@utils/hooks/use-list-reveal-animation/use-list-reveal-animation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { useFormik } from 'formik';
import { useRef, useState } from 'react';
import type { Any } from 'src/types';
import { useOnFormValidationChangeMessage } from '../../../hooks/use-on-form-validation-change-message';
import { usePersonalInfoTranslations } from './hooks/usePersonalInfoTranslations';
import { useValidationSchema } from './validation-schema';

const LIST_ANIMATION_ELEMENT_ID = 'form-field';
const CHECKBOXES_LIST_ANIMATION_ID = 'checkbox-form-field';

const INITIAL_CHECKBOXES_ANIMATION_DELAY = 700;
const CHECKBOXES_ANIMATION_DELAY = 100;

interface FormikFormData {
  firstName: string;
  lastName: string;
  gender: GenderType | null;
  recentOccupation: string | null;
  recentCompany: string | null;
  companyName: string | null;
  linkedinUrl: string | null;
  profilePicturePath: string | null;
  profilePictureUrl: string | null;
  radioLinkedinOrOccupation: string | null;
}

interface PersonalInfoFormProps {
  personalDetails: IGetInvestorPersonalDetails;
  isPersonalDetailsLoading: boolean;
  investorType: InvestorType;
  refetchPersonalDetails: () => void;
  onFormValidStateChange: (validStatus: boolean, error: string | null) => void;
}

export const PersonalInfoForm = ({
  personalDetails,
  isPersonalDetailsLoading,
  refetchPersonalDetails,
  onFormValidStateChange,
  investorType,
}: PersonalInfoFormProps) => {
  useListEntryRevealAnimation(LIST_ANIMATION_ELEMENT_ID);
  const { dispatch, state } = useUserAccount();

  const { isChanged, resetChangedWithDelay, setChanged } = useChangedFields();

  const defaultRadioValue = personalDetails?.recentOccupation ? 'recent-occupation' : 'linkedin';

  const [checkboxesAnimationDelay, setCheckboxesAnimationDelay] = useState<number>(INITIAL_CHECKBOXES_ANIMATION_DELAY);

  const [
    avatarInfoLabelL1,
    avatarInfoLabelL2,
    uploadLabel,
    firstNameLabel,
    lastNameLabel,
    genderLabel,
    manLabel,
    womanLabel,
    nonBinaryLabel,
    preferNotToSayLabel,
    recentOccupationLabel,
    recentOccupationInfo,
    linkedinUrlLabel,
    linkedinUrlInfo,
    companyNameLabel,
    recentCompanyLabel,
    verificationLabel,
    verificationLinkedinLabel,
    orLabel,
  ] = usePersonalInfoTranslations();

  const { mutateAsync: updatePersonalDetails, isLoading: isUpdatingPersonalDetails } = useMutation(
    updatePersonalDetailsAction,
    {
      onSuccess: (_, variables) => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        handleHighlight(variables as Any);
        refetchPersonalDetails();
      },
      onError: (err: AxiosError) => {
        message.error({ content: getServerError(err) });
      },
    },
  );

  const formik = useFormik<FormikFormData>({
    initialValues: {
      firstName: personalDetails?.firstName ?? '',
      lastName: personalDetails?.lastName ?? '',
      gender: personalDetails?.gender ?? null,
      linkedinUrl: personalDetails?.linkedinUrl ?? null,
      companyName: personalDetails?.companyName ?? null,
      recentOccupation: personalDetails?.recentOccupation ?? null,
      recentCompany: personalDetails?.recentCompany ?? null,
      profilePictureUrl: personalDetails?.profilePicture?.url ?? null,
      profilePicturePath: personalDetails?.profilePicture?.path ?? null,
      radioLinkedinOrOccupation: defaultRadioValue ?? null,
    },
    onSubmit({
      radioLinkedinOrOccupation,
      profilePicturePath,
      profilePictureUrl,
      companyName,
      recentOccupation,
      recentCompany,
      ...values
    }) {
      const payload: InvestorPersonalDetailsUpdatePayload = {
        ...values,
        aboutMe: null,
        profilePhotoPath: profilePicturePath,
        ...(investorType === InvestorType.Institutional ? { companyName } : { recentOccupation, recentCompany }),
        phoneNumber: personalDetails?.phoneNumber || '',
        twitterUrl: (personalDetails?.twitterUrl || null) as string,
        linkedinUrl: values.linkedinUrl,
      };

      updatePersonalDetails(payload);
    },
    enableReinitialize: true,
    validationSchema: useValidationSchema(investorType),
  });

  useListEntryRevealAnimation(
    CHECKBOXES_LIST_ANIMATION_ID,
    { delay: checkboxesAnimationDelay },
    { deps: [formik.values.radioLinkedinOrOccupation], shouldReinit: () => true },
    false,
    () => setCheckboxesAnimationDelay(CHECKBOXES_ANIMATION_DELAY),
  );

  const isLinkedinRadioSelected = formik.values.radioLinkedinOrOccupation === 'linkedin';

  const handleHighlight = (variables: FormikFormData) => {
    setChanged(variables as Any, formik.initialValues as Any);
    resetChangedWithDelay();
  };

  const genderLabels = new Map<GenderType, string>([
    [GenderType.Man, manLabel],
    [GenderType.Woman, womanLabel],
    [GenderType.NonBinary, nonBinaryLabel],
    [GenderType.PreferNotToSay, preferNotToSayLabel],
  ]);

  const fieldLabels = new Map<keyof FormikFormData, string>([
    ['firstName', firstNameLabel],
    ['lastName', lastNameLabel],
    ['gender', genderLabel],
    ['recentOccupation', recentOccupationLabel],
    ['companyName', companyNameLabel],
    ['recentCompany', recentCompanyLabel],
    ['linkedinUrl', linkedinUrlLabel],
    ['profilePicturePath', ''],
    ['profilePictureUrl', ''],
  ]);

  useOnFormValidationChangeMessage(formik, fieldLabels, onFormValidStateChange);

  const invisibleInputRef = useRef<HTMLInputElement>(null);
  const { mutateAsync: uploadPhoto, isLoading: isUploadingPhoto } = useMutation(uploadProfileAvatar, {
    onSuccess: (res) => {
      formik.setFieldValue('profilePicturePath', res.data.filePath);
      formik.setFieldValue('profilePictureUrl', res.data.fileUrl);
      dispatch(
        setUserMetadata({
          ...state,
          avatarUrl: res.data.fileUrl || null,
        }),
      );
      formik.submitForm();
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  const areFieldsDisabled = isPersonalDetailsLoading && isUpdatingPersonalDetails;

  return (
    <S.Form>
      <S.FormikField
        wide
        label={{
          for: 'profilePicturePath',
          label: fieldLabels.get('profilePicturePath') ?? '',
        }}
        error={formik.errors.profilePicturePath}
        touched={formik.touched.profilePicturePath}
        data-animation-id={LIST_ANIMATION_ELEMENT_ID}
      >
        <S.AvatarUploader>
          <HiddenImageInput submitFn={(formData: FormData) => uploadPhoto({ formData })} ref={invisibleInputRef} />
          {isUploadingPhoto ? (
            <S.AvatarSpin size="large" />
          ) : (
            <Avatar
              letter={formik.values.firstName?.charAt(0) ?? 'I'}
              size={75}
              avatarUrl={formik.values.profilePictureUrl}
              pastelBackground
            />
          )}
          <S.Controls>
            <S.AvatarUploadButton
              role="button"
              onClick={() => invisibleInputRef.current?.click()}
              data-testid="personal-avatar-upload-button"
            >
              {uploadLabel}
            </S.AvatarUploadButton>
            <S.AvatarTextInfo>
              {avatarInfoLabelL1}
              <br />
              {avatarInfoLabelL2}
            </S.AvatarTextInfo>
          </S.Controls>
        </S.AvatarUploader>
      </S.FormikField>

      <S.FormikField
        wide
        label={{
          label: fieldLabels.get('firstName') ?? '',
          for: 'firstName',
        }}
        error={formik.errors.firstName}
        touched={formik.touched.firstName}
        data-animation-id={LIST_ANIMATION_ELEMENT_ID}
      >
        <HighlightWrapper highlight={isChanged('firstName')}>
          <Input
            {...formik.getFieldProps('firstName')}
            onBlur={formik.submitForm}
            $highlight={isChanged('firstName')}
            disabled={areFieldsDisabled}
            data-testid="personal-input-firstName"
            id="firstName"
          />
        </HighlightWrapper>
      </S.FormikField>

      <S.FormikField
        wide
        label={{
          label: fieldLabels.get('lastName') ?? '',
          for: 'lastName',
        }}
        error={formik.errors.lastName}
        touched={formik.touched.lastName}
        data-animation-id={LIST_ANIMATION_ELEMENT_ID}
      >
        <HighlightWrapper highlight={isChanged('lastName')}>
          <Input
            {...formik.getFieldProps('lastName')}
            onBlur={formik.submitForm}
            $highlight={isChanged('lastName')}
            disabled={areFieldsDisabled}
            data-testid="personal-input-lastName"
            id="lastName"
          />
        </HighlightWrapper>
      </S.FormikField>

      <S.FormikField
        wide
        label={{
          label: fieldLabels.get('gender') ?? '',
          for: 'gender',
        }}
        error={formik.errors.gender}
        touched={formik.touched.gender}
        data-animation-id={LIST_ANIMATION_ELEMENT_ID}
      >
        <HighlightWrapper highlight={isChanged('gender')}>
          <Select
            data-testid="personal-select-gender"
            {...formik.getFieldProps('gender')}
            value={formik.values.gender ?? undefined}
            onChange={(value) => {
              formik.setFieldTouched('gender', true);
              formik.setFieldValue('gender', value || null);
              if (formik.isValid) formik.submitForm();
            }}
          >
            <Select.Option key="empty" value="">
              ---
            </Select.Option>
            {[...genderLabels.entries()].map(([value, label]) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </HighlightWrapper>
      </S.FormikField>

      {investorType === InvestorType.Angel && (
        <>
          <S.RadioGroupContainer data-animation-id={LIST_ANIMATION_ELEMENT_ID}>
            <S.RadioGroupContentWrapper>
              <S.RadioGroupTitle>{verificationLabel}</S.RadioGroupTitle>

              <S.RadioGroup variant="horizontal" defaultValue={defaultRadioValue}>
                <V2Radio
                  variant="secondary"
                  value="linkedin"
                  onChange={(e) => formik.setFieldValue('radioLinkedinOrOccupation', e.target.value)}
                >
                  <S.LabelText>{verificationLinkedinLabel}</S.LabelText>
                </V2Radio>
                <S.Span>{orLabel}</S.Span>
                <V2Radio
                  value="recent-occupation"
                  variant="secondary"
                  onChange={(e) => formik.setFieldValue('radioLinkedinOrOccupation', e.target.value)}
                >
                  <S.LabelText>{recentOccupationLabel}</S.LabelText>
                </V2Radio>
              </S.RadioGroup>
            </S.RadioGroupContentWrapper>
          </S.RadioGroupContainer>

          {isLinkedinRadioSelected ? (
            <S.FormikField
              wide
              label={{
                for: 'linkedinUrl',
                label: (
                  <span>
                    {fieldLabels.get('linkedinUrl')} <Tooltip title={linkedinUrlInfo} />
                  </span>
                ),
              }}
              error={formik.errors.linkedinUrl}
              touched={formik.touched.linkedinUrl}
              data-animation-id={CHECKBOXES_LIST_ANIMATION_ID}
            >
              <HighlightWrapper highlight={isChanged('linkedinUrl')}>
                <Input
                  {...formik.getFieldProps('linkedinUrl')}
                  onBlur={formik.submitForm}
                  $highlight={isChanged('linkedinUrl')}
                  disabled={areFieldsDisabled}
                  data-testid="personal-input-linkedinUrl"
                  id="linkedinUrl"
                  data-animation-id={CHECKBOXES_LIST_ANIMATION_ID}
                />
              </HighlightWrapper>
            </S.FormikField>
          ) : (
            <>
              <S.FormikField
                wide
                label={{
                  label: (
                    <span>
                      {recentOccupationLabel} <Tooltip title={recentOccupationInfo} />
                    </span>
                  ),
                  for: 'recentOccupation',
                }}
                error={formik.errors.recentOccupation}
                touched={formik.touched.recentOccupation}
                data-animation-id={CHECKBOXES_LIST_ANIMATION_ID}
              >
                <HighlightWrapper highlight={isChanged('recentOccupation')}>
                  <Input
                    {...formik.getFieldProps('recentOccupation')}
                    onChange={(e) => formik.setFieldValue('recentOccupation', e.target.value || null)}
                    onBlur={formik.submitForm}
                    $highlight={isChanged('recentOccupation')}
                    disabled={areFieldsDisabled}
                    data-testid="personal-input-recentOccupation"
                    id="recentOccupation"
                  />
                </HighlightWrapper>
              </S.FormikField>

              <S.FormikField
                wide
                label={{
                  label: <span>{recentCompanyLabel}</span>,
                  for: 'recentCompany',
                }}
                error={formik.errors.recentCompany}
                touched={formik.touched.recentCompany}
                data-animation-id={CHECKBOXES_LIST_ANIMATION_ID}
              >
                <HighlightWrapper highlight={isChanged('recentCompany')}>
                  <Input
                    {...formik.getFieldProps('recentCompany')}
                    onChange={(e) => formik.setFieldValue('recentCompany', e.target.value || null)}
                    onBlur={formik.submitForm}
                    $highlight={isChanged('recentCompany')}
                    disabled={areFieldsDisabled}
                    data-testid="personal-input-recentCompany"
                    id="recentCompany"
                  />
                </HighlightWrapper>
              </S.FormikField>
            </>
          )}
        </>
      )}

      {investorType === InvestorType.Institutional && (
        <>
          <S.FormikField
            wide
            label={{
              label: (
                <span>
                  {fieldLabels.get('companyName')} <S.RequiredAsterisk>*</S.RequiredAsterisk>
                </span>
              ),
              for: 'companyName',
            }}
            error={formik.errors.companyName}
            touched={formik.touched.companyName}
            data-animation-id={LIST_ANIMATION_ELEMENT_ID}
          >
            <HighlightWrapper highlight={isChanged('companyName')}>
              <Input
                {...formik.getFieldProps('companyName')}
                onChange={(e) => formik.setFieldValue('companyName', e.target.value || null)}
                onBlur={formik.submitForm}
                $highlight={isChanged('companyName')}
                disabled={areFieldsDisabled}
                data-testid="personal-input-companyName"
                id="companyName"
              />
            </HighlightWrapper>
          </S.FormikField>
          <S.FormikField
            wide
            label={{
              for: 'linkedinUrl',
              label: (
                <span>
                  {fieldLabels.get('linkedinUrl')} <Tooltip title={linkedinUrlInfo} />
                </span>
              ),
            }}
            error={formik.errors.linkedinUrl}
            touched={formik.touched.linkedinUrl}
            data-animation-id={LIST_ANIMATION_ELEMENT_ID}
          >
            <HighlightWrapper highlight={isChanged('linkedinUrl')}>
              <Input
                {...formik.getFieldProps('linkedinUrl')}
                onBlur={formik.submitForm}
                $highlight={isChanged('linkedinUrl')}
                disabled={areFieldsDisabled}
                data-testid="personal-input-linkedinUrl"
                id="linkedinUrl"
              />
            </HighlightWrapper>
          </S.FormikField>
        </>
      )}
    </S.Form>
  );
};

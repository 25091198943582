import { SecureLink } from '@parts/link/link';
import styled, { css } from 'styled-components';

const Wrapper = styled.section`
  align-items: center;
  background: ${({ theme }) => theme.color.infoLinkBackground};
  border-radius: ${({ theme }) => theme.radius.default};
  border: 1px solid ${({ theme }) => theme.color.border};
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-direction: column;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-direction: column;
  }
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.color.fontPrimary};
`;

const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSize.small};
  max-width: 80%;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    max-width: 100%;
  }
`;

const TextHolder = styled.div`
  padding: 0 20px;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin-bottom: 10px;
    text-align: center;
  }
`;

const Button = styled(SecureLink)<{ color: 'blue' | 'white' }>`
  background: ${({ theme }) => theme.color.submitButton};
  border-radius: ${({ theme }) => theme.radius.default};
  border: 1px solid ${({ theme }) => theme.color.submitButton};
  color: ${({ theme }) => theme.baseColors.White};
  cursor: pointer;
  min-width: fit-content;
  padding: 5px 20px;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.baseColors.White};
    background: ${({ theme }) => theme.color.submitButtonHover};
    border-color: ${({ theme }) => theme.color.submitButtonHover};
  }

  ${({ color }) =>
    color === 'white' &&
    css`
      background: ${({ theme }) => theme.color.infoLinkButton};
      color: ${({ theme }) => theme.color.submitButton};

      &:hover {
        color: ${({ theme }) => theme.color.submitButton};
        background: ${({ theme }) => theme.color.infoLinkButtonHover};
      }
    `}
`;

const ImgHolder = styled.div`
  max-width: 100px;
  text-align: center;
  width: 100%;
`;

export default { Wrapper, TextHolder, Description, Button, ImgHolder, Title, InnerWrapper };

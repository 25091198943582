import { Section as SectionBase } from '@parts/section/section';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

const Section = styled(SectionBase)`
  padding: 10px 40px 5px;
  margin: 0;

  h3 {
    color: ${({ theme }) => theme.color.metricTitle};
    font-size: ${({ theme }) => theme.fontSize.regular};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
  }

  > div {
    &:first-child {
      margin-bottom: -5px;
      flex-direction: row;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        height: 0;
      }

      div {
        margin-top: 0;
      }
    }
  }

  &:last-of-type {
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      &:first-child {
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin-bottom: ${({ theme }) => theme.spacing.medium};
  }
`;

const CornerLabel = styled.div<{ mode?: string }>`
  width: 100px;
  border-radius: 20px;
  padding: 2px;
  position: relative;
  font-size: ${({ theme }) => theme.fontSize.xxsmall};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.comingSoonLabel};

  ${({ mode }) =>
    mode &&
    css`
      background: ${mode === 'light'
        ? 'linear-gradient(90deg, rgba(255, 168, 0, 0.4) -22.86%, rgba(255, 206, 111, 0.3) 112.86%)'
        : rgba(247, 59, 96, 0.3)};
    `};
`;

const EmptyGraph = styled.div`
  svg {
    margin-top: 25px;
    height: 130px;
    margin-bottom: 10px;
    margin-right: 55px;

    @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
      height: 120px;
      margin-right: 0;
      margin-left: 40px;
    }

    @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
      margin-bottom: ${({ theme }) => theme.spacing.medium};
      height: 100px;
      margin-left: 0;
    }
  }
`;

const Wrapper = styled.div<{ isBarChartView: boolean }>`
  display: flex;

  ${({ isBarChartView }) =>
    isBarChartView &&
    css`
      flex-direction: column;
    `}
`;

export default {
  Section,
  CornerLabel,
  EmptyGraph,
  Wrapper,
};

import { clearAllNotifications } from '@context/notifications/notifications.action-creators';
import { clearAllNotificationsAction } from '@layout/parts/bell/api/notifications.actions';
import { useMutation } from '@tanstack/react-query';
import { useNotificationsContext } from '../use-notifications/use-notifications';

export const useNotificationList = ({ refetch }: { refetch?: Function }) => {
  const { dispatch, state } = useNotificationsContext();

  const unreadNotificationPresent = state.currentNotifications.some((n) => !n.read);

  const { mutateAsync: clearNotifications } = useMutation(clearAllNotificationsAction, {
    onSuccess: async () => {
      if (refetch) await refetch();
      dispatch(clearAllNotifications());
    },
  });

  return { unreadNotificationPresent, clearNotifications };
};

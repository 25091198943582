import styled, { css } from 'styled-components';

const Wrapper = styled.div<{ $clickable?: boolean; disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background: ${({ theme }) => theme.color.cardBackground};
  color: ${({ theme }) => theme.color.fontSecondary};
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: ${({ theme }) => theme.radius.default};
  position: relative;

  ${({ $clickable }) =>
    $clickable &&
    css`
      cursor: pointer;
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `};
`;

const Content = styled.div``;

const ExpandButton = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.spacing.xsmall};
  right: ${({ theme }) => theme.spacing.xsmall};
  cursor: pointer;
  background: none;
  border: none;
`;

const CornerAddition = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing.xsmall};
  left: ${({ theme }) => theme.spacing.xsmall};
`;

export default { Wrapper, Content, ExpandButton, CornerAddition };

import type { HTMLAttributes } from 'react';
import S from './description.styles';

interface DescriptionProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  title?: string | JSX.Element;
  content: string | JSX.Element;
}

export const Description = ({ title, content, ...restProps }: DescriptionProps) => (
  <div {...restProps}>
    {title && <S.Title data-testid="description-title">{title}</S.Title>}
    <S.Content data-testid="description-content">{content}</S.Content>
  </div>
);

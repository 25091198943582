export enum Roles {
  NO_ROLE = 'no-role',
  INVESTOR = 'investor',
  INVESTOR_NED = 'investor-ned',
  NED = 'ned',
  FOUNDER = 'founder',
}

export enum AdvisorRole {
  ADVISOR = 'Advisor',
  NED = 'Ned',
  FRACTIONAL = 'Fractional',
  PLACEMENT = 'Placement',
}

export const createRoleFromValue = (value: string) => {
  const roles: { [key: string]: Roles } = {
    NO_ROLE: Roles.NO_ROLE,
    Investor: Roles.INVESTOR,
    Ned: Roles.NED,
    Founder: Roles.FOUNDER,
  };

  return roles[value];
};

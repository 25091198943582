import { Link as LinkBase } from 'react-router-dom';
import styled from 'styled-components';

const NameWithIcon = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Link = styled(LinkBase)`
  text-decoration: none;
  color: ${({ theme }) => theme.color.fontPrimary};

  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

const Wrapper = styled.div<{ disabled?: boolean; cursorVisible?: boolean }>`
  pointer-events: auto;
  cursor: ${({ cursorVisible }) => (cursorVisible ? 'pointer' : 'default')};
  &:active {
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  }
`;

const DisabledWrapper = styled.div`
  cursor: not-allowed;

  & div:last-of-type {
    opacity: 0.4;
  }
`;

const Name = styled.span`
  margin: ${({ theme }) => theme.spacing.small};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    white-space: normal;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    max-width: 500px;
  }
`;

export default { NameWithIcon, Name, Link, Wrapper, DisabledWrapper };

import { FullWidthField } from '@parts/forms/formik-full-field/formik-full-field';
import { Radio } from '@parts/radio/radio';
import { BooleanComparator, QueryFilterKind } from '@utils/hooks/use-filtering/use-filtering';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { SubFilterProps } from '../../filter';

export const BooleanByCondition = ({
  getFieldProps,
  getFieldMeta,
  setFieldValue,
  dataKey,
  keys,
}: Omit<SubFilterProps, 'options'>) => {
  const [allLabel, falseLabel, trueLabel] = useTranslation(['filters.all', 'filters.false', 'filters.true']);

  const { value } = getFieldProps(keys.value);
  const meta = getFieldMeta(keys.value);

  const currentValue = value?.[QueryFilterKind.Boolean]?.value;

  return (
    <>
      <FullWidthField
        label={{ label: '', for: `${dataKey} by condition` }}
        error={meta?.error ?? undefined}
        touched={meta?.touched ?? undefined}
      >
        <Radio.Group
          value={typeof currentValue === 'boolean' ? currentValue : undefined}
          onChange={(e) => {
            if ([true, false].includes(e.target.value)) {
              return setFieldValue(keys.value, {
                key: dataKey,
                [QueryFilterKind.Boolean]: {
                  value: e.target.value,
                  comparator: BooleanComparator.Equal,
                },
              });
            }

            return setFieldValue(keys.value, undefined);
          }}
        >
          <Radio value={undefined}>{allLabel}</Radio>
          <Radio value={false}>{falseLabel}</Radio>
          <Radio value>{trueLabel}</Radio>
        </Radio.Group>
      </FullWidthField>
    </>
  );
};

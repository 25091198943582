import styled from 'styled-components';
import { BuildYourProfileScore as BuildYourProfileScoreBase } from './parts/build-your-profile-score';

const BuildYourProfileScore = styled(BuildYourProfileScoreBase)`
  transform: translateY(-10px);
  margin-bottom: 5px;

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    margin-bottom: 0px;
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export default { BuildYourProfileScore };

import { SecureLink } from '@parts/link/link';
import { Links } from '@router/links';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './post-role.styles';

export const PostRole = ({ url }: { url: string }) => {
  const [description, buttonLabel, subTitle, learnMore] = useTranslation([
    'advisors.postRole.description',
    'advisors.postRole',
    'advisors.subTitle',
    'advisors.learnMore',
  ]);

  return (
    <S.Section data-testid="post-role-section">
      <S.Description>{description}</S.Description>
      <SecureLink href={Links.ROLES_BROWSE()} data-testid="post-role-link">
        <S.AddRoleButton type="primary">{buttonLabel}</S.AddRoleButton>
      </SecureLink>
      <S.SubTitle data-testid="advisor-subTitle">
        {subTitle}
        <S.Link data-testid="learn-more-link" href={url} outer>
          {learnMore}
        </S.Link>
      </S.SubTitle>
    </S.Section>
  );
};

import styled from 'styled-components';

const InvisibleButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;
  &:focus,
  &:focus-within,
  &:focus-visible {
    outline: 2px solid
      ${({ theme }) => (theme.v2.mode === 'light' ? theme.v2.colors.blue[200] : theme.v2.colors.blue[400])};
  }
  outline-offset: 2px;
`;

export default { InvisibleButton };

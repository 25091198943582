import styled from 'styled-components';

const LogoutButton = styled.button`
  display: block;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.baseColors.Blue200};
`;

export default { LogoutButton };

import type { History } from 'history';
import type { AnchorHTMLAttributes, DetailedHTMLProps, PropsWithoutRef, RefAttributes } from 'react';
import type { LinkProps } from 'react-router-dom';
import S from './link.styles';

type SecureLinkBaseProps = {
  href: string;
};
type SecureOuterLink = SecureLinkBaseProps &
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
    outer?: boolean;
  };

export type SecureLinkProps = SecureOuterLink;

export interface LinkOverloaded {
  (props: SecureOuterLink): JSX.Element;
}

export const SecureLink: LinkOverloaded = ({
  rel = 'noreferrer noopener',
  outer,
  href,
  children,
  ...rest
}: SecureLinkProps) =>
  outer ? (
    <a {...rest} rel={rel} href={href}>
      {children}
    </a>
  ) : (
    <S.Link
      {...(rest as PropsWithoutRef<LinkProps<History.LocationState>> & RefAttributes<HTMLAnchorElement>)}
      to={href}
    >
      {children}
    </S.Link>
  );

import { TwitterOutlined as TwitterOutlinedBase } from '@ant-design/icons';
import styled from 'styled-components';

const TwitterOutlinedIcon = styled(TwitterOutlinedBase)`
  width: 12px;
  height: 12px;
  color: ${({ theme }) => theme.baseColors.Blue200};
  margin: 5px;

  path {
    fill: #3e60eb;
  }
`;

export default { TwitterOutlinedIcon };

import * as Yup from 'yup';

export const useValidationSchema = () => {
  return Yup.object({
    onlyNewlyRegistered: Yup.boolean(),
    industries: Yup.array().of(Yup.string()),
    skillSet: Yup.array().of(Yup.string()),
    compensation: Yup.object({
      role: Yup.string(),
      compensationType: Yup.string().nullable(),
      minDateRate: Yup.number().nullable(),
      maxDateRate: Yup.number().nullable(),
    }),
    countriesOfResidence: Yup.array().of(Yup.string()),
    regions: Yup.array().of(Yup.string()),
  });
};

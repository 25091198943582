import * as Yup from 'yup';

const max = (count: number, estimatedWordLength: number) => (value: string | null) => {
  if (count === 0) return false;
  if (!value || value === null) return true;

  const plainText = JSON.parse(value)
    .blocks.map((v: object & { text: string }) => v.text)
    .join(',');
  const characters = Array.from(plainText);

  return plainText.trim().split(' ').length <= count && characters.length < count * estimatedWordLength;
};

const min = (count: number) => (value: string | null | undefined) => {
  if (count === 0) return true;
  if (!value) return false;

  return count < value.split(' ').length;
};

export const maxWords = (limit: number, minLabel: string, maxLabel: string) =>
  Yup.mixed()
    .test({
      message: () => minLabel,
      test: min(0),
    })
    .test({
      message: () => maxLabel,
      test: max(limit, 10),
    })
    .nullable();

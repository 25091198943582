import { V2TitleGroup } from '@/components/ui/v2-title-group/v2-title-group';
import styled from 'styled-components';

const Wrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
  min-height: 540px;
`;

const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 8px;
  width: 100%;
  margin-top: 32px;

  @media (min-width: 744px) {
    gap: 16px;
    flex-direction: row;
    margin-top: 75px;
  }
`;

const TitleGroupWrapper = styled(V2TitleGroup)`
  margin: 0 auto 40px auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonSelectWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  max-width: 440px;
`;

const QuestionsWrapper = styled('div')`
  margin: 24px auto;
`;

const SingleQuestionWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 40px;
`;

export default {
  Wrapper,
  ButtonWrapper,
  TitleGroupWrapper,
  ButtonSelectWrapper,
  QuestionsWrapper,
  SingleQuestionWrapper,
};

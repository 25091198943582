import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import type { ReactNode } from 'react';
import S from './table-column.styles';

export const TableColumn = ({
  children,
  title,
  className,
  mobileInlineChildren: InlineChildren,
  dataTestId,
  hideMobile,
  mobileFullWidth,
}: {
  children: ReactNode;
  title?: ReactNode;
  className?: string;
  mobileInlineChildren?: ReactNode;
  dataTestId?: string;
  hideMobile?: boolean;
  mobileFullWidth?: boolean;
}) => {
  const {
    deviceData: { isMobile, isTablet },
  } = useDeviceDetect();

  return (
    <S.Row className={className} data-testid={dataTestId || 'table-column'}>
      {(isMobile || isTablet) && !hideMobile ? (
        <S.Wrapper>
          <S.RowMobile $mobileFullWidth={mobileFullWidth}>
            {title && <S.RowMobileHeader>{title}</S.RowMobileHeader>}
            <S.RowMobileValue $mobileFullWidth={mobileFullWidth}>{children || ' - '}</S.RowMobileValue>
          </S.RowMobile>
          {InlineChildren}
        </S.Wrapper>
      ) : (
        <>{children || ' - '}</>
      )}
    </S.Row>
  );
};

import { SuccessBanner } from './parts/success-banner/success-banner';
import type { StepContentProps } from '@pages/content/onboarding/founder/paths.config';
import Title from '@pages/content/onboarding/parts/title/title';
import { CodatRequestContextValue } from '@pages/content/pulse/api/connect-codat/connect-codat.action';
import { IntegratedMetrics as PulseIntegratedMetrics } from '@pages/content/pulse/founder/parts/management/parts/integrated-metrics/integrated-metrics';
import { useElementRevealAnimation } from '@utils/hooks/use-element-reveal-animation/use-element-reveal-animation';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';

const REVEAL_ANIMATION_ELEMENT_ID = 'integrated-metrics-animation-id';

export const IntegratedMetrics = ({ currentPage, endPage }: StepContentProps) => {
  useElementRevealAnimation(REVEAL_ANIMATION_ELEMENT_ID, { duration: 350 });
  const [titleLabel, subtitleLabel] = useTranslation(['metrics.codat.title', 'metrics.codat.description']);

  return (
    <div>
      <Title title={titleLabel} currentPage={currentPage} endPage={endPage} subHeadline={subtitleLabel} />
      <div data-animation-id={REVEAL_ANIMATION_ELEMENT_ID}>
        <PulseIntegratedMetrics
          showNotConnectedHeading={false}
          codatRequestContext={CodatRequestContextValue.Onboarding}
          connectedSuccessStateOverride={<SuccessBanner />}
        />
      </div>
    </div>
  );
};

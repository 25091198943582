import type { SharedDashboard } from '@pages/content/pulse/parts/dashboards/parts/dashboard/submenu/parts/share-dashboard/share-dashboards';
import Checkbox from '@parts/checkbox/checkbox';
import message from '@parts/message/message';
import { Links } from '@router/links';
import type { MutateFunction } from '@tanstack/react-query';
import { copyToClipboard } from '@utils/fns/copy-to-clipboard';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Col, Row, Tooltip } from 'antd';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import { useState } from 'react';
import * as Yup from 'yup';
import { Input } from '../input/input';
import S from './share-by-url.styles';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { DateFormat } from '@i18n/date-formats';

export const ShareByUrl = ({
  expireAt,
  hash,
  id,
  password,
  resetUrl,
  generateUrl,
  handlePasswordUpdate,
}: SharedDashboard & {
  resetUrl: MutateFunction<{ data: SharedDashboard }, unknown, string, unknown>;
  generateUrl: MutateFunction<{ data: SharedDashboard }, unknown, string, unknown>;
  handlePasswordUpdate: MutateFunction<
    { data: SharedDashboard },
    unknown,
    {
      dashboardId: string;
      password: string | null;
    },
    unknown
  >;
}) => {
  const [
    accessThePageText,
    forSecurityText,
    hoursText,
    hourText,
    resetLinkText,
    generateLinkText,
    orText,
    tooltipText,
    copyText,
    copiedToClipboardText,
    saveText,
    passwordTooltip,
    passwordLabel,
    limitMin,
    limitMax,
  ] = useTranslation([
    'pulseDashboard.shared.modal.shareAsUrl.description.accessThePage',
    'pulseDashboard.shared.modal.shareAsUrl.description.forSecurity',
    'pulseDashboard.shared.modal.shareAsUrl.description.hours',
    'pulseDashboard.shared.modal.shareAsUrl.description.hour',
    'pulseDashboard.shared.modal.shareAsUrl.description.resetLink',
    'pulseDashboard.shared.modal.shareAsUrl.description.generateLink',
    'pulseDashboard.shared.modal.shareAsUrl.description.or',
    'pulseDashboard.shared.modal.shareAsUrl.description.tooltip',
    'pulseDashboard.shared.modal.shareAsUrl.description.copy',
    'pulseDashboard.shared.modal.shareAsUrl.description.copiedToClipboard',
    'pulseDashboard.shared.modal.shareAsUrl.description.save',
    'pulseDashboard.shared.modal.shareAsUrl.description.passwordTooltip',
    'pulseDashboard.shared.modal.shareAsUrl.description.passwordLabel',
    'formik.validation.min',
    'formik.validation.max',
  ]);

  const history = useHistory();
  const { dateFormatter } = useUserDateFormat();

  const hoursLeft = (() => {
    const expireInHours = DateTime.fromISO(expireAt).diffNow().as('hours');

    const expireInLessThan24Hours = expireInHours <= 24;

    if (!expireInLessThan24Hours) return null;

    if (expireInHours <= 1) {
      return (
        <>
          {expireInHours}
          {hourText}
        </>
      );
    }

    return (
      <>
        {expireInHours}
        {hoursText}
      </>
    );
  })();

  const reportUrl = window.location.origin + history.createHref({ pathname: Links.PROGRESS_REPORT(hash) });

  const [displayPassword, setDisplayPassword] = useState<boolean>(Boolean(password));

  const {
    getFieldProps,
    touched,
    errors,
    submitForm,
    setValues,
    isSubmitting,
    setSubmitting,
    dirty,
    isValid,
    resetForm,
  } = useFormik<{
    password: string | null;
  }>({
    onSubmit: (values) => {
      handlePasswordUpdate({ dashboardId: id, password: values.password });
      setSubmitting(false);
      resetForm({ values });
    },
    initialValues: {
      password,
    },
    validateOnMount: false,
    validationSchema: Yup.object({
      password: Yup.string().nullable().min(3, limitMin).max(50, limitMax),
    }),
  });

  const handleCheckboxToggle = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;

    setDisplayPassword(checked);

    if (checked === false) {
      setValues({ password: null });
      submitForm();
    }
  };

  const copyToClipboardHandler = () => {
    copyToClipboard(
      reportUrl,
      () => message.success({ content: copiedToClipboardText }),
      (err: string) => message.error({ content: err }),
    );
  };

  return (
    <S.Form>
      <S.FirstLine>{accessThePageText}</S.FirstLine>
      <S.Text>
        {forSecurityText}
        {hoursLeft}
        <S.Date>{dateFormatter(expireAt, DateFormat.FULL_DATE_TIME)}</S.Date>
      </S.Text>
      <S.UrlActions>
        <S.Link type="link" onClick={() => resetUrl(id)}>
          {resetLinkText}
        </S.Link>
        <S.OrSpacer>{orText}</S.OrSpacer>
        <S.LinkWithTooltip>
          <S.Link type="link" onClick={() => generateUrl(id)}>
            {generateLinkText}
          </S.Link>
          <Tooltip title={tooltipText}>
            <S.InfoIcon />
          </Tooltip>
        </S.LinkWithTooltip>
      </S.UrlActions>

      <S.TopMarginRow gutter={[10, 0]}>
        <Col xs={20} sm={20} md={20} lg={20} xl={20}>
          <Input type="text" value={reportUrl} readOnly />
        </Col>
        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
          <S.CopyButton data-testid="copy-btn-url" onClick={copyToClipboardHandler}>
            {copyText}
          </S.CopyButton>
        </Col>
      </S.TopMarginRow>

      <S.CheckboxFormikField
        label={{
          for: 'password',
          label: (
            <span>
              {passwordLabel}
              <Tooltip title={passwordTooltip} />
            </span>
          ),
        }}
      >
        <Checkbox checked={displayPassword} onChange={(e) => handleCheckboxToggle(e)} id="password" />
      </S.CheckboxFormikField>

      {displayPassword && (
        <S.FormikField error={errors.password} touched={touched.password}>
          <Row gutter={[10, 0]}>
            <Col xs={20} sm={20} md={20} lg={20} xl={20}>
              <Input {...getFieldProps('password')} />
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
              <S.SaveButton
                data-testid="save-btn-url"
                onClick={submitForm}
                animate={isSubmitting}
                disabled={!dirty || !isValid}
              >
                {saveText}
              </S.SaveButton>
            </Col>
          </Row>
        </S.FormikField>
      )}
    </S.Form>
  );
};

import { Can, useUserAbility } from '@abilities';
import { AbilityAction, AbilitySubject } from '@abilities/ability/types';
import { FounderLayout } from '@layout/founder/founder.layout';
import {
  GET_FOUNDER_SMART_MATCH_SUGGESTION_CACHE_KEY,
  getFounderSmartMatchSuggestion,
} from '@pages/content/lens/api/get-founder-smart-match-suggestion';
import { getFounderPublishStatusAction } from '@pages/content/profile/founder/api/get-founder-publish-status/get-founder-publish-status.action';
import { publishFounderProfileAction } from '@pages/content/profile/founder/api/publish-founder-profile/publish-founder-profile.action';
import { PageHeader } from '@parts/page-header/page-header';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { MatchedInvestor } from '../parts/cards/matched-investor';
import { SmartMatch } from '../parts/smart-match/smart-match';
import { UpgradeFromFreemiumBanner } from './parts/upgrade-from-freemium-banner';

export const FounderLensPage = () => {
  const [
    title,
    emptySecuredTitle,
    emptySecuredSubTitle,
    emptyPrivateTitle,
    emptyPrivateSubTitle,
    emptyPublish,
    goToProfile,
  ] = useTranslation([
    'lens.founder.title',
    'lens.founder.secured.title',
    'lens.founder.secured.subTitle',
    'lens.founder.private.title',
    'lens.founder.private.subTitle',
    'lens.founder.private.publish',
    'lens.founder.goToProfile',
  ]);

  const userAbility = useUserAbility();
  const isDiscoverable = userAbility.can(AbilityAction.ACCESS, AbilitySubject.DISCOVER);

  return (
    <FounderLayout>
      <PageHeader title={title} />
      <Can I={AbilityAction.ACCESS} a={AbilitySubject.LENS_SMART_MATCH} Fallback={UpgradeFromFreemiumBanner}>
        <SmartMatch
          translations={{
            emptySecuredTitle,
            emptySecuredSubTitle,
            emptyPrivateTitle,
            emptyPrivateSubTitle,
            emptyPublish,
            goToProfile,
          }}
          publishProfileAction={publishFounderProfileAction}
          smartMatchSuggestion={getFounderSmartMatchSuggestion}
          getPublishStatusAction={getFounderPublishStatusAction}
          Item={MatchedInvestor}
          smartMatchSuggestionCacheKey={GET_FOUNDER_SMART_MATCH_SUGGESTION_CACHE_KEY}
          discoverable={isDiscoverable}
        />
      </Can>
    </FounderLayout>
  );
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export interface InvestorDocumentsForCompany {
  id: string;
  name: string;
  url: string;
  note: string | null;
  uploadedAt: Date;
}

export const INVESTOR_DOCUMENTS_FOR_COMPANY_CACHE_KEY = (relationshipId: string) =>
  `get-investor-documents-for-company-${relationshipId}`;

export const getInvestorDocumentsForCompanyAction =
  (relationshipId: string) => async (): Promise<GraphqlResponse<InvestorDocumentsForCompany[]>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
        query getInvestorDocumentsForCompany {
          investorsDocumentsForCompany(investingRelationshipId: "${relationshipId}") {
            id
            name
            url
            note
            uploadedAt
          }
        }
      `,
    });

    return graphqlResponseWrapper(data, ['investorsDocumentsForCompany']);
  };

import { useElementRevealAnimation } from '@utils/hooks/use-element-reveal-animation/use-element-reveal-animation';
import { useListEntryRevealAnimation } from '@utils/hooks/use-list-reveal-animation/use-list-reveal-animation';
import S from './companies-list.style';

export const CompaniesList = ({
  title,
  items,
  maxInRow = 4,
  id = '',
}: {
  items: { imageUrl: string; name: string }[];
  title?: string;
  maxInRow?: number;
  id: string;
}) => {
  const listAnimationId = `elements-stagger-${id}`;
  useListEntryRevealAnimation(listAnimationId);
  useElementRevealAnimation('title', { duration: 700, delay: 1000 });

  return (
    <>
      {title ? (
        <S.ListTitle data-animation-id="title" data-testid="companies-list-title">
          {title}
        </S.ListTitle>
      ) : null}

      <S.List $maxInRow={maxInRow} data-testid="companies-list">
        {items.map(({ imageUrl, name }, index) => (
          <S.ListItem key={index} data-testid="companies-list-item" data-animation-id={listAnimationId}>
            <img loading="lazy" src={imageUrl} alt={name} />
          </S.ListItem>
        ))}
      </S.List>
    </>
  );
};

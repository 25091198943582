// TODO: CON-11585
//  This code requires urgent refactoring or rewriting from scratch due to its lack of readability,
//  structure, and potential security threats. It is recommended to conduct a thorough code review to
//  identify and fix issues related to performance, scalability, and security.

import { useChangedFields } from '@/pages/content/onboarding/hooks/use-changed-fields';
import { PillButtonCheckbox } from '@/parts/pill-button-checkbox/pill-button-checkbox';
import type { InvestorOnboardingInvestmentSpecResponseData } from '@pages/content/onboarding/investor/api/get-investor-onboarding-investment-spec-data.action';
import type { IInvestorInvestmentPayload } from '@pages/content/profile/investor/api/investments/investments';
import {
  INVESTMENT_RANGE_MAX,
  INVESTMENT_RANGE_MIN,
  INVESTMENT_RANGE_STEP,
} from '@pages/content/profile/investor/parts/investment/investment';
import { AmountRangeSlider } from '@parts/amount-range-slider/amount-range-slider';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useUserCurrency } from '@utils/hooks/use-user-currency/use-user-currency';
import { useFormik } from 'formik';
import { useEffect, useState, type HTMLAttributes } from 'react';
import type { Any } from 'src/types';
import S from './form.style';
import { useValidationSchema } from './validation-schema';

interface FormikFormData {
  isDeployingCapitalInNextThreeMonths: boolean | null;
}

interface InvestmentFormProps extends HTMLAttributes<HTMLElement> {
  isLoading: boolean;
  data: InvestorOnboardingInvestmentSpecResponseData;
  handleUpdate: (v: IInvestorInvestmentPayload) => Promise<Any>;
  onFormValidStateChange: (validStatus: boolean, error: string | null) => void;
}

export const InvestmentForm = ({
  data,
  handleUpdate,
  isLoading,
  onFormValidStateChange,
  ...restProps
}: InvestmentFormProps) => {
  const [
    rangeLabel,
    isDeployingCapitalInNextThreeMonthsLabel,
    isDeployingCapitalInNextThreeMonthsYes,
    isDeployingCapitalInNextThreeMonthsNo,
  ] = useTranslation([
    'investor-onboarding.investments.form.rangeLabel',
    'investor-onboarding.investments.form.isDeployingCapitalInNextThreeMonthsLabel',
    'investor-onboarding.investments.form.isDeployingCapitalInNextThreeMonths.yes',
    'investor-onboarding.investments.form.isDeployingCapitalInNextThreeMonths.no',
  ]);

  const [currentInvestmentRangeValue, setCurrentInvestmentRangeValue] = useState<[number, number]>(() => [
    INVESTMENT_RANGE_MIN,
    INVESTMENT_RANGE_MAX,
  ]);

  const isDeployingCapitalInNextThreeMonthsOptions = [
    { label: isDeployingCapitalInNextThreeMonthsNo, value: false },
    { label: isDeployingCapitalInNextThreeMonthsYes, value: true },
  ];

  useEffect(() => {
    const newVal: [number, number] = [data.minInvestmentAmount ?? 0, data.maxInvestmentAmount ?? 0];
    setCurrentInvestmentRangeValue(newVal);
  }, [data]);

  const { resetChangedWithDelay, setChanged } = useChangedFields();
  const handleHighlight = (v: FormikFormData): void => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    setChanged(v as Any, formik.initialValues as Any);
    resetChangedWithDelay();
  };

  const formik = useFormik<FormikFormData>({
    async onSubmit(values) {
      await handleUpdate({
        minInvestmentAmount: currentInvestmentRangeValue[0],
        maxInvestmentAmount: currentInvestmentRangeValue[1],
        isDeployingCapitalInNextThreeMonths: values.isDeployingCapitalInNextThreeMonths,
      });
      handleHighlight(values);
    },
    initialValues: {
      isDeployingCapitalInNextThreeMonths: data.isDeployingCapitalInNextThreeMonths,
    },
    enableReinitialize: true,
    validationSchema: useValidationSchema(),
  });

  const { currencySymbol } = useUserCurrency();

  return (
    <S.FormWrapper data-testid="onboarding-investments-spec-form" {...restProps}>
      <S.GroupFullWidth title={isDeployingCapitalInNextThreeMonthsLabel}>
        {isDeployingCapitalInNextThreeMonthsOptions.map((opt) => (
          <PillButtonCheckbox
            key={opt.label}
            onChange={async () => {
              await formik.setValues({ isDeployingCapitalInNextThreeMonths: opt.value });
              formik.submitForm();
            }}
            checked={formik.values.isDeployingCapitalInNextThreeMonths === opt.value}
          >
            {opt.label}
          </PillButtonCheckbox>
        ))}
      </S.GroupFullWidth>
      <S.FormikField label={{ for: '', label: rangeLabel }}>
        <AmountRangeSlider
          min={INVESTMENT_RANGE_MIN}
          max={INVESTMENT_RANGE_MAX}
          step={INVESTMENT_RANGE_STEP}
          value={currentInvestmentRangeValue}
          onChange={setCurrentInvestmentRangeValue}
          onAfterChange={formik.submitForm}
          disabled={isLoading}
          loading={isLoading}
          displayValuePrefix={currencySymbol}
        />
      </S.FormikField>
    </S.FormWrapper>
  );
};

import type { Size } from '@ui/v2-badge/v2-badge.types';
import styled, { type DefaultTheme } from 'styled-components';

const getBadgeDynamicStyles = (theme: DefaultTheme, size: Size) => {
  return {
    sm: {
      padding: '5px 4px 3px 4px',
      fontSize: theme.v2.typography.sizes['2xs'],
      fontWeight: theme.v2.typography.weights.regular,
      lineHeight: theme.v2.typography.lineHeights.base,
    },
    md: {
      padding: '5px 8px 3px 8px',
      fontSize: theme.v2.typography.sizes['2xs'],
      fontWeight: theme.v2.typography.weights.medium,
      lineHeight: theme.v2.typography.lineHeights.base,
    },
    lg: {
      padding: '5px 12px 3px 12px',
      fontSize: theme.v2.typography.sizes.xs,
      fontWeight: theme.v2.typography.weights.semibold,
      lineHeight: theme.v2.typography.lineHeights.button,
    },
  }[size];
};

const IconWrapper = styled('div')``;

const Base = styled('div')<{ size: Size }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  font-family: ${({ theme }) => theme.v2.typography.fonts.body};
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme, size }) => getBadgeDynamicStyles(theme, size)};

  border: 1px solid;
  border-radius: ${({ theme }) => theme.v2.borders.radius.md};

  ${IconWrapper} {
    margin-top: ${({ size }) => (size === 'sm' ? '-1px' : 0)};
  }
`;

const Accent = styled(Base)<{ withBorder: boolean }>`
  background-color: ${({ theme }) => theme.v2.colors.red[50]};
  color: ${({ theme }) => theme.v2.colors.red[300]};
  border-color: ${({ withBorder, theme }) => (withBorder ? theme.v2.colors.red[300] : theme.v2.colors.red[50])};
`;

const Primary = styled(Base)<{ withBorder: boolean }>`
  background-color: ${({ theme }) => theme.v2.colors.blue[50]};
  color: ${({ theme }) => theme.v2.colors.blue[300]};
  border-color: ${({ withBorder, theme }) => (withBorder ? theme.v2.colors.blue[300] : theme.v2.colors.blue[50])};
`;

const Neutral = styled(Base)<{ withBorder: boolean }>`
  background-color: ${({ theme }) => theme.v2.colors.neutral[50]};
  color: ${({ theme }) => theme.v2.colors.neutral[500]};
  border-color: ${({ withBorder, theme }) => (withBorder ? theme.v2.colors.neutral[300] : theme.v2.colors.neutral[50])};
`;

const Success = styled(Base)<{ withBorder: boolean }>`
  background-color: ${({ theme }) => theme.v2.colors.green[100]};
  color: ${({ theme }) => theme.v2.colors.green[500]};
  border-color: ${({ withBorder, theme }) => (withBorder ? theme.v2.colors.green[500] : theme.v2.colors.green[100])};
`;

const NeutralReverse = styled(Base)<{ withBorder: boolean }>`
  background-color: rgba(255, 255, 255, 0.5);
  color: ${({ theme }) => theme.v2.colors.neutral[50]};
  border-color: ${({ withBorder, theme }) => (withBorder ? theme.v2.colors.neutral[50] : theme.v2.colors.neutral[500])};
`;

export default { Accent, Primary, Neutral, Success, IconWrapper, NeutralReverse };

import styled from 'styled-components';

const Counter = styled.span`
  margin: 5px;
  background: ${({ theme }) => theme.color.background};
  border-radius: 50%;
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  width: 30px;
  display: inline-block;
  height: 30px;
  text-align: center;
  line-height: 30px;
`;

export default { Counter };

import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.base};
  padding: 0 ${({ theme }) => theme.spacing.small} 0;

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    padding: 0;
  }
`;

export default { Wrapper };

import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './info.styles';

export const Info = ({
  avatarUrl,
  name,
}: {
  avatarUrl: string | null | undefined;
  name: string | null | undefined;
}) => {
  const [forLabel] = useTranslation(['progress.report.for']);
  return (
    <S.Wrapper>
      {forLabel} {avatarUrl && <S.Avatar avatarUrl={avatarUrl} size={60} />}
      <S.Name>{name}</S.Name>
    </S.Wrapper>
  );
};

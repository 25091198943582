import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import { Collapse } from 'antd';
import styled from 'styled-components';

const V2Collapse = styled(Collapse)`
  color: inherit;

  .ant-collapse-item {
    .ant-collapse-header {
      position: relative;
      display: flex;
      padding: 6px 32px 6px 12px;
      color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.neutral[50])};
      background-color: ${({ theme }) =>
        themedStyle(theme.v2.mode, theme.v2.colors.blue[50], theme.v2.colors.blue[200] + '33')};
      font-size: ${({ theme }) => theme.v2.typography.sizes.sm};
      font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
      border: 0 none;
      border-radius: ${({ theme }) => theme.v2.borders.radius.md} !important;

      ${({ theme }) =>
        customMediaQuery('font-size', {
          sm: theme.v2.typography.sizes.md,
        })}

      ${customMediaQuery('padding', {
        sm: '12px 32px 12px 12px',
      })}


      // icon
      > div:first-child {
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translateY(-50%) rotate(0);
        transform-origin: center center;
        transition: transform 0.3s ease;
      }

      &[aria-expanded='true'] {
        > div:first-child {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    .ant-collapse-content {
      color: inherit;

      > .ant-collapse-content-box {
        padding: 16px 0 0;
        font-size: ${({ theme }) => theme.v2.typography.sizes.xs};
        color: inherit;

        ${({ theme }) =>
          customMediaQuery('font-size', {
            sm: theme.v2.typography.sizes.sm,
          })}
      }
    }

    .ant-collapse-arrow {
      padding: 0 0 !important;
    }
  }
`;

V2Collapse.Panel = styled(Collapse.Panel)`
  &:not(:first-child) {
    margin-top: 16px;
  }
`;

export default { V2Collapse };

import type { Notification } from '@/context/notifications/notifications.reducer';
import { NotificationModules, NotificationTypes } from '@/utils/type-definitions/notifications';
import { useTranslation } from '../../utils/hooks/use-translation/use-translation';

const AccountNotification = ({ item: _item }: { item: Notification }) => {
  const [welcomeLl] = useTranslation(['notifications.welcome']);

  return <>{welcomeLl}</>;
};

const ConnectionsNotification = ({ item: { type, senderName } }: { item: Notification }) => {
  const [connectionSentLabel, connectionAcceptedLabel, connectionDeclinedLabel] = useTranslation([
    'notifications.follow.sent',
    'notifications.follow.accepted',
    'notifications.follow.declined',
  ]);

  if (type === NotificationTypes.ConnectionSentToFounder || type === NotificationTypes.ConnectionSentToParticipant) {
    return (
      <>
        <b>{senderName}</b> {connectionSentLabel}
      </>
    );
  }

  if (
    type === NotificationTypes.ConnectionAcceptedByFounder ||
    type === NotificationTypes.ConnectionAcceptedByParticipant
  ) {
    return (
      <>
        <b>{senderName}</b> {connectionAcceptedLabel}
      </>
    );
  }

  if (
    type === NotificationTypes.ConnectionDeclinedByFounder ||
    type === NotificationTypes.ConnectionDeclinedByParticipant
  ) {
    return (
      <>
        <b>{senderName}</b> {connectionDeclinedLabel}
      </>
    );
  }

  return <>---</>;
};

const MetricsNotification = ({ item: { type, senderName, payload } }: { item: Notification }) => {
  const [
    metricTrackLabelFirst,
    metricTrackLabelSecond,
    metricTriggerMatchedLabelFirst,
    metricTriggerMatchedLabelSecond,
    metricTriggerMatchedLabelThird,
    metricTriggerActivatedLabelFirst,
    metricTriggerActivatedLabelSecond,
    metricTriggerActivatedLabelThird,
  ] = useTranslation([
    'notifications.metric.track.first',
    'notifications.metric.track.second',
    'notifications.metric.trigger.matched.first',
    'notifications.metric.trigger.matched.second',
    'notifications.metric.trigger.matched.third',
    'notifications.metric.trigger.activated.first',
    'notifications.metric.trigger.activated.second',
    'notifications.metric.trigger.activated.third',
  ]);

  if (type === NotificationTypes.MetricTriggerSet) {
    return (
      <>
        <b>{senderName}</b> {metricTrackLabelFirst} (<b>{payload!.metricName}</b>), {metricTrackLabelSecond}
      </>
    );
  }

  if (type === NotificationTypes.MetricTriggerMatched) {
    return (
      <>
        {metricTriggerMatchedLabelFirst} (<b>{payload!.metricName}</b>) {metricTriggerMatchedLabelSecond}{' '}
        <b>{senderName}</b> {metricTriggerMatchedLabelThird}
      </>
    );
  }

  if (type === NotificationTypes.MetricTriggerReactivated) {
    return (
      <>
        {metricTriggerActivatedLabelFirst} (<b>{payload!.metricName}</b>) {metricTriggerActivatedLabelSecond}{' '}
        <b>{senderName}</b> {metricTriggerActivatedLabelThird}
      </>
    );
  }

  return <>---</>;
};

const RolesNotification = ({ item: { type, senderName, payload } }: { item: Notification }) => {
  const [rolesSentLabelFirst, rolesSentLabelSecond, rolesApprovedLabelFirst, rolesApprovedLabelSecond] = useTranslation(
    [
      'notifications.roles.sent.first',
      'notifications.roles.sent.second',
      'notifications.roles.approved.first',
      'notifications.roles.approved.second',
    ],
  );

  if (type === NotificationTypes.ApplicationForRoleSent) {
    return (
      <>
        <b>{senderName}</b> {rolesSentLabelFirst} <b>{payload!.roleTitle}</b> {rolesSentLabelSecond}
      </>
    );
  }

  if (type === NotificationTypes.ApplicationForRoleApproved) {
    return (
      <>
        <b>{senderName}</b> {rolesApprovedLabelFirst} <b>{payload!.roleTitle}</b> {rolesApprovedLabelSecond}
      </>
    );
  }

  return <>---</>;
};

const ExperienceNotification = ({ item: { type, senderName } }: { item: Notification }) => {
  const [experienceAddedByAdvisorLL] = useTranslation(['notifications.experience.added.by.advisor']);

  if (type === NotificationTypes.InternalExperienceAddedByAdvisor) {
    return (
      <>
        <b>{senderName}</b> {experienceAddedByAdvisorLL}
      </>
    );
  }

  return <>---</>;
};

export const NotificationContent = ({ item }: { item: Notification }) => {
  switch (item.module) {
    case NotificationModules.Account:
      return <AccountNotification item={item} />;
    case NotificationModules.Connections:
      return <ConnectionsNotification item={item} />;
    case NotificationModules.Metrics:
      return <MetricsNotification item={item} />;
    case NotificationModules.Roles:
      return <RolesNotification item={item} />;
    case NotificationModules.AdvisorExperience:
      return <ExperienceNotification item={item} />;
    default:
      return <>---</>;
  }
};

import ExpandIconBase from '@assets/icons/expand.svg?react';
import { CornerInfo as CornerInfoBase } from '@pages/content/profile/parts/corner-info/corner-info';
import { Section as SectionBase } from '@parts/section/section';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Info = styled(CornerInfoBase)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.fontPrimary};
`;

const Corner = styled.div`
  display: flex;
  gap: 60px;
  flex-wrap: wrap;
  margin-bottom: ${({ theme }) => theme.spacing.xbase};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    gap: 50px;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    gap: 25px;
    justify-content: center;
    margin-bottom: ${({ theme }) => theme.spacing.medium};
  }
`;

const Expand = styled(Link)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.link};
  text-decoration: none;

  svg {
    transition: 0.5s;
  }

  &:hover {
    color: ${({ theme }) => theme.color.link};
    svg {
      transform: scale(1.2);
    }
  }
`;

const Icon = styled(ExpandIconBase)`
  margin-right: 7px;
  text-transform: capitalize;
`;

const Section = styled(SectionBase)`
  > div:first-of-type {
    @media (max-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) {
      flex-direction: row;
      align-items: center;
    }
  }

  > div {
    padding: 0;
  }
`;

export default { Corner, Info, Expand, Icon, Section };

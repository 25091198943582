import message from '@/parts/message/message';
import { getServerError } from '@/utils/fns/get-server-error';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { FOUNDER_ONBOARDING_QUESTIONS_KEY } from '../api/get-founder-qualifying-questions.action';
import { updateSmartMatchAction } from '../api/patch-update-smart-match.action';

export const usePatchUpdateSmartMatch = (cb?: () => void) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: updateSmartMatchAction,
    onSuccess: () => {
      queryClient.refetchQueries([FOUNDER_ONBOARDING_QUESTIONS_KEY]);
      cb?.();
    },
    onError: (error: AxiosError) => message.error({ content: getServerError(error) }),
  });

  return {
    ...mutation,
    isUpdateSmartMatchLoading: mutation.isLoading,
    isUpdateSmartMatchError: mutation.isError,
    UpdateSmartMatchMutation: mutation.mutate,
  };
};

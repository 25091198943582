import type { V2Breakpoints } from '@/styles/v2-theme/breakpoints';
import { v2 } from '@/styles/v2-theme/v2-theme';
import { useState, useLayoutEffect } from 'react';

export const useMediaQuery = () => {
  const [screenSize, setScreenSize] = useState<keyof V2Breakpoints>('xs');
  const parsePixelValue = (value: string) => parseInt(value.replace('px', ''));

  useLayoutEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= parsePixelValue(v2.breakpoints.lg)) setScreenSize('lg');
      else if (window.innerWidth >= parsePixelValue(v2.breakpoints.md)) setScreenSize('md');
      else if (window.innerWidth >= parsePixelValue(v2.breakpoints.sm)) setScreenSize('sm');
      else setScreenSize('xs');
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return screenSize;
};

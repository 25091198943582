import { Table as TableBase } from 'antd';
import styled, { css } from 'styled-components';

const Wrapper = styled(TableBase)<{ wideTable?: boolean; stripped?: boolean }>`
  background: ${({ theme }) => theme.color.tableBackground};

  .ant-table-cell {
    border-top: 0 !important;
  }

  .ant-table-fixed {
    table-layout: fixed;
  }

  .ant-table-tbody > tr > td {
    word-wrap: break-word;
    word-break: break-all;
  }

  .ant-table-thead > tr > th {
    background: ${({ theme }) => theme.color.tableBackground};
    border-top: 1px solid ${({ theme }) => theme.color.tableBorder};
    border-bottom: 1px solid ${({ theme }) => theme.color.tableBorder};
    font-size: ${({ theme }) => theme.fontSize.xsmall};
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 5px;
    color: ${({ theme }) => theme.color.fontSecondary};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};

    .ant-table-column-sorters {
      padding: 0;
    }
  }

  .ant-table-measure-row {
    display: none;
  }

  .disabled-trigger {
    td {
      &:not(:last-of-type) {
        opacity: 0.3;
      }

      &:last-of-type {
        svg:not(.remove-btn) {
          opacity: 0.3;
        }
      }
    }
  }

  .archived-row {
    opacity: 0.3;
  }

  .archived-investment {
    opacity: 0.4;
  }

  .editable-double-column {
    flex-direction: column;
  }

  .ant-table-tbody > tr > td {
    vertical-align: middle;
    color: ${({ theme }) => theme.color.fontPrimary};
    border-bottom: 1px solid ${({ theme }) => theme.color.tableBorder};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    padding: 10px 5px;
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: break-spaces;
    margin-top: ${({ theme }) => theme.spacing.xmedium};
  }

  ${({ wideTable }) =>
    wideTable
      ? css`
          .ant-table-tbody > tr > td {
            word-wrap: normal;
            word-break: normal;
          }

          .sticky-column {
            position: sticky;
            top: 0;
            left: 0;
            z-index: 1;
          }
        `
      : css`
          .ant-table-tbody > tr > td {
            @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
              border-bottom: none;
              padding: 0;
              white-space: normal;

              .ant-empty.ant-empty-normal {
                position: absolute;
                width: 100%;
                background: ${({ theme }) => theme.color.tableBackground};
                min-height: 120px;

                > div {
                  margin-top: 30px;
                }
              }
            }
          }

          .editable-double-column {
            @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
              form {
                align-self: flex-start;
                margin-left: 160px;

                &:first-of-type {
                  margin: 0;
                }
              }
            }
          }

          .archived-investment {
            @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
              opacity: 1;

              td {
                > :not(button) {
                  opacity: 0.4;
                }
              }
            }
          }

          @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
            border-bottom: 1px solid ${({ theme }) => theme.color.border};

            /* Force table to not be like tables anymore */
            table,
            thead,
            tbody,
            th,
            td,
            tr {
              display: block;
            }

            /* Hide table headers (but not display: none;, for accessibility) */
            thead tr {
              position: absolute;
              top: -9999px;
              left: -9999px;
            }

            tr {
              border-top: 1px solid ${({ theme }) => theme.color.border};
              margin-bottom: ${({ theme }) => theme.spacing.small};
              padding-top: ${({ theme }) => theme.spacing.medium};

              &:first-of-type {
                border-top: 0;
              }
            }

            td {
              /* Behave  like a "row" */
              border: none;
              position: relative;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              border-bottom: 1px solid ${({ theme }) => theme.color.border};
            }

            .ant-table-container {
              min-height: 90px;
            }

            td:before {
              /* Top/left values mimic padding */
              white-space: nowrap;
              width: 80px;
              display: block;
              padding-right: ${({ theme }) => theme.spacing.medium};
              margin-right: ${({ theme }) => theme.spacing.medium};
              font-size: ${({ theme }) => theme.fontSize.small};
              font-weight: ${({ theme }) => theme.fontWeight.semibold};
            }
          }
        `}

  ${({ stripped }) =>
    stripped &&
    css`
      @media screen and (min-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
        .ant-table-tbody {
          > tr.ant-table-row {
            > td {
              font-size: ${({ theme }) => theme.fontSize.small};
            }

            &:nth-child(odd) {
              td {
                background-color: ${({ theme }) => theme.color.tableStripedRowBackground};
              }
              &:hover {
                > td {
                  background-color: ${({ theme }) => theme.color.tableStripedRowBackground};
                }
              }
            }

            &:hover {
              > td {
                background-color: unset;
              }
            }
          }
        }
      }
    `}
`;

Wrapper.defaultProps = {
  pagination: false,
};

export default { Wrapper };

import { useUserAbility } from '@abilities';
import { FounderLayout } from '@layout/founder/founder.layout';
import {
  GET_FOUNDER_DASHBOARD_CACHE_KEY,
  type Dashboard as DashboardDetails,
} from '@pages/content/pulse/parts/dashboards/api/get-founder-dashboard/get-founder-dashboard.action';
import { FounderDashboards } from '@pages/content/pulse/parts/dashboards/founder-dashboards.layout';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { PageHeader } from '@parts/page-header/page-header';
import { Routes } from '@router/routes';
import { pulseDashboardsKey, pulseInvestorsKey } from '@router/tabs-keys';
import { useQuery } from '@tanstack/react-query';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import {
  GET_FOUNDER_DASHBOARDS,
  getFounderDashboardsAction,
} from '../api/get-founder-dashboards/get-founder-dashboards.action';
import { getFounderDashboardAction } from '../parts/dashboards/api/get-founder-dashboard/get-founder-dashboard.action';
import { dashboardIdParam } from '../parts/dashboards/parts/dashboards-menu/dashboards-menu';
import { Shareholders } from './parts/cap-table/shareholders';

import { AbilityAction, AbilitySubject } from '@abilities/ability/types';
import S from './founder-pulse.styles';

export const FounderPulsePage = () => {
  const userAbility = useUserAbility();
  const [titleLabel, dashboardsLabel, investorsLabel, investmentLabel] = useTranslation([
    'pulse.founder.title',
    'pulse.founder.tabs.dashboards',
    'pulse.founder.tabs.shareholders',
    'pulse.founder.tabs.investment',
  ]);

  const hasPermissionsToPortfolio = userAbility.can(AbilityAction.ACCESS, AbilitySubject.PORTFOLIO);
  const investorsSectionLabel = hasPermissionsToPortfolio ? investorsLabel : investmentLabel;

  const { [dashboardIdParam]: dashboardId } = useQueryParams() as {
    [dashboardIdParam]: string;
  };

  const { data: dashboardsResponse, isLoading } = useQuery([GET_FOUNDER_DASHBOARDS], getFounderDashboardsAction);

  const { data: dashboardDetailsResponse, isLoading: dashboardDetailsIsLoading } = useQuery(
    [GET_FOUNDER_DASHBOARD_CACHE_KEY, dashboardId],
    getFounderDashboardAction(dashboardId),
    { enabled: Boolean(dashboardId) },
  );

  if (isLoading || !dashboardsResponse || !dashboardsResponse.data) {
    return <FullHeightSpinner />;
  }

  return (
    <FounderLayout>
      <PageHeader title={<span data-testid="founder-pulse-page-header">{titleLabel}</span>} />
      <S.Tabs
        locator="content"
        route={Routes.PULSE}
        defaultLocator={pulseDashboardsKey}
        destroyInactiveTabPane
        tabs={[
          {
            key: pulseDashboardsKey,
            label: dashboardsLabel,
            content: (
              <FounderDashboards
                dashboardsQueryKeyCache="get-founder-dashboards"
                dashboardDetailsQueryKeyCache="get-founder-dashboard"
                dashboardDetails={dashboardDetailsResponse?.data as DashboardDetails}
                dashboardDetailsIsLoading={dashboardDetailsIsLoading}
                dashboards={dashboardsResponse?.data.dashboardsList}
                hasCloseConnection={dashboardsResponse?.data.hasCloseConnections}
              />
            ),
          },
          {
            key: pulseInvestorsKey,
            label: investorsSectionLabel,
            content: <Shareholders allowedFounderPMT={hasPermissionsToPortfolio} />,
          },
        ]}
      />
    </FounderLayout>
  );
};

import { useQueryWithPagination } from '@/hooks/use-query-with-pagination';
import type { Sorter } from '@/utils/type-definitions/filters';
import { GET_NED_FOUNDER_PENDING_CONNECTIONS_KEY, getAdvisorInvites } from '../api/get-advisor-invites.query';
import type { AdvisorFounderPendingConnection } from '../types';

const PER_PAGE = 8;

type GetAdvisorPendingConnectionsQueryConfig = {
  sort?: Sorter;
};

export const useGetAdvisorPendingConnections = ({ sort }: GetAdvisorPendingConnectionsQueryConfig) => {
  const { data, error, isLoading, isFetching, isInitialLoading, pagination, ...restQueryResult } =
    useQueryWithPagination<AdvisorFounderPendingConnection>(
      [GET_NED_FOUNDER_PENDING_CONNECTIONS_KEY, sort?.key ?? '', sort?.order ?? ''],
      (page: number, perPage: number) => getAdvisorInvites({ page, perPage }),
      { perPage: PER_PAGE, refetchOnWindowFocus: true },
    );

  return {
    ...restQueryResult,
    advisorPendingConnectionsData: data,
    advisorPendingConnectionsError: error,
    isAdvisorPendingConnectionsDataLoading: isLoading,
    isAdvisorPendingConnectionsDataFetching: isFetching,
    isAdvisorPendingConnectionsInitialLoading: isInitialLoading,
    pagination,
  };
};

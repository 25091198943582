import styled, { css } from 'styled-components';

export const getGradientBorderStyle = (color: string, borderWidth: string = '2px', borderRadius: string = '3px') => css`
  content: '';
  position: absolute;
  inset: -${borderWidth};
  padding: ${borderWidth};
  background: ${color};
  border-radius: ${borderRadius};
  -webkit-mask:
    linear-gradient(transparent 0 0) content-box,
    linear-gradient(transparent 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
`;

const GradientBorderBox = styled.div<{ $disabled: boolean }>`
  &::before {
    ${({ theme }) => getGradientBorderStyle(theme.gradient.neon, '2px', theme.radius.medium)}
  }
`;

export default { GradientBorderBox };

import type { PropsWithChildren } from 'react';
import type { CustomArrowProps } from 'react-slick';

type SlideshowArrowProps = PropsWithChildren<CustomArrowProps> & {
  type: 'prev' | 'next';
};

const SlideshowArrow = ({ type, children, currentSlide, slideCount = 0, ...props }: SlideshowArrowProps) => {
  const additionalClass =
    (currentSlide === 0 && type === 'prev') || (currentSlide === slideCount - 1 && type === 'next')
      ? ' slick-disabled'
      : '';
  return (
    <button
      {...props}
      className={`slick-${type} slick-arrow${additionalClass}`}
      aria-hidden="true"
      aria-disabled={Boolean(additionalClass)}
      type="button"
    >
      {children}
    </button>
  );
};
export default SlideshowArrow;

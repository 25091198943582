import styled from 'styled-components';

const DashboardName = styled.p`
  margin: ${({ theme }) => theme.spacing.medium} 0;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 24px;
  line-height: 34px;
`;

export default { DashboardName };

import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import jwt from 'jsonwebtoken';
import { useEffect, useState } from 'react';

export const useAuth0Token = () => {
  const { token: auth0Token } = useQueryParams();

  const [isAuth0Token, setIsAuth0Token] = useState<null | boolean>(null);

  useEffect(() => {
    try {
      const decodedAuth0Token = jwt.decode(auth0Token as string);
      setIsAuth0Token(Boolean(decodedAuth0Token));
    } catch {
      setIsAuth0Token(false);
    }
  }, [auth0Token]);

  return { isAuth0Token, auth0Token };
};

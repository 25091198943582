import { InvestorLayout as Layout } from '@layout/investor/investor.layout';
import { PageHeader } from '@parts/page-header/page-header';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { Location } from 'history';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import { AdditionalInfo } from './parts/additional-info/additional-info';
import { Details } from './parts/details/details';
import Documents from './parts/documents/documents';
import { FundingHistory } from './parts/funding-history/funding-history';
import { InvestmentsInCompany } from './parts/investments-in-company/investments-in-company';
import { ManagementTeam } from './parts/management-team/management-team';
import { Routes } from '@router/routes';

export const CompanyDetailsPage = () => {
  const [companyDetailsLabel] = useTranslation(['portfolio.investor.investments.companyDetails']);

  const history = useHistory();
  const { state }: Location<{ page?: string }> = useLocation();

  return (
    <>
      <Layout>
        <PageHeader
          title={companyDetailsLabel}
          onBack={() => {
            history.push(
              generatePath(`${Routes.PORTFOLIO}?page=:page`, {
                page: state && state.page ? state.page : '1',
              }),
            );
          }}
        />
        <Details />
        <FundingHistory />
        <InvestmentsInCompany />
        <Documents />
        <ManagementTeam />
        <AdditionalInfo />
      </Layout>
    </>
  );
};

import { useEnforceTheme } from '@/utils/hooks/use-enforce-theme/use-enforce-theme';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { Links } from '@router/links';
import { Routes } from '@router/routes';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { useLogout } from '@utils/hooks/use-logout/use-logout';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Alert } from 'antd';
import { stringify } from 'query-string';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { finishInterruptedRegistrationAction } from '../../../api/register/finish-interrupted-registration.action';
import {
  INVITATION_TOKEN_KEY,
  type InvitationTokenData,
} from '../../invitation-registration/invitation-registration.page';
import { useAuth0Token } from '../hooks/use-auth0-token/use-auth0-token';
import RegisterWrapper from '../parts/register-wrapper/register-wrapper';
import { CONTINUE_AUTH0_URL } from '../register-form/founder-form/founder-form';
import { REFERRAL_CODE_KEY, type ReferralCode } from '../register-form/register-form.page';
import ChooseRoleForm from './form/choose-role.form';

import S from './choose-role.page.styles';

export const ChooseRolePage = () => {
  useEnforceTheme('dark');
  const { isAuth0Token, auth0Token } = useAuth0Token();
  const { state } = useQueryParams<{ state?: string }>();
  const storageInvitationToken = localStorage.getItem(INVITATION_TOKEN_KEY);
  const storageReferralCode = localStorage.getItem(REFERRAL_CODE_KEY);
  const history = useHistory();
  const [defaultRequestErrorTitle, defaultRequestErrorMessage, logoutLabel] = useTranslation([
    'messages.error.defaultTitle',
    'messages.error.default',
    'messages.error.logout',
  ]);

  const [errorText, setErrorText] = useState<string | null>(null);
  const [showLogout, setShowLogout] = useState<boolean>(false);

  const {
    mutate: checkFinishInterruptedRegistration,
    isLoading,
    isSuccess: wasInterrupted,
  } = useMutation(finishInterruptedRegistrationAction, {
    onError(error: AxiosError) {
      // If user account is not found in the DB continue normal register flow
      // in case of any other error show error message
      const errorName = error.response?.data.name;
      const interruptedRegistrationErrorName = 'NotFoundError';
      const interruptedRegistrationInvariantErrorName = 'InvariantError';

      if (errorName !== interruptedRegistrationErrorName) {
        const errorMessage = error.response?.data.error;
        setErrorText(errorMessage ?? defaultRequestErrorMessage);
      }
      if (errorName === interruptedRegistrationInvariantErrorName) {
        setShowLogout(true);
      }
    },
    onSuccess(response) {
      window.location.href = history.createHref({
        pathname: CONTINUE_AUTH0_URL,
        search: stringify({ token: response.data.token, state: state! }),
      });
    },
  });

  useEffect(() => {
    if (!auth0Token || !state) return;
    checkFinishInterruptedRegistration({ registrationToken: auth0Token as string, state });
  }, []);

  useEffect(() => {
    if (storageInvitationToken && isAuth0Token) {
      const invitationToken: InvitationTokenData = JSON.parse(storageInvitationToken);
      history.push({ pathname: Links.REGISTER_ROLE(invitationToken.role || ''), search: history.location.search });
    } else if (storageReferralCode && isAuth0Token) {
      const referralCode: ReferralCode = JSON.parse(storageReferralCode);
      history.push({ pathname: Links.REGISTER_ROLE(referralCode.role || ''), search: history.location.search });
    } else if (isAuth0Token === false) {
      history.replace(Routes.HOME);
    }
  }, [storageInvitationToken, storageReferralCode, isAuth0Token]);

  const { logout } = useLogout();

  const logoutButton = <S.LogoutButton onClick={() => logout()}>{logoutLabel}</S.LogoutButton>;

  return storageInvitationToken || storageReferralCode || isAuth0Token === false || isLoading || wasInterrupted ? (
    <FullHeightSpinner />
  ) : (
    <RegisterWrapper>
      {errorText ? (
        <Alert
          message={defaultRequestErrorTitle}
          description={
            <>
              {errorText}
              {showLogout ? logoutButton : null}
            </>
          }
          type="error"
          showIcon
        />
      ) : (
        <ChooseRoleForm />
      )}
    </RegisterWrapper>
  );
};

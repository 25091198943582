import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled, { css } from 'styled-components';

const V2SearchInputForm = styled.form<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  font-size: inherit;
  justify-content: flex-end;
  width: 100%;

  > label {
    margin-right: 5px;
    cursor: pointer;
  }

  > input {
    background-color: transparent;
    margin-right: 5px;
    border: none;
    transition:
      max-width 300ms,
      opacity 250ms;
    width: 0;
    padding: 2px 0;
    max-width: 0;
    border-bottom: 1px solid transparent;
    flex-grow: 1;

    ${({ $isActive }) => {
      return $isActive
        ? css`
            width: auto;
            max-width: 100%;
            border-bottom: 1px solid;
            border-color: ${({ theme }) =>
              themedStyle(theme.v2.mode, theme.v2.colors.neutral[500], theme.v2.colors.neutral[400])};
            padding: 2px 5px;
          `
        : null;
    }}

    &:focus {
      outline: none;
      border-bottom: 1px solid;
      border-bottom-color: ${({ theme }) =>
        themedStyle(theme.v2.mode, theme.v2.colors.neutral[500], theme.v2.colors.neutral[400])};
    }

    &:disabled {
      opacity: 0.65;
    }
  }

  > button {
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    transition: opacity 250ms;

    &:focus,
    &:active,
    &:hover {
      outline: none;
    }

    &:disabled {
      opacity: 0.65;
    }
  }
`;

export default { V2SearchInputForm };

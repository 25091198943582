import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';
import { ERoleApplicationStatus } from '../../parts/applications/api/get-role-application.actions';

export type RoleApplication = {
  id: string;
  role: string;
  roleId: string;
  company: string;
  appliedAt: string;
  status: ERoleApplicationStatus;
  companyAvatarUrl: string | null;
  companyId: string;
};

interface NedRoleApplicationsResponse {
  items: RoleApplication[];
  total: number;
}

export const GET_NED_ROLE_APPLICATIONS = 'get-ned-role-applications';

export const getNedRoleApplications =
  (start: number, limit: number) => async (): Promise<GraphqlResponse<NedRoleApplicationsResponse>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
    query getNedRoleApplications {
      nedRoleApplications(start: ${start}, limit: ${limit}) {
        items {
          id
          role
          roleId
          company
          companyAvatarUrl
          appliedAt
          status
          companyAvatarUrl
          companyId
        }
        total
      }
    }
    `,
    });

    return graphqlResponseWrapper(data, ['nedRoleApplications']);
  };

import { Tooltip } from '@parts/tooltip/tooltip';
import S from './label-with-tooltip.styles';

type LabelWithTooltipProps = {
  label: string;
  tooltip: string;
};

export const LabelWithTooltip = ({ label, tooltip }: LabelWithTooltipProps) => (
  <S.Wrapper>
    <S.Label>{label}</S.Label>
    <Tooltip title={tooltip} />
  </S.Wrapper>
);

import { useV2DropdownContext } from '../v2-dropdown-context';

import S from '../v2-dropdown.styles';

type MenuProps = {
  children: React.ReactElement | React.ReactElement[];
};

export const Menu = ({ children }: MenuProps) => {
  useV2DropdownContext();

  return <S.Menu>{children}</S.Menu>;
};

import type { V2BadgeProps } from '@ui/v2-badge/v2-badge.types';
import { V2Icon } from '@ui/v2-icon/v2-icon';
import React from 'react';
import S from './v2-badge.styles';

export const V2Badge: React.FC<V2BadgeProps> = ({
  text,
  variant = 'primary',
  size = 'md',
  withBorder = false,
  startIcon,
  'data-testid': testId = 'v2-badge',
  ...rest
}) => {
  const BadgeVariant = {
    primary: S.Primary,
    accent: S.Accent,
    neutral: S.Neutral,
    success: S.Success,
    'neutral-reverse': S.NeutralReverse,
  }[variant];

  return (
    <BadgeVariant data-testid={testId} size={size} withBorder={withBorder} {...rest}>
      {startIcon && (
        <S.IconWrapper>
          <V2Icon name={startIcon} size={size === 'sm' ? 'xxs' : 'sm'} data-testid={`${testId}-start-icon`} />
        </S.IconWrapper>
      )}
      <p>{text}</p>
    </BadgeVariant>
  );
};

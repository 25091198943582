import { LinkButton } from '@parts/link-button/link-button';
import { SecureLink as SecureLinkBase } from '@parts/link/link';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 550px;
  margin: 0 auto;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.xmedium};
`;

const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.xlarge};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin: ${({ theme }) => theme.spacing.xmedium};
  color: ${({ theme }) => theme.onboardingColors.fontPrimary};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.big};
  }
`;

const Description = styled.p`
  color: ${({ theme }) => theme.onboardingColors.fontPrimary};
  margin-bottom: ${({ theme }) => theme.spacing.base};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

const SecureLink = styled(SecureLinkBase)`
  font-size: ${({ theme }) => theme.fontSize.xmedium};
  text-decoration: none;
  color: ${({ theme }) => theme.baseColors.Blue200};
  white-space: nowrap;
`;

const ProfileReturnLink = styled(LinkButton)`
  max-width: 200px;
  margin-bottom: ${({ theme }) => theme.spacing.base};
`;

export default { Wrapper, Title, Description, SecureLink, ProfileReturnLink };

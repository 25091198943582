import { axiosInstance } from '@utils/axios/axios-instance';

export enum NumericConditions {
  Equal = 'Equal',
  GreaterThan = 'GreaterThan',
  GreaterOrEqual = 'GreaterOrEqual',
  LowerThan = 'LowerThan',
  LowerOrEqual = 'LowerOrEqual',
  Between = 'Between',
}

export interface SetUpNumericTriggerActionPayload {
  value: number;
  condition: NumericConditions;
}

interface SetUpNumericRangeTriggerActionPayload {
  startValue: number;
  endValue: number;
}

export const setUpNumericTrigger = (metricId: string) => async (payload: SetUpNumericTriggerActionPayload) =>
  axiosInstance.post(`/trigger/numeric/${metricId}`, payload);

export const setUpNumericRangeTrigger = (metricId: string) => async (payload: SetUpNumericRangeTriggerActionPayload) =>
  axiosInstance.post(`/trigger/numeric-range/${metricId}`, payload);

export const updateNumericTrigger = (triggerId: string) => async (payload: SetUpNumericTriggerActionPayload) =>
  axiosInstance.patch(`/trigger/numeric/${triggerId}`, payload);

export const updateNumericRangeTrigger =
  (triggerId: string) => async (payload: SetUpNumericRangeTriggerActionPayload) =>
    axiosInstance.patch(`/trigger/numeric-range/${triggerId}`, payload);

import type { ReactNode } from 'react';
import S from './tile.styles';

interface AdvisorLandingTileProps {
  title: string;
  description: string;
  disabled?: boolean;
  action: ReactNode;
  badges: ReactNode;
}

export const AdvisorLandingTile = ({
  title,
  description,
  action,
  badges,
  disabled = false,
}: AdvisorLandingTileProps) => (
  <S.Wrapper $disabled={disabled} data-testid="advisor-landing-tile">
    <S.BadgesWrapper>{badges}</S.BadgesWrapper>

    <S.Content>
      <div>
        <S.Title>{title}</S.Title>
        <S.Text>{description}</S.Text>
      </div>
      <div>{action}</div>
    </S.Content>
  </S.Wrapper>
);

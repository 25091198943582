import { LazyDatePicker } from '@parts/date-picker/date-picker.lazy';
import { Radio as BaseRadio } from '@parts/radio/radio';
import styled from 'styled-components';

const Radio = styled(BaseRadio)`
  display: inline-block;
  width: auto;

  .ant-radio-inner {
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.baseColors.Blue200};
  }
`;

const DatePicker = styled(LazyDatePicker)`
  &.ant-picker {
    width: 50%;
    min-width: 175px;
  }
`;

export default {
  Radio,
  DatePicker,
};

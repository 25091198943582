import React from 'react';
import type { V2GradientSvgProps } from './v2-gradient-svg.types';

export const V2GradientSvg: React.FC<V2GradientSvgProps> = ({ startColor, endColor, idCSS, rotation }) => {
  const gradientTransform = `rotate(${rotation})`;

  return (
    <svg style={{ height: 0, position: 'absolute' }}>
      <defs>
        <linearGradient id={idCSS} gradientTransform={gradientTransform}>
          <stop offset="0%" stopColor={startColor} />
          <stop offset="100%" stopColor={endColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};

import { createContext, type Dispatch } from 'react';
import type { NotificationsActionType, NotificationsState } from './notifications.reducer';

export type NotificationsStateApi = {
  state: NotificationsState;
  dispatch: Dispatch<NotificationsActionType>;
};

export const notificationsInitialState: NotificationsState = {
  currentNotifications: [],
  subscribed: false,
};

export const NotificationsContext = createContext<NotificationsStateApi | NotificationsState>(
  notificationsInitialState,
);

import { currencyToShortFormat } from '@utils/fns/currency-to-short-format';
import { Business } from './parts/business/business';
import { Favorite } from './parts/favorite/favorite';
import { Growth } from './parts/growth/growth';
import { Salary } from './parts/salary/salary';

import S from './meta-config.styles';

const noValue = <S.NoValue>---</S.NoValue>;

const displayInvestmentStage = (investmentStage: string | string[], productStage?: string) => {
  if (Array.isArray(investmentStage)) return investmentStage.join(', ');
  return `${investmentStage} (${productStage})`;
};

export const minMaxInvestment = (
  formatMethod: Function,
  minimumInvestmentAmount?: number | null,
  maximumInvestmentAmount?: number | null,
  currencySymbol = '',
) => {
  const minIsZero = !minimumInvestmentAmount;
  const maxIsZero = !maximumInvestmentAmount;

  if (!minIsZero && !maxIsZero) {
    return `${currencySymbol}${formatMethod(minimumInvestmentAmount)} - ${formatMethod(maximumInvestmentAmount)}`;
  }

  if (minIsZero && maxIsZero) {
    return noValue;
  }

  if (Boolean(minimumInvestmentAmount) && maxIsZero) {
    return `${currencySymbol}${formatMethod(minimumInvestmentAmount)} <`;
  }

  if (minIsZero && Boolean(maximumInvestmentAmount)) {
    return `${currencySymbol}0 - ${formatMethod(maximumInvestmentAmount)}`;
  }

  return noValue;
};

export default ({
  customerGroup,
  investmentStage,
  productStage,
  industry,
  maximumInvestmentAmount,
  minimumInvestmentAmount,
  currencySymbol,
}: {
  industry?: string[];
  customerGroup?: string[];
  investmentStage?: string | string[];
  productStage?: string;
  maximumInvestmentAmount?: number | null;
  minimumInvestmentAmount?: number | null;
  currencySymbol?: string;
}) => ({
  business: {
    icon: <Business />,
    value: customerGroup?.join(', ') || noValue,
    visible: (customerGroup && customerGroup.length > 0) || false,
  },
  growth: {
    icon: <Growth />,
    value: displayInvestmentStage(investmentStage || '', productStage),
    visible: Boolean(investmentStage || productStage),
  },
  favorite: {
    icon: <Favorite />,
    value: industry?.join(', ') || noValue,
    visible: (industry && industry.length > 0) || false,
  },
  salary: {
    icon: <Salary />,
    value: minMaxInvestment(currencyToShortFormat, minimumInvestmentAmount, maximumInvestmentAmount, currencySymbol),
    visible: Boolean(
      minMaxInvestment(currencyToShortFormat, minimumInvestmentAmount, maximumInvestmentAmount, currencySymbol),
    ),
  },
});

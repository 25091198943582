import type { Notification } from '@context/notifications/notifications.reducer';
import { EmptyState } from '@layout/parts/bell/empty-state/empty-state';
import { useNotificationList } from '@utils/hooks/use-notification-list/use-notification-list';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { GraphqlResponse } from 'src/types';
import { NotificationItem } from '../notification-item/notification-item';
import S from './notification-list.styles';

interface NotificationListProps {
  notifications: Notification[];
  refetch: () => Promise<GraphqlResponse<{ items: (object & { id: string })[]; total: number }> | undefined>;
}

export const NotificationList = ({ notifications, refetch }: NotificationListProps) => {
  const clearAll = useTranslation('notifications.overlay.clearAll');

  const { unreadNotificationPresent, clearNotifications } = useNotificationList({ refetch });

  return (
    <S.Container>
      <S.Header>
        {unreadNotificationPresent && (
          <S.ClearAllButton type="link" onClick={() => clearNotifications()}>
            {clearAll}
          </S.ClearAllButton>
        )}
      </S.Header>
      {notifications.length ? (
        <S.List>
          {notifications.map((notification) => (
            <S.ListItem key={notification.id}>
              <NotificationItem item={notification} refetch={refetch} />
            </S.ListItem>
          ))}
        </S.List>
      ) : (
        <EmptyState />
      )}
    </S.Container>
  );
};

import { Group as GroupBase } from '@/pages/content/onboarding/investor/content/business/parts/group/group';
import styled from 'styled-components';

const Group = styled(GroupBase)`
  max-width: 100%;
`;

const InvestmentsTableInfo = styled.p`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.small};
  margin-bottom: ${({ theme }) => theme.spacing.xmedium};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  color: ${({ theme }) => theme.color.grayedOutText};

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) {
    margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
  }
`;

export default { Group, InvestmentsTableInfo };

import { Radio } from 'antd';
import { ShimmerBadge } from '@parts/shimmer-badge/shimmer-badge';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { StarRating } from '@parts/star-rating/star-rating';
import S from './rating-filter.styles';

const DEFAULT_STAR_ROWS = 5;

interface RatingFilterProps {
  starRowsAmount?: number;
}

export const RatingFilter = ({ starRowsAmount = DEFAULT_STAR_ROWS }: RatingFilterProps) => {
  const [ratingLabel, comingSoonLabel] = useTranslation(['advisors.search.filters.radio.rating', 'badges.comingSoon']);

  const ratings = new Array(starRowsAmount).fill(0).map((_, index) => starRowsAmount - index);

  return (
    <div>
      <S.Wrapper>
        <S.Span>{ratingLabel}</S.Span>
        <S.ShimmerBadgeWrapper>
          <ShimmerBadge label={comingSoonLabel} />
        </S.ShimmerBadgeWrapper>
      </S.Wrapper>
      <S.RadioGroup disabled>
        {ratings.map((rating) => (
          <Radio key={rating} value={rating} data-testid="rating-radio-button">
            <StarRating defaultValue={rating} small disabled />
          </Radio>
        ))}
      </S.RadioGroup>
    </div>
  );
};

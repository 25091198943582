import type { GetReadinessScoreQueryResponseData } from '@/modules/founder/readiness-score/api/get-readiness-score.query';
import { Links } from '@/router/links';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { StepContent, type ContentStep } from '../step-content/step-content';

export const MarketFitContent = ({ data }: { data: GetReadinessScoreQueryResponseData['marketFit'] }) => {
  const [titleLL, descriptionLL, questionsStepTitleLL, questionsStepDescriptionLL] = useTranslation([
    'homepage.readinessScore.modal.marketFit.title',
    'homepage.readinessScore.modal.marketFit.description',
    'homepage.readinessScore.modal.marketFit.step.questions.title',
    'homepage.readinessScore.modal.marketFit.step.questions.description',
  ]);

  const { marketFitScore, questions } = data;

  const steps: ContentStep[] = [
    {
      id: 'questions',
      title: questionsStepTitleLL,
      description: questionsStepDescriptionLL,
      icon: 'shop',
      actionUrl: Links.PROFILE() + '?st=market-fit',
      received: questions.answered,
      available: questions.available,
    },
  ];

  return (
    <StepContent
      introTitle={titleLL}
      introDescription={descriptionLL}
      headerIcon="shop"
      steps={steps}
      score={marketFitScore}
    />
  );
};

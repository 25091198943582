import { useQuery } from '@tanstack/react-query';
import {
  GET_FOUNDER_ADVISOR_QUESTIONS_ANSWERS,
  getFounderAdvisorQuestionsAnswers,
} from '../api/get-founder-advisor-questions-answers.query';

export const useGetFounderAdvisorQuestionsAnswers = () => {
  const query = useQuery([GET_FOUNDER_ADVISOR_QUESTIONS_ANSWERS], getFounderAdvisorQuestionsAnswers, {
    refetchOnWindowFocus: true,
    cacheTime: 0,
  });

  return {
    founderAdvisorQuestionsAnswersData: query.data?.data,
    isFounderAdvisorQuestionsAnswersLoading: query.isLoading,
    isFounderAdvisorQuestionsAnswersFetching: query.isFetching,
    isFounderAdvisorQuestionsAnswersFetched: query.isFetched,
    ogQueryResult: query,
  };
};

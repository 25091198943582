import type { AppMessagesKey } from '@i18n/messages';
import { InvestorType } from '@pages/content/profile/investor/api/types';
import { TranslationText } from '@parts/translation-text/translation-text';
import { type ReactNode } from 'react';
import S from './info-tile.styles';

const SHARED_BOLDED_COUNTER_NUMBER = 2; // 0-based index , (c) will be bolded in this case, adjust for needs

type BulletListType = {
  text: AppMessagesKey;
  list: AppMessagesKey[] | null;
}[];

export const InfoTile = ({
  investorType,
  title,
  iconUrl,
  description,
  secondDescription,
  secondBulletList,
  bulletList,
  selected = false,
  onClick,
  className,
  disabled = false,
  showWarning = false,
}: {
  investorType: InvestorType;
  title: ReactNode;
  iconUrl: string;
  description: AppMessagesKey;
  secondDescription?: AppMessagesKey;
  bulletList: BulletListType;
  secondBulletList?: BulletListType;
  selected?: boolean;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  showWarning?: boolean;
}) => {
  const getListItemClassName = (shouldAddBoldClass: boolean, idx: number) => {
    return shouldAddBoldClass && SHARED_BOLDED_COUNTER_NUMBER === idx ? 'bold-counter' : '';
  };

  const TileListSection = ({
    listBulletPoints,
    listDescription,
    renderBoldCounter = false,
  }: {
    listBulletPoints?: BulletListType;
    listDescription?: AppMessagesKey;
    renderBoldCounter?: boolean;
  }) => (
    <S.Description $investorType={investorType}>
      {listDescription && <TranslationText id={listDescription} values={{ b: (boldedText) => <b>{boldedText}</b> }} />}
      {listBulletPoints && (
        <S.BulletList $investorType={investorType}>
          {listBulletPoints.map((item, idx) => (
            <li key={idx} className={getListItemClassName(renderBoldCounter, idx)}>
              <TranslationText id={item.text} values={{ b: (boldedText) => <b>{boldedText}</b> }} />
              {item.list && (
                <S.SubBulletList>
                  {item.list.map((subItem, subIdx) => (
                    <li key={subIdx}>
                      <TranslationText id={subItem} />
                    </li>
                  ))}
                </S.SubBulletList>
              )}
            </li>
          ))}
        </S.BulletList>
      )}
    </S.Description>
  );

  return (
    <S.InfoTile
      $investorType={investorType}
      $selected={selected}
      onClick={onClick}
      className={className}
      data-testid="investor-info-tile"
      disabled={disabled}
    >
      {iconUrl ? <img src={iconUrl} alt={`${title}-icon`} loading="lazy" /> : null}
      <S.Title>{title}</S.Title>
      {selected ? <S.Tick data-testid="green-tick-icon" /> : null}

      <TileListSection listDescription={description} listBulletPoints={bulletList} renderBoldCounter />
      <TileListSection listDescription={secondDescription} listBulletPoints={secondBulletList} />

      {showWarning && (
        <S.WarningTextContainer>
          <p>
            <TranslationText id="investor-onboarding-affirmations.sharedWarning.li1" />
          </p>
          <TranslationText id="investor-onboarding-affirmations.sharedWarning.li2" />
        </S.WarningTextContainer>
      )}
    </S.InfoTile>
  );
};

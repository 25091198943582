import styled from 'styled-components';

const ListItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 120px;
  margin: ${(props) => props.theme.spacing.xmedium} ${(props) => props.theme.spacing.medium};

  @media (max-width: ${(props) => props.theme.deviceBreakpoint.mobile}) {
    width: 50%;
  }

  img {
    display: block;
    width: auto;
    max-width: 100%;
  }
`;

const List = styled.ul<{ $maxInRow: number }>`
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  width: 100%;
  max-width: ${(props) => props.$maxInRow * 170}px;
  margin: ${(props) => props.theme.spacing.medium} auto;
`;

const ListTitle = styled.h3`
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.baseColors.Blue300};
  opacity: 0.6;
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  letter-spacing: 2px;
  margin-top: ${({ theme }) => theme.spacing.base};
`;

export default { List, ListItem, ListTitle };

import {
  FundingRoundsContext,
  type FundingRoundsStateApi,
} from '@pages/content/pulse/founder/parts/progress/parts/context/funding-rounds.context';
import { useContext } from 'react';

export const useFundingRoundsState = (): FundingRoundsStateApi => {
  const context = useContext(FundingRoundsContext) as FundingRoundsStateApi;

  if (!context) {
    throw new Error('useFundingRoundsState should be used within an FundingRoundsContext.');
  }

  return context;
};

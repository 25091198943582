import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';
import type { Investment, InvestmentCompany } from '../parts/editor/editor-interfaces';

interface GetInvestment extends Investment, InvestmentCompany {
  investingRelationshipId: string;
  round: {
    id: string | null;
    type: string | null;
    date: Date | null;
    isEditable: boolean;
  };
}

export const GET_INVESTMENT_CACHE_KEY = (investmentId: string) => `${investmentId}-investment`;
export const getInvestmentAction = (investmentId: string) => async (): Promise<GraphqlResponse<GetInvestment>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getInvestment {
        investment(investmentId: "${investmentId}") {
          id
          investingRelationshipId
          company {
            avatarUrl
            name
            type
          }
          round {
            id
            type
            date
            isEditable
          }
          amount
          shares
          percentageOwnership
          preMoneyVal
          postMoneyVal
          shareClass
          leadInvestor
          personalNote
          terms {
            warrants {
              amount
              price
              expiry
            }
            options
            convertibleLoanNote
            discount
          }
        }
      }
    `,
  });

  return graphqlResponseWrapper(data, ['investment']);
};

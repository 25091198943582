import { Section } from '@parts/section/section';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { type Experience } from '@/pages/content/public-profile/ned/api/get-ned-public-page-details';
import { V2ExperienceTile } from '@/components/blocks/v2-experience-tile/v2-experience-tile';
import { V2Avatar } from '@/components/ui/v2-avatar/v2-avatar';
import { DateFormat } from '@/utils/i18n/date-formats';
import { useUserDateFormat } from '@/utils/hooks/use-user-date-format/use-user-date-format';
import S from './experience-public-section.styles';

const getCompanyInfo = (experience: Experience, formatDate: (date: Date) => string) => {
  const formattedEndDate = experience.endDate ? formatDate(experience.endDate) : '';

  return {
    details: {
      companyName: experience.company.name,
      role: experience.role,
      startDate: formatDate(experience.startDate),
      endDate: experience.isOngoing ? 'Ongoing' : formattedEndDate,
    },
    descriptionText: experience.experienceSummary,
  };
};

export const ExperiencePublicSection = ({ experience }: { experience: Experience[] }) => {
  const { dateFormatter } = useUserDateFormat();
  const formatDate = (date: Date) => dateFormatter(date, DateFormat.LETTER_MONTH_LONG_YEAR);
  const [boardExperienceHeaderLabel] = useTranslation(['profile.preview.ned.boardExperience.header']);

  return (
    <Section header={boardExperienceHeaderLabel}>
      <S.TilesWrapper>
        {experience?.map((exp) => (
          <V2ExperienceTile
            key={exp.company.id}
            avatar={<V2Avatar name={exp.company.name} data-testid="user-avatar" src={exp.company.avatarUrl} />}
            companyInfo={getCompanyInfo(exp, formatDate)}
            industryItems={exp.company?.industry?.map((industry) => (
              <V2ExperienceTile.Pill key={industry} isIndustry>
                {industry}
              </V2ExperienceTile.Pill>
            ))}
            skillsItems={exp.skillSet?.map((skillSet) => (
              <V2ExperienceTile.Pill key={skillSet}>{skillSet}</V2ExperienceTile.Pill>
            ))}
            description={exp.experienceSummary ? <V2ExperienceTile.Description /> : null}
            companyInfoSection={
              <V2ExperienceTile.Details>
                <V2ExperienceTile.CompanyName />
                <V2ExperienceTile.Role />
                <V2ExperienceTile.DateRange />
              </V2ExperienceTile.Details>
            }
          />
        ))}
      </S.TilesWrapper>
    </Section>
  );
};

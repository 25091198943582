/* eslint-disable @typescript-eslint/no-use-before-define */
import { FounderLayout } from '@layout/founder/founder.layout';
import { EmptyState } from '@parts/empty-state/empty-state';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import message from '@parts/message/message';
import { PageHeader } from '@parts/page-header/page-header';
import { Links } from '@router/links';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import useConfirmModal from '@utils/hooks/use-confirm-modal/use-confirm-modal';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useRoleManagement } from '@utils/hooks/use-role-management/use-role-management';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { closeRole, deleteRole, refreshRole, setRoles } from '../../management/context/management.action-creators';
import Element from '../../parts/element/element';
import type { RoleMutationType } from '../../roles.types';
import {
  GET_FOUNDER_ROLES_CACHE_KEY,
  getFounderRolesAction,
  type FounderRoleResponse,
} from '../api/get-founder-roles.actions';
import { closeRoleAction, deleteRoleAction, refreshRoleAction } from '../api/management.actions';
import S from './founder.page.styles';
import { RolesTable } from './parts/roles/roles';

export const FounderCatalogue = () => {
  const {
    dispatch,
    state: { roles, isDataLoaded },
  } = useRoleManagement();
  const { mutateAsync: closeRoleMutation } = useMutation(closeRoleAction);
  const { mutateAsync: refreshRoleMutation } = useMutation(refreshRoleAction);
  const { mutateAsync: deleteRoleMutation } = useMutation(deleteRoleAction);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [currentRole, setCurrentRole] = useState<FounderRoleResponse | null>(null);
  const [currentAction, setCurrentAction] = useState<RoleMutationType | null>(null);
  const [modalOkText, setModalOkText] = useState('');

  const [
    title,
    reopenTitle,
    reopenContent,
    reopenButton,
    reopenSuccess,
    closeTitle,
    closeContent,
    closeButton,
    closeSuccess,
    deleteTitle,
    deleteContent,
    deleteButton,
    deleteSuccess,
    emptyTitleLabel,
    emptySubTitle,
    emptyCta,
  ] = useTranslation([
    'roles.founder.title',
    'roles.reopen.title',
    'roles.reopen.content',
    'roles.reopen.button',
    'roles.reopen.success',
    'roles.close.title',
    'roles.close.content',
    'roles.close.button',
    'roles.close.success',
    'roles.delete.title',
    'roles.delete.content',
    'global.delete',
    'roles.delete.success',
    'roles.founder.empty.title',
    'roles.founder.empty.subTitle',
    'roles.founder.cta',
  ]);

  const { modal, show } = useConfirmModal({
    title: modalTitle,
    content: modalContent,
    confirmLabel: modalOkText,
    onConfirm: () => {
      switch (currentAction) {
        default:
          return;

        case 'refresh':
          return handleRoleRefresh(currentRole!.id);

        case 'close':
          return handleRoleClose(currentRole!.id);

        case 'delete':
          return handleRoleDelete(currentRole!.id);
      }
    },
  });

  const { data: founderRolesResponse, isLoading } = useQuery([GET_FOUNDER_ROLES_CACHE_KEY], getFounderRolesAction);

  const handleRoleRefresh = async (roleId: string) => {
    try {
      await refreshRoleMutation(roleId);
      dispatch(refreshRole(roleId));

      message.success({ content: reopenSuccess });
    } catch (error) {
      message.error({ content: getServerError(error as AxiosError) });
    }
  };

  const handleRoleClose = async (roleId: string) => {
    try {
      await closeRoleMutation(roleId);
      dispatch(closeRole(roleId));

      message.success({ content: closeSuccess });
    } catch (error) {
      message.error({ content: getServerError(error as AxiosError) });
    }
  };

  const handleRoleDelete = async (roleId: string) => {
    try {
      await deleteRoleMutation(roleId);
      dispatch(deleteRole(roleId));

      message.success({ content: deleteSuccess });
    } catch (error) {
      message.error({ content: getServerError(error as AxiosError) });
    }
  };

  const handleRowMutationAction = (role: FounderRoleResponse, mutationType: RoleMutationType) => {
    setCurrentAction(mutationType);
    setCurrentRole(role);

    switch (mutationType) {
      default:
      case 'refresh':
        setModalTitle(reopenTitle);
        setModalContent(reopenContent);
        setModalOkText(reopenButton);
        break;

      case 'close':
        setModalTitle(closeTitle);
        setModalContent(closeContent);
        setModalOkText(closeButton);
        break;

      case 'delete':
        setModalTitle(deleteTitle);
        setModalContent(deleteContent);
        setModalOkText(deleteButton);
        break;
    }

    show();
  };

  useEffect(() => {
    if (isDataLoaded || !founderRolesResponse?.data?.length) {
      return;
    }

    dispatch(setRoles(founderRolesResponse.data));
  }, [founderRolesResponse, dispatch, isDataLoaded]);

  const history = useHistory();
  const handleGoBack = () => history.push(Links.ADVISORS());

  return (
    <FounderLayout>
      <PageHeader title={title} onBack={handleGoBack} />
      {(() => {
        if (isLoading) {
          return <FullHeightSpinner />;
        }

        if (roles.length === 0) {
          return (
            <EmptyState title={emptyTitleLabel} subTitle={emptySubTitle}>
              <S.AddRole type="primary">
                <Link to={Links.CREATE_NEW_ROLE()}>{emptyCta}</Link>
              </S.AddRole>
            </EmptyState>
          );
        }

        return (
          <Element data-testid="roles">
            <RolesTable data={roles} loading={isLoading} onRowClick={handleRowMutationAction} />
          </Element>
        );
      })()}

      {modal}
    </FounderLayout>
  );
};

import styled from 'styled-components';

const Wrapper = styled.div<{ width?: string; height?: string }>`
  height: ${(props) => (props.height ? `${props.height}px` : '40px')};
  width: ${(props) => (props.width ? `${props.width}px` : '40px')};
  border-radius: 100%;
  position: relative;
  overflow: hidden;
`;

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export default { Wrapper, Image };

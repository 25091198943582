import { axiosInstance } from '@utils/axios/axios-instance';
import type { ShareholderInvestment } from '../founder/parts/editor/editor-interfaces';

export const updateSingleShareholderAction = ({
  relationId,
  investmentId,
  investment,
}: {
  relationId: string;
  investmentId: string;
  investment: ShareholderInvestment;
}) =>
  axiosInstance.put(`/shareholders-management/shareholder/${relationId}/investment/${investmentId}`, { ...investment });

import metricsImage from '@assets/images/sm-with-metrics/metrics.webp';
import { Description } from '@pages/content/onboarding/founder/content/performance/parts/description/description';
import { Wrapper } from '@pages/content/onboarding/founder/content/performance/parts/wrapper/wrapper';
import type { StepContentProps } from '@pages/content/onboarding/founder/paths.config';
import Title from '@pages/content/onboarding/parts/title/title';
import { useListEntryRevealAnimation } from '@utils/hooks/use-list-reveal-animation/use-list-reveal-animation';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useUserCurrency } from '@utils/hooks/use-user-currency/use-user-currency';
import S from './smart-match-with-metrics.styles';

const LIST_ANIMATION_ELEMENT_ID = 'match-with-metrics-reveal-animation-element';

export const SmartMatchWithMetrics = ({ currentPage, endPage }: StepContentProps) => {
  useListEntryRevealAnimation(LIST_ANIMATION_ELEMENT_ID);

  const { currencySymbol } = useUserCurrency();
  const [titleLabel, alt, descriptionLabel, listItems] = useTranslation([
    'founder-onboarding.smartMatchWithMetrics.title',
    'founder-onboarding.smartMatchWithMetrics.alt',
    {
      id: 'founder-onboarding.smartMatchWithMetrics.description.content',
      variables: { currency: currencySymbol },
    },
    'founder-onboarding.smartMatchWithMetrics.description.list.items',
  ]);

  const descriptionContent = () => {
    const bulletPoints = listItems.split('\n');
    const firstColList = bulletPoints.slice(0, 3);
    const secondColList = bulletPoints.slice(3);

    return (
      <>
        <S.Image src={metricsImage} alt={alt} data-animation-id={LIST_ANIMATION_ELEMENT_ID} loading="lazy" />
        <p data-animation-id={LIST_ANIMATION_ELEMENT_ID}>{descriptionLabel}</p>
        <S.ListsWrapper data-animation-id={LIST_ANIMATION_ELEMENT_ID}>
          <S.BulletList>
            {firstColList.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </S.BulletList>
          <S.BulletList>
            {secondColList.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </S.BulletList>
        </S.ListsWrapper>
      </>
    );
  };

  return (
    <>
      <Title title={titleLabel} currentPage={currentPage} endPage={endPage} subHeadline="" />
      <Wrapper>
        <Description content={descriptionContent()} data-testid="path3-step2-description" />
      </Wrapper>
    </>
  );
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

interface IGetFounderDetails {
  aboutCompany: string;
  businessName: string;
  logo: {
    path: string | null;
    url: string | null;
  };
}

export const GET_FOUNDER_DATA_REQUEST = 'get_founder-data-request';

export const getFounderDataAction = async (): Promise<GraphqlResponse<IGetFounderDetails>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
    query getFounderData {
      getFounderPersonalDetails {
        businessName,
        logo{
          path,
          url,
        },
        aboutCompany,
      },
    }
  `,
  });

  return graphqlResponseWrapper(data, ['getFounderPersonalDetails']);
};

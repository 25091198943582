import type { Sorter } from '@/utils/type-definitions/filters';
import { createContext, useContext } from 'react';

const V2AriaTableContext = createContext<{ sorting?: Sorter; gridTemplateColumns: string } | null>(null);

export const useV2AriaTableContext = () => {
  const context = useContext(V2AriaTableContext);
  if (!context) throw new Error('V2AriaTable.* component must be rendered as child of V2AriaTable component');

  return context;
};

export { V2AriaTableContext };

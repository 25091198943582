import { FormikField as FormikFieldBase } from '@parts/forms/formik-field/formik-field';
import { Radio as RadioBase } from '@parts/radio/radio';
import { SubmitButton as SubmitButtonBase } from '@parts/submit-button/submit-button';
import { Input as InputBase } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.medium};
  color: ${({ theme }) => theme.color.fontPrimary};
`;

const FormikField = styled(FormikFieldBase)`
  margin-top: ${({ theme }) => theme.spacing.xmedium};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`;

const Input = styled(InputBase)`
  display: block;
  max-width: 100%;
  width: 350px;
`;

const TextArea = styled(InputBase.TextArea)`
  display: block;
  max-width: 100%;
  width: 350px;
`;

const Radio = styled(RadioBase)`
  width: auto;
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const SubmitButton = styled(SubmitButtonBase)`
  margin-top: ${({ theme }) => theme.spacing.medium};
`;

export default {
  Wrapper,
  FormikField,
  Radio,
  SubmitButton,
  Input,
  TextArea,
};

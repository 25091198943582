import React from 'react';
import type { RadioChangeEvent } from 'antd/lib/radio';
import S from './v2-radio.styles';

export type RadioVariant = 'primary' | 'secondary';

export type V2RadioProps = Partial<{
  children: React.ReactNode;
  value: string | number;
  variant: RadioVariant;
  disabled: boolean;
  checked: boolean;
  'data-testid': string;
  onChange: (e: RadioChangeEvent) => void;
  className: string;
}>;

export const V2Radio = ({
  children,
  value,
  variant = 'primary',
  disabled,
  checked,
  'data-testid': testId = 'v2-radio-button',
  onChange,
  className,
}: V2RadioProps) => (
  <S.V2Radio
    value={value}
    variant={variant}
    disabled={disabled}
    checked={checked}
    data-testid={testId}
    onChange={onChange}
    className={className}
  >
    {children}
  </S.V2Radio>
);

import { Roles } from '@domain/accounts/roles';
import { InvestorNedLayout } from '@layout/investor-ned/investor-ned.layout';
import { NotFoundPage } from '@pages/utility/not-found/not-found.page';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { FounderPreview } from './founder/founder.page';
import { NedPreview } from './ned/ned.page';

export const PreviewRolePage = () => {
  const {
    state: { userRole },
  } = useUserAccount();

  switch (userRole) {
    case Roles.FOUNDER:
      return <FounderPreview />;
    case Roles.NED:
      return <NedPreview />;
    case Roles.INVESTOR_NED:
      return <NedPreview Layout={InvestorNedLayout} />;
    default:
      return <NotFoundPage />;
  }
};

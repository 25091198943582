import { axiosInstance } from '@utils/axios/axios-instance';
import type { INedAbout, INedPersonalDetails, INedSmartMatch } from '../../ned-profile.page';

export type INedUpdatePersonalDetailsPayload = Omit<INedPersonalDetails, 'countryOfResidence'>;
export const updatePersonalDetailsAction = ({ profilePhotoUrl, email, ...rest }: INedUpdatePersonalDetailsPayload) =>
  axiosInstance.patch('/profile/ned/personal-details', rest);

export const updateSmartMatchAction = (formData: INedSmartMatch) =>
  axiosInstance.patch('/profile/ned/smart-match', formData);

export const updateAboutMeAction = (formData: Partial<INedAbout>) =>
  axiosInstance.patch('/profile/ned/about-section', formData);

import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const ReadinessCarouselTile = styled.div`
  padding: 16px;
  height: 100%;
  border-radius: ${({ theme }) => theme.v2.borders.radius.lg};
  border: 1px solid ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[50], theme.v2.colors.blue[500])};
  background-color: ${({ theme }) =>
    themedStyle(theme.v2.mode, theme.v2.colors.neutral[50], theme.v2.colors.blue[800])};
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[700], theme.v2.colors.neutral[50])};

  @media screen and (min-width: 1440px) {
    padding: 16px 24px;
  }
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  gap: 12px;

  @media screen and (min-width: 1440px) {
    gap: 16px;
  }

  > button {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    display: inline;
    text-align: left;
  }
`;

const Title = styled.h3`
  font-size: ${({ theme }) => theme.v2.typography.sizes.sm};
  font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.neutral[50])};
  line-height: 1.375;

  @media screen and (min-width: 1440px) {
    font-size: ${({ theme }) => theme.v2.typography.sizes.md};
  }
`;

const SubTitle = styled.h4<{ $completed: boolean }>`
  font-size: ${({ theme }) => theme.v2.typography.sizes.xs};
  color: ${({ theme }) => theme.v2.colors.magenta[400]};
  margin-top: 2px;
  color: ${({ theme, $completed }) =>
    $completed
      ? themedStyle(theme.v2.mode, theme.v2.colors.neutral[700], theme.v2.colors.neutral[400])
      : theme.v2.colors.magenta[400]};

  @media screen and (min-width: 1440px) {
    font-size: ${({ theme }) => theme.v2.typography.sizes.sm};
  }
`;

const Content = styled.div`
  margin-top: 12px;

  @media screen and (min-width: 1440px) {
    margin-top: 16px;
  }
`;

const Actions = styled.div`
  margin-top: 12px;

  @media screen and (min-width: 1440px) {
    margin-top: 16px;
  }

  > *,
  * > * {
    font-size: ${({ theme }) => theme.v2.typography.sizes.xs};

    @media screen and (min-width: 1440px) {
      font-size: ${({ theme }) => theme.v2.typography.sizes.sm};
    }
  }
`;

const List = styled.ul`
  padding: 0 0 0 16px;
  margin: 0;
  li {
    font-size: ${({ theme }) => theme.v2.typography.sizes.xs};
    line-height: 1.25;

    &:not(:first-child) {
      margin-top: 4px;
    }

    @media screen and (min-width: 1440px) {
      font-size: ${({ theme }) => theme.v2.typography.sizes.md};
    }
  }
`;

export default { ReadinessCarouselTile, Title, SubTitle, Content, List, Header, Actions };

import type { ProductDetailsDraft } from '@domain/subscriptions/product-details';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const PRODUCT_DATA_CACHE_KEY = 'product-data';

export const getNedProducts = async (): Promise<GraphqlResponse<ProductDetailsDraft[]>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getNedProducts {
        nedProducts {
          name
          description
          value
          currency
          netAmount
          grossAmount
          vatAmount
          vatRate
        }
      }
      `,
  });

  return graphqlResponseWrapper(data, ['nedProducts']);
};

import S from './info.styles';

export const Info = ({
  title,
  description,
  supplement,
  className,
}: {
  title: string;
  description: string;
  supplement?: string;
  className?: string;
}) => (
  <S.Wrapper className={className}>
    <S.Title>
      <S.Name>{title}</S.Name>
      {supplement && `: ${supplement}`}
    </S.Title>
    <S.Description>{description}</S.Description>
  </S.Wrapper>
);

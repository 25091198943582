import { Alert as AlertBase } from 'antd';
import styled from 'styled-components';

const Alert = styled(AlertBase)`
  margin-bottom: ${({ theme }) => theme.spacing.xmedium};

  .ant-alert-message {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: pre;
  }

  svg {
    margin-right: ${({ theme }) => theme.spacing.xsmall};
  }
`;

export default { Alert };

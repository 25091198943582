import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import anime from 'animejs';
import { useEffect } from 'react';
import S from './animated-logo.styles';

export const AnimatedLogo = () => {
  const {
    deviceData: { isMobile },
  } = useDeviceDetect();

  const basicConfig = {
    duration: 650,
    easing: 'linear',
  };

  useEffect(() => {
    const tlFirst = anime.timeline({ ...basicConfig, targets: '#first' });
    const tlSecond = anime.timeline({ ...basicConfig, targets: '#second' });
    const tlThird = anime.timeline({ ...basicConfig, targets: '#third' });
    const tlFourth = anime.timeline({ ...basicConfig, targets: '#fourth' });
    const tlFifth = anime.timeline({ ...basicConfig, targets: '#fifth' });
    const tlConnectd = anime.timeline({ targets: '#connectd' });

    tlFirst.add({
      rotate: '50deg',
      scale: 0.6,
      translateY: 850,
      translateX: -220,
    });

    tlSecond.add({
      rotate: '25deg',
      translateX: 330,
      translateY: -120,
      scale: 0.9,
    });

    tlThird.add({
      keyframes: [{ opacity: 0.3 }, { opacity: 0.5 }],
      translateX: 250,
      translateY: -70,
      scale: 0.6,
    });

    tlFourth.add({
      keyframes: [{ opacity: 0.3 }, { opacity: 0.5 }],
      translateX: -200,
      translateY: -200,
      scale: 0.7,
    });

    tlFifth.add({
      translateY: -200,
    });

    tlFirst.add({
      scale: 0.2717,
      translateY: 1700,
      translateX: -300,
    });

    tlSecond.add({
      scale: 0.2717,
      translateX: 420,
      translateY: -240,
    });

    tlThird.add({
      keyframes: [{ opacity: 0.7 }, { opacity: 0.9 }, { opacity: 1 }],
      rotate: '45deg',
      scale: 0.2717,
      translateX: 825,
      translateY: -330,
    });

    tlFourth.add({
      keyframes: [{ opacity: 0.7 }, { opacity: 0.9 }, { opacity: 1 }],
      scale: 0.2717,
      translateX: -560,
      translateY: -270,
    });

    tlFifth.add({
      rotate: '-60deg',
      scale: 0.3976,
      translateY: -645,
      translateX: -450,
    });

    tlFirst.add({
      rotate: '+=10deg',
      translateY: '+=106',
      translateX: '+=380',
    });

    tlSecond.add({
      rotate: '+=20deg',
      translateX: '-=74',
      translateY: '-=122',
    });

    tlThird.add({
      translateX: '+=20',
    });

    tlFourth.add({
      translateX: '-=32',
      translateY: '-=14',
    });

    tlFifth.add({
      translateY: '-=19',
      translateX: '-=30',
    });

    tlConnectd.add({
      targets: '#connectd path',
      opacity: 0,
      duration: 0,
      easing: 'linear',
      translateX: 1220,
      translateY: 300,
    });

    tlConnectd.add({
      targets: '#connectd path',
      translateX: 965,
      translateY: 300,
      duration: 1250,
      easing: 'easeInOutQuad',
      delay: 2000,
      opacity: 1,
    });

    anime({
      targets: '#animated-logo',
      scale: isMobile ? 2 : 1.2,
      translateY: isMobile ? '30px' : '90px',
      duration: 0,
    });

    anime({
      targets: '#animated-logo',
      translateX: '-18%',
      delay: 3250,
      duration: 1500,
    });

    return () => {
      anime.remove('#first');
      anime.remove('#second');
      anime.remove('#third');
      anime.remove('#fourth');
      anime.remove('#connectd');
      anime.remove('#animated-logo');
      anime.remove('#connectd path');
    };
  }, [isMobile]);

  return <S.Logo />;
};

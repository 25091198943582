import type { SharedDashboard } from '@pages/content/pulse/parts/dashboards/parts/dashboard/submenu/parts/share-dashboard/share-dashboards';
import { axiosInstance } from '@utils/axios/axios-instance';
import type { AxiosResponse } from '@utils/axios/types';

export const updatePreviewReport = ({
  dashboardId,
  password,
}: {
  dashboardId: string;
  password: string | null;
}): Promise<AxiosResponse<SharedDashboard>> =>
  axiosInstance.patch(`/pulse/dashboards/${dashboardId}/preview-endpoint`, {
    password,
  });

import styled from 'styled-components';

const Wrapper = styled.div`
  width: 240px;
  height: 225px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.medium};
  border: 1px solid ${({ theme }) => theme.baseColors.Blue100};
  border-radius: 8px;
  text-align: center;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.xsmall}) {
    width: 200px;
    padding: ${({ theme }) => theme.spacing.xmedium};
  }
`;

const CompanyName = styled.span`
  font-size: ${({ theme }) => theme.fontSize.xmedium};
  display: block;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  letter-spacing: 0.3px;
  color: ${({ theme }) => theme.color.fontPrimary};
`;

export default { Wrapper, CompanyName };

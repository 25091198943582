import { OneLineEllipsis } from '@parts/one-line-ellipsis/one-line-ellipsis';
import { TableColumn } from '@parts/table-column/table-column';
import type { ColumnsType } from 'antd/lib/table';
import type { PreviousInvestmentWithId } from '../../../../investment';
import S from './table.styles';

interface InvestmentColumns {
  onEditRow: (row: PreviousInvestmentWithId) => void;
  onRemoveRow: (row: PreviousInvestmentWithId) => void;
  translations: { [key: string]: string };
  disableActions?: boolean;
  hideActionsColumn?: boolean;
}

export const Columns = ({
  onEditRow,
  onRemoveRow,
  translations,
  disableActions = false,
  hideActionsColumn = false,
}: InvestmentColumns): ColumnsType<PreviousInvestmentWithId> => {
  const cols: ColumnsType<PreviousInvestmentWithId> = [
    {
      title: translations.companyName,
      dataIndex: 'company',
      key: 'company',
      width: '25%',
      render: (name: string) => (
        <TableColumn title={translations.companyName}>
          <OneLineEllipsis>{name}</OneLineEllipsis>
        </TableColumn>
      ),
    },
    {
      title: translations.notes,
      dataIndex: 'note',
      key: 'note',
      width: '65%',
      render: (note: string) => (
        <TableColumn title={translations.notes}>
          <OneLineEllipsis>{note}</OneLineEllipsis>
        </TableColumn>
      ),
    },
    {
      ...(hideActionsColumn
        ? {}
        : {
            title: '',
            dataIndex: '',
            key: 'controls',
            render: (_, row) => (
              <S.Controls>
                <S.EditRowIcon data-testid="edit-icon" onClick={() => disableActions === false && onEditRow(row)} />
                <S.RemoveRowIcon data-testid="trash" onClick={() => disableActions === false && onRemoveRow(row)} />
              </S.Controls>
            ),
          }),
    },
  ];

  return cols;
};

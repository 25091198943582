import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_NUMERIC_METRIC_SCALE_QUERY_CACHE_KEY = (metricId: string) => `get-numeric-metric-scale-${metricId}`;

export interface NumericMetricScaleData {
  scale: number;
}

export const getNumericMetricScaleAction =
  (id: string) => async (): Promise<GraphqlResponse<NumericMetricScaleData>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
      query getNumericMetricScale {
        metricMetadata(metricId: "${id}") {
          scale,
        }
      }
    `,
    });

    return graphqlResponseWrapper(data, ['metricMetadata']);
  };

import { Button as ButtonBase } from 'antd';
import styled from 'styled-components';

const Wrapper = styled(ButtonBase)`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.color.submitButton};
  font-size: ${({ theme }) => theme.fontSize.regular};
  padding: 0;
  height: fit-content !important;
  margin-top: ${({ theme }) => theme.spacing.base};
`;

export default { Wrapper };

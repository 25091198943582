import { V2NotificationIndicator } from '@/components/ui/v2-notification-indicator/v2-notification-indicator';
import { useAdvisorInvitesCount } from '@/modules/advisor/connections/hooks/use-get-advisor-invites-count';
import { isActiveInvestor, isActiveNed, isInvestorWaitingForApproval, useUserAbility } from '@abilities';
import { AbilityAction, AbilitySubject } from '@abilities/ability/types';
import UserRegular from '@assets/icons/user-regular.svg?react';
import { communityAdvisorLink } from '@constants/links';
import { ActiveLayout } from '@layout/active/active.layout';
import { DropMenu, type ItemWithIcon } from '@layout/parts/menu-dropdown/menu-dropdown';
import { Routes } from '@router/routes';
import { lensBrowseKey, pulseCompaniesKey, pulseTriggersKey, rolesAppliedKey, rolesBrowseKey } from '@router/tabs-keys';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import type { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

export const NedLayout = ({
  children,
  legacyContainer = true,
}: {
  children?: ReactNode | ReactNode[];
  legacyContainer?: boolean;
}) => {
  const location = useLocation();
  const userAbility = useUserAbility();
  const { invitesCount } = useAdvisorInvitesCount();
  const {
    state: { products },
  } = useUserAccount();

  const [myProfile, roles, nedAcademy, lens, legalLabel, community, portfolioLabel, pulse, connections] =
    useTranslation([
      'navigation.myProfile',
      'navigation.roles',
      'navigation.academy',
      'navigation.lens',
      'navigation.legal',
      'navigation.community',
      'navigation.portfolio',
      'navigation.pulse',
      'navigation.connections',
    ]);

  const profileMenu: ItemWithIcon[] = [{ route: Routes.PROFILE, label: myProfile, icon: <UserRegular /> }];

  const isActiveNedStatus = isActiveNed(products);
  const isInvestorWaitingForApprovalStatus = isInvestorWaitingForApproval(products);
  const isActiveInvestorStatus = isActiveInvestor(products);

  const hasPermissionsToAdvisorCommunity = userAbility.can(AbilityAction.ACCESS, AbilitySubject.ADVISOR_COMMUNITY);
  const hasPermissionsToPortfolio = userAbility.can(AbilityAction.ACCESS, AbilitySubject.PORTFOLIO);
  const hasPermissionsToLegalDocs = userAbility.can(AbilityAction.ACCESS, AbilitySubject.LEGAL_DOCS);
  const hasAccessToNedAcademy = userAbility.can(AbilityAction.ACCESS_PAGE, Routes.ACCESS_NED_ACADEMY);

  return (
    <ActiveLayout
      legacyContainer={legacyContainer}
      menu={[
        {
          route: Routes.ADVISOR_CONNECTIONS,
          label: (
            <V2NotificationIndicator count={invitesCount} mode="dot" position="top-right" dotPlacementMode="outside">
              {connections}
            </V2NotificationIndicator>
          ),
        },
        {
          route: communityAdvisorLink as Routes,
          label: community,
          isExternalLink: true,
          disabled: !hasPermissionsToAdvisorCommunity,
          showTooltip: !hasPermissionsToAdvisorCommunity,
        },
        ...(isActiveInvestorStatus || isInvestorWaitingForApprovalStatus
          ? [
              ...(hasPermissionsToPortfolio
                ? [
                    {
                      route: Routes.PORTFOLIO,
                      label: portfolioLabel,
                      disabled: isInvestorWaitingForApprovalStatus,
                    },
                  ]
                : []),
              {
                route: Routes.PULSE,
                label: pulse,
                defaultParams: { content: pulseCompaniesKey },
                allowedParamValues: { content: [pulseCompaniesKey, pulseTriggersKey] },
                disabled: isInvestorWaitingForApprovalStatus,
              },
            ]
          : []),
        ...(isActiveNedStatus
          ? [
              {
                route: Routes.LENS,
                label: lens,
                defaultParams: { action: lensBrowseKey },
                allowedParamValues: { action: [lensBrowseKey] },
              },
              {
                route: Routes.ROLES,
                label: roles,
                defaultParams: { content: rolesBrowseKey },
                allowedParamValues: { content: [rolesBrowseKey, rolesAppliedKey] },
              },
              ...(hasPermissionsToLegalDocs ? [{ route: Routes.LEGAL, label: legalLabel }] : []),

              {
                route: Routes.ACCESS_NED_ACADEMY,
                label: nedAcademy,
                disabled: !hasAccessToNedAcademy,
              },
            ]
          : []),
      ]}
      profileMenu={profileMenu}
      locationPathName={location.pathname}
      dropMenu={<DropMenu menu={profileMenu} />}
    >
      {children}
    </ActiveLayout>
  );
};

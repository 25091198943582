import { isActiveInvestor } from '@abilities';
import { Roles } from '@domain/accounts/roles';
import { InvestorNedLayout } from '@layout/investor-ned/investor-ned.layout';
import { NotFoundPage } from '@pages/utility/not-found/not-found.page';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { FounderLensPage } from './founder/founder-lens.page';
import { InvestorLensPage } from './investor/investor-lens.page';
import { NedLensPage } from './ned/ned-lens.page';

export const LensPage = () => {
  const {
    state: { userRole, products },
  } = useUserAccount();

  switch (userRole) {
    case Roles.FOUNDER:
      return <FounderLensPage />;
    case Roles.INVESTOR:
      return <InvestorLensPage />;
    case Roles.INVESTOR_NED:
      if (isActiveInvestor(products)) {
        return <InvestorLensPage Layout={InvestorNedLayout} />;
      }

      return <NedLensPage Layout={InvestorNedLayout} />;
    case Roles.NED:
      return <NedLensPage />;
    default:
      return <NotFoundPage />;
  }
};

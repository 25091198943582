import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export enum ERoleApplicationStatus {
  APPROVED = 'Approved',
  REDJECTED = 'Rejected',
  WAITTING_FOR_APPROVAL = 'WaitingForApproval',
}

export interface RoleApplicationsResponse {
  avatarUrl: string;
  name: string;
  location: string;
  appliedAt: string;
  resumeUrl: string;
  status: ERoleApplicationStatus;
  nedId: string;
  id: string;
}

export const GET_ROLE_APPLICATIONS_CACHE_KEY = 'get-role-applications';

export const getRoleApplicationsAction =
  (roleId: string) => async (): Promise<GraphqlResponse<RoleApplicationsResponse[]>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
      query getRoleApplicationsAction {
        previewRoleApplications(roleId: "${roleId}") {
          avatarUrl,
          name,
          location,
          appliedAt,
          resumeUrl,
          status,
          nedId,
          id
        }
      }
    `,
    });

    return graphqlResponseWrapper(data, ['previewRoleApplications']);
  };

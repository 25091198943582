import type { ChangeEvent } from 'react';
import { useV2NoteTileContext } from '../v2-note-tile-context';
import S from '../v2-note-tile.styles';

type Props = {
  onChange: (value: ChangeEvent<HTMLTextAreaElement>) => void;
  disabled: boolean;
  name: string;
  value?: string;
  placeholder?: string;
  ['data-testid']?: string;
};

export const Content = ({
  onChange,
  disabled,
  'data-testid': dataTestId = 'v2-note-tile-content',
  value,
  placeholder,
  name,
  ...props
}: Props) => {
  const { content } = useV2NoteTileContext();

  return (
    <S.Content
      {...props}
      onChange={onChange}
      disabled={disabled}
      name={name}
      rows={4}
      data-testid={dataTestId}
      value={value ?? content}
      placeholder={placeholder}
    />
  );
};

import { useRef } from 'react';

export const useDelayedSubmit = (handleChange: Function, onSubmit: Function) => {
  const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const onChange = (e: React.ChangeEvent, additionalParam?: string) => {
    handleChange(e);

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      onSubmit(e, additionalParam);
    }, 1000);
  };

  return { onChange };
};

export default useDelayedSubmit;

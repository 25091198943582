import { NotificationContent } from '@/parts/notifications/notification-content';
import { useHistory } from '@/utils/hooks/use-history/use-history';
import type { Notification } from '@context/notifications/notifications.reducer';
import {
  useNotificationMarkAsRead,
  useNotificationRedirectUrl,
} from '@utils/hooks/use-notifications/use-notifications';
import { DateTime } from 'luxon';

import S from './notification-item.styles';

export const NotificationItem = ({ item }: { item: Notification }) => {
  const history = useHistory();
  const redirectUrl = useNotificationRedirectUrl(item);
  const { markAsRead } = useNotificationMarkAsRead({ notification: item });

  const handleClick = async () => {
    await markAsRead();
    if (redirectUrl) history.push(redirectUrl);
  };

  return (
    <S.Item read={item.read} onClick={handleClick}>
      <S.Avatar size={40} avatarUrl={item.iconUrl} />
      <S.TextWrapper>
        <S.Content>
          <NotificationContent item={item} />
        </S.Content>
        <S.Time>{DateTime.fromISO(item.timestamp).toRelative()}</S.Time>
      </S.TextWrapper>
    </S.Item>
  );
};

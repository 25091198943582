import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[50], theme.v2.colors.blue[700])};
  border: 1px solid ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[100], theme.v2.colors.blue[600])};

  > *:nth-last-child(2) {
    flex-grow: 1;
  }
`;

const Section = styled.div`
  padding: 18px;
  text-decoration: none;

  &:not(:last-child) {
    border-bottom: 1px solid
      ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[100], theme.v2.colors.blue[600])};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.v2.colors.blue[300]};
  gap: 16px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const ParagraphWrapper = styled.div`
  max-height: 200px;
  overflow: hidden;
  position: relative;
`;

const Paragraph = styled.div`
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[500], theme.v2.colors.neutral[300])};
  font-weight: ${({ theme }) => theme.v2.typography.weights.regular};
  font-size: ${({ theme }) => theme.v2.typography.sizes.sm};
  max-width: 100%;
  word-break: break-word;
  white-space: pre-wrap;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-weight: ${({ theme }) => theme.v2.typography.weights.regular};
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[500], theme.v2.colors.neutral[300])};
  font-size: ${({ theme }) => theme.v2.typography.sizes.sm};
`;

const ListElement = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 8px;
`;

const Gradient = styled.div`
  width: 100%;
  height: 128px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(transparent, white);
`;

export default { Card, Section, Row, Actions, ParagraphWrapper, Paragraph, List, ListElement, Gradient };

import CheckboxFormikFieldBase from '@pages/content/profile/parts/checkbox-formik-field/checkbox-formik-field';
import { Section as SectionBase } from '@parts/section/section';
import styled from 'styled-components';

const Section = styled(SectionBase)`
  padding: ${({ theme }) => theme.spacing.xmedium};
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const CheckboxFormikField = styled(CheckboxFormikFieldBase)`
  .ant-checkbox-wrapper {
    margin-right: ${({ theme }) => theme.spacing.small};
  }

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    margin-top: 22px;
  }
`;

export default { Section, CheckboxFormikField };

import type { FundingRound } from '@pages/content/pulse/founder/parts/progress/parts/funding-rounds/funding-rounds';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export interface CompanyPreview {
  fundingRounds: FundingRound[];
  name: string;
  avatarUrl: string | null;
  followersCount: number;
}

export const GET_COMPANY_PREVIEW_CACHE_KEY = 'get-company-preview';

export const getCompanyPreview = (founderId: string) => async (): Promise<GraphqlResponse<CompanyPreview>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getCompanyPreview {
        previewCompany(founderId: "${founderId}") {
          name
          avatarUrl
          followersCount
          fundingRounds {
            id
            amountRaised
            amountSought
            date
            status
            type
            useOfFunds
            valuation
          }
        }
      }
    `,
  });

  return graphqlResponseWrapper(data, ['previewCompany']);
};

/* eslint-disable @typescript-eslint/no-use-before-define */
import { Roles } from '@domain/accounts/roles';
import AvatarWithName from '@parts/avatar-with-name/avatar-with-name';
import EditorDrawer from '@parts/editor-drawer/editor-drawer';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { useQuery } from '@tanstack/react-query';
import { useInvestmentsEditor } from '@utils/hooks/use-investments-editor/use-investments-editor';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { Any } from 'src/types';
import { EditorDetails } from '../../../../parts/editor-details/editor-details';
import { addConnectionAction } from '../../api/add-connection.action';
import { getInvestmentAction, GET_INVESTMENT_CACHE_KEY } from '../../api/get-investment.action';
import { updateInvestmentAction } from '../../api/update-investment.action';
import type { Investment, InvestmentCompany } from './editor-interfaces';
import { useAllFundingRoundTypes } from './hooks/use-all-funding-round-types';
import { useValidationSchema } from './validation-schema';

interface FormikState extends InvestmentCompany {
  investingRelationshipId: string;
  investments: Investment[];
}

const emptyState = { company: {}, investingRelationshipId: null, investments: [] };

export const UpdateEditor = ({
  drawer: Drawer,
  hideDrawer,
  refetch,
  investmentId = '',
}: {
  drawer: Function;
  hideDrawer: Function;
  refetch: Function;
  investmentId: string;
}) => {
  const [titleLabel] = useTranslation(['portfolio.investor.drawer.update']);

  const { allFundingRoundTypesResponse } = useAllFundingRoundTypes();

  const { data: investmentData, isLoading } = useQuery(
    [GET_INVESTMENT_CACHE_KEY(investmentId)],
    getInvestmentAction(investmentId),
    {
      enabled: Boolean(investmentId),
    },
  );

  const allFundingRoundTypes = allFundingRoundTypesResponse?.data?.type || [];

  const initialValues = investmentData?.data
    ? {
        company: investmentData.data.company,
        investingRelationshipId: investmentData.data.investingRelationshipId,
        investments: [investmentData.data],
      }
    : emptyState;

  const onClear = () => {
    setValues(emptyState);
  };

  const onSubmit = (v: Any) => {
    const {
      id: investId,
      company,
      round: { isEditable, ...roundRest },
      ...rest
    } = v.investments[0];
    return update({ ...rest, round: roundRest });
  };

  const {
    update,
    createdFundingRounds,
    onClose,
    deleteInvestment,
    formik: {
      setFieldValue,
      getFieldProps,
      getFieldMeta,
      submitForm,
      values: formikValues,
      setValues,
      setFieldTouched,
      dirty,
      isValid,
      submitCount,
      isSubmitting,
    },
  } = useInvestmentsEditor({
    onClear,
    hideDrawer,
    initialValues,
    onSubmit,
    validationSchema: useValidationSchema,
    createAction: addConnectionAction,
    updateAction: updateInvestmentAction(investmentId),
    trackedPageParam: 'page',
    closeRefetch: refetch,
  });

  const values = formikValues as FormikState;
  const isInternal = values.company?.type === 'Internal';

  return (
    <EditorDrawer
      drawer={Drawer}
      search={
        values.company?.name && <AvatarWithName avatarUrl={values.company.avatarUrl} name={values.company.name} />
      }
      title={titleLabel}
      onClose={onClose}
      isLoading={isLoading}
      footer={{
        onConfirm: submitForm,
        disabledEvaluation: {
          dirty,
          submitCount,
          isValid: isValid && Boolean(values.investments.length),
          isSubmitting,
        },
        onCancel: onClose,
      }}
    >
      {values.company?.name && (
        <>
          {!createdFundingRounds.isFundingRoundsApiCalled ? (
            <FullHeightSpinner small />
          ) : (
            <EditorDetails
              index={0}
              internalEntityId={isInternal ? investmentId : ''}
              foundingRounds={{
                registered: createdFundingRounds.data || [],
                externalTypes: allFundingRoundTypes,
                activeRegistered: [],
              }}
              role={Roles.INVESTOR}
              deleteInvestment={deleteInvestment}
              setFieldValue={setFieldValue}
              getFieldProps={getFieldProps}
              getFieldMeta={getFieldMeta}
              setFieldTouched={setFieldTouched}
            />
          )}
        </>
      )}
    </EditorDrawer>
  );
};

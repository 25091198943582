import type { InvestorDocumentsForCompany } from '@pages/content/company-details/api/get-investor-documents-for-company/get-investor-documents-for-company';
import { Table } from '@parts/table/table';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { ColumnsType } from 'antd/lib/table';
import type { FieldInputProps, FieldMetaProps } from 'formik';
import type { ChangeEventHandler, ReactNode } from 'react';
import DocumentsColumn from '../columns/columns';

export interface ExtendedInvestorDocumentsForCompany extends InvestorDocumentsForCompany {
  component?: ReactNode;
}

const DocumentsTable = ({
  data,
  getFieldProps,
  getFieldMeta,
  onChange,
  showRemovingConfirmation,
}: {
  data: ExtendedInvestorDocumentsForCompany[];
  getFieldProps: (name: string) => FieldInputProps<string>;
  getFieldMeta: (name: string) => FieldMetaProps<string>;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  showRemovingConfirmation: Function;
}) => {
  const [uploadedLabel, nameLabel, noteLabel, addNoteLabel] = useTranslation([
    'portfolio.investor.documents.table.uploaded',
    'portfolio.investor.documents.table.name',
    'portfolio.investor.documents.table.note',
    'portfolio.investor.documents.table.addNote',
  ]);

  return (
    <Table
      dataSource={data}
      columns={
        DocumentsColumn({
          t: {
            uploadedLabel,
            nameLabel,
            noteLabel,
            addNoteLabel,
          },
          getFieldProps,
          getFieldMeta,
          onChange,
          showRemovingConfirmation,
        }) as ColumnsType<object>
      }
      rowKey="id"
    />
  );
};

export default DocumentsTable;

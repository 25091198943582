import { useReducer, type ReactNode, type Reducer } from 'react';
import { FundingRoundsContext, fundingRoundsInitialState } from './funding-rounds.context';
import { fundingRoundsReducer, type FundingRoundsActionType, type FundingRoundsState } from './funding-rounds.reducer';

interface FundingRoundsStateProviderProps {
  children: ReactNode;
}

export const FundingRoundsStateProvider = ({ children }: FundingRoundsStateProviderProps) => {
  const [state, dispatch] = useReducer<Reducer<FundingRoundsState, FundingRoundsActionType>>(
    fundingRoundsReducer,
    fundingRoundsInitialState,
  );

  return <FundingRoundsContext.Provider value={{ state, dispatch }}>{children}</FundingRoundsContext.Provider>;
};

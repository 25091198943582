import Aisle3Logo from '@assets/images/advisor-testimonials/aisle-3.webp';
import BeyondBloodLogo from '@assets/images/advisor-testimonials/beyond-blood-diagnostics.webp';
import BuildScanLogo from '@assets/images/advisor-testimonials/build-scan.webp';
import TheTrainingLogo from '@assets/images/advisor-testimonials/the-training-marketplace.webp';
import { bookConsultationLink, findingTalentLink } from '@constants/links';
import { FounderLayout as Layout } from '@layout/founder/founder.layout';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { AdvisorTilesSection } from './parts/advisor-tiles/advisor-tiles-section';
import { BookConsultation } from './parts/book-consultation/book-consultation';
import { AdvisorsCarousel } from './parts/carousel/advisors-carousel';
import { AdvisorsHeadingSection } from './parts/heading/heading';
import { PostRole } from './parts/post-role/post-role';
import type { ProfileDetails } from './parts/profile-tile/profile-tile';
import { SuccessStoriesHeading } from './parts/success-stories-heading/success-stories-heading';

export const AdvisorsPage = () => {
  const [BuildScanDesc, TheTrainingDesc, BeyondBloodDesc, Aisle3Desc] = useTranslation([
    'advisors.testimonialCarousel.BuildScan.description',
    'advisors.testimonialCarousel.TheTraining.description',
    'advisors.testimonialCarousel.BeyondBlood.description',
    'advisors.testimonialCarousel.Aisle3.description',
  ]);

  const testimonial: ProfileDetails[] = [
    {
      companyName: 'BuildScan',
      description: BuildScanDesc,
      companyImgUrl: BuildScanLogo,
    },
    {
      companyName: 'The Training Marketplace',
      description: TheTrainingDesc,
      companyImgUrl: TheTrainingLogo,
    },
    {
      companyName: 'Beyond Blood Diagnostics',
      description: BeyondBloodDesc,
      companyImgUrl: BeyondBloodLogo,
    },
    {
      companyName: 'Aisle 3',
      description: Aisle3Desc,
      companyImgUrl: Aisle3Logo,
    },
  ];

  return (
    <Layout>
      <AdvisorsHeadingSection />
      <AdvisorTilesSection />
      <PostRole url={findingTalentLink} />
      <BookConsultation url={bookConsultationLink} />
      <SuccessStoriesHeading />
      <AdvisorsCarousel items={testimonial} />
    </Layout>
  );
};

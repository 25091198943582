import { ItemsGrid } from '@parts/items-grid/items-grid';
import { Company } from './parts/company/company';

export const CompaniesGrid = ({
  refetch,
  items,
  isLoading,
}: {
  refetch: Function;
  items: (object & {
    id: string;
  })[];
  isLoading: boolean;
}) => <ItemsGrid keyBase="connected" Item={Company} itemProps={{ refetch }} data={items} isLoading={isLoading} />;

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import { mapQueryStringToGraphqlParams } from '@utils/fns/map-query-string-to-graphql-params';
import type { ParsedQuery } from 'query-string';
import type { GraphqlResponse } from 'src/types';

export interface SmartMatchSuggestionForFounderItems {
  id: string;
  level: string;
  customerGroup: string[];
  description: string;
  industry: string;
  investmentStage: string;
  joinedAt: string;
  name: string;
  productStage: string;
  avatarUrl: string;
  followRequestId: string | null;
  location: string;
  maximumInvestmentAmount: number | null;
  minimumInvestmentAmount: number | null;
  followInvitationId: string;
}

export interface GetFounderSmartMatchSuggestions {
  items: SmartMatchSuggestionForFounderItems[];
  total: number;
}

export const GET_FOUNDER_SMART_MATCH_SUGGESTION_CACHE_KEY = 'get-founder-smart-match-suggestions';

export const getFounderSmartMatchSuggestion =
  (start: number, limit: number, excludedParams: string[], otherParams?: ParsedQuery<string>) =>
  async (): Promise<GraphqlResponse<GetFounderSmartMatchSuggestions>> => {
    const acceptedParams = ['page', 'regions', 'countriesOfResidence'];

    const filteredParams = Object.keys(otherParams ?? {}).reduce<ParsedQuery<string>>((acc, key) => {
      if (acceptedParams.includes(key) && otherParams) {
        acc[key] = otherParams[key];
      }
      return acc;
    }, {});

    const params = filteredParams && mapQueryStringToGraphqlParams(filteredParams, excludedParams, false);

    const queryName = `smartMatchSuggestionForFounder(start: ${start}, limit: ${limit}${params?.supplement} )`;

    const { data } = await axiosInstance.post('/query', {
      variables: params?.variables,
      query: `
      query getFounderSmartMatchSuggestion ${params?.arguments} {
        ${queryName} {
            items {
                avatarUrl
                id
                level
                description
                industry
                investmentStage
                joinedAt
                location
                maximumInvestmentAmount
                minimumInvestmentAmount
                name
                connectRequestId: followInvitationId
                customerGroup
            }
          total
      }
    }`,
    });

    return graphqlResponseWrapper(data, ['smartMatchSuggestionForFounder']);
  };

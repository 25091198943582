import { Line as LineBase } from '@pages/content/parts/metrics/parts/line/line';
import { theme } from '@styles/theme-config';
import styled from 'styled-components';

const Wrapper = styled.div`
  background: ${theme.baseColors.White};
  color: ${theme.baseColors.Grey100};
  height: auto;
  display: flex;
  width: 220px;
`;

const Date = styled.div`
  text-align: center;
  padding: ${theme.spacing.small};
  border-bottom: 1px solid #dbe0ee;
  width: 100%;
`;

const Item = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin: ${theme.spacing.small} 0;
  width: 100%;
  padding: 0 ${theme.spacing.small};
`;

const Label = styled.div`
  display: flex;
  flex-wrap: 0;
  align-items: center;
`;

const Number = styled.span`
  font-weight: ${theme.fontWeight.semibold};
  margin-left: ${theme.spacing.small};
`;

const Note = styled.div`
  border-top: 1px solid #dbe0ee;
  padding: ${theme.spacing.small};
  white-space: pre-wrap;
  width: 100%;
  color: ${theme.baseColors.Grey200};
`;

const Line = styled(LineBase)`
  margin-right: ${theme.spacing.small};
`;

export default { Wrapper, Date, Item, Number, Note, Label, Line };

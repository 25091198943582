import { useUserAbility } from '@abilities';
import { AbilityAction, AbilitySubject } from '@abilities/ability/types';
import UserRegular from '@assets/icons/user-regular.svg?react';
import { communityLink } from '@constants/links';
import { ActiveLayout } from '@layout/active/active.layout';
import { DropMenu, type ItemWithIcon } from '@layout/parts/menu-dropdown/menu-dropdown';
import { InvestorRiskWarning } from '@parts/investor-risk-warning/investor-risk-warning';
import { Routes } from '@router/routes';
import {
  lensBrowseCompaniesKey,
  lensInvitesKey,
  lensSmartMatchKey,
  pulseCompaniesKey,
  pulseTriggersKey,
} from '@router/tabs-keys';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

export const InvestorLayout = ({
  children,
  legacyContainer = true,
}: {
  children?: ReactNode | ReactNode[];
  legacyContainer?: boolean;
}) => {
  const location = useLocation();
  const userAbility = useUserAbility();

  const [pulse, lens, myProfile, portfolioLabel, community] = useTranslation([
    'navigation.pulse',
    'navigation.lens',
    'navigation.myProfile',
    'navigation.portfolio',
    'navigation.community',
  ]);

  const profileMenu: ItemWithIcon[] = [{ route: Routes.PROFILE, label: myProfile, icon: <UserRegular /> }];
  const hasPermissionsToPortfolio = userAbility.can(AbilityAction.ACCESS, AbilitySubject.PORTFOLIO);

  return (
    <ActiveLayout
      menu={[
        { route: communityLink as Routes, label: community, isExternalLink: true },
        {
          route: Routes.LENS,
          label: lens,
          defaultParams: { action: lensSmartMatchKey },
          allowedParamValues: { action: [lensSmartMatchKey, lensBrowseCompaniesKey, lensInvitesKey] },
        },
        ...(hasPermissionsToPortfolio
          ? [
              {
                route: Routes.PORTFOLIO,
                label: portfolioLabel,
              },
            ]
          : []),
        {
          route: Routes.PULSE,
          label: pulse,
          defaultParams: { content: pulseCompaniesKey },
          allowedParamValues: { content: [pulseCompaniesKey, pulseTriggersKey] },
        },
      ]}
      profileMenu={profileMenu}
      locationPathName={location.pathname}
      legacyContainer={legacyContainer}
      dropMenu={<DropMenu menu={profileMenu} />}
      infoStickyBannerTop={<InvestorRiskWarning />}
    >
      {children}
    </ActiveLayout>
  );
};

import { Drawer as DrawerBase } from 'antd';
import styled from 'styled-components';

const Drawer = styled(DrawerBase)`
  .ant-drawer-header {
    background-color: ${({ theme }) => theme.color.dashboardBackground};
    padding-right: ${({ theme }) => theme.spacing.base};

    .ant-drawer-close {
      padding: 16px;

      .anticon.anticon-close-circle {
        font-size: 23px;
        color: ${({ theme }) => theme.color.primary};
      }
    }
  }

  .ant-drawer-mask {
    background-color: ${({ theme }) => theme.color.maskBg};
  }

  .ant-drawer-title {
    margin-top: 0;
    text-align: left;
    color: ${({ theme }) => theme.color.primary};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
  }
`;

export default { Drawer };

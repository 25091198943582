import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled, { css } from 'styled-components';

const Section = styled.section`
  background: ${({ theme }) => theme.color.sectionBackground};
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: ${({ theme }) => theme.radius.medium};
  padding: ${({ theme }) => theme.spacing.base};
  margin: 0 0 ${({ theme }) => theme.spacing.medium} 0;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    padding: ${({ theme }) => theme.spacing.xmedium};
  }
`;

const Header = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-shrink: 1;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) {
    flex-direction: column;
  }
`;

const TitleHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;
`;

const Title = styled.h3`
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.neutral[50])};
  font-weight: ${({ theme }) => theme.v2.typography.weights.bold};
  line-height: ${({ theme }) => theme.v2.typography.lineHeights.button};
  ${({ theme }) =>
    customMediaQuery('font-size', {
      xs: theme.v2.typography.sizes.xl,
      sm: theme.v2.typography.sizes['2xl'],
    })}
`;

const CornerAddition = styled.div<{ $spaced: boolean; $width: number }>`
  ${({ $spaced }) =>
    $spaced &&
    css`
      @media (max-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) {
        margin-top: ${({ theme }) => theme.spacing.medium};
      }
    `}

  margin-left: auto;

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) {
    flex-basis: ${({ $width }) => $width}px;
  }
`;

const SubTitle = styled.h4`
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme }) => theme.color.fontSecondary};
  margin-top: 20px;
`;

export default { Section, Header, Title, SubTitle, TitleHolder, CornerAddition };

import { ShimmerBadge } from '@parts/shimmer-badge/shimmer-badge';
import S from './previous-position-filter.styles';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';

export const PreviousPositionsFilter = () => {
  const [previousPositionsLabel, zeroToThreeLabel, threeToFiveLabel, fivePlusLabel, comingSoonLabel] = useTranslation([
    'advisors.search.filters.radio.previousPositions',
    'advisors.search.filters.radio.previousPositions.value.zeroToThree',
    'advisors.search.filters.radio.previousPositions.value.threeToFive',
    'advisors.search.filters.radio.previousPositions.value.fivePlus',
    'badges.comingSoon',
  ]);

  // TODO - values should be changed to proper values when options will be enabled
  const radioOptions = [
    { value: '0-3', label: zeroToThreeLabel },
    { value: '3-5', label: threeToFiveLabel },
    { value: '5+', label: fivePlusLabel },
  ];

  return (
    <div>
      <S.Wrapper>
        <S.Span>{previousPositionsLabel}</S.Span>
        <S.ShimmerBadgeWrapper>
          <ShimmerBadge label={comingSoonLabel} />
        </S.ShimmerBadgeWrapper>
      </S.Wrapper>
      <S.RadioGroup defaultValue="0-3" disabled>
        {radioOptions.map((option) => (
          <S.Radio key={option.value} value={option.value}>
            {option.label}
          </S.Radio>
        ))}
      </S.RadioGroup>
    </div>
  );
};

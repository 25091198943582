import { Checkbox as CheckboxBase } from 'antd';
import styled from 'styled-components';

export const Checkbox = styled(CheckboxBase)<{ $checked: boolean; $visible: boolean; $lighterBackground: boolean }>`
  padding: 8px 16px;
  border: 1px solid ${({ theme }) => theme.onboardingColors.buttonBorder};
  background: ${({ theme, $lighterBackground, $checked }) => {
    if ($lighterBackground && !$checked) {
      return theme.onboardingColors.inputBorder;
    }
    if ($checked) {
      return theme.onboardingColors.buttonBorder;
    }
    return 'transparent';
  }};
  border-radius: 20px;
  cursor: pointer;
  margin: 4px !important;
  display: ${({ $visible }) => ($visible ? 'inline-block' : 'none')};

  .ant-checkbox-inner {
    display: none;
  }

  span {
    color: ${({ $checked, theme }) => ($checked ? theme.baseColors.White : theme.onboardingColors.buttonBorder)};
  }
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.onboardingColors.buttonText};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export default { Checkbox, Text };

import LinkedinIcon from '@assets/icons/linkedin-logo.svg?react';
import styled from 'styled-components';

const LinkedinOutlined = styled(LinkedinIcon)`
  width: auto;
  height: 100%;
  color: ${({ theme }) => theme.baseColors.Blue200};

  path {
    fill: ${({ theme }) => theme.baseColors.Blue200};
  }
`;

export default { LinkedinOutlined };

import { useHistory } from '@/utils/hooks/use-history/use-history';
import { useQueryParams } from '@/utils/hooks/use-query/use-query-params';
import type { TeamMemberData } from '@pages/content/profile/founder/api/get-founder-team-members/get-founder-team-members.action';
import {
  GET_TEAM_MEMBER_ATTRIBUTE_OPTIONS_KEY,
  getTeamMemberAttributeList,
} from '@pages/content/profile/founder/api/team-member-attributes/team-member-attributes';
import { useDrawer } from '@parts/drawer/use-drawer';
import message from '@parts/message/message';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import useConfirmModal from '@utils/hooks/use-confirm-modal/use-confirm-modal';
import { useElementRevealAnimation } from '@utils/hooks/use-element-reveal-animation/use-element-reveal-animation';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { parse } from 'query-string';
import { useState } from 'react';
import { createTeamMembersAction } from '../../../../api/create-team-members/create-team-members.action';
import { deleteTeamMembersAction } from '../../../../api/delete-team-members/delete-team-members.action';
import { updateTeamMembersAction } from '../../../../api/update-team-members/update-team-members.action';
import { Drawer } from './parts/drawer/drawer';
import { TeamMembersTable } from './parts/drawer/table/table';
import S from './team-members.styles';

const REVEAL_ANIMATION_ELEMENT_ID = ' team-members-element-animation';

export const INITIAL_DATA_SEARCH_KEY = 'populateForm';
export const TEAM_MEMBER_DRAWER_OPEN_SEARCH_KEY = 'teamMemberDrawerOpen';

export const TeamMembersManager = ({
  teamMembers,
  onListUpdate,
}: {
  teamMembers: TeamMemberData[];
  onListUpdate?: () => void;
}) => {
  const history = useHistory();
  const { [INITIAL_DATA_SEARCH_KEY]: qsFormInitialValues, [TEAM_MEMBER_DRAWER_OPEN_SEARCH_KEY]: drawerOpen } =
    useQueryParams() as {
      [INITIAL_DATA_SEARCH_KEY]: string;
      [TEAM_MEMBER_DRAWER_OPEN_SEARCH_KEY]: string;
    };

  useElementRevealAnimation(REVEAL_ANIMATION_ELEMENT_ID, { duration: 350 });
  const [addTeamMemberLabel, removeHeaderLabel, removeConfirmLabel, removedSuccessLabel, saveSuccessLabel] =
    useTranslation([
      'profile.founder.section.teamMembers.addTeamMember',
      'profile.founder.section.teamMembers.remove.header',
      'profile.founder.section.teamMembers.remove.confirm',
      'messages.form.removedSuccess',
      'messages.form.success',
    ]);

  const { data: teamMemberAttributeOptionsResponse, isLoading: isLoadingAttributeOptions } = useQuery(
    [GET_TEAM_MEMBER_ATTRIBUTE_OPTIONS_KEY],
    getTeamMemberAttributeList,
  );

  const { roles, areas } = teamMemberAttributeOptionsResponse?.data ?? { roles: [], areas: [] };

  const [currentMemberId, setCurrentMemberId] = useState<undefined | string>(undefined);

  const [members, setMembers] = useState(teamMembers);

  const {
    Drawer: EditDrawer,
    show: showEditDrawer,
    hide: hideEditDrawer,
    isVisible: isEditDrawerVisible,
  } = useDrawer(drawerOpen === 'true', { onAfterHide: () => history.push(history.location.pathname) });

  const { mutateAsync: createTeamMember, isLoading: isTeamMemberCreating } = useMutation(createTeamMembersAction, {
    onSuccess: (res, vars) => {
      setMembers((prev) => [...prev, { ...vars, id: res?.data?.id }]);
      message.success({ content: saveSuccessLabel });
      setCurrentMemberId(undefined);
      hideEditDrawer();

      if (onListUpdate) onListUpdate();
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  const { mutateAsync: updateTeamMember, isLoading: isTeamMemberUpdating } = useMutation(updateTeamMembersAction, {
    onSuccess: (_, vars) => {
      message.success({ content: saveSuccessLabel });
      setMembers((prev) => prev.map((m) => (m.id === vars.id ? { ...vars.formData, id: vars.id } : m)));
      setCurrentMemberId(undefined);
      hideEditDrawer();

      if (onListUpdate) onListUpdate();
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  const { mutateAsync: deleteTeamMember } = useMutation(deleteTeamMembersAction, {
    onSettled: () => {
      setCurrentMemberId(undefined);
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
    onSuccess: () => {
      message.success({ content: removedSuccessLabel });
      setMembers((prev) => prev.filter((item) => item.id !== currentMemberId));

      if (onListUpdate) onListUpdate();
    },
  });

  const { modal: deleteModal, show: showDeleteMemberModal } = useConfirmModal({
    title: removeHeaderLabel,
    content: removeConfirmLabel,
    onConfirm: () => deleteTeamMember(currentMemberId as string),
  });

  const onEditRow = (id: string) => {
    showEditDrawer();
    setCurrentMemberId(id);
  };

  const onRemoveRow = (row: TeamMemberData) => () => {
    showDeleteMemberModal();
    setCurrentMemberId(row.id);
  };

  const submitDrawer = (teamMember: TeamMemberData) => {
    const { id, ...formData } = {
      ...teamMember,
      area: teamMember.area || null,
      role: teamMember.role || null,
    };

    if (teamMember.id) {
      updateTeamMember({ id: teamMember.id, formData });
    } else {
      createTeamMember(formData);
    }
  };

  const getFormDefaults = () => {
    const { name = '', position = '', joined = '', experience = '', role = '', area = '' } = parse(qsFormInitialValues);

    return {
      id: undefined,
      name: typeof name === 'string' ? name : '',
      position: typeof position === 'string' ? position : '',
      joined: typeof joined === 'string' ? joined : '',
      experience: typeof experience === 'string' ? experience : '',
      role: roles.includes((role || '') as string) ? (role as string) || '' : '',
      area: areas.includes((area || '') as string) ? (area as string) || '' : '',
    };
  };

  return (
    <div data-animation-id={REVEAL_ANIMATION_ELEMENT_ID}>
      {deleteModal}
      <TeamMembersTable onEditRow={onEditRow} members={members} onRemoveRow={onRemoveRow} />
      {isEditDrawerVisible && !isLoadingAttributeOptions && (
        <Drawer
          disabled={isTeamMemberCreating || isTeamMemberUpdating}
          onClose={hideEditDrawer}
          onSubmit={submitDrawer}
          Drawer={EditDrawer}
          roleOptions={roles}
          areaOptions={areas}
          teamMember={members.find((re) => re.id === currentMemberId) || getFormDefaults()}
        />
      )}

      <S.AddNewRow
        type="link"
        onClick={() => {
          setCurrentMemberId(undefined);
          showEditDrawer();
        }}
        data-testid="team-member-add-new"
      >
        + {addTeamMemberLabel}
      </S.AddNewRow>
    </div>
  );
};

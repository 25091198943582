import { useQuery } from '@tanstack/react-query';
import {
  GET_FOUNDER_PUBLISH_STATUS_QUERY_CACHE_KEY,
  getFounderPublishStatusQuery,
} from '../api/get-founder-publish-status.query';

export const useGetFounderPublishStatusQuery = () => {
  const query = useQuery([GET_FOUNDER_PUBLISH_STATUS_QUERY_CACHE_KEY], getFounderPublishStatusQuery, {
    cacheTime: 0,
    refetchOnWindowFocus: true,
  });

  return {
    founderPublishStatusData: query.data,
    isLoadingPublishStatus: query.isLoading,
    isFetchingPublishStatus: query.isFetching,
    refetchPublishStatus: query.refetch,
    ogQueryResult: query,
  };
};

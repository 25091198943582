import Honeybadger from '@honeybadger-io/js';
import { beforeNotify as beforeNotifyHandler } from './utils/before-notify';

const hb = Honeybadger.configure({
  apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY,
  environment: process.env.REACT_APP_ENV,
  developmentEnvironments: ['local', 'test'],
  filters: ['password', 'email', 'firstName', 'lastName', 'token'],
  revision: process.env.REACT_APP_REVISION_SHA ?? 'master',
  maxErrors: 100,
});

hb.beforeNotify(beforeNotifyHandler);

export const honeybadger = hb;

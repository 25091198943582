import styled from 'styled-components';

const Container = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Icon = styled.div`
  width: 180px;
  height: 130px;
`;

const Text = styled.h4`
  margin-top: ${({ theme }) => theme.spacing.xbase};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.color.header};
`;

const S = {
  Container,
  Icon,
  Text,
};

export default S;

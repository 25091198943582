import { FormAlert } from '@parts/forms/error/form-alert/form-alert';
import OnTopFooter from '@parts/on-top-footer/on-top-footer';
import { theme } from '@styles/theme-config';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useFormik } from 'formik';
import type { PreviousInvestmentWithId } from '../../investment';
import S from './add-investment-drawer.styles';
import { useValidationSchema } from './validation';

interface AddInvestmentDrawerProps {
  drawer: Function;
  investment?: PreviousInvestmentWithId | null;
  error?: string;
  onClose: () => void;
  onSubmit: (v: PreviousInvestmentWithId) => void;
  loading: boolean;
}

export const AddInvestmentDrawer = ({
  drawer: Drawer,
  onClose,
  loading,
  investment,
  error,
  onSubmit,
}: AddInvestmentDrawerProps) => {
  const [addInvestmentLabel, errorLabel, createLabel, editLabel, companyNameLabel, notesLabel, cancelLabel] =
    useTranslation([
      'profile.investor.section.investments.addInvestment',
      'profile.investor.section.investments.investmentError',
      'profile.investor.section.investments.createInvestment',
      'profile.investor.section.investments.editInvestment',
      'profile.investor.section.investments.table.companyName',
      'profile.investor.section.investments.table.notes',
      'profile.investor.section.investments.cancel',
    ]);

  const isCreatingFundingRound = !investment;

  const {
    deviceData: { isMobile },
  } = useDeviceDetect();

  const { resetForm, submitForm, errors, touched, getFieldProps } = useFormik<Omit<PreviousInvestmentWithId, 'id'>>({
    initialValues: {
      company: investment?.company || '',
      note: investment?.note || '',
    },
    onSubmit(v) {
      onSubmit({
        ...v,
        id: isCreatingFundingRound ? '-1' : investment.id,
      });
    },
    validationSchema: useValidationSchema(),
    enableReinitialize: true,
  });

  const closeModal = () => {
    resetForm();
    onClose();
  };

  return (
    <Drawer
      width={isMobile ? '100%' : theme.ui.drawerWidth}
      title={addInvestmentLabel}
      onClose={onClose}
      footer={
        <OnTopFooter
          confirmLabel={isCreatingFundingRound ? createLabel : editLabel}
          cancelLabel={cancelLabel}
          loading={loading}
          onConfirm={submitForm}
          onCancel={closeModal}
          reverseButtons
        />
      }
    >
      {error && <FormAlert message={errorLabel} description={error} type="error" showIcon />}
      <form onSubmit={submitForm}>
        <S.FormikField
          label={{
            for: 'company',
            label: companyNameLabel,
          }}
          error={errors.company}
          touched={touched.company}
        >
          <Input {...getFieldProps('company')} id="company" type="text" />
        </S.FormikField>
        <S.FormikField
          label={{
            for: 'note',
            label: notesLabel,
          }}
          error={errors.note}
          touched={touched.note}
        >
          <TextArea id="note" autoSize={{ minRows: 4, maxRows: 4 }} {...getFieldProps('note')} />
        </S.FormikField>
      </form>
    </Drawer>
  );
};

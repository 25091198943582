import type { GraphqlResponse } from '@/types';
import { graphqlResponseWrapper } from '@/utils/fns/graphql-response-wrapper';
import { axiosInstance } from '@utils/axios/axios-instance';

type FollowInvitationID = {
  followInvitationID: string;
};

export const getFollowInvitationIDFromInvestorID = async (
  investorId: string,
): Promise<GraphqlResponse<FollowInvitationID>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
    query getFollowInvitationIDFromInvestorID {
      getInvestorPublicProfileData(investorId: "${investorId}") {
        followInvitationID,
      }
    }`,
  });

  return graphqlResponseWrapper(data, ['getInvestorPublicProfileData']);
};

export const disconnectInvestorAction = async (investorId: string) => {
  const res = await getFollowInvitationIDFromInvestorID(investorId);
  if (res.data) {
    const { followInvitationID } = res.data;
    return axiosInstance.post(`/connections/founder/connection/${followInvitationID}/remove`);
  }

  throw new Error('Failed to disconnect investor');
};

import DrawerSearch from '@pages/content/parts/drawer-search/drawer-search';
import {
  GET_CONNECTED_INVESTORS_CACHE_KEY,
  getConnectedInvestorsAction,
} from '@pages/content/pulse/api/get-connected-investors';
import { FieldSearch } from '@parts/field-search/field-search';
import { useQuery } from '@tanstack/react-query';
import useConfirmModal from '@utils/hooks/use-confirm-modal/use-confirm-modal';
import { useDebounce } from '@utils/hooks/use-debounce/use-debounce';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { clearQueryParamValue, idQueryParam } from '../../create-editor';
import { findInvestorBy } from './helpers';

export const DRAWER_DEBOUNCE_MIN_SEARCHABLE_LENGTH = 1;

export const InvestorSearch = ({
  setFieldValue,
  getFieldProps,
  getFieldMeta,
  fieldKey,
  onSearch,
  setInternalInvestorId,
  onClear,
  disabled,
}: {
  setFieldValue: Function;
  getFieldProps: Function;
  getFieldMeta: Function;
  onSearch: Function;
  onClear: Function;
  setInternalInvestorId?: Function;
  fieldKey: string;
  disabled: boolean;
}) => {
  const [searchLabel, placeholderLabel, resetTitleLabel, resetDescriptionLabel] = useTranslation([
    'pulse.founder.drawer.searchName',
    'pulse.founder.drawer.placeholder',
    'pulse.founder.drawer.resetTitle',
    'pulse.founder.drawer.resetDescription',
  ]);

  const { id } = useQueryParams() as { id: string };
  const { value } = getFieldProps(fieldKey);
  const location = useLocation();

  const self = useRef<HTMLInputElement>(null);
  const debouncedSearch = useDebounce(value || '', 300);

  const { modal, show } = useConfirmModal({
    title: resetTitleLabel,
    content: resetDescriptionLabel,
    onConfirm: () => onClear(),
  });

  const { data: response } = useQuery(
    [GET_CONNECTED_INVESTORS_CACHE_KEY(debouncedSearch)],
    getConnectedInvestorsAction(debouncedSearch),
    {
      enabled: debouncedSearch.length >= DRAWER_DEBOUNCE_MIN_SEARCHABLE_LENGTH,
      onSuccess: (res) => {
        const activeInvestor = findInvestorBy('id', id, res.data?.investors);
        const params = new URLSearchParams(location.search);

        if (activeInvestor && setInternalInvestorId && params.get(idQueryParam) !== clearQueryParamValue) {
          setInternalInvestorId(activeInvestor.id);
        }
      },
    },
  );

  const investors = response?.data?.investors || [];
  const isInvestorFound = Boolean(findInvestorBy('name', value, investors));

  return (
    <>
      {modal}
      <FieldSearch
        label={{
          for: fieldKey,
          label: searchLabel,
        }}
        {...getFieldMeta(fieldKey)}
      >
        <DrawerSearch
          placeholder={placeholderLabel}
          selectRef={self}
          isDisabled={disabled}
          onSearch={(val) => setFieldValue(fieldKey, val.toLowerCase() || null)}
          value={value}
          onSelect={(val) => {
            const investorByName = findInvestorBy('name', val as string, investors);
            let investorByEmail;

            if (!investorByName) {
              investorByEmail = findInvestorBy('email', val as string, investors);
            }

            onSearch({
              value: val,
              investorId: investorByName?.id || investorByEmail?.id,
              investingRelationshipId:
                investorByName?.investingRelationshipId || investorByEmail?.investingRelationshipId,
            });
          }}
          dropdownClassName={`portfolio-entity-select ${
            debouncedSearch.length < DRAWER_DEBOUNCE_MIN_SEARCHABLE_LENGTH ? 'hidden' : ''
          }`}
          onClick={() => {
            if (disabled) show();
          }}
          options={[
            ...(!isInvestorFound ? [{ name: value }] : []),
            ...(response?.data ? [...response?.data.investors] : []),
          ]}
        />
      </FieldSearch>
    </>
  );
};

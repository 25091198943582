import OptionalTooltip from '@pages/content/parts/editor-details/parts/optional-tooltip/optional-tooltip';
import type { TriggerRowData } from '@pages/content/pulse/investor/parts/triggers/triggers';
import { TableColumn } from '@parts/table-column/table-column';
import type { ReactNode } from 'react';

interface TriggerColumnProps {
  title: string;
  children: ReactNode;
  row: TriggerRowData;
}

export const TriggerTableColumn = ({ title, children, row }: TriggerColumnProps) => (
  <TableColumn title={title}>
    <OptionalTooltip show={!row.isAvailable} title={row.tooltip}>
      {children}
    </OptionalTooltip>
  </TableColumn>
);

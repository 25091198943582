import EditRowIconBase from '@assets/icons/edit-icon.svg?react';
import RemoveRowIconBase from '@assets/icons/trash.svg?react';
import { Button } from 'antd';
import styled from 'styled-components';

const UseOfFundsContainer = styled.p<{ $smallerSize: boolean }>`
  max-width: ${({ $smallerSize }) => ($smallerSize ? '100px' : '200px')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const EditRowIcon = styled(EditRowIconBase)`
  width: 25px;
  height: 19px;

  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
`;

const RemoveRowIcon = styled(RemoveRowIconBase)`
  width: 25px;
  height: 19px;

  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
`;

const HeaderInfo = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.medium};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const HeaderTitle = styled.h3`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.medium};
  color: ${({ theme }) => theme.color.fontPrimary};
`;

const ButtonIcon = styled(Button)`
  height: 25px !important;
  padding: 0;

  &[disabled] {
    opacity: 0.3;
  }
`;

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export default {
  Controls,
  UseOfFundsContainer,
  EditRowIcon,
  RemoveRowIcon,
  HeaderTitle,
  HeaderInfo,
  ButtonIcon,
};

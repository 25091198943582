import { Switch } from 'antd';
import styled from 'styled-components';

const V2Switch = styled(Switch)`
  background-color: ${({ theme }) => theme.v2.colors.blue[100]};

  &.ant-switch-checked {
    background-color: ${({ theme }) => theme.v2.colors.blue[300]};
  }
`;

export default { V2Switch };

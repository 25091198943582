import {
  GET_CREATED_FUNDING_ROUNDS_CACHE_KEY,
  getCreatedFundingRoundsAction,
} from '@pages/content/api/get-registered-funding-rounds.action';
import type { Investment } from '@pages/content/portfolio/investor/parts/editor/editor-interfaces';
import { idQueryParam, newQueryParamValue } from '@pages/content/pulse/founder/parts/editor/create-editor';
import type { ShareholderInvestment } from '@pages/content/pulse/founder/parts/editor/editor-interfaces';
import message from '@parts/message/message';
import { useMutation, useQuery, type MutationFunction } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useFormik } from 'formik';
import { stringify } from 'query-string';
import type { Any } from 'src/types';
import { useHistory } from '../use-history/use-history';
import { useQueryParams } from '../use-query/use-query-params';
import { useTranslation } from '../use-translation/use-translation';

export const getActiveRegisteredFundingRounds = (investments: (ShareholderInvestment | Investment)[]) =>
  investments.reduce((acc: string[], curr) => {
    const {
      round: { id: roundId },
    } = curr;

    if (roundId === null) {
      return acc;
    }

    return [...acc, roundId];
  }, []);

export const useInvestmentsEditor = ({
  hideDrawer,
  onClear,
  createAction,
  updateAction,
  founderId,
  initialValues,
  onSubmit,
  validationSchema,
  trackedPageParam,
  isShareholder,
  closeRefetch,
}: {
  hideDrawer: Function;
  onClear: Function;
  createAction: MutationFunction<AxiosResponse, Any>;
  updateAction: MutationFunction<AxiosResponse, Any>;
  founderId?: string;
  initialValues: { [key: string]: Any };
  onSubmit: (v: Any) => void;
  validationSchema: () => void;
  trackedPageParam: string;
  isShareholder?: boolean;
  closeRefetch?: Function;
}) => {
  const history = useHistory();
  const [savedLabel] = useTranslation(['global.drawer.saved']);
  const { [trackedPageParam]: page } = useQueryParams();

  const goToNone = () => history.push({ search: stringify({ [trackedPageParam]: page }) });

  const goToNew = () =>
    history.push({
      search: stringify({
        [idQueryParam]: newQueryParamValue,
        [trackedPageParam]: page === '1' ? undefined : page,
      }),
    });

  const goToRelation = (id: string) =>
    history.push({
      search: stringify({
        [idQueryParam]: id,
        [trackedPageParam]: page,
      }),
    });

  const {
    setFieldValue,
    getFieldProps,
    getFieldMeta,
    submitForm,
    values,
    setValues,
    setFieldTouched,
    dirty,
    isValid,
    submitCount,
    errors,
    resetForm,
    isSubmitting,
  } = useFormik({
    onSubmit,
    initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema(),
  });

  const deleteInvestment = (index: number) => {
    setValues({
      ...values,
      investments: values.investments.filter((_el: ShareholderInvestment | Investment, i: number) => i !== index),
    });
  };

  const id =
    values.investingRelationshipId && values.investingRelationshipId !== newQueryParamValue
      ? values.investingRelationshipId
      : founderId;

  const { data: response, refetch } = useQuery(
    [GET_CREATED_FUNDING_ROUNDS_CACHE_KEY(id)],
    getCreatedFundingRoundsAction(
      id,
      Boolean(isShareholder) ||
        (values.investingRelationshipId && values.investingRelationshipId !== newQueryParamValue),
    ),
    { enabled: isShareholder ? false : Boolean(id) },
  );

  const onClose = () => {
    if (closeRefetch) {
      closeRefetch();
    }
    resetForm();
    hideDrawer();
    onClear();
    goToNone();
  };

  const mutationConfig = {
    onSuccess: () => {
      onClose();
      message.success({ content: savedLabel });
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  };

  const { mutateAsync: add, isLoading: createLoading } = useMutation(createAction, mutationConfig);
  const { mutateAsync: update, isLoading: updateLoading } = useMutation(updateAction, mutationConfig);

  const isMutationLoading = createLoading || updateLoading;

  return {
    add,
    update,
    goToNew,
    goToNone,
    onClose,
    goToRelation,
    deleteInvestment,
    createdFundingRounds: {
      isFundingRoundsApiCalled: Boolean(response?.data),
      data: response?.data || null,
      refetch,
    },
    isMutationLoading,
    formik: {
      setFieldValue,
      getFieldProps,
      getFieldMeta,
      submitForm,
      values,
      setValues,
      setFieldTouched,
      dirty,
      isValid,
      submitCount,
      errors,
      isSubmitting,
    },
  };
};

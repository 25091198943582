import type { AdvisorRole } from '@/domain/accounts/roles';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_FOUNDER_ADVISOR_QUESTIONS_ANSWERS = 'get-founder-advisor-questions-answers';

export type FounderAdvisorQAQueryResponse = {
  skillSet: null | string[];
  industryKnowledge: null | number;
  advisorType: null | AdvisorRole.ADVISOR | AdvisorRole.NED | AdvisorRole.FRACTIONAL;
  help: null | string;
};

export const getFounderAdvisorQuestionsAnswers = async (): Promise<GraphqlResponse<FounderAdvisorQAQueryResponse>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query founderAdvisorQuestionsAnswers {
        founderAdvisorQuestionsAnswers {
          skillSet
          industryKnowledge
          advisorType
          help
        }
      }
    `,
  });

  return graphqlResponseWrapper(data, ['founderAdvisorQuestionsAnswers']);
};

import { useQuery } from '@tanstack/react-query';
import {
  FOUNDER_SMART_MATCH_SKILLS_CACHE_KEY,
  getFounderSmartMatchSkillsOptions,
} from '../api/get-founder-smart-match-skills';

export const useGetFounderSmartMatchSkillsOptions = () => {
  const query = useQuery([FOUNDER_SMART_MATCH_SKILLS_CACHE_KEY], getFounderSmartMatchSkillsOptions, {
    refetchOnWindowFocus: true,
    cacheTime: 0,
  });

  return {
    founderSmartMatchSkillsData: query.data?.data ?? [],
    isFounderSmartMatchSkillsLoading: query.isLoading,
    isFounderSmartMatchSkillsFetching: query.isFetching,
    isFounderSmartMatchSkillsFetched: query.isFetched,
    ogQueryResult: query,
  };
};

import { V2Alert } from '@/components/ui/v2-alert/v2-alert';
import { V2Container } from '@/components/ui/v2-container/v2-container';
import { V2NavigationTabs } from '@/components/ui/v2-navigation-tabs/v2-navigation-tabs';
import { V2NotificationIndicator } from '@/components/ui/v2-notification-indicator/v2-notification-indicator';
import { V2Title } from '@/components/ui/v2-title/v2-title';
import { useMediaQuery } from '@/hooks/use-media-query';
import { InvestorNedLayout } from '@/layout/investor-ned/investor-ned.layout';
import { TranslationText } from '@/parts/translation-text/translation-text';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { Connections } from './components/connections/connections';
import { Invites } from './components/invites/invites';
import { useInvestorAdvisorInvitesCount } from './hooks/use-get-invites-count';

import S from './connections-page.styles';

export const InvestorAdvisorConnectionsPage = () => {
  const size = useMediaQuery();
  const isMobile = ['xs'].includes(size);

  const [title, connectionsLabel, invitesLabel] = useTranslation([
    'connections.title',
    'connections.advisor.tab.connections',
    'connections.advisor.tab.invites',
  ]);

  const { invitesCount } = useInvestorAdvisorInvitesCount();

  return (
    <InvestorNedLayout legacyContainer={false}>
      <V2Container>
        {!isMobile && (
          <S.TitleWrapper>
            <V2Title title={title} size="4xl" />
          </S.TitleWrapper>
        )}
        <V2NavigationTabs
          tabs={[
            {
              content: <Connections />,
              key: 'connections',
              label: connectionsLabel,
              disabled: false,
            },
            {
              content: <Invites />,
              key: 'invites',
              label: (
                <V2NotificationIndicator count={invitesCount} position="middle-right" enlargeWithPadding>
                  {invitesLabel}
                </V2NotificationIndicator>
              ),
              disabled: false,
            },
          ]}
          fallbackElement={<V2Alert title={<TranslationText id="utility.noResultsFound" />} />}
        />
      </V2Container>
    </InvestorNedLayout>
  );
};

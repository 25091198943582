import { useQuery } from '@tanstack/react-query';
import {
  INVESTOR_ADVISOR_FOUNDER_INVITES_COUNT_QUERY_KEY,
  getInvestorAdvisorInvitesCount,
} from '../api/get-investor-advisor-invites-count';
import type { InvestorAdvisorConnectionIntent } from '../types';

type GetInvitesCountQueryConfig = {
  intent: InvestorAdvisorConnectionIntent | undefined;
};

export const useInvestorAdvisorInvitesCount = ({ intent }: GetInvitesCountQueryConfig = { intent: undefined }) => {
  const queryResult = useQuery([INVESTOR_ADVISOR_FOUNDER_INVITES_COUNT_QUERY_KEY, intent ?? ''], () =>
    getInvestorAdvisorInvitesCount({ intent }),
  );

  const { data, error, isLoading, isFetching, isInitialLoading } = queryResult;
  const count = data?.data?.total ?? 0;

  return {
    invitesCount: count,
    invitesCountError: error,
    isInvitesCountDataLoading: isLoading,
    isInvitesCountDataFetching: isFetching,
    isInvitesCountInitialLoading: isInitialLoading,
    ogQuery: queryResult,
  };
};

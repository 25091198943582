import { openModalNestedContentQueryParamKey } from '@/hooks/use-modal-multilevel-query';
import { openModalQueryParamKey } from '@/hooks/use-modal-query';
import { useQuery } from '@tanstack/react-query';
import { removeObjectProperties } from '@utils/fns/remove-object-properties';
import { scrollToTop } from '@utils/fns/scroll-to-top';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import queryString, { type ParsedQuery } from 'query-string';
import { useState } from 'react';
import type { Any, GraphqlResponse } from 'src/types';
import { FilterKinds, useFiltering, type AllowedComparators } from '../use-filtering/use-filtering';

export const PERMANENTLY_EXCLUDED_QUERY_PARAMS = [openModalQueryParamKey, openModalNestedContentQueryParamKey];

export type TQueryPaginationActionParam<T = void> = (
  start: number,
  limit: number,
  excludedParams: string[],
  otherParams?: ParsedQuery<string>,
) => () => Promise<
  GraphqlResponse<{
    items: T[] | Any[];
    total: number;
    hasItems?: boolean;
    sortingRules?: string[];
    filteringRules?: {
      key: string;
      availableValues: string[];
      type: FilterKinds;
      allowedComparators: AllowedComparators;
    }[];
  }>
>;

export type PaginationQueryConfig = {
  enabled?: boolean;
  refetchOnWindowFocus?: boolean;
  cacheTime?: number;
  refetchInterval?: number;
  usePreviousPage?: boolean;
};

export const useQueryPagination = <T>({
  pageParam,
  itemsPerPage,
  action,
  cacheKey,
  scrollToTopOnPageChanged,
  excludedParams = [],
  queryConfig = {
    refetchOnWindowFocus: false,
    usePreviousPage: false,
  },
}: {
  pageParam: string;
  itemsPerPage: number;
  action: TQueryPaginationActionParam<T | Any>;
  cacheKey: string;
  excludedParams?: string[];
  queryConfig?: PaginationQueryConfig;
  scrollToTopOnPageChanged?: boolean;
}) => {
  const history = useHistory();
  const query = useQueryParams();

  const stringifiedQuery = queryString.stringify(
    removeObjectProperties(query, [...PERMANENTLY_EXCLUDED_QUERY_PARAMS, ...excludedParams]),
  );

  const currentPage = Number(query[pageParam]) || 1;
  const [maxPages, setMaxPages] = useState(1);

  const { dispatch, sortKey } = useFiltering({ pageParam });

  const updatePage = (value: number | number) => {
    history.replace({
      search: `?${queryString.stringify({ ...query, [pageParam]: value })}`,
    });
  };

  const setPage = (incomingPage: number) => {
    if (incomingPage < 1 || incomingPage > maxPages) return;
    updatePage(incomingPage);
  };

  const {
    data: response,
    refetch,
    isLoading,
    isFetching,
  } = useQuery(
    [cacheKey, stringifiedQuery],
    action(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage,
      [...PERMANENTLY_EXCLUDED_QUERY_PARAMS, pageParam, ...excludedParams],
      query,
    ),
    {
      ...queryConfig,
      onSuccess: (resp) => {
        if (resp && resp.data) {
          const max = Math.ceil(resp.data.total / itemsPerPage) || 1;
          setMaxPages(max);

          if (currentPage > max) {
            updatePage(max);
          }
        }

        if (resp && resp.errors) {
          history.replace({
            search: `?${queryString.stringify({ [pageParam]: 1 })}`,
          });
        }

        if (scrollToTopOnPageChanged) {
          scrollToTop();
        }
      },
    },
  );

  const result = {
    maxPages,
    currentPage,
    response,
    isLoading,
    isFetching,
    refetch: refetch as () => Promise<GraphqlResponse<{ items: (object & { id: string })[]; total: number }> | Any>,
    items: [] as T[],
    total: 0,
    hasItems: false,
    querying: {
      dispatch,
      sortKey,
      sortingRules: response?.data?.sortingRules || [],
      filteringRules: response?.data?.filteringRules || [],
    },
    additionalData: {},
    handleNext: () => setPage(currentPage + 1),
    handlePrev: () => setPage(currentPage - 1),
  };

  if (response && response.data) {
    const { items, total, sortingRules, hasItems, filteringRules, ...additionalData } = response.data;

    return { ...result, items, total, hasItems, additionalData } as typeof result;
  }

  return result;
};

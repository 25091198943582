import { useDrawer } from '@parts/drawer/use-drawer';
import { FormikStringInput } from '@parts/forms/formik-string-input/formik-string-input';
import message from '@parts/message/message';
import OnTopFooter from '@parts/on-top-footer/on-top-footer';
import { Links } from '@router/links';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useFormik } from 'formik';
import qs from 'query-string';
import { useHistory } from 'react-router-dom';
import { updateDashboardNameAction } from '../../../api/update-dashboard-name/update-dashboard-name.action';
import { dashboardIdParam } from '../../dashboards-menu/dashboards-menu';
import { useValidationSchema } from './validation-schema';

const UpdateDashboardName = ({
  name,
  dashboardsQueryKeyCache,
}: {
  name: string;
  dashboardsQueryKeyCache: string | unknown[];
}) => {
  const history = useHistory();
  const { [dashboardIdParam]: id } = useQueryParams() as { [dashboardIdParam]: string };

  const { Drawer } = useDrawer(true);
  const [titleLabel, nameLabel, updateLabel, cancelLabel, savedLabel] = useTranslation([
    'pulse.nameDashboard.title',
    'pulse.dashboard.name',
    'global.update',
    'global.cancel',
    'pulse.nameDashboard.saved',
  ]);

  const onClose = () => {
    const dashboardId = qs.stringify({ [dashboardIdParam]: id });
    history.push(`${Links.PULSE_FOUNDER_DASHBOARDS()}${dashboardId ? `?${dashboardId}` : ''}`);
  };
  const queryClient = useQueryClient();
  const { mutateAsync: updateDashboardName } = useMutation(updateDashboardNameAction, {
    onSuccess: () => {
      queryClient.invalidateQueries([dashboardsQueryKeyCache]);
      message.success({
        content: savedLabel,
      });
      onClose();
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  const formik = useFormik<{ name: string }>({
    onSubmit: (values) => {
      updateDashboardName({ name: values.name, dashboardId: id });
    },
    initialValues: {
      name,
    },
    validateOnMount: true,
    validationSchema: useValidationSchema(),
  });

  return (
    <Drawer
      title={titleLabel}
      onClose={onClose}
      footer={
        <OnTopFooter
          confirmLabel={updateLabel}
          cancelLabel={cancelLabel}
          onConfirm={() => {
            formik.submitForm();
          }}
          onCancel={onClose}
          disabled={!formik.isValid || !formik.dirty}
          reverseButtons
        />
      }
    >
      <FormikStringInput formik={formik} name="name" label={nameLabel} />
    </Drawer>
  );
};

export default UpdateDashboardName;

import { batchImportNumericMetricNodesAction } from '@pages/content/pulse/api/batch-import-numeric-metric-nodes/batch-import-numeric-metric-nodes.action';
import { MetricType } from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import {
  GET_NUMERIC_METRIC_NODES_AND_META_QUERY_CACHE_KEY,
  getNumericMetricNodesAndMetaAction,
} from '@pages/content/pulse/api/get-numeric-metric-nodes-and-meta/get-numeric-metric-nodes-and-meta.action';
import {
  GET_NUMERIC_METRIC_SCALE_QUERY_CACHE_KEY,
  getNumericMetricScaleAction,
} from '@pages/content/pulse/api/get-numeric-metric-scale/get-numeric-metric-scale.action';
import { upsertNumericMetricNodeAction } from '@pages/content/pulse/api/upsert-numeric-metric/upsert-numeric-metric.action';
import { getMetricSymbol } from '@pages/content/pulse/parts/dashboards/parts/dashboard/metrics/parts/metric-item/parts/chart-metric/chart-metric';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import message from '@parts/message/message';
import { Pagination } from '@parts/pagination/pagination';
import { useMutation, useQuery } from '@tanstack/react-query';
import { dateFromYearAndMonth } from '@utils/fns/date-from-year-and-month';
import { sortChartNodes } from '@utils/fns/sort-chart-nodes';
import { useDebounce } from '@utils/hooks/use-debounce/use-debounce';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { usePagination } from '@utils/hooks/use-pagination/use-pagination';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useUserCurrency } from '@utils/hooks/use-user-currency/use-user-currency';
import Big from 'big.js';
import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { EmptyState } from '../empty-state/empty-state';
import S from './numeric-editor.styles';
import { LiveChart, type Node } from './parts/live-chart/live-chart';
import { Row, getRowHtmlId } from './parts/row/row';
import { Uploader } from './parts/uploader/uploader';

const LIVE_CHART_DEBOUNCE_DURATION = 300;
const ELEMENTS_ON_PAGE = 12;

export enum ScaleTransformPurpose {
  WRITE = 'write',
  READ = 'read',
}

export const getFactor = (scale: number) => Big(10).pow(Number(scale)).toNumber();

export const scaleValidation = (value: number, scale: number) =>
  Number.isInteger(
    Big(value || 0)
      .times(getFactor(scale))
      .toNumber(),
  );

export const scaleTransform = (value: number, scale: number, purpose: ScaleTransformPurpose) => {
  const factor = getFactor(scale);

  if (purpose === ScaleTransformPurpose.WRITE) {
    return Big(factor || 0)
      .times(value)
      .toNumber();
  }

  return Number.isNaN(Number(value))
    ? value
    : Big(value || 0)
        .div(factor)
        .toNumber();
};

export const scaleNodesTransform = (points: Node[], purpose: ScaleTransformPurpose, scale: number) =>
  points.map((dp) => ({
    ...dp,
    value: dp && dp.value ? scaleTransform(dp.value, scale, purpose) : dp.value,
    forecast: dp && dp.forecast ? scaleTransform(dp.forecast, scale, purpose) : dp.forecast,
  }));

export enum Mode {
  EDIT = 'edit',
  IMPORT = 'import',
}

export const NumericEditor = ({
  metricId,
  title,
  type,
  integratedMetricsView,
}: {
  metricId: string;
  title: string;
  type: MetricType;
  integratedMetricsView?: boolean;
}) => {
  const {
    deviceData: { isDesktop },
  } = useDeviceDetect();
  const { currencySymbol } = useUserCurrency();

  const [mode, setMode] = useState(Mode.EDIT);
  const [scale, setScale] = useState<number>(0);

  const [activeNodeId, setActiveNodeId] = useState('');

  const [
    importLabel,
    monthLabel,
    valueLabel,
    forecastLabel,
    noteLabel,
    addMonthLabel,
    emptyEditLabel,
    emptyImportLabel,
    importWithCsvLabel,
    requiredLabel,
    tooManyDigitsLabel,
    csvReplacedLabel,
    autoSavedLabel,
    cancelLabel,
    csvImportLabel,
    missingDateErrorLabel,
    forecastNotNumberErrorLabel,
    valueNotNumberErrorLabel,
    valueFromFutureErrorLabel,
    noteLengthExceededErrorLabel,
    problemsLabel,
    rowLabel,
    dataSuccessfullyImportedLabel,
  ] = useTranslation([
    'pulse.editor.import',
    'pulse.editor.month',
    'pulse.editor.valueLabel',
    'pulse.editor.forecastLabel',
    'pulse.editor.noteLabel',
    'pulse.editor.addMonthLabel',
    'pulse.editor.empty.edit',
    'pulse.editor.empty.import',
    'pulse.editor.importWithCsv',
    'formik.validation.required',
    'formik.validation.tooManyDigits',
    'metrics.import.csv.replaced',
    'metrics.manage.autosaved',
    'metrics.import.csv.cancel',
    'metrics.import.csv.import',
    'metrics.import.csv.missingDate',
    'metrics.import.csv.valueNotNumber',
    'metrics.import.csv.forecastNotNumber',
    'metrics.import.csv.valueFromFuture',
    'metrics.import.csv.noteLengthExceeded',
    'metrics.import.csv.problems',
    'metrics.import.csv.row',
    'metrics.import.csv.success',
  ]);

  useEffect(() => {
    setMode(Mode.EDIT);
  }, [metricId]);

  const { data: scaleResponse, isLoading: isLoadingScale } = useQuery(
    [GET_NUMERIC_METRIC_SCALE_QUERY_CACHE_KEY(metricId)],
    getNumericMetricScaleAction(metricId),
    {
      refetchOnWindowFocus: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      onSuccess: (res) => {
        if (res?.data) setScale(res?.data.scale);
      },
    },
  );

  const tooManyDigitsScaleLabel = `${tooManyDigitsLabel} (max ${scale})`;

  const { errors, values, getFieldHelpers, getFieldProps, getFieldMeta, submitForm, setValues } = useFormik<Node[]>({
    initialValues: [],
    onSubmit: (v) => {
      if (mode === Mode.EDIT) return;

      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      batchImport(
        scaleNodesTransform(v, ScaleTransformPurpose.WRITE, scale).map((node) => {
          const { id, ...rest } = node;
          return rest;
        }),
      );
    },
    enableReinitialize: true,
    validationSchema: Yup.array(
      Yup.object({
        month: Yup.number().min(1, missingDateErrorLabel).max(12, missingDateErrorLabel).required(requiredLabel),
        year: Yup.number().min(1, missingDateErrorLabel).required(requiredLabel),
        forecast: Yup.number()
          .test('there-should-not-be-more-than-scale-digits-after-decimal', tooManyDigitsScaleLabel, function () {
            return scaleValidation(this.parent.forecast, scale);
          })
          .max(scaleTransform(Number.MAX_SAFE_INTEGER, scale, ScaleTransformPurpose.READ))
          .typeError(forecastNotNumberErrorLabel)
          .nullable(),
        note: Yup.string().max(100, noteLengthExceededErrorLabel).nullable(),
        value: Yup.number()
          .test('there-should-not-be-more-than-scale-digits-after-decimal', tooManyDigitsScaleLabel, function () {
            return scaleValidation(this.parent.value, scale);
          })
          .max(scaleTransform(Number.MAX_SAFE_INTEGER, scale, ScaleTransformPurpose.READ))
          .typeError(valueNotNumberErrorLabel)
          .test('null-if-is-future', valueFromFutureErrorLabel, function () {
            if (dateFromYearAndMonth(this.parent.year, this.parent.month) > DateTime.local()) {
              return this.parent.value === null;
            }

            return true;
          })
          .nullable(),
      }),
    ),
  });

  const symbol = getMetricSymbol(type, { currencySymbol });

  const { currentPage, maxPages, setPage } = usePagination({
    totalItems: values.length,
    itemsPerPage: ELEMENTS_ON_PAGE,
  });

  useEffect(() => {
    setPage(maxPages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.length]);

  useEffect(() => {
    if (scaleResponse?.data?.scale && !isLoadingScale) setScale(scaleResponse?.data?.scale);
  }, [isLoadingScale, scaleResponse]);

  const scrollToNode = (id: string) => {
    const node = document.getElementById(getRowHtmlId(id));

    if (node) {
      node.scrollIntoView({ behavior: 'smooth', block: isDesktop ? 'center' : 'end' });
    }
  };

  const setActiveRow = (id: string, { scroll }: { scroll?: boolean } = {}) => {
    if (activeNodeId !== id) {
      setActiveNodeId(id);
    }
    if (scroll) scrollToNode(id);
  };

  const { mutateAsync: batchImport } = useMutation(batchImportNumericMetricNodesAction(metricId), {
    onSuccess: (res) => {
      setValues(scaleNodesTransform(res.data.dataPoints, ScaleTransformPurpose.READ, scale));
      setMode(Mode.EDIT);
      setActiveRow(res.data.dataPoints[res.data.dataPoints.length - 1].id);
      message.success({ content: dataSuccessfullyImportedLabel });
    },
  });

  const { isLoading, refetch } = useQuery(
    [GET_NUMERIC_METRIC_NODES_AND_META_QUERY_CACHE_KEY(metricId)],
    getNumericMetricNodesAndMetaAction(metricId),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onSuccess: (res) => {
        if (res.data) {
          setScale(res.data.metricMetadata.scale);
          setValues(
            scaleNodesTransform(
              res.data.getMetricDataPoints,
              ScaleTransformPurpose.READ,
              res.data.metricMetadata.scale,
            ),
          );
        }
      },
      enabled: !isLoadingScale,
    },
  );

  const { mutateAsync: insert } = useMutation(upsertNumericMetricNodeAction(metricId), {
    onSuccess: (res, vars) => {
      setValues([...values, { ...vars, id: res.data } as Node]);
      setActiveRow(res.data, { scroll: true });
    },
  });

  const debouncedValues = useDebounce(values, LIVE_CHART_DEBOUNCE_DURATION);

  const historyTrace = values.reduce((acc, curr) => acc + curr.month + curr.year, '');

  const errorsPresent = Object.keys(errors).length > 0;

  useEffect(() => {
    if (errorsPresent) return;

    setValues(sortChartNodes(values));
    // eslint-disable-next-line
  }, [historyTrace, errorsPresent]);

  const addNewNode = () => {
    const lastNode = values[values.length - 1];

    const date = lastNode
      ? dateFromYearAndMonth(lastNode.year, lastNode.month).plus({ month: 1 })
      : DateTime.local().minus({ year: 1, month: 6 });

    const newNode = {
      year: date.year,
      month: date.month,
      value: null,
      forecast: null,
      note: null,
      id: null,
    };

    if (mode === Mode.EDIT) {
      return insert(newNode);
    }
    return setValues([...values, { ...newNode, id: uuidv4() }]);
  };

  const nodesPresent = values.length > 0;

  const discard = (id: string) => {
    const index = values.findIndex((n) => n.id === id);
    const v = values.filter((n) => n.id !== id);
    setValues(v);

    if (index === 0 && v.length > 0) {
      setActiveRow(v[0].id);
    }

    if (index !== 0) {
      setActiveRow(v[index - 1].id);
    }

    if (v.length === 0) {
      setMode(Mode.EDIT);
    }
  };

  const removeDuplicates = (arr: (string | undefined)[]) => arr.filter((elem, pos) => arr.indexOf(elem) === pos);

  if (isLoading || isLoadingScale) return <FullHeightSpinner />;

  const descriptionLabel = integratedMetricsView ? 'Data is imported and cannot be edited.' : autoSavedLabel;

  return (
    <S.Wrapper>
      <S.Header>
        <S.Info
          title={`${title} ${symbol && `(${symbol})`}`}
          supplement={mode === Mode.IMPORT ? importWithCsvLabel : undefined}
          description={mode === Mode.EDIT ? descriptionLabel : csvReplacedLabel}
        />
        {mode === Mode.EDIT && !integratedMetricsView && (
          <S.Import
            data-testid="import-from-csv"
            onClick={() => {
              setValues([]);
              setMode(Mode.IMPORT);
            }}
          >
            {importLabel}
          </S.Import>
        )}
      </S.Header>
      <S.Preview>
        {nodesPresent ? (
          <LiveChart nodes={debouncedValues} />
        ) : (
          <EmptyState text={mode === Mode.EDIT ? emptyEditLabel : emptyImportLabel} />
        )}
      </S.Preview>
      {mode === Mode.IMPORT && <Uploader scale={scale} setValues={setValues} metricId={metricId} />}
      {errorsPresent && (
        <S.Alert
          type="error"
          closable={false}
          message={
            <S.Problems>
              {problemsLabel}:
              {Object.keys(errors).map((key: string) => (
                <S.Error
                  key={`numeric-editor-error-${key}`}
                  onClick={() => {
                    setPage(Math.ceil(Number(key) / ELEMENTS_ON_PAGE));
                    setActiveRow(values[Number(key)].id, { scroll: true });
                  }}
                >
                  {rowLabel} {Number(key) + 1}: {removeDuplicates(Object.values(errors[Number(key)] ?? {})).join(', ')}
                </S.Error>
              ))}
            </S.Problems>
          }
        />
      )}
      {isDesktop && nodesPresent && (
        <S.Columns>
          <S.Column>{monthLabel}</S.Column>
          <S.Column>
            {valueLabel} {symbol && `(${symbol})`}
          </S.Column>
          <S.Column>
            {forecastLabel} {symbol && `(${symbol})`}
          </S.Column>
          <S.NoteColumn>{noteLabel}</S.NoteColumn>
        </S.Columns>
      )}
      {values.slice((currentPage - 1) * ELEMENTS_ON_PAGE, currentPage * ELEMENTS_ON_PAGE).map((r, i: number) => (
        <Row
          {...r}
          scale={scale}
          i={(currentPage - 1) * ELEMENTS_ON_PAGE + i}
          key={r.id}
          isCorrect={!errors[i]}
          labels={{
            month: monthLabel,
            value: valueLabel,
            forecast: forecastLabel,
            note: noteLabel,
          }}
          extendedDependencyArray={[integratedMetricsView ? values.length : 0]}
          setActive={() => setActiveRow(r.id)}
          table={isDesktop}
          mode={mode}
          getFieldHelpers={getFieldHelpers}
          getFieldProps={getFieldProps}
          getFieldMeta={getFieldMeta}
          scrollIntoView={scrollToNode}
          disabledDates={values.map((v) => dateFromYearAndMonth(v.year, v.month).toFormat('yyyy-MM'))}
          active={r.id === activeNodeId}
          metricId={metricId}
          discard={discard}
          integratedMetricsView={integratedMetricsView}
        />
      ))}
      {(mode === Mode.EDIT || values.length > 0) && !errorsPresent && (
        <S.Add data-testid="add-month" onClick={addNewNode}>
          + {addMonthLabel}
        </S.Add>
      )}
      {maxPages > 1 && (
        <Pagination
          centered
          currentPage={currentPage}
          handleNext={() => setPage(currentPage + 1)}
          handlePrev={() => setPage(currentPage - 1)}
          maxPages={maxPages}
        />
      )}
      {mode === Mode.IMPORT && !integratedMetricsView && (
        <S.ImportFooter>
          <S.Save disabled={values.length === 0 || Object.values(errors).length > 0} onClick={submitForm}>
            {csvImportLabel}
          </S.Save>

          <S.GoBack
            type="ghost"
            onClick={() => {
              refetch();
              setMode(Mode.EDIT);
            }}
          >
            {cancelLabel}
          </S.GoBack>
        </S.ImportFooter>
      )}
    </S.Wrapper>
  );
};

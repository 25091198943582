import { V2Collapse } from '@/components/ui/v2-collapse/v2-collapse';
import { V2Icon } from '@/components/ui/v2-icon/v2-icon';
import { V2Link } from '@/components/ui/v2-link/v2-link';
import { V2ProfileCardHeader } from '@/components/ui/v2-profile-card-header/v2-profile-card-header';
import { AdvisorRole } from '@/domain/accounts/roles';
import type { AdvisorProfilePreviewData } from '@/modules/founder/advisor-search/api/get-advisor-profile-preview-details';
import { getWysiWygValueSafely } from '@/pages/content/profile/hooks/use-wysiwyg';
import { Links } from '@/router/links';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { SanitizeWysiwyg } from '@utils/fns/sanitize-wysiwyg';

import S from './modal-content.styles';

type AdvisorPreviewModalContentProps = {
  data: AdvisorProfilePreviewData & { id: string };
  roleType: AdvisorRole;
};

export const AdvisorPreviewModalContent: React.FC<AdvisorPreviewModalContentProps> = ({ data, roleType }) => {
  const [
    viewFullProfileLL,
    bioTabTitleLL,
    experiencesTabTitleLL,
    industryLL,
    primarySkillsetLL,
    secondarySkillsetLL,
    roleTypeLL,
    compensationLL,
    aboutMeLL,
    offeringLL,
    expectationsLL,
    cvLL,
    linkedInLL,
  ] = useTranslation([
    'advisors.search.previewModal.header.viewFullProfile',
    'advisors.search.previewModal.tabs.bio.title',
    'advisors.search.previewModal.tabs.experiences.title',
    'profile.preview.ned.preferences.industry',
    'profile.preview.ned.preferences.primarySkillset',
    'profile.preview.ned.preferences.secondarySkillset',
    'profile.preview.ned.preferences.roleType',
    'profile.preview.ned.preferences.compensation',
    'profile.preview.ned.aboutMe.aboutMe',
    'profile.preview.ned.aboutMe.offering',
    'profile.preview.ned.aboutMe.expectations',
    'global.cv',
    'global.LinkedIn',
  ]);

  const getCompensation = (advisorRole?: AdvisorRole) => {
    let compensations = data.compensations;
    if (advisorRole) compensations = data.compensations.filter((c) => c.role === advisorRole);

    return [...new Set(compensations.map((c) => c.compensationType))].join(', ');
  };

  const items = [
    { label: industryLL, value: data.industries.join(', ') },
    { label: primarySkillsetLL, value: data.primarySkillSet },
    { label: secondarySkillsetLL, value: data.secondarySkillSet.join(', ') },
    {
      label: roleTypeLL,
      value: (() =>
        [
          data.isAdvisor ? AdvisorRole.ADVISOR : null,
          data.isNed ? AdvisorRole.NED : null,
          data.isFractional ? AdvisorRole.FRACTIONAL : null,
        ]
          .filter(Boolean)
          .join(', '))(),
    },
    { label: compensationLL, value: getCompensation(roleType) },
  ].filter((item) => Boolean(item.value));

  const collapsedItems = [
    {
      key: 'about',
      title: aboutMeLL,
      content: getWysiWygValueSafely(data.aboutMe) ? (
        <div
          dangerouslySetInnerHTML={{
            __html: SanitizeWysiwyg(JSON.parse(data.aboutMe as unknown as string)),
          }}
        />
      ) : null,
    },
    {
      key: 'offering',
      title: offeringLL,
      content: getWysiWygValueSafely(data.myOffering) ? (
        <div
          dangerouslySetInnerHTML={{
            __html: SanitizeWysiwyg(JSON.parse(data.myOffering as unknown as string)),
          }}
        />
      ) : null,
    },
    {
      key: 'expectations',
      title: expectationsLL,
      content: getWysiWygValueSafely(data.myExpectations) ? (
        <div
          dangerouslySetInnerHTML={{
            __html: SanitizeWysiwyg(JSON.parse(data.myExpectations as unknown as string)),
          }}
        />
      ) : null,
    },
  ].filter((item) => Boolean(item.content));

  const getHeaderSubtitle = () => {
    if (data.experience.length === 0 && !data.resume?.url && !data.linkedinUrl) {
      return null;
    }

    return (
      <S.CardSubtitle>
        {data.experience.length ? `${data.experience.length} positions` : null}
        {data.resume?.url ? (
          <V2Link to={data.resume.url} outer>
            <V2Icon name="document" />
            {cvLL}
          </V2Link>
        ) : null}
        {data.linkedinUrl ? (
          <V2Link to={data.linkedinUrl} outer>
            <V2Icon name="linkedin" />
            {linkedInLL}
          </V2Link>
        ) : null}
      </S.CardSubtitle>
    );
  };

  const bioTabContent = (
    <div>
      <S.StyledAttributesList>
        {items.map((item) => (
          <li key={item.label}>
            <span>{item.label}</span>
            <span>{item.value}</span>
          </li>
        ))}
      </S.StyledAttributesList>
      <V2Collapse items={collapsedItems} />
    </div>
  );

  return (
    <div data-testid="advisor-preview-modal-content">
      <V2ProfileCardHeader
        name={data.name}
        url={Links.NED_PUBLIC_PROFILE(data.id)}
        country={data.countryOfResidence}
        avatarUrl={data.profilePicture?.url}
        subtitle={getHeaderSubtitle()}
      />
      <S.LinkWrapper>
        <V2Link to={Links.NED_PUBLIC_PROFILE(data.id)} variant="primary">
          {viewFullProfileLL} <V2Icon name="box-arrow-up-right" />
        </V2Link>
      </S.LinkWrapper>

      <S.V2Tabs
        gaps="none"
        gradientColor="blue-gradient"
        items={[
          { key: 'bio', title: bioTabTitleLL, content: bioTabContent },
          { key: 'experiences', title: experiencesTabTitleLL, content: null, disabled: true },
        ]}
      />
    </div>
  );
};

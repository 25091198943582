import type { IGetInvestorPersonalDetails } from '@pages/content/profile/investor/api/types';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const INVESTOR_PERSONAL_DETAILS_CACHE_KEY = 'investor-personal-details-cache-key';

export type InvestorPersonalDetailsResponseData = IGetInvestorPersonalDetails;

export const getInvestorPersonalDetails = async (): Promise<GraphqlResponse<InvestorPersonalDetailsResponseData>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
    query getInvestorPersonalDetails {
      getInvestorPersonalDetails {
        email,
        firstName,
        lastName,
        gender,
        recentOccupation,
        recentCompany,
        companyName,
        countryOfResidence,
        phoneNumber,
        linkedinUrl,
        twitterUrl,
        aboutMe,
        type
        profilePicture {
          url,
          path
        }
      },
    }
  `,
  });

  return graphqlResponseWrapper(data, ['getInvestorPersonalDetails']);
};

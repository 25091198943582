import { type ColorThemeMode } from '@/context/theme/app-theme.context';
import { getLsThemeKey } from '@/context/theme/app-theme.provider';
import { useTheme } from '@/hooks/use-theme';
import { useEffect } from 'react';

export const useEnforceTheme = (targetMode: ColorThemeMode) => {
  const { setMode } = useTheme();

  useEffect(() => {
    setMode(targetMode);

    return () => {
      const userPreference = window.localStorage.getItem(getLsThemeKey());
      setMode(userPreference === 'dark' ? 'dark' : 'light');
    };
  }, []);
};

/* eslint-disable @typescript-eslint/no-use-before-define */
import { Roles } from '@domain/accounts/roles';
import { createNewShareholderAction } from '@pages/content/pulse/api/create-new-shareholder';
import {
  getInvestmentForFounderAction,
  GET_INVESTMENT_FOR_FOUNDER_CACHE_KEY,
} from '@pages/content/pulse/api/get-founder-investment.action';
import { updateSingleShareholderAction } from '@pages/content/pulse/api/update-single-shareholder.action';
import AvatarWithName from '@parts/avatar-with-name/avatar-with-name';
import EditorDrawer from '@parts/editor-drawer/editor-drawer';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { pulseInvestorsKey } from '@router/tabs-keys';
import { useQuery } from '@tanstack/react-query';
import {
  getActiveRegisteredFundingRounds,
  useInvestmentsEditor,
} from '@utils/hooks/use-investments-editor/use-investments-editor';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useEffect } from 'react';
import { EditorDetails } from '../../../../parts/editor-details/editor-details';
import type { FormikState } from './editor-interfaces';
import { prepareDataForInitialValues, prepareDataForUpdate } from './helpers';
import { useValidationSchema } from './validation-schema';

const initialFormikValues = {
  nameOrEmail: null,
  investorId: null,
  investments: [],
  investingRelationshipId: null,
};

type UpdateEditorProps = {
  drawer: Function;
  hideDrawer: Function;
  id: string;
  investmentId: string;
  refetch: () => void;
};

export const UpdateEditor = ({
  drawer: Drawer,
  hideDrawer,
  refetch,
  id = '',
  investmentId = '',
}: UpdateEditorProps) => {
  const [titleLabel] = useTranslation(['pulse.founder.drawer.title']);

  const { data: investmentResponse, isLoading } = useQuery(
    [GET_INVESTMENT_FOR_FOUNDER_CACHE_KEY(investmentId)],
    getInvestmentForFounderAction(investmentId),
    {
      enabled: Boolean(investmentId),
    },
  );

  const initialValues = investmentResponse?.data
    ? prepareDataForInitialValues(investmentResponse.data)
    : initialFormikValues;

  useEffect(() => {
    if (investmentResponse?.data?.confirmed) {
      goToNone();
    }
  }, [investmentResponse]);

  const onClear = () => {
    setValues(initialFormikValues);
  };

  const onSubmit = async (v: FormikState) => {
    const data = prepareDataForUpdate(v);

    await update({ investmentId, relationId: id, investment: data.investments[0] });
    refetch();
  };

  const {
    update,
    createdFundingRounds,
    onClose,
    goToNone,
    formik: {
      setFieldValue,
      getFieldProps,
      getFieldMeta,
      submitForm,
      values: formikValues,
      setValues,
      setFieldTouched,
      dirty,
      isValid,
      submitCount,
    },
    deleteInvestment,
  } = useInvestmentsEditor({
    onClear,
    hideDrawer,
    validationSchema: useValidationSchema,
    initialValues,
    onSubmit,
    createAction: createNewShareholderAction,
    updateAction: updateSingleShareholderAction,
    trackedPageParam: pulseInvestorsKey,
    isShareholder: true,
  });

  const values = formikValues as FormikState;
  const selectedFundingRounds = getActiveRegisteredFundingRounds(values.investments);

  useEffect(() => {
    if (id) {
      createdFundingRounds.refetch();
    }
  }, [values.investingRelationshipId, id]);

  const commonProps = { setFieldValue, getFieldProps, getFieldMeta, setFieldTouched };

  return (
    <EditorDrawer
      drawer={Drawer}
      search={values.nameOrEmail && <AvatarWithName avatarUrl="" name={values.nameOrEmail} />}
      title={titleLabel}
      onClose={onClose}
      isLoading={isLoading}
      footer={{
        onConfirm: submitForm,
        disabledEvaluation: {
          dirty,
          submitCount,
          isValid: isValid && Boolean(values.investments.length),
        },
        onCancel: onClose,
      }}
    >
      {values.nameOrEmail && (
        <>
          {!createdFundingRounds.isFundingRoundsApiCalled ? (
            <FullHeightSpinner small />
          ) : (
            <EditorDetails
              key={`${values.nameOrEmail}`}
              index={0}
              foundingRounds={{
                registered: createdFundingRounds.data || [],
                externalTypes: [],
                activeRegistered: selectedFundingRounds,
              }}
              role={Roles.FOUNDER}
              deleteInvestment={deleteInvestment}
              {...commonProps}
              disableDeleting
            />
          )}
        </>
      )}
    </EditorDrawer>
  );
};

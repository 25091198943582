import ArrowRightIconBase from '@assets/icons/arrow-right.svg?react';
import { SecureLink as SecureLinkBase } from '@parts/link/link';
import { LogoSquare as LogoSquareBase } from '@parts/logo-square/logo-square';
import { Logo as LogoBase } from '@parts/logo/logo';
import { Button as ButtonBase } from 'antd';
import styled, { css } from 'styled-components';

const Wrapper = styled.header`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  padding-top: ${({ theme }) => theme.spacing.medium};
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: ${({ theme }) => theme.color.dashboardBackground};

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) {
    margin: ${({ theme }) => theme.spacing.xbase} 0;
    margin-top: 0;
  }
`;

const logo = css`
  margin-right: ${({ theme }) => theme.spacing.medium};
`;

const Logo = styled(LogoBase)`
  ${logo}
  width: 160px;
  display: none;

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    display: block;
  }
`;

const LogoSquare = styled(LogoSquareBase)`
  ${logo}

  display: none;
  width: 30px;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    display: block;
  }
`;

const Content = styled.div`
  flex-basis: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  padding: ${({ theme }) => theme.spacing.medium} ${({ theme }) => theme.spacing.xsmall};
  padding-top: 0;
  display: flex;

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: ${({ theme }) => theme.spacing.medium};
  }
`;

const Brand = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

const Slogan = styled.p`
  color: ${({ theme }) => theme.color.fontPrimary};
  font-size: ${({ theme }) => theme.fontSize.small};
  display: none;

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    display: block;
  }
`;

const GoTo = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
`;

const ArrowRight = styled(ArrowRightIconBase)`
  margin-left: ${({ theme }) => theme.fontSize.small};
`;
const SecureLink = styled(SecureLinkBase)`
  text-decoration: none;
`;

export default { Wrapper, Content, Logo, LogoSquare, Brand, Slogan, GoTo, ArrowRight, SecureLink };

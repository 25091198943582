import { Divider as DividerComponent } from 'antd';
import styled from 'styled-components';

const Divider = styled(DividerComponent)`
  border-color: ${({ theme }) => theme.baseColors.Blue600};
  margin-top: ${({ theme }) => theme.spacing.xlarge};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`;

export default { Divider };

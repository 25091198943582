import { axiosInstance } from '@utils/axios/axios-instance';
import type { AxiosConfig } from '@utils/axios/types';

export interface ResendConfirmationLinkPayload {
  token: string;
}

export const resendConfirmationLinkAction = async (payload: ResendConfirmationLinkPayload) =>
  axiosInstance.post('/resend-confirmation-link', payload, {
    skipAuthorization: true,
  } as AxiosConfig);

// TODO: CON-11585
//  This code requires urgent refactoring or rewriting from scratch due to its lack of readability,
//  structure, and potential security threats. It is recommended to conduct a thorough code review to
//  identify and fix issues related to performance, scalability, and security.

import { InvestmentsScale } from '@pages/content/profile/investor/api/types';
import type { PreviousInvestmentWithId } from '@pages/content/profile/investor/parts/investment/investment';
import { PillButtonCheckbox } from '@parts/pill-button-checkbox/pill-button-checkbox';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { HTMLAttributes } from 'react';
import type { Any } from 'src/types';
import S from './investment-section.styles';

interface InvestmentSectionProps extends HTMLAttributes<HTMLElement> {
  updateInvestments: (payload: PreviousInvestmentWithId[]) => Promise<Any>;
  updateInvestmentsScale: (payload: InvestmentsScale) => Promise<Any>;
  investmentsScale: InvestmentsScale;
  disabled?: boolean;
}

const InvestmentsScaleOptions = [
  { label: '0', payload: InvestmentsScale.NONE },
  { label: '1-3', payload: InvestmentsScale.ONE_TO_THREE },
  { label: '4-10', payload: InvestmentsScale.FOUR_TO_TEN },
  { label: '11+', payload: InvestmentsScale.MORE_THAN_TEN },
];

export const InvestmentSection = ({
  updateInvestments,
  updateInvestmentsScale,
  investmentsScale,
  disabled = false,
  ...restProps
}: InvestmentSectionProps) => {
  const [title] = useTranslation(['investor-onboarding.investments.investmentSection.title']);

  return (
    <section data-testid="previous-investments-section" {...restProps}>
      <S.Group title={title}>
        {InvestmentsScaleOptions.map((opt) => (
          <PillButtonCheckbox
            key={opt.payload}
            onChange={() => updateInvestmentsScale(opt.payload)}
            checked={investmentsScale === opt.payload}
            disabled={disabled}
          >
            {opt.label}
          </PillButtonCheckbox>
        ))}
      </S.Group>
    </section>
  );
};

import RemoveFileIcon from '@assets/icons/delete-icon.svg?react';
import DownloadIcon from '@assets/icons/download-icon.svg?react';
import { Button as ButtonBase } from 'antd';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 15px;
  background-color: ${({ theme }) => theme.baseColors.Blue300};
  height: 100%;
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: ${({ theme }) => theme.radius.default};
  height: ${({ theme }) => theme.ui.inputHeight};
  color: ${({ theme }) => theme.baseColors.Grey100};
`;

const iconSize = css`
  width: 25px;
  height: 19px;
  margin-left: 10px;
`;

const Actions = styled.div`
  display: flex;
`;

const RemoveFile = styled(RemoveFileIcon)`
  ${iconSize}
  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
`;

const DownloadFile = styled(DownloadIcon)`
  ${iconSize}
  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
`;

const Button = styled(ButtonBase)`
  height: 25px;
  padding: 0;
  display: flex;
  align-items: center;
`;

export default { Wrapper, Button, RemoveFile, DownloadFile, Actions };

import WideTableBase from '@parts/wide-table/wide-table';
import styled from 'styled-components';

const WideTable = styled(WideTableBase)`
  .ant-table-tbody > tr > td {
    padding: 0;
    border: none;
  }
`;

const Wrapper = styled.div``;

export default { WideTable, Wrapper };

import styled from 'styled-components';

const Content = styled.div`
  padding: 30px 20px;
`;

const Message = styled.div`
  text-align: center;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 15px;
  margin-top: 40px;
`;

export default { Content, Footer, Message };

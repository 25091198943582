import { AdvisorRole } from '@/domain/accounts/roles';
import { useSavedParamsForQuery } from '@/hooks/use-saved-params-for-query';
import { createAdvisorSearchSearchQuery } from '@/modules/founder/homepage/components/advisor-quick-search-questions-modal/components/utils';
import { Links } from '@/router/links';
import type { Any } from '@/types';
import { safeParseJson } from '@/utils/fns/safe-parse-json';
import { FounderLayout } from '@layout/founder/founder.layout';
import { ConditionalRender } from '@parts/condition-renderer/conditional-renderer';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { PageHeader } from '@parts/page-header/page-header';
import { Routes } from '@router/routes';
import { useQuery } from '@tanstack/react-query';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { parse } from 'query-string';
import { Redirect, useParams } from 'react-router-dom';
import { PublishEmptyState } from '../lens/parts/publish-empty-state/publish-empty-state';
import {
  GET_FOUNDER_PUBLISH_STATUS_CACHE_KEY,
  getFounderPublishStatusAction,
} from '../profile/founder/api/get-founder-publish-status/get-founder-publish-status.action';
import { publishFounderProfileAction } from '../profile/founder/api/publish-founder-profile/publish-founder-profile.action';
import { AdvisorSearch } from './parts/tab-contents/advisor';
import { FractionalSearch } from './parts/tab-contents/fractional';
import { NedSearch } from './parts/tab-contents/ned';
import { PlacementsSearch } from './parts/tab-contents/placements';
import { Tabs, type TabItem } from './parts/tabs/tabs';

export const AdvisorsSearchPage = () => {
  const history = useHistory();
  const { role } = useParams<{ role: string }>();
  const [
    pageTitle,
    placementsTabTitle,
    placementsTabTitleTooltip,
    advisorTabTitle,
    advisorTabTitleTooltip,
    nedTabTitle,
    nedTabTitleTooltip,
    fractionalTabTitle,
    fractionalTabTitleTooltip,
    emptyPrivateTitleLabel,
    emptyPrivateSubTitleLabel,
    emptyPublishLabel,
    goToProfileLabel,
  ] = useTranslation([
    'advisors.search.title',
    'advisors.search.tabs.title.placements',
    'advisors.tiles.proBono.description',
    'advisors.search.tabs.title.advisor',
    'advisors.tiles.advisors.description',
    'advisors.search.tabs.title.ned',
    'advisors.tiles.neds.description',
    'advisors.search.tabs.title.fractional',
    'advisors.tiles.fractional.description',
    'advisors.search.publishEmptyState.title',
    'advisors.search.publishEmptyState.subtitle',
    'advisors.search.publishEmptyState.toggle.publish',
    'advisors.search.publishEmptyState.button.goToProfile',
  ]);

  // Do not fetch saved filters if they are already in the URL
  const shouldFetchPreviousFilters =
    !history.location.search.includes('filters') && !history.location.search.includes('regions');
  const { savedParamsDataResponse, isInitialSavedParamsLoading } = useSavedParamsForQuery({
    queryKey: 'FilterNedsByFounderQuery',
    enabled: shouldFetchPreviousFilters,
    onSuccess: (res) => {
      if (!res?.data?.lastSearchData) return;

      const { regions = [], ...filters } = safeParseJson<Record<string, Any>>(res.data.lastSearchData, {});

      history.push(history.location.pathname + '?' + createAdvisorSearchSearchQuery({ filters, regions }, role));
    },
  });

  const {
    data: founderPublishStatusResponse,
    isLoading: isLoadingFounderPublishStatus,
    refetch: refetchFounderPublishStatus,
  } = useQuery([GET_FOUNDER_PUBLISH_STATUS_CACHE_KEY], getFounderPublishStatusAction);

  const isValidRole = Object.values(AdvisorRole)
    .map((item) => item.toLowerCase())
    .includes(role);

  if (!isValidRole) {
    return <Redirect to={Routes.ADVISORS} />;
  }

  const onTabChange = (key: string) => {
    const filters = safeParseJson<Record<string, Any>>(parse(history.location.search).filters as string, {});
    const regions = safeParseJson<string[]>(parse(history.location.search).regions as string, []);

    history.push(Links.ADVISORS_SEARCH_ROLE(key) + '?' + createAdvisorSearchSearchQuery({ filters, regions }, key));
  };

  const isLoadingSavedFilters =
    shouldFetchPreviousFilters && isInitialSavedParamsLoading && !savedParamsDataResponse?.data;
  const shouldRenderLoader =
    isLoadingSavedFilters || isLoadingFounderPublishStatus || !founderPublishStatusResponse?.data;

  const { published, smartMatchShortages } = founderPublishStatusResponse?.data || {
    published: false,
    smartMatchShortages: [],
  };

  const publishProfileElement = (
    <PublishEmptyState
      published={published}
      smartMatchShortages={smartMatchShortages}
      refetch={refetchFounderPublishStatus}
      publishProfileAction={publishFounderProfileAction}
      translations={{
        emptyPrivateTitle: emptyPrivateTitleLabel,
        emptyPrivateSubTitle: emptyPrivateSubTitleLabel,
        emptyPublish: emptyPublishLabel,
        goToProfile: goToProfileLabel,
      }}
    />
  );

  const tabs: TabItem[] = [
    {
      key: AdvisorRole.PLACEMENT.toLowerCase(),
      title: placementsTabTitle,
      titleTooltip: placementsTabTitleTooltip,
      content: (
        <ConditionalRender condition={published} element={<PlacementsSearch />} fallback={publishProfileElement} />
      ),
    },
    {
      key: AdvisorRole.ADVISOR.toLowerCase(),
      title: advisorTabTitle,
      titleTooltip: advisorTabTitleTooltip,
      content: <ConditionalRender condition={published} element={<AdvisorSearch />} fallback={publishProfileElement} />,
    },
    {
      key: AdvisorRole.FRACTIONAL.toLowerCase(),
      title: fractionalTabTitle,
      titleTooltip: fractionalTabTitleTooltip,
      content: (
        <ConditionalRender condition={published} element={<FractionalSearch />} fallback={publishProfileElement} />
      ),
    },
    {
      key: AdvisorRole.NED.toLowerCase(),
      title: nedTabTitle,
      titleTooltip: nedTabTitleTooltip,
      content: <ConditionalRender condition={published} element={<NedSearch />} fallback={publishProfileElement} />,
    },
  ];

  return (
    <FounderLayout>
      <PageHeader title={pageTitle} />
      {shouldRenderLoader ? <FullHeightSpinner /> : <Tabs items={tabs} activeKey={role} onChange={onTabChange} />}
    </FounderLayout>
  );
};

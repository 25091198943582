import { useV2PaginationContext } from '../v2-pagination-context';

import S from '../v2-pagination.styles';

type NextPageButtonProps = {
  onClick: (page: number) => void;
};

export const NextPageButton = ({ onClick }: NextPageButtonProps) => {
  const { pagination } = useV2PaginationContext();
  const isDisabled = pagination.current >= pagination.total;
  const handleClick = () => onClick(pagination.current + 1);

  return (
    <S.Button
      name="chevron-right"
      onClick={handleClick}
      disabled={isDisabled}
      size="md"
      data-testid="next-page-button"
    />
  );
};

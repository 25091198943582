import { SecureLink } from '@parts/link/link';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { ReactNode } from 'react';
import { AdvisorLandingTile } from '../tile/tile';
import S from './advisor-tiles-list.styles';

export interface AdvisorTileDefinition {
  id: number | string;
  title: string;
  description: string;
  url: string;
  disabled?: boolean;
  badges?: ReactNode;
  buttonTestId: string;
}

interface AdvisorTileListProps {
  items: AdvisorTileDefinition[];
}

export const AdvisorTilesList = ({ items }: AdvisorTileListProps) => {
  const [buttonText] = useTranslation(['advisors.tiles.actionButton']);

  const makeTile = (tile: AdvisorTileDefinition) => {
    const actionElement = (
      <SecureLink href={tile.url} data-testid={tile.buttonTestId}>
        <S.Button variant="outlined" size="large">
          {buttonText}
        </S.Button>
      </SecureLink>
    );

    return (
      <AdvisorLandingTile
        title={tile.title}
        description={tile.description}
        action={actionElement}
        badges={tile.badges}
        disabled={tile.disabled}
      />
    );
  };

  return (
    <S.List>
      {items.map((item) => (
        <S.ListItem key={item.id}>{makeTile(item)}</S.ListItem>
      ))}
    </S.List>
  );
};

import AlertBase from '@parts/alert/alert';
import styled from 'styled-components';

const AutoConnectAlert = styled(AlertBase)`
  width: 100%;
  max-width: 500px;
  margin: 20px auto;
  display: flex;
  align-items: center;

  > span {
    font-size: ${({ theme }) => theme.fontSize.medium};
  }

  .ant-alert-message {
    justify-content: flex-start;
    margin: 0;
  }
`;
const NotConnectedAlert = styled.span`
  font-weight: bold;
`;

const AutoConnectWrapper = styled.div`
  display: inline-block;
  white-space: pre-wrap;
`;
export default { AutoConnectAlert, NotConnectedAlert, AutoConnectWrapper };

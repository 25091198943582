import { DateFormat } from '@i18n/date-formats';
import { idQueryParam, investmentIdQueryParam } from '@pages/content/pulse/founder/parts/editor/create-editor';
import { TableColumn } from '@parts/table-column/table-column';
import { Links } from '@router/links';
import { Routes } from '@router/routes';
import { currencyToShortFormat } from '@utils/fns/currency-to-short-format';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import type { ColumnsType } from 'antd/lib/table';
import type { Any } from 'src/types';
import type { Investment } from '../../../editor/editor-interfaces';
import RoundTypeTooltip from '../../../round-type-tooltip/round-type-tooltip';
import { Archived } from '../archived-label/archived-label';
import { PortfolioDropdownList } from './dropdown-list';

export const Columns = ({
  t,
  refetches,
  renderSource,
}: {
  t: { [key: string]: string };
  refetches: Function;
  renderSource?: 'company-details';
}): ColumnsType<Investment> => {
  const { dateFormatter } = useUserDateFormat();

  return [
    {
      title: t.dateLabel,
      render: (record: Any) => (
        <TableColumn title={t.dateLabel}>
          {dateFormatter(record.round.date, DateFormat.LETTER_MONTH_LONG_YEAR)}
        </TableColumn>
      ),
    },
    {
      title: t.roundLabel,
      render: (record: Any) => (
        <TableColumn title={t.roundLabel}>
          <RoundTypeTooltip confirmed={record.confirmed} typeName={record.round.type} initiator={record.initiator} />
        </TableColumn>
      ),
    },
    {
      title: t.sharesLabel,
      dataIndex: 'shares',
      key: 'shares',
      render: (v: Any) => <TableColumn title={t.sharesLabel}>{v}</TableColumn>,
    },
    {
      title: t.ownedLabel,
      dataIndex: 'percentageOwnership',
      key: 'percentageOwnership',
      render: (v: Any) => <TableColumn title={t.ownedLabel}>{v ? `${v}%` : v}</TableColumn>,
    },
    {
      title: t.preMoneyValLabel,
      dataIndex: 'preMoneyVal',
      key: 'preMoneyVal',
      render: (v: Any) => <TableColumn title={t.preMoneyValLabel}>{v ? currencyToShortFormat(v, '£') : v}</TableColumn>,
    },
    {
      title: t.postMoneyValLabel,
      dataIndex: 'postMoneyVal',
      key: 'postMoneyVal',
      render: (v: Any) => (
        <TableColumn title={t.postMoneyValLabel}>{v ? currencyToShortFormat(v, '£') : v}</TableColumn>
      ),
    },
    {
      title: t.investedLabel,
      dataIndex: 'amount',
      key: 'amount',
      render: (v: Any) => <TableColumn title={t.investedLabel}>{v ? currencyToShortFormat(v, '£') : v}</TableColumn>,
      width: '120px',
    },
    {
      title: t.currentValueLabel,
      dataIndex: 'currentValue',
      key: 'currentValue',
      render: (v: Any) => (
        <TableColumn title={t.currentValueLabel}>{v ? currencyToShortFormat(v, '£') : v}</TableColumn>
      ),
      width: '150px',
    },
    {
      title: '',
      render: (inv: Any) =>
        inv.archived ? (
          <Archived />
        ) : (
          <PortfolioDropdownList
            refetch={() => refetches()}
            editHref={(() => {
              if (renderSource && renderSource === 'company-details') {
                return Links.PORTFOLIO_COMPANY_DETAILS(inv.investingRelationshipId).concat(
                  `?${investmentIdQueryParam}=${inv.id}`,
                );
              }
              return `${Routes.PORTFOLIO}?${idQueryParam}=${inv.investingRelationshipId}&${investmentIdQueryParam}=${inv.id}`;
            })()}
            investmentId={inv.id}
          />
        ),
    },
  ];
};

import { useQuery } from '@tanstack/react-query';
import { GET_CONNECTION_NOTES_KEY, getConnectionNotesQuery } from '../api/get-connection-notes.query';

type GetConnectionNotesQueryConfig = {
  targetAccountId?: string;
  isVisible?: boolean;
};

export const useGetConnectionNotes = ({ targetAccountId, isVisible }: GetConnectionNotesQueryConfig) => {
  const { data, isLoading, isFetching, error, isFetched, refetch, isInitialLoading, ...restQueryResult } = useQuery(
    [GET_CONNECTION_NOTES_KEY, targetAccountId],
    () => getConnectionNotesQuery({ targetAccountId }),
    {
      refetchOnWindowFocus: true,
      cacheTime: 0,
      enabled: isVisible,
    },
  );

  return {
    ...restQueryResult,
    connectionNotesData: data,
    connectionNotesError: error,
    isConnectionNotesDataLoading: isLoading,
    isConnectionNotesDataFetching: isFetching,
    isConnectionNotesInitialLoading: isInitialLoading,
    refetchConnectionNotes: refetch,
  };
};

import type { HTMLAttributes } from 'react';
import { useV2AriaMobileConnectionCardContext } from '../v2-aria-mobile-connection-card-context';

import S from '../v2-aria-mobile-connection-card.styles';

type FooterProps = {
  children: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export const Footer = ({ children, ...rest }: FooterProps) => {
  useV2AriaMobileConnectionCardContext();

  return <S.Footer {...rest}>{children}</S.Footer>;
};

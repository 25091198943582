import { useQuery } from '@tanstack/react-query';
import { GET_SKILLS_AND_INDUSTRIES_KEY, getSkillsAndIndustries } from '../api/get-skills-and-industries.query';

export const useGetSkillsAndIndustries = () => {
  const { data, error, isLoading, ...restQueryResult } = useQuery({
    queryFn: getSkillsAndIndustries,
    queryKey: [GET_SKILLS_AND_INDUSTRIES_KEY],
  });

  return {
    ...restQueryResult,
    isLoading,
    industries: data?.data?.industries,
    skillSets: data?.data?.skillSets,
  };
};

import { V2Sort } from '@/components/ui/v2-sort/v2-sort';
import { useV2AriaTableContext } from '../v2-aria-table-context';
import S from '../v2-aria-table.styles';

type HeadCellProps = {
  id: string;
  children?: React.ReactNode;
  onSort?: () => void;
};

export const HeadCell = ({ id, children, onSort }: HeadCellProps) => {
  const { sorting } = useV2AriaTableContext();
  const variant = sorting?.key === id ? sorting.order : undefined;

  return (
    <S.HeadCell>
      {children}
      {onSort && <V2Sort variant={variant} onClick={onSort} />}
    </S.HeadCell>
  );
};

import styled from 'styled-components';

const Wrapper = styled.article`
  height: 100%;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    height: 285px;
  }
`;

export default {
  Wrapper,
};

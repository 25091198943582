import { useQuery } from '@tanstack/react-query';
import { READINESS_SCORE_KEY, getReadinessScoreQuery } from '../api/get-readiness-score.query';

export const useGetReadinessScore = () => {
  const { data, isLoading, isFetched, isError, ...restQuery } = useQuery(
    [READINESS_SCORE_KEY],
    getReadinessScoreQuery,
    { refetchOnWindowFocus: false, cacheTime: 0 },
  );

  return {
    ...restQuery,
    readinessScoreData: data?.data,
    isReadinessScoreLoading: isLoading,
    isReadinessScoreFetched: isFetched,
    isReadinessScoreError: isError,
  };
};

import { Button as ButtonBase } from 'antd';
import styled from 'styled-components';

const AddRole = styled(ButtonBase)`
  margin-top: 30px;
  padding: ${({ theme }) => theme.spacing.medium};
  line-height: 0;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontSize.regular};

  & > a {
    text-decoration: none;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: 100%;
  }
`;

export default {
  AddRole,
};

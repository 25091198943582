import React, { type ReactNode } from 'react';
import { V2Badge } from '../v2-badge/v2-badge';
import type { V2BadgeProps } from '../v2-badge/v2-badge.types';

import S from './v2-widget-card.styles';

type V2WidgetCardProps = {
  badges?: V2BadgeProps[] | ReactNode;
};

export const V2WidgetCard: React.FC<V2WidgetCardProps> = ({ badges, children }) => {
  const getBadgesElements = (b: V2BadgeProps[] | ReactNode): ReactNode => {
    if (React.isValidElement(b)) return b;
    return (b as V2BadgeProps[]).map((badge) => <V2Badge key={badge.text} {...badge} />);
  };

  return (
    <S.CardWrapper>
      <S.ContentWrapper>{children}</S.ContentWrapper>
      {badges ? <S.BadgeWrapper data-testid="badge-wrapper">{getBadgesElements(badges)}</S.BadgeWrapper> : null}
    </S.CardWrapper>
  );
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export interface Dashboard {
  name: string;
  id: string;
}

export const GET_FOUNDER_DASHBOARDS_FOR_VIEWER_QUERY_CACHE_KEY = 'get-founder-dashboards-for-viewer';

export const getFoundersDashboardsForViewer =
  (founderId: string) => async (): Promise<GraphqlResponse<Dashboard[]>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
      query getFoundersDashboardsForViewer {
        foundersDashboardsForViewer(founderId: "${founderId}") {
          name,
          id
        }
      }
`,
    });

    return graphqlResponseWrapper(data, ['foundersDashboardsForViewer']);
  };

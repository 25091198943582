import styled from 'styled-components';

const CardWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
`;

const ContentWrapper = styled.div`
  min-height: 153px;
  width: 100%;
  align-self: stretch;
  border: 1px solid ${({ theme }) => (theme.v2.mode === 'light' ? theme.v2.colors.blue[50] : theme.v2.colors.blue[500])};
  padding: 16px;
  background: ${({ theme }) => (theme.v2.mode === 'light' ? theme.v2.colors.neutral[50] : theme.v2.colors.blue[700])};
  border-radius: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  display: inline-flex;
`;

const BadgeWrapper = styled.div`
  position: absolute;
  right: 15px;
  top: -10px;
  gap: 4px;
  display: inline-flex;
`;

export default {
  CardWrapper,
  ContentWrapper,
  BadgeWrapper,
};

import { LazyMonthDatePicker } from '@parts/date-picker/date-picker.lazy';
import { FullWidthField } from '@parts/forms/formik-full-field/formik-full-field';
import { HalfWidthField } from '@parts/forms/formik-half-field/formik-half-field';
import { FormikLabel } from '@parts/forms/formik-label/formik-label';
import { QuarterWidthField } from '@parts/forms/formik-quarter-field/formik-quarter-field';
import { NumberInput } from '@parts/forms/number-input/number-input';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';

import { Roles } from '@domain/accounts/roles';
import {
  ConvertibleLoanNotes,
  getAdditionalInfoSelectOptionsAction,
  GET_ADDITIONAL_INFO_SELECT_OPTIONS_CACHE_KEY,
  Options,
  ShareClasses,
} from '@pages/content/portfolio/investor/api/get-additional-info-select-options.action';
import { useQuery } from '@tanstack/react-query';
import { Input } from 'antd';
import S from './additional-info.styles';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { DateFormat } from '@i18n/date-formats';

export const AdditionalInfo = ({
  index,
  getFieldProps,
  getFieldMeta,
  onChange,
  role,
}: {
  index: number;
  getFieldProps: Function;
  getFieldMeta: Function;
  onChange: Function;
  role: Roles.INVESTOR | Roles.FOUNDER;
}) => {
  const [
    shareClassLabel,
    ordinaryLabel,
    preferenceLabel,
    nonVotingLabel,
    redeemableLabel,
    leadInvestorLabel,
    personalNoteLabel,
    visibleLabel,
    termsLabel,
    warrantsLabel,
    expiryLabel,
    amountLabel,
    priceLabel,
    optionsLabel,
    vestingLabel,
    goodLeaverLabel,
    badLeaverLabel,
    loanNoteLabel,
    couponLabel,
    payoutLabel,
    backstopLabel,
    discountLabel,
  ] = useTranslation([
    'portfolio.investor.drawer.additionalInfo.shareClass',
    'portfolio.investor.drawer.additionalInfo.shareClass.ordinary',
    'portfolio.investor.drawer.additionalInfo.shareClass.preference',
    'portfolio.investor.drawer.additionalInfo.shareClass.nonVoting',
    'portfolio.investor.drawer.additionalInfo.shareClass.redeemable',
    'portfolio.investor.drawer.additionalInfo.leadInvestor',
    'portfolio.investor.drawer.additionalInfo.personalNote',
    'portfolio.investor.drawer.additionalInfo.personalNote.visible',
    'portfolio.investor.drawer.additionalInfo.terms',
    'portfolio.investor.drawer.additionalInfo.warrants',
    'portfolio.investor.drawer.additionalInfo.expiry',
    'portfolio.investor.drawer.additionalInfo.amount',
    'portfolio.investor.drawer.additionalInfo.price',
    'portfolio.investor.drawer.additionalInfo.options',
    'portfolio.investor.drawer.additionalInfo.options.vesting',
    'portfolio.investor.drawer.additionalInfo.options.goodLeaver',
    'portfolio.investor.drawer.additionalInfo.badLeaver',
    'portfolio.investor.drawer.additionalInfo.loanNote',
    'portfolio.investor.drawer.additionalInfo.loanNote.coupon',
    'portfolio.investor.drawer.additionalInfo.loanNote.payout',
    'portfolio.investor.drawer.additionalInfo.loanNote.backstop',
    'portfolio.investor.drawer.additionalInfo.discount',
  ]);

  const { dateFormatter } = useUserDateFormat();

  const investmentKey = `investments[${index}]`;

  const shareClassKey = `${investmentKey}.shareClass`;
  const leadInvestorKey = `${investmentKey}.leadInvestor`;
  const personalNoteKey = `${investmentKey}.personalNote`;
  const warrantsAmountKey = `${investmentKey}.terms.warrants.amount`;
  const warrantsExpiryKey = `${investmentKey}.terms.warrants.expiry`;
  const warrantsPriceKey = `${investmentKey}.terms.warrants.price`;
  const optionsKey = `${investmentKey}.terms.options`;
  const convertibleLoanNoteKey = `${investmentKey}.terms.convertibleLoanNote`;
  const discountKey = `${investmentKey}.terms.discount`;

  const shareClassProps = getFieldProps(shareClassKey);
  const termsDateProps = getFieldProps(warrantsExpiryKey);
  const optionsProps = getFieldProps(optionsKey);
  const loanNoteProps = getFieldProps(convertibleLoanNoteKey);

  const { data: response } = useQuery(
    [GET_ADDITIONAL_INFO_SELECT_OPTIONS_CACHE_KEY],
    getAdditionalInfoSelectOptionsAction,
  );

  const shareClasses = response?.data?.shareClasses || [];
  const options = response?.data?.options || [];
  const convertibleLoanNotes = response?.data?.convertibleLoanNotes || [];

  const shareClassLabels = {
    [ShareClasses.ORDINARY]: ordinaryLabel,
    [ShareClasses.PREFERENCE]: preferenceLabel,
    [ShareClasses.NON_VOTING]: nonVotingLabel,
    [ShareClasses.REDEEMABLE]: redeemableLabel,
  };

  const optionsLabels = {
    [Options.VESTING]: vestingLabel,
    [Options.GOOD_LEAVER]: goodLeaverLabel,
    [Options.BAD_LEAVER]: badLeaverLabel,
  };

  const convertibleLoanNotesLabels = {
    [ConvertibleLoanNotes.COUPON_INTEREST]: couponLabel,
    [ConvertibleLoanNotes.PAYOUT]: payoutLabel,
    [ConvertibleLoanNotes.BACKSTOP]: backstopLabel,
  };

  return (
    <>
      <S.FormikRow>
        <FullWidthField
          label={{
            for: shareClassKey,
            label: <FormikLabel>{shareClassLabel}</FormikLabel>,
          }}
          {...getFieldMeta(shareClassKey)}
        >
          <S.Select
            value={shareClassProps.value || undefined}
            onSelect={(v) => onChange(v)(shareClassKey)}
            data-testid="shareclass-select"
          >
            <S.Select.Option key="empty" value="" data-testid="shareclass-option-empty">
              {' '}
            </S.Select.Option>
            {shareClasses.map((sc) => (
              <S.Select.Option key={sc} value={sc} data-testid={`shareclass-option-${sc}`}>
                {shareClassLabels[sc]}
              </S.Select.Option>
            ))}
          </S.Select>
        </FullWidthField>
      </S.FormikRow>

      <S.FormikRow>
        <FullWidthField
          label={{
            for: leadInvestorKey,
            label: <FormikLabel>{leadInvestorLabel}</FormikLabel>,
          }}
          {...getFieldMeta(leadInvestorKey)}
        >
          <Input
            data-testid="lead-investor-input"
            {...getFieldProps(leadInvestorKey)}
            onChange={(e) => onChange(e.target.value)(leadInvestorKey)}
          />
        </FullWidthField>
      </S.FormikRow>

      {role === Roles.INVESTOR && (
        <S.FormikRow>
          <FullWidthField
            label={{
              for: personalNoteKey,
              label: (
                <FormikLabel>
                  {personalNoteLabel} <S.LightLabel>{visibleLabel}</S.LightLabel>
                </FormikLabel>
              ),
            }}
            {...getFieldMeta(personalNoteKey)}
          >
            <S.PersonalNote
              data-testid="personal-note-input"
              {...getFieldProps(personalNoteKey)}
              rows={5}
              onChange={(e) => onChange(e.target.value)(personalNoteKey)}
            />
          </FullWidthField>
        </S.FormikRow>
      )}

      <S.Line />
      <S.Title>{termsLabel}</S.Title>
      <S.WarrantsLabel>{warrantsLabel}</S.WarrantsLabel>

      <S.FormikRow>
        <HalfWidthField
          label={{
            for: warrantsExpiryKey,
            label: <S.LightLabel>{expiryLabel}</S.LightLabel>,
          }}
          {...getFieldMeta(warrantsExpiryKey)}
        >
          <LazyMonthDatePicker
            data-testid="month-picker-terms-date"
            {...termsDateProps}
            value={termsDateProps.value ? new Date(termsDateProps.value) : undefined}
            onChange={(v: Date) => onChange(v)(warrantsExpiryKey)}
            format={(value: Date) => dateFormatter(value, DateFormat.FULL_MONTH_LONG_YEAR)}
            allowClear
          />
        </HalfWidthField>
        <QuarterWidthField
          label={{
            for: warrantsAmountKey,
            label: <S.LightLabel>{amountLabel}</S.LightLabel>,
          }}
          {...getFieldMeta(warrantsAmountKey)}
        >
          <NumberInput
            data-testid="warrnts-amount-input"
            {...getFieldProps(warrantsAmountKey)}
            onChange={(e) => onChange(e.target.value, true)(warrantsAmountKey)}
          />
        </QuarterWidthField>
        <QuarterWidthField
          label={{
            for: warrantsPriceKey,
            label: <S.LightLabel>{priceLabel}</S.LightLabel>,
          }}
          {...getFieldMeta(warrantsPriceKey)}
        >
          <NumberInput
            data-testid="warrants-price-input"
            {...getFieldProps(warrantsPriceKey)}
            onChange={(e) => onChange(e.target.value, true)(warrantsPriceKey)}
          />
        </QuarterWidthField>
      </S.FormikRow>

      <S.FormikRow>
        <FullWidthField
          label={{
            for: optionsKey,
            label: <FormikLabel>{optionsLabel}</FormikLabel>,
          }}
          {...getFieldMeta(optionsKey)}
        >
          <S.Select
            value={optionsProps.value || undefined}
            onSelect={(v) => onChange(v)(optionsKey)}
            data-testid="select-options"
          >
            <S.Select.Option key="empty" value="" data-testid="select-options-empty">
              {' '}
            </S.Select.Option>
            {options.map((o) => (
              <S.Select.Option key={o} value={o} data-testid={`select-options-${o}`}>
                {optionsLabels[o]}
              </S.Select.Option>
            ))}
          </S.Select>
        </FullWidthField>
      </S.FormikRow>

      <S.FormikRow>
        <FullWidthField
          label={{
            for: convertibleLoanNoteKey,
            label: <FormikLabel>{loanNoteLabel}</FormikLabel>,
          }}
          {...getFieldMeta(convertibleLoanNoteKey)}
        >
          <S.Select
            value={loanNoteProps.value || undefined}
            onSelect={(v) => onChange(v)(convertibleLoanNoteKey)}
            data-testid="lead-note-select"
          >
            <S.Select.Option key="empty" value="" data-testid="lean-note-empty-option">
              {' '}
            </S.Select.Option>
            {convertibleLoanNotes.map((cln) => (
              <S.Select.Option key={cln} value={cln} data-testid={`lean-note-${cln}`}>
                {convertibleLoanNotesLabels[cln]}
              </S.Select.Option>
            ))}
          </S.Select>
        </FullWidthField>
      </S.FormikRow>

      <S.FormikRow>
        <QuarterWidthField
          label={{
            for: discountKey,
            label: <FormikLabel>{discountLabel}</FormikLabel>,
          }}
          {...getFieldMeta(discountKey)}
        >
          <NumberInput
            data-testid="discount-input"
            {...getFieldProps(discountKey, true)}
            onChange={(e) => onChange(e.target.value, true)(discountKey)}
          />
        </QuarterWidthField>
      </S.FormikRow>
    </>
  );
};

import { useFormik } from 'formik';
import { isUndefined } from 'lodash';
import { V2Button } from '@/components/ui/v2-button/v2-button';
import { V2Modal } from '@/components/ui/v2-modal/v2-modal';
import { V2TitleGroup } from '@/components/ui/v2-title-group/v2-title-group';
import { useMediaQuery } from '@/hooks/use-media-query';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import * as Yup from 'yup';

import S from './investor-capital-deployment-modal.styles';

type InvestorCapitalDeploymentModalProps = {
  isOpen: boolean;
  initialValues: { isDeployingCapital?: boolean };
  callback: (value?: boolean) => void;
  close?: () => void;
};

export const InvestorCapitalDeploymentModal = ({
  isOpen,
  initialValues,
  callback,
  close,
}: InvestorCapitalDeploymentModalProps) => {
  const size = useMediaQuery();

  const formik = useFormik<{ isDeployingCapital?: boolean }>({
    initialValues: { isDeployingCapital: initialValues.isDeployingCapital },
    validationSchema: Yup.object({ isDeployingCapital: Yup.boolean().required() }),
    onSubmit: (val) => {
      callback(val.isDeployingCapital);
      close?.();
    },
  });

  const [title, subtitle, yes, no, done] = useTranslation([
    'homepage.investor.capitalDeployment.title',
    'homepage.investor.capitalDeployment.subtitle',
    'homepage.investor.capitalDeployment.yes',
    'homepage.investor.capitalDeployment.no',
    'homepage.investor.capitalDeployment.done',
  ]);

  const buttonWidth = ['xs'].includes(size);
  const buttonSize = ['xs', 'sm'].includes(size) ? 'small' : 'large';

  const options = [
    { value: true, label: yes },
    { value: false, label: no },
  ];

  const isButtonDisabled =
    Boolean(formik.errors.isDeployingCapital) || isUndefined(formik.values.isDeployingCapital) || formik.isSubmitting;

  return (
    <V2Modal isOpen={isOpen} closable={false} footer={null} size="lg">
      <S.Wrapper>
        <V2TitleGroup icon="cash-coin" title={title} subtitle={subtitle} align="center" maxWidth={640} />

        <S.ButtonSelectWrapper>
          {options.map((option) => (
            <V2Button
              isFull={buttonWidth}
              size={buttonSize}
              key={option.label}
              variant={formik.values.isDeployingCapital === option.value ? 'selected' : 'outlined'}
              onClick={(e) => {
                e.preventDefault();
                formik.setValues({ isDeployingCapital: option.value });
              }}
            >
              {option.label}
            </V2Button>
          ))}
        </S.ButtonSelectWrapper>

        <S.ButtonWrapper>
          <V2Button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              formik.submitForm();
            }}
            size={buttonSize}
            isFull={buttonWidth}
            disabled={isButtonDisabled}
          >
            {done}
          </V2Button>
        </S.ButtonWrapper>
      </S.Wrapper>
    </V2Modal>
  );
};

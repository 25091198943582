import { createContext, type Dispatch } from 'react';
import type { RoleManagementActionType, RoleManagementState } from './management.reducer';

export type RoleManagementStateApi = {
  state: RoleManagementState;
  dispatch: Dispatch<RoleManagementActionType>;
};

export const roleManagementInitialState: RoleManagementState = {
  roles: [],
  isDataLoaded: false,
};

export const RoleManagementContext = createContext<RoleManagementStateApi | RoleManagementState>(
  roleManagementInitialState,
);

/* eslint-disable react/prop-types */
import type { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import type { FC } from 'react';
import S from './info-link.styles';

export interface InfoLinkProps {
  title: string;
  description: string;
  imgUrl: string;
  dataTestId?: string;
  button: {
    text: string;
    theme: 'blue' | 'white';
    link: string;
    refetch?: (options?: RefetchOptions) => Promise<QueryObserverResult<string>>;
    openInNewTab?: boolean;
  };
}

const InfoLink: FC<InfoLinkProps> = ({ title, description, imgUrl, button, dataTestId }: InfoLinkProps) => {
  const InfoButton = () => {
    if (button?.refetch) {
      return (
        <S.Button
          data-testid={dataTestId}
          as="div"
          onClick={async () => {
            const redirectLink = await button.refetch!();

            if (redirectLink.data) {
              if (button.openInNewTab) {
                return window.open(redirectLink.data, '_blank', 'noreferrer,noopener');
              }

              window.location.href = redirectLink.data;
            }
          }}
          color={button.theme}
        >
          {button.text}
        </S.Button>
      );
    }

    if (button.link.startsWith('mailto:')) {
      return (
        <S.Button data-testid={dataTestId} as="a" href={button.link} color={button.theme}>
          {button.text}
        </S.Button>
      );
    }

    return button.openInNewTab ? (
      <S.Button data-testid={dataTestId} href={button.link} color={button.theme} outer target="_blank">
        {button.text}
      </S.Button>
    ) : (
      <S.Button data-testid={dataTestId} href={button.link} color={button.theme}>
        {button.text}
      </S.Button>
    );
  };

  return (
    <S.Wrapper data-testid="InfoLink-component">
      <S.InnerWrapper>
        <S.ImgHolder>{<img src={imgUrl} alt="info-icon" loading="lazy" />}</S.ImgHolder>
        <S.TextHolder>
          <S.Title>{title}</S.Title>
          <S.Description>{description}</S.Description>
        </S.TextHolder>
      </S.InnerWrapper>
      <InfoButton />
    </S.Wrapper>
  );
};

export default InfoLink;

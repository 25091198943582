import styled from 'styled-components';

const V2FlagImage = styled.img`
  width: 20px;
  height: 12px;
  vertical-align: sub;
  object-fit: contain;
`;

export default { V2FlagImage };

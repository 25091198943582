import { Switch } from 'react-router-dom';
import { ConditionalRoute } from './conditional-route';
import { AppRouteConfig } from './route.config';

export const AppRoutes = () => (
  <Switch>
    {Object.entries(AppRouteConfig()).map(([path, currentRoute]) =>
      <ConditionalRoute {...currentRoute} path={path} key={path} exact={currentRoute.exact ?? true} />)}
  </Switch>
);

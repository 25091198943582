import { Select as SelectBase, Space as SpaceBase } from 'antd';
import styled from 'styled-components';

const Select = styled(SelectBase)<{ $lightMode?: boolean; $centeredText?: boolean }>`
  border: 1px solid ${({ theme }) => theme.baseColors.Grey50};
  border-radius: ${({ theme }) => theme.radius.default};
  height: 50px;
  width: 100%;
  background-color: ${({ theme, $lightMode }) => ($lightMode ? theme.baseColors.White : 'transparent')} !important;
  border-color: ${({ theme }) => theme.baseColors.Grey50} !important;

  input {
    color: ${({ theme, $lightMode }) => ($lightMode ? theme.baseColors.Black : theme.baseColors.White)} !important;
  }

  .ant-select {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
  }

  .ant-select-arrow span {
    margin: 0;
    border-radius: ${({ theme }) => theme.radius.medium};
    background-color: ${({ theme, $lightMode }) => ($lightMode ? theme.baseColors.Blue300 : 'transparent')} !important;

    path {
      fill: ${({ theme, $lightMode }) => ($lightMode ? null : theme.baseColors.Grey250)} !important;
    }
  }

  .ant-select-arrow svg {
    padding: ${({ theme }) => theme.spacing.xxsmall};
    font-size: ${({ theme }) => theme.fontSize.regular} !important;
  }

  .ant-select-selector {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: transparent !important;
    height: 100% !important;
  }

  .ant-select-selection-item {
    text-align: ${({ $centeredText }) => ($centeredText ? 'center' : 'left')} !important;
    padding-right: 0 !important;
    color: ${({ theme, $lightMode }) => ($lightMode ? theme.baseColors.Black : theme.baseColors.Blue300)} !important;
    background-color: transparent !important;
  }

  .ant-select-selector:hover {
    border: 1px solid ${({ theme }) => theme.baseColors.Grey50} !important;
    outline: none !important;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:not(:focus),
  .ant-select-selector:active,
  .ant-select-selector:focus-visible,
  .ant-select-selector:hover,
  .ant-select-selector::selection,
  &[selected],
  .ant-select-open .ant-select-selector {
    border: none !important;
    border-color: none !important;
    outline: 0 !important;
  }

  .ant-select-disabled,
  .ant-picker.ant-picker-disabled {
    background-color: transparent !important;
    border-color: ${({ theme }) => theme.baseColors.Grey50} !important;
    opacity: 1 !important;
  }
`;

const Img = styled.img`
  height: 18px;
  width: 18px;
  vertical-align: sub;
`;

const Space = styled(SpaceBase)``;

export default {
  Select,
  Space,
  Img,
};

import { InstitutionType, InvestorType } from '@pages/content/profile/investor/api/types';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const INVESTOR_ONBOARDING_STATE_CACHE_KEY = 'investor-onboarding-state-cache';

export interface InvestorOnboardingStateResponseData {
  onboardingState: {
    onboardingStarted: boolean;
  };
  accountDetails: {
    type: InvestorType | null;
    institutionType: InstitutionType | null;
  };
}

export const getInvestorOnboardingState = async (): Promise<GraphqlResponse<InvestorOnboardingStateResponseData>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getInvestorOnboardingState {
        onboardingState: investorOnboardingState {
          onboardingStarted
        }
        accountDetails: getInvestorPersonalDetails {
          type
          institutionType
        },
      }
    `,
  });

  return graphqlResponseWrapper(data, ['onboardingState', 'accountDetails']);
};

import React from 'react';
import type { V2AvatarProps, V2AvatarSize } from './v2-avatar.types';

import S from './v2-avatar.styles';

const sizes: Record<V2AvatarSize, number> = {
  xs: 32,
  sm: 40,
  md: 56,
  lg: 72,
};

export const V2Avatar: React.FC<V2AvatarProps> = ({
  src,
  name = '',
  alt = '',
  size = 'md',
  className,
  'data-testid': testId = 'v2-avatar',
}) => {
  return (
    <S.V2Avatar className={className} alt={alt} src={src} size={sizes[size]} data-testid={testId}>
      {name[0] || ''}
    </S.V2Avatar>
  );
};

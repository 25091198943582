import styled from 'styled-components';

interface DotProps {
  active?: boolean;
}

const Dot = styled.div<DotProps>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme, active }) => (active ? theme.baseColors.Green : 'rgba(97, 106, 113, 0.4)')};
`;

export default { Dot };

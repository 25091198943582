import { Tooltip } from '@parts/tooltip/tooltip';
import { SwitchLabelled } from '@parts/switch-labelled/switch-labelled';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';

interface IPublishSwitch {
  isPublic: boolean;
  onClick: () => void;
  disabled?: boolean;
  publicLabel: string;
  notPublicLabel?: string;
}

export const PublishSwitch = ({ isPublic, onClick, disabled, publicLabel, notPublicLabel }: IPublishSwitch) => {
  const [defaultNotPublicLabel, publishLabel] = useTranslation([
    'profile.smartMatch.notPublicLabel',
    'profile.smartMatch.publishLabel',
  ]);

  const tooltip = <Tooltip title={publishLabel} />;

  const produceLabel = (label: string) => (
    <>
      {label} {tooltip}
    </>
  );

  return (
    <SwitchLabelled
      disabled={disabled}
      rightLabel={{
        default: produceLabel(isPublic ? publicLabel : notPublicLabel ?? defaultNotPublicLabel),
      }}
      isChecked={isPublic}
      onClick={onClick}
    />
  );
};

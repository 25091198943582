import { AdvisorRole } from '@/domain/accounts/roles';
import { applySearchToQuery, type Search } from '@pages/content/lens/utils/apply-filters-to-query';
import { type Compensation } from '@pages/content/profile/ned/ned-profile.page';
import { COMPENSATION_MAX, COMPENSATION_MIN } from '@parts/compensation-selector/compensation-selector';
import { cleanObject } from '@utils/fns/clean-empty-values';
import { safeParseJson } from '@utils/fns/safe-parse-json';
import { sortArrayAlphabetically } from '@utils/fns/sortArrayAlphabetically';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { PENDO_EVENTS, type PendoEventPayloadMap } from '@utils/hooks/use-pendo/use-pendo';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import type { RegionValue } from '@utils/type-definitions/regions-values';
import { Col, Row } from 'antd';
import type { SelectValue } from 'antd/lib/select';
import { useFormik } from 'formik';
import type { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { Header } from '../../../lens/parts/header/header';
import { PreviousPositionsFilter } from '../form/previous-position/previous-position-filter';
import { RatingFilter } from '../form/rating/rating-filter';
import { AdvisorsFiltersCheckboxes } from './advisors-filters-checkboxes/advisors-filters-checkboxes';
import { CompensationFilter } from './compensation/compensation-filter';
import { CountriesAndRegionsFilter } from './countries-and-regions/countries-and-regions';
import { IndustriesFilter } from './industries/industries';
import { SkillSetsFilter } from './skill-sets/skill-sets';
import { useValidationSchema } from './validation-schema';

import S from './filters.styles';

export type CompensationFilterType = {
  isBlended: boolean;
  isEquity: boolean;
  isPaid: boolean;
  paidRates: {
    minDayRate: number;
    maxDayRate: number;
  };
};

type UrlQueryFilters = {
  onlyNewlyRegistered: boolean;
  onlySavedProfiles: boolean;
  industries: string[];
  skillSet: string[];
  compensation: CompensationFilterType;
  countriesOfResidence: CountryCodes[];
  regions: RegionValue[];
};

export type FormikFiltersData = {
  onlyNewlyRegistered: boolean;
  onlySavedProfiles: boolean;
  industries: string[];
  skillSet: string[];
  compensation: CompensationFilterType;
  countriesOfResidence: CountryCodes[];
  regions: RegionValue[];
};

const emptyQueryFilters: UrlQueryFilters = {
  onlyNewlyRegistered: false,
  onlySavedProfiles: false,
  industries: [],
  skillSet: [],
  compensation: {
    isBlended: false,
    isEquity: false,
    isPaid: false,
    paidRates: {
      minDayRate: COMPENSATION_MIN,
      maxDayRate: COMPENSATION_MAX,
    },
  },
  countriesOfResidence: [],
  regions: [],
};

const reportValuesToPendo = (values: PendoEventPayloadMap[PENDO_EVENTS.TRACK_ADVISORS_FILTERS]) => {
  if (!pendo?.track) {
    return;
  }

  const industriesEmpty = values.industries.length === 0;
  const skillSetEmpty = values.skillSets.length === 0;

  if (industriesEmpty && skillSetEmpty) {
    return;
  }

  pendo.track(PENDO_EVENTS.TRACK_ADVISORS_FILTERS, {
    role: values.role,
    industries: sortArrayAlphabetically(values.industries),
    skillSets: sortArrayAlphabetically(values.skillSets),
  });
};

const createFormDefaultValues = (query: { [key: string]: string | string[] | null }): FormikFiltersData => {
  const filtersParsed = safeParseJson<Partial<UrlQueryFilters>>(
    typeof query.filters === 'string' ? query.filters : '',
    emptyQueryFilters,
  );

  const regions = safeParseJson<RegionValue[]>(query.regions as string, []);
  const countriesOfResidence = safeParseJson<CountryCodes[]>(query.countriesOfResidence as string, []);
  const compensation: CompensationFilterType = {
    isBlended: Boolean(filtersParsed.compensation?.isBlended),
    isEquity: Boolean(filtersParsed.compensation?.isEquity),
    isPaid: Boolean(filtersParsed.compensation?.isPaid),
    paidRates: {
      minDayRate: filtersParsed.compensation?.paidRates?.minDayRate ?? COMPENSATION_MIN,
      maxDayRate: filtersParsed.compensation?.paidRates?.maxDayRate ?? COMPENSATION_MAX,
    },
  };

  return {
    onlyNewlyRegistered: Boolean(filtersParsed.onlyNewlyRegistered),
    onlySavedProfiles: Boolean(filtersParsed.onlySavedProfiles),
    industries: filtersParsed.industries ? filtersParsed.industries : [],
    skillSet: filtersParsed.skillSet ? filtersParsed.skillSet : [],
    compensation,
    regions,
    countriesOfResidence,
  };
};

const allFilters = [
  'checkboxes',
  'industries',
  'skillSet',
  'compensation',
  'countriesAndRegions',
  'previousPositions',
  'rating',
] as const;

type FilterItem = (typeof allFilters)[number];

export const Filters = ({
  advisorRole,
  filtersList = [...allFilters],
}: {
  advisorRole: AdvisorRole;
  filtersList?: FilterItem[];
}) => {
  const [title] = useTranslation(['advisors.search.filters.collapse.title']);
  const query = useQueryParams();
  const history = useHistory();
  const {
    deviceData: { isDesktop },
  } = useDeviceDetect();

  const handleSubmit = (values: FormikFiltersData) => {
    const { page, filters, ...initialQuery } = query;

    const { isBlended, isPaid, isEquity } = values.compensation;
    const shouldApplyCompensationFilter = isBlended || isPaid || isEquity;

    const { regions, countriesOfResidence, ...formValuesAsFilters } = cleanObject({
      ...values,
      compensation: shouldApplyCompensationFilter ? values.compensation : undefined,
    });

    applySearchToQuery({
      history,
      initialQuery,
      search: {
        filters: {
          ...formValuesAsFilters,
          onlyNewlyRegistered: values.onlyNewlyRegistered ? true : undefined,
          onlySavedProfiles: values.onlySavedProfiles ? true : undefined,
        },
        regions: values.regions.length ? values.regions : undefined,
        countriesOfResidence: values.countriesOfResidence.length ? values.countriesOfResidence : undefined,
      } as unknown as Search,
    });
  };

  const { getFieldProps, setFieldValue, submitForm, values } = useFormik<FormikFiltersData>({
    initialValues: createFormDefaultValues(query),
    onSubmit: handleSubmit,
    validateOnMount: true,
    validationSchema: useValidationSchema(),
  });

  const handleChangeAndSubmit = async (
    fieldName: string,
    fieldValue: Compensation | SelectValue | boolean | null | unknown,
  ) => {
    const res = await setFieldValue(fieldName, fieldValue);

    submitForm();

    return res;
  };

  const renderFilterElement = (filter: FilterItem, element: ReactNode) =>
    filtersList.includes(filter) ? element : null;

  const FiltersForm = (
    <S.Form>
      <Row gutter={[24, 12]}>
        {renderFilterElement(
          'checkboxes',
          <Col xs={24} md={24} lg={24}>
            <AdvisorsFiltersCheckboxes getFieldProps={getFieldProps} setFieldValue={handleChangeAndSubmit} />
          </Col>,
        )}
        {renderFilterElement(
          'industries',
          <Col xs={24} md={12} lg={24}>
            <IndustriesFilter
              getFieldProps={getFieldProps}
              setFieldValue={async (field: string, value: SelectValue | null) => {
                await handleChangeAndSubmit(field, value);
                reportValuesToPendo({ role: advisorRole, skillSets: values.skillSet, ...values });
              }}
            />
          </Col>,
        )}
        {renderFilterElement(
          'skillSet',
          <Col xs={24} md={12} lg={24}>
            <SkillSetsFilter
              getFieldProps={getFieldProps}
              setFieldValue={async (field: string, value: SelectValue | null) => {
                await handleChangeAndSubmit(field, value);
                reportValuesToPendo({ role: advisorRole, skillSets: values.skillSet, ...values });
              }}
            />
          </Col>,
        )}
        {renderFilterElement(
          'countriesAndRegions',
          <Col xs={24} md={12} lg={24}>
            <CountriesAndRegionsFilter
              values={values}
              setFieldValue={handleChangeAndSubmit}
              advisorRole={advisorRole}
            />
          </Col>,
        )}
        {renderFilterElement(
          'compensation',
          <Col xs={24} md={24} lg={24}>
            <CompensationFilter
              advisorRole={advisorRole}
              getFieldProps={getFieldProps}
              setFieldValue={handleChangeAndSubmit}
            />
          </Col>,
        )}
        {renderFilterElement(
          'previousPositions',
          <Col xs={24} md={12} lg={24}>
            <PreviousPositionsFilter />
          </Col>,
        )}
        {renderFilterElement(
          'rating',
          <Col xs={24} md={12} lg={24}>
            <RatingFilter />
          </Col>,
        )}
      </Row>
    </S.Form>
  );

  return isDesktop ? (
    FiltersForm
  ) : (
    <S.Collapse bordered={false} defaultActiveKey={['1']}>
      <S.Collapse.Panel showArrow={false} header={<Header title={title} icon={<S.Arrow />} />} key={title}>
        {FiltersForm}
      </S.Collapse.Panel>
    </S.Collapse>
  );
};

import type { V2ButtonProps } from '@/components/ui/v2-button/v2-button.types';
import { acceptConnection } from '@/modules/founder/connections/api/accept-connection.action';
import message from '@/parts/message/message';
import { getServerError } from '@/utils/fns/get-server-error';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import useUserAccount from '@/utils/hooks/use-user-account/use-user-account';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { ConnectionStatus } from '@utils/type-definitions/connections';
import { cancelNedConnection, createNedConnection, removeNedConnection } from '../../api/connection-ned.action';
import type { TConnectionStatus } from '../../types';

import S from './connection-button.styles';

type ConnectionButtonProps = {
  connectionStatus: TConnectionStatus['status'];
  initiator: TConnectionStatus['initiator'];
  advisorId: string;
  connectionId: string | null;
  buttonSize?: V2ButtonProps['size'];
  buttonFixedHeight?: string;
  disabled: boolean;
  callback: () => void;
  'data-testid'?: string;
  allowDisconnect?: boolean;
};

export const ConnectionButton = ({
  connectionStatus,
  advisorId,
  connectionId,
  initiator,
  buttonSize,
  buttonFixedHeight = 'auto',
  disabled,
  callback,
  'data-testid': dataTestId,
  allowDisconnect = true,
}: ConnectionButtonProps) => {
  const {
    state: { userRole },
  } = useUserAccount();
  const [connectLabel, connectedLabel, pendingLabel, disconnectLabel, acceptLabel] = useTranslation([
    'advisors.match.connect',
    'advisors.match.connected',
    'advisors.match.pending',
    'advisors.match.disconnect',
    'advisors.match.accept',
  ]);

  const { mutate: connect, isLoading: isConnectLoading } = useMutation({
    mutationFn: createNedConnection(advisorId),
    onSuccess: () => callback(),
    onError: (err: AxiosError) => message.error({ content: getServerError(err) }),
  });

  const { mutate: cancel, isLoading: isCancelLoading } = useMutation({
    mutationFn: cancelNedConnection(connectionId),
    onSuccess: () => callback(),
    onError: (err: AxiosError) => message.error({ content: getServerError(err) }),
  });

  const { mutate: accept, isLoading: isAcceptConnectionLoading } = useMutation({
    mutationFn: acceptConnection(connectionId!),
    onSuccess: () => callback(),
    onError: (err: AxiosError) => message.error({ content: getServerError(err) }),
  });

  const { mutate: remove, isLoading: isRemoveLoading } = useMutation({
    mutationFn: removeNedConnection(connectionId),
    onSuccess: () => callback(),
    onError: (err: AxiosError) => message.error({ content: getServerError(err) }),
  });

  const isLoading = isConnectLoading || isCancelLoading || isRemoveLoading || isAcceptConnectionLoading;

  const connectionButtonLabel = () => {
    switch (connectionStatus) {
      case ConnectionStatus.Accepted:
        return allowDisconnect ? disconnectLabel : connectedLabel;
      case ConnectionStatus.Pending:
        return initiator?.toLowerCase() === userRole.toLowerCase() ? pendingLabel : acceptLabel;
      default:
        return connectLabel;
    }
  };

  const getConnectAction = () => {
    switch (connectionStatus) {
      case ConnectionStatus.Accepted:
        return allowDisconnect ? remove : undefined;
      case ConnectionStatus.Pending:
        return initiator?.toLowerCase() === userRole.toLowerCase() ? cancel : accept;
      default:
        return connect;
    }
  };

  const getButtonVariant = (): V2ButtonProps['variant'] => {
    switch (connectionStatus) {
      case ConnectionStatus.Accepted:
        return 'outlined-success';
      default:
        return 'primary';
    }
  };

  const getButtonDisableStatus = () => {
    if (allowDisconnect === false && connectionStatus === ConnectionStatus.Accepted) return true;
    return isLoading || disabled;
  };

  return (
    <S.Button
      data-testid={dataTestId}
      onClick={() => getConnectAction()?.()}
      variant={getButtonVariant()}
      disabled={getButtonDisableStatus()}
      size={buttonSize}
      style={{ height: buttonFixedHeight }}
    >
      {connectionButtonLabel()}
    </S.Button>
  );
};

import styled from 'styled-components';

const Section = styled.section`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.large};
  padding-top: ${({ theme }) => theme.spacing.large};
  border-top: 1px solid ${({ theme }) => theme.baseColors.Blue100}80;
`;

const Title = styled.h4`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.color.fontPrimary};
`;

export default {
  Section,
  Title,
};

export enum RegionValue {
  Europe = 'Europe',
  SouthAmerica = 'S. America',
  NorthAmerica = 'N. America',
  MENA = 'MENA',
  SubSaharanAfrica = 'Sub-Saharan Africa',
  APAC = 'APAC',
  Global = 'Global',
}

export const RegionNamesMap: Record<string, string> = {
  Europe: 'Europe',
  SouthAmerica: 'South America',
  NorthAmerica: 'North America',
  MENA: 'MENA',
  SubSaharanAfrica: 'Sub-Saharan Africa',
  APAC: 'APAC',
  Global: 'Global',
};

/* eslint-disable react-hooks/exhaustive-deps */
import {
  scaleTransform,
  ScaleTransformPurpose,
} from '@pages/content/pulse/founder/parts/management/parts/metrics/parts/numeric-editor/numeric-editor';
import type {
  ChartNode,
  Node,
} from '@pages/content/pulse/founder/parts/management/parts/metrics/parts/numeric-editor/parts/live-chart/live-chart';
import { dateFromYearAndMonth } from '@utils/fns/date-from-year-and-month';
import { sortChartNodes } from '@utils/fns/sort-chart-nodes';
import { useMemo } from 'react';

export const useChartSeries = ({ nodes, scale }: { nodes: Node[]; scale: number }) => {
  const removeIds = (series: ChartNode[]) => series.map((node) => node.slice(1));

  const sanitizeSeries = (series: ChartNode[]) => series.filter(([, v]) => v);

  const sortedNodes = sortChartNodes(nodes);

  const rawValueSeries = useMemo(
    () => sortedNodes.map((node) => [node.id, dateFromYearAndMonth(node.year, node.month).toISO(), node.value]),
    [sortedNodes],
  );

  const rawForecastSeries = useMemo(
    () =>
      sortedNodes.map((node, i, nds) => {
        const { forecast } = node;

        const nextForecast = nds.slice(i).find((r) => r.forecast)?.forecast ?? null;
        const previousForecast =
          nds
            .slice(0, i)
            .reverse()
            .find((r) => r.forecast)?.forecast ?? null;

        const isLast = i === nds.length - 1;

        const computedForecast = (() => {
          if (!isLast || forecast || nextForecast) return forecast;

          return previousForecast;
        })();

        return [node.id, dateFromYearAndMonth(node.year, node.month).toISO(), computedForecast];
      }),
    [sortedNodes],
  );

  const valueSeries = useMemo(() => removeIds(rawValueSeries), [rawValueSeries]);
  const forecastSeries = useMemo(() => removeIds(rawForecastSeries), [rawForecastSeries]);

  const series = useMemo(
    () => [
      { name: 'Value', data: valueSeries },
      { name: 'Forecast', data: forecastSeries },
    ],
    [valueSeries, forecastSeries],
  );

  const scaleSeriesTransform = (srs: ChartNode[], direction: ScaleTransformPurpose) =>
    srs.map(([time, value]) => [time, value ? scaleTransform(Number(value), scale, direction) : value]);

  const previewValueSeries = useMemo(() => sanitizeSeries(valueSeries), [valueSeries]);

  const previewForecastSeries = useMemo(() => sanitizeSeries(forecastSeries), [forecastSeries]);

  const previewSeries = useMemo(
    () => [
      { name: 'Value', data: scaleSeriesTransform(previewValueSeries, ScaleTransformPurpose.READ) },
      {
        name: 'Forecast',
        data: scaleSeriesTransform(previewForecastSeries, ScaleTransformPurpose.READ),
      },
    ],
    [previewValueSeries, previewForecastSeries],
  );

  return {
    sortedNodes,
    rawValueSeries,
    rawForecastSeries,
    series,
    previewSeries,
  };
};

import styled from 'styled-components';

const Wrapper = styled.a`
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  color: ${({ theme }) => theme.color.primary};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    display: block;
  }
`;

export default { Wrapper };

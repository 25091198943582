import { FounderOnboardingContext } from '@context/founder-onboarding/founder-onboarding.context';
import { useContext } from 'react';

export const useFounderOnboardingContext = () => {
  const context = useContext(FounderOnboardingContext);

  if (!context) {
    throw new Error('useFounderOnboardingContext should be used within Onboarding context.');
  }

  return context;
};

import { useMediaQuery } from '@/hooks/use-media-query';
import { V2IconBox } from '@ui/v2-icon-box/v2-icon-box';
import { V2Link } from '@ui/v2-link/v2-link';
import type { V2SuggestionBannerProps } from '@ui/v2-suggestion-banner/v2-suggestion-banner.types';
import React from 'react';
import S from './v2-suggestion-banner.styles';

export const V2SuggestionBanner: React.FC<V2SuggestionBannerProps> = ({
  text,
  variant,
  icon,
  actionLink,
  'data-testid': dataTestId = 'v2-suggestion-banner',
}) => {
  const mediaQuery = useMediaQuery();

  return (
    <S.V2SuggestionBanner $variant={variant ?? 'primary'} data-testid={dataTestId}>
      <V2IconBox icon={icon} size={mediaQuery === 'xs' ? 'xs' : 'sm'} variant="secondary" />
      <S.TextWrapper>
        {text}
        {actionLink && <V2Link to={actionLink.href}>{actionLink.text}</V2Link>}
      </S.TextWrapper>
    </S.V2SuggestionBanner>
  );
};

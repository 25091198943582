import type { GetReadinessScoreQueryResponseData } from '@/modules/founder/readiness-score/api/get-readiness-score.query';
import { useTogglePublishFounderProfile } from '@/modules/founder/readiness-score/hooks/use-toggle-publish-founder-profile';
import { Links } from '@/router/links';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { StepContent, type ContentStep } from '../step-content/step-content';
import { V2Loader } from '@/components/ui/v2-loader/v2-loader';
import { V2Tooltip } from '@/components/ui/v2-tooltip/v2-tooltip';
import { useGetFounderPublishStatusQuery } from '@/modules/founder/readiness-score/hooks/use-get-founder-publish-status-query';
import message from '@/parts/message/message';
import S from './profile-content.styles';
import { V2Link } from '@/components/ui/v2-link/v2-link';
import { publishProfileGuideLink } from '@/constants/links';

export const ProfileContent = ({ data }: { data: GetReadinessScoreQueryResponseData['profile'] }) => {
  const [
    titleLL,
    descriptionFirstPartLL,
    descriptionLinkLL,
    descriptionSecondPartLL,
    personalTitleLL,
    personalDescriptionLL,
    smartMatchTitleLL,
    smartMatchDescriptionLL,
    uploadedFilesTitleLL,
    uploadedFilesDescriptionLL,
    publishTitleLL,
    publishDescriptionLL,
    publishTooltipNotReadyLL,
    publishedTooltipLL,
    publishSuccessMessageLL,
  ] = useTranslation([
    'homepage.readinessScore.modal.profile.title',
    'homepage.readinessScore.modal.profile.descriptionFirstPart',
    'homepage.readinessScore.modal.profile.descriptionLink',
    'homepage.readinessScore.modal.profile.descriptionSecondPart',
    'homepage.readinessScore.modal.profile.step.personal.title',
    'homepage.readinessScore.modal.profile.step.personal.description',
    'homepage.readinessScore.modal.profile.step.smartMatch.title',
    'homepage.readinessScore.modal.profile.step.smartMatch.description',
    'homepage.readinessScore.modal.profile.step.uploadedFiles.title',
    'homepage.readinessScore.modal.profile.step.uploadedFiles.description',
    'homepage.readinessScore.modal.profile.step.publish.title',
    'homepage.readinessScore.modal.profile.step.publish.description',
    'homepage.readinessScore.modal.profile.step.publish.tooltip.notReady',
    'homepage.readinessScore.modal.profile.step.publish.tooltip.published',
    'homepage.readinessScore.modal.profile.step.publish.success.message',
  ]);
  const { founderPublishStatusData, isFetchingPublishStatus, isLoadingPublishStatus, refetchPublishStatus } =
    useGetFounderPublishStatusQuery();
  const published = founderPublishStatusData?.data?.published ?? false;

  const descriptionIntro = (
    <span>
      {descriptionFirstPartLL}{' '}
      <V2Link outer to={publishProfileGuideLink}>
        {descriptionLinkLL}
      </V2Link>{' '}
      {descriptionSecondPartLL}
    </span>
  );

  const { togglePublishFounderProfile, isTogglePublishFounderProfileLoading } = useTogglePublishFounderProfile({
    published,
    onSuccessCallback: async () => {
      await refetchPublishStatus();
      message.success({ content: publishSuccessMessageLL });
    },
  });

  const isReadyToPublish = () => {
    const shortages = founderPublishStatusData?.data?.smartMatchShortages;
    return Boolean(shortages && shortages.length === 0);
  };

  const getPublishTooltipText = (): string => {
    if (founderPublishStatusData?.data?.published) {
      return publishedTooltipLL;
    }

    if (isReadyToPublish() === false) {
      return publishTooltipNotReadyLL;
    }

    return '';
  };

  const isPublishSwitchDisabled =
    isTogglePublishFounderProfileLoading || isFetchingPublishStatus || published || !isReadyToPublish();

  const { personalDetailsScore, smartMatchScore, uploadedFilesScore } = data;
  const steps: ContentStep[] = [
    {
      id: 'personal',
      title: personalTitleLL,
      description: personalDescriptionLL,
      icon: 'house',
      actionUrl: Links.PROFILE() + '?st=company-information',
      received: personalDetailsScore.received,
      available: personalDetailsScore.available,
      required: true,
    },
    {
      id: 'smartMatch',
      title: smartMatchTitleLL,
      description: smartMatchDescriptionLL,
      icon: 'arrow-left-right',
      actionUrl: Links.PROFILE() + '?st=smart-match',
      received: smartMatchScore.received,
      available: smartMatchScore.available,
      required: true,
    },
    {
      id: 'uploadedFiles',
      title: uploadedFilesTitleLL,
      description: uploadedFilesDescriptionLL,
      icon: 'document',
      actionUrl: Links.PROFILE() + '?st=additional-info',
      received: uploadedFilesScore.received,
      available: uploadedFilesScore.available,
    },
    // static step for publish
    {
      id: 'publish',
      title: publishTitleLL,
      description: publishDescriptionLL,
      icon: 'eye',
      customActionElement: (
        <V2Tooltip tooltipText={getPublishTooltipText()} includeIcon={false}>
          <S.Switch
            checked={published}
            disabled={isPublishSwitchDisabled}
            onChange={() => togglePublishFounderProfile()}
          />
        </V2Tooltip>
      ),
      received: published ? 1 : 0,
      available: 1,
    },
  ];

  const score = steps.reduce(
    (acc, step) => {
      // don't include static publish step in the score
      if (step.id === 'publish') return acc;

      acc.received += step.received;
      acc.available += step.available;
      return acc;
    },
    { received: 0, available: 0 },
  );

  if (isLoadingPublishStatus) {
    return <V2Loader height="300px" />;
  }

  return (
    <StepContent
      introTitle={titleLL}
      introDescription={descriptionIntro}
      headerIcon="person"
      steps={steps}
      score={score}
    />
  );
};

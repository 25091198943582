import { useTheme } from '@/hooks/use-theme';
import useUserAccount from '@/utils/hooks/use-user-account/use-user-account';
import { ExperienceSection } from '@/modules/advisor/profile/components/experience-section/experience-section';
import { PageHeader } from '@parts/page-header/page-header';
import { mapEmptyToString } from '@utils/fns/map-empty-to-string';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useUserCurrency } from '@utils/hooks/use-user-currency/use-user-currency';
import { useState } from 'react';
import type { PublishStatus } from '../../hooks/use-publish-status-evaluation';
import { PublicPreviewNotification } from '../../parts/public-preview-notification/public-preview-notification';
import type { INedUpdatePersonalDetailsPayload } from '../api/ned-profile/ned-profile.action';
import type { INedAbout, INedProfileData, INedSmartMatch } from '../ned-profile.page';
import { AboutMe } from './about-me/about-me';
import { BecomeInvestor } from './become-investor/become-investor';
import { PersonalDetails } from './personal-details/personal-details';
import { SmartMatch } from './smart-match/smart-match';

import S from './ned-profile.styles';

interface INedProfileProps {
  profileData: INedProfileData;
  industriesOptions: string[];
  skillsOptions: string[];
  handleSubmitPersonalDetails: (formState: INedUpdatePersonalDetailsPayload) => void;
  handleSubmitSmartMatch: (formState: INedSmartMatch) => void;
  handleSubmitAboutMe: (formState: INedAbout) => void;
  refetchNedData: () => void;
  becomeInvestor: {
    hasProcessStarted: boolean;
    hasProcessEnded: boolean;
    awaitingApproval: boolean;
  };
  isMutatingAboutMe?: boolean;
  isMutatingSmartMatch?: boolean;
  refetchPublishStatus: () => void;
  publishStatus: PublishStatus;
}

export const NedProfile = ({
  profileData,
  handleSubmitPersonalDetails,
  handleSubmitSmartMatch,
  handleSubmitAboutMe,
  industriesOptions,
  skillsOptions,
  becomeInvestor,
  refetchNedData,
  isMutatingAboutMe = false,
  isMutatingSmartMatch = false,
  refetchPublishStatus,
  publishStatus,
}: INedProfileProps) => {
  const {
    state: { userId },
  } = useUserAccount();
  const { mode } = useTheme();

  const { currencySymbol } = useUserCurrency();
  const [header] = useTranslation(['profile.header']);

  const [isPublicPreview, setIsPublicPreview] = useState(false);

  const showBecomeInvestorSection = !becomeInvestor.hasProcessEnded || becomeInvestor.awaitingApproval;

  const isLightTheme = mode === 'light';

  const profilePage = (
    <>
      <PageHeader title={header} />
      <PersonalDetails
        personalDetails={{
          email: profileData.email,
          profilePhotoPath: profileData.profilePhotoPath,
          profilePhotoUrl: profileData.profilePhotoUrl,
          linkedinUrl: profileData.linkedinUrl,
          twitterUrl: profileData.twitterUrl,
          countryOfResidence: profileData.countryOfResidence,
          firstName: profileData.firstName,
          lastName: profileData.lastName,
          phoneNumber: profileData.phoneNumber,
          resume: { ...profileData.resume! },
        }}
        handleSubmit={handleSubmitPersonalDetails}
        togglePubicView={() => {
          refetchNedData();
          setIsPublicPreview((prev) => !prev);
        }}
      />
      <AboutMe
        data={{
          aboutMe: profileData.aboutMe,
          myExpectations: profileData.myExpectations,
          myOffering: profileData.myOffering,
        }}
        handleSubmit={handleSubmitAboutMe}
        busy={isMutatingAboutMe}
      />
      <SmartMatch
        smartMatchData={{
          industries: profileData.industries,
          isAdvisor: profileData.isAdvisor,
          isNed: profileData.isNed,
          isFractional: profileData.isFractional,
          primarySkillSet: profileData.primarySkillSet,
          secondarySkillSet: profileData.secondarySkillSet,
          compensations: profileData.compensations,
          targetGeography: profileData.targetGeography,
        }}
        isLightTheme={isLightTheme}
        handleSubmit={handleSubmitSmartMatch}
        industriesOptions={industriesOptions}
        skillsOptions={skillsOptions}
        busy={isMutatingSmartMatch}
        refetchPublishStatus={refetchPublishStatus}
        publishStatus={publishStatus}
        currencySymbol={currencySymbol}
      />
      {/* TODO: remove div when moving to v2 */}
      <div style={{ marginBottom: 20 }}>
        <ExperienceSection />
      </div>

      {showBecomeInvestorSection && <BecomeInvestor awaitingAcceptance={becomeInvestor.awaitingApproval} />}
    </>
  );

  const publicProfilePage = (
    <>
      <PublicPreviewNotification onClick={() => setIsPublicPreview((prev) => !prev)} />
      <S.NedPublicProfile
        id={userId!}
        handleBackAction={() => setIsPublicPreview((prev) => !prev)}
        publicData={{
          ...mapEmptyToString(profileData),
          name: `${profileData.firstName} ${profileData.lastName}`,
          resume: {
            name: profileData.resume?.name || '',
            path: profileData.resume?.path || '',
            url: profileData.resume?.url || '',
          },
          profilePicture: {
            name: profileData.profilePhotoPath,
            path: profileData.profilePhotoPath,
            url: profileData.profilePhotoUrl,
          },
        }}
      />
    </>
  );

  return isPublicPreview ? publicProfilePage : profilePage;
};

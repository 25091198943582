import { useQuery } from '@tanstack/react-query';
import {
  FOUNDER_ONBOARDING_QUESTIONS_KEY,
  getFounderQualifyingQuestions,
} from '../api/get-founder-qualifying-questions.action';

export const useGetFounderQualifyingQuestions = () => {
  const query = useQuery([FOUNDER_ONBOARDING_QUESTIONS_KEY], getFounderQualifyingQuestions);

  return {
    ...query,
    founderQualifyingQuestionsData: query.data?.data,
    isFounderQualifyingQuestionsLoading: query.isLoading,
    isFounderQualifyingQuestionsError: query.isError,
  };
};

import EmptyPortfolioIcon from '@assets/icons/empty-portfolio.svg?react';
import { EmptyState as EmptyStateBase } from '@parts/empty-state/empty-state';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { MouseEventHandler } from 'react';
import S from './empty-state.styles';

export const EmptyState = ({ onButtonClick }: { onButtonClick: MouseEventHandler<HTMLElement> }) => {
  const [emptyTitleLabel, emptySubtitleLabel, addShareholder] = useTranslation([
    'shareholders.founder.emptyState.title',
    'shareholders.founder.emptyState.subTitle',
    'shareholders.investor.emptyState.addShareholder',
  ]);

  return (
    <EmptyStateBase title={emptyTitleLabel} subTitle={emptySubtitleLabel} Icon={EmptyPortfolioIcon}>
      <S.Button onClick={onButtonClick}>{addShareholder}</S.Button>
    </EmptyStateBase>
  );
};

import type { FundingRound } from '@pages/content/pulse/founder/parts/progress/parts/funding-rounds/funding-rounds';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export interface GetFounderDataResponse {
  fundingRounds: FundingRound[];
  companyOwnerMeta: {
    name: string;
    avatarUrl: string | null;
  };
}

export const GET_FOUNDER_DATA_CACHE_KEY = 'get-founder-data';

export const getFounderData = async (): Promise<GraphqlResponse<GetFounderDataResponse>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getFounderData {
        fundingRounds {
          id
          type
          amountSought
          amountRaised
          valuation
          status
          useOfFunds
          date
          isUsedInInvestment
        }
        companyOwnerMeta {
          name
          avatarUrl
        }
      }`,
  });

  return graphqlResponseWrapper(data, ['fundingRounds', 'companyOwnerMeta']);
};

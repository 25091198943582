import type { Node } from '@pages/content/pulse/founder/parts/management/parts/metrics/parts/numeric-editor/parts/live-chart/live-chart';
import { currencyToShortFormat } from '@utils/fns/currency-to-short-format';
import { getPercentageDifference } from '@utils/fns/get-percentage-difference';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { ColumnsType } from 'antd/lib/table';
import { DateTime, Settings } from 'luxon';
import S from './columns.styles';

Settings.defaultLocale = 'en';

export const Columns = ({ data }: { data: Node[] }): ColumnsType => {
  const [currentValueLabel, forecastLabel, differenceAmountLabel] = useTranslation([
    'metrics.table.currentValue',
    'metrics.table.forecast',
    'metrics.table.differenceAmount',
  ]);

  const {
    deviceData: { isMobile },
  } = useDeviceDetect();

  const getDate = ({ month, year }: { month: number; year: number }) =>
    `${DateTime.fromObject({ year, month }).monthShort} ${year}`;

  const calculateDifferenceAmount = (value: number | null, forecast: number | null) => {
    const differenceToString = (() => {
      if (value !== null && forecast !== null) {
        return String(forecast - value);
      }
      if (value === null && forecast !== null) {
        return String(forecast);
      }

      return '';
    })();

    const percentageDifference = (() => {
      if (value !== null && forecast !== null) {
        const diff = getPercentageDifference(value, forecast);

        return `(${diff > 0 ? '+' : ''}${diff}%)`;
      }
      return '(0%)';
    })();

    const isNegative = Boolean(differenceToString.startsWith('-'));

    const getMathSign = () => {
      if (differenceToString.startsWith('0') || differenceToString === '') {
        return '';
      }
      if (isNegative) {
        return '+';
      }
      return '-';
    };

    return `${getMathSign()}${
      isNegative ? currencyToShortFormat(differenceToString.slice(1)) : currencyToShortFormat(differenceToString)
    } ${percentageDifference}`;
  };

  const columnValues = (): ColumnsType =>
    [...Array(data.length).keys()].map((_, index) => ({
      title: getDate({ month: data[index].month, year: data[index].year }),
      width: isMobile ? '100px' : '120px',
      className: data.length - 1 === index ? 'scroll-column' : '',
      render: () => (
        <div key={index}>
          <S.TableRow>{data[index].value ? currencyToShortFormat(String(data[index].value)) : '-'}</S.TableRow>
          <S.TableRow>{data[index].forecast ? currencyToShortFormat(String(data[index].forecast)) : '-'}</S.TableRow>
          <S.TableRow>{calculateDifferenceAmount(data[index].value, data[index].forecast)}</S.TableRow>
        </div>
      ),
    }));

  return [
    {
      title: '',
      width: isMobile ? '130px' : '155px',
      className: 'sticky-column',
      render: () => (
        <>
          <S.TableRow title="">
            <S.Label>{currentValueLabel}</S.Label>
          </S.TableRow>

          <S.TableRow title="">
            <S.Label>{forecastLabel}</S.Label>
          </S.TableRow>

          <S.TableRow title="">
            <S.Label>{differenceAmountLabel}</S.Label>
          </S.TableRow>
        </>
      ),
    },
    ...columnValues(),
  ];
};

import type { ButtonProps } from 'antd/lib/button';
import type { ReactNode } from 'react';
import S from './submit-button.styles';

export type DisabledEvaluation = {
  dirty: boolean;
  isValid: boolean;
  submitCount: number;
  isSubmitting?: boolean;
};

interface ISubmitButtonProps extends ButtonProps {
  children: ReactNode | ReactNode[];
  type?: 'primary' | 'link';
  className?: string;
  disabled?: boolean;
  disabledEvaluation?: DisabledEvaluation;
  dataTestId?: string;
  loading?: boolean;
}

export const SubmitButton = ({
  children,
  className,
  type = 'primary',
  disabled,
  dataTestId,
  loading = false,
  disabledEvaluation,
  ...rest
}: ISubmitButtonProps) => {
  const disabledFromEvaluation =
    (disabledEvaluation && !disabledEvaluation.isValid) ||
    (disabledEvaluation && !disabledEvaluation.dirty) ||
    (!disabledEvaluation?.dirty && disabledEvaluation?.submitCount === 0) ||
    disabledEvaluation?.isSubmitting;

  if (!children) throw new Error('Button must have children');

  return (
    <S.SubmitButton
      data-testid={dataTestId}
      className={className}
      loading={loading}
      type={type}
      {...rest}
      disabled={disabled || disabledFromEvaluation}
    >
      {children}
    </S.SubmitButton>
  );
};

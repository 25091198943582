import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { honeybadger } from '@utils/honeybadger/honeybadger';
import {
  createApiMutationNotice,
  createApiQueryNotice,
  createGraphQlErrorNotice,
} from '@utils/honeybadger/utils/notifications';
import type { ReactNode } from 'react';
import type { Any, GraphQlErrorResponse, GraphqlResponse } from 'src/types';

const queryCache = new QueryCache({
  onError: (error, query) => honeybadger.notify({ ...createApiQueryNotice(error as AxiosError, query) }),
  onSuccess: (data) => {
    const resData = data as GraphqlResponse<Any>;
    const hasError = resData?.errors !== null && Array.isArray(resData?.errors);
    if (hasError) {
      const notice = createGraphQlErrorNotice(resData as GraphQlErrorResponse);
      honeybadger.notify(notice);
    }
  },
});

const mutationCache = new MutationCache({
  onError: (error) => honeybadger.notify({ ...createApiMutationNotice(error as AxiosError) }),
});

const queryClient = new QueryClient({ queryCache, mutationCache });

export const AppQueryClientProvider = ({ children }: { children: ReactNode }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);

import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  column-gap: ${({ theme }) => theme.spacing.small};
  row-gap: ${({ theme }) => theme.spacing.base};
  margin-right: 0;
  margin-left: 0;
  margin-top: ${({ theme }) => theme.spacing.base};

  @supports not (display: grid) {
    display: flex;
    flex-wrap: wrap;
    margin-right: -${({ theme }) => theme.spacing.small};
    margin-left: -${({ theme }) => theme.spacing.small};
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    margin-bottom: ${({ theme }) => theme.spacing.medium};
  }
`;

const Item = styled.div`
  text-align: center;

  @supports not (display: grid) {
    flex-grow: 1;
    flex-basis: calc(20% - 2 * ${({ theme }) => theme.spacing.small});
    min-width: 160px;
    margin-top: ${({ theme }) => theme.spacing.base};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
`;

const ItemTitle = styled.h4`
  margin-top: ${({ theme }) => theme.spacing.xmedium};
  color: ${({ theme }) => theme.color.fontPrimary};
  font-size: ${({ theme }) => theme.fontSize.regular};
`;

const Text = styled.p`
  margin-top: ${({ theme }) => theme.spacing.small};
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.fontPrimary};
  word-break: break-all;
`;

export default {
  Wrapper,
  Item,
  IconWrapper,
  ItemTitle,
  Text,
};

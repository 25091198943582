import { isTestRunning } from '@utils/fns/is-test-running';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useEffect, useRef, useState } from 'react';
import S from './score.style';

export type ScoreStatus = 'error' | 'warning' | 'success';

const MIDDLE_MIN_VALUE = 40;
const HIGH_MIN_VALUE = 70;

export const getScoreStatus = (score: number): ScoreStatus => {
  if (score < MIDDLE_MIN_VALUE) return 'error';
  if (score < HIGH_MIN_VALUE) return 'warning';
  return 'success';
};

export function useChangingNumber(target: number, initial: number) {
  const [currentValue, setCurrentValue] = useState(initial);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (isTestRunning()) {
      setCurrentValue(target);
      return;
    }

    const steps = Math.abs(target - currentValue);

    const timeout = setTimeout(() => {
      if (target > currentValue) setCurrentValue((s) => s + 1);
      else if (target < currentValue) setCurrentValue((s) => s - 1);
      else clearTimeout(timeout);
    }, 250 / steps);

    timeoutRef.current = timeout;

    return () => clearTimeout(timeout);
  }, [target, currentValue]);

  return currentValue;
}

export const ReadinessScore = ({ score, initial = 0 }: { score: number; initial?: number }) => {
  const [title] = useTranslation(['founder-onboarding.readinessScore.title']);
  const displayScore = useChangingNumber(score, initial);

  const scoreStatus = getScoreStatus(score);

  return (
    <S.ItemWrapper data-testid="onboarding-readiness-score-box">
      <S.ScoreWrapper>
        <S.ScoreRingColorError
          data-testid={`readiness-score-circle-ring-error${scoreStatus === 'error' ? '-active' : ''}`}
          active={scoreStatus === 'error'}
        />
        <S.ScoreRingColorWarning
          data-testid={`readiness-score-circle-ring-warning${scoreStatus === 'warning' ? '-active' : ''}`}
          active={scoreStatus === 'warning'}
        />
        <S.ScoreRingColorSuccess
          data-testid={`readiness-score-circle-ring-success${scoreStatus === 'success' ? '-active' : ''}`}
          active={scoreStatus === 'success'}
        />

        <S.ScoreRingOuter>
          <S.ScoreRingInner>{displayScore}</S.ScoreRingInner>
        </S.ScoreRingOuter>
      </S.ScoreWrapper>
      <S.Title>{title}</S.Title>
    </S.ItemWrapper>
  );
};

import { SwitchLabelled as SwitchLabelledBase } from '@parts/switch-labelled/switch-labelled';
import { Dropdown as DropdownBase, Menu as MenuBase } from 'antd';
import styled from 'styled-components';

const Trigger = styled(DropdownBase.Button)`
  border-color: ${({ theme }) => theme.baseColors.Transparent};
  .ant-btn {
    padding: 0;
    border: 0;
    margin: 0;

    &:after {
      display: none;
    }
    > div {
      margin: 0;
    }
  }

  .ant-btn-icon-only {
    border-color: ${({ theme }) => theme.baseColors.Transparent};
    position: absolute;
    border: 0px;
    background: transparent;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 9;
  }
`;

const Menu = styled(MenuBase)`
  width: 200px;
  border: 1px solid ${({ theme }) => theme.color.border};
  padding: 0;

  .ant-dropdown-menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    border-bottom: 1px solid ${({ theme }) => theme.color.border};

    &:hover {
      background-color: ${({ theme }) => theme.color.backgroundHover};
    }

    &:last-of-type {
      border-bottom: 0;
    }

    svg {
      position: absolute;
      left: 10px;
      width: 24px;
      height: 24px;

      path {
        fill: ${({ theme }) => theme.color.profileMenu};
      }
    }

    a,
    span {
      color: ${({ theme }) => theme.color.profileMenu};
      font-weight: ${({ theme }) => theme.fontWeight.semibold};
      text-decoration: none;
      display: flex;
      width: 100%;
      margin: 0;
      padding: 0;
      justify-content: flex-start;
      align-items: center;
      padding: 14px 20px 14px 40px;
      position: relative;
    }

    &.ant-dropdown-menu-item-disabled,
    &.ant-dropdown-menu-item-disabled > span {
      cursor: default;
    }
  }

  .ant-dropdown-menu-item.dropdown-extra-item {
    svg {
      left: 0;
      position: relative;
      width: auto;
      height: auto;
      display: inline-block;
    }

    p {
      padding: 0;
    }
  }
`;

const DarkModeLabel = styled.span`
  padding-left: ${({ theme }) => theme.spacing.small}!important;
  color: ${({ theme }) => theme.color.fontPrimary}!important;
`;

const SwitchLabelled = styled(SwitchLabelledBase)`
  margin-right: ${({ theme }) => theme.spacing.small}!important;
`;

export default { Trigger, Menu, DarkModeLabel, SwitchLabelled };

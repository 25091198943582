import message from '@/parts/message/message';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteExperience } from '../api/delete-experience.action';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { GET_EXPERIENCE_QUERY_KEY } from '../api/get-experience.action';

export const useRemoveExperience = (experienceId: string, cb: () => void) => {
  const [successMessageLL] = useTranslation(['profile.advisor.exp.deleteExperience.success']);
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    queryClient.refetchQueries([GET_EXPERIENCE_QUERY_KEY]);
    message.success({ content: successMessageLL });
    cb();
  };

  const mutation = useMutation(deleteExperience(experienceId), { onSuccess });

  return {
    deleteExperience: mutation.mutate,
    isDeleteLoading: mutation.isLoading,
  };
};

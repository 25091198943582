import { V2Modal } from '@/components/ui/v2-modal/v2-modal';
import S from './notes-modal.styles';
import { useGetConnectionNotes } from '@/modules/founder/connections/hooks/use-connection-notes';
import { useUserDateFormat } from '@/utils/hooks/use-user-date-format/use-user-date-format';
import { DateFormat } from '@i18n/date-formats';
import { useMemo, useState } from 'react';
import { V2Notes } from '@/components/blocks/v2-notes/v2-notes';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { DateTime } from 'luxon';
import { V2Loader } from '@/components/ui/v2-loader/v2-loader';

type NotesModalProps = {
  isOpen: boolean;
  close: () => void;
  targetId: string;
  connectionName: string;
};

export const NotesModal = ({ isOpen, close, targetId, connectionName }: NotesModalProps) => {
  const [addButtonTranslation, modalHeader] = useTranslation([
    'connections.founder.table.notes.addButton',
    'inbox.modal.header',
  ]);
  const title = `${modalHeader} ${connectionName}`;
  const { dateFormatter } = useUserDateFormat();
  const [isNewNoteVisible, setIsNewNoteVisible] = useState(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const { connectionNotesData, isConnectionNotesDataLoading, refetchConnectionNotes } = useGetConnectionNotes({
    targetAccountId: targetId,
  });
  const reversedNotes = useMemo(() => connectionNotesData?.data?.reverse(), [connectionNotesData?.data]);

  const handleAddNewNote = () => {
    setIsNewNoteVisible(true);
    setSelectedId('new');
  };

  const emptyNotesCount = 3 - (reversedNotes?.length ?? 0);
  const emptyNotesArray = Array.from({ length: emptyNotesCount }, (_, index) => index);

  return (
    <V2Modal isOpen={isOpen} onCancel={close} size="lg" title={title} footer={null}>
      <S.ModalContent>
        <S.NotesList>
          {isNewNoteVisible && emptyNotesArray.length === 0 && (
            <V2Notes.TileWrapper
              key="new"
              noteId="new"
              size="sm"
              isDefaultState={false}
              targetId={targetId}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              setIsNewNoteVisible={setIsNewNoteVisible}
              refetchConnectionNotes={refetchConnectionNotes}
              noteContent={{
                title: '',
                date: dateFormatter(DateTime.now(), DateFormat.FULL_DATE_DASHED),
                content: '',
              }}
            />
          )}
          {isConnectionNotesDataLoading ? (
            <V2Loader />
          ) : (
            reversedNotes?.map((note) => (
              <V2Notes.TileWrapper
                key={note.id}
                noteId={note.id}
                isDefaultState={false}
                targetId={targetId}
                size="sm"
                refetchConnectionNotes={refetchConnectionNotes}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                setIsNewNoteVisible={setIsNewNoteVisible}
                noteContent={{
                  title: note.title,
                  date: dateFormatter(note.createdAt, DateFormat.FULL_DATE_DASHED),
                  content: note.content,
                }}
              />
            ))
          )}
          {emptyNotesArray.map((e) => (
            <V2Notes.TileWrapper
              key={`new-${e}`}
              noteId={`new-${e}`}
              size="sm"
              isDefaultState={false}
              targetId={targetId}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
              setIsNewNoteVisible={setIsNewNoteVisible}
              refetchConnectionNotes={refetchConnectionNotes}
              noteContent={{
                title: '',
                date: dateFormatter(DateTime.now(), DateFormat.FULL_DATE_DASHED),
                content: '',
              }}
            />
          ))}
        </S.NotesList>
        <S.ButtonWrapper>
          <V2Notes.TextButton
            onClick={handleAddNewNote}
            startIcon="plus"
            variant="text"
            disabled={emptyNotesArray.length !== 0 || isNewNoteVisible}
          >
            {addButtonTranslation}
          </V2Notes.TextButton>
        </S.ButtonWrapper>
      </S.ModalContent>
    </V2Modal>
  );
};

import S from './circular-image.styles';

interface CircularImageProps {
  imageUrl?: string | null;
  size?: number;
  className?: string;
  background?: string;
}

export const CircularImage = ({ imageUrl, size = 100, className, background }: CircularImageProps) => (
  <S.Image imageUrl={imageUrl} size={size} className={className} $background={background} />
);

import HexagonalBackground from '@assets/illustrations/auth/hexagonal-background.svg?react';
import styled from 'styled-components';

const OuterWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.registerColors.background};
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing.xbase} ${({ theme }) => theme.spacing.xmedium};
  min-height: 100vh;
`;

const Wrapper = styled.div`
  height: 100%;
  max-width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const LeftHexagonalBackground = styled(HexagonalBackground)`
  display: none;
  position: absolute;
  z-index: 0;
  left: 0;
  top: -50px;
  max-height: 100vh;
  width: auto;
  overflow: hidden;

  @media all and (min-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    display: block;
  }
`;

const RightHexagonalBackground = styled(LeftHexagonalBackground)`
  right: 0;
  bottom: -50px;
  top: unset;
  left: unset;
  max-height: 100vh;
  transform: rotate(180deg);
`;

export default { Wrapper, OuterWrapper, LeftHexagonalBackground, RightHexagonalBackground };

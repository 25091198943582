import { Link as LinkBase } from 'react-router-dom';
import styled, { css } from 'styled-components';
import type { FontSizeType, StyledLinkProps } from './V2-link.types';

const fontSize: FontSizeType = {
  small: '12px',
  medium: '14px',
  large: '16px',
};

const Link = styled(LinkBase)<StyledLinkProps>`
  text-decoration: ${({ $hasUnderline }) => ($hasUnderline ? 'underline' : 'none')};

  ${({ variant, theme, size }) =>
    variant !== 'default' &&
    css`
      all: initial;
      color: ${theme.v2.colors.blue[300]};

      font-size: ${fontSize[size]};
      font-weight: ${theme.v2.typography.weights.semibold};
      font-family: ${theme.v2.typography.fonts.body};
      line-height: 21px;
      word-wrap: break-word;
      cursor: pointer;
    }

    &:hover {
      color: ${theme.v2.colors.blue[300]};
    }

    @media (max-width: ${theme.v2.breakpoints.sm}) {
      font-size: 12px;
    `}

  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')} !important;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

const Anchor = styled.a<StyledLinkProps>`
  color: ${({ theme }) => theme.v2.colors.blue[300]};
  font-size: ${({ size }) => fontSize[size]};
  background: transparent;
  text-decoration: ${({ $hasUnderline }) => ($hasUnderline ? 'underline' : 'none')};

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: ${({ theme }) => theme.v2.colors.blue[300]};
  }

  &:disabled {
    color: ${({ theme }) => theme.v2.colors.blue[200]};
    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }
`;

export default { Link, Anchor };

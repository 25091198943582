import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const ModalContentOverview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  padding: 0 16px;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: start;
    padding: 0 24px;
  }
`;

const TopContent = styled.div`
  header {
    h3 {
      font-size: ${({ theme }) => theme.v2.typography.sizes.lg};
      font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
      color: ${({ theme }) => theme.v2.colors.magenta[400]};
    }
  }

  p {
    margin-top: 6px;
    font-size: ${({ theme }) => theme.v2.typography.sizes.xs};

    @media screen and (min-width: 768px) {
      font-size: ${({ theme }) => theme.v2.typography.sizes.sm};
    }
    @media screen and (min-width: 1140px) {
      font-size: ${({ theme }) => theme.v2.typography.sizes.md};
    }
  }
`;

const CarouselWrapper = styled.div`
  padding: 32px 16px 0;

  @media screen and (min-width: 768px) {
    padding: 32px 0 0;
  }
`;

const ProgressPointsDisplay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${({ theme }) => themedStyle(theme.v2.mode, '#697077', theme.v2.colors.neutral[50])};

  span {
    &:first-child {
      display: inline-block;
      font-size: ${({ theme }) => theme.v2.typography.sizes['2xl']};
      font-weight: ${({ theme }) => theme.v2.typography.weights.bold};

      &:last-child {
        display: inline-block;
        margin-top: 4px;
        font-size: ${({ theme }) => theme.v2.typography.sizes.xs};
        font-weight: ${({ theme }) => theme.v2.typography.weights.medium};
      }
    }
  }
`;

export default {
  ModalContentOverview,
  TopContent,
  CarouselWrapper,
  ProgressPointsDisplay,
};

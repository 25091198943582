import { DateFormat } from '@i18n/date-formats';
import { ChartViewType } from '@pages/content/pulse/parts/dashboards/parts/dashboard/metrics/parts/metric-item/parts/chart-metric/parts/chart-view-dropdown/chart-view-dropdown';
import { theme as themeConfig } from '@styles/theme-config';
import { currencyToShortFormat } from '@utils/fns/currency-to-short-format';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import type { ApexOptions } from 'apexcharts';

export const useLineBarOptions = (
  allDates: Date[],
  selectedChartType: 'line' | 'bar',
  chartType: ChartViewType,
): ApexOptions => {
  const { dateFormatter } = useUserDateFormat();

  return {
    dataLabels: {
      enabled: false,
    },
    legend: { show: false },
    chart: {
      type: selectedChartType,
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
        autoSelected: 'pan',
      },
    },
    xaxis: {
      type: 'category',
      categories: allDates.map((date) => dateFormatter(date, DateFormat.LETTER_MONTH_SHORT_YEAR)),
      labels: {
        show: true,
        style: {
          colors: themeConfig.color.metricFont,
          fontSize: themeConfig.fontSize.xxsmall,
          fontFamily: themeConfig.fontFamily.primary,
          fontWeight: themeConfig.fontWeight.semibold,
        },
      },
      axisBorder: {
        show: false,
      },
      tooltip: { enabled: false },
    },
    yaxis: {
      labels: {
        show: true,
        align: 'left',
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: themeConfig.color.metricFont,
          fontSize: themeConfig.fontSize.xxsmall,
          fontFamily: themeConfig.fontFamily.primary,
          fontWeight: themeConfig.fontWeight.semibold,
        },
        formatter: (value: number) => currencyToShortFormat(value) || '0',
      },
    },
    fill: {
      opacity: chartType === ChartViewType.COLUMN ? 0.5 : 1,
      colors: [themeConfig.color.chartBlueColor],
    },
    plotOptions: {
      bar: {
        columnWidth: '30%',
      },
    },
    tooltip: {
      x: {
        formatter: (_val: number, { dataPointIndex }: { dataPointIndex: number }) =>
          dateFormatter(allDates[dataPointIndex], DateFormat.LETTER_MONTH_LONG_YEAR),
      },
      marker: {
        show: false,
      },
    },
    stroke: {
      curve: 'straight',
      width: chartType === ChartViewType.COLUMN ? [2] : [4],
      colors: [themeConfig.baseColors.Blue200, themeConfig.baseColors.Red],
    },
    grid: {
      show: true,
      strokeDashArray: 5,
      borderColor: themeConfig.color.chartBlueColor,
    },
  };
};

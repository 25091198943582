import type { OnboardingStepError } from '@context/founder-onboarding/founder-onboarding.context';
import { InvestorType } from '@pages/content/profile/investor/api/types';
import type { InvestorOnboardingState } from './investor-onboarding.context';
import type { InvestorOnboardingStep } from '@pages/content/onboarding/investor/paths';

export enum InvestorOnboardingActions {
  SET_ONBOARDING_STATE = 'SET_ONBOARDING_STATE',
  SET_ONBOARDING_ACCOUNT_TYPE = 'SET_ONBOARDING_ACCOUNT_TYPE',
  ADD_ONBOARDING_STEP_ERROR = 'ADD_ONBOARDING_STEP_ERROR',
  REMOVE_ONBOARDING_STEP_ERROR = 'REMOVE_ONBOARDING_STEP_ERROR',
}

export const setInvestorOnboardingAction = (payload: InvestorOnboardingState) => ({
  type: InvestorOnboardingActions.SET_ONBOARDING_STATE as const,
  payload,
});

export const setInvestorOnboardingAccountType = (payload: InvestorType) => ({
  type: InvestorOnboardingActions.SET_ONBOARDING_ACCOUNT_TYPE as const,
  payload,
});

export const addInvestorOnboardingStepError = (payload: OnboardingStepError<InvestorOnboardingStep>) => ({
  type: InvestorOnboardingActions.ADD_ONBOARDING_STEP_ERROR as const,
  payload,
});

export const removeInvestorOnboardingStepError = (payload: string) => ({
  type: InvestorOnboardingActions.REMOVE_ONBOARDING_STEP_ERROR as const,
  payload,
});

import React from 'react';
import S from './v2-title-group.styles';
import type { V2_ICONS_CONFIG } from '../v2-icon/icons';
import { useMediaQuery } from '@/hooks/use-media-query';
import { V2Icon } from '../v2-icon/v2-icon';

export type V2TitleGroupProps = {
  title: string;
  subtitle: string;
  currentStep?: number;
  countOfSteps?: number;
  align?: 'left' | 'center' | 'right';
  icon?: (typeof V2_ICONS_CONFIG)[number]['name'];
  maxWidth?: number;
};

export const V2TitleGroup: React.FC<V2TitleGroupProps> = ({
  title,
  subtitle,
  currentStep,
  countOfSteps,
  align = 'left',
  icon,
  maxWidth,
}) => {
  const size = useMediaQuery();
  const fraction = !icon && currentStep && countOfSteps ? currentStep + ' / ' + countOfSteps : undefined;

  return (
    <S.Wrapper align={align} maxWidth={maxWidth}>
      {fraction && <S.Fraction>{fraction}</S.Fraction>}
      {icon && (
        <S.IconWrapper>
          <V2Icon name={icon} size={['xs', 'sm'].includes(size) ? 'md' : 'xl'} />
        </S.IconWrapper>
      )}
      <S.Title>{title}</S.Title>
      <S.Subtitle>{subtitle}</S.Subtitle>
    </S.Wrapper>
  );
};

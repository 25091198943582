import MessageIcon from '@assets/icons/message-icon.svg?react';

import S from './message.styles';

export const Message = ({ unreadCount }: { unreadCount?: number }) => (
  <>
    <S.IconWrapper data-testid="message">
      <MessageIcon />
    </S.IconWrapper>
    <S.NotificationIndicator count={unreadCount} mode="dot" />
  </>
);

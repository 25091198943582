import DisconnectIconBase from '@assets/icons/unfriend.svg?react';
import { SmartMatchProgress as SmartMatchProgressBase } from '@pages/content/lens/parts/smart-match-progress/smart-match-progress';
import { SubmitButton } from '@parts/submit-button/submit-button';
import styled, { css } from 'styled-components';

const SmartMatchProgress = styled(SmartMatchProgressBase)<{ isLast?: boolean }>`
  padding: 0 ${({ theme }) => theme.spacing.medium};
  padding-top: ${({ theme }) => theme.spacing.medium};
  border-top: 1px solid ${({ theme }) => theme.color.border};
  position: relative;

  ${({ isLast }) =>
    isLast &&
    css`
      margin-bottom: ${({ theme }) => theme.spacing.medium};
    `}
`;

const Message = styled(SubmitButton)`
  padding: 0;
  margin: 0;
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &:disabled {
    svg,
    path {
      opacity: 0.7;
    }
  }

  &:disabled:hover {
    border: none;
  }
`;

const Disconnect = styled(SubmitButton)`
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const DisconnectIcon = styled(DisconnectIconBase)``;

export default { Message, DisconnectIcon, Disconnect, SmartMatchProgress };

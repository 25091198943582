import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[700], theme.v2.colors.neutral[50])};
`;

const Description = styled.div`
  flex: 1;
  margin-right: 24px;
  max-width: 600px;

  ${customMediaQuery('margin-right', { md: '48px' })}
`;

const DescriptionTitle = styled.h3`
  font-size: ${({ theme }) => theme.v2.typography.sizes.lg};
  font-weight: ${({ theme }) => theme.v2.typography.weights.bold};
  color: ${({ theme }) => theme.v2.colors.magenta[400]};
  line-height: 1.25;

  ${({ theme }) => customMediaQuery('font-size', { lg: theme.v2.typography.sizes.xl })}
`;

const DescriptionInfo = styled.p`
  font-size: ${({ theme }) => theme.v2.typography.sizes.xs};
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[700], theme.v2.colors.neutral[400])};
  margin-top: 4px;

  ${({ theme }) =>
    customMediaQuery('font-size', {
      md: theme.v2.typography.sizes.sm,
      lg: theme.v2.typography.sizes.md,
    })}
`;

const DescriptionProgress = styled.div`
  margin-top: 12px;
  max-width: 450px;
`;

const Content = styled.div`
  ul {
    list-style-type: none;
  }
`;

const ContentTitle = styled.h4<{ $variant: 'primary' | 'dimmed' }>`
  font-size: ${({ theme }) => theme.v2.typography.sizes.sm};
  font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
  color: ${({ theme, $variant }) =>
    $variant === 'dimmed'
      ? theme.v2.colors.neutral[500]
      : themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.neutral[50])};
  text-transform: uppercase;
  margin-top: 24px;

  ${({ theme }) =>
    customMediaQuery('font-size', {
      md: theme.v2.typography.sizes.md,
    })}
`;

const ContentFooter = styled.footer`
  margin-top: 24px;
  margin-bottom: 12px;
  font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};

  span {
    color: ${({ theme }) => theme.v2.colors.red[400]};
  }
`;

export default {
  Header,
  Description,
  DescriptionTitle,
  DescriptionInfo,
  DescriptionProgress,
  Content,
  ContentTitle,
  ContentFooter,
};

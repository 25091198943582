export function hasEveryCommonFieldAndValue<T extends Record<string, unknown>>(baseObj: T, obj: Partial<T>): boolean {
  const baseObjKeys = Object.keys(baseObj) as Array<keyof T>;

  for (const key of baseObjKeys) {
    if (key === 'url') {
      const regex = baseObj[key] as RegExp;

      if (regex && regex.test(obj.url as string) === false) {
        return false;
      }

      continue;
    }

    if (baseObj[key] !== obj[key]) {
      return false;
    }
  }

  return true;
}

export function hasSomeCommonFieldAndValue<T extends Record<string, unknown>>(baseObj: T, obj: Partial<T>): boolean {
  const baseObjKeys = Object.keys(baseObj) as Array<keyof T>;

  for (const key of baseObjKeys) {
    if (key === 'url') {
      const regex = baseObj[key] as RegExp;

      if (regex && regex.test(obj.url as string)) {
        return true;
      }

      continue;
    }

    if (baseObj[key] === obj[key]) {
      return true;
    }
  }

  return false;
}

import { V2FilterPanel } from '@/components/ui/v2-filter-panel/v2-filter-panel';
import type { FilterOption } from '@/components/ui/v2-filter-panel/v2-filter-panel.types';
import { V2MobileFilters } from '@/components/ui/v2-mobile-filters/v2-mobile-filters';
import { useMediaQuery } from '@/hooks/use-media-query';
import { TranslationText } from '@/parts/translation-text/translation-text';
import type { Sorter } from '@/utils/type-definitions/filters';

type InviteFiltersProps = {
  filters: FilterOption[];
  activeFilter?: string[];
  disabled?: boolean;
  onFilterChange: (value: string[]) => void;
  submit: (payload: { filters: string[]; search: string; sort: Sorter | undefined }) => void;
};

export const InviteFilters = ({
  filters,
  activeFilter,
  onFilterChange,
  submit,
  disabled = false,
}: InviteFiltersProps) => {
  const breakpoint = useMediaQuery();
  const isMobile = ['xs', 'sm'].includes(breakpoint);

  return isMobile ? (
    <V2MobileFilters
      triggerLabel={<TranslationText id="connections.filters.button" />}
      showElements={['filters']}
      filters={filters}
      activeFilter={activeFilter}
      submit={submit}
      disabled={disabled}
    />
  ) : (
    <V2FilterPanel
      filters={filters}
      selectedFilters={activeFilter}
      onFilterChange={onFilterChange}
      disabled={disabled}
    />
  );
};

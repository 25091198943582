import { FormikField } from '@pages/content/profile/parts/formik-field/formik-field';
import styled from 'styled-components';

const FormikFieldPhoneNumber = styled(FormikField)`
  .antd-country-phone-input {
    padding: 0;
    height: ${({ theme }) => theme.ui.inputHeight};

    .ant-input {
      height: 100%;
    }

    .ant-select-selection-item {
      line-height: ${({ theme }) => theme.ui.inputHeight};
    }
  }
  .ant-select-selection-search-input {
    height: 100% !important;
  }

  .ant-select-arrow {
    .anticon {
      border-radius: 50%;
      background-color: ${({ theme }) => theme.color.selectArrowFill};
      width: 12px;
      display: inline-block;
    }

    svg {
      width: 6px;
    }

    path {
      fill: ${({ theme }) => theme.color.selectArrowBackground};
    }
  }
`;

export default { FormikFieldPhoneNumber };

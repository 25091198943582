import { countryFlag } from '@pages/content/profile/utils/country-flag';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import CountryPhoneInput, { ConfigProvider, type CountryPhoneInputProps } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/en/world.json';

export const CountryPhoneNumber = (props: CountryPhoneInputProps) => {
  const [czechRepublicLabel] = useTranslation(['profile.form.selectCountry.czechRepublic']);

  return (
    <ConfigProvider
      locale={en}
      areaMapper={(area) => ({
        ...area,
        name: area.name === 'Czechia' ? czechRepublicLabel : area.name,
        emoji: (
          <img
            alt="flag"
            style={{ width: 18, height: 18, verticalAlign: 'sub' }}
            loading="lazy"
            src={countryFlag(area.short)}
          />
        ),
      })}
    >
      <CountryPhoneInput
        {...props}
        defaultValue={{ short: CountryCodes.GB }}
        selectProps={{
          filterOption: (input, option) => {
            const key = (option?.key ?? '').toString().toLowerCase();
            const countryCode = (option?.value ?? '').toString().toLowerCase();

            const inputText = input.toLowerCase().trim();
            const lastSpaceIndex = key.lastIndexOf(' ');

            const countryName = key.substring(0, lastSpaceIndex);
            const phoneCode = key.substring(lastSpaceIndex + 1);

            return (
              countryName.startsWith(inputText) || phoneCode.startsWith(inputText) || countryCode.startsWith(inputText)
            );
          },
        }}
      />
    </ConfigProvider>
  );
};

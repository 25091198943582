import BillBase from '@assets/icons/bill.svg?react';
import CardIdBase from '@assets/icons/card-id.svg?react';
import { EmptyState as EmptyStateBase } from '@parts/empty-state/empty-state';
import styled, { css } from 'styled-components';

const iconStyles = css`
  margin-bottom: -35px;
  > path {
    fill: ${({ theme }) => theme.baseColors.Grey210};
  }
`;
const BillIcon = styled(BillBase)`
  ${iconStyles}
`;

const CardIdIcon = styled(CardIdBase)`
  ${iconStyles}
`;

const EmptyState = styled(EmptyStateBase)`
  > h3 {
    color: ${({ theme }) => theme.baseColors.Grey210};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
  }
`;

const NameWrap = styled.span`
  word-break: break-word;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export default { BillIcon, EmptyState, CardIdIcon, NameWrap };

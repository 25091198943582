import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_PENDING_INVESTMENTS_INVESTOR_CACHE_KEY = 'get-pending-investments-investor';

export interface PendingInvestmentsInvestor {
  id: string;
  round: {
    date: Date | string;
    type: string;
  };
  shares: number | null;
  amount: number;
  percentageOwnership: number | null;
  company: {
    id: string;
    name: string;
    avatarUrl: string | null;
  };
}

export const getPendingInvestmentsAction = async (): Promise<GraphqlResponse<PendingInvestmentsInvestor[]>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getPendingInvestments {
        pendingInvestorRequests{
            id
            round {
              date
              type
            } 
            shares
            amount
            percentageOwnership
            company {
              id
              name
              avatarUrl
            }
        }
      }
  `,
  });

  return graphqlResponseWrapper(data, ['pendingInvestorRequests']);
};

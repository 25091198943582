import _uniqueId from 'lodash/uniqueId';
import { forwardRef, useState, type ForwardedRef } from 'react';

export const PendingSvgIcon = forwardRef(
  ({ className, ...rest }: { className?: string }, ref: ForwardedRef<SVGSVGElement>) => {
    // Unique id for each instance prevents bug related to disappearing SVG
    const [id] = useState(_uniqueId());

    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        ref={ref}
        {...rest}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.25 7C0.25 3.274 3.26725 0.25 6.99325 0.25C10.726 0.25 13.75 3.274 13.75 7C13.75 10.726 10.726 13.75 6.99325 13.75C3.26725 13.75 0.25 10.726 0.25 7ZM6.32489 3.625H7.33739V7.16875L10.3749 8.971L9.86864 9.80125L6.32489 7.675V3.625Z"
          fill={`url(#${id})`}
        />
        <defs>
          <linearGradient id={id} x1="-2.83571" y1="7" x2="15.4857" y2="7" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFA800" />
            <stop offset="1" stopColor="#FFCE6F" />
          </linearGradient>
        </defs>
      </svg>
    );
  },
);

PendingSvgIcon.displayName = 'PendingSvgIcon';

import {
  getInvestmentsInCompanyAction,
  GET_DETAILED_PORTFOLIO_CACHE_KEY,
  type InvestmentInCompany,
} from '@pages/content/portfolio/investor/api/get-detailed-portfolio.action';
import { RefetchPortfolioContext } from '@pages/content/portfolio/investor/context/refetch-portfolio.context';
import { ShowMoreLessButton } from '@parts/show-more-less-button/show-more-less-button';
import { SubTable } from '@parts/sub-table/sub-table';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { ColumnsType } from 'antd/lib/table';
import { forwardRef, useContext, useImperativeHandle, useState } from 'react';
import { Columns } from './columns';
import S from './sub-table.styles';

const INVESTMENTS_TABLE_STARTING_LENGTH = 3;

export const InvestmentsTable = forwardRef(
  (
    {
      investingRelationshipId: id,
      refetchCompanyDetailsSummary,
      renderSource,
      paginated = false,
    }: {
      investingRelationshipId: string;
      refetchCompanyDetailsSummary?: Function;
      renderSource?: 'company-details';
      paginated?: boolean;
    },
    ref,
  ) => {
    const [
      dateLabel,
      roundLabel,
      sharesLabel,
      ownedLabel,
      preMoneyValLabel,
      postMoneyValLabel,
      investedLabel,
      currentValueLabel,
      confirmedByInvestorLabel,
      confirmedByFounderLabel,
      awaitingConfirmationLabel,
    ] = useTranslation([
      'portfolio.investor.table.date',
      'portfolio.investor.table.round',
      'portfolio.investor.table.shares',
      'portfolio.investor.table.owned',
      'portfolio.investor.table.preMoneyVal',
      'portfolio.investor.table.postMoneyVal',
      'portfolio.investor.table.invested',
      'portfolio.investor.table.currentValue',
      'portfolio.investor.table.confirmedByInvestor',
      'portfolio.investor.table.confirmedByFounder',
      'portfolio.investor.table.awaitingConfirmation',
    ]);

    const [isResized, setIsResized] = useState(false);

    const { data: response, refetch: refetchInvestmentsInCompany } = useQuery(
      [GET_DETAILED_PORTFOLIO_CACHE_KEY(id)],
      getInvestmentsInCompanyAction(id),
      { enabled: Boolean(id) },
    );

    useImperativeHandle(ref, () => ({
      refetchInvestments() {
        refetchInvestmentsInCompany();
      },
    }));

    const refetchPortfolio = useContext(RefetchPortfolioContext);

    if (!response || !response.data) return <S.Spin />;

    const data = response.data.map((el) => ({ ...el, investingRelationshipId: id }));

    const refetches = () => {
      refetchInvestmentsInCompany();
      refetchPortfolio();
      if (refetchCompanyDetailsSummary) {
        refetchCompanyDetailsSummary();
      }
    };

    return (
      <>
        <SubTable
          dataSource={data}
          pagination={
            paginated && {
              style: { display: 'none' },
              pageSize: isResized ? data?.length : INVESTMENTS_TABLE_STARTING_LENGTH,
              hideOnSinglePage: true,
            }
          }
          columns={
            Columns({
              refetches,
              renderSource,
              t: {
                dateLabel,
                roundLabel,
                sharesLabel,
                investedLabel,
                ownedLabel,
                preMoneyValLabel,
                postMoneyValLabel,
                currentValueLabel,
                confirmedByInvestorLabel,
                confirmedByFounderLabel,
                awaitingConfirmationLabel,
              },
            }) as ColumnsType<object>
          }
          rowKey="id"
          rowClassName={(row: InvestmentInCompany) => (row.archived ? 'archived-investment' : '')}
        />
        {data?.length > INVESTMENTS_TABLE_STARTING_LENGTH && paginated && (
          <ShowMoreLessButton isResized={isResized} setIsResized={setIsResized} />
        )}
      </>
    );
  },
);

InvestmentsTable.displayName = 'InvestmentsTable';

import type { ProductDetailsDraft } from '@domain/subscriptions/product-details';
import type { AppAction } from 'src/types';
import type { ValidatePromoCodeResponse } from '../api/create-new-subscription/create-new-subscription.action';

export const SET_LOADING_ERROR = 'checkout/set-loading-error';
export const SET_PRODUCTS = 'checkout/set-products';
export const SET_SELECTED_PRODUCT_VALUE = 'checkout/set-selected-product-value';
export const TOGGLE_LOADING = 'checkout/toggle-loading';
export const ADD_DISCOUNT = 'checkout/add-discount';
export const CLEAR_DISCOUNT = 'checkout/clear-discount';

export interface CheckoutState {
  isLoading: boolean;
  loadingError: string | null;

  products: ProductDetailsDraft[];

  selectedProductValue: string;
  discount: ValidatePromoCodeResponse | null;
}

export type CheckoutActionType =
  | AppAction<typeof SET_LOADING_ERROR, { err: string | null }>
  | AppAction<typeof TOGGLE_LOADING, { loading: boolean }>
  | AppAction<typeof SET_PRODUCTS, { products: ProductDetailsDraft[] }>
  | AppAction<typeof SET_SELECTED_PRODUCT_VALUE, { value: string }>
  | AppAction<typeof ADD_DISCOUNT, { discount: ValidatePromoCodeResponse }>
  | AppAction<typeof CLEAR_DISCOUNT>;

export const checkoutReducer = (state: CheckoutState, action: CheckoutActionType): CheckoutState => {
  switch (action.type) {
    case SET_LOADING_ERROR:
      return {
        ...state,
        loadingError: action.err,
      };

    case TOGGLE_LOADING:
      return {
        ...state,
        isLoading: action.loading,
      };

    case SET_PRODUCTS:
      return {
        ...state,
        products: action.products,
      };

    case SET_SELECTED_PRODUCT_VALUE:
      return {
        ...state,
        selectedProductValue: action.value,
        discount: null,
      };

    case ADD_DISCOUNT: {
      return {
        ...state,
        discount: action.discount,
      };
    }

    case CLEAR_DISCOUNT:
      return {
        ...state,
        discount: null,
      };

    default:
      return state;
  }
};

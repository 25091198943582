import React from 'react';
import { V2Icon } from '../v2-icon/v2-icon';
import { V2NotificationIndicator } from '../v2-notification-indicator/v2-notification-indicator';
import type { V2ButtonProps } from './v2-button.types';

import S from './v2-button.styles';

export const V2Button: React.FC<V2ButtonProps> = ({
  size = 'small',
  variant = 'primary',
  children,
  startIcon,
  endIcon,
  isFull,
  isResponsive,
  'data-testid': testId = 'button',
  notificationsCount,
  ...rest
}) => {
  const ButtonVariant = {
    outlined: S.Outlined,
    'outlined-success': S.OutlinedSuccess,
    primary: S.Primary,
    selected: S.Selected,
    link: S.Link,
    linkThin: S.LinkThin,
    secondary: S.Secondary,
    text: S.Text,
  }[variant];

  const iconSize = size === 'small' ? 'sm' : 'md';

  if (notificationsCount && endIcon) throw new Error("Can't use notificationsCount with endIcon");

  return (
    <ButtonVariant size={size} full={isFull} data-testid={testId} isResponsive={isResponsive} {...rest}>
      {startIcon && <V2Icon name={startIcon} size={iconSize} data-testid={`${testId}-start-icon`} />}
      <S.ChildrenWrapper>{children}</S.ChildrenWrapper>
      {endIcon && <V2Icon name={endIcon} size={iconSize} data-testid={`${testId}-end-icon`} />}
      {Boolean(notificationsCount) && (
        <V2NotificationIndicator count={notificationsCount} data-testid={`${testId}-notification-dot`} />
      )}
    </ButtonVariant>
  );
};

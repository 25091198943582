import { buildQueryParams, buildQueryParamTypes } from '@/utils/fns/graphql-query-builder';
import type { Sorter } from '@/utils/type-definitions/filters';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';
import type { InvestorAdvisorConnectionIntent, InvestorAdvisorFounderConnection } from './../types';

export const GET_NED_INVESTOR_FOUNDER_CONNECTIONS_KEY = 'get-ned-investor-founder-connections-query';

type ResponseData = {
  total: number;
  items: InvestorAdvisorFounderConnection[];
};

type QueryConfig = {
  page?: number;
  perPage?: number;
  searchByName?: string;
  intent: InvestorAdvisorConnectionIntent | undefined;
  sort?: Sorter;
};

export const getConnections = async ({
  page = 1,
  perPage = 6,
  searchByName = '',
  intent,
  sort,
}: QueryConfig): Promise<GraphqlResponse<ResponseData>> => {
  const variablesType = {
    start: 'Int!',
    limit: 'Int!',
    searchByName: 'String!',
    intent: 'FilterableConnectionIntentValue',
    sort: '[SortType!]',
  };

  const variables = {
    start: (page - 1) * perPage,
    limit: page * perPage,
    searchByName,
    intent,
    sort,
  };

  const { data } = await axiosInstance.post('/query', {
    variables,
    query: `
     query getNedInvestorFounderConnections (${buildQueryParamTypes(variablesType)}) {
        getNedInvestorFounderConnections (${buildQueryParams(variablesType)}) {
           total
           items {
            connectionId
            connectionDate
            notesCount
            unreadMessagesCount
            founderId
            businessName
            representativeName
            avatarUrl
            isNew
            investmentStage
            industry
            canBePreviewed
            connectionIntent
          }
         }
       }
    `,
  });

  return graphqlResponseWrapper(data, ['getNedInvestorFounderConnections']);
};

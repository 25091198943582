import { axiosInstance } from '@utils/axios/axios-instance';
import type { CompanyDetailsData } from '../../founder-profile.page';

export type UpdateCompanyDetailsPayload = Omit<CompanyDetailsData, 'countryOfResidence'>;

export const updateCompanyDetailsAction = (formData: UpdateCompanyDetailsPayload) => {
  const { logoUrl, email, ...rest } = formData;

  return axiosInstance.patch('/profile/founder/personal-details', rest);
};

import type { HTMLAttributes } from 'react';
import { useV2AriaMobileConnectionCardContext } from '../v2-aria-mobile-connection-card-context';

import S from '../v2-aria-mobile-connection-card.styles';

type BodyProps = {
  children: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export const Body = ({ children, ...rest }: BodyProps) => {
  useV2AriaMobileConnectionCardContext();

  return <S.Body {...rest}>{children}</S.Body>;
};

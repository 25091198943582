import { Homepage } from '@/modules/advisor/homepage/homepage';
import type { FC } from 'react';

type AdvisorStartPageProps = {
  Layout: FC<{ legacyContainer?: boolean }>;
};

export const AdvisorStartPage = ({ Layout }: AdvisorStartPageProps) => {
  return (
    <Layout legacyContainer={false}>
      <Homepage />
    </Layout>
  );
};

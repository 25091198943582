import { Roles } from '@/domain/accounts/roles';
import { investorIdQueryParam } from '@/pages/content/public-profile/investor/investor.page';
import { Links } from '@/router/links';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { V2Badge } from '../v2-badge/v2-badge';
import { V2Tooltip } from '../v2-tooltip/v2-tooltip';
import type { V2InvestorAdvisorProfileLinkProps } from './v2-investor-advisor-profile-link.types';

import S from './v2-investor-advisor-profile-link.styles';

export const V2InvestorAdvisorProfileLink = ({
  profileId,
  current,
  ['data-testid']: dataTestId = 'v2-investor-advisor-profile-link',
  ...restAttributes
}: V2InvestorAdvisorProfileLinkProps) => {
  const secondRole = current === Roles.INVESTOR ? Roles.NED : Roles.INVESTOR;

  const [linkLL, tooltipLL] = useTranslation([
    {
      id: 'profile.preview.investor-advisor.multirole-link-text',
      variables: { role: secondRole === Roles.NED ? 'advisor' : 'investor' },
    },
    {
      id: 'profile.preview.investor-advisor.multirole-link-tooltip',
      variables: { role: secondRole === Roles.NED ? 'advisor' : 'investor' },
    },
  ]);

  const urls = {
    [Roles.INVESTOR]: Links.INVESTOR_PUBLIC_PROFILE() + `?${investorIdQueryParam}=${profileId}`,
    [Roles.NED]: Links.NED_PUBLIC_PROFILE(profileId),
  };

  return (
    <V2Tooltip tooltipText={tooltipLL}>
      <S.Link variant="primary" data-testid={dataTestId} to={urls[secondRole]} {...restAttributes}>
        <V2Badge text={linkLL} startIcon="eye" />
      </S.Link>
    </V2Tooltip>
  );
};

export enum ConnectionStatus {
  Pending = 'Pending',
  Accepted = 'Accepted',
  Declined = 'Declined',
  Blocked = 'Blocked',
  Canceled = 'Canceled',
}

export enum ConnectionType {
  Request = 'Request',
  Invitation = 'Invitation',
}

import { V2Loader } from '@/components/ui/v2-loader/v2-loader';
import { V2SuggestionBanner } from '@/components/ui/v2-suggestion-banner/v2-suggestion-banner';
import { useWizard } from '@/hooks/use-wizard';
import { useGetFounderAdvisorQuestionsAnswers } from '@/modules/founder/hooks/use-get-founder-advisor-questions-answers';
import { useGetFounderSmartMatchSkillsOptions } from '@/modules/founder/hooks/use-get-founder-smart-match-skills';
import { useGetMostPopularSkillsets } from '@/modules/founder/hooks/use-get-most-popular-skillsets';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { AdvisorTypeStep } from './components/advisor-type-step';
import { FinalStep } from './components/final-step';
import { HelpStep } from './components/help-step';
import { IndustryKnowledgeStep } from './components/industry-knowledge-step';
import { SkillsetStep } from './components/skillset-step';
import { WelcomeStep } from './components/welcome-step';

import { bookMeetingLink } from '@/constants/links';
import S from './advisor-quick-search-questions-modal.styles';

type ModalProps = {
  isOpen: boolean;
  close: () => void;
};

const steps = [
  {
    id: 'welcome-step',
    component: WelcomeStep,
  },
  {
    id: 'skillset-step',
    component: SkillsetStep,
  },
  {
    id: 'industry-knowledge-step',
    component: IndustryKnowledgeStep,
  },
  {
    id: 'advisor-type-step',
    component: AdvisorTypeStep,
  },
  {
    id: 'help-step',
    component: HelpStep,
  },
  {
    id: 'final-step',
    component: FinalStep,
  },
];

export const AdvisorQuickSearchQuestionsModal = ({ isOpen, close }: ModalProps) => {
  const { currentStep, progress, nextStep, previousStep, resetState } = useWizard({ steps, init: 0 });
  const CurrentStepComponent = steps[currentStep].component;

  const { founderAdvisorQuestionsAnswersData, isFounderAdvisorQuestionsAnswersLoading } =
    useGetFounderAdvisorQuestionsAnswers();
  const { founderSmartMatchSkillsData, isFounderSmartMatchSkillsLoading } = useGetFounderSmartMatchSkillsOptions();
  const { mostPopularSkillsetsData, isMostPopularSkillsetsLoading } = useGetMostPopularSkillsets();

  const isLoading =
    isFounderSmartMatchSkillsLoading || isMostPopularSkillsetsLoading || isFounderAdvisorQuestionsAnswersLoading;
  const isDataLoaded = founderSmartMatchSkillsData && mostPopularSkillsetsData && founderAdvisorQuestionsAnswersData;
  const shouldRender = Boolean(isLoading || isDataLoaded);

  const [consultationButtonTranslation] = useTranslation(['advisors-quick-search.modal-consultation-button']);

  const closeAndReset = () => {
    close();
    resetState();
  };

  return (
    <S.Modal
      isOpen={isOpen}
      closable={true}
      onCancel={close}
      footer={null}
      size="lg"
      progress={progress}
      data-testid="qualifying-questions-modal"
    >
      {isLoading && <V2Loader />}

      {shouldRender && founderAdvisorQuestionsAnswersData && (
        <CurrentStepComponent
          initialValues={founderAdvisorQuestionsAnswersData}
          founderSmartMatchSkillsData={founderSmartMatchSkillsData}
          mostPopularSkillsetsData={mostPopularSkillsetsData}
          back={previousStep}
          next={nextStep}
          close={closeAndReset}
        />
      )}

      <S.BannerWrapper>
        <a rel="noreferrer" target="_blank" href={bookMeetingLink} style={{ textDecoration: 'none' }}>
          <V2SuggestionBanner text={consultationButtonTranslation} icon="calendar" />
        </a>
      </S.BannerWrapper>
    </S.Modal>
  );
};

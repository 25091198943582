import {
  GET_BROWSE_COMPANIES_FILTERS_CACHE_KEY,
  getBrowseCompaniesFilters,
} from '@pages/content/lens/api/get-browse-companies-filters';
import {
  GET_BROWSE_COMPANIES_METRICS_CACHE_KEY,
  getBrowseCompaniesMetrics,
} from '@pages/content/lens/api/get-browse-companies-metrics';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { type FilterOptions } from '../../filters/filters';
import { mapLastSearchName } from '../../filters/helpers/helpers';
import { initialMetricTypeFilter } from '../../metric-filter/metric-filter';

export const useSavedFilters = ({ onInitialLoad }: { onInitialLoad: (filterOptions: FilterOptions) => unknown }) => {
  const [isFullyLoaded, setIsFullyLoaded] = useState(false);

  const { data: companiesFiltersResponseData, isLoading: isCompaniesFiltersLoading } = useQuery(
    [GET_BROWSE_COMPANIES_FILTERS_CACHE_KEY],
    getBrowseCompaniesFilters,
    { cacheTime: 0, refetchOnWindowFocus: false },
  );
  const { data: companiesMetricsResponseData, isLoading: isCompaniesMetricsLoading } = useQuery(
    [GET_BROWSE_COMPANIES_METRICS_CACHE_KEY],
    getBrowseCompaniesMetrics,
    { cacheTime: 3600, refetchOnWindowFocus: false },
  );

  const { lastSearchData } = companiesFiltersResponseData?.data || {};
  const { availableMetrics } = companiesMetricsResponseData?.data || {};
  const lastSearchWithMetricsData = lastSearchData && mapLastSearchName(lastSearchData, availableMetrics);

  const savedFiltersWithDefaults: FilterOptions = {
    namePhrase: lastSearchWithMetricsData?.namePhrase || '',
    industry: lastSearchWithMetricsData?.industry || [],
    customerGroup: lastSearchWithMetricsData?.customerGroup || [],
    productStage: lastSearchWithMetricsData?.productStage || [],
    investmentStage: lastSearchWithMetricsData?.investmentStage || [],
    metrics: lastSearchWithMetricsData?.metrics || [],
    firstMetricTypeFilter: lastSearchWithMetricsData?.metrics?.[0] || initialMetricTypeFilter,
    secondMetricTypeFilter: lastSearchWithMetricsData?.metrics?.[1] || initialMetricTypeFilter,
    thirdMetricTypeFilter: lastSearchWithMetricsData?.metrics?.[2] || initialMetricTypeFilter,
    hasSeisLimitsRemaining: lastSearchWithMetricsData?.hasSeisLimitsRemaining || false,
    hasEisLimitsRemaining: lastSearchWithMetricsData?.hasEisLimitsRemaining || false,
    onlyNewlyRegistered: lastSearchWithMetricsData?.onlyNewlyRegistered || false,
    sort: lastSearchWithMetricsData?.sort || null,
    countriesOfResidence: lastSearchWithMetricsData?.countriesOfResidence || [],
    regions: lastSearchWithMetricsData?.regions || [],
  };

  useEffect(() => {
    if (isFullyLoaded) return;

    if (companiesFiltersResponseData && companiesMetricsResponseData) {
      onInitialLoad(savedFiltersWithDefaults);
      setIsFullyLoaded(true);
    }
  }, [companiesFiltersResponseData, companiesMetricsResponseData]);

  return {
    isLoaded: isFullyLoaded,
    isLoading: isCompaniesFiltersLoading || isCompaniesMetricsLoading,
    filters: savedFiltersWithDefaults,
    availableMetrics,
  };
};

import { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import S from './lazy-editor.styles';

const Editor = lazy(() => import('react-draft-wysiwyg').then((module) => ({ default: module.Editor })));

type LazyEditorProps = React.ComponentProps<typeof Editor>;

export const LazyEditor = (props: LazyEditorProps) => (
  <Suspense fallback={<S.LoadingPlaceholder prefix={<Spin />} disabled />}>
    <Editor {...props} />
  </Suspense>
);

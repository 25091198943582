import React, { createContext, useContext } from 'react';
import { useAddExperienceReducer, type Action, type State } from './add-experience-modal.reducer';

type AddExperienceContextData = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

const ExperienceContext = createContext<AddExperienceContextData | undefined>(undefined);

export const useAddExperienceContext = (): AddExperienceContextData => {
  const context = useContext(ExperienceContext);
  if (!context) {
    throw new Error('useExperienceContext must be used within an ExperienceProvider');
  }
  return context;
};

export const AddExperienceProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useAddExperienceReducer();

  return (
    <ExperienceContext.Provider value={{ state, dispatch }}>
      <>{children}</>
    </ExperienceContext.Provider>
  );
};

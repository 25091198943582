import { Input } from 'antd';
import styled from 'styled-components';

const Wrapper = styled(Input)`
  background-color: ${({ theme }) => theme.color.disabled};
  color: ${({ theme }) => theme.color.fontInput};
  border: 1px solid ${({ theme }) => theme.color.border}!important;
  padding: ${({ theme }) => theme.spacing.small};
`;

export default { Wrapper };

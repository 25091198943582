import { Content } from './parts/content';
import { DateLabel } from './parts/date-label';
import { Title } from './parts/title';
import { V2NoteTileContext } from './v2-note-tile-context';
import S from './v2-note-tile.styles';
import type { V2NoteTileProps } from './v2-note-tile.types';

const V2NoteTile = ({ heading, content, footer, noteContent, inEditMode, warningText, size }: V2NoteTileProps) => {
  return (
    <V2NoteTileContext.Provider value={noteContent}>
      <S.WarningWrapper>
        <S.NoteTile $inEditMode={inEditMode} $isWarning={Boolean(warningText)} $size={size}>
          {heading}
          {content}
          {footer}
        </S.NoteTile>
        <S.Warning>{warningText}</S.Warning>
      </S.WarningWrapper>
    </V2NoteTileContext.Provider>
  );
};

V2NoteTile.Heading = S.HeadingWrapper;
V2NoteTile.Title = Title;
V2NoteTile.DateLabel = DateLabel;
V2NoteTile.Content = Content;
V2NoteTile.ButtonsWrapper = S.ButtonsWrapper;
V2NoteTile.LeftTextButton = S.StyledLeftTextButton;
V2NoteTile.RightTextButton = S.StyledRightTextButton;
V2NoteTile.IconButton = S.StyledIconButton;

export { V2NoteTile };

import styled, { css } from 'styled-components';

const Wrapper = styled.div<{ small: boolean; $height: string | undefined; $background: boolean }>`
  height: ${({ small, $height }) => ($height ?? small ? '20vh' : '100vh')};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ $background }) =>
    $background &&
    css`
      background: ${({ theme }) => theme.color.layoutBackground};
    `};
`;

export default { Wrapper };

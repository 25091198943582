import {
  GET_SMART_MATCH_OPTIONS_REQUESTS_CACHE_KEY,
  getSmartMatchOptionsAction,
} from '@/pages/content/profile/api/get-smart-match-options/get-smart-match-options.action';
import {
  addInvestorOnboardingStepError,
  removeInvestorOnboardingStepError,
} from '@context/investor-onboarding/investor-onboarding.actions';
import type { StepContentProps } from '@pages/content/onboarding/investor/paths.config';
import Title from '@pages/content/onboarding/parts/title/title';
import { updateSmartMatchAction } from '@pages/content/profile/investor/api/update-smart-match/update-smart-match';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import message from '@parts/message/message';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useInvestorOnboardingContext } from '@utils/hooks/use-onboarding-context/use-investor-onboarding-context';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useEffect } from 'react';
import {
  ONBOARDING_INVESTOR_BUSINESS_DATA_CACHE_KEY,
  getInvestorOnboardingBusinessDataAction,
} from '../../api/get-investor-onboarding-business-data.action';
import { INVESTOR_ONBOARDING_STEP_BUSINESS_KEY } from '../../paths';
import { InvestorBusinessEditor } from './parts/editor';

const BUSINESS_INITIAL_DATA_LOAD_ERROR_ID = `${INVESTOR_ONBOARDING_STEP_BUSINESS_KEY}-initial-load-data-error`;

export const BusinessPageContent = ({ currentPage, endPage }: StepContentProps) => {
  const [title, subTitle, nextButtonTargetGeographyErrorLabel] = useTranslation([
    'investor-onboarding.business.title',
    'investor-onboarding.business.subTitle',
    'investor-onboarding.business.nextButtonTargetGeographyError',
  ]);

  const { dispatch } = useInvestorOnboardingContext();

  useEffect(() => {
    dispatch(
      addInvestorOnboardingStepError({
        id: BUSINESS_INITIAL_DATA_LOAD_ERROR_ID,
        step: INVESTOR_ONBOARDING_STEP_BUSINESS_KEY,
        error: nextButtonTargetGeographyErrorLabel,
        disableButton: true,
      }),
    );
  }, []);

  const { mutateAsync: updateSmartMatch, isLoading: isMutationLoading } = useMutation(updateSmartMatchAction, {
    onError(err: AxiosError) {
      message.error({ content: getServerError(err) });
    },
  });

  const {
    data: dataResponse,
    isLoading: isDataLoading,
    isFetching: isDataFetching,
    refetch,
  } = useQuery([ONBOARDING_INVESTOR_BUSINESS_DATA_CACHE_KEY], getInvestorOnboardingBusinessDataAction, {
    cacheTime: 0,
    onSuccess(data) {
      if (!data.data) return;

      dispatch(removeInvestorOnboardingStepError(BUSINESS_INITIAL_DATA_LOAD_ERROR_ID));
    },
  });

  const { data: optionsResponse, isLoading: isOptionsLoading } = useQuery(
    [GET_SMART_MATCH_OPTIONS_REQUESTS_CACHE_KEY],
    getSmartMatchOptionsAction,
    { cacheTime: Infinity, staleTime: Infinity },
  );

  const isLoading = isOptionsLoading || isDataLoading;
  const isDataLoaded = Boolean(optionsResponse?.data && dataResponse?.data);
  const actionPending = isDataFetching || isMutationLoading;

  return (
    <>
      <Title title={title} subHeadline={subTitle} currentPage={currentPage} endPage={endPage} />

      {isLoading || !isDataLoaded ? (
        <FullHeightSpinner small />
      ) : (
        <InvestorBusinessEditor
          smartMatchOptions={optionsResponse?.data!}
          data={dataResponse?.data!}
          refetchData={refetch}
          handleUpdate={updateSmartMatch}
          actionsPending={actionPending}
        />
      )}
    </>
  );
};

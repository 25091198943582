import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_METRICS_ACTION_CACHE_KEY = 'get-founder-metrics';

export type MetricsOwner = {
  internalMetrics: InternalMetric[];
  externalMetrics: ExternalMetric[];
};

export enum MetricType {
  Boolean = 'Boolean',
  Numeric = 'Numeric',
  Percentage = 'Percentage',
  Score = 'Score',
  Money = 'Money',
}

type MetricBase = {
  id: string;
  name: string;
  type: MetricType;
  category: string;
  isExternal: boolean;
};

export type ExternalMetric = MetricBase & {
  hasDataPoints: boolean;
};

export type InternalMetric = MetricBase;

export const getFounderMetricsAction = async (): Promise<GraphqlResponse<MetricsOwner>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getFounderMetrics {
        companyOwnerMetrics {
          internalMetrics {
            id
            name
            type
            category
            isExternal
          }
          externalMetrics {
            id
            name
            type
            category
            isExternal
            hasDataPoints
          }
        }
      }
    `,
  });

  return graphqlResponseWrapper(data, ['companyOwnerMetrics']);
};

import { V2RadioGroup } from '@/components/ui/v2-radio-group/v2-radio-group';
import { FormikField as FormikFieldBase } from '@pages/content/profile/parts/formik-field/formik-field';
import { Spin } from 'antd';
import styled from 'styled-components';

const RequiredAsterisk = styled.span`
  color: ${({ theme }) => theme.color.error};
`;

const FormikField = styled(FormikFieldBase)<{ wide?: boolean }>`
  margin: ${({ theme }) => theme.spacing.xmedium} auto 0;
  max-width: ${({ wide }) => (wide ? '500px' : '380px')};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.normal};

  label {
    font-size: ${({ theme }) => theme.fontSize.small};
  }
`;

const AvatarSpin = styled(Spin)`
  height: 75px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AvatarUploader = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const Controls = styled.div`
  display: inline-block;
  width: 70%;
  margin-left: ${({ theme }) => theme.spacing.xmedium};
`;

const AvatarUploadButton = styled.span`
  color: ${({ theme }) => theme.color.submitButton};
  display: inline-block;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  cursor: pointer;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const AvatarTextInfo = styled.span`
  color: ${({ theme }) => theme.onboardingColors.avatarInfo};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  display: block;
`;

const Form = styled.form`
  margin: 60px auto ${({ theme }) => theme.spacing.base};
  width: 100%;
`;

const RadioGroupContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.small};
`;

const RadioGroupContentWrapper = styled.div`
  width: 500px;
  font-size: ${({ theme }) => theme.fontSize.small};
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing.small};
`;

const RadioGroup = styled(V2RadioGroup)`
  gap: ${({ theme }) => theme.spacing.base};
  padding-left: ${({ theme }) => theme.spacing.xmedium};

  @media (max-width: 540px) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xsmall};
  }
`;

const RadioGroupTitle = styled.span`
  font-weight: ${({ theme }) => theme.v2.typography.weights.medium};
  margin-bottom: 10px;
`;

const Span = styled.span`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.v2.colors.neutral[50]};
  display: flex;
  align-items: center;
`;

const LabelText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.v2.colors.notifications['info-dark']};
`;

export default {
  RequiredAsterisk,
  FormikField,
  AvatarSpin,
  AvatarUploader,
  Controls,
  AvatarUploadButton,
  AvatarTextInfo,
  Form,
  RadioGroupContainer,
  RadioGroupContentWrapper,
  RadioGroup,
  RadioGroupTitle,
  Span,
  LabelText,
};

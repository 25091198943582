import { Roles } from '@domain/accounts/roles';

import { NumericConditions } from '@pages/content/public-profile/founder/api/set-up-numeric-trigger.actions';
import { MetricType } from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import {
  scaleTransform,
  ScaleTransformPurpose,
} from '@pages/content/pulse/founder/parts/management/parts/metrics/parts/numeric-editor/numeric-editor';
import { TriggerTableColumn } from '@pages/content/pulse/investor/parts/triggers/parts/table/parts/columns/parts/trigger-table-column';
import type { TriggerRowData } from '@pages/content/pulse/investor/parts/triggers/triggers';
import { getMetricSymbol } from '@pages/content/pulse/parts/dashboards/parts/dashboard/metrics/parts/metric-item/parts/chart-metric/chart-metric';
import { SetUpNumericTrigger } from '@pages/content/pulse/parts/dashboards/parts/dashboard/metrics/parts/metric-item/parts/trigger-management/parts/set-up-numeric-trigger/set-up-numeric-trigger';
import { FilterHeader } from '@parts/filter-header/filter-header';
import { Icon } from '@parts/icon/icon';
import Modal from '@parts/modal/modal';
import OnTopFooter from '@parts/on-top-footer/on-top-footer';
import { SwitchLabelled } from '@parts/switch-labelled/switch-labelled';
import { UserTableCustomRow } from '@parts/user-table-row/user-table-row';
import type { ColumnsQuerying } from '@utils/hooks/use-filtering/use-filtering';
import { useUserCurrency } from '@utils/hooks/use-user-currency/use-user-currency';
import type { ColumnsType } from 'antd/lib/table';
import * as H from 'history';
import S from './columns.styles';

export const Columns = ({
  t,
  history,
  editedTrigger,
  setEditedTrigger,
  deletedTrigger,
  setDeletedTrigger,
  deleteTrigger,
  refetch,
  conditionLabels,
  toggleActivity,
  querying,
  currencySymbol,
}: {
  t: { [key: string]: string };
  history: H.History<H.LocationState>;
  editedTrigger: string | null;
  setEditedTrigger: (id: string | null) => void;
  deletedTrigger: string | null;
  setDeletedTrigger: (id: string | null) => void;
  deleteTrigger: Function;
  refetch: Function;
  conditionLabels: { [key: string]: string };
  toggleActivity: Function;
  querying: ColumnsQuerying;
  currencySymbol: string;
}): ColumnsType<TriggerRowData> => {
  const { commaize } = useUserCurrency();

  const renderSymbol = (v: string, symbol: string, type: MetricType) =>
    `${type === MetricType.Money ? symbol : ''} ${v} ${type !== MetricType.Money ? symbol : ''}`;

  const renderValue = (v: number, scale: number, type: MetricType) =>
    commaize(
      scaleTransform(v, scale, ScaleTransformPurpose.READ),
      type === MetricType.Percentage,
      type === MetricType.Percentage ? 0 : scale,
    );

  return [
    {
      title: <FilterHeader querying={querying} dataKey="companyName" title={t.companyNameLabel} />,
      dataIndex: ['companyName', 'companyId', 'companyLogoUrl'],
      key: 'companyId',
      width: '15%',
      render: (_v: unknown, row: TriggerRowData) => (
        <UserTableCustomRow
          title={t.companyNameLabel}
          nameKey="companyName"
          idKey="companyId"
          avatarUrlKey="companyLogoUrl"
          history={history}
          toRole={Roles.FOUNDER}
          isClickableProperty="isAvailable"
          limitNameWidth
          tooltipLabel={row.tooltip}
          redirectEvaluation={[
            {
              shouldBePresent: true,
              key: 'companyId',
            },
          ]}
          row={row}
        />
      ),
    },
    {
      title: <FilterHeader querying={querying} dataKey="metricName" title={t.metricNameLabel} />,
      dataIndex: 'metricName',
      key: 'metricName',
      width: '15%',
      render: (v, row) => (
        <TriggerTableColumn title={t.metricNameLabel} row={row}>
          {v}
        </TriggerTableColumn>
      ),
    },
    {
      title: <FilterHeader querying={querying} dataKey="condition" title={t.conditionLabel} />,
      dataIndex: 'condition',
      key: 'condition',
      width: '6.5%',
      render: (v, row) => (
        <TriggerTableColumn title={t.conditionLabel} row={row}>
          {conditionLabels[v]}
        </TriggerTableColumn>
      ),
    },
    {
      title: <FilterHeader querying={querying} dataKey="value" title={t.valueLabel} />,
      dataIndex: 'value',
      key: 'value',
      width: '12%',
      render: (v, row) => (
        <TriggerTableColumn title={t.valueLabel} row={row}>
          {(() => {
            const type = row.metricType;
            const symbol = getMetricSymbol(row.metricType, { currencySymbol });
            const scale = row.metricScale;
            const range = Number(row.rangeValue);

            if (type === MetricType.Boolean) {
              return v === 0 ? t.falseLabel : t.trueLabel;
            }

            if (row.condition === NumericConditions.Between) {
              return renderSymbol(`${renderValue(v, scale, type)} - ${renderValue(range, scale, type)}`, symbol, type);
            }

            return renderSymbol(renderValue(v, scale, type), symbol, type);
          })()}
        </TriggerTableColumn>
      ),
    },
    {
      title: <FilterHeader querying={querying} dataKey="metricValue" title={t.metricValueLabel} />,
      dataIndex: 'metricValue',
      key: 'metricValue',
      width: '10.5%',
      render: (v, row) => (
        <TriggerTableColumn title={t.metricValueLabel} row={row}>
          {(() => {
            if (row.metricType === MetricType.Boolean) {
              if (v === null) return v;

              if (v === 0) return t.falseLabel;

              return t.trueLabel;
            }

            const symbol = getMetricSymbol(row.metricType, { currencySymbol });
            const scale = row.metricScale;
            const type = row.metricType;

            const value = renderSymbol(typeof v === 'number' ? renderValue(v, scale, type) : '0', symbol, type);

            if (row.isActive) {
              return value;
            }

            return Number(value) === 0 ? ' - ' : value;
          })()}
        </TriggerTableColumn>
      ),
    },
    {
      title: <FilterHeader querying={querying} dataKey="isMet" title={t.metLabel} />,
      dataIndex: 'isMet',
      key: 'isMet',
      width: '1%',
      render: (v: boolean, row) => (
        <TriggerTableColumn row={row} title={t.metLabel}>
          {v ? <S.CheckCircle /> : null}
        </TriggerTableColumn>
      ),
    },
    {
      title: <FilterHeader querying={querying} dataKey="isActive" title={t.activeLabel} />,
      dataIndex: 'isActive',
      key: 'isActive',
      width: '1%',
      render: (v, row) => (
        <TriggerTableColumn title={t.activeLabel} row={row}>
          <SwitchLabelled
            onClick={() => {
              toggleActivity({ triggerId: row.id, isActive: !v });
            }}
            disabled={!row.isAvailable}
            isChecked={v}
          />
        </TriggerTableColumn>
      ),
    },
    {
      width: '4%',
      render: (_: unknown, row: TriggerRowData) => {
        const isBoolean = row.metricName === 'Patent';

        return (
          <S.Controls>
            {isBoolean && row.isAvailable && row.value ? null : (
              <>
                <Icon icon={S.Edit} onClick={() => setEditedTrigger(row.id)} disabled={!row.isAvailable} />
                <SetUpNumericTrigger
                  isOpen={editedTrigger === row.id}
                  onClose={() => setEditedTrigger(null)}
                  handleTriggerSet={() => {
                    refetch();
                    setEditedTrigger(null);
                  }}
                  scale={row.metricScale || 0}
                  metricId={row.metricId}
                  companyAvatarUrl={row.companyLogoUrl ?? undefined}
                  metricName={row.metricName}
                  currentTrigger={{
                    value: row.value ?? undefined,
                    condition: row.condition ?? undefined,
                    rangeValue: row.rangeValue ?? undefined,
                    id: row.id,
                  }}
                  companyName={row.companyName}
                />
              </>
            )}
            <S.Trash className="remove-btn" onClick={() => setDeletedTrigger(row.id)} />
            <Modal
              visible={deletedTrigger === row.id}
              onCancel={() => setDeletedTrigger(null)}
              closeModal={() => setDeletedTrigger(null)}
              title={t.deleteTitleLabel}
              footer={
                <OnTopFooter
                  isDanger
                  onCancel={() => setDeletedTrigger(null)}
                  onConfirm={() => deleteTrigger(row.id)}
                  confirmLabel={t.confirmDeleteLabel}
                  cancelLabel={t.cancelDeleteLabel}
                />
              }
            >
              {t.deleteContentLabel}
            </Modal>
          </S.Controls>
        );
      },
    },
  ];
};

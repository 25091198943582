import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export interface Dashboard {
  name: string;
  id: string;
}

export type Dashboards = {
  hasCloseConnections: boolean;
  dashboardsList: Dashboard[];
};

export const GET_FOUNDER_DASHBOARDS = 'get-founder-dashboards';

export const getFounderDashboardsAction = async (): Promise<GraphqlResponse<Dashboards>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getFounderDashboards {
        foundersDashboards {
          hasCloseConnections,
          dashboardsList {
          name,
          id
        }
      }
    }
    `,
  });

  return graphqlResponseWrapper(data, ['foundersDashboards']);
};

import { V2Switch } from '@/components/ui/v2-switch/v2-switch';
import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const Switch = styled(V2Switch)`
  &.ant-switch-checked {
    background-color: ${({ theme }) =>
      themedStyle(theme.v2.mode, theme.v2.colors.blue[500], theme.v2.colors.blue[500])};
  }
`;

export default { Switch };

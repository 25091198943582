import SecuredIcon from '@assets/icons/undraw-secured.svg';
import { EmptyState } from '@parts/empty-state/empty-state';
import { SubmitButton as Button } from '@parts/submit-button/submit-button';
import { Links } from '@router/links';
import { useMutation, type MutationFunction } from '@tanstack/react-query';
import { useState } from 'react';

import S from './publish-empty-state.styles';

const EmptyIconImage = () => <img src={SecuredIcon} alt="secured-icon" loading="lazy" />;
export interface IPublishEmptyStateTranslations {
  emptySecuredTitle?: string;
  emptySecuredSubTitle?: string;
  emptyPrivateTitle: string;
  emptyPrivateSubTitle: string;
  emptyPublish: string;
  goToProfile: string;
}

export const PublishEmptyState = ({
  smartMatchShortages,
  published,
  refetch,
  translations,
  publishProfileAction,
}: {
  smartMatchShortages: string[];
  published: boolean;
  refetch: Function;
  translations: IPublishEmptyStateTranslations;
  publishProfileAction: MutationFunction<unknown, {}>;
}) => {
  const {
    emptySecuredTitle,
    emptySecuredSubTitle,
    emptyPrivateTitle,
    emptyPrivateSubTitle,
    emptyPublish,
    goToProfile,
  } = translations;

  const [publishedClicked, setPublishedClicked] = useState(false);

  const { mutateAsync: publishProfile } = useMutation(publishProfileAction, {
    onMutate: () => setPublishedClicked(true),
    onSettled: () => {
      setPublishedClicked(true);
      setTimeout(() => {
        refetch();
      }, 1000);
    },
    onError: () => setPublishedClicked(false),
  });

  const emptyStateProps = smartMatchShortages
    ? { title: emptyPrivateTitle, subTitle: emptyPrivateSubTitle }
    : { title: emptySecuredTitle, subTitle: emptySecuredSubTitle };

  return (
    <EmptyState Icon={EmptyIconImage} {...emptyStateProps}>
      {smartMatchShortages.length === 0 ? (
        <S.PublishSwitch
          disabled={false}
          rightLabel={{ default: emptyPublish }}
          isChecked={published || publishedClicked}
          onClick={publishProfile}
        />
      ) : (
        <S.GoToProfile to={Links.PROFILE()}>
          <Button>{goToProfile}</Button>
        </S.GoToProfile>
      )}
    </EmptyState>
  );
};

import { createContext, useContext } from 'react';

type ContextValues = {
  details: {
    companyName: string;
    role: string;
    startDate: string;
    endDate: string | null;
  };
  descriptionText: string | null;
};

const V2ExperienceTileContext = createContext<ContextValues | null>(null);

export const useV2ExperienceTileContext = () => {
  const context = useContext(V2ExperienceTileContext);
  if (!context) throw new Error('V2ExperienceTile.* component must be rendered as child of V2ExperienceTile component');

  return context;
};

export { V2ExperienceTileContext };

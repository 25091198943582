import { V2Badge } from '@/components/ui/v2-badge/v2-badge';
import { V2WidgetCard } from '@/components/ui/v2-widget-card/v2-widget-card';
import type { WidgetCardProps } from '@/modules/founder/homepage/homepage';

import { gradients } from '@/styles/v2-theme/gradients';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';

import { WidgetCardContent } from '../widget-card-content';

type WelcomeTourCardProps = {
  isCompleted: boolean;
  onActionClick: () => void;
  disabled?: boolean;
  ['data-testid']?: string;
};

export const WelcomeTourCard = ({
  isCompleted,
  disabled,
  onActionClick,
  ['data-testid']: dataTestId = 'discoverability-card',
}: WelcomeTourCardProps) => {
  const [titleLL, subTitleLL, descriptionLL, actionLL, actionCompletedLL, badgeTextLL] = useTranslation([
    'homepage.readinessScore.actionsWidget.welcomeTour.title',
    'homepage.readinessScore.actionsWidget.welcomeTour.subTitle',
    'homepage.readinessScore.actionsWidget.welcomeTour.description',
    'homepage.readinessScore.tile.action',
    'homepage.readinessScore.tile.actionCompleted',
    'homepage.readinessScore.tile.badgeText',
  ]);

  const widgetCardData: Omit<WidgetCardProps, 'actionUrl'> = {
    icon: 'puzzle',
    iconSize: 'lg',
    variant: isCompleted ? 'primary' : 'secondary',
    title: titleLL,
    subTitle: subTitleLL,
    description: descriptionLL,
    actionText: isCompleted ? actionCompletedLL : actionLL,
    onActionClick: isCompleted ? () => {} : onActionClick,
    disabled: disabled,
    ['data-testid']: dataTestId,
    badges: isCompleted ? null : (
      <V2Badge
        text={badgeTextLL}
        variant="accent"
        size="sm"
        style={{ backgroundImage: gradients['magenta-gradient'], color: 'white' }}
      />
    ),
  };

  return (
    <V2WidgetCard badges={widgetCardData.badges}>
      <WidgetCardContent {...widgetCardData} />
    </V2WidgetCard>
  );
};

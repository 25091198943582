import styled from 'styled-components';

const ChildrenWrapper = styled.span``;

const Base = styled.button<{ size: 'large' | 'small'; full?: boolean; isResponsive?: boolean }>`
  @media (max-width: ${({ theme }) => theme.v2.breakpoints.sm}) {
    width: ${({ isResponsive }) => (isResponsive ? '100%' : 'auto')};
  }

  box-sizing: border-box;
  outline: none;
  border: none;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: ${({ full }) => (full ? '100%' : 'auto')};
  min-width: 0;
  height: ${({ size }) => (size === 'small' ? '36px' : '46px')};
  padding: ${({ size }) => (size === 'small' ? '8px 16px' : '12px 25.5px')};
  border-radius: 5px;
  flex-shrink: 0;
  font-size: ${({ size, theme }) => (size === 'small' ? theme.v2.typography.sizes.sm : theme.v2.typography.sizes.md)};
  text-align: center;
  font-family: 'Montserrat';
  font-weight: ${({ theme }) => theme.v2.typography.weights.regular};
  line-height: ${({ theme }) => theme.v2.typography.lineHeights.button};

  transition: all 150ms ease;

  &:hover {
    cursor: pointer;
    filter: brightness(1.24);
  }

  &:active {
    filter: brightness(1.32);
  }

  &:disabled {
    cursor: auto;
    filter: brightness(1);
  }
`;

const Primary = styled(Base)`
  background-color: ${({ theme }) => theme.v2.colors.blue[300]};
  color: ${({ theme }) => theme.v2.colors.neutral[100]};
  fill: ${({ theme }) => theme.v2.colors.neutral[100]};
  font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};

  &:hover {
    box-shadow: ${({ theme }) => `${theme.v2.shadows.button}`};
  }

  &:disabled {
    box-shadow: none;
    background-color: ${({ theme }) => theme.v2.colors.blue[200]};
  }
`;

const Outlined = styled(Base)`
  background: transparent;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.v2.colors.blue[300]};
  color: ${({ theme }) => theme.v2.colors.blue[300]};
  fill: ${({ theme }) => theme.v2.colors.blue[300]};
  font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};

  &:disabled {
    border: ${({ theme }) => `solid 1px ${theme.v2.colors.blue[200]}`};
    color: ${({ theme }) => theme.v2.colors.blue[200]};
    fill: ${({ theme }) => theme.v2.colors.blue[200]};
  }
`;

const Secondary = styled(Base)`
  background-color: ${({ theme }) => theme.v2.colors.blue[50]};
  color: ${({ theme }) => theme.v2.colors.blue[300]};

  font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};

  &:hover {
    box-shadow: ${({ theme }) => `${theme.v2.shadows.button}`};
  }

  &:disabled {
    box-shadow: none;
    background-color: ${({ theme }) => theme.v2.colors.neutral[100]};
  }
`;

const OutlinedSuccess = styled(Outlined)`
  border-color: ${({ theme }) => theme.v2.colors.green[500]};
  color: ${({ theme }) => theme.v2.colors.green[500]};
  fill: ${({ theme }) => theme.v2.colors.green[500]};

  &:disabled {
    border-color: ${({ theme }) => theme.v2.colors.green[600]};
    color: ${({ theme }) => theme.v2.colors.green[600]};
    fill: ${({ theme }) => theme.v2.colors.green[600]};
  }
`;

const Selected = styled(Base)`
  color: ${({ theme }) => theme.v2.colors.neutral[50]};
  fill: ${({ theme }) => theme.v2.colors.neutral[50]};
  background: ${({ theme }) => theme.v2.gradients['blue-gradient']};
  font-weight: ${({ theme }) => theme.v2.typography.weights.medium};

  &:hover {
    box-shadow: ${({ theme }) => `${theme.v2.shadows.button}`};
  }

  &:disabled {
    box-shadow: none;
    filter: brightness(1.32) grayscale(0.48);
  }
`;

const Link = styled(Base)`
  color: ${({ theme }) => theme.v2.colors.blue[300]};
  background: transparent;
  font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
  padding: 0;
  height: auto;

  ${ChildrenWrapper} {
    text-decoration: underline;
  }

  &:hover,
  &:active,
  &:focus {
    ${ChildrenWrapper} {
      text-decoration: none;
    }
  }

  &:disabled {
    color: ${({ theme }) => theme.v2.colors.blue[200]};
  }
`;

const LinkThin = styled(Link)`
  font-weight: ${({ theme }) => theme.v2.typography.weights.medium};
`;

const Text = styled(Outlined)`
  border: none;
`;

export default { ChildrenWrapper, Primary, Outlined, OutlinedSuccess, Selected, Link, LinkThin, Secondary, Text };

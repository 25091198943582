import CloseIcon from '@assets/icons/toast/toast-close-icon.svg?react';
import ErrorIcon from '@assets/icons/toast/toast-error-icon.svg?react';
import InfoIcon from '@assets/icons/toast/toast-info-icon.svg?react';
import SuccessIcon from '@assets/icons/toast/toast-success-icon.svg?react';
import WarnIcon from '@assets/icons/toast/toast-warn-icon.svg?react';
import { message as MessageBase } from 'antd';
import type { ReactNode } from 'react';
import S from './message.styles';

interface IMessageProps {
  content: ReactNode;
  duration?: number;
}

export const formatMessage = (sentence: string) => sentence.replace('BusinessRuleValidationError:', '');

const addCloseToContent = (content: ReactNode) => {
  const message = typeof content === 'string' ? formatMessage(content) : content;

  return (
    <S.Content>
      {message}
      <CloseIcon />
    </S.Content>
  );
};

const success = (config: IMessageProps) =>
  MessageBase.success({
    ...config,
    content: addCloseToContent(config.content),
    icon: <SuccessIcon />,
    key: 'successKey',
    onClick: () => MessageBase.destroy('successKey'),
  });
const error = (config: IMessageProps) =>
  MessageBase.error({
    ...config,
    content: addCloseToContent(config.content),
    icon: <ErrorIcon />,
    key: 'errorKey',
    onClick: () => MessageBase.destroy('errorKey'),
  });
const info = (config: IMessageProps) =>
  MessageBase.info({
    ...config,
    content: addCloseToContent(config.content),
    icon: <WarnIcon />,
    key: 'infoKey',
    onClick: () => MessageBase.destroy('infoKey'),
  });
const warning = (config: IMessageProps) =>
  MessageBase.warning({
    ...config,
    content: addCloseToContent(config.content),
    icon: <InfoIcon />,
    key: 'warningKey',
    onClick: () => MessageBase.destroy('warningKey'),
  });

export default {
  success,
  error,
  info,
  warning,
};

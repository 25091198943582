import styled from 'styled-components';

const ProgressList = styled.ul``;

const ProgressItem = styled.li<{ color: 'red' | 'yellow' | 'green' }>`
  font-size: 13px;
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.small};
  margin-right: ${({ theme }) => theme.spacing.small};
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: '';
    display: inline-block;
    background-color: ${({ color, theme }) => theme.onboardingColors[`${color}Tick`]};
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: relative;
    top: 1px;
    margin-right: ${({ theme }) => theme.spacing.small};
  }
`;

export default {
  ProgressList,
  ProgressItem,
};

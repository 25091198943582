import { V2WidgetCard } from '@/components/ui/v2-widget-card/v2-widget-card';
import type { WidgetCardProps } from '@/modules/founder/homepage/homepage';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { WidgetCardContent } from '../widget-card-content';

type ActionWidgetCardProps = {
  wasCompleted: boolean;
  openModal: () => void;
  ['data-testid']?: string;
};

export const AdvisoryQuickSearchCard = ({
  wasCompleted,
  openModal,
  ['data-testid']: dataTestId = 'advisory-quick-search-card',
}: ActionWidgetCardProps) => {
  const [title, subtitle, description, action] = useTranslation([
    'homepage.readinessScore.actionsWidget.advisoryQuickSearch.title',
    'homepage.readinessScore.actionsWidget.advisoryQuickSearch.subtitle',
    'homepage.readinessScore.actionsWidget.advisoryQuickSearch.description',
    'homepage.readinessScore.actionsWidget.advisoryQuickSearch.action',
  ]);

  const widgetCardData: Omit<WidgetCardProps, 'actionUrl' | 'secondarySubTitle'> = {
    title: title,
    subTitle: wasCompleted ? '' : subtitle,
    description: description,
    actionText: action,
    variant: 'primary',
    icon: 'people',
    onActionClick: () => openModal(),
    ['data-testid']: dataTestId,
  };

  return (
    <V2WidgetCard>
      <WidgetCardContent {...widgetCardData} />
    </V2WidgetCard>
  );
};

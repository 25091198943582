import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  h4 {
    color: ${({ theme }) => theme.v2.colors.neutral[50]};
  }
`;

const DescriptionSubtitle = styled.span`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.v2.typography.weights.medium};
`;

const DescriptionList = styled.ul`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  margin: 40px 0;
`;

const ButtonWrapper = styled.div`
  width: 150px;
  margin-bottom: 25px;
`;

export default { Wrapper, DescriptionSubtitle, DescriptionList, ButtonWrapper };

import type { FollowRequestDetails } from '@pages/content/lens/api/get-investor-browse-companies';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import { mapQueryStringToGraphqlParams } from '@utils/fns/map-query-string-to-graphql-params';
import type { ParsedQuery } from 'query-string';
import type { GraphqlResponse } from 'src/types';

export interface SmartMatchSuggestionsItemsForInvestor {
  id: string;
  level: string;
  customerGroup: string[];
  description: string;
  industry: string;
  investmentStage: string;
  joinedAt: string;
  name: string;
  productStage: string;
  logoUrl: string;
  isNew: boolean;
  followRequestId: FollowRequestDetails | null;
}

export interface GetInvestorSmartMatchSuggestions {
  items: SmartMatchSuggestionsItemsForInvestor[];
  total: number;
}

const FILTER_SMART_MATCH_SORT_OPTIONS_GRAPHQL_TYPE = 'FilterCompaniesSortOptions!';

export const GET_INVESTOR_SMART_MATCH_SUGGESTION_CACHE_KEY = 'get-investor-smart-match-suggestions';
export const getInvestorSmartMatchSuggestion =
  (start: number, limit: number, excludedParams: string[], otherParams?: ParsedQuery<string>) =>
  async (): Promise<GraphqlResponse<GetInvestorSmartMatchSuggestions>> => {
    const acceptedParams = [
      'page',
      'sort',
      'regions',
      'countriesOfResidence',
      'hasEisLimitsRemaining',
      'hasSeisLimitsRemaining',
      'metrics',
      'onlyNewlyRegistered',
    ];

    const filteredParams = Object.keys(otherParams ?? {}).reduce<ParsedQuery<string>>((acc, key) => {
      if (acceptedParams.includes(key) && otherParams) {
        acc[key] = otherParams[key];
      }
      return acc;
    }, {});

    const params =
      filteredParams &&
      mapQueryStringToGraphqlParams(
        filteredParams,
        excludedParams,
        false,
        FILTER_SMART_MATCH_SORT_OPTIONS_GRAPHQL_TYPE,
      );

    const queryName = `smartMatchSuggestionForInvestor(start: ${start}, limit: ${limit}${params?.supplement})`;

    const { data } = await axiosInstance.post('/query', {
      variables: params?.variables,
      query: `
      query getInvestorSmartMatchSuggestion${params?.arguments} {
        ${queryName} {
            items {
                id
                level
                customerGroup
                description
                industry
                investmentStage
                joinedAt
                name
                productStage
                logoUrl
                isNew
                followRequest { 
                    id
                    type
                }
            }
          total
      }
    }`,
    });

    return graphqlResponseWrapper(data, ['smartMatchSuggestionForInvestor']);
  };

import styled from 'styled-components';

const ListDescription = styled.div`
  max-width: 700px;
  margin: 0 auto ${({ theme }) => theme.spacing.xlarge};
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontSize.xmedium};
  white-space: pre-line;

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    margin-top: -${({ theme }) => theme.spacing.xmedium};
  }

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.highResDesktop}) {
    margin-top: 0;
  }
`;

const List = styled.ul`
  margin: 0 auto;
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: ${({ theme }) => theme.spacing.large};
  width: 100%;
  max-width: 1000px;

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${({ theme }) => theme.spacing.base};
  }

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

const ListElementArrow = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const ListElement = styled.li`
  position: relative;

  ${ListElementArrow} {
    position: absolute;

    top: 100%;
    left: 50%;
    transform: translate(-50%, 50%) rotate(90deg);

    @media (min-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
      top: 25px;
      left: 100%;
      margin-bottom: ${({ theme }) => theme.spacing.medium};
      transform: translate(50%, 0%) rotate(0deg);
    }
  }

  &:last-of-type ${ListElementArrow} {
    display: none;
  }
`;

const FeatureItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: break-spaces;

  svg {
    width: 70px;
    height: 70px;
  }
`;

const FeatureItemTitle = styled.p`
  margin-top: ${({ theme }) => theme.spacing.xbase};
  text-align: center;
  color: ${({ theme }) => theme.baseColors.White};
`;

export default {
  ListDescription,
  List,
  ListElement,
  FeatureItem,
  FeatureItemTitle,
  FeatureItemArrow: ListElementArrow,
};

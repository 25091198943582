import { InlineError } from '@parts/forms/error/inline-error/inline-error';
import { StyledLabel } from '@parts/forms/label/label';
import type { FontWeight } from '@styles/theme-config';
import { useMemo, type CSSProperties, type ReactNode } from 'react';
import styled, { css } from 'styled-components';

export interface FormikFieldProps {
  children?: ReactNode;
  required?: boolean;
  label?: {
    for: string;
    label: string | JSX.Element;
  };
  labelAlignedToCenter?: boolean;
  touched?: boolean;
  error?: string | string[];
  style?: CSSProperties;
  className?: string;
  datatestid?: string;
  icon?: JSX.Element;
  suffix?: ReactNode;
  postfix?: ReactNode;
  cell?: boolean;
  hideError?: boolean;
  fontWeight?: FontWeight;
  registerMarginBottom?: boolean;
  marginBottom?: boolean;
}

interface StyledDivProps {
  hasError?: boolean;
  cell?: boolean;
  registerMarginBottom?: boolean;
}

const FORMIK_INPUT_HEIGHT = 50;

const StyledDiv = styled.div<StyledDivProps>`
  margin-bottom: ${({ registerMarginBottom, theme }) => `${registerMarginBottom ? '14px' : theme.spacing.base} `};
  display: flex;
  flex-direction: column;

  ${({ cell }) =>
    cell &&
    css`
      margin: 0;
    `};

  & > div {
    width: 100%;
  }

  > input[type='checkbox'] {
    width: 20px;
    height: 20px;
    display: block;
  }

  .ant-input-password-icon {
    color: ${({ theme }) => theme.color.fontPrimary};
  }

  .ant-input {
    ${({ hasError, theme }) => hasError && `border: 1px solid ${theme.baseColors.Red}!important`};
  }

  .ant-input-password {
    ${({ hasError, theme }) => hasError && `border: 1px solid ${theme.baseColors.Red}!important`};

    .ant-input {
      border: none !important;
    }
  }

  .antd-country-phone-input {
    ${({ hasError, theme }) => hasError && `border: 1px solid ${theme.baseColors.Red}`};

    .ant-input {
      border: none !important;
    }
  }

  .ant-checkbox-wrapper {
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
  }

  .ant-picker {
    width: 100%;
    height: ${() => `${FORMIK_INPUT_HEIGHT}px`};
  }

  .ant-input-number {
    display: block;
    height: 45px;
    width: 100%;

    .ant-input-number-input-wrap {
      height: ${() => `${FORMIK_INPUT_HEIGHT}px`};

      > input {
        height: ${() => `${FORMIK_INPUT_HEIGHT}px`};
      }
    }
  }

  .ant-checkbox-wrapper {
    margin-left: ${({ theme }) => theme.spacing.small};
  }

  .ant-select {
    min-height: ${() => `${FORMIK_INPUT_HEIGHT}px`};

    .ant-select-selector {
      min-height: ${() => `${FORMIK_INPUT_HEIGHT}px`};
    }
  }
`;

const Suffix = styled.div`
  flex: 100%;
  display: flex;
  justify-content: flex-end;
`;

const Postfix = styled.div`
  flex: 100%;
  display: flex;
`;

export const FormikField = ({
  children,
  label,
  error,
  touched,
  style,
  className,
  icon,
  datatestid,
  suffix,
  postfix,
  hideError,
  required,
  cell,
  labelAlignedToCenter,
  fontWeight,
  registerMarginBottom,
  marginBottom,
  ...restProps
}: FormikFieldProps) => {
  const shouldDisplayError = useMemo<boolean>(
    () => Boolean(touched) && Boolean(error) && !hideError,
    [hideError, touched, error],
  );

  return (
    <StyledDiv
      registerMarginBottom={registerMarginBottom}
      className={className}
      hasError={Boolean(touched && error)}
      style={style}
      cell={cell}
      data-testid={datatestid}
      {...restProps}
    >
      {label && !cell && (
        <StyledLabel
          fontWeight={fontWeight}
          required={required}
          labelAlignedToCenter={labelAlignedToCenter}
          htmlFor={label.for}
          marginBottom={marginBottom}
        >
          {label.label}
          {icon && icon}
        </StyledLabel>
      )}
      {children && <div>{children}</div>}
      {suffix && <Suffix>{suffix}</Suffix>}
      {shouldDisplayError && <InlineError>{error}</InlineError>}
      {postfix && <Postfix>{postfix}</Postfix>}
    </StyledDiv>
  );
};

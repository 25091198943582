import { Roles } from './roles';
import { SubscriptionProductStatus, SubscriptionProductType, type SubscriptionProduct } from './subscription-products';

export const getRoleFromProducts = (products: SubscriptionProduct[]) => {
  if (
    products.some(
      (prod) => prod.type === SubscriptionProductType.INVESTOR && prod.status === SubscriptionProductStatus.APPROVED,
    ) &&
    products.some(
      (prod) => prod.type === SubscriptionProductType.NED && prod.status === SubscriptionProductStatus.APPROVED,
    )
  ) {
    return Roles.INVESTOR_NED;
  }
  if (
    products.every((prod) => prod.type !== SubscriptionProductType.NED) &&
    products.some((prod) => prod.type === SubscriptionProductType.INVESTOR)
  ) {
    return Roles.INVESTOR;
  }

  if (products.some((prod) => prod.type === SubscriptionProductType.NED)) {
    return Roles.NED;
  }

  if (products.some((prod) => prod.type === SubscriptionProductType.FOUNDER)) {
    return Roles.FOUNDER;
  }

  return Roles.NO_ROLE;
};

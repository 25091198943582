import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_INVESTOR_BROWSE_DEFAULTS_CACHE_KEY = 'get-investor-browse-defaults';

export const getInvestorBrowseDefaults = async (): Promise<
  GraphqlResponse<{
    locations: string[];
    skillSets: string[];
    industries: string[];
    investmentStages: string[];
    customerGroups: string[];
    productStages: string[];
  }>
> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getInvestorBrowseDefaults {
        locations,
        skillSets,
        productStages,
        investmentStages,
        customerGroups,
        industries,
      }
  `,
  });
  return graphqlResponseWrapper(data, [
    'locations',
    'skillSets',
    'productStages',
    'investmentStages',
    'customerGroups',
    'industries',
  ]);
};

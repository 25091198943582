import type { MarketFitQuestionItem } from '@/pages/content/profile/founder/api/get-market-fit-questions/get-market-fit-questions.query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { Any } from 'src/types';
import * as Yup from 'yup';

export const useValidationSchema = (list: MarketFitQuestionItem[]) => {
  const [
    requiredValidationMessage,
    maxValidationMessageStart,
    maxValidationMessageEnd,
    minValidationMessageStart,
    minValidationMessageEnd,
  ] = useTranslation([
    'founder-onboarding.profile.marketFit.question.validation.required',
    'founder-onboarding.profile.marketFit.question.validation.length.max.start',
    'founder-onboarding.profile.marketFit.question.validation.length.max.end',
    'founder-onboarding.profile.marketFit.question.validation.length.min.start',
    'founder-onboarding.profile.marketFit.question.validation.length.min.end',
  ]);

  const schema: Record<string, Any> = {};

  for (const q of list) {
    const boolKey = `${q.questionCode}-bool`;
    const textKey = `${q.questionCode}-text`;

    schema[boolKey] = Yup.boolean().nullable();
    schema[textKey] = Yup.string()
      .nullable()
      .when(boolKey, {
        is: (val: boolean | undefined) => val === true,
        then: Yup.string()
          .required(requiredValidationMessage)
          .min(q.lengthLimits.min, `${minValidationMessageStart} ${q.lengthLimits.min} ${minValidationMessageEnd}`)
          .max(q.lengthLimits.max, `${maxValidationMessageStart} ${q.lengthLimits.max} ${maxValidationMessageEnd}`)
          .nullable(),
      });
  }

  return Yup.object(schema);
};

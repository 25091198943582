import { Button } from 'antd';
import styled, { css } from 'styled-components';

const antdTransition = 'all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)';

const defaultConnectBtnStyles = css`
  background-color: ${({ theme }) => theme.color.submitButton};
  border-color: ${({ theme }) => theme.color.submitButton};
  color: ${({ theme }) => theme.baseColors.White};
`;

const defaultSaveBtnStyles = css`
  background: transparent;
  border-color: ${({ theme }) => theme.color.submitButton};
  color: ${({ theme }) => theme.color.submitButton};

  &:disabled {
    background: transparent;
    color: ${({ theme }) => theme.color.submitButton};
    border-color: ${({ theme }) => theme.color.submitButton};
  }
`;

const defaultSvgHoverStyles = css`
  & svg {
    path {
      fill: ${({ theme }) => theme.baseColors.Blue150};
      transition: ${antdTransition};
    }
  }
`;

const savedBtnStyles = css`
  background: transparent;
  color: ${({ theme }) => theme.v2.colors.green[500]};
  border-color: ${({ theme }) => theme.v2.colors.green[500]};

  & svg {
    path {
      fill: ${({ theme }) => theme.v2.colors.green[500]};
      transition: ${antdTransition};
    }
  }

  &:hover {
    background: transparent;
    color: ${({ theme }) => theme.v2.colors.green[500]};
    border-color: ${({ theme }) => theme.v2.colors.green[500]};

    & svg {
      path {
        fill: ${({ theme }) => theme.v2.colors.green[500]};
        transition: ${antdTransition};
      }
    }
  }

  &:disabled {
    background: transparent;
    color: ${({ theme }) => theme.v2.colors.green[500]} !important;
    border-color: ${({ theme }) => theme.v2.colors.green[500]} !important;

    & svg {
      path {
        fill: ${({ theme }) => theme.v2.colors.green[500]} !important;
        transition: ${antdTransition};
      }
    }
  }
`;

const commonButtonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  padding: ${({ theme }) => theme.spacing.xmedium};
  line-height: 1;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ConnectBtn = styled(Button)`
  ${commonButtonStyles}
  ${defaultConnectBtnStyles}

  &:hover,
  &:disabled {
    ${defaultConnectBtnStyles}
    opacity: 0.5;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  padding-bottom: ${({ theme }) => theme.spacing.base};
  gap: 24px;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
  }
`;

const SaveBtn = styled(Button)<{ $isSaved?: boolean }>`
  ${commonButtonStyles}
  background-color: transparent;
  color: ${({ theme }) => theme.color.submitButton};
  border-color: ${({ theme }) => theme.color.submitButton};

  &:active,
  &:focus {
    background-color: inherit;
  }

  &:hover {
    background-color: transparent;
    transition: ${antdTransition};
    ${defaultSvgHoverStyles}
  }

  & svg {
    margin-right: ${({ theme }) => theme.spacing.small};
    transition: ${antdTransition};

    path {
      fill: ${({ theme }) => theme.color.submitButton};
    }
  }

  &:disabled {
    ${defaultSaveBtnStyles}
    opacity: 0.5;
  }

  ${({ $isSaved }) => $isSaved && savedBtnStyles}
`;

const MessageBtn = styled(Button)`
  ${commonButtonStyles}
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.baseColors.Blue200};
  color: ${({ theme }) => theme.baseColors.Blue200};

  &:hover {
    background-color: transparent;
    border-color: ${({ theme }) => theme.baseColors.Blue150};
    ${defaultSvgHoverStyles}
  }

  & svg {
    margin-right: ${({ theme }) => theme.spacing.small};
    transition: ${antdTransition};
  }
`;

export default { HeaderWrapper, ConnectBtn, ButtonsWrapper, SaveBtn, MessageBtn };

import S from './mobile-tile.styles';

export const MobileTile = ({
  title,
  onClick,
  className,
}: {
  title: string;
  onClick: () => void;
  className?: string;
}) => (
  <S.Wrapper onClick={onClick} className={className} data-testid="onboarding-mobile-tile">
    <S.Title>{title}</S.Title>
  </S.Wrapper>
);

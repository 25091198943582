import FiltersBase from '@assets/icons/filters.svg?react';
import { SubmitButton as SubmitButtonBase } from '@parts/submit-button/submit-button';
import { Button as ButtonBase, Dropdown as DropdownBase } from 'antd';
import styled, { css } from 'styled-components';

const Overlay = styled.div`
  padding: 0;
  width: ${({ theme }) => theme.ui.columnFiltersWidth};
  background: ${({ theme }) => theme.color.inputBackground};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

const Footer = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.border};
  padding: ${({ theme }) => theme.spacing.medium};
  display: flex;
  flex-wrap: nowrap;
`;

const buttonStyles = css`
  height: 35px !important;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-grow: 0;
`;

const Dropdown = styled(DropdownBase)<{ isActive: boolean }>`
  ${({ isActive }) =>
    isActive &&
    css`
      background: ${({ theme }) => `${theme.baseColors.Green}1A`};
    `}
  display: flex;
  padding: ${({ theme }) => theme.spacing.xxsmall};
  align-items: center;
`;

const Cancel = styled(ButtonBase)`
  ${buttonStyles};
  color: ${({ theme }) => theme.color.primary};
  margin-left: ${({ theme }) => theme.spacing.small};
`;

const Save = styled(SubmitButtonBase)`
  ${buttonStyles};
`;

const Reset = styled(ButtonBase)`
  ${buttonStyles};
`;

const Filters = styled(FiltersBase)`
  cursor: pointer;
  margin-left: ${({ theme }) => theme.spacing.xsmall};
`;

export default { Overlay, Footer, Cancel, Save, Reset, Filters, Dropdown };

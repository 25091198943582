import { Roles } from '@domain/accounts/roles';
import { Links } from '@router/links';
import { GlobalStyles } from '@styles/global.styles';
import { ResetStyles } from '@styles/reset.styles';
import { darkThemeColors, lightThemeColors, theme as themeBase } from '@styles/theme-config';
import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { AppThemeContext, type ColorThemeMode } from './app-theme.context';

export const USER_THEME_LS_KEY = 'user-theme';
// in the future we can add userId to the key to support multiple user themes
export const getLsThemeKey = () => USER_THEME_LS_KEY;

const getValidTheme = (themeMode: string): ColorThemeMode =>
  (['dark', 'light'] as const).includes(themeMode as ColorThemeMode) ? (themeMode as ColorThemeMode) : 'light';

export const getThemeVariables = (mode?: ColorThemeMode) => {
  const color = mode === 'light' ? lightThemeColors : darkThemeColors;
  return { ...themeBase, color, mode };
};

export const AppThemeProvider: React.FC = ({ children }) => {
  const { pathname } = useLocation();

  const lsThemeModeValue = window.localStorage.getItem(getLsThemeKey());
  const [mode, setMode] = useState<ColorThemeMode>(() => getValidTheme(lsThemeModeValue as ColorThemeMode));

  const themeConfiguration = getThemeVariables(mode);

  return (
    <StyledThemeProvider theme={{ ...themeConfiguration, v2: { ...themeConfiguration.v2, mode: getValidTheme(mode) } }}>
      <AppThemeContext.Provider
        value={useMemo(
          () => ({
            mode,
            setMode: (themeMode: ColorThemeMode) => setMode(getValidTheme(themeMode)),
          }),
          [mode],
        )}
      >
        <ResetStyles />
        <GlobalStyles adjustToRegister={pathname === Links.REGISTER_ROLE(Roles.FOUNDER)} />
        {children}
      </AppThemeContext.Provider>
    </StyledThemeProvider>
  );
};

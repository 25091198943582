import { V2Container } from '@/components/ui/v2-container/v2-container';
import { V2Loader } from '@/components/ui/v2-loader/v2-loader';
import { SubscriptionProductType, getHighestSubscriptionProduct } from '@/domain/accounts/subscription-products';
import useUserAccount from '@/utils/hooks/use-user-account/use-user-account';
import { ComingSoonCards, T2PComingSoonCards } from './components/coming-soon/coming-soon';
import { AdvisorCuratedFeed } from './components/curated-feed/curated-feed';
import { PublishProfileCard } from './components/publish-profile-card';
import { WelcomeTilesSectionWrapper } from './components/welcome-tiles-section-wrapper';
import { useGetAdvisorProfileReadiness } from './hooks/use-get-advisor-profile-readiness';
import { useGetAdvisorPublishStatus } from './hooks/use-get-advisor-publish-status';
import { calculateTotalPercentage } from './utils/calculate-total-percentage';

import S from './homepage.styles';

export const Homepage = () => {
  const {
    state: { name, products },
  } = useUserAccount();

  const { advisorProfileReadinessData, isAdvisorProfileReadinessLoading } = useGetAdvisorProfileReadiness();
  const {
    advisorPublishStatusData: advisorProfileDataData,
    isAdvisorPublishStatusLoading: isAdvisorProfileDataLoading,
  } = useGetAdvisorPublishStatus();

  const isT2PAdvisor =
    getHighestSubscriptionProduct(products).type === SubscriptionProductType.NED_TRANSITION_TO_PORTFOLIO;

  if (isAdvisorProfileReadinessLoading || isAdvisorProfileDataLoading) return <V2Loader />;

  const status = advisorProfileDataData?.published ? 'published' : 'unpublished';
  const progress = advisorProfileReadinessData?.data ? calculateTotalPercentage(advisorProfileReadinessData.data) : 0;

  const canUserPublish = !advisorProfileDataData?.smartMatchShortages.length && !isAdvisorProfileDataLoading;

  return (
    <>
      <V2Container>
        <S.PageTitle data-testid="advisor-homepage-title" title={`Welcome ${name}!`} size="2xl" />
      </V2Container>

      <WelcomeTilesSectionWrapper>
        <PublishProfileCard
          data-testid="advisor-homepage-tile-build-your-profile"
          progress={progress}
          status={status}
          canUserPublish={canUserPublish}
        />
        {isT2PAdvisor ? <T2PComingSoonCards /> : <ComingSoonCards />}
      </WelcomeTilesSectionWrapper>

      <AdvisorCuratedFeed />
    </>
  );
};

import { axiosInstance } from '@utils/axios/axios-instance';

export const removeConnection = (connectionId: string) => async () => {
  try {
    const results = await Promise.any([
      axiosInstance.post(`/connections/ned/connection/${connectionId}/remove`),
      axiosInstance.post(`/connections/investor/connection/${connectionId}/remove`),
    ]);
    return results;
  } catch (error) {
    console.error('Error removing connection:', error);
    throw error;
  }
};

import { DateFormat } from '@i18n/date-formats';
import { TableColumn } from '@parts/table-column/table-column';
import { currencyToShortFormat } from '@utils/fns/currency-to-short-format';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { Tooltip } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import type { FundingRound } from './funding-rounds';
import S from './funding-rounds.styles';

export const Columns = ({
  translations: [
    dateLabel,
    typeLabel,
    amountSoughtLabel,
    amountRaisedLabel,
    valuationLabel,
    statusLabel,
    useOfFundsLabel,
    removalDisabled,
  ],
  compact,
  onEditRow,
  onDeleteRow,
  currencySymbol,
  readOnly = false,
  showComplete = false,
}: {
  translations: string[];
  onEditRow: (row: FundingRound) => () => void;
  onDeleteRow: (row: FundingRound) => () => void;
  compact: boolean;
  currencySymbol: string;
  readOnly?: boolean;
  showComplete?: boolean;
}): ColumnsType<FundingRound> => {
  const { dateFormatter } = useUserDateFormat();

  return [
    {
      title: dateLabel,
      dataIndex: 'date',
      key: 'date',

      width: '15%',
      render: (date) => (
        <TableColumn title={dateLabel}>{dateFormatter(date, DateFormat.SHORT_MONTH_FULL_DATE)}</TableColumn>
      ),
    },
    {
      title: typeLabel,
      dataIndex: 'type',
      key: 'type',
      width: '15%',
      render: (value) => <TableColumn title={typeLabel}>{value}</TableColumn>,
    },
    {
      title: `${amountSoughtLabel} (${currencySymbol})`,
      dataIndex: 'amountSought',
      key: 'amountSought',
      width: '15%',
      render: (value) => <TableColumn title={amountSoughtLabel}>{currencyToShortFormat(value)}</TableColumn>,
    },
    {
      title: `${amountRaisedLabel} (${currencySymbol})`,
      dataIndex: 'amountRaised',
      key: 'amountRaised',
      width: '15%',
      render: (value) => <TableColumn title={amountRaisedLabel}>{currencyToShortFormat(value)}</TableColumn>,
    },
    {
      title: `${valuationLabel} (${currencySymbol})`,
      dataIndex: 'valuation',
      key: 'valuation',
      width: '15%',
      render: (value) => <TableColumn title={valuationLabel}>{currencyToShortFormat(value)}</TableColumn>,
    },
    showComplete
      ? {
          title: '% Complete',
          dataIndex: '',
          key: '',
          width: '10%',
          render: (_, record) => (
            <TableColumn title="% Complete">
              {(() => {
                if (record.amountSought === 0) {
                  return ((record.amountRaised / 1) * 100).toFixed(1);
                }
                return ((record.amountRaised / record.amountSought) * 100).toFixed(1);
              })()}
            </TableColumn>
          ),
        }
      : {
          width: 0,
        },
    {
      title: statusLabel,
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: (value) => <TableColumn title={statusLabel}>{value}</TableColumn>,
    },
    {
      title: useOfFundsLabel,
      dataIndex: 'useOfFunds',
      key: 'useOfFunds',
      width: showComplete ? '100px' : '200px',
      render: (value: string) =>
        compact ? (
          <TableColumn title={useOfFundsLabel}>{value}</TableColumn>
        ) : (
          <TableColumn>
            <Tooltip title={value}>
              <S.UseOfFundsContainer $smallerSize={Boolean(showComplete)}>{value}</S.UseOfFundsContainer>
            </Tooltip>
          </TableColumn>
        ),
    },
    {
      title: '',
      dataIndex: '',
      key: 'editRow',
      width: '5%',
      render: (_, row, index) =>
        !readOnly && (
          <S.Controls key={`edit-row-${index}`}>
            <S.ButtonIcon type="link" onClick={onEditRow(row)} data-testid="edit-row">
              <S.EditRowIcon />
            </S.ButtonIcon>
            {row.isUsedInInvestment ? (
              <Tooltip title={removalDisabled}>
                <S.ButtonIcon type="link" onClick={onDeleteRow(row)} data-testid="delete-row" disabled>
                  <S.RemoveRowIcon
                    style={{
                      width: '25px',
                      height: '19px',
                    }}
                  />
                </S.ButtonIcon>
              </Tooltip>
            ) : (
              <S.ButtonIcon type="link" onClick={onDeleteRow(row)} data-testid="delete-row">
                <S.RemoveRowIcon
                  style={{
                    width: '25px',
                    height: '19px',
                  }}
                />
              </S.ButtonIcon>
            )}
          </S.Controls>
        ),
    },
  ];
};

import type { V2IconName } from '@/components/ui/v2-icon/icons';
import { V2InfoPanel } from '@/components/ui/v2-info-panel/v2-info-panel';
import { V2Link } from '@/components/ui/v2-link/v2-link';
import { V2ProgressBar } from '@/components/ui/v2-progress-bar/v2-progress-bar';
import { V2WithAsterisk } from '@/components/ui/v2-with-asterisk/v2-with-asterisk';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import type { ReactNode } from 'react';

import S from './content.style';

export type ContentStepBase = {
  id: string;
  title: ReactNode;
  description: ReactNode;
  received: number;
  available: number;
  icon: V2IconName;
  required?: boolean;
  actionUrl?: string;
  customActionElement?: ReactNode;
};

type ContentStepWithActionUrl = ContentStepBase & { actionUrl: string; customActionElement?: never };
type ContentStepWithCustomActionElement = ContentStepBase & {
  customActionElement: ReactNode;
  actionUrl?: never;
};

export type ContentStep = ContentStepWithActionUrl | ContentStepWithCustomActionElement;

export const Content = ({
  introTitle,
  introDescription,
  steps,
}: {
  introTitle: ReactNode;
  introDescription: ReactNode;
  steps: ContentStep[];
}) => {
  const [todoListTitleLL, completedListTitleLL, requiredInfoLL, editLL] = useTranslation([
    'homepage.buildYourProfileModal.list.todo',
    'homepage.buildYourProfileModal.list.completed',
    'homepage.buildYourProfileModal.list.requiredInfo',
    'homepage.buildYourProfileModal.steps.item.edit',
  ]);

  const totalReceived = steps.reduce((acc, step) => acc + step.received, 0);
  const totalAvailable = steps.reduce((acc, step) => acc + step.available, 0);
  const [todoSteps, completedSteps] = steps.reduce<[ContentStep[], ContentStep[]]>(
    (acc, step) => {
      if (step.received < step.available) acc[0].push(step);
      else acc[1].push(step);
      return acc;
    },
    [[], []],
  );

  return (
    <>
      <S.Header>
        <S.Description>
          <S.DescriptionTitle>{introTitle}</S.DescriptionTitle>
          <S.DescriptionInfo>{introDescription}</S.DescriptionInfo>
          <S.DescriptionProgress>
            <V2ProgressBar percent={(totalReceived / totalAvailable) * 100} variant="blue-red" />
          </S.DescriptionProgress>
        </S.Description>
      </S.Header>

      <S.Content>
        {todoSteps.length > 0 ? (
          <>
            <S.ContentTitle $variant="primary" data-testid="todo-list-title">
              {todoListTitleLL}
            </S.ContentTitle>
            <ul>
              {todoSteps.map((step) => (
                <li key={step.id}>
                  <V2InfoPanel
                    title={step.title}
                    subtitle={step.description}
                    icon={step.icon}
                    required={step.required}
                    data-testid="todo-step-info-panel"
                    {...(step.actionUrl
                      ? { actionUrl: step.actionUrl }
                      : { customActionElement: step.customActionElement })}
                  />
                </li>
              ))}
            </ul>
          </>
        ) : null}

        {completedSteps.length > 0 ? (
          <>
            <S.ContentTitle $variant="dimmed" data-testid="completed-list-title">
              {completedListTitleLL}
            </S.ContentTitle>
            <ul>
              {completedSteps.map((step) => (
                <li key={step.id}>
                  <V2InfoPanel
                    variant="inactive"
                    required={step.required}
                    title={step.title}
                    subtitle={
                      <span>
                        <span>{step.description}</span>
                        {step.actionUrl ? (
                          <>
                            {' '}
                            <V2Link to={step.actionUrl} variant="primary">
                              {editLL}
                            </V2Link>
                          </>
                        ) : null}
                      </span>
                    }
                    icon={step.icon}
                    customActionElement={step.customActionElement}
                    data-testid="completed-step-info-panel"
                  />
                </li>
              ))}
            </ul>
          </>
        ) : null}
      </S.Content>

      <S.ContentFooter>
        <V2WithAsterisk position="start">{requiredInfoLL}</V2WithAsterisk>
      </S.ContentFooter>
    </>
  );
};

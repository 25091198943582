import { EmptyState as EmptyStateBase } from '@parts/empty-state/empty-state';
import styled from 'styled-components';

const EmptyState = styled(EmptyStateBase)`
  margin: 0px;
  > * {
    color: ${({ theme }) => theme.color.grayedOutText};
  }
`;

export default { EmptyState };

import { FormikField } from '@pages/content/profile/parts/formik-field/formik-field';
import Checkbox from '@parts/checkbox/checkbox';
import { Section } from '@parts/section/section';
import { SubmitButton } from '@parts/submit-button/submit-button';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useUserCurrency } from '@utils/hooks/use-user-currency/use-user-currency';
import { Col, Row } from 'antd';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useFormik } from 'formik';
import type { Any } from 'src/types';
import * as Yup from 'yup';
import type { IInvestorInvestmentPayload } from '../../api/investments/investments';
import { InvestorType, type IInvestorInvestment, type PreviousInvestment } from '../../api/types';
import { useValidationSchema as usePreviousInvestmentDrawerFormValidation } from './parts/add-investment-drawer/validation';
import { PreviousInvestmentsEditor } from './parts/editor/editor';

import S from './investment.style';
import { PillButtonCheckbox } from '@/parts/pill-button-checkbox/pill-button-checkbox';

export const INVESTMENT_RANGE_MIN = 0;
export const INVESTMENT_RANGE_MAX = 2_500_000;
export const INVESTMENT_RANGE_STEP = 20_000;

function isArrayOfRecords(
  item: string | undefined | Record<string, string>[] | string[],
): item is Record<string, string>[] {
  if (Array.isArray(item)) {
    if ((item as Any[]).every((element: Record<string, string> | string) => typeof element === 'object')) {
      return true;
    }
  }

  return false;
}

export interface PreviousInvestmentWithId extends PreviousInvestment {
  id: string;
}

interface IInvestmentProps {
  investment: IInvestorInvestment;
  investorType: InvestorType | null;
  handleSubmit: (formState: IInvestorInvestmentPayload) => void;
}

export const Investment = ({ investment, investorType, handleSubmit }: IInvestmentProps) => {
  const [
    header,
    ticketSizeLabel,
    investmentThesisLabel,
    previousInvestmentsLabel,
    hidePreviousInvestmentsLabel,
    saveChanges,
    maxLengthError,
    safeInt,
    isDeployingCapitalInNextThreeMonthsLabel,
    isDeployingCapitalInNextThreeMonthsFalse,
    isDeployingCapitalInNextThreeMonthsTrue,
  ] = useTranslation([
    'profile.investor.section.investments.header',
    'profile.investor.section.investments.ticketSizeLabel',
    'profile.investor.section.investments.investmentThesis',
    'profile.investor.section.investments.previousInvestments',
    'profile.investor.section.investments.hidePreviousInvestments',
    'profile.investor.section.investments.saveChanges',
    'formik.validation.max',
    'formik.validation.safeInt',
    'profile.investor.section.investments.isDeployingCapitalInNextThreeMonths',
    'profile.investor.section.investments.isDeployingCapitalInNextThreeMonths.false',
    'profile.investor.section.investments.isDeployingCapitalInNextThreeMonths.true',
  ]);

  const formik = useFormik<IInvestorInvestment>({
    initialValues: { ...investment },
    onSubmit: ({ hidePreviousInvestments, ...formValues }) => {
      const valuesToSubmit = {
        ...formValues,
        investmentThesis: formValues.investmentThesis || null,
        ...(investorType === InvestorType.Angel ? { hidePreviousInvestments } : {}),
      };
      handleSubmit(valuesToSubmit);

      formik.resetForm({
        values: {
          ...valuesToSubmit,
          investmentThesis: valuesToSubmit.investmentThesis ?? '',
          hidePreviousInvestments: valuesToSubmit.hidePreviousInvestments || false,
        },
      });
    },
    validateOnMount: true,
    validationSchema: Yup.object().shape({
      minInvestmentAmount: Yup.number().min(0).max(INVESTMENT_RANGE_MAX, `${safeInt} ${INVESTMENT_RANGE_MAX}`),
      maxInvestmentAmount: Yup.number().min(0).max(INVESTMENT_RANGE_MAX, `${safeInt} ${INVESTMENT_RANGE_MAX}`),
      investmentThesis: Yup.string().nullable().max(500, maxLengthError),
      hidePreviousInvestments: Yup.boolean().nullable(),
      previousInvestmentsTable: Yup.array().of(usePreviousInvestmentDrawerFormValidation()).nullable(),
      isDeployingCapitalInNextThreeMonths: Yup.boolean().nullable(),
    }),
  });

  const {
    errors,
    touched,
    getFieldProps,
    setFieldTouched,
    setFieldValue,
    submitForm,
    isValid,
    dirty,
    values,
    submitCount,
  } = formik;

  const ticketSizeError = errors.maxInvestmentAmount || errors.minInvestmentAmount;
  const ticketSizeTouched = touched.maxInvestmentAmount || touched.minInvestmentAmount;

  const previousInvestmentsWithIds: PreviousInvestmentWithId[] = (values.previousInvestmentsTable || []).map(
    (item, index) => ({
      ...item,
      id: index.toString(),
    }),
  );

  const updateInvestments = async (investments: PreviousInvestmentWithId[]) => {
    const investmentsList: PreviousInvestment[] = investments.map((item) => ({
      company: item.company,
      note: item.note,
    }));

    setFieldValue('previousInvestmentsTable', investmentsList);
    setFieldTouched('previousInvestmentsTable');
  };

  const previousInvestmentsTableError = (() => {
    if (isArrayOfRecords(errors.previousInvestmentsTable)) {
      const tableItemsErrorsList = errors.previousInvestmentsTable;
      const firstErrorIndex = tableItemsErrorsList.findIndex((item) => Boolean(item));
      const firstErrorMessage = Object.values(tableItemsErrorsList[firstErrorIndex])[0];

      return `Investment #${firstErrorIndex + 1} - ${firstErrorMessage}`;
    }

    return undefined;
  })();

  const { currencySymbol } = useUserCurrency();
  const isLightTheme = false;

  const options = [
    { value: false, label: isDeployingCapitalInNextThreeMonthsFalse },
    { value: true, label: isDeployingCapitalInNextThreeMonthsTrue },
  ];

  return (
    <Section header={header}>
      <form onSubmit={submitForm} data-testid="investor-investment-form">
        <Col xs={24} sm={24} md={24} lg={16} xl={17}>
          <FormikField
            label={{
              for: 'isDeployingCapitalInNextThreeMonths',
              label: isDeployingCapitalInNextThreeMonthsLabel,
            }}
            touched={touched.maxInvestmentAmount}
            error={errors.maxInvestmentAmount}
          >
            {options.map((option) => (
              <PillButtonCheckbox
                key={option.label}
                isLightTheme={isLightTheme}
                checked={values.isDeployingCapitalInNextThreeMonths === option.value}
                onChange={() => {
                  formik.setValues({
                    ...formik.values,
                    isDeployingCapitalInNextThreeMonths: option.value,
                  });
                }}
              >
                {option.label}
              </PillButtonCheckbox>
            ))}
          </FormikField>

          <Row gutter={[24, 0]}>
            <Col xs={24} sm={12}>
              <FormikField
                label={{ for: '', label: ticketSizeLabel }}
                error={ticketSizeError}
                touched={ticketSizeTouched}
              >
                <S.AmountRangeSlider
                  value={[values.minInvestmentAmount, values.maxInvestmentAmount]}
                  onChange={([min, max]) => {
                    setFieldValue('minInvestmentAmount', min);
                    setFieldValue('maxInvestmentAmount', max);

                    setFieldTouched('minInvestmentAmount');
                    setFieldTouched('maxInvestmentAmount');
                  }}
                  step={INVESTMENT_RANGE_STEP}
                  max={INVESTMENT_RANGE_MAX}
                  min={INVESTMENT_RANGE_MIN}
                  displayValuePrefix={currencySymbol}
                />
              </FormikField>
            </Col>
          </Row>

          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24}>
              <FormikField
                label={{
                  for: 'investmentThesis',
                  label: investmentThesisLabel,
                }}
                error={errors.investmentThesis}
                touched={touched.investmentThesis}
              >
                <S.Textarea id="investmentThesis" rows={6} {...getFieldProps('investmentThesis')} />
              </FormikField>
            </Col>
          </Row>
        </Col>

        {investorType === InvestorType.Angel ? (
          <>
            <FormikField
              label={{ for: '', label: previousInvestmentsLabel }}
              error={previousInvestmentsTableError}
              touched={Boolean(touched.previousInvestmentsTable) || Boolean(previousInvestmentsTableError)}
            >
              <PreviousInvestmentsEditor
                data={previousInvestmentsWithIds}
                updateInvestments={updateInvestments}
                shouldShowAddInvestmentButton={previousInvestmentsWithIds.length < 5}
                showEmptyTable={false}
              />
            </FormikField>
            <Col xs={24} sm={24} md={24} lg={16} xl={17}>
              <Row gutter={[24, 0]}>
                <Col xs={24} sm={24}>
                  <S.CheckboxFormikFieldStyled
                    label={{ for: 'hidePreviousInvestments', label: hidePreviousInvestmentsLabel }}
                    error={errors.hidePreviousInvestments}
                    touched={touched.hidePreviousInvestments}
                  >
                    <Checkbox
                      {...getFieldProps('hidePreviousInvestments')}
                      onChange={(e: CheckboxChangeEvent) => {
                        setFieldValue('hidePreviousInvestments', e.target.checked);
                        setFieldTouched('hidePreviousInvestments', true);
                      }}
                      checked={values.hidePreviousInvestments ?? false}
                      id="hidePreviousInvestments"
                    />
                  </S.CheckboxFormikFieldStyled>
                </Col>
              </Row>
            </Col>
          </>
        ) : null}

        <SubmitButton
          type="primary"
          htmlType="submit"
          form="founder-profile-info-form"
          onClick={submitForm}
          disabledEvaluation={{ isValid, dirty, submitCount }}
        >
          {saveChanges}
        </SubmitButton>
      </form>
    </Section>
  );
};

import {
  type CompanyDetails,
  getCompanyDetails,
  GET_COMPANY_DETAILS_CACHE_KEY,
} from '@pages/content/api/get-company-details.action';
import { founderIdQueryParam } from '@pages/content/public-profile/founder/founder.page';
import AvatarWithName from '@parts/avatar-with-name/avatar-with-name';
import { Icon, type IconComponent } from '@parts/icon/icon';
import { SecureLink } from '@parts/link/link';
import { Section } from '@parts/section/section';
import { Links } from '@router/links';
import { useQuery } from '@tanstack/react-query';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useParams } from 'react-router-dom';
import S from './details.styles';
import Column from './parts/column/column';

export const Details = () => {
  const [currentStageLabel, industryLabel, targetMarketLabel, locationLabel, pulseLabel] = useTranslation([
    'companyDetails.currentStage',
    'companyDetails.industry',
    'companyDetails.targetMarket',
    'companyDetails.location',
    'companyDetails.pulse',
  ]);

  const history = useHistory();

  const { relationshipId } = useParams<{ relationshipId: string }>();

  const { data: response } = useQuery(
    [GET_COMPANY_DETAILS_CACHE_KEY(relationshipId)],
    getCompanyDetails(relationshipId),
  );

  const {
    deviceData: { isMobile },
  } = useDeviceDetect();

  const {
    businessName,
    isConnected,
    avatarUrl,
    currentStage,
    industry,
    targetMarket,
    location,
    linkedinUrl,
    twitterUrl,
    companyId,
  } = Object(response?.data) as Partial<CompanyDetails>;

  return (
    <Section>
      <S.Wrapper>
        <S.Company>
          <S.AvatarLink
            href={history.createHref({
              search: `${founderIdQueryParam}=${companyId}`,
              pathname: Links.FOUNDER_PUBLIC_PROFILE_DETAILS(),
            })}
          >
            <AvatarWithName
              name={businessName || ''}
              avatarUrl={avatarUrl || ''}
              avatarSize="medium"
              fontSize="medium"
            />
          </S.AvatarLink>
        </S.Company>
        {isMobile && (isConnected || linkedinUrl || twitterUrl) && <S.Divider />}
        <S.Socials>
          {isConnected && (
            <SecureLink
              href={history.createHref({
                search: `${founderIdQueryParam}=${companyId}`,
                pathname: Links.FOUNDER_PUBLIC_PROFILE_DASHBOARDS(),
              })}
            >
              <S.PulseLabel>{pulseLabel}</S.PulseLabel>
            </SecureLink>
          )}
          {linkedinUrl && (
            <SecureLink target="blank" outer href={linkedinUrl}>
              <div>
                <Icon icon={S.LinkedinOutlined as IconComponent} />
              </div>
            </SecureLink>
          )}
          {twitterUrl && (
            <SecureLink target="blank" outer href={twitterUrl}>
              <div>
                <Icon icon={S.TwitterOutlinedIcon as IconComponent} />
              </div>
            </SecureLink>
          )}
        </S.Socials>
      </S.Wrapper>
      <S.Divider />
      <S.About>
        <Column label={currentStageLabel}>{currentStage || ''}</Column>
        <Column label={industryLabel}>{industry?.[0] || ''}</Column>
        <Column label={targetMarketLabel}>{targetMarket?.join(', ') || ''}</Column>
        <Column label={locationLabel}>{location || ''}</Column>
      </S.About>
    </Section>
  );
};

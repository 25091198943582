import type { FilterOption } from '@/components/ui/v2-filter-panel/v2-filter-panel.types';
import { V2Sort } from '@/components/ui/v2-sort/v2-sort';
import type { SortOrder, Sorter } from '@/utils/type-definitions/filters';
import { useState } from 'react';
import type { V2MobileFiltersProps } from './mobile-filters-form.types';

import S from './mobile-filters-form.styles';

const V2FilterSwitch = ({
  filters,
  activeFilters = [],
  onChange,
  disabled = false,
}: {
  filters: FilterOption[];
  activeFilters?: string[];
  onChange: (value: string[]) => void;
  disabled?: boolean;
}) => {
  return (
    <S.FilterSwitch>
      {filters.map(({ value, label }) => (
        <li key={value}>
          <input
            type="checkbox"
            id={`filter-switch-${value}`}
            name="filter-switch"
            value={value}
            checked={activeFilters.includes(value)}
            onChange={(e) => onChange(e.target.checked ? [e.target.value] : [])}
            disabled={disabled}
          />
          <label htmlFor={`filter-switch-${value}`}>{label}</label>
        </li>
      ))}
    </S.FilterSwitch>
  );
};

export const MobileFiltersForm = ({
  filters,
  activeFilters = [],
  initialSearchValue = '',
  sortValue,
  onChange,
  sortOptions,
  showElements = ['filters', 'sort', 'search'],
  disabled = false,
}: V2MobileFiltersProps) => {
  const [currentSort, setCurrentSort] = useState<Sorter | undefined>(sortValue);

  const handleSortChange = (key: string) => {
    const sortChangeOrder: (SortOrder | undefined)[] = ['Asc', 'Desc', undefined];
    const nextSort = sortChangeOrder[(sortChangeOrder.indexOf(currentSort?.order) + 1) % sortChangeOrder.length];

    onChange({ sort: nextSort ? { key, order: nextSort } : undefined });
    setCurrentSort(nextSort ? { key, order: nextSort } : undefined);
  };

  return (
    <S.MobileFiltersForm onSubmit={(e) => e.preventDefault()}>
      {showElements.includes('search') ? (
        <S.SearchInputWrapper>
          <S.SearchInput
            placeholder="Search"
            onBlur={(e) => onChange({ search: e.target.value })}
            defaultValue={initialSearchValue}
            disabled={disabled}
          />
          <S.SearchIcon name="search" size="md" />
        </S.SearchInputWrapper>
      ) : null}

      {showElements.includes('sort')
        ? sortOptions.map(({ label, key }) => (
            <S.SortButton
              key={key}
              onClick={() => handleSortChange(key)}
              disabled={disabled}
              $active={sortValue?.key === key}
            >
              <V2Sort tagName="span" variant={sortValue?.key === key ? sortValue.order : undefined} /> {label}
            </S.SortButton>
          ))
        : null}

      {showElements.includes('filters') ? (
        <V2FilterSwitch
          onChange={(value) => onChange({ filters: value })}
          activeFilters={activeFilters}
          filters={filters}
          disabled={disabled}
        />
      ) : null}
    </S.MobileFiltersForm>
  );
};

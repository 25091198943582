import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { Suspense, lazy } from 'react';
import type { Props as ReactApexChartProps } from 'react-apexcharts';

const ReactApexChart = lazy(() => {
  try {
    return import('react-apexcharts');
  } catch (error) {
    if (error instanceof Error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    return { default: () => <></> } as unknown as Promise<typeof import('react-apexcharts')>;
  }
});

export const LazyReactApexChart = (props: ReactApexChartProps) => (
  <Suspense fallback={<FullHeightSpinner small />}>
    <ReactApexChart {...props} />
  </Suspense>
);

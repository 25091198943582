import { Avatar } from 'antd';
import styled from 'styled-components';

const V2Avatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.v2.colors.blue[300]};
  font-weight: ${({ theme }) => theme.v2.typography.weights.bold};
  flex-shrink: 0;

  .ant-avatar-string {
    font-size: ${({ theme }) => theme.v2.typography.sizes['4xl']};
    text-transform: uppercase;
  }
`;

export default { V2Avatar };

import { SecureLink as SecureLinkBase } from '@parts/link/link';
import { Button as ButtonBase } from '@parts/button/button';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.xsmall}) {
    padding: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  padding: 0;
  background-color: ${({ theme }) => theme.baseColors.White};
  border-radius: ${({ theme }) => theme.radius.default};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    height: auto;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.xsmall}) {
    flex-direction: column;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacing.xbase};
  margin-right: ${({ theme }) => theme.spacing.medium};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.xsmall}) {
    margin-left: ${({ theme }) => theme.spacing.xmedium};
  }
`;

const Image = styled.img`
  height: 100%;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.xsmall}) {
    width: 100%;
    align-self: center;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  font-size: 30px;
  margin-top: ${({ theme }) => theme.spacing.xmedium};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  width: 100%;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.xsmall}) {
    padding-top: ${({ theme }) => theme.spacing.xmedium};
  }

  @media (max-width: 380px) {
    flex-direction: column;
  }

  h2 {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  span {
    margin-left: ${({ theme }) => theme.spacing.small};
    color: ${({ theme }) => theme.baseColors.Blue200};

    @media (max-width: 380px) {
      margin-left: 0;
    }
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.small};
  margin-top: ${({ theme }) => theme.spacing.xmedium};
  font-size: ${({ theme }) => theme.fontSize.regular};
  color: ${({ theme }) => theme.baseColors.Blue400};
  max-width: 500px;
  list-style: none;

  li:nth-child(1) {
    margin-left: -${({ theme }) => theme.spacing.xxsmall};
  }
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const ListItemSpan = styled.span`
  margin-left: ${({ theme }) => theme.spacing.xmedium};
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.medium};
  width: 100%;
`;

const CancelButton = styled(ButtonBase)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.baseColors.Blue200};
  border-color: ${({ theme }) => theme.baseColors.Blue200};
  width: 50%;
  padding: 0;
`;

const SecureLink = styled(SecureLinkBase)`
  display: flex;
  justify-content: center;
  margin: 22px 0;
  min-width: 50%;
`;

const Button = styled(ButtonBase)`
  width: 100%;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.xsmall}) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default {
  Wrapper,
  Image,
  SecureLink,
  Container,
  TitleWrapper,
  List,
  ListItem,
  ListItemSpan,
  ContentWrapper,
  ButtonsContainer,
  Button,
  CancelButton,
};

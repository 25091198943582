import { NextButton } from '@layout/wizard/parts/footer/footer.styles';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 70px;
`;

const WelcomeText = styled.p`
  font-size: 34px;
  color: ${({ theme }) => theme.baseColors.White};
  position: absolute;
  top: 28%;
  opacity: 0;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    font-size: 24px;
    top: 33%;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    font-size: 24px;
    top: 12%;
  }
`;

const LowerText = styled.div`
  position: absolute;
  text-align: center;
  bottom: 29%;
  opacity: 0;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    font-size: 24px;
    bottom: 31%;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    bottom: 15%;
  }
`;

const Text = styled.p<{ $bolded?: boolean }>`
  color: ${({ theme }) => theme.onboardingColors.headingSubtitle};
  font-size: ${({ theme }) => theme.fontSize.xmedium};
  font-weight: ${({ $bolded, theme }) => ($bolded ? theme.fontWeight.semibold : theme.fontWeight.light)};
  display: block;
  letter-spacing: 0.4px;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.small};
  }
`;

const Button = styled(NextButton)`
  flex-basis: 50px;
  width: 200px;
  opacity: 0;
  transform: translateY(50px);

  img {
    margin-left: ${({ theme }) => theme.spacing.small};
  }
`;

export default { Wrapper, WelcomeText, LowerText, Text, Button };

import { axiosInstance } from '@utils/axios/axios-instance';

export interface FounderQualifyingQuestionsRequestData {
  monthlyRevenueScale?: number;
  headcountScale?: number;
  interestSourcingInvestment?: number;
  interestFindingAdvisors?: number;
  interestInvestorReporting?: number;
}

export const patchFounderQualifyingQuestionsAction = (payload: FounderQualifyingQuestionsRequestData) =>
  axiosInstance.patch('/onboarding/founder/founder-qualifying-questions', payload);

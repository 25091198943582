import { DATE_FORMATS, type DateFormat } from '@i18n/date-formats';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import { DateTime } from 'luxon';

const getDateFormatConfig = (countryOfResidence: CountryCodes | null) => {
  if (!countryOfResidence) {
    return DATE_FORMATS[CountryCodes.GB];
  }

  return DATE_FORMATS[countryOfResidence] ?? DATE_FORMATS[CountryCodes.GB];
};

export const dateFormatter =
  (dateFormatConfig: { [key in DateFormat]: string }) =>
  (date: string | Date | DateTime | number, format: DateFormat) => {
    let dateTime: DateTime | undefined = undefined;

    if (!Number.isNaN(Number(date))) {
      dateTime = DateTime.fromMillis(Number(date));
    } else if (date instanceof Date) {
      dateTime = DateTime.fromJSDate(date);
    } else if (typeof date === 'string') {
      dateTime = DateTime.fromISO(date);
    }

    return (dateTime ?? (date as DateTime)).toFormat(dateFormatConfig[format]).replace('!', "'");
  };

export const useUserDateFormat = () => {
  const {
    state: { countryOfResidence },
  } = useUserAccount();

  const formats = getDateFormatConfig(countryOfResidence);

  return { dateFormatter: dateFormatter(formats), formats };
};

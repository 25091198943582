import { Select as SelectBase } from '@parts/select/select';
import { InputNumber as InputNumberBase } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    margin-bottom: ${({ theme }) => theme.spacing.xmedium};
  }
`;

const SliderWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin-bottom: ${({ theme }) => theme.spacing.xmedium};
  }
`;

const Select = styled(SelectBase)`
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};

  .ant-select-selector {
    height: 50px !important;
  }

  .ant-select-selection-overflow {
    flex-wrap: nowrap;
  }
`;

const Range = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.normal};
`;

const InputNumber = styled(InputNumberBase)`
  margin-bottom: ${({ theme }) => theme.spacing.xmedium};
`;

const Label = styled.p`
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
`;

const MobileInput = styled.div``;

const Filters = styled.div`
  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin-bottom: 30px;
  }
`;

export default { Select, Wrapper, SliderWrapper, Range, Label, InputNumber, MobileInput, Filters };

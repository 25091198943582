import { V2AriaTable } from '@/components/blocks/v2-aria-table/v2-aria-table';
import type { AdvisorFounderConnection } from '../../../types';

export const InvestmentStageCell = ({ investmentStage }: AdvisorFounderConnection) => {
  return (
    <V2AriaTable.BodyCell>
      <V2AriaTable.Text>{investmentStage ?? '-'}</V2AriaTable.Text>
    </V2AriaTable.BodyCell>
  );
};

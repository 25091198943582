import styled from 'styled-components';

export const PaymentInfoContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.color.sectionBackground};

  & > svg {
    width: 50px;
  }

  & > h1,
  & > p {
    text-align: center;
  }

  & > p {
    margin: ${({ theme }) => theme.spacing.small};
  }

  & > a {
    margin-top: ${({ theme }) => theme.spacing.small};
  }
`;

import { createContext, useContext } from 'react';

const V2AriaMobileConnectionCardContext = createContext<{ value: undefined } | null>(null);

export const useV2AriaMobileConnectionCardContext = () => {
  const context = useContext(V2AriaMobileConnectionCardContext);
  if (!context)
    throw new Error(
      'V2AriaMobileConnectionCard.* component must be rendered as child of V2AriaMobileConnectionCard component',
    );

  return context;
};

export { V2AriaMobileConnectionCardContext };

import { InvestorType } from '@pages/content/profile/investor/api/types';
import { LINKEDIN_URL_REGEXP } from '@utils/fns/reg-exp-validators';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import * as Yup from 'yup';

import { useValidationSchema as useNedPersonalDetailsValidationSchema } from '../../../ned/parts/personal-details/validation-schema';

export const useValidationSchema = (investorType: InvestorType | null) => {
  const [formikRequired, formikMin, formikMax, linkedinUrlError, occupationOrLinkedinUrlError] = useTranslation([
    'formik.validation.required',
    'formik.validation.min',
    'formik.validation.max',
    'formik.validation.phoneNumber',
    'formik.validation.linkedInUrl',
    'formik.validation.twitterUrl',
    'profile.investor.section.personalDetails.form.occupationOrLinkedinUrlError',
  ]);
  const nedValidationSchema = useNedPersonalDetailsValidationSchema();

  return nedValidationSchema.concat(
    Yup.object().shape(
      {
        ...(investorType === InvestorType.Institutional && {
          companyName: Yup.string().trim().min(2, formikMin).max(50, formikMax).required(formikRequired).nullable(),
        }),
        ...(investorType === InvestorType.Angel && {
          recentOccupation: Yup.string()
            .trim()
            .max(50, formikMax)
            .nullable()
            .when('linkedinUrl', {
              is: (val: string | null | undefined) => !val || val.length === 0,
              then: Yup.string().trim().max(50, formikMax).nullable().required(occupationOrLinkedinUrlError),
            }),
          linkedinUrl: Yup.string()
            .trim()
            .matches(LINKEDIN_URL_REGEXP, linkedinUrlError)
            .nullable()
            .when('recentOccupation', {
              is: (val: string | null | undefined) => !val || val.length === 0,
              then: Yup.string()
                .trim()
                .matches(LINKEDIN_URL_REGEXP, linkedinUrlError)
                .nullable()
                .required(occupationOrLinkedinUrlError),
            }),
        }),
      },
      investorType === InvestorType.Angel ? [['recentOccupation', 'linkedinUrl']] : undefined,
    ),
  );
};

import { hasPermissionsToPortfolio, isActiveInvestor, isActiveNed, isInvestorWaitingForApproval } from '@abilities';
import CommunityIcon from '@assets/icons/start/community.svg';
import LegalIcon from '@assets/icons/start/legal.svg';
import LensIcon from '@assets/icons/start/lens.svg';
import PortfolioIcon from '@assets/icons/start/portfolio.svg';
import ProfileIcon from '@assets/icons/start/profile.svg';
import PulseIcon from '@assets/icons/start/pulse.svg';
import RolesIcon from '@assets/icons/start/roles.svg';
import { communityLink as communityInvestorLink, communityAdvisorLink } from '@constants/links';
import { Roles } from '@domain/accounts/roles';
import { PermissionNames, type ApiDefinedPermission } from '@pages/auth/api/account-data/account-data.types';
import { Links } from '@router/links';
import { Routes } from '@router/routes';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import Tile from '../tile/tile';
import S from './profile-tiles.styles';

type Pages = 'community' | 'lens' | 'pulse' | 'legal' | 'roles' | 'profile' | 'advisors' | 'academy' | 'portfolio';

const investorsTiles: Pages[] = ['community', 'lens', 'pulse', 'profile', 'portfolio'];
const foundersTiles: Pages[] = ['community', 'lens', 'pulse', 'legal', 'advisors', 'profile'];
const nedsTiles: Pages[] = ['community', 'lens', 'legal', 'academy', 'roles', 'profile'];
const investorNedsTiles: Pages[] = ['community', 'lens', 'legal', 'pulse', 'academy', 'roles', 'portfolio', 'profile'];

const ProfileTiles = () => {
  // TODO: handle permissions with ablities
  const {
    state: { userRole, permissions, products, isAcademy },
  } = useUserAccount();

  const [
    lensLabel,
    investorsLabel,
    pulseLabel,
    reportLabel,
    legalLabel,
    rolesLabel,
    profileLabel,
    academyLabel,
    portfolioLabel,
    communityLabel,
    founderInvestorsDescLabel,
    lensNedDescLabel,
    pulseDescLabel,
    reportFounderDescLabel,
    legalDescLabel,
    rolesDescLabel,
    profileDescLabel,
    academyDescLabel,
    advisorsTitleLabel,
    advisorsDescLabel,
    portfolioDescLabel,
    communityDescLabel,
    communityInvestorLabel,
  ] = useTranslation([
    'navigation.lens',
    'navigation.investors',
    'navigation.pulse',
    'navigation.report',
    'navigation.legal',
    'navigation.roles',
    'navigation.myProfile',
    'navigation.academy',
    'navigation.portfolio',
    'navigation.community',
    'start.lens',
    'start.lens.ned',
    'start.pulse',
    'start.report.founder',
    'start.legal',
    'start.roles',
    'start.myProfile',
    'start.academy',
    'start.advisors.title',
    'start.advisors.description',
    'start.portfolio',
    'start.community',
    'start.community.investor',
  ]);

  const getPulseLink = () => {
    if (userRole === Roles.INVESTOR) {
      return Links.PULSE_INVESTOR_COMPANIES();
    }

    if (userRole === Roles.INVESTOR_NED) {
      return Links.PULSE_NED_COMPANIES();
    }

    return Links.PULSE_FOUNDER_DASHBOARDS();
  };
  const isActiveInvestorValue = isActiveInvestor(products);
  const isActiveNedValue = isActiveNed(products);
  const isActiveInvestorNed = userRole === Roles.INVESTOR_NED && isActiveNedValue && isActiveInvestorValue;

  const getLensDescription = () => (userRole === Roles.NED ? lensNedDescLabel : founderInvestorsDescLabel);
  const getCommunityDescription = () =>
    userRole === Roles.INVESTOR || isActiveInvestorNed ? communityInvestorLabel : communityDescLabel;
  const getPulseDescription = () => (userRole === Roles.FOUNDER ? reportFounderDescLabel : pulseDescLabel);

  const getCommunityLink = () => {
    if (isActiveInvestorNed || isActiveInvestorValue) {
      return communityInvestorLink;
    }

    if (isActiveNedValue) {
      return communityAdvisorLink as string;
    }

    return '#';
  };

  const allTiles: {
    [key in Pages]: {
      title: string;
      desc: string;
      img: string;
      link: string;
      comingSoonLabel?: boolean;
      externalLink?: boolean;
    };
  } = {
    community: {
      title: communityLabel,
      desc: getCommunityDescription(),
      img: CommunityIcon,
      link: getCommunityLink(),
      externalLink: true,
    },
    lens: {
      title: userRole === Roles.FOUNDER ? investorsLabel : lensLabel,
      desc: getLensDescription(),
      img: LensIcon,
      link: Links.LENS_SMART_MATCH(),
    },
    pulse: {
      title: userRole === Roles.FOUNDER ? reportLabel : pulseLabel,
      desc: getPulseDescription(),
      img: PulseIcon,
      link: getPulseLink(),
    },
    legal: {
      title: legalLabel,
      desc: legalDescLabel,
      img: LegalIcon,
      link: Routes.LEGAL,
    },
    roles: {
      title: rolesLabel,
      desc: rolesDescLabel,
      img: RolesIcon,
      link: Links.ROLES_BROWSE(),
    },
    advisors: {
      title: advisorsTitleLabel,
      desc: advisorsDescLabel,
      img: RolesIcon,
      link: Links.ADVISORS(),
    },
    profile: {
      title: profileLabel,
      desc: profileDescLabel,
      img: ProfileIcon,
      link: Routes.PROFILE,
    },
    academy: {
      title: academyLabel,
      desc: academyDescLabel,
      img: LegalIcon,
      link: Routes.ACCESS_NED_ACADEMY,
    },
    portfolio: {
      title: portfolioLabel,
      desc: portfolioDescLabel,
      img: PortfolioIcon,
      link: Routes.PORTFOLIO,
    },
  };

  const filterOutTilesWithoutPermissions = (pages: Pages[], perms: ApiDefinedPermission[]) => {
    const permissionsMap: Partial<Record<PermissionNames, boolean>> = {
      [PermissionNames.LegalDocs]: perms.some((e) => e.name === PermissionNames.LegalDocs && e.allowed),
      [PermissionNames.Portfolio]: perms.some((e) => e.name === PermissionNames.Portfolio && e.allowed),
    };

    const pagesArr = pages.filter((el) => {
      if (el === 'legal' && !permissionsMap[PermissionNames.LegalDocs]) {
        return false;
      }
      if (el === 'portfolio' && !permissionsMap[PermissionNames.Portfolio]) {
        return false;
      }

      return true;
    });

    return pagesArr;
  };

  const setInvestorNedPermissions = (arr: Pages[]) => {
    const isWaitingForApproval = isInvestorWaitingForApproval(products);
    const isPortfolioPermission = hasPermissionsToPortfolio(permissions);

    const pagesArr = arr.filter((el) => {
      if (isWaitingForApproval && (el === 'pulse' || el === 'portfolio')) {
        return false;
      } else if (!isWaitingForApproval) {
        if (el === 'portfolio' && !isPortfolioPermission) {
          return false;
        }
      }
      if (el === 'academy' && !isAcademy) {
        return false;
      }

      return true;
    });
    return pagesArr;
  };

  const setNedPermissions = (arr: Pages[]) => (isAcademy ? arr : arr.filter((el) => el !== 'academy'));

  const getTiles = () => {
    switch (userRole) {
      case Roles.INVESTOR:
        return filterOutTilesWithoutPermissions(investorsTiles, permissions);
      case Roles.FOUNDER:
        return filterOutTilesWithoutPermissions(foundersTiles, permissions);
      case Roles.NED:
        return setNedPermissions(filterOutTilesWithoutPermissions(nedsTiles, permissions));
      case Roles.INVESTOR_NED:
        return setInvestorNedPermissions(filterOutTilesWithoutPermissions(investorNedsTiles, permissions));
      default:
        return [];
    }
  };

  return (
    <S.Section data-testid="profile-tiles">
      {getTiles().map((el) => {
        const tile = allTiles[el];

        return (
          <Tile
            disabled={tile.comingSoonLabel}
            comingSoonBadge={tile.comingSoonLabel}
            key={el}
            title={tile.title}
            description={tile.desc}
            Img={tile.img}
            link={tile.link}
            externalLink={tile.externalLink}
          />
        );
      })}
    </S.Section>
  );
};

export default ProfileTiles;

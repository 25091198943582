import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import { RegionValue } from '@utils/type-definitions/regions-values';
import type { GraphqlResponse } from 'src/types';

export interface GetUsedCountriesAndRegionsResult {
  usedCountries: CountryCodes[];
  notUsedCountries: CountryCodes[];
  regions: RegionValue[];
}

export const GET_COUNTRIES_USED_BY_INVESTORS_CACHE_KEY = 'get-countries-used-by-investors';

export const getCountriesUsedByInvestorsAction = async (): Promise<
  GraphqlResponse<GetUsedCountriesAndRegionsResult>
> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
    query getCountriesUsedByInvestors {
      getCountriesUsedByInvestors {
          usedCountries
          notUsedCountries
          regions
      }
    }
      `,
  });

  return graphqlResponseWrapper(data, ['getCountriesUsedByInvestors']);
};

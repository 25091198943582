import {
  ChartViewDropdown,
  ChartViewType,
} from '@pages/content/pulse/parts/dashboards/parts/dashboard/metrics/parts/metric-item/parts/chart-metric/parts/chart-view-dropdown/chart-view-dropdown';
import { LazyReactApexChart } from '@parts/react-apex-chart/react-apex-chart.lazy';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useState } from 'react';
import S from '../../graph.styles';
import EmptyGraph from '../empty-graph/empty-graph';
import { useCircularBarGraphOptions } from './circular-bar-graph-options';
import { Legend } from './parts/legend';

export const CircularBarGraph = ({
  data,
  title,
  noDataLabel,
}: {
  data: [string, number][];
  title: string;
  noDataLabel: string;
}) => {
  const [chartType, setChartType] = useState<ChartViewType>(ChartViewType.CIRCULAR);

  const selectedChartType = chartType === ChartViewType.BAR ? 'bar' : 'pie';

  const {
    deviceData: { isDesktop, isTablet, isMobile },
  } = useDeviceDetect();

  const getDevice = () => {
    if (isDesktop) {
      return 'desktop';
    }
    if (isTablet) {
      return 'tablet';
    }
    if (isMobile) {
      return 'mobile';
    }
    return 'desktop';
  };

  const preparedData = data.map((item) => ({ name: item[0], data: [item[1]] }));
  const circularChartPreparedData = preparedData.map((item) => item.data[0]);
  const labelsToCircularChart = preparedData.map((item) => `${item.name} (${item.data})`);

  const options = useCircularBarGraphOptions(getDevice(), selectedChartType, labelsToCircularChart);

  return (
    <S.Section
      header={title}
      className="circular-bar-graph"
      data-testid="graph"
      cornerAddition={{
        content: Boolean(data.length) && (
          <ChartViewDropdown
            onMenuItemSelect={(v: ChartViewType) => setChartType(v)}
            numericDisabled
            chartsGroup={[ChartViewType.CIRCULAR, ChartViewType.BAR]}
          />
        ),
        width: 0,
      }}
    >
      {data.length ? (
        <S.Wrapper isBarChartView={selectedChartType === 'bar'}>
          <LazyReactApexChart
            width="100%"
            height={chartType === ChartViewType.BAR ? 67 : 180}
            series={chartType === ChartViewType.BAR ? preparedData : circularChartPreparedData}
            type={selectedChartType}
            options={options}
          />
          <Legend data={data} isBarChartView={selectedChartType === 'bar'} />
        </S.Wrapper>
      ) : (
        <EmptyGraph label={noDataLabel} />
      )}
    </S.Section>
  );
};

export default CircularBarGraph;

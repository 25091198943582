import type { ReactNode } from 'react';
import { EllipsisText } from './parts/ellipsis-text';

import S from './meta.styles';

interface RowProps {
  icon: ReactNode;
  value: string | JSX.Element;
  maxLine?: number;
  onSeeMore?: () => void;
}

export const MetaRow = ({ icon, value, maxLine, onSeeMore }: RowProps) => {
  const shouldRenderEllipsis = typeof value === 'string' && maxLine !== undefined;

  return (
    <S.Row>
      {icon}
      {shouldRenderEllipsis ? <EllipsisText text={value as string} maxLine={maxLine} onSeeMore={onSeeMore} /> : value}
    </S.Row>
  );
};

interface MetaProps {
  items: {
    icon: ReactNode;
    value: string | JSX.Element;
    visible?: boolean;
    maxLine?: number;
    onSeeMore?: () => void;
  }[];
  disabled?: boolean;
}

const noValue = '---';

export const Meta = ({ items, disabled }: MetaProps) => (
  <S.Wrapper disabled={disabled}>
    {items.map((itm, i) => (
      <MetaRow
        key={`${itm.value.toString()}${i}`}
        icon={itm.icon}
        value={itm.value || noValue}
        maxLine={itm.maxLine}
        onSeeMore={itm.onSeeMore}
      />
    ))}
  </S.Wrapper>
);

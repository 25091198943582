import type { Notification } from '@context/notifications/notifications.reducer';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { PageHeader } from '@parts/page-header/page-header';
import { Pagination } from '@parts/pagination/pagination';
import { useQueryPagination } from '@utils/hooks/use-query-pagination/use-query-pagination';
import { getAllNotifications, GET_ALL_NOTIFICATIONS_CACHE_KEY } from '../../api/notifications.actions';
import { NotificationList } from '../notification-list/notification-list';
import S from './notifications.styles';

export const Notifications = () => {
  const {
    items: notifications,
    total,
    isLoading,
    refetch,
    ...paginationConfig
  } = useQueryPagination({
    itemsPerPage: 10,
    pageParam: 'page',
    action: getAllNotifications,
    cacheKey: GET_ALL_NOTIFICATIONS_CACHE_KEY,
    queryConfig: {
      refetchOnWindowFocus: true,
    },
  });

  return (
    <S.Container>
      <PageHeader title="Notifications" />
      {isLoading ? (
        <FullHeightSpinner />
      ) : (
        <NotificationList notifications={notifications as Notification[]} refetch={refetch} />
      )}
      {!isLoading && paginationConfig.maxPages > 1 && <Pagination {...paginationConfig} centered />}
    </S.Container>
  );
};

import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { CountryCodes, CountryNames } from '@utils/type-definitions/iso-to-country-name';
import { RegionNamesMap, type RegionValue } from '@utils/type-definitions/regions-values';
import type { SelectValue } from 'antd/lib/select';
import { GroupSelect } from '../group-select/group-select';

interface CountriesAndRegionsSelectProps {
  onChange: (value: SelectValue) => void;
  countriesOfResidence: string[];
  regions: string[];
  notUsedCountries: string[];
  value: string[];
  id?: string;
  showArrow?: boolean;
  showCountriesAndRegionsPlaceholder?: boolean;
  isLoading?: boolean;
}

const getRegionName = (region: RegionValue) => {
  return RegionNamesMap[region] ?? region;
};
const getCountryName = (countryIso: CountryCodes) => {
  return CountryNames[countryIso] ?? countryIso;
};

export const CountriesRegionsSelect = ({
  countriesOfResidence,
  onChange,
  notUsedCountries,
  regions,
  value,
  id,
  showArrow = true,
  showCountriesAndRegionsPlaceholder = true,
  isLoading = false,
}: CountriesAndRegionsSelectProps) => {
  const [countriesLabel, regionsLabel, countriesAndRegionsPlaceholder] = useTranslation([
    'lens.filters.countries',
    'lens.filters.regions',
    'lens.filters.selectPlaceholder',
  ]);

  const optionGroups = [
    {
      label: regionsLabel,
      options: regions.map((region) => ({
        value: region,
        label: getRegionName(region as RegionValue),
      })),
    },
    {
      label: countriesLabel,
      options: countriesOfResidence.map((country) => ({
        value: country,
        label: getCountryName(country as CountryCodes),
      })),
    },
    {
      label: null,
      disabled: true,
      options: (notUsedCountries || [])?.map((country) => ({
        value: country,
        label: getCountryName(country as CountryCodes),
        disabled: true,
      })),
    },
  ];

  return (
    <GroupSelect
      showArrow={showArrow}
      id={id}
      onChange={onChange}
      isLoading={isLoading}
      options={optionGroups}
      value={value}
      placeholder={showCountriesAndRegionsPlaceholder ? countriesAndRegionsPlaceholder : ''}
    />
  );
};

import {
  InvestorOnboardingContext,
  type InvestorOnboardingState,
} from '@context/investor-onboarding/investor-onboarding.context';
import {
  getInvestorOnboardingState,
  INVESTOR_ONBOARDING_STATE_CACHE_KEY,
} from '@pages/auth/api/onboarding-state/investor-onboarding-state.action';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { useQuery } from '@tanstack/react-query';
import { useReducer, type ReactNode, type Reducer } from 'react';
import { setInvestorOnboardingAction } from './investor-onboarding.actions';
import {
  initialInvestorOnboardingState,
  reducer,
  type InvestorOnboardingActionType,
} from './investor-onboarding.reducer';

export const InvestorOnboardingContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer<Reducer<InvestorOnboardingState, InvestorOnboardingActionType>>(
    reducer,
    initialInvestorOnboardingState,
  );

  const { refetch, isLoading, isFetching } = useQuery(
    [INVESTOR_ONBOARDING_STATE_CACHE_KEY],
    getInvestorOnboardingState,
    {
      enabled: true,
      cacheTime: 0,
      onSuccess(res) {
        if (!res?.data) return;

        const { accountDetails, onboardingState } = res.data;

        dispatch(
          setInvestorOnboardingAction({
            ...initialInvestorOnboardingState,
            loaded: true,
            started: onboardingState.onboardingStarted,
            accountType: accountDetails.type,
            stepErrors: state.stepErrors,
            institutionType: accountDetails.institutionType,
          }),
        );
      },
    },
  );

  return (
    <InvestorOnboardingContext.Provider
      value={{
        state,
        dispatch,
        refetch,
        isLoading: isLoading || isFetching,
      }}
    >
      {state.loaded ? children : <FullHeightSpinner />}
    </InvestorOnboardingContext.Provider>
  );
};

import { Button } from 'antd';
import styled from 'styled-components';

const Container = styled.article`
  background-color: ${({ theme }) => theme.color.sectionBackground};
  padding: ${({ theme }) => `${theme.spacing.xbase} ${theme.spacing.base}`};
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: ${({ theme }) => theme.radius.default};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    padding: ${({ theme }) => `${theme.spacing.xsmall} ${theme.spacing.small}`};
  }
`;

const Header = styled.header`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.spacing.medium};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin-bottom: ${({ theme }) => theme.spacing.small};
  }
`;

const ClearAllButton = styled(Button)`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.color.submitButton};
`;

const List = styled.ul`
  list-style: none;

  & > li:first-child {
    & > section {
      border-top-left-radius: ${({ theme }) => theme.radius.default};
      border-top-right-radius: ${({ theme }) => theme.radius.default};
    }
  }

  & > li:last-child {
    border-bottom: none;

    & > section {
      border-bottom-left-radius: ${({ theme }) => theme.radius.default};
      border-bottom-right-radius: ${({ theme }) => theme.radius.default};
    }
  }
`;

const ListItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
`;

const EmptyStateWrapper = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const S = {
  Container,
  Header,
  ClearAllButton,
  List,
  ListItem,
  EmptyStateWrapper,
};

export default S;

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_AVAILABLE_COMPANIES_CACHE_KEY = (filter: string) => `${filter}-available-companies`;

export enum CompanyType {
  EXTERNAL = 'External',
  INTERNAL = 'Internal',
}

export const getAvailableCompaniesAction =
  (filter: string) =>
  async (): Promise<
    GraphqlResponse<{
      companies: {
        isConnected: boolean;
        name: string;
        founderEmail: string;
        avatarUrl: string | null;
        investingRelationshipId: string;
        type: CompanyType;
        id: string;
      }[];
    }>
  > => {
    const { data } = await axiosInstance.post('/query', {
      query: `
      query getAvailableCompanies {
        getAvailableCompanies(filter: "${filter}") {
          companies {
            isConnected
            founderEmail
            investingRelationshipId
            avatarUrl
            name
            type
            id
          }
        }
      }
    `,
    });

    return graphqlResponseWrapper(data, ['getAvailableCompanies']);
  };

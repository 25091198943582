import { V2Button } from '@/components/ui/v2-button/v2-button';
import { V2TitleGroup } from '@/components/ui/v2-title-group/v2-title-group';
import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import styled from 'styled-components';

const Wrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto;
  min-height: 540px;
  gap: 24px;
`;

const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 8px;
  width: 100%;
  margin-top: 32px;

  @media (min-width: ${({ theme }) => theme.v2.breakpoints.sm}) {
    gap: 16px;
    flex-direction: row;
    margin-top: 75px;
  }
`;

const TitleGroupWrapper = styled(V2TitleGroup)`
  margin: 0 auto 40px auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ButtonSelectWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  max-width: 440px;
`;

const QuestionsWrapper = styled('div')`
  width: 100%;
  margin: 24px auto;
  flex-grow: 1;
  max-width: 560px;
`;

const SingleQuestionWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 40px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  max-width: 420px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 40px;

  ${customMediaQuery('padding', {
    xs: '4px',
    sm: '24px',
    md: '40px',
  })}
`;

const Image = styled.img`
  width: 204px;
  height: 204px;
`;

const DarkImage = styled(Image)`
  display: ${({ theme }) => (theme.v2.mode === 'dark' ? 'block' : 'none')};
`;

const LightImage = styled(Image)`
  display: ${({ theme }) => (theme.v2.mode === 'light' ? 'block' : 'none')};
`;

const Title = styled.h1`
  text-align: center;
  color: ${({ theme }) => (theme.v2.mode === 'dark' ? theme.v2.colors.neutral['50'] : theme.v2.colors.blue['400'])};
  font-size: ${({ theme }) => theme.v2.typography.sizes['2xl']};
  font-weight: ${({ theme }) => theme.v2.typography.weights.bold};
  line-height: ${({ theme }) => theme.v2.typography.lineHeights.button};
  margin-bottom: 6px;
`;

const Paragraph = styled.p`
  text-align: center;
  margin-bottom: 4px;
  color: ${({ theme }) => (theme.v2.mode === 'dark' ? theme.v2.colors.neutral['400'] : theme.v2.colors.neutral['700'])};
  font-size: ${({ theme }) => theme.v2.typography.sizes.md};
  font-weight: ${({ theme }) => theme.v2.typography.weights.regular};
`;

const Button = styled(V2Button)`
  margin-top: 32px;
`;

export default {
  Wrapper,
  ButtonWrapper,
  TitleGroupWrapper,
  ButtonSelectWrapper,
  QuestionsWrapper,
  SingleQuestionWrapper,
  Container,
  Image,
  DarkImage,
  LightImage,
  Title,
  Paragraph,
  Button,
};

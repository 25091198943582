import { Counter as CounterBase } from '@pages/content/lens/parts/counter/counter';
import { Section as SectionBase } from '@parts/section/section';
import styled from 'styled-components';

const Section = styled(SectionBase)`
  align-items: center;

  div:first-of-type {
    + div {
      margin: 0;
    }

    div {
      flex-grow: 0;
      margin-left: 0;

      span {
        margin: 0;
        margin-top: 2px;
      }
    }

    @media (max-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) {
      flex-direction: row;
    }
  }
`;

const Counter = styled(CounterBase)`
  margin-left: ${({ theme }) => theme.spacing.small} !important;
`;

export default { Section, Counter };

import { LazyDatePicker as DatePicker } from '@parts/date-picker/date-picker.lazy';
import { FullWidthField } from '@parts/forms/formik-full-field/formik-full-field';
import { Select } from '@parts/select/select';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { DateTime } from 'luxon';
import type { SubFilterProps } from '../../filter';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { DateFormat } from '@i18n/date-formats';

export const DateByCondition = ({
  getFieldProps,
  getFieldMeta,
  setFieldValue,
  dataKey,
  options,
  keys,
}: SubFilterProps) => {
  const valueProps = getFieldProps(keys.value);
  const valueMeta = getFieldMeta(keys.value);

  const comparatorProps = getFieldProps(keys.comparator);
  const comparatorMeta = getFieldMeta(keys.comparator);

  const [selectConditionLabel] = useTranslation(['filters.selectCondition']);

  const { formats } = useUserDateFormat();

  return (
    <>
      <FullWidthField
        label={{ label: '', for: `${dataKey} by date condition comparator` }}
        error={comparatorMeta.error}
        touched={comparatorMeta.touched}
      >
        <Select
          {...comparatorProps}
          placeholder={selectConditionLabel}
          value={comparatorProps.value}
          onChange={(v) => {
            setFieldValue(keys.key, dataKey);
            setFieldValue(keys.comparator, v);
          }}
        >
          {options}
        </Select>
      </FullWidthField>
      <FullWidthField
        label={{ label: '', for: `${dataKey} by date condition value` }}
        error={valueMeta.error}
        touched={valueMeta.touched}
      >
        <DatePicker
          {...valueProps}
          value={valueProps.value ? new Date(valueProps.value) : undefined}
          onChange={(v: Date) => {
            if (!v) return;

            const selectedDate = DateTime.fromJSDate(v);
            const { year, month, day } = selectedDate.toObject();
            setFieldValue(keys.value, DateTime.fromObject({ year, month, day, hour: 12, minute: 0 }).toJSDate());
          }}
          format={formats[DateFormat.FULL_DATE_DASHED]}
        />
      </FullWidthField>
    </>
  );
};

import { axiosInstance } from '@utils/axios/axios-instance';

export type FounderAdvisorQuestionsPatchPayload = Partial<{
  skillSet: string[];
  industryKnowledge: number;
  advisorType: 'Advisor' | 'Fractional' | 'Ned' | 'NotSureYet';
  helpNeeded: string;
}>;

export const patchFounderAdvisorsQuestions = (payload: FounderAdvisorQuestionsPatchPayload) =>
  axiosInstance.patch('/onboarding/founder/founder-advisors-questions', payload);

// TODO: CON-11585
//  This code requires urgent refactoring or rewriting from scratch due to its lack of readability,
//  structure, and potential security threats. It is recommended to conduct a thorough code review to
//  identify and fix issues related to performance, scalability, and security.

import {
  addInvestorOnboardingStepError,
  removeInvestorOnboardingStepError,
} from '@context/investor-onboarding/investor-onboarding.actions';
import { INVESTOR_ONBOARDING_STEP_INVESTMENT_KEY } from '@pages/content/onboarding/investor/paths';
import Title from '@pages/content/onboarding/parts/title/title';
import { updateInvestmentsAction } from '@pages/content/profile/investor/api/investments/investments';
import { InvestmentsScale, InvestorType, type PreviousInvestment } from '@pages/content/profile/investor/api/types';
import type { PreviousInvestmentWithId } from '@pages/content/profile/investor/parts/investment/investment';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import message from '@parts/message/message';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useListEntryRevealAnimation } from '@utils/hooks/use-list-reveal-animation/use-list-reveal-animation';
import { useInvestorOnboardingContext } from '@utils/hooks/use-onboarding-context/use-investor-onboarding-context';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import {
  INVESTOR_ONBOARDING_INVESTMENT_CACHE_KEY,
  getInvestorOnboardingInvestmentSpecData,
} from '../../api/get-investor-onboarding-investment-spec-data.action';
import { type StepContentProps } from '../../paths.config';
import { InvestmentForm } from './parts/form/form';
import { InvestmentSection } from './parts/investment-section/investment-section';

const LIST_ANIMATION_ELEMENT_ID = 'investment-content-list-animation-id';

export const InvestmentPageContent = ({ currentPage, endPage }: StepContentProps) => {
  const [title] = useTranslation(['investor-onboarding.investments.title']);

  const {
    state: { accountType },
    dispatch: investorOnboardingDispatch,
  } = useInvestorOnboardingContext();

  const { data, isLoading, isFetching, refetch } = useQuery(
    [INVESTOR_ONBOARDING_INVESTMENT_CACHE_KEY],
    getInvestorOnboardingInvestmentSpecData,
    { cacheTime: 0 },
  );

  const { mutateAsync: updateInvestments, isLoading: isMutationPending } = useMutation(updateInvestmentsAction, {
    async onSuccess() {
      await refetch();
    },
    onError(err: AxiosError) {
      message.error({ content: getServerError(err) });
    },
  });

  useListEntryRevealAnimation(
    LIST_ANIMATION_ELEMENT_ID,
    {},
    {
      shouldReinit: (_prevDeps: boolean[], nextDeps: boolean[]) => nextDeps[0] === false,
      deps: [isLoading],
    },
  );

  const showPreviousInvestmentsSection = accountType === InvestorType.Angel;

  const investmentsDataLoaded = data?.data;
  const investmentFormDataFetching = isLoading || isFetching || isMutationPending;
  const investmentsScale = data?.data?.previousInvestmentsScale ?? InvestmentsScale.NONE;

  const updatePreviousInvestments = async (investments: PreviousInvestmentWithId[]) => {
    const investmentsList: PreviousInvestment[] = investments.map((item) => ({
      company: item.company,
      note: item.note,
    }));

    await updateInvestments({
      previousInvestmentsTable: investmentsList,
    });
  };

  const updatePreviousInvestmentsScale = async (scale: InvestmentsScale) => {
    await updateInvestments({ previousInvestmentsScale: scale });
  };

  const handleFormValidStateChange = (validStatus: boolean, error: string | null) => {
    const errorId = `${INVESTOR_ONBOARDING_STEP_INVESTMENT_KEY}-client-data-error`;

    if (validStatus === true) {
      investorOnboardingDispatch(removeInvestorOnboardingStepError(errorId));
      return;
    }

    investorOnboardingDispatch(
      addInvestorOnboardingStepError({
        id: errorId,
        step: INVESTOR_ONBOARDING_STEP_INVESTMENT_KEY,
        error: error ?? '',
        disableButton: false,
      }),
    );
  };

  return (
    <>
      <Title title={title} currentPage={currentPage} endPage={endPage} />
      {isLoading ? (
        <FullHeightSpinner />
      ) : (
        showPreviousInvestmentsSection && (
          <InvestmentSection
            updateInvestments={updatePreviousInvestments}
            updateInvestmentsScale={updatePreviousInvestmentsScale}
            investmentsScale={investmentsScale}
            disabled={investmentFormDataFetching}
            data-animation-id={LIST_ANIMATION_ELEMENT_ID}
          />
        )
      )}
      {investmentsDataLoaded ? (
        <InvestmentForm
          data={data?.data!}
          handleUpdate={updateInvestments}
          isLoading={investmentFormDataFetching}
          onFormValidStateChange={handleFormValidStateChange}
          data-animation-id={LIST_ANIMATION_ELEMENT_ID}
        />
      ) : (
        <FullHeightSpinner small />
      )}
    </>
  );
};

import { emailDomain } from '@/constants/emails';
import { trustedSenderResourceLink } from '@/constants/links';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { V2NotificationBanner } from '@ui/v2-notification-banner/v2-notification-banner';

import S from './trusted-sender.styles';

export const TrustedSenderNotificationBanner = ({ onClose }: { onClose: () => void }) => {
  const [description, linkText] = useTranslation([
    { id: 'infoBanners.founder.trustedSender.content', variables: { email: emailDomain } },
    'infoBanners.founder.trustedSender.linkText',
  ]);

  return (
    <V2NotificationBanner closable containerized onClose={onClose} data-testid="trusted-sender-notification-banner">
      {description}&nbsp;
      <S.StyledSecureLink href={trustedSenderResourceLink} target="_blank" outer>
        {linkText}
      </S.StyledSecureLink>
      .
    </V2NotificationBanner>
  );
};

import { namePhraseKey } from '@pages/content/lens/investor/parts/browse-companies/parts/filters/filters';
import type { ParsedQuery } from 'query-string';

const removeTrailingComma = (str: string) => str.replace(/^,|,$/g, '');

const SORT_TYPE = '[SortType!]';
const FILTER_TYPE = '[FilterType!]';
const METRICS_FILTER_TYPE = '[MetricsFilterType!]';
const REGIONS_TYPE = '[RegionValue!]';
const COUNTRIES_OF_RESIDENCE_TYPE = '[CountryCodeValue!]';

enum Arguments {
  FILTERS = 'filters',
  SORT = 'sort',
  METRICS = 'metrics',
  REGIONS = 'regions',
  COUNTRIES_OF_RESIDENCE = 'countriesOfResidence',
}

export const createArgumentsString = (
  params: ParsedQuery<string>,
  sortType: string = SORT_TYPE,
  filterType = FILTER_TYPE,
) => {
  let result = '';

  const argumentsMap: Record<Arguments, string> = {
    filters: `$filters: ${filterType}`,
    sort: `$sort: ${sortType}`,
    metrics: `$metrics: ${METRICS_FILTER_TYPE}`,
    regions: `$regions: ${REGIONS_TYPE}`,
    countriesOfResidence: `$countriesOfResidence: ${COUNTRIES_OF_RESIDENCE_TYPE}`,
  };

  for (const prop in params) {
    if (Object.hasOwn(params, prop)) {
      const foundArgument: string = argumentsMap[prop as keyof typeof argumentsMap] ?? '';

      if (foundArgument) result += `${foundArgument},`;
    }
  }

  return result.length ? `(${removeTrailingComma(result)})` : result;
};

export const mapQueryStringToGraphqlParams = (
  otherParams: ParsedQuery<string>,
  excludedParams: string[],
  rolesFiltering: boolean = false,
  sortType: string = SORT_TYPE,
  filtersType: string = FILTER_TYPE,
) => ({
  arguments: createArgumentsString(otherParams, sortType, filtersType),
  variables: {
    ...(otherParams.filters &&
      !Array.isArray(otherParams.filters) && {
        filters: JSON.parse(otherParams.filters),
      }),
    ...(otherParams.sort &&
      !Array.isArray(otherParams.sort) && {
        sort: JSON.parse(otherParams.sort),
      }),
    ...(otherParams.metrics &&
      !Array.isArray(otherParams.metrics) && {
        metrics: JSON.parse(otherParams.metrics),
      }),
    ...(otherParams.regions &&
      !Array.isArray(otherParams.regions) && {
        regions: JSON.parse(otherParams.regions),
      }),
    ...(otherParams.countriesOfResidence &&
      !Array.isArray(otherParams.countriesOfResidence) && {
        countriesOfResidence: JSON.parse(otherParams.countriesOfResidence),
      }),
  },
  supplement: Object.keys(otherParams).reduce((acc, key) => {
    if (excludedParams.includes(key)) return acc;

    const value = otherParams[key];

    if (typeof value === 'string') {
      if (
        [
          Arguments.FILTERS,
          Arguments.SORT,
          Arguments.METRICS,
          Arguments.REGIONS,
          Arguments.COUNTRIES_OF_RESIDENCE,
        ].includes(key as Arguments)
      ) {
        return `${acc}, ${`${key}: $${key}`}`;
      }

      if (['true', 'false'].includes(value) || key === namePhraseKey) {
        return `${acc}, ${key}: ${value}`;
      }

      if (['roleTitle', 'industries', 'skillSet'].includes(key)) {
        return `${acc}, ${key}: "${value}"`;
      }

      return `${acc}, ${key}: ["${value}"]`;
    }

    if (rolesFiltering) {
      if (value) {
        return `${acc}, ${key}: [${value.map((item: string) => `"${item}"`)}]`;
      }
    }

    if (value && value.length > 0) {
      return `${acc}, ${key}: [${value.map((item: string) => `"${item}"`)}]`;
    }

    return acc;
  }, ''),
});

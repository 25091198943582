import type { ReactNode } from 'react';
import FreemiumFounderImage from '@assets/images/freemium-modal/founder-freemium.png';
import { Routes } from '@router/routes';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import S from './upgrade-freemium.styles';

export const UpgradeFreemium = ({
  darkTitle,
  blueTitle,
  listItems,
  submitButtonProps,
  cancelButtonProps,
}: {
  darkTitle: string;
  blueTitle: string;
  listItems: { icon: string; text: string; alt: string }[];
  submitButtonProps: { text: ReactNode; link: Routes };
  cancelButtonProps?: { text: ReactNode; onClick?: () => void };
}) => {
  const {
    deviceData: { isMobile },
  } = useDeviceDetect();

  const buttonWithLink = (
    <S.SecureLink href={submitButtonProps.link} data-testid="upgrade-freemium-link">
      <S.Button type="primary">{submitButtonProps.text}</S.Button>
    </S.SecureLink>
  );

  const buttonsWithCancelButton = (
    <S.ButtonsContainer>
      <S.CancelButton onClick={cancelButtonProps?.onClick}>{cancelButtonProps?.text}</S.CancelButton>
      {buttonWithLink}
    </S.ButtonsContainer>
  );

  return (
    <S.Container>
      <S.Wrapper>
        {!isMobile ? <S.Image src={FreemiumFounderImage} alt="Freemium Founder Logo" loading="lazy" /> : null}
        <S.ContentWrapper>
          <S.TitleWrapper>
            {darkTitle && <h2>{darkTitle}</h2>}
            {blueTitle && <span>{blueTitle}</span>}
          </S.TitleWrapper>

          <S.List>
            {listItems.map(({ icon, text, alt }) => (
              <S.ListItem key={text}>
                <img src={icon} alt={alt} loading="lazy" />
                <S.ListItemSpan>{text}</S.ListItemSpan>
              </S.ListItem>
            ))}
          </S.List>

          {cancelButtonProps ? buttonsWithCancelButton : buttonWithLink}
        </S.ContentWrapper>
      </S.Wrapper>
    </S.Container>
  );
};

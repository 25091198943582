import { useQuery } from '@tanstack/react-query';
import { GET_COMPANY_DETAILS_QUERY_KEY, getCompanyDetails } from '../api/get-company-details.query';

export const useGetCompanyDetails = (val?: string) => {
  const { data, error, isLoading, ...restQueryResult } = useQuery({
    queryFn: () => getCompanyDetails(val as string),
    queryKey: [GET_COMPANY_DETAILS_QUERY_KEY, val],
    enabled: Boolean(val),
    cacheTime: 0,
  });

  return {
    ...restQueryResult,
    companyData: data?.data,
    companyError: error,
    isCompanyDataLoading: isLoading,
  };
};

import { useQueryWithPagination } from '@/hooks/use-query-with-pagination';
import type { Sorter } from '@/utils/type-definitions/filters';
import { GET_NED_FOUNDER_CONNECTIONS_KEY, getAdvisorConnections } from '../api/get-advisor-connections.query';
import type { AdvisorFounderConnection } from '../types';

const PER_PAGE = 8;

type GetAdvisorConnectionsQueryConfig = {
  searchByName?: string;
  sort?: Sorter;
};

export const useGetAdvisorConnections = ({ searchByName = '', sort }: GetAdvisorConnectionsQueryConfig) => {
  const { data, error, isLoading, isFetching, isInitialLoading, pagination, ...restQueryResult } =
    useQueryWithPagination<AdvisorFounderConnection>(
      [GET_NED_FOUNDER_CONNECTIONS_KEY, searchByName, sort?.key ?? '', sort?.order ?? ''],
      (page: number, perPage: number) => getAdvisorConnections({ page, perPage, searchByName, sort }),
      { perPage: PER_PAGE, refetchOnWindowFocus: true },
    );

  return {
    ...restQueryResult,
    advisorConnectionsData: data,
    advisorConnectionsError: error,
    isAdvisorConnectionsDataLoading: isLoading,
    isAdvisorConnectionsDataFetching: isFetching,
    isAdvisorConnectionsInitialLoading: isInitialLoading,
    pagination,
  };
};

import { useV2AriaTableContext } from '../v2-aria-table-context';

import S from '../v2-aria-table.styles';

type TextProps = {
  children: string;
};

export const Text = ({ children }: TextProps) => {
  useV2AriaTableContext();

  return <S.Text>{children}</S.Text>;
};

import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { CompaniesList } from './parts/companies-list';

import aceFreakImage from '@assets/images/onboarding-investor/logos/ace-freak.webp';
import backedImage from '@assets/images/onboarding-investor/logos/backed.webp';
import bwcImage from '@assets/images/onboarding-investor/logos/bwc.webp';
import edenredImage from '@assets/images/onboarding-investor/logos/edenred.webp';
import femFoundryImage from '@assets/images/onboarding-investor/logos/fem-foundry.webp';
import fuelVenturesImage from '@assets/images/onboarding-investor/logos/fuel-ventures.webp';
import hambroPerksImage from '@assets/images/onboarding-investor/logos/hambro-perks.webp';
import idwardImage from '@assets/images/onboarding-investor/logos/idward.webp';
import loveTheSalesImage from '@assets/images/onboarding-investor/logos/love-the-sales.webp';
import myNestBoxImage from '@assets/images/onboarding-investor/logos/my-nest-box.webp';
import playfairImage from '@assets/images/onboarding-investor/logos/playfair.webp';
import safeToNetImage from '@assets/images/onboarding-investor/logos/safe-to-net.webp';
import scribeImage from '@assets/images/onboarding-investor/logos/scribe.webp';
import stratiphyImage from '@assets/images/onboarding-investor/logos/stratiphy.webp';
import superseedImage from '@assets/images/onboarding-investor/logos/superseed.webp';
import sureValleyImage from '@assets/images/onboarding-investor/logos/sure-valley.webp';
import vizzyImage from '@assets/images/onboarding-investor/logos/vizzy.webp';

export interface CompaniesListItem {
  imageUrl: string;
  name: string;
}

const joinLikesOfList: CompaniesListItem[] = [
  { imageUrl: sureValleyImage, name: 'SureValley' },
  { imageUrl: bwcImage, name: 'Bwc' },
  { imageUrl: edenredImage, name: 'Edenred' },
  { imageUrl: fuelVenturesImage, name: 'Fuel Ventures' },
  { imageUrl: backedImage, name: 'Backed' },
  { imageUrl: superseedImage, name: 'Superseed' },
  { imageUrl: hambroPerksImage, name: 'Hambro Perks' },
  { imageUrl: playfairImage, name: 'Playfair' },
];

const startupMembersList: CompaniesListItem[] = [
  { imageUrl: vizzyImage, name: 'Vizzy' },
  { imageUrl: scribeImage, name: 'Scribe' },
  { imageUrl: aceFreakImage, name: 'Ace Freak' },
  { imageUrl: loveTheSalesImage, name: 'Love The Sales' },
  { imageUrl: safeToNetImage, name: 'Safe To Net' },
  { imageUrl: myNestBoxImage, name: 'My Nest Box' },
  { imageUrl: femFoundryImage, name: 'Fem Foundry' },
  { imageUrl: idwardImage, name: 'Idward' },
  { imageUrl: stratiphyImage, name: 'Stratiphy' },
];

export const IntroCompanies = () => {
  const [list1Title, list2Title] = useTranslation([
    'investor-onboarding.intro.companiesList1.title',
    'investor-onboarding.intro.companiesList2.title',
  ]);

  return (
    <section data-testid="intro-companies">
      <CompaniesList title={list1Title} items={joinLikesOfList} id="companies-list-1" />
      <CompaniesList title={list2Title} items={startupMembersList} maxInRow={5} id="companies-list-2" />
    </section>
  );
};

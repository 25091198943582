import { axiosInstance } from '@utils/axios/axios-instance';

export const createNedConnection = (nedId: string) => async () =>
  axiosInstance.post(`/connections/founder/ned/${nedId}`);

export const cancelNedConnection = (connectionId: string | null) => async () => {
  if (!connectionId) throw new Error('Connection ID is required');
  return axiosInstance.post(`/connections/founder/connection/${connectionId}/cancel`);
};

export const removeNedConnection = (connectionId: string | null) => async () => {
  if (!connectionId) throw new Error('Connection ID is required');
  return axiosInstance.post(`/connections/founder/connection/${connectionId}/remove`);
};

import type { FounderOnboardingPathStep } from '@pages/content/onboarding/founder/paths';
import { createContext, type Dispatch } from 'react';
import { initialFounderOnboardingState, type FounderOnboardingActionType } from './founder-onboarding.reducer';

export interface OnboardingStepError<TStep> {
  id: string;
  step: TStep;
  error: string;
  disableButton: boolean;
}

export interface FounderOnboardingState {
  finished: boolean;
  stepErrors: OnboardingStepError<FounderOnboardingPathStep>[];
  playgroundFinished: boolean;
}

export interface FounderOnboardingContextApi {
  state: FounderOnboardingState;
  dispatch: Dispatch<FounderOnboardingActionType>;
}

export const FounderOnboardingContext = createContext<FounderOnboardingContextApi>({
  state: initialFounderOnboardingState,
  dispatch: () => {},
});

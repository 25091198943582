import message from '@/parts/message/message';
import { getServerError } from '@/utils/fns/get-server-error';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { FOUNDER_ONBOARDING_QUESTIONS_KEY } from '../api/get-founder-qualifying-questions.action';
import { patchFounderQualifyingQuestionsAction } from '../api/patch-founder-qualifying-questions.action';

export const usePatchFounderQualifyingQuestions = (cb?: () => void) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: patchFounderQualifyingQuestionsAction,
    onSuccess: () => {
      queryClient.invalidateQueries([FOUNDER_ONBOARDING_QUESTIONS_KEY]);
      cb?.();
    },
    onError: (error: AxiosError) => message.error({ content: getServerError(error) }),
  });

  return {
    ...mutation,
    isPatchFounderQualifyingQuestionsLoading: mutation.isLoading,
    isPatchFounderQualifyingQuestionsError: mutation.isError,
    patchFounderQualifyingQuestionsMutation: mutation.mutate,
  };
};

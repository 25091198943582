import { updateManagementTeamAction } from '@pages/content/company-details/api/management-team/update-management-team.action';
import message from '@parts/message/message';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { PHONE_NUMBER_REGEXP } from '@utils/fns/reg-exp-validators';
import { useDelayedSubmit } from '@utils/hooks/use-delayed-submit/use-delayed-submit';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useFormik } from 'formik';
import { useEffect, type ReactNode } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import S from './editable-table-column.styles';

export const EditableTableColumn = ({
  children,
  title,
  fieldKey = '',
  maxLength = 10,
  id: teamMemberId,
  placeholder,
  disabled,
}: {
  children: ReactNode;
  title?: string;
  fieldKey?: string;
  maxLength?: number;
  id: string;
  placeholder?: string;
  disabled?: boolean;
}) => {
  const { relationshipId } = useParams<{ relationshipId: string }>();

  const [savedLabel, addLabel, maxLengthError, phoneErrorLabel] = useTranslation([
    'global.drawer.saved',
    'portfolio.investor.managementTeam.add',
    'formik.validation.max',
    'formik.validation.phoneNumber',
  ]);

  const { mutateAsync: updateTeamMember } = useMutation(updateManagementTeamAction(relationshipId, teamMemberId), {
    onSuccess: () => {
      message.success({ content: savedLabel });
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  const fieldValidation = Yup.string().max(maxLength, maxLengthError).nullable();

  const phoneNumberValidation = Yup.string()
    .trim()
    .test('phoneNumberRegex', phoneErrorLabel, (value) => {
      const phoneNumber = value?.replace(/ /g, '');
      if (!phoneNumber || phoneNumber.length < 10) return true;
      return phoneNumber.match(PHONE_NUMBER_REGEXP) !== null;
    })
    .test('phoneNumberLength', maxLengthError, (value) => {
      const phoneNumber = value?.replace(/ /g, '');
      if (!phoneNumber) return true;
      return !(phoneNumber.length > maxLength);
    });

  const { getFieldProps, errors, handleChange, submitForm } = useFormik({
    onSubmit: (v: { [key: string]: unknown }) => {
      updateTeamMember(
        (() => {
          const temporaryValue = { ...v };
          temporaryValue[fieldKey] = temporaryValue[fieldKey] !== '' ? String(temporaryValue[fieldKey]) : null;
          return temporaryValue;
        })(),
      );
    },
    initialValues: {
      [fieldKey]: children || '',
    },
    validationSchema: Yup.object().shape({
      [fieldKey]: fieldKey === 'phoneNumber' ? phoneNumberValidation : fieldValidation,
    }),
  });

  const { onChange } = useDelayedSubmit(handleChange, submitForm);

  useEffect(() => {
    if (errors[fieldKey]) {
      message.error({ content: errors[fieldKey] });
    }
  }, [errors[fieldKey]]);

  return (
    <form>
      <S.TableColumn title={title} dataTestId="editable-table-column">
        <S.Input
          disabled={disabled}
          colored={fieldKey === 'email'}
          autoComplete="off"
          {...getFieldProps(fieldKey)}
          placeholder={placeholder || addLabel}
          onChange={onChange}
          type={fieldKey === 'age' ? 'number' : 'text'}
        />
      </S.TableColumn>
    </form>
  );
};

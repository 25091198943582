import { axiosInstance } from '@utils/axios/axios-instance';

export interface ConnectionNoteRequestData {
  targetId?: string;
  body?: {
    title?: string;
    content?: string;
  };
}

export const postConnectionNoteAction = (payload: ConnectionNoteRequestData) =>
  axiosInstance.post(`/connections/notes/${payload.targetId}`, payload.body);

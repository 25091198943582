import styled from 'styled-components';

const BackButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-right: 12px;
  }
`;

const MultilevelContentWrapper = styled.div<{ $activeKey: string }>`
  display: flex;
  position: relative;
  background: inherit;
  width: 200%;
  transform: ${({ $activeKey }) => ($activeKey === 'base' ? 'translateX(0)' : 'translateX(-50%)')};
  transition:
    transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1) 0s,
    opacity 0.3s cubic-bezier(0.25, 0.1, 0.25, 1) 0.075s;
`;

const MultilevelBaseContentWrapper = styled.div<{ $isActive: boolean }>`
  width: 50%;
  position: relative;
  background: inherit;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  pointer-events: ${({ $isActive }) => ($isActive ? 'auto' : 'none')};
`;

const MultilevelNestedContentWrapper = styled.div<{ $isActive: boolean }>`
  width: 50%;
  background: inherit;
  overflow-y: auto;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.1)};
  pointer-events: ${({ $isActive }) => ($isActive ? 'auto' : 'none')};
`;

export default {
  BackButton,
  TitleWrapper,
  MultilevelContentWrapper,
  MultilevelBaseContentWrapper,
  MultilevelNestedContentWrapper,
};

import { AccountStatus } from '@context/user/user-account.context';
import { Routes } from '@router/routes';
import { getForbiddenRoutesForNotOnboardedInvestor } from '@router/utils/route-helpers';
import { AbilityAction, type RoleAbilityBuilderFunction } from '../types';
import { hasFinishedInvestorOnboarding } from './utils/has-finished-investor-onboarding';
import { hasNotApplicableInvestorOnboarding } from './utils/has-not-applicable-investor-onboarding';
import { hasPendingInvestorOnboarding } from './utils/has-pending-investor-onboarding';
import { isActiveInvestor } from './utils/is-active-investor';
import { isUnpaidNed } from './utils/is-unpaid-ned';

const createRoutingAbilities: RoleAbilityBuilderFunction = (builder, { onboarding, products, accountStatus }) => {
  const { cannot, can } = builder;

  if (hasFinishedInvestorOnboarding(onboarding)) {
    can(AbilityAction.ACCESS_PAGE, Routes.ONBOARDING_INVESTOR_SUMMARY);
    cannot(AbilityAction.ACCESS_PAGE, Routes.ONBOARDING_INVESTOR);

    if (isActiveInvestor(products)) {
      getForbiddenRoutesForNotOnboardedInvestor().forEach((route) => {
        can(AbilityAction.ACCESS_PAGE, route);
      });
    }
  }

  if (hasPendingInvestorOnboarding(onboarding)) {
    can(AbilityAction.ACCESS_PAGE, Routes.ONBOARDING_INVESTOR);
    cannot(AbilityAction.ACCESS_PAGE, Routes.ONBOARDING_INVESTOR_SUMMARY);

    if (isActiveInvestor(products)) {
      getForbiddenRoutesForNotOnboardedInvestor().forEach((route) => {
        cannot(AbilityAction.ACCESS_PAGE, route);
      });
    }
  }

  if (hasNotApplicableInvestorOnboarding(onboarding)) {
    cannot(AbilityAction.ACCESS_PAGE, Routes.ONBOARDING_INVESTOR);
    cannot(AbilityAction.ACCESS_PAGE, Routes.ONBOARDING_INVESTOR_SUMMARY);
  }

  if (isActiveInvestor(products)) {
    cannot(AbilityAction.ACCESS_PAGE, Routes.INVESTOR_AWAITING_APPROVAL);
  }

  if (accountStatus === AccountStatus.ACTIVE && isUnpaidNed(products)) {
    can(AbilityAction.ACCESS_PAGE, Routes.EXTEND_PRODUCTS);
  } else {
    cannot(AbilityAction.ACCESS_PAGE, Routes.EXTEND_PRODUCTS);
  }

  return builder;
};

const createActionAbilities: RoleAbilityBuilderFunction = (builder, {}) => {
  return builder;
};

export const createInvestorAbility: RoleAbilityBuilderFunction = (builder, attributes) => {
  createRoutingAbilities(builder, attributes);
  createActionAbilities(builder, attributes);

  return builder;
};

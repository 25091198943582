import CheckIcon from '@assets/icons/check.svg?react';
import RemoveBase from '@assets/icons/remove.svg?react';
import { LazyMonthDatePicker as DatePicker } from '@parts/date-picker/date-picker.lazy';
import { FormikField } from '@parts/forms/formik-field/formik-field';
import { NumberInput as NumberInputBase } from '@parts/forms/number-input/number-input';
import { activeBorder } from '@parts/metrics-view-configure/parts/metric-item.styles';
import { Input as InputBase } from 'antd';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

export const NUMERIC_EDITOR_COLUMN_WIDTH = '130px';

const activeBackground = css`
  background-color: ${({ theme }) => theme.color.backgroundHover};
`;

const successBorder = css`
  border: 1px solid ${({ theme }) => theme.color.success};
`;

const Remove = styled(RemoveBase)<{ isDisabled?: boolean }>`
  width: 40px;
  height: auto;
  padding: ${({ theme }) => theme.spacing.xsmall};
  cursor: pointer;
  ${({ isDisabled }) =>
    isDisabled &&
    css`
      visibility: hidden;
      pointer-events: none;
    `}
`;

const MonthPicker = styled(DatePicker)<{ error: boolean }>`
  caret-color: transparent;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  ${({ error }) =>
    error &&
    css`
      border: 1px solid ${({ theme }) => theme.color.error} !important;
    `}

  &.ant-picker-focused {
    ${activeBackground};
  }
`;

const Field = styled(FormikField)`
  flex-shrink: 0;
  flex-grow: 0;
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const NoteField = styled(Field)`
  flex: auto;
  flex-grow: 1;
`;

const input = css`
  margin: 0;
  transition: none;
  border-radius: 0;

  &:focus,
  &:active {
    border: 0;
    ${activeBackground};
  }
  &.ant-input-disabled {
    opacity: 0.6;
  }
`;

const Input = styled(InputBase)`
  ${input}
`;

const NumberInput = styled(NumberInputBase)`
  ${input};
`;

const NoteInput = styled(Input)`
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const Check = styled(CheckIcon)`
  position: absolute;
  top: -15px;
  right: -15px;
  display: none;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.sectionBackground};
`;

const Wrapper = styled.div<{ active: boolean }>`
  display: flex;
  flex-wrap: nowrap;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    margin-top: ${({ theme }) => theme.spacing.xbase};

    & + & {
      padding-top: ${({ theme }) => theme.spacing.xbase};
      border-top: 1px solid ${({ theme }) => theme.color.border};
    }

    ${({ active }) =>
      active &&
      css`
        ${Remove} {
          align-self: flex-start;
          margin-top: 25px;
        }
      `}
  }
`;

const Fields = styled.div<{ active: boolean; justSaved: boolean; table: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  flex-direction: column;

  ${({ table, active, justSaved }) =>
    table
      ? css`
          flex-direction: row;

          ${Field} {
            flex-basis: ${NUMERIC_EDITOR_COLUMN_WIDTH};
            margin-bottom: 0;
          }

          ${active && activeBorder};

          ${justSaved &&
          css`
            border-radius: 4px;
            ${successBorder}

            ${Check} {
              display: block;
            }

            ${Field}, ${MonthPicker}, ${Input}, ${NumberInput} {
              background-color: ${({ theme }) => rgba(theme.color.success, 0.05)};
            }
          `}
        `
      : css`
          flex-direction: column;
          flex-grow: 1;

          ${MonthPicker}, ${Input}, ${NumberInput} {
            border-radius: 4px;
            transition: none;
            margin-bottom: ${({ theme }) => theme.spacing.xsmall};

            ${active && activeBorder};
          }
        `}};
}
`;

export default {
  Wrapper,
  Fields,
  Input,
  NumberInput,
  MonthPicker,
  Field,
  NoteField,
  Check,
  NoteInput,
  Remove,
};

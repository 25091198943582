import styled from 'styled-components';

const Wrapper = styled.header``;

const Title = styled.div``;

const Name = styled.h3`
  display: inline;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontSize.xmedium};
  color: ${({ theme }) => theme.color.activeMetricManagementTitle};
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const Description = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.activeMetricManagementDescription};
`;

export default { Title, Wrapper, Name, Description };

import * as Yup from 'yup';

export const useValidationSchema = () =>
  Yup.object({
    metrics: Yup.array().of(
      Yup.object()
        .shape({
          name: Yup.string(),
          min: Yup.number(),
          max: Yup.number().nullable(),
          filteringMode: Yup.string(),
        })
        .nullable(),
    ),
  });

import { V2Checkbox } from '@/components/ui/v2-checkbox/v2-checkbox';
import { V2InvisibleButton } from '@/components/ui/v2-invisible-button/v2-invisible-button';
import { V2Tooltip } from '@/components/ui/v2-tooltip/v2-tooltip';
import { useMediaQuery } from '@/hooks/use-media-query';
import type { GetReadinessScoreQueryResponseData } from '@/modules/founder/readiness-score/api/get-readiness-score.query';
import { useReadinessScoreModal } from '@/modules/founder/readiness-score/hooks/use-readiness-score-modal';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';

import S from './readiness-score-widget.styles';

type ReadinessScoreWidgetProps = { data: GetReadinessScoreQueryResponseData; ['data-testid']?: string };

const CheckboxWithTriggerButton = ({
  label,
  disabled,
  completed = false,
  onClick,
  checkboxSize,
  ['data-testid']: dataTestId = 'checkbox-with-trigger-button',
}: {
  label: string;
  disabled?: boolean;
  completed: boolean;
  onClick: () => void;
  checkboxSize: 'small' | 'large';
  ['data-testid']?: string;
}) => {
  const [editLL, completeLL] = useTranslation(['homepage.readinessScore.edit', 'homepage.readinessScore.complete']);
  return (
    <S.CheckboxGroupItemWrapper data-testid={dataTestId}>
      <V2Checkbox
        checked={completed}
        label={label}
        size={checkboxSize}
        disabled={disabled}
        readonly
        lineTroughOnCheck
        data-testid={`${dataTestId}-checkbox`}
      />
      {!disabled && (
        <S.Button
          variant="link"
          onClick={onClick}
          data-testid={`${dataTestId}-checkbox-label-action-${(completed ? editLL : completeLL).toLowerCase()}`}
        >
          {completed ? editLL : completeLL}
        </S.Button>
      )}
    </S.CheckboxGroupItemWrapper>
  );
};

export const ReadinessScoreWidget = ({
  data,
  ['data-testid']: testId = 'readiness-score-widget',
}: ReadinessScoreWidgetProps) => {
  const size = useMediaQuery();
  const { openReadinessScoreModal, readinessScoreModal } = useReadinessScoreModal('founder-readiness-score');
  const [title, paragraph, profileLabel, metricsLabel, teamLabel, marketFitLabel, whatIsThis] = useTranslation([
    'homepage.readinessScore.title',
    'homepage.readinessScore.paragraph',
    'homepage.readinessScore.profile',
    'homepage.readinessScore.metrics',
    'homepage.readinessScore.team',
    'homepage.readinessScore.marketFit',
    'homepage.readinessScore.whatIsThis',
  ]);

  const profile = data.profile;
  const team = data.team;
  const metrics = data.metrics;
  const marketFit = data.marketFit;

  const isProfileCompleted =
    profile.personalDetailsScore.received > 0 &&
    profile.smartMatchScore.received > 0 &&
    profile.uploadedFilesScore.received > 0 &&
    profile.personalDetailsScore.received === profile.personalDetailsScore.available &&
    profile.smartMatchScore.received === profile.smartMatchScore.available &&
    profile.uploadedFilesScore.received === profile.uploadedFilesScore.available;

  const isTeamCompleted =
    team.teamMembersScore.received > 0 && team.teamMembersScore.received === team.teamMembersScore.available;

  const isMetricsCompleted =
    metrics.hasScoring &&
    metrics.manualMetricsScore.received > 0 &&
    metrics.integratedMetricsScore.received > 0 &&
    metrics.dashboardScore.received > 0 &&
    metrics.manualMetricsScore.received === metrics.manualMetricsScore.available &&
    metrics.integratedMetricsScore.received === metrics.integratedMetricsScore.available &&
    metrics.dashboardScore.received === metrics.dashboardScore.available;

  const isMarketFitCompleted = marketFit.questions.answered === marketFit.questions.available;

  const progressbarSize = ['xs', 'sm'].includes(size) ? 'default' : 'large';
  const checkboxSize = ['xs', 'sm'].includes(size) ? 'small' : 'large';

  return (
    <S.Wrapper data-testid={testId}>
      <S.Head>
        <V2InvisibleButton onClick={() => openReadinessScoreModal()}>
          <S.Progressbar
            data-testid="homepage-tiles-readiness-score-progressbar"
            percent={data?.score}
            variant={'magenta'}
            size={progressbarSize}
          />
        </V2InvisibleButton>
        <S.TextWrapper>
          <V2InvisibleButton onClick={() => openReadinessScoreModal()}>
            <S.Title data-testid="homepage-tiles-readiness-score-title">{title}</S.Title>
          </V2InvisibleButton>
          <S.Paragraph>{paragraph}</S.Paragraph>
        </S.TextWrapper>
      </S.Head>
      <S.MobileParagraph>{paragraph}</S.MobileParagraph>
      <S.Line />
      <S.CheckboxGroup>
        <CheckboxWithTriggerButton
          label={profileLabel}
          completed={isProfileCompleted}
          onClick={() => openReadinessScoreModal({ key: 'profile' })}
          checkboxSize={checkboxSize}
          data-testid="homepage-tiles-readiness-score-profile-checkbox-profile"
        />
        <CheckboxWithTriggerButton
          label={teamLabel}
          completed={isTeamCompleted}
          onClick={() => openReadinessScoreModal({ key: 'team' })}
          checkboxSize={checkboxSize}
          data-testid="homepage-tiles-readiness-score-profile-checkbox-team"
        />
        <CheckboxWithTriggerButton
          label={metricsLabel}
          disabled={metrics.hasScoring === false}
          completed={isMetricsCompleted}
          onClick={() => openReadinessScoreModal({ key: 'metrics' })}
          checkboxSize={checkboxSize}
          data-testid="homepage-tiles-readiness-score-profile-checkbox-metrics"
        />
        <CheckboxWithTriggerButton
          label={marketFitLabel}
          completed={isMarketFitCompleted}
          onClick={() => openReadinessScoreModal({ key: 'marketFit' })}
          checkboxSize={checkboxSize}
          data-testid="homepage-tiles-readiness-score-profile-checkbox-market-fit"
        />
      </S.CheckboxGroup>
      <S.TooltipWrapper>
        <V2InvisibleButton
          onClick={() => openReadinessScoreModal()}
          data-testid="homepage-tiles-readiness-score-tooltip-button"
        >
          <V2Tooltip iconPosition="left" data-testid="homepage-tiles-readiness-score-tooltip">
            <S.TooltipLabel>{whatIsThis}</S.TooltipLabel>
          </V2Tooltip>
        </V2InvisibleButton>
      </S.TooltipWrapper>

      {readinessScoreModal}
    </S.Wrapper>
  );
};

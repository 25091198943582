import { V2Container } from '@/components/ui/v2-container/v2-container';
import { V2Title } from '@/components/ui/v2-title/v2-title';
import { V2FeedTile } from '@ui/v2-feed-tile/v2-feed-tile';
import type { V2CuratedFeedSectionProps } from './v2-curated-feed-section.types';

import S from './v2-curated-feed-section.styles';
import { V2Carousel } from '@/components/blocks/v2-carousel/v2-carousel';
import { useMediaQuery } from '@/hooks/use-media-query';

export const V2CuratedFeedSection = ({
  title,
  description,
  items,
  'data-testid': dataTestId = 'curated-feed-section',
  footer = null,
}: V2CuratedFeedSectionProps) => {
  const mediaQuery = useMediaQuery();
  const isMobile = ['xs', 'sm'].includes(mediaQuery);

  return (
    <V2Container>
      <S.CuratedFeed data-testid={dataTestId}>
        <S.Header>
          {title && <V2Title size="2xl" title={title} />}
          <p>{description}</p>
        </S.Header>

        {isMobile ? (
          <S.Grid>
            {items.map((item) => (
              <V2FeedTile key={item.title} {...item} />
            ))}
          </S.Grid>
        ) : (
          <V2Carousel stack="vertical">
            <V2Carousel.ItemsWrapper>
              {items.map((item) => (
                <V2Carousel.Slide key={item.title}>
                  <V2FeedTile key={item.title} {...item} />
                </V2Carousel.Slide>
              ))}
            </V2Carousel.ItemsWrapper>
            {items.length > 3 && <V2Carousel.StepIndicatorWithActions />}
          </V2Carousel>
        )}
      </S.CuratedFeed>

      {footer}
    </V2Container>
  );
};

import type { ReactNode } from 'react';
import S from './amount-range-slider.style';
import { ValueDisplay } from './parts/value-display';
import { useUserCurrency } from '@utils/hooks/use-user-currency/use-user-currency';

export interface RangerSliderProps {
  min: number;
  max: number;
  step: number;
  value: [number, number];
  onChange: (value: [number, number]) => void;
  onAfterChange?: (value: [number, number]) => void;
  displayValuePrefix?: string;
  disabled?: boolean;
  loading?: boolean;
  allowEditing?: boolean;
  tooltipVisible?: boolean;
  className?: string;
  valueDisplay?: ReactNode;
  'data-testid'?: string;
}

export const AmountRangeSlider = ({
  max,
  displayValuePrefix,
  tooltipVisible = false,
  className = '',
  valueDisplay,
  'data-testid': dataTestId,
  ...restProps
}: RangerSliderProps) => {
  const { commaize } = useUserCurrency();

  const tipFormatter = (v: number | undefined) => {
    const val = v ?? 0;

    const prefix = displayValuePrefix ? `${displayValuePrefix} ` : '';
    const formattedVal = commaize(val, true);

    if (val === max) {
      return `${prefix}${formattedVal} +`;
    }

    return `${prefix}${formattedVal}`;
  };

  return (
    <S.Wrapper className={className} data-testid={dataTestId}>
      {valueDisplay ?? <ValueDisplay displayValuePrefix={displayValuePrefix} max={max} {...restProps} />}
      <S.Slider max={max} tooltipVisible={tooltipVisible} tipFormatter={tipFormatter} range {...restProps} />
    </S.Wrapper>
  );
};

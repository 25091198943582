import { Logo } from '@parts/logo/logo';
import { Fragment, type ReactNode } from 'react';
import { PlainBackground } from '../parts/plain-background/plain-background.layout';
import { InnerBlock } from './block.layout';
import { GradientWrapper, LogoWrapper } from './center-block.styles';

interface Props {
  children: ReactNode;
  enlarged?: boolean;
  className?: string;
  gradient?: boolean;
}

const components = {
  gradient: GradientWrapper,
  empty: Fragment,
};

export const CenterBlock = ({ children, className, gradient, enlarged = false }: Props) => {
  const Container = components[gradient ? 'gradient' : 'empty'];

  return (
    <PlainBackground className={className}>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <Container>
        <InnerBlock enlarged={enlarged}>{children}</InnerBlock>
      </Container>
    </PlainBackground>
  );
};

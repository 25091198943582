import { useAuth0 } from '@auth0/auth0-react';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { REDIRECT_TO_PARAMETER_NAME } from '@router/utils/get-redirect-route';
import { useEffect } from 'react';

export const LoginPage = () => {
  const { loginWithRedirect, user, isLoading, isAuthenticated } = useAuth0();
  const redirectToPath = new URLSearchParams(window.location.search).get(REDIRECT_TO_PARAMETER_NAME) ?? '/';

  useEffect(() => {
    if (!isAuthenticated && !user && !isLoading) {
      setTimeout(() => {
        loginWithRedirect({
          authorizationParams: { screen_hint: 'signin' },
          appState: { returnTo: redirectToPath },
        });
      }, 500);
    }
  }, [isLoading]);

  return <FullHeightSpinner $background />;
};

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useParams } from 'react-router-dom';
import {
  getManagementTeamAction,
  GET_MANAGEMENT_TEAM_CACHE_KEY,
} from '../../api/management-team/get-management-team.action';
import S from './management-team.styles';
import { EmptyState } from './parts/empty-state/empty-state';
import { ManagementTeamTable } from './parts/table/table';

export const ManagementTeam = () => {
  const [headerLabel] = useTranslation(['portfolio.investor.managementTeam.header']);

  const { relationshipId } = useParams<{ relationshipId: string }>();

  const { data: response } = useQuery([GET_MANAGEMENT_TEAM_CACHE_KEY], getManagementTeamAction(relationshipId), {
    cacheTime: 0,
  });

  return (
    <S.Section
      header={headerLabel}
      cornerAddition={{
        content: <S.Counter value={response?.data?.filter((v) => !v.isDeletedByFounder).length} />,
        width: 50,
      }}
    >
      {response?.data?.[0] ? <ManagementTeamTable data={response.data} /> : <EmptyState />}
    </S.Section>
  );
};

import { TrashButton } from '@pages/content/profile/parts/trash-button/trash-button';
import useConfirmModal from '@utils/hooks/use-confirm-modal/use-confirm-modal';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';

const DeleteMetric = ({ onRemove }: { onRemove: () => void }) => {
  const [deleteContentLabel, deleteTitleLabel, confirmDeleteLabel] = useTranslation([
    'pulse.chart.delete.modal.content',
    'pulse.chart.delete.modal.title',
    'global.delete',
  ]);
  const { modal: confirmDeleteMetric, show: showConfirmDeleteMetric } = useConfirmModal({
    title: deleteTitleLabel,
    content: deleteContentLabel,
    onConfirm: onRemove,
    confirmLabel: confirmDeleteLabel,
  });
  return (
    <>
      <TrashButton onRemove={showConfirmDeleteMetric} />
      {confirmDeleteMetric}
    </>
  );
};
export { DeleteMetric };

import { axiosInstance } from '@utils/axios/axios-instance';
import type { AxiosConfig } from '@utils/axios/types';

export interface RegisterAdvisorActionPayloadAuth0 {
  firstName: string;
  lastName: string;
  email?: string;
  countryOfResidence: string;
  phoneNumber: string | null;
  invitationToken?: string;
  state?: string;
  registrationToken?: string;
}

export const registerAdvisorAction = async (payload: RegisterAdvisorActionPayloadAuth0) =>
  axiosInstance.post('/ned', payload, {
    skipAuthorization: true,
  } as AxiosConfig);

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_ADVISOR_PUBLISH_STATUS = 'get-ned-publish-status';

type ResponseData = {
  published: boolean;
  smartMatchShortages: string[];
};

export const getAdvisorPublishedStatus = async (): Promise<GraphqlResponse<ResponseData>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getAdvisorPublishedStatus {
        getNedSmartMatch {
          published
          smartMatchShortages
        }
      }
    `,
  });

  return graphqlResponseWrapper(data, ['getNedSmartMatch']);
};

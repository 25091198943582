import { PageHeader } from '@parts/page-header/page-header';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Inbox } from '../inbox/inbox';
import { V2Container } from '@/components/ui/v2-container/v2-container';

import S from './chat.styles';

export const Chat = () => {
  const [title] = useTranslation(['inbox.title']);

  return (
    <V2Container>
      <S.TitleWrapper>
        <PageHeader title={title} />
      </S.TitleWrapper>
      <Inbox />
    </V2Container>
  );
};

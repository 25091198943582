import { axiosInstance } from '@/utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@/utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export type SavedParamsForQueryKey = 'FilterNedsByFounderQuery' | 'FilterCompaniesByNedQuery';

export const GET_SAVED_PARAMS_FOR_QUERY_CACHE_KEY = 'get-saved-params-for-query';

export interface LastSearchDataResponseData {
  lastSearchData: string | null;
}

export const getSavedParamsForQuery =
  (queryType: string) => async (): Promise<GraphqlResponse<LastSearchDataResponseData>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
        query getSavedParamsForQuery {
          getSavedParamsForQuery(queryType: ${queryType}) {
            lastSearchData
          }
        }
    `,
    });

    return graphqlResponseWrapper(data, ['getSavedParamsForQuery']);
  };

export const savedParamsForQueryMock = {
  data: {
    getSavedParamsForQuery: {
      lastSearchData: null,
    },
  },
};

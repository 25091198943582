import S from './connection-alert.styles';

const ConnectionAlert = ({
  notConnectedAlertLabel,
  autoConnectAlertLabel,
}: {
  notConnectedAlertLabel: string;
  autoConnectAlertLabel: string;
}) => (
  <S.AutoConnectAlert
    showIcon
    message={
      <S.AutoConnectWrapper>
        <S.NotConnectedAlert>{notConnectedAlertLabel} </S.NotConnectedAlert>
        {autoConnectAlertLabel}
      </S.AutoConnectWrapper>
    }
    type="info"
  />
);

export default ConnectionAlert;

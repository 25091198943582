/* eslint-disable */
// @ts-nocheck

import { useEffect } from 'react';

export enum PENDO_EVENTS {
  TRACK_ADVISORS_FILTERS = 'trackAdvisorsFilters',
}

export type PendoEventPayloadMap = {
  [PENDO_EVENTS.TRACK_ADVISORS_FILTERS]: { role: string; industries: string[]; skillSets: string[] };
};

export const usePendo = () => {
  useEffect(() => {
    const apiKey = process.env.REACT_APP_PENDO_API_KEY;
    const domain = process.env.REACT_APP_PENDO_CUSTOM_DOMAIN || 'cdn.eu.pendo.io';

    if (apiKey) {
      (function (p, e, n, d, o) {
        var v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = o._q || [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w)
          (function (m) {
            o[m] =
              o[m] ||
              function () {
                o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
              };
          })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src = 'https://' + domain + '/agent/static/' + apiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
    }
  }, []);
};

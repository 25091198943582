import { EmptyState } from '@parts/empty-state/empty-state';
import styled from 'styled-components';

const WaitingScreen = styled(EmptyState)`
  h3 {
    margin: ${({ theme }) => theme.spacing.small} 0;
  }

  h4 {
    max-width: 450px;
  }
`;

export default { WaitingScreen };

import { V2Modal as V2ModalBase } from '@/components/ui/v2-modal/v2-modal';
import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import styled from 'styled-components';

const V2Modal = styled(V2ModalBase)`
  ${customMediaQuery('max-width', {
    sm: 'calc(100% - 45px * 2)',
  })}

  .ant-modal-content {
    overflow: visible;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border: 0 none;
  background-color: ${({ theme }) => theme.v2.colors.blue[100]};
  color: ${({ theme }) => theme.v2.colors.blue[400]};
  border-radius: 50%;
  cursor: pointer;

  &:disabled {
    opacity: 0.45;
    cursor: not-allowed;
  }
`;

const ButtonPrev = styled(Button)`
  position: absolute;
  top: -25px;
  left: 50px;
  transform: translate(-125%, -50%);

  ${customMediaQuery('top', {
    sm: '50%',
  })}
  ${customMediaQuery('left', {
    sm: '0',
  })}
`;

const ButtonNext = styled(Button)`
  position: absolute;
  top: -25px;
  right: 50px;
  transform: translate(125%, -50%);

  ${customMediaQuery('top', {
    sm: '50%',
  })}
  ${customMediaQuery('right', {
    sm: '0',
  })}
`;

const ContentWrapper = styled.div`
  max-height: calc(100vh - 100px);
  overflow-y: auto;
`;

export default { V2Modal, ButtonPrev, ButtonNext, ContentWrapper };

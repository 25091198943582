import * as Yup from 'yup';

export const AddExperienceFormSchema = Yup.object({
  name: Yup.string().required('Company name is required.').max(100, 'Company name must be at most 100 characters'),
  role: Yup.string().max(50, 'Role must be at most 50 characters').required('Role is required'),
  industry: Yup.array(Yup.string().required('Industry is required'))
    .min(1, 'At least one industry is required')
    .nullable(),
  email: Yup.string()
    .email('Invalid email format')
    .nullable()
    .test('is-valid-email', 'Invalid email format', (value) => {
      if (value === null || value === undefined || value === '') return true;
      return Yup.string().email().isValidSync(value);
    }),
  startDate: Yup.string()
    .required('Start date is required')
    .test('startDate-before-endDate', 'Start date must be before end date', function (value) {
      const { endDate, isOngoing } = this.parent;
      if (!isOngoing && value && endDate) return new Date(value) < new Date(endDate);
      return true;
    }),
  endDate: Yup.string()
    .nullable()
    .when('isOngoing', {
      is: false,
      then: Yup.string().required('End date is required if the experience is not ongoing'),
    }),
  isOngoing: Yup.boolean().required('Ongoing status is required'),
  compensationType: Yup.string().nullable().optional(),
  skillSet: Yup.array(Yup.string().required('Skill is required')).min(1, 'At least one skill is required'),
  experienceSummary: Yup.string().max(500, 'Experience summary must be at most 500 characters').nullable(),
});

export type AddExperienceFormSchemaType = Yup.InferType<typeof AddExperienceFormSchema>;

export type AddExperienceState = {
  companyId?: string;
  companyDetails?: {
    businessName: string;
    industry: string[];
  };
  formData: AddExperienceFormSchemaType;
};

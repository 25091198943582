import * as H from 'history';
import queryString, { type Stringifiable } from 'query-string';

export type Search = object & {
  filters?: Stringifiable[];
  sort?: Stringifiable[];
  metrics?: Stringifiable[];
  regions?: Stringifiable[];
  countriesOfResidence?: Stringifiable[];
};

export const applySearchToQuery = ({
  initialQuery,
  search: searchValues,
  history,
  replace = false,
}: {
  initialQuery: queryString.ParsedQuery<string>;
  search: Search;
  history: H.History;
  replace?: boolean;
}) => {
  const action = replace ? history.replace : history.push;
  action({
    search: `?${queryString.stringify({
      ...initialQuery,
      ...{
        ...searchValues,
        ...(searchValues.filters && {
          filters: JSON.stringify(searchValues.filters),
        }),
        ...(searchValues.sort && {
          sort: JSON.stringify(searchValues.sort),
        }),
        ...(searchValues.metrics && {
          metrics: JSON.stringify(searchValues.metrics),
        }),
        ...(searchValues.regions && {
          regions: JSON.stringify(searchValues.regions),
        }),
        ...(searchValues.countriesOfResidence && {
          countriesOfResidence: JSON.stringify(searchValues.countriesOfResidence),
        }),
      },
    })}`,
  });
};

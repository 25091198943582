import { conversationQueryParam } from '@pages/content/messaging/parts/inbox/inbox';
import message from '@parts/message/message';
import { Routes } from '@router/routes';
import { useMutation, type MutationFunction } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useHistory } from '@utils/hooks/use-history/use-history';

import { engageFounderMessagingAction } from '@/pages/content/lens/api/message-engage-founder';
import { engageInvestorMessagingAction } from '@/pages/content/lens/api/message-engage-investor';
import { engageFounderMessagingAction as nedEngageFounderMessagingAction } from '@/pages/content/lens/api/message-ned-engage-founder';
import { engageNedMessagingAction } from '@/pages/content/roles/parts/applications/api/message-engage-ned';

export const universalEngageConversationAction = async (anyId: string) => {
  try {
    return await Promise.any([
      engageNedMessagingAction(anyId),
      nedEngageFounderMessagingAction(anyId),
      engageInvestorMessagingAction(anyId),
      engageFounderMessagingAction(anyId),
    ]);
  } catch (error) {
    message.error({ content: 'All engagement actions failed' });
    throw new Error('All engagement actions failed');
  }
};

export const useEngageConversation = (action: MutationFunction<{ data: string }, string>, openInNewCard = false) => {
  const history = useHistory();

  const { mutateAsync, isLoading } = useMutation(action, {
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
    onSuccess: (res) => {
      if (openInNewCard) {
        const url = new URL(window.location.origin);
        url.pathname = Routes.MESSAGING;
        url.searchParams.set(conversationQueryParam, res.data);

        window.open(url.toString(), '_blank');
        return;
      }

      history.push({
        search: `${conversationQueryParam}=${res.data}`,
        pathname: Routes.MESSAGING,
      });
    },
  });

  return { mutateAsync, isLoading };
};

import * as Yup from 'yup';

export const useValidation = () => {
  return Yup.object({
    industry: Yup.array().of(Yup.string()),
    customerGroup: Yup.array().of(Yup.string()),
    productStage: Yup.array().of(Yup.string()),
    location: Yup.array().of(Yup.string()),
    regions: Yup.array().of(Yup.string()),
    countriesOfResidence: Yup.array().of(Yup.string()),
  });
};

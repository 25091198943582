export enum TargetGeographyValue {
  UK = 'UK',
  Europe = 'Europe',
  NorthAmerica = 'N. America',
  SouthAmerica = 'S. America',
  MENA = 'MENA',
  SubSaharanAfrica = 'Sub-Saharan Africa',
  APAC = 'APAC',
  Global = 'Global',
}
export const TARGET_GEOGRAPHIES_OPTIONS = Object.values(TargetGeographyValue);

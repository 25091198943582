import {
  GET_METRICS_ACTION_CACHE_KEY,
  getFounderMetricsAction,
} from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import {
  addDashboardElementsAction,
  type AddDashboardElementsType,
} from '@pages/content/pulse/parts/dashboards/api/add-metric/add-dashboard-elements.action';
import { SearchableMetrics } from '@pages/content/pulse/parts/dashboards/parts/dashboard/add-metric/parts/searchable-metrics';
import { useValidationSchema } from '@pages/content/pulse/parts/dashboards/parts/dashboard/add-metric/validation-schema';
import { useDrawer } from '@parts/drawer/use-drawer';
import { FormikField } from '@parts/forms/formik-field/formik-field';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import message from '@parts/message/message';
import OnTopFooter from '@parts/on-top-footer/on-top-footer';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useFormik } from 'formik';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { addMetricParam } from '../../../founder-dashboards.layout';
import { dashboardIdParam } from '../../dashboards-menu/dashboards-menu';

export const AddDashboardElements = ({
  dashboardId,
  dashboardDetailsQueryKeyCache,
}: {
  dashboardId: string;
  dashboardDetailsQueryKeyCache: string | unknown[];
}) => {
  const { data: response, isLoading } = useQuery([GET_METRICS_ACTION_CACHE_KEY], getFounderMetricsAction);

  const history = useHistory();
  const onClose = () => {
    const parsedLocationSearch = {
      ...queryString.parse(window.location.search),
      [dashboardIdParam]: dashboardId,
      [addMetricParam]: undefined,
    };

    history.push(`?${queryString.stringify(parsedLocationSearch)}`);
  };
  const { Drawer } = useDrawer(true);

  const [addLabel, cancelLabel, metricAddedLabel] = useTranslation([
    'pulse.dashboard.addMetric',
    'global.cancel',
    'pulse.dashboard.addMetric.success',
  ]);
  const queryClient = useQueryClient();

  const { mutateAsync: addDashboardElements, isLoading: addDashboardElementsLoading } = useMutation(
    addDashboardElementsAction(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          Array.isArray(dashboardDetailsQueryKeyCache)
            ? [...dashboardDetailsQueryKeyCache, dashboardId]
            : [dashboardDetailsQueryKeyCache, dashboardId],
        );
        message.success({
          content: metricAddedLabel,
        });
        onClose();
      },
      onError: (err: AxiosError) => {
        message.error({ content: getServerError(err) });
      },
    },
  );

  const formik = useFormik<AddDashboardElementsType>({
    onSubmit: ({ metrics, addTextBox }) => addDashboardElements({ addTextBox, metrics, dashboardId }),
    initialValues: {
      metrics: [],
      addTextBox: false,
    },
    validateOnMount: true,
    validationSchema: useValidationSchema(),
  });
  if (isLoading || !response || !response.data) return <FullHeightSpinner />;

  return (
    <Drawer
      title={addLabel}
      onClose={onClose}
      footer={
        <OnTopFooter
          data-testid="add-metric"
          confirmLabel={addLabel}
          cancelLabel={cancelLabel}
          onConfirm={() => {
            formik.submitForm();
          }}
          onCancel={onClose}
          disabled={!formik.isValid}
          reverseButtons
          loading={addDashboardElementsLoading}
        />
      }
    >
      <FormikField {...formik.getFieldMeta('metrics')} datatestid="create-visibility">
        <SearchableMetrics fieldName="metrics" setFieldValue={formik.setFieldValue} metrics={response.data} />
      </FormikField>
    </Drawer>
  );
};

import LogoSvg from '@assets/brand/logo.svg?react';
import Blob from '@assets/illustrations/blob.svg?react';
import { Spin } from 'antd';
import { BlobContainer, LogoContainer, StyledContainer, TextContainer } from './loading.page.styles';

interface LoadingPageProps {
  message: string;
}

export const LoadingPage = ({ message }: LoadingPageProps) => (
  <StyledContainer>
    <BlobContainer>
      <Blob />
    </BlobContainer>
    <LogoContainer>
      <LogoSvg />
    </LogoContainer>
    <TextContainer>
      {message}
      <Spin />
    </TextContainer>
  </StyledContainer>
);

import { useQuery } from '@tanstack/react-query';
import {
  HOMEPAGE_QUALIFYING_QUESTIONS_STATE,
  getQualifyingQuestionsData,
} from '../api/get-founder-qualifying-questions-state';
import { OnboardingStatus } from '@/domain/accounts/types';

export const useGetFounderQualifyingQuestionsState = () => {
  const query = useQuery([HOMEPAGE_QUALIFYING_QUESTIONS_STATE], getQualifyingQuestionsData);

  const qualifyingQuestionsState = query.data?.data;

  const isFinished =
    qualifyingQuestionsState?.onboardingStatus.founderQualifyingQuestions === OnboardingStatus.Finished;
  const isPending = qualifyingQuestionsState?.onboardingStatus.founderQualifyingQuestions === OnboardingStatus.Pending;
  const isNotApplicable =
    qualifyingQuestionsState?.onboardingStatus.founderQualifyingQuestions === OnboardingStatus.NotApplicable;

  return {
    ...query,
    isQualifyingQuestionsFinished: isFinished,
    isQualifyQuestionsPending: isPending,
    isQualifyQuestionsNotApplicable: isNotApplicable,
    qualifyingQuestionsState: query.data?.data,
    isQualifyingQuestionsStateLoading: query.isLoading,
  };
};

import message from '@/parts/message/message';
import { getServerError } from '@/utils/fns/get-server-error';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { bookmarkNedAction, unbookmarkNedAction } from '../api/bookmark-ned.action';

export const useAdvisorBookmark = (advisorId: string, isBookmarked: boolean, onSuccess: () => void) => {
  const [saveSuccessLabel, unsaveSuccessLabel] = useTranslation(['message.saved.success', 'message.unsaved.success']);
  const action = isBookmarked ? unbookmarkNedAction(advisorId) : bookmarkNedAction(advisorId);

  const { mutate, mutateAsync, isLoading, ...rest } = useMutation(action, {
    onSuccess: () => {
      message.success({ content: isBookmarked ? unsaveSuccessLabel : saveSuccessLabel });
      if (onSuccess) onSuccess();
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  return {
    ...rest,
    handleBookmark: mutateAsync,
    isBookmarkPending: isLoading,
  };
};

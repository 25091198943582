import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_READINESS_SCORE_CACHE_KEY = 'get-readiness-score-cache-key';

export interface ReadinessScoreResponseData {
  score: number | null;
}

export const getReadinessScore = async (): Promise<GraphqlResponse<ReadinessScoreResponseData>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getReadinessScore {
        readinessScore {
          score
        }
      }
    `,
  });

  return graphqlResponseWrapper(data, ['readinessScore']);
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import { mapQueryStringToGraphqlParams } from '@utils/fns/map-query-string-to-graphql-params';
import { ConnectionType } from '@utils/type-definitions/connections';
import type { ParsedQuery } from 'query-string';
import type { GraphqlResponse } from 'src/types';

export interface FollowRequestDetails {
  id: string;
  type: ConnectionType;
}

export interface InvestorBrowseCompaniesItems {
  id: string;
  customerGroup: string[];
  description: string;
  industry: string;
  investmentStage: string;
  joinedAt: string;
  name: string;
  productStage: string;
  logoUrl: string;
  isNew: boolean;
  followRequest: FollowRequestDetails | null;
}

export interface GetInvestorBrowseCompanies {
  items: InvestorBrowseCompaniesItems[];
  total: number;
}

const FILTER_COMPANIES_SORT_OPTIONS_GRAPHQL_TYPE = 'FilterCompaniesSortOptions!';

export const GET_INVESTOR_BROWSE_COMPANIES_CACHE_KEY = 'get-investor-browse-companies';

export const getInvestorBrowseCompanies =
  (start: number, limit: number, excludedParams: string[], otherParams?: ParsedQuery<string>) =>
  async (): Promise<GraphqlResponse<GetInvestorBrowseCompanies>> => {
    const params =
      otherParams &&
      mapQueryStringToGraphqlParams(otherParams, excludedParams, false, FILTER_COMPANIES_SORT_OPTIONS_GRAPHQL_TYPE);

    const queryName = `investorBrowseCompanies(start: ${start}, limit: ${limit}${params?.supplement})`;

    const { data } = await axiosInstance.post('/query', {
      variables: params?.variables,
      query: `
    query getInvestorBrowseCompanies${params?.arguments} {
        ${queryName} {
            items {
                id
                customerGroup
                description
                industry
                investmentStage
                joinedAt
                name
                productStage
                logoUrl
                isNew
                followRequest { 
                    id
                    type
                }
            }
            total
      }
    }
  `,
    });

    return graphqlResponseWrapper(data, ['investorBrowseCompanies']);
  };

import { getFundingHistory, GET_FUNDING_HISTORY_CACHE_KEY } from '@pages/content/api/get-funding-history.action';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { FundingHistoryTable } from './table/table';

export const FundingHistory = () => {
  const { relationshipId } = useParams<{ relationshipId: string }>();

  const { data: response, isLoading } = useQuery(
    [GET_FUNDING_HISTORY_CACHE_KEY(relationshipId)],
    getFundingHistory(relationshipId),
  );

  return <FundingHistoryTable data={response?.data || []} loading={isLoading} />;
};

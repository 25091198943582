import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export interface SingleRole {
  id: string;
  roleTitle: string;
  companyName: string;
  companyId: string;
  companyLogoUrl: string;
  description: string;
  location: string;
  compensation: string;
  roleType: string;
  engagement: string;
  experience: string;
  skillSet: string[];
  placedAt: string;
  aboutCompany: string;
  expiresAt: string;
  referenceNumber: string;
  isProfileCvUploaded: boolean;
  isApplied: boolean;
  isNew: boolean;
  file: {
    name: string | null;
  };
}

export const GET_ROLE_DETAILS_CACHE_KEY = 'get-role-details';

export const getRoleDetails = (roleId: string) => async (): Promise<GraphqlResponse<SingleRole>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getRoleDetails {
        previewRoleByNed(roleId: "${roleId}") {
          id
          roleTitle,
          companyName,
          companyId,
          companyLogoUrl,
          description,
          location,
          compensation,
          roleType,
          engagement,
          experience,
          skillSet,
          placedAt,
          aboutCompany,
          expiresAt,
          referenceNumber,
          isProfileCvUploaded,
          isApplied,
          isNew,
          file {
            name,
            url
          }
        }
      }
    `,
  });

  return graphqlResponseWrapper(data, ['previewRoleByNed']);
};

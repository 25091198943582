import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const V2BigBoyMultiSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 480px;
  margin: 32px auto 0 auto;

  ${customMediaQuery('gap', {
    xs: '12px 12px',
    md: '12px 24px',
  })}
`;

const Text = styled('p')`
  display: block;
  white-space: wrap;
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[300], theme.v2.colors.blue[200])};

  font-size: ${({ theme }) => theme.v2.typography.sizes.sm};
`;

export default { V2BigBoyMultiSelect, ButtonWrapper, Text };

import {
  GET_INVESTOR_PUBLISH_STATUS_CACHE_KEY,
  getInvestorPublishStatus,
} from '@pages/content/api/get-investor-publish-status.action';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { MessageSymbol } from '@parts/message-symbol/message-symbol';
import { SubmitButton } from '@parts/submit-button/submit-button';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Tooltip } from 'antd';
import { CtaWrapper } from './connection-manager.styles';

interface InvestorNotConnectedProps {
  send: (id: string) => Promise<{ data: string }>;
  message: (id: string) => Promise<{ data: string }>;
  acceptingInProgress: boolean;
  counterpartId: string;
  canConnect?: boolean;
  isMessageLoading?: boolean;
}

export const InvestorSmartMatchCheck = ({
  send,
  message,
  acceptingInProgress,
  counterpartId,
  canConnect = true,
  isMessageLoading,
}: InvestorNotConnectedProps) => {
  const [enableSmartMatchLabel, connectLabel] = useTranslation([
    'profile.preview.investor.smartMatch',
    'profile.preview.founder.connect',
  ]);

  const { data: response, isLoading } = useQuery([GET_INVESTOR_PUBLISH_STATUS_CACHE_KEY], getInvestorPublishStatus);

  if (isLoading || !response || !response.data) return <FullHeightSpinner />;
  const { published } = response.data;
  return (
    <Tooltip title={!published ? enableSmartMatchLabel : ''}>
      <CtaWrapper>
        <SubmitButton
          data-testid="public-profile-smart-match-connect"
          disabled={acceptingInProgress || !published || !canConnect}
          onClick={() => send(counterpartId)}
        >
          {connectLabel}
        </SubmitButton>
        <SubmitButton
          data-testid="public-profile-smart-match-not-connected-message"
          disabled={!published || isMessageLoading}
          type="link"
          onClick={() => message(counterpartId)}
        >
          <MessageSymbol />
        </SubmitButton>
      </CtaWrapper>
    </Tooltip>
  );
};

import { V2Pagination } from '@/components/blocks/v2-pagination/v2-pagination';
import { V2Alert } from '@/components/ui/v2-alert/v2-alert';
import { V2Loader } from '@/components/ui/v2-loader/v2-loader';
import { useMediaQuery } from '@/hooks/use-media-query';
import { useUrlFilters } from '@/hooks/use-url-filters';
import { TranslationText } from '@/parts/translation-text/translation-text';
import type { Sorter } from '@/utils/type-definitions/filters';
import { useState } from 'react';
import { useGetAdvisorConnections } from '../../hooks/use-get-advisor-connections';
import { AdvisorConnectionsMobileList } from './connections-mobile-list';
import { AdvisorConnectionsTable } from './connections-table';
import { AdvisorConnectionsFilters } from './filters';

import S from '../../connections-page.styles';

export const AdvisorConnections = () => {
  const size = useMediaQuery();
  const isMobile = ['sm', 'xs'].includes(size);

  const [sorting, setSorting] = useState<Sorter | undefined>(undefined);
  const { filterValueSingle, apply } = useUrlFilters<'phrase'>(['phrase']);

  const { advisorConnectionsData, pagination, isAdvisorConnectionsDataLoading, refetch } = useGetAdvisorConnections({
    searchByName: filterValueSingle('phrase'),
    sort: sorting,
  });

  if (isAdvisorConnectionsDataLoading && !advisorConnectionsData?.data) return <V2Loader height="320px" />;
  if (!advisorConnectionsData?.data)
    return <V2Alert variant="error" title="Error loading data" description="Please try again later" showIcon />;

  return (
    <S.TabWrapper data-testid="advisor-connections-table">
      <AdvisorConnectionsFilters
        disabled={isAdvisorConnectionsDataLoading}
        initialSearchValue={filterValueSingle('phrase')}
        onSearchSubmit={(v) => apply({ phrase: v })}
        sortValue={sorting}
        sortOptions={[{ label: <TranslationText id="connections.advisor.sortBy.name" />, key: 'businessName' }]}
        submit={(v) => {
          setSorting(v.sort ? v.sort : undefined);
          apply({ phrase: v.search });
        }}
      />

      {isMobile ? (
        <AdvisorConnectionsMobileList data={advisorConnectionsData.data.items} refetch={refetch} />
      ) : (
        <AdvisorConnectionsTable
          data={advisorConnectionsData.data.items}
          sorting={sorting}
          setSorting={setSorting}
          refetch={refetch}
        />
      )}
      <V2Pagination current={pagination.page} total={pagination.totalPages}>
        <V2Pagination.PreviousPageButton onClick={pagination.goToPreviousPage} />
        <V2Pagination.DisplayCurrentPageNumber />
        <V2Pagination.NextPageButton onClick={pagination.goToNextPage} />
      </V2Pagination>
    </S.TabWrapper>
  );
};

import styled from 'styled-components';

const ProgressBar = styled.div<{ $width: number }>`
  height: 100%;
  width: ${({ $width }) => `${$width}%`};
  background: ${({ theme }) => theme.gradient.neon};
  transition: width ease-in-out 500ms;
`;

const ProgressBarRail = styled.div`
  position: absolute;
  z-index: 1000;
  left: 0;
  top: 70px /* nav height */;
  height: 8px;
  width: 100%;
  background-color: ${({ theme }) => theme.onboardingColors.progressBarRail};
`;

export default {
  ProgressBar,
  ProgressBarRail,
};

import { useRef } from 'react';

export function useDelayFunction(callback: Function, delay = 1000) {
  const flashTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const callbackWithTimeDelay = () => {
    if (flashTimeout.current) clearTimeout(flashTimeout.current);

    flashTimeout.current = setTimeout(() => {
      callback();
    }, delay);
  };

  return callbackWithTimeDelay;
}

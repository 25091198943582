import { V2Pagination } from '@/components/blocks/v2-pagination/v2-pagination';
import { V2Alert } from '@/components/ui/v2-alert/v2-alert';
import { V2Loader } from '@/components/ui/v2-loader/v2-loader';
import { useMediaQuery } from '@/hooks/use-media-query';
import { useUrlFilters } from '@/hooks/use-url-filters';
import { TranslationText } from '@/parts/translation-text/translation-text';
import type { Sorter } from '@/utils/type-definitions/filters';
import { useState } from 'react';
import { useGetFounderConnectionsQuery } from '../../hooks/use-get-founder-connections-query';
import type { FounderConnectionType } from '../../types';
import { FounderConnectionsFilters } from '../filters';
import { ConnectionsMobileList } from './connections-mobile-list';
import { ConnectionsTable } from './connections-table';

import S from '../../connections-page.styles';

const clearInvalidConnectionTypeFilter = (value: string): FounderConnectionType => {
  const validConnectionTypes: FounderConnectionType[] = ['Advisor', 'Investor', 'Both'];
  return validConnectionTypes.find((item) => item === value) ?? 'Both';
};

export const Connections = () => {
  const size = useMediaQuery();
  const isMobile = ['sm', 'xs'].includes(size);

  const [sorting, setSorting] = useState<Sorter | undefined>(undefined);
  const { filterValueSingle, apply } = useUrlFilters<'connectionType' | 'phrase'>(['connectionType', 'phrase']);

  const { founderConnectionsData, isFounderConnectionsDataLoading, pagination, refetch } =
    useGetFounderConnectionsQuery({
      sort: sorting,
      search: filterValueSingle('phrase'),
      connectionType: clearInvalidConnectionTypeFilter(filterValueSingle('connectionType')),
    });

  if (isFounderConnectionsDataLoading && !founderConnectionsData?.data) return <V2Loader height="320px" />;
  if (!founderConnectionsData?.data)
    return <V2Alert variant="error" title="Error loading data" description="Please try again later" showIcon />;

  return (
    <S.TabWrapper data-testid="founder-connections-table">
      <FounderConnectionsFilters
        disabled={isFounderConnectionsDataLoading}
        filters={[
          { value: 'Both', label: <TranslationText id="connections.founder.filters.both" /> },
          { value: 'Investor', label: <TranslationText id="connections.founder.filters.investors" /> },
          { value: 'Advisor', label: <TranslationText id="connections.founder.filters.advisors" /> },
        ]}
        activeFilter={[filterValueSingle('connectionType')]}
        onFilterChange={(v) => apply({ connectionType: v, phrase: filterValueSingle('phrase') })}
        initialSearchValue={filterValueSingle('phrase')}
        onSearchSubmit={(v) => apply({ phrase: v, connectionType: [filterValueSingle('connectionType')] })}
        sortValue={sorting}
        sortOptions={[
          { label: <TranslationText id="connections.founder.sortBy.name" />, key: 'name' },
          { label: <TranslationText id="connections.founder.sortBy.closeConnection" />, key: 'isCloseConnection' },
        ]}
        submit={(v) => {
          setSorting(v.sort ? v.sort : undefined);
          apply({ connectionType: v.filters, phrase: v.search });
        }}
      />

      {isMobile ? (
        <ConnectionsMobileList data={founderConnectionsData.data?.items} refetch={refetch} />
      ) : (
        <ConnectionsTable
          data={founderConnectionsData.data?.items}
          sorting={sorting}
          setSorting={setSorting}
          refetch={refetch}
        />
      )}

      <V2Pagination current={pagination.page} total={pagination.totalPages}>
        <V2Pagination.PreviousPageButton onClick={pagination.goToPreviousPage} />
        <V2Pagination.DisplayCurrentPageNumber />
        <V2Pagination.NextPageButton onClick={pagination.goToNextPage} />
      </V2Pagination>
    </S.TabWrapper>
  );
};

import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';
import type { V2CarouselProps } from './v2-carousel.types';
import { V2InvisibleButton } from '@/components/ui/v2-invisible-button/v2-invisible-button';

const Carousel = styled.section<{ $stack: V2CarouselProps['stack'] }>`
  flex-grow: 1;
  width: 100%;
  margin: auto;
  display: flex;
  overflow-y: visible;
  overflow-x: hidden;
  align-items: center;
  flex-direction: ${({ $stack }) => ($stack === 'vertical' ? 'column' : 'row')};
  gap: ${({ $stack }) => ($stack === 'vertical' ? '20px' : '10px')};

  @media (max-width: ${({ theme }) => theme.v2.breakpoints.md}) {
    padding: 0px;
    margin-bottom: -20px;
  }
`;

const Viewport = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
`;

const IconButton = styled(V2InvisibleButton)`
  background-color: inherit;
  border: none;
  height: 30px;
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.blue[300])};
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.v2.breakpoints.md}) {
    display: none;
  }

  &:hover,
  &:focus,
  &:active {
    color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.blue[300])};
  }

  &:disabled {
    color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[400], theme.v2.colors.blue[400])};
    cursor: not-allowed;

    &:hover,
    &:focus,
    &:active {
      color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[400], theme.v2.colors.blue[400])};
    }
  }
`;

const Slide = styled.div`
  flex: 0 0 32%;
  padding: 0 0;

  @media (max-width: ${({ theme }) => theme.v2.breakpoints.md}) {
    flex: 0 0 100%;

    &:first-child {
      flex: 0 0 70%;
    }
  }
`;

const BottomNavigationPanel = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
`;

const Dots = styled.div`
  display: flex;
  gap: 5px;
`;

const Dot = styled.button<{ $isSelected: boolean }>`
  margin: 0px;
  padding: 0px;
  border: none;
  width: 10px;
  height: 10px;
  background: ${({ theme, $isSelected }) =>
    $isSelected
      ? theme.v2.colors.blue[300]
      : themedStyle(theme.v2.mode, theme.v2.colors.blue[100], theme.v2.colors.blue[400])};
  border-radius: 100%;
`;

export default { Carousel, Viewport, Container, IconButton, Slide, BottomNavigationPanel, Dots, Dot };

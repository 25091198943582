import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_SKILL_SETS_REQUESTS_CACHE_KEY = 'get-skill-sets';

export const getSkillSetsOptionAction = async (): Promise<GraphqlResponse<string[]>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
    query getSmartMatchOptions {
        skillSets
    }
  `,
  });

  return graphqlResponseWrapper(data, ['skillSets']);
};

import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const PanelBaseStyles = css`
  position: relative;
  display: flex;
  width: 100%;
  padding: 16px 50px 16px 0;
  border-radius: ${({ theme }) => theme.v2.borders.radius.sm};
  border-bottom: 1px solid
    ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[100], theme.v2.colors.blue[500])};
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[700], theme.v2.colors.neutral[50])};
  text-decoration: none;
`;

const V2InfoPanelLink = styled(Link)`
  ${PanelBaseStyles}

  &:hover,
  &:focus,
  &:active {
    color: inherit;
    text-decoration: none;
  }

  &:focus {
    outline: 1px solid ${({ theme }) => theme.v2.colors.blue[200]};
    outline-offset: 1px;
  }
`;

const V2InfoPanel = styled.div`
  ${PanelBaseStyles}
`;

const IconWrapper = styled.div`
  margin-right: 12px;
`;

const DescriptionWrapper = styled.div``;

const Title = styled.h4`
  font-size: ${({ theme }) => theme.v2.typography.sizes.sm};
  font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
  line-height: 1.25;
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[800], theme.v2.colors.neutral[50])};

  ${({ theme }) =>
    customMediaQuery('font-size', {
      md: theme.v2.typography.sizes.md,
    })}
`;

const Subtitle = styled.p`
  margin-top: 4px;
  font-size: ${({ theme }) => theme.v2.typography.sizes.xs};
  font-weight: ${({ theme }) => theme.v2.typography.weights.medium};
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[600], theme.v2.colors.neutral[400])};

  ${({ theme }) =>
    customMediaQuery('font-size', {
      md: theme.v2.typography.sizes.sm,
    })}
`;

const PanelActionWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.v2.colors.blue[400]};
`;

export default {
  V2InfoPanel,
  V2InfoPanelLink,
  IconWrapper,
  DescriptionWrapper,
  Title,
  Subtitle,
  PanelActionWrapper,
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export interface InvestmentTermsData {
  id: string;
  company: {
    name: string;
  };
  terms: {
    warrants: {
      amount: number | null;
      price: number | null;
      expiry: Date | null;
    };
    options: string | null;
    convertibleLoanNote: string | null;
    discount: number | null;
  };
  round: {
    date: Date;
    type: string;
  };
}

export const GET_INVESTMENT_TERMS_CACHE_KEY = (investmentId: string) => `${investmentId}-investment-terms`;

export const getInvestmentTermsAction =
  (investmentId: string) => async (): Promise<GraphqlResponse<InvestmentTermsData>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
      query getInvestmentTerms {
        investmentTerms(investmentId: "${investmentId}") {
          id
          company {
            name
          }
          round {
            type
            date
          }
          terms {
            warrants {
              amount
              price
              expiry
            }
            options
            convertibleLoanNote
            discount
          }
        }
      }
    `,
    });

    return graphqlResponseWrapper(data, ['investmentTerms']);
  };

import FileName from '../file-name/file-name';

import useConfirmModal from '@utils/hooks/use-confirm-modal/use-confirm-modal';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './uploaded-file.styles';

const UploadedFile = ({
  handleDelete,
  fileName,
  fileUrl,
  className,
}: {
  handleDelete: () => void;
  fileName: string;
  className?: string;
  fileUrl?: string;
}) => {
  const confirmConfirmDeleteModal = () => {
    handleDelete();
  };

  const [header, confirm] = useTranslation(['confirmModal.file.remove.header', 'confirmModal.file.remove.confirm']);

  const { modal: confirmDeleteModal, show: showConfirmDeleteModal } = useConfirmModal({
    title: header,
    content: confirm,
    onConfirm: confirmConfirmDeleteModal,
  });

  return (
    <S.Wrapper className={className}>
      {confirmDeleteModal}
      <FileName name={fileName} />
      <S.Actions>
        {fileUrl && (
          <S.Button type="link" data-testid="file-uploader-download-file" href={fileUrl} download>
            <S.DownloadFile />
          </S.Button>
        )}

        <S.Button type="link" data-testid="file-uploader-confirm-remove" onClick={showConfirmDeleteModal}>
          <S.RemoveFile />
        </S.Button>
      </S.Actions>
    </S.Wrapper>
  );
};

export default UploadedFile;

import { matchPath } from 'react-router';
export enum AdminRoutes {
  LOGIN_AS_USER = '/login-as-user',
}

export enum Routes {
  HOME = '/',
  START_PAGE = '/start',
  PULSE = '/pulse/:content',
  LOGIN = '/login',

  REGISTER = '/register',
  REGISTER_INVITED_INVESTOR = '/investor/invitation-registration',
  REGISTER_INVITED_FOUNDER = '/founder/invitation-registration',
  REGISTER_ROLE = '/register/:role?',
  REDIRECT_TO_REGISTER = '/redirect-to-register',
  LEGAL = '/legal',
  PORTFOLIO = '/portfolio',
  PORTFOLIO_COMPANY_DETAILS = '/portfolio/company-details/:relationshipId',
  PORTFOLIO_EXPANDED_VIEW = '/portfolio/expanded-view',
  PORTFOLIO_EXPANDED_VIEW_SINGLE = '/portfolio/expanded-view/:id',
  CHECKOUT = '/checkout',
  CHECKOUT_SUCCESS = '/checkout/success',
  CHECKOUT_CANCEL = '/checkout/cancel',
  EXTEND_PRODUCTS = '/extend-products/checkout',
  UPGRADE_PRODUCTS = '/upgrade-products/checkout',
  EMAIL_UNVERIFIED = '/email-unverified',
  PROGRESS_REPORT = '/progress-report/:hash',
  PAYMENT_IN_PROGRESS = '/payment-in-progress',
  INVESTOR_PUBLIC_PROFILE = '/public-profile/investor',
  FOUNDER_PUBLIC_PROFILE = '/public-profile/founder/:mode',
  FOUNDER_CONNECTIONS = '/founder/connections',
  ADVISOR_CONNECTIONS = '/advisor/connections',
  INVESTOR_ADVISOR_CONNECTIONS = '/investor-advisor/connections',
  NED_PUBLIC_PROFILE = '/public-profile/ned/:id',
  PROFILE = '/profile',
  MULTI_ROLE_PROFILE = '/profile/:role',
  ROLES = '/roles/:content',
  CREATE_NEW_ROLE = '/create-new-role',
  EDIT_ROLE = '/edit-role/:id',
  PREVIEW_ROLE = '/role/:id',
  ROLE_APPLICATIONS = '/role/:id/applications',
  LENS = '/lens/:action',
  ADVISORS = '/advisors',
  MESSAGING = '/messaging',
  NOTIFICATIONS = '/notifications',
  INTERNAL_SERVER_ERROR = '/error/500',
  ADVISORS_SEARCH_ROLE = '/advisors/search/:role',
  NOT_FOUND = '/error/404',
  ACCESS_NED_ACADEMY = '/ned-academy-access',
  MANAGE_METRIC = '/metric/manage/:mode',
  INVESTOR_CONFIRM_INVESTMENT = '/confirm-investment-claimed-by-founder/:id',
  FOUNDER_CONFIRM_INVESTMENT = '/confirm-investment-claimed-by-investor/:id',
  ONBOARDING_FOUNDER_STEP = '/onboarding-founder/:path/:step',
  ONBOARDING_INVESTOR = '/onboarding-investor',
  ONBOARDING_INVESTOR_SUMMARY = '/onboarding-investor/summary',
  INVESTOR_AWAITING_APPROVAL = '/investor-awaiting-approval',
  COR_SELECTION = '/country-of-residence',
  CATCH_ALL = '*',
}

export const getRouteConfigPath = (actualPath: string | readonly string[] | undefined): Routes | undefined => {
  // find matching route from routes config using  matchPath from react-router
  // https://reactrouter.com/web/api/matchPath

  if (!actualPath || Array.isArray(actualPath)) {
    return undefined;
  }

  const matchingRoute = Object.values(Routes).find((route) => {
    const match = matchPath(actualPath as string, { path: route, exact: true });
    return Boolean(match);
  });

  return matchingRoute as Routes;
};

import {
  SubscriptionProductStatus,
  SubscriptionProductType,
  type SubscriptionProduct,
} from '@domain/accounts/subscription-products';

// TODO - check if this can not be used in so many places
export const isActiveInvestor = (products: SubscriptionProduct[]) =>
  products.some(
    (prod) => prod.type === SubscriptionProductType.INVESTOR && prod.status === SubscriptionProductStatus.APPROVED,
  );

import type { GetTokenSilentlyOptions } from '@auth0/auth0-react';
import type { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';
import { Roles } from '@domain/accounts/roles';
import { Links } from '@router/links';
import { getRoleAccessibleRoutes } from '@router/utils/route-helpers';
import { AxiosHeaders } from 'axios';
import { tokenStorage } from '../../token/token-storage';
import { axiosInstance } from '../axios-instance';
import type { AxiosConfig } from '../types';

type GetAuth0AccessToken = {
  (
    options: GetTokenSilentlyOptions & {
      detailedResponse: true;
    },
  ): Promise<GetTokenSilentlyVerboseResponse>;
  (options?: GetTokenSilentlyOptions): Promise<string>;
  (options: GetTokenSilentlyOptions): Promise<string | GetTokenSilentlyVerboseResponse>;
};

const locationIsAllowed = (location: string, allowedRoutes: string[]) =>
  allowedRoutes.filter((el) => el.includes(location.split('/')[1])).length > 0;

export const prepareConfigWithToken = (
  getAccessTokenSilently: GetAuth0AccessToken,
  isTokenInMemory: boolean,
  skipAuthorization: boolean,
) => {
  // @ts-ignore
  axiosInstance.interceptors.request.use(async (config: AxiosConfig) => {
    if (config.skipAuthorization || skipAuthorization) {
      return config;
    }

    const token = isTokenInMemory ? tokenStorage.getAccessToken() : await getAccessTokenSilently();

    if (!token) {
      if (!locationIsAllowed(window.location.pathname, getRoleAccessibleRoutes(Roles.NO_ROLE))) {
        window.location.href = Links.LOGIN();
      }

      return config;
    }

    return {
      ...config,
      headers: new AxiosHeaders({
        ...config.headers,
        'X-JWT': `Bearer ${token}`,
      }),
    };
  });
};

import { MetricFilterMode, type MetricOptions } from '@pages/content/lens/api/get-browse-companies-filters';
import { currencyToShortFormat } from '@utils/fns/currency-to-short-format';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Radio, Slider } from 'antd';
import type { RadioChangeEvent } from 'antd/lib/radio';
import { useEffect, useState } from 'react';
import type { Any } from 'src/types';
import { useTheme } from 'styled-components';
import S from './metric-filter.styles';

const MIN_RANGE = 0;
const MAX_RANGE = 1;

export const initialMetricTypeFilter: Omit<MetricOptions, 'step'> & { filteringMode: MetricFilterMode } = {
  name: '',
  code: '',
  min: 0,
  max: 0,
  filteringMode: 'Value' as MetricFilterMode.Value,
};

export const MetricFilter = ({
  metricsOptions,
  lastSearchedMetric,
  setFieldValue,
  selectedFilters,
  filterKey,
}: {
  metricsOptions: MetricOptions[];
  lastSearchedMetric?: Omit<MetricOptions, 'step'>;
  setFieldValue: Function;
  selectedFilters: Omit<MetricOptions, 'step'>[] | [];
  filterKey: 'firstMetricTypeFilter' | 'secondMetricTypeFilter' | 'thirdMetricTypeFilter';
}) => {
  const [selectMetricLabel, clearLabel, actualsRadioLabel, forecastRadioLabel, minRangeLabel, maxRangeLabel] =
    useTranslation([
      'lens.filters.selectMetric',
      'lens.filters.clear',
      'lens.filters.radio.actuals',
      'lens.filters.radio.forecast',
      'lens.filters.minRange',
      'lens.filters.maxRange',
    ]);
  const [selectedMetric, setSelectedMetric] = useState<MetricOptions | null>(null);
  const [selectedRange, setSelectedRange] = useState<[number, number]>([0, 0]);
  const [selectedFilteringMode, setSelectedFilteringMode] = useState<MetricFilterMode>(MetricFilterMode.Value);

  const {
    deviceData: { isMobile },
  } = useDeviceDetect();

  const theme = useTheme();

  useEffect(() => {
    if (lastSearchedMetric && metricsOptions.length && lastSearchedMetric.name !== '') {
      const indexOfLastSearchedMetric = metricsOptions.findIndex((filter) => filter.code === lastSearchedMetric.code);
      setSelectedRange([lastSearchedMetric.min, lastSearchedMetric.max]);
      setSelectedFilteringMode(lastSearchedMetric.filteringMode);
      return setSelectedMetric({
        name: lastSearchedMetric.name,
        code: lastSearchedMetric.code,
        min: metricsOptions[indexOfLastSearchedMetric].min,
        max: metricsOptions[indexOfLastSearchedMetric].max,
        step: metricsOptions[indexOfLastSearchedMetric].step,
        filteringMode: lastSearchedMetric.filteringMode,
      });
    }
    return setSelectedMetric(null);
  }, [lastSearchedMetric]);

  const clearFilter = () => {
    setFieldValue(filterKey, { name: '', code: '', min: 0, max: 0, filteringMode: MetricFilterMode.Value });
  };

  const addFilter = () => {
    if (selectedMetric) {
      if (!selectedFilters.some((filter) => filter.code === selectedMetric.code)) {
        setFieldValue(filterKey, {
          name: selectedMetric.name,
          code: selectedMetric.code,
          min: selectedRange[MIN_RANGE],
          max: selectedRange[MAX_RANGE],
          filteringMode: selectedMetric.filteringMode,
        });
      }
    }
  };

  const checkIsMaxRangeSet = () => {
    if (selectedMetric) {
      if (selectedRange[MAX_RANGE] === selectedMetric.max) {
        setFieldValue(filterKey, {
          name: selectedMetric.name,
          code: selectedMetric.code,
          min: selectedRange[MIN_RANGE],
          max: null,
          filteringMode: selectedMetric.filteringMode,
        });
      }
    }
  };

  const handleRadioChange = (e: RadioChangeEvent) => {
    setSelectedFilteringMode(e.target.value);
    setFieldValue(filterKey, {
      name: selectedMetric?.name,
      code: selectedMetric?.code,
      min: selectedRange[MIN_RANGE],
      max: selectedRange[MAX_RANGE],
      filteringMode: e.target.value,
    });
  };

  useEffect(() => {
    if (selectedMetric) {
      addFilter();
      checkIsMaxRangeSet();
    }
  }, [selectedMetric, selectedRange]);

  const roundValueToStep = (value: string, step: number) => Math.ceil(Number(value) / step) * step;

  return (
    <S.Wrapper>
      <S.Select
        data-testid="select-metric"
        placeholder={selectMetricLabel}
        value={selectedMetric?.name}
        onChange={(i: Any) => {
          if (i === -1) {
            clearFilter();
            return setSelectedMetric(null);
          }
          clearFilter();
          setSelectedFilteringMode(MetricFilterMode.Value);
          const { name, code, min, max, step } = metricsOptions[i];
          setSelectedMetric({
            name,
            code,
            min,
            max,
            step,
            filteringMode: selectedFilteringMode,
          });
          setSelectedRange([metricsOptions[i].min, metricsOptions[i].max]);
        }}
      >
        <S.Select.Option key="clear" value={-1} disabled={!selectedMetric}>
          {clearLabel}
        </S.Select.Option>
        {metricsOptions.map((metric, index) => {
          const isDisabled = selectedFilters.some((filter) => filter.code === metric.code);

          return (
            <S.Select.Option
              // From today, I have an even stronger aversion towards antd
              style={isDisabled ? { background: theme.color.disabledSelectOption } : undefined}
              disabled={isDisabled}
              key={index}
              value={index}
              data-testid={`select-metric-${index}`}
            >
              {metric.name}
            </S.Select.Option>
          );
        })}
      </S.Select>
      {selectedMetric && (
        <S.Filters>
          <S.SliderWrapper>
            <S.Range>
              {`${currencyToShortFormat(String(selectedRange[MIN_RANGE]))} - ${currencyToShortFormat(
                String(selectedRange[MAX_RANGE] === null ? selectedMetric.max : selectedRange[MAX_RANGE]),
              )}${selectedRange[MAX_RANGE] === null ? '+' : ''}`}
            </S.Range>
            <Slider
              range
              min={selectedMetric.min}
              max={selectedMetric.max}
              step={selectedMetric.step}
              onChange={(v: [number, number]) => setSelectedRange(v)}
              value={[
                selectedRange[MIN_RANGE],
                selectedRange[MAX_RANGE] === null ? selectedMetric.max : selectedRange[MAX_RANGE],
              ]}
              onAfterChange={(range: [number, number]) => {
                setSelectedRange([range[MIN_RANGE], range[MAX_RANGE]]);
                setFieldValue(filterKey, {
                  name: selectedMetric.name,
                  code: selectedMetric.code,
                  min: selectedRange[MIN_RANGE],
                  max: selectedRange[MAX_RANGE],
                  filteringMode: selectedMetric.filteringMode,
                });
              }}
            />
          </S.SliderWrapper>
          {isMobile && (
            <>
              <S.MobileInput>
                <S.Label>{minRangeLabel}</S.Label>
                <S.InputNumber
                  type="number"
                  min={selectedMetric.min}
                  max={selectedMetric.max}
                  value={selectedRange[MIN_RANGE]}
                  onChange={
                    (v) => {
                      setSelectedRange([
                        Number(v) < selectedMetric.max
                          ? roundValueToStep(v as string, selectedMetric.step)
                          : selectedMetric.max,
                        selectedRange[MAX_RANGE],
                      ]);
                    }
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                  step={selectedMetric.step}
                />
              </S.MobileInput>
              <S.MobileInput>
                <S.Label>{maxRangeLabel}</S.Label>
                <S.InputNumber
                  type="number"
                  min={selectedRange[MIN_RANGE]}
                  max={selectedMetric.max}
                  value={selectedRange[MAX_RANGE] === null ? selectedMetric.max : selectedRange[MAX_RANGE]}
                  onChange={
                    (v) => {
                      setSelectedRange([
                        selectedRange[MIN_RANGE],
                        Number(v) < selectedMetric.max
                          ? roundValueToStep(v as string, selectedMetric.step)
                          : selectedMetric.max,
                      ]);
                      checkIsMaxRangeSet();
                    }
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                  step={selectedMetric.step}
                />
              </S.MobileInput>
            </>
          )}

          <Radio.Group value={selectedFilteringMode} onChange={handleRadioChange}>
            <Radio value={MetricFilterMode.Value}>{actualsRadioLabel}</Radio>
            <Radio value={MetricFilterMode.Forecast}>{forecastRadioLabel}</Radio>
          </Radio.Group>
        </S.Filters>
      )}
    </S.Wrapper>
  );
};

import { FormikField as FormikFieldBase } from '@parts/forms/formik-field/formik-field';
import { Input as InputBase, Radio as RadioBase } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacing.base};
`;

const Input = styled(InputBase)``;

const Radio = styled(RadioBase)`
  text-align: center;
  width: 130px;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  &:first-of-type {
    font-weight: ${({ theme }) => theme.fontWeight.light};
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    bottom: auto;
    height: 100%;
    width: 1px;
    background-color: ${({ theme }) => theme.baseColors.Blue200};
  }

  &:last-child:after {
    display: none;
  }

  .ant-radio {
    display: none;
  }

  &.ant-radio-wrapper-checked {
    background-color: ${({ theme }) => theme.baseColors.Blue200};

    span {
      color: ${({ theme }) => theme.baseColors.White};
    }
  }
`;

const RadioGroup = styled(RadioBase.Group)`
  border: 1px solid ${({ theme }) => theme.baseColors.Blue200};
  border-radius: 6px;
  margin: ${({ theme }) => theme.spacing.medium} 0;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    display: flex;
    flex-wrap: nowrap;

    ${Radio} {
      flex-grow: 1;
      flex-shrink: 0;
      width: 70px;
    }
  }

  .ant-radio-wrapper {
    margin: 0;
    padding: 12px 0;
  }
`;

const NoteWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing.xsmall} 0;
`;

const Header = styled.h3`
  color: ${({ theme }) => theme.color.activeMetricManagementTitle};
`;

const FormikField = styled(FormikFieldBase)`
  .ant-input {
    margin: ${({ theme }) => theme.spacing.small} 0;
    background-color: ${({ theme }) => theme.color.dashboardBackground};
  }
`;

export default {
  Wrapper,
  Radio,
  RadioGroup,
  NoteWrapper,
  Header,
  FormikField,
  Input,
};

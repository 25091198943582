import { useDrawer } from '@parts/drawer/use-drawer';
import { FormikField } from '@parts/forms/formik-field/formik-field';
import { FormikStringInput } from '@parts/forms/formik-string-input/formik-string-input';
import message from '@parts/message/message';
import OnTopFooter from '@parts/on-top-footer/on-top-footer';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useFormik } from 'formik';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { GET_FOUNDER_DASHBOARD_CACHE_KEY } from '../../../api/get-founder-dashboard/get-founder-dashboard.action';
import { updateTextBoxAction, type TextBox } from '../../../api/update-text-box/update-text-box.actions';
import { textBoxIdParam, updateTextBoxParam } from '../../../founder-dashboards.layout';
import S from './update-text-box.styles';
import { useValidationSchema } from './use-validation-schema';

const UpdateTextBox = ({
  title,
  mainText,
  textBoxId,
  dashboardId,
}: {
  title: string | null;
  mainText: string | null;
  textBoxId: string;
  dashboardId: string;
}) => {
  const [
    saveLabel,
    cancelLabel,
    titleLabel,
    mainTextLabel,
    addHeaderLabel,
    updateTextBoxHeader,
    successAddTextBoxLabel,
    successUpdateTextBoxLabel,
  ] = useTranslation([
    'global.drawer.save',
    'global.cancel',
    'pulse.dashboard.drawer.textBox.title',
    'pulse.dashboard.drawer.textBox.mainText',
    'pulse.dashboard.drawer.addTextBox.header',
    'pulse.dashboard.drawer.updateTextBox.header',
    'pulse.dashboard.drawer.addTextBox.success',
    'pulse.dashboard.drawer.updateTextBox.success',
  ]);
  const history = useHistory();

  const { Drawer } = useDrawer(true);
  const onClose = () => {
    const parsedLocationSearch = {
      ...queryString.parse(window.location.search),
      [updateTextBoxParam]: undefined,
      [textBoxIdParam]: undefined,
    };

    history.push(`?${queryString.stringify(parsedLocationSearch)}`);
  };
  const queryClient = useQueryClient();

  const { mutateAsync: updateTextBox, isLoading } = useMutation(updateTextBoxAction, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_FOUNDER_DASHBOARD_CACHE_KEY]);

      message.success({
        content: title ? successUpdateTextBoxLabel : successAddTextBoxLabel,
      });
      onClose();
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  const formik = useFormik<TextBox>({
    onSubmit: ({ mainText: mainTextValue, title: titleValue }) => {
      updateTextBox({
        payload: { mainText: mainTextValue, title: titleValue },
        dashboardId,
        textBoxId,
      });
    },
    initialValues: {
      mainText,
      title,
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: useValidationSchema(),
  });
  return (
    <Drawer
      title={title ? `${updateTextBoxHeader} ${title}` : addHeaderLabel}
      onClose={onClose}
      footer={
        <OnTopFooter
          onConfirm={formik.submitForm}
          data-testid="update-text-box"
          confirmLabel={saveLabel}
          cancelLabel={cancelLabel}
          onCancel={onClose}
          reverseButtons
          disabled={!formik.isValid}
          loading={isLoading}
        />
      }
    >
      <FormikStringInput formik={formik} name="title" label={titleLabel} />
      <FormikField
        label={{
          label: mainTextLabel,
          for: 'mainText',
        }}
        error={formik.errors.mainText}
        touched={formik.touched.mainText}
      >
        <S.DrawerTextArea rows={6} id="mainText" {...formik.getFieldProps('mainText')} />
      </FormikField>
    </Drawer>
  );
};

export default UpdateTextBox;

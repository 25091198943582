import React from 'react';
import { V2Icon } from '../v2-icon/v2-icon';
import type { V2IconBoxProps } from './v2-icon-box.types';

import S from './v2-icon-box.styles';

export const V2IconBox: React.FC<V2IconBoxProps> = ({
  children,
  icon,
  variant = 'primary',
  size = 'md',
  backgroundColor,
  iconColor,
  'data-testid': testId = 'v2-icon-box',
}) => {
  const IconBoxVariant = {
    primary: S.Primary,
    secondary: S.Secondary,
    neutral: S.Neutral,
    'neutral-light': S.NeutralLight,
  }[variant];

  const iconSize = {
    xs: 16,
    sm: 24,
    md: 24,
    lg: 30,
  }[size];

  const content = children ?? <V2Icon name={icon ?? 'question-diamond'} size={iconSize} />;

  return (
    <IconBoxVariant
      $size={size}
      style={{
        ...(backgroundColor ? { backgroundColor: backgroundColor } : {}),
        ...(iconColor ? { color: iconColor } : {}),
      }}
      data-testid={testId}
    >
      {content}
    </IconBoxVariant>
  );
};

import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import type { ReactNode } from 'react';
import S from './pill-button-checkbox.styles';

export const PillButtonCheckbox = ({
  children,
  onChange,
  checked = false,
  disabled = false,
  visible = true,
  testidPrefix = '',
  isLightTheme = false,
}: {
  children: ReactNode;
  onChange: (v: CheckboxChangeEvent) => void;
  checked?: boolean;
  disabled?: boolean;
  visible?: boolean;
  testidPrefix?: string;
  isLightTheme?: boolean;
}) => (
  <S.Checkbox
    onChange={onChange}
    checked={checked}
    disabled={disabled}
    $checked={checked}
    $lighterBackground={isLightTheme}
    $visible={visible}
    data-testid={`${testidPrefix ? `${testidPrefix}-` : ''}pill-button${checked ? '-checked' : ''}`}
  >
    <S.Text>{children}</S.Text>
  </S.Checkbox>
);

import currency from 'currency.js';

export const calculatePercentageDiscountValue = (netAmount: string, discountPercentage: number) => {
  if (Number.isNaN(discountPercentage) || discountPercentage < 0 || discountPercentage > 100) {
    return currency(0).value;
  }

  const discountAmount = currency(netAmount).multiply(discountPercentage / 100);
  return currency(discountAmount).value;
};

import { V2SearchInputForm } from '../v2-search-input-form/v2-search-input-form';
import S from './v2-filter-panel.styles';
import type { FilterOption, V2FilterPanelProps } from './v2-filter-panel.types';

const FilterSwitch = ({
  filters,
  selected = [],
  onChange,
  disabled = false,
  multiSelect = false,
}: {
  filters: FilterOption[];
  selected?: string | string[];
  onChange: (value: string[]) => void;
  multiSelect?: boolean;
  disabled?: boolean;
}) => {
  const activeList = Array.isArray(selected) ? selected : [selected];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (multiSelect) {
      const newValue = e.target.checked
        ? [...activeList, e.target.value]
        : activeList.filter((v) => v !== e.target.value);
      onChange(newValue);
      return;
    }

    const newValue = e.target.checked ? [e.target.value] : [];
    onChange(newValue);
  };

  return (
    <S.FilterSwitch>
      {filters.map(({ value, label }) => (
        <li key={value}>
          <input
            type="checkbox"
            id={`filter-switch-${value}`}
            name="filter-switch"
            value={value}
            checked={activeList.includes(value)}
            onChange={handleChange}
            disabled={disabled}
          />
          <label htmlFor={`filter-switch-${value}`}>{label}</label>
        </li>
      ))}
    </S.FilterSwitch>
  );
};

export const V2FilterPanel = ({
  filters,
  selectedFilters = [],
  onFilterChange = () => {},
  search = false,
  initialSearchValue = '',
  onSearchSubmit = () => {},
  disabled = false,
  ['data-testid']: testId = 'v2-filter-panel',
  ...restProps
}: V2FilterPanelProps) => {
  return (
    <S.V2FilterPanel data-testid={testId} {...restProps}>
      <FilterSwitch
        onChange={(e) => onFilterChange(e)}
        selected={selectedFilters}
        filters={filters}
        disabled={disabled}
      />

      {search ? (
        <S.Search>
          <V2SearchInputForm
            initialValue={initialSearchValue}
            onSubmit={(value) => onSearchSubmit(value)}
            disabled={disabled}
          />
        </S.Search>
      ) : null}
    </S.V2FilterPanel>
  );
};

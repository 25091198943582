import { FormikField } from '@parts/forms/formik-field/formik-field';
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import type { FormikProps } from 'formik';
import type { ReactNode } from 'react';
import { AuthPhoneNumberInput } from '../../../../parts/auth-phone-number-input/auth-phone-number-input';
import S from './auth-input-field.styles';

interface AuthInputFieldProps<T> {
  formik: FormikProps<T>;
  label: string | JSX.Element;
  field: keyof T;
  type: InputType;
  testId?: string;
  placeholder?: string;
  prefix?: ReactNode;
  disabled?: boolean;
  required?: boolean;
  countryOfResidence?: CountryCodes | '';
}

type InputType = 'text' | 'email' | 'password' | 'phone_number';

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const AuthInputField = <T,>({
  formik,
  label,
  field,
  type,
  testId,
  placeholder,
  prefix,
  required,
  disabled,
  countryOfResidence = '',
}: AuthInputFieldProps<T>) => {
  const fieldName = field as string;
  const autoComplete = disabled ? 'off' : undefined;
  const getInputElement = (inputType: InputType) => {
    switch (inputType) {
      case 'password':
        return (
          <S.AuthPasswordInput
            autoComplete={autoComplete}
            id={fieldName}
            required={required}
            type={inputType}
            prefix={prefix}
            data-testid={testId}
            placeholder={placeholder}
            disabled={disabled}
            {...formik.getFieldProps(fieldName)}
          />
        );
      case 'phone_number':
        return (
          <AuthPhoneNumberInput
            id={fieldName}
            testId={testId}
            field={field}
            formik={formik}
            disabled={disabled}
            countryOfResidence={countryOfResidence}
          />
        );
      default:
        return (
          <S.AuthInput
            autoComplete={autoComplete}
            required={required}
            id={fieldName}
            type={inputType}
            prefix={prefix}
            data-testid={testId}
            placeholder={placeholder}
            disabled={disabled}
            {...formik.getFieldProps(fieldName)}
          />
        );
    }
  };
  const Input = getInputElement(type);
  return (
    <FormikField
      registerMarginBottom
      marginBottom
      fontWeight="normal"
      suffix
      required={required}
      labelAlignedToCenter
      label={{ for: fieldName, label }}
      touched={formik.touched[field] as boolean}
      error={formik.errors[field] as string}
    >
      <S.InputWrapper
        short={type === 'phone_number'}
        className={`${formik.errors[field] && formik.touched[field] ? 'error' : ''}`}
      >
        {Input}
      </S.InputWrapper>
    </FormikField>
  );
};

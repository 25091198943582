import ArrowRightIconBase from '@assets/icons/arrow-right.svg?react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.small};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ScoreWrapper = styled.div<{ size?: number }>`
  width: ${({ size }) => (size ? `${size}px` : '55px')};
  height: ${({ size }) => (size ? `${size}px` : '55px')};
  display: inline-block;
`;

const TextWrapper = styled.div`
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacing.xsmall};
`;

const Title = styled.p<{ fontSize?: number }>`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.color.readinessScoreMenuTab};
  padding: 0;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '13px')};
  margin-left: ${({ theme }) => theme.spacing.xsmall};
  display: block;
  line-height: 20px;
`;

const Subtext = styled(Title)<{ fontSize?: number }>`
  color: ${({ theme }) => theme.color.primary};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '13px')};
`;

const ArrowRightIcon = styled(ArrowRightIconBase)`
  width: 10px !important;
  height: 10px !important;
  left: 0;

  path {
    fill: ${({ theme }) => theme.color.primary} !important;
  }
`;

export default { Wrapper, ScoreWrapper, TextWrapper, Title, Subtext, ArrowRightIcon };

import { markAsRead } from '@/context/notifications/notifications.action-creators';
import { Roles } from '@/domain/accounts/roles';
import { NotificationTypes } from '@/utils/type-definitions/notifications';
import { NotificationsContext, type NotificationsStateApi } from '@context/notifications/notifications.context';
import { type Notification } from '@context/notifications/notifications.reducer';
import { markNotificationAsReadAction } from '@layout/parts/bell/api/notifications.actions';
import { founderIdQueryParam } from '@pages/content/public-profile/founder/founder.page';
import { investorIdQueryParam } from '@pages/content/public-profile/investor/investor.page';
import { Links } from '@router/links';
import { useMutation } from '@tanstack/react-query';
import { useContext } from 'react';
import { SCROLL_TO_TARGET_PARAM } from '../use-scroll-to-element/use-scroll-to-element-with-query-params';

export const useNotificationsContext = () => {
  const context = useContext(NotificationsContext) as NotificationsStateApi;

  if (!context) {
    throw new Error('useNotifications should be used within NotificationsContext.');
  }

  return context;
};

export const useNotificationMarkAsRead = ({
  notification,
  refetch,
}: {
  notification: Notification;
  refetch?: Function;
}) => {
  const { dispatch } = useNotificationsContext();

  const { mutateAsync: markNotificationAsRead } = useMutation(markNotificationAsReadAction, {
    onSuccess: async () => {
      if (refetch) await refetch();
      dispatch(markAsRead(notification.id));
    },
  });

  return { markAsRead: () => markNotificationAsRead(notification.id) };
};

// TODO Needs to be refactored
export const useNotificationRedirectUrl = ({ type, payload }: Notification) => {
  const { profileId, roleId, role } = payload || {};

  if (profileId) {
    if ([NotificationTypes.ConnectionSentToFounder, NotificationTypes.MetricTriggerSet].includes(type)) {
      return `${Links.INVESTOR_PUBLIC_PROFILE()}?${investorIdQueryParam}=${profileId}`;
    }

    if ([NotificationTypes.ConnectionAcceptedByFounder, NotificationTypes.ConnectionDeclinedByFounder].includes(type)) {
      return `${Links.FOUNDER_PUBLIC_PROFILE_DETAILS()}?${founderIdQueryParam}=${profileId}`;
    }

    if (NotificationTypes.ConnectionSentToParticipant === type) {
      return `${Links.FOUNDER_PUBLIC_PROFILE_DETAILS()}?${founderIdQueryParam}=${profileId}`;
    }

    if ([NotificationTypes.ConnectionAcceptedByParticipant].includes(type)) {
      const roleProfileUrl = {
        [Roles.NED]: `${Links.NED_PUBLIC_PROFILE(profileId)}`,
        [Roles.INVESTOR]: `${Links.INVESTOR_PUBLIC_PROFILE()}?${investorIdQueryParam}=${profileId}`,
      };

      return roleProfileUrl[role ?? Roles.INVESTOR];
    }

    if ([NotificationTypes.ConnectionDeclinedByParticipant].includes(type)) {
      const roleProfileUrl = {
        [Roles.NED]: `${Links.NED_PUBLIC_PROFILE(profileId)}`,
        [Roles.INVESTOR]: `${Links.INVESTOR_PUBLIC_PROFILE()}?${investorIdQueryParam}=${profileId}`,
      };

      return roleProfileUrl[role ?? Roles.INVESTOR];
    }

    if (type === NotificationTypes.MetricTriggerMatched) {
      return `${Links.FOUNDER_PUBLIC_PROFILE_DASHBOARDS()}?${founderIdQueryParam}=${profileId}`;
    }

    if (type === NotificationTypes.InternalExperienceAddedByAdvisor) {
      return Links.NED_PUBLIC_PROFILE(profileId) + `?${SCROLL_TO_TARGET_PARAM}=board-experience`;
    }
  }

  if (roleId) {
    if ([NotificationTypes.ApplicationForRoleSent, NotificationTypes.ApplicationForRoleApproved].includes(type)) {
      return `${Links.ROLE_APPLICATIONS(roleId)}`;
    }
  }
};

import { V2AriaTable } from '@/components/blocks/v2-aria-table/v2-aria-table';
import { V2Dropdown } from '@/components/blocks/v2-dropdown/v2-dropdown';
import { V2Button } from '@/components/ui/v2-button/v2-button';
import message from '@/parts/message/message';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { useChangeConnectionIntent } from '../../../hooks/use-change-connection-intent';
import type { InvestorAdvisorConnectionIntent, InvestorAdvisorFounderConnection } from '../../../types';

type ConnectionTypeCellProps = InvestorAdvisorFounderConnection & {
  onUpdated: () => void;
};

export const ConnectionIntentCell = ({ connectionId, connectionIntent, onUpdated }: ConnectionTypeCellProps) => {
  const [advisingLL, investingLL, successMessageLL] = useTranslation([
    'connections.investor-advisor.connection-intent.advising',
    'connections.investor-advisor.connection-intent.investing',
    'connections.investor-advisor.intent-updated.success-message',
  ]);

  const { changeConnectionIntent, isChangeConnectionIntentLoading: isChangeConnectionLoading } =
    useChangeConnectionIntent({
      connectionId,
      onSuccessCallback: () => {
        message.success({ content: successMessageLL });
        onUpdated();
      },
    });

  const menuOptions: { id: InvestorAdvisorConnectionIntent; label: string; onClick: () => void }[] = [
    { id: 'Investing' as const, label: investingLL, onClick: () => changeConnectionIntent('Investing') },
    { id: 'Advising' as const, label: advisingLL, onClick: () => changeConnectionIntent('Advising') },
  ].filter((item) => item.id !== connectionIntent);

  return (
    <V2AriaTable.BodyCell>
      <V2Dropdown
        key={connectionId + connectionIntent}
        overlay={
          <V2Dropdown.Menu>
            {menuOptions.map((item) => (
              <V2Dropdown.Item key={item.id} onClick={item.onClick} disabled={isChangeConnectionLoading}>
                {item.label}
              </V2Dropdown.Item>
            ))}
          </V2Dropdown.Menu>
        }
        toggle={
          <V2Button variant="outlined" startIcon="chevron-down">
            {connectionIntent}
          </V2Button>
        }
      />
    </V2AriaTable.BodyCell>
  );
};

import { V2Button } from '@/components/ui/v2-button/v2-button';
import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import { LinkButton } from '@parts/link-button/link-button';
import { Button, Switch as SwitchBase } from 'antd';
import styled from 'styled-components';

const checkoutContainerWidth = '900px';
const checkoutContainerWideWidth = '1200px';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.color.checkoutBackground};
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const WrapperContainer = styled.div<{ $wide?: boolean }>`
  width: 100%;
  max-width: 100%;
  padding: ${({ theme }) => theme.spacing.medium} ${({ theme }) => theme.spacing.xbase};

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) {
    max-width: ${({ $wide }) => ($wide ? checkoutContainerWideWidth : checkoutContainerWidth)};
  }
`;

const BackButton = styled(Button)`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.baseColors.White};
  font-size: ${({ theme }) => theme.fontSize.regular};
  color: ${({ theme }) => theme.baseColors.White};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    path {
      fill: ${({ theme }) => theme.baseColors.White};
    }
  }

  &:hover,
  &:active,
  &:focus {
    border: 1px solid ${({ theme }) => theme.baseColors.White};
    background: transparent;
  }
`;

const SignOutButton = styled(V2Button)`
  border: 1px solid ${({ theme }) => theme.v2.colors.neutral[50]};
  padding: 12px 20px;

  color: ${({ theme }) => theme.v2.colors.neutral[50]};
  text-decoration: none;
`;

const HeaderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 0 ${({ theme }) => theme.spacing.xlarge};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    padding: 0 ${({ theme }) => theme.spacing.xlarge};
  }

  ${BackButton} {
    position: absolute;
    top: 0;
    left: 0;
  }

  ${SignOutButton} {
    position: absolute;
    top: 0;
    right: 0;

    @media (max-width: 690px) {
      position: fixed;
      top: 15px;
      right: 15px;
    }
  }
`;

const Content = styled.div`
  height: 590px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    height: 100%;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    padding: ${({ theme }) => theme.spacing.medium} 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  > a {
    color: ${({ theme }) => theme.baseColors.White};
    display: block;
    font-size: ${({ theme }) => theme.fontSize.xxsmall};
    text-align: center;
    margin: 35px 0 ${({ theme }) => theme.spacing.medium} 0;
  }
`;

const Controls = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.medium};
  margin-bottom: ${({ theme }) => theme.spacing.base};
  text-align: center;

  > a {
    color: ${({ theme }) => theme.baseColors.White};
    display: block;
    font-size: ${({ theme }) => theme.fontSize.xxsmall};
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    justify-content: center;
  }
`;

const Header = styled.header`
  color: ${({ theme }) => theme.color.checkoutHeader};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  text-transform: uppercase;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin-bottom: ${({ theme }) => theme.spacing.small};
  }
`;

const Label = styled.h2`
  font-size: 32px;
  color: ${({ theme }) => theme.baseColors.White};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  text-align: center;
`;

const Pagination = styled.div`
  color: rgba(255, 255, 255, 0.5);
  margin: ${({ theme }) => theme.spacing.xmedium} 0;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin-top: 80px;
    margin-bottom: ${({ theme }) => theme.spacing.xmedium};
  }
`;

const BigNumber = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: 34px;
  display: inline-block;
  margin-right: ${({ theme }) => theme.spacing.xxsmall};
  color: ${({ theme }) => theme.baseColors.White};
`;

const SmallNumber = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  display: inline-block;
  color: rgba(255, 255, 255, 0.5);
  margin-left: ${({ theme }) => theme.spacing.xxsmall};
`;

const SwitchWrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin-bottom: ${({ theme }) => theme.spacing.small};
  }
`;

const Switch = styled(SwitchBase)`
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacing.small};

  &[aria-checked='true'] {
    background: ${({ theme }) => theme.gradient.checkoutButtons};
  }

  span {
    font-size: 9px;
  }
`;

const Vat = styled.p`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.baseColors.White};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  display: inline-block;
`;

const Tiles = styled.div<{ spaceAround: boolean }>`
  display: flex;
  justify-content: ${({ spaceAround }) => (spaceAround ? 'space-around' : 'space-between')};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-direction: column;
    align-items: center;
    gap: 45px;
  }
`;

const Back = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  color: ${({ theme }) => theme.baseColors.White};
  display: block;
  font-size: ${({ theme }) => theme.fontSize.xxsmall};
`;

export const NarrowLink = styled(LinkButton)`
  margin-top: ${({ theme }) => theme.spacing.base};
  margin-bottom: ${({ theme }) => theme.spacing.base};
  max-width: 350px;
  padding: ${({ theme }) => theme.spacing.small};
  text-align: center;

  background: ${({ theme }) => theme.baseColors.Pink};
  border-color: ${({ theme }) => theme.baseColors.Pink};
  border-radius: ${({ theme }) => theme.radius.medium};

  svg {
    transition: transform 0.5s;
  }

  &:hover,
  &:active,
  &:focus {
    background: ${({ theme }) => theme.baseColors.White};
    border-color: ${({ theme }) => theme.baseColors.White};
    color: ${({ theme }) => theme.baseColors.Blue400};

    &:not(:disabled) {
      transform: scale(1.01);
    }

    svg {
      transform: translateX(5px);
    }
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.xsmall};
  }
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  max-width: 576px;
  gap: 40px;
  margin: 0 auto;
`;

const ModalTitle = styled.h1`
  width: 100%;
  white-space: wrap;
  text-align: center;
  color: ${({ theme }) => {
    if (theme.v2.mode === 'dark') return theme.v2.colors.neutral[50];
    if (theme.v2.mode === 'light') return theme.v2.colors.blue[400];
  }};
  font-weight: ${({ theme }) => theme.v2.typography.weights.bold};

  ${({ theme }) =>
    customMediaQuery('font-size', {
      xs: theme.v2.typography.sizes.lg,
      sm: theme.v2.typography.sizes.xl,
      md: theme.v2.typography.sizes['2xl'],
    })}
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export default {
  Wrapper,
  WrapperContainer,
  BackButton,
  Content,
  HeaderWrapper,
  Header,
  Label,
  Controls,
  BigNumber,
  SmallNumber,
  Pagination,
  Switch,
  Vat,
  SwitchWrapper,
  Tiles,
  Back,
  NarrowLink,
  ModalWrapper,
  ModalTitle,
  ButtonWrapper,
  SignOutButton,
};

import { V2NotificationIndicator } from '@/components/ui/v2-notification-indicator/v2-notification-indicator';
import { useModal } from '@/hooks/use-modal';
import { useFounderInvitesCountQuery } from '@/modules/founder/connections/hooks/use-founder-invites-count.query';
import { QualifyingQuestionsModal } from '@/modules/founder/homepage/components/qualifying-questions-modal/qualifying-questions-modal';
import { useReadinessScoreModal } from '@/modules/founder/readiness-score/hooks/use-readiness-score-modal';
import { scrollToTop } from '@/utils/fns/scroll-to-top';
import { useUserAbility } from '@abilities';
import { AbilityAction, AbilitySubject } from '@abilities/ability/types';
import UserRegular from '@assets/icons/user-regular.svg?react';
import { communityLink } from '@constants/links';
import { OnboardingStatus } from '@domain/accounts/types';
import { ActiveLayout, type MenuItem } from '@layout/active/active.layout';
import { DropMenu, type ItemWithIcon } from '@layout/parts/menu-dropdown/menu-dropdown';
import { Routes } from '@router/routes';
import {
  lensConnectionsKey,
  lensRequestsKey,
  lensSmartMatchKey,
  pulseDashboardsKey,
  pulseInvestorsKey,
} from '@router/tabs-keys';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { useLayoutEffect, type FC } from 'react';
import { useLocation } from 'react-router-dom';
import { NotificationBanners } from '../parts/notification-banners/notification-banners';
import { ReadinessScoreTab } from './parts/readiness-score-tab/readiness-score-tab';

export const FounderLayout: FC<{ legacyContainer?: boolean }> = ({ children, legacyContainer = true }) => {
  const { state: userAccountState } = useUserAccount();
  const location = useLocation();
  const userAbility = useUserAbility();
  const { invitesCount } = useFounderInvitesCountQuery();
  const { openReadinessScoreModal, readinessScoreModal } = useReadinessScoreModal('founder-readiness-score-layout');

  const [reportLabel, investorsLabel, legalLabel, advisorsLabel, myProfileLabel, community, connections] =
    useTranslation([
      'navigation.report',
      'navigation.investors',
      'navigation.legal',
      'navigation.advisors',
      'navigation.myProfile',
      'navigation.community',
      'navigation.connections',
    ]);

  const shouldOpenQualifyingQuestionsModal =
    userAccountState.onboarding.founderQualifyingQuestions === OnboardingStatus.Pending;

  const { isOpen, close } = useModal(shouldOpenQualifyingQuestionsModal);

  const hasLegalDocsPermission = userAbility.can(AbilityAction.ACCESS, AbilitySubject.LEGAL_DOCS);

  const FounderRoutes: MenuItem[] = [
    {
      route: Routes.FOUNDER_CONNECTIONS,
      label: (
        <V2NotificationIndicator count={invitesCount} mode="dot" position="top-right" dotPlacementMode="outside">
          {connections}
        </V2NotificationIndicator>
      ),
    },
    {
      route: Routes.LENS,
      label: investorsLabel,
      defaultParams: { action: lensSmartMatchKey },
      allowedParamValues: { action: [lensSmartMatchKey, lensConnectionsKey, lensRequestsKey] },
    },
    { route: Routes.ADVISORS, label: advisorsLabel },
    {
      route: Routes.PULSE,
      label: reportLabel,
      defaultParams: { content: pulseDashboardsKey },
      allowedParamValues: { content: [pulseDashboardsKey, pulseInvestorsKey] },
    },
    ...(hasLegalDocsPermission ? [{ route: Routes.LEGAL, label: legalLabel }] : []),
    { route: communityLink as Routes, label: community, isExternalLink: true, showTooltip: true, disabled: true },
  ];

  const profileMenuRoutes: ItemWithIcon[] = [{ route: Routes.PROFILE, label: myProfileLabel, icon: <UserRegular /> }];

  useLayoutEffect(() => {
    scrollToTop();
  }, []);

  return (
    <ActiveLayout
      menu={FounderRoutes}
      profileMenu={profileMenuRoutes}
      locationPathName={location.pathname}
      infoStickyBannerBottom={<NotificationBanners />}
      legacyContainer={legacyContainer}
      dropMenu={
        <DropMenu menu={profileMenuRoutes} extraMenuItem={ReadinessScoreTab({ onClick: openReadinessScoreModal })} />
      }
      extraProfileMenuItem={
        <ReadinessScoreTab fontSize={15} scoreSize={60} scoreNumberFontSize={24} onClick={openReadinessScoreModal} />
      }
    >
      {children}

      {readinessScoreModal}
      <QualifyingQuestionsModal isOpen={isOpen} mode="auto" close={close} />
    </ActiveLayout>
  );
};

import { axiosInstance, FILE_UPLOAD_TIMEOUT } from '@utils/axios/axios-instance';
import type { AxiosResponse } from '@utils/axios/types';

export interface InvestorDocument {
  id: string;
  name: string;
  url: string;
  uploaded_at: Date;
  note: string;
}

export const UploadInvestorDocumentAction =
  (investingRelationshipId: string) =>
  (payload: FormData): Promise<AxiosResponse<InvestorDocument>> =>
    axiosInstance.post(
      `/portfolio-management/investing-relationship/${investingRelationshipId}/investor-document`,
      payload,
      {
        headers: {
          'Content-type': 'multipart/form-data',
        },
        timeout: FILE_UPLOAD_TIMEOUT,
      },
    );

export type AccessToken = string;

// TODO: Clear after new login-as-user is implemented.
export class TokenStorage {
  #token: AccessToken = '';

  #expiresAt = 0;

  setAccessToken(token: AccessToken, exp: number) {
    this.#expiresAt = exp;
    this.#token = token;
  }

  getAccessToken() {
    return this.#token;
  }

  resetAccessToken() {
    this.setAccessToken('', 0);
  }

  isTokenSet() {
    return this.#token ? this.#token.length > 0 : false;
  }

  hasAccessTokenExpired() {
    return this.#expiresAt < Date.now() / 1000;
  }

  isTokenActive() {
    return this.isTokenSet() && !this.hasAccessTokenExpired();
  }
}

export const tokenStorage = new TokenStorage();

import EmptyPortfolioIcon from '@assets/icons/empty-portfolio.svg';
import { InvestorLayout } from '@layout/investor/investor.layout';
import { idQueryParam, investmentIdQueryParam } from '@pages/content/pulse/founder/parts/editor/create-editor';
import { useDrawer } from '@parts/drawer/use-drawer';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import CircularBarGraph from '@parts/graph/parts/circular-bar-graph/circular-bar-graph';
import { ComingSoonGraph } from '@parts/graph/parts/coming-soon-graph/coming-soon-graph';
import LineBarGraph from '@parts/graph/parts/line-bar-graph/line-bar-graph';
import { Investments } from '@parts/investments/investments';
import { DEFAULT_ITEMS_PER_PAGE_IN_GRID } from '@parts/items-grid/items-grid';
import { PageHeader } from '@parts/page-header/page-header';
import { Pagination } from '@parts/pagination/pagination';
import { PendingInvestments } from '@parts/pending-investments/pending-investments';
import { SplitedSection } from '@parts/splited-section/splited-section';
import { useQuery } from '@tanstack/react-query';
import { useQueryPagination } from '@utils/hooks/use-query-pagination/use-query-pagination';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { DateTime } from 'luxon';
import { createRef, useEffect, useState } from 'react';
import {
  GET_INVESTOR_PORTFOLIO_METRICS_CACHE_KEY,
  getInvestorPortfolioMetricsAction,
} from './api/get-investor-portfolio-metrics/get-investor-portfolio-metrics.action';
import {
  GET_INVESTOR_PORTFOLIO_CACHE_KEY,
  getInvestorPortfolioAction,
  type InvestorPortfolioItem,
} from './api/get-investor-portfolio/get-investor-portfolio.action';
import {
  GET_PENDING_INVESTMENTS_INVESTOR_CACHE_KEY,
  getPendingInvestmentsAction,
} from './api/get-pending-investments.action';
import { RefetchPortfolioContext } from './context/refetch-portfolio.context';
import S from './investor-portfolio.styles';
import { CreateEditor } from './parts/editor/create-editor';
import { UpdateEditor } from './parts/editor/update-editor';
import { EmptyState } from './parts/empty-state/empty-state';
import { Table } from './parts/table/table';

const EmptyIconImage = () => <img src={EmptyPortfolioIcon} alt="empty-icon" loading="lazy" />;

const isDifferentDate = (lastInvestDate: DateTime, today: DateTime) => {
  if (lastInvestDate.toFormat('yyyy') !== today.toFormat('yyyy')) {
    return true;
  }

  if (lastInvestDate.toFormat('MM') !== today.toFormat('MM')) {
    return true;
  }

  return false;
};

export const getPortfolioValueData = (data: [Date, number][]): [Date, number][] => {
  if (!data.length) {
    return [];
  }

  const lastElement = data.slice(-1)[0];
  const lastInvestDate = DateTime.fromISO(lastElement[0] as unknown as string);
  const today = DateTime.now();

  if (lastInvestDate < today && isDifferentDate(lastInvestDate, today)) {
    return [...data, [today.toISO() as unknown as Date, lastElement[1]]];
  }
  return data;
};

export const InvestorPortfolio = ({ Layout = InvestorLayout }: { Layout?: Function }) => {
  const { [idQueryParam]: relationId, [investmentIdQueryParam]: investmentId } = useQueryParams() as {
    [idQueryParam]: string;
    [investmentIdQueryParam]: string;
  };
  const { data: pendingInvestments, isLoading: pendingInvestmentsLoading } = useQuery(
    [GET_PENDING_INVESTMENTS_INVESTOR_CACHE_KEY],
    getPendingInvestmentsAction,
  );

  const [founderId, setFounderId] = useState('');

  const { Drawer, show: showDrawer, hide: hideDrawer } = useDrawer(Boolean(relationId));

  const [
    emptyTitleLabel,
    emptySubTitleLabel,
    addInvestmentLabel,
    investmentsLabel,
    portfolioValueLabel,
    customerGroupLabel,
    locationsLabel,
    stageLabel,
    industryLabel,
    pageTitleLabel,
    noDataLabel,
  ] = useTranslation([
    'portfolio.investor.emptyState.title',
    'portfolio.investor.emptyState.subTitle',
    'portfolio.investor.emptyState.addInvestment',
    'portfolio.investor.graph.investments',
    'portfolio.investor.graph.portfolioValue',
    'portfolio.investor.graph.customerGroupLabel',
    'portfolio.investor.graph.locations',
    'portfolio.investor.graph.stage',
    'portfolio.investor.graph.industry',
    'portfolio.portfolio',
    'portfolio.investor.graph.noDataLabel',
  ]);

  const {
    items,
    total,
    isLoading,
    refetch: portfolioRefetch,
    additionalData,
    ...pagination
  } = useQueryPagination<InvestorPortfolioItem>({
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE_IN_GRID,
    pageParam: 'page',
    action: getInvestorPortfolioAction,
    cacheKey: GET_INVESTOR_PORTFOLIO_CACHE_KEY,
    excludedParams: [idQueryParam, investmentIdQueryParam],
    scrollToTopOnPageChanged: true,
    queryConfig: {
      refetchOnWindowFocus: true,
    },
  });

  const hasItems = total > 0;

  const { data: response, refetch: metricsRefetch } = useQuery(
    [GET_INVESTOR_PORTFOLIO_METRICS_CACHE_KEY],
    getInvestorPortfolioMetricsAction,
    {
      enabled: false,
    },
  );

  const refetch = () => {
    portfolioRefetch();
    metricsRefetch();
  };

  useEffect(() => {
    if (!relationId) {
      metricsRefetch();
    }
  }, [relationId]);

  if (isLoading) return <FullHeightSpinner />;

  const subTableRefs = items.map((item) => ({
    id: item.id,
    ref: createRef<{ refetchInvestments: Function }>(),
  }));

  const { totalInvested, totalInvestmentsCount, totalInvestmentsValue } = additionalData as {
    totalInvested: number;
    totalInvestmentsCount: number;
    totalInvestmentsValue: number;
  };

  const investmentsGraphData = response?.data?.investmentsHistory || [];
  const portfolioValueGraphData = getPortfolioValueData(response?.data?.portfolioValueHistory || []);
  const customerGroupsData = response?.data?.customerGroups || [];
  const locationsData = response?.data?.locations || [];
  const stageData = response?.data?.investmentStages || [];

  const pendingInvestmentsInvestor = (pendingInvestments?.data?.length || 0) > 0 && (
    <PendingInvestments data={pendingInvestments?.data || []} isLoading={pendingInvestmentsLoading} />
  );

  return (
    <RefetchPortfolioContext.Provider value={refetch}>
      <Layout>
        {investmentId ? (
          <UpdateEditor
            investmentId={investmentId}
            drawer={Drawer}
            hideDrawer={hideDrawer}
            refetch={() => portfolioRefetch()}
          />
        ) : (
          <CreateEditor
            id={relationId}
            founderId={founderId}
            setFounderId={setFounderId}
            drawer={Drawer}
            hideDrawer={hideDrawer}
            data-testid="Portolfio_Investment"
            refetch={() => portfolioRefetch()}
          />
        )}
        <PageHeader title={pageTitleLabel} />
        {(() => {
          if (!hasItems) {
            return (
              <>
                {pendingInvestmentsInvestor}
                <EmptyState
                  title={emptyTitleLabel}
                  subTitle={emptySubTitleLabel}
                  Icon={EmptyIconImage}
                  buttonLabel={addInvestmentLabel}
                  onClick={showDrawer}
                />
              </>
            );
          }

          return (
            <>
              {pendingInvestmentsInvestor}
              <Investments
                count={totalInvestmentsCount}
                invested={totalInvested}
                value={totalInvestmentsValue}
                addLabel={addInvestmentLabel}
                showDrawer={showDrawer}
              >
                <Table entityId={founderId} subTableRefs={subTableRefs} items={items as InvestorPortfolioItem[]} />
                {pagination && pagination.maxPages > 1 && <Pagination centered {...pagination} />}
              </Investments>
              {Boolean(investmentsGraphData.length || portfolioValueGraphData.length) && (
                <S.GraphWrapper>
                  <SplitedSection>
                    <LineBarGraph data={investmentsGraphData} title={investmentsLabel} noDataLabel={noDataLabel} />
                    <LineBarGraph
                      data={portfolioValueGraphData}
                      title={portfolioValueLabel}
                      noDataLabel={noDataLabel}
                    />
                    <CircularBarGraph data={customerGroupsData} title={customerGroupLabel} noDataLabel={noDataLabel} />
                    <CircularBarGraph data={locationsData} title={locationsLabel} noDataLabel={noDataLabel} />
                    <CircularBarGraph data={stageData} title={stageLabel} noDataLabel={noDataLabel} />
                    <ComingSoonGraph title={industryLabel} />
                  </SplitedSection>
                </S.GraphWrapper>
              )}
            </>
          );
        })()}
      </Layout>
    </RefetchPortfolioContext.Provider>
  );
};

import { V2Modal } from '@/components/ui/v2-modal/v2-modal';
import styled from 'styled-components';

const Modal = styled(V2Modal)`
  .ant-modal-content {
    position: relative;
    overflow: visible;
  }
`;

const BannerWrapper = styled.div`
  position: absolute;
  bottom: -80px;
  left: 0;
  width: 100%;
  box-shadow: 0px 12px 64px 2px rgba(0, 0, 0, 0.21);
`;

export default {
  Modal,
  BannerWrapper,
};

import { Slider as SliderBase } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.fieldset`
  text-align: center;
`;

const Slider = styled(SliderBase)`
  .ant-slider-track {
    background-color: ${({ theme }) => theme.baseColors.Blue200};
  }

  .ant-slider-handle {
    background-color: ${({ theme }) => theme.baseColors.Blue200};
  }

  &:hover {
    .ant-slider-track {
      background-color: ${({ theme }) => theme.baseColors.Blue150};
    }

    .ant-slider-handle {
      background-color: ${({ theme }) => theme.baseColors.Blue150};
    }
  }

  &.ant-slider-disabled {
    .ant-slider-track {
      background-color: ${({ theme }) => theme.baseColors.Blue200} !important;
      opacity: 0.8;
    }

    .ant-slider-handle {
      background-color: ${({ theme }) => theme.baseColors.Blue200} !important;
      opacity: 0.8;
    }
  }
`;

export default {
  Wrapper,
  Slider,
};

import { NumericConditions } from '@pages/content/public-profile/founder/api/set-up-numeric-trigger.actions';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import { mapQueryStringToGraphqlParams } from '@utils/fns/map-query-string-to-graphql-params';
import type { ParsedQuery } from 'query-string';
import type { GraphqlResponse } from 'src/types';
import type { FilteredQueryResponse } from '../get-investments-in-founder/get-investments-in-founder.action';
import { MetricType } from '../get-metrics/get-metrics.actions';

export interface Trigger {
  id: string;
  condition: NumericConditions | null;
  isActive: boolean;
  isMetricVisible: boolean;
  isConnected: boolean;
  value: number | null;
  rangeValue: number | null;
  isMet: boolean;
  companyName: string;
  companyId: string;
  metricType: MetricType;
  companyLogoUrl: string | null;
  metricId: string;
  metricScale: number;
  metricName: string;
  metricValue: number | null;
}

export const GET_TRIGGERS_ACTION_CACHE_KEY = 'get-investor-triggers';

export const getTriggersAction =
  (start: number, limit: number, excludedParams: string[], otherParams?: ParsedQuery<string>) =>
  async (): Promise<GraphqlResponse<FilteredQueryResponse & { items: Trigger[] }>> => {
    const params = otherParams && mapQueryStringToGraphqlParams(otherParams, excludedParams);

    const { data } = await axiosInstance.post('/query', {
      variables: params?.variables,
      query: `
      query getTriggers${params?.arguments} {
        getInvestorTriggers(start: ${start}, limit: ${limit}${params?.supplement}) {
          total
          hasItems
          items {
            id
            isActive
            isMetricVisible
            isConnected
            condition
            value
            rangeValue
            isMet
            companyName
            metricType,
            companyLogoUrl
            companyId
            metricId
            metricScale
            metricName
            metricValue
          }
          sortingRules
          filteringRules {
            key
            availableValues
            type
            allowedComparators
          }
        }
      }
    `,
    });

    return graphqlResponseWrapper(data, ['getInvestorTriggers']);
  };

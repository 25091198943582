import styled, { css } from 'styled-components';

import GridViewIconBase from '@assets/icons/grid-view-icon.svg?react';
import ListViewIconBase from '@assets/icons/list-view-icon.svg?react';

const iconStyles = css`
  margin: ${({ theme }) => theme.spacing.medium};
  margin-right: 0;
  cursor: pointer;
  transition: opacity 0.3s ease-in;
  opacity: 0.4;
`;

const DisplayMode = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    display: none;
  }
`;

const setActiveStyle = (active: boolean) => {
  if (active) {
    return css`
      opacity: 1;
      pointer-events: none;
    `;
  }
};

const GridViewIcon = styled<Icon>(GridViewIconBase)`
  ${iconStyles}
  ${({ $active }) => setActiveStyle($active)}
`;

const ListViewIcon = styled<Icon>(ListViewIconBase)`
  ${iconStyles}
  ${({ $active }) => setActiveStyle($active)}
`;

interface Icon extends React.FunctionComponent<React.SVGProps<SVGSVGElement> & { $active: boolean }> {}

export default { DisplayMode, GridViewIcon, ListViewIcon };

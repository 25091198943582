import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import imageDarkMode from '@assets/v2-images/advisor-quick-search-start-dark.webp';
import imageLightMode from '@assets/v2-images/advisor-quick-search-start-light.webp';
import type { StepProps } from '../types';

import S from './steps.styles';

export const WelcomeStep = ({ next }: StepProps) => {
  const [title, description, startButton] = useTranslation([
    'advisors-quick-search.modal-title',
    'advisors-quick-search.modal-description',
    'advisors-quick-search.modal-start-button',
  ]);

  return (
    <S.Container>
      <S.LightImage src={imageLightMode} width="204px" height="204px" />
      <S.DarkImage src={imageDarkMode} />
      <S.Title>{title}</S.Title>
      <S.Paragraph>{description}</S.Paragraph>
      <S.Button variant="primary" size="large" onClick={next} endIcon="arrow-right">
        {startButton}
      </S.Button>
    </S.Container>
  );
};

import { V2Button } from '@/components/ui/v2-button/v2-button';
import { V2Loader } from '@/components/ui/v2-loader/v2-loader';
import { V2TitleGroup } from '@/components/ui/v2-title-group/v2-title-group';
import { useDebounce } from '@/utils/hooks/use-debounce/use-debounce';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { Input } from 'antd';
import { useEffect } from 'react';
import { useSearchCompanies } from '../../../hooks/use-get-search-companies';

import { useAddExperienceContext } from '../add-experience-modal.context';
import S from '../add-experience-modal.styles';

type SelectCompanyProps = {
  next: () => void;
};

export const SelectCompany = ({ next }: SelectCompanyProps) => {
  const { state, dispatch } = useAddExperienceContext();
  const { companiesData, companiesMutate, isCompaniesDataLoading } = useSearchCompanies();
  const debouncedSearchTerm = useDebounce(state.searchPhrase, 500);

  const [title, description, cantFind, add, placeholder] = useTranslation([
    'profile.advisor.exp.modal.title',
    'profile.advisor.exp.modal.description',
    'profile.advisor.exp.modal.cantFind',
    'profile.advisor.exp.modal.add',
    'profile.advisor.exp.modal.placeholder',
  ]);

  useEffect(() => {
    if (debouncedSearchTerm.trim()) {
      companiesMutate(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, companiesMutate]);

  const onSelect = (id?: string) => {
    dispatch({ type: 'SET_COMPANY_ID', payload: id });
    next();
  };

  return (
    <S.Container>
      <V2TitleGroup title={title} subtitle={description} icon="search" align="center" />
      <S.InputWrapper>
        <Input
          placeholder={placeholder}
          value={state.searchPhrase}
          onChange={(e) => dispatch({ type: 'SET_SEARCH_PHRASE', payload: e.target.value })}
        />
        {state.searchPhrase && (
          <S.CompaniesList
            bordered
            dataSource={companiesData?.items}
            header={isCompaniesDataLoading && <V2Loader />}
            renderItem={(i) => {
              const item = i as { id: string; name: string };
              return (
                <S.CompaniesList.Item>
                  <S.InvisibleButton onClick={() => onSelect(item.id)}>{item.name}</S.InvisibleButton>
                </S.CompaniesList.Item>
              );
            }}
            footer={
              <S.InvisibleButton onClick={() => onSelect()}>
                <p>{cantFind}</p>
                <V2Button startIcon="plus" variant="link">
                  {`${add} ${state.searchPhrase.slice(0, 30)} ${state.searchPhrase.length > 30 ? '...' : ''}`}
                </V2Button>
              </S.InvisibleButton>
            }
          />
        )}
      </S.InputWrapper>
    </S.Container>
  );
};

import type { DrawerProps as DrawerPropsBase } from 'antd/lib/drawer';
import { memo, type ReactNode } from 'react';
import S from './drawer.styles';

interface DrawerProps extends DrawerPropsBase {
  children: ReactNode | ReactNode[];
  closeDrawer: () => void;
  visible: boolean;
  footer?: ReactNode;
  title?: string | ReactNode;
  width: number | string;
}

const Drawer = ({ children, closeDrawer, visible, footer, title, onClose, width, ...rest }: DrawerProps) => (
  <S.Drawer
    visible={visible}
    title={title}
    onClose={onClose || closeDrawer}
    footer={footer}
    data-testid="drawer"
    width={width}
    {...rest}
  >
    {children}
  </S.Drawer>
);

export default memo(Drawer);

import { FounderLayout } from '@layout/founder/founder.layout';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { useQuery } from '@tanstack/react-query';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useParams } from 'react-router-dom';
import {
  GET_NED_PUBLIC_PAGE_DETAILS_CACHE_KEY,
  getNedPublicProfileDetailsAction,
} from './api/get-ned-public-page-details';
import { NedPublicProfile } from './parts/ned-public-profile';
import { QuestionContact } from '@/pages/utility/parts/question-contact/question-contact';
import { EmptyState } from '@parts/empty-state/empty-state';
import SecuredIcon from '@assets/icons/undraw-secured.svg';
import { LinkButtonA } from '@parts/link-button/link-button';
import { Links } from '@/router/links';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';

import S from './ned.page.styles';

const EXPECTED_ERROR_MESSAGE =
  'Founder and Ned accounts must be connected or Both founder and Ned accounts must be published or Advisor must apply for your role before conversation.';

const EmptyIconImage = () => <img src={SecuredIcon} alt="secured-icon" loading="lazy" />;

const NotAllowedErrorContent = () => {
  const [title, subTitle, buttonText] = useTranslation([
    'profile.ned.publicProfile.expectedError.title',
    'profile.ned.publicProfile.expectedError.subTitle',
    'profile.ned.publicProfile.expectedError.button.text',
  ]);

  const trimmedErrorMessage =
    EXPECTED_ERROR_MESSAGE[EXPECTED_ERROR_MESSAGE.length - 1] === '.'
      ? EXPECTED_ERROR_MESSAGE.slice(0, -1)
      : EXPECTED_ERROR_MESSAGE;

  const errorList = trimmedErrorMessage.split(' or ');

  return (
    <S.Wrapper>
      <EmptyState Icon={EmptyIconImage} title={title} subTitle={subTitle}>
        <S.DescriptionList>{errorList?.map((errorPart, index) => <li key={index}>{errorPart}</li>)}</S.DescriptionList>
        <S.ButtonWrapper>
          <LinkButtonA href={Links.PROFILE()}>{buttonText}</LinkButtonA>
        </S.ButtonWrapper>
        <QuestionContact />
      </EmptyState>
    </S.Wrapper>
  );
};

export const NedPublicPage = () => {
  const { id } = useParams() as { id: string };
  const history = useHistory();

  const { data: response, isLoading } = useQuery(
    [GET_NED_PUBLIC_PAGE_DETAILS_CACHE_KEY, id],
    getNedPublicProfileDetailsAction(id),
    { cacheTime: 0 },
  );

  if (response && response.errors && response?.errors[0].message === EXPECTED_ERROR_MESSAGE) {
    return (
      <FounderLayout>
        <NotAllowedErrorContent />
      </FounderLayout>
    );
  }

  if (isLoading || !response || !response.data) {
    return (
      <FounderLayout>
        <FullHeightSpinner />
      </FounderLayout>
    );
  }

  return (
    <FounderLayout>
      <NedPublicProfile id={id} publicData={response.data} handleBackAction={history.goBack} />
    </FounderLayout>
  );
};

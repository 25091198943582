import { theme as themeConfig } from '@styles/theme-config';
import styled from 'styled-components';

const Line = styled.div`
  height: 4px;
  width: 15px;
  border-radius: 2px;
`;

const Forecast = styled(Line)`
  background: ${themeConfig.baseColors.Red};
`;

const Value = styled(Line)`
  background: ${themeConfig.baseColors.Blue200};
`;

export default { Forecast, Value };

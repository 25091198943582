import { axiosInstance } from '@utils/axios/axios-instance';
import type { AxiosConfig } from '@utils/axios/types';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_PROGRESS_REPORT_ACCESS_CONFIG_CACHE_KEY = (hash: string) => `progress-report-access-config-${hash}`;

interface ProgressReportAccessConfig {
  isExpired: boolean;
  isPasswordProtected: boolean;
}

export const getProgressReportAccessConfig =
  (hash: string) => async (): Promise<GraphqlResponse<ProgressReportAccessConfig>> => {
    const { data } = await axiosInstance.post(
      '/query',
      {
        query: `
      query getProgressReportAccessConfig {
        getPublicDashboardAccessState(hash: "${hash}") {
            isExpired
            isPasswordProtected
        }
      }
    `,
      },
      { skipAuthorization: true } as AxiosConfig,
    );

    return graphqlResponseWrapper(data, ['getPublicDashboardAccessState']);
  };

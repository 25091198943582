import { isActiveInvestor } from '@abilities';
import { Roles } from '@domain/accounts/roles';
import { FounderPulsePage } from '@pages/content/pulse/founder/founder-pulse.page';
import { InvestorPulsePage } from '@pages/content/pulse/investor/investor-pulse.page';
import { NotFoundPage } from '@pages/utility/not-found/not-found.page';
import { Links } from '@router/links';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { Redirect } from 'react-router-dom';

export const PulsePage = () => {
  const {
    state: { userRole, products },
  } = useUserAccount();

  const redirectToProfile = <Redirect to={Links.PROFILE()} />;

  switch (userRole) {
    case Roles.FOUNDER:
      return <FounderPulsePage />;
    case Roles.INVESTOR:
      return <InvestorPulsePage />;
    case Roles.INVESTOR_NED: {
      if (!isActiveInvestor(products)) {
        return redirectToProfile;
      }
      return <InvestorPulsePage />;
    }
    case Roles.NED:
      return redirectToProfile;
    default:
      return <NotFoundPage />;
  }
};

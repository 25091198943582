import styled from 'styled-components';
import { Radio } from 'antd';
import type { RadioVariant } from './v2-radio';

const V2Radio = styled(Radio)<{ variant?: RadioVariant }>`
  margin: 6px 6px 6px 0;
  display: inline-flex;
  width: 100%;
  white-space: break-spaces;
  align-items: center;
  background-color: inherit;
  white-space: nowrap;

  font-size: ${({ variant }) => (variant === 'secondary' ? '16px' : '14px')};

  .ant-radio-inner {
    border-color: ${({ theme }) => theme.v2.colors.blue[400]};
    background-color: inherit;
    width: ${({ variant }) => (variant === 'primary' ? '16px' : '20px')};
    height: ${({ variant }) => (variant === 'primary' ? '16px' : '20px')};
  }

  .ant-radio-inner:after {
    background-color: ${({ theme }) => theme.v2.colors.blue[300]};
    width: ${({ variant }) => (variant === 'primary' ? '10px' : '14px')};
    height: ${({ variant }) => (variant === 'primary' ? '10px' : '14px')};
  }

  .ant-radio-checked .ant-radio-inner {
    background-color: inherit;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: ${({ theme }) => theme.v2.colors.blue[300]};
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: none;
  }

  .ant-radio-disabled {
    + span {
      color: inherit;
      opacity: 0.5;

      .ant-radio-input:checked + .ant-radio-inner {
        background-color: ${({ theme }) => theme.v2.colors.blue[300]};
      }
    }

    .ant-radio-inner {
      opacity: 0.5;
      border-color: ${({ theme }) => theme.v2.colors.blue[300]} !important;

      ::after {
        background-color: ${({ theme }) => theme.v2.colors.blue[300]};
      }
    }
  }
`;

export default { V2Radio };

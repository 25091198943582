import { Layout as LayoutBase } from 'antd';
import styled from 'styled-components';

const Wrapper = styled(LayoutBase.Content)`
  margin: 0 auto;
  padding: 0 10px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    padding: 0 ${({ theme }) => theme.spacing.xsmall};
    margin: 0;
  }
`;

export default { Wrapper };

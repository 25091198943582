import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const Card = styled.div`
  user-select: none;
  min-width: 300px;
  padding: 20px;
  border-radius: 6px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 16px;
  background: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[50], theme.v2.colors.blue[700])};
  border: 1px solid
    ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[100], theme.v2.colors.blue[600])};
`;

const Head = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[200], theme.v2.colors.blue[800])};
  border-radius: 6px;
  width: 100%;
  padding: 12px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`;

const List = styled.div`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  gap: 8px;
  margin: 4px 0;
`;

const Detail = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: start;
  gap: 8px;
  font-weight: ${({ theme }) => theme.v2.typography.weights.medium};
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[500], theme.v2.colors.neutral[300])};
`;

const Action = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: start;
  gap: 8px;
  font-weight: ${({ theme }) => theme.v2.typography.weights.medium};
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[300], theme.v2.colors.blue[300])};

  transition: all 150ms ease;

  &:hover {
    cursor: pointer;
    filter: brightness(1.32);
  }

  &:active {
    filter: brightness(1.48);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    filter: brightness(1);
  }
`;

const Body = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const Footer = styled.div`
  width: 100%;
`;

const Divider = styled.span`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.v2.colors.blue[300]};
  display: block;
  margin-top: ${({ theme }) => theme.spacing.xxsmall};
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
`;

export default { Card, Head, Row, List, Detail, Action, Body, Footer, Divider };

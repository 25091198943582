import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import type { V2Theme } from '@/styles/v2-theme/v2-theme';
import { createElement } from 'react';
import styled from 'styled-components';

type TitleProps = {
  size: keyof V2Theme['typography']['sizes'];
  weight: keyof V2Theme['typography']['weights'];
  family: keyof V2Theme['typography']['fonts'];
};

const Title = styled(({ tag = 'h1', ...props }) => createElement(tag, props))<TitleProps>`
  width: 100%;
  white-space: wrap;
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.neutral[50])};

  font-size: ${({ size, theme }) => theme.v2.typography.sizes[size]};
  font-weight: ${({ weight, theme }) => theme.v2.typography.weights[weight]};
  font-family: ${({ family, theme }) => theme.v2.typography.fonts[family]};
  line-height: 1.25;
`;

export default { Title };

import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import Modal from 'antd/lib/modal/Modal';
import styled, { css } from 'styled-components';

const V2Modal = styled(Modal)`
  top: 45px;
  max-width: calc(100% - 5px * 2);

  ${customMediaQuery('top', {
    lg: '65px',
  })}

  ${customMediaQuery('max-width', {
    lg: '100%',
  })}

  .ant-modal-content {
    overflow: hidden;
    border-radius: 6px;
    background-color: ${({ theme }) =>
      themedStyle(theme.v2.mode, theme.v2.colors.neutral[50], theme.v2.colors.blue[700])};
    border: 1px solid ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[50], theme.v2.colors.blue[500])};
  }

  .ant-modal-body {
    padding: 0;
    color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[700], theme.v2.colors.neutral[50])};
  }

  .ant-modal-header {
    padding: 12px 16px;
    background-color: ${({ theme }) =>
      themedStyle(theme.v2.mode, theme.v2.colors.neutral[100], theme.v2.colors.blue[800])};
    border-bottom: 0 none;

    .ant-modal-title {
      color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.neutral[50])};
      font-size: ${({ theme }) => theme.v2.typography.sizes.md};
      font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
    }
  }

  .ant-modal-close {
    color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.neutral[50])};

    .ant-modal-close-x {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 46px;
      height: 46px;
      transform: translate(1px, -1px);
    }
  }

  .ant-modal-footer {
    padding: 12px 16px;
    border-top: 0 none;
    box-shadow: ${({ theme }) => themedStyle(theme.v2.mode, `0px 2px 20px 0px ${theme.v2.colors.blue[50]}66`, 'none')};

    ${({ theme }) =>
      themedStyle(
        theme.v2.mode,
        css`
          box-shadow: 0px 2px 20px 0px ${theme.v2.colors.blue[50]}66;
        `,
        css`
          border-top: 1px solid ${theme.v2.colors.blue[500]};
        `,
      )}

    button {
      + button {
        margin-left: 12px;
      }
    }
  }
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    > button {
      width: calc(50% - (12px / 2));
    }
  }
`;

const ContentWrapper = styled.div`
  padding: 12px 16px;
  background-color: ${({ theme }) =>
    themedStyle(theme.v2.mode, theme.v2.colors.neutral[50], theme.v2.colors.blue[700])};
`;

const ProgressBarWrapper = styled.div`
  transform: translateY(7px);
  overflow: hidden;
`;

export default { V2Modal, ModalFooter, ContentWrapper, ProgressBarWrapper };

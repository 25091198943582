import CheckboxFormikFieldBase from '@pages/content/profile/parts/checkbox-formik-field/checkbox-formik-field';
import UploadedFileBase from '@pages/content/profile/parts/uploaded-file/uploaded-file';
import styled from 'styled-components';

export const UploadedFile = styled(UploadedFileBase)`
  &:not(:last-of-type) {
    margin-bottom: ${({ theme }) => theme.spacing.small};
  }
`;

export const CheckboxFormikField = styled(CheckboxFormikFieldBase)`
  label {
    margin: 0;
  }
`;

export default { UploadedFile, CheckboxFormikField };

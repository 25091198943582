import { AdvisorRole } from '@/domain/accounts/roles';
import { Links } from '@/router/links';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import imageDarkMode from '@assets/v2-images/advisor-quick-search-end-dark.webp';
import imageLightMode from '@assets/v2-images/advisor-quick-search-end-light.webp';
import { useHistory } from 'react-router-dom';
import type { StepProps } from '../types';
import { createAdvisorSearchSearchQuery } from './utils';

import S from './steps.styles';

export const FinalStep = ({ initialValues, close }: StepProps) => {
  const history = useHistory();
  const [view, title, description] = useTranslation([
    'advisors-quick-search.modal-view-matches-button',
    'advisors-quick-search.final-step.title',
    'advisors-quick-search.final-step.description',
  ]);

  const submitFn = (advisorType: AdvisorRole | null, skillSet: string[] | null) => {
    const allowedTypes = [AdvisorRole.PLACEMENT, AdvisorRole.ADVISOR, AdvisorRole.NED, AdvisorRole.FRACTIONAL];

    if (!advisorType || !allowedTypes.includes(advisorType)) {
      return close();
    }

    const query = createAdvisorSearchSearchQuery({ filters: { skillSet: skillSet || [] }, regions: [] }, advisorType);
    return history.push(Links.ADVISORS_SEARCH_ROLE(advisorType.toLowerCase()) + '?' + query);
  };

  return (
    <S.Container>
      <S.LightImage src={imageLightMode} width="204px" height="204px" />
      <S.DarkImage src={imageDarkMode} />
      <S.Title>{title}</S.Title>
      <S.Paragraph>{description}</S.Paragraph>
      <S.Button
        variant="primary"
        size="large"
        onClick={() => submitFn(initialValues?.advisorType, initialValues?.skillSet)}
        endIcon="arrow-right"
      >
        {view}
      </S.Button>
    </S.Container>
  );
};

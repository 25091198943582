import { engageNedMessagingAction } from '@pages/content/roles/parts/applications/api/message-engage-ned';
import { Table as TableBase } from '@parts/table/table';
import { useMutation, type QueryObserverResult, type RefetchOptions } from '@tanstack/react-query';
import finishUpdate from '@utils/fns/finish-update';
import useConfirmModal from '@utils/hooks/use-confirm-modal/use-confirm-modal';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useEngageConversation } from '@utils/hooks/use-engage-conversation/use-engage-conversation';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import type { GraphqlResponse } from 'src/types';
import { applicationApproveAction } from '../../../api/application-approve';
import { applicationRejectAction } from '../../../api/application-reject';
import type { RoleApplicationsResponse } from '../../../api/get-role-application.actions';
import { Columns } from './columns';
import S from './table.styles';

export const Table = ({
  applications,
  refetchApplications,
}: {
  applications: RoleApplicationsResponse[];
  refetchApplications: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<GraphqlResponse<RoleApplicationsResponse[]>, unknown>>;
}) => {
  const {
    deviceData: { isDesktop },
  } = useDeviceDetect();

  const { mutateAsync: message } = useEngageConversation(engageNedMessagingAction);

  const translations = useTranslation([
    'roles.applications.table.name',
    'roles.applications.table.location',
    'roles.applications.table.applied',
    'roles.applications.table.cv',
    'roles.applications.table.chat',
    'roles.applications.table.invited',
    'roles.applications.table.rejected',
  ]);

  const [
    approveHeader,
    approveText,
    rejectHeader,
    rejectText,
    successApproveFirst,
    successApproveSecond,
    successRejectFirst,
    successRejectSecond,
  ] = useTranslation([
    'roles.applications.modal.approve.header',
    'roles.applications.modal.approve.text',
    'roles.applications.modal.reject.header',
    'roles.applications.modal.reject.text',
    'roles.applications.approve.success.first',
    'roles.applications.approve.success.second',
    'roles.applications.reject.success.first',
    'roles.applications.reject.success.second',
  ]);

  const [currentApplicationId, setCurrentApplicationId] = useState<string | null>(null);

  const { mutateAsync: approve } = useMutation(applicationApproveAction, {
    onSuccess: () => {
      refetchApplications();
      setCurrentApplicationId(null);
    },
  });

  const { mutateAsync: reject } = useMutation(applicationRejectAction, {
    onSuccess: () => setCurrentApplicationId(null),
  });

  const onMessage = (nedId: string) => message(nedId);

  const applicantName = applications.find((app) => app.id === currentApplicationId)?.name || '';

  const apiSuccessMessage = (prefix: string, text: string, suffix: string) => (
    <p>
      <span>{prefix}</span>
      <strong>{text}</strong>
      <span>{suffix}</span>
    </p>
  );

  const confirmRejection = () => {
    if (!currentApplicationId) return;

    finishUpdate(
      reject(currentApplicationId).then(() => refetchApplications()),
      apiSuccessMessage(successRejectFirst, applicantName, successRejectSecond),
    );
  };

  const confirmApproval = () => {
    if (!currentApplicationId) return;

    finishUpdate(
      approve(currentApplicationId).then(() => refetchApplications()),
      apiSuccessMessage(successApproveFirst, applicantName, successApproveSecond),
    );
  };

  const { modal: confirmApplicationApprove, show: showConfirmApplicationApprove } = useConfirmModal({
    title: approveHeader,
    content: `${approveText} ${applicantName}?`,
    onConfirm: confirmApproval,
  });

  const { modal: confirmApplicationReject, show: showConfirmApplicationReject } = useConfirmModal({
    title: rejectHeader,
    content: `${rejectText} ${applicantName}?`,
    onConfirm: confirmRejection,
  });

  const onApprove = (id: string) => {
    setCurrentApplicationId(id);
    showConfirmApplicationApprove();
  };

  const onReject = (id: string) => {
    setCurrentApplicationId(id);
    showConfirmApplicationReject();
  };

  return (
    <S.Wrapper>
      <TableBase
        columns={
          Columns({
            translations,
            onMessage,
            onApprove,
            onReject,
            oneColumn: !isDesktop,
          }) as ColumnsType<object>
        }
        rowKey="id"
        dataSource={applications}
      />

      {confirmApplicationApprove}
      {confirmApplicationReject}
    </S.Wrapper>
  );
};

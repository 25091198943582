import type { SmartMatchOptionsResponseData } from '@pages/content/profile/api/get-smart-match-options/get-smart-match-options.action';
import { FormikField } from '@pages/content/profile/parts/formik-field/formik-field';
import { CountriesRegionsSelect } from '@parts/countries-regions-select/countries-regions-select';
import { SubmitButton as Apply } from '@parts/submit-button/submit-button';
import { sortArrayAlphabetically } from '@utils/fns/sortArrayAlphabetically';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import { RegionNamesMap, RegionValue } from '@utils/type-definitions/regions-values';
import { Col, Row } from 'antd';
import type { SelectValue } from 'antd/lib/select';
import { useFormik } from 'formik';
import { Header } from '../../../parts/header/header';
import type { LensFilters } from '../../ned-lens.page';
import { useValidation } from './validation';

import S from '@pages/content/lens/parts/filters/filters.styles';

type FiltersFormData = LensFilters;

type FiltersProps = {
  initialValues: FiltersFormData;
  onSubmit: (values: Partial<FiltersFormData>) => void;
  options: {
    smartMatchOptions: SmartMatchOptionsResponseData;
    availableRegions: RegionValue[];
    availableCountries: CountryCodes[];
    notUsedCountries: CountryCodes[];
  };
};

export const Filters = ({
  initialValues,
  onSubmit,
  options: { smartMatchOptions, availableRegions, availableCountries, notUsedCountries },
}: FiltersProps) => {
  const [
    apply,
    header,
    countriesAndRegionsLabel,
    industryLabel,
    selectPlaceholder,
    customerGroupLabel,
    productStageLabel,
  ] = useTranslation([
    'lens.filters.apply',
    'lens.filters.header',
    'lens.filters.countriesAndRegions',
    'lens.filters.industry',
    'lens.filters.selectPlaceholder',
    'lens.filters.customerGroup',
    'lens.filters.productStage',
  ]);

  const {
    industries: allIndustriesList,
    customerGroups: allCustomerGroups,
    productStages: allProductStages,
  } = smartMatchOptions;
  const { submitForm, isValid, setFieldValue, setFieldTouched, errors, touched, getFieldProps } =
    useFormik<FiltersFormData>({
      initialValues,
      onSubmit,
      validateOnMount: true,
      enableReinitialize: true,
      validationSchema: useValidation(),
    });

  const handleSelectChange = (value: SelectValue, field: string) => {
    setFieldValue(field, value);
    setFieldTouched(field, true);
  };

  const handleSelectCountryRegionChange = (countriesKey: string, regionsKey: string) => (value: SelectValue) => {
    const eventValues = Array.isArray(value) ? value : [];

    const countriesData = eventValues.filter((item) => Object.values(CountryCodes).includes(item as CountryCodes));
    const regionsData = eventValues.filter((item) => Object.keys(RegionNamesMap).includes(item as RegionValue));

    setFieldValue(countriesKey, countriesData);
    setFieldTouched(countriesKey, true);

    setFieldValue(regionsKey, regionsData);
    setFieldTouched(regionsKey, true);
  };

  return (
    <S.Collapse bordered={false} defaultActiveKey={['filters']}>
      <S.Collapse.Panel showArrow={false} header={<Header title={header} icon={<S.Arrow />} />} key="filters">
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} xl={16} xxl={8}>
            <FormikField
              label={{
                for: 'countriesAndRegions',
                label: countriesAndRegionsLabel,
              }}
            >
              <CountriesRegionsSelect
                regions={availableRegions}
                countriesOfResidence={availableCountries}
                notUsedCountries={notUsedCountries}
                onChange={handleSelectCountryRegionChange('countriesOfResidence', 'regions')}
                value={[
                  ...(getFieldProps('countriesOfResidence').value ?? []),
                  ...(getFieldProps('regions').value ?? []),
                ]}
              />
            </FormikField>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} xl={8}>
            <FormikField
              label={{
                for: 'industry',
                label: industryLabel,
              }}
              error={errors.industry ? (errors.industry as string) : undefined}
              touched={touched.industry}
            >
              <S.MultiSelectSearch
                placeholder={selectPlaceholder}
                {...getFieldProps('industry')}
                options={sortArrayAlphabetically(allIndustriesList)}
                onChange={(value: SelectValue) => handleSelectChange(value, 'industry')}
                maxTagCount="responsive"
              />
            </FormikField>
          </Col>
          <Col xs={24} sm={24} md={12} xl={8}>
            <FormikField
              label={{
                for: 'productStage',
                label: productStageLabel,
              }}
              touched={touched.productStage}
            >
              <S.MultiSelectSearch
                {...getFieldProps('productStage')}
                onChange={(value: SelectValue) => handleSelectChange(value, 'productStage')}
                placeholder={selectPlaceholder}
                options={allProductStages}
                maxTagCount="responsive"
              />
            </FormikField>
          </Col>
          <Col xs={24} sm={24} md={12} xl={8}>
            <FormikField
              label={{
                for: 'customerGroup',
                label: customerGroupLabel,
              }}
              error={errors.customerGroup ? (errors.customerGroup as string) : undefined}
              touched={touched.customerGroup}
            >
              <S.MultiSelectSearch
                placeholder={selectPlaceholder}
                {...getFieldProps('customerGroup')}
                options={allCustomerGroups}
                onChange={(value: SelectValue) => handleSelectChange(value, 'customerGroup')}
                maxTagCount="responsive"
              />
            </FormikField>
          </Col>
        </Row>

        <Row>
          <Apply onClick={submitForm} disabled={!isValid}>
            {apply}
          </Apply>
        </Row>
      </S.Collapse.Panel>
    </S.Collapse>
  );
};

import BareCheckIcon from '@assets/icons/bare-check.svg?react';
import { FileUpload } from '@parts/file-upload/file-upload';
import { SubmitButton } from '@parts/submit-button/submit-button';
import styled, { css } from 'styled-components';
import Section from '../section/section';

const Wrapper = styled(Section)<{ disabled: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const CvPresent = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  text-align: center;
  color: ${({ theme }) => theme.color.fontPrimary};
  letter-spacing: 0.22px;
  line-height: 150%;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  flex: 100%;
`;

const CvUpload = styled(FileUpload)`
  flex: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`;

const Applied = styled.div`
  border: 2px solid ${({ theme }) => theme.baseColors.Green};
  padding: ${({ theme }) => theme.spacing.small};
  border-radius: ${({ theme }) => theme.radius.default};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontSize.xmedium};
  flex-basis: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ApplyBtn = styled(SubmitButton)``;

const Check = styled(BareCheckIcon)`
  margin-right: 5px;

  path {
    fill: ${({ theme }) => theme.baseColors.Green};
  }
`;

export default { CvPresent, ApplyBtn, Wrapper, CvUpload, Applied, Check };

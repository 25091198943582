import DisconnectIcon from '@assets/icons/unfriend.svg?react';
import { Roles } from '@domain/accounts/roles';
import { MessageSymbol } from '@parts/message-symbol/message-symbol';
import { TableColumn } from '@parts/table-column/table-column';
import type { MutateFunction } from '@tanstack/react-query';
import type { ColumnsType } from 'antd/lib/table';
import * as H from 'history';
import { UserTableRow } from '../../../../../../../../parts/user-table-row/user-table-row';
import type { ConnectedCompany } from './table';
import S from './table.styles';

export const Columns = ({
  message,
  isMessageLoading,
  onDisconnect,
  disconnectInProgress,
  translations,
  history,
}: {
  message: MutateFunction<{ data: string }, unknown, string, unknown>;
  isMessageLoading: boolean;
  onDisconnect: (id: string) => void;
  disconnectInProgress: boolean;
  translations: { [key: string]: string };
  history: H.History<H.LocationState>;
}): ColumnsType<ConnectedCompany> => [
  UserTableRow({
    history,
    toRole: Roles.FOUNDER,
    title: translations.companyNameLabel,
    nameKey: 'name',
    idKey: 'id',
    avatarUrlKey: 'investor.avatarUrl',
    redirectEvaluation: [
      {
        shouldBePresent: true,
        key: 'id',
      },
    ],
  }),
  {
    title: translations.industryLabel,
    dataIndex: 'industry',
    key: 'industry',
    width: '20%',
    render: (value) => <TableColumn title={translations.industryLabel}>{value.join(' ')}</TableColumn>,
  },
  {
    title: translations.investmentStageLabel,
    dataIndex: 'productStage',
    key: 'productStage',
    width: '15%',
    render: (stage) => <TableColumn title={translations.investmentStageLabel}>{stage}</TableColumn>,
  },
  {
    title: translations.foundingStatusLabel,
    dataIndex: 'investmentStage',
    key: 'investmentStage',
    width: '15%',
    render: (stage) => <TableColumn title={translations.foundingStatusLabel}>{stage}</TableColumn>,
  },
  {
    title: translations.chatLabel,
    dataIndex: 'chat',
    key: 'chat',
    render: (_, row) => (
      <S.TableColumn title={translations.chatLabel}>
        <S.SubmitButton
          data-testid="tracked-companies-message"
          type="link"
          onClick={() => message(row.id)}
          disabled={!row.canBePreviewed || isMessageLoading}
        >
          <MessageSymbol />
        </S.SubmitButton>
      </S.TableColumn>
    ),
    align: 'center',
    width: 50,
  },
  {
    title: translations.disconnectLabel,
    dataIndex: 'disconnect',
    key: 'disconnect',
    render: (_, row) => (
      <S.TableColumn title={translations.disconnectLabel}>
        <S.SubmitButton disabled={disconnectInProgress} type="link" onClick={() => onDisconnect(row.id)}>
          <DisconnectIcon />
        </S.SubmitButton>
      </S.TableColumn>
    ),
    align: 'center',
    width: 50,
  },
];

import { V2FilterPanel } from '@/components/ui/v2-filter-panel/v2-filter-panel';
import { V2MobileFilters } from '@/components/ui/v2-mobile-filters/v2-mobile-filters';
import { useMediaQuery } from '@/hooks/use-media-query';
import { TranslationText } from '@/parts/translation-text/translation-text';
import type { Sorter } from '@/utils/type-definitions/filters';
import type { ReactNode } from 'react';

export type AdvisorConnectionsFiltersProps = {
  initialSearchValue?: string;
  onSearchSubmit: (value: string) => void;
  sortValue: Sorter | undefined;
  sortOptions?: { label: ReactNode; key: string }[];
  submit: (payload: { filters: string[]; search: string; sort: Sorter | undefined }) => void;
  disabled?: boolean;
};

export const AdvisorConnectionsFilters = ({
  initialSearchValue,
  onSearchSubmit,
  sortValue,
  sortOptions = [],
  submit,
  disabled = false,
}: AdvisorConnectionsFiltersProps) => {
  const breakpoint = useMediaQuery();
  const isMobile = ['xs', 'sm'].includes(breakpoint);

  return isMobile ? (
    <V2MobileFilters
      showElements={['sort', 'search']}
      triggerLabel={<TranslationText id="connections.filters.button" />}
      initialSearchValue={initialSearchValue}
      sortValue={sortValue}
      sortOptions={sortOptions}
      submit={submit}
      disabled={disabled}
    />
  ) : (
    <V2FilterPanel
      filters={[]}
      search
      initialSearchValue={initialSearchValue}
      onSearchSubmit={onSearchSubmit}
      disabled={disabled}
    />
  );
};

import { Input } from 'antd';
import styled from 'styled-components';

const LoadingPlaceholder = styled(Input)`
  height: 50px;
`;

export default {
  LoadingPlaceholder,
};

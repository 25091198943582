import styled from 'styled-components';

const Header = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacing.xsmall};
`;

const Title = styled.p`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-right: ${({ theme }) => theme.spacing.xsmall};
  color: ${({ theme }) => theme.color.fontPrimary};
`;

export default {
  Header,
  Title,
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_PENDING_INVESTMENTS_FOUNDER_CACHE_KEY = 'get-pending-investments-founder';

export interface PendingInvestmentsFounder {
  id: string;
  round: {
    date: Date | string;
    type: string;
  };
  shares: number | null;
  amount: number;
  percentageOwnership: number | null;
  investor: {
    id: string;
    name: string;
    avatarUrl: string | null;
    isConnected: boolean;
    isFollowRequestPending: boolean;
  };
}

export const getPendingInvestmentsAction = async (): Promise<GraphqlResponse<PendingInvestmentsFounder[]>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getPendingInvestments {
        pendingShareholderRequests{
            id
            round {
              date
              type
            } 
            shares
            amount
            percentageOwnership
            investor {
              id
              name
              avatarUrl
              isConnected
              isFollowRequestPending
            }
        }
      }
  `,
  });

  return graphqlResponseWrapper(data, ['pendingShareholderRequests']);
};

import LogoSquareBase from '@assets/brand/logo-square.svg?react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled(Link)``;

const Logo = styled(LogoSquareBase)`
  fill: ${({ theme }) => theme.color.iconFill};
`;

export default { Wrapper, Logo };

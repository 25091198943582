import type {
  IGetInvestorInvestment,
  IGetInvestorPersonalDetails,
  IInvestorSmartMatch,
} from '../../investor/api/types';
import type { INedAbout, INedExperience, INedSmartMatch } from '../../ned/ned-profile.page';
import {
  SET_INVESTOR_DATA_LOADED,
  SET_INVESTOR_INVESTMENTS,
  SET_INVESTOR_SMART_MATCH,
  SET_NED_ABOUT_DATA,
  SET_NED_BOARD_EXPERIENCE,
  SET_NED_DATA_LOADED,
  SET_NED_RESUME,
  SET_NED_SMART_MATCH,
  SET_PERSONAL_DETAILS,
  type InvestorNedActionType,
} from './investor-ned.reducer';

export const setInvestorNedPersonalDetails: (value: IGetInvestorPersonalDetails) => InvestorNedActionType = (
  value,
) => ({
  type: SET_PERSONAL_DETAILS,
  value,
});

export const setInvestorNedAboutMe: (value: INedAbout) => InvestorNedActionType = (value) => ({
  type: SET_NED_ABOUT_DATA,
  value,
});

export const setInvestorNedInvestorSmartMatch: (value: IInvestorSmartMatch) => InvestorNedActionType = (value) => ({
  type: SET_INVESTOR_SMART_MATCH,
  value,
});

export const setInvestorNedInvestorInvestments: (value: IGetInvestorInvestment) => InvestorNedActionType = (value) => ({
  type: SET_INVESTOR_INVESTMENTS,
  value,
});

export const setInvestorNedResume: (value: {
  resumePath: string;
  resumeName: string;
  resumeUrl: string;
}) => InvestorNedActionType = (value) => ({
  type: SET_NED_RESUME,
  value,
});

export const setInvestorNedBoardExperience: (value: INedExperience[]) => InvestorNedActionType = (value) => ({
  type: SET_NED_BOARD_EXPERIENCE,
  value,
});

export const setInvestorNedSmartMatch: (value: INedSmartMatch) => InvestorNedActionType = (value) => ({
  type: SET_NED_SMART_MATCH,
  value,
});

export const setInvestorNedNedDataLoaded: (value: boolean) => InvestorNedActionType = (value) => ({
  type: SET_NED_DATA_LOADED,
  value,
});

export const setInvestorNedInvestorDataLoaded: (value: boolean) => InvestorNedActionType = (value) => ({
  type: SET_INVESTOR_DATA_LOADED,
  value,
});

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';
import type { OnboardingStatus } from '@/domain/accounts/types';

type FounderQualifyingQuestionsDataResponse = {
  onboardingStatus: {
    founderQualifyingQuestions: OnboardingStatus;
  };
};

export const HOMEPAGE_QUALIFYING_QUESTIONS_STATE = 'homepage-founder-qualifying-questions-state';

export const getQualifyingQuestionsData = async (): Promise<
  GraphqlResponse<FounderQualifyingQuestionsDataResponse>
> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
        query getQualifyingQuestionsData {
          account {
            onboardingStatus {
              founderQualifyingQuestions
            }
          }
        }
    `,
  });

  return graphqlResponseWrapper(data, ['account']);
};

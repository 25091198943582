import { SecureLink } from '@parts/link/link';
import { SubmitButton } from '@parts/submit-button/submit-button';
import styled from 'styled-components';

const Banner = styled.div`
  padding: ${({ theme }) => theme.spacing.medium};
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: ${({ theme }) => theme.color.sectionBackground};
  color: ${({ theme }) => theme.color.fontPrimary};
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.color.border};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  align-items: center;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-direction: column;
  }
`;

const Content = styled.div``;

const Title = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.xmedium};
  font-weight: bold;
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.small};
  color: ${({ theme }) => theme.color.fontPrimary};
`;

const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSize.small};
  display: block;

  > span {
    display: block;

    &:first-of-type {
      margin-bottom: ${({ theme }) => theme.spacing.xsmall};
    }
  }
`;

const Link = styled(SecureLink)`
  color: ${({ theme }) => theme.color.link};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.color.link};
  }
`;

const Button = styled(SubmitButton)`
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${({ theme }) => theme.spacing.medium};

  &.ant-btn:not(.ant-btn-sm) {
    height: 40px;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin-top: ${({ theme }) => theme.spacing.medium};
  }
`;

export default { Banner, Content, Title, Description, Link, Button };

import { Table as TableBased } from '@parts/table/table';
import styled from 'styled-components';

const Table = styled(TableBased)<{ wideTable?: boolean }>`
  .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before,
  .ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
    box-shadow: none;
  }
`;

export default { Table };

import { V2CircleProgressBar } from '@/components/ui/v2-circle-progress-bar/v2-circle-progress-bar';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { V2AdvisorHomepageCard } from '../v2-advisor-homepage-card/v2-advisor-homepage-card';
import type { V2AdvisorHomepageWidgetProps } from './v2-advisor-homepage-widget.types';

import S from './v2-advisor-homepage-widget.styles';

const V2AdvisorHomepageWidget = ({ progress, status, actions, ...restAttributes }: V2AdvisorHomepageWidgetProps) => {
  const [titleUnpublished, titlePublished, paragraphUncompleted, paragraphUnpublished, paragraphPublished] =
    useTranslation([
      'homepage.advisor.readiness.widget.title.unpublished',
      'homepage.advisor.readiness.widget.title.published',
      'homepage.advisor.readiness.widget.paragraph.uncompleted',
      'homepage.advisor.readiness.widget.paragraph.unpublished',
      'homepage.advisor.readiness.widget.paragraph.published',
    ]);

  const title = status === 'unpublished' ? titleUnpublished : titlePublished;
  const paragraph =
    status === 'unpublished' ? (progress < 80 ? paragraphUncompleted : paragraphUnpublished) : paragraphPublished;

  return (
    <V2AdvisorHomepageCard {...restAttributes}>
      <S.CardContent>
        <V2CircleProgressBar percent={progress} valuePostfix="%" variant="magenta" size="small" />
        <S.Title>{title}</S.Title>
        <S.Paragraph>{paragraph}</S.Paragraph>
        <S.Actions>{actions}</S.Actions>
      </S.CardContent>
    </V2AdvisorHomepageCard>
  );
};

export { V2AdvisorHomepageWidget };

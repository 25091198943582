import { FormikField as FormikFieldBase } from '@parts/forms/formik-field/formik-field';
import styled from 'styled-components';

const Wrapper = styled(FormikFieldBase)`
  flex-basis: 25%;
  margin: 0;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex: 1;
  }
`;

export default { Wrapper };

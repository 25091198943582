import { V2InvestorAdvisorProfileLink } from '@/components/ui/v2-investor-advisor-profile-link/v2-investor-advisor-profile-link';
import { Roles } from '@/domain/accounts/roles';
import type { TargetGeographyValue } from '@constants/target-geography';
import { useWysiwyg } from '@pages/content/profile/hooks/use-wysiwyg';
import type { PreviousInvestment } from '@pages/content/profile/investor/api/types';
import type { PreviousInvestmentWithId } from '@pages/content/profile/investor/parts/investment/investment';
import { PreviousInvestmentsTable } from '@pages/content/profile/investor/parts/investment/parts/editor/parts/table/table';
import { Avatar } from '@parts/avatar/avatar';
import { minMaxInvestment } from '@parts/meta-config/meta-config';
import { PageHeader } from '@parts/page-header/page-header';
import { Section } from '@parts/section/section';
import { SanitizeWysiwyg } from '@utils/fns/sanitize-wysiwyg';
import { scrollToTop } from '@utils/fns/scroll-to-top';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useUserCurrency } from '@utils/hooks/use-user-currency/use-user-currency';
import { CountryCodes, CountryNames } from '@utils/type-definitions/iso-to-country-name';
import { Col, Row as RowBase } from 'antd';
import type { ReactNode } from 'react';
import LinkedinOutlinedIcon from '../../../profile/parts/linkedin-outlined-icon/linkedin-outlined-icon';
import TwitterOutlinedIcon from '../../../profile/parts/twitter-outlined-icon/twitter-outlined-icon';
import { ExternalUrl } from '../../parts/extrenal-url/external-url';
import { Row } from '../../parts/row/row';
import { SectionReversedOnMobile } from '../../parts/section-reversed-on-mobile/section-reversed-on-mobile';
import { Tag } from '../../parts/tag/tag';

import S from './investor-public-profile.styles';

interface IInvestorPublicData {
  countryOfResidence: CountryCodes | null;
  profilePicture: {
    url: string;
  };
  linkedinUrl: string;
  twitterUrl: string;
  aboutMe: string;
  productStage: string[];
  customerGroup: string[];
  industries: string[];
  investmentStage: string[];
  investmentThesis: string | null;
  targetGeography: TargetGeographyValue[] | null;
  maxInvestmentAmount: number | null;
  minInvestmentAmount: number | null;
  previousInvestmentsTable: PreviousInvestment[] | null;
  hidePreviousInvestments: boolean | null;
  companyName?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  name?: string | null;
  isInvestorAdvisor: boolean;
}

export const InvestorPublicProfile = ({
  id,
  connection,
  publicData,
  className,
  handleBackAction,
  isConnectionPossible = true,
}: {
  id: string;
  connection?: ReactNode;
  publicData: IInvestorPublicData;
  className?: string;
  handleBackAction: () => void;
  isConnectionPossible?: boolean;
}) => {
  const [
    profileDetailsHeaderLabel,
    profileDetailsCompanyNameLabel,
    profileDetailsCountryOfResidenceLabel,
    profileDetailsLinkedinLabel,
    profileDetailsTwitterLabel,
    profileDetailsAboutMeLabel,
    preferencesIndustryLabel,
    preferencesCustomerGroupLabel,
    preferencesProductStageLabel,
    preferencesInvestmentStageLabel,
    preferencesHeaderLabel,
    investmentHeaderLabel,
    investmentAmountLabel,
    investmentTargetGeographyLabel,
    investmentInvestmentThesisLabel,
    previousInvestmentsLabel,
    profileDetailsEmptyLabel,
    investmentEmptyLabel,
  ] = useTranslation([
    'profile.preview.investor.profileDetails.header',
    'profile.preview.investor.profileDetails.companyName',
    'profile.preview.investor.profileDetails.countryOfResidence',
    'profile.preview.investor.profileDetails.linkedin',
    'profile.preview.investor.profileDetails.twitter',
    'profile.preview.investor.profileDetails.aboutMe',
    'profile.preview.investor.preferences.industry',
    'profile.preview.investor.preferences.customerGroup',
    'profile.preview.investor.preferences.productStage',
    'profile.preview.investor.preferences.investmentStage',
    'profile.preview.investor.preferences.header',
    'profile.preview.investor.investment.header',
    'profile.preview.investor.investment.amount',
    'profile.preview.investor.investment.targetGeography',
    'profile.preview.investor.investment.investmentThesis',
    'profile.preview.investor.investment.previousInvestments',
    'profile.preview.investor.profileDetails.empty',
    'profile.preview.investor.investment.empty',
  ]);

  const { currencySymbol, commaize } = useUserCurrency();

  const { initialStateHasText: hasAboutMeText } = useWysiwyg({ content: publicData.aboutMe });

  scrollToTop();

  const previousInvestments: PreviousInvestmentWithId[] = (publicData.previousInvestmentsTable || []).map(
    (item, index) => ({ ...item, id: index.toString() }),
  );

  const shouldShowPreviousInvestments = () => {
    if (publicData.hidePreviousInvestments === true) return false;
    if (previousInvestments.length <= 0) return false;
    return true;
  };

  const hasInvestmentAmountValues =
    (publicData.minInvestmentAmount && Boolean(publicData.minInvestmentAmount)) ||
    (publicData.maxInvestmentAmount && Boolean(publicData.maxInvestmentAmount));

  const hasInvestmentThesis = Boolean(publicData.investmentThesis?.trim());
  const hasTargetGeography = (publicData.targetGeography ?? []).length > 0;
  const hasInvestmentPreferences =
    hasInvestmentAmountValues || hasInvestmentThesis || hasTargetGeography || shouldShowPreviousInvestments();

  const hasAnyProfileDetails =
    publicData.countryOfResidence ||
    publicData.linkedinUrl ||
    publicData.twitterUrl ||
    publicData.companyName ||
    hasAboutMeText ||
    publicData.profilePicture;

  const shouldShowConnectionPanel = isConnectionPossible && connection;

  const nameToDisplay =
    typeof publicData.name === 'string' ? publicData.name : `${publicData.firstName} ${publicData.lastName}`;

  return (
    <div className={className}>
      <PageHeader
        title={
          <S.NameWrap>
            <span>{nameToDisplay}</span>
            {publicData.isInvestorAdvisor && <V2InvestorAdvisorProfileLink current={Roles.INVESTOR} profileId={id} />}
          </S.NameWrap>
        }
        onBack={handleBackAction}
      />

      {shouldShowConnectionPanel ? connection : null}

      <SectionReversedOnMobile header={profileDetailsHeaderLabel}>
        {hasAnyProfileDetails ? (
          <RowBase>
            <Col md={19}>
              {publicData.companyName ? (
                <Row label={profileDetailsCompanyNameLabel} content={publicData.companyName} />
              ) : null}

              {publicData.countryOfResidence ? (
                <Row
                  label={profileDetailsCountryOfResidenceLabel}
                  content={CountryNames[publicData.countryOfResidence]}
                />
              ) : null}

              {publicData.linkedinUrl ? (
                <Row
                  label={
                    <>
                      {profileDetailsLinkedinLabel}
                      <LinkedinOutlinedIcon />
                    </>
                  }
                  content={<ExternalUrl href={publicData.linkedinUrl} />}
                />
              ) : null}

              {publicData.twitterUrl ? (
                <Row
                  label={
                    <>
                      {profileDetailsTwitterLabel}
                      <TwitterOutlinedIcon />
                    </>
                  }
                  content={<ExternalUrl href={publicData.twitterUrl} />}
                />
              ) : null}
              {hasAboutMeText ? (
                <Row
                  label={profileDetailsAboutMeLabel}
                  content={
                    <div
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: SanitizeWysiwyg(JSON.parse(publicData.aboutMe)),
                      }}
                    />
                  }
                />
              ) : null}
            </Col>
            {publicData.profilePicture ? (
              <Col xs={24} sm={24} md={24} lg={5}>
                <Avatar avatarUrl={publicData.profilePicture?.url || ''} size={120} />
              </Col>
            ) : null}
          </RowBase>
        ) : (
          <S.EmptyState slim title={profileDetailsEmptyLabel} Icon={S.CardIdIcon} />
        )}
      </SectionReversedOnMobile>

      <Section header={preferencesHeaderLabel}>
        {publicData.industries.length !== 0 ? (
          <Row
            label={preferencesIndustryLabel}
            content={
              <>
                {publicData.industries.map((industry) => (
                  <Tag key={industry}>{industry}</Tag>
                ))}
              </>
            }
          />
        ) : null}
        {publicData.customerGroup.length !== 0 ? (
          <Row
            label={preferencesCustomerGroupLabel}
            content={
              <>
                {publicData.customerGroup.map((customerGroup) => (
                  <Tag key={customerGroup}>{customerGroup}</Tag>
                ))}
              </>
            }
          />
        ) : null}
        {publicData.productStage.length > 0 ? (
          <Row
            label={preferencesProductStageLabel}
            content={
              <>
                {publicData.productStage.map((productStage) => (
                  <Tag key={productStage}>{productStage}</Tag>
                ))}
              </>
            }
          />
        ) : null}

        {publicData.investmentStage?.length > 0 ? (
          <Row
            label={preferencesInvestmentStageLabel}
            content={
              <>
                {publicData.investmentStage.map((investmentStage) => (
                  <Tag key={investmentStage}>{investmentStage}</Tag>
                ))}
              </>
            }
          />
        ) : null}
      </Section>
      <Section header={investmentHeaderLabel}>
        {hasInvestmentPreferences ? (
          <>
            {hasInvestmentAmountValues ? (
              <Row
                label={investmentAmountLabel}
                content={minMaxInvestment(
                  commaize,
                  publicData.minInvestmentAmount,
                  publicData.maxInvestmentAmount,
                  currencySymbol,
                )}
              />
            ) : null}

            {hasTargetGeography ? (
              <Row
                label={investmentTargetGeographyLabel}
                content={
                  <>
                    {publicData.targetGeography!.map((item) => (
                      <Tag key={item}>{item}</Tag>
                    ))}
                  </>
                }
              />
            ) : null}

            {hasInvestmentThesis ? (
              <Row label={investmentInvestmentThesisLabel} content={<span>{publicData.investmentThesis}</span>} />
            ) : null}

            {shouldShowPreviousInvestments() ? (
              <Row
                label={previousInvestmentsLabel}
                content={
                  <PreviousInvestmentsTable
                    data={previousInvestments}
                    hideActionsColumn
                    onEditRow={() => {}}
                    onRemoveRow={() => {}}
                  />
                }
              />
            ) : null}
          </>
        ) : (
          <S.EmptyState slim title={investmentEmptyLabel} Icon={S.BillIcon} />
        )}
      </Section>
    </div>
  );
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export interface GetRoleFormResponseData {
  locations: string[];
  investmentStages: string[];
  compensationTypes: string[];
  engagementTypes: string[];
  roleTypes: string[];
  skillSets: string[];
}

export const GET_ROLE_FORM_DATA_CACHE_KEY = 'get-role-form-data';

export const getRoleFormDataAction = async (): Promise<GraphqlResponse<GetRoleFormResponseData>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getRoleFormData {
        locations
        investmentStages
        compensationTypes
        engagementTypes
        roleTypes
        skillSets
      }
    `,
  });

  return graphqlResponseWrapper(data, [
    'locations',
    'investmentStages',
    'compensationTypes',
    'engagementTypes',
    'roleTypes',
    'skillSets',
  ]);
};

import { V2Icon } from '@/components/ui/v2-icon/v2-icon';
import { useMediaQuery } from '@/hooks/use-media-query';
import type { V2IconName } from '@ui/v2-icon/icons';
import type { CarouselProps } from 'antd/lib/carousel';
import React from 'react';
import { ReadinessCarouselTile } from '../carousel-tile/tile';

import S from './carousel-styles';

export type ReadinessScoreCarouselItem = {
  title: string;
  items: string[];
  onClick: () => void;
  completion: { completed: number; total: number };
  icon: V2IconName;
};

type ReadinessScoreModalCarouselProps = {
  items: ReadinessScoreCarouselItem[];
};

const carouselSettings: CarouselProps = {
  dots: false,
  infinite: false,
  slidesToScroll: 1,
  slidesToShow: 1.75,
  autoplay: false,
  centerMode: false,
  arrows: true,
  nextArrow: (
    <button data-testid="carousel-prev-button">
      <V2Icon name="chevron-right" size="lg" />
    </button>
  ),
  prevArrow: (
    <button data-testid="carousel-next-button">
      <V2Icon name="chevron-left" size="lg" />
    </button>
  ),
};

export const ReadinessScoreModalCarousel: React.FC<ReadinessScoreModalCarouselProps> = ({ items }) => {
  const screenSize = useMediaQuery();

  const slides = items.map((item) => (
    <div key={item.title} data-testid="readiness-score-modal-carousel-item">
      <ReadinessCarouselTile {...item} />
    </div>
  ));

  return screenSize === 'xs' ? (
    <S.SlidesStack>{slides}</S.SlidesStack>
  ) : (
    <S.ModalCarousel {...carouselSettings}>{slides}</S.ModalCarousel>
  );
};

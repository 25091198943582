import PrimarySkillSetIcon from '@assets/icons/primary-skillset.svg?react';
import CompensationTypeIcon from '@assets/icons/role-type.svg?react';
import SecondarySkillSetIcon from '@assets/icons/secondary-skillset.svg?react';
import { Badge as BadgeBase } from '@pages/content/public-profile/parts/badge/badge';
import styled, { css } from 'styled-components';

const spacing = css`
  margin-right: ${({ theme }) => theme.spacing.small};
`;

const PrimarySkillSet = styled(PrimarySkillSetIcon)`
  ${spacing}
  position: relative;
  left: 1px;
`;

const SecondarySkillSet = styled(SecondarySkillSetIcon)`
  ${spacing}
`;

const CompensationType = styled(CompensationTypeIcon)`
  ${spacing}
`;

const Badge = styled(BadgeBase)`
  font-size: ${({ theme }) => theme.fontSize.xxsmall};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding: 2px 4px;
  position: relative;
  bottom: 2px;
  right: 2px;
`;

export default { PrimarySkillSet, SecondarySkillSet, CompensationType, Badge };

import S from '@pages/content/dashboard/investor/parts/triggers/parts/table/table.styles';
import { deleteMetricsTriggerAction } from '@pages/content/public-profile/founder/api/delete-metrics-trigger.actions';
import { toggleTriggerActivityAction } from '@pages/content/public-profile/founder/api/toggle-trigger-activity.actions';
import type { TriggerRowData } from '@pages/content/pulse/investor/parts/triggers/triggers';
import { useMutation } from '@tanstack/react-query';
import type { ColumnsQuerying } from '@utils/hooks/use-filtering/use-filtering';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useNumericConditionLabels } from '@utils/hooks/use-numeric-condition-labels/use-numeric-condition-labels';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useUserCurrency } from '@utils/hooks/use-user-currency/use-user-currency';
import type { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { Columns } from './parts/columns/columns';

export const TriggersTable = ({
  items,
  refetch,
  querying,
}: {
  querying: ColumnsQuerying;
  items: TriggerRowData[];
  refetch: Function;
}) => {
  const { currencySymbol } = useUserCurrency();
  const history = useHistory();

  const [editedTrigger, setEditedTrigger] = useState<string | null>(null);
  const [deletedTrigger, setDeletedTrigger] = useState<string | null>(null);

  const conditionLabels = useNumericConditionLabels();

  const { mutateAsync: toggleActivity } = useMutation(toggleTriggerActivityAction, {
    onSettled: () => {
      refetch();
    },
  });

  const [
    companyNameLabel,
    metricNameLabel,
    conditionLabel,
    valueLabel,
    metricValueLabel,
    metLabel,
    activeLabel,
    confirmDeleteLabel,
    cancelDeleteLabel,
    deleteTitleLabel,
    deleteContentLabel,
    trueLabel,
    falseLabel,
    undisclosedLabel,
  ] = useTranslation([
    'lens.investor.table.companyName',
    'pulse.investor.triggers.metricName',
    'pulse.investor.triggers.condition',
    'pulse.investor.triggers.value',
    'pulse.investor.triggers.metricValue',
    'pulse.investor.triggers.met',
    'pulse.investor.triggers.active',
    'metrics.trigger.modal.confirm',
    'metrics.trigger.modal.cancel',
    'metrics.trigger.modal.title',
    'metrics.trigger.modal.content',
    'metrics.manage.boolean.true',
    'metrics.manage.boolean.false',
    'pulse.boolean.undisclosed',
  ]);

  const { mutateAsync: deleteTrigger } = useMutation(deleteMetricsTriggerAction, {
    onSettled: () => setDeletedTrigger(null),
    onSuccess: () => refetch(),
  });

  return (
    <S.Wrapper
      dataSource={items}
      columns={
        Columns({
          t: {
            companyNameLabel,
            metricNameLabel,
            conditionLabel,
            valueLabel,
            metricValueLabel,
            metLabel,
            activeLabel,
            confirmDeleteLabel,
            cancelDeleteLabel,
            deleteTitleLabel,
            deleteContentLabel,
            trueLabel,
            falseLabel,
            undisclosedLabel,
          },
          querying,
          history,
          editedTrigger,
          setEditedTrigger,
          deletedTrigger,
          setDeletedTrigger,
          deleteTrigger,
          refetch,
          toggleActivity,
          conditionLabels,
          currencySymbol,
        }) as ColumnsType<object>
      }
      rowKey="id"
      rowClassName={(row: TriggerRowData) => (!row.isAvailable ? 'disabled-trigger' : '')}
    />
  );
};

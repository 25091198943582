import type { ReactNode } from 'react';
import S from './section-reversed-on-mobile.styles';

export const SectionReversedOnMobile = ({
  header,
  children,
}: {
  header: string;
  children: ReactNode | ReactNode[];
}) => <S.SectionReversedOnMobile header={header}>{children}</S.SectionReversedOnMobile>;

import styled from 'styled-components';
import { V2InvisibleButton } from '../v2-invisible-button/v2-invisible-button';

const V2IconButton = styled(V2InvisibleButton)`
  padding: 8px;

  &:hover {
    cursor: pointer;
    filter: brightness(1.24);
  }

  &:active {
    filter: brightness(1.32);
  }

  &:disabled {
    cursor: not-allowed;
    filter: brightness(1);
    opacity: 0.48;
  }
`;

export default { V2IconButton };

import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import * as Yup from 'yup';
import { firstNameValidation } from '../founder-form/validation-schema';

const INVESTOR_NAME_MAX_LENGTH = 100;

export const useValidationSchema = () => {
  const [fieldRequiredLabel, minLengthLabel, maxLengthLabel] = useTranslation([
    'formik.validation.required',
    'formik.validation.min',
    'formik.validation.max',
  ]);

  return Yup.object({
    firstName: firstNameValidation(minLengthLabel, fieldRequiredLabel).max(INVESTOR_NAME_MAX_LENGTH, maxLengthLabel),
    lastName: Yup.string().trim().required(fieldRequiredLabel).max(INVESTOR_NAME_MAX_LENGTH, maxLengthLabel),
    countryOfResidence: Yup.string().trim().required(fieldRequiredLabel),
    usState: Yup.string().when('countryOfResidence', {
      is: 'US',
      then: Yup.string().required(fieldRequiredLabel),
      otherwise: Yup.string().notRequired().nullable(),
    }),
  });
};

import { blacklistInvestorAction } from '@/pages/content/lens/api/blacklist-investor.action';
import { useUserAbility } from '@abilities';
import { AbilityAction, AbilitySubject } from '@abilities/ability/types';
import { isFounderFreemium } from '@domain/accounts/subscription-products';
import { FounderLayout } from '@layout/founder/founder.layout';
import { acceptInvitationFromFounder } from '@pages/content/lens/api/accept-invitation-from-founder.action';
import { cancelPendingConnectionInvitationRequestAction } from '@pages/content/lens/api/cancel-pending-connection-request-action';
import { declineInvitationFromInvestorAction } from '@pages/content/lens/api/decline-invitation-from-investor.action';
import { disconnectInvestorAction } from '@pages/content/lens/api/disconnect-investor.action';
import { founderInvitesInvestorAction } from '@pages/content/lens/api/founder-invites-investor.action';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { Links } from '@router/links';
import { useQuery } from '@tanstack/react-query';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { Redirect } from 'react-router-dom';
import { ConnectionManager } from '../parts/connection-manager/connection-manager';
import {
  GET_INVESTOR_PUBLIC_PAGE_DETAILS_CACHE_KEY,
  getInvestorPublicPageDetailsAction,
} from './api/get-investor-public-page-details.action';
import { InvestorPublicProfile } from './parts/investor-public-profile';

export const investorIdQueryParam = 'id';

export const InvestorPreviewPage = () => {
  const {
    state: { products },
  } = useUserAccount();
  const history = useHistory();

  const { [investorIdQueryParam]: id } = useQueryParams() as {
    [investorIdQueryParam]: string;
  };

  const userAbility = useUserAbility();
  const isDiscoverable = userAbility.can(AbilityAction.ACCESS, AbilitySubject.DISCOVER);

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery([`${GET_INVESTOR_PUBLIC_PAGE_DETAILS_CACHE_KEY}_${id}`], getInvestorPublicPageDetailsAction(id));

  // Workaround to cover scenario of entering without valid invitation
  if (response && response.errors && response.errors[0].message === 'Investor does not exist') {
    return <Redirect to={Links.NOT_FOUND()} />;
  }

  if (isLoading || !response || !response.data) {
    return (
      <FounderLayout>
        <FullHeightSpinner />
      </FounderLayout>
    );
  }

  const isConnectionNotAccepted = response.data.connection?.status !== 'Accepted';

  const isCurrentSubscriptionFreemium = isFounderFreemium(products);
  if (isCurrentSubscriptionFreemium && isConnectionNotAccepted) {
    return <Redirect to={Links.NOT_FOUND()} />;
  }

  return (
    <FounderLayout>
      <InvestorPublicProfile
        id={id}
        handleBackAction={history.goBack}
        connection={
          <ConnectionManager
            counterpartId={id}
            connection={response.data.connection}
            invitationId={response.data.followInvitationID}
            name={response.data.name}
            smartMatchLevel={response.data.smartMatchLevel}
            notConnectedMessaging={false}
            refetch={refetch}
            actions={{
              send: founderInvitesInvestorAction,
              cancelSent: cancelPendingConnectionInvitationRequestAction,
              accept: acceptInvitationFromFounder,
              decline: declineInvitationFromInvestorAction,
              disconnect: disconnectInvestorAction,
              blacklist: blacklistInvestorAction,
            }}
          />
        }
        publicData={response.data}
        isConnectionPossible={isDiscoverable}
      />
    </FounderLayout>
  );
};

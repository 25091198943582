import React from 'react';
import { V2Avatar } from '../v2-avatar/v2-avatar';
import type { V2AvatarWithDetailsProps } from './v2-avatar-with-details.types';

import S from './v2-avatar-with-details.styles';

export const V2AvatarWithDetails: React.FC<V2AvatarWithDetailsProps> = ({
  avatar,
  mainText,
  additionalText,
  badges = [],
}) => {
  const avatarProps = { ...avatar, name: avatar.name.toLocaleUpperCase() };

  return (
    <S.Wrapper>
      <V2Avatar {...avatarProps} size="sm" />
      <S.Details>
        <S.MainTextWrapper>
          <S.MainText>{mainText}</S.MainText>
          {badges && (
            <S.Badges>
              {badges.map((badge, index) => (
                <React.Fragment key={`badge-${index}`}>{badge}</React.Fragment>
              ))}
            </S.Badges>
          )}
        </S.MainTextWrapper>
        {additionalText && <S.AdditionalText>{additionalText}</S.AdditionalText>}
      </S.Details>
    </S.Wrapper>
  );
};

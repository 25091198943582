import { deleteMetricsTriggerAction } from '@pages/content/public-profile/founder/api/delete-metrics-trigger.actions';
import {
  GET_METRICS_ACTION_CACHE_KEY,
  getTriggerForMetric,
} from '@pages/content/public-profile/founder/api/get-trigger-for-metric.actions';
import { toggleTriggerActivityAction } from '@pages/content/public-profile/founder/api/toggle-trigger-activity.actions';
import { MetricType } from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import { useModal } from '@parts/modal/use-modal';
import NavDropdown from '@parts/nav-dropdown/nav-dropdown';
import OnTopFooter from '@parts/on-top-footer/on-top-footer';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useState } from 'react';
import { SetUpBooleanTrigger } from './parts/set-up-boolean-trigger/set-up-boolean-trigger';
import { SetUpNumericTrigger } from './parts/set-up-numeric-trigger/set-up-numeric-trigger';
import S from './trigger-management.styles';

const TriggerManagement = ({
  metricType,
  metricId,
  currentValue,
  name,
  scale,
  businessName,
}: {
  metricId: string;
  metricType: MetricType;
  currentValue: boolean | null | number;
  name: string;
  scale?: number;
  businessName?: string;
}) => {
  const { Modal: DeleteModal, open, show, hide } = useModal();
  const [managementVisible, setManagementVisible] = useState(false);

  const handleShowingManagement = () => setManagementVisible(true);
  const handleHidingManagement = () => setManagementVisible(false);

  const { data: response, refetch } = useQuery([GET_METRICS_ACTION_CACHE_KEY, metricId], getTriggerForMetric(metricId));

  const triggerSet = response?.data?.trigger ?? false;
  const isActive = response?.data?.trigger?.isActive ?? false;
  const triggerId = response?.data?.trigger?.id ?? '';
  const isMet = response?.data?.trigger?.isMet ?? false;

  const [
    setUpLabel,
    editLabel,
    deleteLabel,
    inactivateLabel,
    activateLabel,
    confirmLabel,
    cancelLabel,
    titleLabel,
    contentLabel,
  ] = useTranslation([
    'metrics.trigger.setUp',
    'metrics.trigger.edit',
    'metrics.trigger.delete',
    'metrics.trigger.inactivate',
    'metrics.trigger.activate',
    'metrics.trigger.modal.confirm',
    'metrics.trigger.modal.cancel',
    'metrics.trigger.modal.title',
    'metrics.trigger.modal.content',
  ]);

  const refetchConfig = {
    onSuccess: () => {
      refetch();
    },
    onSettled: hide,
  };

  const { mutateAsync: deleteTrigger } = useMutation(deleteMetricsTriggerAction, refetchConfig);
  const { mutateAsync: toggleActivity } = useMutation(toggleTriggerActivityAction, refetchConfig);

  const management = () => {
    if (!managementVisible) return;

    switch (metricType) {
      case MetricType.Numeric:
        return (
          <SetUpNumericTrigger
            isOpen
            businessName={businessName}
            scale={response?.data?.trigger?.scale ?? scale ?? 0}
            currentTrigger={response?.data?.trigger || { id: '' }}
            onClose={handleHidingManagement}
            activate={!isActive && triggerSet ? () => toggleActivity({ triggerId, isActive: true }) : undefined}
            metricId={metricId}
            metricName={name}
            handleTriggerSet={() => {
              refetch();
              handleHidingManagement();
            }}
          />
        );

      case MetricType.Boolean:
      default:
        return (
          <SetUpBooleanTrigger
            businessName={businessName}
            currentValue={Boolean(currentValue)}
            onClose={handleHidingManagement}
            metricId={metricId}
            metricName={name}
            handleTriggerSet={() => {
              refetch();
              handleHidingManagement();
            }}
          />
        );
    }
  };

  const setTriggerOption = (
    <S.Item onClick={handleShowingManagement}>
      <S.Add />
      {setUpLabel}
    </S.Item>
  );

  const editTriggerOption = (
    <S.Item onClick={handleShowingManagement}>
      <S.Edit />
      {editLabel}
    </S.Item>
  );

  const deleteTriggerOption = (
    <S.Item onClick={show}>
      <S.Trash /> {deleteLabel}
    </S.Item>
  );

  const setInactiveTriggerOption = (
    <S.Item onClick={() => toggleActivity({ triggerId, isActive: !isActive })}>
      <S.Mute /> {inactivateLabel}
    </S.Item>
  );

  const setActiveTriggerOption = (
    <S.Item onClick={() => toggleActivity({ triggerId, isActive: !isActive })}>
      <S.Active /> {activateLabel}
    </S.Item>
  );

  const items = (() => {
    if (metricType === MetricType.Boolean) {
      if (triggerSet) {
        return [isActive ? setInactiveTriggerOption : setActiveTriggerOption, deleteTriggerOption];
      }

      return [setTriggerOption];
    }

    if (triggerSet) {
      return isActive
        ? [editTriggerOption, setInactiveTriggerOption, deleteTriggerOption]
        : [editTriggerOption, deleteTriggerOption];
    }

    return [setTriggerOption];
  })();

  const icon = (() => {
    if (!isActive && (isMet || triggerSet)) return <S.TriggerMute />;
    if (isMet) return <S.TriggerMet />;
    if (isActive) return <S.TriggerActive />;

    return <S.TriggerAdd />;
  })();

  return (
    <>
      <DeleteModal
        visible={open}
        closeModal={hide}
        title={titleLabel}
        footer={
          <OnTopFooter
            isDanger
            onCancel={hide}
            onConfirm={() => {
              if (!triggerId) return;

              deleteTrigger(triggerId);
            }}
            confirmLabel={confirmLabel}
            cancelLabel={cancelLabel}
          />
        }
      >
        {contentLabel}
      </DeleteModal>
      {management()}
      <NavDropdown items={items} icon={icon} />
    </>
  );
};

export default TriggerManagement;

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const Tiles = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -${({ theme }) => theme.spacing.xsmall};

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.highResDesktop}) {
    margin: 0 -${({ theme }) => theme.spacing.small};
  }

  > * {
    margin: ${({ theme }) => theme.spacing.xsmall} ${({ theme }) => theme.spacing.xsmall};

    @media (min-width: ${({ theme }) => theme.deviceBreakpoint.highResDesktop}) {
      margin: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.small};
    }
  }
`;

export default { Wrapper, Tiles };

import { Menu as MenuBase } from 'antd';
import { Link as LinkBase } from 'react-router-dom';
import styled from 'styled-components';
import { Content as ContentBase } from '../parts/content/content';

const Content = styled(ContentBase)<{ $spacedTop: boolean }>`
  padding-top: ${({ theme, $spacedTop }) => ($spacedTop ? theme.spacing.base : 0)};
  padding-bottom: ${({ theme }) => theme.spacing.base};
`;

const MenuItem = styled(MenuBase.Item)`
  color: ${({ theme }) => theme.color.primary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  .ant-menu-item-icon {
    display: none;
  }
`;

const Menu = styled(MenuBase)``;

const Link = styled(LinkBase)``;

const MenuBottom = styled.div``;

export default {
  MenuItem,
  Menu,
  Link,
  MenuBottom,
  Content,
};

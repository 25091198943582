import { Radio as RadioBase } from 'antd';
import styled from 'styled-components';

const Wrapper = styled(RadioBase)`
  margin: 6px;
  margin-left: 0;
  display: inline-flex;
  width: 100%;
  white-space: break-spaces;
  align-items: center;

  .ant-radio-inner {
    border: ${({ theme }) => `1px solid ${theme.color.primary}`};
    height: 20px;
    width: 20px;

    &::after {
      height: 12px;
      width: 12px;
    }
  }
`;

export default { Wrapper };

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  align-self: flex-end;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: 100%;
  }
`;

const Text = styled.div`
  display: inline-block;
  margin-left: ${({ theme }) => theme.spacing.xmedium};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: 90%;
  }
`;

const Title = styled.dt`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.color.fontPrimary};
  font-size: ${({ theme }) => theme.fontSize.regular};
`;

const Description = styled.dd`
  color: ${({ theme }) => theme.color.fontSecondary};
  font-size: ${({ theme }) => theme.fontSize.regular};

  a {
    color: blue;
    text-decoration: none;
  }
`;

export default { Wrapper, Text, Title, Description };

import WandererIcon from '@assets/icons/undraw-wanderer.svg';
import { EmptyState } from '@parts/empty-state/empty-state';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { DEFAULT_ITEMS_PER_PAGE_IN_GRID, ItemsGrid } from '@parts/items-grid/items-grid';
import { Pagination } from '@parts/pagination/pagination';
import { useQueryPagination } from '@utils/hooks/use-query-pagination/use-query-pagination';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { GET_NED_ROLES_CACHE_KEY, getNedRolesAction, type NedRole } from '../../../api/get-ned-roles.actions';
import { Role } from '../role/role';
import { Filters } from './parts/filters/filters';

const EmptyIconImage = () => <img src={WandererIcon} alt="wanderer-icon" loading="lazy" />;

export const BrowseRoles = () => {
  const [emptyTitle, emptySubTitle] = useTranslation(['roles.ned.empty.title', 'roles.ned.empty.subtitle']);

  const {
    items,
    total,
    isLoading,
    refetch: refetchRoles,
    ...pagination
  } = useQueryPagination<NedRole>({
    itemsPerPage: DEFAULT_ITEMS_PER_PAGE_IN_GRID,
    pageParam: 'page',
    action: getNedRolesAction,
    cacheKey: GET_NED_ROLES_CACHE_KEY,
    queryConfig: {
      usePreviousPage: true,
    },
  });

  const content = () => {
    if (isLoading) return <FullHeightSpinner small />;

    if (items && items.length > 0) {
      return (
        <>
          <ItemsGrid keyBase="role" Item={Role} data={items} />
          {pagination.maxPages > 1 && <Pagination bottomSpaced centered {...pagination} />}
        </>
      );
    }

    return <EmptyState Icon={EmptyIconImage} title={emptyTitle} subTitle={emptySubTitle} />;
  };

  return (
    <>
      <Filters />
      {content()}
    </>
  );
};

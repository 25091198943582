import { CalendarIcon as CalendarIconBase } from '@parts/calendar-icon/calendar-icon';
import { FormAlert } from '@parts/forms/error/form-alert/form-alert';
import { SubmitButton as SubmitButtonBase } from '@parts/submit-button/submit-button';
import { Button } from 'antd';
import styled, { css } from 'styled-components';
import { Info as InfoBase } from '../info/info';
import { Action } from './parts/action/action';
import { NUMERIC_EDITOR_COLUMN_WIDTH } from './parts/row/row.styles';

const rightSpacingStyles = css`
  margin-right: ${({ theme }) => theme.spacing.base};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    margin-right: 0;
  }
`;

const Alert = styled(FormAlert)`
  ${rightSpacingStyles};
`;

const Wrapper = styled.div`
  position: relative;
  flex-wrap: wrap;
`;

const Problems = styled.div``;

const Error = styled.p`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const ImportFooter = styled.footer`
  border-top: 1px solid ${({ theme }) => theme.color.border};
  margin-top: ${({ theme }) => theme.spacing.medium};
  padding-top: ${({ theme }) => theme.spacing.medium};
  ${rightSpacingStyles};
  display: flex;
`;

const GoBack = styled(Button)`
  border-color: ${({ theme }) => theme.color.submitButton};
  color: ${({ theme }) => theme.color.submitButton};
  width: 100px;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-grow: 1;
  }
`;

const Save = styled(SubmitButtonBase)`
  background-color: ${({ theme }) => theme.color.submitButton};
  margin-right: ${({ theme }) => theme.spacing.small};
  width: 100px;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-grow: 1;
  }
`;

const Header = styled.div`
  flex: 100%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  ${rightSpacingStyles};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    margin-top: ${({ theme }) => theme.spacing.medium};
    justify-content: flex-start;
  }
`;

const Info = styled(InfoBase)`
  flex-shrink: 0;
  flex-grow: 1;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    flex-shrink: 1;
  }
`;

const Import = styled(Action)`
  flex-grow: 0;
  flex-shrink: 0;
`;

const stickyStyles = css`
  position: sticky;
  z-index: 100;
  background: ${({ theme }) => theme.color.sectionBackground};
`;

const Preview = styled.div`
  ${stickyStyles};
  top: ${({ theme }) => theme.ui.mobileMenuHeight};
  border-bottom: 1px solid ${({ theme }) => theme.color.border};

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    padding-right: 40px;
    margin-right: 0;
    border-bottom: 0;
    top: ${({ theme }) => theme.ui.desktopMenuHeight};
  }
`;

const Columns = styled.div`
  ${stickyStyles};
  ${rightSpacingStyles};
  margin-right: 38px;
  top: ${({ theme }) =>
    `${Number.parseInt(theme.ui.liveChartHeight, 10) + Number.parseInt(theme.ui.desktopMenuHeight, 10)}px`};
  display: flex;
  background: ${({ theme }) => theme.color.sectionBackground};
  padding: ${({ theme }) => theme.spacing.xsmall} 0;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  border: 1px solid ${({ theme }) => theme.color.border};
  border-right: 0;
  border-left: 0;
  color: ${({ theme }) => theme.color.tableHeader};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
`;

const Column = styled.div`
  flex: ${NUMERIC_EDITOR_COLUMN_WIDTH};
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
`;

const NoteColumn = styled(Column)`
  flex: auto;
  flex-grow: 1;

  &:after {
    width: 30px;
    height: 35px;
    background-color: ${({ theme }) => theme.color.tableBackground};
    position: absolute;
    content: '';
    right: -35px;
  }
`;

const CalendarIcon = styled(CalendarIconBase)`
  margin-top: ${({ theme }) => theme.spacing.base};
  width: 70px;
  height: 70px;
`;

const Add = styled.div`
  margin-top: ${({ theme }) => theme.spacing.medium};
  color: ${({ theme }) => theme.color.primary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  cursor: pointer;
  width: 120px;
`;

export default {
  Alert,
  Problems,
  Error,
  ImportFooter,
  Wrapper,
  Add,
  Preview,
  Column,
  NoteColumn,
  CalendarIcon,
  Columns,
  Header,
  Import,
  GoBack,
  Save,
  Info,
};

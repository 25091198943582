import { checkIfValidWysiWygValue } from '@pages/content/profile/hooks/use-wysiwyg';
import LinkedinOutlinedIcon from '@pages/content/profile/parts/linkedin-outlined-icon/linkedin-outlined-icon';
import TwitterOutlinedIcon from '@pages/content/profile/parts/twitter-outlined-icon/twitter-outlined-icon';
import { ExternalUrl } from '@pages/content/public-profile/parts/extrenal-url/external-url';
import { Row } from '@pages/content/public-profile/parts/row/row';
import { SectionReversedOnMobile } from '@pages/content/public-profile/parts/section-reversed-on-mobile/section-reversed-on-mobile';
import { Avatar } from '@parts/avatar/avatar';
import { SanitizeWysiwyg } from '@utils/fns/sanitize-wysiwyg';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { CountryNames } from '@utils/type-definitions/iso-to-country-name';
import { Col, Row as RowBase } from 'antd';
import type { NedPublicProfileData } from '../../api/get-ned-public-page-details';
import type { RawDraftContentState } from 'draft-js';

import S from './personal-details.styles';

type PersonalDetailsProps = Partial<
  Pick<NedPublicProfileData, 'countryOfResidence' | 'linkedinUrl' | 'twitterUrl' | 'resume' | 'profilePicture'> & {
    bio: RawDraftContentState | null;
  }
>;

export const PersonalDetails = ({
  countryOfResidence,
  linkedinUrl,
  twitterUrl,
  resume,
  profilePicture,
  bio,
}: PersonalDetailsProps) => {
  const [
    detailsHeaderLabel,
    detailsCountryOfResidenceLabel,
    detailsLinkedinLabel,
    detailsTwitterLabel,
    detailsResumeLabel,
    bioLabel,
  ] = useTranslation([
    'profile.preview.ned.details.header',
    'profile.preview.ned.details.countryOfResidence',
    'profile.preview.ned.details.linkedin',
    'profile.preview.ned.details.twitter',
    'profile.preview.ned.details.resume',
    'profile.preview.ned.aboutMe.bio',
  ]);

  const hasBioContent =
    (checkIfValidWysiWygValue(bio) &&
      JSON.parse(bio as unknown as string)?.blocks?.some((item: { text: string }) => item.text !== '')) ||
    false;

  return (
    <SectionReversedOnMobile header={detailsHeaderLabel}>
      <RowBase>
        <Col md={19}>
          {countryOfResidence && (
            <Row label={detailsCountryOfResidenceLabel} content={CountryNames[countryOfResidence]} />
          )}

          {linkedinUrl && (
            <Row
              label={
                <>
                  {detailsLinkedinLabel}
                  <LinkedinOutlinedIcon />
                </>
              }
              content={<ExternalUrl href={linkedinUrl ?? ''} />}
            />
          )}

          {twitterUrl && (
            <Row
              label={
                <>
                  {detailsTwitterLabel}
                  <TwitterOutlinedIcon />
                </>
              }
              content={<ExternalUrl href={twitterUrl ?? ''} />}
            />
          )}

          {resume?.url && (
            <Row
              label={detailsResumeLabel}
              content={
                <S.ExternalUrl key={resume.url} href={resume.url ?? ''}>
                  <S.DownloadIcon /> {resume.name}
                </S.ExternalUrl>
              }
            />
          )}

          {hasBioContent && (
            <Row
              label={bioLabel}
              content={
                <div
                  dangerouslySetInnerHTML={{
                    __html: SanitizeWysiwyg(JSON.parse(bio as unknown as string)),
                  }}
                />
              }
            />
          )}
        </Col>
        {profilePicture?.url && (
          <Col xs={24} sm={24} md={24} lg={5}>
            <S.Avatar>
              <Avatar avatarUrl={profilePicture?.url} size={120} />
            </S.Avatar>
          </Col>
        )}
      </RowBase>
    </SectionReversedOnMobile>
  );
};

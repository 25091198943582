/* eslint-disable no-nested-ternary */
import { hasCoRSet } from '@abilities';
import { Roles } from '@domain/accounts/roles';
import { OnboardingStatus } from '@domain/accounts/types';
import type { RoleOnboardingStatus } from '@pages/auth/api/account-data/account-data.types';
import { Routes } from '@router/routes';
import { CountryCodes } from '@utils/type-definitions/iso-to-country-name';

type RedirectRouteRoleMapping = Partial<Record<Routes, Omit<Record<Roles, Routes>, Roles.NO_ROLE>>>;

export const REDIRECT_TO_PARAMETER_NAME = 'redirectTo';

export const createLoginUrl = (locationHref: string): string => {
  const appendPathToLogin = (url: string): string => {
    const { pathname, search } = new URL(url);
    const redirectTo = encodeURIComponent(`${pathname}${search}`);
    return search || pathname !== '/' ? `?${REDIRECT_TO_PARAMETER_NAME}=${redirectTo}` : '';
  };

  return Routes.LOGIN + appendPathToLogin(locationHref);
};

export const getRedirectRoute = (
  route: Routes,
  userRole: Roles,
  onboardingStatus: RoleOnboardingStatus,
  countryOfResidence: CountryCodes | null,
) => {
  if (userRole === Roles.NO_ROLE) {
    return createLoginUrl(window.location.href);
  }

  if (!hasCoRSet(countryOfResidence)) {
    return Routes.COR_SELECTION;
  }

  if (userRole === Roles.INVESTOR && onboardingStatus.investor === OnboardingStatus.Pending) {
    return Routes.ONBOARDING_INVESTOR;
  }

  const routeRedirectRoleMappings: RedirectRouteRoleMapping = {
    [Routes.LOGIN]: {
      [Roles.FOUNDER]: Routes.START_PAGE,
      [Roles.INVESTOR]: Routes.START_PAGE,
      [Roles.NED]: Routes.START_PAGE,
      [Roles.INVESTOR_NED]: Routes.START_PAGE,
    },
    [Routes.REGISTER]: {
      [Roles.FOUNDER]: Routes.START_PAGE,
      [Roles.INVESTOR]: Routes.START_PAGE,
      [Roles.NED]: Routes.START_PAGE,
      [Roles.INVESTOR_NED]: Routes.START_PAGE,
    },
    [Routes.REGISTER_INVITED_INVESTOR]: {
      [Roles.FOUNDER]: Routes.START_PAGE,
      [Roles.INVESTOR]: Routes.START_PAGE,
      [Roles.NED]: Routes.START_PAGE,
      [Roles.INVESTOR_NED]: Routes.START_PAGE,
    },
    [Routes.REGISTER_INVITED_FOUNDER]: {
      [Roles.FOUNDER]: Routes.START_PAGE,
      [Roles.INVESTOR]: Routes.START_PAGE,
      [Roles.NED]: Routes.START_PAGE,
      [Roles.INVESTOR_NED]: Routes.START_PAGE,
    },
    [Routes.REGISTER_ROLE]: {
      [Roles.FOUNDER]: Routes.START_PAGE,
      [Roles.INVESTOR]: Routes.START_PAGE,
      [Roles.NED]: Routes.START_PAGE,
      [Roles.INVESTOR_NED]: Routes.START_PAGE,
    },
    [Routes.EMAIL_UNVERIFIED]: {
      [Roles.FOUNDER]: Routes.START_PAGE,
      [Roles.INVESTOR]: Routes.START_PAGE,
      [Roles.NED]: Routes.START_PAGE,
      [Roles.INVESTOR_NED]: Routes.START_PAGE,
    },
    [Routes.REDIRECT_TO_REGISTER]: {
      [Roles.FOUNDER]: Routes.START_PAGE,
      [Roles.INVESTOR]: Routes.START_PAGE,
      [Roles.NED]: Routes.START_PAGE,
      [Roles.INVESTOR_NED]: Routes.START_PAGE,
    },
    [Routes.CHECKOUT_CANCEL]: {
      [Roles.FOUNDER]: Routes.START_PAGE,
      [Roles.INVESTOR]: Routes.START_PAGE,
      [Roles.NED]: Routes.START_PAGE,
      [Roles.INVESTOR_NED]: Routes.START_PAGE,
    },
    [Routes.CHECKOUT]: {
      [Roles.FOUNDER]: Routes.START_PAGE,
      [Roles.INVESTOR]: Routes.START_PAGE,
      [Roles.NED]: Routes.START_PAGE,
      [Roles.INVESTOR_NED]: Routes.START_PAGE,
    },
    [Routes.EXTEND_PRODUCTS]: {
      [Roles.FOUNDER]: Routes.START_PAGE,
      [Roles.INVESTOR]: Routes.START_PAGE,
      [Roles.NED]: Routes.START_PAGE,
      [Roles.INVESTOR_NED]: Routes.START_PAGE,
    },
    [Routes.INVESTOR_AWAITING_APPROVAL]: {
      [Roles.FOUNDER]: Routes.START_PAGE,
      [Roles.INVESTOR]: Routes.START_PAGE,
      [Roles.NED]: Routes.START_PAGE,
      [Roles.INVESTOR_NED]: Routes.START_PAGE,
    },
    [Routes.ONBOARDING_INVESTOR_SUMMARY]: {
      [Roles.FOUNDER]: Routes.START_PAGE,
      [Roles.INVESTOR]: Routes.START_PAGE,
      [Roles.NED]: Routes.START_PAGE,
      [Roles.INVESTOR_NED]: Routes.START_PAGE,
    },
    [Routes.COR_SELECTION]: {
      [Roles.FOUNDER]: Routes.START_PAGE,
      [Roles.INVESTOR]: Routes.START_PAGE,
      [Roles.NED]: Routes.START_PAGE,
      [Roles.INVESTOR_NED]: Routes.START_PAGE,
    },
    [Routes.HOME]: {
      [Roles.FOUNDER]: Routes.CHECKOUT,
      [Roles.INVESTOR]: Routes.INVESTOR_AWAITING_APPROVAL,
      [Roles.NED]: Routes.CHECKOUT,
      [Roles.INVESTOR_NED]: Routes.CHECKOUT,
    },
    [Routes.START_PAGE]: {
      [Roles.FOUNDER]: Routes.CHECKOUT,
      [Roles.INVESTOR]: Routes.INVESTOR_AWAITING_APPROVAL,
      [Roles.NED]: Routes.CHECKOUT,
      [Roles.INVESTOR_NED]: Routes.CHECKOUT,
    },
    [Routes.PULSE]: {
      [Roles.FOUNDER]: Routes.CHECKOUT,
      [Roles.INVESTOR]: Routes.INVESTOR_AWAITING_APPROVAL,
      [Roles.NED]: Routes.CHECKOUT,
      [Roles.INVESTOR_NED]: Routes.CHECKOUT,
    },
    [Routes.PROFILE]: {
      [Roles.FOUNDER]: Routes.CHECKOUT,
      [Roles.INVESTOR]: Routes.INVESTOR_AWAITING_APPROVAL,
      [Roles.NED]: Routes.CHECKOUT,
      [Roles.INVESTOR_NED]: Routes.CHECKOUT,
    },
    [Routes.MULTI_ROLE_PROFILE]: {
      [Roles.FOUNDER]: Routes.PROFILE,
      [Roles.INVESTOR]: Routes.INVESTOR_AWAITING_APPROVAL,
      [Roles.NED]: Routes.PROFILE,
      [Roles.INVESTOR_NED]: Routes.CHECKOUT,
    },
    [Routes.PREVIEW_ROLE]: {
      [Roles.FOUNDER]: Routes.CHECKOUT,
      [Roles.INVESTOR]: Routes.INVESTOR_AWAITING_APPROVAL,
      [Roles.NED]: Routes.CHECKOUT,
      [Roles.INVESTOR_NED]: Routes.CHECKOUT,
    },
    [Routes.LENS]: {
      [Roles.FOUNDER]: Routes.CHECKOUT,
      [Roles.INVESTOR]: Routes.INVESTOR_AWAITING_APPROVAL,
      [Roles.NED]: Routes.CHECKOUT,
      [Roles.INVESTOR_NED]: Routes.CHECKOUT,
    },
    [Routes.MESSAGING]: {
      [Roles.FOUNDER]: Routes.CHECKOUT,
      [Roles.INVESTOR]: Routes.INVESTOR_AWAITING_APPROVAL,
      [Roles.NED]: Routes.CHECKOUT,
      [Roles.INVESTOR_NED]: Routes.CHECKOUT,
    },
    [Routes.NOTIFICATIONS]: {
      [Roles.FOUNDER]: Routes.CHECKOUT,
      [Roles.INVESTOR]: Routes.INVESTOR_AWAITING_APPROVAL,
      [Roles.NED]: Routes.CHECKOUT,
      [Roles.INVESTOR_NED]: Routes.CHECKOUT,
    },
    [Routes.UPGRADE_PRODUCTS]: {
      [Roles.FOUNDER]: Routes.PROFILE,
      [Roles.INVESTOR]: Routes.NOT_FOUND,
      [Roles.NED]: Routes.NOT_FOUND,
      [Roles.INVESTOR_NED]: Routes.NOT_FOUND,
    },
    [Routes.NOT_FOUND]: {
      [Roles.FOUNDER]: Routes.NOT_FOUND,
      [Roles.INVESTOR]: Routes.NOT_FOUND,
      [Roles.NED]: Routes.NOT_FOUND,
      [Roles.INVESTOR_NED]: Routes.NOT_FOUND,
    },
  };

  return routeRedirectRoleMappings[route]?.[userRole] ?? Routes.NOT_FOUND;
};

import { GET_NED_PUBLISH_STATUS_WITH_SHORTAGES_CACHE_KEY } from '@/pages/content/profile/ned/api/get-ned-publish-status/get-ned-publish-status.action';
import message from '@/parts/message/message';
import { getServerError } from '@/utils/fns/get-server-error';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { GET_ADVISOR_PROFILE_READINESS } from '../api/get-advisor-profile-readiness.query';
import { GET_ADVISOR_PUBLISH_STATUS } from '../api/get-advisor-publish-status.query';
import { publishAdvisorProfileAction } from '../api/publish-advisor-profile.action';
import { unPublishAdvisorProfileAction } from '../api/unpublish-advisor-profile.action';

export const useTogglePublishAdvisorProfile = ({
  published,
  onSuccess,
}: {
  published: boolean;
  onSuccess?: (() => void) | (() => Promise<void>);
}) => {
  const client = useQueryClient();

  const action = published ? unPublishAdvisorProfileAction : publishAdvisorProfileAction;
  const mutation = useMutation(action, {
    onSuccess: async () => {
      await Promise.all([
        client.invalidateQueries([GET_ADVISOR_PUBLISH_STATUS]),
        client.invalidateQueries([GET_ADVISOR_PROFILE_READINESS]),
        client.invalidateQueries([GET_NED_PUBLISH_STATUS_WITH_SHORTAGES_CACHE_KEY]),
      ]);
      onSuccess?.();
    },
    onError: (error: AxiosError) => message.error({ content: getServerError(error) }),
  });

  return {
    togglePublishAdvisorProfile: mutation.mutateAsync,
    isTogglePublishAdvisorProfileLoading: mutation.isLoading,
    ogMutationResult: { ...mutation },
  };
};

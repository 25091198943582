import { V2Title } from '@/components/ui/v2-title/v2-title';
import styled from 'styled-components';

const PageTitle = styled(V2Title)`
  margin-top: 20px;
`;

export default {
  PageTitle,
};

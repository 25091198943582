const basketId = '56d7926f-f472-4cb5-a0e9-6e4cea88fa66';
const byRotationId = '723659d3-8ac8-4a57-b3dd-0204062fad74';
const kyteId = '7380eedc-225b-4e53-82ba-909ebdaebbb4';
const wisdomWorksId = 'd74103fa-07ed-4f40-86db-e086313c2715';
const greenLithiumId = '4e49540e-c086-458e-b8f7-12ced77f7ef0';

const generateFounderUrl = (id: string) => `https://app.connectd.com/public-profile/founder/details?id=${id}`;

export const basketUrl = generateFounderUrl(basketId);
export const byRotationUrl = generateFounderUrl(byRotationId);
export const kyteUrl = generateFounderUrl(kyteId);
export const wisdomWorksUrl = generateFounderUrl(wisdomWorksId);
export const greenLithiumUrl = generateFounderUrl(greenLithiumId);

import { V2AriaTable } from '@/components/blocks/v2-aria-table/v2-aria-table';
import { V2TableStateInfo } from '@/components/ui/v2-table-state-info/v2-table-state-info';
import { TranslationText } from '@/parts/translation-text/translation-text';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import type { Sorter } from '@/utils/type-definitions/filters';
import { useState, type Dispatch, type SetStateAction } from 'react';
import type { FounderConnection } from '../../types';
import { ChatButtonCell } from './table-parts/chat-button-cell';
import { ComingSoonCell } from './table-parts/coming-soon-cell';
import { ConnectionTypeCell } from './table-parts/connection-type-cell';
import { MoreActionsCell } from './table-parts/more-actions-cell';
import { NameAndDetailsCell } from './table-parts/name-and-details-cell';
import { NotesButtonCell } from './table-parts/notes-button-cell';
import { TableRowCollapsible } from './table-parts/row-collapsible';
import { DateCell } from './table-parts/date-cell';

type TConnectionsTableProps = {
  data: FounderConnection[];
  refetch: () => void;
  sorting?: Sorter;
  setSorting: Dispatch<SetStateAction<Sorter | undefined>>;
};

export const ConnectionsTable = ({ data, sorting, setSorting, refetch }: TConnectionsTableProps) => {
  const [nameTranslation, dateTranslation, typeTranslation, actionsTranslation] = useTranslation([
    'connections.founder.table.name',
    'connections.founder.table.date',
    'connections.founder.table.type',
    'connections.founder.table.actions',
  ]);

  const [isVisibleMap, setIsVisibleMap] = useState(() => {
    const initialVisibility: Record<string, boolean> = {};
    data.forEach((row) => (initialVisibility[row.connectionId] = false));
    return initialVisibility;
  });

  const toggleVisibility = (id: string) => setIsVisibleMap((prev) => ({ ...prev, [id]: !prev[id] }));

  const handleSort = (id: string) => {
    setSorting((prevSorting) => {
      if (!prevSorting || prevSorting.key !== id) return { key: id, order: 'Asc' };
      else if (prevSorting.order === 'Asc') return { key: id, order: 'Desc' };
      else return undefined;
    });
  };

  return (
    <V2AriaTable
      sorting={sorting}
      gridTemplateColumns="0.30fr 0.15fr 0.20fr 0.14fr 0.16fr 0.16fr 0.05fr"
      head={
        <V2AriaTable.HeadRow>
          <V2AriaTable.HeadCell id="name" onSort={() => handleSort('name')}>
            {nameTranslation}
          </V2AriaTable.HeadCell>
          <V2AriaTable.HeadCell id="date">{dateTranslation}</V2AriaTable.HeadCell>
          <V2AriaTable.HeadCell id="isCloseConnection" onSort={() => handleSort('isCloseConnection')}>
            {typeTranslation}
          </V2AriaTable.HeadCell>
          <V2AriaTable.HeadCell id="soon">{actionsTranslation}</V2AriaTable.HeadCell>
          <V2AriaTable.HeadCell id="notes" />
          <V2AriaTable.HeadCell id="chat" />
          <V2AriaTable.HeadCell id="more" />
        </V2AriaTable.HeadRow>
      }
      body={
        data.length === 0 ? (
          <V2AriaTable.BodyRow customGridTemplateColumns="1fr">
            <V2TableStateInfo
              spaceTop
              showIcon
              title={<TranslationText id="connections.founder.table.connections.empty.title" />}
              description={<TranslationText id="connections.founder.table.connections.empty.description" />}
            />
          </V2AriaTable.BodyRow>
        ) : (
          data.map((row, index) => (
            <V2AriaTable.RowWrapper
              key={row.connectionId}
              rowContent={
                <V2AriaTable.BodyRow key={row.connectionId}>
                  <NameAndDetailsCell {...row} />
                  <DateCell {...row} />
                  <ConnectionTypeCell {...row} refetch={refetch} data-testid={`founder-connection-type-${index}`} />
                  <ComingSoonCell {...row} />
                  <NotesButtonCell
                    {...row}
                    toggleVisibility={toggleVisibility}
                    data-testid={`founder-connection-open-notes-${index}`}
                  />
                  <ChatButtonCell {...row} />
                  <MoreActionsCell {...row} refetch={refetch} />
                </V2AriaTable.BodyRow>
              }
              collapsible={
                <TableRowCollapsible
                  onUpdated={refetch}
                  targetId={row.participantId}
                  isVisible={isVisibleMap[row.connectionId]}
                />
              }
            />
          ))
        )
      }
    />
  );
};

import { safeParseJson } from '@utils/fns/safe-parse-json';
import { honeybadger } from '../honeybadger';
import type { BaseNotice } from './notifications';

const LS_ERROR_NOTIFICATION_KEY = 'ERROR_NOTIFICATION';
const LS_ERROR_CODE_KEY = 'ERROR_CODE';

export const storeErrorNoticeInLocalStorage = (notice: BaseNotice, errorCodeId?: string) => {
  window.localStorage.setItem(LS_ERROR_NOTIFICATION_KEY, JSON.stringify(notice));

  if (errorCodeId) {
    window.localStorage.setItem(LS_ERROR_CODE_KEY, errorCodeId);
  }
};

export const readAndSendErrorNoticeFromLocalStorage = (): string | null => {
  const errorResponse = window.localStorage.getItem(LS_ERROR_NOTIFICATION_KEY);

  if (errorResponse) {
    const notice = safeParseJson(errorResponse, null);

    if (notice) {
      honeybadger.notify(notice);
    }
  }

  const errorCodeId = window.localStorage.getItem(LS_ERROR_CODE_KEY);

  window.localStorage.removeItem(LS_ERROR_NOTIFICATION_KEY);
  window.localStorage.removeItem(LS_ERROR_CODE_KEY);

  return errorCodeId;
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import { mapQueryStringToGraphqlParams } from '@utils/fns/map-query-string-to-graphql-params';
import { FilterKinds, type AllowedComparators } from '@utils/hooks/use-filtering/use-filtering';
import type { ParsedQuery } from 'query-string';
import type { GraphqlResponse } from 'src/types';

export type FilteredQueryResponse = {
  hasItems: boolean;
  sortingRules: string[];
  total: number;
  filteringRules: {
    key: string;
    availableValues: string[];
    type: FilterKinds;
    allowedComparators: AllowedComparators;
  }[];
};

export type Investment = {
  id: string;
  round: {
    date: Date;
    type: string;
  };
  investingRelationId: string;
  percentageOwnership: number;
  amount: number;
  shares: number;
  confirmed: boolean;
  initiator: string;
  archived: boolean;
  investor: {
    avatarUrl: string;
    name: string;
    id: string;
    isConnected: boolean;
    isFollowRequestPending: boolean;
  };
};

export const GET_INVESTMENTS_IN_FOUNDER_CACHE_KEY = 'get-investments-in-founder-cache-key';

export const getInvestmentInFounder =
  (start: number, limit: number, excludedParams: string[], otherParams?: ParsedQuery<string>) =>
  async (): Promise<GraphqlResponse<FilteredQueryResponse & { items: Investment[] }>> => {
    const params = otherParams && mapQueryStringToGraphqlParams(otherParams, excludedParams);

    const { data } = await axiosInstance.post('/query', {
      variables: params?.variables,
      query: `
    query getInvestmentInFounder${params?.arguments} {
        investmentsInFoundersCompany(start: ${start}, limit: ${limit}${params?.supplement}) {
            sortingRules
            raised
            filteringRules {
              key
              availableValues
              type
              allowedComparators
            }
            items {
                id
                round {
                  date
                  type
                }
                investingRelationId
                percentageOwnership
                amount
                shares
                confirmed
                initiator
                investingRelationId
                archived
                investor {
                  id
                  avatarUrl
                  name
                  isConnected
                  isFollowRequestPending
                }
            }
            total
            hasItems
        }
      }
    `,
    });

    return graphqlResponseWrapper(data, ['investmentsInFoundersCompany']);
  };

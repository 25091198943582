import styled from 'styled-components';

export const LogoWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};

  > a {
    margin: auto;
  }
`;

export const GradientWrapper = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.radius.default};
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    border-radius: inherit;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -2px;
    background: ${({ theme }) => theme.gradient.auth};
  }
`;

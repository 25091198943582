import { deleteNumericMetricNodeAction } from '@pages/content/pulse/api/delete-numeric-metric-node/delete-numeric-metric-node.action';
import { upsertNumericMetricNodeAction } from '@pages/content/pulse/api/upsert-numeric-metric/upsert-numeric-metric.action';
import { metricIdQueryParam } from '@pages/content/pulse/founder/parts/management/management';
import { useMutation } from '@tanstack/react-query';
import { dateFromYearAndMonth } from '@utils/fns/date-from-year-and-month';
import disableDotAndComma from '@utils/fns/disable-dot-and-comma';
import { useAutoSubmit } from '@utils/hooks/use-autosubmit/use-autosubmit';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { Tooltip } from 'antd';
import { DateTime } from 'luxon';
import { useEffect, useRef, type ChangeEvent, type KeyboardEvent } from 'react';
import { Mode, ScaleTransformPurpose, scaleTransform } from '../../numeric-editor';
import S from './row.styles';

export const nodeIdQueryParam = 'nodeId';
export const EDITOR_DEBOUNCE_DURATION = 750;
export const getRowHtmlId = (id: string) => `node-${id}`;

export const Row = ({
  i,
  id,
  value,
  forecast,
  note,
  year,
  month,
  getFieldHelpers,
  getFieldProps,
  getFieldMeta,
  mode,
  disabledDates,
  table,
  labels,
  discard,
  active,
  setActive,
  scrollIntoView,
  metricId,
  scale,
  isCorrect,
  integratedMetricsView,
  extendedDependencyArray,
}: {
  i: number;
  id: string;
  value: number | null;
  forecast: number | null;
  note: string | null;
  year: number;
  month: number;
  getFieldHelpers: Function;
  getFieldProps: Function;
  getFieldMeta: Function;
  mode: Mode;
  disabledDates: string[];
  table: boolean;
  labels: {
    value: string;
    month: string;
    forecast: string;
    note: string;
  };
  active: boolean;
  metricId: string;
  discard: Function;
  setActive: Function;
  scrollIntoView: Function;
  scale: number;
  isCorrect: boolean;
  integratedMetricsView?: boolean;
  extendedDependencyArray: unknown[];
}) => {
  const valueRef = useRef<HTMLInputElement>(null);
  const forecastRef = useRef<HTMLInputElement>(null);
  const disableIntegratedField = integratedMetricsView && value !== null;
  const { [metricIdQueryParam]: selectedMetricId } = useQueryParams() as {
    [metricIdQueryParam]: string;
  };
  const [disabledTooltipLabel] = useTranslation(['pulse.editor.tooltip.disabledField']);

  const { formats } = useUserDateFormat();

  const { mutateAsync: update } = useMutation(upsertNumericMetricNodeAction(selectedMetricId));
  const { mutateAsync: remove } = useMutation(deleteNumericMetricNodeAction, {
    onSuccess: () => {
      discard(id);
    },
  });

  const { justSubmitted } = useAutoSubmit({
    submit: () =>
      update(
        integratedMetricsView
          ? {
              id,
              year,
              month,
              note,
              forecast: forecast ? scaleTransform(forecast, scale, ScaleTransformPurpose.WRITE) : forecast,
            }
          : {
              id,
              year,
              month,
              forecast: forecast ? scaleTransform(forecast, scale, ScaleTransformPurpose.WRITE) : forecast,
              value: value ? scaleTransform(value, scale, ScaleTransformPurpose.WRITE) : value,
              note,
            },
      ),
    watch: `${year} // ${month} // ${forecast} // ${value} // ${note}`,
    enabled: mode === Mode.EDIT && isCorrect,
    debounceDelay: EDITOR_DEBOUNCE_DURATION,
    messageOnSave: !table,
    preventUpdate: !active,
    extendedDependencyArray,
  });

  const valueKey = `[${i}].value`;
  const forecastKey = `[${i}].forecast`;
  const yearKey = `[${i}].year`;
  const monthKey = `[${i}].month`;
  const noteKey = `[${i}].note`;

  const fieldMeta = (key: string) => {
    const meta = getFieldMeta(key);

    if (mode === Mode.EDIT) return meta;

    return { ...meta, touched: true };
  };

  const nodeDateMeta = (() => {
    const yearMeta = getFieldMeta(yearKey);
    const monthMeta = getFieldMeta(monthKey);
    return {
      touched: mode !== Mode.EDIT || yearMeta.touched || monthMeta.touched,
      error: [yearMeta.error, monthMeta.error].filter((e) => e).join(', '),
    };
  })();

  const valueMeta = fieldMeta(valueKey);
  const forecastMeta = fieldMeta(forecastKey);

  useEffect(() => {
    if (forecastRef?.current) {
      forecastRef.current.focus();
    }
  }, [forecastMeta.error]);

  useEffect(() => {
    if (valueRef?.current && !integratedMetricsView) {
      valueRef.current.focus();
    }
  }, [valueMeta.error]);

  const nodeDate = dateFromYearAndMonth(getFieldProps(yearKey).value, getFieldProps(monthKey).value);

  const currentDate = DateTime.local();

  const { setValue: setForecastEntry, setTouched: setForecastEntryTouched } = getFieldHelpers(forecastKey);
  const { setValue: setValueEntry, setTouched: setValueEntryTouched } = getFieldHelpers(valueKey);
  const { setValue: setNoteEntry, setTouched: setNoteEntryTouched } = getFieldHelpers(noteKey);

  const setNodeDate = (selectedDate: Date | null) => {
    if (!selectedDate) return;

    const { setValue } = getFieldHelpers(`[${i}]`);
    const { setTouched: setYearEntryTouched } = getFieldHelpers(yearKey);
    const { setTouched: setMonthEntryTouched } = getFieldHelpers(monthKey);

    const date = DateTime.fromJSDate(selectedDate);

    setYearEntryTouched(true);
    setMonthEntryTouched(true);
    setValue(
      {
        forecast,
        note,
        id,
        year: date.year,
        month: date.month,
        value: date > currentDate ? null : value,
      },
      true,
    );

    setTimeout(() => scrollIntoView(id), 500);
  };

  const setNodeEntry =
    (setEntry: Function, setEntryTouched: Function, options?: { toNumber?: boolean }) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      setEntryTouched(true);
      setEntry((options?.toNumber ? Number(e.target.value) : e.target.value) || null, true);
    };

  const commonFieldProps = { cell: table, hideError: true };

  const ValueInput = valueMeta.error ? S.Input : S.NumberInput;
  const ForecastInput = forecastMeta.error ? S.Input : S.NumberInput;

  return (
    <S.Wrapper active={active}>
      <S.Fields
        id={getRowHtmlId(id)}
        table={table}
        justSaved={justSubmitted}
        active={active}
        onClick={() => setActive()}
      >
        <S.Field label={{ label: labels.month, for: monthKey + id }} {...commonFieldProps} {...nodeDateMeta}>
          {disableIntegratedField ? (
            <Tooltip title={disabledTooltipLabel}>
              <S.MonthPicker
                error={Boolean(nodeDateMeta.error)}
                value={nodeDate.isValid ? nodeDate.toJSDate() : null}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (e.keyCode !== 9) e.preventDefault();
                }}
                disabled
                onChange={setNodeDate}
                onFocus={() => setActive()}
                allowClear={false}
                format={formats.letterMonthLongYear}
                disabledDate={(d: Date) => disabledDates.includes(DateTime.fromJSDate(d).toFormat('yyyy-MM'))}
              />
            </Tooltip>
          ) : (
            <S.MonthPicker
              error={Boolean(nodeDateMeta.error)}
              value={nodeDate.isValid ? nodeDate.toJSDate() : null}
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.keyCode !== 9) e.preventDefault();
              }}
              onChange={setNodeDate}
              onFocus={() => setActive()}
              allowClear={false}
              format={formats.letterMonthLongYear}
              disabledDate={(d: Date) => disabledDates.includes(DateTime.fromJSDate(d).toFormat('yyyy-MM'))}
            />
          )}
        </S.Field>
        <S.Field label={{ label: labels.value, for: valueKey + id }} {...commonFieldProps} {...valueMeta}>
          {integratedMetricsView ? (
            <Tooltip title={disabledTooltipLabel}>
              <div>
                <ValueInput
                  {...getFieldProps(valueKey)}
                  ref={valueRef}
                  disabled={integratedMetricsView || (!valueMeta.error && nodeDate > DateTime.local())}
                  onFocus={setActive}
                  onKeyDown={!scale ? disableDotAndComma : undefined}
                  onChange={(v) => {
                    setNodeEntry(setValueEntry, setValueEntryTouched, { toNumber: true })(v);
                  }}
                />
              </div>
            </Tooltip>
          ) : (
            <ValueInput
              {...getFieldProps(valueKey)}
              ref={valueRef}
              disabled={!valueMeta.error && nodeDate > DateTime.local()}
              onFocus={setActive}
              onKeyDown={!scale ? disableDotAndComma : undefined}
              onChange={(v) => {
                setNodeEntry(setValueEntry, setValueEntryTouched, { toNumber: true })(v);
              }}
            />
          )}
        </S.Field>
        <S.Field label={{ label: labels.forecast, for: forecastKey + id }} {...commonFieldProps} {...forecastMeta}>
          <ForecastInput
            {...getFieldProps(forecastKey)}
            ref={forecastRef}
            onFocus={setActive}
            onKeyDown={!scale ? disableDotAndComma : undefined}
            onChange={(v) => {
              setNodeEntry(setForecastEntry, setForecastEntryTouched, { toNumber: true })(v);
            }}
          />
        </S.Field>
        <S.NoteField label={{ label: labels.note, for: noteKey + id }} {...commonFieldProps} {...fieldMeta(noteKey)}>
          <S.NoteInput
            {...getFieldProps(noteKey)}
            onFocus={setActive}
            onChange={(v) => setNodeEntry(setNoteEntry, setNoteEntryTouched)(v)}
          />
        </S.NoteField>
        <S.Check />
      </S.Fields>
      <S.Remove
        onClick={() => {
          if (!disableIntegratedField) {
            if (mode === Mode.EDIT) {
              return remove({ metricId, nodeId: id });
            }

            discard(id);
          }
        }}
        isDisabled={disableIntegratedField}
      />
    </S.Wrapper>
  );
};

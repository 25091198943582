import ChatIcon from '@assets/icons/chat-icon.svg?react';
import GreenTickIcon from '@assets/icons/circular-green-tick-icon.svg?react';
import RedTickIcon from '@assets/icons/circular-red-tick-icon.svg?react';
import OrangeSentIcon from '@assets/icons/sent-icon.svg?react';
import { DateFormat } from '@i18n/date-formats';
import { ERoleApplicationStatus } from '@pages/content/roles/parts/applications/api/get-role-application.actions';
import { TableColumn } from '@parts/table-column/table-column';
import { TwoLinesEllipsis } from '@parts/two-lines-ellipsis/two-lines-ellipsis';
import { Links } from '@router/links';
import type { MutateFunction } from '@tanstack/react-query';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { Tooltip } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { Tag } from '../../../../parts/tag/tag';
import type { RoleApplication } from '../../../api/get-ned-role-applications.actions';
import S from './column.styles';

export const Columns = ({
  t,
  message,
  isMessageLoading,
  isDesktop,
}: {
  t: {
    [key: string]: string;
  };
  isDesktop: boolean;
  message: MutateFunction<{ data: string }, unknown, string, unknown>;
  isMessageLoading: boolean;
}): ColumnsType<RoleApplication> => {
  const { page } = useQueryParams();

  const { dateFormatter } = useUserDateFormat();

  const statusIcon = (status: ERoleApplicationStatus) => {
    if (status === ERoleApplicationStatus.APPROVED) {
      return (
        <Tag icon={<GreenTickIcon />} color="green">
          {t.invitedLabel}
        </Tag>
      );
    }
    if (status === ERoleApplicationStatus.REDJECTED) {
      return (
        <Tag icon={<RedTickIcon />} color="red">
          {t.rejectedLabel}
        </Tag>
      );
    }
    if (status === ERoleApplicationStatus.WAITTING_FOR_APPROVAL) {
      return (
        <Tag icon={<OrangeSentIcon />} color="orange">
          {t.sentLabel}
        </Tag>
      );
    }
    return null;
  };

  return [
    {
      title: t.roleLabel,
      dataIndex: 'role',
      key: 'role',
      width: '30%',
      render: (name, record) => (
        <S.TableRow title={t.roleLabel}>
          <TwoLinesEllipsis>
            <Tooltip title={name}>
              <S.Link to={Links.PREVIEW_ROLE(record.roleId).concat(`?page=${page}`)}>{name}</S.Link>
            </Tooltip>
          </TwoLinesEllipsis>
        </S.TableRow>
      ),
    },
    {
      title: t.companyLabel,
      dataIndex: 'company',
      key: 'company',
      width: '25%',
      render: (company, record) => (
        <TableColumn title={t.companyLabel}>
          <S.Name>
            <S.CircularImage size={30} imageUrl={record.companyAvatarUrl} />
            {company}
          </S.Name>
        </TableColumn>
      ),
    },
    {
      title: t.appliedLabel,
      dataIndex: 'appliedAt',
      key: 'appliedAt',
      width: '15%',
      render: (date) => (
        <TableColumn title={t.appliedLabel}>{dateFormatter(date, DateFormat.SHORT_MONTH_FULL_DATE)}</TableColumn>
      ),
    },
    {
      title: t.statusLabel,
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: (statusValue: ERoleApplicationStatus) => (
        <TableColumn title={t.statusLabel}>{statusIcon(statusValue)}</TableColumn>
      ),
    },
    {
      title: t.chatLabel,
      width: '15%',
      align: isDesktop ? 'right' : 'left',
      render: (_, record) =>
        record.status === ERoleApplicationStatus.APPROVED && (
          <S.MessageTableColumn title={t.chatLabel}>
            <S.Button type="link" onClick={() => message(record.companyId)} disabled={isMessageLoading}>
              <ChatIcon />
            </S.Button>
          </S.MessageTableColumn>
        ),
    },
  ];
};

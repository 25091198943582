import { Input as InputBase } from 'antd';
import styled from 'styled-components';

const Wrapper = styled(InputBase)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

export default { Wrapper };

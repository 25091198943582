export const LINKEDIN_URL_REGEXP = RegExp(
  /^(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?([a-z]{2,3}\.)?linkedin\.com\/(in|company)(.+)$/,
);
export const YOUTUBE_URL_REGEXP =
  /((https?\.)?www\.)?(youtube\.com\/\S*(?:(?:\/e(?:mbed))?\/|watch\?(?:\S*?&?v=))|youtu\.be\/)([a-zA-Z0-9_-]{6,11})/g;
export const VIMEO_URL_REGEXP =
  /(^((http|https):\/\/)?((www\.|player\.)|(www\.player\.))?vimeo\.com(\/[A-Za-z0-9\-._~:/?#[\]@!$&'()*+,;=]*)?)/g;
export const TWITTER_URL_REGEXP = RegExp(
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w-]*\/)*([\w-]*)/,
);
export const PHONE_NUMBER_REGEXP = /^\s*\+?\s*([0-9][\s-]*){9,}$/;
export const ONLY_NUMBERS_AND_DASHES_REGEXP = /^[0-9-]*$/;

import { useQuery } from '@tanstack/react-query';
import {
  GET_FOUNDER_LEARNING_PROGRESS_KEY,
  getFounderLearningProgressQuery,
} from '../api/get-founder-learning-progress.query';

export const useGetFounderLearningProgress = () => {
  const { data, isLoading, isFetching, isFetched, refetch, ...resetQueryResult } = useQuery(
    [GET_FOUNDER_LEARNING_PROGRESS_KEY],
    getFounderLearningProgressQuery,
    {
      refetchOnWindowFocus: true,
      cacheTime: 0,
    },
  );

  return {
    founderLearningProgressData: data,
    refetchFounderLearningProgress: refetch,
    isFounderOnboardingProgressLoading: isLoading,
    isFounderOnboardingProgressFetching: isFetching,
    isFounderOnboardingProgressFetched: isFetched,
    ...resetQueryResult,
  };
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_CONNECTION_NOTES_KEY = 'get-connection-notes-query';

type ResponseData = {
  content: string;
  createdAt: string;
  id: string;
  title: string;
};

type QueryConfig = {
  targetAccountId?: string;
};

export const getConnectionNotesQuery = async ({
  targetAccountId,
}: QueryConfig): Promise<GraphqlResponse<ResponseData[]>> => {
  const { data } = await axiosInstance.post('/query', {
    variables: {
      targetAccountId,
    },
    query: `
     query getConnectionNotes ($targetAccountId: ID!) {
      connectionNotes (targetAccountId: $targetAccountId) {
          id
          title
          content
          createdAt
      }
    }
    `,
  });

  return graphqlResponseWrapper(data, ['connectionNotes']);
};

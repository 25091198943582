import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export interface FounderRoleResponse {
  id: string;
  applications: number;
  postedAt: string;
  title: string;
  views: number;
  status: string;
  expiresAt: string;
}

export const GET_FOUNDER_ROLES_CACHE_KEY = 'get-founder-roles';

export const getFounderRolesAction = async (): Promise<GraphqlResponse<FounderRoleResponse[]>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getFounderRoles {
        founderRoles {
          id
          applications
          postedAt
          title
          views
          status
          expiresAt
        }    
      }    
    `,
  });

  return graphqlResponseWrapper(data, ['founderRoles']);
};

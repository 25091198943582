import type { ChartNode } from '@pages/content/pulse/founder/parts/management/parts/metrics/parts/numeric-editor/parts/live-chart/live-chart';
import { getBoundaryEntryTime } from '@utils/fns/get-boundary-entry-time';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Tooltip } from 'antd';
import { DateTime } from 'luxon';
import S from './periods.styles';

export enum ChartTimePeriod {
  THREE_MONTHS = '3M',
  SIX_MONTHS = '6M',
  YEAR_TO_DATE = 'YTD',
  ONE_YEAR = '1Y',
  THREE_YEARS = '3Y',
  MAX = 'Max',
}

type PeriodOption = {
  value: ChartTimePeriod;
  label: string;
  description: string;
  disabled: boolean;
};

export const getPeriodOption = (
  value: ChartTimePeriod,
  label: string,
  description: string,
  disabled: boolean = false,
) => ({
  value,
  label,
  description,
  disabled,
});

export const Periods = ({
  setActive,
  active,
  metricId,
  options,
  className,
  series,
}: {
  setActive: Function;
  active: ChartTimePeriod;
  className?: string;
  metricId: string;
  options?: PeriodOption[];
  series: { name: string; data: ChartNode[] }[];
}) => {
  const [valueSeries, forecastSeries] = series;
  const { data: values } = valueSeries;
  const { data: forecasts } = forecastSeries;

  const [
    last3monthsLabel,
    last3monthsDescriptionLabel,
    last6monthsLabel,
    lastSixMonthsDescriptionLabel,
    last1YearLabel,
    lastYearDescriptionLabel,
    ytdLabel,
    ytdDescriptionLabel,
    lastThreeYearsLabel,
    lastThreeYearsDescriptionLabel,
    maxLabel,
    maxDescription,
  ] = useTranslation([
    'pulse.intervals.last.3.months.label',
    'pulse.intervals.last.3.months.description',
    'pulse.intervals.last.6.months.label',
    'pulse.intervals.last.6.months.description',
    'pulse.intervals.last.1.year.label',
    'pulse.intervals.last.1.year.description',
    'pulse.intervals.last.YTD.label',
    'pulse.intervals.last.YTD.description',
    'pulse.intervals.last.3.year.label',
    'pulse.intervals.last.3.year.description',
    'pulse.intervals.max.label',
    'pulse.intervals.max.description',
  ]);

  const periods = (() => {
    const result = [
      getPeriodOption(ChartTimePeriod.THREE_MONTHS, last3monthsLabel, last3monthsDescriptionLabel),
      getPeriodOption(ChartTimePeriod.SIX_MONTHS, last6monthsLabel, lastSixMonthsDescriptionLabel),
    ];

    const oldestValue = getBoundaryEntryTime('past', values);
    const oldestForecast = getBoundaryEntryTime('past', forecasts);
    const newestValue = getBoundaryEntryTime('future', values);
    const newestForecast = getBoundaryEntryTime('future', forecasts);

    const monthsSpan = DateTime.fromMillis(Math.max(newestForecast, newestValue))
      .diff(
        DateTime.fromMillis(
          oldestValue && oldestForecast ? Math.min(oldestValue, oldestForecast) : oldestValue || oldestForecast,
        ),
        ['months'],
      )
      .toObject().months;

    if (monthsSpan && monthsSpan > 10) {
      result.push(getPeriodOption(ChartTimePeriod.YEAR_TO_DATE, ytdLabel, ytdDescriptionLabel));
      result.push(getPeriodOption(ChartTimePeriod.ONE_YEAR, last1YearLabel, lastYearDescriptionLabel));
    }

    if (monthsSpan && monthsSpan > 30) {
      result.push(getPeriodOption(ChartTimePeriod.THREE_YEARS, lastThreeYearsLabel, lastThreeYearsDescriptionLabel));
    }

    result.push(getPeriodOption(ChartTimePeriod.MAX, maxLabel, maxDescription));

    return result;
  })();

  return (
    <S.Wrapper className={className}>
      {(options || periods)
        .filter((o) => !o.disabled)
        .map(({ label, value, description, disabled }) => {
          const inputId = `history-period-${metricId}-${value}`;

          return (
            <Tooltip title={description} placement="bottom" key={value}>
              <S.Input
                type="radio"
                disabled={!series.find((s) => s.data.length > 0) || disabled}
                name={`${metricId}-periods`}
                value={value}
                checked={active === value}
                id={inputId}
                onChange={(e) => {
                  setActive(e.target.value);
                }}
              />
              <S.Label htmlFor={inputId}>{label}</S.Label>
            </Tooltip>
          );
        })}
    </S.Wrapper>
  );
};

import styled, { css } from 'styled-components';

const actionStyles = css`
  color: ${({ theme }) => theme.color.submitButton};
`;

const Action = styled.p`
  ${actionStyles};
  font-size: ${({ theme }) => theme.fontSize.small};
  margin: ${({ theme }) => theme.spacing.small} 0;
  display: inline-block;
`;

export default { Action };

import EmptyStateIcon from '@assets/icons/applictions-empty-state.svg?react';
import { FounderLayout } from '@layout/founder/founder.layout';
import { EmptyState } from '@parts/empty-state/empty-state';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { PageHeader } from '@parts/page-header/page-header';
import { Section } from '@parts/section/section';
import { Links } from '@router/links';
import { useQuery } from '@tanstack/react-query';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useParams } from 'react-router';
import { getRoleApplicationsAction, GET_ROLE_APPLICATIONS_CACHE_KEY } from '../api/get-role-application.actions';
import { getFounderRoleTitleAction, GET_FOUNDER_ROLE_TITLE_CACHE_KEY } from '../api/get-role-title.actions';
import { Table } from './parts/table/table';

export const ListApplicationsPage = () => {
  const [title, emptyStateDescription] = useTranslation(['roles.applications.title', 'roles.applications.empty']);
  const { id } = useParams() as { id: string };

  const {
    data: roleApplicationsResponse,
    isLoading: roleApplicationsIsLoading,
    refetch: refetchApplications,
  } = useQuery([`${GET_ROLE_APPLICATIONS_CACHE_KEY}_${id}`], getRoleApplicationsAction(id), {
    cacheTime: 0,
  });

  const { data: roleTitleResponse, isLoading: roleTitleIsLoading } = useQuery(
    [`${GET_FOUNDER_ROLE_TITLE_CACHE_KEY}_${id}`],
    getFounderRoleTitleAction(id),
  );

  const history = useHistory();

  const handleGoBack = () => history.push(Links.ROLES_BROWSE());

  if (
    roleApplicationsIsLoading ||
    roleTitleIsLoading ||
    !roleTitleResponse ||
    !roleTitleResponse.data ||
    !roleApplicationsResponse ||
    !roleApplicationsResponse.data
  ) {
    return (
      <FounderLayout>
        <FullHeightSpinner />
      </FounderLayout>
    );
  }

  return (
    <FounderLayout>
      <PageHeader title={roleTitleResponse.data.title} onBack={handleGoBack} />
      <Section header={title}>
        {!roleApplicationsResponse.data.length ? (
          <EmptyState subTitle={emptyStateDescription} Icon={EmptyStateIcon} />
        ) : (
          <Table applications={roleApplicationsResponse.data} refetchApplications={refetchApplications} />
        )}
      </Section>
    </FounderLayout>
  );
};

import { SecureLink } from '@parts/link/link';
import styled from 'styled-components';

const Section = styled.section`
  margin-top: ${({ theme }) => theme.spacing.base};
`;

const IconLink = styled(SecureLink)`
  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
`;

export default { Section, IconLink };

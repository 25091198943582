import { Logo as LogoBase } from '@parts/logo/logo';
import { Button as ButtonBase, Layout as LayoutBase } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Content as ContentBase } from '../parts/content/content';

const Logo = styled(LogoBase)`
  margin-left: 15px;
  width: 160px;
`;

const Header = styled(LayoutBase.Header)`
  display: flex;
  align-items: center;
`;

const DashboardLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const Content = styled(ContentBase)`
  padding-top: ${({ theme }) => theme.spacing.base};
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing.small};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    padding: 0 ${({ theme }) => theme.spacing.xsmall};
  }
`;

const Layout = styled(LayoutBase)`
  background: ${({ theme }) => theme.color.dashboardBackground};
  min-height: 100vh;
`;

const Logout = styled(ButtonBase)`
  color: ${({ theme }) => theme.color.primary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export default { Layout, Logo, Content, Logout, DashboardLink, Header };

import { ONBOARDING_STEP_PERFORMANCE_MANUAL_METRICS } from '@pages/content/onboarding/founder/paths';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';

export const useTipsContent = (stepKey: string) => {
  const [performanceManualMetrics] = useTranslation(['founder-onboarding.tips.content.performanceManualMetrics']);

  switch (stepKey) {
    case ONBOARDING_STEP_PERFORMANCE_MANUAL_METRICS:
      return performanceManualMetrics;
    default:
      return null;
  }
};

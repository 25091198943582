import { TileWrapper } from './parts/tile-wrapper';
import type { V2NotesProps } from './v2-notes.types';

import S from './v2-notes.styles';

const V2Notes = ({ action, carousel, ...rest }: V2NotesProps) => {
  return (
    <S.Row {...rest}>
      {action}
      {carousel}
    </S.Row>
  );
};

V2Notes.TextButton = S.TextButton;
V2Notes.ButtonWrapper = S.ButtonWrapper;
V2Notes.TileWrapper = TileWrapper;

export { V2Notes };

import type { HTMLAttributes } from 'react';
import { useV2AriaMobileConnectionCardContext } from '../v2-aria-mobile-connection-card-context';

import S from '../v2-aria-mobile-connection-card.styles';

type RowProps = {
  children: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export const Row = ({ children, ...rest }: RowProps) => {
  useV2AriaMobileConnectionCardContext();

  return <S.Row {...rest}>{children}</S.Row>;
};

import type { ReactNode } from 'react';
import S from './header.styles';

interface IHeaderProps {
  title: string;
  icon: ReactNode;
}

export const Header = ({ title, icon }: IHeaderProps) => (
  <S.Header>
    <S.Title>{title}</S.Title>
    {icon}
  </S.Header>
);

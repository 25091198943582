import { MetricType } from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import {
  LiveChart,
  type Node,
} from '@pages/content/pulse/founder/parts/management/parts/metrics/parts/numeric-editor/parts/live-chart/live-chart';
import { getMetricSymbol } from '@pages/content/pulse/parts/dashboards/parts/dashboard/metrics/parts/metric-item/parts/chart-metric/chart-metric';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { addMonths } from 'date-fns';
import { useState } from 'react';
import S from './playground-box.styles';

interface PlaygroundBoxProps {
  onPlayed: () => void;
  currencySymbol: string;
}

export const PlaygroundBox = ({ onPlayed, currencySymbol }: PlaygroundBoxProps) => {
  const [metricLabel, exampleLabel, monthLabel, valueLabel, forecastLabel] = useTranslation([
    'founder-onboarding.manualMetrics.playgroundBox.metric',
    'founder-onboarding.manualMetrics.playgroundBox.example',
    'pulse.editor.month',
    'pulse.editor.valueLabel',
    'pulse.editor.forecastLabel',
  ]);

  const {
    deviceData: { isMobile },
  } = useDeviceDetect();

  const symbol = getMetricSymbol(MetricType.Money, { currencySymbol });
  const currentDate = new Date();

  const startingNodes: Node[] = [0, 1, 2].map((id) => ({
    year: addMonths(currentDate, id).getFullYear(),
    month: addMonths(currentDate, id).getMonth(),
    value: null,
    forecast: null,
    note: null,
    id: id.toString(),
  }));

  const [isMarked, setIsMarked] = useState(false);
  const [nodes, setNodes] = useState(startingNodes);

  const getDisplayDate = (node: Node) => {
    const date = new Date();
    date.setMonth(node.month);
    return `${date.toLocaleString('en-US', {
      month: 'short',
    })} ${node.year}`;
  };

  const markAsPlayed = (newState: Node[]) => {
    const values = newState.flatMap((node) => (node.value ? [node.value] : []));
    const forecasts = newState.flatMap((node) => (node.forecast ? [node.forecast] : []));

    if (!isMarked && (values.length > 1 || forecasts.length > 1)) {
      onPlayed();
      setIsMarked(true);
    }
  };

  const setValueOrForecast = (id: string, type: 'value' | 'forecast', value: number) => {
    const prevState = nodes;
    const editedNode = prevState.find((node) => node.id === id)!;
    editedNode[type === 'value' ? 'value' : 'forecast'] = value;
    const newState = prevState.map((oldNode) => (oldNode.id === id ? editedNode : oldNode));
    setNodes(newState);
    markAsPlayed(newState);
  };

  return (
    <S.Box>
      <S.TitleWrapper>
        <S.Title>{metricLabel}</S.Title>
        <S.Badge>{exampleLabel}</S.Badge>
      </S.TitleWrapper>

      <S.Columns withBorder>
        <S.Column>{monthLabel}</S.Column>
        <S.Column>
          {valueLabel} {!isMobile && `(${symbol})`}
        </S.Column>
        <S.Column>
          {forecastLabel} {!isMobile && `(${symbol})`}
        </S.Column>
      </S.Columns>

      <S.EditArea>
        {nodes.map((node) => (
          <S.Columns key={node.id}>
            <S.Column>{getDisplayDate(node)}</S.Column>
            <S.Column>
              <S.NumberInput
                data-testid={`node-${node.id}-value-input`}
                onChange={(event) => setValueOrForecast(node.id, 'value', Number(event.target.value))}
              />
            </S.Column>
            <S.Column>
              <S.NumberInput
                data-testid={`node-${node.id}-forecast-input`}
                onChange={(event) => setValueOrForecast(node.id, 'forecast', Number(event.target.value))}
              />
            </S.Column>
          </S.Columns>
        ))}
      </S.EditArea>

      <S.ChartArea>
        <LiveChart nodes={nodes} showPeriods={false} />
      </S.ChartArea>
    </S.Box>
  );
};

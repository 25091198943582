import Padlock from '@assets/icons/padlock.svg';
import { Roles } from '@domain/accounts/roles';
import { AcceptOrIgnoreInvitation } from '@pages/content/lens/parts/cards/parts/accept-or-ignore-invitation/accept-or-ignore-invitation';
import { useUpgradeFreemiumModal } from '@pages/content/start/founder/hooks/use-upgrade-freemium-modal/use-upgrade-freemium-modal';
import { MessageSymbol } from '@parts/message-symbol/message-symbol';
import { useMutation, type MutationFunction } from '@tanstack/react-query';
import { useEngageConversation } from '@utils/hooks/use-engage-conversation/use-engage-conversation';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { ConnectionType } from '@utils/type-definitions/connections';
import type { ReactNode } from 'react';
import S from './engagement-decision.styles';

interface IEngagementDecision {
  id: string;
  followRequestId: string | null;
  followRequestType: ConnectionType | null;
  userRole: Roles.FOUNDER | Roles.INVESTOR;
  cancelConnectionAction: MutationFunction<{ data: string }, string>;
  connectAction: MutationFunction<{ data: string }, string>;
  messageAction: MutationFunction<{ data: string }, string>;
  notInterested: Function;
  refetch: Function;
  messagingShown?: boolean;
  isFounderFreemium?: boolean;
}

export const EngagementDecision = ({
  id,
  followRequestId,
  followRequestType,
  userRole,
  cancelConnectionAction,
  connectAction,
  notInterested,
  messageAction,
  refetch,
  messagingShown = true,
  isFounderFreemium = false,
}: IEngagementDecision) => {
  const [requestSent, notInterestedLabel, connectLabel, cancelLabel] = useTranslation([
    'lens.match.requestSent',
    'lens.match.notInterested',
    'lens.match.connect',
    'lens.match.cancel',
  ]);

  const { modal: UpgradeFreemiumModal, show: showFreemiumModal } = useUpgradeFreemiumModal();

  const mutationConfig = {
    onSuccess: () => refetch(),
  };

  const { mutateAsync: message, isLoading: isMessageLoading } = useEngageConversation(messageAction);
  const { mutateAsync: cancel } = useMutation(cancelConnectionAction, mutationConfig);
  const { mutateAsync: connect, isLoading } = useMutation(connectAction, mutationConfig);

  const determineConnectAction = isFounderFreemium ? showFreemiumModal : () => connect(id);

  const footerButtons = (
    <>
      <S.Connect data-testid="lens-smart-match-connect" onClick={determineConnectAction} loading={isLoading}>
        {isFounderFreemium ? <S.Padlock src={Padlock} alt="lock" loading="lazy" /> : null}
        {connectLabel}
      </S.Connect>
      <S.NotInterested data-testid="lens-smart-match-not-interested" type="link" onClick={() => notInterested()}>
        {notInterestedLabel}
      </S.NotInterested>
      {UpgradeFreemiumModal}
    </>
  );

  const requestSentBody = (idToCancel: string) => (
    <S.RequestSent>
      <S.GreenTick /> {requestSent}
      <S.Cancel type="link" onClick={() => cancel(idToCancel)}>
        {cancelLabel}
      </S.Cancel>
    </S.RequestSent>
  );

  const showAcceptFollowRequest =
    followRequestId && userRole === Roles.INVESTOR && followRequestType === ConnectionType.Invitation;

  let decisionBody: ReactNode;

  if (showAcceptFollowRequest) {
    decisionBody = <AcceptOrIgnoreInvitation id={followRequestId} refetch={refetch} />;
  } else if (followRequestId) {
    decisionBody = requestSentBody(followRequestId);
  } else {
    decisionBody = footerButtons;
  }

  return (
    <S.Footer noBorder>
      <S.DecisionsHolder>{decisionBody}</S.DecisionsHolder>
      {messagingShown && (
        <S.Message
          data-testid="lens-smart-match-message"
          disabled={isMessageLoading}
          type="link"
          onClick={() => message(id)}
        >
          <MessageSymbol />
        </S.Message>
      )}
    </S.Footer>
  );
};

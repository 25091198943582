import React from 'react';
import { V2Icon } from '../v2-icon/v2-icon';
import type { V2CollapseProps } from './v2-collapse.types';

import S from './v2-collapse.styles';

const ExpandIcon = () => <V2Icon name="chevron-down" size="sm" />;

export const V2Collapse: React.FC<V2CollapseProps> = ({ items, defaultActiveKey, onChange, accordion = false }) => {
  return (
    <S.V2Collapse
      defaultActiveKey={defaultActiveKey}
      onChange={onChange}
      accordion={accordion}
      expandIcon={ExpandIcon}
      expandIconPosition="right"
      ghost
    >
      {items.map((item) => (
        <S.V2Collapse.Panel header={item.title} key={item.key}>
          {item.content}
        </S.V2Collapse.Panel>
      ))}
    </S.V2Collapse>
  );
};

import metricIcon from '@assets/icons/metric-icon.svg?react';
import { SubmitButton } from '@parts/submit-button/submit-button';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './empty-dashboard-state.styles';

const EmptyDashboardState = ({ onClick }: { onClick: () => void }) => {
  const [emptyDashboardLabel, addMetricLabel] = useTranslation(['pulse.dashboard.empty', 'pulse.dashboard.addMetric']);

  return (
    <S.EmptyState Icon={metricIcon} title={emptyDashboardLabel}>
      <SubmitButton data-testid="empty-dashboard-state-btn" onClick={onClick}>
        {addMetricLabel}
      </SubmitButton>
    </S.EmptyState>
  );
};

export default EmptyDashboardState;

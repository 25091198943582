export const INVESTOR_ONBOARDING_STEP_TYPE_KEY = 'user-type';
export const INVESTOR_ONBOARDING_STEP_INTRO_KEY = 'intro';
export const INVESTOR_ONBOARDING_STEP_PERSONAL_KEY = 'personal';
export const INVESTOR_ONBOARDING_STEP_BUSINESS_KEY = 'business';
export const INVESTOR_ONBOARDING_STEP_INVESTMENT_KEY = 'investment';
export const INVESTOR_ONBOARDING_STEP_MATCH_KEY = 'match';
export const INVESTOR_ONBOARDING_STEP_COMMUNITY_KEY = 'community';
export const INVESTOR_ONBOARDING_STEP_AFFIRMATIONS_KEY = 'affirmations';
export const INVESTOR_ONBOARDING_STEP_TRUSTED_SENDER_KEY = 'trusted-sender';

export type InvestorOnboardingStep =
  | typeof INVESTOR_ONBOARDING_STEP_TYPE_KEY
  | typeof INVESTOR_ONBOARDING_STEP_INTRO_KEY
  | typeof INVESTOR_ONBOARDING_STEP_PERSONAL_KEY
  | typeof INVESTOR_ONBOARDING_STEP_BUSINESS_KEY
  | typeof INVESTOR_ONBOARDING_STEP_INVESTMENT_KEY
  | typeof INVESTOR_ONBOARDING_STEP_MATCH_KEY
  | typeof INVESTOR_ONBOARDING_STEP_COMMUNITY_KEY
  | typeof INVESTOR_ONBOARDING_STEP_AFFIRMATIONS_KEY
  | typeof INVESTOR_ONBOARDING_STEP_TRUSTED_SENDER_KEY;

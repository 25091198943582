import { GET_FOUNDER_DATA_CACHE_KEY, getFounderData } from '@pages/auth/api/get-founder-data/get-founder-data.actions';
import { FundingRound, openQueryParam } from '@pages/content/parts/funding-round/funding-round';
import {
  GET_INVESTMENTS_IN_FOUNDER_CACHE_KEY,
  getInvestmentInFounder,
  type Investment,
} from '@pages/content/pulse/api/get-investments-in-founder/get-investments-in-founder.action';
import {
  GET_PENDING_INVESTMENTS_FOUNDER_CACHE_KEY,
  getPendingInvestmentsAction,
} from '@pages/content/pulse/api/get-pending-investments.action';
import { useDrawer } from '@parts/drawer/use-drawer';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { Investments } from '@parts/investments/investments';
import { Pagination } from '@parts/pagination/pagination';
import { PendingInvestments } from '@parts/pending-investments/pending-investments';
import { Section as SectionBase } from '@parts/section/section';
import { pulseInvestorsKey } from '@router/tabs-keys';
import { useQuery } from '@tanstack/react-query';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useFundingRoundsState } from '@utils/hooks/use-funding-rounds-state/use-funding-rounds-state';
import { useQueryPagination } from '@utils/hooks/use-query-pagination/use-query-pagination';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { CreateEditor, idQueryParam, investmentIdQueryParam } from '../editor/create-editor';
import { UpdateEditor } from '../editor/update-editor';
import { setFundingRounds, toggleFundingRoundsLoading } from '../progress/parts/context/funding-rounds.action-creators';
import { EmptyState } from './parts/empty-state/empty-state';
import { CapTable } from './parts/table/table';

const ITEMS_PER_PAGE = 6;
const PAGE_PARAM = 'page';

export const Shareholders = ({ allowedFounderPMT }: { allowedFounderPMT: boolean }) => {
  const {
    deviceData: { isDesktop },
  } = useDeviceDetect();
  const { [idQueryParam]: id, [investmentIdQueryParam]: investmentId } = useQueryParams() as {
    [idQueryParam]: string;
    [investmentIdQueryParam]: string;
  };

  const [addShareholder] = useTranslation(['shareholders.investor.emptyState.addShareholder']);

  const { items, total, isLoading, refetch, hasItems, querying, additionalData, ...pagination } =
    useQueryPagination<Investment>({
      itemsPerPage: ITEMS_PER_PAGE,
      pageParam: PAGE_PARAM,
      excludedParams: [pulseInvestorsKey, 'progress', idQueryParam, investmentIdQueryParam, openQueryParam],
      action: getInvestmentInFounder,
      cacheKey: GET_INVESTMENTS_IN_FOUNDER_CACHE_KEY,
      scrollToTopOnPageChanged: !isDesktop,
      queryConfig: {
        enabled: allowedFounderPMT,
        cacheTime: 0,
        refetchOnWindowFocus: true,
      },
    });

  const {
    data: response,
    isLoading: pendingInvestmentsLoading,
    refetch: refetchPendingInvestment,
  } = useQuery([GET_PENDING_INVESTMENTS_FOUNDER_CACHE_KEY], getPendingInvestmentsAction, {
    enabled: allowedFounderPMT,
  });

  const { dispatch } = useFundingRoundsState();

  useQuery([GET_FOUNDER_DATA_CACHE_KEY], getFounderData, {
    onSuccess: (resp) => {
      dispatch(setFundingRounds(resp?.data?.fundingRounds || []));
      dispatch(toggleFundingRoundsLoading(false));
    },
  });

  const { raised } = additionalData as { raised: number };

  const { Drawer, show: showDrawer, hide: hideDrawer } = useDrawer(Boolean(id));

  const pendingInvestments =
    (response?.data?.length || 0) > 0 ? (
      <PendingInvestments
        refetch={refetchPendingInvestment}
        data={response?.data || []}
        isLoading={pendingInvestmentsLoading}
      />
    ) : (
      <></>
    );

  const renderEditor = investmentId ? (
    <UpdateEditor investmentId={investmentId} id={id} drawer={Drawer} hideDrawer={hideDrawer} refetch={refetch} />
  ) : (
    <CreateEditor data-testid="AddInvestor" id={id} drawer={Drawer} hideDrawer={hideDrawer} refetch={refetch} />
  );

  if (!allowedFounderPMT)
    return (
      <>
        <SectionBase>
          <FundingRound />
        </SectionBase>
        {renderEditor}
      </>
    );

  if (isLoading) return <FullHeightSpinner small />;

  return (
    <>
      <SectionBase>
        <FundingRound />
      </SectionBase>
      {renderEditor}
      {pendingInvestments}
      {hasItems ? (
        <Investments showDrawer={showDrawer} hideExpandButton raised={raised} addLabel={addShareholder}>
          <CapTable refetch={refetch} querying={querying} items={items} />
          {pagination && pagination.maxPages > 1 && <Pagination centered {...pagination} />}
        </Investments>
      ) : (
        <EmptyState onButtonClick={showDrawer} />
      )}
    </>
  );
};

import TickBase from '@assets/icons/input-feedback-tick.svg?react';
import styled from 'styled-components';

const HighlightWrapper = styled.div`
  display: flex;
  position: relative;
  overflow: visible;
`;

const HighlightWrapperChildren = styled.div`
  flex: 1;
`;

const TickMarker = styled(TickBase)<{ $visible: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  margin-left: ${({ theme }) => theme.spacing.small};
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  transition: opacity 250ms ease-in-out;
  transform: translateX(30px);
  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.xsmall}) {
    transform: translateX(5px) translateY(-5px);
    z-index: 1000;
  }
`;

export default { HighlightWrapper, HighlightWrapperChildren, TickMarker };

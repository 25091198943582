import { Roles } from '@domain/accounts/roles';
import type { Investment } from '@pages/content/pulse/api/get-investments-in-founder/get-investments-in-founder.action';
import type { Trigger } from '@pages/content/pulse/api/get-triggers/get-triggers.action';
import { idQueryParam, investmentIdQueryParam } from '@pages/content/pulse/founder/parts/editor/create-editor';
import { Icon } from '@parts/icon/icon';
import { TableColumn } from '@parts/table-column/table-column';
import { TableStatus } from '@parts/table-status/table-status';
import { UserTableRow } from '@parts/user-table-row/user-table-row';
import { currencyToShortFormat } from '@utils/fns/currency-to-short-format';
import type { ColumnsQuerying } from '@utils/hooks/use-filtering/use-filtering';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { Tooltip } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import * as H from 'history';
import { FilterHeader } from '../../../../../../../../../../parts/filter-header/filter-header';
import { Archived } from '../../archived-label/archived-label';
import S from './columns.styles';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { DateFormat } from '@i18n/date-formats';

export const Columns = ({
  t,
  querying,
  history,
  refetch,
}: {
  t: { [key: string]: string };
  history: H.History<H.LocationState>;
  querying: ColumnsQuerying;
  refetch: Function;
}): ColumnsType<Trigger> => {
  const onEditClick = (v: Investment) =>
    history.push(`?${idQueryParam}=${v.investingRelationId}&${investmentIdQueryParam}=${v.id}`);

  const {
    state: { userRole },
  } = useUserAccount();

  const { dateFormatter } = useUserDateFormat();

  const [confirmTitleLabel, confirmLabel, cancelLabel, contentLabel, contentQuestionLabel, shareholdersTableTooltip] =
    useTranslation([
      'portfolio.investment.modalConnection.title',
      'portfolio.investment.modalConnection.confirm',
      'portfolio.investment.modalConnection.cancel',
      'portfolio.investment.modalConnection.content',
      'portfolio.investment.modalConnection.contentQuestion',
      'shareholders.table.tooltip',
    ]);

  return [
    UserTableRow<Investment>({
      history,
      toRole: Roles.INVESTOR,
      title: <FilterHeader querying={querying} dataKey="investor.name" title={t.nameOrEmailLabel} />,
      nameKey: 'investor.name',
      idKey: 'investor.id',
      avatarUrlKey: 'investor.avatarUrl',
      redirectEvaluation: [
        {
          shouldBePresent: [Roles.INVESTOR, 'isConnected'],
          key: 'investor.id',
        },
      ],
      connectionModal: {
        shouldBePresent: userRole === Roles.FOUNDER,
        confirmLabel,
        cancelLabel,
        contentLabel,
        contentQuestionLabel,
        confirmTitleLabel,
        isFollowRequestPending: [Roles.INVESTOR, 'isFollowRequestPending'],
      },

      avatarSize: 'medium',
      width: '370px',
      refetch,
      limitNameWidth: true,
    }),
    {
      title: <FilterHeader querying={querying} dataKey="round.date" title={t.roundDateLabel} />,
      dataIndex: ['round', 'date'],
      key: 'round.date',
      width: '30%',
      render: (v) => (
        <TableColumn title={t.roundDateLabel}>{dateFormatter(v, DateFormat.LETTER_MONTH_LONG_YEAR)}</TableColumn>
      ),
    },
    {
      title: <FilterHeader querying={querying} dataKey="round.type" title={t.roundTypeLabel} />,
      key: 'round.type',
      width: '25%',
      render: (record) => (
        <TableColumn title={t.roundTypeLabel}>
          {record.round.type}
          <Tooltip
            title={() => {
              if (record.confirmed) {
                return record.initiator === 'Investor' ? t.confirmedByFounderLabel : t.confirmedByInvestorLabel;
              }
              return t.awaitingConfirmationLabel;
            }}
          >
            {record.confirmed ? <TableStatus.Confirmed /> : <TableStatus.Pending />}
          </Tooltip>
        </TableColumn>
      ),
    },
    {
      title: <FilterHeader querying={querying} dataKey="shares" title={t.sharesLabel} />,
      dataIndex: 'shares',
      key: 'shares',
      width: '15%',
      render: (v) => <TableColumn title={t.sharesLabel}>{v}</TableColumn>,
    },
    {
      title: <FilterHeader querying={querying} dataKey="amount" title={t.amountLabel} />,
      dataIndex: 'amount',
      key: 'amount',
      width: '15%',
      render: (v) => <TableColumn title={t.amountLabel}>{v ? `£${currencyToShortFormat(v)}` : null}</TableColumn>,
    },
    {
      title: <FilterHeader querying={querying} dataKey="percentageOwnership" title={t.percentageOwnershipLabel} />,
      dataIndex: 'percentageOwnership',
      key: 'percentageOwnership',
      width: '15%',
      render: (v) => <TableColumn title={t.percentageOwnershipLabel}>{v ? `${v}%` : '-'}</TableColumn>,
    },
    {
      width: '5%',
      render: (v) => {
        if (v.archived) {
          return <Archived />;
        }
        if (v.confirmed) {
          return (
            <Tooltip title={shareholdersTableTooltip}>
              <S.Controls data-testid="editIconDisabled">
                <Icon icon={S.Edit} disabled />
              </S.Controls>
            </Tooltip>
          );
        }
        return (
          <S.Controls data-testid="editIconEnabled" onClick={() => onEditClick(v)}>
            <Icon icon={S.Edit} data-testid="edit-icon" />
          </S.Controls>
        );
      },
    },
  ];
};

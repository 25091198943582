import { useQuery } from '@tanstack/react-query';
import { GET_SKILLS_SETS_KEY, getMostPopularSkillsets } from '../api/get-most-popular-skillsets.query';

export const useGetMostPopularSkillsets = () => {
  const query = useQuery([GET_SKILLS_SETS_KEY], getMostPopularSkillsets);

  return {
    mostPopularSkillsetsData: query.data?.data ?? [],
    isMostPopularSkillsetsLoading: query.isLoading,
    isMostPopularSkillsetsFetching: query.isFetching,
    isMostPopularSkillsetsFetched: query.isFetched,
    ogQueryResult: query,
  };
};

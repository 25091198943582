import AlertBase from '@parts/alert/alert';
import { SecureLink as LinkBase } from '@parts/link/link';
import { SubmitButton as ButtonBase } from '@parts/submit-button/submit-button';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Heading = styled.header`
  margin-bottom: ${({ theme }) => theme.spacing.base};
`;

const Title = styled.h1`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.color.fontPrimary};
  font-size: 22px;
  margin-bottom: ${({ theme }) => theme.spacing.small};
  text-align: center;
`;

const Subtitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.xmedium};
  color: ${({ theme }) => theme.color.fontSecondary};
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
`;

const DescriptionList = styled.dl`
  display: flex;
  flex-direction: column;
  gap: 23px;
  margin-bottom: ${({ theme }) => theme.spacing.base};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    padding: ${({ theme }) => theme.spacing.small};
  }
`;

const Buttons = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xbase};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin-bottom: 100px;
  }
`;

const LearnMore = styled(ButtonBase)`
  background-color: transparent;
  color: ${({ theme }) => theme.baseColors.Blue200};
  margin-left: ${({ theme }) => theme.spacing.small};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin: 0;
    margin-top: ${({ theme }) => theme.spacing.small};
  }

  &:hover,
  &:focus {
    background-color: transparent;
    color: ${({ theme }) => theme.baseColors.Blue200};
  }
`;

const Link = styled(LinkBase)`
  text-decoration: none;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.color.link};
`;

const Alert = styled(AlertBase)`
  padding: 10px 15px;

  .ant-alert-message {
    justify-content: space-between;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    .ant-alert-message {
      flex-direction: column;

      span {
        align-self: flex-start;
      }

      a {
        align-self: flex-start;
        margin-top: ${({ theme }) => theme.spacing.xsmall};
      }
    }
  }
`;

const Logo = styled.img`
  object-fit: contain;
  margin-bottom: ${({ theme }) => theme.spacing.base};
`;

export default { Wrapper, Title, Subtitle, Heading, DescriptionList, Buttons, LearnMore, Link, Alert, Logo };

import styled from 'styled-components';

const Divider = styled.span`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.border};
  display: block;
  margin-top: ${({ theme }) => theme.spacing.xlarge};
  margin-bottom: ${({ theme }) => theme.spacing.xlarge};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    margin-top: ${({ theme }) => theme.spacing.base};
    margin-bottom: ${({ theme }) => theme.spacing.base};
  }
`;

export default { Divider };

import type { ReactNode } from 'react';
import S from './v2-radio-group.styles';

export type RadioGroupVariant = 'vertical' | 'horizontal';

type V2RadioGroupProps = {
  disabled?: boolean;
  defaultValue?: string | number;
  variant?: RadioGroupVariant;
  'data-testid'?: string;
  children: ReactNode;
  name?: string;
  className?: string;
};

export const V2RadioGroup = ({
  disabled = false,
  defaultValue,
  'data-testid': testId = 'v2-radio-group',
  variant = 'horizontal',
  children,
  name = 'radio-group',
  className,
}: V2RadioGroupProps) => (
  <S.V2RadioGroup
    disabled={disabled}
    defaultValue={defaultValue}
    data-testid={testId}
    variant={variant}
    name={name}
    className={className}
  >
    {children}
  </S.V2RadioGroup>
);

import { useState } from 'react';
import CustomModal from './modal';

export const useModal = (open: boolean = false) => {
  const [isOpen, setIsOpen] = useState(() => open);

  return {
    Modal: CustomModal,
    open: isOpen,
    show: () => setIsOpen(true),
    hide: () => setIsOpen(false),
  };
};

import React from 'react';

import S from './v2-container.styles';

type V2ContainerProps = Partial<{
  children: React.ReactNode;
  background: string;
  'data-testid': string;
}> &
  React.HTMLAttributes<HTMLDivElement>;

export const V2Container: React.FC<V2ContainerProps> = ({
  children,
  background,
  'data-testid': testId = 'v2-container',
  ...rest
}) => {
  return (
    <S.V2Container $background={background} data-testid={testId} {...rest}>
      {children}
    </S.V2Container>
  );
};

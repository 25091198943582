import EyeIconBase from '@assets/icons/eye.svg?react';
import { Button } from 'antd';
import { transparentize } from 'polished';
import styled from 'styled-components';

const EyeIcon = styled(EyeIconBase)`
  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
  margin-right: 5px;
`;

const NotificationWrapper = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.color.fontPrimary};
  padding: ${({ theme }) => theme.spacing.small};
  border: 1px solid ${({ theme }) => theme.baseColors.Blue200};
  background-color: ${({ theme }) => transparentize(0.81, theme.baseColors.Blue200)};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const Link = styled(Button)`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.baseColors.Blue200};
  padding: 0;
  text-decoration: underline;
  margin-left: 5px;
  span {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

export default { EyeIcon, NotificationWrapper, Link };

import RawSearchIcon from '@assets/icons/search.svg?react';
import { Collapse, Input } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    margin-bottom: ${({ theme }) => theme.spacing.medium};
  }
`;

const Headline = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontSize.regular};
  margin: ${({ theme }) => theme.spacing.small} 0 ${({ theme }) => theme.spacing.xsmall} 0;
`;

const Info = styled.p`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.fontSecondary};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
`;

const Search = styled(Input)`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.xmedium};
  margin-bottom: ${({ theme }) => theme.spacing.medium};

  input {
    color: ${({ theme }) => theme.color.primary};
  }
`;

const SearchIcon = styled(RawSearchIcon)`
  height: 13px;
  path {
    fill: ${({ theme }) => theme.color.placeholder};
  }
`;

const SearchInput = styled(Input)`
  height: 32px;
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  margin-block: 10px;

  .ant-input {
    height: fit-content;
  }
`;

const PanelWrapper = styled.div`
  width: 270px;
  position: absolute;
  left: 0;
  height: 46px;
  top: 0;
`;

const Panel = styled(Collapse.Panel)<{ isActive?: boolean }>`
  margin: 5px 0;
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    bottom: 0;
  }

  .ant-collapse-header {
    color: ${({ theme }) => theme.color.fontPrimary} !important;
    background: ${({ theme }) => theme.color.panelBackground};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: ${({ isActive }) => (isActive ? '0' : '5px')};
    border-bottom-left-radius: ${({ isActive }) => (isActive ? '0' : '5px')};
  }
  .ant-collapse-content-box {
    background: ${({ theme }) => theme.color.panelBackground};
    border-bottom-right-radius: ${({ isActive }) => (isActive ? '5px' : '0')};
    border-bottom-left-radius: ${({ isActive }) => (isActive ? '5px' : '0')};
  }
`;

export default {
  Wrapper,
  Headline,
  Info,
  Search,
  SearchIcon,
  SearchInput,
  PanelWrapper,
  Panel,
};

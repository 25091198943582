import { FormikField } from '@pages/content/profile/parts/formik-field/formik-field';
import { Col, Row } from 'antd';
import styled from 'styled-components';

const FieldLabel = styled.p`
  display: block;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

const SeparatedColumn = styled(Col)`
  border-left: 1px solid ${({ theme }) => theme.color.border};
`;

const RowWithBottomMargin = styled(Row)`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`;

const FormikFieldWithNoMargin = styled(FormikField)`
  margin-bottom: 0;
`;

export default { FieldLabel, SeparatedColumn, RowWithBottomMargin, FormikFieldWithNoMargin };

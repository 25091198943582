import type { GraphqlResponse } from '@/types';
import { axiosInstance } from '@/utils/axios/axios-instance';

type ResponseData = {
  industries: string[];
  skillSets: string[];
};

export const GET_SKILLS_AND_INDUSTRIES_KEY = 'get-skills-and-industries-key';

export const getSkillsAndIndustries = async (): Promise<GraphqlResponse<ResponseData>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
      query getSmartMatchOptions {
        industries
        skillSets
      }
    `,
  });

  return data;
};

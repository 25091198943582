import { useCircularBarGraphOptions } from '../circular-bar-graph-options';
import S from './legend.styles';

export const Legend = ({ data, isBarChartView }: { data: [string, number][]; isBarChartView?: boolean }) => {
  const { colors } = useCircularBarGraphOptions();

  return (
    <S.Wrapper isBarChartView={isBarChartView}>
      {data.map((item, index) => (
        <S.Item key={index}>
          <S.BulletPoint color={colors![index % colors!.length]} /> <S.Text>{`${item[0]} (${item[1]})`}</S.Text>
        </S.Item>
      ))}
    </S.Wrapper>
  );
};

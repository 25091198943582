import { DotsIcon as DotsIconBase } from '@parts/dropdown-list/dropdown-list.styles';
import { Button as ButtonBase, Menu } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`;

const Buttons = styled.div`
  display: flex;
  gap: 10px;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-direction: column;
  }

  button:nth-child(2) {
    svg {
      margin-right: 5px;
    }
  }
`;

const Item = styled(Menu.Item)`
  > span {
    color: ${({ theme }) => theme.color.dropdownItem};
  }
`;

const Button = styled(ButtonBase)`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.color.submitButton};
  width: min-content;
  height: 40px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: ${({ theme }) => theme.fontSize.regular};
  color: ${({ theme }) => theme.color.dashboardBtn};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  &:hover,
  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.baseColors.Blue200};
    background-color: ${({ theme }) => theme.color.legalDocumentHoverBackground};
  }
`;

const DotButton = styled(Button)`
  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: 100px;
  }
`;
const AddMetricButton = styled(Button)`
  padding: 0 8px;
`;
const PlusIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const DotsIcon = styled(DotsIconBase)`
  margin-top: 4px;
`;
const Question = styled.div`
  font-weight: bold;
`;
export default {
  Wrapper,
  Buttons,
  Item,
  Button,
  DotsIcon,
  Question,
  AddMetricButton,
  PlusIconWrapper,
  DotButton,
};

import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './success-stories-heading.styles';

export const SuccessStoriesHeading = () => {
  const [title] = useTranslation(['advisors.successStories']);

  return (
    <S.Section>
      <S.Title>{title}</S.Title>
    </S.Section>
  );
};

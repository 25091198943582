import {
  getInvestorPublishStatus,
  GET_INVESTOR_PUBLISH_STATUS_CACHE_KEY,
} from '@pages/content/api/get-investor-publish-status.action';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { useQuery, type MutationFunction } from '@tanstack/react-query';
import {
  PublishEmptyState,
  type IPublishEmptyStateTranslations,
} from '../../../parts/publish-empty-state/publish-empty-state';
import { BrowsedMatchesGrid } from './parts/browsed-matches-grid/browsed-matches-grid';
import { useSavedFilters } from './parts/browsed-matches-grid/hooks/use-saved-filters';
import { useParseAndSearch } from './parts/filters/hooks/use-parse-and-search';

export const BrowseCompanies = ({
  publishProfileAction,
  translations,
}: {
  publishProfileAction: MutationFunction<unknown, {}>;
  translations: IPublishEmptyStateTranslations;
}) => {
  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery([GET_INVESTOR_PUBLISH_STATUS_CACHE_KEY], getInvestorPublishStatus);

  const parseAndSearch = useParseAndSearch();
  const {
    isLoaded: isSavedFiltersLoaded,
    filters: savedFilters,
    availableMetrics,
  } = useSavedFilters({ onInitialLoad: parseAndSearch });

  if (isLoading || !response?.data || !isSavedFiltersLoaded) return <FullHeightSpinner />;

  const { published, smartMatchShortages } = response.data;

  if (published) return <BrowsedMatchesGrid availableMetrics={availableMetrics} userSavedFilters={savedFilters} />;

  return (
    <PublishEmptyState
      publishProfileAction={publishProfileAction}
      published={published}
      smartMatchShortages={smartMatchShortages}
      refetch={refetch}
      translations={translations}
    />
  );
};

import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 600px;
  margin: 100px auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.medium};
  text-align: center;
`;

const Headline = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  margin-top: ${({ theme }) => theme.spacing.medium};
  color: ${({ theme }) => theme.color.fontPrimary};
`;

const Text = styled.p`
  margin-top: ${({ theme }) => theme.spacing.medium};
`;

export default { Wrapper, Headline, Text };

import { V2Button } from '@/components/ui/v2-button/v2-button';
import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[100], theme.v2.colors.blue[600])};
  background: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[50], theme.v2.colors.blue[700])};
`;

const Title = styled.h2`
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.neutral[50])};
  font-weight: ${({ theme }) => theme.v2.typography.weights.bold};
  line-height: ${({ theme }) => theme.v2.typography.lineHeights.button};

  ${({ theme }) =>
    customMediaQuery('font-size', {
      xs: theme.v2.typography.sizes.xl,
      sm: theme.v2.typography.sizes['2xl'],
    })}
`;

const Paragraph = styled.p`
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[500], theme.v2.colors.neutral[300])};
  font-weight: ${({ theme }) => theme.v2.typography.weights.regular};
  font-size: ${({ theme }) => theme.v2.typography.sizes.sm};
  max-width: 100%;
  word-break: break-word;
  white-space: pre-wrap;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Button = styled(V2Button)`
  text-decoration: none;
`;

const ExperienceList = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
`;

export default {
  Card,
  Title,
  Paragraph,
  ButtonWrapper,
  Button,
  ExperienceList,
};

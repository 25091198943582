import styled, { css } from 'styled-components';

import CheckIcon from '@assets/icons/check.svg?react';
import CloseIcon from '@assets/icons/close.svg?react';
import UndisclosedIcon from '@assets/icons/tooltip.svg?react';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.radius.default};
  height: 80%;
`;

const IconContainer = styled.div``;

const Label = styled.h4`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.color.primary};
`;

const Note = styled.p`
  color: ${({ theme }) => theme.baseColors.Grey200};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.small};
  margin-top: ${({ theme }) => theme.spacing.small};
  text-align: center;
`;

const iconSizing = css`
  width: 32px;
  height: 32px;
`;

const No = styled(CloseIcon)`
  & > path {
    fill: ${({ theme }) => theme.color.secondary};
    stroke: ${({ theme }) => theme.color.secondary};
  }
`;

const Yes = styled(CheckIcon)`
  ${iconSizing}
`;

const Undisclosed = styled(UndisclosedIcon)`
  ${iconSizing}
`;

export default { Wrapper, Label, IconContainer, Note, No, Yes, Undisclosed };

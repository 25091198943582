import RoleUpdateImage from '@assets/icons/role-update-image.svg?react';
import { SubmitButton } from '@parts/submit-button/submit-button';
import { Tooltip } from 'antd';
import type { ReactNode } from 'react';
import S from './become-encouragement.styles';

export interface BecomeEncouragementProps {
  header: ReactNode;
  description: ReactNode;
  buttonContent: ReactNode;
  onDecision: Function;
  decisionLoading?: boolean;
  additionalInfo?: ReactNode;
  disable?: boolean;
  disableTooltip?: ReactNode;
}

export const BecomeEncouragement = ({
  header,
  description,
  buttonContent,
  onDecision,
  decisionLoading = false,
  additionalInfo,
  disable = false,
  disableTooltip = null,
}: BecomeEncouragementProps) => {
  const buttonElement = (
    <div>
      <SubmitButton
        onClick={() => onDecision()}
        disabled={decisionLoading || disable}
        loading={decisionLoading}
        data-testid="become-ned"
      >
        {buttonContent}
      </SubmitButton>
    </div>
  );

  const showDisableTooltip = disable && Boolean(disableTooltip);

  return (
    <S.Section $deactivate={disable}>
      <RoleUpdateImage />
      <S.Header>{header}</S.Header>
      <S.Description>
        {description}
        <span role="img" aria-label="img">
          🌎
        </span>
      </S.Description>

      <S.ButtonWrapper>
        {showDisableTooltip ? <Tooltip title={disableTooltip}>{buttonElement}</Tooltip> : buttonElement}
      </S.ButtonWrapper>

      {additionalInfo ? <S.AdditionalInfo>{additionalInfo}</S.AdditionalInfo> : null}
    </S.Section>
  );
};

import styled from 'styled-components';

const V2Tooltip = styled.div<{ iconPosition: 'left' | 'right' }>`
  align-items: center;
  display: flex;
  flex-direction: ${({ iconPosition }) => (iconPosition === 'left' ? 'row-reverse' : 'row')};
  gap: 4px;
  justify-content: center;
`;

const Span = styled.span<{ color?: string }>`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 4px;
  cursor: help;

  color: ${({ theme, color }) => {
    if (color) return color;
    if (theme.v2.mode === 'light') return theme.v2.colors.blue[400];
    if (theme.v2.mode === 'dark') return theme.v2.colors.blue[300];
  }};
`;

export default { V2Tooltip, Span };

import { Spin } from 'antd';
import React from 'react';
import type { V2LoaderProps } from './v2-loader.types';

import S from './v2-loader.styles';

// TODO: Add final loader component when we have it designed
// TODO: this is only temporary implementation
export const V2Loader: React.FC<V2LoaderProps> = ({ height = '100%', 'data-testid': testId = 'v2-loader' }) => {
  return (
    <S.V2Loader $height={height} data-testid={testId}>
      <Spin size="large" />
    </S.V2Loader>
  );
};

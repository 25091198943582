import { useEnforceTheme } from '@/utils/hooks/use-enforce-theme/use-enforce-theme';
import { InvestorOnboardingContextProvider } from '@context/investor-onboarding/investor-onboarding.provider';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { InvestorOnboardingStepPage } from './investor-onboarding-step.page';
import { InvestorOnboardingWelcomePage } from './investor-onboarding-welcome.page';

export const InvestorOnboardingRoutingPage = () => {
  useEnforceTheme('dark');
  const { path } = useRouteMatch();

  return (
    <InvestorOnboardingContextProvider>
      <Switch>
        <Route path={`${path}/:step`} component={InvestorOnboardingStepPage} />
        <Route path={path} exact component={InvestorOnboardingWelcomePage} />
      </Switch>
    </InvestorOnboardingContextProvider>
  );
};

import { Row } from 'antd';
import styled from 'styled-components';

const MobileFormReversed = styled(Row)`
  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    flex-direction: column-reverse;
  }
`;
export default {
  MobileFormReversed,
};

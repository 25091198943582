import { Progress as ProgressBase } from 'antd';
import type { Theme } from 'src/types';
import styled from 'styled-components';
import { MatchLevel } from './smart-match-progress';

interface BarProps {
  $matchLevel: MatchLevel;
}

const Wrapper = styled.div``;

const Label = styled.div`
  color: ${({ theme }) => theme.baseColors.White};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.xxsmall};
  text-transform: uppercase;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  right: auto;
  margin: auto;
  z-index: 1;
  height: 15px;
`;

const BarHolder = styled.div`
  position: relative;
`;

const Bar = styled(ProgressBase)<BarProps>`
  .ant-progress-inner {
    background-color: ${({ theme }) => theme.color.background};
    height: 20px;
    border-radius: 4px;
  }

  .ant-progress-bg {
    height: 100% !important;
    border-radius: 4px 0px 0px 4px;

    background: ${({ $matchLevel, theme }: { $matchLevel: MatchLevel; theme: Theme }) => {
      if ($matchLevel === MatchLevel.Perfect) return theme.gradient.green;
      if ($matchLevel === MatchLevel.Strong) return theme.gradient.green;
      if ($matchLevel === MatchLevel.Good) return theme.gradient.yellow;
      return theme.gradient.red;
    }};
  }
`;

export default {
  Bar,
  BarHolder,
  Wrapper,
  Label,
};

export function createPointingArrow(
  position: 'top' | 'right' | 'bottom' | 'left' | 'center' | 'custom' | undefined,
  verticalAlign: 'top' | 'bottom',
  horizontalAlign: 'left' | 'right',
) {
  if (!position || position === 'custom') {
    return {};
  }

  const arrowWidth = 16;
  const arrowHeight = 12;
  const arrowColor = 'white';
  const isVertical = position === 'top' || position === 'bottom';
  const spaceFromSide = 10;

  const oppositeSide = {
    top: 'bottom',
    bottom: 'top',
    right: 'left',
    left: 'right',
    center: 'center',
  };

  return {
    [`--rtp-arrow-${isVertical ? oppositeSide[horizontalAlign] : verticalAlign}`]: arrowHeight + spaceFromSide + 'px',
    [`--rtp-arrow-${oppositeSide[position]}`]: -arrowHeight + 2 + 'px',
    [`--rtp-arrow-border-${isVertical ? 'left' : 'top'}`]: `${arrowWidth / 2}px solid transparent`,
    [`--rtp-arrow-border-${isVertical ? 'right' : 'bottom'}`]: `${arrowWidth / 2}px solid transparent`,
    [`--rtp-arrow-border-${position}`]: `${arrowHeight}px solid ${arrowColor}`,
  };
}

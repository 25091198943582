import EmptyDocument from '@assets/icons/empty-document.svg';
import { EmptyState } from '@parts/empty-state/empty-state';
import { FileUpload, type FileUploadInputRef } from '@parts/file-upload/file-upload';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import message from '@parts/message/message';
import { Section } from '@parts/section/section';
import { Tooltip } from '@parts/tooltip/tooltip';
import { useMutation, useQuery } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import useConfirmModal from '@utils/hooks/use-confirm-modal/use-confirm-modal';
import useDelayedSubmit from '@utils/hooks/use-delayed-submit/use-delayed-submit';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useFormik } from 'formik';
import { useRef, useState, type ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { deleteInvestorDocumentAction } from '../../api/delete-investor-document.action';
import {
  INVESTOR_DOCUMENTS_FOR_COMPANY_CACHE_KEY,
  getInvestorDocumentsForCompanyAction,
  type InvestorDocumentsForCompany,
} from '../../api/get-investor-documents-for-company/get-investor-documents-for-company';
import { updateDocumentsNoteAction } from '../../api/update-documents-note.action';
import { UploadInvestorDocumentAction } from '../../api/upload-investor-document.action';
import S from './documents.styles';
import DocumentsTable from './parts/table/table';

const allowedMimeTypes = `
  application/pdf,
  application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document,
  application/vnd.ms-excel,
  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
  application/vnd.ms-powerpoint,
  application/vnd.openxmlformats-officedocument.presentationml.presentation`;

const EmptyIconImage = () => <img src={EmptyDocument} alt="empty-document" loading="lazy" />;

const Documents = () => {
  const { relationshipId } = useParams<{ relationshipId: string }>();
  const [docIdToRemove, setDocIdToRemove] = useState<string | null>(null);

  const [
    savedLabel,
    tooltipLabel,
    modalTitleLabel,
    modalContentLabel,
    headlineLabel,
    noDataLabel,
    maxLengthLabel,
    deletedLabel,
    uploadedLabel,
  ] = useTranslation([
    'global.drawer.saved',
    'portfolio.investor.documents.tooltip',
    'portfolio.investor.documents.modal.title',
    'portfolio.investor.documents.modal.content',
    'portfolio.investor.documents.headline',
    'portfolio.investor.documents.noData',
    'formik.validation.max',
    'portfolio.investor.documents.deleted',
    'portfolio.investor.documents.uploaded',
  ]);

  const { data: response, isLoading } = useQuery(
    [INVESTOR_DOCUMENTS_FOR_COMPANY_CACHE_KEY(relationshipId)],
    getInvestorDocumentsForCompanyAction(relationshipId),
  );

  const { getFieldProps, getFieldMeta, values, handleChange, submitForm, setValues } = useFormik<{
    data: InvestorDocumentsForCompany[];
  }>({
    onSubmit: () => {
      message.success({ content: savedLabel });
    },
    initialValues: {
      data: response?.data || [],
    },
    validationSchema: Yup.object({
      data: Yup.array().of(
        Yup.object({
          note: Yup.string().nullable().max(100, maxLengthLabel),
        }),
      ),
    }),
    enableReinitialize: true,
  });

  const { mutateAsync: updateDocumentsNote } = useMutation(updateDocumentsNoteAction(relationshipId), {
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });
  const { mutateAsync: deleteDocument } = useMutation(deleteInvestorDocumentAction, {
    onSuccess: () => {
      setValues({ data: values.data.filter((el) => el.id !== docIdToRemove) });
      setDocIdToRemove(null);
      message.success({ content: deletedLabel });
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  const preSubmit = (e: ChangeEvent<HTMLTextAreaElement>, documentId: string) => {
    updateDocumentsNote({ documentId, note: e.target.value }).then(() => {
      submitForm();
    });
  };

  const { onChange } = useDelayedSubmit(handleChange, preSubmit);
  const uploaderRef = useRef<FileUploadInputRef>(null);
  const onAddClick = () => {
    uploaderRef?.current?.inputRef?.current?.click();
  };

  const { modal: confirmApplicationApproveModal, show: showRemovingConfirmation } = useConfirmModal({
    title: modalTitleLabel,
    content: modalContentLabel,
    onConfirm: () => {
      if (docIdToRemove) {
        deleteDocument({ investingRelationshipId: relationshipId, documentId: docIdToRemove });
      }
    },
  });

  const onRemoveClick = (id: string) => {
    setDocIdToRemove(id);
    showRemovingConfirmation();
  };

  const title = (
    <>
      {headlineLabel}
      <Tooltip title={tooltipLabel} />
    </>
  );

  const renderEmptyState = isLoading ? (
    <FullHeightSpinner small />
  ) : (
    <EmptyState slim subTitle={noDataLabel} Icon={EmptyIconImage} />
  );

  return (
    <Section
      header={title}
      cornerAddition={{
        content: (
          <S.Button data-testid="Upload_Document_Button" onClick={onAddClick}>
            + Add
          </S.Button>
        ),
        width: 80,
      }}
    >
      {confirmApplicationApproveModal}
      <FileUpload
        hiddenMode
        multipleControlled
        ref={uploaderRef}
        uploadMutation={(formData) => UploadInvestorDocumentAction(relationshipId)(formData)}
        mimeTypes={allowedMimeTypes}
        onUploadSuccess={async (resp) => {
          message.success({ content: uploadedLabel });
          setValues({
            data: [resp.data, ...values.data],
          });
        }}
        render={(loader, details) => (
          <>
            {values.data.length || details?.isLoading ? (
              <DocumentsTable
                data={
                  details?.isLoading
                    ? [
                        {
                          id: 'new',
                          name: details?.name || '',
                          url: '',
                          note: '',
                          uploadedAt: new Date(),
                          component: loader,
                        },
                        ...values.data,
                      ]
                    : values.data
                }
                getFieldProps={getFieldProps}
                getFieldMeta={getFieldMeta}
                onChange={onChange}
                showRemovingConfirmation={onRemoveClick}
              />
            ) : (
              renderEmptyState
            )}
          </>
        )}
      />
    </Section>
  );
};

export default Documents;

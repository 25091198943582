import React from 'react';
import { BodyCell } from './parts/body-cell';
import { BodyRow } from './parts/body-row';
import { RowWrapper } from './parts/row-wrapper';
import { HeadCell } from './parts/head-cell';
import { HeadRow } from './parts/head-row';
import { Text } from './parts/text';
import { V2AriaTableContext } from './v2-aria-table-context';
import type { V2AriaTableProps } from './v2-aria-table.types';

import S from './v2-aria-table.styles';

const V2AriaTable = ({
  body,
  head,
  sorting,
  gridTemplateColumns = 'repeat(auto-fit, minmax(0, 1fr))',
  'data-testid': dataTestId,
}: V2AriaTableProps) => {
  const contextValue = React.useMemo(() => ({ sorting, gridTemplateColumns }), [sorting, gridTemplateColumns]);

  return (
    <V2AriaTableContext.Provider value={contextValue}>
      <S.Table data-testid={dataTestId} data-role="table">
        {head}
        {body}
      </S.Table>
    </V2AriaTableContext.Provider>
  );
};

V2AriaTable.HeadRow = HeadRow;
V2AriaTable.HeadCell = HeadCell;
V2AriaTable.BodyRow = BodyRow;
V2AriaTable.RowWrapper = RowWrapper;
V2AriaTable.BodyCell = BodyCell;
V2AriaTable.Text = Text;

export { V2AriaTable };

import {
  CLEAR_ALL,
  MARK_AS_READ,
  SET_NOTIFICATIONS,
  type Notification,
  type NotificationsActionType,
} from './notifications.reducer';

export const setNotifications: (notifications: Notification[]) => NotificationsActionType = (notifications) => ({
  notifications,
  type: SET_NOTIFICATIONS,
});

export const markAsRead: (notificationId: string) => NotificationsActionType = (notificationId) => ({
  notificationId,
  type: MARK_AS_READ,
});

export const clearAllNotifications: () => NotificationsActionType = () => ({
  type: CLEAR_ALL,
});

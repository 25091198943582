import { createContext, useContext } from 'react';
import type { V2PaginationProps } from './v2-pagination.types';

const V2PaginationContext = createContext<{ pagination: Pick<V2PaginationProps, 'current' | 'total'> } | null>(null);

export const useV2PaginationContext = () => {
  const context = useContext(V2PaginationContext);
  if (!context) throw new Error('V2Pagination.* component must be rendered as child of V2Pagination component');

  return context;
};

export { V2PaginationContext };

import { borders, type V2Borders } from '@styles/v2-theme/borders';
import { colors, type V2Colors } from '@styles/v2-theme/colors';
import { gradients, type V2GradientsConfig } from '@styles/v2-theme/gradients';
import { breakpoints, type V2Breakpoints } from './breakpoints';
import { shadows, type V2Shadows } from './shadows';
import { typography, type V2Typography } from './typography';
import { widths, type V2Widths } from './widths';

export type V2Theme = {
  colors: V2Colors;
  typography: V2Typography;
  gradients: V2GradientsConfig;
  shadows: V2Shadows;
  widths: V2Widths;
  borders: V2Borders;
  breakpoints: V2Breakpoints;
  mode: 'light' | 'dark';
};

export const v2: V2Theme = {
  colors,
  typography,
  gradients,
  shadows,
  widths,
  borders,
  breakpoints,
  mode: 'light',
};

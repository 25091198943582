import { MaintenancePage } from './maintenance.page';

const LS_BYPASS_MAINTENANCE_KEY = 'bypassMaintenanceMode';

export const MaintenanceModeWrapper = ({ children }: { children: JSX.Element }) => {
  const bypassMaintenance = window.localStorage.getItem(LS_BYPASS_MAINTENANCE_KEY) === 'true';
  const isMaintenanceMode = Boolean(window.MAINTENANCE_MODE);

  if (bypassMaintenance) return children;
  return isMaintenanceMode ? <MaintenancePage /> : children;
};

import {
  ChartViewDropdown,
  ChartViewType,
} from '@pages/content/pulse/parts/dashboards/parts/dashboard/metrics/parts/metric-item/parts/chart-metric/parts/chart-view-dropdown/chart-view-dropdown';
import { LazyReactApexChart } from '@parts/react-apex-chart/react-apex-chart.lazy';
import { useState } from 'react';
import S from '../../graph.styles';
import EmptyGraph from '../empty-graph/empty-graph';
import { useLineBarOptions } from './line-bar-graph-options';

const LineBarGraph = ({ data, title, noDataLabel }: { data: [Date, number][]; title: string; noDataLabel: string }) => {
  const [chartType, setChartType] = useState<ChartViewType>(ChartViewType.LINEAR);

  const selectedChartType = chartType === ChartViewType.LINEAR ? 'line' : 'bar';
  const allDates = data.map((el) => new Date(el[0]));
  const preparedData = data.map((el) => el[1]);
  const options = useLineBarOptions(allDates, selectedChartType, chartType);

  return (
    <S.Section
      header={title}
      data-testid="graph"
      cornerAddition={{
        content: Boolean(data.length) && (
          <ChartViewDropdown
            onMenuItemSelect={(v: ChartViewType) => setChartType(v)}
            numericDisabled
            chartsGroup={[ChartViewType.LINEAR, ChartViewType.COLUMN]}
          />
        ),
        width: 0,
      }}
    >
      {data.length ? (
        <LazyReactApexChart
          height={180}
          series={[
            {
              name: title,
              data: preparedData,
            },
          ]}
          type={selectedChartType}
          options={options}
        />
      ) : (
        <EmptyGraph label={noDataLabel} />
      )}
    </S.Section>
  );
};

export default LineBarGraph;

import { maxWords } from '@pages/content/profile/utils/max-words';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import * as Yup from 'yup';

export const useValidationSchema = () => {
  const [minLength, maxLength] = useTranslation(['formik.validation.min', 'formik.validation.max']);

  return Yup.object({
    aboutCompany: maxWords(500, minLength, maxLength),
    achievements: maxWords(500, minLength, maxLength),
  });
};

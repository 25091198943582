import currency from 'currency.js';

export const moneyFormat = (
  value: number | string,
  symbol: string = '',
  thousandsSeparator: string = '',
  dropEvenDecimals = false,
  scale = 2,
): string => {
  const formatted = currency(value).format({ separator: thousandsSeparator, symbol, precision: scale });

  if (dropEvenDecimals) {
    const [integerPart, decimalPart] = formatted.split('.');

    if (decimalPart === '00') {
      return integerPart;
    }
  }

  return formatted;
};

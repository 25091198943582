import type { MetricsOwner } from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import CollapseSearch from './collapse-menu/collapse-search';
import S from './searchable-metrics.styles';

export const SearchableMetrics = ({
  metrics,
  setFieldValue,
  fieldName,
}: {
  metrics: MetricsOwner;
  setFieldValue: Function;
  fieldName: string;
}) => {
  const [metricsDescriptionLabel] = useTranslation(['pulseDashboard.shared.addMetric.description']);

  return (
    <>
      <S.MetricsDesc>{metricsDescriptionLabel}</S.MetricsDesc>
      <CollapseSearch fieldName={fieldName} setFieldValue={setFieldValue} metrics={metrics} />
    </>
  );
};

import { DateTime } from 'luxon';

export const dateFromYearAndMonth = (year: number, month: number) => {
  const prependedMonth = (() => {
    const monthNumber = month;

    if (monthNumber >= 10) return monthNumber;

    return `0${monthNumber}`;
  })();
  return DateTime.fromFormat(`${year}-${prependedMonth}`, 'yyyy-MM');
};

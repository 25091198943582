import { founderIdQueryParam } from '@pages/content/public-profile/founder/founder.page';
import { idQueryParam, investmentIdQueryParam } from '@pages/content/pulse/founder/parts/editor/create-editor';
import AvatarWithName from '@parts/avatar-with-name/avatar-with-name';
import { useDrawer } from '@parts/drawer/use-drawer';
import { Investments } from '@parts/investments/investments';
import { InvestorRiskWarning } from '@parts/investor-risk-warning/investor-risk-warning';
import { PageHeader } from '@parts/page-header/page-header';
import { Pagination } from '@parts/pagination/pagination';
import { Links } from '@router/links';
import { Routes } from '@router/routes';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useQueryPagination } from '@utils/hooks/use-query-pagination/use-query-pagination';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { stringify } from 'query-string';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  GET_EXTENDED_INVESTMENTS_CACHE_KEY,
  getExtendedInvestments,
  type ExtendedInvestment,
  type ExtendedInvestments,
} from '../api/get-extended-investments/get-extended-investments.action';
import { CreateEditor } from '../parts/editor/create-editor';
import { UpdateEditor } from '../parts/editor/update-editor';
import PortfolioWideTable from '../parts/portfolio-wide-table/portfolio-wide-table';
import S from './expand-view.styles';

export const ExpandView = () => {
  const [founderId, setFounderId] = useState('');
  const [addInvestmentLabel, showAllLabel] = useTranslation([
    'portfolio.investor.investments.addInvestment',
    'portfolio.investor.expandView.showAll',
  ]);
  const { [idQueryParam]: relationId, [investmentIdQueryParam]: investmentId } = useQueryParams() as {
    [idQueryParam]: string;
    [investmentIdQueryParam]: string;
  };
  const { id: singleRelationId } = useParams<{ id: string }>();
  const isSingleMode = Boolean(singleRelationId);
  const history = useHistory();
  const {
    deviceData: { isDesktop },
  } = useDeviceDetect();

  const { Drawer, show: showDrawer, hide: hideDrawer } = useDrawer(Boolean(investmentId));
  const { items, total, additionalData, isLoading, hasItems, refetch, querying, ...pagination } =
    useQueryPagination<ExtendedInvestment>({
      itemsPerPage: 12,
      pageParam: 'page',
      excludedParams: ['note', 'investment', 'id'],
      action: (start, limit, excludedParams, otherParams) => {
        const result = getExtendedInvestments({
          start,
          limit,
          excludedParams,
          otherParams,
          relationId: singleRelationId,
        });
        return result;
      },
      cacheKey: GET_EXTENDED_INVESTMENTS_CACHE_KEY,
      scrollToTopOnPageChanged: !isDesktop,
      queryConfig: {
        cacheTime: 0,
        refetchOnWindowFocus: true,
      },
    });
  const { totalInvested, totalCurrentValue, totalShares, totalOwned, totalInvestmentsCount } =
    additionalData as ExtendedInvestments;

  const profileHref = history.createHref({
    pathname: Links.FOUNDER_PUBLIC_PROFILE_DETAILS(),
    search: stringify({ [founderIdQueryParam]: items[0]?.company.id }),
  });
  const backHref = history.createHref({ pathname: Links.PORTFOLIO_COMPANY_DETAILS(singleRelationId) });

  const singleModeComponent = items.length ? (
    <PageHeader
      title={
        <AvatarWithName
          avatarUrl={items[0]?.company.avatarUrl ?? ''}
          name={items[0]?.company.name}
          avatarSize="medium"
          link={profileHref}
        />
      }
      onBack={() => history.push(backHref)}
    />
  ) : null;

  return (
    <>
      <S.FooterWrapper>
        <InvestorRiskWarning />
      </S.FooterWrapper>
      <Investments
        singleMode={isSingleMode ? { Component: singleModeComponent } : undefined}
        expandViewOpen
        hideExpandButton
        shares={totalShares}
        owned={totalOwned}
        invested={totalInvested}
        value={totalCurrentValue}
        showDrawer={showDrawer}
        addLabel={addInvestmentLabel}
        count={totalInvestmentsCount}
      >
        {isSingleMode && <S.ShowAllLink to={`${Routes.PORTFOLIO_EXPANDED_VIEW}`}>{showAllLabel}</S.ShowAllLink>}
        <PortfolioWideTable
          data-testid="table_expanded_view"
          singleMode={isSingleMode}
          querying={querying}
          items={items}
          isLoading={isLoading}
          refetch={refetch}
        />
        {investmentId ? (
          <UpdateEditor investmentId={investmentId} drawer={Drawer} hideDrawer={hideDrawer} refetch={refetch} />
        ) : (
          <CreateEditor
            data-testid="create_investment_expanded_view"
            founderId={founderId}
            setFounderId={setFounderId}
            drawer={Drawer}
            hideDrawer={hideDrawer}
            id={relationId}
            refetch={refetch}
          />
        )}
        {pagination && !isLoading && pagination.maxPages > 1 && <Pagination {...pagination} centered />}
      </Investments>
    </>
  );
};

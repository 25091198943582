import { V2Icon } from '@/components/ui/v2-icon/v2-icon';
import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const MobileFiltersForm = styled.form`
  background-color: transparent;
`;

const FilterSwitch = styled.ul`
  width: 100%;
  list-style: none;
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[700], theme.v2.colors.neutral[300])};

  li {
    &:not(:last-child) {
      margin-bottom: 15px;
    }

    label {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px 20px;
      font-size: ${({ theme }) => theme.v2.typography.sizes.md};
      font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
      border: 1px solid;
      border-radius: ${({ theme }) => theme.v2.borders.radius.md};
      color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.neutral[200])};
      border-color: ${({ theme }) =>
        themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.neutral[200])};
      background-color: ${({ theme }) =>
        themedStyle(theme.v2.mode, theme.v2.colors.neutral[50], theme.v2.colors.blue[700])};
      cursor: pointer;
      transition: opacity 250ms;
    }

    input {
      position: absolute;
      opacity: 0;
      pointer-events: none;

      &:checked + label {
        border: 2px solid;
        text-decoration: underline;
        text-decoration-thickness: 2px;
      }

      &:disabled + label {
        opacity: 0.65;
      }
    }
  }
`;

const SearchInputWrapper = styled.div`
  position: relative;
`;

const SearchIcon = styled(V2Icon)`
  position: absolute;
  top: 14px;
  left: 9.5ch;
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[500], theme.v2.colors.neutral[300])};
  pointer-events: none;
  visibility: hidden;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 12px 20px;
  font-size: ${({ theme }) => theme.v2.typography.sizes.md};
  font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
  border: 1px solid;
  border-radius: ${({ theme }) => theme.v2.borders.radius.md};
  border-color: ${({ theme }) =>
    themedStyle(theme.v2.mode, theme.v2.colors.neutral[500], theme.v2.colors.neutral[300])};
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[500], theme.v2.colors.neutral[300])};
  background-color: ${({ theme }) =>
    themedStyle(theme.v2.mode, theme.v2.colors.neutral[50], theme.v2.colors.neutral[700])};
  margin-bottom: 15px;

  &:disabled {
    opacity: 0.65;
  }

  &:placeholder-shown {
    + ${SearchIcon} {
      visibility: visible;
    }
  }
`;

const SortButton = styled.button<{ $active: boolean }>`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  font-size: ${({ theme }) => theme.v2.typography.sizes.md};
  font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
  border: 1px solid;
  border-radius: ${({ theme }) => theme.v2.borders.radius.md};
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.neutral[200])};
  border-color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.neutral[200])};
  background-color: ${({ theme }) =>
    themedStyle(theme.v2.mode, theme.v2.colors.neutral[50], theme.v2.colors.blue[700])};
  cursor: pointer;
  margin-bottom: 15px;
  border-width: ${({ $active }) => ($active ? '2px' : '1px')};

  &:disabled {
    opacity: 0.65;
  }
`;

export default { MobileFiltersForm, FilterSwitch, SearchInputWrapper, SearchInput, SearchIcon, SortButton };

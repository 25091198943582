import closeIcon from '@assets/icons/x.svg?react';
import { MobileTile as MobileTileBase } from '@pages/content/onboarding/parts/aside/parts/mobile-tile/mobile-tile';
import styled, { css } from 'styled-components';

export const gradientBorderStyles = css`
  content: '';
  position: absolute;
  inset: 0;
  padding: 2px;
  background: ${({ theme }) => theme.baseColors.Blue100};
  -webkit-mask:
    linear-gradient(transparent 0 0) content-box,
    linear-gradient(transparent 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  border-radius: ${({ theme }) => theme.radius.medium};
`;

const FullScreen = css`
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
`;

const Wrapper = styled.div<{ $fullScreen: boolean; $gradientBorder: boolean }>`
  background: ${({ theme }) => theme.onboardingColors.asideBackground};
  border-radius: ${({ theme }) => theme.radius.medium};
  position: relative;
  z-index: 0;
  padding: ${({ theme }) => theme.spacing.medium};

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    &:before {
      ${gradientBorderStyles};

      ${({ $gradientBorder }) =>
        $gradientBorder &&
        css`
          background: ${({ theme }) => theme.gradient.onboardingAsideGradient};
        `}
    }
  }

  ${({ $fullScreen }) => $fullScreen && FullScreen}
`;

const Title = styled.p`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  letter-spacing: 0.5px;
  margin-bottom: ${({ theme }) => theme.spacing.xmedium};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.xmedium};
  }
`;

const MobileTile = styled(MobileTileBase)`
  flex: 1;
`;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseIcon = styled(closeIcon)`
  cursor: pointer;
`;

export default {
  Wrapper,
  Title,
  MobileTile,
  CloseIcon,
  HeadingWrapper,
};

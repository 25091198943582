import { useState } from 'react';

type WizardArgs = {
  steps: unknown[];
  init?: number;
};

export const useWizard = ({ steps, init = 0 }: WizardArgs) => {
  const [currentStep, setCurrentStep] = useState(init);

  if (init < 0) {
    throw new Error('Initial step must be greater than or equal to 0'); // Updated error message
  }
  if (init > steps.length) {
    throw new Error('Initial step must be less than the number of steps');
  }

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const previousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const resetState = () => {
    setCurrentStep(0);
  };

  const setStep = (step: number) => {
    if (step >= 0 && step < steps.length) {
      setCurrentStep(step);
    }
  };

  return {
    currentStep,
    nextStep,
    previousStep,
    setStep,
    isLastStep: currentStep === steps.length - 1,
    isFirstStep: currentStep === 0,
    progress: ((currentStep + 1) / steps.length) * 100,
    resetState,
  };
};

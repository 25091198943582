import styled from 'styled-components';

import { CenterBlock } from '@layout/center-block/center-block.layout';

const Wrapper = styled(CenterBlock)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.xbase};
`;

export default { Wrapper, IconWrapper };

import styled from 'styled-components';

const Wrapper = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.color.primary};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export default { Wrapper };

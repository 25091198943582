import { FilterCompaniesSortOptions, MetricFilterMode } from '@pages/content/lens/api/get-browse-companies-filters';
import {
  GET_BROWSE_COMPANIES_METRICS_CACHE_KEY,
  getBrowseCompaniesMetrics,
  type MetricOptions,
} from '@pages/content/lens/api/get-browse-companies-metrics';
import {
  GET_COUNTRIES_USED_BY_FOUNDERS_CACHE_KEY,
  getCountriesUsedByFoundersAction,
} from '@pages/content/lens/api/get-countries-used-by-founders.action';
import type { FilterOptions } from '@pages/content/lens/investor/parts/browse-companies/parts/filters/filters';
import {
  MetricFilter,
  initialMetricTypeFilter,
} from '@pages/content/lens/investor/parts/browse-companies/parts/metric-filter/metric-filter';
import { InvestorLensSortSelect } from '@pages/content/lens/investor/parts/browse-companies/parts/sort-select/sort-select';
import { applySearchToQuery, type Search } from '@pages/content/lens/utils/apply-filters-to-query';
import { FormikField } from '@pages/content/profile/parts/formik-field/formik-field';
import { Tooltip } from '@parts/tooltip/tooltip';
import Checkbox from '@parts/checkbox/checkbox';
import { CountriesRegionsSelect } from '@parts/countries-regions-select/countries-regions-select';
import { SubmitButton } from '@parts/submit-button/submit-button';
import { useQuery } from '@tanstack/react-query';
import { filterWithAllowed } from '@utils/fns/filter-with-allowed';
import { trimCharacters } from '@utils/fns/trim-characters';
import { useAvailableCountriesAndRegions } from '@utils/hooks/use-available-countries-and-regions/use-available-countries-and-regions';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import { RegionNamesMap, RegionValue } from '@utils/type-definitions/regions-values';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { Col, Row } from 'antd';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import type { SelectValue } from 'antd/lib/select';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import S from './filters.styles';
import { useValidationSchema } from './validation-schema';

type FormikFormData = Pick<
  FilterOptions,
  | 'firstMetricTypeFilter'
  | 'secondMetricTypeFilter'
  | 'thirdMetricTypeFilter'
  | 'hasEisLimitsRemaining'
  | 'hasSeisLimitsRemaining'
  | 'onlyNewlyRegistered'
  | 'sort'
  | 'countriesOfResidence'
  | 'regions'
>;

const getMetricsFromQuery = (
  qr: { [key: string]: string | string[] | null },
  availableMetrics: MetricOptions[],
): Omit<MetricOptions, 'step'>[] => {
  if (!qr.metrics || typeof qr.metrics !== 'string') return [];

  try {
    const parsedMetricsFromQuery = JSON.parse(qr.metrics) as Omit<MetricOptions, 'name' | 'step'>[] & {
      filteringMode: MetricFilterMode;
    };
    const parsedMetricsWithNames = parsedMetricsFromQuery.map((item: Omit<MetricOptions, 'name' | 'step'>) => {
      const metricDefinition = availableMetrics?.find((metricItem) => metricItem.code === item.code);
      if (!metricDefinition) throw new Error(`Cannot find metric with code ${item.code}`);

      return {
        ...item,
        name: metricDefinition.name,
        max: Math.min(metricDefinition.max, item.max),
        min: Math.max(metricDefinition.min, item.min),
        filteringMode: item.filteringMode ?? initialMetricTypeFilter.filteringMode,
      };
    });

    return parsedMetricsWithNames;
  } catch (error) {
    return [];
  }
};

const isValidSortValue = (value: unknown): value is FilterCompaniesSortOptions => {
  return Object.values(FilterCompaniesSortOptions).includes(value as FilterCompaniesSortOptions);
};

export const FoundersFiltering = () => {
  const [
    metricsLabel,
    apply,
    seisLabel,
    eisLabel,
    newOnlyLabel,
    newOnlyTooltip,
    sortSelectPrefixLabel,
    countriesAndRegionsLabel,
  ] = useTranslation([
    'lens.filters.metrics',
    'lens.filters.apply',
    'lens.filters.seis',
    'lens.filters.eis',
    'lens.filters.newOnlyLabel',
    'lens.filters.newOnlyTooltip',
    'lens.filters.sortSelect.prefixLabel',
    'lens.filters.countriesAndRegions',
  ]);

  const {
    helpers: { isFrom },
  } = useUserAccount();

  const shouldShowSeisEis = isFrom(CountryCodes.GB);

  const history = useHistory();
  const {
    page,
    hasSeisLimitsRemaining: queryHasSeisLimitsRemaining,
    hasEisLimitsRemaining: queryHasEisLimitsRemaining,
    onlyNewlyRegistered: queryOnlyNewlyRegistered,
    countriesOfResidence: queryCountriesOfResidence,
    regions: queryRegions,
    sort: querySort,
    ...restQueryParams
  } = useQueryParams();

  const { availableCountries, availableRegions, notUsedCountries } = useAvailableCountriesAndRegions(
    GET_COUNTRIES_USED_BY_FOUNDERS_CACHE_KEY,
    getCountriesUsedByFoundersAction('INVESTOR'),
  );

  const { data: companiesMetricsResponseData } = useQuery(
    [GET_BROWSE_COMPANIES_METRICS_CACHE_KEY],
    getBrowseCompaniesMetrics,
    { cacheTime: 3600, refetchOnWindowFocus: false },
  );

  const { availableMetrics } = companiesMetricsResponseData?.data || {};

  const handleSubmit = (formValues: FormikFormData) => {
    const metricsWithoutName = [
      { ...formValues.firstMetricTypeFilter },
      { ...formValues.secondMetricTypeFilter },
      { ...formValues.thirdMetricTypeFilter },
    ]
      .filter((metricFilter) => metricFilter.code)
      .map((el) => {
        const { name, ...rest } = el;
        return rest;
      });

    applySearchToQuery({
      history,
      initialQuery: restQueryParams,
      search: {
        page: 1,
        countriesOfResidence: formValues.countriesOfResidence.length ? formValues.countriesOfResidence : undefined,
        regions: formValues.regions.length ? formValues.regions : undefined,
        metrics: metricsWithoutName,
        hasSeisLimitsRemaining: formValues.hasSeisLimitsRemaining ? true : undefined,
        hasEisLimitsRemaining: formValues.hasEisLimitsRemaining ? true : undefined,
        onlyNewlyRegistered: formValues.onlyNewlyRegistered ? true : undefined,
        sort: (formValues.sort as string) || null,
      } as unknown as Search,
    });
  };

  const { setFieldValue, values, submitForm, isValid, setFieldTouched, getFieldProps } = useFormik<FormikFormData>({
    initialValues: {
      firstMetricTypeFilter: { ...initialMetricTypeFilter },
      secondMetricTypeFilter: { ...initialMetricTypeFilter },
      thirdMetricTypeFilter: { ...initialMetricTypeFilter },
      hasEisLimitsRemaining: queryHasEisLimitsRemaining === 'true',
      hasSeisLimitsRemaining: queryHasSeisLimitsRemaining === 'true',
      onlyNewlyRegistered: queryOnlyNewlyRegistered === 'true',
      sort: isValidSortValue(trimCharacters((querySort as string) ?? '', '"'))
        ? (trimCharacters(querySort as string, '"') as FilterCompaniesSortOptions)
        : FilterCompaniesSortOptions.Newest,
      countriesOfResidence: [],
      regions: [],
    },
    onSubmit: handleSubmit,
    validationSchema: useValidationSchema(),
  });

  useEffect(() => {
    if (availableCountries && availableRegions) {
      setFieldValue(
        'countriesOfResidence',
        filterWithAllowed<CountryCodes>(
          queryCountriesOfResidence ? (JSON.parse((queryCountriesOfResidence ?? '') as string) as CountryCodes[]) : [],
          availableCountries,
        ),
      );
      setFieldValue(
        'regions',
        filterWithAllowed<RegionValue>(
          queryRegions ? (JSON.parse((queryRegions ?? '') as string) as RegionValue[]) : [],
          availableRegions,
        ),
      );
    }
  }, [queryCountriesOfResidence, queryRegions, availableCountries, availableRegions, setFieldValue]);
  /** set initial values when available metrics are fetched */

  useEffect(() => {
    const metricsFromQuery = getMetricsFromQuery(restQueryParams, availableMetrics ?? []);
    const metricsKeys = new Set(['firstMetricTypeFilter', 'secondMetricTypeFilter', 'thirdMetricTypeFilter']);
    [...metricsKeys].forEach((key, index) => {
      if (metricsFromQuery[index]) {
        setFieldValue(key, metricsFromQuery[index]);
      }
    });
  }, [availableMetrics, setFieldValue]);

  const handleSelectCountryRegionChange = (countriesKey: string, regionsKey: string) => (value: SelectValue) => {
    const eventValues = Array.isArray(value) ? value : [];

    const countriesData = eventValues.filter((item) => Object.values(CountryCodes).includes(item as CountryCodes));
    const regionsData = eventValues.filter((item) => Object.keys(RegionNamesMap).includes(item as RegionValue));
    setFieldValue(countriesKey, countriesData);
    setFieldTouched(countriesKey, true);

    setFieldValue(regionsKey, regionsData);
    setFieldTouched(regionsKey, true);
  };

  return (
    <>
      <S.Section>
        <Row gutter={[24, 0]} align="middle">
          <Col xs={24} sm={12} md={24} lg={14}>
            <FormikField
              label={{
                for: 'countriesAndRegions',
                label: countriesAndRegionsLabel,
              }}
            >
              <CountriesRegionsSelect
                id="countriesAndRegions"
                onChange={handleSelectCountryRegionChange('countriesOfResidence', 'regions')}
                regions={availableRegions}
                countriesOfResidence={availableCountries}
                notUsedCountries={notUsedCountries}
                value={[...values.countriesOfResidence, ...values.regions]}
              />
            </FormikField>
          </Col>
          {shouldShowSeisEis && (
            <>
              <Col xs={16} md={16} lg={2}>
                <S.CheckboxFormikField
                  label={{
                    for: 'hasSeisLimitsRemaining',
                    label: seisLabel,
                  }}
                  data-testid="filters-seis-checkbox"
                >
                  <Checkbox
                    checked={values.hasSeisLimitsRemaining}
                    onChange={(e: CheckboxChangeEvent) => {
                      setFieldValue('hasSeisLimitsRemaining', e.target.checked);
                      setFieldTouched('hasSeisLimitsRemaining', true);
                    }}
                    id="hasSeisLimitsRemaining"
                  />
                </S.CheckboxFormikField>
              </Col>
              <Col xs={16} md={16} lg={2}>
                <S.CheckboxFormikField
                  label={{
                    for: 'hasEisLimitsRemaining',
                    label: eisLabel,
                  }}
                  data-testid="filters-eis-checkbox"
                >
                  <Checkbox
                    checked={values.hasEisLimitsRemaining}
                    onChange={(e: CheckboxChangeEvent) => {
                      setFieldValue('hasEisLimitsRemaining', e.target.checked);
                      setFieldTouched('hasEisLimitsRemaining', true);
                    }}
                    id="hasEisLimitsRemaining"
                  />
                </S.CheckboxFormikField>
              </Col>
            </>
          )}
          <Col xs={16} md={16} lg={5}>
            <S.CheckboxFormikField
              label={{
                for: 'onlyNewlyRegistered',
                label: (
                  <Row>
                    <Col>{newOnlyLabel}</Col>
                    <Col>
                      <Tooltip title={newOnlyTooltip} />
                    </Col>
                  </Row>
                ),
              }}
              data-testid="filters-show-only-new-checkbox"
            >
              <Checkbox
                checked={values.onlyNewlyRegistered}
                onChange={(e: CheckboxChangeEvent) => {
                  setFieldValue('onlyNewlyRegistered', e.target.checked);
                  setFieldTouched('onlyNewlyRegistered', true);
                }}
                id="onlyNewlyRegistered"
              />
            </S.CheckboxFormikField>
          </Col>
        </Row>
        <FormikField
          label={{
            for: 'metrics',
            label: metricsLabel,
          }}
        >
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={8}>
              <MetricFilter
                metricsOptions={availableMetrics || []}
                setFieldValue={setFieldValue}
                selectedFilters={[
                  values.firstMetricTypeFilter,
                  values.secondMetricTypeFilter,
                  values.thirdMetricTypeFilter,
                ]}
                lastSearchedMetric={values.firstMetricTypeFilter}
                filterKey="firstMetricTypeFilter"
              />
            </Col>
            <Col xs={24} sm={24} md={8}>
              <MetricFilter
                metricsOptions={availableMetrics || []}
                setFieldValue={setFieldValue}
                selectedFilters={[
                  values.firstMetricTypeFilter,
                  values.secondMetricTypeFilter,
                  values.thirdMetricTypeFilter,
                ]}
                lastSearchedMetric={values.secondMetricTypeFilter}
                filterKey="secondMetricTypeFilter"
              />
            </Col>
            <Col xs={24} sm={24} md={8}>
              <MetricFilter
                metricsOptions={availableMetrics || []}
                setFieldValue={setFieldValue}
                selectedFilters={[
                  values.firstMetricTypeFilter,
                  values.secondMetricTypeFilter,
                  values.thirdMetricTypeFilter,
                ]}
                lastSearchedMetric={values.thirdMetricTypeFilter}
                filterKey="thirdMetricTypeFilter"
              />
            </Col>
          </Row>
        </FormikField>
        <Row>
          <SubmitButton onClick={submitForm} disabled={!isValid}>
            {apply}
          </SubmitButton>
        </Row>
      </S.Section>

      <InvestorLensSortSelect
        {...getFieldProps('sort')}
        value={values.sort || undefined}
        label={sortSelectPrefixLabel}
        onChange={(v: SelectValue) => {
          setFieldValue('sort', v ?? null);
          setFieldTouched('sort', true);
          submitForm();
        }}
      />
    </>
  );
};

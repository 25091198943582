import { V2TourMessage } from '@/components/ui/v2-tour-message/v2-tour-message';
import { useTour as useTourBase, type StepType } from '@reactour/tour';
import { useEffect } from 'react';
import { sleep } from '../fns/sleep';
import type { TourStep } from './types';

const mapTourStepToStepType = (steps: TourStep[]): StepType[] => {
  return steps.map((step) => {
    return {
      ...step,
      content: <V2TourMessage title={step.title} description={step.description} footer={step.footer} />,
    };
  });
};

export const useTour = (steps: TourStep[]) => {
  const { setSteps, setIsOpen, steps: tourSteps } = useTourBase();

  useEffect(() => {
    if (!setSteps) return;
    setSteps(mapTourStepToStepType(steps));
  }, [steps.length, setSteps]);

  const start = async ({ delay = 0 }: { delay?: number } = {}) => {
    await sleep(delay);
    setIsOpen(true);
  };

  const stop = async ({ delay = 0 }: { delay?: number } = {}) => {
    await sleep(delay);
    setIsOpen(false);
  };

  return { start, stop, steps: tourSteps };
};

import { Roles } from '@domain/accounts/roles';
import { isFounderFreemium } from '@domain/accounts/subscription-products';
import { MatchLevel } from '@pages/content/lens/parts/smart-match-progress/smart-match-progress';
import { checkIfValidWysiWygValue } from '@pages/content/profile/hooks/use-wysiwyg';
import { investorIdQueryParam } from '@pages/content/public-profile/investor/investor.page';
import S from '@parts/card/card.styles';
import { CompanyTile } from '@parts/company-tile/company-tile';
import { EngagementDecision } from '@parts/engagement-decision/engagement-decision';
import { GridItem } from '@parts/grid-item/grid-item';
import message from '@parts/message/message';
import metaConfig from '@parts/meta-config/meta-config';
import { Routes } from '@router/routes';
import { useMutation } from '@tanstack/react-query';
import { SanitizeWysiwyg } from '@utils/fns/sanitize-wysiwyg';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { useUserCurrency } from '@utils/hooks/use-user-currency/use-user-currency';
import { ConnectionType } from '@utils/type-definitions/connections';
import { useHistory } from 'react-router-dom';
import { blacklistInvestorAction } from '../../api/blacklist-investor.action';
import { cancelPendingConnectionInvitationRequestAction } from '../../api/cancel-pending-connection-request-action';
import { founderInvitesInvestorAction } from '../../api/founder-invites-investor.action';
import { engageInvestorMessagingAction } from '../../api/message-engage-investor';

const INDUSTRIES_LINE_LIMIT = 3;

export const MatchedInvestor = ({
  id,
  level,
  customerGroup,
  description,
  industry,
  investmentStage,
  name,
  productStage,
  avatarUrl,
  connectRequestId,
  location,
  maximumInvestmentAmount,
  minimumInvestmentAmount,
  refetchSmartMatch,
  discoverable,
}: {
  id: string;
  level: MatchLevel;
  customerGroup: string[];
  description: string;
  industry: string[];
  investmentStage: string[];
  name: string;
  productStage: string;
  avatarUrl: string;
  connectRequestId: string | null;
  location: string;
  maximumInvestmentAmount: number | null;
  minimumInvestmentAmount: number | null;
  refetchSmartMatch: Function;
  discoverable: boolean;
}) => {
  const history = useHistory();
  const [notInterestedSuccessful] = useTranslation(['lens.match.notInterestedSuccessful']);
  const { currencySymbol } = useUserCurrency();
  const {
    state: { products },
  } = useUserAccount();

  const { mutateAsync: blacklistFounder } = useMutation(blacklistInvestorAction(id), {
    onSuccess: () => {
      refetchSmartMatch();
      message.success({
        content: (
          <p>
            {notInterestedSuccessful} {name}
          </p>
        ),
      });
    },
  });

  const publicProfileUrl = history.createHref({
    search: `${investorIdQueryParam}=${id}`,
    pathname: Routes.INVESTOR_PUBLIC_PROFILE,
  });
  const isCurrentSubscriptionFreemium = isFounderFreemium(products);

  const initialLetters = name
    .split(' ')
    .map((word: string) => word[0])
    .join('');

  const investorName = isCurrentSubscriptionFreemium ? initialLetters : name;

  return (
    <GridItem
      dataTestId="matched-investor"
      header={<CompanyTile name={investorName} logoUrl={avatarUrl} supplement={location} />}
      meta={[
        {
          ...metaConfig({ industry }).favorite,
          maxLine: INDUSTRIES_LINE_LIMIT,
          onSeeMore: () => history.push(publicProfileUrl),
        },
        metaConfig({ investmentStage, productStage }).growth,
        metaConfig({ customerGroup }).business,
        metaConfig({ maximumInvestmentAmount, minimumInvestmentAmount, currencySymbol }).salary,
      ]}
      description={{
        dangerouslySet: true,
        content: checkIfValidWysiWygValue(description) ? SanitizeWysiwyg(JSON.parse(description)) : null,
      }}
      footer={
        discoverable ? (
          <EngagementDecision
            id={id}
            followRequestId={connectRequestId}
            followRequestType={ConnectionType.Invitation}
            userRole={Roles.FOUNDER}
            notInterested={blacklistFounder}
            cancelConnectionAction={cancelPendingConnectionInvitationRequestAction}
            connectAction={founderInvitesInvestorAction}
            messageAction={engageInvestorMessagingAction}
            refetch={refetchSmartMatch}
            messagingShown={false}
            isFounderFreemium={isCurrentSubscriptionFreemium}
          />
        ) : null
      }
      url={publicProfileUrl}
    >
      <S.SmartMatchProgress isLast={!discoverable} level={level} />
    </GridItem>
  );
};

import styled from 'styled-components';

const V2Label = styled('label')<{ align: 'left' | 'center' | 'right' }>`
  display: block;
  width: 100%;
  text-align: ${({ align }) => align};
  font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
  font-size: ${({ theme }) => theme.v2.typography.sizes.md};
  margin-bottom: 16px 0;
  color: ${({ theme }) => {
    if (theme.v2.mode === 'light') return theme.v2.colors.neutral[700];
    if (theme.v2.mode === 'dark') return theme.v2.colors.neutral[50];
  }};
`;

export default { V2Label };

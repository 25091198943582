import ChevronIcon from '@assets/icons/chevron.svg?react';
import styled from 'styled-components';

const Wrapper = styled(ChevronIcon)`
  width: 20px;
  height: auto;
  --arrowcolor: ${({ theme }) => theme.color.fontPrimary};
`;

export default { Wrapper };

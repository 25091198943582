import { TrashButton } from '@pages/content/profile/parts/trash-button/trash-button';
import { TableColumn } from '@parts/table-column/table-column';
import type { ColumnsType } from 'antd/lib/table';
import type { FieldInputProps, FieldMetaProps } from 'formik';
import type { ChangeEvent } from 'react';
import type { ExtendedInvestorDocumentsForCompany } from '../table/table';
import S from './columns.styles';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { DateFormat } from '@i18n/date-formats';

const DocumentsColumn = ({
  t,
  getFieldProps,
  getFieldMeta,
  onChange,
  showRemovingConfirmation,
}: {
  t: { [key: string]: string };
  getFieldProps: (name: string) => FieldInputProps<string>;
  getFieldMeta: (name: string) => FieldMetaProps<string>;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>, param?: string) => void;
  showRemovingConfirmation: Function;
}): ColumnsType<ExtendedInvestorDocumentsForCompany> => {
  const { dateFormatter } = useUserDateFormat();

  return [
    {
      title: t.uploadedLabel,
      dataIndex: 'uploadedAt',
      key: 'uploadedAt',
      width: '15%',
      render: (date: string) => (
        <TableColumn title={t.uploadedLabel} data-testid="uploaded-date">
          {dateFormatter(date, DateFormat.SHORT_MONTH_FULL_DATE)}
        </TableColumn>
      ),
    },

    {
      title: t.nameLabel,
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      render: (name: string, record) => (
        <TableColumn title={t.nameLabel}>
          <S.Link href={record.url} download target="_blank" rel="noopener noreferrer" data-testid="name-link">
            {record.component ? <S.ComponentHolder>{record.component}</S.ComponentHolder> : name}
          </S.Link>
        </TableColumn>
      ),
    },
    {
      title: t.noteLabel,
      dataIndex: 'note',
      key: 'note',
      width: '50%',
      render: (_note: string, record, index) => {
        const { onChange: defaultOnChange, ...props } = getFieldProps(`data[${index}].note`);
        const { error, touched } = getFieldMeta(`data[${index}].note`);

        return (
          <TableColumn title={t.noteLabel} mobileFullWidth>
            <S.FormikField error={error} touched={touched} cell hideError>
              <S.Input
                id="lastName"
                {...props}
                placeholder={t.addNoteLabel}
                onChange={(e) => onChange(e, record.id)}
                data-testid="note-input"
                autoSize
              />
            </S.FormikField>
          </TableColumn>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'icon',
      width: '5%',
      render: (row, record) => (
        <S.RightTableColumn>
          <TrashButton
            disabled={Boolean(record.component)}
            onRemove={() => showRemovingConfirmation(row.id)}
            data-testid="trash-button"
          />
        </S.RightTableColumn>
      ),
    },
  ];
};

export default DocumentsColumn;

import type { PropsWithChildren } from 'react';
import S from './form-holder.styles';

const FormHolder = ({ children, label, onSubmit }: PropsWithChildren<{ label?: string; onSubmit?: () => void }>) => (
  <S.Form onSubmit={onSubmit}>
    {label && <S.Label data-testid="form-holder-label">{label}</S.Label>}
    {children}
  </S.Form>
);

export default FormHolder;

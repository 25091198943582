import type { HTMLAttributes } from 'react';
import { useV2AriaMobileConnectionCardContext } from '../v2-aria-mobile-connection-card-context';

import S from '../v2-aria-mobile-connection-card.styles';

type ListProps = {
  children: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export const List = ({ children, ...rest }: ListProps) => {
  useV2AriaMobileConnectionCardContext();

  return <S.List {...rest}>{children}</S.List>;
};

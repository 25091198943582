import Icon from '@ant-design/icons/lib/components/Icon';
import { archiveInvestmentAction } from '@pages/content/portfolio/investor/api/archive-investment.action';
import { DropdownList } from '@parts/dropdown-list/dropdown-list';
import { useMutation } from '@tanstack/react-query';
import useConfirmModal from '@utils/hooks/use-confirm-modal/use-confirm-modal';
import useShowTerms from '@utils/hooks/use-show-terms/use-show-terms';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Menu } from 'antd';
import S from './dropdown-list.styles';

export const PortfolioDropdownList = ({
  editHref,
  investmentId,
  refetch,
}: {
  editHref: string;
  investmentId: string;
  refetch: Function;
}) => {
  const [
    titleConfirmModal,
    contentConfirmModal,
    warningContent,
    confirmLabel,
    dropdownListArchive,
    dropdownListEdit,
    showTermsLabel,
  ] = useTranslation([
    'portfolio.investor.popup.header',
    'portfolio.investor.popup.content',
    'portfolio.investor.popup.warning',
    'portfolio.investor.popup.confirmLabel',
    'portfolio.investor.dropdownListOption.archive',
    'portfolio.investor.dropdownListOption.edit',
    'portfolio.investor.dropdownListOption.showTerms',
  ]);

  const { modal: termsModal, show: showTermsModal } = useShowTerms(investmentId);

  const modalContent = (
    <>
      <S.ModalContent>{contentConfirmModal}</S.ModalContent>
      <p>{warningContent}</p>
    </>
  );

  const { mutate: archiveInvestment } = useMutation(archiveInvestmentAction, {
    onSuccess: () => refetch(),
  });

  const { modal, show } = useConfirmModal({
    title: titleConfirmModal,
    content: modalContent,
    confirmLabel,
    onConfirm: () => archiveInvestment(investmentId),
  });

  const menu = (
    <Menu>
      <S.MenuItem>
        <S.Link to={editHref} data-testid="edit-investment">
          <Icon component={() => <S.Edit data-testid="edit-icon" />} />
          <S.DropdownOption>{dropdownListEdit}</S.DropdownOption>
        </S.Link>
      </S.MenuItem>
      <S.MenuItem data-testid="show_terms" icon={<S.DetailsIcon />} onClick={showTermsModal}>
        <S.DropdownOption>{showTermsLabel}</S.DropdownOption>
      </S.MenuItem>
      <S.MenuItem data-testid="archive" icon={<S.Archive />} onClick={() => show()}>
        <S.DropdownOption>{dropdownListArchive}</S.DropdownOption>
      </S.MenuItem>
    </Menu>
  );
  return (
    <>
      <DropdownList overlay={menu} />
      {modal}
      {termsModal}
    </>
  );
};

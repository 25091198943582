import { useScrollToElementWithQueryParams } from '@/utils/hooks/use-scroll-to-element/use-scroll-to-element-with-query-params';
import { updateCompanyDetailsAction } from '@pages/content/profile/founder/api/update-company-details/update-company-details.action';
import type { CompanyDetailsData } from '@pages/content/profile/founder/founder-profile.page';
import { getWysiWygValueSafely, useWysiwyg } from '@pages/content/profile/hooks/use-wysiwyg';
import { FormikField } from '@pages/content/profile/parts/formik-field/formik-field';
import MobileFormReversed from '@pages/content/profile/parts/mobile-form-reversed/mobile-form-reversed';
import { Wysiwyg } from '@pages/content/profile/parts/wysiwyg/wysiwyg';
import message from '@parts/message/message';
import { Section } from '@parts/section/section';
import { SubmitButton } from '@parts/submit-button/submit-button';
import { Tooltip } from '@parts/tooltip/tooltip';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Col, Row } from 'antd';
import 'antd-country-phone-input/dist/index.css';
import { EditorState, type RawDraftContentState } from 'draft-js';
import { useFormik } from 'formik';
import { type ReactNode } from 'react';
import { useValidationSchema } from './validation-schema';

type CompanyBioData = Pick<CompanyDetailsData, 'aboutCompany' | 'achievements'>;

type CompanyBioProps = {
  data: CompanyBioData;
  refetchPublishStatus: () => void;
  refetchFounderProfile: () => void;
};

export const CompanyBio = ({ data, refetchPublishStatus, refetchFounderProfile }: CompanyBioProps) => {
  const scrollToRef = useScrollToElementWithQueryParams<HTMLDivElement>('company-information');
  const [
    formUpdatedLabel,
    headerLabel,
    aboutCompanyLabel,
    aboutCompanyTooltipLabel,
    achievementsLabel,
    saveChangesLabel,
  ] = useTranslation([
    'messages.form.success',
    'profile.founder.section.companyBio.form.header',
    'profile.founder.section.companyBio.form.aboutCompany',
    'profile.founder.section.companyBio.form.aboutCompany.tooltip',
    'profile.founder.section.companyBio.form.achievements',
    'profile.founder.section.basicDetails.form.saveChanges',
  ]);

  const {
    state: aboutCompanyEditorState,
    setState: setAboutCompanyEditorState,
    isActive: isAboutCompanyEditorActive,
    setIsActive: setIsAboutCompanyEditorActive,
  } = useWysiwyg({
    content: getWysiWygValueSafely(data.aboutCompany),
  });

  const {
    state: achievementsCompanyEditorState,
    setState: setAchievementsEditorState,
    isActive: isAchievementsEditorActive,
    setIsActive: setIsAchievementsEditorActive,
  } = useWysiwyg({
    content: getWysiWygValueSafely(data.achievements),
  });

  const { mutateAsync: updateCompanyDetails } = useMutation(updateCompanyDetailsAction, {
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
    onSuccess: () => {
      refetchFounderProfile();
      refetchPublishStatus();
      message.success({ content: formUpdatedLabel });
    },
  });

  const { values, isValid, dirty, submitCount, ...formik } = useFormik<CompanyBioData>({
    initialValues: {
      achievements: getWysiWygValueSafely(data.achievements),
      aboutCompany: getWysiWygValueSafely(data.aboutCompany),
    },
    onSubmit: (v) => {
      updateCompanyDetails(v as CompanyDetailsData);
    },
    validateOnMount: true,
    enableReinitialize: true,
    validationSchema: useValidationSchema(),
  });

  const onWysiwygChange = (nextState: EditorState, setEditorState: Function, field: string) => {
    setEditorState(nextState);
    formik.setFieldTouched(field, true);
  };

  const fullColumn = (content: ReactNode) => <Col xs={12}>{content}</Col>;

  return (
    <Section header={headerLabel} innerRef={scrollToRef}>
      <form data-testid="founder-company-bio-form">
        <MobileFormReversed gutter={[24, 24]}>
          <Col xs={24} sm={24} md={24} lg={16} xl={17}>
            <Row>
              <Col span="24">
                <FormikField
                  label={{
                    for: 'aboutCompany',
                    label: (
                      <span>
                        {aboutCompanyLabel}
                        <Tooltip title={aboutCompanyTooltipLabel} />
                      </span>
                    ),
                  }}
                  error={formik.errors.aboutCompany}
                  touched={formik.touched.aboutCompany}
                >
                  <Wysiwyg
                    editorState={aboutCompanyEditorState}
                    onChange={(contentState: RawDraftContentState | EditorState) => {
                      formik.setFieldValue('aboutCompany', JSON.stringify(contentState));
                    }}
                    onEditorStateChange={(s) => {
                      onWysiwygChange(s, setAboutCompanyEditorState, 'aboutCompany');
                    }}
                    isEditorActive={isAboutCompanyEditorActive}
                    onFocus={() => setIsAboutCompanyEditorActive(true)}
                  />
                </FormikField>
              </Col>
            </Row>
            <Row>
              <Col span="24">
                <FormikField
                  label={{
                    for: 'achievements',
                    label: achievementsLabel,
                  }}
                  error={formik.errors.achievements}
                  touched={formik.touched.achievements}
                >
                  <Wysiwyg
                    editorState={achievementsCompanyEditorState}
                    onChange={(contentState: RawDraftContentState | EditorState) => {
                      formik.setFieldValue('achievements', JSON.stringify(contentState));
                    }}
                    onEditorStateChange={(s) => {
                      onWysiwygChange(s, setAchievementsEditorState, 'achievements');
                    }}
                    isEditorActive={isAchievementsEditorActive}
                    onFocus={() => setIsAchievementsEditorActive(true)}
                  />
                </FormikField>
              </Col>
            </Row>
          </Col>
        </MobileFormReversed>
        <Row>
          {fullColumn(
            <SubmitButton
              data-testid="save_company_bio_btn"
              type="primary"
              disabledEvaluation={{ isValid, dirty, submitCount }}
              onClick={formik.submitForm}
            >
              {saveChangesLabel}
            </SubmitButton>,
          )}
        </Row>
      </form>
    </Section>
  );
};

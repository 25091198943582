import { SwitchLabelled } from '@parts/switch-labelled/switch-labelled';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const PublishSwitch = styled(SwitchLabelled)`
  background: ${({ theme }) => theme.color.sectionBackground};
  padding: 5px;
  padding-right: 15px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  margin: 10px;
  color: ${({ theme }) => theme.color.fontPrimary};
  border: 1px solid ${({ theme }) => theme.color.border};
`;

const GoToProfile = styled(Link)`
  margin-top: ${({ theme }) => theme.spacing.base};
`;

export default { GoToProfile, PublishSwitch };

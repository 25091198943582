import styled from 'styled-components';

interface InnerBlockProps {
  enlarged: boolean;
}

export const InnerBlock = styled.div<InnerBlockProps>`
  border-radius: 5px;
  width: 100%;
  max-width: 100%;
  background: ${({ theme }) => theme.color.sectionBackground};
  padding: ${({ theme }) => theme.spacing.base};
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.border};

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: ${(props) => (props.enlarged ? '730px' : '540px')};
    padding: ${({ theme }) => theme.spacing.xlarge};
  }

  > h1 {
    margin-top: ${({ theme }) => theme.spacing.base};
    margin-bottom: ${({ theme }) => theme.spacing.base};
  }

  > form {
    width: 100%;

    > button[type='submit'] {
      display: block;
      width: 100%;

      @media (min-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
        width: calc(100% - ${({ theme }) => theme.spacing.xlarge});
        margin: auto;
      }
    }
  }
`;

import type { GraphqlResponse } from '@/types';
import { axiosInstance } from '@/utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@/utils/fns/graphql-response-wrapper';

export const FOUNDER_SMART_MATCH_SKILLS_CACHE_KEY = 'founder-smart-match-skills';

export const getFounderSmartMatchSkillsOptions = async (): Promise<GraphqlResponse<string[]>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
    query getSmartMatchOptions {
      skillSets
    }
  `,
  });

  return graphqlResponseWrapper(data, ['skillSets']);
};

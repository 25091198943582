import { Progress } from 'antd';
import styled from 'styled-components';
import type { V2ProgressBarVariants } from './v2-progress-bar';

const V2ProgressBar = styled(Progress)<{ variant: V2ProgressBarVariants }>`
  ${({ variant, strokeColor, theme }) => {
    if (strokeColor) {
      return `
        .ant-progress-bg {
          background: ${strokeColor};
        }
      `;
    }

    if (variant) {
      const variantColorMap = {
        primary: theme.v2.colors.blue[300],
        'blue-red': theme.v2.gradients['blue-red-gradient'],
        magenta: theme.v2.gradients['magenta-gradient'],
        green: theme.v2.gradients['green-gradient'],
        blue: theme.v2.gradients['blue-gradient'],
      };

      return `
        .ant-progress-bg {
          background: ${variantColorMap[variant]};
        }
      `;
    }
  }}
`;

export default { V2ProgressBar };

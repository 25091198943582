import styled from 'styled-components';

export const StyledContainer = styled.main`
  background: ${({ theme }) => theme.color.layoutBackground};
  color: ${({ theme }) => theme.color.fontPrimary};
  max-height: 100vh;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.appGlobalMargin.default};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    padding: ${({ theme }) => theme.appGlobalMargin.mobile};
  }
`;

export const BlobContainer = styled.div`
  width: 60%;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
  z-index: 0;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    display: none;
  }

  path {
    fill: ${({ theme }) => theme.color.sectionBackground};
  }
`;

export const LogoContainer = styled.div`
  width: 300px;
  z-index: 1;

  path:first-of-type {
    fill: ${({ theme }) => theme.color.logoFill};
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: 200px;
  }
`;

export const TextContainer = styled.div`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: 500;
  margin-top: 20px;
  z-index: 1;

  & > .ant-spin {
    margin-left: ${({ theme }) => theme.spacing.small};
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    font-size: ${({ theme }) => theme.fontSize.regular};
    text-align: center;
  }
`;

import { useTranslation } from '@utils/hooks/use-translation/use-translation';

export const usePersonalInfoTranslations = () =>
  useTranslation([
    'onboarding.avatar.info.l1',
    'onboarding.avatar.info.l2',
    'onboarding.avatar.upload',
    'investor-onboarding.personal.firstNameLabel',
    'investor-onboarding.personal.LastNameLabel',
    'gender.select.title',
    'gender.select.man',
    'gender.select.woman',
    'gender.select.nonBinary',
    'gender.select.preferNotToSay',
    'investor-onboarding.personal.occupationLabel',
    'investor-onboarding.personal.occupationInfo',
    'investor-onboarding.personal.linkedinUrlLabel',
    'investor-onboarding.personal.LinkedinUrlInfo',
    'investor-onboarding.personal.companyNameLabel',
    'investor-onboarding.personal.recentCompanyLabel',
    'investor-onboarding.personal.verificationLabel',
    'investor-onboarding.personal.verification.linkedin',
    'investor-onboarding.personal.verification.orLabel',
  ]);

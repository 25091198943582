import React from 'react';
import { useQueryParamsTabs } from './hooks/use-query-params-tabs';
import type { V2NavigationTabsProps } from './v2-navigation-tabs.types';

import S from './v2-navigation-tabs.styles';

export const V2NavigationTabs: React.FC<V2NavigationTabsProps> = ({
  tabs,
  defaultActive,
  fallbackElement,
  ...rest
}) => {
  const { activeTab, setActiveTab } = useQueryParamsTabs({
    defaultActive: defaultActive,
    tabsList: tabs.map((tab) => tab.key),
  });
  const currentTabElement = tabs.find((tab) => tab.key === activeTab)?.content;

  return (
    <S.Container {...rest}>
      <S.LabelsList>
        {tabs.map((tab) => (
          <S.Label key={tab.key}>
            <S.LabelButton
              onClick={() => setActiveTab(tab.key)}
              data-active={tab.key === activeTab}
              disabled={tab.disabled}
            >
              {tab.label}
            </S.LabelButton>
          </S.Label>
        ))}
      </S.LabelsList>

      <S.ContentContainer>{currentTabElement ?? fallbackElement}</S.ContentContainer>
    </S.Container>
  );
};

import ChatIcon from '@assets/icons/chat-icon.svg?react';
import DownloadIcon from '@assets/icons/download-icon.svg?react';
import { TableColumn } from '@parts/table-column/table-column';
import { Links } from '@router/links';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import type { ColumnsType } from 'antd/lib/table';
import { Tag } from '../../../../tag/tag';
import type { RoleApplicationsResponse } from '../../../api/get-role-application.actions';
import S from './table.styles';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { DateFormat } from '@i18n/date-formats';

interface RolesTableColumnsProps {
  translations: string[];
  onMessage: (nedId: string) => void;
  onApprove: (id: string) => void;
  onReject: (id: string) => void;
  oneColumn?: boolean;
}

export const Columns = ({
  translations: [title, location, appliedAt, resumeUrl, nedId, invited, rejected],
  oneColumn,
  onMessage,
  onApprove,
  onReject,
}: RolesTableColumnsProps) => {
  const {
    deviceData: { isMobile, isTablet },
  } = useDeviceDetect();

  const { dateFormatter } = useUserDateFormat();

  const approveBtn = (record: RoleApplicationsResponse) => (
    <S.GreenTick onClick={() => onApprove(record.id)} data-testid="confirm-ned-role-application" />
  );

  const rejectBtn = (record: RoleApplicationsResponse) => (
    <S.RedTick onClick={() => onReject(record.id)} data-testid="reject-ned-role-application" />
  );

  const renderActionColumn = (record: RoleApplicationsResponse) => {
    if (record.status === 'WaitingForApproval') {
      return (
        <>
          {approveBtn(record)}
          {rejectBtn(record)}
        </>
      );
    }

    if (record.status === 'Approved') {
      return (
        <Tag icon={<S.GreenTick />} color="success">
          {invited}
        </Tag>
      );
    }

    return (
      <Tag icon={<S.RedTick />} color="error">
        {rejected}
      </Tag>
    );
  };

  const avatarSize = isMobile || isTablet ? 50 : 30;

  return [
    {
      title,
      dataIndex: 'name',
      key: 'name',
      width: '45%',
      render: (_: string, record: RoleApplicationsResponse) => (
        <TableColumn title={title}>
          <S.Link to={`${Links.NED_PUBLIC_PROFILE(record.nedId)}`}>
            <S.Name>
              <S.CircularImage size={avatarSize} imageUrl={record.avatarUrl} />
              {record.name}
            </S.Name>
          </S.Link>
        </TableColumn>
      ),
    },
    {
      title: location,
      dataIndex: 'location',
      key: 'location',
      width: '20%',
      render: (value: string) => <TableColumn title={location}>{value}</TableColumn>,
    },
    {
      title: appliedAt,
      dataIndex: 'appliedAt',
      key: 'appliedAt',
      width: '15%',
      render: (_: string, record: RoleApplicationsResponse) => (
        <TableColumn title={appliedAt}>{dateFormatter(record.appliedAt, DateFormat.SHORT_MONTH_FULL_DATE)}</TableColumn>
      ),
    },
    {
      title: resumeUrl,
      dataIndex: 'resumeUrl',
      key: 'resumeUrl',
      width: '5%',
      render: (_: string, record: RoleApplicationsResponse) => (
        <TableColumn title={resumeUrl}>
          <S.IconWrapper type="download" href={record.resumeUrl}>
            <DownloadIcon />
          </S.IconWrapper>
        </TableColumn>
      ),
    },
    {
      title: nedId,
      dataIndex: 'nedId',
      key: 'nedId',
      width: '5%',
      render: (_: string, record: RoleApplicationsResponse) => (
        <TableColumn title={nedId}>
          <S.IconWrapper onClick={() => onMessage(record.nedId)}>
            <ChatIcon data-testid="chat-ned-role-application" />
          </S.IconWrapper>
        </TableColumn>
      ),
    },
    ...(oneColumn
      ? ([
          {
            title: '',
            width: '10%',
            render: (_: string, record: RoleApplicationsResponse) => (
              <S.ButtonsContainer>{renderActionColumn(record)}</S.ButtonsContainer>
            ),
          },
        ] as ColumnsType<RoleApplicationsResponse>)
      : ([
          {
            title: '',
            width: '15%',
            render: (_: string, record: RoleApplicationsResponse) => (
              <S.ButtonsContainer>{renderActionColumn(record)}</S.ButtonsContainer>
            ),
          },
        ] as ColumnsType<RoleApplicationsResponse>)),
  ];
};

import TickBase from '@assets/icons/green-tick-icon.svg?react';
import { gradientBorderStyles } from '@pages/content/onboarding/parts/aside/parts/aside-item/aside-item.styles';
import { tickStyles } from '@pages/content/onboarding/parts/gradient-tile/tile.styles';
import { InvestorType } from '@pages/content/profile/investor/api/types';
import styled, { css } from 'styled-components';

const InfoTile = styled.button<{ $selected: boolean; $investorType: InvestorType }>`
  width: 250px;
  height: 540px;
  position: relative;
  background: ${({ theme }) => theme.onboardingColors.investorTypeTileBackground};
  border-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.xbase} ${({ theme }) => theme.spacing.medium};
  text-align: left;
  white-space: pre-line;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    margin: ${({ theme }) => theme.spacing.small} 0;
  }

  &:before {
    ${gradientBorderStyles}

    ${({ $selected }) =>
      $selected &&
      css`
        background: ${({ theme }) => theme.gradient.onboardingInvestorTileGradient};
      `}
  }

  ${({ $investorType }) =>
    $investorType === InvestorType.Angel &&
    css`
      width: 750px;
      height: 850px;
      position: relative;
      background: ${({ theme }) => theme.onboardingColors.investorTypeTileBackground};
      border-color: transparent;
      cursor: pointer;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: ${({ theme }) => theme.spacing.xmedium} ${({ theme }) => theme.spacing.medium};
      text-align: left;
      white-space: pre-line;

      @media (max-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) {
        width: 680px;
      }
      @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
        margin: ${({ theme }) => theme.spacing.small} 0;
        width: 550px;
        height: 995px;
      }

      @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
        width: 360px;
        height: 1385px;
      }
      @media (max-width: ${({ theme }) => theme.deviceBreakpoint.xsmall}) {
        width: 300px;
        height: 1600px;
      }
    `}
`;

const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  color: ${({ theme }) => theme.baseColors.Blue300};
  margin-top: ${({ theme }) => theme.spacing.small};
`;

const Description = styled.div<{ $investorType: InvestorType }>`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.baseColors.Blue300};
  margin-top: ${({ theme }) => theme.spacing.xmedium};
`;

const WarningTextContainer = styled.div`
  color: ${({ theme }) => theme.baseColors.Blue300};
  padding-top: ${({ theme }) => theme.spacing.medium};
  font-size: ${({ theme }) => theme.fontSize.small};

  p:first-child {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    padding-bottom: ${({ theme }) => theme.spacing.xsmall};
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    padding-right: ${({ theme }) => theme.spacing.xsmall};
  }
`;

const Tick = styled(TickBase)`
  ${tickStyles}
`;

export const BulletList = styled.ul<{ $investorType: InvestorType }>`
  list-style-type: none;
  list-style-position: inside;
  counter-reset: item;
  padding-left: ${({ theme }) => theme.spacing.small};

  &:first-of-type {
    margin-top: ${({ theme }) => theme.spacing.small};
  }

  > li {
    counter-increment: item;
    padding-left: ${({ $investorType }) => ($investorType !== InvestorType.Institutional ? '75px' : '0px')};

    &:before {
      content: '(' counter(item, lower-alpha) ') ';
      position: ${({ $investorType }) => ($investorType === InvestorType.Institutional ? 'unset' : 'absolute')};
      left: 60px;

      @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
        position: relative;
        left: 0;
        padding-right: ${({ theme }) => theme.spacing.xsmall};
      }
    }

    @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
      padding-left: ${({ theme }) => theme.spacing.xmedium};
    }

    @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
      padding-left: 0;
    }

    &.bold-counter:before {
      font-weight: ${({ theme }) => theme.fontWeight.bold};
    }
  }
`;

export const SubBulletList = styled.ul`
  list-style-type: none;
  counter-reset: RomanianItem;

  li {
    counter-increment: RomanianItem;
    &:before {
      content: '(' counter(RomanianItem, lower-roman) ') ';
    }

    @media (max-width: ${({ theme }) => theme.deviceBreakpoint.xsmall}) {
      padding-left: 0;
    }
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    padding-left: 25px;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.xsmall}) {
    padding-left: ${({ theme }) => theme.spacing.small};
  }
`;

export const SubListItem = styled.p`
  margin-left: ${({ theme }) => theme.spacing.xmedium};
`;

export default {
  InfoTile,
  Tick,
  Title,
  Description,
  BulletList,
  SubBulletList,
  WarningTextContainer,
  SubListItem,
};

import S from './company-tile.styles';

export const CompanyTile = ({
  name,
  logoUrl,
  about,
  supplement,
  url,
  isNew = false,
  isFlagVisible = false,
  disabled = false,
}: {
  name: string;
  supplement: string;
  logoUrl: string | null;
  about?: string;
  url?: string;
  isNew?: boolean;
  isFlagVisible?: boolean;
  disabled?: boolean;
}) => (
  <S.CounterPartTile
    name={name}
    supplement={supplement}
    logoUrl={logoUrl}
    about={about}
    url={url}
    isNew={isNew}
    isFlagVisible={isFlagVisible}
    disabled={disabled}
  />
);

import type { MetricData } from '@pages/content/progress-report/api/get-progress-report-numeric-metric-nodes/get-progress-report-numeric-metric-nodes.action';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_NUMERIC_METRIC_NODES_AND_META_QUERY_CACHE_KEY = (metricId: string) =>
  `get-numeric-metric-nodes-and-meta-${metricId}`;

export interface MetricResponseData extends MetricData {}

export const getNumericMetricNodesAndMetaAction =
  (id: string) => async (): Promise<GraphqlResponse<MetricResponseData>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
        query getNumericMetricNodesAndMeta {
          getMetricDataPoints(metricId: "${id}") {
            month,
            year,
            value,
            forecast,
            note,
            id,
          }
          metricMetadata(metricId: "${id}") {
            scale,
          }
      }
    `,
    });

    return graphqlResponseWrapper(data, ['getMetricDataPoints', 'metricMetadata']);
  };

import EditIconBase from '@assets/icons/edit-icon.svg?react';
import GreenCheckCircleIconBase from '@assets/icons/green-check-circle.svg?react';
import TrashIconBase from '@assets/icons/trash.svg?react';
import styled, { css } from 'styled-components';

const iconStyles = css`
  cursor: pointer;
  width: 18px;
  height: auto;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
`;

const Trash = styled(TrashIconBase)`
  ${iconStyles}
`;

const Edit = styled(EditIconBase)`
  ${iconStyles}
  margin-left: ${({ theme }) => theme.spacing.small};
  margin-right: ${({ theme }) => theme.spacing.small};
`;

const CheckCircle = styled(GreenCheckCircleIconBase)``;

export default { Trash, Edit, Controls, CheckCircle };

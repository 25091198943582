import type { ApexOptions } from 'apexcharts';
import { useTheme } from 'styled-components';

export const useCircularBarGraphOptions = (
  device?: 'mobile' | 'tablet' | 'desktop',
  selectedChartType?: 'pie' | 'bar',
  labelsToCircularChart?: string[],
): ApexOptions => {
  const theme = useTheme();

  return {
    chart: {
      type: selectedChartType,
      stackType: '100%',
      stacked: true,
      animations: {
        enabled: false,
      },
      offsetX: selectedChartType === 'bar' ? -15 : 5,
      toolbar: {
        show: false,
      },
      selection: {
        enabled: false,
      },
    },
    fill: {
      opacity: 1,
    },
    labels: labelsToCircularChart,
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '100',
      },
      pie: {
        expandOnClick: false,
        customScale: device === 'mobile' ? 0.8 : 0.9,
        offsetY: 10,
        offsetX: (() => {
          if (device === 'desktop') {
            return -70;
          }
          if (device === 'tablet') {
            return -55;
          }
          return -36;
        })(),
      },
    },
    stroke: {
      colors: [theme.color.sectionBackground],
      width: 1,
    },
    colors: [
      theme.chartColors.Blue1,
      theme.chartColors.Blue2,
      theme.chartColors.Blue3,
      theme.chartColors.Yellow,
      theme.chartColors.Orange,
      theme.chartColors.Pink,
      theme.chartColors.Purple,
    ],
    xaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        filter: {
          type: 'none',
        },
      },
    },
  };
};

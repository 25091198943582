import { useScrollToTopOnPathChange } from '@utils/hooks/use-scroll-to-top-on-path-change/use-scroll-to-top-on-path-change';
import type { ReactNode } from 'react';
import { AsideBar } from '../aside/aside-bar';

import { WizardLayout } from '@layout/wizard/wizard.layout';
import S from './layout-default.style';

interface OnboardingLayoutProps {
  children: ReactNode;
  onPreviousClick?: () => void;
  onNextClick: (() => void) | null;
  nextDisabled?: boolean;
  currentStep?: number;
  totalSteps?: number;
  lastPage?: boolean;
  startingPage?: boolean;
  asideContent?: ReactNode;
  showFooter?: boolean;
  centerContent?: boolean;
  finishLater?: boolean;
  onFinishLater?: () => void;
}

export const OnboardingLayout = ({
  children,
  onPreviousClick,
  onNextClick,
  nextDisabled = false,
  currentStep = 0,
  totalSteps = 0,
  lastPage = false,
  startingPage = false,
  asideContent = null,
  showFooter = true,
  centerContent = false,
  finishLater = false,
  onFinishLater,
}: OnboardingLayoutProps) => {
  useScrollToTopOnPathChange();

  return (
    <WizardLayout
      footerProps={{
        previousHandler: onPreviousClick,
        nextHandler: onNextClick ?? (() => {}),
        nextDisabled,
        startingPage,
        lastPage,
      }}
      withFooter={showFooter}
      currentStep={currentStep}
      totalSteps={totalSteps}
      centerContent={centerContent}
      finishLater={finishLater}
      onFinishLater={onFinishLater}
    >
      <S.LayoutInner data-testid="onboarding-layout-content" showAside={Boolean(asideContent)}>
        {asideContent && <AsideBar>{asideContent}</AsideBar>}
        <S.LayoutChildWrapper>{children}</S.LayoutChildWrapper>
      </S.LayoutInner>
      {showFooter && <S.BottomSpacer />}
    </WizardLayout>
  );
};

import message from '@/parts/message/message';
import { getServerError } from '@/utils/fns/get-server-error';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { changeConnectionIntent } from '../api/change-connection-intent';

export const useChangeConnectionIntent = ({
  connectionId,
  onSuccessCallback,
  onErrorCallback,
}: {
  connectionId: string;
  onSuccessCallback?: () => void;
  onErrorCallback?: (err: AxiosError) => void;
}) => {
  const mutation = useMutation(changeConnectionIntent(connectionId), {
    onSuccess: () => onSuccessCallback?.(),
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
      onErrorCallback?.(err);
    },
  });

  return {
    changeConnectionIntent: mutation.mutate,
    isChangeConnectionIntentLoading: mutation.isLoading,
    ogMutation: mutation,
  };
};

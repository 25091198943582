import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const overlayGradientLight =
  'linear-gradient(0.18deg, #D9E3FE 0.16%, #dce5fcfa 41.97%, #dee6fad9 66.29%, #ffffffcd 99.84%)';
const overlayGradientDark =
  'linear-gradient(0.18deg, #223355 0.16%, #334466fa 41.97%, #445677d9 66.29%, #556789cd 99.84%)';

const Card = styled.div`
  position: relative;
  border-radius: 6px;
  padding: 20px;
  height: 100%;
  overflow: hidden;
  border: 1px solid ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[100], theme.v2.colors.blue[600])};
  background: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[50], theme.v2.colors.blue[700])};
`;

const DisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => themedStyle(theme.v2.mode, overlayGradientLight, overlayGradientDark)};
  z-index: 1;
  pointer-events: none;
`;

const DisabledOverlayInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export default { Card, DisabledOverlay, DisabledOverlayInner };

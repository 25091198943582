import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.color.dashboardBackground};
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: ${({ theme }) => theme.radius.default};
  margin-bottom: ${({ theme }) => theme.spacing.base};
  display: flex;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-wrap: wrap;
  }
`;

const Column = styled.div`
  flex: 50%;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.xmedium};

  & + & {
    border-left: 1px solid ${({ theme }) => theme.color.border};
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex: 100%;
    padding-bottom: 0;

    & + & {
      border-left: 0;
      padding-top: 0;
      margin: 0 0 ${({ theme }) => theme.spacing.small} 0;
    }
  }
`;

const Row = styled.div`
  display: flex;
`;

const copy = css`
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: 24px;
  letter-spacing: 0.5px;
`;

const Label = styled.p`
  ${copy};
  flex-basis: 120px;
  flex-grow: 0;
  flex-shrink: 0;
  font-weight: ${({ theme }) => theme.fontWeight.normal};
`;

const Value = styled.p`
  ${copy};
  margin-left: ${({ theme }) => theme.spacing.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export default { Wrapper, Column, Row, Label, Value };

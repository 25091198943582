import type { AdvisorSearchProfile } from '@pages/content/lens/api/get-founder-browse-neds';
import { MatchedAdvisor } from '../matched-advisor/matched-advisor';
import S from './browsed-matches-grid.styles';

export const BROWSE_ADVISORS_GRID_TEST_ID = 'browse-advisors-grid';

export const BrowsedMatchesGrid = ({
  items,
  isLoading,
  refetch,
  onPreview,
}: {
  items: AdvisorSearchProfile[];
  isLoading: boolean;
  refetch: () => void;
  onPreview?: (id: string) => void;
}) => (
  <S.Grid data-testid={BROWSE_ADVISORS_GRID_TEST_ID} columns={3}>
    {items.map((advisor) => (
      <MatchedAdvisor
        id={advisor.id}
        name={advisor.name}
        key={advisor.id}
        primarySkillSet={advisor.primarySkillSet}
        secondarySkillSet={advisor.secondarySkillSet}
        avatarUrl={advisor.avatarUrl}
        isNew={advisor.isNew}
        compensationType={advisor.compensationType}
        industries={advisor.industries}
        isBookmarked={advisor.isBookmarked}
        onPreview={onPreview}
        connection={advisor.connection}
        isLoading={isLoading}
        refetch={refetch}
      />
    ))}
  </S.Grid>
);

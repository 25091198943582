import styled from 'styled-components';

const InvestorRiskWarning = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: ${({ theme }) => theme.baseColors.White};
  border-bottom: ${({ theme }) => `1px solid ${theme.color.border}`};
  color: ${({ theme }) => theme.baseColors.Grey200};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  padding: ${({ theme }) => `${theme.spacing.xsmall} ${theme.spacing.large}`};
  width: 100%;
  z-index: 1000;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    padding: ${({ theme }) => `${theme.spacing.xsmall} ${theme.spacing.medium}`};
    position: relative;
  }

  > div {
    :nth-of-type(2) {
      margin-top: ${({ theme }) => theme.spacing.xsmall};
    }
  }
`;

const ModalLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.color.link};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  margin-left: ${({ theme }) => theme.spacing.xsmall};
`;

export default { InvestorRiskWarning, ModalLink };

import { useState } from 'react';
import ExtensiveModal from './extensive-modal';

export const useExtensiveModal = (open: boolean = false) => {
  const [isOpen, setIsOpen] = useState(() => open);

  return {
    Modal: ExtensiveModal,
    open: isOpen,
    show: () => setIsOpen(true),
    hide: () => setIsOpen(false),
  };
};

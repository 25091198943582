import SuccessIcon from '@assets/icons/investor-onboarding/success.svg?react';
import { Section } from '@parts/section/section';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';

import S from './become-awaiting.styles';

export const BecomeInvestorAwaiting = () => {
  const [title, description] = useTranslation([
    'profile.ned.section.becomeAnInvestor.awaiting.title',
    'profile.ned.section.becomeAnInvestor.awaiting.description',
  ]);

  return (
    <Section>
      <S.Wrapper>
        <SuccessIcon data-testid="success" />
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
      </S.Wrapper>
    </Section>
  );
};

import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import * as Yup from 'yup';
import { nameValidation } from '../create-dashboard/validation-schema';

export const useValidationSchema = () => {
  const [requiredLabel] = useTranslation(['formik.validation.required']);

  return Yup.object({
    name: nameValidation(requiredLabel),
  });
};

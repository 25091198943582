import S from './v2-title.styles';
import type { V2TitleProps } from './v2-title.types';

export const V2Title = ({
  title,
  size = 'lg',
  weight = 'bold',
  family = 'body',
  tag,
  ...restAttributes
}: V2TitleProps) => {
  return (
    <S.Title size={size} weight={weight} family={family} tag={tag} {...restAttributes}>
      {title}
    </S.Title>
  );
};

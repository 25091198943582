import type { ChangeEvent } from 'react';
import { useV2NoteTileContext } from '../v2-note-tile-context';
import S from '../v2-note-tile.styles';

type Props = {
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
  name: string;
  value?: string;
  placeholder?: string;
  ['data-testid']?: string;
};

export const Title = ({
  onChange,
  disabled,
  'data-testid': dataTestId = 'v2-note-tile-title',
  value,
  placeholder,
  name,
  ...props
}: Props) => {
  const { title } = useV2NoteTileContext();

  return (
    <S.Title
      {...props}
      name={name}
      onChange={onChange}
      disabled={disabled}
      data-testid={dataTestId}
      value={value ?? title}
      placeholder={placeholder}
    />
  );
};

import type { USState } from '@/utils/type-definitions/us-state';
import { axiosInstance } from '@utils/axios/axios-instance';
import type { CountryCodes } from '@utils/type-definitions/iso-to-country-name';

interface SetCountryOfResidencePayload {
  countryOfResidence?: CountryCodes;
  usState?: USState;
}

export const setCountryOfResidenceAction = (payload: SetCountryOfResidencePayload) =>
  axiosInstance.post('/select-country-of-residence', payload);

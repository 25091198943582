import type { TargetGeographyValue } from '@constants/target-geography';
import { axiosInstance } from '@utils/axios/axios-instance';

export interface IInvestorSmartMatchPayload {
  industries: string[] | null;
  customerGroup: string[] | null;
  productStage: string[] | null;
  investmentStage: string[] | null;
  targetGeography: TargetGeographyValue[];
}

export const updateSmartMatchAction = (formData: IInvestorSmartMatchPayload) =>
  axiosInstance.patch('/profile/investor/smart-match', formData);

import { useHistory } from '@/utils/hooks/use-history/use-history';
import { useQueryParams } from '@/utils/hooks/use-query/use-query-params';
import { stringify } from 'query-string';

const FILTERS_PREFIX = 'filter';

type Stringifiable = string | string[] | undefined;
type FilterKey = 'connectionType' | 'intent' | 'phrase';
type FilterQueryKey = `filter${Capitalize<FilterKey>}`;

const capitalizeFilterKey = (key: FilterKey): FilterQueryKey => {
  return `${FILTERS_PREFIX}${key[0].toUpperCase()}${key.slice(1)}` as FilterQueryKey;
};

/**
 * Custom hook for managing URL filters.
 *
 * @template T - The type of filter keys - union of FilterKey.
 * @param {T[]} filterKeys - An array of filter keys that are going to be used.
 * @returns {Object} - An object containing the filters and helper functions.
 */
export const useUrlFilters = <T extends FilterKey>(filterKeys: T[]) => {
  const history = useHistory();
  const queryParams = useQueryParams<Record<FilterQueryKey, Stringifiable>>();

  const filters: Record<T, Stringifiable> = {} as Record<T, Stringifiable>;
  for (const key of filterKeys) {
    filters[key] = queryParams[capitalizeFilterKey(key)];
  }

  const apply = (updatedFilters: Record<T, Stringifiable>) => {
    const filtersObject: Record<FilterQueryKey, Stringifiable> = {} as Record<FilterQueryKey, Stringifiable>;
    for (const key of filterKeys) {
      if (Object.hasOwn(updatedFilters, key)) {
        filtersObject[capitalizeFilterKey(key)] = updatedFilters[key] || undefined;
      }
    }

    history.replace({ search: stringify({ ...queryParams, ...filtersObject }) });
  };

  const applyDiscriminative = (updatedFilters: Record<FilterQueryKey, Stringifiable>) => {
    history.replace({ search: stringify({ ...updatedFilters }) });
  };

  /********************************************
   * Helpers
   *
   */
  const filterValueAsArray = (key: T): string[] =>
    Array.isArray(filters[key]) ? (filters[key] as string[]) : filters[key] ? ([filters[key]!] as string[]) : [];
  const filterValueSingle = (key: T) =>
    Array.isArray(filters[key]) ? (filters[key]![0] as string) : filters[key] ? (filters[key] as string) : '';

  return {
    filters,
    apply,

    applyDiscriminative,

    // Helpers
    filterValueAsArray,
    filterValueSingle,
  };
};

import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: 600px;
  margin-bottom: 40px;
`;

export const Title = styled.span`
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  color: ${({ theme }) => theme.baseColors.White};
  display: block;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.small};
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export default { Wrapper, Title, Content, TitleWrapper };

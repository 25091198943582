import type { Dashboard as DashboardType } from '@pages/content/pulse/api/get-founder-dashboards/get-founder-dashboards.action';
import type { Dashboard as DashboardDetailsData } from '@pages/content/pulse/parts/dashboards/api/get-founder-dashboard/get-founder-dashboard.action';
import { DashboardDetails } from '@pages/content/pulse/parts/dashboards/parts/dashboard/dashboard-details';
import {
  dashboardIdParam,
  DashboardsMenu,
} from '@pages/content/pulse/parts/dashboards/parts/dashboards-menu/dashboards-menu';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import qs from 'qs';
import { useHistory } from 'react-router-dom';
import { AddDashboardElements } from './parts/dashboard/add-metric/add-dashboard-elements';
import EmptyDashboardState from './parts/dashboard/metrics/parts/empty-dashboard-state/empty-dashboard-state';
import { Submenu } from './parts/dashboard/submenu/submenu';
import UpdateDashboardName from './parts/dashboard/update-dashboard-name/update-dashboard-name';

export const newDashboardParam = 'new';
export const updateDashboardNameParam = 'update-name';
export const addMetricParam = 'add-metric';
export const updateTextBoxParam = 'update-text-box';
export const textBoxIdParam = 'textBoxId';

export const FounderDashboards = ({
  dashboards,
  hasCloseConnection,
  dashboardDetailsQueryKeyCache,
  dashboardDetailsIsLoading,
  dashboardDetails,
  dashboardsQueryKeyCache,
  followMode,
  reportMode,
  allowSetNotifications,
}: {
  dashboards: DashboardType[] | null | undefined;
  hasCloseConnection?: boolean;
  dashboardDetailsQueryKeyCache: string | unknown[];
  dashboardDetailsIsLoading: boolean;
  dashboardDetails: DashboardDetailsData;
  dashboardsQueryKeyCache: string | unknown[];
  followMode?: boolean;
  reportMode?: boolean;
  allowSetNotifications?: boolean;
}) => {
  const history = useHistory();
  const {
    [dashboardIdParam]: selectedDashboardId,
    [addMetricParam]: isAddingMetric,
    [updateDashboardNameParam]: isUpdatingName,
  } = useQueryParams() as {
    [dashboardIdParam]: string;
    [addMetricParam]: string;
    [updateDashboardNameParam]: string;
  };

  const currentDashboardId = selectedDashboardId || dashboards?.[0]?.id;
  const targetDashboard = dashboards?.find((d) => d.id === currentDashboardId);
  const dashboardName = targetDashboard?.name ?? '';

  const displayUpdateNameForm = () => {
    const parsedLocationSearch = {
      ...qs.parse(window.location.search),
      [dashboardIdParam]: currentDashboardId,
      [updateDashboardNameParam]: true,
    };

    history.push(`?${qs.stringify(parsedLocationSearch)}`);
  };

  const displayAddForm = () => {
    const parsedLocationSearch = {
      ...qs.parse(window.location.search),
      [dashboardIdParam]: currentDashboardId,
      [addMetricParam]: true,
    };

    history.push(`?${qs.stringify(parsedLocationSearch)}`);
  };

  const isDashboardEmpty = !dashboardDetails?.metrics?.length && !dashboardDetails?.textBoxes?.length;

  return (
    <>
      <DashboardsMenu
        hasCloseConnection={hasCloseConnection}
        showManageDataButton
        showAddDashboardButton
        dashboards={dashboards}
      />

      {currentDashboardId && dashboardDetails ? (
        <>
          <Submenu
            dashboardId={currentDashboardId}
            onChangeNameClick={displayUpdateNameForm}
            visibility={dashboardDetails?.visibility}
            displayAddForm={displayAddForm}
            hasCloseConnection={hasCloseConnection}
          />
          <DashboardDetails
            followMode={followMode}
            reportMode={reportMode}
            dashboardDetailsQueryKeyCache={dashboardDetailsQueryKeyCache}
            dashboardDetailsIsLoading={dashboardDetailsIsLoading}
            dashboardDetails={dashboardDetails}
            dashboardId={currentDashboardId}
            allowSetNotifications={allowSetNotifications}
          />
          {isAddingMetric && (
            <AddDashboardElements
              dashboardId={currentDashboardId}
              dashboardDetailsQueryKeyCache={dashboardDetailsQueryKeyCache}
            />
          )}
          {isUpdatingName && (
            <UpdateDashboardName dashboardsQueryKeyCache={dashboardsQueryKeyCache} name={dashboardName} />
          )}
        </>
      ) : null}
      {targetDashboard && isDashboardEmpty && <EmptyDashboardState onClick={displayAddForm} />}
    </>
  );
};

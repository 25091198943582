import { axiosInstance } from '@utils/axios/axios-instance';

export enum VisibilityMode {
  Private = 'Private',
  Connections = 'Connections',
  Community = 'Community',
  CloseConnections = 'CloseConnections',
}

export const setDashboardVisbilityAction = async (dashboardId: string, payload: { visibility: VisibilityMode }) =>
  axiosInstance.patch(`/pulse/dashboards/${dashboardId}/update-visibility`, payload);

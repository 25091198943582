import CheckboxFormikFieldBase from '@pages/content/profile/parts/checkbox-formik-field/checkbox-formik-field';
import styled from 'styled-components';

const CheckboxFormikField = styled(CheckboxFormikFieldBase)`
  margin-bottom: ${({ theme }) => theme.spacing.xmedium} !important;

  .ant-checkbox-wrapper {
    margin-right: ${({ theme }) => theme.spacing.small};
  }

  .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.baseColors.Blue200} !important;
  }

  .ant-checkbox-disabled {
    .ant-checkbox-inner {
      opacity: 0.2;

      ::after {
        background-color: ${({ theme }) => theme.baseColors.Blue200};
      }
    }

    .ant-checkbox-wrapper {
      opacity: 0.5;
      color: ${({ theme }) => theme.color.disabledCheckbox};
    }
  }

  label {
    font-size: ${({ theme }) => theme.fontSize.small};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    color: ${({ theme }) => theme.color.fontPrimary};
    white-space: nowrap;
  }
`;

const DisabledSpan = styled.span`
  opacity: 0.5;
  color: ${({ theme }) => theme.color.disabledCheckbox};
`;

const Wrapper = styled.div`
  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    display: flex;
    gap: ${({ theme }) => theme.spacing.large};
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    gap: ${({ theme }) => theme.spacing.xbase};
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.xsmall}) {
    gap: ${({ theme }) => theme.spacing.small};
  }
`;

export default { CheckboxFormikField, DisabledSpan, Wrapper };

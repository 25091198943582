import { V2AriaMobileConnectionCard } from '@/components/blocks/v2-aria-mobile-connection-card/v2-aria-mobile-connection-card';
import { V2AvatarWithDetails } from '@/components/ui/v2-avatar-with-details/v2-avatar-with-details';
import { V2Button } from '@/components/ui/v2-button/v2-button';
import { V2Collapsible } from '@/components/ui/v2-collapsible/v2-collapsible';
import { V2IconButton } from '@/components/ui/v2-icon-button/v2-icon-button';
import { V2Link } from '@/components/ui/v2-link/v2-link';
import { V2TableStateInfo } from '@/components/ui/v2-table-state-info/v2-table-state-info';
import { investorIdQueryParam } from '@/pages/content/public-profile/investor/investor.page';
import { TranslationText } from '@/parts/translation-text/translation-text';
import { Links } from '@/router/links';
import {
  universalEngageConversationAction,
  useEngageConversation,
} from '@/utils/hooks/use-engage-conversation/use-engage-conversation';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { useState } from 'react';
import { useHandleCloseConnection } from '../../hooks/use-handle-close-connection';
import { useRemoveConnection } from '../../hooks/use-remove-connection';
import type { FounderConnection, FounderConnectionType } from '../../types';
import { TableRowCollapsible } from './table-parts/row-collapsible';

type TConnectionsMobileListProps = {
  data: FounderConnection[];
  refetch: () => void;
};

type TCardProps = {
  item: FounderConnection;
  refetch: () => void;
};

const Card = ({ item, refetch }: TCardProps) => {
  const [closeConnectionTranslation, removeConnectionTranslation, chatTranslation, notesTranslation] = useTranslation([
    'connections.close-connection',
    'connections.remove-connection',
    'connections.chat',
    'connections.notes',
  ]);

  const { removeConnection, isRemoveConnectionLoading } = useRemoveConnection(item.connectionId, refetch);
  const { handleCloseConnection, isCloseConnectionLoading } = useHandleCloseConnection(
    item.connectionId,
    item.isCloseConnection,
    refetch,
  );

  const { mutateAsync: engageMessage, isLoading: messageLoading } = useEngageConversation(
    universalEngageConversationAction,
  );
  const [isNotesVisible, setIsNotesVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const profileUrl: Record<FounderConnectionType, string> = {
    Advisor: `${Links.NED_PUBLIC_PROFILE(item.participantId)}`,
    Investor: `${Links.INVESTOR_PUBLIC_PROFILE()}?${investorIdQueryParam}=${item.participantId}`,
    Both: `${Links.INVESTOR_PUBLIC_PROFILE()}?${investorIdQueryParam}=${item.participantId}`,
  };

  return (
    <V2AriaMobileConnectionCard
      head={
        <V2AriaMobileConnectionCard.Head>
          <V2AriaMobileConnectionCard.Row>
            <V2Link to={profileUrl[item.connectionType]} hasUnderline={false}>
              <V2AvatarWithDetails
                avatar={{ name: item.name, src: item.avatarUrl }}
                mainText={item.name}
                additionalText={item.investorType}
              />
            </V2Link>
            <V2IconButton name="kebab" size="md" onClick={() => setIsVisible((p) => !p)} />
          </V2AriaMobileConnectionCard.Row>
          <V2Collapsible visible={isVisible}>
            <V2AriaMobileConnectionCard.List>
              <V2AriaMobileConnectionCard.Action
                icon="people"
                text={closeConnectionTranslation}
                onClick={() => handleCloseConnection()}
                disabled={isCloseConnectionLoading}
              />
              <V2AriaMobileConnectionCard.Action
                icon="person-dash"
                text={removeConnectionTranslation}
                onClick={() => removeConnection()}
                disabled={isRemoveConnectionLoading}
              />
            </V2AriaMobileConnectionCard.List>
          </V2Collapsible>
        </V2AriaMobileConnectionCard.Head>
      }
      body={
        <V2AriaMobileConnectionCard.Body>
          <V2AriaMobileConnectionCard.List>
            <V2AriaMobileConnectionCard.Detail icon="clock" text={item.connectionDate.split('T')[0]} />
            <V2AriaMobileConnectionCard.Detail icon="building" text="Industry" />
            <V2AriaMobileConnectionCard.Detail icon="people" text="Close connection" />
          </V2AriaMobileConnectionCard.List>
        </V2AriaMobileConnectionCard.Body>
      }
      footer={
        <V2AriaMobileConnectionCard.Footer>
          <V2AriaMobileConnectionCard.Row>
            <V2Button
              variant="outlined"
              startIcon="chat-left-dots"
              disabled={messageLoading}
              onClick={() => engageMessage(item.participantId)}
              size="large"
            >
              {chatTranslation}
            </V2Button>
            <V2Button variant="link" endIcon="chevron-down" size="large" onClick={() => setIsNotesVisible((p) => !p)}>
              {notesTranslation}
            </V2Button>
          </V2AriaMobileConnectionCard.Row>
        </V2AriaMobileConnectionCard.Footer>
      }
      notes={<TableRowCollapsible onUpdated={refetch} targetId={item.participantId} isVisible={isNotesVisible} />}
    />
  );
};

export const ConnectionsMobileList = ({ data, refetch }: TConnectionsMobileListProps) => {
  return (
    <>
      {data.length === 0 ? (
        <V2TableStateInfo
          showIcon
          title={<TranslationText id="connections.founder.table.invites.empty.title" />}
          description={<TranslationText id="connections.founder.table.invites.empty.description" />}
        />
      ) : (
        data.map((item) => <Card key={item.participantId} item={item} refetch={refetch} />)
      )}
    </>
  );
};

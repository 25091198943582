import GreenTickIcon from '@assets/icons/circular-green-tick-icon.svg?react';
import RedTickIcon from '@assets/icons/circular-red-tick-icon.svg?react';
import { CircularImage as CircularImageBase } from '@parts/circular-image/circular-image';
import { Link as LinkBase } from 'react-router-dom';
import styled, { css } from 'styled-components';

const Link = styled(LinkBase)`
  color: ${({ theme }) => theme.color.fontPrimary};
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.color.fontPrimary};
  }
`;

const iconStyles = css`
  width: 18px;
  height: auto;
  margin-left: ${({ theme }) => theme.spacing.xsmall};
  cursor: pointer;
`;

const GreenTick = styled(GreenTickIcon)`
  ${iconStyles}
`;

const RedTick = styled(RedTickIcon)`
  ${iconStyles}
`;

const Name = styled.div`
  display: flex;
  align-items: center;
`;

const CircularImage = styled(CircularImageBase)`
  margin-right: ${({ theme }) => theme.spacing.small};
`;

const IconWrapper = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;

  &:first-child {
    margin-left: 0;
  }
`;

const Wrapper = styled.section`
  margin-top: ${({ theme }) => theme.spacing.xbase};
`;

export default {
  Name,
  GreenTick,
  RedTick,
  ButtonsContainer,
  CircularImage,
  IconWrapper,
  Wrapper,
  Link,
};

import type { ReactNode } from 'react';
import S from './column.styles';

interface ColumnProps {
  label: string;
  children: ReactNode;
}

const Column = ({ label, children }: ColumnProps) => (
  <S.Wrapper>
    <S.Label>{label}</S.Label>
    <S.Value>{children}</S.Value>
  </S.Wrapper>
);

export default Column;

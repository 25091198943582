import { setFounderLearningStepFinished } from '@/pages/content/onboarding/founder/api/set-learning-step-finished.action';
import message from '@/parts/message/message';
import type { Any } from '@/types';
import { getServerError } from '@/utils/fns/get-server-error';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';

export const useFinishFounderLearningStep = (
  config: {
    onSuccess?: (() => void) | (() => Promise<Any>);
    onError?: (err: AxiosError) => void;
  } = {},
) => {
  const { mutateAsync: finishLearningStep, isLoading: isFinishLearningStepPending } = useMutation(
    setFounderLearningStepFinished,
    {
      onError: (err: AxiosError) => message.error({ content: getServerError(err) }),
      ...config,
    },
  );

  return { finishLearningStep, isFinishLearningStepPending };
};

import { engageFounderMessagingAction } from '@pages/content/lens/api/message-engage-founder';
import { Footer } from '@pages/content/lens/parts/footer/footer';
import { investorDisconnectFounderAction } from '@pages/content/pulse/api/disconnect-founder/disconnect-founder.action';
import { CompanyTile } from '@parts/company-tile/company-tile';
import { GridItem } from '@parts/grid-item/grid-item';
import { MessageSymbol } from '@parts/message-symbol/message-symbol';
import metaConfig from '@parts/meta-config/meta-config';
import { useMutation } from '@tanstack/react-query';
import { SanitizeWysiwyg } from '@utils/fns/sanitize-wysiwyg';
import { useEngageConversation } from '@utils/hooks/use-engage-conversation/use-engage-conversation';

import { checkIfValidWysiWygValue } from '@pages/content/profile/hooks/use-wysiwyg';
import { founderIdQueryParam } from '@pages/content/public-profile/founder/founder.page';
import S from '@parts/card/card.styles';
import { Links } from '@router/links';
import useConfirmModal from '@utils/hooks/use-confirm-modal/use-confirm-modal';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';

export const Company = ({
  id,
  name,
  logoUrl,
  industry,
  customerGroup,
  productStage,
  investmentStage,
  about,
  refetch,
  canBePreviewed,
}: {
  id: string;
  name: string;
  logoUrl: string;
  industry: string[];
  customerGroup: string[];
  investmentStage: string;
  productStage: string;
  about: string;
  refetch: Function;
  canBePreviewed: boolean;
}) => {
  const history = useHistory();

  const { mutateAsync: disconnect, isLoading: disconnectInProgress } = useMutation(investorDisconnectFounderAction, {
    onSettled: () => refetch(),
  });

  const [disconnectLabel, disconnectFounder] = useTranslation(['lens.disconnect', 'lens.investor.disconnectCompany']);

  const { mutateAsync: message, isLoading: isMessageLoading } = useEngageConversation(engageFounderMessagingAction);

  const { modal: confirmDisconnectModal, show: showConfirmDisconnectModal } = useConfirmModal({
    title: disconnectLabel,
    content: disconnectFounder,
    onConfirm: () => disconnect(id),
  });

  return (
    <GridItem
      header={
        <CompanyTile
          name={name}
          logoUrl={logoUrl}
          supplement={industry.length > 0 ? industry.join(', ') : '---'}
          disabled={!canBePreviewed}
        />
      }
      meta={[metaConfig({ customerGroup }).business, metaConfig({ investmentStage, productStage }).growth]}
      description={{
        dangerouslySet: true,
        content: checkIfValidWysiWygValue(about) ? SanitizeWysiwyg(JSON.parse(about)) : null,
      }}
      footer={
        <Footer>
          {confirmDisconnectModal}
          <S.Message type="link" onClick={() => message(id)} disabled={!canBePreviewed ?? isMessageLoading}>
            <MessageSymbol />
          </S.Message>
          <S.Disconnect type="link" disabled={disconnectInProgress} onClick={showConfirmDisconnectModal}>
            <S.DisconnectIcon />
          </S.Disconnect>
        </Footer>
      }
      url={history.createHref({
        search: `${founderIdQueryParam}=${id}`,
        pathname: Links.FOUNDER_PUBLIC_PROFILE_DETAILS(),
      })}
      disabled={!canBePreviewed}
    />
  );
};

import styled from 'styled-components';

const Wrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  border: ${({ theme }) => `1px solid ${theme.color.background}`};
  border-radius: ${({ theme }) => theme.radius.medium};
  background: ${({ theme }) => theme.color.sectionBackground};
  padding: ${({ theme }) => theme.spacing.medium};
`;

export default { Wrapper };

import { V2AriaTable } from '@/components/blocks/v2-aria-table/v2-aria-table';
import { V2TableStateInfo } from '@/components/ui/v2-table-state-info/v2-table-state-info';
import { TableRowCollapsible } from '@/modules/founder/connections/components/connections/table-parts/row-collapsible';
import { TranslationText } from '@/parts/translation-text/translation-text';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import type { Sorter } from '@/utils/type-definitions/filters';
import { useState, type Dispatch, type SetStateAction } from 'react';
import type { AdvisorFounderConnection } from '../../types';
import { ChatButtonCell } from './table-parts/chat-button-cell';
import { ComingSoonCell } from './table-parts/coming-soon-cell';
import { IndustryCell } from './table-parts/industry-cell';
import { InvestmentStageCell } from './table-parts/investment-stage-cell';
import { MoreActionsCell } from './table-parts/more-actions-cell';
import { NameAndDetailsCell } from './table-parts/name-and-details-cell';
import { NotesButtonCell } from './table-parts/notes-button-cell';
import { DateCell } from './table-parts/date-cell';

type AdvisorConnectionsTableProps = {
  data: AdvisorFounderConnection[];
  sorting?: Sorter;
  setSorting: Dispatch<SetStateAction<Sorter | undefined>>;
  refetch: () => void;
};

export const AdvisorConnectionsTable = ({ data, sorting, setSorting, refetch }: AdvisorConnectionsTableProps) => {
  const [nameTranslation, dateTranslation, stageTranslation, industryTranslation, actionsTranslation] = useTranslation([
    'connections.advisor.table.name',
    'connections.advisor.table.date',
    'connections.advisor.table.stage',
    'connections.advisor.table.industry',
    'connections.advisor.table.actions',
  ]);

  const [isVisibleMap, setIsVisibleMap] = useState(() => {
    const initialVisibility: Record<string, boolean> = {};
    data.forEach((row) => (initialVisibility[row.connectionId] = false));
    return initialVisibility;
  });

  const toggleVisibility = (id: string) => setIsVisibleMap((prev) => ({ ...prev, [id]: !prev[id] }));

  const handleSort = (id: string) => {
    setSorting((prevSorting) => {
      if (!prevSorting || prevSorting.key !== id) return { key: id, order: 'Asc' };
      else if (prevSorting.order === 'Asc') return { key: id, order: 'Desc' };
      else return undefined;
    });
  };

  return (
    <V2AriaTable
      sorting={sorting}
      gridTemplateColumns="0.27fr 0.15fr 0.18fr 0.25fr 0.14fr 0.16fr 0.16fr 0.05fr"
      head={
        <V2AriaTable.HeadRow>
          <V2AriaTable.HeadCell id="businessName" onSort={() => handleSort('businessName')}>
            {nameTranslation}
          </V2AriaTable.HeadCell>
          <V2AriaTable.HeadCell id="date">{dateTranslation}</V2AriaTable.HeadCell>
          <V2AriaTable.HeadCell id="stage">{stageTranslation}</V2AriaTable.HeadCell>
          <V2AriaTable.HeadCell id="industry">{industryTranslation}</V2AriaTable.HeadCell>
          <V2AriaTable.HeadCell id="soon">{actionsTranslation}</V2AriaTable.HeadCell>
          <V2AriaTable.HeadCell id="notes" />
          <V2AriaTable.HeadCell id="chat" />
          <V2AriaTable.HeadCell id="more" />
        </V2AriaTable.HeadRow>
      }
      body={
        data.length === 0 ? (
          <V2AriaTable.BodyRow key={'empty'} customGridTemplateColumns="1fr">
            <V2TableStateInfo
              spaceTop
              showIcon
              title={<TranslationText id="connections.advisor.table.connections.empty.title" />}
              description={<TranslationText id="connections.advisor.table.connections.empty.description" />}
            />
          </V2AriaTable.BodyRow>
        ) : (
          data.map((row, index) => (
            <V2AriaTable.RowWrapper
              key={row.connectionId}
              rowContent={
                <V2AriaTable.BodyRow key={row.connectionId}>
                  <NameAndDetailsCell {...row} />
                  <DateCell {...row} />
                  <InvestmentStageCell {...row} />
                  <IndustryCell {...row} />
                  <ComingSoonCell {...row} />
                  <NotesButtonCell
                    {...row}
                    toggleVisibility={toggleVisibility}
                    data-testid={`advisor-connection-open-notes-${index}`}
                  />
                  <ChatButtonCell {...row} />
                  <MoreActionsCell {...row} refetch={refetch} />
                </V2AriaTable.BodyRow>
              }
              collapsible={
                <TableRowCollapsible
                  onUpdated={refetch}
                  targetId={row.founderId}
                  isVisible={isVisibleMap[row.connectionId]}
                />
              }
            />
          ))
        )
      }
    />
  );
};

import { SecureLink } from '@parts/link/link';
import styled, { css } from 'styled-components';

const space = 20;

const Link = styled(SecureLink)<{ disabled: boolean }>`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'inherit')};
  text-decoration: none;

  &:visited {
    text-decoration: none;
  }
`;

const LinkWrapper = styled.div<{ disabled: boolean }>`
  background: ${({ theme }) => theme.color.cardBackground};
  border-radius: ${({ theme }) => theme.radius.default};
  border: 1px solid ${({ theme }) => theme.color.border};
  display: block;
  max-width: 100%;
  padding: ${space}px;
  text-decoration: none;
  transition: 0.3s;
  width: 100%;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ disabled, theme }) =>
    !disabled &&
    css`
      &:hover {
        border-color: ${theme.baseColors.Blue200};
      }
    `}
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.fontSize.medium};
  position: relative;
  margin-top: ${space}px;

  &::before {
    background: ${({ theme }) => theme.color.border};
    content: '';
    display: block;
    height: 1px;
    margin-left: -${space}px;
    position: absolute;
    top: -${space}px;
    width: calc(100% + ${2 * space}px);
  }
`;

const Desc = styled.p`
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.fontPrimary};
`;

const ImgHolder = styled.div`
  padding: 20px 10px;
  text-align: center;

  svg,
  img {
    width: auto;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    padding: 0;

    svg,
    img {
      max-height: 100px;
    }
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.xsmall}) {
    svg,
    img {
      max-height: 80px;
    }
  }
`;

const BadgeWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
`;

export default { LinkWrapper, Link, Title, Desc, ImgHolder, BadgeWrapper };

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export interface RoleSearchOptionsResponseData {
  locations: string[];
  skillSets: string[];
  roleTypes: string[];
  compensationTypes: string[];
  engagementTypes: string[];
  productStages: string[];
}

export const GET_ROLE_SEARCH_OPTIONS_CACHE_KEY = 'get-role-search-options';

export const getRoleSearchOptionsAction = async (): Promise<GraphqlResponse<RoleSearchOptionsResponseData>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
    query getRoleSearchOptions {
      locations
      skillSets
      roleTypes
      engagementTypes
      compensationTypes
      productStages
    }
    `,
  });

  return graphqlResponseWrapper(data, [
    'locations',
    'skillSets',
    'roleTypes',
    'compensationTypes',
    'engagementTypes',
    'productStages',
  ]);
};

import { finishMetricPlaygroundAction } from '@pages/content/onboarding/founder/api/finish-metric-playground.action';
import { PlaygroundBox } from '@pages/content/onboarding/founder/content/performance/manual-metrics/parts/playground-box/playground-box';
import { Description } from '@pages/content/onboarding/founder/content/performance/parts/description/description';
import type { StepContentProps } from '@pages/content/onboarding/founder/paths.config';
import Title from '@pages/content/onboarding/parts/title/title';
import { useMutation } from '@tanstack/react-query';
import { useListEntryRevealAnimation } from '@utils/hooks/use-list-reveal-animation/use-list-reveal-animation';
import { useFounderOnboardingContext } from '@utils/hooks/use-onboarding-context/use-founder-onboarding-context';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useUserCurrency } from '@utils/hooks/use-user-currency/use-user-currency';
import S from './manual-metrics.styles';

const LIST_ANIMATION_ELEMENT_ID = 'manual-metrics-list-animation-id';

export const ManualMetrics = ({ currentPage, endPage }: StepContentProps) => {
  const { currencySymbol } = useUserCurrency();
  useListEntryRevealAnimation(LIST_ANIMATION_ELEMENT_ID);
  const [title, descriptionBody] = useTranslation([
    'founder-onboarding.manualMetrics.title',
    'founder-onboarding.manualMetrics.description.body',
  ]);

  const {
    state: { playgroundFinished },
  } = useFounderOnboardingContext();
  const { mutateAsync: finishMetricPlayground } = useMutation(finishMetricPlaygroundAction);

  const markAsPlayed = () => {
    if (playgroundFinished) {
      return;
    }

    finishMetricPlayground();
  };

  return (
    <S.Wrapper>
      <Title title={title} currentPage={currentPage} endPage={endPage} />
      <div>
        <S.DescriptionWrapper data-animation-id={LIST_ANIMATION_ELEMENT_ID}>
          <Description content={descriptionBody} />
        </S.DescriptionWrapper>
        <div data-animation-id={LIST_ANIMATION_ELEMENT_ID}>
          <PlaygroundBox onPlayed={markAsPlayed} currencySymbol={currencySymbol} />
        </div>
      </div>
    </S.Wrapper>
  );
};

import {
  GET_COUNTRIES_USED_BY_INVESTORS_CACHE_KEY,
  getCountriesUsedByInvestorsAction,
} from '@pages/content/lens/api/get-countries-used-by-investors.action';
import { applySearchToQuery, type Search } from '@pages/content/lens/utils/apply-filters-to-query';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { filterWithAllowed } from '@utils/fns/filter-with-allowed';
import { safeParseJson } from '@utils/fns/safe-parse-json';
import { useAvailableCountriesAndRegions } from '@utils/hooks/use-available-countries-and-regions/use-available-countries-and-regions';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import { RegionValue } from '@utils/type-definitions/regions-values';
import { InvestorFiltersForm } from './parts/form';

import S from './parts/form.styles';

interface CountriesAndRegionsPayload {
  countriesOfResidence: CountryCodes[];
  regions: RegionValue[];
}

export const InvestorsFiltering = () => {
  const history = useHistory();
  const { page, countriesOfResidence, regions, ...restQueryParams } = useQueryParams();
  const queryCountriesOfResidence = safeParseJson<string[]>(countriesOfResidence as string, []);
  const queryRegions = safeParseJson<string[]>(regions as string, []);

  const { availableCountries, availableRegions, notUsedCountries, isLoaded } = useAvailableCountriesAndRegions(
    GET_COUNTRIES_USED_BY_INVESTORS_CACHE_KEY,
    getCountriesUsedByInvestorsAction,
  );

  const initialValidRegions = filterWithAllowed<RegionValue>((queryRegions as RegionValue[]) ?? [], availableRegions);
  const initialValidCountriesOfResidence = filterWithAllowed<CountryCodes>(
    (queryCountriesOfResidence as CountryCodes[]) ?? [],
    availableCountries,
  );

  const handleSubmit = (formValues: CountriesAndRegionsPayload) => {
    applySearchToQuery({
      history,
      initialQuery: restQueryParams,
      search: {
        page: 1,
        ...(formValues.regions?.length ? { regions: formValues.regions } : {}),
        ...(formValues.countriesOfResidence?.length ? { countriesOfResidence: formValues.countriesOfResidence } : {}),
      } as unknown as Search,
    });
  };

  if (!isLoaded) {
    return <FullHeightSpinner small />;
  }

  return (
    <S.Section>
      <InvestorFiltersForm
        onSubmit={handleSubmit}
        initialValues={{
          regions: initialValidRegions,
          countriesOfResidence: initialValidCountriesOfResidence,
        }}
        availableCountries={availableCountries}
        availableRegions={availableRegions}
        notUsedCountries={notUsedCountries}
      />
    </S.Section>
  );
};

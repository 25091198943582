import { TranslationText } from '@parts/translation-text/translation-text';
import { V2Button } from '@ui/v2-button/v2-button';
import { V2Icon } from '@ui/v2-icon/v2-icon';
import { V2ProgressBar } from '@ui/v2-progress-bar/v2-progress-bar';
import type { CSSProperties } from 'react';
import React, { useMemo } from 'react';

import S from './v2-modal.styles';

type ModalSize = 'sm' | 'md' | 'lg';

const modalWidth: Record<ModalSize, number> = {
  sm: 320,
  md: 700,
  lg: 840,
};

type V2ModalProps = {
  isOpen: boolean;
  title?: React.ReactNode;
  onCancel?: () => void;
  onConfirm?: () => void;
  closable?: boolean;
  footer?: React.ReactNode;
  cancelText?: string;
  confirmText?: string;
  size?: ModalSize;
  backdropStyle?: CSSProperties;
  progress?: number;
  'data-testid'?: string;
  className?: string;
  centered?: boolean;
  maskCollapse?: boolean;
};

export const V2Modal: React.FC<V2ModalProps> = ({
  children,
  closable,
  isOpen,
  title,
  onConfirm = () => {},
  onCancel = () => {},
  cancelText,
  confirmText,
  backdropStyle,
  footer,
  size = 'md',
  progress,
  className,
  maskCollapse = true,
  centered = false,
  'data-testid': testId = 'v2-modal',
}) => {
  const modalFooter = useMemo(() => {
    if (footer === null) return null;
    if (footer !== undefined) return footer;
    return (
      <S.ModalFooter>
        <V2Button data-testid={testId + '-footer-cancel'} onClick={onCancel} size="small" variant="outlined">
          {cancelText ?? <TranslationText id="global.cancel" />}
        </V2Button>
        <V2Button data-testid={testId + '-footer-confirm'} onClick={onConfirm} size="small" variant="primary">
          {confirmText ?? <TranslationText id="global.confirm" />}
        </V2Button>
      </S.ModalFooter>
    );
  }, [cancelText, confirmText, footer, onCancel, onConfirm, testId]);

  return (
    <S.V2Modal
      maskClosable={maskCollapse}
      visible={isOpen}
      title={title}
      closable={closable}
      onCancel={onCancel}
      onOk={onConfirm}
      width={modalWidth[size]}
      footer={modalFooter}
      closeIcon={<V2Icon name="close" size="md" />}
      maskStyle={backdropStyle}
      className={`v2-modal ${className}`}
      data-testid={testId}
      data-ui-v="2"
      destroyOnClose
      centered={centered}
    >
      <S.ContentWrapper>{children}</S.ContentWrapper>
      {typeof progress === 'number' && (
        <S.ProgressBarWrapper>
          <V2ProgressBar variant="blue" percent={progress} />
        </S.ProgressBarWrapper>
      )}
    </S.V2Modal>
  );
};

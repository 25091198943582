import { maxWysiwygChars } from '@pages/content/profile/utils/max-wysiwyg-characters';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import * as Yup from 'yup';

const MAX_CHARACTERS_LIMIT = 1000;

export const useValidationSchema = () => {
  const [formikMax] = useTranslation(['formik.validation.max']);

  return Yup.object({
    aboutMe: maxWysiwygChars(MAX_CHARACTERS_LIMIT, formikMax),
    myOffering: maxWysiwygChars(MAX_CHARACTERS_LIMIT, formikMax),
    myExpectations: maxWysiwygChars(MAX_CHARACTERS_LIMIT, formikMax),
  });
};

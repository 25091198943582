import { Select as SelectBase } from '@pages/content/profile/parts/select/select';
import { LazyDatePicker as DatePickerBase } from '@parts/date-picker/date-picker.lazy';
import { Input as InputBase } from 'antd';
import styled, { css } from 'styled-components';

const highlightElementStyle = css`
  border: 1px solid ${({ theme }) => theme.baseColors.Green} !important;

  &:hover,
  &:active,
  &:focus {
    border: 1px solid ${({ theme }) => theme.baseColors.Green} !important;
  }
`;

export const Select = styled(SelectBase)<{ $highlight: boolean }>`
  .ant-select-selector {
    ${({ $highlight }) => $highlight && highlightElementStyle}
  }
`;

export const Input = styled(InputBase)<{ $highlight: boolean }>`
  ${({ $highlight }) => $highlight && highlightElementStyle}
`;

export const TextArea = styled(InputBase.TextArea)<{ $highlight: boolean }>`
  ${({ $highlight }) => $highlight && highlightElementStyle}
`;

export const DatePicker = styled(DatePickerBase)<{ $highlight: boolean }>`
  ${({ $highlight }) => $highlight && highlightElementStyle}
`;

export default { Input, Select, DatePicker };

import type { GraphqlResponse } from '@/types';
import { graphqlResponseWrapper } from '@/utils/fns/graphql-response-wrapper';
import { axiosInstance } from '@utils/axios/axios-instance';

type FollowInvitationId = {
  followInvitationId: string;
};

export const getFollowInvitationIdFromFounderId = async (
  founderId: string,
): Promise<GraphqlResponse<FollowInvitationId>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
    query getFollowInvitationIdFromFounderId {
        getFounderPublicData(founderId:"${founderId}") {
          followInvitationId
        }
    }`,
  });

  return graphqlResponseWrapper(data, ['getFounderPublicData']);
};

export const investorDisconnectFounderAction = async (founderId: string) => {
  const res = await getFollowInvitationIdFromFounderId(founderId);

  if (res.data) {
    const { followInvitationId } = res.data;
    return axiosInstance.post(`/connections/investor/connection/${followInvitationId}/remove`);
  }

  throw new Error('Failed to disconnect founder');
};

import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  justify-content: space-between;
  display: flex;
  width: 100%;
`;

const RowMobile = styled.div<{ $mobileFullWidth?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ $mobileFullWidth }) =>
    $mobileFullWidth &&
    css`
      width: 100%;
      justify-content: flex-start;
    `}
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  word-break: break-word;
  width: 100%;
`;

const RowMobileHeader = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  color: ${({ theme }) => theme.color.fontSecondary};
  width: 140px;
  margin-right: 20px;
  flex-shrink: 0;
  text-align: left;
  word-break: normal;
`;

const RowMobileValue = styled.div<{ $mobileFullWidth?: boolean }>`
  ${({ $mobileFullWidth }) =>
    $mobileFullWidth &&
    css`
      width: 100%;
    `}
`;

export default { RowMobile, Row, RowMobileHeader, RowMobileValue, Wrapper };

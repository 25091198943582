import { Textarea as TextareaBase } from '@pages/content/profile/parts/textarea/textarea';
import styled from 'styled-components';

const Header = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin-bottom: ${({ theme }) => theme.spacing.medium};

  &:not(:first-of-type) {
    margin-top: ${({ theme }) => theme.spacing.medium};
    margin-bottom: ${({ theme }) => theme.spacing.small};
  }
`;

const Text = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.normal};
`;

const Textarea = styled(TextareaBase)`
  margin-top: ${({ theme }) => theme.spacing.medium};
`;

export default {
  Header,
  Text,
  Textarea,
};

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import { mapQueryStringToGraphqlParams } from '@utils/fns/map-query-string-to-graphql-params';
import type { ParsedQuery } from 'query-string';
import type { GraphqlResponse } from 'src/types';

export interface NedBrowseCompaniesItems {
  id: string;
  customerGroup: string[];
  description: string | null;
  industry: string[];
  investmentStage: string;
  joinedAt: string;
  name: string;
  productStage: string;
  logoUrl: string | null;
  requestIntroId: string | null;
}
export interface IGetNedBrowseCompanies {
  items: NedBrowseCompaniesItems[];
  total: number;
}

export const GET_NED_BROWSE_COMPANIES_CACHE_KEY = 'get-ned-browse-companies';

export const getNedBrowseCompanies =
  (start: number, limit: number, excludedParams: string[], otherParams?: ParsedQuery<string>) =>
  async (): Promise<GraphqlResponse<IGetNedBrowseCompanies>> => {
    const params = otherParams && mapQueryStringToGraphqlParams(otherParams, excludedParams);

    const { data } = await axiosInstance.post('/query', {
      variables: params?.variables,
      query: `
      query getNedBrowseCompanies ${params?.arguments} {
        nedBrowseCompanies(start: ${start}, limit: ${limit}${params?.supplement}) {
          items {
            id
            description
            name
            joinedAt
            logoUrl
            requestIntroId
            customerGroup
            industry
            investmentStage
            productStage
          }
          total
      }
    }`,
    });

    return graphqlResponseWrapper(data, ['nedBrowseCompanies']);
  };

import type { ReactNode } from 'react';
import S from './tile.styles';

export const GradientTile = ({
  iconSrc,
  title,
  subTitle = null,
  selected = false,
  onClick,
  className,
  disabled = false,
  dataAnimationId = '',
}: {
  iconSrc: string;
  title: ReactNode;
  subTitle?: ReactNode;
  selected?: boolean;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  dataAnimationId?: string;
}) => (
  <S.Button
    $selected={selected}
    onClick={onClick}
    data-animation-id={dataAnimationId}
    className={className}
    data-testid="investor-tile"
    disabled={disabled}
  >
    <S.Icon $small={Boolean(subTitle)} src={iconSrc} loading="lazy" />
    <S.Title>{title}</S.Title>
    {subTitle ? <S.SubTitle>{subTitle}</S.SubTitle> : null}

    {selected ? <S.Tick data-testid="tick-icon" /> : null}
  </S.Button>
);

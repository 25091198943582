import CompanyTileBase from '@pages/content/roles/parts/company-tile/company-tile';
import styled from 'styled-components';

const CounterPartTile = styled(CompanyTileBase)`
  padding: ${({ theme }) => theme.spacing.medium};
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  flex-direction: column;
  color: ${({ theme }) => theme.spacing.medium};

  > p {
    margin-left: 50px;
    margin-top: ${({ theme }) => theme.spacing.xsmall};
    color: ${({ theme }) => theme.color.fontSecondary};
  }
`;

export default { CounterPartTile };

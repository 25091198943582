import { profilePhoneNumberValidation } from '@pages/content/profile/founder/parts/editor/parts/company-details/validation-schema';
import { maxWords } from '@pages/content/profile/utils/max-words';
import { LINKEDIN_URL_REGEXP, TWITTER_URL_REGEXP } from '@utils/fns/reg-exp-validators';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import * as Yup from 'yup';

const NED_PROFILE_NAME_MAX_LENGTH = 100;

export const useValidationSchema = () => {
  const [formikRequired, formikMin, formikMax, formikPhoneNumber, linkedinUrlError, twitterUrlError] = useTranslation([
    'formik.validation.required',
    'formik.validation.min',
    'formik.validation.max',
    'formik.validation.phoneNumber',
    'formik.validation.linkedInUrl',
    'formik.validation.twitterUrl',
  ]);

  return Yup.object({
    email: Yup.string().email().required(),
    profilePhotoPath: Yup.string().nullable(),
    profilePhotoUrl: Yup.string().nullable(),
    linkedinUrl: Yup.string().matches(LINKEDIN_URL_REGEXP, linkedinUrlError).nullable(),
    twitterUrl: Yup.string().matches(TWITTER_URL_REGEXP, twitterUrlError).nullable(),
    aboutMe: maxWords(500, formikMin, formikMax),
    location: Yup.string().min(2, formikMin),
    firstName: Yup.string().min(2, formikMin).max(NED_PROFILE_NAME_MAX_LENGTH, formikMax).required(formikRequired),
    lastName: Yup.string().trim().max(NED_PROFILE_NAME_MAX_LENGTH, formikMax).required(formikRequired),
    gender: Yup.string().nullable(),
    phoneNumber: profilePhoneNumberValidation(formikPhoneNumber, formikRequired),
    resume: Yup.object({
      name: Yup.string().nullable(),
      path: Yup.string().nullable(),
    })
      .optional()
      .nullable(),
  });
};

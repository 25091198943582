import { Section } from '@parts/section/section';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Divider } from 'antd';
import { Row } from '../../../parts/row/row';
import { Tag } from '../../../parts/tag/tag';
import type { NedPublicProfileData } from '../../api/get-ned-public-page-details';
import { CompensationsTable } from './parts/compensations-table/table';

type PreferencesProps = { roles: string[]; currencySymbol: string } & Pick<
  NedPublicProfileData,
  'industries' | 'compensations' | 'primarySkillSet' | 'secondarySkillSet' | 'targetGeography'
>;

export const Preferences = ({
  industries,
  compensations,
  primarySkillSet,
  roles,
  secondarySkillSet,
  currencySymbol,
  targetGeography,
}: PreferencesProps) => {
  const [
    preferencesHeaderLabel,
    preferencesIndustryLabel,
    preferencesPreferredRoleLabel,
    preferencesPrimarySkillsetLabel,
    preferencesSecondarySkillsetLabel,
    compensationsTitle,
    targetGeographyLabel,
  ] = useTranslation([
    'profile.preview.ned.preferences.header',
    'profile.preview.ned.preferences.industry',
    'profile.preview.ned.preferences.preferredRole',
    'profile.preview.ned.preferences.primarySkillset',
    'profile.preview.ned.preferences.secondarySkillset',
    'profile.preview.ned.preferences.compensationsTitle',
    'profile.preview.ned.preferences.targetGeography',
  ]);

  const filledCompensations = (compensations ?? []).filter((item) => item.compensationType !== null);

  const showRoles = Boolean(roles?.length);
  const showIndustries = Boolean(industries?.length);
  const showPrimarySkillset = primarySkillSet && primarySkillSet?.length > 0;
  const showSecondarySkillset = Boolean(secondarySkillSet?.length);
  const showCompensations = Boolean(filledCompensations.length);
  const showTargetGeography = targetGeography && Boolean(targetGeography?.length);

  const showSection =
    showIndustries ||
    showPrimarySkillset ||
    showSecondarySkillset ||
    showRoles ||
    showCompensations ||
    showTargetGeography;

  if (!showSection) {
    return null;
  }

  return (
    <Section header={preferencesHeaderLabel}>
      {showRoles && (
        <Row
          label={preferencesPreferredRoleLabel}
          content={roles.map((role) => (
            <Tag key={role}>{role}</Tag>
          ))}
        />
      )}

      {showIndustries && <Row label={preferencesIndustryLabel} content={industries.join(', ')} />}

      {showPrimarySkillset && <Row label={preferencesPrimarySkillsetLabel} content={<Tag>{primarySkillSet}</Tag>} />}

      {showSecondarySkillset && (
        <Row
          label={preferencesSecondarySkillsetLabel}
          content={secondarySkillSet.map((skill) => (
            <Tag key={skill}>{skill}</Tag>
          ))}
        />
      )}

      {showTargetGeography && (
        <Row
          label={targetGeographyLabel}
          content={targetGeography.map((el) => (
            <Tag key={el}>{el}</Tag>
          ))}
        />
      )}

      {showCompensations && (
        <>
          <Divider />
          <Row label={compensationsTitle} content={null} />
          <CompensationsTable currencySymbol={currencySymbol} compensations={compensations} />
        </>
      )}
    </Section>
  );
};

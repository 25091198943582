import jwt from 'jsonwebtoken';

const removeStateFromToken = (token: string) => token.replace(/&state=.*$/, '');

export const checkIsTokenActive = (token: string) => {
  const formattedToken = removeStateFromToken(token as string);

  const { exp } = jwt.decode(formattedToken) as { exp: number };

  return exp > Date.now() / 1000;
};

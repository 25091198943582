export const ONBOARDING_PATH_PERFORMANCE_KEY = 'performance';

export const ONBOARDING_STEP_PERFORMANCE_DATA_DRIVEN_NETWORK = 'data-driven-network';
export const ONBOARDING_STEP_PERFORMANCE_SMART_MATCH_WITH_METRICS = 'smart-match-with-metrics';
export const ONBOARDING_STEP_PERFORMANCE_MANUAL_METRICS = 'manual-metrics';
export const ONBOARDING_STEP_PERFORMANCE_INTEGRATED_METRICS = 'integrated-metrics';
export const ONBOARDING_STEP_PERFORMANCE_TRUSTED_SENDER = 'trusted-sender';

export type FounderOnboardingPathName = typeof ONBOARDING_PATH_PERFORMANCE_KEY;

export type FounderOnboardingPathStep =
  | typeof ONBOARDING_STEP_PERFORMANCE_DATA_DRIVEN_NETWORK
  | typeof ONBOARDING_STEP_PERFORMANCE_SMART_MATCH_WITH_METRICS
  | typeof ONBOARDING_STEP_PERFORMANCE_MANUAL_METRICS
  | typeof ONBOARDING_STEP_PERFORMANCE_INTEGRATED_METRICS
  | typeof ONBOARDING_STEP_PERFORMANCE_TRUSTED_SENDER;

import { createAxiosInstance } from '@utils/axios/create-axios';
import { AxiosHeaders } from 'axios';
import { isTestRunning } from '../fns/is-test-running';
import { baseErrorInterceptor, turnstileInterceptor } from './utils/interceptors';

export const FILE_UPLOAD_TIMEOUT = 120_000;

const instance = createAxiosInstance({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: 15_000,
  timeoutErrorMessage: 'Request timeout.',
  headers: new AxiosHeaders({
    'Content-Type': 'application/json;charset=utf-8',
  }),
  skipAuthorization: false,
  withCredentials: true,
});

instance.interceptors.response.use((config) => config, turnstileInterceptor(instance));
instance.interceptors.response.use((config) => config, baseErrorInterceptor(instance));

export const axiosInstance = isTestRunning() ? createAxiosInstance({}) : instance;

import { V2Button } from '@/components/ui/v2-button/v2-button';
import styled from 'styled-components';

const SignOutButton = styled(V2Button)`
  margin-top: 25px;
  width: 100%;
  color: ${({ theme }) => theme.v2.colors.neutral[500]};
`;

export default { SignOutButton };

import { useAuth0 } from '@auth0/auth0-react';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import 'eventsource/lib/eventsource-polyfill';
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
  type FunctionComponent,
  type Reducer,
} from 'react';
import { setNotifications } from './notifications.action-creators';
import { NotificationsContext, notificationsInitialState } from './notifications.context';
import { notificationsReducer, type NotificationsActionType, type NotificationsState } from './notifications.reducer';

export const NotificationsStateProvider: FunctionComponent = ({ children }) => {
  const {
    state: { userId },
  } = useUserAccount();

  const [state, dispatch] = useReducer<Reducer<NotificationsState, NotificationsActionType>>(
    notificationsReducer,
    notificationsInitialState,
  );

  const [token, setToken] = useState<string | null>(null);

  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const eventSourceRef = useRef<EventSource | null>(null);

  const getToken = useCallback(
    async (controller: { mounted: boolean }) => {
      try {
        if (isLoading) {
          return;
        }
        const accessToken = await getAccessTokenSilently();
        if (controller.mounted) {
          setToken(accessToken);
        }
      } catch {}
    },
    [getAccessTokenSilently, isLoading, setToken],
  );

  useEffect(() => {
    const controller = { mounted: true };
    getToken(controller);
    return () => {
      controller.mounted = false;
    };
  }, [getToken]);
  useEffect(() => {
    const controller = { mounted: true };

    if (!userId) {
      return;
    }

    if (!state.subscribed && isAuthenticated && token) {
      eventSourceRef.current = new EventSourcePolyfill(`${process.env.REACT_APP_API_ENDPOINT}/notifications`, {
        headers: {
          'X-JWT': `Bearer ${token}`,
        },
      });
      eventSourceRef.current.onmessage = (event: { data: string }) => {
        const parsedData = JSON.parse(event.data);
        dispatch(setNotifications(parsedData));
      };

      eventSourceRef.current.onerror = () => {
        getToken(controller);
      };
    }
    return () => {
      controller.mounted = false;
    };
  }, [state.subscribed, userId, token, getToken]);

  const providerValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return <NotificationsContext.Provider value={providerValue}>{children}</NotificationsContext.Provider>;
};

import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import S from './info.styles';
import type { NedPublicProfileData } from '@/pages/content/public-profile/ned/api/get-ned-public-page-details';

type Props = {
  data: NedPublicProfileData;
};

const formatIndustries = (industries: string[]): string => {
  if (industries.length <= 2) {
    return industries.join(', ');
  }
  return `${industries.slice(0, 2).join(', ')}...`;
};

export const Info = ({ data }: Props) => {
  const [industryLabel, primarySkillsetLabel, roleTypeLabel, compensationLabel] = useTranslation([
    'inbox.sidePanel.industry',
    'inbox.sidePanel.primarySkillset',
    'inbox.sidePanel.roleType',
    'inbox.sidePanel.compensation',
  ]);

  return (
    <S.Wrapper>
      <p>{industryLabel}</p>
      <p>{formatIndustries(data.industries)}</p>
      <p>{primarySkillsetLabel}</p>
      <p>{data.primarySkillSet}</p>
      <p>{roleTypeLabel}</p>
      <p>{[...new Set(data.compensations.map((e) => e.role))].join(', ')}</p>
      <p>{compensationLabel}</p>
      <p>{[...new Set(data.compensations.map((e) => e.compensationType))].join(', ')}</p>
    </S.Wrapper>
  );
};

import { DataDrivenNetwork } from '@pages/content/onboarding/founder/content/performance/data-driven-network/data-driven-network';
import { IntegratedMetrics } from '@pages/content/onboarding/founder/content/performance/integrated-metrics/integrated-metrics';
import { ManualMetrics } from '@pages/content/onboarding/founder/content/performance/manual-metrics/manual-metrics';
import { SmartMatchWithMetrics } from '@pages/content/onboarding/founder/content/performance/smart-match-with-metrics/smart-match-with-metrics';
import { TrustedSender } from '@pages/content/onboarding/founder/content/performance/trusted-sender/trusted-sender';
import {
  ONBOARDING_STEP_PERFORMANCE_DATA_DRIVEN_NETWORK,
  ONBOARDING_STEP_PERFORMANCE_INTEGRATED_METRICS,
  ONBOARDING_STEP_PERFORMANCE_MANUAL_METRICS,
  ONBOARDING_STEP_PERFORMANCE_SMART_MATCH_WITH_METRICS,
  ONBOARDING_STEP_PERFORMANCE_TRUSTED_SENDER,
  type FounderOnboardingPathName,
  type FounderOnboardingPathStep,
} from '@pages/content/onboarding/founder/paths';
import type { FunctionComponent } from 'react';

export type FounderPathsConfig = Record<FounderOnboardingPathName, Step[]>;

export interface StepContentProps {
  currentPage: number;
  endPage: number;
}

export interface StepContentPropsWithoutRefetch {
  currentPage: number;
  endPage: number;
}

interface Step {
  key: FounderOnboardingPathStep;
  Content: FunctionComponent<StepContentProps>;
}

export const paths: FounderPathsConfig = {
  performance: [
    { key: ONBOARDING_STEP_PERFORMANCE_DATA_DRIVEN_NETWORK, Content: DataDrivenNetwork },
    { key: ONBOARDING_STEP_PERFORMANCE_SMART_MATCH_WITH_METRICS, Content: SmartMatchWithMetrics },
    { key: ONBOARDING_STEP_PERFORMANCE_MANUAL_METRICS, Content: ManualMetrics },
    { key: ONBOARDING_STEP_PERFORMANCE_INTEGRATED_METRICS, Content: IntegratedMetrics },
    { key: ONBOARDING_STEP_PERFORMANCE_TRUSTED_SENDER, Content: TrustedSender },
  ],
};

import {
  addInvestorOnboardingStepError,
  removeInvestorOnboardingStepError,
} from '@context/investor-onboarding/investor-onboarding.actions';
import { InvestorType, SelfCertificationType } from '@pages/content/profile/investor/api/types';
import Checkbox from '@parts/checkbox/checkbox';
import { TranslationText } from '@parts/translation-text/translation-text';
import { sleep } from '@utils/fns/sleep';
import { useListEntryRevealAnimation } from '@utils/hooks/use-list-reveal-animation/use-list-reveal-animation';
import { useInvestorOnboardingContext } from '@utils/hooks/use-onboarding-context/use-investor-onboarding-context';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import type { Any } from 'src/types';
import * as Yup from 'yup';
import { InfoTile } from '../info-tile/info-tile';
import { accreditedOptions, angelOptions, institutionalOptions } from './editor.data';
import S from './editor.styles';
import { INVESTOR_ONBOARDING_STEP_AFFIRMATIONS_KEY } from '@pages/content/onboarding/investor/paths';
import { SimpleInfoTile } from '../simple-info-tile/simple-info-tile';

export const AffirmationsEditor = ({
  data,
  onChange,
  investorType,
  disabled = false,
  isUserUS = false,
}: {
  data: {
    selfCertificationCategory: SelfCertificationType | null;
    selfCertificationTimestamp: string | null;
  };
  onChange: (payload: { certificationCategory: SelfCertificationType; confirmed: boolean }) => Promise<Any>;
  investorType: InvestorType;
  disabled?: boolean;
  isUserUS?: boolean;
}) => {
  const [
    confirmationLabel,
    noCertificationInstitutionalError,
    noCertificationAngelError,
    noCertificationAccreditedError,
    notConfirmedError,
    formikRequiredError,
  ] = useTranslation([
    'investor-onboarding-affirmations.confirmationLabel',
    'investor-onboarding.affirmations.noCertificationInstitutionalError',
    'investor-onboarding.affirmations.noCertificationAngelError',
    'investor-onboarding.affirmations.accredited.noCertificationError',
    'investor-onboarding.affirmations.notConfirmedError',
    'formik.validation.required',
  ]);

  useListEntryRevealAnimation('reveal-element');

  const { dispatch: investorOnboardingDispatch } = useInvestorOnboardingContext();

  const { selfCertificationCategory, selfCertificationTimestamp } = data ?? {
    selfCertificationCategory: null,
    selfCertificationTimestamp: null,
  };

  const formik = useFormik<{ selfCertificationCategory: SelfCertificationType | null; confirmed: boolean }>({
    initialValues: {
      confirmed: isUserUS || Boolean(selfCertificationTimestamp),
      selfCertificationCategory,
    },
    async onSubmit(v) {
      await onChange({
        confirmed: v.confirmed,
        certificationCategory: v.selfCertificationCategory!,
      });
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: Yup.object({
      selfCertificationCategory: Yup.string().required(),
      confirmed: Yup.bool().required(formikRequiredError).oneOf([true], formikRequiredError),
    }),
  });

  const noCertificationInstitutionalErrorMsg = isUserUS
    ? noCertificationAccreditedError
    : noCertificationInstitutionalError;

  const noCertificationAngelErrorMsg = isUserUS ? noCertificationAccreditedError : noCertificationAngelError;

  const handleSetStepError = () => {
    const errorCertificationId = `${INVESTOR_ONBOARDING_STEP_AFFIRMATIONS_KEY}-client-error-cert`;
    const errorConfirmId = `${INVESTOR_ONBOARDING_STEP_AFFIRMATIONS_KEY}-client-error-confirmed`;

    let errorId: string | null = null;
    let errorMessage = '';

    if (formik.values.confirmed !== true) {
      errorId = errorConfirmId;
      errorMessage = notConfirmedError;
    }

    if (!formik.values.selfCertificationCategory) {
      errorId = errorCertificationId;
      errorMessage =
        investorType === InvestorType.Institutional
          ? noCertificationInstitutionalErrorMsg
          : noCertificationAngelErrorMsg;
    }

    if (!errorId) {
      investorOnboardingDispatch(removeInvestorOnboardingStepError(errorCertificationId));
      investorOnboardingDispatch(removeInvestorOnboardingStepError(errorConfirmId));
      return;
    }

    investorOnboardingDispatch(
      addInvestorOnboardingStepError({
        id: errorId,
        step: INVESTOR_ONBOARDING_STEP_AFFIRMATIONS_KEY,
        error: errorMessage,
        disableButton: false,
      }),
    );
  };

  useEffect(() => {
    handleSetStepError();
  }, [formik.isValid, formik.values]);

  const optionsList = investorType === InvestorType.Institutional ? institutionalOptions : angelOptions;

  const shouldShowCheckbox = !isUserUS;

  const {
    title: accreditedTitle,
    description: accreditedDesc,
    secondDescription: secondAccreditedDesc,
    confirmationText,
    link: accreditedInvestorLink,
    key: accreditedKey,
    Icon: accIcon,
  } = accreditedOptions;

  const SimpleTile = (
    <SimpleInfoTile
      data-animation-id="reveal-element"
      title={accreditedTitle}
      description={accreditedDesc}
      secondDescription={secondAccreditedDesc}
      link={accreditedInvestorLink}
      onClick={async () => {
        formik.setFieldValue('selfCertificationCategory', accreditedKey);
        await sleep(1);
        await formik.submitForm();
      }}
      iconUrl={accIcon}
      selected={formik.values.selfCertificationCategory === accreditedKey}
      additionalText={confirmationText}
    />
  );

  const InfoTiles = optionsList.map(
    ({ title, description, secondDescription, bulletPoints, secondBulletPoints, Icon, showWarning, key }) => (
      <S.TilesListItem key={title} data-animation-id="reveal-element">
        <InfoTile
          investorType={investorType}
          title={<TranslationText id={title} />}
          description={description}
          secondDescription={secondDescription}
          bulletList={bulletPoints}
          secondBulletList={secondBulletPoints}
          iconUrl={Icon}
          onClick={async () => {
            formik.setFieldValue('selfCertificationCategory', key);
            await sleep(1);
            await formik.submitForm();
          }}
          selected={formik.values.selfCertificationCategory === key}
          disabled={disabled}
          showWarning={showWarning}
        />
      </S.TilesListItem>
    ),
  );

  return (
    <S.Wrapper>
      {isUserUS ? <S.TilesList>{SimpleTile}</S.TilesList> : <S.TilesList>{InfoTiles}</S.TilesList>}
      {shouldShowCheckbox ? (
        <S.CheckboxFormikField
          data-animation-id="reveal-element"
          label={{ for: 'confirmed', label: confirmationLabel }}
          error={formik.errors.confirmed}
          touched={formik.touched.confirmed}
          $accreditedInvestor={isUserUS}
        >
          <Checkbox
            id="confirmed"
            checked={formik.getFieldProps('confirmed').value}
            disabled={disabled}
            onChange={async () => {
              formik.setFieldValue('confirmed', !formik.getFieldProps('confirmed').value);
              await sleep(1);
              await formik.submitForm();
            }}
          />
        </S.CheckboxFormikField>
      ) : null}
    </S.Wrapper>
  );
};

import {
  ConvertibleLoanNotes,
  Options,
  ShareClasses,
} from '@pages/content/portfolio/investor/api/get-additional-info-select-options.action';
import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export interface ClaimedInvestment {
  entity: {
    id: string;
    avatarUrl?: string;
    name: string;
    isConnected: boolean;
  };
  investment: {
    id: string;
    shares?: number;
    round: {
      date: Date;
      type: string;
    };
    confirmed: boolean;
    archived: boolean;
    amount: number;
    shareClass?: ShareClasses;
    percentageOwnership?: number;
    terms: {
      warrants: {
        amount?: number;
        price?: number;
        expiry?: Date;
      };
      convertibleLoanNote?: ConvertibleLoanNotes;
      discount?: number;
      options: Options;
    };
  };
}

export const GET_INVESTMENT_CLAIMED_BY_FOUNDER_CACHE_KEY = 'investmentClaimedByFounder';

export const getInvestmentClaimedByFounderAction =
  (id: string) => async (): Promise<GraphqlResponse<ClaimedInvestment | null>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
      query getInvestmentClaimedByFounder {
        investmentClaimedByFounder(investmentId: "${id}") {
          entity: company {
            avatarUrl
            name
            isConnected
          }
          investment {
            id
            round {
              date
              type
            }
            confirmed
            archived
            shares
            shareClass
            percentageOwnership
            amount
            terms {
              warrants {
                amount
                price
                expiry
              }
              convertibleLoanNote
              discount
              options
            }
          }
        }
      }
    `,
    });

    return graphqlResponseWrapper(data, ['investmentClaimedByFounder']);
  };

import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './meta.styles';
import { useUserDateFormat } from '@utils/hooks/use-user-date-format/use-user-date-format';
import { DateFormat } from '@i18n/date-formats';

const row = (title: string, value: string | number) => (
  <S.Row>
    <S.Label>{title}</S.Label>
    <S.Value>{value}</S.Value>
  </S.Row>
);

export const Meta = ({
  location,
  roleType,
  compensation,
  engagement,
  experience,
  skillSet,
  postedAt,
  closesAt,
}: {
  location: string;
  roleType: string;
  compensation: string;
  engagement: string;
  experience: number;
  skillSet: string[];
  postedAt: string;
  closesAt: string;
}) => {
  const [
    locationLabel,
    roleTypeLabel,
    compensationLabel,
    engagementLabel,
    experienceLabel,
    skillsetLabel,
    placedOnLabel,
    closesAtLabel,
    year,
    years,
  ] = useTranslation([
    'roles.preview.meta.location',
    'roles.preview.meta.roleType',
    'roles.preview.meta.compensation',
    'roles.preview.meta.engagement',
    'roles.preview.meta.experience',
    'roles.preview.meta.skillset',
    'roles.preview.meta.placedOn',
    'roles.preview.meta.closes',
    'roles.preview.meta.year.single',
    'roles.preview.meta.year.plural',
  ]);

  const { dateFormatter } = useUserDateFormat();

  const experienceFormat = (experienceValue: number) => {
    if (experienceValue === 1) return `1 ${year}`;
    if (experienceValue <= 5) return `${experienceValue} ${years}`;
    return `+ 5 ${years}`;
  };

  return (
    <S.Wrapper>
      <S.Column>
        {row(locationLabel, location)}
        {row(roleTypeLabel, roleType)}
        {row(compensationLabel, compensation)}
        {row(engagementLabel, engagement)}
        {row(experienceLabel, experienceFormat(experience))}
      </S.Column>
      <S.Column>
        {row(skillsetLabel, skillSet.join(', '))}
        {row(placedOnLabel, dateFormatter(postedAt, DateFormat.SHORT_MONTH_FULL_DATE))}
        {row(closesAtLabel, dateFormatter(closesAt, DateFormat.SHORT_MONTH_FULL_DATE))}
      </S.Column>
    </S.Wrapper>
  );
};

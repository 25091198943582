import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import * as Yup from 'yup';

export const useValidationSchema = () => {
  const [maxTitleLength, maxContentLength] = useTranslation([
    'connections.founder.table.notes.tile.max.title',
    'connections.founder.table.notes.tile.max.content',
  ]);

  return Yup.object({
    title: Yup.string().required().max(15, maxTitleLength).trim().nullable(),
    content: Yup.string().required().max(160, maxContentLength).trim().nullable(),
  });
};

import { Container } from '@parts/container/container';
import styled, { css } from 'styled-components';

const Layout = styled.div`
  background-color: ${({ theme }) => theme.baseColors.Blue400};
`;

const LayoutInner = styled.div<{ showAside: boolean }>`
  width: 100%;
  padding: 0;

  ${({ showAside }) =>
    showAside &&
    css`
      @media (min-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
        display: flex;
        padding-right: 265px;

        > aside {
          width: 235px;
          min-width: 235px;
        }

        > div {
          flex: 1;
        }
      }
    `}
`;

const LayoutChildWrapper = styled(Container)`
  height: 100%;
`;

const BottomSpacer = styled.div`
  margin-top: auto;
  height: 70px;
`;

export default { Layout, LayoutInner, LayoutChildWrapper, BottomSpacer };

import { useEnforceTheme } from '@/utils/hooks/use-enforce-theme/use-enforce-theme';
import { useAuth0 } from '@auth0/auth0-react';
import { Roles } from '@domain/accounts/roles';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { Links } from '@router/links';
import { Routes } from '@router/routes';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { useAuth0Token } from '../hooks/use-auth0-token/use-auth0-token';
import RegisterWrapper from '../parts/register-wrapper/register-wrapper';
import AdvisorForm from './advisor-form/advisor-form';
import FounderForm from './founder-form/founder-form';
import InvestorForm from './investor-form/investor-form';

export type ReferralCode = {
  role: Roles.FOUNDER;
  token: string;
};
export const REFERRAL_CODE_KEY = 'referralCode';

export const RegisterFormPage = () => {
  const { role } = useParams() as { role: Roles };
  const { isAuth0Token } = useAuth0Token();
  const { referral, invitedEmail } = useQueryParams();
  const { loginWithRedirect, user, isLoading, isAuthenticated } = useAuth0();
  const history = useHistory();
  const [showForm, setShowForm] = useState(false);

  useEnforceTheme('dark');

  useEffect(() => {
    if (referral) {
      const invitationTokenValue: ReferralCode = {
        token: referral.toString(),
        role: Roles.FOUNDER,
      };
      localStorage.setItem(REFERRAL_CODE_KEY, JSON.stringify(invitationTokenValue));

      if (!isAuthenticated && !user && !isLoading) {
        loginWithRedirect({
          authorizationParams: {
            screen_hint: 'signup',
            login_hint: invitedEmail ? invitedEmail?.toString() : undefined,
          },
        });
      } else if (!isLoading) {
        history.replace(Routes.HOME);
      }
    } else if (isAuth0Token === false) {
      history.replace(Routes.HOME);
    } else {
      setShowForm(true);
    }
  }, [referral, isAuth0Token]);

  const renderForm = () => {
    switch (role) {
      case Roles.INVESTOR:
        return <InvestorForm />;
      case Roles.FOUNDER:
        return <FounderForm />;
      case Roles.NED:
        return <AdvisorForm />;
      default:
        return <Redirect to={Links.LOGIN()} />;
    }
  };

  return !showForm ? <FullHeightSpinner /> : <RegisterWrapper>{renderForm()}</RegisterWrapper>;
};

import {
  LN_QUESTION_CODE,
  NI_QUESTION_CODE,
  PSE_QUESTION_CODE,
  STF_QUESTION_CODE,
  WII_QUESTION_CODE,
  WWM_QUESTION_CODE,
  type MarketFitQuestionItem,
  type QuestionCode,
} from '@/pages/content/profile/founder/api/get-market-fit-questions/get-market-fit-questions.query';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';

export interface MarketFitQuestionWithLabel extends MarketFitQuestionItem {
  label: string;
}

export const useMarketFitQuestionsLabel = (list: MarketFitQuestionItem[]) => {
  const [stfQuestionText, pseQuestionText, wiiQuestionText, wwmQuestionText, lnQuestionText, niQuestionText] =
    useTranslation([
      `profile.founder.marketFit.question.${STF_QUESTION_CODE}`,
      `profile.founder.marketFit.question.${PSE_QUESTION_CODE}`,
      `profile.founder.marketFit.question.${WII_QUESTION_CODE}`,
      `profile.founder.marketFit.question.${WWM_QUESTION_CODE}`,
      `profile.founder.marketFit.question.${LN_QUESTION_CODE}`,
      `profile.founder.marketFit.question.${NI_QUESTION_CODE}`,
    ]);

  const questionLabels: Record<QuestionCode, string> = {
    [STF_QUESTION_CODE]: stfQuestionText,
    [PSE_QUESTION_CODE]: pseQuestionText,
    [WII_QUESTION_CODE]: wiiQuestionText,
    [WWM_QUESTION_CODE]: wwmQuestionText,
    [LN_QUESTION_CODE]: lnQuestionText,
    [NI_QUESTION_CODE]: niQuestionText,
  };

  const questionsList: MarketFitQuestionWithLabel[] = list.map((item) => ({
    ...item,
    label: questionLabels[item.questionCode] ?? '',
  }));

  return {
    questionsList,
  };
};

import { CountryCodes } from '@utils/type-definitions/iso-to-country-name';

const NO_CURRENCY_SYMBOL = '---';

const CURRENCY_SYMBOLS: Record<string, string> = {
  GBP: '£',
  USD: '$',
  EUR: '€',
};

const DECIMAL_SEPARATORS: Partial<Record<CountryCodes, string>> = {
  [CountryCodes.GB]: ',',
  [CountryCodes.US]: ',',
};

export const useCurrency = () => {
  const getCurrencySymbol = (currency: string | null) => {
    if (!currency) {
      return NO_CURRENCY_SYMBOL;
    }

    return CURRENCY_SYMBOLS[currency.toUpperCase()] ?? currency;
  };

  const getDecimalSeparator = (countryOfResidence: CountryCodes | null) => {
    if (!countryOfResidence) {
      return DECIMAL_SEPARATORS[CountryCodes.GB];
    }

    return DECIMAL_SEPARATORS[countryOfResidence] ?? DECIMAL_SEPARATORS[CountryCodes.GB];
  };

  return { getCurrencySymbol, getDecimalSeparator };
};

import type { CheckboxProps as AntdCheckboxProps } from 'antd/lib/checkbox';
import S from './checkbox.styles';

interface CheckboxProps extends AntdCheckboxProps {
  round?: boolean;
}

const Checkbox = (props: CheckboxProps) => <S.Checkbox {...props} />;
Checkbox.Group = S.CheckboxGroup;

export default Checkbox;

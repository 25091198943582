import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import { scrollToTop } from '@utils/fns/scroll-to-top';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './show-more-less-button.styles';

export const ShowMoreLessButton = ({
  isResized,
  setIsResized,
  scroll,
}: {
  isResized: boolean;
  setIsResized: Function;
  scroll?: boolean;
}) => {
  const [showMore, showLess] = useTranslation(['global.show.more', 'global.show.less']);

  return isResized ? (
    <S.ButtonWrapper>
      <S.Button
        data-testid="show-less"
        type="link"
        onClick={() => {
          setIsResized(!isResized);
          if (scroll) {
            scrollToTop();
          }
        }}
      >
        {showLess}
        <UpCircleOutlined />
      </S.Button>
    </S.ButtonWrapper>
  ) : (
    <S.ButtonWrapper>
      <S.Button type="link" data-testid="show-more" onClick={() => setIsResized(!isResized)}>
        {showMore}
        <DownCircleOutlined />
      </S.Button>
    </S.ButtonWrapper>
  );
};

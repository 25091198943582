import { useMutation, type UseMutateAsyncFunction } from '@tanstack/react-query';
import type { AxiosResponse } from '@utils/axios/types';
import useConfirmModal from '@utils/hooks/use-confirm-modal/use-confirm-modal';
import useIsMount from '@utils/hooks/use-is-mount/use-is-mount';
import { useEffect } from 'react';
import { PublishShortages } from '../parts/publish-shortages/publish-shortages';
import { Radar } from '../parts/radar/radar';

export interface PublishStatus {
  smartMatchShortages: string[];
  published: boolean;
}

export const usePublishStatusEvaluation = ({
  publishAction,
  unPublishAction,
  refetchPublishStatus,
  publishStatus,
  publishHeader,
  unPublishMessage,
  publishMessage,
  disable,
  enable,
  close,
  shortagesHeader,
  shortagesMessage,
}: {
  publishAction: UseMutateAsyncFunction<AxiosResponse<unknown>>;
  unPublishAction: UseMutateAsyncFunction<AxiosResponse<unknown>>;
  refetchPublishStatus: Function;
  publishStatus: PublishStatus;
  publishHeader: string;
  unPublishMessage: string;
  publishMessage: string;
  disable: string;
  enable: string;
  close: string;
  shortagesHeader: string;
  shortagesMessage: string;
}) => {
  const isMount = useIsMount();

  const afterPublishUnPublishConfig = {
    onSettled: () => refetchPublishStatus(),
  };

  const { mutateAsync: unPublishProfile } = useMutation(unPublishAction, afterPublishUnPublishConfig);
  const { mutateAsync: publishProfile } = useMutation(publishAction, afterPublishUnPublishConfig);

  const isPublic = publishStatus.published;

  const { modal: publishModal, show: showPublishModal } = useConfirmModal({
    title: publishHeader,
    content: isPublic ? unPublishMessage : publishMessage,
    onConfirm: (isPublic ? unPublishProfile : publishProfile) as unknown as () => void,
    icon: <Radar />,
    confirmLabel: isPublic ? disable : enable,
  });

  const { modal: shortagesModal, show: showShortagesModal } = useConfirmModal({
    title: shortagesHeader,
    content: (
      <>
        {shortagesMessage}
        <PublishShortages items={publishStatus.smartMatchShortages} />
      </>
    ),
    noCancel: true,
    confirmLabel: close,
    icon: <Radar />,
  });

  useEffect(() => {
    if (isMount) return;

    if (publishStatus.smartMatchShortages.length === 0 && !publishStatus.published) {
      // publish messages
      showPublishModal();
    }

    if (publishStatus.smartMatchShortages.length > 0 && publishStatus.published) {
      // unPublish messages
      showPublishModal();
    }
  }, [publishStatus.smartMatchShortages]);

  useEffect(() => {
    if (isMount) return;

    if (publishStatus.smartMatchShortages.length > 0 && !publishStatus.published) {
      showShortagesModal();
    }
  }, [publishStatus.published]);

  return {
    publishModal,
    shortagesModal,
    isPublic,
    showPublishModal,
    showShortagesModal,
  };
};

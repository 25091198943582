import styled from 'styled-components';

export const StyledForm = styled.form`
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    line-height: 43px !important;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  & > button:first-child {
    margin-right: ${({ theme }) => theme.spacing.small};
  }
`;

import type { Node } from '@pages/content/pulse/founder/parts/management/parts/metrics/parts/numeric-editor/parts/live-chart/live-chart';
import { ChartTimePeriod } from '@parts/periods/periods';
import { dateFromYearAndMonth } from '@utils/fns/date-from-year-and-month';
import { getBoundaryEntryTime } from '@utils/fns/get-boundary-entry-time';
import { DateTime, type DurationObject } from 'luxon';
import { useMemo } from 'react';
import { useChartSeries } from '../use-chart-series/use-chart-series';

export const useChartView = ({ nodes, period, scale }: { nodes: Node[]; period: ChartTimePeriod; scale: number }) => {
  const { series, previewSeries, sortedNodes } = useChartSeries({ nodes, scale });

  const [valueSeries, forecastSeries] = series;
  const { data: values } = valueSeries;
  const { data: forecasts } = forecastSeries;

  const [previewValueSeries, previewForecastSeries] = previewSeries;
  const { data: previewValues } = previewValueSeries;
  const { data: previewForecasts } = previewForecastSeries;

  const max = useMemo(() => {
    const newestValueTime = getBoundaryEntryTime('future', values);

    const newestForecastTime = getBoundaryEntryTime('future', forecasts);

    const applyOffset = (offset: DurationObject) => {
      if (newestForecastTime > newestValueTime) {
        if (newestValueTime) {
          return DateTime.fromMillis(newestValueTime).plus(offset);
        }

        return DateTime.fromMillis(newestForecastTime);
      }

      return DateTime.fromMillis(newestForecastTime || newestValueTime);
    };

    return {
      [ChartTimePeriod.THREE_MONTHS]: applyOffset({ months: 1 }),
      [ChartTimePeriod.SIX_MONTHS]: applyOffset({ months: 1 }),
      [ChartTimePeriod.ONE_YEAR]: applyOffset({ months: 2 }),
      [ChartTimePeriod.YEAR_TO_DATE]: DateTime.local(),
      [ChartTimePeriod.THREE_YEARS]: applyOffset({ months: 6 }),
      [ChartTimePeriod.MAX]: DateTime.fromMillis(Math.max(newestForecastTime, newestValueTime)),
    }[period];
  }, [values, forecasts, period]);

  const min = useMemo(() => {
    const computeMin = (config: DurationObject) => max.minus(config).toMillis();

    const oldestValue = getBoundaryEntryTime('past', values);

    const oldestForecast = getBoundaryEntryTime('past', forecasts);

    return {
      [ChartTimePeriod.THREE_MONTHS]: computeMin({ months: 3 }),
      [ChartTimePeriod.SIX_MONTHS]: computeMin({ months: 6 }),
      [ChartTimePeriod.ONE_YEAR]: computeMin({ months: 12 }),
      [ChartTimePeriod.YEAR_TO_DATE]: computeMin({ months: 12 }),
      [ChartTimePeriod.THREE_YEARS]: computeMin({ months: 36 }),
      [ChartTimePeriod.MAX]:
        oldestValue && oldestForecast ? Math.min(oldestValue, oldestForecast) : oldestValue || oldestForecast,
    }[period];
  }, [period, values, max, forecasts]);

  const currentForecast = useMemo(() => {
    if (previewValues.length > 0) {
      const [valueDate] = previewValues[previewValues.length - 1];

      const forecast = previewForecasts.find(([forecastDate]) => forecastDate === valueDate);

      return forecast ? forecast[1] : null;
    }

    return null;
  }, [previewForecasts, previewValues]);

  const currentValue = useMemo(() => {
    if (previewValues.length > 0) {
      return previewValues[previewValues.length - 1];
    }

    return null;
  }, [previewValues]);

  const currentProgress = useMemo(() => {
    const [preLast, last] = previewValues.slice(-2);

    if (!preLast || !last) return null;

    const [, lastValue] = last;
    const [, preLastValue] = preLast;

    return Math.round((Number(lastValue) / Number(preLastValue) - 1) * 100);
  }, [previewValues]);

  const notes = useMemo(
    () => sortedNodes.map((node) => [dateFromYearAndMonth(node.year, node.month).toISO(), node.note]),
    [sortedNodes],
  );

  const currentNote = useMemo(() => {
    if (previewValues.length > 0) {
      const [valueDate] = previewValues[previewValues.length - 1];

      const note = notes.find(([noteDate]) => noteDate === valueDate);

      return note ? note[1] : null;
    }

    return null;
  }, [notes, previewValues]);

  return {
    notes,
    currentForecast,
    currentValue,
    currentProgress,
    currentNote,
    max: max.toMillis(),
    min: min,
  };
};

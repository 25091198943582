import { V2Button } from '@/components/ui/v2-button/v2-button';
import styled from 'styled-components';

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.medium};
  justify-content: center;
  width: 100%;
  max-width: 100%;
  padding: 0;
  list-style: none;

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.highResDesktop}) {
    justify-content: space-between;
  }
`;

const ListItem = styled.li`
  flex-basis: 265px;
`;

const Button = styled(V2Button)`
  margin: 0 auto;
`;

export default { List, ListItem, Button };

import { getGradientBorderStyle } from '@parts/gradient-border-box/gradient-border-box.styles';
import styled, { css } from 'styled-components';

const borderWidth = '2px';
const borderRadius = '3px';

const TabTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  position: relative;

  > div {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -45%);
  }
`;

const TabBarItem = styled.div<{ isActive: boolean }>`
  position: relative;
  border: ${borderWidth} solid transparent;
  border-radius: ${borderRadius};

  &::before {
    ${({ theme }) => getGradientBorderStyle(theme.color.border, '1px', borderRadius)}
  }

  ${({ isActive }) =>
    isActive &&
    css`
      &::after {
        content: '';
        position: absolute;
        right: 0;
        left: 0;
        bottom: -${borderWidth};
        height: ${borderWidth};
        z-index: 1;
        background-color: ${({ theme }) => theme.color.tabButtonBackground};
      }

      &::before {
        ${({ theme }) => getGradientBorderStyle(theme.gradient.neon, borderWidth, borderRadius)}
      }
    `}

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    ${({ isActive }) =>
      isActive &&
      css`
        border: ${borderWidth} solid transparent;

        &::after {
          display: none;
        }
      `}
  }
`;

const TabBar = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: ${borderWidth};
    background: ${({ theme }) => theme.gradient.neon};
    z-index: 0;
  }

  ${TabBarItem} {
    width: calc(25% - ${({ theme }) => theme.spacing.small});
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-wrap: wrap;
    margin-bottom: -${({ theme }) => theme.spacing.small};

    ${TabBarItem} {
      margin-bottom: 6px;
      width: calc(50% - 3px);
    }

    &::after {
      display: none;
    }
  }
`;

const TabButton = styled.button`
  width: 100%;
  border: 0 none;
  padding: ${({ theme }) => theme.spacing.xmedium} ${({ theme }) => theme.spacing.medium};
  font-size: ${({ theme }) => theme.fontSize.small};
  line-height: ${({ theme }) => theme.fontSize.xmedium};
  color: ${({ theme }) => theme.color.fontPrimary};
  background-color: ${({ theme }) => theme.color.tabButtonBackground};
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }
`;

export default { TabTitle, TabBar, TabBarItem, TabButton };

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import { mapQueryStringToGraphqlParams } from '@utils/fns/map-query-string-to-graphql-params';
import type { ParsedQuery } from 'query-string';
import type { GraphqlResponse } from 'src/types';

export interface NedRole {
  id: string;
  companyId: string;
  companyLogoUrl: string;
  companyName: string;
  compensation: string;
  description: string;
  engagement: string;
  location: string;
  roleTitle: string;
  roleType: string;
  isNew: boolean;
}

export interface RolesFiltersOption {
  roleTitle: string | null;
  location: string[];
  skillSet: string[];
  roleType: string[];
  engagement: string[];
  compensation: string[];
  companyStage: string[];
  onlyNewlyActivated: boolean;
}

export interface NedRoleResponse {
  items: NedRole[];
  total: number;
}

export const roleFiltersInitialValues = {
  roleTitle: null,
  location: [],
  skillSet: [],
  roleType: [],
  engagement: [],
  compensation: [],
  companyStage: [],
};

export const GET_NED_ROLES_CACHE_KEY = 'get-ned-roles';

export const getNedRolesAction =
  (start: number, limit: number, excludedParams: string[], otherParams?: ParsedQuery<string>) =>
  async (): Promise<GraphqlResponse<NedRoleResponse>> => {
    const newParams = { ...roleFiltersInitialValues, ...otherParams };

    const params = newParams && mapQueryStringToGraphqlParams(newParams, excludedParams, true);

    const { data } = await axiosInstance.post('/query', {
      query: `
      query getNedRoles {
        roleCatalogue(start: ${start}, limit: ${limit}${params?.supplement}) {
          items {
            id
            companyId
            companyLogoUrl
            companyName
            compensation
            description
            engagement
            location
            roleTitle
            roleType
            isNew
          }
          total
        }
      }
    `,
    });

    return graphqlResponseWrapper(data, ['roleCatalogue']);
  };

import { FormikRow as FormikRowBase } from '@parts/forms/formik-row/formik-row';
import { type LinkOverloaded, SecureLink as LinkBase } from '@parts/link/link';
import styled from 'styled-components';

const Link: LinkOverloaded = styled(LinkBase)`
  text-decoration: none;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.color.link};
`;

const Row = styled(FormikRowBase)`
  margin-top: ${({ theme }) => theme.spacing.medium};

  &:last-of-type {
    margin: 0;
  }
`;
export default { Link, Row };

/* eslint-disable no-nested-ternary */
import { investorCancelInviteFromFounderAction } from '@/pages/content/lens/api/investor-cancel-invite-from-founder.action';
import { investorInvitesFounderAction } from '@/pages/content/lens/api/invite-founder.action';
import type { Any } from '@/types';
import { isInvestorWaitingForApproval } from '@abilities';
import { Roles } from '@domain/accounts/roles';
import { InvestorNedLayout } from '@layout/investor-ned/investor-ned.layout';
import { InvestorLayout } from '@layout/investor/investor.layout';
import { NedLayout } from '@layout/ned/ned.layout';
import { investorBlacklistFounderAction } from '@pages/content/lens/api/blacklist-founder.action';
import { cancelRequestedIntroAction } from '@pages/content/lens/api/cancel-intro-request-to-admin';
import { engageFounderMessagingAction } from '@pages/content/lens/api/message-engage-founder';
import { sendRequestIntroAction } from '@pages/content/lens/api/send-intro-request-to-admin';
import {
  setFundingRounds,
  toggleFundingRoundsLoading,
} from '@pages/content/pulse/founder/parts/progress/parts/context/funding-rounds.action-creators';
import type { Dashboard as DashboardDetails } from '@pages/content/pulse/parts/dashboards/api/get-founder-dashboard/get-founder-dashboard.action';
import { getFoundersDashboardForViewerAction } from '@pages/content/pulse/parts/dashboards/api/get-investor-dashboard/get-investor-dashboard.action';
import {
  GET_FOUNDER_DASHBOARDS_FOR_VIEWER_QUERY_CACHE_KEY,
  getFoundersDashboardsForViewer,
} from '@pages/content/pulse/parts/dashboards/api/get-investor-dashboards.ts/get-investor-dashboards';
import { InvestorDashboards } from '@pages/content/pulse/parts/dashboards/investor-dashboards.layout';
import { dashboardIdParam } from '@pages/content/pulse/parts/dashboards/parts/dashboards-menu/dashboards-menu';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import notification from '@parts/message/message';
import { PageHeader } from '@parts/page-header/page-header';
import { SubmitButton } from '@parts/submit-button/submit-button';
import { Routes } from '@router/routes';
import { publicProfileFounderDashboardsKey, publicProfileFounderDetailsKey } from '@router/tabs-keys';
import { useMutation, useQuery, type MutationFunction } from '@tanstack/react-query';
import type { AxiosResponse } from '@utils/axios/types';
import { scrollToTop } from '@utils/fns/scroll-to-top';
import { useFundingRoundsState } from '@utils/hooks/use-funding-rounds-state/use-funding-rounds-state';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { useEffect } from 'react';
import { advisorAcceptConnections } from '../../lens/api/advisor-accept-connections';
import { advisorDeclineConnections } from '../../lens/api/advisor-decline-connections';
import { advisorRemoveConnections } from '../../lens/api/advisor-remove-connections';
import { investorAcceptInviteFromFounderAction } from '../../lens/api/investor-accept-invite-from-founder.action';
import { investorDeclineInvitationFromFounderAction } from '../../lens/api/investor-decline-invitation-from-founder.action';
import { investorDisconnectFounderAction } from '../../pulse/api/disconnect-founder/disconnect-founder.action';
import { Badge } from '../parts/badge/badge';
import { ConnectionManager } from '../parts/connection-manager/connection-manager';
import { GET_COMPANY_PREVIEW_CACHE_KEY, getCompanyPreview } from './api/get-company-preview.actions';
import {
  GET_FOUNDER_PUBLIC_PAGE_DETAILS_CACHE_KEY,
  getFounderPublicProfileDetailsAction,
} from './api/get-founder-public-profile.action';
import { Overview } from './parts/overview/overview';

import S from './founder.page.styles';

export const founderIdQueryParam = 'id';

const Content = ({ Layout = InvestorLayout }: { Layout?: Function }) => {
  const history = useHistory();
  const { [dashboardIdParam]: dashboardId } = useQueryParams() as {
    [dashboardIdParam]: string;
  };

  const {
    state: { userRole, products },
  } = useUserAccount();

  const investorIsWaitingForApproval = isInvestorWaitingForApproval(products);

  const { [founderIdQueryParam]: id } = useQueryParams() as {
    [founderIdQueryParam]: string;
  };

  const [
    detailsLabel,
    requestIntroLabel,
    sentRequestLabel,
    requestSentLabel,
    cancelLabel,
    portfolioLabel,
    dashboardLabel,
    badgeNewText,
  ] = useTranslation([
    'profile.preview.founder.details',
    'profile.preview.founder.requestIntro',
    'lens.requestIntro.requestSend',
    'profile.preview.founder.requestSent',
    'profile.preview.founder.cancel',
    'portfolio.portfolio',
    'profile.preview.founder.dashboards',
    'profile.badgeNew',
  ]);

  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery([`${GET_FOUNDER_PUBLIC_PAGE_DETAILS_CACHE_KEY}_${id}`], getFounderPublicProfileDetailsAction(id));
  const { dispatch } = useFundingRoundsState();

  useQuery([`${GET_COMPANY_PREVIEW_CACHE_KEY}_${id}`], getCompanyPreview(id), {
    onSuccess: (resp) => {
      dispatch(setFundingRounds(resp?.data?.fundingRounds || []));
      dispatch(toggleFundingRoundsLoading(false));
    },
    refetchOnWindowFocus: false,
    cacheTime: 60000,
  });
  const { mutateAsync: sendRequestIntro, isLoading: sendRequestIntroInProgress } = useMutation(sendRequestIntroAction, {
    onSettled: () => {
      refetch();
      notification.success({ content: sentRequestLabel });
    },
  });

  const { mutateAsync: cancelRequestedIntro, isLoading: cancelRequestedIntroInProgress } = useMutation(
    cancelRequestedIntroAction,
    {
      onSettled: () => {
        refetch();
      },
    },
  );
  const { data: dashboardsResponse, isLoading: isLoadingDashboards } = useQuery(
    [GET_FOUNDER_DASHBOARDS_FOR_VIEWER_QUERY_CACHE_KEY],
    getFoundersDashboardsForViewer(id),
  );

  const { data: dashboardDetails, isLoading: dashboardDetailsIsLoading } = useQuery(
    ['get-founder-dashboard-for-viewer', dashboardId],
    getFoundersDashboardForViewerAction(dashboardId, id),
    {
      enabled: Boolean(dashboardId),
    },
  );

  if (isLoading || !response?.data || isLoadingDashboards) {
    return (
      <Layout>
        <FullHeightSpinner />
      </Layout>
    );
  }

  const {
    businessName,
    smartMatchLevel,
    followInvitationId,
    connection,
    requestIntroId,
    hasInvestingRelationship,
    isNew,
  } = response.data;

  const isFollowed = connection?.status === 'Accepted';

  const tabsOptions = [
    {
      key: publicProfileFounderDetailsKey,
      label: detailsLabel,
      content: <Overview data={response.data} connection={isFollowed} />,
    },

    {
      key: publicProfileFounderDashboardsKey,
      label: dashboardLabel,
      content: (
        <InvestorDashboards
          dashboardDetails={dashboardDetails?.data as DashboardDetails}
          dashboardDetailsIsLoading={dashboardDetailsIsLoading}
          dashboards={dashboardsResponse?.data}
          allowSetNotifications={isFollowed}
          followMode
          businessName={businessName}
        />
      ),
    },
  ];

  const canConnect = response.data.canConnect;
  const isNewBadge = isNew && <Badge data-testid="badge-new">{badgeNewText}</Badge>;
  const portfolioBadge = hasInvestingRelationship && <S.PortfolioBadge>{portfolioLabel}</S.PortfolioBadge>;
  const connectionActions = () => {
    if (userRole === Roles.NED) {
      return {
        accept: advisorAcceptConnections,
        decline: advisorDeclineConnections,
        disconnect: advisorRemoveConnections,
        engageMessage: engageFounderMessagingAction,

        // NED does not use these actions
        // Need to be refactored - refactor connections manager https://exlabs.atlassian.net/browse/CON-12170
        send: (async () => {}) as unknown as MutationFunction<{ data: string }, string>,
        cancelSent: (async () => {}) as unknown as MutationFunction<{ data: string }, string>,
        blacklist: (async () => {}) as unknown as (
          counterpartId: string,
        ) => (payload: Any) => Promise<AxiosResponse<unknown>>,
      };
    }

    return {
      send: investorInvitesFounderAction,
      cancelSent: investorCancelInviteFromFounderAction,
      accept: investorAcceptInviteFromFounderAction,
      decline: investorDeclineInvitationFromFounderAction,
      disconnect: investorDisconnectFounderAction,
      blacklist: investorBlacklistFounderAction,
      engageMessage: engageFounderMessagingAction,
    };
  };

  const renderButtonBasedOnRoleAndConnection = () => {
    if (userRole === Roles.NED || (userRole === Roles.INVESTOR_NED && investorIsWaitingForApproval)) {
      if (requestIntroId) {
        return (
          <>
            <S.GreenTick />
            <S.RequestIntroPending>{requestSentLabel}</S.RequestIntroPending>
            <SubmitButton
              type="link"
              disabled={cancelRequestedIntroInProgress}
              onClick={() => cancelRequestedIntro(requestIntroId)}
            >
              {cancelLabel}
            </SubmitButton>
          </>
        );
      }

      if (!connection) {
        return (
          <SubmitButton disabled={sendRequestIntroInProgress} onClick={() => sendRequestIntro(id)}>
            {requestIntroLabel}
          </SubmitButton>
        );
      }
    }
  };

  return (
    <Layout>
      <PageHeader
        title={businessName}
        onBack={() => history.goBack()}
        subTitle={
          <>
            {isNewBadge}
            {portfolioBadge}
          </>
        }
      />
      <ConnectionManager
        canConnect={canConnect}
        counterpartId={id}
        connection={connection}
        invitationId={followInvitationId}
        name={businessName}
        smartMatchLevel={smartMatchLevel}
        refetch={refetch}
        actions={connectionActions()}
        notConnectedMessaging={userRole === Roles.INVESTOR || userRole === Roles.INVESTOR_NED}
      >
        {renderButtonBasedOnRoleAndConnection()}
      </ConnectionManager>

      <S.Tabs
        locator="mode"
        route={Routes.FOUNDER_PUBLIC_PROFILE}
        defaultLocator={publicProfileFounderDetailsKey}
        persistQueryParamsOnTabChange
        tabs={tabsOptions}
      />
    </Layout>
  );
};

export const FounderPublicPage = () => {
  const {
    state: { userRole },
  } = useUserAccount();

  useEffect(() => {
    scrollToTop();
  }, []);

  switch (userRole) {
    case Roles.INVESTOR_NED:
      return <Content Layout={InvestorNedLayout} />;
    case Roles.NED:
      return <Content Layout={NedLayout} />;
    case Roles.INVESTOR:
    default:
      return <Content />;
  }
};

import { useEnforceTheme } from '@/utils/hooks/use-enforce-theme/use-enforce-theme';
import Pattern from '@assets/illustrations/onboarding/pattern.svg';
import type { ReactNode } from 'react';
import { Footer, type FooterProps } from './parts/footer/footer';
import { Nav } from './parts/nav/nav';
import { ProgressBar } from './parts/progress-bar/progress-bar';

import S from './wizard.layout.styles';

interface WizardLayoutProps {
  children: ReactNode;
  withFooter?: boolean;
  currentStep?: number;
  totalSteps?: number;
  footerProps: FooterProps;
  centerContent?: boolean;
  finishLater?: boolean;
  onFinishLater?: () => void;
}

export const WizardLayout = ({
  children,
  withFooter = true,
  currentStep,
  totalSteps,
  footerProps,
  centerContent = false,
  finishLater = false,
  onFinishLater = () => {},
}: WizardLayoutProps) => {
  useEnforceTheme('dark');
  const showProgress = currentStep && totalSteps;

  return (
    <S.Wrapper>
      <S.TopRightPattern src={Pattern} />
      <S.BottomLeftPattern src={Pattern} $withFooter={withFooter} />
      {showProgress ? <ProgressBar currentStep={currentStep} totalSteps={totalSteps} /> : null}

      <Nav finishLater={finishLater} onFinishLater={onFinishLater} />
      <S.Content $centerContent={centerContent}>{children}</S.Content>
      {withFooter ? <Footer {...footerProps} /> : null}
    </S.Wrapper>
  );
};

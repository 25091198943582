import SearchIcon from '@assets/icons/search.svg';
import { type LastSearch, type MetricOptions } from '@pages/content/lens/api/get-browse-companies-filters';
import S from '@pages/content/lens/parts/filters/filters.styles';
import type { SmartMatchOptionsResponseData } from '@pages/content/profile/api/get-smart-match-options/get-smart-match-options.action';
import { FormikField } from '@pages/content/profile/parts/formik-field/formik-field';
import { Tooltip } from '@parts/tooltip/tooltip';
import Checkbox from '@parts/checkbox/checkbox';
import { CountriesRegionsSelect } from '@parts/countries-regions-select/countries-regions-select';
import { sortArrayAlphabetically } from '@utils/fns/sortArrayAlphabetically';
import { useAvailableCountriesAndRegions } from '@utils/hooks/use-available-countries-and-regions/use-available-countries-and-regions';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { CountryCodes } from '@utils/type-definitions/iso-to-country-name';
import { RegionNamesMap, RegionValue } from '@utils/type-definitions/regions-values';
import useUserAccount from '@utils/hooks/use-user-account/use-user-account';
import { Col, Row } from 'antd';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import type { SelectValue } from 'antd/lib/select';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { MetricFilter, initialMetricTypeFilter } from '../metric-filter/metric-filter';
import { InvestorLensSortSelect } from '../sort-select/sort-select';
import { SubmitButton as Apply } from '@parts/submit-button/submit-button';
import type { FiltersSubmitFn } from './hooks/use-parse-and-search';
import { useValidationSchema } from './validation-schema';
import {
  GET_COUNTRIES_USED_BY_FOUNDERS_CACHE_KEY,
  getCountriesUsedByFoundersAction,
} from '@pages/content/lens/api/get-countries-used-by-founders.action';

export const namePhraseKey = 'namePhrase';

export interface FilterOptions extends Omit<LastSearch, 'metrics'> {
  metrics: Omit<MetricOptions, 'step'>[] | [];
  firstMetricTypeFilter: Omit<MetricOptions, 'step'>;
  secondMetricTypeFilter: Omit<MetricOptions, 'step'>;
  thirdMetricTypeFilter: Omit<MetricOptions, 'step'>;
  hasEisLimitsRemaining: boolean;
  hasSeisLimitsRemaining: boolean;
  onlyNewlyRegistered: boolean;
  countriesOfResidence: CountryCodes[];
  regions: RegionValue[];
}

export const Filters = ({
  smartMatchOptions,
  availableMetrics = [],
  initialFilterOptions,
  onSubmit,
}: {
  smartMatchOptions: SmartMatchOptionsResponseData;
  availableMetrics?: MetricOptions[];
  initialFilterOptions?: Partial<FilterOptions>;
  onSubmit: FiltersSubmitFn;
}) => {
  const [
    apply,
    industryLabel,
    countriesAndRegionsLabel,
    selectPlaceholder,
    customerGroupLabel,
    productStageLabel,
    investmentStageLabel,
    namePhraseLabel,
    namePhrasePlaceholderLabel,
    metricsLabel,
    seisLabel,
    eisLabel,
    newOnlyLabel,
    newOnlyTooltip,
    sortSelectPrefixLabel,
  ] = useTranslation([
    'lens.filters.apply',
    'lens.filters.industry',
    'lens.filters.countriesAndRegions',
    'lens.filters.selectPlaceholder',
    'lens.filters.customerGroup',
    'lens.filters.productStage',
    'lens.filters.investmentStage',
    'lens.filters.namePhrase',
    'lens.filters.namePhrase.placeholder',
    'lens.filters.metrics',
    'lens.filters.seis',
    'lens.filters.eis',
    'lens.filters.newOnlyLabel',
    'lens.filters.newOnlyTooltip',
    'lens.filters.sortSelect.prefixLabel',
  ]);

  const {
    helpers: { isFrom },
  } = useUserAccount();

  const shouldShowSeisEis = isFrom(CountryCodes.GB);

  const {
    industries: possibleIndustries,
    customerGroups: possibleCustomerGroups,
    productStages: possibleProductStages,
    investmentStages: possibleInvestmentStage,
  } = smartMatchOptions;

  const { submitForm, setFieldValue, setFieldTouched, errors, isValid, touched, getFieldProps, values } =
    useFormik<FilterOptions>({
      initialValues: {
        namePhrase: '',
        industry: [],
        customerGroup: [],
        productStage: [],
        investmentStage: [],
        metrics: [],
        firstMetricTypeFilter: initialMetricTypeFilter,
        secondMetricTypeFilter: initialMetricTypeFilter,
        thirdMetricTypeFilter: initialMetricTypeFilter,
        hasEisLimitsRemaining: false,
        hasSeisLimitsRemaining: false,
        onlyNewlyRegistered: false,
        sort: null,
        countriesOfResidence: [],
        regions: [],
        ...(initialFilterOptions ?? {}),
      },
      onSubmit,
      enableReinitialize: false,
      validateOnMount: true,
      validationSchema: useValidationSchema(),
    });

  useEffect(() => {
    setFieldValue(
      'metrics',
      [
        { ...values.firstMetricTypeFilter },
        { ...values.secondMetricTypeFilter },
        { ...values.thirdMetricTypeFilter },
      ].filter((filter) => Object.keys(filter).length && filter.code !== ''),
    );
  }, [values.firstMetricTypeFilter, values.secondMetricTypeFilter, values.thirdMetricTypeFilter]);

  const handleSelectChange = (value: SelectValue, field: string) => {
    setFieldValue(field, value);
    setFieldTouched(field, true);
  };

  const namePhraseProps = getFieldProps(namePhraseKey);

  const { availableCountries, availableRegions, notUsedCountries } = useAvailableCountriesAndRegions(
    GET_COUNTRIES_USED_BY_FOUNDERS_CACHE_KEY,
    getCountriesUsedByFoundersAction('INVESTOR'),
  );

  const handleSelectCountryRegionChange = (countriesKey: string, regionsKey: string) => (value: SelectValue) => {
    const eventValues = Array.isArray(value) ? value : [];

    const countriesData = eventValues.filter((item) => Object.values(CountryCodes).includes(item as CountryCodes));
    const regionsData = eventValues.filter((item) => Object.keys(RegionNamesMap).includes(item as RegionValue));

    setFieldValue(countriesKey, countriesData);
    setFieldTouched(countriesKey, true);

    setFieldValue(regionsKey, regionsData);
    setFieldTouched(regionsKey, true);
  };
  return (
    <>
      <S.Section>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={8}>
            <FormikField
              label={{
                for: namePhraseKey,
                label: namePhraseLabel,
              }}
            >
              <S.Input
                {...namePhraseProps}
                placeholder={namePhrasePlaceholderLabel}
                data-testid="browse-companies-company-name"
                prefix={<img src={SearchIcon} alt="search" loading="lazy" />}
                onChange={(e) => setFieldValue(namePhraseKey, e.target.value)}
                value={values.namePhrase || undefined}
              />
            </FormikField>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <FormikField
              label={{
                for: 'industry',
                label: industryLabel,
              }}
              error={errors.industry ? (errors.industry as string) : undefined}
              touched={touched.industry}
            >
              <S.MultiSelectSearch
                placeholder={selectPlaceholder}
                {...getFieldProps('industry')}
                options={sortArrayAlphabetically(possibleIndustries)}
                onChange={(value: SelectValue) => handleSelectChange(value, 'industry')}
                maxTagCount="responsive"
              />
            </FormikField>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <FormikField
              label={{
                for: 'countriesAndRegions',
                label: countriesAndRegionsLabel,
              }}
            >
              <CountriesRegionsSelect
                id="countriesAndRegions"
                onChange={handleSelectCountryRegionChange('countriesOfResidence', 'regions')}
                regions={availableRegions}
                countriesOfResidence={availableCountries}
                notUsedCountries={notUsedCountries}
                value={[...values.countriesOfResidence, ...values.regions]}
              />
            </FormikField>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={8}>
            <FormikField
              label={{
                for: 'customerGroup',
                label: customerGroupLabel,
              }}
              error={errors.customerGroup ? (errors.customerGroup as string) : undefined}
              touched={touched.customerGroup}
            >
              <S.MultiSelectSearch
                placeholder={selectPlaceholder}
                {...getFieldProps('customerGroup')}
                options={possibleCustomerGroups}
                onChange={(value: SelectValue) => handleSelectChange(value, 'customerGroup')}
                maxTagCount="responsive"
              />
            </FormikField>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <FormikField
              label={{
                for: 'productStage',
                label: productStageLabel,
              }}
              touched={touched.productStage}
            >
              <S.MultiSelectSearch
                {...getFieldProps('productStage')}
                onChange={(value: SelectValue) => handleSelectChange(value, 'productStage')}
                placeholder={selectPlaceholder}
                options={possibleProductStages}
                maxTagCount="responsive"
              />
            </FormikField>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <FormikField
              label={{
                for: 'investmentStage',
                label: investmentStageLabel,
              }}
              touched={touched.investmentStage}
            >
              <S.MultiSelectSearch
                {...getFieldProps('investmentStage')}
                onChange={(value: SelectValue) => handleSelectChange(value, 'investmentStage')}
                placeholder={selectPlaceholder}
                options={possibleInvestmentStage}
                maxTagCount="responsive"
              />
            </FormikField>
          </Col>
        </Row>
        <Row gutter={[24, 0]} align="middle">
          {shouldShowSeisEis && (
            <>
              <Col xs={12} sm={12} md={3} xl={2}>
                <S.CheckboxFormikField
                  label={{
                    for: 'hasSeisLimitsRemaining',
                    label: seisLabel,
                  }}
                  error={errors.hasSeisLimitsRemaining}
                  touched={touched.hasSeisLimitsRemaining}
                  data-testid="filters-seis-checkbox"
                >
                  <Checkbox
                    {...getFieldProps('hasSeisLimitsRemaining')}
                    onChange={(e: CheckboxChangeEvent) => {
                      setFieldValue('hasSeisLimitsRemaining', e.target.checked);
                      setFieldTouched('hasSeisLimitsRemaining', true);
                    }}
                    checked={values.hasSeisLimitsRemaining}
                    id="hasSeisLimitsRemaining"
                  />
                </S.CheckboxFormikField>
              </Col>
              <Col xs={12} sm={12} md={3} xl={2}>
                <S.CheckboxFormikField
                  label={{
                    for: 'hasEisLimitsRemaining',
                    label: eisLabel,
                  }}
                  error={errors.hasEisLimitsRemaining}
                  touched={touched.hasEisLimitsRemaining}
                >
                  <Checkbox
                    {...getFieldProps('hasEisLimitsRemaining')}
                    onChange={(e: CheckboxChangeEvent) => {
                      setFieldValue('hasEisLimitsRemaining', e.target.checked);
                      setFieldTouched('hasEisLimitsRemaining', true);
                    }}
                    checked={values.hasEisLimitsRemaining}
                    id="hasEisLimitsRemaining"
                    data-testid="filters-eis-checkbox"
                  />
                </S.CheckboxFormikField>
              </Col>
            </>
          )}
          <Col xs={24} sm={12} md={8}>
            <S.CheckboxFormikField
              label={{
                for: 'onlyNewlyRegistered',
                label: (
                  <Row>
                    <Col>{newOnlyLabel}</Col>
                    <Col>
                      <Tooltip title={newOnlyTooltip} />
                    </Col>
                  </Row>
                ),
              }}
              error={errors.onlyNewlyRegistered}
              touched={touched.onlyNewlyRegistered}
              data-testid="filters-show-only-new-checkbox"
            >
              <Checkbox
                {...getFieldProps('onlyNewlyRegistered')}
                onChange={(e: CheckboxChangeEvent) => {
                  setFieldValue('onlyNewlyRegistered', e.target.checked);
                  setFieldTouched('onlyNewlyRegistered', true);
                }}
                checked={values.onlyNewlyRegistered}
                id="onlyNewlyRegistered"
              />
            </S.CheckboxFormikField>
          </Col>
        </Row>
        <FormikField
          label={{
            for: 'metrics',
            label: metricsLabel,
          }}
        >
          <Row gutter={[24, 0]}>
            <Col xs={24} sm={24} md={8}>
              <MetricFilter
                metricsOptions={availableMetrics || []}
                setFieldValue={setFieldValue}
                selectedFilters={[
                  values.firstMetricTypeFilter,
                  values.secondMetricTypeFilter,
                  values.thirdMetricTypeFilter,
                ]}
                lastSearchedMetric={values.firstMetricTypeFilter}
                filterKey="firstMetricTypeFilter"
              />
            </Col>
            <Col xs={24} sm={24} md={8}>
              <MetricFilter
                metricsOptions={availableMetrics || []}
                setFieldValue={setFieldValue}
                selectedFilters={[
                  values.firstMetricTypeFilter,
                  values.secondMetricTypeFilter,
                  values.thirdMetricTypeFilter,
                ]}
                lastSearchedMetric={values.secondMetricTypeFilter}
                filterKey="secondMetricTypeFilter"
              />
            </Col>
            <Col xs={24} sm={24} md={8}>
              <MetricFilter
                metricsOptions={availableMetrics || []}
                setFieldValue={setFieldValue}
                selectedFilters={[
                  values.firstMetricTypeFilter,
                  values.secondMetricTypeFilter,
                  values.thirdMetricTypeFilter,
                ]}
                lastSearchedMetric={values.thirdMetricTypeFilter}
                filterKey="thirdMetricTypeFilter"
              />
            </Col>
          </Row>
        </FormikField>
        <Row>
          <Apply onClick={submitForm} disabled={!isValid}>
            {apply}
          </Apply>
        </Row>
      </S.Section>

      <InvestorLensSortSelect
        {...getFieldProps('sort')}
        value={values.sort || undefined}
        label={sortSelectPrefixLabel}
        onChange={(v: SelectValue) => {
          handleSelectChange(v ?? null, 'sort');
          submitForm();
        }}
      />
    </>
  );
};

import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
  position: relative;
  grid-auto-rows: 1fr;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    grid-template-columns: minmax(0, 1fr);
    gap: 0;
  }
`;

export default {
  Wrapper,
};

import styled from 'styled-components';
import { Action } from '../action/action';

const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.medium};
`;

const Row = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.spacing.medium};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-wrap: wrap;
  }
`;

const Description = styled.p``;

const Label = styled.label`
  flex-basis: 100px;
  flex-shrink: 0;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex-basis: 100%;
    margin-bottom: ${({ theme }) => theme.spacing.small};
  }
`;

const Download = styled(Action)`
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  font-size: ${({ theme }) => theme.fontSize.regular};
`;

const Content = styled.div`
  width: 100%;
  margin-right: ${({ theme }) => theme.spacing.base};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    margin-right: 0;
  }
`;

export default { Wrapper, Row, Label, Content, Download, Description };

import {
  RoleManagementContext,
  type RoleManagementStateApi,
} from '@pages/content/roles/management/context/management.context';
import { useContext } from 'react';

export const useRoleManagement = () => {
  const context = useContext(RoleManagementContext) as RoleManagementStateApi;

  if (!context) {
    throw new Error('useRoleManagement should be used within RoleManagementContext.');
  }

  return context;
};

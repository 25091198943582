import MetricRibbonBase from '@assets/icons/metric-ribbon.svg?react';
import { Checkbox as CheckboxBase, Collapse, Input } from 'antd';
import styled, { css } from 'styled-components';

const SearchInput = styled(Input)`
  height: 32px;
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  margin-block: 10px;

  .ant-input {
    height: fit-content;
  }
`;

const PanelWrapper = styled.div`
  width: 270px;
  position: absolute;
  left: 0;
  height: 46px;
  top: 0;
`;

const Panel = styled(Collapse.Panel)<{ isActive?: boolean }>`
  margin: 5px 0;
  &:first-of-type {
    margin-top: 0;
  }
  &:last-of-type {
    bottom: 0;
  }

  .ant-collapse-header {
    color: ${({ theme }) => theme.color.fontPrimary} !important;
    background: ${({ theme }) => theme.color.panelBackground};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: ${({ isActive }) => (isActive ? '0' : '5px')};
    border-bottom-left-radius: ${({ isActive }) => (isActive ? '0' : '5px')};
  }
  .ant-collapse-content-box {
    background: ${({ theme }) => theme.color.panelBackground};
    border-bottom-right-radius: ${({ isActive }) => (isActive ? '5px' : '0')};
    border-bottom-left-radius: ${({ isActive }) => (isActive ? '5px' : '0')};
  }
`;
const CheckboxGroup = styled(CheckboxBase.Group)`
  .ant-checkbox-wrapper {
    margin-left: 0px;
  }

  .ant-tooltip-disabled-compatible-wrapper {
    display: block !important;
  }
`;

const Checkbox = styled(CheckboxBase)`
  color: ${({ theme }) => theme.color.primary};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.regular} !important;
  width: 100%;
  margin: 5px 0;
  padding: 5px 7px;
  border: 1px solid ${({ theme }) => theme.color.checkboxBorder};

  .ant-checkbox-inner {
    ${({ theme }) =>
      theme.color.checkboxTogglerBackground &&
      css`
        background: ${theme.color.checkboxTogglerBackground};
      `}
    border: 0.5px solid ${({ theme }) => theme.color.checkboxTogglerBorder};
  }

  .ant-checkbox + span {
    display: inline-flex;
    width: calc(100% - 16px);
    justify-content: space-between;
    align-items: center;
    padding-right: 0;
  }

  .ant-checkbox-disabled + span {
    color: ${({ theme }) => theme.color.primary};
    opacity: 0.5;
  }
`;

const MetricRibbon = styled(MetricRibbonBase)`
  width: 20px;
  height: 20px;

  path {
    fill: ${({ theme }) => theme.color.ribbonFill};
  }
`;

const Name = styled.label`
  width: 90%;
`;

const OtherCategory = styled.span`
  color: ${({ theme }) => theme.color.fontSecondary};
`;

const OtherCategoryWrapper = styled.div`
  margin: ${({ theme }) => `${theme.spacing.xmedium} ${theme.spacing.xsmall} 0 ${theme.spacing.xsmall}`};
`;

const OtherCategoryName = styled(Name)`
  width: 217px;
`;

export default {
  SearchInput,
  OtherCategory,
  OtherCategoryWrapper,
  PanelWrapper,
  Checkbox,
  CheckboxGroup,
  Panel,
  MetricRibbon,
  Name,
  OtherCategoryName,
};

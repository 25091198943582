import { V2Alert } from '../v2-alert/v2-alert';
import type { V2TableStateInfoProps } from './v2-table-state-info.types';

import S from './v2-table-state-info.styles';

export const V2TableStateInfo = ({
  title,
  description,
  variant,
  showIcon,
  customIcon,
  spaceTop = false,
  className = '',
  style = {},
  'data-testid': dataTestId = 'v2-table-state-info',
}: V2TableStateInfoProps) => {
  return (
    <S.V2TableStateInfo style={style} spaceTop={spaceTop}>
      <V2Alert
        title={title}
        description={description}
        variant={variant}
        showIcon={showIcon}
        customIcon={customIcon}
        className={className}
        data-testid={dataTestId}
      />
    </S.V2TableStateInfo>
  );
};

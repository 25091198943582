import { FormikField } from '@parts/forms/formik-field/formik-field';
import { SubmitButton } from '@parts/submit-button/submit-button';
import styled from 'styled-components';

const PasswordField = styled(FormikField)`
  margin-top: ${({ theme }) => theme.spacing.base};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
`;

const Button = styled(SubmitButton)`
  width: 100%;
`;

export default { PasswordField, Content, Button };

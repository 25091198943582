import InfoIconBase from '@assets/icons/info-circle.svg?react';
import styled from 'styled-components';

const Description = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  font-size: ${({ theme }) => theme.fontSize.small};
  color: ${({ theme }) => theme.color.fontSecondary};
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`;

const InfoIcon = styled(InfoIconBase)`
  position: relative;
  top: 3px;
  left: 4px;
`;

export default { Description, InfoIcon };

export const currencyToShortFormat = (num: string | number, prefix: string = '') => {
  const amount = Number(num);

  if (amount < 999) {
    return `${prefix}${amount.toFixed(2)}`.replace('.00', '');
  }

  if (amount > 999 && amount < 1000000) {
    return `${prefix}${(amount / 1000).toFixed(2)}K`.replace('.00', '');
  }

  if (amount >= 1000000) {
    return `${prefix}${(amount / 1000000).toFixed(2)}M`.replace('.00', '');
  }

  return `${prefix}${amount.toString()}`;
};

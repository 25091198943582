import { FormikField as FormikFieldBase } from '@parts/forms/formik-field/formik-field';
import { StyledLabel } from '@parts/forms/label/label';
import styled from 'styled-components';

const Wrapper = styled(FormikFieldBase)`
  margin-bottom: ${({ theme }) => theme.spacing.xmedium};

  ${StyledLabel} {
    margin-bottom: ${({ theme }) => theme.spacing.xsmall};
    font-size: ${({ theme }) => theme.fontSize.regular};
    color: ${({ theme }) => theme.color.fontPrimary};
  }
`;

export default { Wrapper };

import { useV2AdvisorConnectionCardContext } from '../v2-advisor-connection-card-context';

import S from '../v2-advisor-connection-card.styles';

type SectionProps = {
  children: React.ReactNode;
};

export const Section = ({ children }: SectionProps) => {
  useV2AdvisorConnectionCardContext();

  return <S.Section>{children}</S.Section>;
};

import { useAuth0 } from '@auth0/auth0-react';
import { Roles } from '@domain/accounts/roles';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { Routes } from '@router/routes';
import { useQueryParams } from '@utils/hooks/use-query/use-query-params';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const INVITATION_TOKEN_KEY = 'invitationToken';
export type InvitationTokenData = {
  token: string;
  role: 'investor' | 'founder';
  businessName?: string;
};
export const InvitationRegistrationPage = () => {
  const {
    token,
    invitedEmail: investorInvitedEmail,
    invitedemail: founderInvitedEmail,
    businessname,
  } = useQueryParams();
  const { loginWithRedirect, user, isLoading, isAuthenticated } = useAuth0();
  const history = useHistory();
  const route = history.location.pathname.split('?')[0];
  const getEmail = () => {
    if (founderInvitedEmail) {
      return founderInvitedEmail.toString();
    }
    if (investorInvitedEmail) {
      return investorInvitedEmail.toString();
    }
  };
  useEffect(() => {
    if (token) {
      const invitationTokenValue: InvitationTokenData = {
        token: token.toString(),
        role: route === Routes.REGISTER_INVITED_INVESTOR ? Roles.INVESTOR : Roles.FOUNDER,
        businessName: businessname?.toString(),
      };

      localStorage.setItem(INVITATION_TOKEN_KEY, JSON.stringify(invitationTokenValue));

      if (!isAuthenticated && !user && !isLoading) {
        loginWithRedirect({
          authorizationParams: {
            screen_hint: 'signup',
            login_hint: getEmail(),
          },
        });
      } else if (!isLoading) {
        history.replace(Routes.HOME);
      }
    } else {
      history.replace(Routes.HOME);
    }
  }, [token]);

  return <FullHeightSpinner />;
};

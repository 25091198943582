import { mapEmptyToString } from '@utils/fns/map-empty-to-string';
import type { AppAction } from 'src/types';
import type { PublishStatus } from '../../hooks/use-publish-status-evaluation';
import {
  InstitutionType,
  InvestorType,
  type IGetInvestorInvestment,
  type IGetInvestorPersonalDetails,
  type IInvestorInvestment,
  type IInvestorSmartMatch,
  GenderType,
} from '../../investor/api/types';
import type { INedAbout, INedPersonalDetails, INedExperience, INedSmartMatch } from '../../ned/ned-profile.page';

export const SET_INVESTOR_SMART_MATCH = 'set-investor-smart-match';
export const SET_PERSONAL_DETAILS = 'set-personal-details';
export const SET_INVESTOR_INVESTMENTS = 'set-investor-investments';
export const SET_NED_BOARD_EXPERIENCE = 'set-ned-board-experience';
export const SET_NED_RESUME = 'set-ned-resume';
export const SET_NED_SMART_MATCH = 'set-ned-smart-match';
export const SET_NED_ABOUT_DATA = 'set-ned-about';
export const SET_NED_DATA_LOADED = 'set-ned-data-loaded';
export const SET_INVESTOR_DATA_LOADED = 'set-investor-loaded';

export interface IInvestorNedPersonalDetails extends INedPersonalDetails {
  investorType: InvestorType | null;
  institutionType: InstitutionType | null;
  recentOccupation: string | null;
  companyName: string | null;
  gender: GenderType | null;
}

export interface InvestorNedState {
  personalDetails: IInvestorNedPersonalDetails;
  about: INedAbout;
  investorSmartMatch: IInvestorSmartMatch;
  investment: IInvestorInvestment;
  nedBoardExperience: INedExperience[];
  publishStatus: PublishStatus;
  nedSmartMatch: INedSmartMatch;
  investorDataLoaded: boolean;
  nedDataLoaded: boolean;
}

export type InvestorNedActionType =
  | AppAction<typeof SET_INVESTOR_SMART_MATCH, { value: IInvestorSmartMatch }>
  | AppAction<typeof SET_PERSONAL_DETAILS, { value: IGetInvestorPersonalDetails }>
  | AppAction<typeof SET_NED_RESUME, { value: { resumeName: string; resumePath: string; resumeUrl: string } }>
  | AppAction<typeof SET_NED_BOARD_EXPERIENCE, { value: INedExperience[] }>
  | AppAction<typeof SET_NED_SMART_MATCH, { value: INedSmartMatch }>
  | AppAction<typeof SET_NED_ABOUT_DATA, { value: INedAbout }>
  | AppAction<typeof SET_NED_DATA_LOADED, { value: boolean }>
  | AppAction<typeof SET_INVESTOR_DATA_LOADED, { value: boolean }>
  | AppAction<typeof SET_INVESTOR_INVESTMENTS, { value: IGetInvestorInvestment }>;

export const investorNedReducer = (state: InvestorNedState, action: InvestorNedActionType): InvestorNedState => {
  switch (action.type) {
    case SET_PERSONAL_DETAILS: {
      const personalDetailsWithoutNulls = {
        ...mapEmptyToString(action.value),
        profilePhotoPath: action.value.profilePicture?.path || '',
        profilePhotoUrl: action.value.profilePicture?.url || '',
        investorType: action.value.type || InvestorType.NonSpecified,
      };

      const { profilePicture, logoPath, type, ...personalDetails } = personalDetailsWithoutNulls;
      return {
        ...state,
        personalDetails: {
          ...personalDetails,
          institutionType: action.value.institutionType || null,
        },
      };
    }

    case SET_INVESTOR_SMART_MATCH:
      return {
        ...state,
        investorSmartMatch: {
          ...action.value,
        },
      };

    case SET_NED_RESUME:
      return {
        ...state,
        personalDetails: {
          ...state.personalDetails,
          resume: {
            name: action.value.resumeName,
            path: action.value.resumePath,
            url: action.value.resumeUrl,
          },
        },
      };

    case SET_NED_BOARD_EXPERIENCE:
      return {
        ...state,
        nedBoardExperience: action.value,
      };

    case SET_NED_SMART_MATCH:
      return {
        ...state,
        nedSmartMatch: { ...action.value },
      };

    case SET_NED_ABOUT_DATA:
      return {
        ...state,
        about: { ...action.value },
      };

    case SET_NED_DATA_LOADED:
      return {
        ...state,
        nedDataLoaded: action.value,
      };
    case SET_INVESTOR_DATA_LOADED:
      return {
        ...state,
        investorDataLoaded: action.value,
      };

    case SET_INVESTOR_INVESTMENTS: {
      const {
        minInvestmentAmount,
        maxInvestmentAmount,
        investmentThesis,
        previousInvestmentsTable,
        hidePreviousInvestments,
        isDeployingCapitalInNextThreeMonths,
      } = action.value;

      const investmentsWithoutNulls: IInvestorInvestment = {
        minInvestmentAmount: minInvestmentAmount ?? 0,
        maxInvestmentAmount: maxInvestmentAmount ?? 0,
        investmentThesis: investmentThesis ?? '',
        hidePreviousInvestments: hidePreviousInvestments || false,
        previousInvestmentsTable: previousInvestmentsTable || [],
        isDeployingCapitalInNextThreeMonths: isDeployingCapitalInNextThreeMonths,
      };
      return {
        ...state,
        investment: investmentsWithoutNulls,
      };
    }

    default:
      return state;
  }
};

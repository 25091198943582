import { Input as InputBase } from 'antd';
import styled, { css } from 'styled-components';

const InputWrapper = styled.div<{ short?: boolean }>`
  height: ${({ short }) => (short ? 54 : 50)}px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.baseColors.Grey50};
  border-radius: ${({ theme }) => theme.radius.default};

  .ant-input {
    height: 100%;
    text-align: center;
    background-color: ${({ theme }) => theme.registerColors.inputBackground};
  }

  &.error {
    border-color: transparent;

    .ant-input-affix-wrapper {
      border: 1px solid ${({ theme }) => theme.baseColors.Red};
      input {
        border: none !important;
      }
    }
  }

  &::before {
    margin: -1px;
    background: transparent;
  }

  &:focus-within {
    border-color: transparent;

    .ant-input-affix-wrapper {
      border: 1px solid ${({ theme }) => theme.registerColors.inputBorder};
      ${({
        theme: {
          registerColors: { borderShadow },
          spacing: { small, xxsmall },
        },
      }) => css`
        box-shadow: 0px 0px ${small} ${xxsmall} ${borderShadow};
      `};

      input {
        border: none !important;
      }
    }
  }
`;

const styledInputSpan = css`
  background-color: ${({ theme }) => theme.registerColors.inputBackground};
  border: none;
  height: 100%;
  &[disabled] {
    background-color: ${({ theme }) => theme.registerColors.inputBackground};
    opacity: 0.5;
  }

  &:focus-within {
    ${({
      theme: {
        baseColors: { White },
        registerColors: { inputBorder, borderShadow },
        spacing: { small, xxsmall },
      },
    }) => css`
      color: ${White};
      border: 1px solid ${inputBorder};
      box-shadow: 0px 0px ${small} ${xxsmall} ${borderShadow};
    `};
  }
`;

const AuthInput = styled(InputBase)<{
  disabled?: boolean;
}>`
  ${styledInputSpan}
  ${({ disabled }) =>
    disabled &&
    css`
      + div[data-lastpass-icon-root],
      [data-lastpass-root] {
        display: none !important;
      }
    `}
`;

const AuthPasswordInput = styled(InputBase.Password)`
  ${styledInputSpan}
`;

export default {
  AuthPasswordInput,
  InputWrapper,
  AuthInput,
};

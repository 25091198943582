import CheckboxFormikField from '@pages/content/profile/parts/checkbox-formik-field/checkbox-formik-field';
import { Textarea as TextareaBase } from '@pages/content/profile/parts/textarea/textarea';
import { AmountRangeSlider as AmountRangeSliderBase } from '@parts/amount-range-slider/amount-range-slider';

import styled from 'styled-components';

const AmountRangeSlider = styled(AmountRangeSliderBase)`
  margin-top: ${({ theme }) => theme.spacing.small};
  text-align: left;
`;

const Textarea = styled(TextareaBase)`
  resize: vertical;
  overflow-y: auto;
`;

const CheckboxFormikFieldStyled = styled(CheckboxFormikField)`
  margin-top: ${({ theme }) => theme.spacing.small};
`;

export default { Textarea, AmountRangeSlider, CheckboxFormikFieldStyled };

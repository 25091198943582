import styled from 'styled-components';

const FileName = styled.div`
  position: relative;
  z-index: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default { FileName };

import { FounderLayout } from '@layout/founder/founder.layout';
import message from '@parts/message/message';
import { PageHeader } from '@parts/page-header/page-header';
import { Links } from '@router/links';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useHistory } from '@utils/hooks/use-history/use-history';
import { useRoleManagement } from '@utils/hooks/use-role-management/use-role-management';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import Element from '../../parts/element/element';
import { editRoleAction } from '../api/edit-role.actions';
import { GET_SINGLE_ROLE_CACHE_KEY, getSingleRoleAction } from '../api/get-single-role.actions';
import { updateRole } from '../context/management.action-creators';
import { RoleDetailsForm } from '../parts/role-details/role-details.form';

export const EditRolePage = () => {
  const [updateMsg, title] = useTranslation(['roles.update.success', 'roles.update.title']);

  const { dispatch } = useRoleManagement();
  const { id: roleId } = useParams() as { id: string };
  const queryCache = useQueryClient();

  const { data: roleResponse, refetch } = useQuery(
    [`${GET_SINGLE_ROLE_CACHE_KEY}_${roleId}`],
    getSingleRoleAction(roleId),
    {
      enabled: false,
    },
  );
  const history = useHistory();

  const { mutateAsync: editRole } = useMutation(editRoleAction(roleId), {
    onSuccess: (_, payload) => {
      queryCache.setQueryData([GET_SINGLE_ROLE_CACHE_KEY], roleResponse?.data);

      message.success({ content: updateMsg });
      dispatch(updateRole(roleId, payload.title));

      history.push(Links.ROLES_BROWSE());
      refetch();
    },
    onError: (err: AxiosError) => {
      message.error({ content: getServerError(err) });
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  const handleGoBack = () => history.push(Links.ROLES_BROWSE());

  return (
    <FounderLayout>
      <PageHeader title={title} onBack={handleGoBack} />
      <Element>
        {roleResponse?.data && (
          <RoleDetailsForm
            onSubmit={editRole}
            roleDetails={{ ...roleResponse.data, type: roleResponse.data.roleType }}
          />
        )}
      </Element>
    </FounderLayout>
  );
};

import { GET_INVESTOR_PUBLISH_STATUS_CACHE_KEY } from '@pages/content/api/get-investor-publish-status.action';
import type { GetInvestorSmartMatchSuggestions } from '@pages/content/lens/api/get-investor-smart-match-suggestion';
import type { PublishStatus } from '@pages/content/profile/hooks/use-publish-status-evaluation';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { useQuery, type MutationFunction } from '@tanstack/react-query';
import type { ParsedQuery } from 'query-string';
import type { GraphqlResponse } from 'src/types';
import type { GetFounderSmartMatchSuggestions } from '../../api/get-founder-smart-match-suggestion';
import { PublishEmptyState, type IPublishEmptyStateTranslations } from '../publish-empty-state/publish-empty-state';
import { MatchesGrid } from './parts/matches-grid/matches-grid';

export const SmartMatch = ({
  translations,
  publishProfileAction,
  smartMatchSuggestion,
  getPublishStatusAction,
  Item,
  smartMatchSuggestionCacheKey,
  refetchInvitationsCount,
  discoverable = true,
}: {
  translations: IPublishEmptyStateTranslations;
  publishProfileAction: MutationFunction<unknown, {}>;
  smartMatchSuggestion: (
    start: number,
    limit: number,
    excludedParams: string[],
    otherParams?: ParsedQuery<string>,
  ) => () => Promise<GraphqlResponse<GetInvestorSmartMatchSuggestions | GetFounderSmartMatchSuggestions>>;
  getPublishStatusAction: () => Promise<GraphqlResponse<PublishStatus>>;
  Item: Function;
  smartMatchSuggestionCacheKey: string;
  refetchInvitationsCount?: Function;
  discoverable?: boolean;
}) => {
  const {
    data: response,
    isLoading,
    refetch,
  } = useQuery([GET_INVESTOR_PUBLISH_STATUS_CACHE_KEY], getPublishStatusAction);

  if (isLoading || !response || !response.data) return <FullHeightSpinner />;

  const { published, smartMatchShortages } = response.data;

  if (!published) {
    return (
      <PublishEmptyState
        published={published}
        smartMatchShortages={smartMatchShortages}
        refetch={refetch}
        translations={translations}
        publishProfileAction={publishProfileAction}
      />
    );
  }

  return (
    <MatchesGrid
      getSmartMatchSuggestion={smartMatchSuggestion}
      Item={Item}
      discoverable={discoverable}
      smartMatchSuggestionCacheKey={smartMatchSuggestionCacheKey}
      refetchInvitationsCount={refetchInvitationsCount}
    />
  );
};

import { TableColumn as TableRowBase } from '@parts/table-column/table-column';
import styled from 'styled-components';

const TableRow = styled(TableRowBase)`
  border-left: none;
  border-right: none;
  height: 42px;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    font-size: 12px;
  }

  &:first-of-type,
  &:nth-of-type(2) {
    border-bottom: 1px solid ${({ theme }) => theme.color.border};
  }
`;

const Label = styled.label`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  padding-left: 15px;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    font-size: 12px;
    padding: 0;
  }
`;

export default { TableRow, Label };

import EmptyIcon from '@assets/icons/dashboards-empty.svg';
import { EmptyState } from '@parts/empty-state/empty-state';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './dashboards-empty-state-styles';

const EmptyIconImage = () => <img src={EmptyIcon} alt="empty" loading="lazy" />;
interface DashboardsEmptyStateProps {
  showAddDashboardButton?: boolean;
  displayAddForm?: () => void;
}

export const DashboardsEmptyState = ({
  showAddDashboardButton = false,
  displayAddForm = () => {},
}: DashboardsEmptyStateProps) => {
  const [titleLabel, subTitleLabel, buttonLabel] = useTranslation([
    showAddDashboardButton ? 'founder.pulseDashboard.empty.title' : 'investor.pulseDashboard.empty.title',
    showAddDashboardButton ? 'founder.pulseDashboard.empty.subtitle' : 'investor.pulseDashboard.empty.subtitle',
    'founder.pulseDashboard.empty.button',
  ]);

  return (
    <EmptyState Icon={EmptyIconImage} title={titleLabel} subTitle={subTitleLabel}>
      {showAddDashboardButton && (
        <S.Button data-testid="empty-state-add-button" onClick={displayAddForm}>
          {buttonLabel}
        </S.Button>
      )}
    </EmptyState>
  );
};

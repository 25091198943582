import { ProfilePicture } from '@parts/profile-picture/profile-picture';
import { useProfileDetailsModal } from './parts/hooks/use-profile-details-modal/use-profile-details-modal';
import S from './profile-tile.styles';

const AVATAR_SIZE_PX = '84';

export interface ProfileDetails {
  companyName: string;
  companyImgUrl: string;
  description: string;
}

export const ProfileTile = ({ profileDetails }: { profileDetails: ProfileDetails }) => {
  const { modal: profileDetailsModal, show: showModal } = useProfileDetailsModal({ ...profileDetails, visible: false });

  const { companyName, companyImgUrl } = profileDetails;

  return (
    <>
      <S.Wrapper data-testid="user-tile" onClick={showModal}>
        <ProfilePicture width={AVATAR_SIZE_PX} height={AVATAR_SIZE_PX} src={companyImgUrl} />
        <S.CompanyName>{companyName}</S.CompanyName>
      </S.Wrapper>
      {profileDetailsModal}
    </>
  );
};

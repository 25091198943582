// @ts-nocheck

export const accessNestedPropertyByPath = (object, path, defaultValue = null) => {
  const ph = (() => {
    if (Array.isArray(path)) return path;

    if (path.includes('.')) return path.split('.');

    return [path];
  })();

  if (object && ph.length) return accessNestedPropertyByPath(object[ph.shift()], ph, defaultValue);
  return object === undefined ? defaultValue : object;
};

import { bookmarkNedAction, unbookmarkNedAction } from '@/modules/founder/advisor-search/api/bookmark-ned.action';
import { ConnectionButton } from '@/modules/founder/advisor-search/components/connection-button/connection-button';
import type { TConnectionStatus } from '@/modules/founder/advisor-search/types';
import MessageIcon from '@assets/icons/message-empty.svg?react';
import StarIcon from '@assets/icons/star-outline.svg?react';
import { engageNedMessagingAction } from '@pages/content/roles/parts/applications/api/message-engage-ned';
import message from '@parts/message/message';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useEngageConversation } from '@utils/hooks/use-engage-conversation/use-engage-conversation';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { GET_NED_PUBLIC_PAGE_DETAILS_CACHE_KEY } from '../../api/get-ned-public-page-details';

import S from './interactions-manager.styles';

type InteractionsManagerProps = {
  isBookmarked: boolean;
  connectionStatus: TConnectionStatus['status'];
  initiator: TConnectionStatus['initiator'];
  connectionId: string | null;
};

type QueryParams = {
  id: string;
};

export const InteractionsManager = ({
  isBookmarked,
  connectionStatus,
  connectionId,
  initiator,
}: InteractionsManagerProps) => {
  const [saveLabel, savedLabel, saveSuccessLabel, unsaveSuccessLabel, messageLabel] = useTranslation([
    'advisors.match.save',
    'advisors.match.saved',
    'message.saved.success',
    'message.unsaved.success',
    'message.label',
  ]);

  const { id: nedId } = useParams<QueryParams>();

  const [isSaved, setIsSaved] = useState(() => isBookmarked);

  const { mutateAsync: saveNed, isLoading: isSaving } = useMutation(bookmarkNedAction(nedId), {
    onSuccess: () => {
      setIsSaved(true);

      message.success({ content: saveSuccessLabel });
    },
    onError: (error: AxiosError) => {
      message.error({ content: getServerError(error) });
    },
  });

  const { mutateAsync: unsaveNed, isLoading: isUnsaving } = useMutation(unbookmarkNedAction(nedId), {
    onSuccess: () => {
      setIsSaved(false);

      message.success({ content: unsaveSuccessLabel });
    },
    onError: (error: AxiosError) => {
      message.error({ content: getServerError(error) });
    },
  });

  const { mutateAsync: messageAction, isLoading: isMessageLoading } = useEngageConversation(engageNedMessagingAction);

  const determineSaveAction = isSaved ? () => unsaveNed() : () => saveNed();

  const client = useQueryClient();

  return (
    <S.ButtonsWrapper>
      <ConnectionButton
        connectionStatus={connectionStatus}
        advisorId={nedId}
        connectionId={connectionId}
        buttonSize="small"
        buttonFixedHeight="50px"
        disabled={Boolean(client.isFetching([GET_NED_PUBLIC_PAGE_DETAILS_CACHE_KEY, nedId]))}
        callback={() => client.refetchQueries([GET_NED_PUBLIC_PAGE_DETAILS_CACHE_KEY, nedId])}
        initiator={initiator}
      />
      <S.MessageBtn
        type="link"
        disabled={isMessageLoading}
        onClick={() => messageAction(nedId)}
        icon={<MessageIcon />}
        data-testid="advisor-profile-message-button"
      >
        {messageLabel}
      </S.MessageBtn>
      <S.SaveBtn
        $isSaved={isSaved}
        onClick={determineSaveAction}
        disabled={isSaving || isUnsaving}
        icon={<StarIcon />}
        data-testid="advisor-profile-save-button"
      >
        {isSaved ? savedLabel : saveLabel}
      </S.SaveBtn>
    </S.ButtonsWrapper>
  );
};

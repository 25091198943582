import { V2CuratedFeedSection } from '@/components/ui/v2-curated-feed-section/v2-curated-feed-section';
import type { CuratedFeedItem } from '@/components/ui/v2-curated-feed-section/v2-curated-feed-section.types';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';

export const AdvisorCuratedFeed = () => {
  const [title, description, tile1Title, tile1Description, tile2Title, tile2Description, tile3Title, tile3Description] =
    useTranslation([
      'homepage.advisor.curatedFeed.title',
      'homepage.advisor.curatedFeed.description',
      'homepage.advisor.curatedFeed.tile1.title',
      'homepage.advisor.curatedFeed.tile1.description',
      'homepage.advisor.curatedFeed.tile2.title',
      'homepage.advisor.curatedFeed.tile2.description',
      'homepage.advisor.curatedFeed.tile3.title',
      'homepage.advisor.curatedFeed.tile3.description',
    ]);

  const items: CuratedFeedItem[] = [
    {
      title: tile1Title,
      description: tile1Description,
      variant: 'navy',
      'data-testid': 'advisor-curated-feed-tile-1',
    },
    {
      title: tile2Title,
      description: tile2Description,
      variant: 'blue',
      'data-testid': 'advisor-curated-feed-tile-2',
    },
    {
      title: tile3Title,
      description: tile3Description,
      variant: 'green',
      'data-testid': 'advisor-curated-feed-tile-3',
    },
  ];

  return (
    <V2CuratedFeedSection
      title={title}
      description={description}
      data-testid="advisor-homepage-curated-feed-section"
      items={items}
    />
  );
};

/* eslint-disable @typescript-eslint/no-use-before-define */
import { NedLayout } from '@layout/ned/ned.layout';
import { PageHeader } from '@parts/page-header/page-header';
import { Tabs } from '@parts/tabs/tabs';
import { rolesAppliedKey, rolesBrowseKey } from '@router/tabs-keys';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Applied } from './parts/applied/applied';
import { BrowseRoles } from './parts/browse-roles/browse-roles';
import { Routes } from '@router/routes';

export const NedCatalogue = ({ Layout = NedLayout }: { Layout?: Function }) => {
  const [title, browseLabel, appliedLabel] = useTranslation([
    'roles.ned.title',
    'roles.ned.tabs.browse',
    'roles.ned.tabs.applied',
  ]);

  return (
    <Layout>
      <PageHeader title={title} />
      <Tabs
        locator="content"
        route={Routes.ROLES}
        defaultLocator={rolesBrowseKey}
        tabs={[
          {
            key: rolesBrowseKey,
            label: browseLabel,
            content: <BrowseRoles />,
          },
          {
            key: rolesAppliedKey,
            label: appliedLabel,
            content: <Applied />,
          },
        ]}
      />
    </Layout>
  );
};

import type { FounderRoleResponse } from '@pages/content/roles/catalogue/api/get-founder-roles.actions';
import {
  ADD_NEW_ROLE,
  CLOSE_ROLE,
  DELETE_ROLE,
  REFRESH_ROLE,
  SET_ROLES,
  UPDATE_ROLE,
  type RoleManagementActionType,
} from './management.reducer';

export const setRoles: (roles: FounderRoleResponse[]) => RoleManagementActionType = (roles) => ({
  type: SET_ROLES,
  roles,
});

export const addNewRole: (id: string, title: string) => RoleManagementActionType = (id, title) => ({
  type: ADD_NEW_ROLE,
  role: {
    id,
    title,
  },
});

export const closeRole: (roleId: string) => RoleManagementActionType = (roleId) => ({
  roleId,
  type: CLOSE_ROLE,
});

export const refreshRole: (roleId: string) => RoleManagementActionType = (roleId) => ({
  roleId,
  type: REFRESH_ROLE,
});

export const deleteRole: (roleId: string) => RoleManagementActionType = (roleId) => ({
  roleId,
  type: DELETE_ROLE,
});

export const updateRole: (id: string, title: string) => RoleManagementActionType = (id, title) => ({
  type: UPDATE_ROLE,
  role: {
    id,
    title,
  },
});

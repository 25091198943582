import { V2Button } from '@/components/ui/v2-button/v2-button';
import { useMediaQuery } from '@/hooks/use-media-query';
import type { FounderQualifyingQuestionsResponseData } from '@/modules/founder/homepage/api/get-founder-qualifying-questions.action';
import { usePatchUpdateSmartMatch } from '@/modules/founder/homepage/hooks/use-patch-update-smart-match';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import S from './steps.styles';

type InvestmentStageStepProps = {
  currentStep: number;
  countOfSteps: number;
  initialValues: FounderQualifyingQuestionsResponseData;
  next: () => void;
  back: () => void;
};

export const InvestmentStageStep = ({
  currentStep,
  countOfSteps,
  initialValues,
  next,
  back,
}: InvestmentStageStepProps) => {
  const device = useMediaQuery();
  const { UpdateSmartMatchMutation } = usePatchUpdateSmartMatch(next);

  const formik = useFormik<{ investmentStage?: string }>({
    initialValues: { investmentStage: initialValues.investmentStage },
    validationSchema: Yup.object({ investmentStage: Yup.string().required() }),
    onSubmit: (values) => UpdateSmartMatchMutation({ investmentStage: values.investmentStage || null }),
  });

  const [title, subtitle, backText, nextText] = useTranslation([
    'qualifying-questions.investment-stage-step.title',
    'qualifying-questions.select-one',
    'qualifying-questions.previous-question.action',
    'qualifying-questions.next-question.action',
  ]);

  const options = ['Pre-Seed', 'Seed', 'Seed+', 'Series A', 'Series B', 'Series C'];
  const selectedButtonSize = device === 'xs' ? 'small' : 'large';
  const shouldIconBeDisplayed = device === 'xs' ? undefined : true;

  return (
    <S.Wrapper>
      <S.TitleGroupWrapper
        title={title}
        subtitle={subtitle}
        align="center"
        currentStep={currentStep}
        countOfSteps={countOfSteps}
      />
      <form onSubmit={formik.handleSubmit}>
        <S.ButtonSelectWrapper>
          {options.map((option) => (
            <V2Button
              size={selectedButtonSize}
              key={option}
              variant={formik.values.investmentStage === option ? 'selected' : 'outlined'}
              onClick={(e) => {
                e.preventDefault();
                formik.setValues({ investmentStage: option });
              }}
            >
              {option}
            </V2Button>
          ))}
        </S.ButtonSelectWrapper>
      </form>

      <S.ButtonWrapper>
        {currentStep > 1 && (
          <V2Button
            size={selectedButtonSize}
            variant="outlined"
            startIcon={shouldIconBeDisplayed && 'arrow-left'}
            onClick={back}
          >
            {backText}
          </V2Button>
        )}

        <V2Button
          size={selectedButtonSize}
          variant="primary"
          onClick={formik.submitForm}
          endIcon={shouldIconBeDisplayed && 'arrow-right'}
          disabled={Boolean(formik.errors.investmentStage) || !formik.values.investmentStage}
        >
          {nextText}
        </V2Button>
      </S.ButtonWrapper>
    </S.Wrapper>
  );
};

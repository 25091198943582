import { axiosInstance } from '@utils/axios/axios-instance';

export const acceptConnection = (connectionId: string) => async () => {
  try {
    const results = await Promise.any([
      axiosInstance.post(`/connections/ned/connection/${connectionId}/accept`),
      axiosInstance.post(`/connections/investor/connection/${connectionId}/accept`),
    ]);
    return results;
  } catch (error) {
    console.error('Error accepting connection:', error);
    throw error;
  }
};

import { MailLink } from '@parts/mail-link/mail-link';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Description } from '../description/description';
import { contactHelpConnectdEmail } from '@constants/emails';

interface QuestionContactProps {
  message?: string;
}

export const QuestionContact = ({ message }: QuestionContactProps) => {
  const [defaultMessage] = useTranslation(['default.contact']);

  return (
    <Description>
      {message ?? defaultMessage} <MailLink label={contactHelpConnectdEmail} />
    </Description>
  );
};

import { useQuery } from '@tanstack/react-query';
import { GET_FOUNDER_PUBLISH_STATUS_CACHE_KEY, getFounderPublishStatus } from '../api/get-founder-published-status';

export const useGetFounderPublishedStatus = () => {
  const query = useQuery([GET_FOUNDER_PUBLISH_STATUS_CACHE_KEY], getFounderPublishStatus);

  return {
    ...query,
    isFounderPublished: query.data?.data?.published ?? false,
    isFounderPublishedLoading: query.isLoading,
    isFounderPublishedError: query.isError,
  };
};

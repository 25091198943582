import { useTheme } from '@/hooks/use-theme';
import { getLsThemeKey } from '@context/theme/app-theme.provider';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './theme-switch.styles';

export const ThemeSwitch = () => {
  const { mode, setMode } = useTheme();
  const [darkModeLabel] = useTranslation(['navigation.darkMode']);

  const handleThemeModeChange = () => {
    document.body.classList.add('no-transition');

    const nextMode = mode === 'dark' ? 'light' : 'dark';

    setMode(nextMode);
    localStorage.setItem(getLsThemeKey(), nextMode);

    setTimeout(() => {
      document.body.classList.remove('no-transition');
    }, 1000);
  };

  return (
    <>
      <S.DarkModeLabel>{darkModeLabel}</S.DarkModeLabel>
      <S.SwitchLabelled onClick={handleThemeModeChange} isChecked={mode === 'dark'} />
    </>
  );
};

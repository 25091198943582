import { Radio as RadioBase } from '@parts/radio/radio';
import { Input } from 'antd';
import styled from 'styled-components';

const MetricsDesc = styled.p`
  color: ${({ theme }) => theme.baseColors.Grey200};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  margin: 5px 0;
  display: inline-block;

  svg:first-child {
    margin-left: 4px;
  }
`;

const SearchInput = styled(Input)`
  height: 32px;
  font-size: ${({ theme }) => theme.fontSize.xsmall}
  margin-block: 10px !important;

  .ant-input {
    height: fit-content;
  }
`;

const RadioButton = styled(RadioBase.Button)`
  width: 100%;
  margin: 5px 0;
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  border-color: ${({ theme }) => theme.color.border};
  color: ${({ theme }) => theme.color.primary};
  background-color: ${({ theme }) => theme.color.sectionBackground};
`;

export default { MetricsDesc, RadioButton, SearchInput };

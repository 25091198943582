import type { FunctionComponent } from 'react';

import S from './welcome-tiles-section-wrapper.styles';

export const WelcomeTilesSectionWrapper: FunctionComponent = ({ children }) => {
  return (
    <S.Container>
      <S.Grid data-testid="welcome-tiles-section-wrapper-grid">{children}</S.Grid>
    </S.Container>
  );
};

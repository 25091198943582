import { TrashButton } from '@pages/content/profile/parts/trash-button/trash-button';
import useConfirmModal from '@utils/hooks/use-confirm-modal/use-confirm-modal';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';

export const DeleteTextbox = ({ onRemove }: { onRemove: () => void }) => {
  const [deleteContent, deleteTitle, confirmDelete] = useTranslation([
    'pulse.dashboard.textBox.delete.modal.content',
    'pulse.dashboard.textBox.delete.modal.title',
    'global.delete',
  ]);

  const { modal: confirmDeleteTextbox, show: showConfirmDeleteTextbox } = useConfirmModal({
    title: deleteTitle,
    content: deleteContent,
    onConfirm: onRemove,
    confirmLabel: confirmDelete,
    dataTestId: 'deleteTextbox',
  });

  return (
    <>
      <TrashButton onRemove={showConfirmDeleteTextbox} />
      {confirmDeleteTextbox}
    </>
  );
};

import { TwitterOutlined as TwitterOutlinedBase } from '@ant-design/icons';
import LinkedinIcon from '@assets/icons/linkedin-logo.svg?react';
import WebIconBase from '@assets/icons/web-icon.svg?react';
import DividerBase from '@parts/divider/divider';
import { SecureLink, type SecureLinkProps } from '@parts/link/link';
import type { FC } from 'react';
import styled from 'styled-components';

const Divider = styled(DividerBase)`
  margin: ${({ theme }) => theme.spacing.medium} 0;
`;

const Company = styled.div`
  display: inline-flex;
  align-items: center;
  width: 80%;
`;

const Socials = styled.div`
  display: inline-flex;
  width: 20%;
  align-items: center;
  justify-content: flex-end;
  height: 12px;
  line-height: 12px;
  gap: ${({ theme }) => theme.spacing.small};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }
`;

const About = styled.div`
  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.small};
  }
`;

const TwitterOutlinedIcon = styled(TwitterOutlinedBase)`
  width: 12px;
  height: 12px;

  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
`;

const LinkedinOutlined = styled(LinkedinIcon)`
  width: auto;
  height: 100%;

  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
`;

const PulseLabel = styled.span`
  width: 12px;
  height: 12px;
  color: ${({ theme }) => theme.baseColors.Blue200};
  text-decoration: none;
`;

const WebIcon = styled(WebIconBase)`
  width: auto;
  height: 16px;

  path {
    fill: ${({ theme }) => theme.color.iconFill};
  }
`;

const AvatarLink = styled(SecureLink)`
  display: flex;
  align-items: center;
` as FC<SecureLinkProps>;

const Wrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.desktop}) {
    display: flex;
    align-items: center;
  }
`;

export default {
  Divider,
  Company,
  About,
  Socials,
  TwitterOutlinedIcon,
  LinkedinOutlined,
  PulseLabel,
  WebIcon,
  AvatarLink,
  Wrapper,
};

import LockIconBase from '@assets/icons/legal/documents/filled-lock.svg?react';
import OpenInNewBase from '@assets/icons/legal/documents/open-in-new.svg?react';
import S from '@parts/grid-item/grid-item.styles';
import styled, { css } from 'styled-components';

const sharedStyle = css`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const Wrapper = styled(S.Wrapper)`
  position: relative;
  transition: 0.3s;

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: calc(100vw - 40px);
  }

  &:hover {
    border-color: ${({ theme }) => theme.baseColors.Blue200};
    background: ${({ theme }) => theme.color.legalDocumentHoverBackground};
  }
`;

const Line = styled.div`
  padding: ${({ theme }) => theme.spacing.small};
  border-top: 1px solid ${({ theme }) => theme.color.border};
`;

const Image = styled.div<{ isBlocked: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 26px;

  svg path[stroke] {
    stroke: ${({ theme, isBlocked }) => (isBlocked ? theme.baseColors.Grey200 : theme.color.legalDocumentImage)};
  }

  svg path[fill] {
    fill: ${({ theme, isBlocked }) => (isBlocked ? theme.baseColors.Grey200 : theme.color.legalDocumentImage)};
  }
`;

const OpenInNew = styled(OpenInNewBase)`
  ${sharedStyle}
`;

const LockIcon = styled(LockIconBase)<{ mode?: string }>`
  ${sharedStyle}
  opacity: ${({ mode }) => (mode === 'light' ? 1 : 0.5)};

  path {
    fill: ${({ theme }) => theme.color.lockIcon};
  }
`;

const CornerLabel = styled.div`
  ${sharedStyle}
  width: 100px;
  border-radius: 20px;
  background: #e5e9f9;
  color: #10328e;
  opacity: 0.5;
  font-size: ${({ theme }) => theme.fontSize.xxsmall};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  text-align: center;
  color: ${({ theme }) => theme.color.tagFont};
  text-transform: uppercase;
`;

const Title = styled.h3<{ isComingSoon?: boolean; isBlocked: boolean }>`
  font-weight: ${({ theme }) => theme.fontWeight.normal};
  color: ${({ theme, isComingSoon, isBlocked }) =>
    isComingSoon || isBlocked ? theme.baseColors.Grey225 : theme.baseColors.Blue200};
  padding-left: 20px;
`;

const Description = styled.p<{ isComingSoon?: boolean; isBlocked: boolean }>`
  color: ${({ theme, isComingSoon, isBlocked }) =>
    isComingSoon || isBlocked ? theme.baseColors.Grey225 : theme.color.legalDocumentDesc};
  font-size: ${({ theme }) => theme.fontSize.small};
  margin-bottom: 17px;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 7px;
`;

const Link = styled.a<{ isBlocked: boolean }>`
  display: block;
  cursor: ${({ isBlocked }) => !isBlocked && 'pointer'};
  pointer-events: ${({ isBlocked }) => isBlocked && 'none'};
  text-decoration: none;
`;

export default {
  Line,
  Image,
  Wrapper,
  OpenInNew,
  Title,
  CornerLabel,
  Description,
  Link,
  LockIcon,
};

import {
  getExternalFundingRoundTypesAction,
  GET_EXTERNAL_FUNDING_ROUND_TYPES_CACHE_KEY,
} from '@pages/content/portfolio/investor/api/get-external-funding-round-types.action';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

export const useAllFundingRoundTypes = () => {
  const { data: allFundingRoundTypesResponse, refetch: refetchAllFundingRoundTypes } = useQuery(
    [GET_EXTERNAL_FUNDING_ROUND_TYPES_CACHE_KEY],
    getExternalFundingRoundTypesAction,
    {
      enabled: false,
    },
  );

  useEffect(() => {
    refetchAllFundingRoundTypes();
  }, []);

  return {
    allFundingRoundTypesResponse,
  };
};

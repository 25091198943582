import { useTheme } from '@/hooks/use-theme';
import BasketImage from '@assets/images/investor-home-page/banners/basket.webp';
import ByRotationImage from '@assets/images/investor-home-page/banners/by-rotation.webp';
import CommunityLaunchDarkImage from '@assets/images/investor-home-page/banners/community-launch-dark.webp';
import CommunityLaunchLightImage from '@assets/images/investor-home-page/banners/community-launch-light.webp';
import GreenLithiumImage from '@assets/images/investor-home-page/banners/green-lithium.webp';
import KyteImage from '@assets/images/investor-home-page/banners/kyte.webp';
import WisdomWorksImage from '@assets/images/investor-home-page/banners/wisdom-works.webp';
import {
  basketUrl,
  byRotationUrl,
  greenLithiumUrl,
  kyteUrl,
  wisdomWorksUrl,
} from '@pages/content/start/investor/parts/banners/hardcoded-banner-data';
import { getGreaterHeight, type BannerHeight } from '@pages/content/start/utils/get-greater-height';
import SlideShow from '@parts/slideshow/slideshow';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useState, type FC } from 'react';
import ProfileTiles from '../parts/profile-tiles/profile-tiles';
import { CommunityBanner, type CommunityBannerType } from './parts/banners/community-banners/community-banner';
import HeadlineWrapper from './parts/headline-wrapper/headline-wrapper';

// TODO: CON-11593 move to src/modules/homepage/components/investor-homepage.tsx
import { InvestorCapitalDeploymentModal } from '@/modules/founder/homepage/components/investor-capital-deployment-modal/investor-capital-deployment-modal';
import { useGetInvestorCapitalDeployment } from '@/modules/founder/homepage/hooks/use-get-investor-capital-deployment';
import { usePatchInvestorCapitalDeployment } from '@/modules/founder/homepage/hooks/use-patch-investor-capital-deployment';
import message from '@/parts/message/message';

const sliderChangeSpeed = 10000;

export const InvestorStartPage = ({ Layout }: { Layout: FC<{ legacyContainer?: boolean }> }) => {
  const { mode: themeMode } = useTheme();
  const [
    basketLabel,
    basketDescription,
    byRotationLabel,
    byRotationDescription,
    kyteLabel,
    kyteDescription,
    wisdomWorksLabel,
    wisdomWorksDescription,
    greenLithiumLabel,
    greenLithiumDescription,
    communityLaunchLabel,
    capitalDeplymentUpdatedLabel,
  ] = useTranslation([
    'start.community.basket.title',
    'start.community.basket.description',
    'start.community.byRotation.title',
    'start.community.byRotation.description',
    'start.community.kyte.title',
    'start.community.kyte.description',
    'start.community.wisdomWorks.title',
    'start.community.wisdomWorks.description',
    'start.community.greenLithium.title',
    'start.community.greenLithium.description',
    'start.community.launch.title',
    'messages.form.success',
  ]);
  const [communityLaunchBannerHeight, setCommunityLaunchBannerHeight] = useState<BannerHeight>(null);
  const [basketBannerHeight, setBasketBannerHeight] = useState<BannerHeight>(null);
  const [byRotationBannerHeight, setbyRotationBannerHeight] = useState<BannerHeight>(null);
  const [kyteBannerHeight, setKyteBannerHeight] = useState<BannerHeight>(null);
  const [wisdomWorksBannerHeight, setWisdomWorksBannerHeight] = useState<BannerHeight>(null);
  const [greenLithiumBannerHeight, setGreenLithiumBannerHeight] = useState<BannerHeight>(null);

  // TODO: CON-11593 move to src/modules/homepage/components/investor-homepage.tsx
  const { investorCapitalDeploymentData } = useGetInvestorCapitalDeployment();
  const { patchInvestorCapitalDeploymentMutation } = usePatchInvestorCapitalDeployment(() =>
    message.success({ content: capitalDeplymentUpdatedLabel }),
  );

  const bannersHeight = [
    communityLaunchBannerHeight,
    basketBannerHeight,
    byRotationBannerHeight,
    kyteBannerHeight,
    wisdomWorksBannerHeight,
    greenLithiumBannerHeight,
  ];

  const communityLaunchBannerSrc = themeMode === 'light' ? CommunityLaunchLightImage : CommunityLaunchDarkImage;

  const communityBanners: CommunityBannerType[] = [
    {
      title: communityLaunchLabel,
      image: communityLaunchBannerSrc,
      height: communityLaunchBannerHeight,
      setHeight: setCommunityLaunchBannerHeight,
    },
    {
      title: basketLabel,
      description: basketDescription,
      image: BasketImage,
      url: basketUrl,
      height: basketBannerHeight,
      setHeight: setBasketBannerHeight,
    },
    {
      title: byRotationLabel,
      description: byRotationDescription,
      image: ByRotationImage,
      url: byRotationUrl,
      height: byRotationBannerHeight,
      setHeight: setbyRotationBannerHeight,
    },
    {
      title: kyteLabel,
      description: kyteDescription,
      image: KyteImage,
      url: kyteUrl,
      height: kyteBannerHeight,
      setHeight: setKyteBannerHeight,
    },
    {
      title: wisdomWorksLabel,
      description: wisdomWorksDescription,
      image: WisdomWorksImage,
      url: wisdomWorksUrl,
      height: wisdomWorksBannerHeight,
      setHeight: setWisdomWorksBannerHeight,
    },
    {
      title: greenLithiumLabel,
      description: greenLithiumDescription,
      image: GreenLithiumImage,
      url: greenLithiumUrl,
      height: greenLithiumBannerHeight,
      setHeight: setGreenLithiumBannerHeight,
    },
  ];

  return (
    <>
      <Layout>
        <HeadlineWrapper />
        <SlideShow
          autoplay
          autoplaySpeed={sliderChangeSpeed}
          banners={[
            ...communityBanners.map(({ title, description, image, url, height, setHeight }) => (
              <CommunityBanner
                key={title}
                title={title}
                description={description}
                image={image}
                url={url}
                height={getGreaterHeight(height, bannersHeight)}
                setHeight={setHeight}
              />
            )),
          ]}
        />
        <ProfileTiles />
      </Layout>

      {/* TODO: CON-11593 move to src/modules/homepage/components/investor-homepage.tsx */}
      {investorCapitalDeploymentData && (
        <InvestorCapitalDeploymentModal
          isOpen={investorCapitalDeploymentData.shouldShowDeployingCapitalModal}
          initialValues={{
            isDeployingCapital: investorCapitalDeploymentData.isDeployingCapitalInNextThreeMonths ?? undefined,
          }}
          callback={(val) => patchInvestorCapitalDeploymentMutation({ isDeployingCapitalInNextThreeMonths: val })}
        />
      )}
    </>
  );
};

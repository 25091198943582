import { Button } from 'antd';
import styled from 'styled-components';

const AddNewRow = styled(Button)`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: left;
  padding-left: 0;
  margin-top: 10px;

  > span {
    color: ${({ theme }) => theme.baseColors.Blue200};
    font-size: ${({ theme }) => theme.fontSize.small};
  }
`;

const UseOfFundsContainer = styled.p`
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default {
  UseOfFundsContainer,
  AddNewRow,
};

import SearchIcon from '@assets/icons/search.svg?react';
import type { ExternalMetric, InternalMetric } from '@pages/content/pulse/api/get-metrics/get-metrics.actions';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Collapse } from 'antd';
import levenshtein from 'js-levenshtein';
import groupBy from 'lodash.groupby';
import { useEffect, useState, type ChangeEvent } from 'react';
import S from './metrics-view-configure.styles';
import MetricItem from './parts/metric-item';

const MAX_WRONG_LETTERS = 3;
const ORGANIZED_METRICS_CATEGORIES = ['Revenue', 'Financial', 'Marketing', 'Customer Success'];

export const MetricsViewConfigure = ({
  values,
  integratedMetricsView,
}: {
  values: ExternalMetric[] | InternalMetric[];
  integratedMetricsView?: boolean;
}) => {
  const groupedMetrics = groupBy(values, 'category');
  const [filter, setFilter] = useState<string | null>(null);
  const [categoriesOpen, setCategoriesOpen] = useState(new Set<string>([]));

  const categories = Object.keys(groupedMetrics);
  const metricCategories = Array.from(new Set<string>([...ORGANIZED_METRICS_CATEGORIES, ...categories]));

  const [searchLabel] = useTranslation(['pulseDashboard.shared.search']);

  const panelWrapperOnClick = (categoriesList: string[]) => {
    setCategoriesOpen(new Set<string>(categoriesList));
  };

  const checkFilterMatch = (name: string): boolean => {
    const nameInLowerCase = name.toLocaleLowerCase();
    const filterInLowerCase = filter?.toLocaleLowerCase() ?? '';

    return filter
      ? nameInLowerCase.includes(filterInLowerCase) ||
          levenshtein(nameInLowerCase, filterInLowerCase) <= MAX_WRONG_LETTERS
      : false;
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => setFilter(e.target.value);

  useEffect(() => {
    const newCategoryOpen = new Set<string>([]);
    metricCategories.forEach((category) => {
      const group = groupedMetrics[category];
      const isInCategory = Boolean(group && group.some((metric) => checkFilterMatch(metric.name)));

      if (isInCategory) {
        newCategoryOpen.add(category);
      }
    });

    setCategoriesOpen(newCategoryOpen);
  }, [filter]);

  return (
    <>
      <S.SearchInput
        placeholder={searchLabel}
        onChange={onChange}
        prefix={<SearchIcon />}
        data-testid="metric-search"
      />
      <Collapse
        expandIconPosition="right"
        ghost
        activeKey={[...categoriesOpen]}
        onChange={(list) => panelWrapperOnClick(typeof list === 'string' ? [list] : list)}
      >
        {metricCategories.map((category) => {
          const group = groupedMetrics[category];

          if (!group) return null;
          return (
            <S.Panel key={category} header={<span data-testid={`panel-${category.toLowerCase()}`}>{category}</span>}>
              {group.map(
                (metric) =>
                  (!filter || checkFilterMatch(metric.name)) && (
                    <MetricItem key={metric.id} integratedMetricsView={integratedMetricsView} metric={metric} />
                  ),
              )}
            </S.Panel>
          );
        })}
      </Collapse>
    </>
  );
};

import {
  LN_QUESTION_CODE,
  type MarketFitQuestionItem,
} from '@/pages/content/profile/founder/api/get-market-fit-questions/get-market-fit-questions.query';
import { updateFounderMarketFitAction } from '@/pages/content/profile/founder/api/update-market-fit/market-fit.action';
import { EmptyState } from '@/parts/empty-state/empty-state';
import { ConditionalShowToggle } from '@parts/conditional-show-toggle/conditional-show-toggle';
import message from '@parts/message/message';
import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from '@utils/axios/types';
import { getServerError } from '@utils/fns/get-server-error';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { RadioChangeEvent } from 'antd/lib/radio';
import { useMarketFitFormik } from './hooks/use-market-fit-formik';
import { useMarketFitQuestionsLabel } from './hooks/use-market-fit-questions';

import S from './market-fit.styles';

interface Props {
  data: MarketFitQuestionItem[];
}

export const MarketFitManager = ({ data }: Props) => {
  const [submitLL, submitSuccessLL, emptyLL] = useTranslation([
    'profile.founder.section.marketFit.form.save',
    'profile.founder.section.marketFit.form.save.success',
    'utility.noResultsFound',
  ]);

  const { mutateAsync: saveMarketFitAnswers, isLoading: isMutating } = useMutation(updateFounderMarketFitAction, {
    onSuccess() {
      message.success({ content: submitSuccessLL });
    },
    onError(err: AxiosError) {
      message.error({ content: getServerError(err) });
    },
  });

  const { questionsList } = useMarketFitQuestionsLabel(data ?? []);
  const formik = useMarketFitFormik(questionsList, saveMarketFitAnswers);

  const submitForm = () => {
    formik.submitForm();

    formik.resetForm({ values: formik.values });
  };

  if (!questionsList.length) return <EmptyState title={emptyLL} />;

  return (
    <S.Wrapper>
      <form>
        {questionsList.map(({ questionCode, label }) => (
          <div data-testid="market-fit-question" key={questionCode}>
            <S.FormikField
              error={formik.errors[`${questionCode}-text`]}
              touched={Boolean(formik.values[`${questionCode}-bool`]) && formik.touched[`${questionCode}-text`]}
            >
              <ConditionalShowToggle
                label={label}
                disabled={isMutating}
                value={formik.getFieldProps(`${questionCode}-bool`).value}
                onChange={(e: RadioChangeEvent) => {
                  formik.setFieldValue(`${questionCode}-bool`, e.target.value);
                  formik.setFieldTouched(`${questionCode}-bool`, true);

                  if (e.target.value === false) formik.setFieldValue(`${questionCode}-text`, null);
                  if (e.target.value === true) formik.setFieldTouched(`${questionCode}-text`);
                  setTimeout(formik.validateForm);
                }}
                RadioItem={S.Radio}
                align="start"
                textAlign="start"
                justify="start"
              >
                {questionCode === LN_QUESTION_CODE ? (
                  <S.TextArea rows={4} {...formik.getFieldProps(`${questionCode}-text`)} disabled={isMutating} />
                ) : (
                  <S.Input {...formik.getFieldProps(`${questionCode}-text`)} disabled={isMutating} />
                )}
              </ConditionalShowToggle>
            </S.FormikField>
          </div>
        ))}
        <S.SubmitButton disabled={!formik.dirty || !formik.isValid} onClick={submitForm} loading={isMutating}>
          {submitLL}
        </S.SubmitButton>
      </form>
    </S.Wrapper>
  );
};

import { useWysiwyg } from '@pages/content/profile/hooks/use-wysiwyg';
import { LazyEditor } from '@pages/content/profile/parts/wysiwyg/lazy-editor';
import { theme } from '@styles/theme-config';
import { EditorState, type RawDraftContentState } from 'draft-js';

interface DescriptionEditorProps {
  onChange: (contentState: RawDraftContentState) => void;
  placeholder?: string;
  content?: RawDraftContentState;
}

export const DescriptionEditor = ({ onChange, placeholder, content }: DescriptionEditorProps) => {
  const { state, setState } = useWysiwyg({ content });

  const onEditorStateChange = (nextState: EditorState) => {
    setState(nextState);
  };

  return (
    <LazyEditor
      webDriverTestID="editor-text-area"
      stripPastedStyles
      onChange={onChange}
      onEditorStateChange={onEditorStateChange}
      toolbar={{
        options: ['link', 'inline', 'blockType', 'list', 'textAlign'],
        inline: { options: ['bold', 'italic', 'underline'] },
        textAlign: {
          inDropdown: true,
          options: ['left', 'center', 'right', 'justify'],
        },
        list: {
          inDropdown: false,
          options: ['ordered', 'unordered'],
        },
        link: {
          inDropdown: false,
          options: ['link'],
          showOpenOptionOnHover: false,
          defaultTargetOption: '_blank',
        },
        blockType: {
          inDropdown: true,
          options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
        },
      }}
      editorStyle={{
        minHeight: '300px',
        height: 'auto',
        paddingLeft: theme.spacing.small,
        wordBreak: 'break-all',
      }}
      toolbarStyle={{
        border: 'none',
        borderBottom: `1px solid ${theme.color.border}`,
        borderTopLeftRadius: theme.radius.default,
        borderTopRightRadius: theme.radius.default,
        padding: theme.spacing.small,
      }}
      wrapperStyle={{
        border: `1px solid ${theme.color.border}`,
        borderRadius: theme.radius.default,
      }}
      placeholder={placeholder}
      editorState={state}
    />
  );
};

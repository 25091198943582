import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './value.styles';

export const Value = ({ value, note }: { value: boolean | null; note: string | null }) => {
  const [yesLabel, noLabel, undisclosedLabel] = useTranslation([
    'global.yes',
    'global.no',
    'pulse.boolean.undisclosed',
  ]);

  const config = (() => {
    if (value === null) return { label: undisclosedLabel, icon: <S.Undisclosed /> };
    if (value) return { label: yesLabel, icon: <S.Yes /> };
    return { label: noLabel, icon: <S.No /> };
  })();

  return (
    <S.Wrapper>
      <S.IconContainer>{config.icon}</S.IconContainer>
      <S.Label>{config.label}</S.Label>
      <S.Note>{note}</S.Note>
    </S.Wrapper>
  );
};

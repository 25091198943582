import { V2Icon } from '@/components/ui/v2-icon/v2-icon';
import { V2Loader } from '@/components/ui/v2-loader/v2-loader';
import type { AdvisorRole } from '@/domain/accounts/roles';
import type { AdvisorProfilePreviewData } from '../../api/get-advisor-profile-preview-details';
import { ConnectionsManager } from './connections-manager';
import { AdvisorPreviewModalContent } from './modal-content';

import S from './modal.styles';

type AdvisorPreviewModalProps = {
  isOpen: boolean;
  data: (AdvisorProfilePreviewData & { id: string }) | null;
  isLoading?: boolean;
  close: () => void;
  onPrev: () => void;
  onNext: () => void;
  advisorRole: AdvisorRole;
  disabledPrev?: boolean;
  disabledNext?: boolean;
  revalidateData: () => void;
};

export const AdvisorPreviewModal = ({
  isOpen,
  close,
  data,
  onPrev,
  onNext,
  advisorRole,
  isLoading = false,
  disabledPrev = false,
  disabledNext = false,
  revalidateData,
}: AdvisorPreviewModalProps) => {
  return (
    <S.V2Modal
      isOpen={isOpen}
      title={<>&nbsp;</>}
      size="lg"
      onCancel={close}
      footer={
        <ConnectionsManager
          advisorId={data?.id!}
          isSaved={data?.isBookmarked ?? false}
          connectionStatus={data?.connection?.status ?? null}
          initiator={data?.connection?.initiator ?? null}
          onBookmarkSuccess={revalidateData}
          connectionId={data?.connection?.id ?? null}
        />
      }
      data-testid="advisor-preview-modal"
    >
      <S.ContentWrapper>
        {isLoading || !data ? (
          <V2Loader height="600px" />
        ) : (
          <AdvisorPreviewModalContent data={data} roleType={advisorRole} />
        )}

        <S.ButtonPrev
          disabled={disabledPrev}
          onClick={onPrev}
          data-testid="advisor-preview-modal-button-prev"
          aria-label="previous"
        >
          <V2Icon name="chevron-left" />
        </S.ButtonPrev>
        <S.ButtonNext
          disabled={disabledNext}
          onClick={onNext}
          data-testid="advisor-preview-modal-button-next"
          aria-label="next"
        >
          <V2Icon name="chevron-right" />
        </S.ButtonNext>
      </S.ContentWrapper>
    </S.V2Modal>
  );
};

import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import OnTopFooter from '@parts/on-top-footer/on-top-footer';
import { theme } from '@styles/theme-config';
import { useDeviceDetect } from '@utils/hooks/use-device-detect/use-device-detect';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { Tooltip } from 'antd';
import type { ReactNode } from 'react';
import S from './editor-drawer.styles';

const EditorDrawer = ({
  drawer: Drawer,
  search: Search,
  title,
  onClose,
  description,
  tooltip,
  isLoading,
  isMutationLoading = false,
  footer: { onConfirm, onCancel, disabledEvaluation },
  children,
  ...rest
}: {
  drawer: Function;
  search: ReactNode;
  title: string;
  onClose: () => void;
  description?: string[];
  tooltip?: ReactNode;
  isLoading: boolean;
  isMutationLoading?: boolean;
  footer: {
    onConfirm: () => void;
    onCancel: () => void;
    disabledEvaluation: {
      dirty: boolean;
      submitCount: number;
      isValid: boolean;
      isSubmitting?: boolean;
    };
  };
  children: ReactNode;
}) => {
  const {
    deviceData: { isMobile },
  } = useDeviceDetect();

  const [saveLabel, cancelLabel] = useTranslation(['global.drawer.save', 'global.drawer.cancel']);

  return (
    <Drawer
      width={isMobile ? '100%' : theme.ui.drawerWidth}
      {...rest}
      footer={
        !isLoading ? (
          <OnTopFooter
            {...rest}
            confirmLabel={saveLabel}
            cancelLabel={cancelLabel}
            onConfirm={onConfirm}
            onCancel={onCancel}
            disabledEvaluation={disabledEvaluation}
            reverseButtons
            loading={isMutationLoading}
          />
        ) : null
      }
      title={title}
      onClose={onClose}
    >
      {isLoading ? (
        <FullHeightSpinner small />
      ) : (
        <>
          {description &&
            description.map((v, index) => {
              if (tooltip) {
                return (
                  <S.Description key={index}>
                    {v}
                    <Tooltip title={tooltip} placement="rightTop">
                      <S.InfoIcon />
                    </Tooltip>
                  </S.Description>
                );
              }
              return <S.Description key={index}>{v}</S.Description>;
            })}
          {Search}
          {children}
        </>
      )}
    </Drawer>
  );
};

export default EditorDrawer;

import { customMediaQuery } from '@/styles/v2-theme/utils/custom-media-query';
import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const BadgesWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 8px;
  height: max-content;
`;

const ProfileMeta = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex-grow: 1;
`;

const FooterWrapper = styled.footer`
  margin-top: 12px;
`;

const ProfileCardHeader = styled.header`
  padding: 12px;
  background-color: ${({ theme }) =>
    themedStyle(theme.v2.mode, theme.v2.colors.neutral[200], theme.v2.colors.blue[800])};
  border-radius: ${({ theme }) => theme.v2.borders.radius.lg};

  ${ProfileMeta} {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  ${BadgesWrapper} {
    width: 100%;
    margin-bottom: 12px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  ${FooterWrapper} {
    width: 100%;
  }
`;

const ProfileMetaTitle = styled.h3`
  font-size: ${({ theme }) => theme.v2.typography.sizes.md};
  line-height: 1.4;
  font-weight: ${({ theme }) => theme.v2.typography.weights.bold};
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.neutral[50])};
  display: inline-flex;
  align-items: center;

  ${({ theme }) =>
    customMediaQuery('font-size', {
      sm: theme.v2.typography.sizes.xl,
    })}

  img {
    margin-left: 12px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const ProfileMetaSubTitle = styled.p`
  font-size: ${({ theme }) => theme.v2.typography.sizes.xs};
  font-weight: ${({ theme }) => theme.v2.typography.weights.medium};
  color: ${({ theme }) => theme.v2.colors.neutral[500]};

  ${({ theme }) =>
    customMediaQuery('font-size', {
      sm: theme.v2.typography.sizes.sm,
    })}
`;

export default { ProfileCardHeader, ProfileMeta, ProfileMetaTitle, ProfileMetaSubTitle, BadgesWrapper, FooterWrapper };

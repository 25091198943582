import styled from 'styled-components';

const PreviewCardContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  user-select: none;
`;

export default { PreviewCardContent };

import styled, { css } from 'styled-components';
import { ChartMetric as ChartMetricBase } from './parts/chart-metric/chart-metric';

const ZoomedWrapper = styled.div<{ isTable?: boolean }>`
  position: absolute;
  width: 100vw;
  left: 0px;
  z-index: 1;
  background-color: ${({ theme }) => theme.color.dashboardBackground};
  display: flex;
  justify-content: center;
  cursor: default;
  height: ${({ isTable }) => (isTable ? '250px' : '580px')};

  .apexcharts-toolbar {
    display: none;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: 100%;
    height: 570px;
  }
`;

const ZoomedChartMetric = styled((props) => <ChartMetricBase {...props} />)`
  .apexcharts-canvas,
  footer {
    margin-top: ${({ theme }) => theme.spacing.small};
  }
`;

const FullscreenWrapper = styled.div<{ isZooming?: boolean }>`
  height: 100%;
  width: 100%;

  ${({ isZooming }) =>
    isZooming &&
    css`
      height: 100vh;
      width: 100vw;
      background: rgba(16, 50, 142, 0.75);
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1500;
    `};
`;

export default {
  ZoomedWrapper,
  FullscreenWrapper,
  ZoomedChartMetric,
};

import styled from 'styled-components';

const AvatarFrame = styled.div`
  margin-left: ${({ theme }) => theme.spacing.medium};

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.tablet}) {
    margin-left: auto;
  }
`;

export default { AvatarFrame };

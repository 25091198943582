import { axiosInstance } from '@utils/axios/axios-instance';

export interface UpdateSmartMatch {
  industries?: string[] | null;
  customerGroup?: string[] | null;
  productStage?: string | null;
  investmentStage?: string | null;
  hasEisLimitsRemaining?: boolean;
  hasSeisLimitsRemaining?: boolean;
}

export const updateSmartMatchAction = (formData: UpdateSmartMatch) =>
  axiosInstance.patch('/profile/founder/smart-match', formData);

import { useQueryWithPagination } from '@/hooks/use-query-with-pagination';
import type { Sorter } from '@/utils/type-definitions/filters';
import { GET_FOUNDER_CONNECTIONS_KEY } from '../api/get-founder-connections.query';
import { getFounderInvites } from '../api/get-founder-invites.query';
import type { FounderPendingConnection } from '../types';

const PER_PAGE = 8;

type GetFounderPendingConnectionsQueryConfig = {
  sort?: Sorter;
};

export const useGetFounderInvitesQuery = ({ sort }: GetFounderPendingConnectionsQueryConfig) => {
  const { data, error, isLoading, isFetching, isInitialLoading, pagination, ...restQueryResult } =
    useQueryWithPagination<FounderPendingConnection>(
      [GET_FOUNDER_CONNECTIONS_KEY, sort?.key ?? '', sort?.order ?? ''],
      (page: number, perPage: number) => getFounderInvites({ page, perPage, sort }),
      { perPage: PER_PAGE, refetchOnWindowFocus: true },
    );

  return {
    ...restQueryResult,
    founderInvitesData: data,
    founderInvitesError: error,
    isFounderInvitesDataLoading: isLoading,
    isFounderInvitesDataFetching: isFetching,
    isFounderInvitesInitialLoading: isInitialLoading,
    pagination,
  };
};

import type { V2BadgeProps } from '@/components/ui/v2-badge/v2-badge.types';
import { V2Container } from '@/components/ui/v2-container/v2-container';
import { V2Loader } from '@/components/ui/v2-loader/v2-loader';
import { useModal } from '@/hooks/use-modal';
import { ONBOARDING_PATH_PERFORMANCE_KEY } from '@/pages/content/onboarding/founder/paths';
import { paths as founderOnboardingPaths } from '@/pages/content/onboarding/founder/paths.config';
import {
  getPathLength as getOnboardingPathLength,
  getPathSteps,
} from '@/pages/content/onboarding/founder/paths.helpers';
import { toPascalCase } from '@/utils/fns/to-pascal-case';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import type { ReactNode } from 'react';
import { useGetReadinessScore } from '../readiness-score/hooks/use-get-readiness-score-query';
import { AdvisorQuickSearchQuestionsModal } from './components/advisor-quick-search-questions-modal/advisor-quick-search-questions-modal';
import { CuratedFeed } from './components/curated-feed/curated-feed';
import { AdvisoryQuickSearchCard } from './components/readines-score-section/actions-widget/cards.tsx/advisory-quick-search';
import { DiscoverabilityCard } from './components/readines-score-section/actions-widget/cards.tsx/discoverability';
import { MetricsReportingCard } from './components/readines-score-section/actions-widget/cards.tsx/metrics-reporting';
import { WelcomeTourCard } from './components/readines-score-section/actions-widget/cards.tsx/welcome-tour';
import { type WidgetCardContentProps } from './components/readines-score-section/actions-widget/widget-card-content';
import { ReadinessScoreSection } from './components/readines-score-section/readiness-score-section';
import { ReadinessScoreWidget } from './components/readiness-score-widget/readiness-score-widget';
import { useFinishFounderLearningStep } from './hooks/use-finish-founder-learning-step';
import { useGetFounderLearningProgress } from './hooks/use-get-founder-learning-progress';
import { useGetFounderPublishedStatus } from './hooks/use-get-founder-published-status';
import { useGetFounderQualifyingQuestionsState } from './hooks/use-get-founder-qualifying-questions-state';
import { useGetFounderAdvisorQuestionsAnswers } from '../hooks/use-get-founder-advisor-questions-answers';

export type WidgetCardProps = WidgetCardContentProps & { badges?: V2BadgeProps[] | ReactNode };

export const Homepage = () => {
  const [titleLabel, subTitleLabel] = useTranslation([
    'homepage.readinessScoreSection.title',
    'homepage.readinessScoreSection.subTitle',
  ]);
  const { founderLearningProgressData, isFounderOnboardingProgressLoading, refetchFounderLearningProgress } =
    useGetFounderLearningProgress();
  const { finishLearningStep, isFinishLearningStepPending } = useFinishFounderLearningStep({
    onSuccess: refetchFounderLearningProgress,
  });
  const { founderAdvisorQuestionsAnswersData } = useGetFounderAdvisorQuestionsAnswers();

  const hasCompletedQuestions = Boolean(founderAdvisorQuestionsAnswersData?.advisorType);

  const metricsFinishedStepsCount = () => {
    if (!founderLearningProgressData?.data?.finishedLearningSteps) return 0;

    const metricsPathSteps = (getPathSteps(founderOnboardingPaths, ONBOARDING_PATH_PERFORMANCE_KEY) || []).map((item) =>
      toPascalCase(item.key),
    );
    const learningStepsInMetricsPath = founderLearningProgressData.data.finishedLearningSteps.filter((step) =>
      metricsPathSteps.includes(step),
    );

    return learningStepsInMetricsPath.length;
  };
  const { readinessScoreData, isReadinessScoreLoading, isReadinessScoreError, isReadinessScoreFetched } =
    useGetReadinessScore();

  const { isOpen, close, open } = useModal();

  const { isFounderPublished, isLoading: publishedDataLoading } = useGetFounderPublishedStatus();
  const { isQualifyingQuestionsStateLoading } = useGetFounderQualifyingQuestionsState();
  const isLoading =
    isFounderOnboardingProgressLoading &&
    isReadinessScoreLoading &&
    publishedDataLoading &&
    isQualifyingQuestionsStateLoading;
  const isFetched = isReadinessScoreFetched;

  if (isLoading || !isFetched) return <V2Loader height="80vh" />;
  if (isReadinessScoreError || !readinessScoreData)
    return (
      <V2Container>
        {/* TODO replace with the error message component when it's designed */}
        <p style={{ textAlign: 'center', marginBottom: '24px' }}>Failed to load readiness score data</p>
      </V2Container>
    );

  return (
    <>
      <ReadinessScoreSection title={titleLabel} subtitle={subTitleLabel}>
        <ReadinessScoreWidget data={readinessScoreData} />
        <WelcomeTourCard
          data-testid="tiles-welcome-tour-card"
          isCompleted={founderLearningProgressData?.data?.finishedLearningSteps.includes('StartHere') || false}
          onActionClick={() => finishLearningStep('StartHere')}
          disabled={isFinishLearningStepPending}
        />
        <MetricsReportingCard
          totalCount={getOnboardingPathLength(founderOnboardingPaths, ONBOARDING_PATH_PERFORMANCE_KEY)}
          completedCount={metricsFinishedStepsCount()}
          data-testid="tiles-metrics-reporting-card"
        />
        <DiscoverabilityCard isAllDone={isFounderPublished} data-testid="tiles-discoverability-card" />
        <AdvisoryQuickSearchCard
          wasCompleted={hasCompletedQuestions}
          openModal={open}
          data-testid="advisory-quick-search-card"
        />
      </ReadinessScoreSection>
      <AdvisorQuickSearchQuestionsModal isOpen={isOpen} close={close} />
      <CuratedFeed />
    </>
  );
};

import ReadingTimeIcon from '@assets/icons/reading-time.svg';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import S from './empty-state.styles';

export const EmptyState = () => {
  const text = useTranslation('notifications.overlay.emptyState');

  return (
    <S.Container>
      <S.Icon>
        <img src={ReadingTimeIcon} alt="icon" loading="lazy" />
      </S.Icon>
      <S.Text>{text}</S.Text>
    </S.Container>
  );
};

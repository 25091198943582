import {
  SubscriptionProductStatus,
  SubscriptionProductType,
  type SubscriptionProduct,
} from '@domain/accounts/subscription-products';

export const isNedWaitingForApproval = (products: SubscriptionProduct[]) =>
  products.some(
    (prod) =>
      prod.type === SubscriptionProductType.NED && prod.status === SubscriptionProductStatus.WAITING_FOR_APPROVAL,
  );

import { CheckIcon, StyledInput, StyledLabel } from './circle-loader.styles';

interface CircleLoaderProps {
  checked: boolean;
  className?: string;
}

export const CircleLoader = ({ checked, className }: CircleLoaderProps) => (
  <>
    <StyledInput type="checkbox" id="check-circle-loader" checked={checked} />
    <StyledLabel htmlFor="check-circle-loader" className={className}>
      <CheckIcon />
    </StyledLabel>
  </>
);

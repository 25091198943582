import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { applyForRole } from '../../api/apply-for-role';
import { deleteRoleCv } from '../../api/delete-role-cv';
import { uploadRoleCv } from '../../api/upload-role-cv';

import S from './apply.styles';

export const Apply = ({
  profileCvUploaded,
  roleCvName,
  isApplied,
  refetch,
  disabled = false,
  dataTestId,
}: {
  profileCvUploaded: boolean;
  roleCvName: string | null;
  isApplied: boolean;
  refetch?: Function;
  disabled?: boolean;
  dataTestId?: string;
}) => {
  const { id } = useParams() as { id: string };

  const [profileCvUploadedLabel, cvUploadedLabel, applyNowLabel, appliedLabel] = useTranslation([
    'roles.ned.profile.cv.uploaded',
    'roles.ned.cv.uploaded',
    'roles.ned.apply.cta',
    'roles.ned.applied',
  ]);

  const [cvName, setCvName] = useState<string | null>(roleCvName);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const { mutateAsync: apply } = useMutation(applyForRole(id), {
    onSuccess: () => {
      if (refetch) {
        refetch();
      }
    },
  });

  return (
    <S.Wrapper disabled={disabled}>
      {isApplied ? (
        <S.Applied>
          <S.Check />
          {appliedLabel}
        </S.Applied>
      ) : (
        <>
          {(profileCvUploaded || cvName) && (
            <S.CvPresent>{cvName ? cvUploadedLabel : profileCvUploadedLabel}</S.CvPresent>
          )}
          <S.CvUpload
            file={cvName || ''}
            uploadMutation={(formData: FormData) => {
              setIsFileUploading(true);
              return uploadRoleCv(id)(formData);
            }}
            onUploadSuccess={(_, formData) => {
              const fileName = formData ? formData.get('fileName') : '';
              setCvName(fileName as string);
              setIsFileUploading(false);
            }}
            deleteMutation={() => deleteRoleCv(id)}
            onDeleteSuccess={() => setCvName(null)}
            mimeTypes="application/pdf"
            onUploadError={() => setIsFileUploading(false)}
          />
          <S.ApplyBtn
            disabled={(!profileCvUploaded && !cvName) || isFileUploading}
            onClick={() => apply()}
            data-testid={dataTestId}
          >
            {applyNowLabel}
          </S.ApplyBtn>
        </>
      )}
    </S.Wrapper>
  );
};

import { Button, Carousel as CarouselBase } from 'antd';
import styled from 'styled-components';

const CarouselWrapper = styled.div`
  position: relative;
  margin: ${({ theme }) => theme.spacing.large} auto ${({ theme }) => theme.spacing.large};
  width: 400px;
  max-width: 100%;
  padding: 0 ${({ theme }) => theme.spacing.xlarge};

  @media (min-width: 621px) {
    width: 620px;
  }

  @media (min-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    width: 100%;
    margin: ${({ theme }) => theme.spacing.large} 0 ${({ theme }) => theme.spacing.large};
  }

  .ant-carousel .slick-dots-bottom {
    bottom: -${({ theme }) => theme.spacing.xbase};
  }
`;

const Carousel = styled(CarouselBase)`
  .slick-arrow {
    position: absolute;
    top: 50%;
    z-index: 1;

    &.slick-prev {
      left: -45px;
      transform: translateY(-50%);
    }
    &.slick-next {
      right: -25px;
      transform: translateY(-50%);
    }
  }
`;

const CarouselSlide = styled.article`
  > * {
    margin: 0 auto;
    border-color: ${({ theme }) => theme.baseColors.Blue200};
    transition: 0.3s;

    &:hover {
      background: ${({ theme }) => theme.color.legalDocumentHoverBackground};
    }
  }
`;

const ChangeButton = styled(Button)`
  height: 40px !important;
  width: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    path {
      fill: ${({ theme }) => theme.baseColors.White};
    }
  }
`;

const NextButton = styled(ChangeButton)`
  svg {
    transform: rotate(180deg);
  }
`;

const PrevButton = styled(ChangeButton)``;

export default { CarouselWrapper, Carousel, CarouselSlide, PrevButton, NextButton };

import { V2AriaTable } from '@/components/blocks/v2-aria-table/v2-aria-table';
import { V2Button } from '@/components/ui/v2-button/v2-button';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import type { FounderConnection } from '../../../types';

export const NotesButtonCell = ({
  connectionId,
  connectionNotesCount,
  toggleVisibility,
  'data-testid': dataTestId,
}: FounderConnection & { toggleVisibility: (id: string) => void; 'data-testid': string }) => {
  const [notesLabelTranslation] = useTranslation(['connections.notes']);

  return (
    <V2AriaTable.BodyCell align="center">
      <V2Button
        notificationsCount={connectionNotesCount}
        variant="link"
        startIcon="chevron-down"
        onClick={() => toggleVisibility(connectionId)}
        data-testid={dataTestId}
      >
        {notesLabelTranslation}
      </V2Button>
    </V2AriaTable.BodyCell>
  );
};

import type { FundingRound } from '@pages/content/pulse/founder/parts/progress/parts/funding-rounds/funding-rounds';
import type { AppAction } from 'src/types';

export const SET_FUNDING_ROUNDS = 'funding-rounds/set-funding-rounds';
export const TOGGLE_FUNDING_ROUNDS_LOADING = 'funding-round/toggle-funding-rounds-loading';
export const UPDATE_FUNDING_ROUND = 'funding-rounds/update-funding-round';
export const INSERT_FUNDING_ROUND = 'funding-rounds/insert-funding-round';
export const DELETE_FUNDING_ROUND = 'funding-rounds/delete-funding-round';

export interface FundingRoundsState {
  fundingRounds: FundingRound[];
  loadingFundingRounds: boolean;
}

export type FundingRoundsActionType =
  | AppAction<typeof SET_FUNDING_ROUNDS, { fundingRounds: FundingRound[] }>
  | AppAction<typeof TOGGLE_FUNDING_ROUNDS_LOADING, { loading: boolean }>
  | AppAction<typeof UPDATE_FUNDING_ROUND, { fundingRound: FundingRound }>
  | AppAction<typeof INSERT_FUNDING_ROUND, { fundingRound: FundingRound }>
  | AppAction<typeof DELETE_FUNDING_ROUND, { fundingRoundId: string }>;

export const fundingRoundsReducer = (
  state: FundingRoundsState,
  action: FundingRoundsActionType,
): FundingRoundsState => {
  switch (action.type) {
    case SET_FUNDING_ROUNDS:
      return {
        ...state,
        fundingRounds: action.fundingRounds,
      };

    case TOGGLE_FUNDING_ROUNDS_LOADING:
      return {
        ...state,
        loadingFundingRounds: action.loading,
      };

    case UPDATE_FUNDING_ROUND:
      return {
        ...state,
        fundingRounds: state.fundingRounds.map((existingFundingRound) =>
          existingFundingRound.id === action.fundingRound.id
            ? {
                ...existingFundingRound,
                ...action.fundingRound,
              }
            : existingFundingRound,
        ),
      };

    case INSERT_FUNDING_ROUND:
      return {
        ...state,
        fundingRounds: [...state.fundingRounds, action.fundingRound],
      };

    case DELETE_FUNDING_ROUND:
      return {
        ...state,
        fundingRounds: state.fundingRounds.filter(
          (existingFundingRound) => existingFundingRound.id !== action.fundingRoundId,
        ),
      };

    default:
      return state;
  }
};

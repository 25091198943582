import { V2Container } from '@/components/ui/v2-container/v2-container';
import { V2NavigationTabs } from '@/components/ui/v2-navigation-tabs/v2-navigation-tabs';
import { V2NotificationIndicator } from '@/components/ui/v2-notification-indicator/v2-notification-indicator';
import { V2Title } from '@/components/ui/v2-title/v2-title';
import { useMediaQuery } from '@/hooks/use-media-query';
import { FounderLayout } from '@/layout/founder/founder.layout';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { Connections } from './components/connections/connections';
import { FounderInvites } from './components/invites/invites';
import { useFounderInvitesCountQuery } from './hooks/use-founder-invites-count.query';

import S from './connections-page.styles';

export const FounderConnectionsPage = () => {
  const size = useMediaQuery();
  const isMobile = ['xs'].includes(size);

  const [title, connectionsLabel, invitesLabel] = useTranslation([
    'connections.title',
    'connections.founder.tab.connections',
    'connections.founder.tab.invites',
  ]);

  const { invitesCount } = useFounderInvitesCountQuery();

  return (
    <FounderLayout legacyContainer={false}>
      <V2Container>
        {!isMobile && (
          <S.TitleWrapper>
            <V2Title title={title} size="4xl" />
          </S.TitleWrapper>
        )}
        <V2NavigationTabs
          tabs={[
            {
              content: <Connections />,
              key: 'connectd',
              label: connectionsLabel,
              disabled: false,
            },
            {
              content: <FounderInvites />,
              key: 'invites',
              label: (
                <V2NotificationIndicator count={invitesCount} position="middle-right" enlargeWithPadding>
                  {invitesLabel}
                </V2NotificationIndicator>
              ),
              disabled: false,
            },
          ]}
          fallbackElement={<div>TODO Tab not found</div>}
        />
      </V2Container>
    </FounderLayout>
  );
};

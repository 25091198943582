import ChartIcon from '@assets/icons/chart.svg?react';
import styled from 'styled-components';

const Holder = styled.div`
  padding: ${({ theme }) => theme.spacing.small};
  border-radius: ${({ theme }) => theme.radius.default};
  box-shadow: none;
  min-height: 205px;
  color: ${({ theme }) => theme.color.primary};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
`;

const LabelP = styled.p`
  padding: ${({ theme }) => theme.spacing.small};
  margin-bottom: 40px;
`;

const NoDataIcon = styled(ChartIcon)`
  fill: ${({ theme }) => theme.color.iconFill};
  margin-bottom: 40px;
`;

export default { Holder, LabelP, NoDataIcon };

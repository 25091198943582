import { SecureLink } from '@/parts/link/link';
import styled from 'styled-components';

const StyledSecureLink = styled(SecureLink)`
  color: currentColor;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: currentColor;
  }
`;

export default { StyledSecureLink };

import { useV2AriaTableContext } from '../v2-aria-table-context';

import S from '../v2-aria-table.styles';

type BodyCellProps = {
  align?: 'start' | 'end' | 'center';
  children: React.ReactNode;
};

export const BodyCell = ({ children, align = 'start' }: BodyCellProps) => {
  useV2AriaTableContext();

  return <S.BodyCell align={align}>{children}</S.BodyCell>;
};

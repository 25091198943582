import { V2NotificationIndicator } from '@/components/ui/v2-notification-indicator/v2-notification-indicator';
import styled from 'styled-components';

const IconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14px;
`;

const NotificationIndicator = styled(V2NotificationIndicator)`
  position: absolute;
  top: -10px;
  right: -5px;
`;

export default { IconWrapper, NotificationIndicator };

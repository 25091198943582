import { Row as RowBase } from 'antd';
import styled from 'styled-components';

const Row = styled(RowBase)<{ direction: 'row' | 'column' }>`
  flex-direction: ${({ direction }) => direction};
  margin: ${({ theme }) => theme.spacing.small} 0;
  word-wrap: break-word;
  white-space: normal;
`;

const Label = styled.p`
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  word-wrap: break-word;
  white-space: normal;
`;

export default { Label, Row };

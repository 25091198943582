import S from './empty-graph.styles';

const EmptyGraph = ({ label }: { label: string }) => (
  <S.Holder>
    <S.NoDataIcon />
    <S.LabelP>{label}</S.LabelP>
  </S.Holder>
);

export default EmptyGraph;

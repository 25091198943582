import { NUMERIC_EDITOR_COLUMN_WIDTH } from '@pages/content/pulse/founder/parts/management/parts/metrics/parts/numeric-editor/parts/row/row.styles';
import { NumberInput as NumberInputBase } from '@parts/forms/number-input/number-input';
import styled from 'styled-components';

const Box = styled.div`
  max-width: 440px;
  width: 100%;
  margin: auto;
  border-radius: ${({ theme }) => theme.radius.medium};
  border: 2px solid ${({ theme }) => theme.color.checkboxTogglerBorder};
  padding: ${({ theme }) => theme.spacing.medium};
  margin-top: ${({ theme }) => theme.spacing.base};
  background-color: ${({ theme }) => theme.onboardingColors.onboardingBackground};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
`;

const Title = styled.h3`
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontSize.regular};
  color: ${({ theme }) => theme.onboardingColors.fontPrimary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Badge = styled.span`
  color: ${({ theme }) => theme.baseColors.Green};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontSize.xxsmall};
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: ${({ theme }) => theme.baseColors.Green}20;
  border-radius: ${({ theme }) => theme.radius.medium};
  padding: ${({ theme }) => theme.spacing.xxsmall} ${({ theme }) => theme.spacing.xsmall};
  margin: ${({ theme }) => theme.spacing.xxsmall};
`;

const Columns = styled.div<{ withBorder?: boolean }>`
  display: flex;
  padding: ${({ theme }) => theme.spacing.xsmall} 0;

  color: ${({ theme }) => theme.color.tableHeader};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  font-size: ${({ theme }) => theme.fontSize.xsmall};

  border: ${({ withBorder, theme }) => (withBorder ? `1px solid ${theme.color.border}` : 0)};
  border-right: 0;
  border-left: 0;
`;

const Column = styled.div`
  flex: ${NUMERIC_EDITOR_COLUMN_WIDTH};
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 0;
  margin: 0 2px;

  &:first-child {
    padding-left: 5px;
  }

  @media (max-width: ${({ theme }) => theme.deviceBreakpoint.mobile}) {
    flex: 100px;
  }
`;

const NumberInput = styled(NumberInputBase)`
  margin: 0;
  transition: none;
  border-radius: 0;
  height: 40px !important;
  background-color: ${({ theme }) => theme.onboardingColors.selectBorder} !important;

  &:focus,
  &:active {
    border: 0;
  }
  &.ant-input-disabled {
    opacity: 0.6;
  }
`;

const EditArea = styled.div`
  & > div {
    color: ${({ theme }) => theme.onboardingColors.headingSubtitle};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    font-size: 14px;
  }

  margin-bottom: ${({ theme }) => theme.spacing.medium};
`;

const ChartArea = styled.div`
  margin-bottom: -50px;
`;

export default { Box, Title, Badge, Columns, Column, NumberInput, EditArea, ChartArea, TitleWrapper };

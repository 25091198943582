import type { CSSProperties, ReactNode } from 'react';
import S from './element.styles';

interface HolderProps {
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
}

export const Element = ({ children, style, className, ...rest }: HolderProps) => (
  <S.Wrapper style={style} className={className} {...rest}>
    {children}
  </S.Wrapper>
);

import { TableColumn as TableColumnBase } from '@parts/table-column/table-column';
import styled, { css } from 'styled-components';

const TableColumn = styled(TableColumnBase)`
  width: 100% !important;
`;

const Name = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

const Input = styled.input<{ colored: boolean }>`
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.color.sectionBackground};
  text-overflow: ellipsis;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    opacity: 0.5;
  }

  ${({ colored }) =>
    colored &&
    css`
      color: ${({ theme }) => theme.color.fontBlue};
    `}
`;

export default { Input, TableColumn, Name };

import { Spin as SpinBase } from 'antd';
import styled from 'styled-components';

const Spin = styled(SpinBase)`
  margin: auto;
  display: block;
  padding-bottom: ${({ theme }) => theme.spacing.xsmall};
  padding-top: ${({ theme }) => theme.spacing.small};
`;

export default { Spin };

import { CountryCodes, CountryNames } from '@utils/type-definitions/iso-to-country-name';
import { countryFlag } from '../../utils/country-flag';
import S from './country-of-residence.styles';
import { FormikField } from '@pages/content/profile/parts/formik-field/formik-field';

const DisabledCountryOfResidence = ({
  countryOfResidence,
  countryOfResidenceLabel,
}: {
  countryOfResidence: CountryCodes;
  countryOfResidenceLabel: string;
}) => {
  return (
    <FormikField
      label={{
        for: 'countryOfResidence',
        label: <span>{countryOfResidenceLabel}</span>,
      }}
    >
      <S.Input
        prefix={
          <S.FlagIcon
            alt={`flag of ${CountryNames[countryOfResidence]}`}
            src={countryFlag(countryOfResidence)}
            loading="lazy"
          />
        }
        readOnly
        value={CountryNames[countryOfResidence]}
        disabled
      />
    </FormikField>
  );
};
export default DisabledCountryOfResidence;

import S from '../v2-carousel.styles';
import { useV2CarouselContext } from '../v2-carousel-context';
import { V2Icon } from '@/components/ui/v2-icon/v2-icon';

export const PrevIconButton = () => {
  const { prevBtnDisabled, onPrevButtonClick } = useV2CarouselContext();

  return (
    <S.IconButton data-testid="carousel-prev-button" disabled={prevBtnDisabled} onClick={onPrevButtonClick}>
      <V2Icon name="chevron-left" size="lg" />
    </S.IconButton>
  );
};

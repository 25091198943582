import { Button as ButtonBase, Divider as DividerBase } from 'antd';
import styled from 'styled-components';

const Divider = styled(DividerBase)`
  background-color: ${({ theme }) => theme.color.background};
`;

const Button = styled(ButtonBase)`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.color.submitButton};
  width: min-content;
  height: 40px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: ${({ theme }) => theme.fontSize.regular};
  color: ${({ theme }) => theme.color.dashboardBtn};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};

  &:hover,
  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.baseColors.Blue200};
    background-color: ${({ theme }) => theme.color.legalDocumentHoverBackground};
  }
`;

export default { Button, Divider };

import {
  InvestorNedContext,
  type InvestorNedStateApi,
} from '@pages/content/profile/investor-ned/context/investor-ned.context';
import { useContext } from 'react';

export const useInvestorNedState = (): InvestorNedStateApi => {
  const context = useContext(InvestorNedContext) as InvestorNedStateApi;

  if (!context) {
    throw new Error('useInvestorNedStateApi should be used within an InvestorNedProvider.');
  }

  return context;
};

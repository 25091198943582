import { Children, type ReactNode } from 'react';
import S from './label-row.styles';

const LabelRow = ({
  label,
  children,
  labelWidth,
  spacedChildren,
  dataTestId,
}: {
  label: string;
  children: ReactNode;
  labelWidth?: number;
  spacedChildren?: boolean;
  dataTestId?: string;
}) => (
  <S.Wrapper data-testid={dataTestId || 'label-row'}>
    <S.TitleDT $labelWidth={labelWidth}>{label}</S.TitleDT>
    <S.DescriptionDD>
      {spacedChildren
        ? Children.map(children, (child: ReactNode) => <S.InlineText data-testid="inline-text">{child}</S.InlineText>)
        : children}
    </S.DescriptionDD>
  </S.Wrapper>
);

export default LabelRow;

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_INVESTOR_NED_SELECT_OPTIONS = 'get-investor-ned-select-options';

export interface IGetInvestorNedSelectOptions {
  skillSets: string[];
  productStages: string[];
  investmentStages: string[];
  customerGroups: string[];
  locations: string[];
  industries: string[];
  // ! TODO https://exlabs.atlassian.net/browse/CON-8580
  // possibleNedRoles: string[];
}

export const getInvestorNedSelectOptionsAction = async (): Promise<GraphqlResponse<IGetInvestorNedSelectOptions>> => {
  const { data } = await axiosInstance.post('/query', {
    query: `
    query getInvestorNedSelectOptions {
      locations,
      skillSets,
      productStages,
      investmentStages,
      customerGroups,
      industries,
    }
  `,
  });

  return graphqlResponseWrapper(data, [
    'locations',
    'skillSets',
    'productStages',
    'investmentStages',
    'customerGroups',
    'industries',
  ]);
};

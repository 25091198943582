import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
`;

const LabelsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
  gap: 0;

  @media (min-width: ${({ theme }) => theme.v2.breakpoints.sm}) {
    gap: 24px;
  }
`;

const Label = styled.li`
  flex: 1 1 auto;
  min-width: 50%;

  @media (min-width: ${({ theme }) => theme.v2.breakpoints.sm}) {
    flex: 0 1 auto;
    min-width: 0;
  }

  & [data-active='true'] {
    border-bottom: ${({ theme }) => `3px solid ${theme.v2.colors.red[300]}`};
  }
`;

const LabelButton = styled.button`
  background: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral[50], theme.v2.colors.blue[800])};
  border: none;
  padding: 10px;
  width: 100%;
  font-weight: ${({ theme }) => theme.v2.typography.weights.semibold};
  font-size: ${({ theme }) => theme.v2.typography.sizes.xs};
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue[400], theme.v2.colors.neutral[50])};
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  @media (min-width: ${({ theme }) => theme.v2.breakpoints.sm}) {
    background: transparent;
    padding: 20px 0;
    font-weight: ${({ theme }) => theme.v2.typography.weights.bold};
    font-size: ${({ theme }) => theme.v2.typography.sizes.lg};
  }
`;

const ContentContainer = styled.div`
  margin-top: 24px;
`;

export default { Container, LabelsList, Label, LabelButton, ContentContainer };

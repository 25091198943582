import type { ReactNode } from 'react';
import S from './pattern.styles';

export const PatternLayout = ({ children }: { children?: ReactNode }) => (
  <S.PatternLayoutWrapper>
    <S.TopRightPattern />
    <S.BottomLeftPattern />
    {children}
  </S.PatternLayoutWrapper>
);

import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';

export const GET_COMPANY_DETAILS_CACHE_KEY = (id: string) => `get-company-details-${id}`;

export interface CompanyDetails {
  companyId: string;
  avatarUrl: string | null;
  businessName: string;
  linkedinUrl: string | null;
  twitterUrl: string | null;
  websiteUrl: string | null;
  currentStage: string | null;
  industry: string[];
  businessModel: string[];
  targetMarket: string[];
  location: string | null;
  isConnected: boolean;
}
export const getCompanyDetails =
  (investingRelationshipId: string) => async (): Promise<GraphqlResponse<CompanyDetails>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
      query getCompanyDetails {
        companySummary(investingRelationshipId: "${investingRelationshipId}") {
            companyId
            avatarUrl
            businessName
            linkedinUrl
            twitterUrl
            websiteUrl
            currentStage
            industry
            businessModel
            targetMarket
            location    
            isConnected
        }
      }
  `,
    });

    return graphqlResponseWrapper(data, ['companySummary']);
  };

import { useSavedParamsForQuery } from '@/hooks/use-saved-params-for-query';
import type { Any } from '@/types';
import { safeParseJson } from '@/utils/fns/safe-parse-json';
import { useQueryParams } from '@/utils/hooks/use-query/use-query-params';
import type { CountryCodes } from '@/utils/type-definitions/iso-to-country-name';
import type { RegionValue } from '@/utils/type-definitions/regions-values';
import EmptyBrowsing from '@assets/icons/undraw-empty.svg';
import { NedLayout } from '@layout/ned/ned.layout';
import {
  GET_SMART_MATCH_OPTIONS_REQUESTS_CACHE_KEY,
  getSmartMatchOptionsAction,
} from '@pages/content/profile/api/get-smart-match-options/get-smart-match-options.action';
import { FullHeightSpinner } from '@parts/full-height-spinner/full-height-spinner';
import { DEFAULT_ITEMS_PER_PAGE_IN_GRID, ItemsGrid } from '@parts/items-grid/items-grid';
import { PageHeader } from '@parts/page-header/page-header';
import { useQuery } from '@tanstack/react-query';
import { filterWithAllowed } from '@utils/fns/filter-with-allowed';
import { useAvailableCountriesAndRegions } from '@utils/hooks/use-available-countries-and-regions/use-available-countries-and-regions';
import { useQueryPagination } from '@utils/hooks/use-query-pagination/use-query-pagination';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import type { LastSearchDataResponseData } from '../../api/get-saved-params-for-query';
import {
  GET_COUNTRIES_USED_BY_FOUNDERS_CACHE_KEY,
  getCountriesUsedByFoundersAction,
} from '../api/get-countries-used-by-founders.action';
import {
  GET_NED_BROWSE_COMPANIES_CACHE_KEY,
  getNedBrowseCompanies,
  type NedBrowseCompaniesItems,
} from '../api/get-ned-browse-companies';
import { BrowsedFounderByNed } from '../parts/cards/browsed-founder-by-ned';
import { applySearchToQuery } from '../utils/apply-filters-to-query';
import { Filters } from './parts/filters/filters';

const filtersList = ['productStage', 'industry', 'customerGroup', 'countriesOfResidence', 'regions'] as const;
export type LensFilters = {
  industry: string[];
  customerGroup: string[];
  productStage: string[];
  countriesOfResidence: CountryCodes[];
  regions: RegionValue[];
};

const parseSavedFiltersData = (data: LastSearchDataResponseData): Partial<LensFilters> => {
  if (!data?.lastSearchData) return {};

  const parsed = safeParseJson<Record<string, Any>>(data.lastSearchData, {});

  const result: Partial<LensFilters> = {};
  for (const filterKey of filtersList) {
    if (filtersList.includes(filterKey) && parsed[filterKey]) result[filterKey] = parsed[filterKey];
  }
  return result;
};

const getFormDefaultValues = (
  query: { [key: string]: string | string[] | null },
  lastSearch: Partial<LensFilters>,
  availableRegions: RegionValue[],
  availableCountries: CountryCodes[],
): LensFilters => {
  const getQueryArray = (queryItem: string | string[] | null): string[] => {
    if (!queryItem) return [];
    if (Array.isArray(queryItem)) return queryItem;
    return [queryItem];
  };

  const customerGroup = query.customerGroup ? getQueryArray(query.customerGroup) : lastSearch.customerGroup ?? [];
  const productStage = query.productStage ? getQueryArray(query.productStage) : lastSearch.productStage ?? [];
  const industry = query.industry ? getQueryArray(query.industry) : lastSearch.industry ?? [];

  const regions = filterWithAllowed(
    query.regions ? safeParseJson<RegionValue[]>(query.regions as string, []) : lastSearch.regions ?? [],
    availableRegions,
  );

  const countriesOfResidence = filterWithAllowed<CountryCodes>(
    query.countriesOfResidence
      ? safeParseJson<CountryCodes[]>(query.countriesOfResidence as string, [])
      : lastSearch.countriesOfResidence ?? [],
    availableCountries,
  );

  return { industry, customerGroup, productStage, countriesOfResidence, regions };
};

export const NedLensPage = ({ Layout = NedLayout }: { Layout?: Function }) => {
  const [enableDataFetch, setEnableDataFetch] = useState(false);
  const history = useHistory();
  const query = useQueryParams();
  const [title, emptyTitle, emptySubTitle] = useTranslation([
    'lens.title',
    'lens.investor.emptyMatched.title',
    'lens.investor.emptyMatched.subTitle',
  ]);

  const searchQueryIncludeFilters = filtersList.some((filter) => history.location.search.includes(filter));
  const { savedParamsDataResponse, isInitialSavedParamsLoading } = useSavedParamsForQuery({
    queryKey: 'FilterCompaniesByNedQuery',
    enabled: searchQueryIncludeFilters === false,
  });

  const { data: responseSmartMatchOptions } = useQuery(
    [GET_SMART_MATCH_OPTIONS_REQUESTS_CACHE_KEY],
    getSmartMatchOptionsAction,
  );

  const {
    availableCountries,
    availableRegions,
    notUsedCountries,
    isLoaded: areAvailableCountriesAndRegionsLoaded,
  } = useAvailableCountriesAndRegions(
    GET_COUNTRIES_USED_BY_FOUNDERS_CACHE_KEY,
    getCountriesUsedByFoundersAction('NED'),
  );

  const isDataLoaded =
    Boolean(responseSmartMatchOptions?.data) && areAvailableCountriesAndRegionsLoaded && !isInitialSavedParamsLoading;

  const { items, total, isLoading, refetch, additionalData, ...paginationConfig } =
    useQueryPagination<NedBrowseCompaniesItems>({
      itemsPerPage: DEFAULT_ITEMS_PER_PAGE_IN_GRID,
      pageParam: 'page',
      action: getNedBrowseCompanies,
      cacheKey: GET_NED_BROWSE_COMPANIES_CACHE_KEY,
      scrollToTopOnPageChanged: true,
      queryConfig: { enabled: enableDataFetch },
    });

  const handleApplyFilters = (values: Partial<LensFilters>) => {
    const { page, ...initialQuery } = query;
    const { countriesOfResidence, regions, ...restValues } = values;
    applySearchToQuery({
      history,
      initialQuery: { ...initialQuery, page: '1' },
      search: {
        ...restValues,
        countriesOfResidence: countriesOfResidence?.length ? countriesOfResidence : undefined,
        regions: regions?.length ? regions : undefined,
      },
    });
  };

  useEffect(() => {
    if (!isDataLoaded) return;

    setEnableDataFetch(true);
    // apply saved filters after data is loaded
    applySearchToQuery({
      history,
      initialQuery: query,
      search: parseSavedFiltersData(savedParamsDataResponse?.data!),
      replace: true,
    });
  }, [isDataLoaded]);

  return (
    <Layout>
      <PageHeader title={title} />
      {!isDataLoaded ? (
        <FullHeightSpinner height="50vh" />
      ) : (
        <>
          <Filters
            initialValues={getFormDefaultValues(query, {}, availableRegions, availableCountries)}
            onSubmit={handleApplyFilters}
            options={{
              availableCountries,
              availableRegions,
              notUsedCountries,
              smartMatchOptions: responseSmartMatchOptions?.data!,
            }}
          />
          <ItemsGrid
            data={items}
            additionalData={additionalData}
            keyBase="browse-companies"
            isLoading={isLoading}
            Item={BrowsedFounderByNed}
            pagination={paginationConfig}
            itemProps={{ refetchBrowseFounders: refetch }}
            emptyConfig={{
              isEmpty: items.length === 0,
              title: emptyTitle,
              subTitle: emptySubTitle,
              iconUrl: EmptyBrowsing,
            }}
          />
        </>
      )}
    </Layout>
  );
};

import { themedStyle } from '@/styles/v2-theme/utils/themed-style';
import styled from 'styled-components';

const V2TourMessage = styled.div`
  padding: 12px;
  border-radius: 8px;
  background-color: ${({ theme }) =>
    themedStyle(theme.v2.mode, theme.v2.colors.neutral['50'], theme.v2.colors.neutral['50'])};
`;

const Title = styled.header`
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.blue['400'], theme.v2.colors.blue['400'])};
  font-size: ${({ theme }) => theme.v2.typography.sizes.sm};
  font-weight: ${({ theme }) => theme.v2.typography.weights.bold};
`;

const Description = styled.div`
  color: ${({ theme }) => themedStyle(theme.v2.mode, theme.v2.colors.neutral['500'], theme.v2.colors.neutral['500'])};
  font-size: ${({ theme }) => theme.v2.typography.sizes.sm};
  margin-top: 8px;
`;

const Footer = styled.footer`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

export default { V2TourMessage, Title, Description, Footer };

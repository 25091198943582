import ArrowRightBlue from '@assets/icons/arrow-right-blue.svg?react';
import ConnectIcon from '@assets/icons/investor-onboarding/connect-colorful.svg?react';
import InvestIcon from '@assets/icons/investor-onboarding/invest-white.svg?react';
import MetricsIcon from '@assets/icons/investor-onboarding/metrics-colorful.svg?react';
import ProfileIcon from '@assets/icons/investor-onboarding/profile-white.svg?react';
import SettingsIcon from '@assets/icons/investor-onboarding/settings-colorful.svg?react';
import { TranslationText } from '@parts/translation-text/translation-text';
import { useElementRevealAnimation } from '@utils/hooks/use-element-reveal-animation/use-element-reveal-animation';
import { useListEntryRevealAnimation } from '@utils/hooks/use-list-reveal-animation/use-list-reveal-animation';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import type { ReactNode } from 'react';
import S from './match-features.style';

export interface MatchesListItem {
  title: ReactNode;
  icon: ReactNode;
}

const matchesIconsList: MatchesListItem[] = [
  { title: <TranslationText id="investor-onboarding.match.features.list.settings" />, icon: <SettingsIcon /> },
  { title: <TranslationText id="investor-onboarding.match.features.list.metrics" />, icon: <MetricsIcon /> },
  { title: <TranslationText id="investor-onboarding.match.features.list.profiles" />, icon: <ProfileIcon /> },
  { title: <TranslationText id="investor-onboarding.match.features.list.connect" />, icon: <ConnectIcon /> },
  { title: <TranslationText id="investor-onboarding.match.features.list.invest" />, icon: <InvestIcon /> },
];

export const MatchFeatures = () => {
  const [description] = useTranslation(['investor-onboarding.match.features.description']);
  useListEntryRevealAnimation('list-elements');
  useElementRevealAnimation('description');

  return (
    <section data-testid="match-features">
      <S.ListDescription data-animation-id="description">{description}</S.ListDescription>

      <S.List data-testid="match-features-list">
        {matchesIconsList.map(({ title, icon }, idx) => (
          <S.ListElement key={idx} data-testid="match-features-list-element" data-animation-id="list-elements">
            <S.FeatureItem>
              {icon}
              <S.FeatureItemTitle>{title}</S.FeatureItemTitle>
            </S.FeatureItem>
            <S.FeatureItemArrow>
              <ArrowRightBlue />
            </S.FeatureItemArrow>
          </S.ListElement>
        ))}
      </S.List>
    </section>
  );
};

import S from './simple-info-tile.styles';
import { TranslationText } from '@parts/translation-text/translation-text';
import type { AppMessagesKey } from '@i18n/messages';

export const SimpleInfoTile = ({
  title,
  iconUrl,
  description,
  secondDescription,
  link,
  selected = false,
  additionalText,
  onClick,
}: {
  title: AppMessagesKey;
  iconUrl: string;
  description: AppMessagesKey;
  secondDescription?: AppMessagesKey;
  link: string;
  selected?: boolean;
  onClick: () => void;
  additionalText?: AppMessagesKey;
}) => {
  return (
    <S.Tile $selected={selected} onClick={onClick} data-testid="simple-info-tile">
      {iconUrl ? <img src={iconUrl} alt={`${title}-icon`} loading="lazy" /> : null}
      <S.Title>
        <TranslationText id={title} />
      </S.Title>
      <S.Description>
        <TranslationText id={description} />
      </S.Description>
      {secondDescription && (
        <S.Description>
          <TranslationText id={secondDescription} />
        </S.Description>
      )}
      <S.SecureLink href={link} outer target="_blank" onClick={(e) => e.stopPropagation()}>
        <TranslationText id={title} />
      </S.SecureLink>
      {additionalText ? (
        <S.Description>
          <i>
            <TranslationText id={additionalText} />
          </i>
        </S.Description>
      ) : null}
      {selected ? <S.Tick data-testid="tick-icon" /> : null}
    </S.Tile>
  );
};

import message from '@/parts/message/message';
import { getServerError } from '@/utils/fns/get-server-error';
import { useTranslation } from '@/utils/hooks/use-translation/use-translation';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { editExperience } from '../api/edit-experience.action';
import { GET_EXPERIENCE_QUERY_KEY } from '../api/get-experience.action';

export const useEditExperience = (cb?: () => void) => {
  const [saved] = useTranslation(['global.saved']);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: editExperience,
    onSuccess: async () => {
      message.success({ content: saved });
      queryClient.refetchQueries([GET_EXPERIENCE_QUERY_KEY]);
      cb?.();
    },
    onError: (error: AxiosError) => message.error({ content: getServerError(error) }),
  });

  return {
    isEditExperienceLoading: mutation.isLoading,
    isEditExperienceError: mutation.isError,
    editExperienceMutation: mutation.mutate,
    ogMutation: mutation,
  };
};

import { useDrawer } from '@parts/drawer/use-drawer';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useState, type HTMLAttributes } from 'react';
import type { PreviousInvestmentWithId } from '../../investment';
import { AddInvestmentDrawer } from '../add-investment-drawer/add-investment-drawer';
import S from './editor.styles';
import { DeleteModal } from './parts/delete-modal/delete-modal';
import { PreviousInvestmentsTable } from './parts/table/table';

interface InvestmentsTableProps extends HTMLAttributes<HTMLDivElement> {
  data: PreviousInvestmentWithId[];
  updateInvestments: (payload: PreviousInvestmentWithId[]) => Promise<void>;
  shouldShowAddInvestmentButton?: boolean;
  showEmptyTable?: boolean;
  disabled?: boolean;
}

export const PreviousInvestmentsEditor = ({
  data,
  updateInvestments,
  shouldShowAddInvestmentButton = true,
  showEmptyTable = true,
  disabled = false,
  ...restProps
}: InvestmentsTableProps) => {
  const [addInvestmentLabel] = useTranslation(['profile.investor.section.investments.table.addInvestment']);

  const [error, setError] = useState<string | null>(null);
  const [currentRowId, setCurrentRowId] = useState<string | null>(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const { Drawer, show: showDrawer, hide: hideDrawer, isVisible: isCreatingNew } = useDrawer();

  const onEditRow = (row: PreviousInvestmentWithId) => {
    setCurrentRowId(row.id);
    setIsDeleteModalVisible(false);
    setError(null);
    showDrawer();
  };

  const onRemoveRow = (row: PreviousInvestmentWithId) => {
    setCurrentRowId(row.id);
    hideDrawer();
    setError(null);
    setIsDeleteModalVisible(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalVisible(false);
  };

  const createInvestment = async (v: PreviousInvestmentWithId) => {
    await updateInvestments([...data, v]);
    hideDrawer();
  };

  const editInvestment = async (v: PreviousInvestmentWithId) => {
    if (!v) return;
    const targetIndex = data.findIndex((item) => item.id === currentRowId);
    const updated = [...data];
    updated[targetIndex] = v;

    await updateInvestments(updated);
    hideDrawer();
    setCurrentRowId(null);
  };

  const deleteInvestment = async (id: string) => {
    const filteredData = data.filter((inv) => inv.id !== id);

    await updateInvestments(filteredData);

    setIsDeleteModalVisible(false);
    hideDrawer();
    setCurrentRowId(null);
  };

  const handleDrawerClose = () => {
    hideDrawer();
    setCurrentRowId(null);
  };

  const currentInvestment = data.find((item) => item.id === currentRowId) ?? null;

  const isDrawerVisible = currentRowId || isCreatingNew;
  const isEditing = currentRowId;

  const showTable = showEmptyTable ? true : data.length > 0;

  return (
    <S.TableWrapper {...restProps} data-testid="investments-table">
      {showTable ? (
        <PreviousInvestmentsTable data={data} onEditRow={onEditRow} onRemoveRow={onRemoveRow} disabled={disabled} />
      ) : null}
      {shouldShowAddInvestmentButton && (
        <S.Button onClick={showDrawer} data-testid="investments-add-round-trigger">
          {addInvestmentLabel}
        </S.Button>
      )}
      {isDrawerVisible && (
        <AddInvestmentDrawer
          drawer={Drawer}
          onClose={handleDrawerClose}
          loading={false}
          investment={currentInvestment}
          error={error || undefined}
          onSubmit={isEditing ? editInvestment : createInvestment}
        />
      )}
      {isDeleteModalVisible && currentInvestment && (
        <DeleteModal
          onConfirmClick={() => deleteInvestment(currentInvestment.id)}
          onDeleteClick={handleDeleteModalClose}
        />
      )}
    </S.TableWrapper>
  );
};

import { createContext, type Dispatch } from 'react';
import type { MetricsActionType, MetricsState } from './metrics.reducer';

export type MetricsStateApi = {
  state: MetricsState;
  dispatch: Dispatch<MetricsActionType>;
};

export const metricsInitialState: MetricsState = {
  ownerData: {
    name: '',
    avatarUrl: '',
  },
};

export const MetricsContext = createContext<MetricsStateApi | MetricsState>(metricsInitialState);

import { ShowMoreLessButton } from '@parts/show-more-less-button/show-more-less-button';
import { Table } from '@parts/table/table';
import { useTranslation } from '@utils/hooks/use-translation/use-translation';
import { useState } from 'react';
import { Columns } from './parts/columns';
import { Row } from './parts/row';

export interface ManagementTeam {
  id: string;
  isDeletedByFounder: boolean;
  name: string;
  role: string;
  email: string | null;
  phoneNumber: string | null;
  age: number | null;
  gender: string | null;
  ethnicity: string | null;
}

const MANAGEMENT_TEAM_STARTING_LENGTH = 3;

export const ManagementTeamTable = ({ data }: { data: ManagementTeam[] }) => {
  const [namePositionLabel, contactLabel, ageLabel, genderLabel, ethnicityLabel] = useTranslation([
    'portfolio.investor.managementTeam.namePosition',
    'portfolio.investor.managementTeam.contact',
    'portfolio.investor.managementTeam.age',
    'portfolio.investor.managementTeam.gender',
    'portfolio.investor.managementTeam.ethnicity',
  ]);

  const [isResized, setIsResized] = useState(false);

  return (
    <>
      <Table
        dataSource={data}
        pagination={{
          style: { display: 'none' },
          pageSize: isResized ? data.length : MANAGEMENT_TEAM_STARTING_LENGTH,
          hideOnSinglePage: true,
        }}
        columns={Columns({
          t: {
            namePositionLabel,
            contactLabel,
            ageLabel,
            genderLabel,
            ethnicityLabel,
          },
        })}
        rowKey="id"
        rowClassName={(row: ManagementTeam) => (row.isDeletedByFounder ? 'archived-row' : '')}
        components={{
          body: {
            row: Row,
          },
        }}
      />
      {data?.length > MANAGEMENT_TEAM_STARTING_LENGTH && (
        <ShowMoreLessButton isResized={isResized} setIsResized={setIsResized} />
      )}
    </>
  );
};

import { Select as SelectBase } from '@parts/select/select';
import { Tag as TagBase } from '@parts/tag/tag';
import styled from 'styled-components';

const Select = styled(SelectBase)`
  width: 100%;

  .ant-select-selection-overflow {
    padding-bottom: 5px;
  }

  .ant-select-selection-overflow-item {
    margin: 4px 2px;
    margin-bottom: 0;
  }

  .ant-select-selector {
    padding-right: ${({ theme }) => theme.spacing.base} !important;
  }

  .ant-select-selection-overflow-item-rest {
    .ant-select-selection-item {
      color: ${({ theme }) => theme.color.fontPrimary};
      margin-right: 2px;
      font-size: ${({ theme }) => theme.fontSize.small};
      background: transparent;
      border: 0;
      background-color: ${({ theme }) => theme.color.inputBackground};
      border: 1px solid ${({ theme }) => theme.color.border};
      border-radius: 4px;
      margin: 0;
      height: 100%;
      line-height: 34px;
    }
  }
`;

const Tag = styled(TagBase)``;

export default {
  Tag,
  Select,
};

import type { Any } from 'src/types';

export const safeParseJson = <T>(value: string, fallback: Any) => {
  try {
    const result = JSON.parse(value) as T;
    if (result === null) throw new Error('safeParseJson result cannot be null');
    return JSON.parse(value) as T;
  } catch (e) {
    return fallback as T;
  }
};

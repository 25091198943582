import { Roles } from '@domain/accounts/roles';
import { founderIdQueryParam } from '@pages/content/public-profile/founder/founder.page';
import { investorIdQueryParam } from '@pages/content/public-profile/investor/investor.page';
import AvatarWithName, { type Size } from '@parts/avatar-with-name/avatar-with-name';
import { TableColumn } from '@parts/table-column/table-column';
import { Links } from '@router/links';
import { Routes } from '@router/routes';
import { accessNestedPropertyByPath } from '@utils/fns/getByPath';
import useConnectionModal from '@utils/hooks/use-connection-modal/use-connection-modal';
import { Tooltip } from 'antd';
import * as H from 'history';
import { useState, type ReactNode } from 'react';
import type { Any, NestedKeyof } from 'src/types';
import S from './user-table-row.styles';

const DivComponent = ({ children }: { children: ReactNode }) => <div>{children}</div>;
const components = {
  link: S.Link,
  div: DivComponent,
};

type UserTableRowProps<T extends object> = {
  title: ReactNode;
  testID?: string;
  history: H.History<H.LocationState>;
  nameKey: string;
  idKey: string;
  avatarUrlKey: string;
  toRole: Roles;
  isClickableProperty?: string;
  tooltipLabel?: string;
  redirectEvaluation?: {
    key: string;
    shouldBePresent: T extends object ? NestedKeyof<T> | boolean : string | boolean;
  }[];
  forceNoRedirect?: boolean;
  avatarSize?: Size;
  width?: string;
  connectionModal?: {
    shouldBePresent: boolean;
    isFollowRequestPending: T extends object ? NestedKeyof<T> | boolean : string | boolean;
    confirmLabel: string;
    cancelLabel: string;
    contentLabel: string;
    contentQuestionLabel: string;
    confirmTitleLabel: string;
  };
  limitNameWidth?: boolean;
  refetch?: Function;
};
type CustomRowProps<T extends object> = Pick<
  UserTableRowProps<T>,
  | 'connectionModal'
  | 'forceNoRedirect'
  | 'redirectEvaluation'
  | 'idKey'
  | 'history'
  | 'toRole'
  | 'isClickableProperty'
  | 'tooltipLabel'
  | 'avatarSize'
  | 'refetch'
  | 'nameKey'
  | 'title'
  | 'avatarUrlKey'
  | 'limitNameWidth'
> & {
  row: Any;
};

export const UserTableCustomRow = <T extends object>({
  row,
  redirectEvaluation,
  connectionModal,
  forceNoRedirect,
  idKey,
  isClickableProperty,
  toRole,
  history,
  nameKey,
  title,
  tooltipLabel,
  avatarUrlKey,
  refetch,
  avatarSize = 'small',
  limitNameWidth,
}: CustomRowProps<T>) => {
  const canRedirect = (() => {
    if (redirectEvaluation) {
      return redirectEvaluation.every((cnd) => {
        const value = accessNestedPropertyByPath(row, cnd.key);
        const isArray = Array.isArray(cnd.shouldBePresent);
        const isString = typeof cnd.shouldBePresent === 'string';
        const hasCorrectType = isArray || isString;
        if (value && hasCorrectType) {
          const shouldBePresent = accessNestedPropertyByPath(
            row,
            isArray ? (cnd.shouldBePresent as unknown as string[]).join('.') : cnd.shouldBePresent,
          );
          return shouldBePresent ? value : !value;
        }

        return cnd.shouldBePresent ? value : !value;
      });
    }
  })();
  const isRequestPending = (() => {
    if (connectionModal) {
      const isArray = Array.isArray(connectionModal.isFollowRequestPending);
      const isString = typeof connectionModal.isFollowRequestPending === 'string';
      const hasCorrectType = isArray || isString;
      if (hasCorrectType) {
        const isPending = accessNestedPropertyByPath(
          row,
          isArray
            ? (connectionModal.isFollowRequestPending as unknown as string[]).join('.')
            : connectionModal.isFollowRequestPending,
        );
        return isPending;
      }
      return connectionModal.isFollowRequestPending;
    }
  })();

  const {
    Modal: ConnectionModal,
    open: isConnectionModalOpen,
    show: openConnectionModal,
    hide: hideConnectionModal,
  } = useConnectionModal();

  const [hovered, setHovered] = useState(false);
  const LinkComponent = forceNoRedirect || !canRedirect ? components.div : components.link;

  const id = accessNestedPropertyByPath(row, idKey);

  const to = (() => {
    const currentLocationAndSearch = (window.location.pathname + window.location.search).slice(1);

    if (!id) return currentLocationAndSearch;

    if (toRole === Roles.FOUNDER) {
      return history.createHref({
        search: `${founderIdQueryParam}=${accessNestedPropertyByPath(row, idKey)}`,
        pathname: Links.FOUNDER_PUBLIC_PROFILE_DETAILS(),
      });
    }

    if (toRole === Roles.INVESTOR) {
      return history.createHref({
        search: `${investorIdQueryParam}=${accessNestedPropertyByPath(row, idKey)}`,
        pathname: Routes.INVESTOR_PUBLIC_PROFILE.replace(':content', 'details'),
      });
    }

    return currentLocationAndSearch;
  })();

  const name = accessNestedPropertyByPath(row, nameKey);
  const rolesWithModal = new Set([Roles.INVESTOR, Roles.INVESTOR_NED]);
  const canPublicProfileBePreviewed = row.canBePreviewed;

  const isDisabled = (isClickableProperty && !row[isClickableProperty]) || !id || false;
  const showConnectionModal =
    connectionModal &&
    connectionModal.shouldBePresent &&
    !isRequestPending &&
    !canRedirect &&
    rolesWithModal.has(toRole) &&
    !isDisabled;

  const avatarWithNameProps = {
    tooltip: limitNameWidth ? name : undefined,
    name: name || '',
    avatarUrl: accessNestedPropertyByPath(row, avatarUrlKey),
    avatarSize: avatarSize,
    limitWidth: Boolean(limitNameWidth),
    smallerWidthLimit: Boolean(limitNameWidth),
  };

  return (
    <TableColumn title={title}>
      <Tooltip
        title={tooltipLabel && tooltipLabel}
        placement="bottom"
        align={{ offset: [-30, -5] }}
        overlayClassName={isDisabled && hovered ? 'tooltip-visible' : 'tooltip-hidden'}
      >
        <S.Wrapper
          onClick={() => {
            if (showConnectionModal) {
              openConnectionModal();
            }
          }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          disabled={isDisabled}
          cursorVisible={showConnectionModal}
        >
          {canPublicProfileBePreviewed ? (
            <LinkComponent to={to}>
              <AvatarWithName {...avatarWithNameProps} />
            </LinkComponent>
          ) : (
            <S.DisabledWrapper>
              <AvatarWithName {...avatarWithNameProps} />
            </S.DisabledWrapper>
          )}
        </S.Wrapper>
      </Tooltip>
      {connectionModal ? (
        <ConnectionModal
          open={isConnectionModalOpen}
          hide={hideConnectionModal}
          id={id}
          refetch={refetch}
          confirmLabel={connectionModal.confirmLabel}
          cancelLabel={connectionModal.cancelLabel}
          contentLabel={connectionModal.contentLabel}
          contentQuestionLabel={connectionModal.contentQuestionLabel}
          confirmTitleLabel={connectionModal.confirmTitleLabel}
          nameOfRecipient={name}
        />
      ) : null}
    </TableColumn>
  );
};

export const UserTableRow = <T extends object>({
  title,
  nameKey,
  idKey,
  avatarUrlKey,
  width = '30%',
  ...rest
}: UserTableRowProps<T>) => ({
  title,
  dataIndex: [nameKey, idKey, avatarUrlKey],
  key: idKey,
  render: (_: unknown, row: Any) => (
    <UserTableCustomRow
      title={title}
      nameKey={nameKey}
      idKey={idKey}
      avatarUrlKey={avatarUrlKey}
      {...(rest as Omit<CustomRowProps<T>, 'title' | 'nameKey' | 'idKey' | 'avatarUrlKey'>)}
      row={row}
    />
  ),
  width,
});

import { AdvisorRole } from '@/domain/accounts/roles';
import { useModal } from '@/hooks/use-modal';
import { useAdvisorPreviewProfileQuery } from '@/modules/founder/advisor-search/hooks/use-advisor-preview-profile-query';
import { useState } from 'react';

import { AdvisorPreviewModal } from '../modal';

export const useAdvisorPreview = ({
  idList,
  hasNextPage,
  fetchNextPage,
  role,
  revalidateData,
}: {
  idList: string[];
  hasNextPage: boolean;
  fetchNextPage: () => Promise<unknown>;
  revalidateData: () => Promise<unknown>;
  role: AdvisorRole;
}) => {
  const { isOpen: isModalOpen, close: closeModal, open: openModal } = useModal();
  const [previewItemId, setPreviewItemId] = useState<string>('');

  const {
    advisorPreviewProfileData,
    isAdvisorPreviewProfileLoading,
    isAdvisorPreviewProfileFetching,
    refetchAdvisorPreviewProfile,
  } = useAdvisorPreviewProfileQuery(previewItemId);

  const isFirstItem = idList.indexOf(previewItemId) === 0;
  const isLastItem = idList.indexOf(previewItemId) === idList.length - 1;

  const handleNext = () => {
    const isCurrentItemSecondLast = idList.indexOf(previewItemId) === idList.length - 2;

    if (isCurrentItemSecondLast && hasNextPage) {
      fetchNextPage();
    }

    const nextItemId = idList[idList.indexOf(previewItemId) + 1];
    if (nextItemId) {
      setPreviewItemId(nextItemId);
    }
  };

  const handlePrev = () => {
    const prevItemId = idList[idList.indexOf(previewItemId) - 1];
    if (prevItemId) {
      setPreviewItemId(prevItemId);
    }
  };

  const openPreview = (id: string) => {
    setPreviewItemId(id);
    openModal();
  };

  const closePreview = () => {
    setPreviewItemId('');
    closeModal();
  };

  const revalData = () => {
    refetchAdvisorPreviewProfile();
    revalidateData();
  };

  const data = advisorPreviewProfileData?.data ? { id: previewItemId, ...advisorPreviewProfileData.data } : null;

  return {
    openPreview: openPreview,
    component: (
      <AdvisorPreviewModal
        isOpen={isModalOpen}
        close={closePreview}
        data={data}
        isLoading={isAdvisorPreviewProfileLoading}
        onPrev={handlePrev}
        onNext={handleNext}
        disabledPrev={isFirstItem || isAdvisorPreviewProfileFetching}
        disabledNext={isLastItem || isAdvisorPreviewProfileFetching}
        advisorRole={role}
        revalidateData={revalData}
      />
    ),
  };
};

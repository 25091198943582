import {
  InvestorOnboardingContext,
  type InvestorOnboardingContextApi,
} from '@context/investor-onboarding/investor-onboarding.context';
import { useContext } from 'react';

export const useInvestorOnboardingContext = () => {
  const context = useContext(InvestorOnboardingContext) as InvestorOnboardingContextApi;

  if (!context) {
    throw new Error('useInvestorOnboardingContext should be used within Onboarding context.');
  }

  return context;
};

import { theme } from '@/styles/theme-config';
import { TourProvider as BaseTourProvider, type ProviderProps as BaseTourProviderProps } from '@reactour/tour';
import { createPointingArrow } from './utils';

import '@reactour/popover/dist/index.css';

const backDropColor = theme.v2.colors.neutral['500'];
const config: Omit<BaseTourProviderProps, 'children'> = {
  steps: [],
  showDots: false,
  showNavigation: false,
  showBadge: false,
  showCloseButton: false,
  padding: { popover: 10, wrapper: 0, mask: 0 },
  styles: {
    maskWrapper: (base) => ({
      ...base,
      opacity: 0.3,
      backgroundColor: backDropColor,
    }),
    popover: (base, state) => {
      const arrowStyles = state ? createPointingArrow(state.position, state.verticalAlign, state.horizontalAlign) : {};
      return {
        ...base,
        borderRadius: '8px',
        padding: 0,
        ...arrowStyles,
      };
    },
  },
  disableInteraction: true,
  onClickMask: () => {}, // disable click on mask actions
  keyboardHandler(e, clickProps) {
    if (!clickProps) return;
    // disable escape key
    if (e.key === 'Escape') {
      return;
    }
  },
};

export const TourProvider = ({ children }: { children: React.ReactNode }) => {
  return <BaseTourProvider {...config}>{children}</BaseTourProvider>;
};

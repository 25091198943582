import { axiosInstance } from '@utils/axios/axios-instance';
import { graphqlResponseWrapper } from '@utils/fns/graphql-response-wrapper';
import type { GraphqlResponse } from 'src/types';
import type { GetUsedCountriesAndRegionsResult } from './get-countries-used-by-investors.action';

export const GET_COUNTRIES_USED_BY_FOUNDERS_CACHE_KEY = 'get-countries-used-by-founders';

export const getCountriesUsedByFoundersAction =
  (context: 'NED' | 'INVESTOR') => async (): Promise<GraphqlResponse<GetUsedCountriesAndRegionsResult>> => {
    const { data } = await axiosInstance.post('/query', {
      query: `
    query getCountriesUsedByFounders {
      getCountriesUsedByFounders (context: ${context}) {
          usedCountries
          notUsedCountries
          regions
      }
    }
      `,
    });

    return graphqlResponseWrapper(data, ['getCountriesUsedByFounders']);
  };
